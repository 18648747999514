// import React from 'react';
import DetailsData from './DetailsData';
// import ExpansionRowDetails from 'components/Tables/ExpansionRowDetails';
import useStyles from './useStyles';

type IProps = { assessmentDetails: any };

const dataConfig = {
  column1: [
    {
      key: 'zdevMetadata.bundleIdentifier',
      label: 'Bundle ID:',
      // fetch: () => (<span>Name goes here</span>)
    },
    {
      key: 'createdAt',
      label: 'Created:',
    },
    {
      key: 'createdAt',
      label: 'Assessment Date:',
    },
  ],
  column2: [
    {
      key: 'buildFindingsCount',
      label: 'Findings Count:',
    },
    // {
    //   key: 'buildFindingsCount',
    //   label: 'Open Tickets:',
    // },
    // {
    //   key: 'buildFindingsCount',
    //   label: 'Open Findings:',
    // },
  ],
  column3: [
    {
      key: 'zdevMetadata.md5',
      label: 'File Hash:',
    },
  ],
};
const FindingsAssessmentsDetailsCard = ({ assessmentDetails }: IProps) => {
  const classes = useStyles();
  if (!assessmentDetails) {
    return null;
  }

  return (
    <div className={classes.detailsDataContainer}>
      <DetailsData
        dataProperty="column1"
        config={dataConfig}
        data={assessmentDetails}
      />
      <DetailsData
        dataProperty="column2"
        config={dataConfig}
        data={assessmentDetails}
      />
      <DetailsData
        dataProperty="column3"
        config={dataConfig}
        data={assessmentDetails}
      />
    </div>
  );
};

export default FindingsAssessmentsDetailsCard;
