import { IFilterChip, IFilterMapping } from './models';
import { IQueryParams } from 'components/main/devices/common/models';

export const commonIgnoredQueryParams = [
  'duration',
  'page',
  'size',
  'order',
  'orderBy',
  'dependentData',
  'search',
  'tab',
];

export const uncommonIgnoredQueryParams = [
  'teamId',
  'zappId',
  'teamfilter',
  'appId',
];

export const createChipsFromQueryParams = (
  mapping: IFilterMapping[],
  queryParams: IQueryParams
): IFilterChip[] => {
  const queryParamNames = Object.keys(queryParams);
  const ignoredParamNames = [
    ...commonIgnoredQueryParams,
    ...uncommonIgnoredQueryParams,
  ];

  const filteredMapItems = mapping.filter(
    ({ path }) =>
      queryParamNames.includes(path) && !ignoredParamNames.includes(path)
  );

  return filteredMapItems.map(({ path, transform }) => {
    if (typeof transform !== 'function') {
      return { label: '', name: path, value: '' };
    }

    const transformedResult = transform(queryParams);

    return {
      label: `${transformedResult?.label ?? ''}: ${transformedResult?.value}`,
      name: path,
      value: transformedResult?.value ?? '',
    };
  });
};
