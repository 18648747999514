import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    collapseRoot: {
      backgroundColor: palette.background.default,
      width: '100%',
    },
    listRoot: {
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
      listStyle: 'none',
      paddingLeft: 0,
    },
    elementRoot: {
      display: 'inline-block',
    },
    elementLabel: {
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
    },
  })
);

export default useStyles;
