import Checkbox from 'components/UI/input/Checkbox';
import InputSkeleton from 'components/UI/InputSkeleton';
import useStyles from './useStyles';
import { TFunction } from 'react-i18next';

interface IGroupsSkeletonProps {
  t: TFunction<'translation', undefined>;
  isSOTIConnector: boolean;
  isGoogleConnector: boolean;
  hasSpacesCapability: boolean;
  isIntune: boolean;
  thirdPartyMDMSelected: boolean;
}

const GroupsSkeleton: React.FC<IGroupsSkeletonProps> = ({
  t,
  isSOTIConnector,
  isGoogleConnector,
  hasSpacesCapability,
  isIntune,
  thirdPartyMDMSelected,
}) => {
  const classes = useStyles();
  if (isSOTIConnector || isGoogleConnector) {
    return (
      <div className={classes.nestedGroups}>
        <Checkbox
          disabled
          label={
            isGoogleConnector
              ? t('MTD.INTEGRATIONS.NESTED_ORG_UNITS')
              : t('MTD.INTEGRATIONS.INCLUDE_NESTED_GROUPS')
          }
        />
      </div>
    );
  }

  return (
    <>
      {isIntune && (
        <div className={classes.skeletonContainer}>
          <div className={classes.skeletonWithSpace}>
            <InputSkeleton label={t('MTD.INTEGRATIONS.SYNC_MODE')} />
          </div>
          <div className={classes.maxIdleSkeleton}>
            <InputSkeleton label={t('MTD.INTEGRATIONS.MAX_IDLE_OF_DAYES')} />
          </div>
          {!thirdPartyMDMSelected && (
            <div className={classes.nestedGroups}>
              <Checkbox
                disabled
                label={t('MTD.INTEGRATIONS.INCLUDE_NESTED_GROUPS')}
              />
            </div>
          )}
        </div>
      )}
      {hasSpacesCapability && (
        <div className={classes.skeletonWithDeviceSpace}>
          <InputSkeleton label={t('GLOBAL.DEVICE_SPACES')} />
        </div>
      )}
    </>
  );
};

export default GroupsSkeleton;
