import { useEffect, useState } from 'react';
import { IZScanPolicyLocationQuery, IZscanIndicator } from './models';
import { getIndicatorsByQuery } from 'api/zDevService';

export default (query: IZScanPolicyLocationQuery) => {
  const [indicatorTableData, setIndicatorTableData] =
    useState<IZscanIndicator[]>();

  useEffect(() => {
    const fetchRawIndicators = async () => {
      const { data } = await getIndicatorsByQuery(query);
      return setIndicatorTableData(data);
    };
    fetchRawIndicators();
  }, [query]);

  return { indicatorTableData };
};
