import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Moment from 'moment';

// ui
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ZButton from 'UI/Buttons/ZButton';

// services
import {
  openSnackBar,
  toggleModalDiffered,
  toggleModalDirect,
} from 'utils/storeUtils';

// utils
import { calculateDuration } from 'utils/RangeUtils';

import RangeSelectorAgnostic from 'components/main/common/RangeSelector-Agnostic';
import { PubSub } from 'utils/eventUtils';
import { useTranslation } from 'react-i18next';

export const CsvExport = ({ data }) => {
  const [exportRecordsCount, setExportRecordsCount] = useState(0);
  const { t, ready } = useTranslation();

  function rangeHelper(rangeObj) {
    if (rangeObj && rangeObj.duration === 9) {
      return {
        allTime: 'All Time',
      };
    }

    if (_.has(rangeObj, 'duration')) {
      return {
        to: Moment(calculateDuration(rangeObj.duration).before).unix(),
        from: Moment(calculateDuration(rangeObj.duration).after).unix(),
      };
    }

    if (rangeObj.to && rangeObj.from) {
      return {
        to: rangeObj.to,
        from: rangeObj.from,
      };
    }
  }

  function fetchRecordNumber() {
    let rangeObj = {};

    if (!_.isEmpty(rangeValue)) {
      rangeObj = rangeHelper(rangeValue);
    }

    data
      .downloadCount(
        {
          ...data.downloadParams,
          module: data?.module,
          to:
            rangeObj.allTime !== 'All Time' && !rangeValue
              ? _.get(data, 'downloadParams.to')
              : rangeObj.to,
          from:
            rangeObj.allTime !== 'All Time' && !rangeValue
              ? _.get(data, 'downloadParams.from')
              : rangeObj.from,
          before:
            rangeObj.allTime !== 'All Time' && rangeValue && rangeObj.before,
          after:
            rangeObj.allTime !== 'All Time' && rangeValue && rangeObj.after,
        },
        filteredTableColumnHeaders
      )
      .then((resp) => setExportRecordsCount(resp.data))
      .catch((error) => openSnackBar(`Error in record count: ${error}`));
  }

  const [rangeValue, setRangeValue] = useState(undefined);
  useEffect(() => {
    if (rangeValue) {
      fetchRecordNumber(rangeValue);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [rangeValue]);

  useEffect(() => {
    const onSetRangeValue = PubSub.subscribe(
      'RANGE_SELECTOR_ACTION',
      setRangeValue
    );

    fetchRecordNumber();
    return () => {
      onSetRangeValue.remove();
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  // all the tableheaders come in and we need to customize what the user selects via column selector
  const filteredTableColumnHeaders = data.columnHeaders
    .filter((header) => header?.show)
    .map((tableHeader) => tableHeader.id);

  function handleDownload(filename) {
    return () => {
      let rangeObj = {};

      if (!_.isEmpty(rangeValue)) {
        rangeObj = rangeHelper(rangeValue);
      }

      data
        .download(
          {
            ...data.downloadParams,
            module: data?.module,
            to:
              rangeObj.allTime !== 'All Time' && !rangeValue
                ? _.get(data, 'downloadParams.to')
                : rangeObj.to,
            from:
              rangeObj.allTime !== 'All Time' && !rangeValue
                ? _.get(data, 'downloadParams.from')
                : rangeObj.from,
            before:
              rangeObj.allTime !== 'All Time' && rangeValue && rangeObj.before,
            after:
              rangeObj.allTime !== 'All Time' && rangeValue && rangeObj.after,
          },
          filteredTableColumnHeaders
        )
        .then((resp) => {
          const link = document.createElement('a');
          link.href = resp.data;
          link.setAttribute('download', `${filename}`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          toggleModalDirect('CsvExport', false);
        })
        .catch(({ response }) => openSnackBar(_.get(response, 'data.message')));
    };
  }

  if (!ready) {
    return null;
  }

  return (
    <DialogContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        {/* <p>{t('MTD.CSV_EXPORT.MODAL_DESCRIPTION')}</p> */}
        <RangeSelectorAgnostic
          additionalRangeTypes={
            _.get(data, 'filename').includes('.csv')
              ? [{ name: t('GLOBAL.ALL_TIME'), ordinal: 9 }]
              : []
          }
        />
      </div>
      <p>{t('MTD.CSV_EXPORT.CSV_DOWNLOAD_DETAIL', { exportRecordsCount })}</p>
      <DialogActions>
        <ZButton
          styleName="modalCancel"
          action={toggleModalDiffered('CsvExport', false)}
          color="secondary"
          buttonText={t('GLOBAL.CANCEL')}
        />
        <ZButton
          action={handleDownload(data.filename)}
          buttonType="submit"
          color="primary"
          styleName="modalSave"
          buttonText={t('GLOBAL.DOWNLOAD')}
        />
      </DialogActions>
    </DialogContent>
  );
};

CsvExport.defaultProps = {
  data: {},
};

export default CsvExport;
