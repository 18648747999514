import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    empty: {
      alignItems: 'center',
      border: `1px solid ${palette.text.primary}`,
      display: 'flex',
      fontSize: 36,
      height: 'calc(100vh - 290px)',
      justifyContent: 'center',
    },
    green: {
      backgroundColor: palette.common.green,
      fontSize: 13,
      padding: '8px 15px',
      textTransform: 'uppercase',

      '&:hover': {
        backgroundColor: palette.common.avocado,
      },
    },
    mailIcon: {
      color: palette.common.almostWhite,
      '&:hover': {
        color: palette.common.lightCyan,
        cursor: 'pointer',
      },
    },
    toggledMailIcon: {
      color: palette.common.green,
      '&:hover': {
        color: palette.common.lightCyan,
        cursor: 'pointer',
      },
    },
    mdmGroupLabel: {
      fontSize: 14,
      fontWeight: 'bold',
      lineHeight: '1.2',
    },
    header: {
      color: palette.text.contrast,
    },
    policyDeployed: {
      alignItems: 'center',
      display: 'flex',

      '& div:first-child': {
        alignItems: 'center',
        border: `2px solid ${palette.common.avocado}`,
        borderRadius: 64,
        display: 'flex',
        fontSize: 36,
        height: 64,
        justifyContent: 'center',
        margin: '0 24px 0 0',
        width: 64,
        backgroundColor: palette.common.darkGrey,

        '& svg': {
          color: palette.common.white,
          height: 42,
          minHeight: 42,
          minWidth: 42,
          width: 42,
        },
      },
    },
    navigationWarning: {
      alignItems: 'center',
      display: 'flex',

      '& svg': {
        color: palette.common.tomato,
        height: 64,
        margin: '0 24px',
        minHeight: 64,
        minWidth: 64,
        width: 64,
      },
    },
    platform: {
      alignItems: 'center',
      display: 'flex',

      '& svg': {
        fontSize: 20,
        marginRight: 10,
        maxHeight: 20,
        maxWidth: 20,
        minHeight: 20,
        minWidth: 20,
      },
    },
    operatingSystem: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '50px',
    },
    tableColumn: {
      display: 'flex',
      alignItems: 'center',
    },
    checkboxColumn: {
      marginLeft: '-10px',
      display: 'flex',
      alignItems: 'center',
    },
    mdmAutoComplete: {
      width: 200,
    },
    actionsBar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    searchBox: {
      width: '40%',
    },
    searchIcon: {
      height: '100%',
      maxHeight: 'initial',
      width: 55,
      padding: '0 5px',
      marginLeft: 0,
      justifyContent: 'center',
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translateY(-50%)',
      background: palette.background.paper,
      border: `1px solid ${palette.primary.border}`,

      '& svg': {
        fontSize: 32,
      },
    },
  })
);

export default useStyles;
