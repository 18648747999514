import { ServiceHandlerFactory } from 'utils/serviceUtils';
import _ from 'lodash';

const rolesBase = `/api/auth/v1/roles`;

// export function postNewRole(role) {
//   return client.post('api/auth/v1/roles', JSON.stringify(role));
// }

// export function editSelectedRole(payload) {
//   return client.put(`api/auth/v1/roles/${payload.id}`, JSON.stringify(payload));
// }

// export function deleteSelectedRole(id) {
//   return client.delete(`/api/auth/v1/roles/${id}`);
// }

export const fetchRolesTablePage = ServiceHandlerFactory({
  axios: {
    url: `${rolesBase}/search`,
  },
});
export const fetchAllScopes = ServiceHandlerFactory({
  axios: {
    url: `/api/auth/v1/scopes/map`,
  },
});

export const fetchAllRoles = ServiceHandlerFactory({
  axios: {
    url: `${rolesBase}`,
  },
});

export const getAvailableRolesAsSelectList = roles => {
  return roles.map(role => ({
    value: role.id,
    label: role.name,
    scopeBounds: role.scopeBounds,
    accountBounded: _.eq(role.scopeBounds, 'ACCOUNT_BOUNDED'),
  }));
};
