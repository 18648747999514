import { selectedTeam as selectedTeamAtom } from 'atoms/teams';
import DevicePool from 'components/main/ZDefendInsights/OperationalStatus/DevicePool';
import React from 'react';
import { useRecoilValue } from 'recoil';
import useStyles from '../useStyles';

interface IOperationalStatusProps {
  zappId: string | null | number | undefined;
}

const OperationalStatus: React.FC<IOperationalStatusProps> = ({ zappId }) => {
  const classes = useStyles();
  const teamId = useRecoilValue(selectedTeamAtom);

  return (
    <div className={classes.dashboard}>
      <div className={classes.row}>
        <DevicePool teamId={teamId} zappId={zappId} />
      </div>
    </div>
  );
};

export default OperationalStatus;
