import {
  IGenericColumnHeader,
  IGenericRowMapping,
} from 'components/UI/GenericTable/models';
import { IResponseGroupMapping } from './models';
import { IGenericRowActions } from 'components/UI/GenericTable/GenericRowActions/models';
import GenericRowActions from 'components/UI/GenericTable/GenericRowActions';
import { TFunction } from 'react-i18next';

export const headers = (
  id: any,
  classes: any,
  t: TFunction<'translation', undefined>
): IGenericColumnHeader[] => [
  {
    id: 'group',
    label:
      id === 'GoogleAdminConnector'
        ? t('GLOBAL.UNIT_NAME')
        : t('GLOBAL.GROUP_NAME'),
  },
  {
    id: 'actions',
    label: t('GLOBAL.ACTIONS'),
    styleHeader: classes.styleActionHeader,
  },
];

export const getRowMapping = (
  classes: any,
  handleAddResponseActionGroup: () => void,
  handleDeleteResponseGroup: (id: any) => void
): IGenericRowMapping[] => [
  {
    path: 'group',
    columnContent: (fullData) => {
      const rowData: IResponseGroupMapping =
        fullData.rowData as IResponseGroupMapping;
      return (
        <div className={fullData?.classList?.columnContent}>
          {rowData?.group?.label ?? ''}
        </div>
      );
    },
  },

  {
    path: 'actions',
    columnContent: (fullData, rowIndex, tableData) => {
      const isLastRow = !!tableData?.[0] && rowIndex === tableData.length - 1;
      const defaultActions: IGenericRowActions[] = [
        {
          actionFn: () => {
            !!fullData?.rowData?.id &&
              handleDeleteResponseGroup?.(fullData?.rowData?.group?.value);
          },

          type: 'delete',
        },

        {
          actionFn: () => {
            handleAddResponseActionGroup?.();
          },
          onlyLastRow: true,
          type: 'add',
        },
      ];

      return (
        <GenericRowActions
          styleCellContent={classes.styleActionHeader}
          actions={defaultActions}
          isLastRow={isLastRow}
        />
      );
    },
  },
];
