import { useTheme } from '@material-ui/styles';
import DoughnutGraph from 'components/UI/graphs/DoughnutGraph';
import { useCallback, useEffect, useState } from 'react';
import {
  IDeviceCountsByVersion,
  IDeviceCountsData,
  IQueryParams,
} from '../common/models';
import { IOSVersionCountData } from './models';
import useStyles from './useStyles';
import { prepareDeviceCountsForGraph } from './utils';
import { useTranslation } from 'react-i18next';

interface IDevicesOverviewGraphProps {
  deviceCounts?: IDeviceCountsData;
  module: string;
  osVersionCounts?: IDeviceCountsByVersion;
  updateUrl: (params: IQueryParams) => void;
}

const DevicesOverviewGraph: React.FC<IDevicesOverviewGraphProps> = ({
  updateUrl,
  osVersionCounts,
  module,
  deviceCounts,
}) => {
  const theme: any = useTheme(); // This is any because we are not yet able to conform to MUI's them standards
  const classes = useStyles();
  const { t, ready } = useTranslation();

  const prepareGraph = useCallback(() => {
    if (!!deviceCounts && !!osVersionCounts) {
      return prepareDeviceCountsForGraph(
        deviceCounts,
        osVersionCounts,
        module,
        theme
      );
    }
  }, [osVersionCounts, deviceCounts, module, theme]);

  const [initialized, setInitialized] = useState<boolean>(false);
  const [devices, setDevices] = useState<IOSVersionCountData[] | undefined>();

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }
    if (!!deviceCounts && !!osVersionCounts) {
      // Data is manually reloaded instead of using props so the graphs don't get removed when data changes
      setDevices(prepareGraph());
    }
  }, [
    prepareGraph,
    deviceCounts,
    osVersionCounts,
    initialized,
    setInitialized,
  ]);

  const handleGraphClick = useCallback(
    (e: React.MouseEvent, elems, os: string) => {
      if (!elems.length) {
        return;
      }
      const elemIndex = elems?.[0]?._index;
      const selectedLabel = elems?.[0]?._chart?.data?.labels?.[`${elemIndex}`];
      const searchKey = os === 'allOSs' ? 'os.name' : 'os.version';
      if (searchKey === 'os.name') {
        return updateUrl({
          'os.name': selectedLabel as string,
          'os.version': undefined,
        });
      }
      return updateUrl({
        'os.name': undefined,
        'os.version': selectedLabel as string,
      });
    },
    [updateUrl]
  );

  if (!ready) {
    return null;
  }

  return (
    <div className={classes.devicesOverview}>
      {devices?.[0] &&
        devices.map(
          ({ counts, labels, os, osLabel, hide, backgroundColor, icon }) => (
            <DoughnutGraph
              data={[
                {
                  backgroundColor,
                  borderWidth: 0,
                  data: counts,
                },
              ]}
              height={285}
              hide={hide}
              icon={icon}
              key={os}
              labels={labels}
              onGraphClick={(e, activeEls) => {
                handleGraphClick(e as React.MouseEvent, activeEls, os);
              }}
              options={{ plugins: { labels: { textMargin: 10 } } }}
              pieText={
                <div className={classes.donutLegendWrapper}>
                  <span className={classes.donutLegendText}>
                    {osLabel == 'Total'
                      ? t('MTD.DEVICES.TOTAL_VERSION_DISTRIBUTION')
                      : osLabel == 'iOS'
                      ? t('MTD.DEVICES.IOS_VERSION_DISTRIBUTION')
                      : osLabel == 'Android'
                      ? t('MTD.DEVICES.ANDROID_VERSION_DISTRIBUTION')
                      : osLabel == 'Chrome OS'
                      ? t('MTD.DEVICES.CHROME_OS_VERSION_DISTRIBUTION')
                      : ''}
                  </span>
                </div>
              }
              width={300}
            />
          )
        )}
    </div>
  );
};

export default DevicesOverviewGraph;
