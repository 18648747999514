import { TFunction } from 'i18next';
import {
  csvInjectionRegex,
  looksGenerallyLikeAnEmailRegex,
} from 'utils/componentUtils';
import * as Yup from 'yup';

const Schema = (t: TFunction) => {
  const basicFields = {
    email: Yup.string()
      .matches(looksGenerallyLikeAnEmailRegex, t('STC.INVALIDEMAIL'))
      .matches(csvInjectionRegex, t('STC.MUSTNOTBEGINWITHSPECIALCHAR')),
    phoneNumber: Yup.number()
      .typeError(t('STC.MUSTBEPHONENUMBER'))
      .positive(t('STC.MUSTBEPHONENUMBER'))
      .integer(t('STC.MUSTBEPHONENUMBER')),
  };

  return Yup.object().shape(basicFields);
};

export default Schema;
