import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { fetchAllAppPolicies } from 'api/AppPolicyService';
import { fetchAllAppSettings } from 'api/AppSettingsService';
import { fetchGroupsPage } from 'api/GroupService';
import { fetchAllPolicies as fetchAllOsRiskPolicies } from 'api/OSRiskService';
import { fetchAllPhishingPolicies } from 'api/PhishingPolicyService';
import { fetchAllPrivacyPoliciesList } from 'api/PrivacyPolicyService';
import { fetchAllTRMPoliciesList } from 'api/TRMPolicyService';
import withRouter from 'components/hocs/withRouter';
import GlobalIndicatorWrapper from 'components/main/common/GlobalIndicatorWrapper';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import ProtectedMapping from 'components/main/protected/ProtectedMapping';
import { Table, TableColumnsContext } from 'components/Tables';
import _ from 'lodash';
import { groupsColumnMapping } from 'mappings/groupsMapping';
import { Component } from 'react';
import { connect } from 'react-redux';
import {
  getAvailableTeams, getAvailableTeamsAsSelectList
} from 'reducers/TeamReducers';
import {
  groupsTableColumnChange, updateUISettings
} from 'reducers/UiSettingsReducers';
import {
  getChangedTableHeadersHash, getUISettingsTableHeaders, getUISettingsWithout
} from 'reducers/UiSettingsSelectors';
import { bindActionCreators, compose } from 'redux';
import ZButton from 'UI/Buttons/ZButton';
import {
  backfillUrl,
  reduceForMultiSelectWithGlobal
} from 'utils/componentUtils';
import { toggleModalDiffered } from 'utils/storeUtils';
import { withBackfill_DEPRECATED } from '../../../hocs/withBackfill';
import { getUserScopesRoles } from 'reducers/UserReducers';
import { cleanGroupsTableData } from './utils';


class Groups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      trmPolicyList: [],
      privacyPolicyList: [],
      phishingPolicyList: [],
      appPolicyList: [],
      osRiskPolicyList: [],
    };
    this.fetchTableData = this.fetchTableData.bind(this);
    this.onPaginationChange = this.onPaginationChange.bind(this);
  }

  componentDidMount() {
    backfillUrl(this.props, ['groupsUiSettings']);
    if (!_.isEmpty(this.props.location.query.size)) {
      this.setState({ loaded: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(this.props.location.query, prevProps.location.query) &&
      !_.isEmpty(this.props.location.query.size)
    ) {
      this.setState({ loaded: true });
    }
  }

  render() {
    const { props, state } = this;
    if (!state.loaded) {
      return null;
    }

    if (!props.location.query.teamId) {
      return null;
    }
    // simple hack to remove filter based on modules
    const mtdModule = props.location.pathname.includes('mtd');
    //

    return (
      <div>
        <div className="view__header" style={{ marginBottom: 12 }}>
          <h1>Groups</h1>
          <ProtectedComponent allow={{ policies: 'manage' }}>
            <ZButton
              action={toggleModalDiffered(
                'GroupsCreateEdit',
                {
                  availableTeams: props.availableTeams,
                  appSettingsList: state.appSettingsList,
                  privacyPolicyList: state.privacyPolicyList,
                  trmPolicyList: state.trmPolicyList,
                  phishingPolicyList: state.phishingPolicyList,
                  appPolicyList: state.appPolicyList,
                  selectedTeamId: props.selectedTeam,
                  scopeBounds: props.scopeBounds,
                  osRiskPolicyList: state.osRiskPolicyList,
                },
                {
                  title: 'Create New Group',
                  fullWidth: true,
                }
              )}
              buttonText="Add New Group"
              color="secondary"
              styleName="titleBar"
              icon={{ elem: AddIcon }}
            />
          </ProtectedComponent>
        </div>

        <div style={{ marginBottom: 15 }}>
          <GlobalIndicatorWrapper inline isGlobal />
        </div>
        <Grid container spacing={8} style={{ marginTop: 10 }}>
          <Grid item md={mtdModule ? 10 : 12}>
            <TableColumnsContext.Provider
              value={{
                columnHeaders: ProtectedMapping(props.currentTableHeaders),
                columnHeadersHash: props.groupsColumnHeadersHash,
                rowMapping: ProtectedMapping(groupsColumnMapping),
                fetchTableData: this.fetchTableData,
                onColumnChange: props.groupsTableColumnChange,
                onChangePage: this.onPaginationChange,
                onChangeRows: this.onPaginationChange,
                onSort: this.onPaginationChange,
                rowHandlers: {
                  availableTeams: props.availableTeams,
                  appSettingsList: state.appSettingsList,
                  privacyPolicyList: state.privacyPolicyList,
                  trmPolicyList: state.trmPolicyList,
                  phishingPolicyList: state.phishingPolicyList,
                  appPolicyList: state.appPolicyList,
                  selectedTeamId: props.selectedTeam,
                  osRiskPolicyList: state.osRiskPolicyList,
                },
              }}
            >
              <Table tableId="Groups" fetchTableData={this.fetchTableData} />
            </TableColumnsContext.Provider>
          </Grid>
        </Grid>
      </div>
    );
  }

  async fetchTableData(query) {
    const { props } = this;
    const selectedTeam = _.get(props, 'location.query.teamId', undefined);

    // trm / threat policies

    let trmPolicyList = [];
    try {

      const { data: trmPolicyRawList } = await fetchAllTRMPoliciesList(selectedTeam);
      trmPolicyList = reduceForMultiSelectWithGlobal(trmPolicyRawList);
    } catch (e) {
      console.log('Error in Threat Policies: ', e);
    }


    // privacy policies
    let privacyPolicyList = [];
    try {

      const { data: privacyPolicyRawList } = await fetchAllPrivacyPoliciesList({
        teamId: selectedTeam,
      });
      privacyPolicyList = reduceForMultiSelectWithGlobal(privacyPolicyRawList);
    } catch (e) {
      console.log('Error in Privacy Policy:', e);
    }

    // app settings
    let appSettingsList = [];
    try {
      const { data: appSettingsRawList } = await fetchAllAppSettings({
        teamId: selectedTeam,
      });
      appSettingsList = reduceForMultiSelectWithGlobal(appSettingsRawList);
    } catch (e) {
      console.log('Error in App Policy:', e);
    }

    // phishing 
    let phishingPolicyList = [];
    try {
      const { data: phishingPolicyRawList } = await fetchAllPhishingPolicies({
        teamId: selectedTeam,
      });
      phishingPolicyList = reduceForMultiSelectWithGlobal(phishingPolicyRawList);
    } catch (e) {
      console.log('Error in Phishing Policy:', e);
    }


    // app policies
    let appPolicyList = [];
    try {

      const { data: appPolicyRawList } = await fetchAllAppPolicies({
        teamId: selectedTeam,
      });
      appPolicyList = reduceForMultiSelectWithGlobal(appPolicyRawList);
    } catch (e) {
      console.log('Error in Policy Apps:', e);
    }


    // OS Risk
    let osRiskPolicyList = [];
    try {
      const { data: osRiskPolicyRawList } = await fetchAllOsRiskPolicies(selectedTeam);
      osRiskPolicyList = reduceForMultiSelectWithGlobal(osRiskPolicyRawList);
    } catch (e) {
      console.log("Error in OS Risk Policy", e);
    }

    // Groups page
    const { data: groupsData } = await fetchGroupsPage(query);

    const tableData = cleanGroupsTableData(
      {
        groupsTableData: groupsData?.content ?? [],
        trmPolicyList,
        privacyPolicyList,
        appSettingsList,
        phishingPolicyList,
        appPolicyList,
        osRiskPolicyList
      }
    );

    this.setState({
      trmPolicyList,
      privacyPolicyList,
      appSettingsList,
      phishingPolicyList,
      appPolicyList,
      osRiskPolicyList
    });
    return {
      tableData: tableData.length ? tableData : [],
      totalResults: groupsData?.totalElements,
      isLoading: false,
    };
  }

  onPaginationChange(update) {
    this.props.updateUISettings({
      domain: 'groups',
      update,
    });
  }

}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      groupsTableColumnChange,
      updateUISettings,
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    groupsColumnHeadersHash: getChangedTableHeadersHash(state, 'groups'),
    currentTableHeaders: getUISettingsTableHeaders(state, 'groups'),
    groupsUiSettings: getUISettingsWithout(state, 'groups', ['tableHeaders']),
    accountId: _.get(state, 'user.account.id'),
    availableTeamsHash: getAvailableTeams(state),
    availableTeams: getAvailableTeamsAsSelectList(state),
    selectedTeam: _.get(state, 'teams.selected'),
    scopeBounds: state?.user?.scopeBounds ?? '',
    userScopes: getUserScopesRoles(state)
  };
};

export default compose(
  withRouter,
  withBackfill_DEPRECATED('uiSettings.groups'),
  connect(mapStateToProps, mapDispatchToProps)
)(Groups);
