import {
  getAvailableTeamsAsSelectList,
  getAvailableTeams,
} from 'reducers/TeamReducers';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

// ui
// components
import { Card } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { MultiSelect } from 'components/Selects/index';
import { Table, TableColumnsContext } from 'components/Tables';
import {
  updateUISettings,
  deviceActivationsTableColumnChange,
} from 'reducers/UiSettingsReducers';
import {
  getChangedTableHeadersHash,
  getUISettingsTableHeaders,
  getUISettingsWithout,
} from 'reducers/UiSettingsSelectors';
import { fetchAllUserActivations } from 'api/UserActivationsService';
// ui
import AddIcon from '@material-ui/icons/Add';
import ZButton from 'UI/Buttons/ZButton';
import { toggleModalDiffered, toggleModalDirect } from 'utils/storeUtils';
import withRouter from 'components/hocs/withRouter';
import { formGroupListForSelect, fetchGroupsByTeamId } from 'api/GroupService';
import TableHeaderCheckbox from 'components/Tables/TableHeader-Checkbox';
import ProtectedComponent from '../protected/ProtectedComponent';
import { DeviceActivationsColumnMapping } from './DeviceActivations.mapping';
import useBackfill from 'utils/useBackfill';
import { useTranslation } from 'react-i18next';

function DeviceActivations(props) {
  const [endUsers, setEndUsers] = useState(null);
  const [pageListUsers, setPageListUsers] = useState([]);
  const [tableHeaderCheck, setTableHeaderCheck] = useState(false);
  const {
    availableTeams = [],
    selectedTeam: selectedTeamId,
    updateUrl,
    q,
  } = props;

  useBackfill({
    query: props.q,
    requiredQueryParams: props.rqps,
    storedQueryParams: props.deviceActivationsUiSettings,
    updateUrl: props.updateUrl,
  });

  // This useEffect hack to prevent multiple data calls should be removed once we can port this to TS (JB)
  useEffect(() => {
    // Note that teamId is EXPLICITLY checked for here because it means we're not ready to fetch activations.
    // See below comment in fetchTableData()
    if (selectedTeamId === null && q?.teamId === undefined) {
      // Add teamId to query params on first load when "All Teams" is selected
      updateUrl(Object.fromEntries([['teamId', selectedTeamId || '']]));
    }
  }, [selectedTeamId, updateUrl, q?.teamId]);

  function fetchTableData() {
    // reset checkboxes both on the tableheader and table
    setEndUsers(null);
    setTableHeaderCheck(false);
    const fetchGroups = fetchGroupsByTeamId();
    const fetchDeviceActivations = fetchAllUserActivations(props.q);
    // This hack to prevent multiple data calls should be removed once we can port this to TS (JB)
    //
    // Note that we EXPLICITLY check for undefined below because:
    //   * If teamId===undefined, we should NOT fetch activations because this means that the url params
    //     haven't been updated yet.
    //   * If teamId==="" or teamId===null, we SHOULD fetch activations because this means that the url
    //     params have been updated.
    //
    if (props?.q?.teamId === undefined) return Promise.all([]);
    return Promise.all([fetchGroups, fetchDeviceActivations]).then(
      ([groups, userActivationsData]) => {
        const groupsHash = formGroupListForSelect(groups.data);
        const userActivationIdList = [];
        const tableData = userActivationsData.data.content.map(
          (userActivation) => {
            const groupName = _.get(
              groupsHash[userActivation.groupId],
              'name',
              'Unknown'
            );

            const teamId = userActivation?.teamId ?? null;
            const team =
              availableTeams.find(({ value }) => value === teamId) ?? {};

            userActivationIdList.push(userActivation.id);
            return {
              ...userActivation,
              groupName,
              team: team?.label
                ? {
                    id: teamId,
                    name: team.label,
                  }
                : {
                    id: null,
                    name: 'N/A',
                  },
            };
          }
        );
        setPageListUsers(userActivationIdList);
        return {
          tableData,
          totalResults: userActivationsData.data.totalElements,
          isLoading: false,
        };
      }
    );
  }

  function handleSelect(fieldName, value) {
    let title;

    if (value.value) {
      switch (value.value) {
        case 'resend':
          title = t('MTD.ACTIVATIONS.RESEND_INVITATION_S');
          break;
        case 'delete':
          title = t('MTD.ACTIVATIONS.DELETE_END_USER_ACTIVATION');
          break;
        default:
          break;
      }
    }

    toggleModalDirect(
      'ActivationsConfirmAction',
      {
        action: value.value,
        checkedUsers: endUsers,
      },
      { title }
    );
  }

  function handleCheckbox(selected) {
    return (e) => {
      let selectedEndUsers = { ...endUsers };
      if (e.target.checked === false) {
        delete selectedEndUsers[selected.id];
      } else if (e.target.checked === true) {
        selectedEndUsers = {
          [selected.id]: 'checked',
          ...endUsers,
        };
      }

      setTableHeaderCheck(false);
      setEndUsers(selectedEndUsers);
    };
  }

  function handleTableheaderCheckbox(e) {
    // toggle select all
    const checkmarkStatus = _.get(e, 'target.checked', false);
    if (checkmarkStatus) {
      setTableHeaderCheck(true);
      const allUsersSelected = pageListUsers.reduce((accum, value) => {
        return {
          [value]: 'checked',
          ...accum,
        };
      }, {});
      setEndUsers(allUsersSelected);
    } else {
      setTableHeaderCheck(false);
      setEndUsers({});
    }
  }
  const { t, ready } = useTranslation();

  if (!ready) {
    return null;
  }

  return (
    <div>
      <div className="view__header">
        <h1>{t('MTD.ACTIVATIONS.END_USER_ACTIVATIONS')}</h1>
        <ProtectedComponent allow={{ zapps: 'manage' }}>
          <div>
            <ZButton
              buttonText={t('MTD.ACTIVATIONS.INVITE_BULK_END_USERS')}
              color="secondary"
              styleName="titleBar"
              icon={{ elem: AddIcon }}
              action={toggleModalDiffered(
                'ActivationsBulk',
                {},
                {
                  title: t('MTD.ACTIVATIONS.INVITE_BULK_END_USERS'),
                  fullWidth: true,
                }
              )}
            />

            <ZButton
              buttonText={t('MTD.ACTIVATIONS.INVITE_END_USER')}
              color="secondary"
              styleName="titleBar"
              icon={{ elem: AddIcon }}
              action={() =>
                toggleModalDirect(
                  'UserActivationsCreateEdit',
                  {
                    availableTeams,
                    selectedTeamId,
                  },
                  {
                    title: t('MTD.ACTIVATIONS.CREATE_END_USER_ACTIVATION'),
                    fullWidth: true,
                  }
                )
              }
            />
          </div>
        </ProtectedComponent>
      </div>
      <Card>
        <Grid item md={3} style={{ padding: 5 }}>
          <MultiSelect
            name="findings-actions"
            buttonPlaceholder={t('GLOBAL.ACTION')}
            options={[
              { label: t('GLOBAL.RESEND_INVITATION'), value: 'resend' },
              { label: t('GLOBAL.DELETE'), value: 'delete' },
            ]}
            onChange={handleSelect}
            // values={state.selectedAction}
            isMulti={false}
          />
        </Grid>
      </Card>

      <TableColumnsContext.Provider
        value={{
          columnHeaders: [
            {
              id: 'id',
              label: '',
              show: true,
              sort: false,
              showInContextMenu: false,
              checkbox: true,
            },
            ...props.currentTableHeaders,
          ],
          columnHeadersHash: props.columnHeadersHash,
          rowMapping: DeviceActivationsColumnMapping(t),
          onColumnChange: props.deviceActivationsTableColumnChange,
          onChangePage: onPaginationChange,
          onChangeRows: onPaginationChange,
          onSort: onPaginationChange,
          rowHandlers: {
            availableTeams,
            handleCheckbox,
            checkedBoxes: endUsers,
            selectedTeamId,
          },
        }}
      >
        <Table
          tableId="deviceActivations"
          fetchTableData={fetchTableData}
          checkboxHandler={handleTableheaderCheckbox}
          tableHeaderCheckboxStatus={tableHeaderCheck}
          components={{
            TableHeader: TableHeaderCheckbox,
          }}
        />
      </TableColumnsContext.Provider>
    </div>
  );
}

const mapStateToProps = (state) => ({
  columnHeadersHash: getChangedTableHeadersHash(state, 'deviceActivations'),
  currentTableHeaders: getUISettingsTableHeaders(state, 'deviceActivations'),
  deviceActivationsUiSettings: getUISettingsWithout(
    state,
    'deviceActivations',
    ['tableHeaders']
  ),
  availableTeamsHash: getAvailableTeams(state),
  availableTeams: getAvailableTeamsAsSelectList(state),
  selectedTeam: _.get(state, 'teams.selected'),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deviceActivationsTableColumnChange,
      updateUISettings,
    },
    dispatch
  );

function onPaginationChange(update) {
  updateUISettings({
    domain: 'deviceActivations',
    update,
  });
}

export default compose(
  withStyles({}, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(DeviceActivations);
