import { IEditableRowMapping } from 'components/UI/EditableTable/models';
import { IGenericColumnHeader } from 'components/UI/GenericTable/models';

export const appPolicyColumnHeaders = (
  ruleType: string
): IGenericColumnHeader[] => {
  return ruleType == 'android_ooc_package' || ruleType == 'android_deny_package'
    ? androidOOCPackageHeaders
    : ruleType == 'android_ooc_package_version' ||
      ruleType == 'android_deny_package_version'
    ? androidOOCPackageVersionHeaders
    : ruleType == 'android_ooc_package_developer' ||
      ruleType == 'android_deny_developer'
    ? androidOOCDeveloperSignature
    : androidOOCPackageHeaders;
};

export const appPolicyRowMapping = (
  ruleType: string
): IEditableRowMapping[] => {
  return ruleType == 'android_ooc_package' || ruleType == 'android_deny_package'
    ? androidOOCPackageRowMapping
    : ruleType == 'android_ooc_package_version' ||
      ruleType == 'android_deny_package_version'
    ? androidOOCPackageVersionRowMapping
    : ruleType == 'android_ooc_package_developer' ||
      ruleType == 'android_deny_package_developer'
    ? androidOOCDeveloperSignatureRowMapping
    : androidOOCPackageRowMapping;
};

const androidOOCPackageRowMapping: IEditableRowMapping[] = [
  {
    path: 'select',
    alwaysEnabled: true,
    inputType: 'checkbox',
  },
  {
    path: 'name',
    inputType: 'text',
  },
  {
    path: 'package',
    inputType: 'text',
  },
];
const androidOOCPackageVersionRowMapping: IEditableRowMapping[] = [
  {
    path: 'select',
    alwaysEnabled: true,
    inputType: 'checkbox',
  },
  {
    path: 'name',
    inputType: 'text',
  },
  {
    path: 'package',
    inputType: 'text',
  },
  {
    path: 'version',
    inputType: 'text',
  },
];
const androidOOCDeveloperSignatureRowMapping: IEditableRowMapping[] = [
  {
    path: 'select',
    alwaysEnabled: true,
    inputType: 'checkbox',
  },
  {
    path: 'name',
    inputType: 'text',
  },
  {
    path: 'signature',
    inputType: 'text',
  },
];

const androidOOCPackageHeaders: IGenericColumnHeader[] = [
  {
    id: 'select',
    label: 'checkbox',
    isSelectAllCheckbox: true,
    onCheckboxChange: e => {
      console.log(e.currentTarget.checked);
    },
  },
  {
    id: 'name',
    label: 'App Name',
  },
  {
    id: 'package',
    label: 'Package ID',
  },
];
const androidOOCPackageVersionHeaders: IGenericColumnHeader[] = [
  {
    id: 'select',
    label: 'checkbox',
    isSelectAllCheckbox: true,
    onCheckboxChange: e => {
      console.log(e.currentTarget.checked);
    },
  },
  {
    id: 'name',
    label: 'App Name',
  },
  {
    id: 'package',
    label: 'Package ID',
  },
  {
    id: 'version',
    label: 'Version',
  },
];
const androidOOCDeveloperSignature: IGenericColumnHeader[] = [
  {
    id: 'select',
    label: 'checkbox',
    isSelectAllCheckbox: true,
    onCheckboxChange: e => {
      console.log(e.currentTarget.checked);
    },
  },
  {
    id: 'name',
    label: 'Developer Name',
  },
  {
    id: 'signature',
    label: 'Developer Signature',
  },
];
