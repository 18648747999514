import Moment from 'moment';
import _ from 'lodash';
import { Api } from 'config/axiosConfig';
import {
  ServiceHandlerFactory /*, formatFinalParams*/,
} from 'utils/serviceUtils';
import { fetchSupportUsersParamsMapping } from 'mappings/services/supportUsersServiceMapping';
import { fetchAdminAccountParamsMapping } from '../components/admin/accounts/accounts.mappings';

export const fetchAdminAccount = ServiceHandlerFactory({
  axios: {
    url: 'api/auth/v2/accounts',
    method: 'get',
  },
  paramSchema: fetchAdminAccountParamsMapping,
});

export function fetchAllAccounts() {
  return Api.get('api/auth/v1/accounts/all');
}

export function fetchAdminAccountById(accountId) {
  return Api.get(`api/auth/v2/accounts/${accountId}`);
}
export function updateAdminAccountById(accountId, account) {
  return Api.put(`api/auth/v2/accounts/${accountId}`, account);
}

export function addNewAccount(account) {
  return Api.post('/api/auth/v2/accounts', account);
}

export function updateFavoriteStatus(accountId, value) {
  return Api.put(`/api/auth/v1/accounts/${accountId}/favorite/${value}`);
}

export const fetchSuperUsersByAccount = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/accounts/superusers',
  },
  paramSchema: fetchSupportUsersParamsMapping,
});

export const deleteAccount = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/accounts/:accountId',
    method: 'delete',
  },
});

export function serializeAdminAccount(data) {
  const responseData = {};
  responseData.description = data?.description;
  responseData.accountName = data?.name;
  responseData.businessPartner = data?.businessPartner;
  responseData.subscriptionId = _.get(data, 'subscription.id', '');
  responseData.ela = _.get(data, 'subscription.ela', '');
  responseData.active = _.get(data, 'subscription.active', '');
  responseData.salesforceId = _.get(data, 'subscription.salesforceId', '');
  responseData.ela = _.get(data, 'subscription.ela', '');
  responseData.subscriptionEnd = Moment(
    _.get(data, 'subscription.subscriptionEnd', '')
  ).format('YYYY-MM-DD');
  responseData.planId = data?.subscription?.planId;
  responseData.created = _.get(data, 'created', new Date());
  return { ...responseData, data };
}
