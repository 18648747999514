import { default as MUIArrowDropDownIcon } from '@material-ui/icons/ArrowDropDown';
import { default as MUIArrowRightIcon } from '@material-ui/icons/ArrowRight';
import { default as MUITreeView } from '@material-ui/lab/TreeView';
import Select, { ISelectItem } from 'components/UI/input/Select';
import {
  Field as FormikField,
  Form as FormikForm,
  Formik,
  FormikProps,
} from 'formik';
import React, { Fragment, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  hasChangesCategories as hasChangesAtom,
  resetCatHandling as resetCategoriesAtom,
} from '../atoms';
import { ICategories, ICategory } from '../models';
import PhishingFormsChangesCheck from '../PhishingFormsChangesCheck';
import {
  apiCategories,
  CATEGORY_OPTIONS,
  checkSubCatFieldsEqual,
  initialValues,
  referenceValues,
  SUB_CATEG_OPTIONS,
} from '../utils';
import CategoryTreeItem from './CategoryTreeItem';
import useStyles from './useStyles';

interface ICategoryTreeProps {
  categoryList: {
    categoryGroups: ICategories[];
  };
  categFromApi?: ICategory[];
}

const CategoryTree: React.FC<ICategoryTreeProps> = ({
  categoryList,
  categFromApi,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const categoryGroups = categoryList?.categoryGroups;
  const resolvedInitialValues = initialValues(t, categoryGroups);
  const formReferenceValues = referenceValues(categoryGroups);
  const setFormHasChanges = useSetRecoilState(hasChangesAtom);
  const resetCategories = useRecoilValue(resetCategoriesAtom);
  const policyApiCateg = apiCategories(t, categoryGroups, categFromApi);
  const hasValues = categFromApi?.length ?? 0;

  const handleMultipleChange = useCallback(
    (
      field: string,
      value: ISelectItem,
      setField: (field: string, val: ISelectItem) => void
    ) => {
      setField(field, value);
      formReferenceValues.forEach((el) => {
        if (field === el.name) {
          el.categories.forEach((el) => {
            setField(el, value);
          });
        }
      });
    },
    [formReferenceValues]
  );

  const checkForChanges = useCallback(
    (dirty: boolean) => {
      setFormHasChanges(dirty);
    },
    [setFormHasChanges]
  );

  const formRef = useRef<FormikProps<any>>(null);

  return (
    <Formik
      enableReinitialize
      initialValues={
        hasValues && !resetCategories ? policyApiCateg : resolvedInitialValues
      }
      onSubmit={() => {}}
      innerRef={formRef}
    >
      {({ values, setFieldValue, dirty }) => {
        return (
          <FormikForm>
            <PhishingFormsChangesCheck
              checkForChangesCateg={checkForChanges}
              dirtyCateg={dirty}
              catValues={values}
            />
            <MUITreeView
              className={classes.tree}
              defaultCollapseIcon={<MUIArrowDropDownIcon />}
              defaultExpandIcon={<MUIArrowRightIcon />}
              defaultEndIcon={<div style={{ width: 54 }} />}
            >
              {!!categoryGroups &&
                categoryGroups.map((cat, idx) => (
                  <Fragment key={idx}>
                    <CategoryTreeItem
                      nodeId={`sup-${cat.groupId.toString()}`}
                      labelText={cat.name!}
                      labelInfo={
                        <FormikField
                          component={Select}
                          disableClearable
                          disabled={false}
                          multiple={false}
                          name={cat.name}
                          options={CATEGORY_OPTIONS(t)}
                          setFieldValue={(field: string, value: ISelectItem) =>
                            handleMultipleChange(field, value, setFieldValue)
                          }
                          type="select"
                        />
                      }
                    >
                      {cat.categories.map((subCat) => (
                        <CategoryTreeItem
                          nodeId={subCat.categoryId.toString()}
                          labelText={subCat.name}
                          labelInfo={
                            <FormikField
                              component={Select}
                              disableClearable
                              disabled={false}
                              multiple={false}
                              name={`cat-${subCat.categoryId}`}
                              options={SUB_CATEG_OPTIONS(t)}
                              setFieldValue={(
                                field: string,
                                value: ISelectItem
                              ) => {
                                checkSubCatFieldsEqual(
                                  field,
                                  value,
                                  values,
                                  setFieldValue,
                                  categoryGroups,
                                  t
                                );
                              }}
                              type="select"
                            />
                          }
                        />
                      ))}
                    </CategoryTreeItem>
                    <hr />
                  </Fragment>
                ))}
            </MUITreeView>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default CategoryTree;
