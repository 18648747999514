import MUITooltip from '@material-ui/core/Tooltip';
import MUIInfoIcon from '@material-ui/icons/InfoOutlined';
import useStyles from './useStyles';

interface ITooltipProps {
  disabled?: boolean;
  placement?:
    | 'bottom'
    | 'bottom-end'
    | 'bottom-start'
    | 'left'
    | 'left-end'
    | 'left-start'
    | 'right'
    | 'right-end'
    | 'right-start'
    | 'top'
    | 'top-end'
    | 'top-start'
    | undefined;
  tooltipText: string;
}

const Tooltip: React.FC<ITooltipProps> = ({
  disabled = false,
  placement,
  tooltipText,
}) => {
  const classes = useStyles();

  return (
    <MUITooltip
      arrow
      disableHoverListener={disabled}
      disableFocusListener={disabled}
      enterDelay={300}
      placement={placement ?? 'top'}
      title={<span className={classes.tooltipText}>{tooltipText}</span>}
      PopperProps={{
        className: classes.tooltipPopper,
      }}
    >
      <MUIInfoIcon
        color={disabled ? undefined : 'primary'}
        className={classes.icon}
      />
    </MUITooltip>
  );
};

export default Tooltip;
