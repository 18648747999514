import AT from 'actions/ActionTypes';
import { adminAccountTableHeaders } from 'components/admin/accounts/accounts.mappings';
import { superUserTableHeaders } from 'components/admin/superusers/superUser.mapping';
import { DeviceActivationsTableHeaders } from 'components/main/activations/DeviceActivations.mapping';
import { GroupActivationsTableHeaders } from 'components/main/activations/GroupActivations.mapping';
import { defaultAppsInventoryTableHeaders } from 'components/main/appsInventory/AppsInventoryTab/appsInventory.mappings';
import { defaultDevicesTableHeaders } from 'components/main/devices/deviceMappings';
import { defaultAuditLogTableHeaders } from 'components/main/manage/AuditLog/auditLog.mappings';
import { defaultAuthorizationTableHeaders } from 'components/main/manage/Authorization/authorization.mapping';
import { defaultDataExportTableHeaders } from 'components/main/manage/DataExport/dataexport.mapping';
import { defaultGroupsTableHeaders } from 'components/main/policies/groups/groups.mappings';
import { defaultMtdGroupsTableHeaders } from 'components/main/policies/groups/mtdGroups.mappings';
import {
  trmPolicyTableColumnHeaders,
  mtdTRMPolicyTableColumnHeaders,
} from 'components/main/policies/threats/TRMPolicy.mappings';
import {
  defaultThreatsTableHeaders,
  defaultThreatTableHeaders,
  defaultMtdThreatsTableHeaders,
} from 'components/main/threats/threatMappings';
import { defaultMtdThreatTableHeaders } from 'components/main/threats/threatMappings';
import { defaultRolesTableHeaders } from 'components/main/users/roles/roles.mapping';
import { defaultTeamsTableHeaders } from 'components/main/users/teams/teamsTable.mapping';
import { usersTableHeaders } from 'components/main/users/users/users.mapping';
import { managedAccountsTableHeaders } from 'components/partner/managedAccounts/managedAccounts.mapping';
import { partnerAuthorizationsTableHeaders } from 'components/partner/partnerAuthorizations.mapping';
import { partnerUsersTableHeaders } from 'components/partner/partnerUsers.mapping';
import { zDevPoliciesTableHeaders } from 'console/zdev/policies/mappings/zdevpolicies.mappings';
import { configurationsTableHeaders } from 'dashboard/zDev/Integrations/TicketingConfigurations/configurations.mapping';
import { integrationTableHeaders } from 'dashboard/zDev/Integrations/TicketingIntegrations/integrations.mapping';
import _ from 'lodash';
import { defaultZShieldAppsTableHeaders } from 'components/main/zShieldApps/ZShieldApps/zShieldApps.mappings';
import { buildsAppsTableHeaders } from 'mappings/buildsAppsMapping';
import { defaultMtdOSVersionsTableHeaders } from 'components/main/os-risk/OSVersionsTab/OSVersions.mappings';
import { defaultMtdOSRelatedCVEsTableHeaders } from 'components/main/os-risk/OSRelatedCVEsTab/OSRelatedCVEs.mappings';
import {
  AUTH_CLEAR_USER,
  ON_ADMIN_EXIT_TENANT,
  SET_BRANDING_PROPERTIES,
} from 'reducers/AuthReducers';
import { MDMTableHeaders } from '../components/main/integrations/emm/emmTable/MDM/utils';
import { defaultInappsTableHeaders } from './AppsReducers';
import { defaultInappsVersionsTableHeaders } from './AppVersionReducers';
import { defaultPlansTableHeaders } from './PlansReducers';
import { partnerUsersAdminTableHeaders } from 'components/admin/partnerUsers/partnerUsers.mapping';
import { defaultZScanAppsTableHeaders } from 'components/main/ZScanApps/zScanApps.mappings';
import { zScanAssessmentsTableHeaders } from 'components/main/ZScanApps/ZScanAssessments/zScanAssessment.mappings';
import { compareBuildsTableHeader } from 'components/main/ZScanApps/ZScanCompareAssessments/ZScanCompareAssessments.mappings';
import { findingsTableHeaders } from 'components/main/ZScanApps/ZScanFindings/zScanFindings.mappings';
import { zScanInstancesTableHeaders } from 'components/main/ZScanApps/ZScanFindings/zScanInstances/zScanInstances.mappings';
import { zScanPoliciesTableHeaders } from 'components/main/ZScanPolicies/zScanPolicies.mappings';
import { showAffectedAppsHeader } from 'components/main/policies/appPolicy/AppCharacteristics/AffectedAppsDevicesModal/affectedApps.mapping';
import { showAffectedDevicesHeader } from 'components/main/policies/appPolicy/AppCharacteristics/AffectedAppsDevicesModal/AffectedDevicesTable/affectedDevices.mapping';

const CLEAR_SELECTED_APPS = 'CLEAR_SELECTED_APPS';
const TOGGLE_THEME = 'TOGGLE_THEME';
const SET_THEME = 'SET_THEME';
const HIDE_UPLOAD_REMINDER = 'HIDE_UPLOAD_REMINDER';
const HIDE_DOWNLOAD_REMINDER = 'HIDE_DOWNLOAD_REMINDER';
const BUILDS_TABLE_COLUMN_CHANGE = 'BUILDS_TABLE_COLUMN_CHANGE';
const BUILDS_APPS_TABLE_COLUMN_CHANGE = 'BUILDS_APPS_TABLE_COLUMN_CHANGE';
const FINDINGS_TABLE_COLUMN_CHANGE = 'FINDINGS_TABLE_COLUMN_CHANGE';
const INSTANCES_TABLE_COLUMN_CHANGE = 'INSTANCES_TABLE_COLUMN_CHANGE';
const COMPARE_BUILDS_TABLE_COLUMN_CHANGE = 'COMPARE_BUILDS_TABLE_COLUMN_CHANGE';
const AUTHORIZATION_TABLE_COLUMN_CHANGE = 'AUTHORIZATION_TABLE_COLUMN_CHANGE';

const AUDIT_LOG_TABLE_COLUMN_CHANGE = 'AUDIT_LOG_TABLE_COLUMN_CHANGE';
const MDM_TABLE_COLUMN_CHANGE = 'MDM_TABLE_COLUMN_CHANGE';
const PLANS_TABLE_COLUMN_CHANGE = 'PLANS_TABLE_COLUMN_CHANGE';
const GROUPS_TABLE_COLUMN_CHANGE = 'GROUPS_TABLE_COLUMN_CHANGE';
const GROUPS_MTD_TABLE_COLUMN_CHANGE = 'GROUPS_MTD_TABLE_COLUMN_CHANGE';
const TRM_POLICY_TABLE_COLUMN_CHANGE = 'TRM_POLICY_TABLE_COLUMN_CHANGE';
const SUPPORT_TABLE_COLUMN_CHANGE = 'SUPPORT_TABLE_COLUMN_CHANGE';
const INTEGRATIONS_TABLE_COLUMN_CHANGE = 'INTEGRATIONS_TABLE_COLUMN_CHANGE';
const CONFIGURATIONS_TABLE_COLUMN_CHANGE = 'CONFIGURATIONS_TABLE_COLUMN_CHANGE';
const ZDEV_POLICIES_TABLE_COLUMN_CHANGE = 'ZDEV_POLICIES_TABLE_COLUMN_CHANGE';
const UPDATE_UI_SETTINGS = 'UPDATE_UI_SETTINGS';
const SET_SYSTEM_PROPERTIES = 'SET_SYSTEM_PROPERTIES';
const DATA_EXPORT_TABLE_COLUMN_CHANGE = 'DATA_EXPORT_TABLE_COLUMN_CHANGE';
const TRM_POLICY_MTD_TABLE_COLUMN_CHANGE = 'TRM_POLICY_MTD_TABLE_COLUMN_CHANGE';
const DEVICE_ACTIVATIONS_TABLE_COLUMN_CHANGE =
  'DEVICE_ACTIVATIONS_TABLE_COLUMN_CHANGE';
const GROUP_ACTIVATIONS_TABLE_COLUMN_CHANGE =
  'GROUP_ACTIVATIONS_TABLE_COLUMN_CHANGE';
const APP_POLICY_TABLE_COLUMN_CHANGE = 'APP_POLICY_TABLE_COLUMN_CHANGE';
const PARTNER_USERS_TABLE_COLUMN_CHANGE = 'PARTNER_USERS_TABLE_COLUMN_CHANGE';
const PARTNER_AUTHORIZATIONS_TABLE_COLUMN_CHANGE =
  'PARTNER_AUTHORIZATIONS_TABLE_COLUMN_CHANGE';
const TEAMS_TABLE_COLUMN_CHANGE = 'TEAMS_TABLE_COLUMN_CHANGE';
const SET_CUSTOM_IDLE_TIME = 'SET_CUSTOM_IDLE_TIME';
const APPS_INVENTORY_TABLE_COLUMN_CHANGE = 'APPS_INVENTORY_TABLE_COLUMN_CHANGE';
const OOC_SELECT_TABLE_COLUMN_CHANGE = 'OOC_SELECT_TABLE_COLUMN_CHANGE';
const ZSHIELDAPPS_TABLE_COLUMN_CHANGE = 'ZSHIELDAPPS_TABLE_COLUMN_CHANGE';
const MTD_OS_VERSIONS_TABLE_COLUMN_CHANGE =
  'MTD_OS_VERSIONS_TABLE_COLUMN_CHANGE';
const MTD_OS_RELATED_CVES_TABLE_COLUMN_CHANGE =
  'MTD_OS_RELATED_CVES_TABLE_COLUMN_CHANGE';
// accounts for mtd and zdefend table settings
const DEVICES_TABLE_COLUMN_CHANGE = 'DEVICES_TABLE_COLUMN_CHANGE';
const MTD_DEVICES_TABLE_COLUMN_CHANGE = 'MTD_DEVICES_TABLE_COLUMN_CHANGE';
const MTD_THREATS_TABLE_COLUMN_CHANGE = 'MTD_THREATS_TABLE_COLUMN_CHANGE';
const THREATS_TABLE_COLUMN_CHANGE = 'THREATS_TABLE_COLUMN_CHANGE';

export const uiSettingsDefaults = {
  theme: 'dark',
  version: 'a.b.c',
  systemProperties: {
    singleTenantMode: 'false',
  },
  reminderSettings: {
    showDownloadReminder: true,
    showUploadReminder: true,
  },
  appsInventory: {
    count: 0,
    order: 'desc',
    orderBy: 'name',
    page: 0,
    size: 25,
    tableHeaders: defaultAppsInventoryTableHeaders,
  },
  auditLog: {
    count: 0,
    order: 'desc',
    orderBy: 'timestamp',
    page: 0,
    size: 25,
    tableHeaders: defaultAuditLogTableHeaders,
  },
  authorization: {
    page: 0,
    size: 25,
    order: 'asc',
    orderBy: 'id',
    tableHeaders: defaultAuthorizationTableHeaders,
  },
  // builds: {
  //   duration: 5,
  //   zappId: 'All',
  //   page: 0,
  //   size: 25,
  //   order: 'desc',
  //   orderBy: 'buildUploadedAt',
  //   tableHeaders: buildsTableHeaders,
  // },
  buildsApps: {
    duration: 5,
    page: 0,
    size: 25,
    order: 'desc',
    orderBy: 'created',
    tableHeaders: buildsAppsTableHeaders,
  },
  zScanApps: {
    duration: 5,
    page: 0,
    size: 25,
    order: 'desc',
    orderBy: 'created',
    tableHeaders: defaultZScanAppsTableHeaders,
  },
  zScanAssessments: {
    page: 0,
    size: 25,
    order: 'desc',
    orderBy: 'scanDate',
    tableHeaders: zScanAssessmentsTableHeaders,
  },
  zScanCompareBuilds: {
    page: 0,
    size: 25,
    order: 'desc',
    orderBy: 'severityOrdinal',
    cbo: 'combined',
    tableHeaders: compareBuildsTableHeader,
  },
  zScanFindings: {
    page: 0,
    size: 25,
    order: 'desc',
    orderBy: 'severityOrdinal',
    tableHeaders: findingsTableHeaders,
  },
  zScanInstances: {
    page: 0,
    size: 25,
    order: 'desc',
    orderBy: 'created',
    tableHeaders: zScanInstancesTableHeaders,
  },
  appPolicyAffectedDevices: {
    page: 0,
    size: 25,
    order: 'desc',
    orderBy: 'name',
    tableHeaders: showAffectedAppsHeader,
  },
  affectedDevicesTable: {
    page: 0,
    size: 25,
    order: 'desc',
    orderBy: 'lastSeen',
    tableHeaders: showAffectedDevicesHeader,
  },
  buildAnalysis: {
    findingsFilter: ['security', 'privacy'],
  },
  dashboard: {
    duration: 7,
    zappId: 'All',
  },
  threats: {
    duration: 5,
    zappId: 'All',
    page: 0,
    size: 25,
    order: 'desc',
    orderBy: 'timestamp',
    tableHeaders: defaultThreatsTableHeaders,
  },
  mtdThreats: {
    duration: 5,
    zappId: 'All',
    page: 0,
    size: 25,
    order: 'desc',
    orderBy: 'timestamp',
    tableHeaders: defaultMtdThreatsTableHeaders,
  },
  devices: {
    duration: 5,
    zappId: 'All',
    page: 0,
    size: 25,
    order: 'desc',
    orderBy: 'lastSeen',
    tab: 'overview',
    tableHeaders: defaultDevicesTableHeaders(),
  },
  mtdDevices: {
    duration: 5,
    zappId: 'All',
    page: 0,
    size: 25,
    order: 'desc',
    orderBy: 'lastSeen',
    tab: 'overview',
    tableHeaders: defaultDevicesTableHeaders('MTD'),
  },
  deviceActivations: {
    page: 0,
    size: 25,
    order: 'desc',
    orderBy: 'created',
    tableHeaders: DeviceActivationsTableHeaders,
  },
  groupActivations: {
    page: 0,
    size: 25,
    order: 'desc',
    orderBy: 'created',
    tableHeaders: GroupActivationsTableHeaders,
  },
  groups: {
    page: 0,
    size: 25,
    order: 'asc',
    orderBy: 'name',
    tableHeaders: defaultGroupsTableHeaders,
  },
  mtdGroups: {
    page: 0,
    size: 25,
    order: 'asc',
    orderBy: 'name',
    tableHeaders: defaultMtdGroupsTableHeaders,
  },
  inapps: {
    count: 0,
    order: 'asc',
    orderBy: 'name',
    page: 0,
    size: 5,
    tableHeaders: defaultInappsTableHeaders,
  },
  inappsVersions: {
    count: 0,
    order: 'desc',
    orderBy: 'version',
    page: 0,
    size: 50,
    tableHeaders: defaultInappsVersionsTableHeaders,
  },
  MDM: {
    page: 0,
    size: 25,
    order: 'desc',
    orderBy: 'created',
    tableHeaders: MDMTableHeaders,
  },
  plans: {
    page: 0,
    size: 25,
    order: 'asc',
    orderBy: 'id',
    tableHeaders: defaultPlansTableHeaders,
  },
  roles: {
    page: 0,
    size: 100,
    order: 'asc',
    orderBy: 'name',
    tableHeaders: defaultRolesTableHeaders,
  },
  trmPolicy: {
    tableHeaders: trmPolicyTableColumnHeaders(),
  },
  trmPolicyMtd: {
    tableHeaders: mtdTRMPolicyTableColumnHeaders(),
  },
  users: {
    count: 0,
    order: 'desc',
    orderBy: 'created',
    page: 0,
    size: 25,
    tableHeaders: usersTableHeaders,
  },
  teams: {
    selected: null,
    count: 0,
    order: 'desc',
    orderBy: 'created',
    page: 0,
    size: 25,
    tableHeaders: defaultTeamsTableHeaders,
  },
  adminAccounts: {
    selected: null,
    count: 0,
    order: 'asc',
    orderBy: 'name',
    page: 0,
    size: 50,
    tableHeaders: adminAccountTableHeaders,
    customIdleTime: null,
  },
  dataExport: {
    selected: null,
    count: 0,
    order: 'desc',
    orderBy: 'lastModifiedDate',
    page: 0,
    size: 25,
    tableHeaders: defaultDataExportTableHeaders,
  },
  superUsers: {
    page: 0,
    size: 25,
    order: 'desc',
    orderBy: 'created',
    tableHeaders: superUserTableHeaders,
  },
  // super ui partner users table
  partnerUsers: {
    count: 0,
    order: 'desc',
    orderBy: 'created',
    page: 0,
    size: 25,
    tableHeaders: partnerUsersAdminTableHeaders,
  },
  // partner dashboard
  partnerUsersForPartnerUI: {
    count: 0,
    order: 'desc',
    orderBy: 'created',
    page: 0,
    size: 25,
    tableHeaders: partnerUsersTableHeaders,
  },
  partnerAuthorizations: {
    count: 0,
    order: 'desc',
    orderBy: 'created',
    page: 0,
    size: 25,
    tableHeaders: partnerAuthorizationsTableHeaders,
  },
  managedAccounts: {
    count: 0,
    order: 'desc',
    orderBy: 'created',
    page: 0,
    size: 25,
    tableHeaders: managedAccountsTableHeaders,
  },
  integrations: {
    page: 0,
    size: 25,
    order: 'desc',
    orderBy: 'modified',
    tableHeaders: integrationTableHeaders,
  },
  configurations: {
    page: 0,
    size: 25,
    order: 'desc',
    orderBy: 'modified',
    tableHeaders: configurationsTableHeaders,
  },
  oocSelect: {
    count: 0,
    order: 'desc',
    orderBy: 'developerName',
    page: 0,
    size: 25,
    // tableHeaders: oocSelectTableHeaders(), no need
  },
  zdevPolicies: {
    page: 0,
    size: 25,
    order: 'desc',
    orderBy: 'name',
    tableHeaders: zDevPoliciesTableHeaders,
  },
  zScanPolicies: {
    page: 0,
    size: 25,
    order: 'desc',
    orderBy: 'name',
    tableHeaders: zScanPoliciesTableHeaders,
  },
  zShieldApps: {
    count: 0,
    order: 'desc',
    orderBy: 'name',
    page: 0,
    size: 25,
    tableHeaders: defaultZShieldAppsTableHeaders,
  },
  mtdOSVersions: {
    count: 0,
    order: 'desc',
    orderBy: 'osVersion',
    page: 0,
    size: 25,
    tableHeaders: defaultMtdOSVersionsTableHeaders,
  },
  mtdOSRelatedCVEs: {
    count: 0,
    order: 'desc',
    orderBy: 'id',
    page: 0,
    size: 25,
    tableHeaders: defaultMtdOSRelatedCVEsTableHeaders,
  },
};

export const toggleTheme = () => {
  return {
    type: TOGGLE_THEME,
  };
};

export const setTheme = (payload) => {
  return {
    type: SET_THEME,
    payload,
  };
};

const tableColumnChange = (state, payload, component) => {
  const { columnData, index, value } = payload;
  const replacedColumns = columnData.slice();
  replacedColumns[index].show = value;
  const newState = {
    ...state,
    [component]: {
      ...state[component],
      tableHeaders: replacedColumns,
    },
  };

  return newState;
};

export const getSelectedTeam = (state) => state.uiSettings.teams.selected;

export const updateSelectedTeam = (teamId) => {
  return {
    type: AT.TEAM_SELECT,
    payload: {
      selected: teamId,
    },
  };
};

export const updateUISettings = ({ domain, update }) => {
  return {
    type: UPDATE_UI_SETTINGS,
    payload: { domain, update },
  };
};

export const clearSelectedApps = () => {
  return {
    type: CLEAR_SELECTED_APPS,
  };
};

export const adminAccountsTableColumnChange = (value, index, columnData) => {
  return {
    type: AT.ADMIN_ACCOUNTS_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const setCustomIdleTime = (value) => {
  return {
    type: SET_CUSTOM_IDLE_TIME,
    payload: value,
  };
};

export const superUsersTableColumnChange = (value, index, columnData) => {
  return {
    type: AT.SUPERUSERS_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};
// partner users table super ui

export const appsInventoryTableColumnChange = (value, index, columnData) => {
  return {
    type: APPS_INVENTORY_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const partnerUsersTableColumnChange = (value, index, columnData) => {
  return {
    type: PARTNER_USERS_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};
export const partnerAuthorizationsTableColumnChange = (
  value,
  index,
  columnData
) => {
  return {
    type: PARTNER_AUTHORIZATIONS_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const dataExportTableColumnChange = (value, index, columnData) => {
  return {
    type: DATA_EXPORT_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const auditLogTableColumnChange = (value, index, columnData) => {
  return {
    type: AUDIT_LOG_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};
export const authorizationTableColumnChange = (value, index, columnData) => {
  return {
    type: AUTHORIZATION_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const buildsTableColumnChange = (value, index, columnData) => {
  return {
    type: BUILDS_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const findingsTableColumnChange = (value, index, columnData) => {
  return {
    type: FINDINGS_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const compareBuildsTableColumnChange = (value, index, columnData) => {
  return {
    type: COMPARE_BUILDS_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const instancesTableColumnChange = (value, index, columnData) => {
  return {
    type: INSTANCES_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const buildsAppsTableColumnChange = (value, index, columnData) => {
  return {
    type: BUILDS_APPS_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const MDMTableColumnChange = (value, index, columnData) => {
  return {
    type: MDM_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};
export const plansTableColumnChange = (value, index, columnData) => {
  return {
    type: PLANS_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const rolesTableColumnChange = (value, index, columnData) => {
  return {
    type: AT.ROLES_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const groupsTableColumnChange = (value, index, columnData) => {
  return {
    type: GROUPS_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};
export const groupsMtdTableColumnChange = (value, index, columnData) => {
  return {
    type: GROUPS_MTD_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};
export const trmPolicyTableColumnChange = (value, index, columnData) => {
  return {
    type: TRM_POLICY_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};
export const trmPolicyMtdTableColumnChange = (value, index, columnData) => {
  return {
    type: TRM_POLICY_MTD_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const supportTableColumnChange = (value, index, columnData) => {
  return {
    type: SUPPORT_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const integrationsTableColumnChange = (value, index, columnData) => {
  return {
    type: INTEGRATIONS_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const configurationsTableColumnChange = (value, index, columnData) => {
  return {
    type: CONFIGURATIONS_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const oocSelectTableColumnChange = (value, index, columnData) => {
  return {
    type: OOC_SELECT_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const zdevPoliciesTableColumnChange = (value, index, columnData) => {
  return {
    type: ZDEV_POLICIES_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const zShieldAppsTableColumnChange = (value, index, columnData) => {
  return {
    type: ZSHIELDAPPS_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const mtdOSVersionsTableColumnChange = (value, index, columnData) => {
  return {
    type: MTD_OS_VERSIONS_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const mtdOSRelatedCVEsTableColumnChange = (value, index, columnData) => {
  return {
    type: MTD_OS_RELATED_CVES_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const teamsTableColumnChange = (value, index, columnData) => {
  return {
    type: TEAMS_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const deviceActivationsTableColumnChange = (
  value,
  index,
  columnData
) => {
  return {
    type: DEVICE_ACTIVATIONS_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const groupActivationsTableColumnChange = (value, index, columnData) => {
  return {
    type: GROUP_ACTIVATIONS_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const appPolicyTableColumnChange = (value, index, columnData) => {
  return {
    type: APP_POLICY_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};
export const devicesTableColumnChange = (value, index, columnData) => {
  return {
    type: DEVICES_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};
export const mtdDevicesTableColumnChange = (value, index, columnData) => {
  return {
    type: MTD_DEVICES_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};
export const threatsTableColumnChange = (value, index, columnData) => {
  return {
    type: THREATS_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const mtdThreatsTableColumnChange = (value, index, columnData) => {
  return {
    type: MTD_THREATS_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};

export const uiSettings = (state = uiSettingsDefaults, action) => {
  switch (action.type) {
    case AT.FETCH_APPS_PAGE_SUCCEEDED:
      const newAppsMetadataState = {
        ...state,
        inapps: {
          ...state.inapps,
          count: action.payload.appsMetadata.count,
          order: action.payload.appsMetadata.order,
          orderBy: action.payload.appsMetadata.orderBy,
          page: action.payload.appsMetadata.page,
          size: action.payload.appsMetadata.size,
        },
      };

      return newAppsMetadataState;

    case AT.FETCH_APP_VERSIONS_PAGE_SUCCEEDED:
      const newAppsVersionsMetadataState = {
        ...state,
        inappsVersions: {
          ...state.inappsVersions,
          count: action.payload.appVersionsMetadata.count,
          order: action.payload.appVersionsMetadata.order,
          orderBy: action.payload.appVersionsMetadata.orderBy,
          page: action.payload.appVersionsMetadata.page,
          size: action.payload.appVersionsMetadata.size,
        },
      };

      return newAppsVersionsMetadataState;

    case UPDATE_UI_SETTINGS:
      if (!_.has(state, action.payload.domain)) {
        return state;
      }

      const modifiedState = {
        ...state,
        [action.payload.domain]: {
          ...state[action.payload.domain],
          ...action.payload.update,
        },
      };

      return modifiedState;

    case AT.FETCH_ZIAP_CONSOLE_VERSION_SUCCEEDED:
      return {
        ...state,
        version: action.payload,
      };

    case AT.SET_UI_THREATS_QUERY:
      const newUISettingsState = {
        ...state,
        threats: {
          ...state.threats,
          query: action.payload.query,
        },
      };

      return newUISettingsState;

    case AT.SET_UI_DASHBOARD_RANGE:
      let newDashboardState = {
        ...state,
        dashboard: {
          ...state.dashboard,
          range: action.payload.range,
        },
      };
      return newDashboardState;

    case AT.SET_UI_DASHBOARD_SELECTED_APP:
      newDashboardState = {
        ...state,
        dashboard: {
          ...state.dashboard,
          zappId: action.payload.zappId,
        },
      };
      return newDashboardState;

    case AT.SET_UI_THREATS_RANGE:
      let newThreatsState = {
        ...state,
        threats: {
          ...state.threats,
          range: action.payload.range,
        },
      };
      return newThreatsState;

    case AT.SET_UI_THREATS_SELECTED_APP:
      newThreatsState = {
        ...state,
        threats: {
          ...state.threats,
          zappId: action.payload.zappId,
        },
      };
      return newThreatsState;

    case AT.SET_UI_DEVICES_SELECTED_APP:
      let newDevicesState = {
        ...state,
        devices: {
          ...state.devices,
          selectedApp: action.payload.selectedApp,
        },
      };
      return newDevicesState;

    case AT.SET_UI_DEVICES_RANGE:
      newDevicesState = {
        ...state,
        devices: {
          ...state.devices,
          range: action.payload.range,
        },
      };
      return newDevicesState;

    case AT.TEAM_SELECT:
      const newTeamsState = {
        ...state,
        teams: {
          ...state.teams,
          selected: action.payload.selected,
        },
        threats: {
          ...state.threats,
          zappId: 'All',
        },
        devices: {
          ...state.devices,
          zappId: 'All',
        },
        dashboard: {
          ...state.dashboard,
          teamId: action.payload.selected,
          zappId: 'All',
        },
      };
      return newTeamsState;

    case ON_ADMIN_EXIT_TENANT:
      return {
        ...state,
        teams: {
          ...state.teams,
          selected: null,
        },
        dashboard: {
          ...state.dashboard,
          teamId: null,
        },
      };

    case CLEAR_SELECTED_APPS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          zappId: 'All',
        },
        threats: {
          ...state.threats,
          zappId: null,
        },
      };

    case AUTH_CLEAR_USER:
      return {
        ...state,
        teams: {
          ...state.teams,
          selected: null,
        },
      };

    case BUILDS_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'builds');

    case BUILDS_APPS_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'buildsApps');

    case AT.DEVICES_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'devices');

    case DEVICE_ACTIVATIONS_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'deviceActivations');

    case FINDINGS_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'findings');

    case GROUP_ACTIVATIONS_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'groupActivations');

    case INSTANCES_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'instances');

    case MDM_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'MDM');

    case COMPARE_BUILDS_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'compare');

    case GROUPS_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'groups');

    case GROUPS_MTD_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'groupsMtd');

    case AT.INAPPS_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'inapps');

    case AT.INAPPS_VERSIONS_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'inappsVersions');

    case AT.ROLES_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'roles');

    case TRM_POLICY_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'trmPolicy');

    case TRM_POLICY_MTD_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'trmPolicyMtd');

    case THREATS_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'threats');

    case MTD_THREATS_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'mtdThreats');

    case DEVICES_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'devices');

    case MTD_DEVICES_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'mtdDevices');

    case AUDIT_LOG_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'auditLog');

    case AT.USERS_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'users');

    case TEAMS_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'teams');

    case AT.ADMIN_ACCOUNTS_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'adminAccounts');

    case DATA_EXPORT_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'dataExport');

    case AT.SUPERUSERS_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'superUsers');

    case APPS_INVENTORY_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'appsInventory');

    case PARTNER_USERS_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'partnerUsers');

    case PARTNER_AUTHORIZATIONS_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'partnerAuthorization');

    case AUTHORIZATION_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'authorization');

    case PLANS_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'authorization');

    case SUPPORT_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'support');

    case INTEGRATIONS_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'integrations');

    case CONFIGURATIONS_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'configurations');

    case OOC_SELECT_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'oocSelect');

    case ZDEV_POLICIES_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'zdevPolicies');

    case ZSHIELDAPPS_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'zShieldApps');

    case APP_POLICY_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'appPolicy');

    case MTD_OS_VERSIONS_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'mtdOSVersions');

    case MTD_OS_RELATED_CVES_TABLE_COLUMN_CHANGE:
      return tableColumnChange(state, action.payload, 'mtdOSRelatedCVEs');

    case SET_SYSTEM_PROPERTIES:
      return {
        ...state,
        systemProperties: action.payload,
      };
    case SET_CUSTOM_IDLE_TIME:
      return {
        ...state,
        customIdleTime: parseInt(action.payload),
      };
    case TOGGLE_THEME:
      return state.theme === 'dark'
        ? { ...state, theme: 'light' }
        : { ...state, theme: 'dark' };

    case SET_THEME:
      return { ...state, ...action.payload };

    case HIDE_UPLOAD_REMINDER:
      return {
        ...state,
        reminderSettings: {
          ...state.reminderSettings,
          showUploadReminder: false,
        },
      };

    case HIDE_DOWNLOAD_REMINDER:
      const newState = {
        ...state,
        reminderSettings: {
          ...state.reminderSettings,
          showDownloadReminder: false,
        },
      };
      return newState;

    case SET_BRANDING_PROPERTIES:
      const { themePreference } = action.payload;
      if (themePreference) {
        return {
          ...state,
          theme: themePreference,
        };
      }

      return {
        ...state,
      };
    default:
      if (state) {
        return state;
      }
      return uiSettingsDefaults;
  }
};

export default uiSettings;
