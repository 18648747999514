import { IFilterEditor, ILocationQuery } from 'components/UI/Table/models';
import { Api } from 'config/axiosConfig';
import moment from 'moment';

export interface IParamObj {
  [index: string]: string;
}

export const createRSQLParams = (
  filters: IFilterEditor[],
  isAppsInventory: boolean
) => {
  return (
    filters
      // Remove non-rsql filters
      .filter(({ useURLSearchParams }) => !useURLSearchParams)
      .map(({ name, customRSQLOperator, type, value }) => {
        // This is used for string/number/SINGLE-select filters with custom RSQL operators
        if (customRSQLOperator && !!value && !value?.[0]) {
          return `${name}=${customRSQLOperator}=${value}`;
        }

        if (type === 'date' && !!value) {
          return `${name}==${moment(value).format('YYYY-MM-DD')}`;
        }

        if (type === 'select' && value?.[0]) {
          // This is used for MULTI-select filters
          const rsqlOperator = customRSQLOperator ?? '';
          const rsqlInOperator = customRSQLOperator ?? 'in';
          if (name === 'securityRisk' || name === 'privacyRisk') {
            name = name.substring(0, name.indexOf('Risk'));
            return value.length === 1
              ? `${name}.keyword=${rsqlOperator}=${value[0]}`
              : `${name}.keyword=${rsqlInOperator}=(${value.join(',')})`;
          }
          if (name === 'highestCvssSeverity') {
            return value.length === 1
              ? `${name}.keyword=${rsqlOperator}=${value[0]}`
              : `${name}.keyword=${rsqlInOperator}=(${value.join(',')})`;
          }
          return value.length === 1
            ? `${name}=${rsqlOperator}=${value[0]}`
            : `${name}=${rsqlInOperator}=(${value.join(',')})`;
        }

        return isAppsInventory && (name === 'name' || name === 'developerName')
          ? `${name}.sort==${
              type === 'string' && typeof value === 'string'
                ? `*${value.replace(/ /g, '*')}*`
                : String(value ?? '')
            }`
          : name === 'securityRisk' || name === 'privacyRisk'
          ? `${name.substring(0, name.indexOf('Risk'))}.keyword==${
              type === 'string' && typeof value === 'string'
                ? `*${value.replace(/ /g, '*')}*`
                : String(value ?? '')
            }`
          : `${name}==${
              type === 'string' && typeof value === 'string'
                ? `*${value.replace(/ /g, '*')}*`
                : String(value ?? '')
            }`;
      })
      .join(';')
  );
};

export const createParams = (
  queries: ILocationQuery,
  filters: IFilterEditor[],
  accountId?: string,
  isAppsInventory = false
) => {
  // Remove nulls and empty string/arrays
  const nonNullFilters = filters.filter(
    ({ value }) =>
      typeof value === 'number' ||
      (typeof value === 'string' && value !== '') ||
      value?.[0]
  );

  // Create params for RSQL
  const query = createRSQLParams(nonNullFilters, isAppsInventory);
  const search = createRSQLParams(nonNullFilters, isAppsInventory);

  // Create params excluded from RSQL
  const nonRSQLParams: IParamObj = {};
  nonNullFilters
    .filter(({ useURLSearchParams }) => useURLSearchParams)
    .forEach(({ name: key, value }) => {
      nonRSQLParams[key] = String(value);
    });

  const { page, size, order } = queries;
  let { orderBy } = queries;

  let sortSuffix = '';
  // https://zimperium.atlassian.net/browse/CNS5-2753?focusedCommentId=244230
  if (['version', 'platform', 'bundleId', 'classification'].includes(orderBy)) {
    sortSuffix = '.keyword';
  }
  if (['developerName', 'name'].includes(orderBy)) {
    sortSuffix = '.sort';
  }
  if (isAppsInventory && orderBy === 'created') {
    orderBy = 'updatedOn';
  }
  if (isAppsInventory && orderBy === 'highestCvssSeverity') {
    orderBy = 'highestCvssSeverityOrdinal';
  }

  return new URLSearchParams({
    ...(accountId && { accountId }),
    page: page,
    size: size,
    sort: isAppsInventory
      ? `${orderBy}${sortSuffix},${order}`
      : `${orderBy},${order}`,
    ...nonRSQLParams,
    ...(!isAppsInventory && search && { search }),
    ...(isAppsInventory && queries.search && { search: queries.search }),
    ...(query && { query }),
  } as Record<string, string>);
};

export const fetchTableData = async (
  query: ILocationQuery,
  filters: IFilterEditor[],
  url: string,
  accountId?: string,
  isAppsInventory?: boolean
) => {
  let data = {
    content: [],
    totalElements: 0,
    numberOfElements: 0,
  };
  const params = createParams(query, filters, accountId, isAppsInventory);

  const apiUrl = `${url}${params.toString()}`;

  try {
    const result = await Api.get(apiUrl);
    if (!!result?.data) {
      data = result.data;
    }
  } catch (e) {
    console.error(e);
  }
  return {
    data: data?.content ?? [],
    count: data?.totalElements ?? 0,
  };
};

export function searchTable<T, Key extends keyof T>(
  searchTerm: string | undefined,
  list: T[],
  fields?: Key[]
): T[] {
  if (!searchTerm) {
    return list;
  }

  return list.filter((obj: any) => {
    const keys = fields || Object.keys(obj);

    return keys.some((key) =>
      String(obj[key]).toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
}
