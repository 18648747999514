import _ from 'lodash';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getPartnerUserScopes,
  getSuperUserScopesRoles,
  getUserScopesRoles,
} from 'reducers/UserReducers';

const ProtectedComponent = ({
  allow,
  children,
  userScopes,
  userScopeBounds,
  componentIfNotAllowed,
  hide,
}) => {
  if (hide) {
    return null;
  }
  if (typeof allow === 'string' && allow === 'any' && !_.isEmpty(userScopes)) {
    return <Fragment>{children}</Fragment>;
  }

  const { scopeBounds } = allow;
  if (
    !_.isUndefined(scopeBounds) &&
    userScopeBounds !== scopeBounds &&
    userScopeBounds !== 'ACCOUNT_BOUNDED'
  ) {
    return null;
  }

  // for multiple scopes
  const allowKeys = Object.keys(allow);
  for (let i = allowKeys.length; i >= 0; i -= 1) {
    const permission = allow[allowKeys[i]]; // yields 'view' or 'manage' or 'enter'
    const userPermissions = _.get(userScopes, allowKeys[i]); // yields an array

    if (_.isArray(userPermissions)) {
      // for those who can manage
      if (userPermissions.includes('manage')) {
        return <Fragment>{children}</Fragment>;
      }

      // check if they can view
      if (userPermissions.includes(permission)) {
        return <Fragment>{children}</Fragment>;
      }
    }
  }

  return componentIfNotAllowed ?? null;
};

const mapStateToProps = (state, ownProps) => {
  let userScopes = getUserScopesRoles(state);
  if (ownProps.isSuper) {
    userScopes = getSuperUserScopesRoles(state);
  } else if (ownProps.isPartner) {
    userScopes = getPartnerUserScopes(state);
  }
  return {
    userScopes,
    userScopeBounds: state.user.scopeBounds,
  };
};

ProtectedComponent.propTypes = {
  allow: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

ProtectedComponent.defaultProps = {
  allow: {},
};

export default connect(mapStateToProps)(ProtectedComponent);
