import AddIcon from '@material-ui/icons/Add';
import Button from 'components/UI/Button';
import Table from 'components/UI/Table';
import {
  IColumnHeader,
  IFilterEditor,
  ILocationQuery,
  ITableRowMapping,
} from 'components/UI/Table/models';
import withRouter from 'components/hocs/withRouter';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
  partnerUsersTableColumnChange,
  updateUISettings,
} from 'reducers/UiSettingsReducers';
import {
  getChangedTableHeadersHash,
  getUISettingsTableHeaders,
  getUISettingsWithout,
} from 'reducers/UiSettingsSelectors';
import { bindActionCreators, compose } from 'redux';
import { toggleModalDirect } from 'utils/storeUtils';
import useBackfill from 'utils/useBackfill';
import {
  managedAccountsColumnMapping,
  resolveFilters,
} from './managedAccounts.mapping';
import { IFilterValues } from './model';
import tableQuery from './tableQuery';
import useStyles from './useStyles';
import { INITIAL_FILTER_VALUES } from './utils';

interface IManagedAccountsProps {
  currentTableHeaders: IColumnHeader[];
  definedUser?: string;
  managedAccountsUiSettings: { [key: string]: unknown };
  partnerUsersTableColumnChange: (...args: unknown[]) => void;
  q: { [key: string]: unknown };
  rqps: string[];
  updateUISettings: (...args: unknown[]) => void;
  updateUrl: (...args: unknown[]) => void;
}

const ManagedAccounts: React.FC<IManagedAccountsProps> = ({
  currentTableHeaders,
  definedUser,
  managedAccountsUiSettings,
  partnerUsersTableColumnChange,
  q: query,
  rqps,
  updateUISettings,
  updateUrl,
}) => {
  useBackfill({
    query,
    requiredQueryParams: rqps,
    storedQueryParams: managedAccountsUiSettings,
    updateUrl: updateUrl,
  });

  const [filterValues, setFilterValues] = useState<IFilterValues>(
    INITIAL_FILTER_VALUES
  );

  const classes = useStyles();

  const filters = useMemo(() => resolveFilters(filterValues), [filterValues]);

  const onPaginationChange = useCallback(
    (update: Partial<ILocationQuery>) => {
      updateUISettings({
        domain: 'managedAccounts',
        update,
      });

      updateUrl(update);
    },
    [updateUISettings, updateUrl]
  );

  const handleColumnChange = useCallback(
    (...args: unknown[]) => {
      partnerUsersTableColumnChange(...args);
    },
    [partnerUsersTableColumnChange]
  );

  const handleFilterChange = (filterEditors: IFilterEditor[]) => {
    const newFilterValues: IFilterValues = {};
    filterEditors.forEach(({ name, value }) => {
      newFilterValues[name] = value;
    });
    setFilterValues(newFilterValues);
  };

  return (
    <div>
      <div className="view__header">
        <h1>Managed Accounts</h1>
        <ProtectedComponent allow={{ managed_accounts: 'create' }} isPartner>
          <Button
            onClick={() =>
              toggleModalDirect(
                'AccountsCreateEdit',
                { partnerModal: true },
                { title: 'Create Managed Account', fullWidth: true }
              )
            }
            text="Add Managed Account"
            color="secondary"
            icon={AddIcon}
          />
        </ProtectedComponent>
      </div>

      <Table
        serverSort={false}
        classList={classes}
        columnHeaders={currentTableHeaders}
        filters={filters}
        onFilterValueChange={handleFilterChange as () => void}
        definedUser={definedUser}
        fetchTableData={() =>
          tableQuery(filters)(query as unknown as ILocationQuery)
        }
        onColumnChange={handleColumnChange}
        onPaginationChange={onPaginationChange}
        query={query as unknown as ILocationQuery}
        rowMapping={managedAccountsColumnMapping as ITableRowMapping[]}
        tableId="ManagedAccounts"
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentTableHeaders: getUISettingsTableHeaders(state, 'managedAccounts'),
  managedAccountsUiSettings: getUISettingsWithout(state, 'managedAccounts', [
    'tableHeaders',
  ]),
  partnerUsersColumnHeadersHash: getChangedTableHeadersHash(
    state,
    'managedAccounts'
  ),
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      partnerUsersTableColumnChange,
      updateUISettings,
    },
    dispatch
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ManagedAccounts);
