import React from 'react';

const TableColumnsContext = React.createContext({
  columnHeaders: [],
  columnHeadersHash: {},
  onChangePage: ({ domain, updateUISettings, update }) => {
    updateUISettings({
      domain,
      update,
    });
  },
  onChangeRows: ({ domain, updateUISettings, update }) => {
    updateUISettings({
      domain,
      update,
    });
  },
});

export default TableColumnsContext;
