import _ from 'lodash';
import React from 'react';

import MaterialTableCell from '@material-ui/core/TableCell';
import MaterialTableRow from '@material-ui/core/TableRow';

import TableColumnsContext from './TableColumnsContext';

const TableRow = (props) => {
  let { rowMapping } = props;
  // check for omitted columns that are not controlled by uiSettings
  // passed in thru the tableBody
  if (props.omitRowPath) {
    rowMapping = _.differenceBy(props.rowMapping, props.omitRowPath, 'path');
  }
  return (
    <TableColumnsContext.Consumer>
      {({ columnHeadersHash, ...ctx }) => {
        // loading state
        if (props.isLoading) {
          return (
            <MaterialTableRow
              className={props.classList.tableRowLoading}
              padding="none"
            >
              <MaterialTableCell colSpan="20" className="is-loading shimmer" />
            </MaterialTableRow>
          );
        }

        // data state
        return (
          <MaterialTableRow
            className={`${props.classList.tableRow} ${props.className}`}
            onClick={props.onClick || undefined}
          >
            {rowMapping.map((map, index) => {
              const columnConfig = _.get(columnHeadersHash, map.path);

              if (columnConfig && !columnConfig.show) {
                return null;
              }

              const columnData = _.get(props.rowData, map.path);
              let columnContent = columnData;

              if (typeof map.columnContent === 'function') {
                columnContent = map.columnContent(columnData, {
                  ...props,
                  ctx,
                });
              }

              return (
                <MaterialTableCell
                  key={`column-frag-${props.rowData.id}-${index}`}
                  className={props.classList[map.className]}
                  classes={{ root: props.classList.tableCell }}
                  style={{ ...map?.cellInlineStyle }}
                >
                  <div>{columnContent}</div>
                </MaterialTableCell>
              );
            })}
          </MaterialTableRow>
        );
      }}
    </TableColumnsContext.Consumer>
  );
};

export default TableRow;
