import AT from 'actions/ActionTypes';

export const selectUser = user => {
  return {
    type: AT.SET_SELECTED_USER,
    payload: user,
  };
};
export const clearSelectUser = () => {
  return {
    type: AT.CLEAR_SELECTED_USER,
  };
};

/* User Table Modal */
export const closeUsersModal = () => {
  return {
    type: AT.USERS_MODAL_CLOSE,
  };
};
export const openUsersModal = () => {
  return {
    type: AT.USERS_MODAL_OPEN,
  };
};

export const usersTableColumnChange = (value, index, columnData) => {
  return {
    type: AT.USERS_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};
