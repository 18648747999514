import { IRowData } from '../models';
import { IFormData, IPolicyGroupOption } from './models';

export const initialValues = (
  rowData?: IRowData,
  selectedTeamId?: string,
  allPolicyGroups?: IPolicyGroupOption[]
): IFormData => {
  return {
    activationLimit: rowData?.activationLimit || 10,
    editMode: !!rowData ?? false,
    email: rowData?.email || '',
    firstName: rowData?.firstName || '',
    group: allPolicyGroups?.[0]
      ? allPolicyGroups.find(({ value }) => value === rowData?.groupId) ?? null
      : null,
    id: rowData?.id || '',
    lastName: rowData?.lastName || '',
    phoneNumber: rowData?.phoneNumber || '',
    selectedTeamId: selectedTeamId || '',
    sendEmailInvite: rowData?.sendEmailInvite ?? false,
    team:
      rowData?.team?.name && rowData?.team?.id
        ? {
            label: rowData.team.name,
            value: rowData.team.id,
          }
        : null,
  };
};
