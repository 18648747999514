export function csvToArray<T>(
  text: string,
  headers: (keyof T)[],
  defaultValues?: Record<any, any>
): T[] {
  // The order of the headers is the order of the headers in the file
  // These names NOT represent the headers of the file

  const csvRows = text.slice(text.indexOf('\n') + 1).split('\n');

  const list: T[] = csvRows.map(i => {
    const values = i.split(',');

    const element = headers.reduce(
      (acc, header, index) => ({
        ...acc,
        [header]: values[index],
        id: String(
          Date.now().toString(32) + Math.random().toString(16)
        ).replace(/\./g, '').trim(),
        ...defaultValues,
      }),
      {} as T
    );

    return element;
  });

  return list;
}
