import { default as MUITypography } from '@material-ui/core/Typography';
import { checkForSubscription } from 'api/SubscriptionService';
import withRouter from 'components/hocs/withRouter';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toggleModalDirect } from 'utils/storeUtils';
import Footer from './Footer';
import Form from './Form';
import { IFormSubmit } from './Form/models';
import useFormStyles from './Form/useStyles';
import Header from './Header';
import useStyles from './useStyles';

interface ISubscriptionComponentProps {}

const SubscriptionComponent: React.FC<ISubscriptionComponentProps> = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const formClasses = useFormStyles();

  const handleSubmit = async (data: IFormSubmit) => {
    try {
      const result = await checkForSubscription({}, data);

      if (!result.data) {
        throw Error('Invalid subscription check response.');
      }

      toggleModalDirect(
        'SubscriptionMFA',
        { subscriptionState: result.data },
        {
          title: t('STC.ENTERVALIDATIONCODE'),
          scroll: 'paper',
          dir: i18n.dir(),
        }
      );
    } catch (e: unknown) {
      toggleModalDirect(
        'SubscriptionText',
        {
          children: (
            <MUITypography className={formClasses.textBody} variant="body2">
              {t('STC.ACCOUNTNOTFOUNDMESSAGE')}
            </MUITypography>
          ),
          link: {
            href: '#',
            onClick: (e: any) => {
              e.preventDefault();
              window.open(
                'https://www.stc.com.sa/wps/wcm/connect/arabic/helpAndSupport/contact',
                '_blank'
              );
            },
            text: t('STC.TECHNICALSUPPORTLINKMESSAGE'),
          },
        },
        {
          title: t('STC.ACCOUNTNOTFOUND'),
          scroll: 'paper',
          dir: i18n.dir(),
        }
      );
    }
  };

  return (
    <>
      <Header />
      <main className={classes.publicContentWrapper}>
        <div className={classes.publicContentContainer}>
          <img
            className={classes.logo}
            src={'subscription/MTD_black_trans.svg'}
            alt="MTD Logo"
          />
          <div className={classes.publicContent}>
            <section className={classes.publicSection}>
              <div dir={i18n.dir() || 'ltr'}>
                <Form handleSubmit={handleSubmit} />
              </div>
            </section>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default withRouter(SubscriptionComponent);
