import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ typography, config, palette }: any) =>
  createStyles({
    backgroundCircle: {
      background: palette.background.paper,
      borderRadius: '50%',
      display: 'flex',
      height: 65,
      justifyContent: 'center',
      marginRight: 5,
      position: 'relative',
      width: 65,
    },
    backgroundCircleAndroid: {
      border: `2px solid ${palette.common.android}`,
    },
    backgroundCircleIos: {
      border: `2px solid ${palette.common.ios}`,
      padding: 0,
    },
    backgroundCircleChromeOS: {
      border: `2px solid ${palette.common.chromeos}`,
    },
    count: {
      color: palette.text.secondary,
      fontFamily: config.fonts.secondaryStackSans,
      fontSize: config.textSizes.inch,
      textDecoration: 'none',
    },
    icon: {
      fontSize: 45,
      left: '50%',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    label: {
      alignSelf: 'center',
      color: palette.text.primary,
      fontFamily: typography.fontFamily,
      fontSize: config.textSizes.minion,
      fontWeight: 400,
      height: 5,
      textDecoration: 'none',
    },
    statisticCard: {
      alignItems: 'center',
      color: palette.text.secondary,
      display: 'flex',
      fontFamily: config.fonts.secondaryStackSans,
    },
    text: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
  })
);

export default useStyles;
