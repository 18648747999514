import { createStyles, makeStyles } from '@material-ui/styles';

// Usage of 'any' inside this style decorator is discouraged; however, our theme
// currently does not conform to MUI's theme structure standards. So this will
// be a followup fix for later.
const useStyles = makeStyles(() =>
  createStyles({
    headerContainer: {
      alignItems: 'center',
      backgroundColor: '#1B445F',
      boxShadow: 'none',
      display: 'flex',
      justifyContent: 'center',
      position: 'fixed',
      width: '100%',
    },

    logoContainer: {
      display: 'block',
    },

    logo: {
      height: 'min(15vw, 100px)',
      margin: '20px 0',

      '@media screen and (max-width: 650px)': {
        height: 'min(15vw, 45px)',
      },
    },
  })
);

export default useStyles;
