import React from 'react';
import { useTranslation } from 'react-i18next';
import { ISelectTRMPolicyTableData } from '../models';
import useStyles from '../useStyles';

interface ITypeColumnProps {
  rowData: ISelectTRMPolicyTableData;
}

enum ThreatType {
  Composite = 'Composite',
  Singular = 'Singular',
}

const TypeColumn: React.FC<ITypeColumnProps> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.tableColumn}>
      <span>{t(`GLOBAL.${ThreatType.Singular.toUpperCase()}`)}</span>
    </div>
  );
};

export default React.memo(TypeColumn);
