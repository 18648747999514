import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    tab: {
      color: palette.text.secondary,
      textTransform: 'none',
      fontSize: 14,
    },
  })
);

export default useStyles;
