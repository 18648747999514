import Tooltip from '@material-ui/core/Tooltip';
import { default as MUIDeleteIcon } from '@material-ui/icons/Delete';
import { default as MUIFileDownloadIcon } from '@material-ui/icons/SaveAlt';
import cc from 'classcat';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import React, { ReactElement } from 'react';
import useStyles from './useStyles';

interface IConditinalTooltipProps {
  children: ReactElement<any, any>;
  disabled?: boolean;
  title: string;
}

const ConditionalTooltip: React.FC<IConditinalTooltipProps> = ({
  title,
  children,
  disabled = false,
}) => {
  return disabled ? (
    <>{children}</>
  ) : (
    <Tooltip title={title}>{children}</Tooltip>
  );
};

interface IBuildButtonsProps {
  downloadDisabled?: boolean;
  onDelete: () => void;
  onDownload: () => void;
}

const BuildButtons: React.FC<IBuildButtonsProps> = ({
  downloadDisabled = false,
  onDelete,
  onDownload,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.buildButtons}>
      <ConditionalTooltip title="Download App" disabled={downloadDisabled}>
        <button
          className={cc([
            classes.iconButton,
            { [classes.disabled]: downloadDisabled },
          ])}
          style={{ cursor: downloadDisabled ? 'auto' : 'pointer' }}
          disabled={downloadDisabled}
          onClick={onDownload}
        >
          <MUIFileDownloadIcon />
        </button>
      </ConditionalTooltip>
      <ProtectedComponent allow={{ app_protections: 'managed' }}>
        <Tooltip title="Delete Build">
          <button onClick={onDelete} className={classes.iconButton}>
            <MUIDeleteIcon />
          </button>
        </Tooltip>
      </ProtectedComponent>
    </div>
  );
};

export default BuildButtons;
