import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import FormHelperText from '@material-ui/core/FormHelperText';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import SelectClasses, { MultiSelectStyles } from './MultiSelectStyles';
import MultiSelectButton from './MultiSelectButton';
import {
  Dropdown,
  DropdownIndicator,
  Option,
  MenuList,
} from './MultiSelectComponentOverrides';
import { withTranslation } from 'react-i18next';

class MultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      values: props.values,
    };
    this.onSelectChange = this.onSelectChange.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { props } = this;

    if (!_.isEmpty(prevProps.values) && _.isEmpty(props.values)) {
      this.setState({
        values: [],
      });
    }

    if (_.isEmpty(prevProps.values) && !_.isEmpty(props.values)) {
      this.setState({
        values: props.values,
      });
    }

    if (!_.isEqual(prevProps.values, props.values)) {
      this.setState({
        values: props.values,
      });
    }
  }

  render() {
    const { props, state } = this;
    if (!props.isShowing) {
      return null;
    }

    let _Option = Option;
    if (_.get(props, 'components.Option')) {
      _Option = props.components.Option;
    }

    const { t } = this.props;

    return (
      <Fragment>
        {props.label && (
          <label
            style={{
              marginTop: 15,
              paddingBottom: 5,
              display: 'block',
              fontSize: 12,
            }}
            htmlFor={props.name}
          >
            {props.label}
          </label>
        )}
        <Dropdown
          id={props.name}
          isOpen={state.isOpen}
          onClose={this.toggleOpen}
          disabled={props.disabled || props.loading}
          isLoading={props.loading}
          fullWidth={props.fullWidth}
          target={
            props.components.DropDownButton ? (
              React.createElement(props.components.DropDownButton, {
                fullWidth: true,
                toggleOpen: this.toggleOpen,
                disabled: props.disabled,
                buttonPlaceholder: props.buttonPlaceholder,
                data: state,
                ...props,
              })
            ) : (
              <MultiSelectButton
                className={props.buttonClass}
                fullWidth
                toggleOpen={this.toggleOpen}
                disabled={props.disabled}
                disableRipple={props.disabled || props.loading}
              >
                {props.loading ? (
                  <span>Loading options...</span>
                ) : (
                  this.generateSelectedLabel(state, props)
                )}
                {props.disabled || props.loading ? null : (
                  <ArrowDropDown className={props.classes.buttonDown} />
                )}
              </MultiSelectButton>
            )
          }
        >
          <Fragment>
            {this.state.isOpen && (
              <ClickAwayListener onClickAway={this.handleClickAway}>
                <Select
                  autoFocus
                  backspaceRemovesValue={false}
                  components={{
                    DropdownIndicator,
                    Option: _Option,
                    MenuList,
                    IndicatorSeparator: null,
                    ...(props.components.Control && {
                      Control: props.components.Control,
                    }),
                  }}
                  classes={props.classes}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={false}
                  isMulti={props.isMulti}
                  menuIsOpen
                  onChange={this.onSelectChange}
                  options={props.options}
                  placeholder={
                    props.placeholder ?? t('GLOBAL.PLACEHOLDER_SELECT_ACTION')
                  }
                  styles={MultiSelectStyles(props.theme)}
                  tabSelectsValue={false}
                  value={state.values}
                  reference={props.reference}
                  handleSearch={props.handleSearch}
                  onBlur={this.handleClickAway}
                />
              </ClickAwayListener>
            )}
          </Fragment>
        </Dropdown>
        <FormHelperText className={props.classes.helperText}>
          {props.helperText}
        </FormHelperText>
      </Fragment>
    );
  }

  toggleOpen() {
    if (!this.props.disabled) {
      this.setState({
        isOpen: !this.state.isOpen,
      });
    }
  }

  handleClickAway = () => {
    this.setState({
      isOpen: false,
    });
    this.props.resetSearch();
  };

  onSelectChange(values, n) {
    const { props } = this;
    this.setState({ values });

    if (typeof props.onChange === 'function') {
      props.onChange(props.name, values, props.extras);
    }

    // close menu on select if not a multi select
    if (!props.isMulti) {
      this.toggleOpen();
    }
  }

  generateSelectedLabel(state, { buttonPlaceholder }) {
    if (Array.isArray(state.values)) {
      return state.values.length
        ? state.values.map(({ label }) => label).join(', ')
        : buttonPlaceholder;
    }

    if (typeof state.values === 'object' && !_.isEmpty(state.values)) {
      return state.values.label;
    }

    return buttonPlaceholder;
  }
}

MultiSelect.defaultProps = {
  isMulti: true,
  isShowing: true,
  disabled: false,
  loading: false,
  buttonPlaceholder: 'Select',
  selectPlaceholder: 'Search...',
  options: [],
  values: [],
  components: {},
  fullWidth: false,
  resetSearch: () => {},
};

MultiSelect.propTypes = {
  isMulti: PropTypes.bool,
  isShowing: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  selectPlaceholder: PropTypes.string,
};

export default withTranslation()(
  withStyles(SelectClasses, { withTheme: true })(MultiSelect)
);
