import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import { SCOPE_BOUNDS } from 'mappings/scopeMapping';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import momentHelper from 'utils/momentUtils';
import GlobalIndicatorWrapper from '../components/main/common/GlobalIndicatorWrapper';
import { toggleModalDiffered } from '../utils/storeUtils';

export const groupsColumnMapping = [
  {
    path: 'name',
    columnContent: (id, { rowData }) => {
      return (
        <GlobalIndicatorWrapper isGlobal={rowData.global} text={null}>
          <span
            style={{
              width: 150,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'inline-block',
            }}
          >
            {rowData.name}
          </span>
        </GlobalIndicatorWrapper>
      );
    },
  },
  {
    path: 'description',
  },
  // {
  //   path: 'emmConnectionId',
  //   scope: 'emm',
  //   columnContent: (id, { rowData }) => {
  //     if (!id) return null;
  //     return (
  //       <ProtectedComponent allow={{ emm: 'view' }}>
  //         <Link
  //           to={`/console/mdm/?mdmtr=${id}`}
  //           style={{
  //             display: 'flex',
  //             alignItems: 'center',
  //             textAlign: 'right',
  //             color: 'inherit',
  //           }}
  //         >
  //           <span style={{ paddingRight: 5 }}>
  //             {_.get(rowData, 'emmConnectionName', 'N/A')}
  //           </span>
  //           <Input
  //             // onClick={handleChange(rowData.id, handleEnterAccount)}
  //             // classes={{ root: classList.enterAccount }}
  //             className="table__os-icon"
  //           />
  //         </Link>
  //       </ProtectedComponent>
  //     );
  //   },
  // },
  {
    path: 'privacyName',
    columnContent: (id, { rowData }) => {
      return (
        <Link
          to={`privacy?privacy=${rowData.privacyId}`}
          style={{ color: 'inherit' }}
        >
          <GlobalIndicatorWrapper
            isGlobal={rowData.privacyGlobal}
            text={rowData.privacyName}
          />
        </Link>
      );
    },
  },
  {
    path: 'trmName',
    columnContent: (id, { rowData }) => {
      return (
        <Link
          to={`trm?trm=${rowData.trmId}&zappId=All&order=asc&orderBy=name`}
          style={{ color: 'inherit' }}
        >
          <GlobalIndicatorWrapper
            isGlobal={rowData.trmGlobal}
            text={rowData.trmName}
          />
        </Link>
      );
    },
  },
  {
    path: 'phishingName',
    scope: 'phishing',
    columnContent: (id, { rowData }) => {
      return (
        <Link
          to={`phishing?phishing=${rowData.phishingPolicyId}`}
          style={{ color: 'inherit' }}
        >
          <GlobalIndicatorWrapper
            isGlobal={rowData.phishingGlobal}
            text={rowData.phishingName}
          />
        </Link>
      );
    },
  },
  {
    path: 'appSettingsName',
    scope: 'app_settings',
    columnContent: (id, { rowData }) => {
      return (
        <Link
          to={`appsettings?appsettings=${rowData.appSettingsId}`}
          style={{ color: 'inherit' }}
        >
          <GlobalIndicatorWrapper
            isGlobal={rowData.appSettingsGlobal}
            text={rowData.appSettingsName}
          />
        </Link>
      );
    },
  },
  {
    path: 'appPolicyName',
    columnContent: (id, { rowData }) => {
      return (
        <Link
          to={`apps?apps=${rowData.appPolicyId}`}
          style={{ color: 'inherit' }}
        >
          <GlobalIndicatorWrapper
            isGlobal={rowData.appPolicyGlobal}
            text={rowData.appPolicyName}
          />
        </Link>
      );
    },
  },
  {
    path: 'osRiskPolicyName',
    columnContent: (id, { rowData }) => {
      return (
        <Link
          to={`osrisk?osrisk=${rowData.osRiskPolicyId}`}
          style={{ color: 'inherit' }}
        >
          <GlobalIndicatorWrapper
            isGlobal={rowData.osRiskPolicyGlobal}
            text={rowData.osRiskPolicyName}
          />
        </Link>
      );
    },
  },
  {
    path: 'created',
    columnContent: (timestamp) => momentHelper(timestamp),
  },
  {
    path: 'modified',
    columnContent: (timestamp) => momentHelper(timestamp),
  },
  {
    path: 'actions',
    columnContent: (id, { rowData, handlers }) => {
      const scopeBounds = rowData.global
        ? SCOPE_BOUNDS.account
        : SCOPE_BOUNDS.team;
      return (
        <Fragment>
          <ProtectedComponent allow={{ scopeBounds, policies: 'manage' }}>
            <Edit
              className="cursor--pointer"
              onClick={toggleModalDiffered(
                'GroupsCreateEdit',
                {
                  availableTeams: handlers.availableTeams,
                  rowData,
                  privacyPolicyList: handlers.privacyPolicyList,
                  trmPolicyList: handlers.trmPolicyList,
                  phishingPolicyList: handlers.phishingPolicyList,
                  appSettingsList: handlers.appSettingsList,
                  appPolicyList: handlers.appPolicyList,
                  selectedTeamId: handlers.selectedTeamId,
                  osRiskPolicyList: handlers.osRiskPolicyList,
                },
                {
                  title: 'Edit Group',
                  fullWidth: true,
                }
              )}
            />
            {!rowData.emm && (
              <Delete
                id="groups_table__delete"
                className="cursor--pointer"
                onClick={toggleModalDiffered(
                  'GroupsDeleteConfirm',
                  {
                    groupId: rowData.id,
                    description: rowData.description,
                    name: rowData.name,
                    attachedApps: rowData.zapps,
                  },
                  { title: 'Delete Group' }
                )}
              />
            )}
          </ProtectedComponent>
        </Fragment>
      );
    },
  },
  {
    path: '',
  },
];
