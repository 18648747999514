export default [
  {
    path: 'page',
  },
  {
    path: 'size',
  },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      if (orderBy && order) {
        return `${orderBy},${order}`;
      }

      return null;
    },
  },
];
