import moment from 'moment';

const defaultValue = 'N/A';
export const detailsConfig = {
  column1: [
    {
      key: 'accountName',
      label: 'Account',
      defaultValue,
    },
    {
      key: 'description',
      label: 'Description',
      defaultValue,
    },
  ],
  column2: [
    {
      key: 'created',
      label: 'Date Created',
      fetch: timestamp => {
        return moment(timestamp).fromNow();
      },
    },
    {
      key: 'preSalesRep',
      label: 'Pre-Sales Rep',
      defaultValue,
    },
    {
      key: 'customerSuccess',
      label: 'Customer Success',
      defaultValue,
    },
  ],
};
