import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const styles = ({ palette, config }) => {
  return {
    wrapper: {
      background: palette.background.paper,
      outline: 'none',
      maxWidth: '50vw',
      maxHeight: 400,
      overflowY: 'scroll',
    },
    tableCell: {
      borderBottomColor: 'transparent',
    },
    tableHeaderCell: {
      color: palette.text.secondary,
      fontWeight: 'bold',
      borderBottomColor: 'transparent',
    },
    tableRow: {
      height: config.textSizes.paragon,
    },
    emptyText: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '5vh',
    },
  };
};

class NearbyNetworks extends Component {
  render() {
    const { classes, threat } = this.props;
    return (
      <div className={classes.wrapper}>
        <Table>
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableHeaderCell}>
                Capabilities
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                Frequency
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>SSID</TableCell>
              <TableCell className={classes.tableHeaderCell}>BSSID</TableCell>
              <TableCell className={classes.tableHeaderCell}>Level</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {threat.nearByNetworks.map((nearByNetwork, index) => {
              return (
                <TableRow key={index} className={classes.tableRow}>
                  <TableCell className={classes.tableCell}>
                    {nearByNetwork.capabilities}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {nearByNetwork.frequency}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {nearByNetwork.ssid}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {nearByNetwork.bssid}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {nearByNetwork.level}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default withStyles(styles)(NearbyNetworks);
