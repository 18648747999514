import { withStyles } from '@material-ui/core';
import Table from 'components/UI/Table';
import {
  IColumnHeader,
  IFilterEditor,
  ILocationQuery,
  TClassList,
} from 'components/UI/Table/models';
import { withBackfill_DEPRECATED } from 'components/hocs/withBackfill';
import withRouter from 'components/hocs/withRouter';
import { useState } from 'react';
import { connect } from 'react-redux';
import {
  updateUISettings,
  mtdOSVersionsTableColumnChange,
} from 'reducers/UiSettingsReducers';
import {
  getChangedTableHeadersHash,
  getUISettingsTableHeaders,
} from 'reducers/UiSettingsSelectors';
import { bindActionCreators, compose } from 'redux';
import { withDirtyState } from 'utils/withDirtyState';
import withMemoizedProps from 'utils/withMemoizedProps';
import { IColumnChangeFn, IPaginationChangeFn, IState } from '../common/models';
import { COLUMN_MAPPINGS, resolveFilters } from './OSVersions.mappings';
import { IFilterValues } from './models';
import styles from './styles';
import tableQuery from './tableQuery';
import { INITIAL_FILTER_VALUES } from './utils';
import { useTranslation } from 'react-i18next';

interface IOSVersionsTabProps {
  classes: TClassList;
  currentTableHeaders: IColumnHeader[];
  jiggleDirtyState: () => void;
  q: ILocationQuery;
  mtdOSVersionsTableColumnChange: IColumnChangeFn;
  updateUrl: IPaginationChangeFn;
}

const OSVersionsTab: React.FC<IOSVersionsTabProps> = ({
  classes,
  currentTableHeaders,
  jiggleDirtyState,
  mtdOSVersionsTableColumnChange,
  q: query,
  updateUrl,
}) => {
  const { t, ready } = useTranslation();

  const [filterValues, setFilterValues] = useState<IFilterValues>(
    INITIAL_FILTER_VALUES
  );
  const filters = resolveFilters(filterValues, t);

  const handleColumnChange: IColumnChangeFn = (value, index, columnData) => {
    jiggleDirtyState();
    mtdOSVersionsTableColumnChange(value, index, columnData);
  };

  const onPaginationChange: IPaginationChangeFn = (update) => {
    updateUISettings({
      domain: 'mtdOSVersions',
      update,
    });

    updateUrl(update);
  };

  const handleFilterChange = (filterEditors: IFilterEditor[]) => {
    const newFilterValues: IFilterValues = {};
    filterEditors.forEach(({ name, value }) => {
      newFilterValues[name] = value;
    });
    setFilterValues(newFilterValues);
  };

  if (!ready) {
    return null;
  }
  return (
    <div className={classes.tableWrapper}>
      <Table
        classList={classes}
        columnHeaders={currentTableHeaders}
        fetchTableData={() => tableQuery(filters)(query)}
        filters={filters}
        onColumnChange={handleColumnChange as () => void}
        onFilterValueChange={handleFilterChange as () => void}
        onPaginationChange={onPaginationChange}
        query={query}
        rowMapping={COLUMN_MAPPINGS(t)}
        tableId={'mtdOsVersions'}
      />
    </div>
  );
};

const mapStateToProps = (state: IState) => {
  return {
    zShieldAppsColumnHeadersHash: getChangedTableHeadersHash(
      state,
      'mtdOSVersions'
    ),
    currentTableHeaders: getUISettingsTableHeaders(state, 'mtdOSVersions'),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      mtdOSVersionsTableColumnChange,
      updateUISettings,
    },
    dispatch
  );
};

export default compose(
  withStyles(styles),
  withRouter,
  withBackfill_DEPRECATED('uiSettings.mtdOSVersions'),
  withDirtyState(),
  connect(mapStateToProps, mapDispatchToProps)
)(
  withMemoizedProps(OSVersionsTab, [
    'currentTableHeaders',
    'dirtyState',
    'q',
    'updateUISettings',
    'updateUrl',
  ])
) as React.FC<Partial<IOSVersionsTabProps>>;
