import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    circleIcon: {
      color: palette.text.primary,
      fontSize: '16px',
    },
    tooltip: {
      alignItems: 'center',
      background: 'transparent',
      display: 'flex',
      justifyContent: 'center',
      margin: 0,
    },
  })
);

export default useStyles;
