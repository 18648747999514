/* eslint-disable max-lines-per-function */
import Button from 'components/UI/Button';
import InputError from 'components/UI/input/InputError';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IStandardAuth } from '../../AddEditCloneEMMAuth/StandardForm/models';
import { IVMWareAuth } from '../../AddEditCloneEMMAuth/VMWareForm/models';
import { IFullData } from '../../emmTable/MDM/models';
import {
  IAuthForGroupsCreate,
  ICloneInfoFromAuth,
  IConnector,
  TModalMode,
} from '../../models';

import Modal from 'components/UI/Modal';
import {
  // ITRMPolicy,
  ISelectTRMPolicyTableData,
} from '../../../../../main/policies/threats/models';
import { AddEditCloneEMMAuth_TITLE } from '../../AddEditCloneEMMAuth/index';
import { IAddEditCloneEMMAuth } from '../../AddEditCloneEMMAuth/models';
import HorizontalLinearStepper from '../../SelectProvider/stepper';
import Form from './Form';
import { IFormData, IGroupsWithPages, IResponseFormData } from './Form/models';
import OptionForm from './Form/optionForm';
import PolicyGroupTable from './PolicyGroupTable';
import PolicyGroupTooltip from './PolicyGroupTooltip';
import ResponseActionGroupTable from './ResponseActionGroupTable';
import ResponseActionGroupTooltip from './ResponseActionGroupTooltip';
import {
  initialResponseTableValues,
  initialTableValues,
} from './initialTableValues';
import {
  IGroupPolicyMapping,
  IIntuneFields,
  IResponseGroupMapping,
} from './models';
import useStyles from './useStyles';
import {
  cloneConnection,
  createConnectionWithGroups,
  createGroupRows,
  createResponseGroupRows,
  getNextId,
  getNextResponseId,
  updateGroups,
} from './utils';

import { fetchTRMPolicyByIdNew } from 'api/TRMPolicyService';
import { getActiveModalAtom } from 'atoms/modals';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { savedIntuneFormData as savedIntuneFormDataAtom } from '../../AddEditCloneEMMAuth/IntuneForm/atoms';
import { IAddEditCloneEMMGroups } from '../../AddEditCloneEMMGroups/models';
import { fetchMDMProviders } from '../../SelectProvider/utils';
import CustomDialog from './CustomDialog/CustomDialog';

export interface ISelectProvider {}

interface IModalContentProps {
  authForGroupsCreate?: IAuthForGroupsCreate<IStandardAuth | IVMWareAuth>;
  cloneInfoFromAuth?: ICloneInfoFromAuth;
  connector?: IConnector;
  data?: IFullData;
  handleClose: () => void;
  mode?: TModalMode;
  noDataAddButtonColor?: 'inherit' | 'primary' | 'secondary' | 'default'; // Only applicable if not passing in noDataTableContent
  noDataAddButtonText?: string;
}
const handleDragOver = (event: React.DragEvent<HTMLButtonElement>) => {
  event.preventDefault();
};
const activeModalAtom = getActiveModalAtom<
  IAddEditCloneEMMAuth | IAddEditCloneEMMGroups<IStandardAuth | IVMWareAuth>
>();

// This component is necessary to prevent initialTableValues from filling the table
// with an empt y array of data before the modal is even launched.
const ModalContent: React.FC<IModalContentProps> = ({
  authForGroupsCreate,
  cloneInfoFromAuth,
  connector,
  data,
  handleClose,
  mode,
}) => {
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [termRules, setTermRules] = useState<ISelectTRMPolicyTableData[]>([]);
  const [groupType, setGroupType] = useState<string>();
  const setActiveModal = useSetRecoilState(activeModalAtom);
  const setSavedIntuneFormData = useSetRecoilState(savedIntuneFormDataAtom);
  const [deviceSpace, setDeviceSpace] = useState<string | number | undefined>(
    data?.rowData?.auth?.device_space_id
  );

  const handleBack = useCallback(async () => {
    if (mode === 'EDIT' || mode === 'CLONE') {
      const connectors = await fetchMDMProviders();
      // Clear out intune data and redirectUrl in case auth was interrupted
      setSavedIntuneFormData(undefined);
      localStorage.removeItem('redirectUrl');
      setActiveModal({
        active: AddEditCloneEMMAuth_TITLE,
        payload: {
          connector: connectors?.find(
            ({ id }) => id === data?.rowData?.connectorType?.id
          ),
          data,
          mode,
        },
      });
    } else if (mode === 'ADD') {
      // Clear out intune data and redirectUrl in case auth was interrupted
      setSavedIntuneFormData(undefined);
      localStorage.removeItem('redirectUrl');
      setActiveModal({
        active: AddEditCloneEMMAuth_TITLE,
        payload: {
          connector,
          data,
          mode,
        },
      });
    }
  }, [connector, data, mode, setActiveModal, setSavedIntuneFormData]);

  const fetchTRMData = async (id?: string | number | null) => {
    if (id) {
      setIsLoading(true);
      const { data } = await fetchTRMPolicyByIdNew({ id });
      setTermRules(data?.rules);
      setIsLoading(false);
    }
    return null;
  };

  useEffect(() => {
    fetchTRMData(data?.rowData?.id);
  }, [data?.rowData?.id]);

  const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);

  const [showResponseActionTooltip, setResponseActionTooltip] =
    useState<boolean>(false);
  const [showPolicy, setShowPolicy] = useState<boolean>(true);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [submissionError, setSubmissionError] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [thirdPartyMDMSelected, setThirdPartyMDMSelected] = useState<boolean>(
    data?.rowData?.mode === 'MAM_WITH_OTHER_MDM_MODE'
  );
  const [reloadedGroupData, setReloadedGroupData] = useState<
    IGroupsWithPages | undefined
  >();

  const [reloadingGroups, setReloadingGroups] = useState<boolean>(false);
  const [showGroupsTooltip, setShowGroupsTooltip] = useState<boolean>(false);
  const [groupPolicyMappings, setGroupPolicyMappings] = useState<
    IGroupPolicyMapping[]
  >(initialTableValues(data?.rowData));

  const [responseGroupMappings, setResponseGroupMappings] = useState<
    IResponseGroupMapping[]
  >(initialResponseTableValues(data?.rowData));

  const [intuneFields, setIntuneFields] = useState<IIntuneFields>({
    maxDeviceIdleDays: data?.rowData?.maxDeviceIdleDays,
    mode: data?.rowData?.mode,
    includeNestedGroups: data?.rowData?.includeNestedGroups,
    allowDevicesWithNoUser: data?.rowData?.allowDevicesWithNoUser,
  });
  const [previousFieldValues, setPreviousFieldValues] = useState<
    Partial<IFormData> | undefined
  >();

  //Table row Tooltip
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipContent, setTooltipContent] = useState<IGroupPolicyMapping>();

  const handleCellMouseEnter = useCallback(
    (
      e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
      content: IGroupPolicyMapping
    ) => {
      e.preventDefault();
      setTooltipContent(content);
      setShowTooltip(true);
    },
    [setShowTooltip, setTooltipContent]
  );
  const handleCellMouseLeave = useCallback(() => {
    setShowTooltip(false);
  }, [setShowTooltip]);
  // Drag and Drop or reorder a row from the table
  const [draggedRow, setDraggedRow] = useState<any>(null);
  const handleDragStart = useCallback(
    (index: number | null | undefined) => {
      setDraggedRow(index);
    },
    [setDraggedRow]
  );

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLButtonElement>, index: any) => {
      event.preventDefault();
      const updatedData = [...groupPolicyMappings];
      const droppedRow = updatedData.splice(draggedRow, 1)[0];
      updatedData.splice(index, 0, droppedRow);
      setGroupPolicyMappings(updatedData);
      setDraggedRow(null);
    },
    [draggedRow, groupPolicyMappings]
  );

  //Policy Actions :handlePolicyGroupOrdering,handleDeletePolicyGroup,handleAddPolicyGroup,handleClickPolicyGroup,clearPolicyGroupTable

  const handleAddTableRowPolicyGroup = useCallback(
    (newMappings: IFormData) => {
      newMappings.groups = newMappings?.groups?.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.value === value.value)
      );

      setGroupPolicyMappings((origGroupMappings) => [
        ...origGroupMappings,
        ...createGroupRows(newMappings, getNextId(origGroupMappings)),
      ]);
    },
    [setGroupPolicyMappings]
  );
  const handleDeletePolicyGroup = useCallback(
    (id: string) => {
      setGroupPolicyMappings((origGroupMappings) =>
        origGroupMappings.filter((group) => group.id !== id)
      );
    },
    [setGroupPolicyMappings]
  );

  const handleAddPolicyGroupModal = useCallback(() => {
    setIsOpenModal(true);
    setShowPolicy(true);
    setGroupType(
      connector?.id === 'MobileIronCloudConnector' ? 'synchronize' : ''
    );
  }, [connector?.id]);

  //ResponseActionsGroups Acction:handleAddResponseGroup,handleDeleteResponseGroup,handleClickResponseActionGroup
  const handleAddTableRowResponseActionGroup = useCallback(
    (newMappings: IResponseFormData) => {
      newMappings.groups = newMappings?.groups?.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.value === value.value)
      );

      setResponseGroupMappings((origGroupMappings) => [
        ...origGroupMappings,
        ...createResponseGroupRows(
          newMappings,
          getNextResponseId(origGroupMappings)
        ),
      ]);
    },
    [setResponseGroupMappings]
  );
  const handleDeleteResponseActionGroup = useCallback(
    (id: string) => {
      const term = [...termRules]?.filter(
        (e: ISelectTRMPolicyTableData) =>
          e.mdmMitigationTarget == id || e.mdmThreatTarget == id
      );
      term.length
        ? setIsOpen(true)
        : setResponseGroupMappings((origGroupMappings) =>
            origGroupMappings.filter((e) => e.group?.value !== id)
          );
    },
    [termRules, setResponseGroupMappings]
  );
  const handleAddResponseActionGroupModal = useCallback(() => {
    setIsOpenModal(true);
    setShowPolicy(false);
    setGroupType(connector?.id === 'MobileIronCloudConnector' ? 'action' : '');
  }, [connector?.id]);

  //clear Group Table
  const clearGroupTable = useCallback(() => {
    setGroupPolicyMappings([]);
    setResponseGroupMappings([]);
  }, [setGroupPolicyMappings]);

  // These groups are excluded from the groups dropdown
  const groupValuesToExclude: string[] = useMemo(
    () =>
      [...responseGroupMappings, ...groupPolicyMappings].map(
        ({ group }) => group?.value as string
      ),
    [groupPolicyMappings, responseGroupMappings]
  );
  const isIntune = useMemo(
    () =>
      connector?.id === 'IntuneUsGovConnector' ||
      connector?.id === 'IntuneGlobalConnector',
    [connector?.id]
  );
  const isSOTIConnector = connector?.id === 'MobiControlConnector';
  const isGoogleConnector = connector?.id === 'GoogleAdminConnector';

  // This handleSave() function is used with the table and groupPolicyMappings, not the form.
  const handleSave = useCallback(async () => {
    setIsSubmitting(true);
    switch (mode) {
      case 'ADD':
        await createConnectionWithGroups(
          groupPolicyMappings,
          responseGroupMappings,
          handleClose,
          setIsSubmitting,
          setSubmissionError,
          authForGroupsCreate,
          intuneFields,
          thirdPartyMDMSelected,
          isSOTIConnector,
          isGoogleConnector
        );
        break;
      case 'EDIT':
        await updateGroups(
          groupPolicyMappings,
          responseGroupMappings,
          handleClose,
          setIsSubmitting,
          setSubmissionError,
          data?.rowData?.id,
          data?.rowData?.auth?.device_space_id,
          data?.rowData?.teamId,
          intuneFields,
          thirdPartyMDMSelected,
          isSOTIConnector,
          isGoogleConnector
        );
        break;
      case 'CLONE':
        await cloneConnection(
          groupPolicyMappings,
          responseGroupMappings,
          handleClose,
          setIsSubmitting,
          setSubmissionError,
          cloneInfoFromAuth?.connectionIdToClone,
          cloneInfoFromAuth?.teamId,
          cloneInfoFromAuth?.name,
          intuneFields,
          thirdPartyMDMSelected,
          isSOTIConnector,
          isGoogleConnector
        );
        break;
    }
    setIsSubmitting(false);
  }, [
    mode,
    groupPolicyMappings,
    responseGroupMappings,
    handleClose,
    authForGroupsCreate,
    intuneFields,
    thirdPartyMDMSelected,
    isSOTIConnector,
    isGoogleConnector,
    data?.rowData?.id,
    data?.rowData?.auth?.device_space_id,
    data?.rowData?.teamId,
    cloneInfoFromAuth?.connectionIdToClone,
    cloneInfoFromAuth?.teamId,
    cloneInfoFromAuth?.name,
  ]);
  if (!ready) {
    return null;
  }

  return (
    <>
      <div>
        <div className={classes.horizontalStepper}>
          <HorizontalLinearStepper labelId={connector?.id} status={2} />
        </div>

        <>
          <div className={classes.policyGroupHeader}>
            {(!isIntune || !thirdPartyMDMSelected) && (
              <PolicyGroupTooltip
                connector={connector}
                setShowGroupsTooltip={setShowGroupsTooltip}
                showGroupsTooltip={showGroupsTooltip}
                t={t}
              />
            )}
            <div>
              <OptionForm
                t={t}
                authForGroupsCreate={authForGroupsCreate}
                clearGroupTable={clearGroupTable}
                cloneInfoFromAuth={cloneInfoFromAuth}
                connector={connector}
                data={data}
                isIntune={isIntune}
                isSubmitting={isSubmitting}
                previousFieldValues={previousFieldValues}
                setThirdPartyMDMSelected={setThirdPartyMDMSelected}
                setIntuneFields={setIntuneFields}
                setPreviousFieldValues={setPreviousFieldValues}
                thirdPartyMDMSelected={thirdPartyMDMSelected}
                mode={mode}
                reloadedGroupData={reloadedGroupData}
                setReloadedGroupData={setReloadedGroupData}
                reloadingGroups={reloadingGroups}
                setReloadingGroups={setReloadingGroups}
                groupType={groupType}
                setDeviceSpace={setDeviceSpace}
              />
            </div>
          </div>
          {(!isIntune || !thirdPartyMDMSelected) && (
            <PolicyGroupTable
              t={t}
              connector={connector}
              handleDeletePolicyGroup={handleDeletePolicyGroup}
              groupPolicyMappings={groupPolicyMappings}
              handleAddPolicyGroupModal={handleAddPolicyGroupModal}
              handleDragOver={handleDragOver}
              handleDragStart={handleDragStart}
              handleDrop={handleDrop}
              showTooltip={showTooltip}
              tooltipContent={tooltipContent}
              handleCellMouseEnter={handleCellMouseEnter}
              handleCellMouseLeave={handleCellMouseLeave}
            />
          )}
          {!!submissionError && <InputError override={submissionError} />}
        </>

        {isOpenModal && (
          <Modal
            caption={
              connector?.id === 'GoogleAdminConnector'
                ? t('MTD.INTEGRATIONS.SELECT_ORGANIZATIONAL_UNITS')
                : t('MTD.INTEGRATIONS.SELECT_GROUPS')
            }
            className={classes.modal}
            onClose={handleClose}
            scrollable={true}
            isOpenModal={isOpenModal}
          >
            <Form
              t={t}
              authForGroupsCreate={authForGroupsCreate}
              clearGroupTable={clearGroupTable}
              cloneInfoFromAuth={cloneInfoFromAuth}
              connector={connector}
              data={data}
              groupValuesToExclude={groupValuesToExclude}
              handleAddTableRowPolicyGroup={handleAddTableRowPolicyGroup}
              isIntune={isIntune}
              isSubmitting={isSubmitting}
              previousFieldValues={previousFieldValues}
              setThirdPartyMDMSelected={setThirdPartyMDMSelected}
              setIntuneFields={setIntuneFields}
              setPreviousFieldValues={setPreviousFieldValues}
              thirdPartyMDMSelected={thirdPartyMDMSelected}
              mode={mode}
              setIsOpenModal={setIsOpenModal}
              showPolicy={showPolicy}
              handleAddTableRowResponseActionGroup={
                handleAddTableRowResponseActionGroup
              }
              reloadedGroupData={reloadedGroupData}
              setReloadedGroupData={setReloadedGroupData}
              reloadingGroups={reloadingGroups}
              setReloadingGroups={setReloadingGroups}
              groupType={groupType}
              deviceSpace={deviceSpace}
            />
          </Modal>
        )}
        <>
          {(!isIntune || !thirdPartyMDMSelected) &&
            connector?.capabilities?.includes('RESPONSE_GROUPS') && (
              <>
                <ResponseActionGroupTooltip
                  connector={connector}
                  setResponseActionTooltip={setResponseActionTooltip}
                  showResponseActionTooltip={showResponseActionTooltip}
                  t={t}
                />

                <div className={isLoading ? 'is-loading shimmer' : ''}>
                  <ResponseActionGroupTable
                    t={t}
                    connector={connector}
                    responseGroupMappings={responseGroupMappings}
                    handleAddResponseActionGroupModal={
                      handleAddResponseActionGroupModal
                    }
                    handleDeleteResponseActionGroup={
                      handleDeleteResponseActionGroup
                    }
                  />
                </div>
              </>
            )}
        </>
        <div
          className={
            connector?.capabilities?.includes('RESPONSE_GROUPS')
              ? classes.buttons
              : classes.buttonsMargin
          }
        >
          <Button
            className={classes.buttonBackgroundColor}
            disabled={isSubmitting}
            onClick={handleBack}
            text={t('GLOBAL.BACK')}
            type="button"
          />

          <Button
            className={classes.buttonBackgroundColor}
            disabled={isSubmitting}
            onClick={handleClose}
            text={t('GLOBAL.CANCEL')}
            type="button"
          />
          <Button
            color="secondary"
            disabled={isSubmitting}
            onClick={handleSave}
            text={t('GLOBAL.FINISH')}
            type="button"
          />
        </div>
      </div>
      <CustomDialog t={t} isOpen={isOpen} onClose={onClose} />
    </>
  );
};
export default ModalContent;
