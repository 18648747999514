import _ from 'lodash';
import Axios from 'axios';

import { Api } from 'config/axiosConfig';
import { calculateDuration } from 'utils/RangeUtils';
import { applyMapping } from 'utils/mapUtils';

// uses a mapping / config file that knows how to
// transform data and map the final params
export const formatFinalParams = (MAP, data) => {
  const params = {};
  for (let i = 0; i < MAP.length; i += 1) {
    const config = MAP[i];

    // key in the final payload
    let paramKey = config.path;
    if (config.paramKey) {
      paramKey =
        typeof config.paramKey === 'function'
          ? config.paramKey(data)
          : config.paramKey;
    }

    // final value, transformed or not
    const value =
      typeof config.transform === 'function'
        ? config.transform(data)
        : data[config.path];

    // gives us a way to omit undefineds from final params
    if (value || _.isNumber(value)) {
      params[paramKey] = value;
    }
  }

  return params;
};

const ServiceHandler = {
  // holds the cancelable call back provided by axios
  // when the request is cancelable
  cancelRequest: null,

  // sets instance default options
  options: {
    // axios object can take anything the underlying axios
    // configuration object can accept (e.g. headers)
    axios: {
      method: 'get',
    },
    // axios instance
    Service: Api,
    // service mapping
    paramSchema: {},
    payloadSchema: {},
    // flag to enable auto cancelable
    isCancelable: false,
  },

  init() {
    return this.makeRequest.bind(this);
  },

  /**
   * Make Request
   * @param {object} _params hold both query params and path param replacements
   * @param {object} data payload data for post, put, patch calls
   *
   * Special Note:
   * _params object takes both path param replacements & normal query params.
   * It is worth noting that since this object combines both path and query params,
   * that path params (e.g. /therats/:threatId) should be uniquly named and must match
   * the path param sepecified in the url, in the corresponding service file. Lastly
   * keep in mind that if you use path params with query params and provide a paramSchema,
   * the formatFinalParams utility will throw away properties not defined in your schema.
   */
  makeRequest(_params = {}, payload = {}) {
    const { options } = this;
    let params = _params;
    let data = payload;
    let cancelToken;
    let { url } = options.axios;

    if (this.cancelRequest) {
      this.cancelRequest();
    }

    /* eslint-disable */
    if (!options.axios.url) {
      throw new Error(`No url provided to ${arguments.callee.name}`);
    }
    /* eslint-enable */

    // prepare query params,
    if (!_.isEmpty(options.paramSchema)) {
      params = this.prepQueryString(options.paramSchema, params);
    }

    // setup the cancelable callback to be called if this same request
    // is made again before completing
    if (options.isCancelable) {
      cancelToken = new Axios.CancelToken(cancelFunc => {
        this.cancelRequest = cancelFunc;
      });
    }

    // replace path params & remove values from passed params
    // so that we don't attach them as query params
    if (options.axios.url.indexOf(':') !== -1) {
      const rx = new RegExp(/:[a-z]+/gi);
      const pathParamMatches = url.match(rx) || [];
      const queryParamKeys = _.difference(
        Object.keys(params),
        pathParamMatches.map(dirtyMatch => dirtyMatch.substring(1))
      );

      // update url
      url = url.replace(rx, match => _params[match.substring(1)]);
      // remove matches from query params
      params = _.pick(params, queryParamKeys);
    }

    if (!_.isEmpty(data) && !_.isEmpty(options.payloadSchema)) {
      data = applyMapping(options.payloadSchema, data);
    }

    return options.Service({
      cancelToken,
      ...options.axios,
      url,
      params,
      data,
    });
  },

  prepQueryString(schema, params) {
    const duration =
      (params.duration && calculateDuration(Number(params.duration))) || {};

    return formatFinalParams(schema, {
      ...params,
      ...duration,
    });
  },
};

export const createServiceHandler = options => {
  // create a new instance of a service handler returning
  // the makeRequest method
  return Object.create(ServiceHandler, {
    options: {
      value: _.merge({}, ServiceHandler.options, options),
    },
  });
};

export const ServiceHandlerFactory = (options = {}) => {
  return createServiceHandler(options).init();
};
