import { Api } from 'config/axiosConfig';

export const postLoginCredentials = credentials => {
  return Api.post('/api/auth/v1/login', {
    username: credentials.email,
    password: credentials.password,
  });
};

export const resetPasswordOnLogin = credentials => {
  return Api.post('/api/auth/v1/login/password', {
    username: credentials.email,
    oldPassword: credentials.password,
    password: credentials.newPassword,
    confirmedPassword: credentials.confirmedPassword,
  });
};

export const submitSignupNewAccount = (credentials, token) => {
  return Api.post(
    `api/auth/v1/signup?validationToken=${token}`,
    JSON.stringify(credentials)
  );
};

export const checkPasswordResetValidationToken = token => {
  return Api.get(`/api/auth/v1/checkPasswordReset/${token}`).catch(error => {
    console.log('Caught error: ', error.response);
  });
};

export function postPasswordResetValidationToken(validationToken, passwords) {
  return Api.post(
    `/api/auth/v1/validatePasswordReset/${validationToken}`,
    passwords
  );
}

/* User Password Reset */

export function requestPasswordReset(email) {
  return Api.post(`/api/auth/v1/passwords/${email}/reset`);
}

export function postTFAConfigured() {
  return Api.post(`/api/auth/v1/login/tfa-config`);
}

// 6 digit
export function verifyTFACode(otp) {
  return Api.post(`/api/auth/v1/login/tfa`, { otp });
}
