import cc from 'classcat';
import { DrawerContext } from 'components/drawer/DrawerProvider';
import { IDrawerContext } from 'components/drawer/DrawerProvider/models';
import DrawerHeader from 'components/UI/DrawerHeader';
import Tabs from 'components/UI/Tabs';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import useAsyncResult from 'utils/useAsyncResult';
import DetailTable from '../../common/DetailTable';
import { IDevicesDrawerData } from '../Devices/models';
import { IPolicyMetaData } from 'components/main/common/DetailTable/models';
import useStyles from './useStyles';
import { fetchPolicyMetadata, getAllDetails, getTabs } from './utils';
import { useTranslation } from 'react-i18next';

const Drawer: React.FC = () => {
  const classes = useStyles();
  const { t, ready } = useTranslation();

  const { closeDrawer, drawerData } =
    useContext<Partial<IDrawerContext<IDevicesDrawerData>>>(DrawerContext);

  const policyMetadata: IPolicyMetaData[] = useAsyncResult(
    fetchPolicyMetadata,
    drawerData?.rowData?.groupId ?? ''
  );

  const details = useMemo(
    () =>
      getAllDetails(
        classes,
        closeDrawer,
        drawerData?.rowData,
        policyMetadata,
        drawerData?.module
      ),
    [classes, drawerData, policyMetadata, closeDrawer]
  );

  const tabs = useMemo(
    () => getTabs(classes, details, t),
    [classes, t, details]
  );
  const handleClose = useCallback(() => {
    if (typeof closeDrawer === 'function') {
      closeDrawer();
    }
  }, [closeDrawer]);

  const deviceModel = drawerData?.rowData?.model;
  const iPhoneModelMap = drawerData?.rowData?.iPhoneModelMap;

  const modelName = useMemo(
    () =>
      !!iPhoneModelMap &&
      !!deviceModel &&
      Object.keys(iPhoneModelMap).includes(deviceModel)
        ? iPhoneModelMap[deviceModel]?.commercialName
        : deviceModel,
    [deviceModel, iPhoneModelMap]
  );

  useEffect(() => {
    window.addEventListener(`auth:logout`, handleClose);

    return () => {
      window.removeEventListener(`auth:logout`, handleClose);
    };
  }, [handleClose]);

  const checkVulnerabilitiesPresent = useCallback(
    () =>
      (
        details?.vulnerabilitySectionDetails?.map((d) => d.content).join('') ??
        ''
      ).length > 0,
    [details?.vulnerabilitySectionDetails]
  );

  const checkStatusPresent = useCallback(
    () =>
      (details?.statusSectionDetails?.map((d) => d.content).join('') ?? '')
        .length > 0,
    [details?.statusSectionDetails]
  );

  if (!ready) {
    return null;
  }

  return (
    <>
      <DrawerHeader
        onClose={handleClose}
        os={drawerData?.rowData?.os?.name ?? ''}
        title={modelName ?? deviceModel ?? ''}
      />
      <div className={cc([classes.drawerCard, classes.topCardMargin])}>
        <div className={classes.topSection}>
          <DetailTable
            details={details.topSectionDeviceDetails}
            onFilterClick={closeDrawer}
          />
          <DetailTable
            details={details.topSectionFocusAppDetails}
            onFilterClick={closeDrawer}
          />
          <DetailTable
            details={details.topSectionCombinedDetails}
            onFilterClick={closeDrawer}
          />
        </div>
        {details.allAppDetails.length > 1 && (
          <div className={classes.multipleAppsSection}>
            <div>
              <div className={classes.multipleAppsHeader}>
                {t('MTD.DEVICES.MULTIPLE_MOBILE_APPS')}
              </div>
            </div>
            <div className={classes.multipleApps}>
              {details.allAppDetails.map((appDetails, index, arr) => (
                <>
                  <div
                    key={appDetails.id}
                    className={cc([
                      classes.appSection,
                      {
                        [classes.divider]: index + 1 !== arr.length,
                        [classes.firstApp]: index === 0,
                        [classes.lastApp]: index === arr.length - 1,
                      },
                    ])}
                  >
                    <DetailTable
                      details={appDetails.details}
                      onFilterClick={closeDrawer}
                    />
                    <DetailTable
                      details={appDetails.permissions}
                      onFilterClick={closeDrawer}
                    />
                  </div>
                </>
              ))}
            </div>
          </div>
        )}
      </div>
      {(checkStatusPresent() || checkVulnerabilitiesPresent()) && (
        <div className={cc([classes.drawerCard, classes.statusCard])}>
          <div>
            <div>
              {checkStatusPresent() && (
                <>
                  <h3>{t('MTD.DEVICES.DEVICE_STATUS')}</h3>
                  <DetailTable
                    details={details.statusSectionDetails}
                    onFilterClick={closeDrawer}
                  />
                </>
              )}
            </div>
            <div>
              {checkVulnerabilitiesPresent() && (
                <>
                  <h3>{t('MTD.DEVICES.VULNERABILITIES')}</h3>
                  <DetailTable
                    details={details.vulnerabilitySectionDetails}
                    onFilterClick={closeDrawer}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <div className={classes.tabs}>
        <Tabs tabs={tabs} />
      </div>
    </>
  );
};

export default Drawer;
