import _ from 'lodash';

export const createEditAppPayloadMapping = [
  'name',
  'description',
  'bundleId',
  'devModeEnabled',
  'customHostname',
  'entitlements',
  {
    path: 'activationLimit',
    transform: state => {
      const limit = Number(state.activationLimit);
      return limit > 0 ? limit : undefined;
    },
  },
  {
    path: 'groupId',
    transform: state => _.get(state, 'groupId.value'),
  },
  {
    path: 'teamId',
    transform: state => _.get(state, 'teamId.value'),
  },
];

export const fetchZappsParamsMapping = [
  { path: 'teamId' },
  { path: 'zappId' },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      if (orderBy && order) {
        return `${orderBy},${order}`;
      }

      return null;
    },
  },
];

export const fetchZappVersionsParamsMapping = [
  { path: 'teamId' },
  { path: 'zappId' },
  {
    path: 'sort',
    transform: ({ orderBy, order }) => {
      if (orderBy && order) {
        return `${orderBy},${order}`;
      }

      return null;
    },
  },
];
