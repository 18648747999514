import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const MaterialUiRadioGroup = ({
  handleChange,
  options,
  className,
  ...props
}) => (
  <RadioGroup className={className} onChange={handleChange} {...props}>
    {options.map((option) => {
      return (
        <FormControlLabel
          key={option.label}
          control={<Radio color="primary" />}
          disabled={props.disabled}
          {...option}
        />
      );
    })}
  </RadioGroup>
);

export const RadioControl = (props) => {
  const radioClasses = classnames(props.classes.radioContainer, {
    [props.classList]: Boolean(props.classList),
  });

  if (props.label) {
    return (
      <div className="flex--spaced">
        <div>{props.label}</div>
        <MaterialUiRadioGroup
          className={radioClasses}
          handleChange={props.handleChange}
          options={props.options}
          {...props}
        />
      </div>
    );
  }
  return (
    <MaterialUiRadioGroup
      handleChange={props.handleChange}
      options={props.options}
      {...props}
    />
  );
};

const styles = {
  radioContainer: {
    flexDirection: 'row',
  },
};

RadioControl.prototypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};
RadioControl.defaultProps = {
  disabled: false,
};
export default withStyles(styles)(RadioControl);
