import cc from 'classcat';
import MultiLinePlotChart from 'components/UI/Chart/MultiLinePlot';
import GenericCard from 'components/UI/GenericCard';
import moment from 'moment';
import { useMemo } from 'react';
import { ISecurityScore } from '../models';
import { default as useSharedStyles } from '../useStyles';
import useStyles from './useStyles';
import { useTranslation } from 'react-i18next';

interface ISecurityScoreTrendProps {
  securityScoreData: ISecurityScore;
}

const SecurityScoreTrend: React.FC<ISecurityScoreTrendProps> = ({
  securityScoreData,
}) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const { t, ready, i18n } = useTranslation();

  const sortedData = useMemo(() => {
    securityScoreData?.data.sort((a, b) => {
      const dateA = moment(a.dateLabel, 'MM/DD/YYYY');
      const dateB = moment(b.dateLabel, 'MM/DD/YYYY');
      return dateA.diff(dateB);
    });
    return securityScoreData?.data;
  }, [securityScoreData?.data]);

  const sevenDayGraduations = useMemo(
    () => (sortedData ?? []).map(({ dateLabel }) => dateLabel),
    [sortedData]
  );

  const securityScoreTrendSegments = useMemo(
    () => [
      {
        color: '#2EBDBC',
        label: 'Security Score',
        plot: (sortedData ?? []).map(({ score }) => score),
      },
    ],
    [sortedData]
  );

  if (!ready) {
    return null;
  }

  return (
    <GenericCard
      className={cc([sharedClasses.card, classes.securityScoreTrendCard])}
    >
      <h2>{t('MTD.INSIGHTS.SECURITY_SCORE_TREND')}</h2>
      <div className={sharedClasses.column}>
        {sevenDayGraduations?.length ? (
          <MultiLinePlotChart
            graduations={sevenDayGraduations}
            segments={securityScoreTrendSegments}
          />
        ) : (
          <div
            className={cc([
              {
                [classes.pendingNonEnglish]:
                  i18n.language?.toLowerCase() !== 'en-us',
              },
              classes.pending,
            ])}
          >
            {t('GLOBAL.NOT_AVAILABLE')}
          </div>
        )}
      </div>
    </GenericCard>
  );
};

export default SecurityScoreTrend;
