import cc from 'classcat';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Bar from './Bar';
import { IAbstractProportionChartColumn } from './models';
import useStyles from './useStyles';

interface IAbstractProportionChartProps {
  className?: string;
  columns: IAbstractProportionChartColumn[];
}

const AbstractProportionChart: React.FC<IAbstractProportionChartProps> = ({
  className,
  columns,
}) => {
  const classes = useStyles();

  const baseline = useMemo(
    () =>
      Math.min(
        ...columns.map(({ count }) => (typeof count === 'number' ? count : 0))
      ),
    [columns]
  );

  const chart = useMemo(
    () => (
      <>
        {columns.map((column, index) => {
          return (
            <React.Fragment key={index}>
              <Bar baseline={baseline} {...column} />
              <span>
                {column.link ? (
                  <Link to={column.link}>{column.label}</Link>
                ) : (
                  column.label
                )}
              </span>
            </React.Fragment>
          );
        })}
      </>
    ),
    [baseline, columns]
  );

  if (columns.length !== 2) {
    return null;
  }

  return (
    <div className={cc([classes.abstractProportion, className])}>{chart}</div>
  );
};

export default AbstractProportionChart;
