import { Card as MUICard } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import { default as MUIDeleteIcon } from '@material-ui/icons/Delete';
import cc from 'classcat';
import { default as OldUploadComplete } from 'components/fileuploader/UploadComplete';
import Uploader from 'components/UI/Uploader';
import { default as OldUploadError } from 'components/fileuploader/UploadError';
import Button from 'components/UI/Button';
import GenericCard from 'components/UI/GenericCard';
import React, { useCallback, useState } from 'react';
import useStyles from './useStyles';
import { getActiveModalAtom } from 'atoms/modals';
import { useSetRecoilState } from 'recoil';
import TextField from 'components/UI/input/TextField';
import { Field as FormikField, Formik, Form } from 'formik';
import Checkbox from 'components/UI/input/Checkbox';
import { formatErrorMessage } from '../utils';
import { IUploadError } from './models';
import { openSnackBar } from 'utils/storeUtils';
import axios from 'axios';
import Axios from 'axios';
import { Api } from 'config/axiosConfig';
import { submitUrl } from 'components/modals/ZScanAppEdit/Form/uiDataElementExpansion/utils';
import { IZScanApp } from 'components/main/ZScanApps/models';

// TODO: Replace later
const UploadError: any = OldUploadError;
const UploadComplete: any = OldUploadComplete;
const activeModalAtom = getActiveModalAtom<IZScanApp>();

const { CancelToken } = Axios;
let source = CancelToken.source();

const ZScanUploadForm: React.FC = () => {
  const classes = useStyles();
  const setActiveModal = useSetRecoilState(activeModalAtom);
  const [fileData, setFileData] = useState<File | null>(null);
  const [urlSubmitting, setUrlSubmitting] = useState<boolean>(false);
  const [uploadedPercent, setUploadedPercent] = useState(0);
  const [uploaded, setUploaded] = useState(false);
  const [notifyUploader, setNotifyUploader] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const resetModal = () => {
    setErrorMessage(null);
    setUploaded(false);
    setUploadedPercent(0);
  };

  const uploadApp = (url: string, formData: FormData) => {
    return Api.post(url, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
      cancelToken: source.token,
      onUploadProgress: (progressEvent: ProgressEvent) => {
        setUploadedPercent((progressEvent.loaded / progressEvent.total) * 100);
      },
    });
  };

  const handleCancelUploadRequest = () => {
    source.cancel();
    setActiveModal(undefined);
    resetModal();
    openSnackBar('Upload Cancelled');
    // this is needed to make requests again.  the cancel token needs to change.
    source = CancelToken.source();
  };

  const handleSubmit = useCallback(
    async ({ storeUrl, shouldAutoUpdateFromAppStore }: any) => {
      if (!fileData && !storeUrl) {
        setErrorMessage('Upload Error - Please Try Again');
        return;
      }

      if (storeUrl) {
        setUrlSubmitting(true);
        try {
          const { data }: any = await submitUrl({
            storeUrl,
            notifyUploader,
            shouldAutoUpdateFromAppStore,
          });
          setUploadedPercent(100);
          setUploaded(true);
          setActiveModal(undefined);
          setActiveModal({
            active: 'ZScanUploadComplete',
            payload: { ...data },
          });
          // need to now set state back to original with new modal
          setFileData(null);
          setUploaded(false);
          setUrlSubmitting(false);
          setUploadedPercent(0);
        } catch (e) {
          if (axios.isAxiosError(e)) {
            const status = e?.response?.status ?? -1;
            const statusText = e?.response?.statusText ?? 'Upload Error';
            const errorData: IUploadError = e?.response?.data;

            const dataString =
              typeof errorData?.message === 'string'
                ? errorData.message
                : 'Please Try Again';
            const errorMessage = formatErrorMessage(
              statusText,
              dataString,
              status
            );
            openSnackBar(errorMessage);
          }
          setUrlSubmitting(false);
          return setErrorMessage('Error in submitting url');
        }
      } else if (fileData) {
        // Create form data
        const formData = new FormData();
        formData.append('buildFile', fileData);
        formData.append('notifyUploader', JSON.stringify(notifyUploader));

        const url = 'api/zdev-upload/v1/uploads/build';

        try {
          const { data } = await uploadApp(url, formData);
          setUploadedPercent(50);
          setUploaded(true);
          setActiveModal(undefined);
          setActiveModal({
            active: 'ZScanUploadComplete',
            payload: { ...data },
          });
          // need to now set state back to original with new modal
          setFileData(null);
          setUploaded(false);
          setUploadedPercent(0);
          resetModal();
          return;
        } catch (e) {
          if (axios.isAxiosError(e)) {
            const errorData: IUploadError = e?.response?.data;
            const dataString =
              typeof errorData?.message === 'string'
                ? errorData.message
                : 'Error submitting file.';
            openSnackBar(dataString);
          }
        }
        resetModal();
        return setActiveModal(undefined);
      }
    },
    [fileData, notifyUploader, setActiveModal]
  );

  const handleRemoveFile = useCallback(() => {
    setFileData(null);
  }, [setFileData]);

  const handleClose = () => {
    setActiveModal(undefined);
    setFileData(null);
    handleCancelUploadRequest();
  };

  const getFileData = useCallback(
    (filedata: File) => {
      setFileData(filedata);
    },
    [setFileData]
  );
  return (
    <>
      <DialogContent>
        {!errorMessage && !fileData && (
          <>
            <Formik
              enableReinitialize
              initialValues={{
                storeUrl: null,
                shouldAutoUpdateFromAppStore: false,
                notificationEmail: '',
              }}
              onSubmit={handleSubmit}
              validateOnBlur
              // validationSchema={Schema(t)}
            >
              {({ values }) => {
                return (
                  <Form>
                    <GenericCard
                      title={
                        <span className={classes.fileUploadTypeText}>
                          SELECT APK/BUNDLE(AAB) FOR ANDROID or IPA FOR iOS
                        </span>
                      }
                    >
                      <div className={classes.uploaderDropZone}>
                        <Uploader
                          accept=".apk,.ipa,.aab"
                          getFileData={getFileData}
                          isShowing
                        />
                      </div>
                    </GenericCard>

                    <FormikField
                      component={TextField}
                      name="storeUrl"
                      label="App Store/Google Play Store URL"
                      // isHidden={false}//!!fileName}
                    />

                    <Checkbox
                      name="notifyUploader"
                      label={'Notify when Complete'}
                      checked={notifyUploader}
                      onChange={() => setNotifyUploader(!notifyUploader)}
                    />
                    <FormikField
                      component={Checkbox}
                      name="shouldAutoUpdateFromAppStore"
                      label="Auto check app store for updates"
                      type="checkbox"
                      disabled={!values.storeUrl}
                    />

                    <div className={classes.buttonPanel}>
                      <Button
                        color="secondary"
                        disabled={false} //{isSubmitting}
                        onClick={handleClose}
                        text="Cancel"
                      />
                      <Button
                        color="secondary"
                        disabled={
                          (fileData === null && !values.storeUrl) ||
                          (uploadedPercent ?? 0) > 1 ||
                          urlSubmitting
                        }
                        onClick={() => handleSubmit}
                        text="Upload App"
                        type="submit"
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </>
        )}
        {!errorMessage && !!fileData?.name && (
          <>
            <MUICard classes={{ root: classes.fileInfoCard }}>
              <div className="text__ellipsis">{fileData.name}</div>
              <button
                className={cc([
                  classes.fileInfoDeleteButton,
                  {
                    [classes.disabled]: false, //isSubmitting,
                  },
                ])}
                disabled={false} //{'isSubmitting'}
                onClick={handleRemoveFile}
                type="button"
              >
                <MUIDeleteIcon />
              </button>
            </MUICard>
            <div className={classes.buttonPanel}>
              <Button
                color="secondary"
                disabled={false} //{isSubmitting}
                onClick={handleClose}
                text="Cancel"
              />
              <Button
                color="secondary"
                disabled={fileData === null || (uploadedPercent ?? 0) > 1}
                onClick={handleSubmit}
                text="Upload App"
                id="upload-form"
                form="upload-form"
              />
            </div>
          </>
        )}
        {!errorMessage && (uploadedPercent ?? 0) > 1 && (
          <LinearProgress variant="determinate" value={uploadedPercent} />
        )}

        <UploadError
          errorMessage={errorMessage}
          handleCloseModal={handleClose}
          isShowing={!!errorMessage}
          retryUpload={resetModal}
        />
        <UploadComplete
          errorMessage={errorMessage}
          handleCloseModal={handleClose}
          isShowing={uploaded && !errorMessage}
        />
      </DialogContent>
    </>
  );
};

export default ZScanUploadForm;
