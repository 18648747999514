import React, { PureComponent } from 'react';
import _ from 'lodash';

// actions
import { publishEvent } from 'utils/eventUtils';

// components
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ZButton from 'UI/Buttons/ZButton';

// services
// import { deleteSelectedDataExportConfig } from 'api/DataExportService';
import { deleteZScanApp } from 'api/zDevService';
import {
  toggleModalDiffered,
  openSnackBar,
  toggleModalDirect,
} from '../../utils/storeUtils';
import withRouter from 'components/hocs/withRouter';

class ZscanAppsDeleteConfirm extends PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const { props } = this;
    const appName = _.get(props, 'data.rowData.name', 'this App');
    const platform = _.get(props, 'data.rowData.platform');
    let platformName;
    if (platform === 'android') {
      platformName = 'Android';
    } else {
      platformName = 'iOS';
    }
    return (
      <DialogContent>
        <p>
          Deleting the {appName} {platformName} app will permanently remove all
          findings and statistics from zScan.
          <br />
          <br /> Click the Delete App button to proceed.
        </p>
        <DialogActions>
          <ZButton
            styleName="modalCancel"
            action={toggleModalDiffered('ZscanAppsDeleteConfirm', false)}
            color="secondary"
            buttonText="Cancel"
          />
          <ZButton
            action={this.handleSubmit}
            buttonType="submit"
            color="primary"
            styleName="modalSave"
            buttonText="Delete App"
          />
        </DialogActions>
      </DialogContent>
    );
  }

  handleSubmit() {
    const { props } = this;
    const appName = _.get(props, 'data.rowData.name', 'Your App');
    deleteZScanApp({ appId: _.get(props, 'data.rowData.id') })
      .then(() => {
        publishEvent('table:force-fetch-zScanApps');
        props.updateUrl({ timestamp: Math.random() });
        toggleModalDirect('ZscanAppsDeleteConfirm', false);
        openSnackBar(`${appName} was successfully deleted`);
      })
      .catch(() => {
        toggleModalDirect('ZscanAppsDeleteConfirm', false);
        openSnackBar(`Deleted Failed`);
      });
  }
}

ZscanAppsDeleteConfirm.defaultProps = {
  data: {},
};

export default withRouter(ZscanAppsDeleteConfirm);
