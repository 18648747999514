import React from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

function FileInputField(props) {
  const fieldName = _.get(props, 'name', 'fileInput');

  const showFileName = () => {
    if (!props.fileName) return 'No file chosen';
    return (
      <div>
        {props.fileName}{' '}
        <DeleteIcon
          className="hover--link"
          onClick={() => props.onDeleteFile()}
        />
      </div>
    );
  };
  // const removeFieldFlag = 'remove'.concat(_.upperFirst(fieldName));
  if (!props.isShowing) {
    return null;
  }
  return (
    <div className={props.classes.fileButtonWrapper}>
      <Button
        variant="contained"
        component="label"
        color="primary"
        disabled={props.disabled}
      >
        <span className="table-cell__ellipsis">
          {props.buttonText || 'Upload File'}
        </span>
        <input
          id={fieldName}
          name="file"
          type="file"
          accept={props.accept}
          className={props.classes.hideFile}
          onChange={event => {
            props.onChange(event);
          }}
          hidden
        />
      </Button>
      {showFileName()}
    </div>
  );
}

const styles = ({ palette }) => {
  return {
    fileButtonWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingTop: 8,
    },
    hideFile: {
      // position: 'absolute',
      zIndex: '1000',
      // opacity: 0,
      cursor: 'pointer',
      right: 0,
      top: 0,
      height: '0%',
      // fontSize: 24,
      width: '100%',
    },
  };
};
export default withStyles(styles)(FileInputField);
