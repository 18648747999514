import React, { Component } from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
// ui
import Divider from '@material-ui/core/Divider';
import CloudDownload from '@material-ui/icons/CloudDownload';
/* Helpers */
import { certificatesConfig } from '../ThreatForensics';
import BuildsAnalysisDetails from '../../../../dashboard/zDev/buildsAnalysis/BuildsAnalysisDetails';
import { openSnackBar } from '../../../../utils/storeUtils';

const styles = ({ palette }) => {
  return {
    wrapper: {
      display: 'flex',
      alignItems: 'stretch',
      width: '100%',
    },
    dataWrapper: {
      display: 'flex',
      flexDirection: 'row',
      padding: 5,
      justifyContent: 'space-between',
    },
    label: {
      color: palette.text.secondary,
      fontWeight: 'bold',
      width: '25%',
    },
    labelValue: {
      width: '75%',
      display: 'flex',
    },
    divider: {
      paddingBottom: '10px',
    },
    downloadAllWrapper: {
      paddingBottom: '20px',
    },
  };
};

const handleDownloadCert = (certData, { threat }) => {
  const combinedCertificates = certData.map(({ raw }) => {
    return `${raw} \n`;
  });
  const textFile = new Blob(combinedCertificates, {
    type: 'text/plain;charset=utf-8',
  });
  const downloadLink = document.createElement('a');
  // Make sure that the link is not displayed
  downloadLink.style.display = 'none';
  // File name
  downloadLink.download = `${_.get(threat, 'zeventId', 'combined')}.crt`;
  // We have to create a link to the file
  downloadLink.href = window.URL.createObjectURL(textFile);
  // Add the link to your DOM
  document.body.appendChild(downloadLink);

  // Lanzamos
  downloadLink.click();
  openSnackBar('Certificate Downloaded');
};
class ThreatsCertificates extends Component {
  render() {
    const { props } = this;
    const certficatesList = _.get(props, 'threat.certificates', []);
    if (_.isEmpty(certficatesList)) {
      return null;
    }
    return certficatesList.map((singleCertificate, index) => {
      return (
        <>
          <div
            className={`${props.classes.downloadAllWrapper} cursor--pointer`}
            onClick={() => handleDownloadCert(certficatesList, props)}
          >
            <CloudDownload
              style={{
                minWidth: 'auto',
                margin: 0,
                padding: 0,
              }}
            />
            <span
              style={{
                paddingLeft: 8,
                verticalAlign: 'super',
                paddingBottom: 20,
              }}
            >
              Download All Certificates
            </span>
          </div>
          <div
            className={props.classes.wrapper}
            key={_.get(singleCertificate, 'serialNumber', index)}
          >
            <BuildsAnalysisDetails
              dataProperty="column1"
              config={certificatesConfig}
              buildsFindingsData={singleCertificate}
            />
            <BuildsAnalysisDetails
              dataProperty="column2"
              config={certificatesConfig}
              buildsFindingsData={singleCertificate}
            />
          </div>
          <div className={props.classes.divider}>
            <Divider />
          </div>
        </>
      );
    });
  }
}

export default withStyles(styles)(ThreatsCertificates);
