export function cleanResponseTypes(responseArray) {
  const osResponseTypes = responseArray.reduce((accum, value) => {
    return {
      ...accum,
      [value.id]: value,
    };
  }, {});
  return basePossibleSelections(osResponseTypes);
}

export const basePossibleSelections = (osResponseTypesPassed) => {
  const androidBucket = {};
  const iosBucket = {};
  const knoxBucket = {};
  const linuxBucket = {};
  const extensionsBucket = {};
  Object.keys(osResponseTypesPassed).forEach((responseId) => {
    const fullResponse = osResponseTypesPassed[responseId];
    fullResponse.operatingSystems.forEach((os) => {
      const knoxArray = [25, 14, 23, 24, 26];
      const chromeExtArray = [32, 33];
      if (knoxArray.includes(Number(responseId))) {
        knoxBucket[responseId] = fullResponse;
      } else if (chromeExtArray.includes(Number(responseId))) {
        extensionsBucket[responseId] = fullResponse;
      } else if (os === 1) {
        androidBucket[responseId] = fullResponse;
      } else if (os === 2) {
        iosBucket[responseId] = fullResponse;
      } else if (os === 4) {
        linuxBucket[responseId] = fullResponse;
      } else if (os === 5) {
        extensionsBucket[responseId] = fullResponse;
      }
    });
  });
  return {
    androidBucket,
    iosBucket,
    knoxBucket,
    linuxBucket,
    extensionsBucket,
  };
};
export function compareSelectedWithBase(selectedResponseTypes) {
  const newObj = {
    androidBucket: {},
    iosBucket: {},
    knoxBucket: !selectedResponseTypes?.length
      ? {
          26: {
            os: 1,
            responseId: 26,
            id: 26,
            status: 1,
            categories: [3, 4],
            operatingSystems: [1],
          },
        }
      : {},
    extensionsBucket: {},
  };

  selectedResponseTypes.forEach((response) => {
    const knoxArray = [25, 14, 23, 24, 26];
    const chromeExtArray = [32, 33];
    if (knoxArray.includes(Number(response.responseId))) {
      newObj.knoxBucket[response.responseId] = response;
    } else if (chromeExtArray.includes(Number(response.responseId))) {
      newObj.extensionsBucket[response.responseId] = response;
    } else if (response.os === 1) {
      newObj.androidBucket[response.responseId] = response;
    } else if (response.os === 2) {
      newObj.iosBucket[response.responseId] = response;
    }
  });
  return newObj;
}
