import MUIDialog from '@material-ui/core/Dialog';
import MUIDialogActions from '@material-ui/core/DialogActions';
import MUIDialogContent from '@material-ui/core/DialogContent';
import MUIDialogTitle from '@material-ui/core/DialogTitle';
import MUIFormControl from '@material-ui/core/FormControl';
import MUIInputAdornment from '@material-ui/core/InputAdornment';
import MUIMenuItem from '@material-ui/core/MenuItem';
import MUISelect from '@material-ui/core/Select';
import { getActiveModalAtom } from 'atoms/modals';
import Button from 'components/UI/Button';
import GenericTable from 'components/UI/GenericTable';
import { GenericPromptModal_TITLE } from 'components/UI/Modals/GenericPrompt';
import { IGenericPromptModalData } from 'components/UI/Modals/GenericPrompt/models';
import DeleteIcon from 'components/UI/icons/DeleteIcon';
import SearchIcon from 'components/UI/icons/SearchIcon';
import Checkbox from 'components/UI/input/Checkbox';
import TextField from 'components/UI/input/TextField';
import JsFileInfoCard from 'components/fileuploader/FileInfoCard';
import JsUploader from 'components/fileuploader/Uploader';
import _ from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { IpRegex, subnetIpRegex } from 'utils/regexUtils';
import { openSnackBar } from 'utils/storeUtils';
import { searchTable } from 'utils/tableUtils';
import { renderSelectGroup } from './SelectGroup';
import { ITableActionGroup, TableActions, tableActions } from './TableActions';
import { arrayToCSV } from './arrayToCSV';
import { csvToArray } from './csvToArray';
import { downloadFile } from './downloadFile';
import { IIpAddress } from './models';
import useStyles from './useStyles';

const Uploader: any = JsUploader;
const FileInfoCard: any = JsFileInfoCard;

const defaultIpAddress: IIpAddress = {
  id: '',
  ipAddress: '',
  ipMask: '',
  selected: false,
};

const fileHeaders = ['ipAddress', 'ipMask'];

const exampleData = [
  {
    ipAddress: '192.68.0.1',
    ipMask: '255.255.255.0',
  },
  {
    ipAddress: '192.68.0.2',
    ipMask: '255.255.255.0',
  },
  {
    ipAddress: '192.68.0.3',
    ipMask: '255.255.255.0',
  },
  {
    ipAddress: '192.68.0.4',
    ipMask: '255.255.255.0',
  },
  {
    ipAddress: '192.68.0.5',
    ipMask: '255.255.255.0',
  },
];

const activeModalAtom = getActiveModalAtom<IGenericPromptModalData>();

const searchFields: (keyof IIpAddress)[] = ['ipAddress'];

interface ISinkholeIpAdressesProps {
  onIpAddressesChanged: (key: 'ipAddresses', value: IIpAddress[]) => void;
  ipAddresses: IIpAddress[];
}

const SinkholeIpAdresses: React.FC<ISinkholeIpAdressesProps> = (props) => {
  const { onIpAddressesChanged, ipAddresses } = props;

  const classes = useStyles();

  const { t, ready } = useTranslation();

  const setActiveModal = useSetRecoilState(activeModalAtom);

  const [searchTerm, setSearchTerm] = useState<string>();
  const [data, setData] = useState(ipAddresses);
  const [newIpAdress, setNewIpAdress] = useState(defaultIpAddress);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTableAction, setSelectedTableAction] =
    useState<TableActions>();
  const [isValidIp, setIsValidIp] = useState(true);
  const [isValidMask, setIsValidMask] = useState(true);

  useEffect(() => {
    if (!_.isEqual(data, ipAddresses)) {
      onIpAddressesChanged('ipAddresses', data);
    }
  }, [data, ipAddresses, onIpAddressesChanged]);

  const exportToCSV = useCallback((): void => {
    const example = selectedTableAction === TableActions.DownloadExample;

    let values = [];
    let fileName = '';

    if (example) {
      values = exampleData;
      fileName = 'Example Ip Addresses';
    } else {
      fileName = `IpAddresses-${new Date().toLocaleDateString()}.csv`;

      const newData =
        selectedTableAction === TableActions.ExportSelected
          ? [...data.filter((ssid) => ssid.selected)]
          : [...data];

      values = newData.map(({ ipAddress, ipMask }) => ({ ipAddress, ipMask }));
    }

    const csvData = arrayToCSV(fileHeaders, values);
    const blob = new Blob([csvData], { type: 'text/csv' });

    downloadFile(blob, fileName);

    setSelectedTableAction(undefined);
  }, [data, selectedTableAction, setSelectedTableAction]);

  const newIpAddessExist = useCallback(() => {
    const searchIpAddress = searchTable(
      newIpAdress.ipAddress,
      data,
      searchFields
    );
    const searchIpMask = searchTable(newIpAdress.ipMask, data, searchFields);

    return searchIpAddress.length + searchIpMask.length > 0;
  }, [data, newIpAdress]);

  const onDeleteClick = useCallback(
    (item: any): void => {
      setActiveModal({
        active: GenericPromptModal_TITLE,
        payload: {
          title: t('MTD.POLICIES.NETWORK_POLICY.DELETE_IP_ADDRESS'),
          message: t('MTD.POLICIES.NETWORK_POLICY.CONFIRM_DELETE'),
          onCancel: () => setActiveModal(undefined),
          onConfirm: () => {
            const newData = [...data].filter((el) => el.id !== item.id);
            setData(newData);
            setActiveModal(undefined);
          },
        },
      });
    },
    [data, setActiveModal, t]
  );

  const onAddClick = useCallback((): void => {
    if (newIpAdress && newIpAdress.ipAddress && newIpAdress.ipMask) {
      const newData = [
        ...data,
        {
          ...newIpAdress,
          id: new Date().valueOf().toString(),
        },
      ];

      setData(newData);
      setNewIpAdress(defaultIpAddress);
    }
  }, [data, newIpAdress]);

  const onSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setSearchTerm(value);
    },
    [setSearchTerm]
  );

  const onIpAdressChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setNewIpAdress({ ...newIpAdress, ipAddress: value });
      setIsValidIp(!value || IpRegex.test(value));
    },
    [setNewIpAdress, setIsValidIp, newIpAdress]
  );

  const onIpMaskChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setNewIpAdress({ ...newIpAdress, ipMask: value });
      setIsValidMask(!value || subnetIpRegex.test(value));
    },
    [setNewIpAdress, setIsValidMask, newIpAdress]
  );

  const onSelectCheckboxChange = useCallback(
    (ipAdress: IIpAddress): void => {
      const selectedIndex = data.findIndex((el) => el.id === ipAdress.id);

      const newData = [...data];
      newData[selectedIndex] = { ...ipAdress, selected: !ipAdress.selected };

      setData(newData);
    },
    [data]
  );

  const onTableActionChange = useCallback(
    (
      event: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ): void => {
      const { value } = event.target;
      setSelectedTableAction(value as TableActions);
    },
    [setSelectedTableAction]
  );

  const onGoClick = useCallback((): void => {
    switch (selectedTableAction) {
      case TableActions.DeleteSelected: {
        const newData = data.filter((el) => !el.selected);
        setData(newData);
        setSelectedTableAction(undefined);
        break;
      }
      case TableActions.ExportSelected:
      case TableActions.ExportTable:
      case TableActions.DownloadExample:
        exportToCSV();
        break;
      case TableActions.ImportTable:
      case TableActions.ImportTableAppend:
        setIsUploadOpen(true);
        break;
      default:
        break;
    }
  }, [data, selectedTableAction, exportToCSV]);

  const onUploadClose = useCallback((): void => {
    setIsUploadOpen(false);
  }, [setIsUploadOpen]);

  const setFileData = useCallback(
    (fileData: File): void => {
      setSelectedFile(fileData);
    },
    [setSelectedFile]
  );

  const removeFile = useCallback((): void => {
    setSelectedFile(undefined);
  }, [setSelectedFile]);

  const handleSubmitForm = useCallback((): void => {
    if (selectedFile) {
      try {
        setIsLoading(true);

        const fileReader = new FileReader();

        fileReader.onload = function (event) {
          if (event.target) {
            const text = event.target.result;

            if (text) {
              const headers: (keyof IIpAddress)[] = ['ipAddress', 'ipMask'];
              const ips = csvToArray(text as string, headers, {
                enabled: true,
                selected: false,
              });

              if (ips.length > 0) {
                const newData = ips;

                if (
                  data.length > 0 &&
                  selectedTableAction === TableActions.ImportTableAppend
                ) {
                  newData.push(..._.cloneDeep(data));
                }

                setData(newData);
              }
            }
          }
        };

        fileReader.readAsText(selectedFile);

        setSelectedFile(undefined);
        setSelectedTableAction(undefined);
        openSnackBar(t('GLOBAL.SUCCESS_UPLOAD'));
        onUploadClose();
      } catch (error: any) {
        openSnackBar(
          error?.response?.data?.message || t('GLOBAL.SOMETHING_WRONG')
        );
      } finally {
        setIsLoading(false);
      }
    }
  }, [data, selectedTableAction, selectedFile, onUploadClose, t]);

  const selectGroup = useCallback(
    (group: ITableActionGroup) => renderSelectGroup(group),
    []
  );

  if (!ready) {
    return null;
  }

  return (
    <div className={classes.root} style={{ gap: '15px', width: '50%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          alignItems: 'end',
        }}
      >
        <TextField
          name="ipAddress"
          label={t('MTD.POLICIES.NETWORK_POLICY.IP_ADDRESS')}
          placeholder="192.168.0.1"
          style={{ minWidth: '180px' }}
          onChange={onIpAdressChange}
          value={newIpAdress.ipAddress}
          helperText={
            !isValidIp
              ? t('MTD.POLICIES.NETWORK_POLICY.INVALID_IP_ADDRESS')
              : undefined
          }
          error={!isValidIp}
        />
        <TextField
          name="ipMask"
          label={t('MTD.POLICIES.NETWORK_POLICY.IP_MASK')}
          placeholder="255.255.0.1"
          style={{ minWidth: '180px' }}
          onChange={onIpMaskChange}
          value={newIpAdress.ipMask}
          helperText={
            !isValidMask
              ? t('MTD.POLICIES.NETWORK_POLICY.INVALID_IP_MASK')
              : undefined
          }
          error={!isValidMask}
        />
        <Button
          text={t('GLOBAL.ADD')}
          color="secondary"
          style={{ height: 'min-content' }}
          onClick={onAddClick}
          disabled={
            !isValidIp ||
            !isValidMask ||
            !newIpAdress.ipAddress ||
            !newIpAdress.ipMask ||
            newIpAddessExist()
          }
        />

        <MUIFormControl className={classes.selectFormControl}>
          <MUISelect
            displayEmpty
            onChange={onTableActionChange}
            value={selectedTableAction || ''}
          >
            <MUIMenuItem value="" disabled>
              {t('GLOBAL.TABLE_ACTIONS')}
            </MUIMenuItem>
            {tableActions(t).map((group) => selectGroup(group))}
          </MUISelect>
        </MUIFormControl>

        <Button
          text={t('GLOBAL.GO')}
          color="secondary"
          style={{ height: 'min-content' }}
          onClick={onGoClick}
        />
      </div>

      <TextField
        name="search"
        placeholder={t('GLOBAL.SEARCH')}
        onChange={onSearchChange}
        InputProps={{
          endAdornment: (
            <MUIInputAdornment
              position="end"
              classes={{ root: classes.searchIcon }}
            >
              <SearchIcon />
            </MUIInputAdornment>
          ),
        }}
      />

      <GenericTable
        columnHeaders={[
          { id: 'select', label: '' },
          {
            id: 'ipAddress',
            label: t('MTD.POLICIES.NETWORK_POLICY.IP_ADDRESS'),
          },
          { id: 'ipMask', label: t('MTD.POLICIES.NETWORK_POLICY.IP_MASK') },
          { id: 'actions', label: t('GLOBAL.ACTIONS') },
        ]}
        rowMapping={[
          {
            path: 'select',
            columnContent: ({ rowData }) => (
              <Checkbox
                key={rowData?.id}
                checked={rowData?.selected as boolean}
                onChange={() =>
                  onSelectCheckboxChange(rowData as unknown as IIpAddress)
                }
                color="primary"
                className="table__checkbox"
              />
            ),
          },
          {
            path: 'actions',
            columnContent: ({ rowData }) => (
              <Button
                style={{ padding: 0, minWidth: 'auto' }}
                variant="text"
                icon={DeleteIcon}
                onClick={() => onDeleteClick(rowData)}
              />
            ),
          },
        ]}
        tableData={searchTable(searchTerm, data, searchFields) as any}
        tableId="IP Addresses Rules"
      />

      <MUIDialog open={isUploadOpen} onClose={onUploadClose}>
        <MUIDialogTitle style={{ display: 'flex', justifyContent: 'center' }}>
          {t('GLOBAL.UPLOAD_CSV')}
        </MUIDialogTitle>
        <MUIDialogContent>
          {!selectedFile && (
            <div className={classes.formGroup}>
              <Uploader
                accept=".csv"
                isShowing={true}
                getFileData={setFileData}
              />
            </div>
          )}

          {selectedFile && (
            <FileInfoCard
              fileData={selectedFile}
              isShowing={!!selectedFile}
              removeFile={removeFile}
              noActionButtons
            />
          )}
        </MUIDialogContent>

        <MUIDialogActions>
          <Button
            text={t('GLOBAL.UPLOAD')}
            disabled={!selectedFile || isLoading}
            onClick={handleSubmitForm}
          />
        </MUIDialogActions>
      </MUIDialog>
    </div>
  );
};

export default SinkholeIpAdresses;
