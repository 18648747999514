import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
/* Helpers */
import { forensicsConfig } from 'components/main/threats/ThreatForensics';
import _ from 'lodash';
import ThreatLocation from "../details/ThreatLocation";

const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;
const styles = ({ palette }) => {
  return {
    wrapper: {
      display: 'flex',
      alignSelf: 'flex-start',
      flexDirection: 'column',
      width: '50%',
    },
    dataWrapper: {
      display: 'flex',
      flexDirection: 'row',
      padding: 5,
      justifyContent: 'space-between',
    },
    label: {
      color: palette.text.secondary,
      fontWeight: 'bold',
      width: '25%',
    },
    labelValue: {
      width: '75%',
      display: 'flex',
    },
    mapWrapper: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: '200px',
      margin: '10px 0px 10px 0px',

    },
    mapContainer: {
      height: '100%',
      width: '100%'
    }
  };
};

class LocationSource extends Component {
  render() {
    const { classes, threat } = this.props;
    return (
      <>
        <div className={classes.mapWrapper}>
          {threat.locationInfo &&
          threat.locationInfo.geoPoint &&
          <ThreatLocation
              // locationInfo contains geoPoint (lat, lon) & source (GPS or GEOIP)
              lat={threat.locationInfo.geoPoint.lat}
              lng={threat.locationInfo.geoPoint.lon}
              isMarkerShown
              zoom={8}
              fullscreenControl={false}
              onMarkerClick={this.handleMarkerClick}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_MAPS_API_KEY}`}
              loadingElement={<div style={{height: '100%'}}/>}
              containerElement={
                <div
                    className={classes.mapContainer}
                />
              }
              mapElement={<div style={{height: '100%'}}/>}
          />
          }
        </div>
        <div className={classes.wrapper}>
          {threat.locationInfo &&
            forensicsConfig.location &&
            forensicsConfig.location.map((configObj, index) => {
              const path = configObj.path ? configObj.path : configObj.key;
              let value = configObj.defaultValue;

              if (_.has(threat, path)) {
                value = _.get(threat, path);
              }
              if (typeof configObj.fetch === 'function') {
                value = configObj.fetch(threat[path]);
              }
              return (
                <div key={index} className={classes.dataWrapper}>
                  <div className={classes.label}>{configObj.label}</div>
                  <div className={classes.labelValue}>{value}</div>
                </div>
              );
            })}
        </div>
      </>
    );
  }
}

export default withStyles(styles)(LocationSource);
