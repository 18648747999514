import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    aclCard: {
      margin: '20px -10px',
      backgroundColor: palette.components.checkboxInputGroup.base,
    },
    groupBackground: {
      paddingTop: '0',
      color: 'white',
      backgroundColor: palette.components.checkboxInputGroup.base,
    },
    polNumberContainer: {
      display: 'flex',
      margin: '15px 0 25px 0',
      backgroundColor: palette.background.default,
      maxWidth: 'fit-content'
    },
    polNumber: {
      padding: '8px 20px 8px 50px',
      fontSize: '21px'
    },
    polNumberDetails: {
      padding: '9px 50px 7px 20px'
    },
    errorBox: {
      marginTop: 20,
      height: 36,
      borderColor: palette.warning.schoolbus,
      justifyContent: 'center',
    },
    usedPoliciesContainer: {
      marginTop: 20,
    },
    usedPoliciesList: {
      listStyleType: 'none',
    },
    usedPoliciesListItem: {
      margin: '3px 0',
    },
    actionsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline'
    },
    wrapper: {
      display: 'flex',
      width: '30%',
      height: '40px',
    },
    select: {
      width: '100%',
      marginRight: 16,
    },
    menu: {
      '& ul': {
        padding: 0,
      },
      '& li': {
        '& span': {
          fontWeight: 'bold',
          color: 'white',
        },
      },
    },
    listGroup: {
      padding: '15px 10px',
      textDecoration: 'none',
      fontStyle: 'normal',
      fontWeight: 'bold',
      borderTop: `2px solid ${palette.primary.border}`,
      borderBottom: `2px solid ${palette.primary.border}`
    },
    listItem: {
      padding: '15px 25px',
    },
    formGroup: {
      outline: `2px dashed ${palette.primary.main}`,
      outlineOffset: '-10px',
      WebkitTransition:
        'outline-offset .15s ease-in-out, background-color .15s linear',
      transition:
        'outline-offset .15s ease-in-out, background-color .15s linear',
      height: 200,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    dialogTitle: {
      display: 'flex',
      justifyContent: 'center',
    }
  })
)

export default useStyles