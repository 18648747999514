

export interface IPlanFeatureType {
    [id: number]: string;
}

// https://github.com/Zimperium/svc-common-security/blob/master/src/main/java/com/zimperium/server/common/security/features/Feature.java

export const PlanFeaturesType: IPlanFeatureType = {
    0: 'Access to Apis',
    1: 'Data Export',
    2: 'Threat Forensics',
    3: 'Device Search External Tracking',
    4: 'Audit Logs',
    5: 'Privacy and Threat Policies',
    6: 'Teams',
    7: 'Roles',
    8: 'Monthly Active Device Limit',
    9: 'In Apps Limit',
    10: 'User Limit',
    11: 'Account Management',
    12: 'App Settings',
    13: 'Phishing Policy',
    200: 'App Inventory',
    201: 'Extension Inventory',
    202: 'OS Risk',
    203: 'MTD Branding Policy',
    300: 'App Protection',
    400: 'ZScan Assessments',
    401: 'ZScan Policies',
    402: 'ZScan Apps',
    600: 'EMM'
}

