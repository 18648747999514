import MUICard from '@material-ui/core/Card';
import MUICardContent from '@material-ui/core/CardContent';
import { CloudDownload } from '@material-ui/icons';
import {
  downloadDevicesCsv,
  downloadDevicesCsvCount,
} from 'api/DevicesService';
import { availableTeams as availableTeamsAtom } from 'atoms/teams';
import { DrawerContext } from 'components/drawer/DrawerProvider';
import { IDrawerContext } from 'components/drawer/DrawerProvider/models';
import withRouter from 'components/hocs/withRouter';
import SearchBox from 'components/UI/input/SearchBox';
import Table from 'components/UI/Table';
import { IColumnHeader, ILocationQuery } from 'components/UI/Table/models';
import Tabs from 'components/UI/Tabs';
import devicesDataFilterMapping from 'mappings/dataFilterMappings/devicesDataFilterMapping';
import React, { useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import { useRecoilValue } from 'recoil';
import {
  updateUISettings,
  devicesTableColumnChange,
  mtdDevicesTableColumnChange,
} from 'reducers/UiSettingsReducers';
import {
  getChangedTableHeadersHash,
  getUISettingsTableHeaders,
  getUISettingsWithout,
} from 'reducers/UiSettingsSelectors';
import { bindActionCreators, compose } from 'redux';
import { toggleModalDiffered } from 'utils/storeUtils';
import useAsyncResult from 'utils/useAsyncResult';
import useBackfill from 'utils/useBackfill';
import { withDirtyState } from 'utils/withDirtyState';
import withMemoizedProps from 'utils/withMemoizedProps';
import FilterChipBox from '../../common/FilterChipBox';
import { IFilterMapping } from '../../common/FilterChipBox/models';
import { IDeviceStats, ILocation, IQueryParams } from '../common/models';
import DeviceDetailsDrawer from '../DeviceDetailsDrawer';
import { deviceColumnMapping } from '../deviceMappings';
import DevicesAppBar from '../DevicesAppBar';
import DevicesOverviewGraph from '../DevicesOverviewGraph';
import DevicesTimelineGraph from '../DevicesTimelineGraph';
import { IDevicesDrawerData, IDevicesUISettings, IRowData } from './models';
import tableQuery from './tableQuery';
import useStyles from './useStyles';
import { fetchStats } from './utils';
import { useTranslation } from 'react-i18next';

interface IDevicesProps {
  location: ILocation;
  devicesUiSettings: IDevicesUISettings;
  devicesTableColumnChange: (...args: unknown[]) => void;
  mtdDevicesTableColumnChange: (...args: unknown[]) => void;
  jiggleDirtyState: (...args: any) => void;
  match: { url: string };
  module: string;
  q: { [key: string]: unknown };
  rqps: string[];
  updateUISettings: (...args: unknown[]) => void;
  currentTableHeaders: IColumnHeader[];
  currentMtdTableHeaders: IColumnHeader[];
  updateUrl: (params: IQueryParams) => void;
}

const Devices: React.FC<IDevicesProps> = ({
  devicesTableColumnChange,
  mtdDevicesTableColumnChange,
  devicesUiSettings,
  currentTableHeaders,
  currentMtdTableHeaders,
  jiggleDirtyState,
  location,
  match,
  q: query,
  updateUISettings,
  updateUrl,
  module,
  rqps,
}) => {
  const classes = useStyles();
  const { t, ready } = useTranslation();

  const { openDrawer } =
    useContext<Partial<IDrawerContext<IDevicesDrawerData>>>(DrawerContext);

  const availableTeams = useRecoilValue(availableTeamsAtom) || [];

  const deviceStats: IDeviceStats | undefined = useAsyncResult(
    fetchStats,
    module,
    query
  );

  useBackfill({
    query,
    requiredQueryParams: rqps,
    storedQueryParams: devicesUiSettings,
    updateUrl,
  });

  const createCSVTableHeaders = useCallback(() => {
    return module === 'mtd' ? currentMtdTableHeaders : currentTableHeaders;
  }, [module, currentTableHeaders, currentMtdTableHeaders]);

  const handleSearch = useCallback(
    (value: string) => {
      updateUrl({ search: value ?? '' });
    },
    [updateUrl]
  );

  const handleDeleteQueryParam = useCallback(
    (paramName: string) => {
      const queryParams = location.query;
      if (paramName === 'appVersionId') {
        updateUrl({
          ...queryParams,
          [paramName]: undefined,
          appName: undefined,
          appVersion: undefined,
        });
      } else {
        updateUrl({ ...queryParams, [paramName]: undefined });
      }
    },
    [updateUrl, location.query]
  );

  const handleColumnChange = useCallback(
    (...args: unknown[]) => {
      module === 'mtd'
        ? mtdDevicesTableColumnChange(...args)
        : devicesTableColumnChange(...args);
      jiggleDirtyState();
    },
    [
      devicesTableColumnChange,
      jiggleDirtyState,
      module,
      mtdDevicesTableColumnChange,
    ]
  );
  const handleOpenDrawer = (rowProps: { data: IRowData }) => {
    if (!openDrawer) {
      return;
    }
    openDrawer({
      drawerProps: {
        rowData: rowProps?.data,
        module,
        classList: classes.customDrawer,
      },
      drawer: DeviceDetailsDrawer,
    });
  };

  const handlePaginationChange = useCallback(
    (update: IQueryParams) => {
      updateUISettings({
        domain: module === 'mtd' ? 'mtdDevices' : 'devices',
        update,
      });

      updateUrl(update);
    },
    [updateUrl, module, updateUISettings]
  );

  if (!ready) {
    return null;
  }

  return (
    <>
      <DevicesAppBar
        deviceCounts={deviceStats?.deviceCounts}
        matchUrl={match?.url ?? ''}
        module={module}
        query={location.query}
        updateUISettings={updateUISettings}
        updateUrl={updateUrl}
      />
      <div className={classes.deviceContent}>
        <MUICard>
          <MUICardContent>
            <Tabs
              tabs={[
                {
                  title: t('MTD.DEVICES.OVERVIEW'),
                  content: (
                    <DevicesOverviewGraph
                      deviceCounts={deviceStats?.deviceCounts}
                      osVersionCounts={deviceStats?.deviceCountsByVersion}
                      module={module}
                      updateUrl={updateUrl}
                    />
                  ),
                },
                {
                  title: t('MTD.DEVICES.TIMELINE'),
                  content: (
                    <DevicesTimelineGraph
                      deviceAggregates={deviceStats?.deviceAggregates}
                      updateUrl={updateUrl}
                      query={location.query}
                    />
                  ),
                },
              ]}
            />
          </MUICardContent>
        </MUICard>

        <MUICard>
          <SearchBox onInputChange={handleSearch} />
        </MUICard>
        <MUICard>
          <FilterChipBox
            mapping={devicesDataFilterMapping(t) as IFilterMapping[]}
            onDeleteChip={handleDeleteQueryParam}
            queryParams={location.query}
          />
        </MUICard>

        <Table
          columnHeaders={
            module === 'mtd' ? currentMtdTableHeaders : currentTableHeaders
          }
          tableId="devices"
          classList={classes}
          footerComponents={() => (
            <CloudDownload
              style={{ cursor: 'pointer', fontSize: 30 }}
              onClick={toggleModalDiffered(
                'CsvExport',
                {
                  columnHeaders: createCSVTableHeaders(),
                  download: downloadDevicesCsv,
                  downloadCount: downloadDevicesCsvCount,
                  downloadParams: location.query,
                  module: module === 'mtd' && 'ZIPS',
                  filename: 'devices.csv',
                },
                {
                  title: t('GLOBAL.EXPORT_TO_CSV'),
                  disableBackdropClick: true,
                }
              )}
            />
          )}
          fetchTableData={() =>
            tableQuery(
              location.query,
              module,
              availableTeams
            )(location.query as unknown as ILocationQuery)
          }
          onColumnChange={handleColumnChange}
          onPaginationChange={handlePaginationChange}
          onRowClick={handleOpenDrawer}
          query={query as unknown as ILocationQuery}
          rowMapping={deviceColumnMapping(t)}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    columnHeadersHash: getChangedTableHeadersHash(state, 'devices'),
    currentTableHeaders: getUISettingsTableHeaders(state, 'devices'),
    currentMtdTableHeaders: getUISettingsTableHeaders(state, 'mtdDevices'),
    devicesUiSettings: getUISettingsWithout(state, 'devices', ['tableHeaders']),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      devicesTableColumnChange,
      mtdDevicesTableColumnChange,
      updateUISettings,
    },
    dispatch
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withDirtyState()
)(
  withMemoizedProps(Devices, [
    'currentTableHeaders',
    'mtdCurrentTableHeaders',
    'dirtyState',
    'q',
    'updateUISettings',
    'updateUrl',
  ])
);
