import { publishEvent } from 'utils/eventUtils';
import axios from 'axios';
import { IMaasAuth, IMaasFormData } from './models';
import { openSnackBar } from 'utils/storeUtils';
import { Api } from 'config/axiosConfig';
import { FormikHelpers } from 'formik';
import { IAuthForGroupsCreate, IAuthPayload } from '../../models';
import { TFunction } from 'react-i18next';

const createAuthPayload = (
  formData: IMaasFormData
): IAuthPayload<IMaasAuth> => ({
  authData: {
    connectorTypeId: formData?.connectorTypeId,
    baseUrl: formData?.baseUrl,
    auth: {
      billing_id: formData?.billing_id,
      user_id: formData?.user_id,
      password: formData?.password,
      app_id: formData?.app_id,
      app_version: formData?.app_version,
      platform_id: formData?.platform_id,
      access_key: formData?.access_key,
      baseUrl: '',
      connectorTypeId: '',
      name: '',
    },
  },
  mode: formData?.mode,
  name: formData?.name,
});

export const createConnection = async (
  formData: IMaasFormData,
  formikHelpers: FormikHelpers<IMaasFormData>,
  setAuthError: React.Dispatch<React.SetStateAction<string>>,
  goToNextPage: (authForGroupsCreate: IAuthForGroupsCreate<IMaasAuth>) => void,
  t: TFunction<'translation', undefined>
) => {
  const authPayload = createAuthPayload(formData);
  const apiUrl = 'api/emm/v1/provision/connection/authenticate';
  try {
    const result = await Api.post(apiUrl, authPayload);
    goToNextPage({
      ...authPayload,
      id: result?.data?.connectionId ?? '',
      teamId:
        typeof formData?.team?.value === 'string' ? formData?.team?.value : '',
    });
  } catch (e) {
    if (axios.isAxiosError(e) && e?.response?.status === 409) {
      setAuthError(t('MTD.INTEGRATIONS.ERROR_ACCOUNT_ALREADY_EXIST'));
    } else {
      setAuthError(t('MTD.INTEGRATIONS.ERROR_AUTHENTICATION'));
    }
    console.error(e);
    formikHelpers.setSubmitting(false);
  }
};

export const updateConnection = async (
  formData: IMaasFormData,
  formikHelpers: FormikHelpers<IMaasFormData>,
  setAuthError: React.Dispatch<React.SetStateAction<string>>,
  closeModal: () => void,
  t: TFunction<'translation', undefined>
) => {
  if (!formData?.connectionId) {
    return;
  }
  const authPayload = createAuthPayload(formData);
  const apiUrl = `api/emm/v1/provision/connection/${formData.connectionId}/auth-data`;
  try {
    await Api.patch(apiUrl, authPayload);
    closeModal();
    openSnackBar(t('MTD.INTEGRATIONS.SUCCESSFULLY_UPDATED_MDM_CONNECTION.'));
    publishEvent('emm:reload-table');
  } catch (e) {
    if (axios.isAxiosError(e) && e?.response?.status === 409) {
      setAuthError(t('MTD.INTEGRATIONS.ERROR_ACCOUNT_ALREADY_EXIST'));
    } else {
      setAuthError(t('MTD.INTEGRATIONS.ERROR_AUTHENTICATION'));
    }
    formikHelpers.setSubmitting(false);
    console.error(e);
  }
};
