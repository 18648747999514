import MUIAndroidIcon from '@material-ui/icons/Android';
import ProtectedLink from 'components/main/protected/ProtectedLink';
import AppleIcon from 'components/UI/icons/AppleIcon';
import {
  IColumnHeader,
  IFilterEditor,
  ITableRowMapping,
} from 'components/UI/Table/models';
import moment from 'moment';
import { IFullData } from '../common/models';
import { IFilterValues, IOSVersionRowData, TRiskMappings } from './models';
import { AVAILABLE_OSES, AVAILABLE_RISKS } from './utils';
import { TFunction } from 'react-i18next';

export const defaultMtdOSVersionsTableHeaders: IColumnHeader[] = [
  {
    filterEditor: 'SelectFilter',
    filterEditorProps: { multiple: true, wrapMultiple: false },
    id: 'risk',
    label: 'GLOBAL.RISK',
    show: true,
    path: 'risk',
    sort: 1,
    minWidth: 130,
    maxWidth: 230,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    filterEditor: 'SelectFilter',
    filterEditorProps: { multiple: true, wrapMultiple: false },
    id: 'os',
    label: 'GLOBAL.PLATFORM',
    show: true,
    path: 'os',
    sort: 1,
    minWidth: 130,
    maxWidth: 230,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    id: 'osVersion',
    label: 'GLOBAL.VERSION',
    show: true,
    path: 'osVersion',
    sort: 1,
    minWidth: 130,
    maxWidth: 230,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    filterEditor: 'DateFilter',
    filterEditorProps: { dateFormat: 'D-MMM-YYYY' },
    id: 'osPatchDate',
    label: 'MTD.OS_RISK.PATCH_DATE',
    show: true,
    path: 'osPatchDate',
    sort: 1,
    minWidth: 130,
    maxWidth: 230,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    filterEditor: 'NumberFilter',
    filterEditorProps: { minValue: 0 },
    id: 'cveCount',
    label: 'MTD.OS_RISK.CVE_COUNT',
    show: true,
    path: 'cveCount',
    sort: 1,
    minWidth: 130,
    maxWidth: 230,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    filterEditor: 'NumberFilter',
    filterEditorProps: { minValue: 0 },
    id: 'deviceCount',
    label: 'GLOBAL.DEVICE_COUNT',
    show: true,
    path: 'deviceCount',
    sort: 1,
    minWidth: 130,
    maxWidth: 230,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    filterEditor: 'NumberFilter',
    filterEditorProps: { minValue: 0 },
    id: 'upgradeableCount',
    label: 'GLOBAL.UPGRADEABLE',
    show: true,
    path: 'upgradeableCount',
    sort: 1,
    minWidth: 130,
    maxWidth: 230,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    filterEditor: 'NumberFilter',
    filterEditorProps: { minValue: 0 },
    id: 'nonUpgradeableCount',
    label: 'GLOBAL.NOT_UPGRADEABLE',
    show: true,
    path: 'nonUpgradeableCount',
    sort: 1,
    minWidth: 130,
    maxWidth: 230,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
];

export const COLUMN_MAPPINGS = (
  t: TFunction<'translation', undefined>
): ITableRowMapping[] => [
  {
    path: 'risk',
    columnContent: (fullData: IFullData<IOSVersionRowData>) => {
      const {
        classList,
        rowData: { risk },
      } = fullData;

      const riskStatuses: TRiskMappings = {
        low: { class: classList.green, name: 'Low' },
        medium: { class: classList.orange, name: 'Medium' },
        elevated: { class: classList.orange, name: 'Medium' },
        high: { class: classList.red, name: 'High' },
        critical: { class: classList.red, name: 'High' },
      };

      if (!risk) {
        return '';
      }

      const riskStatus = riskStatuses[risk.toLowerCase()];

      let statusClasses = classList.statusCircle + ' ';
      statusClasses += riskStatus.class;

      return (
        <div className="flex--spaced">
          <div className={statusClasses} />
          {t(`GLOBAL.${riskStatus?.name.toUpperCase()}`) ?? ''}
        </div>
      );
    },
  },
  {
    path: 'os',
    columnContent: (fullData: IFullData<IOSVersionRowData>) => {
      const {
        rowData: { os },
      } = fullData;

      if (!os || os < 1 || os > 2) {
        return '';
      }

      const osOptions = ['Android', 'iOS'];
      const platform = osOptions[os - 1];

      const Icon = os === 2 ? AppleIcon : MUIAndroidIcon;

      return (
        <div className="flex--spaced">
          <div style={{ paddingRight: '5px' }}>
            <Icon />
          </div>
          {t(`GLOBAL.${platform.toUpperCase()}`)}
        </div>
      );
    },
  },
  { path: 'osVersion' },
  {
    path: 'osPatchDate',
    columnContent: (fullData: IFullData<IOSVersionRowData>) => {
      const { osPatchDate } = fullData.rowData;
      const formattedPatchDate = osPatchDate
        ? moment(osPatchDate).format('D-MMM-YYYY')
        : '';
      return formattedPatchDate;
    },
  },
  { path: 'cveCount' },
  {
    path: 'deviceCount',
    columnContent: (fullData: IFullData<IOSVersionRowData>) => {
      const { rowData } = fullData;
      const osOptions = ['Android', 'iOS'];
      const platform = rowData?.os > 0 ? osOptions[rowData.os - 1] : '';
      const formattedPatchDate = rowData.osPatchDate
        ? moment(rowData.osPatchDate).format('YYYY-MM-DD')
        : '';
      const params = new URLSearchParams({
        duration: '9',
        'os.name': platform,
        'os.version': rowData?.osVersion ?? '',
        'os.patchDate.keyword': formattedPatchDate,
      });

      return (
        <ProtectedLink
          allow={{ devices: 'view' }}
          to={`/console/mtd/devices/?${params.toString()}`}
        >
          {fullData?.rowData?.deviceCount ?? ''}
        </ProtectedLink>
      );
    },
  },
  {
    path: 'upgradeableCount',
    columnContent: (fullData: IFullData<IOSVersionRowData>) => {
      const { os = 0, osVersion } = fullData.rowData;
      const osOptions = ['Android', 'iOS'];
      const platform = os > 0 ? osOptions[os - 1] : '';
      const params = new URLSearchParams({
        duration: '9',
        'os.name': platform,
        'os.version': osVersion,
        upgradeable: 'true',
      });

      return (
        <ProtectedLink
          allow={{ devices: 'view' }}
          to={`/console/mtd/devices/?${params.toString()}`}
        >
          {fullData?.rowData?.upgradeableCount ?? ''}
        </ProtectedLink>
      );
    },
  },
  {
    path: 'nonUpgradeableCount',
    columnContent: (fullData: IFullData<IOSVersionRowData>) => {
      const {
        rowData: { os = 0, osVersion },
      } = fullData;
      const osOptions = ['Android', 'iOS'];
      const platform = os > 0 ? osOptions[os - 1] : '';
      const params = new URLSearchParams({
        duration: '9',
        'os.name': platform,
        'os.version': osVersion,
        upgradeable: 'false',
      });

      return (
        <ProtectedLink
          allow={{ devices: 'view' }}
          to={`/console/mtd/devices?${params.toString()}`}
        >
          {fullData?.rowData?.nonUpgradeableCount ?? ''}
        </ProtectedLink>
      );
    },
  },
];

export const resolveFilters = (
  filterValues: IFilterValues,
  t: TFunction<'translation', undefined>
): IFilterEditor[] => {
  return [
    {
      name: 'risk',
      operator: 'inlist',
      type: 'select',
      value: filterValues?.risk ?? '',
      filterEditorProps: {
        dataSource: AVAILABLE_RISKS.map(({ label, value }) => ({
          id: value,
          label: t(`GLOBAL.${label.toUpperCase()}`),
        })),
      },
    },
    {
      name: 'os',
      operator: 'inlist',
      type: 'select',
      value: filterValues?.os ?? null,
      filterEditorProps: {
        dataSource: AVAILABLE_OSES.map(({ label, value }) => ({
          id: value,
          label: t(`GLOBAL.${label.toUpperCase()}`),
        })),
      },
    },
    {
      name: 'osVersion',
      operator: 'eq',
      type: 'string',
      value: filterValues?.osVersion ?? '',
    },
    {
      name: 'osPatchDate',
      operator: 'eq',
      type: 'date',
      value: filterValues?.osPatchDate ?? '',
    },
    {
      name: 'cveCount',
      operator: 'eq',
      type: 'number',
      value: filterValues?.cveCount ?? null,
    },
    {
      name: 'deviceCount',
      operator: 'eq',
      type: 'number',
      value: filterValues?.deviceCount ?? null,
    },
    {
      name: 'upgradeableCount',
      operator: 'eq',
      type: 'number',
      value: filterValues?.upgradeableCount ?? null,
    },
    {
      name: 'nonUpgradeableCount',
      operator: 'eq',
      type: 'number',
      value: filterValues?.nonUpgradeableCount ?? null,
    },
  ];
};
