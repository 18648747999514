import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// components
import ZButton from 'UI/Buttons/ZButton';

// ui
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  toggleModalDiffered,
  toggleModalDirect,
  openSnackBar,
} from '../../utils/storeUtils';

// actions
import { publishEvent } from 'utils/eventUtils';
import { deleteSelectedApp } from 'actions/AppsActions';

const AppsDeleteConfrim = ({ data, ...props }) => (
  <Fragment>
    <DialogTitle>Delete App</DialogTitle>
    <DialogContent>
      <p>Are you sure you want to delete {data.name}</p>
      <DialogActions>
        <ZButton
          styleName="modalCancel"
          buttonText="Cancel"
          color="secondary"
          action={toggleModalDiffered('AppsDeleteConfirm', false)}
        />
        <ZButton
          buttonType="submit"
          styleName="modalDelete"
          buttonText="Delete App"
          color="primary"
          action={() => props.deleteSelectedApp(data.id, data, onSuccess, onError)
          }
        />
      </DialogActions>
    </DialogContent>
  </Fragment>
);

function onSuccess() {
  toggleModalDirect('AppsDeleteConfirm', false);
  openSnackBar('App Successfuly Deleted');
  publishEvent('table:force-fetch-InApps');
}

// no-op func
function onError() {}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      deleteSelectedApp,
    },
    dispatch,
  );
};

export default connect(
  null,
  mapDispatchToProps,
)(AppsDeleteConfrim);
