module.exports = {
  0: 'Device',
  1: 'Network',
  2: 'Applications',
  3: 'Device Owner',
  4: 'Attacker Network',
  5: 'Browser History',
  6: 'Attacking File',
  7: 'Attacking SMS',
  8: 'Browser Extensions',
};
