export default (state) => {
  if (state.uiSettings.support) {
    delete state.uiSettings.support;
  }
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
    },
  };
};
