import {
  FormControlLabelProps as IMUIFormControlLabelProps,
  TextFieldProps as TMUITextFieldProps,
} from '@material-ui/core';
import { default as MUIFormControlLabel } from '@material-ui/core/FormControlLabel';
import { default as MUITextField } from '@material-ui/core/TextField';
import React from 'react';
import useStyles from './useStyles';
import { FieldProps as IFormikFieldProps } from 'formik';
import InputError from 'components/UI/input/InputError';
import cc from 'classcat';

export interface ITextFieldProps extends Partial<IFormikFieldProps> {
  errorClass?: string;
  label?: IMUIFormControlLabelProps | string;
  width?: string | number;
  isHidden?: boolean;
}

export type TTextFieldProps = ITextFieldProps & TMUITextFieldProps;

const TextField: React.FC<TTextFieldProps> = ({
  errorClass,
  field,
  isHidden,
  form,
  label,
  width,
  ...rest
}) => {
  const classes = useStyles();
  if (isHidden) {
    return null;
  }
  return (
    <>
      <MUIFormControlLabel
        label={label}
        classes={{
          root: classes.root,
          label: !!label ? classes.label : undefined,
        }}
        style={{ width: width || '' }}
        control={
          <MUITextField
            classes={{
              root: cc([
                classes.textfield,
                { [errorClass ?? '']: !!errorClass },
              ]),
            }}
            {...field}
            {...rest}
          />
        }
      />
      <InputError form={form} field={field} />
    </>
  );
};

export default TextField;
