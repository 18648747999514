import memoizeTableQuery from 'components/UI/Table/utils/memoizeTableQuery';
import { fetchTableData } from './utils';
import { IZScanInstancesResponseData } from './models';
import { IQueryParams } from './models';
const memoizeQuery = (
  findingId: string,
  assessmentId: string,
  query: IQueryParams
) => {
  return memoizeTableQuery(async () => {
    const data: IZScanInstancesResponseData | undefined = await fetchTableData({
      ...query,
      'findingId.keyword': findingId,
      'assessmentId.keyword': assessmentId,
    });
    return {
      data: data?.data?.content ?? [],
      count: data?.data?.totalElements ?? 0,
    };
  });
};
export default memoizeQuery;
