import { AxiosResponse } from 'axios';
import { Api } from 'config/axiosConfig';
import { IPolicyGroup } from './models';

export const fetchPolicyGroups = async () => {
  try {
    const apiUrl = 'api/groups/v1/groups';
    const response: AxiosResponse<IPolicyGroup[]> = await Api.get(apiUrl);
    return response.data.map(({ name, id, team }) => ({
      label: name,
      value: id,
      teamId: team?.id ?? '',
    }));
  } catch (e) {
    console.error(e);
  }
};
