import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './useStyles';

const ThreatAlertHeader: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container spacing={1} className={classes.alertHeaderWrapper}>
      <Grid item xs={3}>
        <Typography className={classes.headerLabel}>
          {t('MTD.POLICIES.THREAT.THREAT_NAME')}
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography className={classes.headerLabel}>
          {t('MTD.POLICIES.THREAT.ALERT_TEXT')}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography className={classes.headerLabel}>
          {t('MTD.POLICIES.THREAT.BUTTON_LABEL')}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography className={classes.headerLabel}>
          {t('MTD.POLICIES.THREAT.BUTTON_LINK')}
        </Typography>
      </Grid>
      {/* <Grid item xs={2}>
        <Typography className={classes.headerLabel}>Last Updated</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography className={classes.headerLabel}>Updated By</Typography>
      </Grid> */}
    </Grid>
  );
};

export default ThreatAlertHeader;
