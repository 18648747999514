import { getAffectedApps } from 'api/AppPolicyService';
import { IQueryParams } from './models';
import { IAffectedAppsPostPayload } from '../APCCreatePolicyModal/models';

export const fetchTableData = async (
  query: IQueryParams,
  affectedAppsPostDataPayload: IAffectedAppsPostPayload,
  setTotalDataCount: (count: number) => void
) => {
  let result;
  try {
    result = await getAffectedApps(
      { ...query },
      { ...affectedAppsPostDataPayload }
    );
    setTotalDataCount(result?.data?.totalElements);
  } catch (e) {
    console.error(e);
  }
  return result;
};
