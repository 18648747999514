import { FormikHelpers } from 'formik';
import axios from 'axios';
import { Api } from 'config/axiosConfig';
import Moment from 'moment';
import { publishEvent } from 'utils/eventUtils';
import { openSnackBar, toggleModalDirect } from 'utils/storeUtils';
import { IFormData } from './Form/models';
import { IPayload } from './models';
import { TFunction } from 'react-i18next';

export const handleSubmit = async (
  formData: IFormData,
  formikHelpers: FormikHelpers<IFormData>,
  t: TFunction<'translation', undefined>
) => {
  const payload: IPayload = {
    activationLimit: (formData?.activationLimit as number) ?? null,
    description: formData?.description ?? null,
    groupId: (formData?.group?.value as string) ?? null,
    linkExpiry: formData?.linkExpiry
      ? Moment(formData.linkExpiry).format('YYYY-MM-DD')
      : '',
    name: formData?.name ?? null,
  };

  const baseUrl = 'api/zapp/v1/group-activations';

  if (!!formData?.editMode) {
    try {
      const teamId = formData?.team?.value || null;
      const apiUrl = `${baseUrl}/${formData.id}`;
      await Api.put(apiUrl, { ...payload, teamId });
      openSnackBar(`Successfully updated group activation for ${payload.name}`);
      publishEvent('table:force-fetch-groupActivations');
      toggleModalDirect('UserActivationsCreateEdit', false);
    } catch (e: unknown) {
      if (axios.isAxiosError(e) && e?.response?.status === 409) {
        formikHelpers.setErrors({
          name: 'An activation with this name address already exists.',
        });
      } else {
        formikHelpers.setErrors({
          name: t('MTD.ACTIVATIONS.FAILED_TO_UPDATE_GROUP_ACTIVATION.'),
        });
      }
      formikHelpers.setSubmitting(false);
      console.error(e);
    }
  } else {
    const teamId = formData?.selectedTeamId || formData?.team?.value;

    try {
      const apiUrl = baseUrl;
      await Api.post(apiUrl, { ...payload, teamId });
      openSnackBar(`Successfully created group activation for ${payload.name}`);
      publishEvent('table:force-fetch-groupActivations');
      toggleModalDirect('UserActivationsCreateEdit', false);
    } catch (e: unknown) {
      if (axios.isAxiosError(e) && e?.response?.status === 409) {
        formikHelpers.setErrors({
          name: 'An activation with this name address already exists.',
        });
      } else {
        formikHelpers.setErrors({
          name: 'Failed to create group activation.',
        });
      }
      formikHelpers.setSubmitting(false);
      console.error(e);
    }
  }
};
