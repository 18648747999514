import React from 'react';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import FileInputField from 'components/inputs/FileInputField';
import { TextField } from '../../inputs';
import { MultiSelect } from '../../Selects';
import {
  availableMessageTypes,
  availableSyslogFacility,
  availablePayloadTypes,
  availableTransportTypes,
  availableSyslogSeverity,
} from '../enums/DataExportEnums';
import RequiredSelectWrapper from '../../Selects/MultiSelect/RequiredSelectWrapper';

const key = 'SIEM';
const MultiSelectRequired = (props) => (
  <RequiredSelectWrapper {...props} SelectComponent={MultiSelect} />
);
export const SIEM = (props) => {
  const validate = (field, value) => {
    if (field === 'port') {
      if (_.isEmpty(value)) {
        return true;
      }
      return _.inRange(_.toInteger(value), 1, 65535 + 1);
    }
    return true;
  };

  const handleChange = (field) => (event) => {
    if (field === 'transportTypeTrustedCAFile') {
      const fileData = _.get(event, 'currentTarget.files[0]');
      return props.onChange(key, {
        ...props.config,
        transportTypeTrustedCAFile: fileData,
        markTrustedCAToBeDeleted: false,
      });
    }
    return validate(field, event.target.value)
      ? props.onChange(
        key,
        _.update(_.cloneDeep(props.config), field, () => event.target.value)
      )
      : null;
  };

  const handleDelete = (field) => {
    if (field === 'transportTypeTrustedCAFile') {
      // for existing configs
      // this is needed to remove the value from the dom.
      //  otherwise it will not allow you to delete and re upload a file
      document.getElementById('transportTypeTrustedCAFile').value = null;

      props.onChange(key, {
        ...props.config,
        trustedCAFileName: undefined,
        transportTypeTrustedCAFile: undefined,
        markTrustedCAToBeDeleted: true,
      });
    }
  };

  const handleSelect = (field, selectedOption) => {
    return props.onChange(
      key,
      _.update(_.cloneDeep(props.config), field, () => selectedOption)
    );
  };
  const { config } = props;

  const fillValuesForEditMode = () => {
    if (!_.isObject(config.messageType)) {
      handleSelect(
        'messageType',
        availableMessageTypes[
        _.findIndex(
          availableMessageTypes,
          (_) => _.value === config.messageType
        )
        ]
      );
    }

    if (!_.isObject(config.payloadType)) {
      handleSelect(
        'payloadType',
        availablePayloadTypes[
        _.findIndex(
          availablePayloadTypes,
          (_) => _.value === config.payloadType
        )
        ]
      );
    }

    if (!_.isObject(config.transportType)) {
      handleSelect(
        'transportType',
        availableTransportTypes[
        _.findIndex(
          availableTransportTypes,
          (_) => _.value === config.transportType
        )
        ]
      );
    }

    if (!_.isObject(config.severity)) {
      handleSelect(
        'severity',
        availableSyslogSeverity[
        _.findIndex(
          availableSyslogSeverity,
          (_) => _.value === config.severity
        )
        ]
      );
    }

    if (!_.isObject(config.facility)) {
      handleSelect(
        'facility',
        availableSyslogFacility[
        _.findIndex(
          availableSyslogFacility,
          (_) => _.value === config.facility
        )
        ]
      );
    }
  };

  if (props.editMode) {
    fillValuesForEditMode();
  } else {
    if (!_.isNil(_.get(props, 'isAlienVault'))) {
      _.update(config, 'messageType', () => availableMessageTypes[0]);
      _.update(config, 'payloadType', () => availablePayloadTypes[0]);
    }
    if (!_.isNil(_.get(props, 'isWorkspaceOne'))) {
      _.update(config, 'messageType', () => availableMessageTypes[1]);
      _.update(config, 'payloadType', () => availablePayloadTypes[1]);
      _.update(config, 'transportType', () => availableTransportTypes[2]);
      _.update(config, 'severity', () => availableSyslogSeverity[0]);
      _.update(config, 'facility', () => availableSyslogFacility[0]);
    }
  }

  return (
    <div>
      <MultiSelectRequired
        isShowing={
          _.isNil(_.get(props, 'isAlienVault')) &&
          _.isNil(_.get(props, 'isWorkspaceOne'))
        }
        name="messageType"
        label="Message Type"
        isMulti={false}
        buttonPlaceholder="Select a Message Type"
        options={[availableMessageTypes[0]]} // only show one option for SIEM and REST endpoint
        onChange={handleSelect}
        values={config.messageType}
      />
      <MultiSelectRequired
        name="payloadType"
        label="Payload Type"
        isShowing={
          _.isNil(_.get(props, 'isAlienVault')) &&
          _.isNil(_.get(props, 'isWorkspaceOne'))
        }
        isMulti={false}
        buttonPlaceholder="Select a Payload Type"
        options={[availablePayloadTypes[0]]} // only show one option for SIEM and REST endpoint
        onChange={handleSelect}
        values={config.payloadType}
      />
      <MultiSelectRequired
        isShowing={_.isNil(_.get(props, 'isWorkspaceOne'))}
        name="transportType"
        label="Transport Type"
        isMulti={false}
        buttonPlaceholder="Select a Transport Type"
        options={availableTransportTypes}
        onChange={handleSelect}
        values={config.transportType}
      />
      <FileInputField
        isShowing={_.get(config, 'transportType.value') === 'TCP_TLS' && !props?.isWorkspaceOne}
        name="transportTypeTrustedCAFile"
        label="Pem file"
        accept=".pem"
        onChange={handleChange('transportTypeTrustedCAFile')}
        onDeleteFile={() => handleDelete('transportTypeTrustedCAFile')}
        fileName={
          _.get(config, 'transportTypeTrustedCAFile.name') ||
          _.get(config, 'trustedCAFileName') ||
          null
        }
      />

      <Grid container>
        <Grid item md={8}>
          <TextField
            label="Endpoint"
            value={config.endpoint}
            onChange={handleChange('endpoint')}
          />
        </Grid>
        <Grid item md={4}>
          <TextField
            label="Port"
            value={config.port}
            onChange={handleChange('port')}
          />
        </Grid>
      </Grid>
      <MultiSelectRequired
        isShowing={_.isNil(_.get(props, 'isWorkspaceOne'))}
        name="severity"
        label="Syslog Severity"
        isMulti={false}
        buttonPlaceholder="Select a Syslog Severity"
        options={availableSyslogSeverity}
        onChange={handleSelect}
        values={config.severity}
      />
      <MultiSelectRequired
        isShowing={_.isNil(_.get(props, 'isWorkspaceOne'))}
        name="facility"
        label="Syslog Facility"
        isMulti={false}
        buttonPlaceholder="Select a Syslog Facility"
        options={availableSyslogFacility}
        onChange={handleSelect}
        values={config.facility}
      />
      <TextField
        isShowing={!_.isNil(_.get(props, 'isWorkspaceOne'))}
        label="Integration Token"
        value={config.certificate}
        onChange={handleChange('certificate')}
      />
    </div>
  );
};
