import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

export default state => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      authorization: {
        ...uiSettingsDefaults.authorization,
        tableHeaders: uiSettingsDefaults.authorization.tableHeaders,
      },
    },
  };
};
