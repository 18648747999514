import {
  fetchAllTRMPoliciesList,
  fetchThreatTypes,
  fetchThreatTypesForTRMPolicy,
  fetchTRMPolicyByIdNew,
  fetchUserThreatNotifications,
} from 'api/TRMPolicyService';
import { ISelectItem } from 'components/UI/input/Select';
import { TFunction } from 'react-i18next';
import { ITRMPolicy, ITRMRule, SeverityLevel } from './models';

export const fetchAllPoliciesList = async (
  teamId?: string | null,
  mtdModule?: boolean
): Promise<Array<ISelectItem>> => {
  const policies = await fetchAllTRMPoliciesList(
    teamId,
    mtdModule ? 'ZIPS' : undefined
  );

  return policies.data.map(
    ({ id, name, created, ...rest }: Partial<ITRMPolicy>) => ({
      id,
      name,
      created,
      ...rest,
    })
  );
};

export const fetchTRMData = async (
  t: TFunction<'translation', undefined>,
  id?: string | number | null
): Promise<ITRMPolicy> => {
  const { data } = await fetchTRMPolicyByIdNew({ id });

  return {
    ...data,
    rules: data.rules.map((r: ITRMRule) => ({
      ...r,
      selectRow: false,
      severityString: SeverityLevel(t)[r.severity],
    })),
  };
};

export const fetchThreatTypesData = async (distribution: number) => {
  const threatTypes = await fetchThreatTypes({
    distribution,
  });
  return threatTypes.data;
};

export const fetchThreatTypesDataForTRMPolicyService = async (distribution: string, languagePayload: string) => {
  const threatTypes = await fetchThreatTypesForTRMPolicy(distribution, languagePayload);
  return threatTypes?.data ?? [];
};

export const fetchUserThreatNotificationsData = async (
  trmId: string | undefined
) => {
  const userThreatNotifications = await fetchUserThreatNotifications({ trmId });
  return userThreatNotifications.data;
};
