import cc from 'classcat';
import DoughnutChart from 'components/UI/Chart/Doughnut';
import { IHorizontalLegendItem } from 'components/UI/Chart/HorizontalLegend/models';
import GenericCard from 'components/UI/GenericCard';
import { useMemo } from 'react';
import useAsyncResult from 'utils/useAsyncResult';
import { default as useSharedStyles } from '../../useStyles';
import { IDevicesThreats } from './models';
import useStyles from './useStyles';
import { fetchDevicesThreats, getThreatsImpactedLegendItems } from './utils';

interface IDevicesThreatsProps {
  teamId: string | null | undefined;
  zappId: string | null | number | undefined;
}

const DevicesThreats: React.FC<IDevicesThreatsProps> = ({ teamId, zappId }) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  const devicesThreats = useAsyncResult<IDevicesThreats>(
    fetchDevicesThreats,
    teamId ?? '',
    zappId ?? ''
  );

  const devicesImpacted: IHorizontalLegendItem[] = useMemo(
    () => [
      {
        order: 0,
        color: '#2a95cc',
        label: 'iOS',
        link: `/console/zdefend/devices/?duration=8&os.name=iOS&zappId=${
          zappId ?? 'All'
        }`,
        value: devicesThreats?.totalIosDevices ?? 0,
      },
      {
        order: 1,
        color: '#4cae50',
        label: 'Android',
        link: `/console/zdefend/devices/?duration=8&os.name=Android&zappId=${
          zappId ?? 'All'
        }`,
        value: devicesThreats?.totalAndroidDevices ?? 0,
      },
    ],
    [devicesThreats, zappId]
  );

  const androidThreats = useMemo(
    () =>
      getThreatsImpactedLegendItems({
        critical: devicesThreats?.criticalAndroidThreats ?? 0,
        elevated: devicesThreats?.elevatedAndroidThreats ?? 0,
        low: devicesThreats?.lowAndroidThreats ?? 0,
        os: 'android',
        zappId: `${zappId ?? 'All'}`,
      }),
    [devicesThreats, zappId]
  );

  const iosThreats = useMemo(
    () =>
      getThreatsImpactedLegendItems({
        critical: devicesThreats?.criticalIosThreats ?? 0,
        elevated: devicesThreats?.elevatedIosThreats ?? 0,
        low: devicesThreats?.lowIosThreats ?? 0,
        os: 'ios',
        zappId: `${zappId ?? 'All'}`,
      }),
    [devicesThreats, zappId]
  );

  const hasAndroidThreatsData = useMemo(
    () =>
      devicesThreats?.criticalAndroidThreats > 0 ||
      devicesThreats?.elevatedAndroidThreats > 0 ||
      devicesThreats?.lowAndroidThreats,
    [devicesThreats]
  );

  const hasIosThreatsData = useMemo(
    () =>
      devicesThreats?.criticalIosThreats > 0 ||
      devicesThreats?.elevatedIosThreats > 0 ||
      devicesThreats?.lowIosThreats,
    [devicesThreats]
  );

  const hasData = useMemo(
    () =>
      Object.values(devicesThreats ?? {})?.reduce((a, c) => (a ?? 0) + c, 0) >
      0,
    [devicesThreats]
  );

  return (
    <GenericCard
      className={cc([sharedClasses.card, classes.devicesThreatsCard])}
    >
      <div className={classes.section}>
        <h2>Devices Impacted</h2>
        {hasData ? (
          <DoughnutChart
            forceProportionate
            order
            segments={devicesImpacted}
            summarySuffix="devices"
            withLegend
          />
        ) : (
          <div className={cc([classes.naCard, classes.devicesThreatsCard])}>
            N/A
          </div>
        )}
      </div>
      <div className={classes.section}>
        <h2>Android Threats</h2>
        {hasAndroidThreatsData ? (
          <DoughnutChart
            forceProportionate
            order
            segments={androidThreats}
            summarySuffix="threats"
            withLegend
          />
        ) : (
          <div className={cc([classes.naCard, classes.devicesThreatsCard])}>
            N/A
          </div>
        )}
      </div>
      <div className={classes.section}>
        <h2>iOS Threats</h2>
        {hasIosThreatsData ? (
          <DoughnutChart
            forceProportionate
            order
            segments={iosThreats}
            summarySuffix="threats"
            withLegend
          />
        ) : (
          <div className={cc([classes.naCard, classes.devicesThreatsCard])}>
            N/A
          </div>
        )}
      </div>
    </GenericCard>
  );
};

export default DevicesThreats;
