import * as Yup from 'yup';
import {
  csvInjectionRegex,
  csvInjectionErrorMessage,
} from 'utils/componentUtils';
import { TFunction } from 'react-i18next';

const Schema = (t: TFunction<'translation', undefined>) => {
  return Yup.object().shape({
    api_key: Yup.string().when('modalMode', {
      is: 'ADD',
      then: Yup.string().required(t('MTD.VALIDATIONS.API_KEY_IS_REQUIRED')),
    }),
    baseUrl: Yup.string()
      .matches(csvInjectionRegex, csvInjectionErrorMessage)
      .required(t('MTD.VALIDATIONS.URL_IS_REQUIRED')),
    // certificate: Yup.string()
    //   .matches(csvInjectionRegex, csvInjectionErrorMessage)
    //   .when('modalMode', {
    //     is: 'ADD',
    //     then: Yup.string().required('Certificate upload required.'),
    //   }),
    name: Yup.string()
      .matches(csvInjectionRegex, csvInjectionErrorMessage)
      .required(t('GLOBAL.REQUIRED_NAME')),
    // password: Yup.string()
    //   .when('modalMode', {
    //     is: 'ADD',
    //     then: Yup.string().required('MDM password is required.'),
    //   }),
    // user_id: Yup.string()
    //   .matches(csvInjectionRegex, csvInjectionErrorMessage)
    //   .when('auth_method_name', {
    //     is: 'basic',
    //     then: Yup.string().required('MDM username is required.'),
    //   }),
    team: Yup.mixed().test({
      name: 'team-required-if-all-teams',
      // function keyword used below because we need access to "this"
      test: function (value, ctx) {
        if (!!ctx?.parent?.selectedTeamId) {
          return true;
        }

        return !value
          ? this?.createError({
              message: t('GLOBAL.REQUIRED_TEAM'),
              path: 'team',
            })
          : true;
      },
    }),
  });
};

export default Schema;
