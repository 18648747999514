import React, { ComponentClass, FunctionComponent, useState } from 'react';
import { IOpenDrawerProps } from './models';

export const DrawerContext = React.createContext({});

interface IDrawerProviderProps {}

const DrawerProvider: React.FC<IDrawerProviderProps> = ({ children }) => {
  const [isOpen, setDrawerOpen] = useState<boolean>(false);
  const [drawer, setDrawer] = useState<
    string | FunctionComponent<any> | ComponentClass<any, any> | null
  >(null);
  const [drawerData, setDrawerData] = useState({});

  // Below, "any" type is used because the props will vary drawer to drawer
  const openDrawer = ({ drawerProps, drawer }: IOpenDrawerProps<any>) => {
    setDrawer(() => drawer);
    setDrawerData(drawerProps);
    setDrawerOpen(!isOpen);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    setDrawerData({});
    setDrawer(null);
  };

  return (
    <DrawerContext.Provider
      value={{
        closeDrawer,
        drawer,
        drawerData,
        isOpen,
        openDrawer,
        setDrawerData,
      }}
    >
      {' '}
      {children}
    </DrawerContext.Provider>
  );
};

export default DrawerProvider;
