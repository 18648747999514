// ui
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ZButton from 'UI/Buttons/ZButton';

// services
import {
  toggleModalDirect,
  openSnackBar,
  toggleModalDiffered,
} from 'utils/storeUtils';
import { deleteIntegration } from '../../dashboard/zDev/Integrations/TicketingIntegrations/integrations.service';
import { publishEvent } from 'utils/eventUtils';
import { useTranslation } from 'react-i18next';

export const IntegrationDeleteConfirm = ({ data }) => {
  const { t, ready } = useTranslation();

  function handleDelete() {
    deleteIntegration({ integrationId: data.id })
      .then(() => {
        openSnackBar(
          t('ZSCAN.INTEGRATIONS.SUCCESSFULLY_DELETED_INTEGRATION', {
            integrationName: data.name,
          })
        );
        publishEvent('table:force-fetch-integrations');
        toggleModalDirect('IntegrationDeleteConfirm', false);
      })
      .catch((error) => {
        openSnackBar(
          t('ZSCAN.INTEGRATIONS.DELETE_INTEGRATION_FAILED', {
            integrationName: data.name,
            errorBody: error?.response?.data?.message ?? '',
          })
        );
      });
  }

  if (!ready) {
    return null;
  }

  return (
    <DialogContent>
      <p>
        {t('ZSCAN.INTEGRATIONS.CONFIRM_DELETE_INTEGRATION', {
          integrationName: data.name,
        })}
      </p>
      <DialogActions>
        <ZButton
          styleName="modalCancel"
          action={toggleModalDiffered('IntegrationDeleteConfirm', false)}
          color="secondary"
          buttonText={t('GLOBAL.CANCEL')}
        />
        <ZButton
          action={handleDelete}
          buttonType="submit"
          color="primary"
          styleName="modalSave"
          buttonText={t('GLOBAL.DELETE')}
        />
      </DialogActions>
    </DialogContent>
  );
};

IntegrationDeleteConfirm.defaultProps = {
  data: {},
};

export default IntegrationDeleteConfirm;
