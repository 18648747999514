import CircleIcon from 'components/UI/icons/CircleIcon';
import { IAppVersion } from './models';
import { TFunction } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import { severityByValues } from './appsInventory.mappings';

export const riskAssessment = (
  risk: number | undefined,
  t?: TFunction<'translation', undefined>,
  noText?: boolean
) => {
  const riskNumber = risk as number;

  return (
    <>
      {riskNumber > 66 && <CircleIcon error />}
      {riskNumber > 33 && riskNumber <= 66 && <CircleIcon warning />}
      {riskNumber >= 0 && riskNumber <= 33 && <CircleIcon success />}
      {!noText && riskNumber > 66 && t?.('GLOBAL.HIGH')}
      {!noText && riskNumber > 33 && riskNumber <= 66 && t?.('GLOBAL.MEDIUM')}
      {!noText && riskNumber >= 0 && riskNumber <= 33 && t?.('GLOBAL.LOW')}
    </>
  );
};

export const RiskScoreCell = (
  rowData: Partial<IAppVersion> | null,
  riskScore: number | undefined,
  t?: TFunction<'translation', undefined>
) => {
  // catch all here if no process state
  if (rowData?.processState === 'UNKNOWN' || !rowData?.processState) {
    return <span>{t?.('MTD.APP_INVENTORY.TABLE_VIEW.PROCESSING')}</span>;
  }

  if (rowData?.processState === 'AVAILABLE') {
    return (
      <div style={{ display: 'flex' }}>{riskAssessment(riskScore, t)}</div>
    );
  }

  if (
    rowData?.processState === 'PAID' ||
    rowData?.processState === 'ENCRYPTED' ||
    rowData?.processState === 'NOT_EXECUTABLE' ||
    rowData?.processState === 'ENCRYPTED_OR_UNABLE_TO_PROCESS'
  ) {
    return (
      <Tooltip
        title={
          <>
            {rowData?.processState
              ? t?.(
                  `MTD.APP_INVENTORY.TABLE_VIEW.${
                    rowData?.processState.toUpperCase() ?? ''
                  }`
                )
              : null}
          </>
        }
      >
        <span>{t?.('MTD.APP_INVENTORY.TABLE_VIEW.UNAVAILABLE')}</span>
      </Tooltip>
    );
  }
  return <span>{t?.('MTD.APP_INVENTORY.TABLE_VIEW.UNAVAILABLE')}</span>;
};

export const HighestCvssSeverity = (
  rowData: Partial<IAppVersion> | null,
  t?: TFunction<'translation', undefined>
) => {
  if (!!rowData && rowData?.highestCvssSeverity) {
    if (rowData?.processState === 'AVAILABLE') {
      if (rowData?.cves?.length === 0) {
        return <span>{t?.('MTD.APP_INVENTORY.TABLE_VIEW.NO_CVES_FOUND')}</span>;
      }
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {severityByValues(rowData?.highestCvssSeverity ?? '', t)}
        </div>
      );
    }
  } else {
    if (rowData?.processState === 'UNKNOWN' || !rowData?.processState) {
      return <span>{t?.('MTD.APP_INVENTORY.TABLE_VIEW.PROCESSING')}</span>;
    }
    if (
      rowData?.processState === 'PAID' ||
      rowData?.processState === 'ENCRYPTED' ||
      rowData?.processState === 'NOT_EXECUTABLE' ||
      rowData?.processState === 'ENCRYPTED_OR_UNABLE_TO_PROCESS'
    ) {
      return <span>{t?.('MTD.APP_INVENTORY.TABLE_VIEW.NO_CVES_FOUND')}</span>;
    }
    return <span>{t?.('MTD.APP_INVENTORY.TABLE_VIEW.NO_CVES_FOUND')}</span>;
  }
};
