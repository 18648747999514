import { fetchRiskiestDeviceModels as fetchRiskiestDeviceModelsApi } from "api/ZDefendInsights";
import { IRiskiestDeviceModels } from "./models";

export const fetchRiskiestDeviceModels = async (
  teamId?: string | undefined,
  zappId?: string | undefined
): Promise<IRiskiestDeviceModels> => {
  const result: { data: IRiskiestDeviceModels } = await fetchRiskiestDeviceModelsApi({
    teamId,
    zappId
  });

  return result?.data;
};
