import { Card, CardContent } from '@material-ui/core';
import React from 'react';
import useStyles from './useStyles';

const ThreatAlertSkeleton: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.skeletonItem}>
      <Card
        key="location"
        className="is-loading shimmer"
        style={{ height: 100 }}
      >
        <CardContent></CardContent>
      </Card>
    </div>
  );
};

export default ThreatAlertSkeleton;
