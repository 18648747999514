import React, { PureComponent } from 'react';
import withRouter from 'components/hocs/withRouter';

// services
import { deleteSelectedPlan } from 'api/PlansService';

// actions
import { publishEvent } from 'utils/eventUtils';

// components
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ZButton from 'UI/Buttons/ZButton';
import {
  openSnackBar,
  toggleModalDirect,
  toggleModalDiffered,
} from 'utils/storeUtils';

class PlansDeleteConfirm extends PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <DialogContent>
        <p>Are you sure you want to delete the plan?</p>
        <DialogActions>
          <ZButton
            styleName="modalCancel"
            action={toggleModalDiffered('PlansDeleteConfirm', false)}
            color="secondary"
            buttonText="Cancel"
          />
          <ZButton
            action={this.handleSubmit}
            buttonType="submit"
            color="primary"
            styleName="modalSave"
            buttonText="Delete Plan"
          />
        </DialogActions>
      </DialogContent>
    );
  }

  handleSubmit() {
    const { props } = this;

    deleteSelectedPlan({
      planId: props.data.planId,
    })
      .then(() => {
        toggleModalDirect('PlansDeleteConfirm', false);
        openSnackBar('Plan successfully deleted');

        if (!props.redirectToEdit) {
          return publishEvent('table:force-fetch-plans');
        }

        return props.history.replace('/admin/plans/edit');
      })
      .catch(error => {
        toggleModalDirect('PlansDeleteConfirm', false);
        openSnackBar(`Failed to delete plan: ${error.response.data}`);
        console.log(error);
      });
  }
}

PlansDeleteConfirm.defaultProps = {
  data: {},
};

export default withRouter(PlansDeleteConfirm);
