import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      lineHeight: 1.4,
    },
    labelCell: {
      fontWeight: 'bold',
      whiteSpace: 'pre',
      verticalAlign: 'top',
      '& > div': {
        paddingRight: '15px',
      },
    },
    valueField: {
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'flex-start',
      paddingRight: 15,
    },
  })
);

export default useStyles;
