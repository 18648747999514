import { IDeviceAlertData } from './Form/models';
import { withStyles } from '@material-ui/core/styles';
import Form from './Form';
import React from 'react';
import styles from './styles';
import withRouter from 'components/hocs/withRouter';

interface IDeviceAlertProps {
  data: Partial<IDeviceAlertData>;
}

const DeviceAlert: React.FC<IDeviceAlertProps> = ({ data }) => {
  const handleSubmitForm = () => {
    console.log('handle submit');
  };

  return <Form data={data} handleSubmitForm={handleSubmitForm} />;
};

export default withStyles(styles)(withRouter(DeviceAlert));
