import { ISelectItem } from 'components/UI/input/Select';
import { atom } from 'recoil';

export const selectedTeam = atom<string | null>({
  key: 'selectedTeam',
  default: null,
});

export const availableTeams = atom<ISelectItem[]>({
  key: 'teams-availableTeams',
  default: [],
});
