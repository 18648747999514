import React, { PureComponent } from 'react';
import _ from 'lodash';

// services
import { acceptInstances, unacceptInstances } from 'api/zDevService';
import { PubSub, publishEvent } from '../../utils/eventUtils';
import { toggleModalDirect, openSnackBar } from 'utils/storeUtils';

// components
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ZButton from 'UI/Buttons/ZButton';

// utils
import { cleanAcceptedInstancesPayload } from 'utils/findingsUtils';

class InstancesStatusChangeConfirm extends PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const { props } = this;

    if (_.isEmpty(props.data.checkedLocations)) {
      return (
        <DialogContent>
          <p>Please select an Instance.</p>
          <DialogActions>
            <ZButton
              styleName="modalCancel"
              action={this.handleCancel}
              color="secondary"
              buttonText="Close"
            />
          </DialogActions>
        </DialogContent>
      );
    }
    return (
      <DialogContent>
        <p>
          Are you sure you want to {props.data.status} the selected instances?
        </p>

        <DialogActions>
          <ZButton
            styleName="modalCancel"
            action={this.handleCancel}
            color="secondary"
            buttonText="Cancel"
          />
          <ZButton
            action={this.handleSubmit}
            buttonType="submit"
            color="primary"
            styleName="modalSave"
            buttonText={_.get(props, 'data.status', 'OK')}
          />
        </DialogActions>
      </DialogContent>
    );
  }

  handleCancel() {
    toggleModalDirect('InstancesStatusChangeConfirm', false);
    PubSub.publish('INSTANCES_ACTION_CANCELLED');
  }

  handleSubmit() {
    const { props } = this;
    if (props.data.status === 'ACCEPT') {
      const payload = cleanAcceptedInstancesPayload(
        _.get(props, 'data.checkedLocations', []),
        _.get(props, 'data.appId', null)
      );
      acceptInstances({}, payload)
        .then(() => {
          toggleModalDirect('InstancesStatusChangeConfirm', false);
          openSnackBar('Instance(s) successfully accepted');
          publishEvent('table:force-fetch-instances');
          PubSub.publish('INSTANCES_UPDATED');
        })
        .catch(error => {
          toggleModalDirect('InstancesStatusChangeConfirm', false);
          openSnackBar(
            `Failed to accept Instance(s) - Error: ${_.get(
              error,
              'response.data.message'
            )}`
          );
        });
    }

    if (props.data.status === 'UNACCEPT') {
      const payload = cleanAcceptedInstancesPayload(
        _.get(props, 'data.checkedLocations', []),
        _.get(props, 'data.appId', null)
      );
      unacceptInstances({}, payload)
        .then(() => {
          toggleModalDirect('InstancesStatusChangeConfirm', false);
          openSnackBar('Instance(s) successfully unaccepted');
          publishEvent('table:force-fetch-instances');
          PubSub.publish('INSTANCES_UPDATED');
        })
        .catch(error => {
          toggleModalDirect('InstancesStatusChangeConfirm', false);
          openSnackBar(
            `Failed to unaccept Instance(s) - Error: ${_.get(
              error,
              'response.data.message'
            )}`
          );
        });
    }
  }
}

InstancesStatusChangeConfirm.defaultProps = {
  data: {},
};

export default InstancesStatusChangeConfirm;
