import React, { useMemo } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import cc from 'classcat';

import AndroidIcon from 'components/UI/icons/AndroidIcon';
import AppleIcon from 'components/UI/icons/AppleIcon';
import ChromeOSIcon from 'components/UI/icons/ChromeOSIcon';
import { OS_NAMES } from 'components/main/devices/deviceMappings';
import { TClassList } from 'components/UI/Table/models';

interface IOSLabelIconProps {
  os: string;
  classList?: TClassList;
}

const OSLabelIcon: React.FC<IOSLabelIconProps> = ({ os, classList }) => {
  const osLabelIconClasses = useStyles();

  const classes = useMemo(() => {
    return { ...osLabelIconClasses, ...classList };
  }, [osLabelIconClasses, classList]);

  if (!os) {
    return null;
  }

  const osLowerCaseName = useMemo(() => os?.toLowerCase() ?? '', [os]);
  const osName = useMemo(
    () => OS_NAMES?.[osLowerCaseName] ?? '',
    [osLowerCaseName]
  );
  const icon = useMemo(
    () =>
      osLowerCaseName === 'ios' ? (
        <AppleIcon classList={classes} />
      ) : osLowerCaseName === 'chrome os' || osLowerCaseName === 'chrome_os' ? ( // remove double check when BE renames all references of 'chrome_os' to 'chrome os' or viceversa
        <ChromeOSIcon classList={classes} />
      ) : (
        <AndroidIcon classList={classes} />
      ),
    [osLowerCaseName, classes]
  );

  return (
    <div className="table__os-icon flex--spaced">
      {icon}
      <div className={cc([classes.label, 'table-cell__ellipsis'])}>
        {osName}
      </div>
    </div>
  );
};

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    label: {
      paddingLeft: 5,
      color: palette.components.globalHeader.contrastText,
    },
  })
);

export default OSLabelIcon;
