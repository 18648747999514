import GenericCard from 'components/UI/GenericCard';
import AppleIcon from 'components/UI/icons/AppleIcon';
import CircleOutlineIcon from 'components/UI/icons/CircleOutlineIcon';
import ProtectedLink from 'components/main/protected/ProtectedLink';
import { IStatusItem } from '../DashboardTab/models';
import useStyles from './useStyles';

interface IOSDevicesVulnerableProps {
  title: string;
  items: IStatusItem[];
}

const OSDevicesVulnerable: React.FC<IOSDevicesVulnerableProps> = ({
  title,
  items,
}) => {
  const classes = useStyles();

  const BigAppleIcon = () => {
    return (
      <div className={classes.iosIcon}>
        <AppleIcon classList={classes} />
      </div>
    );
  };

  return (
    <GenericCard title={title} classTitle={classes.title} noPadding>
      <div className={classes.contentContainer}>
        {items.map(
          (
            { icon, value = 0, label, linkUrl, mutedIconColor, borderColor },
            index
          ) => (
            <div className={classes.itemContainer}>
              <ProtectedLink
                allow={{ devices: 'view' }}
                key={index}
                to={linkUrl}
              >
                <div className={classes.itemContents}>
                  <CircleOutlineIcon
                    Icon={icon.name === 'AppleIcon' ? BigAppleIcon : icon}
                    borderColor={borderColor}
                    mutedIconColor={mutedIconColor}
                  />
                  <span className={classes.value}>
                    {value?.toLocaleString('en-US') ?? ''}
                  </span>
                  <span className={classes.label}>{label}</span>
                </div>
              </ProtectedLink>
            </div>
          )
        )}
      </div>
    </GenericCard>
  );
};

export default OSDevicesVulnerable;
