import useStyles from './useStyles';
import EditableTable from 'components/UI/EditableTable';
import {
  IEditableRowMapping,
  TEditableRowData,
} from 'components/UI/EditableTable/models';
import GenericError from 'components/UI/GenericErrorBox';
import {
  IGenericColumnHeader,
  TRowData,
} from 'components/UI/GenericTable/models';
import { useState, useMemo, useCallback } from 'react';
import { CONTROL_VALUE_OPTIONS } from '../../../models';
import { zScanTranscriptParser } from '../utils';


const headers: IGenericColumnHeader[] = [
  { id: 'ignore', label: 'Ignore' },
  { id: 'uiScreenId', label: 'UI Frame ID' },

  { id: 'controlId', label: 'UI Element ID' },
  { id: 'controlType', label: 'Control Type' },

  { id: 'controlValue', label: 'Value' },
  { id: 'order', label: 'Order' }
];

const rowMappings: IEditableRowMapping[] = [
  {
    path: 'ignore',
    alwaysEnabled: true,
    inputType: 'checkbox',
  },
  {
    path: 'uiScreenId',
    inputType: 'text',
  },
  {
    path: 'controlId',
    inputType: 'text',
  },
  {
    path: 'controlType',
    alwaysDisplayInput: true,
    columnToNotify: 'value',
    inputType: 'select',
    options: CONTROL_VALUE_OPTIONS,
  },
  {
    path: 'controlValue',
    inputType: 'text',
  },
  {
    path: 'order',
    inputType: 'number',
    min: 0,
    max: 50
  },
];



interface IExampleTableProps {
  rawJSONZScanTranscript: string;
  handleFormData: (value: string) => void;
}

const ZScanTranscriptTable: React.FC<IExampleTableProps> = ({ rawJSONZScanTranscript, handleFormData }) => {

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const classes = useStyles();
  const parsedTableData = useMemo(() => {
    setErrorMessage(undefined);
    return zScanTranscriptParser(rawJSONZScanTranscript);
  }, [rawJSONZScanTranscript]);

  const handleUpdateRow = useCallback((rowData: TRowData | Partial<TRowData>) => {
    let newRowData;
    if (!rowData.id) {
      newRowData = parsedTableData.push({ ...rowData, id: parsedTableData?.length + 1 });
      handleFormData(JSON.stringify(newRowData));
    }
    newRowData = parsedTableData.map((existingRowData: TRowData) => {
      if (rowData.id === existingRowData.id) {
        return { ...rowData };
      }
      return { ...existingRowData };
    });
    handleFormData(JSON.stringify(newRowData));
  }, [handleFormData, parsedTableData]);

  const handleDeleteRow = useCallback((rowDataId: string) => {
    const parsedTableWithRemovedRow = parsedTableData.filter((fullRowData: TRowData) => fullRowData.id !== rowDataId);
    handleFormData(JSON.stringify(parsedTableWithRemovedRow));
  }, [handleFormData, parsedTableData]);

  return (
    <div className={classes.tableWrapper}>
      <EditableTable
        showNoDataAddButton={false}
        columnHeaders={headers}
        onCreate={(rowData: Partial<TEditableRowData>) => handleUpdateRow(rowData)}
        onDelete={(rowData: TEditableRowData) => handleDeleteRow(rowData.id)}
        onUpdate={(rowData: TEditableRowData) => handleUpdateRow(rowData)}
        rowMapping={rowMappings}
        tableData={parsedTableData?.length ? parsedTableData.map((rowData: TRowData) => {
          return {
            ...rowData,
          };
        }) : []}
        tableId="ZScanTranscripts"
      />
      <GenericError errorMessage={errorMessage} />
    </div>
  );
};

export default ZScanTranscriptTable;