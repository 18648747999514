import { TFunction } from 'react-i18next';
import {
  csvInjectionErrorMessageLocalized,
  csvInjectionRegex,
} from 'utils/componentUtils';
import * as Yup from 'yup';

const Schema = (t: TFunction<'translation', undefined>) => {
  const fields = {
    name: Yup.string()
      .min(1)
      .matches(csvInjectionRegex, t(csvInjectionErrorMessageLocalized))
      .required(t('MTD.VALIDATIONS.NAME_IS_REQUIRED')),
    teamId: Yup.string().test('team', 'Team required.', (value, ctx) => {
      if (!!ctx?.parent?.selectedTeamId) {
        return true;
      }

      if (ctx?.parent?.bounds === 'global') {
        return true;
      }

      return !!value;
    }),
  };

  return Yup.object().shape({ ...fields });
};

export default Schema;
