import { ServiceHandlerFactory } from '../utils/serviceUtils';

const subscriptionsBaseUrl = 'api/subscriptions/v1/subscriptions';

export const checkForSubscription = ServiceHandlerFactory({
  axios: {
    url: `${subscriptionsBaseUrl}`,
    method: 'post',
  },
});

export const verifyMfa = ServiceHandlerFactory({
  axios: {
    url: `${subscriptionsBaseUrl}/verifyMfa`,
    method: 'post',
  },
});

export const resendMfa = ServiceHandlerFactory({
  axios: {
    url: `${subscriptionsBaseUrl}/resendMfa`,
    method: 'post',
  },
});

export const subscribe = ServiceHandlerFactory({
  axios: {
    url: `${subscriptionsBaseUrl}/subscribe`,
    method: 'post',
  },
});

export const resendEmail = ServiceHandlerFactory({
  axios: {
    url: `${subscriptionsBaseUrl}/resendEmail`,
    method: 'post',
  },
});

export const deleteSubscription = ServiceHandlerFactory({
  axios: {
    url: `${subscriptionsBaseUrl}/:externalId`,
    method: 'delete',
  },
});
