import { getActiveModalAtom } from 'atoms/modals';
import Button from 'components/UI/Button';
import GenericTable from 'components/UI/GenericTable';
import Modal from 'components/UI/Modal';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { IViewAppConfig } from './models';
import useStyles from './useStyles';
import { headers, rowMapping } from './ViewAppConfig.mappings';
import { useTranslation } from 'react-i18next';

export const ViewAppConfig_TITLE = 'ViewAppConfig';

const activeModalAtom = getActiveModalAtom<IViewAppConfig>();

const ViewAppConfig: React.FC = () => {
  const [activeModal, setActiveModal] = useRecoilState(activeModalAtom);
  const classes = useStyles();
  const { t, ready } = useTranslation();

  const handleClose = useCallback(() => {
    setActiveModal(undefined);
  }, [setActiveModal]);
  if (!ready) {
    return null;
  }

  return (
    <>
      <Modal
        caption={t('MTD.INTEGRATIONS.KEY_VALUE_PAIR')}
        className={classes.modal}
        onClose={handleClose}
        title={ViewAppConfig_TITLE}
      >
        <p className={classes.note}>
          {t('MTD.INTEGRATIONS.KEY_VALUE_PAIR_DESCRIPTION')}
        </p>
        <div className={classes.table}>
          <GenericTable
            columnHeaders={headers(t)}
            rowMapping={rowMapping}
            tableData={activeModal?.payload?.appConfiguration ?? []}
            tableId="ViewAppConfig"
          />
        </div>
        <div className={classes.buttons}>
          <Button
            color="secondary"
            onClick={handleClose}
            text={t('GLOBAL.CLOSE')}
          />
        </div>
      </Modal>
    </>
  );
};

export default ViewAppConfig;
