import useStyles from './useStyles';
import AndroidIcon from '@material-ui/icons/Android';
import { TClassList } from 'components/UI/Table/models';

export interface IAndroidIcon {
  classList?: TClassList;
}

const AndroidIconComponent: React.FC<IAndroidIcon> = ({ classList }) => {
  const androidIconClasses = useStyles();

  const classes = { ...androidIconClasses, ...classList };
  return <AndroidIcon className={classes.androidIcon} />;
};

export default AndroidIconComponent;
