import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import withRouter from 'components/hocs/withRouter';
import Table from 'components/RDGTables';
import _ from 'lodash';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  dataExportTableColumnChange,
  updateUISettings,
} from 'reducers/UiSettingsReducers';
import {
  getUISettingsTableHeaders,
  getUISettingsWithout,
} from 'reducers/UiSettingsSelectors';
import { bindActionCreators, compose } from 'redux';
import ZButton from 'UI/Buttons/ZButton';
import ReactToCSV from 'utility/ReactCSVDownloader';
import { toggleModalDiffered } from 'utils/storeUtils';
import useBackfill from 'utils/useBackfill';
import { withDirtyState } from 'utils/withDirtyState';
import withMemoizedProps from 'utils/withMemoizedProps';
import ProtectedComponent from '../../protected/ProtectedComponent';
import { dataExportColumnMapping } from './dataexport.mapping';
import tableQuery from './tableQuery';

const DataExport = ({
  classes,
  classList,
  currentTableHeaders,
  dataExportStore,
  dataExportTableColumnChange,
  dataExportUiSettings,
  definedUser,
  jiggleDirtyState,
  q: query,
  rqps,
  updateUISettings,
  updateUrl,
}) => {
  useBackfill({
    query,
    requiredQueryParams: rqps,
    storedQueryParams: dataExportUiSettings,
    updateUrl: updateUrl,
  });

  useEffect(() => {
    if (
      _.hasIn(query, 'tenant') &&
      _.get(dataExportStore, 'microsoft_login_pending') === true
    ) {
      setTimeout(
        toggleModalDiffered(
          'DataExportCreateEdit',
          {
            tenantID: _.get(query, 'tenant'),
            formState: _.get(dataExportStore, 'formState'),
          },
          {
            title: 'Add Data Export Configuration',
            fullWidth: true,
          }
        ),
        1000
      );
    }
  }, [dataExportStore, query]);

  const handleColumnChange = (...args) => {
    jiggleDirtyState();
    dataExportTableColumnChange(...args);
  };

  const onPaginationChange = (update) => {
    updateUISettings({
      domain: 'dataExport',
      update,
    });

    updateUrl(update);
  };

  return (
    <div>
      <div className="view__header">
        <h1>Data Export</h1>
        <ProtectedComponent allow={{ data_exports: 'manage' }}>
          <ZButton
            styleName="titleBar"
            action={toggleModalDiffered(
              'DataExportCreateEdit',
              {},
              {
                title: 'Add Data Export Configuration',
                fullWidth: true,
              }
            )}
            buttonText="Add Data Export Configuration"
            color="secondary"
            icon={{ elem: AddIcon }}
            proLabel
          />
        </ProtectedComponent>
      </div>
      <Table
        classes={classes}
        classList={classList}
        columnHeaders={currentTableHeaders}
        footerComponents={() => (
          <ReactToCSV
            buttonText="exportCSV"
            dataMap={dataExportColumnMapping}
            fileName="dataexport-table"
            header={currentTableHeaders.filter(
              (column) => column.id !== 'actions'
            )}
            table={() => tableQuery(query, true)}
          />
        )}
        definedUser={definedUser}
        fetchTableData={() => tableQuery(query)}
        isSuperUser={'superAdminUser' === definedUser}
        onColumnChange={handleColumnChange}
        onPaginationChange={onPaginationChange}
        query={query}
        rowMapping={dataExportColumnMapping}
        tableId={'DataExport'}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  createEditModal: _.get(state, 'ui.modals.DataExportCreateEdit'),
  currentTableHeaders: getUISettingsTableHeaders(state, 'dataExport'),
  dataExportStore: state.dataExportStore,
  dataExportUiSettings: getUISettingsWithout(state, 'dataExport', [
    'tableHeaders',
  ]),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUISettings,
      dataExportTableColumnChange,
    },
    dispatch
  );

const styles = () => ({
  actionIcon: {
    cursor: 'pointer',
  },

  editLink: {
    textDecoration: 'none',
    color: 'inherit',
  },

  statusCircle: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    marginLeft: 12,
  },
});

export default compose(
  withStyles(styles),
  withRouter,
  withDirtyState(),
  connect(mapStateToProps, mapDispatchToProps)
)(
  withMemoizedProps(DataExport, [
    'createEditModal',
    'currentTableHeaders',
    'dataExportStore',
    'dataExportUiSettings',
    'dirtyState',
    'q',
    'updateUISettings',
  ])
);
