import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

export default state => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,

      devices: {
        ...uiSettingsDefaults.devices,
        tableHeaders: uiSettingsDefaults.devices.tableHeaders,
      },
      deviceActivations: {
        ...uiSettingsDefaults.deviceActivations,
        tableHeaders: uiSettingsDefaults.deviceActivations.tableHeaders,
      },
      groupActivations: {
        ...uiSettingsDefaults.groupActivations,
        tableHeaders: uiSettingsDefaults.groupActivations.tableHeaders,
      },
    },
  };
};
