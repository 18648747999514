import { getActiveModalAtom } from 'atoms/modals';
import Button from 'components/UI/Button';
import Modal from 'components/UI/Modal';
import AndroidIconComponent from 'components/UI/icons/AndroidIcon';
import AppleIcon from 'components/UI/icons/AppleIcon';
import ChromeOSIcon from 'components/UI/icons/ChromeOSIcon';
import CircleOutlineIcon from 'components/UI/icons/CircleOutlineIcon';
import KnoxIcon from 'components/UI/icons/KnoxIcon';
import Checkbox from 'components/UI/input/Checkbox';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import ResponseTypeMapping from 'utility/ResponseTypeMapping';
import { compareSelectedWithBase } from 'utility/ResponseTypesUtilities';
import {
  IOSResponse,
  IOSResponseTypes,
  ISelectTRMPolicyTableData,
  PolicyChangeOptions,
} from '../../models';
import useStyles from './useStyles';

export interface IOSResponseTypesModalData {
  handleChange: (
    optionName: PolicyChangeOptions,
    value: unknown,
    rule: ISelectTRMPolicyTableData
  ) => void;
  rowData: ISelectTRMPolicyTableData;
  responseTypes: IOSResponseTypes | undefined;
}

export interface IOSResponseTypesModalProps {
  data: IOSResponseTypesModalData;
}

export const OSResponseTypesModal_TITLE = 'OSResponseTypes';
const activeModalAtom = getActiveModalAtom<IOSResponseTypesModalData>();

const OSResponseTypesModal: React.FC = () => {
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const activeModal = useRecoilState(activeModalAtom)[0];
  const setActiveModal = useSetRecoilState(activeModalAtom);

  const [chosenOsResponseTypes, setChosenOsResponseTypes] = useState<
    IOSResponseTypes | undefined
  >(undefined);

  const handleCheckboxChange = useCallback(
    (bucket: string, os: string | number, optionChanged: IOSResponse) =>
      (e: ChangeEvent<HTMLInputElement>) => {
        const newChosenOsResponseTypes = {
          ...(chosenOsResponseTypes as IOSResponseTypes),
        };

        if (e.target.checked === false) {
          delete newChosenOsResponseTypes[bucket][optionChanged.id];
          setChosenOsResponseTypes(newChosenOsResponseTypes);
        }

        if (e.target.checked === true) {
          newChosenOsResponseTypes[bucket] = {
            ...newChosenOsResponseTypes[bucket],
            ...{
              [optionChanged.id]: {
                os,
                responseId: optionChanged.id,
                ...optionChanged,
              },
            },
          };
          setChosenOsResponseTypes(newChosenOsResponseTypes);
        }
      },
    [chosenOsResponseTypes]
  );

  const handleSubmit = useCallback(() => {
    activeModal?.payload?.handleChange(
      // This is wrong, but neccessary for now to prevent
      // causing unforseen consequences in other components
      // that consume PolicyChangeOptions (and there are numerous)
      // Remove this later by adding 'responses' to PolicyChangeOptions
      // or consider using a different key for this action - RL
      'responses' as PolicyChangeOptions,
      chosenOsResponseTypes
        ? Object.keys(chosenOsResponseTypes).reduce(
            (acc, cur) => [
              ...acc,
              ...Object.keys(chosenOsResponseTypes[cur]).map(
                (value) => chosenOsResponseTypes[cur][value]
              ),
            ],
            [] as unknown[]
          )
        : [],
      activeModal?.payload?.rowData
    );
    setActiveModal({
      active: undefined,
    });
  }, [activeModal?.payload, setActiveModal, chosenOsResponseTypes]);

  const handleCancel = useCallback(() => {
    setActiveModal({
      active: undefined,
    });
  }, [setActiveModal]);

  useEffect(() => {
    if (activeModal?.payload?.rowData?.responses) {
      setChosenOsResponseTypes(
        activeModal?.payload?.rowData?.responses
          ? (compareSelectedWithBase(
              activeModal?.payload?.rowData?.responses
            ) as IOSResponseTypes)
          : undefined
      );
    }

    return () => {
      if (!activeModal?.payload?.responseTypes) {
        setChosenOsResponseTypes(undefined);
      }
    };
  }, [
    activeModal?.payload?.rowData?.responses,
    activeModal?.payload?.responseTypes,
  ]);

  if (!ready || !activeModal?.payload?.responseTypes) {
    return null;
  }

  return (
    <Modal
      title={OSResponseTypesModal_TITLE}
      caption={t('GLOBAL.DEVICE_ACTIONS')}
      className={classes.modal}
    >
      <div className={classes.content}>
        <div className={classes.column}>
          <div className={classes.os}>
            <div className={classes.title}>
              <CircleOutlineIcon
                Icon={() => <AppleIcon classList={classes} />}
                borderColor="blue"
                noBackground
              />
              <h3>{t('GLOBAL.IOS')}</h3>
            </div>
            <ul>
              {Object.keys(
                activeModal?.payload?.responseTypes?.iosBucket ?? {}
              ).map((option) => (
                <li>
                  <Checkbox
                    color="primary"
                    label={t(
                      (ResponseTypeMapping as { [key: number]: string })[
                        option as unknown as number
                      ]
                    )}
                    onChange={handleCheckboxChange(
                      'iosBucket',
                      2,
                      activeModal?.payload?.responseTypes?.iosBucket[
                        option
                      ] as unknown as IOSResponse
                    )}
                    checked={!!chosenOsResponseTypes?.iosBucket?.[option]}
                  >
                    {(option as unknown as IOSResponse).id}
                  </Checkbox>
                </li>
              ))}
            </ul>
          </div>
          <div className={classes.os}>
            <div className={classes.title}>
              <CircleOutlineIcon
                Icon={() => <AndroidIconComponent classList={classes} />}
                borderColor="blue"
                noBackground
              />
              <h3>{t('GLOBAL.ANDROID')}</h3>
            </div>
            <ul>
              {Object.keys(
                activeModal?.payload?.responseTypes?.androidBucket ?? {}
              ).map((option) => (
                <li>
                  <Checkbox
                    color="primary"
                    label={t(
                      (ResponseTypeMapping as { [key: number]: string })[
                        option as unknown as number
                      ]
                    )}
                    onChange={handleCheckboxChange(
                      'androidBucket',
                      1,
                      activeModal?.payload?.responseTypes?.androidBucket[
                        option
                      ] as unknown as IOSResponse
                    )}
                    checked={!!chosenOsResponseTypes?.androidBucket?.[option]}
                  >
                    {(option as unknown as IOSResponse).id}
                  </Checkbox>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={classes.column}>
          <div className={classes.os}>
            <div className={classes.title}>
              <CircleOutlineIcon
                Icon={() => <KnoxIcon classList={classes} />}
                borderColor="blue"
                noBackground
              />
              <h3>{t('GLOBAL.SAMSUNG_KNOX')}</h3>
            </div>
            <ul>
              {Object.keys(
                activeModal?.payload?.responseTypes?.knoxBucket ?? {}
              ).map((option) => (
                <li>
                  <Checkbox
                    color="primary"
                    label={t(
                      (ResponseTypeMapping as { [key: number]: string })[
                        option as unknown as number
                      ]
                    )}
                    onChange={handleCheckboxChange(
                      'knoxBucket',
                      1,
                      activeModal?.payload?.responseTypes?.knoxBucket[
                        option
                      ] as unknown as IOSResponse
                    )}
                    checked={!!chosenOsResponseTypes?.knoxBucket?.[option]}
                  >
                    {(option as unknown as IOSResponse).id}
                  </Checkbox>
                </li>
              ))}
            </ul>
          </div>
          <div className={classes.os}>
            <div className={classes.title}>
              <CircleOutlineIcon
                Icon={() => <ChromeOSIcon classList={classes} />}
                borderColor="blue"
                noBackground
              />
              <h3>{t('GLOBAL.CHROME_EXTENSIONS')}</h3>
            </div>
            <ul>
              {Object.keys(
                activeModal?.payload?.responseTypes?.extensionsBucket ?? {}
              ).map((option) => (
                <li>
                  <Checkbox
                    color="primary"
                    label={t(
                      (ResponseTypeMapping as { [key: number]: string })[
                        option as unknown as number
                      ]
                    )}
                    onChange={handleCheckboxChange(
                      'extensionsBucket',
                      1,
                      activeModal?.payload?.responseTypes?.extensionsBucket[
                        option
                      ] as unknown as IOSResponse
                    )}
                    checked={
                      !!chosenOsResponseTypes?.extensionsBucket?.[option]
                    }
                  >
                    {(option as unknown as IOSResponse).id}
                  </Checkbox>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={classes.footer}>
          <Button
            className={classes.buttonBackgroundColor}
            onClick={handleCancel}
            text={t('GLOBAL.CANCEL')}
            type="button"
          />
          <Button
            color="secondary"
            onClick={handleSubmit}
            text={t('GLOBAL.SAVE')}
            type="button"
          />
        </div>
      </div>
    </Modal>
  );
};

export default OSResponseTypesModal;
