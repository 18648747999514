import MUIBuildIcon from '@material-ui/icons/Build';
import { getActiveModalAtom } from 'atoms/modals';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import {
  IOSResponseTypes,
  ISelectTRMPolicyTableData,
  PolicyChangeOptions,
} from '../models';
import useStyles from '../useStyles';
import {
  IOSResponseTypesModalData,
  OSResponseTypesModal_TITLE,
} from './ResponseTypesModal';

interface IMtdDeviceActionsProps {
  rowData: ISelectTRMPolicyTableData;
  osResponseTypes?: IOSResponseTypes;
  handleTRMPolicyRuleChange: (
    optionName: PolicyChangeOptions,
    value: unknown,
    rule: ISelectTRMPolicyTableData
  ) => void;
}

const activeModalAtom = getActiveModalAtom<IOSResponseTypesModalData>();

const MtdDeviceActions: React.FC<IMtdDeviceActionsProps> = ({
  rowData,
  osResponseTypes,
  handleTRMPolicyRuleChange,
}) => {
  const classes = useStyles();
  const setActiveModal = useSetRecoilState(activeModalAtom);

  return (
    <MUIBuildIcon
      className={
        rowData.responses.length > 0
          ? classes.toggledMailIcon
          : classes.mailIcon
      }
      onClick={() =>
        setActiveModal(() => ({
          active: OSResponseTypesModal_TITLE,
          payload: {
            rowData,
            responseTypes: osResponseTypes,
            handleChange: handleTRMPolicyRuleChange,
          },
        }))
      }
    />
  );
};

export default React.memo(MtdDeviceActions);
