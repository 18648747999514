import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import MaterialTab from '@material-ui/core/Tab';
import MaterialTabs from '@material-ui/core/Tabs';
import withRouter from 'components/hocs/withRouter';
import AppPolicy from 'components/main/policies/appPolicy/AppPolicy';
import AppSettings from 'components/main/policies/appsettings/AppSettings';
import BrandingPolicy from 'components/main/policies/branding/BrandingPolicy';
import Groups from 'components/main/policies/groups/Groups';
import NetworkPolicy from 'components/main/policies/network/NetworkPolicy';
import PhishingPolicy from 'components/main/policies/phishing/PhishingPolicy';
import PrivacyPolicy from 'components/main/policies/privacy/PrivacyPolicy';
import TRMPolicy from 'components/main/policies/threats/TRMPolicy';
import { useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { selectIsBrandingSubscribed } from 'reducers/AuthReducers';
import ProtectedRoute from 'routes/ProtectedRoute';
import DormancyPolicy from './DormancyPolicy';
import MtdGroups from './groups/MtdGroups';
import OsRiskPolicy from './osRisk/OsRiskPolicy';
import { useTranslation } from 'react-i18next';
import { AppCharacteristicsProvider } from './appPolicy/AppCharacteristicProvider';

const Policies = withStyles({
  tabsAppBar: {
    marginTop: 15,
    marginBottom: 15,
    background: 'none',
    boxShadow: 'none',
    position: 'relative',
    zIndex: 1,
    '& > div': {
      '& > div': {
        overflow: 'auto !important',
      },
    },
  },
})(({ match, ...props }) => {
  const mtdModule = props.module === 'mtd';
  const { scopes } = useSelector(({ auth }) => auth.user);
  const isBrandingSubscribed = useSelector(selectIsBrandingSubscribed);
  const { t, ready } = useTranslation();

  if (!ready) {
    return null;
  }

  return (
    <div>
      <AppBar position="static" className={props.classes.tabsAppBar}>
        <MaterialTabs value={match.params.tabId}>
          <MaterialTab
            className="tabs-label"
            component={Link}
            disableRipple
            key="policies-10"
            label={t('GLOBAL.GROUPS')}
            to={`/console/${mtdModule ? 'mtd' : 'zdefend'}/policies/groups`}
            value="groups"
          />
          {scopes.policies && (
            <MaterialTab
              className="tabs-label"
              component={Link}
              disableRipple
              key="policies-20"
              label={t('MTD.POLICIES.PRIVACY')}
              to={`/console/${mtdModule ? 'mtd' : 'zdefend'}/policies/privacy`}
              value="privacy"
            />
          )}
          {scopes.policies && (
            <MaterialTab
              className="tabs-label"
              component={Link}
              disableRipple
              key="policies-30"
              label={t('GLOBAL.THREAT')}
              to={`/console/${mtdModule ? 'mtd' : 'zdefend'}/policies/trm`}
              value="trm"
            />
          )}

          {scopes.phishing && (
            <MaterialTab
              className="tabs-label"
              component={Link}
              disableRipple
              key="policies-40"
              label={t('GLOBAL.PHISHING')}
              to={`/console/${mtdModule ? 'mtd' : 'zdefend'}/policies/phishing`}
              value="phishing"
              allow={{ policies: 'view' }}
            />
          )}
          {scopes.app_settings && (
            <MaterialTab
              className="tabs-label"
              component={Link}
              disableRipple
              key="policies-50"
              label={t('GLOBAL.APP_SETTINGS')}
              value="appsettings"
              to={`/console/${
                mtdModule ? 'mtd' : 'zdefend'
              }/policies/appsettings`}
              allow={{ app_settings: 'view' }}
            />
          )}
          <MaterialTab
            className="tabs-label"
            component={Link}
            disableRipple
            key="policies-60"
            label={t('MTD.INSIGHTS.APP_POLICY')}
            value="apps"
            to={`/console/${mtdModule ? 'mtd' : 'zdefend'}/policies/apps`}
            allow={{ policies: 'view' }}
          />
          {mtdModule && (
            <MaterialTab
              className="tabs-label"
              component={Link}
              disableRipple
              key="policies-70"
              label={t('GLOBAL.NETWORK_POLICY')}
              value="network"
              to={`/console/${mtdModule ? 'mtd' : 'zdefend'}/policies/network`}
              allow={{ policies: 'view' }}
            />
          )}
          {mtdModule && scopes.policies && (
            <MaterialTab
              className="tabs-label"
              component={Link}
              disableRipple
              key="policies-80"
              label={t('GLOBAL.DEVICE_INACTIVITY')}
              value="dormancy"
              to={`/console/mtd/policies/dormancy`}
            />
          )}
          {mtdModule && isBrandingSubscribed && scopes.policies && (
            <MaterialTab
              className="tabs-label"
              component={Link}
              disableRipple
              key="policies-90"
              label={t('MTD.POLICIES.MOBILE_APP_BRANDING')}
              value="branding"
              to={`/console/mtd/policies/branding`}
            />
          )}
          {scopes.policies && (
            <MaterialTab
              className="tabs-label"
              component={Link}
              disableRipple
              key="policies-100"
              label={t('GLOBAL.OS_RISK')}
              value="osrisk"
              to={`/console/${mtdModule ? 'mtd' : 'zdefend'}/policies/osrisk`}
            />
          )}
        </MaterialTabs>
      </AppBar>
      {renderSubRoute(match.params.tabId, mtdModule)}
    </div>
  );
});

function renderSubRoute(tabId, mtdModule) {
  switch (tabId) {
    case 'apps':
      return (
        <AppCharacteristicsProvider>
          <ProtectedRoute
            allow={{ policies: 'view' }}
            component={AppPolicy}
            mtdModule={mtdModule}
          />
        </AppCharacteristicsProvider>
      );
    case 'appsettings':
      return (
        <ProtectedRoute
          allow={{ app_settings: 'view' }}
          component={AppSettings}
          mtdModule={mtdModule}
        />
      );
    case 'groups':
      return (
        <ProtectedRoute
          allow={{ policies: 'view' }}
          component={mtdModule ? MtdGroups : Groups}
          rqps={['page', 'size', 'orderBy', 'order']}
        />
      );
    case 'phishing':
      return (
        <ProtectedRoute
          allow={{ policies: 'view' }}
          component={PhishingPolicy}
          mtdModule={mtdModule}
        />
      );
    case 'privacy':
      return (
        <ProtectedRoute
          allow={{ policies: 'view' }}
          mtdModule={mtdModule}
          component={PrivacyPolicy}
        />
      );
    case 'trm':
      return (
        <ProtectedRoute
          allow={{ policies: 'view' }}
          mtdModule={mtdModule}
          component={TRMPolicy}
        />
      );
    case 'dormancy':
      return (
        <ProtectedRoute
          allow={{ policies: 'view' }}
          mtdModule={mtdModule}
          component={DormancyPolicy}
        />
      );
    case 'network':
      return (
        <ProtectedRoute
          allow={{ policies: 'view' }}
          mtdModule={mtdModule}
          component={NetworkPolicy}
        />
      );
    case 'branding':
      return (
        <ProtectedRoute
          allow={{ policies: 'view' }}
          mtdModule={mtdModule}
          component={BrandingPolicy}
        />
      );
    case 'osrisk':
      return (
        <ProtectedRoute
          allow={{ policies: 'view' }}
          mtdModule={mtdModule}
          component={OsRiskPolicy}
        />
      );
    default:
      return (
        <Redirect
          to={`/console/${mtdModule ? 'mtd' : 'zdefend'}/policies/groups`}
        />
      );
  }
}

export default withRouter(Policies);
