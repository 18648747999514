import * as Yup from 'yup';
import {
  csvInjectionRegex,
  csvInjectionErrorMessageLocalized,
} from 'utils/componentUtils';
import { TFunction } from 'react-i18next';

const Schema = (t: TFunction<'translation', undefined>) => {
  return Yup.object().shape({
    name: Yup.string()
      .matches(csvInjectionRegex, t(csvInjectionErrorMessageLocalized))
      .required(t('MTD.VALIDATIONS.NAME_IS_REQUIRED')),
    description: Yup.string().matches(
      csvInjectionRegex,
      t(csvInjectionErrorMessageLocalized)
    ),
    teamId: Yup.string().test('team', 'Team required.', (value, ctx) => {
      if (!!ctx?.parent?.selectedTeamId) {
        return true;
      }

      if (ctx?.parent?.bounds === 'global') {
        return true;
      }

      return !!value;
    }),
    selectedPrivacy: Yup.mixed().required(
      t('MTD.VALIDATIONS.PRIVACY_POLICY_REQUIRED')
    ),
    selectedTRM: Yup.mixed().required(
      t('MTD.VALIDATIONS.THREAT_POLICY_REQUIRED')
    ),
  });
};

export default Schema;
