import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    treeItem: {
      color: palette.text.primary,
      fontSize: 14,
    },
    labelValue: {
      color: palette.text.primary,
      fontSize: 14,
      paddingLeft: '2rem',
      width: '75%',
      display: 'flex',
      flexWrap: 'wrap',
      alignSelf: 'center',
    },
  })
);

export default useStyles;
