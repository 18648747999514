import MUIAddIcon from '@material-ui/icons/Add';
import MUISaveIcon from '@material-ui/icons/Check';
import MUICloseIcon from '@material-ui/icons/Close';
import MUIDeleteIcon from '@material-ui/icons/Delete';
import MUIEditIcon from '@material-ui/icons/Edit';
import MUIReorderIcon from '@material-ui/icons/Reorder';
import MUIContentCopyIcon from '@material-ui/icons/FilterNone';
import { TClassList } from '../../Table/models';
import { IActionTypes } from './models';

export const getAction = (type: string, classes: TClassList) => {
  const ACTION_TYPES: IActionTypes = {
    add: {
      color: classes.default,
      icon: <MUIAddIcon />,
    },
    cancel: {
      color: classes.default,
      icon: <MUICloseIcon />,
    },
    delete: {
      color: classes.default,
      icon: <MUIDeleteIcon />,
    },
    edit: {
      color: classes.default,
      icon: <MUIEditIcon />,
    },
    save: {
      color: classes.default,
      icon: <MUISaveIcon />,
    },
    reorder: {
      color: classes.default,
      icon: <MUIReorderIcon />,
    },
    copy: {
      color: classes.default,
      icon: <MUIContentCopyIcon />,
    },
  };

  return Object.keys(ACTION_TYPES).includes(type.toLowerCase())
    ? ACTION_TYPES[type.toLowerCase()]
    : null;
};
