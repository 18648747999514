import React, { useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { IHorizontalLegendItem } from '../../HorizontalLegend/models';
import useStyles from './useStyles';

interface ISegmentProps extends IHorizontalLegendItem {
  index: number;
  scalar: number;
  segments: IHorizontalLegendItem[];
}

const Segment: React.FC<ISegmentProps> = ({
  color,
  index,
  link,
  scalar,
  segments,
  value,
}) => {
  const classes = useStyles();

  const [deepLinked, setDeepLinked] = useState<boolean>(false);

  const redirectMaybe = useMemo(
    () => !!link && deepLinked && <Redirect to={link} />,
    [deepLinked, link]
  );

  const scaledValue = useMemo(() => (value / scalar) * 100, [scalar, value]);

  return (
    <>
      <circle
        className={classes.segment}
        cx="21"
        cy="21"
        fill="none"
        onClick={() => setDeepLinked(true)}
        key={index}
        r="15.91549430918954"
        strokeDasharray={`${scaledValue} ${100 - scaledValue}`}
        strokeDashoffset={`${
          index === 0
            ? 25
            : 100 -
              segments.reduce(
                (a, { value }, i) =>
                  i < index ? a + (value / scalar) * 100 : a,
                0
              ) +
              25
        }`}
        stroke={color}
      />
      {redirectMaybe}
    </>
  );
};

export default Segment;
