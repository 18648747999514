import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

// actions
import { publishEvent } from 'utils/eventUtils';

// components
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ZButton from 'UI/Buttons/ZButton';

// helpers
import {
  openSnackBar,
  toggleModalDiffered,
  toggleModalDirect,
} from 'utils/storeUtils';

// services
import {
  deleteSelectedGroup,
  fetchAttachedZappsByGroupId,
} from 'api/GroupService';
import { fetchAllUserActivations } from 'api/UserActivationsService';
import { fetchAllGroupActivations } from 'api/GroupActivationsService';

class GroupsDeleteConfirm extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      attachedZappList: [],
      isLoaded: false,
      doNotDeleteFlag: false,
    };
  }

  async componentDidMount() {
    const { props } = this;
    const zapps = {};
    let deleteFlag = false;
    if (!_.isEmpty(props.data.groupId)) {
      await fetchAttachedZappsByGroupId({ groupId: props.data.groupId }).then(
        ({ data }) => {
          zapps.apps = data;
          if (!_.isEmpty(data)) {
            deleteFlag = true;
          }
        }
      );
      await fetchAllUserActivations({ groupId: props.data.groupId }).then(
        ({ data }) => {
          if (data.totalElements > 0) {
            deleteFlag = true;
          }
          zapps.userActivations = data.totalElements;
        }
      );
      await fetchAllGroupActivations({ groupId: props.data.groupId }).then(
        ({ data }) => {
          if (data.totalElements > 0) {
            deleteFlag = true;
          }
          zapps.groupActivations = data.totalElements;
        }
      );
      this.setState({
        attachedZappList: zapps,
        isLoaded: true,
        doNotDeleteFlag: deleteFlag,
      });
    }
  }

  render() {
    const { props, state } = this;
    if (!state.isLoaded) {
      return (
        <DialogContent
          className="is-loading shimmer-paper"
          style={{ minHeight: 250, minWidth: 600 }}
        ></DialogContent>
      );
    }

    if (!state.doNotDeleteFlag) {
      return (
        <DialogContent>
          <p>
            You are about to delete the group {props.data.name}. Are you sure
            you want to do this?
          </p>
          <DialogActions>
            <ZButton
              styleName="modalCancel"
              action={toggleModalDiffered('GroupsDeleteConfirm', false)}
              color="secondary"
              buttonText="Cancel"
            />
            <ZButton
              action={this.handleSubmit}
              buttonType="submit"
              color="primary"
              styleName="modalSave"
              buttonText="Delete Group"
            />
          </DialogActions>
        </DialogContent>
      );
    }
    return (
      <DialogContent>
        <p>
          You cannot delete the group {props.data.name}. Because it is attached
          to the following:
        </p>
        <ul className={props.classes.deleteList}>
          {state.attachedZappList.apps.map(singleApp => {
            return (
              <li key={singleApp.id}>{_.get(singleApp, 'name', 'Unknown')}</li>
            );
          })}
        </ul>
        <ul className={props.classes.deleteList}>
          {state.attachedZappList.userActivations.length > 0 &&
            state.attachedZappList.userActivations.map((activations, index) => {
              return <li key={index}>User Activations: {activations}</li>;
            })}
        </ul>
        {state.attachedZappList.userActivations > 0 && (
          <ul className={props.classes.deleteList}>
            <li>User Activations: {state.attachedZappList.userActivations}</li>
          </ul>
        )}
        {state.attachedZappList.groupActivations > 0 && (
          <ul className={props.classes.deleteList}>
            <li>
              Group Activations: {state.attachedZappList.groupActivations}
            </li>
          </ul>
        )}

        <DialogActions>
          <ZButton
            styleName="modalCancel"
            action={toggleModalDiffered('GroupsDeleteConfirm', false)}
            color="secondary"
            buttonText="Cancel"
          />
        </DialogActions>
      </DialogContent>
    );
  }

  handleSubmit() {
    const { props } = this;

    deleteSelectedGroup({ groupId: props.data.groupId })
      .then(() => {
        toggleModalDirect('GroupsDeleteConfirm', false);
        openSnackBar('Group was deleted');
        publishEvent('table:force-fetch-Groups');
      })
      .catch(error => {
        toggleModalDirect('GroupsDeleteConfirm', false);
        openSnackBar(`Failed to delete group: ${error.response.data}`);
      });
  }
}

GroupsDeleteConfirm.defaultProps = {
  data: {},
};

const styles = ({ palette }) => {
  return {
    deleteList: {
      overflowY: 'auto',
      maxHeight: 200,
      marginTop: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingLeft: 0,
      listStyle: 'none',
      flexWrap: 'wrap',

      '& li': {
        width: '33%',
        color: palette.text.primary,
        marginBottom: '5px',
      },
    },
    skeleton: {
      backgroundColor: palette.background.paper,
    },
  };
};

export default withStyles(styles)(GroupsDeleteConfirm);
