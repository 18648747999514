import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, ...theme }: any) =>
  createStyles({
    backgroundCircle: {
      display: 'flex',
      width: 65,
      height: 65,
      marginRight: 5,
      justifyContent: 'center',
      position: 'relative',
      borderRadius: '50%',
      background: palette.background.paper,
    },

    blueBorder: {
      border: `1px solid ${palette.common.ios}`,
    },
    darkGreenBorder: {
      border: `1px solid ${palette.common.low}`,
    },
    greenBorder: {
      border: `1px solid ${palette.common.android}`,
    },
    orangeBorder: {
      border: `1px solid ${palette.common.elevated}`,
    },
    redBorder: {
      border: `1px solid ${palette.common.critical}`,
    },

    statisticCard: {
      color: palette.text.secondary,
      alignItems: 'center',
      marginRight: 32,
    },

    icon: {
      position: 'absolute',
      top: '43%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: 45,
    },

    customIcon: {
      padding: 8,
    },

    text: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: 8,
    },

    label: {
      fontSize: theme.config.textSizes.normal,
      textDecoration: 'none',
      alignSelf: 'center',
      color: palette.text.primary,
    },
  })
);

export default useStyles;
