import { ServiceHandlerFactory } from 'utils/serviceUtils';
import _ from 'lodash';

// mappings
import VectorMapping from 'utility/VectorMapping';
import ThreatCategoryMapping from 'utility/ThreatCategoryMapping';
import { EDistribution } from 'models/distribution';
import { Api } from 'config/axiosConfig';

export const fetchAllTRMPoliciesList = async (teamId, module) => {
  let url = '/api/trm/v1/trms';
  const teamParam = `teamId=${teamId}`;
  const moduleParam = `module=${module}`;

  const hasTeam = teamId?.trim().length;
  const hasModule = module?.trim().length;

  if (hasTeam) {
    url = `${url}?${teamParam}`;
  }

  if (hasModule && hasTeam) {
    url = `${url}&${moduleParam}`;
  } else if (hasModule) {
    url = `${url}?${moduleParam}`;
  }

  return Api.get(url);
};

export const fetchThreatTypes = ServiceHandlerFactory({
  axios: {
    url: 'api/trm/v1/threat-types',
  },
  isCancelable: true,
  paramSchema: [
    {
      path: 'distribution',
      transform: () => EDistribution.ENTERPRISE,
    },
    { path: 'lang' },
  ],
});

export const fetchAllThreatTypes = ServiceHandlerFactory({
  axios: {
    url: 'api/trm/v1/threat-types/all',
  },
  isCancelable: true,
  paramSchema: [{ path: 'lang' }],
});

export const fetchThreatTypesForTRMPolicy = async (
  distribution,
  languagePayload
) => {
  return Api.get(
    `api/trm/v1/threat-types?distribution=${distribution}&lang=${languagePayload}`
  );
};

export const fetchAllResponseTypes = ServiceHandlerFactory({
  axios: {
    url: 'api/trm/v1/response-types',
  },
  isCancelable: true,
});

export const fetchTRMPolicyByIdNew = ServiceHandlerFactory({
  axios: {
    url: 'api/trm/v1/trms/:id',
  },
});

export const createTRMPolicy = ServiceHandlerFactory({
  axios: {
    url: 'api/trm/v1/trms',
    method: 'post',
  },
});

export const updateTRMPolicy = async (policyId, policy) => {
  return Api.put(`api/trm/v1/trms/${policyId}`, policy);
};

export const deleteTRMPolicy = async (policyId) => {
  return Api.delete(`api/trm/v1/trms/${policyId}`);
};

export const cloneSelectedTRMPolicy = ServiceHandlerFactory({
  axios: {
    url: 'api/trm/v1/trms/:id/clone',
    method: 'post',
  },
});

export const getAlertMessages = ServiceHandlerFactory({
  axios: {
    url: 'api/trm/v1/trms/:id/alert-messages/:language',
    method: 'get',
  },
  isCancelable: true,
});

export const updateAlertMessages = ServiceHandlerFactory({
  axios: {
    url: 'api/trm/v1/trms/:id/alert-messages/:language',
    method: 'put',
  },
  isCancelable: true,
});

export const fetchUserThreatNotifications = ServiceHandlerFactory({
  axios: {
    url: 'api/trm/v1/trms/:trmId/notifications',
    method: 'get',
  },
  isCancelable: true,
});

export const fetchAllThreatNotifications = ServiceHandlerFactory({
  axios: {
    url: 'api/trm/v1/trms/:trmId/notifications/all',
    method: 'get',
  },
  isCancelable: true,
});

export const subscribeToThreatNotifications = ServiceHandlerFactory({
  axios: {
    url: 'api/trm/v1/trms/:trmId/notifications',
    method: 'post',
  },
});

export const cleanTrmRulesFullSet = (fullRules) => {
  return fullRules.reduce((accum, rule) => {
    return {
      ...accum,
      [rule.id]: rule,
    };
  }, {});
};

// export const cleanTrmRules = (
//   rules,
//   threatTypes,
//   sortBy,
//   order,
//   severityFilters = [2, 3]
// ) => {
//   const _threatTypes = getThreatTypesById(threatTypes);
//   const sortedRules = rules
//     .filter(
//       a =>
//         ThreatTypeMapping[a.threatTypeId] &&
//         _.find(_threatTypes, { id: a.threatTypeId }) &&
//         severityFilters.includes(a.severity)
//     )
//     .map(_rule => {
//       const ruleId = _rule.threatTypeId;
//       const ruleCategory = _threatTypes[ruleId].category;
//       const ruleVector = _threatTypes[ruleId].vector;

//       const OS = _threatTypes[ruleId].operatingSystems
//         .map(os => {
//           return os === 1 ? 'Android' : 'iOS';
//         })
//         .join(' and ');

//       return {
//         ..._rule,
//         name: _.get(ThreatTypeMapping[_rule.threatTypeId], 'name'),
//         category: _.get(ThreatCategoryMapping[ruleCategory], 'name'),
//         vector: VectorMapping[ruleVector],
//         Enabled: _rule.shouldCollect,
//         OS,
//       };
//     });
//   // filter out the ones not used

//   let inversion = -1;

//   if (order === 'asc') {
//     inversion = 1;
//   }
//   return sortSelectedTRMPolicyRules(sortedRules, sortBy, inversion);
// };

export const getThreatTypesById = (threatTypesItems) => {
  return threatTypesItems.reduce((accum, value) => {
    return {
      ...accum,
      [value.id]: value,
    };
  }, {});
};
// helper funciton to add in names for threat table data
export const threatTableDataHelper = (
  tableData,
  threatHash,
  iPhoneModelMap
) => {
  return tableData.map((singleThreat) => {
    const threatTypeId = _.get(singleThreat, 'threatTypeId');
    const fallbackThreatName = _.get(singleThreat, 'threatTypeName');
    let modelName = singleThreat?.device?.model;
    if (singleThreat?.os === 'ios') {
      if (iPhoneModelMap?.[`${modelName}`]?.commercialName) {
        modelName = iPhoneModelMap[`${modelName}`]?.commercialName;
      }
    }
    return {
      threatServerName: _.get(
        threatHash,
        `${threatTypeId}.serverName`,
        fallbackThreatName
      ),
      threatDescription: _.get(
        threatHash,
        `${threatTypeId}.description`,
        'Unknown'
      ),
      ...singleThreat,
      device: { ...singleThreat.device, model: modelName },
    };
  });
};
// helper functions to sort

export const sortSelectedTRMPolicyRules = (
  rules,
  sortByProperty,
  inversion
) => {
  if (rules) {
    return rules.sort(sortTableDataHandler(sortByProperty, inversion));
  }

  return undefined;
};

function sortTableDataHandler(sortByProperty, inversion) {
  return (ruleA, ruleB) => {
    if (!ruleA[sortByProperty] || !ruleB[sortByProperty]) return 0;
    return (
      ruleA[sortByProperty]
        .toString()
        .localeCompare(ruleB[sortByProperty])
        .toString() * inversion
    );
  };
}

export const cleanTrmRulesCSV = (rules, threatTypes) => {
  const _threatTypes = getThreatTypesById(threatTypes);
  return rules
    .filter(
      (a) =>
        _threatTypes[a.threatTypeId] &&
        _.find(_threatTypes, { id: a.threatTypeId })
    )
    .map((_rule) => {
      const ruleId = _rule.threatTypeId;
      const ruleCategory = _threatTypes[ruleId].category;
      const ruleVector = _threatTypes[ruleId].vector;
      const name = _.get(_threatTypes, `${ruleId}.serverName`, 'name');
      const severity = _.get(_rule, 'severity', '0');
      const OS = _threatTypes[ruleId].operatingSystems
        .map((os) => {
          return os === 1 ? 'Android' : 'iOS';
        })
        .join(' and ');

      return {
        ..._rule,
        name: name || 'N/A',
        category: _.get(ThreatCategoryMapping[ruleCategory], 'name'),
        categoryRaw: ruleCategory,
        vector: VectorMapping[ruleVector],
        vectorRaw: ruleVector,
        enabled: _rule.shouldCollect,
        OS,
        platform: _threatTypes[ruleId].operatingSystems,
        severity: severity.toString(),
      };
    });
};
