import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
// helpers
import {
  reduceForMultiSelect,
  csvInjectionRegex,
  csvInjectionErrorMessage,
} from 'utils/componentUtils';
import ScopeMapping, {
  common,
  zDefend,
  zScan,
  MTD,
  zShield,
} from 'mappings/scopeMapping';
// components
import { FormikTextField } from 'components/inputs/FormikTextField';
import { Grid } from '@material-ui/core';
import { MultiSelect } from 'components/Selects';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import ZButton from 'UI/Buttons/ZButton';
import { requestAllTeams } from 'api/apis';
import {
  createAPIAccessKey,
  updateAPIAccessKey,
} from 'api/AuthorizationService';

import {
  openSnackBar,
  toggleModalDirect,
  toggleModalDiffered,
} from 'utils/storeUtils';

import { publishEvent } from 'utils/eventUtils';
import ScopeOptions from '../inputs/ScopeOptions';
import {
  cleanUpScopesForSubmit,
  formatDefaultScopeOptions,
} from '../../utils/scopeUtils';

const initialState = {
  clientId: '',
  availableScopes: [],
  selectedScopes: [],
  availableTeams: [],
  selectedTeams: [],
  error: '',
};

class AuthorizationEditModal extends Component {
  editMode = false;

  constructor(props) {
    super(props);

    this.sortedDefaultScopes = Object.keys(props.availableScopes)
      .map((scope) => _.get(ScopeMapping, scope, 'Unknown Scope'))
      .sort((a, b) => a.localeCompare(b));

    this.defaultScopeOptions = formatDefaultScopeOptions(props.availableScopes);

    let scopeOptions = this.defaultScopeOptions;
    if (_.get(props, 'data.scopes')) {
      scopeOptions = props.data.scopes.reduce(
        (acc, string) => {
          const [key, prop] = string.split(':');
          if (acc[key]) {
            acc[key][prop] = true;
          }

          return acc;
        },
        { ...this.defaultScopeOptions }
      );
    }

    this.editMode = !_.isEmpty(props.data);
    this.state = !this.editMode
      ? {
          initialState,
          scopeOptions,
        }
      : {
          initialState,

          clientId: props.data.id,
          scopeOptions,
          selectedTeams: props.data.teams.map(({ name, id }) => ({
            label: name,
            value: id,
          })),
        };

    this.handleSelect = this.handleSelect.bind(this);
    this.handleRegenerateSecret = this.handleRegenerateSecret.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleToggleSwitch = this.handleToggleSwitch.bind(this);
  }

  componentDidMount() {
    this.fetchAvailableTeams();
  }

  componentDidUpdate(prevProps) {
    const { props } = this;

    if (!_.isEqual(prevProps.data.id, props.data.id)) {
      const selectedTeams = props.data.teams.map((teamObj) => ({
        label: teamObj.name,
        value: teamObj.id,
      }));

      const selectedScopes = props.data.scopes.map((scope) => ({
        value: scope,
      }));

      this.setState({
        selectedTeams,
        selectedScopes,
      });
    }
  }

  render() {
    const { state, props } = this;
    return (
      <Fragment>
        <DialogContent>
          {this.editMode && (
            <Fragment>
              <ZButton
                fullWidth
                color="primary"
                styleName="modalSave"
                buttonText="Regenerate Secret"
                action={toggleModalDiffered(
                  'AuthorizationsRegenerateConfirm',
                  {
                    apiKeyId: state.clientId,
                    description: state.description,
                  },
                  { title: 'Regenerate Api Access Key' }
                )}
              />
              <div className="or-divider">OR</div>
            </Fragment>
          )}
          <Formik
            initialValues={{
              description: _.get(props, 'data.description', ''),
            }}
            validationSchema={AuthSchema}
            validateOnBlur
            enableReinitialize
            onSubmit={this.handleSubmit}
          >
            {({ dirty, isSubmitting, setFieldValue, values }) => {
              return (
                <Form>
                  <Grid container>
                    <Grid item xs>
                      <Field
                        label="Description"
                        name="description"
                        component={FormikTextField}
                        classList={{ errorClass: props.classes.errorClass }}
                      />
                    </Grid>
                  </Grid>
                  <MultiSelect
                    label="Teams"
                    name="selectedTeams"
                    buttonPlaceholder="Select Teams"
                    options={state.availableTeams}
                    onChange={this.handleSelect}
                    values={state.selectedTeams}
                  />
                  <h4>Configure Permissions</h4>
                  <ScopeOptions
                    title="Common"
                    availableScopes={state.scopeOptions}
                    disable={state.disableChanges}
                    handleToggleSwitch={this.handleToggleSwitch}
                    sortedDefaultScopes={this.sortedDefaultScopes}
                    scopeMapping={common}
                    show={props.availableModules.hasOwnProperty.call(
                      props.availableModules,
                      'COMMON'
                    )}
                  />
                  <ScopeOptions
                    title="zDefend"
                    availableScopes={state.scopeOptions}
                    disable={state.disableChanges}
                    handleToggleSwitch={this.handleToggleSwitch}
                    sortedDefaultScopes={this.sortedDefaultScopes}
                    // zdefend
                    scopeMapping={zDefend}
                    show={props.availableModules.hasOwnProperty.call(
                      props.availableModules,
                      'ZIAP'
                    )}
                  />
                  <ScopeOptions
                    title="MTD"
                    availableScopes={state.scopeOptions}
                    disable={state.disableChanges}
                    handleToggleSwitch={this.handleToggleSwitch}
                    sortedDefaultScopes={this.sortedDefaultScopes}
                    // MTD
                    scopeMapping={MTD}
                    show={props.availableModules.hasOwnProperty.call(
                      props.availableModules,
                      'ZIPS'
                    )}
                  />

                  <ScopeOptions
                    title="zScan"
                    availableScopes={state.scopeOptions}
                    disable={state.disableChanges}
                    handleToggleSwitch={this.handleToggleSwitch}
                    sortedDefaultScopes={this.sortedDefaultScopes}
                    // zscan
                    scopeMapping={zScan}
                    show={props.availableModules.hasOwnProperty.call(
                      props.availableModules,
                      'ZDEV'
                    )}
                  />
                  <ScopeOptions
                    title="zShield"
                    availableScopes={state.scopeOptions}
                    disable={state.disableChanges}
                    handleToggleSwitch={this.handleToggleSwitch}
                    sortedDefaultScopes={this.sortedDefaultScopes}
                    // zshield
                    scopeMapping={zShield}
                    show={props.availableModules.hasOwnProperty.call(
                      props.availableModules,
                      'ZSHIELD'
                    )}
                  />

                  <Typography variant="body1" color="error" align="center">
                    {state.error}
                  </Typography>
                  <DialogActions>
                    <ZButton
                      styleName="modalCancel"
                      action={toggleModalDiffered(
                        'AuthorizationsCreateEdit',
                        false
                      )}
                      color="secondary"
                      buttonText="Cancel"
                    />
                    <ZButton
                      buttonType="submit"
                      color="primary"
                      styleName="modalSave"
                      buttonText="Save API Access"
                    />
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Fragment>
    );
  }

  handleSelect(name, selectedOption) {
    const option = _.isArray(selectedOption)
      ? selectedOption
      : [selectedOption];
    this.setState({ [name]: option });
  }

  handleChange(field) {
    return (event) => {
      this.setState({
        [field]: event.target.value,
      });
    };
  }

  handleToggleSwitch(scope, permission) {
    return () => {
      const { state } = this;
      this.setState({
        scopeOptions: {
          ...this.state.scopeOptions,
          [scope]: {
            ...this.state.scopeOptions[scope],
            [permission]: !state.scopeOptions[scope][permission],
          },
        },
      });
    };
  }

  handleSubmit(values) {
    const { state } = this;

    const payload = {
      description: values.description,
      scopes: cleanUpScopesForSubmit(state.scopeOptions),
      teams: cleanUpDataForSubmit(state.selectedTeams),
    };

    if (!this.editMode) {
      createAPIAccessKey({}, payload)
        .then((resp) => {
          publishEvent('table:force-fetch-authorization');
          toggleModalDirect('AuthorizationsSecretDisplay', resp.data, {
            title: 'API Access Key Generated',
          });
        })
        .catch((error) => {
          toggleModalDirect('AuthorizationsCreateEdit', false);
          openSnackBar(`Error in creating an API Access Key: ${error}`);
        });
    } else {
      updateAPIAccessKey({ apiKey: this.props.data.id }, payload)
        .then(() => {
          publishEvent('table:force-fetch-authorization');
          toggleModalDirect('AuthorizationsCreateEdit', false);
          publishEvent('table:force-fetch-authorization');
          openSnackBar(`${_.get(values, 'description')} Successfully Updated`);
        })
        .catch((error) => {
          toggleModalDirect('AuthorizationsCreateEdit', false);
          openSnackBar(`Error in updating an API Access Key: ${error}`);
        });
    }
  }

  handleRegenerateSecret() {
    this.props.toggleModal('apiRegenerateConfirm', {
      apiKeyId: this.props.data.id,
      description: this.props.data.description,
    });
  }

  fetchAvailableTeams() {
    requestAllTeams()
      .then((resp) => {
        const teams = reduceForMultiSelect(resp.data);

        this.setState({
          availableTeams: teams,
        });
      })
      .catch((error) => console.log(error));
  }
}

function cleanUpDataForSubmit(array = []) {
  return array.map((item) => {
    return item.value;
  });
}

const mapStateToProps = (state) => {
  return {
    availableScopes: _.get(state, 'auth.user.scopes', []),
    availableModules: _.get(state, 'auth.user.modules', {}),
  };
};

export const createModalConfig = {
  title: 'Add API Access Key',
  scrollable: true,
  fullWidth: true,
};

export const editModalConfig = {
  title: 'Edit API Access Key',
  scrollable: true,
  fullWidth: true,
};

const styles = ({ config, palette, shape }) => {
  return {
    scopeLabel: {
      fontSize: config.textSizes.petite,
      marginTop: 15,
    },
    scopeOption: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    permissionLabel: {
      paddingTop: 15,
      paddingLeft: 15,
    },
    rowContainer: {
      width: '100%',
    },
    checkBoxContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    box: {
      marginLeft: '15px',
    },
    errorClass: {
      minWidth: 150,
      padding: '7px 10px',
      marginTop: 19,
      marginLeft: 10,
      position: 'absolute',
      zIndex: 1,
      transform: 'translateY(-50%)',
      background: palette.error.light,
      color: palette.error.contrastText,
      borderRadius: shape.borderRadius,
    },
  };
};

const AuthSchema = Yup.object().shape({
  description: Yup.string()
    .matches(csvInjectionRegex, csvInjectionErrorMessage)
    .required('Description is required'),
});

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps)(AuthorizationEditModal)
);
