import _ from 'lodash';

// action types
export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

// selectors
export const getSnackbar = state => state.snackbar;

// actions
export const openSnackbar = config => {
  return {
    type: OPEN_SNACKBAR,
    payload: _.isString(config)
      ? { open: true, message: config }
      : { open: true, ...config },
  };
};

export const closeSnackbar = () => {
  return {
    type: CLOSE_SNACKBAR,
  };
};

const initialState = {
  open: false,
  autoHideDuration: 5000,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
};

export const snackbar = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SNACKBAR:
      return {
        ...state,
        ...action.payload,
      };
    case CLOSE_SNACKBAR:
      return { ...state, ...initialState };
    default:
      return state;
  }
};
