export interface ITopTenImpactfulCVEs {
  nonUpgradeable: number;
  exploitPocUrl: string | null;
  upgradeable: number;
  cve: string;
  deviceCount: number;
  platform: string;
  severity: Severity;
}

export enum Severity {
  critical = 'Critical',
  high = 'High',
  medium = 'Medium',
  low = 'Low',
}
