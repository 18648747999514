import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import withRouter from 'components/hocs/withRouter';
import classnames from 'classnames';
import Moment from 'moment';

// utils
import { RangeTypes, calculateDuration } from 'utils/RangeUtils';

// ui
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

// selectors

import { PubSub } from 'utils/eventUtils';
import { RangeCalendar } from '../../calendars/index';
import { useTranslation } from 'react-i18next';

const defaultDateState = {
  minDate: new Date(1980, 0, 1),
  maxDate: new Date(2050, 11, 31),
};

function RangeSelector(props) {
  const { t, ready, i18n } = useTranslation();
  let rangeTypes = [];

  let rangeTypesEnum = {};
  const [isOpen, setIsOpen] = useState(false);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [to, setTo] = useState(undefined);
  const [from, setFrom] = useState(undefined);
  const [duration, setDuration] = useState(undefined);

  rangeTypes = [...RangeTypes, ...props.additionalRangeTypes];
  rangeTypesEnum = rangeTypes.reduce(
    (acc, rt) => ({
      ...acc,
      [rt.ordinal]: rt.name,
    }),
    {}
  );

  useEffect(() => {
    setTo(_.get(props, 'location.query.to'));
    setFrom(_.get(props, 'location.query.from'));
    setDuration(_.get(props, 'location.query.duration'));

    // If there is no query param, set All Time duration not to show invalid date
    if (
      !_.get(props, 'location.query.to') &&
      !_.get(props, 'location.query.to') &&
      !_.get(props, 'location.query.to')
    ) {
      setDuration(9); // All Time duration
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const classes = classnames(props.classes.dropdownWrapper, {
    'is-loading': isLoading,
    shimmer: isLoading,
  });

  const mFrom = !duration
    ? Moment.unix(from)
    : calculateDuration(duration).after;

  const mTo = !duration ? Moment.unix(to) : calculateDuration(duration).before;

  const currentLanguage = i18n?.language || 'en';
  const formattedDateRange = `${mFrom
    .locale(currentLanguage)
    .format('MMM Do')} - ${mTo.locale(currentLanguage).format('MMM Do')}`;

  const buttonText = !_.isUndefined(duration)
    ? rangeTypesEnum[duration]
    : formattedDateRange;

  if (!ready) {
    return null;
  }

  return (
    <div className={classes}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <div className="select--button" onClick={handleClick}>
            {t(buttonText)}
            <ArrowDropDown className={props.classes.icon} />
          </div>
          {isOpen && (
            <RangeCalendar
              className={props.classes.calendar}
              rootCalendarProps={{
                locale: {
                  headerFormat: 'MMM Do',
                },
                height: 275,
                minDate: defaultDateState.minDate,
                maxDate: defaultDateState.maxDate,
              }}
              rangePresets={rangeTypes}
              onRangeSelect={onRangeSelect}
              onRangeInitialSelect={onRangeInitialSelect}
              onPresetSelect={onPresetSelect}
              selectedPreset={duration}
              start={mFrom.toDate()}
              end={mTo.toDate()}
            />
          )}
        </div>
      </ClickAwayListener>
    </div>
  );

  function handleClick() {
    setIsOpen(!isOpen);
  }

  function handleClickAway() {
    setIsOpen(false);
  }

  function onRangeInitialSelect(event) {
    // this.setState({
    //   minDate: Moment(event.from)
    //     .subtract(90, 'days')
    //     .startOf('day')
    //     .toDate(),
    //   maxDate: Moment(event.from)
    //     .add(90, 'days')
    //     .endOf('day')
    //     .toDate(),
    // });
  }

  function onRangeSelect(event) {
    setIsOpen(false);
    setFrom(Moment(event.from).unix());
    setTo(Moment(event.to).unix());
    setDuration(undefined);
    PubSub.publish('RANGE_SELECTOR_ACTION', {
      from: Moment(event.from).unix(),
      to: Moment(event.to).unix(),
    });
  }

  function onPresetSelect(durationValue) {
    setIsOpen(false);
    setDuration(durationValue);
    setTo(undefined);
    setFrom(undefined);
    PubSub.publish('RANGE_SELECTOR_ACTION', { duration: durationValue });
  }
}

const styles = ({ palette }) => {
  return {
    dropdownWrapper: {
      display: 'flex',
      position: 'relative',
      justifyContent: 'center',

      '& .select--button': {
        minWidth: 95,
        minHeight: 38,
        padding: '10px 40px 10px 10px',
        position: 'relative',
      },

      '& .select--button:hover': {
        cursor: 'pointer',
      },

      '&.is-loading.shimmer': {
        border: `1px solid ${palette.tables.shimmer}`,
      },
    },

    calendar: {
      position: 'absolute',
      top: 36,
      right: 0,
      zIndex: 1000,
      boxShadow: '0px 5px 10px 0px rgba(0,0,0,.12)',
    },

    icon: {
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translateY(-50%)',
    },
  };
};

export default compose(withRouter, withStyles(styles))(RangeSelector);
