import React from 'react';
import PropTypes from 'prop-types';

const TabContent = props => {
  return <>{props.children}</>;
};

TabContent.defaultProps = {
  label: 'Tab',
  shouldShow: true,
};

TabContent.propTypes = {
  label: PropTypes.string.isRequired,
  tabId: PropTypes.string,
  scope: PropTypes.string,
  allow: PropTypes.string,
};

export default TabContent;
