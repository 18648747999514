import _ from 'lodash';
import React from 'react';

import MaterialTableCell from '@material-ui/core/TableCell';
import MaterialTableRow from '@material-ui/core/TableRow';

import { TableColumnsContext } from 'components/Tables';

const handleChange = (rowDataId, handleChangedRow) => {
  return () => handleChangedRow(rowDataId);
};
const InAppsTableRow = props => {
  const selected = _.get(props, `rest.selectedHash.${props.rowData.id}`, false);
  return (
    <TableColumnsContext.Consumer>
      {({ columnHeadersHash }) => {
        return (
          <MaterialTableRow
            onClick={handleChange(props.rowData, props.rest.handleSelectedRow)}
            className={
              selected ? props.classList.selectedRow : props.classList.tableRow
            }
          >
            {props.rowMapping.map((map, index) => {
              const columnConfig = _.get(columnHeadersHash, map.path);

              if (columnConfig && !columnConfig.show) {
                return null;
              }

              const columnData = _.get(props.rowData, map.path);
              let columnContent = columnData;

              if (typeof map.columnContent === 'function') {
                columnContent = map.columnContent(columnData, props);
              }

              return (
                <MaterialTableCell
                  key={`column-frag-${props.rowData.id}-${index}`}
                  className={props.classList[map.className]}
                  classes={{ root: props.classList.tableCell }}
                >
                  {columnContent}
                </MaterialTableCell>
              );
            })}
          </MaterialTableRow>
        );
      }}
    </TableColumnsContext.Consumer>
  );
};

export default InAppsTableRow;
