import { createStyles, makeStyles } from '@material-ui/styles';

// Usage of 'any' inside this style decorator is discouraged; however, our theme
// currently does not conform to MUI's theme structure standards. So this will
// be a followup fix for later.
const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    detail: {
      alignSelf: 'center',
      color: palette.tables.cell,
      display: 'flex',
      flexWrap: 'wrap',
      fontSize: 13,
      padding: '0px 8px 8px 0px',
      paddingLeft: 8,
      width: '75%',
    },
    label: {
      color: palette.text.secondary,
      fontWeight: 'bold',
      padding: '0px 8px 10px 0px',
      width: '25%',
    },
  })
);

export default useStyles;
