import MUICheckbox from '@material-ui/core/Checkbox';
import MUIDialog from '@material-ui/core/Dialog';
import MUIDialogActions from '@material-ui/core/DialogActions';
import MUIDialogContent from '@material-ui/core/DialogContent';
import MUIDialogTitle from '@material-ui/core/DialogTitle';
import MUIFormControl from '@material-ui/core/FormControl';
import MUIFormControlLabel from '@material-ui/core/FormControlLabel';
import Button from 'components/UI/Button';
import TextField from 'components/UI/input/TextField';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidHttpUrl } from 'utils/urlUtils';
import {
  DeviceActionTypes,
  DeviceActions,
  ICustomResponse,
  IOSResponseTypes,
  ISelectTRMPolicyTableData,
  PolicyChangeOptions,
} from '../models';
import useStyles from './useStyles';

interface IDeviceActionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  rowData: ISelectTRMPolicyTableData;
  osResponseTypes?: IOSResponseTypes;
  handleTRMPolicyRuleChange: (
    optionName: PolicyChangeOptions,
    value: ICustomResponse[] | null,
    rule: ISelectTRMPolicyTableData
  ) => void;
}

interface IActionForm {
  type: DeviceActionTypes;
  argument: string | null;
  checked: boolean;
}

type TOptions = Record<DeviceActionTypes, IActionForm>;

const defaultOptions: TOptions = {
  [DeviceActionTypes.ShutDown]: {
    type: DeviceActionTypes.ShutDown,
    argument: null,
    checked: false,
  },
  [DeviceActionTypes.Label]: {
    type: DeviceActionTypes.Label,
    argument: null,
    checked: false,
  },
  [DeviceActionTypes.Redirect]: {
    type: DeviceActionTypes.Redirect,
    argument: null,
    checked: false,
  },
};

const getOptions = (response: ICustomResponse[] | null): TOptions => {
  if (response && response.length > 0) {
    const options = response.reduce(
      (acc, res) => ({
        ...acc,
        [res.type]: {
          type: res.type,
          argument: res.argument,
          checked: true,
        },
      }),
      {} as TOptions
    );

    return {
      ...defaultOptions,
      ...options,
    };
  }

  return defaultOptions;
};

const DeviceActionsModal: React.FC<IDeviceActionsModalProps> = (props) => {
  const { rowData, isOpen, onClose, handleTRMPolicyRuleChange } = props;

  const classes = useStyles();

  const [options, setOptions] = useState(defaultOptions);

  const { t } = useTranslation();

  useEffect(() => {
    if (rowData.customResponses && rowData.customResponses.length > 0) {
      const newOptions = getOptions(rowData.customResponses);
      setOptions(newOptions);
    }
  }, [rowData]);

  const isValidLabel = useMemo(() => {
    const { argument: label } = options[DeviceActionTypes.Label];

    return label && label.length > 0 && label.length <= 30;
  }, [options]);

  const getLabelErrorMessage = useCallback(() => {
    const { argument: label, checked } = options[DeviceActionTypes.Label];

    if (checked) {
      if (!label || label.length <= 0) {
        return t('MTD.POLICIES.THREAT.ENTER_LABEL');
      }

      if (label && label.length > 30) {
        return t('MTD.POLICIES.THREAT.LABEL_CHARS_MAX');
      }
    }
  }, [options, t]);

  const isValidRedirectLink = useMemo(() => {
    const { argument: link } = options[DeviceActionTypes.Redirect];

    return link && link.length > 0 ? isValidHttpUrl(link) : false;
  }, [options]);

  const getRedirectErrorMessage = useCallback(() => {
    const { argument: link, checked } = options[DeviceActionTypes.Redirect];

    if (checked) {
      if (link !== null && link.length > 0) {
        if (!isValidRedirectLink) {
          return t('MTD.POLICIES.THREAT.INVALID_LINK');
        }
      } else {
        return t('MTD.POLICIES.THREAT.ENTER_LINK');
      }
    }
  }, [options, isValidRedirectLink, t]);

  const isValidForm = useMemo(() => {
    if (
      options[DeviceActionTypes.Label].checked &&
      options[DeviceActionTypes.Redirect].checked
    ) {
      return isValidLabel && isValidRedirectLink;
    } else if (options[DeviceActionTypes.Label].checked) {
      return isValidLabel;
    } else if (options[DeviceActionTypes.Redirect].checked) {
      return isValidRedirectLink;
    } else {
      return true;
    }
  }, [isValidLabel, isValidRedirectLink, options]);

  const onRedirectChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      setOptions((prev) => ({
        ...prev,
        [DeviceActionTypes.Redirect]: {
          ...prev[DeviceActionTypes.Redirect],
          argument: value,
        },
      }));
    },
    []
  );

  const onLabelChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      setOptions((prev) => ({
        ...prev,
        [DeviceActionTypes.Label]: {
          ...prev[DeviceActionTypes.Label],
          argument: value,
        },
      }));
    },
    []
  );

  const onActionChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const selectedAction = event.target.id as DeviceActionTypes;

      setOptions((prev) => ({
        ...prev,
        [selectedAction]: {
          ...prev[selectedAction],
          checked,
          argument: checked ? prev[selectedAction].argument : null,
        },
      }));
    },
    []
  );

  const onCloseClick = useCallback(() => {
    const newOptions = getOptions(rowData.customResponses);
    setOptions(newOptions);
    onClose();
  }, [onClose, rowData]);

  const onSaveClick = useCallback(() => {
    const selectedoptions = Object.values(options).filter((o) => o.checked);

    if (
      !_.isEqual(
        _.sortBy(selectedoptions, (item) => item.type),
        _.sortBy(rowData.customResponses, (item) => item.type)
      )
    ) {
      handleTRMPolicyRuleChange(
        PolicyChangeOptions.DeviceActions,
        selectedoptions,
        rowData
      );
    }

    onClose();
  }, [rowData, options, handleTRMPolicyRuleChange, onClose]);

  return (
    <div>
      <MUIDialog open={isOpen} onClose={onCloseClick} maxWidth="md">
        <MUIDialogTitle>
          {t('MTD.POLICIES.THREAT.THREAT_POLICY_DEVICE_RESPONSE_ACTIONS')}
        </MUIDialogTitle>
        <MUIDialogContent>
          <div>
            <MUIFormControl>
              <MUIFormControl>
                <MUIFormControlLabel
                  value={DeviceActionTypes.ShutDown}
                  control={
                    <MUICheckbox
                      color="primary"
                      id={DeviceActionTypes.ShutDown}
                      onChange={onActionChange}
                      checked={options[DeviceActionTypes.ShutDown].checked}
                    />
                  }
                  label={DeviceActions.ShutDown}
                />

                <MUIFormControlLabel
                  value={DeviceActionTypes.Redirect}
                  control={
                    <MUICheckbox
                      color="primary"
                      id={DeviceActionTypes.Redirect}
                      onChange={onActionChange}
                      checked={options[DeviceActionTypes.Redirect].checked}
                    />
                  }
                  label={DeviceActions.Redirect}
                />
                <TextField
                  className={classes.rowElement}
                  name="Redirect"
                  value={options[DeviceActionTypes.Redirect].argument}
                  onChange={onRedirectChange}
                  disabled={!options[DeviceActionTypes.Redirect].checked}
                  error={
                    options[DeviceActionTypes.Redirect].checked
                      ? !isValidRedirectLink
                      : undefined
                  }
                  helperText={getRedirectErrorMessage()}
                />

                <MUIFormControlLabel
                  className={classes.rowElement}
                  value={DeviceActionTypes.Label}
                  control={
                    <MUICheckbox
                      color="primary"
                      id={DeviceActionTypes.Label}
                      onChange={onActionChange}
                      checked={options[DeviceActionTypes.Label].checked}
                    />
                  }
                  label={DeviceActions.Label}
                />
                <TextField
                  className={classes.rowElement}
                  name="Label"
                  value={options[DeviceActionTypes.Label].argument}
                  onChange={onLabelChange}
                  disabled={!options[DeviceActionTypes.Label].checked}
                  error={
                    options[DeviceActionTypes.Label].checked
                      ? !isValidLabel
                      : undefined
                  }
                  helperText={getLabelErrorMessage()}
                />
              </MUIFormControl>
            </MUIFormControl>
          </div>
        </MUIDialogContent>
        <MUIDialogActions>
          <Button
            text={t('GLOBAL.CANCEL')}
            color="secondary"
            onClick={onCloseClick}
          />
          <Button
            text={t('GLOBAL.SAVE')}
            color="primary"
            onClick={onSaveClick}
            disabled={!isValidForm}
          />
        </MUIDialogActions>
      </MUIDialog>
    </div>
  );
};

export default DeviceActionsModal;
