import _ from 'lodash';
import Axios from 'axios';
import jwt from 'utils/jwtUtils';

import store from 'config/storeConfig';
import {
  setAuthentication,
  clearUserScopes,
  setBrandingProperties,
} from 'reducers/AuthReducers';

// actions
import {
  logout,
  setCurrentUser,
  updatePartnerUser,
  updateSuperUser,
} from 'actions/LocalUserActions';

//import { fetchCurrentUser, fetchBranding } from 'api/apis';

const fetchCurrentUser = () => ApiInstance.get('/api/auth/v1/me');
const fetchBranding = () => ApiInstance.get('/api/auth/v1/branding');

// utils
import { publishEvent } from 'utils/eventUtils';

function tokenHelper({ userToken, partnerToken, superToken }) {
  // inside account
  if (userToken) {
    return userToken;
  }
  // inside super
  if (superToken && !partnerToken && !userToken) {
    return superToken;
  }
  // inside partner ui
  if (!userToken && partnerToken) {
    return partnerToken;
  }
  // inside partner ui as a partner user
  // if (partnerToken) {
  //   return partnerToken;
  // }
}
const ApiInstance = Axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Z-Client-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
});

ApiInstance.interceptors.request.use(
  config => {
    const isPublic = checkPublicRoutes(config.url);

    if (isPublic || config.headers.Authorization) {
      return config;
    }

    // authorization is needed if the route is private & bearer token is missing
    const { auth } = store.getState();
    const userToken = _.get(auth, 'user.accessToken');
    const superToken = _.get(auth, 'superUser.accessToken');
    const partnerToken = _.get(auth, 'partnerUser.accessToken');
    /* eslint-disable no-param-reassign */
    const headerToken = tokenHelper({ userToken, superToken, partnerToken });
    // if (!superToken) {
    //   config.headers.Authorization = `Bearer ${userToken}`;
    // }

    // if (superToken && !userToken) {
    //   config.headers.Authorization = `Bearer ${superToken}`;
    // }

    // if (superToken && userToken) {
    //   config.headers.Authorization = `Bearer ${userToken}`;
    // }

    // if (partnerToken && !userToken) {
    //   config.headers.Authorization = `Bearer ${partnerToken}`;
    // }
    config.headers.Authorization = `Bearer ${headerToken}`;
    /* eslint-enable no-param-reassign */

    return config;
  },
  error => Promise.reject(error)
);

const publicRoutes = [
  '/signup',
  '/login',
  '/confirm',
  '/validateSignUp',
  '/passwords',
  '/checkPasswordReset',
  '/validatePasswordReset',
  '/resendValidationEmail',
  '/password_policy/passwordExpiry',
  '/auth/v1/email',
  '/join',
  '/validationTokens',
  '/checkUserInvited',
  '/validateUserInvited',
  '/sso/token',
  '/sso/status',
  '/system/properties',
  '/branding/resource',
];

const tfaRoutes = ['/api/auth/v1/login/tfa-config', '/api/auth/v1/login/tfa'];

function checkPublicRoutes(url) {
  if (_.includes(tfaRoutes, url)) return false;
  return publicRoutes.find(partial => url.indexOf(partial) !== -1);
}

ApiInstance.interceptors.response.use(
  response => {
    const url = _.get(response, 'request.responseURL');
    const isLogin =
      url &&
      (url.indexOf('auth/v1/login') !== -1 ||
        url.indexOf('auth/v1/login/tfa') !== -1 ||
        url.indexOf('auth/v1/sso/token') !== -1 ||
        url.indexOf('auth/v1/signup?validationToken=') !== -1 ||
        url.indexOf('auth/v1/join?validationToken') !== -1 ||
        Boolean(url.match(/\/auth\/v1\/accounts\/.+(\/enter)$/, 'im')) ||
        Boolean(
          url.match(/\/auth\/v1\/partner_accounts\/.+(\/enter)$/, 'im')
        ) ||
        Boolean(url.match(/\/auth\/v1\/validateUserInvited\/.+/, 'im')));

    // parse & set the token along with the user's scopes ONLY on login
    if (isLogin) {
      const accessToken = _.get(response, 'data.accessToken');
      const refreshToken = _.get(response, 'data.refreshToken');
      // check branding here
      jwt
        .decodeAndPullPayload(accessToken)
        .then(payload => {
          // check 2 factor auth
          store.dispatch(clearUserScopes());
          const storePath =
            (payload.super && 'superUser') ||
            (payload.partner && 'partnerUser') ||
            'user';
          // let storePath = 'user';
          // if (payload.super) {
          //   storePath = 'superUser';
          // } else if (payload.partner && !payload.user) {
          //   storePath = 'partnerUser';
          // }
          const modules = payload.modules
            ? payload.modules.reduce(
                (acc, mod) => ({ ...acc, [mod]: true }),
                {}
              )
            : {};

          // set auth details
          if (payload.tfaRequired) {
            store.dispatch(
              setAuthentication({
                [storePath]: {
                  isAuthenticated: true,
                  expires: payload.exp,
                  tfaRequired: true,
                  scopes: { tfaRequired: true },
                  acct: payload.acct,
                  otpAuthUrl: _.get(response, 'data.otpAuthUrl'),
                  accessToken,
                  refreshToken,
                  modules,
                },
              })
            );
          } else {
            store.dispatch(
              setAuthentication({
                [storePath]: {
                  isAuthenticated: true,
                  expires: payload.exp,
                  scopes: payload.scopes,
                  accessToken,
                  refreshToken,
                  modules,
                  accountId: _.get(payload, 'acct'),
                },
              })
            );
          }
          // give token to authWorker
          publishEvent('auth:add-token', {
            accessToken,
            refreshToken,
            expires: payload.exp,
            storePath,
          });
          return Promise.all([storePath, fetchCurrentUser(), fetchBranding()]);
        })
        .then(results => {
          const userDetails = _.get(results[1], 'data');

          if (results[0] === 'superUser') {
            store.dispatch(updateSuperUser(userDetails));
          } else if (results[0] === 'partnerUser') {
            store.dispatch(updatePartnerUser(userDetails));
          } else {
            store.dispatch(setCurrentUser(userDetails));
          }
          const brandingData = _.get(results[2], 'data', '');
          store.dispatch(setBrandingProperties(brandingData));
        })
        .catch(() => {
          store.dispatch(
            setAuthentication(new Error('Failed to decode token'))
          );
        });
    }

    return response;
  },
  error => {
    const { response } = error;

    if (response && response.status === 401) {
      store.dispatch(logout());
    }

    return Promise.reject(error);
  }
);

export default ApiInstance;
