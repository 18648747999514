import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    formWrapper: {
      display: 'flex',

      '& > form': {
        maxWidth: 560,
        flex: 1,

        '& > button': {
          display: 'block',
          marginTop: 20,
        }
      },
    }
  })
);

export default useStyles;
