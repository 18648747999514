import _ from 'lodash';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/hocs/withRouter';
import { withStyles } from '@material-ui/core/styles';

// ui
import MaterialTable from '@material-ui/core/Table';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import TableBody from './TableBody';
import TableFooter from './TableFooter';
import TableHeader from './TableHeader';

class Table extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: [],
      totalResults: 0,
      isLoading: false,
    };

    this.handleResponseData = this.handleResponseData.bind(this);
    this.bindEvents();
  }

  bindEvents() {
    const { props } = this;

    window.addEventListener(
      `table:force-fetch-${props.tableId}`,
      this.forcedHandler.bind(this)
    );
  }

  componentDidMount() {
    if (this.props.syncUrl) {
      this.fetchTableData(this.props)
        .then(this.handleResponseData)
        .catch((error) => {
          console.log('Failed to load table data on mount');
          console.log(error);
        });
    }
  }

  componentDidUpdate(prevProps) {
    const { props } = this;

    if (
      _.isFunction(props.onDidUpdate) &&
      !props.onDidUpdate(prevProps.q, props.q)
    ) {
      return false;
    }

    if (props.syncUrl && !_.isEqual(prevProps.q, props.q)) {
      this.fetchTableData(props, prevProps)
        .then(this.handleResponseData)
        .catch((error) => {
          console.log('Failed to load table data on update');
          console.log(error);
        });
    }
  }

  componentWillUnmount() {
    window.removeEventListener(
      `table:force-fetch-${this.props.tableId}`,
      this.forcedHandler.bind(this)
    );
  }

  render() {
    const { props, state } = this;
    const classes = {
      ...props.classes,
      ...props.classList,
    };

    return (
      <Fragment>
        <Card className={classes.card}>
          <CardContent
            className={classes.cardContent}
            style={{
              minHeight:
                props.isLoading || !_.get(state, 'tableData.length')
                  ? 355
                  : 'auto',
            }}
          >
            <MaterialTable>
              {props.components.TableHeader ? (
                React.createElement(props.components.TableHeader, {
                  classList: classes,
                  isLoading: state.isLoading,
                  ...props,
                })
              ) : (
                <TableHeader isLoading={state.isLoading} classList={classes} />
              )}

              {props.components.TableBody ? (
                React.createElement(props.components.TableBody, {
                  action: props.action,
                  isLoading: state.isLoading,
                  classList: classes,
                  tableData: state.tableData,
                })
              ) : (
                <TableBody
                  tableData={state.tableData}
                  classList={classes}
                  action={props.action}
                  isLoading={state.isLoading}
                />
              )}

              {props.components.TableFooter ? (
                React.createElement(props.components.TableFooter, {
                  classList: classes,
                  isLoading: state.isLoading,
                  tableData: state.tableData,
                  totalResults: state.totalResults,
                  syncUrl: props.syncUrl,
                  withStore: props.withStore,
                })
              ) : (
                <TableFooter
                  tableData={state.tableData}
                  totalResults={state.totalResults}
                  classList={classes}
                  isLoading={state.isLoading}
                  syncUrl={props.syncUrl}
                  withStore={props.withStore}
                />
              )}
            </MaterialTable>
          </CardContent>
        </Card>
      </Fragment>
    );
  }

  fetchTableData(props, prevProps = {}) {
    this.setState({ isLoading: true });
    return this.props.fetchTableData(props.q, prevProps.q);
  }

  handleResponseData(stateData) {
    this.setState({ ...stateData, isLoading: false });
  }

  forcedHandler() {
    this.fetchTableData(this.props)
      .then(this.handleResponseData)
      .catch((error) => {
        console.log('Failed to load table data on mount');
        console.log(error);
      });
  }
}

Table.defaultProps = {
  classes: {},
  components: {},
  syncUrl: true,
};

Table.propTypes = {
  tableId: PropTypes.string.isRequired,
  syncUrl: PropTypes.bool,
};

const styles = ({ palette, typography }) => {
  return {
    card: {
      overflowX: 'auto',
    },

    cardContent: {
      '&:last-child': {
        paddingBottom: 0,
      },
    },

    // table
    tableWrapper: {
      overflowX: 'auto',
    },

    tableHeaderCell: {
      color: palette.text.link,
      fontWeight: 'bold',
      fontSize: typography.fontSize,
      borderBottomWidth: '2px',

      '&:last-of-type': {
        textAlign: 'right',
      },
    },

    tableHeaderCheckbox: {
      fontWeight: 'bold',
      fontSize: 14,
      borderBottomWidth: '2px',

      '&:last-of-type': {
        textAlign: 'right',
      },
    },
    tableCell: {
      '& > div': {
        display: 'flex',
        alignItems: 'center',
      },

      '&:last-child, &:last-child > div': {
        textAlign: 'right',
        paddingRight: 0,
        justifyContent: 'flex-end',
      },
    },

    tableRowLoading: {
      height: 40,
    },

    tableCellNoData: {
      height: 240,
      textAlign: 'center',
    },
  };
};

export default withStyles(styles)(withRouter(Table));
