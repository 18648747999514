import {
  fetchAggrByAppInstances,
  fetchAllGroupsByTeamId,
  fetchThreatsByAppByTime,
  fetchThreatsPage,
} from 'api/apis';
import { fetchiPhoneMapping } from 'api/DevicesService';
import { fetchGroupsByTeamId } from 'api/GroupService';
import { fetchThreatsStats } from 'api/ThreatsService';
import { fetchAllThreatTypes } from 'api/TRMPolicyService';
import { IAppleNameMapping } from 'components/main/devices/Devices/models';
import { IGraphData } from 'components/UI/graphs/common/models';
import { IFilterEditor } from 'components/UI/Table/models';
import moment from 'moment';
import APPLE_NAME_MAPPING from 'utility/AppleCommericalNames';
import ThreatTypes from 'utility/ThreatTypes';
import { DEFAULT_ORDINAL, graphTickFormatter } from 'utils/RangeUtils';
import { IQueryParams } from '../common/models';
import {
  IThreatByAppDataset,
  IThreatTypeBucket,
  IZappBucket,
} from '../ThreatsByAppGraph/models';
import {
  IGroup,
  IMappingThreatType,
  IRowData,
  IThreatsResponse,
  IThreatStats,
  IThreatTypesFromApi,
  ITimeBucket,
} from './models';
import { formatQueryFromQueryParams } from '../ThreatsAppBar/utils';

export const createRSQLParams = (filters: IFilterEditor[]) => {
  return (
    filters
      // Remove non-rsql filters
      .filter(({ useURLSearchParams }) => !useURLSearchParams)
      .map(({ name, operator: customRSQLOperator, type, value }) => {
        // This is used for string/number/SINGLE-select filters with custom RSQL operators
        if (customRSQLOperator && !!value && !value?.[0]) {
          return `${name}=${customRSQLOperator}=${value}`;
        }

        if (type === 'date' && !!value) {
          return `${name}==${moment(value).format('YYYY-MM-DD')}`;
        }
        if (name === 'vector') {
          name = 'vectorName';
        }
        if (name === 'zappName') {
          name = 'zappInstance.name.sort';
        }

        if (type === 'select' && value?.[0]) {
          // This is used for MULTI-select filters
          const rsqlOperator = customRSQLOperator ?? '=';
          const rsqlInOperator = customRSQLOperator ?? 'in';
          if (name === 'vectorName') {
            return value.length === 1
              ? `${name}.keyword=${rsqlOperator}=${value[0]}`
              : `${name}.keyword=${rsqlInOperator}=(${value.join(',')})`;
          }
          if (name === 'threatTypeId') {
            return value.length === 1
              ? `${name}=${value[0]}`
              : `${name}=in=(${value.join(',')})`;
          }
          return value.length === 1
            ? `${name}=${rsqlOperator}=${value[0]}`
            : `${name}=${rsqlInOperator}=(${value.join(',')})`;
        }

        return name === 'os' || name === 'vectorName'
          ? `${name}.keyword==${(type === 'string' || type === 'select') &&
            typeof value === 'string'
            ? `*${value.replace(/ /g, '*')}*`
            : String(value ?? '')
          }`
          : `${name}==${type === 'string' && typeof value === 'string'
            ? `*${value.replace(/ /g, '*')}*`
            : String(value ?? '')
          }`;
      })
      .join(';')
  );
};

export const fetchThreatTypesFromApi = async (lang?: string) => {
  let result: IMappingThreatType[] = [];
  try {
    result = (await fetchAllThreatTypes({ lang }))?.data ?? [];
  } catch (e) {
    console.error(e);
  }

  return result;
};

export const fetchIPhoneMap = async () => {
  let result: IAppleNameMapping = APPLE_NAME_MAPPING;
  try {
    result = await fetchiPhoneMapping();
  } catch (e) {
    console.error(e);
  }

  return result;
};

export const fetchGroups = async (module: string) => {
  let result: IGroup[] = [];
  try {
    result =
      (
        await (module !== 'mtd'
          ? fetchAllGroupsByTeamId()
          : fetchGroupsByTeamId({ module: 'ZIPS' }))
      )?.data ?? [];
  } catch (e) {
    console.error(e);
  }

  return result;
};

export const fetchTableData = async (
  module: string,
  filters: IFilterEditor[],
  query: IQueryParams,
  threatTypesFromApi?: IThreatTypesFromApi,
  iPhoneModelMap?: IAppleNameMapping,
  groups?: IGroup[]
) => {
  let result: IThreatsResponse | undefined = undefined;
  if (!threatTypesFromApi || !iPhoneModelMap || !groups) {
    return [];
  }
  try {
    const nonNullFilters = filters.filter(
      ({ value }) =>
        typeof value === 'number' ||
        (typeof value === 'string' && value !== '') ||
        value?.[0]
    );
    if (query.orderBy === 'timestamp') {
      query.orderBy = 'timestampInfo.timestamp';
    }
    query.query = createRSQLParams(nonNullFilters);
    result = await fetchThreatsPage(
      module === 'mtd'
        ? {
          module: 'ZIPS',
          ...query,
        }
        : {
          ...query,
        }
    );
    result = {
      ...result,
      content: result?.data.content.map((item: IRowData) => {
        let threatType;
        for (const key in threatTypesFromApi) {
          if (threatTypesFromApi[key].id === item.threatTypeId) {
            threatType = threatTypesFromApi[key];
            break;
          }
        }
        return {
          ...item,
          serverName: threatType?.serverName,
          description: threatType?.description,
        };
      }),
    } as IThreatsResponse;
  } catch (e) {
    console.error(e);
  }
  return result;
};

export const fetchStats = async (module: string, rawQuery: IQueryParams, rawFilters: IFilterEditor[]) => {
  let result: IThreatStats = {
    labels: [],
    critical: [],
    elevated: [],
    parameters: [],
    threatCounts: [],
  };
  const { filters, query } = ammendFiltersFromQuery(rawFilters, rawQuery);
  const nonNullFilters = filters.filter(
    ({ value }) =>
      typeof value === 'number' ||
      (typeof value === 'string' && value !== '') ||
      value?.[0]
  );
  if (query.orderBy === 'timestamp') {
    query.orderBy = 'timestampInfo.timestamp';
  }
  query.query = createRSQLParams(nonNullFilters);

  try {
    const timeBuckets: ITimeBucket[] =
      (
        await fetchThreatsStats(
          module === 'mtd' ? { module: 'ZIPS', ...query } : query
        )
      )?.data?.aggregations?.['filter#time range']?.[
        'date_histogram#severities by time'
      ]?.buckets ?? [];

    result = {
      labels: timeBuckets.map(({ key }) =>
        graphTickFormatter(query?.duration, query?.from, query?.to)(key)
      ),
      critical: timeBuckets.map(
        (bucket) =>
          bucket?.['filters#severities']?.buckets?.['3']?.doc_count ?? 0
      ),
      elevated: timeBuckets.map(
        (bucket) =>
          bucket?.['filters#severities']?.buckets?.['2']?.doc_count ?? 0
      ),
      parameters: timeBuckets.map(({ key }) => key),
      threatCounts: timeBuckets.map(({ doc_count }) => doc_count),
    };
  } catch (e) {
    console.error(e);
  }

  return result;
};

export const fetchUniqueDevices = async (
  module: string,
  rawQuery: IQueryParams,
  rawFilters: IFilterEditor[],
) => {
  let result: IGraphData | undefined;
  const { filters, query } = ammendFiltersFromQuery(rawFilters, rawQuery);
  const nonNullFilters = filters.filter(
    ({ value }) =>
      typeof value === 'number' ||
      (typeof value === 'string' && value !== '') ||
      value?.[0]
  );
  if (query.orderBy === 'timestamp') {
    query.orderBy = 'timestampInfo.timestamp';
  }
  query.query = createRSQLParams(nonNullFilters);
  try {
    await fetchAggrByAppInstances(
      module === 'mtd' ? { module: 'ZIPS', ...query } : query
    );
    const timeBuckets: ITimeBucket[] =
      (
        await fetchAggrByAppInstances(
          module === 'mtd' ? { module: 'ZIPS', ...query } : query
        )
      )?.data?.aggregations?.['filter#timeRange']?.['date_histogram#timestamps']
        ?.buckets ?? [];

    result = {
      labels: timeBuckets.map(({ key }) =>
        graphTickFormatter(query?.duration, query?.from, query?.to)(key)
      ),
      datasets: [
        {
          key: 'uniqueDevices',
          label: 'Devices',
          data: timeBuckets.map(
            (bucket) => bucket?.['cardinality#appInstances']?.value ?? 0
          ),
          parameters: timeBuckets.map(({ key }) => key),
          threatCounts: timeBuckets.map(({ doc_count }) => doc_count),
        },
      ],
    };
  } catch (e) {
    console.error(e);
  }

  return result;
};

export const fetchThreatsByApp = async (
  module: string,
  query: IQueryParams,
  threatTypesFromApi?: IThreatTypesFromApi
) => {
  let result: IGraphData | undefined;
  const revisedQuery = formatQueryFromQueryParams(query);
  try {
    const rawThreatTypes: IThreatTypeBucket[] =
      (
        await fetchThreatsByAppByTime(
          module === 'mtd' ? { module: 'ZIPS', ...revisedQuery } : revisedQuery
        )
      )?.data?.aggregations?.['filter#timeRange']?.['lterms#threatTypeIds']
        ?.buckets ?? [];

    const periodFormat =
      query?.duration &&
        !isNaN(Number(query?.duration)) &&
        Number(query.duration) < DEFAULT_ORDINAL
        ? 'MMM DD HH:mm'
        : 'MMM DD';

    const datasets = rawThreatTypes.reduce(
      (threatAcc: IThreatByAppDataset[], threat) => {
        const rawApps: IZappBucket[] =
          threat?.[`sterms#zappIds`]?.buckets ?? [];

        const threats: IThreatByAppDataset[] = rawApps.map((app) => {
          const timePeriods = app?.['date_histogram#timestamps']?.buckets ?? [];
          const threatType =
            typeof threat?.key === 'number'
              ? ThreatTypes?.[threat.key]
              : undefined;

          const threatTypeFromApi: IMappingThreatType | undefined =
            typeof threat?.key === 'number'
              ? threatTypesFromApi?.[threat.key]
              : undefined;

          return {
            label: `${threatTypeFromApi?.serverName ?? threatType?.name ?? 'N/A'
              }:${app?.key ?? ''}`,
            backgroundColor: threatType?.color ?? '',
            stack: app?.key ?? '',
            threatTypeId: threat?.key ?? '',
            zappName:
              app?.[`sterms#zappName`]?.buckets?.[0]?.key ??
              app?.[`sterms#name`]?.buckets?.[0]?.key ??
              '',
            barThickness: 'flex',
            data: timePeriods.map((period) => {
              return period.doc_count !== 0 ? period.doc_count : undefined;
            }),
          };
        });

        return [...threatAcc, ...threats];
      },
      []
    );

    result = {
      datasets,
      labels: rawThreatTypes?.[0]?.['sterms#zappIds']?.buckets?.[0]?.[
        'date_histogram#timestamps'
      ]?.buckets?.map(
        ({ key }) =>
          `${moment(key).format(periodFormat)} - ${moment(key)
            .add(7, 'days')
            .format(periodFormat)}`
      ),
    };
  } catch (e) {
    console.log(e);
  }

  return result;
};

export const ammendFiltersFromQuery = (
  filters: IFilterEditor[],
  query: IQueryParams
): {
  filters: IFilterEditor[];
  query: IQueryParams;
} => {
  const ammendedFilters: IFilterEditor[] = Object.keys(query)
    .filter((key) => typeof query[key] === 'object' && key !== 'teamfilter')
    .map((key) => ({
      name: key,
      operator: '',
      type: 'select',
      value: query[key],
    }));

  const filtersThatWereAdded: string[] = ammendedFilters.map(
    ({ name }) => name
  );

  return {
    filters: [...filters, ...ammendedFilters],
    query: Object.fromEntries(
      Object.entries(query).filter(
        ([key]) => !filtersThatWereAdded.includes(key)
      )
    ),
  };
};
