import { AxiosResponse } from 'axios';
import { ISelectItem } from 'components/UI/input/Select';
import { Api } from 'config/axiosConfig';
import {
  IAllPolicyOptions,
  IDeviceSpaceOption,
  IGroupsResponse,
  IGroupsWithPages,
  IPolicyGroupOption,
  IResponseOption,
} from './models';

export const fetchDeviceSpaces = async (
  hasSpacesCapability: boolean,
  connectionId: string
): Promise<ISelectItem[]> => {
  let data: ISelectItem[] = [];

  if (!hasSpacesCapability || !connectionId) {
    return data;
  }

  const baseUrl = 'api/emm/v1/provision/connection';
  const apiUrl = `${baseUrl}/${connectionId}/device-spaces`;
  try {
    const result: AxiosResponse<IDeviceSpaceOption[]> = await Api.get(apiUrl);
    if (result?.data?.[0]) {
      data = result?.data?.map(({ mdmId, name }) => ({
        label: name,
        value: mdmId,
      }));
    }
  } catch (e) {
    console.log(e);
  }

  return data;
};

export const fetchGroups = async (
  hasSpacesCapability: boolean,
  connectionId?: string,
  deviceSpaceId?: string | number | null,
  type?: string,
  pageNumber?: number,
  query?: string,
  size?: number,
  sort?: string
): Promise<IGroupsWithPages> => {
  let data: IGroupsWithPages = { groupOptions: [], page: 0, lastPage: 0 };
  if (!connectionId || (hasSpacesCapability && !deviceSpaceId)) {
    return data;
  }
  const baseUrl = 'api/emm/v1/provision/connection';

  const params = new URLSearchParams({
    deviceSpaceId: String(hasSpacesCapability ? deviceSpaceId ?? '' : ''),
    size: String(size ?? ''),
    page: String(pageNumber ?? 0),
    sort: `${String(sort ?? 'name')}%2asc`,
    search: query ?? '',
    type: type ?? '',
  });

  const apiUrl = `${baseUrl}/${connectionId}/device-groups?${params.toString()}`;

  try {
    const result: AxiosResponse<IGroupsResponse> = await Api.get(apiUrl);
    if (!!result?.data?.content?.[0]) {
      data = {
        groupOptions:
          result?.data?.content.map(({ mdmId, name }) => ({
            label: name,
            value: mdmId,
          })) ?? [],
        lastPage: result?.data?.totalPages - 1 ?? 0,
        page: result?.data?.pageable?.pageNumber ?? 0,
      };
    }
  } catch (e) {
    console.log(e);
  }

  return data;
};

export const fetchAppSettingsOptions = async (): Promise<
  IPolicyGroupOption[]
> => {
  let data: IPolicyGroupOption[] = [];

  const apiUrl = 'api/app_settings/v1/appsettings/';
  try {
    const result: AxiosResponse<IResponseOption[]> = await Api.get(apiUrl);
    if (result?.data?.[0]) {
      data = result?.data?.map(({ id, name, team }) => ({
        accountBounded: !team?.id,
        label: name,
        value: id,
        teamId: team?.id,
      }));
    }
  } catch (e) {
    console.log(e);
  }

  return data;
};

export const fetchDormancyPolicies = async (): Promise<
  IPolicyGroupOption[]
> => {
  let data: IPolicyGroupOption[] = [];

  const apiUrl = 'api/devices/v1/dormancy/policies';
  try {
    const result = await Api.get(apiUrl);
    if (!!result?.data) {
      data = result.data.map(({ id, name, teamId }: IResponseOption) => ({
        accountBounded: !teamId,
        label: name,
        value: id,
        teamId,
      }));
    }
  } catch (e) {
    console.log(e);
  }

  return data;
};

export const fetchPhishingPolicies = async (): Promise<
  IPolicyGroupOption[]
> => {
  let data: IPolicyGroupOption[] = [];

  const apiUrl = 'api/phishing/v1/policies/all';
  try {
    const result: AxiosResponse<IResponseOption[]> = await Api.get(apiUrl);
    if (result?.data?.[0]) {
      data = result?.data?.map(({ id, name, team }) => ({
        accountBounded: !team?.id,
        label: name,
        value: id,
        teamId: team?.id,
      }));
    }
  } catch (e) {
    console.log(e);
  }

  return data;
};

export const fetchPrivacyPolicies = async (): Promise<IPolicyGroupOption[]> => {
  let data: IPolicyGroupOption[] = [];

  const apiUrl = '/api/privacy/v1/policies';
  try {
    const result: AxiosResponse<IResponseOption[]> = await Api.get(apiUrl);
    if (result?.data?.[0]) {
      data = result?.data?.map(({ id, name, team }) => ({
        accountBounded: !team?.id,
        label: name,
        value: id,
        teamId: team?.id,
      }));
    }
  } catch (e) {
    console.log(e);
  }

  return data;
};

export const fetchTRMPoliciesByConnectionId = async (
  connectionId: string
): Promise<IPolicyGroupOption[]> => {
  let data: IPolicyGroupOption[] = [];

  if (!!connectionId) {
    const apiUrl = `api/mtd-policy/v1/emm/connections/${connectionId}/trms`;
    try {
      const result: AxiosResponse<IResponseOption[]> = await Api.get(apiUrl);
      if (result?.data?.[0]) {
        data = result?.data?.map(({ id, name, team }) => ({
          accountBounded: !team?.id,
          label: name,
          value: id,
          teamId: team?.id,
        }));
      }
    } catch (e) {
      console.log(e);
    }
  }

  return data;
};

export const fetchNetworkPolicies = async (): Promise<IPolicyGroupOption[]> => {
  let data: IPolicyGroupOption[] = [];

  const apiUrl = 'api/mtd-policy/v1/network/policies/';
  try {
    const result: AxiosResponse<IResponseOption[]> = await Api.get(apiUrl);
    if (result?.data?.[0]) {
      data = result?.data?.map(({ id, name, team }) => ({
        accountBounded: !team?.id,
        label: name,
        value: id,
        teamId: team?.id,
      }));
    }
  } catch (e) {
    console.log(e);
  }

  return data;
};

export const fetchOsRiskPolicies = async (): Promise<IPolicyGroupOption[]> => {
  let data: IPolicyGroupOption[] = [];

  const apiUrl = 'api/mtd-policy/v1/os-risk/policies/';
  try {
    const result: AxiosResponse<IResponseOption[]> = await Api.get(apiUrl);
    if (result?.data?.[0]) {
      data = result?.data?.map(({ id, name, team }) => ({
        accountBounded: !team?.id,
        label: name,
        value: id,
        teamId: team?.id,
      }));
    }
  } catch (e) {
    console.log(e);
  }

  return data;
};

export const fetchAllPolicyOptions = async (
  connectionId: string,
  mode: string,
  teamId: string
): Promise<IAllPolicyOptions> => {
  const [
    appSettingOptions,
    dormancyPolicies,
    phishingPolicies,
    privacyPolicies,
    networkPolicies,
    osRiskPolicies,
    trmPolicies,
  ] = await Promise.all([
    fetchAppSettingsOptions(),
    fetchDormancyPolicies(),
    fetchPhishingPolicies(),
    fetchPrivacyPolicies(),
    fetchNetworkPolicies(),
    fetchOsRiskPolicies(),
    mode !== 'ADD' ? fetchTRMPoliciesByConnectionId(connectionId) : [],
  ]);

  return {
    appSettingOptions: appSettingOptions.filter(
      (policy) => !policy?.teamId || policy?.teamId === teamId
    ),
    dormancyPolicies: dormancyPolicies.filter(
      (policy) => !policy?.teamId || policy?.teamId === teamId
    ),
    phishingPolicies: phishingPolicies.filter(
      (policy) => !policy?.teamId || policy?.teamId === teamId
    ),
    privacyPolicies: privacyPolicies.filter(
      (policy) => !policy?.teamId || policy?.teamId === teamId
    ),
    trmPolicies: trmPolicies.filter(
      (policy) => !policy?.teamId || policy?.teamId === teamId
    ),
    networkPolicies: networkPolicies.filter(
      (policy) => !policy?.teamId || policy?.teamId === teamId
    ),
    osRiskPolicies: osRiskPolicies.filter(
      (policy) => !policy?.teamId || policy?.teamId === teamId
    ),
  };
};
