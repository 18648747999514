import React, { Fragment, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
// ui
import Card from '@material-ui/core/Card';
import DialogActions from '@material-ui/core/DialogActions';
import ZButton from 'UI/Buttons/ZButton';
import Delete from '@material-ui/icons/Delete';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckBox from 'components/inputs/Checkbox';
import { GlobalMenuOption } from 'components/Selects/MultiSelect/GlobalMenuOption';
import GlobalMultiSelectButton from 'components/Selects/MultiSelect/GlobalMultiSelectButton';
import { MultiSelect } from 'components/Selects/index';

const FileInfoCard = (props) => {
  const [notify, setNotify] = useState(false);
  const fileName = _.get(props, 'fileData.name');
  if (!props.isShowing) {
    return null;
  }
  return (
    <Fragment>
      {fileName && (
        <Card
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: 20,
            marginBottom: 0,
          }}
        >
          <>
            <div className="text__ellipsis">{fileName}</div>
            <Delete style={{ cursor: 'pointer' }} onClick={props.removeFile} />
          </>
        </Card>
      )}
      {props.notify && (
        <div>
          <CheckBox
            checked={notify}
            onChange={() => setNotify(!notify)}
            label={props.notifyLabel || 'Notify me when complete'}
          />
          <MultiSelect
            label="zScan Policy"
            name="zScanPolicy"
            isMulti={false}
            buttonPlaceholder="Select zScan Policy"
            components={{
              Option: GlobalMenuOption,
              DropDownButton: GlobalMultiSelectButton,
            }}
            options={props.zScanPolicies}
            onChange={props.handlePolicyChange}
            values={props.selectedPolicy}
          />
        </div>
      )}
      {props.fileLoaded && props.fileLoaded > 1 ? (
        <LinearProgress variant="determinate" value={props.fileLoaded} />
      ) : null}
      {!props.noActionButtons && (
        <DialogActions>
          <ZButton
            styleName="modalCancel"
            action={props.handleCloseModal}
            color="secondary"
            buttonText="Cancel"
            isShowing
          />
          <ZButton
            action={() =>
              props.handleSubmit(
                notify,
                _.get(_.head(props.selectedPolicy), 'value', '')
              )
            }
            buttonType="submit"
            color="primary"
            styleName="modalSave"
            isDisabled={!fileName || !props.enableButton}
            buttonText={props.buttonText}
          />
        </DialogActions>
      )}
    </Fragment>
  );
};

FileInfoCard.propTypes = {
  isShowing: PropTypes.bool,
  fileData: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleCloseModal: PropTypes.func,
  removeFile: PropTypes.func,
  notify: PropTypes.bool,
  notifyLabel: PropTypes.string,
  buttonText: PropTypes.string,
  enableButton: PropTypes.bool,
  noActionButtons: PropTypes.bool,
};

FileInfoCard.defaultProps = {
  isShowing: false,
  fileData: {},
  notify: false,
  notifyLabel: 'Please notify me when complete',
  buttonText: 'Upload Build',
  enableButton: true,
  noActionButtons: false,
};

export default FileInfoCard;
