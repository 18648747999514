import { TypeColumn } from '@inovua/reactdatagrid-enterprise/types';

const getLastVisibleColumnIndex = (columnDefs: TypeColumn[] = []): number => {
  let result = 0;

  [...columnDefs].reverse().some(({ visible } = {}, index) => {
    result = index;
    return visible;
  });

  return columnDefs.length - result - 1;
};

export default getLastVisibleColumnIndex;
