import cc from 'classcat';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { IHorizontalLegendItem, THorizontalLegendSortByFunc } from './models';
import useStyles from './useStyles';

export interface IHorizontalLegendProps {
  className?: string;
  items: IHorizontalLegendItem[];
  order?: THorizontalLegendSortByFunc | boolean;
}

const sortByOrder: THorizontalLegendSortByFunc = (a, b) =>
  (a.order ?? 1) > (b.order ?? 0) ? 1 : -1;

const HorizontalLegend: React.FC<IHorizontalLegendProps> = ({
  className,
  items,
  order,
}) => {
  const classes = useStyles();

  const legendItems = useMemo(
    () =>
      (order
        ? typeof order === 'function'
          ? items.sort(order)
          : items.sort(sortByOrder)
        : items
      ).map(({ color, label, link, value }, index) => (
        <div className={classes.item} key={index}>
          <div className={classes.value}>
            <span className={classes.pip} style={{ backgroundColor: color }} />
            {value}
          </div>
          {link ? <Link to={link}>{label}</Link> : <div>{label}</div>}
        </div>
      )),
    [classes.item, classes.pip, classes.value, items, order]
  );

  return (
    <div className={cc([classes.horizontalLegend, className])}>
      <div className={classes.legend}>{legendItems}</div>
    </div>
  );
};

export default HorizontalLegend;
