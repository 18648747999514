import { fetchUsersTablePage } from 'api/UserTableService';
import { memoizeTableQuery } from 'components/RDGTables/utils';
import _ from 'lodash';

export default memoizeTableQuery(async query => {
  // Fetch Data
  const rawUsersTableData = await fetchUsersTablePage(query);

  // Reduce Data
  const users = _.get(rawUsersTableData, 'data.content', []).map(
    ({ teams, role, lastLogin = '', ...rest }) => ({
      teams,
      ...rest,
      lastLogin,
      roleName: !!role ? role.name : '',
      role: !_.isEmpty(role)
        ? {
            name: role.name,
            value: role.id,
            accountBounded: role.scopeBounds === 'ACCOUNT_BOUNDED',
          }
        : {},
      teamName: teams.map(({ name }) => name).join(','),
      fetchUsersTablePage,
    })
  );

  return {
    data: users,
    count: _.get(rawUsersTableData, 'data.totalElements', 0),
  };
});
