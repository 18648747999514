import _ from 'lodash';

const getThreatTypesById = (threatTypesItems) => {
  return threatTypesItems.reduce((accum, value) => {
    return {
      ...accum,
      [value.id]: value,
    };
  }, {});
};

export const mergeRulesWithThreats = (
  rules,
  threats,
) => {
  const threatHash = getThreatTypesById(threats);
  const table = _.intersectionWith(
    rules,
    threats,
    (rule, threat) => rule.threatTypeId === threat.id
  ).map((rule) => {
    const ttid = rule.threatTypeId;
    const { distributions, operatingSystems, serverName, name, description } = _.get(
      threatHash,
      ttid,
      {}
    );

    return {
      ...rule,
      name: serverName || name,
      description,
      platform: operatingSystems,
      distributions,
    };
  });

  return {
    table,
  };
};
