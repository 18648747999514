import React, { Fragment, useState } from 'react';
import _ from 'lodash';
import { Formik, Field, Form } from 'formik';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ZButton from 'UI/Buttons/ZButton';
// form

import { FormikTextField } from 'components/inputs/FormikTextField';
import { toggleModalDirect, openSnackBar } from 'utils/storeUtils';
import { publishEvent } from 'utils/eventUtils';
import { manualUpdateThreat } from 'api/ThreatsService';
import GenericErrorBox from 'components/inputs/GenericErrorBox';

export default function ThreatsMitigation(props) {
  const [error, setError] = useState(null);
  const handleSubmit = (e, { reason }) => {
    const submitAs = _.get(e, 'target.textContent');
    let mitigatedState = 3;
    if (submitAs === 'Fixed') {
      mitigatedState = 2;
    }
    const snackbarWording = mitigatedState === 3 ? 'Approved' : 'Fixed';
    const threatId = _.get(props, 'data.id');
    manualUpdateThreat({ threatId }, { mitigatedState, reason })
      .then(() => {
        toggleModalDirect('ThreatMitigation', false);
        publishEvent('table:force-fetch-threats');
        openSnackBar(`Threat successfully marked as ${snackbarWording}.`);
      })
      .catch(err => {
        setError(_.get(err, 'response.data.message', 'Unknown Error'));
      });
    // close modal or show error:
  };

  function handleClose() {
    toggleModalDirect('ThreatsMitigation', false);
  }
  return (
    <Fragment>
      <DialogContent>
        <Formik
          initialValues={{
            reason: '',
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values }) => {
            return (
              <Form>
                <Field
                  id="standard"
                  label="Reason"
                  component={FormikTextField}
                  name="reason"
                  multiline
                  rows="6"
                />
                <GenericErrorBox errorMessage={error} />
                <DialogActions>
                  <ZButton
                    styleName="modalCancel"
                    action={() => handleClose()}
                    color="secondary"
                    buttonText="Cancel"
                  />
                  <ZButton
                    id="fix"
                    onClick={e => handleSubmit(e, values)}
                    color="primary"
                    styleName="modalSave"
                    buttonText="Fixed"
                  />
                  <ZButton
                    onClick={e => handleSubmit(e, values)}
                    id="approve"
                    color="primary"
                    styleName="modalSave"
                    buttonText="Approve"
                  />
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Fragment>
  );
}
