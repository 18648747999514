import {
  IGenericColumnHeader,
  IGenericRowMapping,
} from 'components/UI/GenericTable/models';
import { IGroupPolicyMapping } from './models';
import TextField from 'components/UI/input/TextField';
import { IGenericRowActions } from 'components/UI/GenericTable/GenericRowActions/models';
import GenericRowActions from 'components/UI/GenericTable/GenericRowActions';
import { truncateString } from '../../AddEditCloneEMMAuth/utils';
import { TFunction } from 'react-i18next';

export const headers = (
  id: string | undefined,
  t: TFunction<'translation', undefined>
): IGenericColumnHeader[] => [
  {
    id: 'group',
    label:
      id === 'GoogleAdminConnector'
        ? t('GLOBAL.UNIT_NAME')
        : t('GLOBAL.GROUP_NAME'),
  },
  { id: 'trmPolicy', label: t('GLOBAL.THREAT_POLICY') },
  { id: 'privacyPolicy', label: t('GLOBAL.THREAT_POLICY') },
  { id: 'phishingPolicy', label: t('GLOBAL.PHISHING_POLICY') },
  { id: 'appSettings', label: t('GLOBAL.APP_SETTINGS') },
  { id: 'dormancyPolicy', label: t('GLOBAL.DEVICE_INACTIVITY') },
  { id: 'actions', label: t('GLOBAL.ACTIONS') },
];

export const getRowMapping = (
  showTooltip: boolean,
  tooltipContent: IGroupPolicyMapping | undefined,
  handleAddPolicyGroupModal: () => void,
  handleDeletePolicyGroup: (id: string) => void,
  handleDragOver?: (
    e: React.DragEvent<HTMLButtonElement>,
    rowIndex?: number
  ) => void,
  handleDrop?: (
    e: React.DragEvent<HTMLButtonElement>,
    rowIndex?: number | null | undefined
  ) => void,
  handleCellMouseEnter?: (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    content: IGroupPolicyMapping
  ) => void,
  handleCellMouseLeave?: () => void
): IGenericRowMapping[] => [
  {
    path: 'group',
    columnContent: (fullData) => {
      const rowData: IGroupPolicyMapping =
        fullData.rowData as IGroupPolicyMapping;
      return (
        <div
          onMouseEnter={(
            e: React.MouseEvent<HTMLTableRowElement, MouseEvent>
          ) => handleCellMouseEnter?.(e, rowData)}
          onMouseLeave={handleCellMouseLeave}
          className={fullData?.classes?.columnContent}
        >
          <div style={{ display: 'flex', position: 'relative' }}>
            <span>{truncateString(rowData?.group?.label ?? '')}</span>
            {showTooltip && rowData.id === tooltipContent?.id && (
              <div
                style={{
                  backgroundColor: '#333',
                  position: 'absolute',
                  color: '#fff',
                  padding: '5px',
                  borderRadius: '4px',
                  fontSize: '12px',
                  zIndex: 999,
                }}
              >
                {tooltipContent?.group?.label ?? ''}
              </div>
            )}
          </div>
        </div>
      );
    },
  },
  {
    path: 'trmPolicy',
    columnContent: (fullData) => {
      const rowData: IGroupPolicyMapping =
        fullData.rowData as IGroupPolicyMapping;

      return (
        <div className={fullData?.classes?.columnContent}>
          <TextField
            type="text"
            disabled
            value={rowData?.trmPolicy?.label ?? ` Default `}
          />
        </div>
      );
    },
  },
  {
    path: 'privacyPolicy',
    columnContent: (fullData) => {
      const rowData: IGroupPolicyMapping =
        fullData.rowData as IGroupPolicyMapping;
      return (
        <div className={fullData?.classes?.columnContent}>
          <TextField
            type="text"
            disabled
            value={rowData?.privacyPolicy?.label ?? ''}
          />
        </div>
      );
    },
  },
  {
    path: 'phishingPolicy',
    columnContent: (fullData) => {
      const rowData: IGroupPolicyMapping =
        fullData.rowData as IGroupPolicyMapping;
      return (
        <div className={fullData?.classes?.columnContent}>
          <TextField
            type="text"
            disabled
            value={rowData?.phishingPolicy?.label ?? ''}
          />
        </div>
      );
    },
  },
  {
    path: 'appSettings',
    columnContent: (fullData) => {
      const rowData: IGroupPolicyMapping =
        fullData.rowData as IGroupPolicyMapping;
      return (
        <div className={fullData?.classes?.columnContent}>
          <TextField
            type="text"
            disabled
            value={rowData?.appSettings?.label ?? ''}
          />
        </div>
      );
    },
  },
  {
    path: 'dormancyPolicy',
    columnContent: (fullData) => {
      const rowData: IGroupPolicyMapping =
        fullData.rowData as IGroupPolicyMapping;
      return (
        <div className={fullData?.classes?.columnContent}>
          <TextField
            type="text"
            disabled
            value={rowData?.dormancyPolicy?.label ?? 'N/A'}
          />
        </div>
      );
    },
  },
  {
    path: 'actions',
    columnContent: (fullData, rowIndex, tableData) => {
      const isLastRow = !!tableData?.[0] && rowIndex === tableData.length - 1;

      const defaultActions: IGenericRowActions[] = [
        {
          actionFn: () => {
            !!fullData?.rowData?.id &&
              handleDeletePolicyGroup?.(fullData.rowData.id);
          },

          type: 'delete',
        },
        {
          actionFn: (e) => e.preventDefault(),
          onDragOver: (e) => handleDragOver?.(e, rowIndex),
          onDrop: (e) => handleDrop?.(e, rowIndex),
          type: 'reorder',
        },
        {
          actionFn: () => handleAddPolicyGroupModal?.(),
          onlyLastRow: true,
          type: 'add',
        },
      ];

      return (
        <GenericRowActions actions={defaultActions} isLastRow={isLastRow} />
      );
    },
  },
];
