export const defaultInappsTableHeaders = [
  {
    id: 'name',
    numeric: false,
    label: 'Name',
    sort: true,
    show: true,
  },
  {
    id: 'bundleId',
    numeric: false,
    label: 'Bundle Id',
    sort: true,
    show: true,
  },
  {
    id: 'description',
    numeric: false,
    label: 'Description',
    sort: true,
    show: true,
  },
  {
    id: 'team.name',
    numeric: false,
    label: 'Team',
    sort: true,
    show: true,
    filterable: true,
  },
  {
    id: 'groupName',
    numeric: false,
    label: 'Group',
    sort: false,
    show: true,
  },
  {
    id: 'licenseJwt',
    numeric: false,
    label: 'License Key',
    sort: true,
    show: true,
  },
  {
    id: 'created',
    numeric: false,
    label: 'Date Created',
    sort: true,
    show: false,
  },
  {
    id: 'modified',
    numeric: false,
    label: 'Date Modified',
    sort: true,
    show: false,
  },
  {
    id: 'activationLimit',
    label: 'Activation Limit',
    order: 9,
    show: false,
    sort: false,
  },
  {
    id: 'devModeEnabled',
    label: 'Dev Mode',
    order: 10,
    show: false,
    sort: false,
  },
  {
    id: 'actions',
    numeric: false,
    label: 'Actions',
    sort: false,
    show: true,
    isProtected: true,
    scope: 'zapps',
  },
];
