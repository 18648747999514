import { IHandleTeamChangeFn } from 'components/main/policies/common/TeamSelectorRadioForm';
import TeamSelectorRadioForm from 'components/main/policies/common/TeamSelectorRadioForm';
import CustomPolicyOption from 'components/main/policies/common/CustomPolicyOption';
import Button from 'components/UI/Button';
import InputError from 'components/UI/input/InputError';
import RadioGroup, { Radio } from 'components/UI/input/RadioGroup';
import Select from 'components/UI/input/Select';
import TextField from 'components/UI/input/TextField';
import {
  Field as FormikField,
  Form as FormikForm,
  Formik,
  FormikHelpers,
} from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { toggleModalDirect } from 'utils/storeUtils';
import { IAllPolicies, IData } from '../models';
import { filterPolicies, initialPolicies } from '../utils';
import { initialValues } from './initialValues';
import { IFormData } from './models';
import Schema from './schema';
import useStyles from './useStyles';
import { selectIsBrandingSubscribed } from 'reducers/AuthReducers';
import { useSelector } from 'react-redux';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import { useTranslation } from 'react-i18next';

interface IFormProps {
  handleSubmit: (
    formData: IFormData,
    formikHelpers: FormikHelpers<IFormData>
  ) => void;
  data: IData;
}

const Form: React.FC<IFormProps> = ({ handleSubmit, data }) => {
  const mtdModule = data?.mtdModule ?? false;
  const classes = useStyles();
  const isBrandingSubscribed = useSelector(selectIsBrandingSubscribed);
  const { t } = useTranslation();
  const resolvedInitialValues = initialValues(
    data.rowData,
    data?.selectedTeamId,
    data?.scopeBounds
  );
  const unfilteredPolicies = useMemo(() => initialPolicies(data), [data]);
  const [policies, setPolicies] = useState<IAllPolicies>(() =>
    filterPolicies(
      unfilteredPolicies,
      !!data?.rowData?.team || data?.scopeBounds === 'TEAM_BOUNDED'
        ? 'team'
        : 'global',
      data?.rowData?.team?.id ?? data?.selectedTeamId ?? '',
      data?.rowData?.emmConnectionId
    )
  );
  const [selectsDisabled, setSelectsDisabled] = useState<boolean>(false);
  const selectedTeam = !!data?.availableTeams
    ? data.availableTeams.find(({ value }) => value === data?.selectedTeamId)
    : null;
  const policyTeam = data?.rowData?.team ?? null; // Just used for edit
  const displayedTeam =
    policyTeam?.name && policyTeam?.id
      ? { label: policyTeam?.name, value: policyTeam?.id }
      : selectedTeam;

  const handleScopeChange = useCallback(
    (
      bounds: string,
      setFieldValue: (field: string, value: string) => void,
      values: IFormData
    ) => {
      if (!!data?.selectedTeamId || !!values?.teamId) {
        setPolicies(() =>
          filterPolicies(
            unfilteredPolicies,
            bounds,
            data?.selectedTeamId || values?.teamId
          )
        );
      }
      setSelectsDisabled(
        !data?.selectedTeamId && bounds === 'team' && !values?.teamId
      );
      setFieldValue('bounds', bounds);
    },
    [data?.selectedTeamId, setPolicies, unfilteredPolicies]
  );

  const handleTeamChange = useCallback(
    (
      teamId: string,
      setFieldValue: (field: string, value?: string) => void,
      values: IFormData
    ) => {
      setPolicies(() =>
        filterPolicies(unfilteredPolicies, values.bounds, teamId)
      );
      setSelectsDisabled(!teamId);
      setFieldValue('teamId', teamId);
    },
    [setPolicies, unfilteredPolicies]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={resolvedInitialValues}
      onSubmit={handleSubmit}
      validateOnBlur
      validationSchema={Schema(t)}
    >
      {({ isSubmitting, setFieldValue, values, errors, touched }) => {
        return (
          <FormikForm>
            <FormikField type="hidden" name="editMode" />
            <FormikField type="hidden" name="id" />
            <FormikField type="hidden" name="selectedTeamId" />
            <FormikField
              component={TextField}
              disabled={isSubmitting}
              label={t('GLOBAL.GROUP_NAME')}
              name="name"
            />
            <FormikField
              component={TextField}
              disabled={isSubmitting}
              label={t('GLOBAL.DESCRIPTION')}
              name="description"
            />
            <RadioGroup
              label={t('MTD.POLICIES.POLICY_TYPE')}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement>,
                value: string
              ) => {
                handleScopeChange(value, setFieldValue, values);
              }}
            >
              <FormikField
                component={Radio}
                disabled={
                  isSubmitting ||
                  !!data?.rowData ||
                  data?.scopeBounds === 'TEAM_BOUNDED'
                }
                label={t('GLOBAL.GLOBAL')}
                name="bounds"
                size="small"
                type="radio"
                value="global"
              />
              <FormikField
                component={Radio}
                disabled={
                  isSubmitting ||
                  !!data?.rowData ||
                  data?.scopeBounds === 'TEAM_BOUNDED'
                }
                label={`Team${
                  displayedTeam?.label ? ` (${displayedTeam.label})` : ''
                }`}
                name="bounds"
                size="small"
                type="radio"
                value="team"
              />
            </RadioGroup>
            <TeamSelectorRadioForm
              handleTeamChange={handleTeamChange as IHandleTeamChangeFn}
              setFieldValue={setFieldValue}
              availableTeams={data.availableTeams}
              isSubmitting={isSubmitting}
              isRowDataPresent={!!data?.rowData}
              values={values}
              selectedTeamId={selectedTeam?.value}
            />
            {!!touched?.teamId && !!errors?.teamId && (
              <InputError override="Team required" />
            )}
            <FormikField
              component={Select}
              customOption={CustomPolicyOption}
              disableClearable
              disabled={isSubmitting || selectsDisabled}
              label={t('GLOBAL.PRIVACY_POLICY' || '')}
              name="selectedPrivacy"
              multiple={false}
              options={policies.privacyPolicyList}
              setFieldValue={setFieldValue}
              type="select"
            />
            <FormikField
              component={Select}
              customOption={CustomPolicyOption}
              disableClearable
              disabled={isSubmitting || selectsDisabled}
              label={t('GLOBAL.THREAT_POLICY')}
              name="selectedTRM"
              multiple={false}
              options={policies.trmPolicyList}
              setFieldValue={setFieldValue}
              type="select"
            />
            <ProtectedComponent allow={{ phishing: 'view' }}>
              <FormikField
                component={Select}
                customOption={CustomPolicyOption}
                disableClearable
                disabled={isSubmitting || selectsDisabled}
                label={t('GLOBAL.PHISHING_POLICY')}
                name="selectedPhishingPolicy"
                multiple={false}
                options={policies.phishingPolicyList}
                setFieldValue={setFieldValue}
                type="select"
              />
            </ProtectedComponent>
            <ProtectedComponent allow={{ app_settings: 'view' }}>
              <FormikField
                component={Select}
                customOption={CustomPolicyOption}
                disableClearable
                disabled={isSubmitting || selectsDisabled}
                label={t('GLOBAL.APP_SETTINGS')}
                name="selectedAppSettings"
                multiple={false}
                options={policies.appSettingsList}
                setFieldValue={setFieldValue}
                type="select"
              />
            </ProtectedComponent>
            <FormikField
              component={Select}
              customOption={CustomPolicyOption}
              disableClearable
              disabled={isSubmitting || selectsDisabled}
              label={t('MTD.INSIGHTS.APP_POLICY')}
              name="selectedAppPolicy"
              multiple={false}
              options={policies.appPolicyList}
              setFieldValue={setFieldValue}
              type="select"
            />
            {mtdModule && (
              <FormikField
                component={Select}
                customOption={CustomPolicyOption}
                disableClearable
                disabled={isSubmitting || selectsDisabled}
                label={t('GLOBAL.NETWORK_POLICY')}
                name="selectedNetworkPolicy"
                multiple={false}
                options={policies.networkPolicyList}
                setFieldValue={setFieldValue}
                type="select"
              />
            )}
            {mtdModule && (
              <FormikField
                component={Select}
                customOption={CustomPolicyOption}
                disableClearable
                disabled={isSubmitting || selectsDisabled}
                label={t('GLOBAL.DEVICE_INACTIVITY')}
                name="selectedDormancyPolicy"
                multiple={false}
                options={policies.dormancyPolicyList}
                setFieldValue={setFieldValue}
                type="select"
              />
            )}
            {mtdModule && isBrandingSubscribed && (
              <FormikField
                component={Select}
                customOption={CustomPolicyOption}
                disableClearable
                disabled={isSubmitting || selectsDisabled}
                label={t('GLOBAL.BRANDING_POLICY')}
                name="selectedBrandingPolicy"
                multiple={false}
                options={policies.brandingPolicyList}
                setFieldValue={setFieldValue}
                type="select"
              />
            )}
            <FormikField
              component={Select}
              customOption={CustomPolicyOption}
              disableClearable
              disabled={isSubmitting || selectsDisabled}
              label={t('GLOBAL.OS_RISK_POLICY')}
              name="selectedOsRiskPolicy"
              multiple={false}
              options={policies.osRiskPolicyList}
              setFieldValue={setFieldValue}
              type="select"
            />
            <div className={classes.buttonPanel}>
              <Button
                color="secondary"
                disabled={isSubmitting}
                text={t('GLOBAL.CANCEL')}
                onClick={() => toggleModalDirect('AccountsCreateEdit', false)}
              />
              <Button
                color="primary"
                text={t('GLOBAL.SAVE')}
                disabled={isSubmitting}
                isLoading={isSubmitting}
                type="submit"
              />
            </div>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default Form;
