import { fetchProtectedAppsPage } from 'api/zShieldService';
import { ITableQuery } from '../models';

export default async (query: ITableQuery) => {
  // Fetch Data
  const apps = await fetchProtectedAppsPage(query);
  // Reduce Data
  const rawTableData = apps?.data?.content ?? [];

  return {
    data: rawTableData,
    count: apps?.data?.totalElements ?? 0,
  };

};
