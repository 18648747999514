import { ServiceHandlerFactory } from '../utils/serviceUtils';

const MDMBase = 'api/emm/v1/provision/connection';

// fetch EMM MDM providers for a connection i.e. MobileIron
export const fetchMDMProviders = ServiceHandlerFactory({
  axios: {
    url: '/api/emm/v1/provision/connector-types',
  },
});

export const fetchConnections = ServiceHandlerFactory({
  axios: {
    url: `${MDMBase}?accountId=:accountId&teamId=:teamId`,
  },
});

export const fetchDeviceSpaces = ServiceHandlerFactory({
  axios: {
    url: `${MDMBase}/:connectionId/device-spaces`,
  },
});

export const fetchMDMConnectionEnabled = ServiceHandlerFactory({
  axios: {
    url: `${MDMBase}/enabled`,
  },
  paramSchema: [{ path: 'teamId', paramKey: 'teamId' }],
});

export const authenticateMDMConnection = ServiceHandlerFactory({
  axios: {
    url: `${MDMBase}/authenticate`,
    method: 'post',
  },
});

export const verifyMDMConnection = ServiceHandlerFactory({
  axios: {
    url: `${MDMBase}/:connectionId/auth-data`,
    method: 'put',
  },
});

export const fetchDeviceGroupsByDeviceSpace = ServiceHandlerFactory({
  axios: {
    url: `${MDMBase}/:connectionId/device-groups?deviceSpaceId=:deviceSpaceId&page=:pageNumber&size=25&sort=name%2Casc&search=:query`,
  },
  isCancelable: true,
});

export const createMDMConnection = ServiceHandlerFactory({
  axios: {
    url: `${MDMBase}`,
    method: 'post',
  },
});

export const updateMDMConnection = ServiceHandlerFactory({
  axios: {
    url: `${MDMBase}/:connectionId`,
    method: 'patch',
  },
});

export const updateAuthDataMDMConnection = ServiceHandlerFactory({
  axios: {
    url: `${MDMBase}/:connectionId/auth-data`,
    method: 'patch',
  },
});

export const deleteMDMConnection = ServiceHandlerFactory({
  axios: {
    url: `${MDMBase}/:connectionId`,
    method: 'delete',
  },
});

// sync groups for MDM Connection

export const syncMDMConnection = ServiceHandlerFactory({
  axios: {
    url: '/api/emm/v1/sync/:connectionId/bulk-sync?type=DEVICES',
    method: 'post',
  },
});

// edit MDM Groups

export const saveMDMGroupsConnection = ServiceHandlerFactory({
  axios: {
    url: `${MDMBase}/:connectionId/sync-groups`,
    method: 'patch',
  },
});

// grab trms that are available for a specific mdm connection

export const fetchTRMPoliciesMDMConnection = ServiceHandlerFactory({
  axios: {
    url: 'api/mtd-policy/v1/emm/connections/:connectionId/trms',
  },
});
