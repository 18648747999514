import { createStyles, makeStyles } from '@material-ui/styles';
const useStyles = makeStyles(({ config }: any) =>
  createStyles({
    adornmentRoot: {
      height: 'inherit',
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    input: {
      paddingRight: 40,
    },
    label: {
      fontSize: config.textSizes.petite,
      marginTop: 15,
      paddingBottom: 5,
    },
  })
);

export default useStyles;
