import AndroidIcon from 'components/UI/icons/AndroidIcon';
import AppleIcon from 'components/UI/icons/AppleIcon';
import React from 'react';
import { ISelectTRMPolicyTableData } from '../models';
import useStyles from '../useStyles';

interface IOperatingSystemsProps {
  rowData: ISelectTRMPolicyTableData;
}

const OperatingSystems: React.FC<IOperatingSystemsProps> = (props) => {
  const { rowData } = props;

  const classes = useStyles();

  const android = rowData?.platform?.includes(1);
  const iOS = rowData?.platform?.includes(2);
  const NA = rowData?.platform?.length === 0 || !rowData.platform;

  return (
    <div className={classes.operatingSystem}>
      {android && <AndroidIcon />}
      {iOS && <AppleIcon />}
      {NA && 'N/A'}
    </div>
  );
};

export default React.memo(OperatingSystems);
