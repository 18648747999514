import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withRouter from 'components/hocs/withRouter';
import classnames from 'classnames';

import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core';
import AdminUserIcon from '@material-ui/icons/SupervisedUserCircle';
import { toggleTheme } from 'reducers/UiSettingsReducers';
import { clearPartnerUser } from 'reducers/AuthReducers';

import LogoHeader from 'components/admin/common/LogoHeader';

function GlobalHeader(props) {
  function exitAccount() {
    props.history.push('/admin');
    props.clearPartnerUser();
  }

  const { classes, organization, isPartnerUser, isSuperUser } = props;
  const companyClasses = classnames('roboto-light', classes.company);
  const headerClasses = classnames('main-header', classes.header);

  return (
    <AppBar className={headerClasses}>
      <Toolbar className={classes.toolbar}>
        <LogoHeader partnerConsoleLogo={props.partnerConsoleLogo} />
        <Typography variant="h6" className={companyClasses}>
          {organization}
        </Typography>
        <div className={classes.filler} />
        {isPartnerUser && isSuperUser && (
          <IconButton className={classes.buttonIcon} onClick={exitAccount}>
            <AdminUserIcon fontSize="small" className={classes.iconStyle} />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
}

GlobalHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
};

const mapStateToProps = state => {
  const theme = state.uiSettings.theme;
  const branding = state.auth.branding;
  const partnerConsoleLogo = theme === 'dark' ? branding?.darkModeConsoleLogoUrl : branding?.consoleLogoUrl;
  return {
    organization: state.partnerUser.organization,
    isPartnerUser: state.auth.partnerUser.isAuthenticated,
    isSuperUser: _.get(state, 'auth.superUser.isAuthenticated'),
    partnerConsoleLogo: partnerConsoleLogo,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      toggleTheme,
      clearPartnerUser,
    },
    dispatch
  );
};

const styles = ({ palette }) => {
  return {
    header: {
      display: 'flex',
      width: '100%',
      height: 64,
      position: 'fixed',
      top: 0,
      right: 0,
      zIndex: 999,
      boxShadow: 'none',
      backgroundColor: palette.components.globalHeader.background,
    },
    filler: {
      flexGrow: 1,
    },
    iconStyle: {
      color: palette.components.globalHeader.contrastText,
    },
    company: {
      paddingLeft: 10,
      paddingRight: 10,
      color: palette.components.globalHeader.contrastText,
    },
    toolbar: {
      paddingLeft: 0,
      paddingRight: 30,
    },
  };
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(GlobalHeader))
);
