import { useCallback } from 'react';
import Modal from 'components/UI/Modal';
import ZScanForm from './Form';
import { buildPayloadForZScanTranscript } from './Form/uiDataElementExpansion/utils';
import { IData, IZScanUploadFormValues } from './models';
import { updateApp } from 'api/zDevService';
import { useRecoilState, useRecoilValue } from 'recoil';
import { availableTeams as availableTeamsAtom } from 'atoms/teams';
import { getActiveModalAtom } from 'atoms/modals';
import useStyles from './Form/useStyles';
import { openSnackBar } from 'utils/storeUtils';
import { publishEvent } from 'utils/eventUtils';
import useZScanAppById from './useZScanAppById';

export const ZScanAppEdit_TITLE = 'ZScanAppEdit';

const activeModalAtom = getActiveModalAtom<IData>();

const ZScanAppEdit: React.FC = () => {
  const [activeModal, setActiveModal] = useRecoilState(activeModalAtom);
  const appIdToFetch = activeModal?.payload?.id;
  const { zScanApp } = useZScanAppById(appIdToFetch);
  const metadataFromRow = zScanApp?.metadata;
  const teamId = zScanApp?.teamId;
  const availableTeams = useRecoilValue(availableTeamsAtom) || [];
  const appName = zScanApp?.name;
  const bundleId = zScanApp?.bundleId;
  const distributionList = zScanApp?.distributionList ?? null;
  const zScanTranscriptJSONString = zScanApp?.emulatorScript;
  const classes = useStyles();

  const handleClose = useCallback(() => {
    if (activeModal) {
      setActiveModal(undefined);
    }
  }, [setActiveModal, activeModal]);

  const handleSubmit = useCallback(
    async ({
      rawJSONData,
      metadata,
      distributionList,
      team,
    }: IZScanUploadFormValues) => {
      const appId = zScanApp?.id;
      const teamId = team?.value;
      let distributionListInArrayForm = distributionList;
      if (typeof distributionList === 'string') {
        distributionListInArrayForm = distributionList.split('\n');
      }

      if (metadata) {
        const metaDataPayload = metadata.map(({ key, value }) => {
          return {
            key,
            value,
          };
        });
        let zScanScriptPayload = rawJSONData;
        if (rawJSONData?.length) {
          const { zScanScript } = rawJSONData;
          if (!zScanScript) {
            zScanScriptPayload = buildPayloadForZScanTranscript(rawJSONData);
          }
        }
        updateApp(
          { appId },
          {
            distributionList: distributionListInArrayForm,
            metadata: metaDataPayload,
            teamId,
            emulatorScript: zScanScriptPayload,
          }
        )
          .then(() => {
            setActiveModal(undefined);
            publishEvent('table:force-fetch-zScanApps');
            openSnackBar(`Successfully updated`);
          })
          .catch(() => {
            setActiveModal(undefined);
            openSnackBar(`Update Failed`);
          });
      }
    },
    [setActiveModal, zScanApp?.id]
  );

  return (
    <Modal
      caption={'Edit App'}
      className={classes.modal}
      onClose={handleClose}
      title={ZScanAppEdit_TITLE}
    >
      <ZScanForm
        availableTeams={availableTeams}
        rawJSONZScanTranscript={zScanTranscriptJSONString}
        handleSubmit={handleSubmit}
        handleCloseModal={handleClose}
        metadataFromRow={metadataFromRow ?? []}
        teamId={teamId ?? ''}
        appName={appName ?? ''}
        bundleId={bundleId ?? ''}
        distributionList={distributionList ?? ''}
      ></ZScanForm>
    </Modal>
  );
};

export default ZScanAppEdit;
