import classnames from 'classnames';
import useStyles from './useStyles';
import ConnectionIcon from '@material-ui/icons/Power';

export interface IGlobalIndicatorProps {
  inline?: boolean;
  isGlobal?: boolean;
  showMarker?: boolean;
  showLabel?: boolean;
  isEmmConnection?: boolean;
  label: string;
}

const GlobalIndicator: React.FC<IGlobalIndicatorProps> = ({
  inline,
  isGlobal,
  isEmmConnection,
  showLabel = true,
  label = '',
}) => {
  const baseClasses = useStyles();

  const classes = classnames(baseClasses.root, {
    [baseClasses.absolute]: !inline,
    [baseClasses.noMarker]: !isGlobal,
  });

  if (isEmmConnection) {
    return (
      <span className={baseClasses.connectionIcon}>
        <ConnectionIcon className="table__os-icon" />
        {showLabel && label}
      </span>
    );
  }

  return <span className={classes}>{showLabel && label}</span>;
};

export default GlobalIndicator;
