import { IBuildUploadData } from './Form/models';
import { withStyles } from '@material-ui/core/styles';
import Form from './Form';
import React, { useState } from 'react';
import styles from './styles';
import withRouter from 'components/hocs/withRouter';

interface IBuildUploadProps {
  data: Partial<IBuildUploadData>;
}

const BuildUpload: React.FC<IBuildUploadProps> = ({ data }) => {
  const [externalError] = useState<string | undefined>(undefined);

  const handleSubmitForm = () => {
    console.log('handle submit');
  };

  return (
    <Form
      data={data}
      externalError={externalError}
      handleSubmitForm={handleSubmitForm}
    />
  );
};

export default withStyles(styles)(withRouter(BuildUpload));
