import React from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';

export const FormError = withStyles(({ palette, shape }) => ({
  errors: {
    minWidth: 150,
    padding: '7px 10px',
    marginTop: 19,
    marginLeft: 10,
    position: 'absolute',
    top: '50%',
    left: '100%',
    zIndex: 1,
    transform: 'translateY(-50%)',
    background: palette.error.light,
    color: palette.error.contrastText,
    borderRadius: shape.borderRadius,
    whiteSpace: 'pre',
  },
}))(props => {
  const errorClass = _.get(props, 'classlist.errors')
    ? props.classlist.errors
    : props.classes.errors;
  return (
    <span className={errorClass}>{props.form.errors[props.field.name]}</span>
  );
});
