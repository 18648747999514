import React, { useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { IHorizontalLegendItem } from '../../HorizontalLegend/models';
import useStyles from './useStyles';

interface ISegmentProps extends IHorizontalLegendItem {
  index: number;
  scalar: number;
  rPad: number;
}

const Segment: React.FC<ISegmentProps> = ({
  color,
  index,
  link,
  rPad,
  scalar,
  value,
}) => {
  const classes = useStyles();

  const [deepLinked, setDeepLinked] = useState<boolean>(false);

  const redirectMaybe = useMemo(
    () => !!link && deepLinked && <Redirect to={link} />,
    [deepLinked, link]
  );

  const scaledValue = useMemo(() => (value / scalar) * 100, [scalar, value]);
  const rScale = useMemo(() => 1 - rPad * index, [index, rPad]);
  const transform = useMemo(() => 21 * index * rPad, [index, rPad]);

  return scaledValue ? (
    <>
      <circle
        className={classes.segment}
        cx="21"
        cy="21"
        fill="none"
        onClick={() => setDeepLinked(true)}
        r="15.91549430918954"
        stroke={color}
        strokeDasharray={`${scaledValue} ${100 - scaledValue}`}
        strokeDashoffset="25"
        strokeLinecap="round"
        transform={`translate(${transform} ${transform}) scale(${rScale}, ${rScale})`}
      />
      {redirectMaybe}
    </>
  ) : null;
};

export default Segment;
