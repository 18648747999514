import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    label: {
      color: palette.text.primary,
      fontSize: 12,
      marginTop: 15,
      order: 0,
      paddingBottom: 5,
    },
    radios: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 10,
      order: 1,
      width: '100%',
    },
    root: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
    },
    rootVertical: {
      flexDirection: 'column-reverse',
    },
    verticalRadios: {
      border: `1px solid ${palette.components.teamSelector.default}`,
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      padding: '20px',
      marginTop: '8px',
      marginBottom: '28px',
      paddingTop: '10px',
      paddingBottom: '10px',
      maxHeight: '220px',
      overflow: 'auto',
      width: '100%',

      '&::-webkit-scrollbar': {
        width: '10px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        background: palette.common.almostWhite,
        borderRadius: '10px',
      },
    },
  })
);

export default useStyles;
