/**
 * Warning! This file is not meant to be updated frequently!
 * Please make sure you actually need to modify THIS file before doing so!
 *
 * You must meet the following criteria before modifying this file...
 * 1. You are changing something that will affect every theme
 * 2. You think that this is silly, and putting css in js is very silly
 */

import _ from 'lodash';
import _config from './_config';
import _base from './_base';
import Color from 'color';

export const defaultStyleConfig = {
  inputBaseStyles: {
    maxHeight: 38,
    padding: 10,
    position: 'relative',
    background: _config.colors.darkGrey,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: _config.colors.lightBlueGrey,
    fontSize: _base.typography.fontSize,
    lineHeight: 1,
  },
};

export default (themeConfigOverrides = {}) => {
  const config = _.merge({}, defaultStyleConfig, themeConfigOverrides);

  return {
    MuiButton: {
      root: {
        fontWeight: 400,

        '& + button': {
          marginLeft: 10,
        },
      },

      label: {
        fontWeight: 400,
      },
    },

    MuiSelect: {
      root: {
        minHeight: '1.1875em',
      },

      // input: {
      //   ...config.inputBaseStyles,
      //   padding: '10px 40px 10px 10px',
      // },

      icon: {
        right: 5,
      },

      selectMenu: {
        minWidth: 30,
        display: 'flex',
        alignItems: 'center',
        borderRadius: _base.shape.borderRadius,
        lineHeight: 1,
      },
    },

    MuiMenuItem: {
      root: {
        display: 'flex',
        alignItems: 'center',
      },
    },

    MuiInputBase: {
      root: {
        display: 'flex',

        '&:before': {
          borderBottom: 'none !important',
        },
      },

      input: {
        ...config.inputBaseStyles,

        '&:disabled': {
          color: Color(config.primaryText)
            .alpha(0.75)
            .string(),
        },
      },

      multiline: {
        padding: 0,
      },
    },

    MuiInput: {
      inputMultiline: config.inputBaseStyles,
    },

    MuiCard: {
      root: {
        overflow: 'visible',
        marginBottom: 10,
      },
    },

    MuiCardContent: {
      root: {
        '> &:last-child': {
          paddingBottom: 0,
        },

        '&:last-child': {
          paddingBottom: 16,
        },
      },
    },

    MuiTableCell: {
      root: {
        maxWidth: 200,
        padding: '5px 15px',
      },
    },

    MuiTablePagination: {
      selectIcon: {
        top: '50%',
        transform: 'translateY(-50%)',
      },
    },

    MuiDialog: {
      root: {
        overflowY: 'visible',
      },
      paper: {
        maxWidth: 900,
        minWidth: 300,
        overflowY: 'visible',

        '@media screen and (max-width: 450px)': {
          borderRadius: 0,
          margin: 0,
        },
      },
    },

    MuiDialogContent: {
      root: {
        overflowY: 'visible',

        '& > *:first-child': {
          marginTop: 0,
        },
      },
    },

    MuiDialogActions: {
      root: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '24px 0 0 0',

        '& button': {
          marginLeft: 10,
        },
      },
    },

    MuiTab: {
      textColorInherit: {
        opacity: 1,
      },
      label: {
        textTransform: 'none',
        fontSize: _config.textSizes.normal,
      },
    },
  };
};
