import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import withRouter from 'components/hocs/withRouter';
import Table from 'components/RDGTables';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  teamsTableColumnChange,
  updateUISettings,
} from 'reducers/UiSettingsReducers';
import {
  getUISettingsTableHeaders,
  getUISettingsWithout,
} from 'reducers/UiSettingsSelectors';
import { bindActionCreators, compose } from 'redux';
import ZButton from 'UI/Buttons/ZButton';
import ReactToCSV from 'utility/ReactCSVDownloader';
import { toggleModalDiffered } from 'utils/storeUtils';
import useBackfill from 'utils/useBackfill';
import { withDirtyState } from 'utils/withDirtyState';
import withMemoizedProps from 'utils/withMemoizedProps';
import ProtectedComponent from '../../protected/ProtectedComponent';
import tableQuery from './tableQuery';
import { teamsColumnMapping } from './teamsTable.mapping';

const Teams = ({
  classes,
  currentTableHeaders,
  definedUser,
  jiggleDirtyState,
  q: query,
  rqps,
  teamsTableColumnChange,
  teamsUiSettings,
  updateUISettings,
  updateUrl,
}) => {
  useBackfill({
    query,
    requiredQueryParams: rqps,
    storedQueryParams: teamsUiSettings,
    updateUrl: updateUrl,
  });

  const handleColumnChange = (...args) => {
    jiggleDirtyState();
    teamsTableColumnChange(...args);
  };
  const onPaginationChange = (update) => {
    updateUISettings({
      domain: 'teams',
      update,
    });

    updateUrl(update);
  };

  return (
    <div>
      <div className="view__header">
        <h1>Team Management</h1>
        <ProtectedComponent allow={{ users: 'manage' }}>
          <ZButton
            styleName="titleBar"
            action={toggleModalDiffered(
              'TeamsCreateEdit',
              {},
              {
                title: 'Create New Team',
              }
            )}
            buttonText="Add New Team"
            color="secondary"
            icon={{ elem: AddIcon }}
            proLabel
          />
        </ProtectedComponent>
      </div>
      <Table
        classes={classes}
        columnHeaders={currentTableHeaders}
        defaultSortInfo={{}}
        footerComponents={() => (
          <ReactToCSV
            buttonText="exportCSV"
            dataMap={teamsColumnMapping}
            fileName="teams-table"
            header={currentTableHeaders.filter(
              (column) => column.id !== 'actions'
            )}
            table={() => tableQuery(query, true)}
          />
        )}
        heightBuffer={296}
        definedUser={definedUser}
        fetchTableData={() => tableQuery(query)}
        isSuperUser={definedUser === 'superAdminUser'}
        onColumnChange={handleColumnChange}
        onPaginationChange={onPaginationChange}
        query={query}
        rowMapping={teamsColumnMapping}
        tableId="Teams"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  accountId: _.get(state, 'user.account.id'),
  createEditModal: _.get(state, 'ui.modals.TeamsCreateEdit'),
  currentTableHeaders: getUISettingsTableHeaders(state, 'teams'),
  teamsUiSettings: getUISettingsWithout(state, 'teams', ['tableHeaders']),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUISettings,
      teamsTableColumnChange,
    },
    dispatch
  );

const styles = () => ({
  actionIcon: {
    cursor: 'pointer',
  },

  editLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
});

export default compose(
  withStyles(styles),
  withRouter,
  withDirtyState(),
  connect(mapStateToProps, mapDispatchToProps)
)(
  withMemoizedProps(Teams, [
    'createEditModal',
    'currentTableHeaders',
    'dirtyState',
    'q',
    'teamsUiSettings',
    'updateUISettings',
  ])
);
