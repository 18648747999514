import { makeStyles } from '@material-ui/styles';

// Usage of 'any' inside this style decorator is discouraged; however, our theme
// currently does not conform to MUI's theme structure standards. So this will
// be a followup fix for later.
const useStyles = makeStyles(() => ({
  cardRoot: {
    boxShadow: 'none',
  },
  labelStyle: {
    fontSize: 14,
  },
  subheader: {
    fontSize: 12,
    textAlign: 'left',
  },
  twoColumnGroup: {
    alignItems: 'flex-end',
    display: 'grid',
    gridAutoFlow: 'row',
    gridGap: '5px',
    gridTemplateColumns: '50% 50%',
    gridTemplateRows: 'auto auto',
  },
}));

export default useStyles;
