import { withStyles } from '@material-ui/core/styles';
import { updateProtectedApp } from 'api/zShieldService';
import axios from 'axios';
import withRouter from 'components/hocs/withRouter';
import { default as React, useState } from 'react';
import { publishEvent } from 'utils/eventUtils';
import { openSnackBar, toggleModalDirect } from 'utils/storeUtils';
import Form from './Form';
import { IDescData, IFormSubmit } from './Form/models';
import { formatErrorMessage } from './Form/utils';
import styles from './styles';

interface IZShieldEditDescProps {
  data: Partial<IDescData>;
}

const ZShieldEditDesc: React.FC<IZShieldEditDescProps> = ({ data }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (values: IFormSubmit) => {
    try {
      await updateProtectedApp(
        { appId: data.id },
        { os: 'android', ...values }
      );
      publishEvent('zshield:reload-tables');
      toggleModalDirect('ZShieldEditDesc', false);
      openSnackBar(`${data?.name ?? ''} Successfully Updated`);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const status = e?.response?.status ?? -1;
        const statusText = e?.response?.statusText ?? 'Error';
        const dataString =
          typeof e?.response?.data === 'string'
            ? e.response.data
            : 'Please Try Again';
        console.log(e?.response);
        setErrorMessage(formatErrorMessage(statusText, dataString, status));
      } else {
        console.log(e);
        setErrorMessage(formatErrorMessage('Upload Error', 'Please Try Again'));
      }
    }
  };

  return (
    <Form data={data} errorMessage={errorMessage} handleSubmit={handleSubmit} />
  );
};

export default withStyles(styles)(withRouter(ZShieldEditDesc));
