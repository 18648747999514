import { createStyles, makeStyles } from '@material-ui/styles';

// Usage of 'any' inside this style decorator is discouraged; however, our theme
// currently does not conform to MUI's theme structure standards. So this will
// be a followup fix for later.
const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    textFieldRoot: {
      backgroundColor: palette.background.default,
      order: 1,
      overflow: 'auto',
      '& > div.MuiInputBase-root': {
        paddingTop: 0,
        paddingBottom: 0,
        borderRadius: 0,
      },

      '& fieldset': {
        borderColor: palette.primary.border,
      },

      '& input': {
        border: 0,
        outline: 'none',
      },
    },
    large: {
      borderRadius: 4,

      /**
       * Color overrides will go here later (i.e.):
       
       * '& input[type=text]': {
       *   color: 'white',
       *   backgroundColor: 'transparent',
       *  },
       */

      '& fieldset': {
        border: 0,
      },

      '& > div': {
        minHeight: 46,
      },
    },
    popper: {
      zIndex: 9999999999,
      '& div': {
        backgroundColor: palette.background.default,
        overflowX: 'hidden',
        overflowY: 'auto',
        // border: `1px solid ${palette.primary.border}`,
        borderRadius: 0,
        textAlign: 'left',
      },
      '& .MuiAutocomplete-listbox': {
        border: '0x',
        marginBottom: '5px',
        marginTop: '5px',
        maxHeight: 250,
        overflow: 'auto',
      },
    },
    chipMargin: {
      margin: '5px',
    },
  })
);

export default useStyles;
