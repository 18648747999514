import _ from 'lodash';
import React, { PureComponent, Fragment } from 'react';
import withRouter from 'components/hocs/withRouter';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// components
import ZButton from 'UI/Buttons/ZButton';

// ui
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

// utils
import {
  openSnackBar,
  toggleModalDiffered,
  toggleModalDirect,
} from 'utils/storeUtils';

// services
import { deleteSelectedTeam, requestAllTeams } from 'api/apis';
import { fetchSelectedTeamById } from 'api/TeamsService';
import { updateAvailableTeams } from 'reducers/TeamReducers';
import { publishEvent } from '../../utils/eventUtils';

const labels = {
  INAPP: 'Apps',
  GROUP: 'Groups',
  USERS: 'Users',
  TRM: 'Threat Policies',
  PRIVACY: 'Privacy Policies',
  APP_SETTINGS: 'App Settings',
  PHISHING_POLICIES: 'Phishing Policies',
  PHISHING: 'Phishing Policies',
  ZSCAN_APP: 'zScan Apps',
  ZSCAN_POLICY: 'zScan Policies'
};

const initialState = {
  isReady: false,
  hasAssociations: false,
  associations: [],
  errors: [],
};

class TeamsDeleteConfirm extends PureComponent {
  state = initialState;

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { props } = this;

    fetchSelectedTeamById({ teamId: _.get(props, 'data.rowData.id') })
      .then(response => {
        const references = _.get(response, 'data.references', []);
        const associations = references.reduce((accum, ref) => {
          const label = _.get(labels, `${ref.entityType}`, ref.entityType);
          return {
            ...accum,
            [label]: accum[label]
              ? accum[label] + 1
              : 1,
          };
        }, {});

        this.setState({
          hasAssociations: Object.keys(associations).length,
          associations,
          isReady: true,
        });
      })
      .catch(() => {
        this.setState({
          isReady: true,
          errors: [
            {
              message:
                'Error occurred when trying to retrieve associations, please try again.',
            },
          ],
        });
      });
  }

  render() {
    const { props, state } = this;
    if (!state.isReady) {
      return (
        <DialogContent>
          <p>Checking for associations...</p>
        </DialogContent>
      );
    }

    if (state.errors.length) {
      return (
        <DialogContent>
          <ul>
            {state.errors.map(error => (
              <li>{error.message}</li>
            ))}
          </ul>
        </DialogContent>
      );
    }

    return (
      <DialogContent>
        <form onSubmit={this.handleSubmit}>
          {state.hasAssociations ? (
            <Fragment>
              <p>
                You cannot delete the team {_.get(props, 'data.rowData.name')}{' '}
                because it has the following associations:
              </p>
              <ul>
                {Object.keys(state.associations).map((key, index) => {
                  const value = state.associations[key];
                  return (
                    <li key={index}>
                      {key}: {value}
                    </li>
                  );
                })}
              </ul>
            </Fragment>
          ) : (
            <p>
              Are you sure you want to delete{' '}
              {_.get(props, 'data.rowData.name')}?
            </p>
          )}

          <DialogActions>
            <ZButton
              styleName="submit"
              action={toggleModalDiffered('TeamsDeleteConfirm', false)}
              color="secondary"
              buttonText="Cancel"
            />

            <ZButton
              isDisabled={state.hasAssociations}
              buttonType="submit"
              color="primary"
              styleName="modalSave"
              buttonText="Delete Team"
            />
          </DialogActions>
        </form>
      </DialogContent>
    );
  }

  handleSubmit(event) {
    event.preventDefault();
    const { props } = this;

    deleteSelectedTeam({ teamId: _.get(props, 'data.rowData.id') })
      .then(() => {
        publishEvent('table:force-fetch-teamsTable');
        toggleModalDirect('TeamsDeleteConfirm', false);
        openSnackBar(
          `${_.get(props, 'data.rowData.name', 'Team')} Successfully Deleted`
        );
        // fetch all teams and update the global team selector in side bar
        requestAllTeams()
          .then(resp => {
            if (resp.data.length) {
              props.updateAvailableTeams(resp.data);
            }
          })
          .catch(error => {
            console.error('Error fetching teams', error);
          });
      })
      .catch(error => {
        console.log('Error in deleting Team: ', error);
      });
  }
}

TeamsDeleteConfirm.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string,
    name: PropTypes.string,
  }),
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateAvailableTeams,
    },
    dispatch
  );
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(TeamsDeleteConfirm)
);
