import { useCallback, useEffect, useState } from 'react';
import { fetchShallowPlansList } from 'api/PlansService';

export default () => {
  const [plans, setPlans] = useState([]);
  const [plansCheckboxList, setPlansCheckboxList] = useState([]);

  const fetchPlans = useCallback(async () => {
    const { data } = await fetchShallowPlansList();
    setPlans(data);
    const formatToCheckboxList = data.map(singlePlan => ({
      name: singlePlan.name,
      value: singlePlan.id,
      disabled: singlePlan.deprecated,
    }));
    setPlansCheckboxList(formatToCheckboxList);
  }, []);

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);

  return { plansCheckboxList, plans };
};
