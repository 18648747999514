import { ILocationQuery, ITableQuery } from '../models';

const tableQueryToLocationQuery = (
  type: string,
  update: ITableQuery,
  querySize: string
): Partial<ILocationQuery> => {
  const result: Partial<ILocationQuery> = {};

  if (type === 'limit') {
    result.size = update as unknown as string | number;
  } else if (type === 'sort') {
    result.orderBy = update.id;
    result.order = (update.dir as unknown as number) === 1 ? 'asc' : 'desc';
  } else if (type === 'skip') {
    result.page = parseInt((update as unknown as number) / parseInt(querySize) as unknown as string);

  }

  return result;
};

export default tableQueryToLocationQuery;
