import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import ZButton from 'UI/Buttons/ZButton';

const TermsOfService = props => {
  return (
    <div className={props.classes.mainContent}>
      <ZButton onClick={props.history.goBack} buttonText="&#8249; Back" />
      <h2 style={{ textAlign: 'center' }}>
        ZIMPERIUM, INC. END USER LICENSE AGREEMENT
      </h2>
      <h2 style={{ textAlign: 'center' }}>END USER LICENSE AGREEMENT</h2>
      <p className="p3">
        <span className="s1">
          <strong>
            THIS DOCUMENT IS A LEGAL CONTRACT.
            <span className="Apple-converted-space">&nbsp; </span>PLEASE READ IT
            CAREFULLY.
          </strong>
          <span className="Apple-converted-space">&nbsp; </span>These End User
          License Terms (&ldquo;
          <strong>
            <em>Terms</em>
          </strong>
          &rdquo;) govern your access to and use of the products including
          client-side mobile application software (the &ldquo;
          <strong>
            <em>Software</em>
          </strong>
          &rdquo;) provided by Zimperium, Inc. (&ldquo;
          <strong>
            <em>Zimperium</em>
          </strong>
          ,&rdquo; &ldquo;
          <strong>
            <em>we</em>
          </strong>
          ,&rdquo; or &ldquo;
          <strong>
            <em>us</em>
          </strong>
          &rdquo;), and the zCONSOLE cloud-based software-as-a-service
          application that we make available through or in connection with the
          Software (the &ldquo;
          <strong>
            <em>Cloud Application</em>
          </strong>
          &rdquo;).&nbsp;
        </span>
      </p>
      <p className="p4">&nbsp;</p>
      <p className="p3">
        <span className="s1">
          <strong>1. Acceptance of these Terms</strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          Your use of and access to the Software and Cloud Application are
          conditioned on your compliance with these Terms and all applicable
          local, state, national, and international laws, rules and regulations.{' '}
        </span>
        <span className="s2">
          <strong>
            By installing the Software or by using the Software or Cloud
            Application you are indicating that you understand and agree to
            abide by these Terms.
          </strong>
        </span>
        <span className="s1">
          {' '}
          If the Software or Cloud Application is to be used by or on behalf of
          a company or other organization, the individual expressing acceptance
          of these Terms represents and warrants that he or she has the
          authority to bind that company or other organization to these Terms,
          and &ldquo;you,&rdquo; and &ldquo;your&rdquo; will refer to that
          company or other organization. If you do not accept all of these
          Terms, then we are unwilling to license the Software or make the Cloud
          Application available to you, and you must delete all copies of the
          Software without retaining any copies thereof.
        </span>
      </p>
      <p className="p4">&nbsp;</p>
      <p className="p3">
        <span className="s1">
          <strong>2. Rights to Use the Software and Cloud Application</strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          Subject to your compliance with these Terms, we grant you a
          non-exclusive, non-transferable license to: (i) install one copy of
          the Software on each compatible mobile device for which we have issued
          you a valid license for that Software (each, an &ldquo;
          <strong>
            <em>Authorized Device</em>
          </strong>
          &rdquo;); and (ii) use the Software on each such Authorized Device,
          and access and use the Cloud Application in connection with your
          Authorized Device(s), solely for your own personal or internal
          business use during the license period for which we have issued you
          the applicable license(s). The foregoing rights may be suspended or
          terminated as set forth in these Terms. For non-evaluation and other
          paid licenses, the foregoing rights are also conditioned upon your
          timely payment and our receipt of all associated fees (as specified by
          us at the time you selected the license) and applicable taxes, if any.
        </span>
      </p>
      <p className="p4">&nbsp;</p>
      <p className="p3">
        <span className="s1">
          <strong>3. Prohibitions</strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>a. </strong>The Software and Cloud Application are intended to
          be used only in accordance with Zimperium&rsquo;s applicable
          documentation and only to evaluate, manage, and protect the security
          of mobile devices and wireless networks that you own or control. Any
          other use of the Software and Cloud Application is strictly
          prohibited. Prohibited uses include (without limitation) using the
          Software or Cloud Application to attack, probe, assess the security
          of, or interfere with any third party&rsquo;s network, device or other
          target without that third party&rsquo;s express, informed
          authorization. You may not disclose any vulnerability discovered,
          reproduced, or confirmed through the use of the Software or Cloud
          Application except in accordance with industry accepted vulnerability
          disclosure practices. You acknowledge that improper use of the
          Software or Cloud Application could significantly harm your network
          and/or devices, and you assume all risks associated with your use.
          <span className="Apple-converted-space">&nbsp; </span>You further
          acknowledge that any reports or documentation generated or produced in
          connection with Zimperium product(s) or services is considered
          confidential and the property of Zimperium and no part of these
          materials may be copied, used, or disclosed except with written
          permission of Zimperium.&nbsp;
        </span>
      </p>
      <p className="p4">&nbsp;</p>
      <p className="p5">
        <span className="s1">
          <strong>b. </strong>You may not, and you agree not to: (i) copy,
          modify or distribute the Software for any purpose; (ii) transfer,
          sublicense, lease, lend, rent or otherwise distribute the Software or
          the Cloud Application to any third party; (iii) decompile,
          reverse-engineer, disassemble, or create derivative works of the
          Software or the Cloud Application; (iv) make the functionality of the
          Software or the Cloud Application available to multiple users through
          any means; (v) use the Software or Cloud Application in any unlawful
          manner, for any unlawful purpose, or in any manner inconsistent with
          these Terms or our applicable documentation; (vi) access or use any
          areas of the Cloud Application for which we have not granted you
          authorization, or tamper or interfere with our computer systems or the
          technical delivery systems of our providers;
          <span className="Apple-converted-space">&nbsp; </span>(
          <strong>
            <em>
              vii) copy, use, or disclose any reports or other documentation
              provided by Zimperium
            </em>
          </strong>
          ; (viii) gather or use information, such as other users&rsquo; names,
          real names, or email addresses, through the Cloud Application to
          transmit any unsolicited advertising, junk mail, spam or other form of
          solicitation; or (ix) encourage or enable anyone to do any of the
          foregoing.
        </span>
      </p>
      <p className="p6">&nbsp;</p>
      <p className="p5">
        <span className="s1">
          <strong>c. </strong>You promise to indemnify and hold harmless
          Zimperium and its representatives fully against any claims,
          liabilities, costs, expenses, and other harm arising from your
          unauthorized use of the Software or Cloud Application or any other
          violation of these Terms (including any of the prohibitions stated
          above). We reserve the right, but have no obligation, to monitor
          compliance with the prohibitions set forth above, and we may
          investigate and prosecute violations of any of the above and/or
          involve and cooperate with law enforcement authorities in prosecuting
          users who violate these Terms.
        </span>
      </p>
      <p className="p4">&nbsp;</p>
      <p className="p3">
        <span className="s1">
          <strong>4. Ownership</strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          Your rights with respect to the Software and Cloud Application are
          limited to those expressly granted in Section 2 above. Zimperium and
          its licensors reserve sole and exclusive ownership of the Software and
          Cloud Application and all copyrights, patents, trademarks, and other
          intellectual property rights therein. You may not remove, alter, or
          obscure any copyright, trademark, or other proprietary rights notices
          appearing on the Software or Cloud Application. If you provide us with
          any suggestions, comments, or other feedback regarding the Software or
          Cloud Application (&ldquo;
          <strong>
            <em>Feedback</em>
          </strong>
          &rdquo;) you acknowledge that such Feedback will become the exclusive
          property of Zimperium, and we may use (or not use) any such Feedback
          in any manner and for any purpose, without compensation to you and
          without implying or creating any interest on your part in any of our
          products or services that may be based on such Feedback. You hereby
          irrevocably assign to us all right, title, and interest in any
          Feedback you provide.
        </span>
      </p>
      <p className="p4">&nbsp;</p>
      <p className="p3">
        <span className="s1">
          <strong>5. Modifications</strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          The Cloud Application and Software may change from time to time (for
          example, we may push updates to your Authorized Device, increase or
          decrease server capacity, or modify our user interface), and/or we may
          stop providing the Cloud Application or any of its features
          (permanently or temporarily), possibly without prior notice to you. In
          the case of paid licenses, however, we will not discontinue the
          essential features of the Software or Cloud Application during your
          paid license period without offering a reasonable substitute or
          alternative. We may decide to charge users a fee for the use of
          certain features, although if we do, we will provide you with prior
          notice before such fees take effect.{' '}
        </span>
      </p>
      <p className="p4">&nbsp;</p>
      <p className="p3">
        <span className="s1">
          <strong>6. Data Collection and Transmission</strong>
        </span>
      </p>
      <p className="p4">&nbsp;</p>
      <p className="p4">&nbsp;</p>
      <p className="p5">
        <span className="s1">
          <strong>a.</strong>{' '}
        </span>
        <span className="s3">
          If the Software is being delivered to you by an entity you are
          affiliated with (such as an employer, etc.), then the exact nature of
          any information collected is determined by the entity that made the
          Software available to you and is available by request from that
          entity. If you acquired the Software directly from Zimperium, then
          Zimperium will determine which information is collected and is
          available upon request from Zimperium.
        </span>
      </p>
      <p className="p6">&nbsp;</p>
      <p className="p3">
        <span className="s1">
          The Software does not require any personally identifiable information
          from you, but it may collect information from the Authorized Device
          that is useful for the purpose of the Software and Cloud
          Application&rsquo;s functionality, called &ldquo;
          <strong>
            <em>Device Data</em>
          </strong>
          &rdquo;. Device Data collected may include but is not limited to the
          following:
        </span>
      </p>
      <ul className="ul1">
        <ul className="ul1">
          <ul className="ul1">
            <li className="li7">
              <span className="s5">
                The manufacturer and model of your Authorized Device;
              </span>
            </li>
            <li className="li7">
              <span className="s5">
                Certain technical settings of your Authorized Device, including
                the display size of your Authorized Device and firmware version;
              </span>
            </li>
            <li className="li7">
              <span className="s5">
                Your IP address (which can indicate your country and
                geolocation);
              </span>
            </li>
            <li className="li7">
              <span className="s5">
                Wireless carrier for your Authorized Device;
              </span>
            </li>
            <li className="li7">
              <span className="s5">
                The type and version of operating system on your Authorized
                Device;
              </span>
            </li>
            <li className="li7">
              <span className="s5">
                Certain configuration data of your Authorized Device, such as
                whether your Authorized Device is configured to allow root
                access or whether hardware restrictions of the Authorized Device
                have been removed;
              </span>
            </li>
            <li className="li7">
              <span className="s5">
                In some cases, metadata of all applications installed on your
                Authorized Device (including, but not limited to, the name of
                the app, the version of the app);
              </span>
            </li>
            <li className="li7">
              <span className="s5">
                Metadata about networks your Authorized Device connects to
                (including, but not limited to, the SSID of the network, the MAC
                address of the network);
              </span>
            </li>
            <li className="li7">
              <span className="s5">
                In certain circumstances, we may also collect a copy of the
                application;
              </span>
            </li>
            <li className="li7">
              <span className="s5">
                Data from tracking tools used to analyze product performance on
                your Authorized Device; and
              </span>
            </li>
            <li className="li7">
              <span className="s5">
                System monitoring data such as memory utilization, process
                metrics, network statistics (but no data like web pages or
                emails), and other non-user-identifiable type monitoring values.
              </span>
            </li>
          </ul>
        </ul>
      </ul>
      <p className="p4">&nbsp;</p>
      <p className="p3">
        <span className="s1">
          The Software and Cloud Application may also collect &ldquo;
          <strong>
            <em>Forensic Data</em>
          </strong>
          &rdquo;, defined here as information about the attacker when and if
          such an attack occurs. When an attack occurs, Forensic Data collected
          may include but is not limited to the following:
        </span>
      </p>
      <ul className="ul1">
        <ul className="ul1">
          <ul className="ul1">
            <li className="li7">
              <span className="s5">
                The Device Data mentioned above at the time of the attack;
              </span>
            </li>
            <li className="li7">
              <span className="s5">
                The device location data at the time of the attack;
              </span>
            </li>
            <li className="li7">
              <span className="s5">
                The MAC address of the suspected attacker;
              </span>
            </li>
            <li className="li7">
              <span className="s5">
                The application name, bundle ID, and version of the suspected
                malicious apps.
              </span>
            </li>
          </ul>
        </ul>
      </ul>
      <p className="p4">&nbsp;</p>
      <p className="p3">
        <span className="s1">
          Device Data and Forensic Data together, are hereinafter referred to as
          &ldquo;
          <strong>
            <em>Data</em>
          </strong>
          &rdquo;. The{' '}
        </span>
        <span className="s3">
          exact nature of any Data collected is determined by the entity that
          made the Software available to you.
          <span className="Apple-converted-space">&nbsp; </span>
          <strong>
            <em>
              Data may be provided to you in various forms or documentation and
              any such documentation is considered confidential and proprietary
              of Zimperium and you are prohibited from copying or disclosing
              same outside of the permissible use as defined in this Agreement
              without prior written consent of Zimperium.&nbsp;
            </em>
          </strong>
        </span>
      </p>
      <p className="p4">&nbsp;</p>
      <p className="p5">
        <span className="s1">
          <strong>b.</strong> You consent to our collection, transmission,
          storage, monitoring, copying, processing, analysis and use of the Data
          to administer the Cloud Application, to develop and improve the
          Software and Cloud Application and our other products and services,
          and to monitor compliance with these Terms. You acknowledge that this
          may include transmission of your Data to or from locations in the
          United States, Europe, and other countries or jurisdictions,
          potentially including those outside of where you reside or use the
          Software. We may also disclose Forensic Data as needed to cooperate
          with law enforcement activities and otherwise to fulfill our legal
          obligations and protect our legal rights. You are solely responsible
          for securing any privacy-related rights and permissions from your
          individual users of the Software, consistent with this Section, as may
          be required by local law or by your organization&rsquo;s internal
          policies.
        </span>
      </p>
      <p className="p4">&nbsp;</p>
      <p className="p8">
        <span className="s6">
          <strong>7. User Rights Under </strong>
        </span>
        <span className="s1">
          <strong>General Data Protection Regulation (GDPR)</strong>
        </span>
      </p>
      <p className="p9">
        <span className="s1">
          Zimperium cares about your data privacy and wants to be transparent
          about the following:
        </span>
      </p>
      <ul className="ul1">
        <li className="li10">
          <span className="s8">What data is collected.</span>
        </li>
        <li className="li10">
          <span className="s8">How the data is used.</span>
        </li>
        <li className="li10">
          <span className="s8">Your rights to control the data.</span>
        </li>
        <li className="li10">
          <span className="s8">
            How Zimperium&rsquo;s Software is enhanced to accommodate GDPR.
          </span>
        </li>
      </ul>
      <p className="p9">
        <span className="s1">
          Zimperium has made changes to our Software and our privacy statement.
          These changes align with the standards established by the General Data
          Protection Regulation (GDPR). This regulation is a set of laws passed
          in the European Union. Zimperium respects your personal data, and
          never sells it to third parties.
        </span>
      </p>
      <p className="p9">
        <span className="s1">
          For more information on GDPR and limiting personally identifiable
          information (PII) data, see the following website:&nbsp;
          <a href="https://www.eugdpr.org/">
            <span className="s9">https://www.eugdpr.org/</span>
          </a>
        </span>
      </p>
      <p className="p9">
        <span className="s1">
          For an end user with the MTD application, Zimperium supports the
          following user requests:
        </span>
      </p>
      <ul className="ul1">
        <li className="li10">
          <span className="s8">General GDPR Information Inquiry</span>
        </li>
        <li className="li10">
          <span className="s8">The Right to Access</span>
        </li>
        <li className="li10">
          <span className="s8">The Right to Be Forgotten</span>
        </li>
      </ul>
      <p className="p9">
        <span className="s1">For each of these requests you can:</span>
      </p>
      <ul className="ul1">
        <li className="li10">
          <span className="s8">
            Request general GDPR information using the email&nbsp;
            <a href="mailto:gdpr@zimperium.com">
              <span className="s9">gdpr@zimperium.com</span>
            </a>
            .
          </span>
        </li>
        <li className="li10">
          <span className="s8">
            Request an inquiry (or &ldquo;Right to Access&rdquo;) for the data
            persisted about you.
          </span>
        </li>
      </ul>
      <p className="p9">
        <span className="s1">
          This is requested on the Zimperium Customer Support Portal by entering
          a ticket on this website:{' '}
          <a href="https://support.zimperium.com/">
            <span className="s9">https://support.zimperium.com</span>
          </a>
        </span>
      </p>
      <ul className="ul1">
        <li className="li10">
          <span className="s8">
            Request a removal of your information (or &ldquo;Right to Be
            Forgotten&rdquo;) for the data persisted about you. This is
            requested on the Zimperium Customer Support Portal by entering a
            ticket on this website:&nbsp;
            <a href="https://support.zimperium.com/">
              <span className="s9">https://support.zimperium.com</span>
            </a>
            .
          </span>
        </li>
      </ul>
      <p className="p10">
        <span className="s1">
          <strong>Note</strong>: The &ldquo;Right to be Forgotten&rdquo; request
          causes your information to be&nbsp;permanently removed and going
          forward you will be an anonymous user to the Software. This request
          cannot be rolled back or undone.
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>8. Contacting You</strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          As part of making the Cloud Application available to you, we may need
          to provide you with certain communications, such as service
          announcements and administrative messages. These communications are
          considered part of the Cloud Application, which you may not be able to
          opt-out from receiving.{' '}
        </span>
      </p>
      <p className="p4">&nbsp;</p>
      <p className="p3">
        <span className="s1">
          <strong>9. Third-Party Software</strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          The Software and/or Cloud Application may be accompanied by or utilize
          certain third-party software components, libraries or applications
          that are distributed (with or without modifications) under open-source
          licensing terms (the &ldquo;
          <strong>
            <em>Open-Source Components</em>
          </strong>
          &rdquo;). Your rights with respect to the Open-Source Components are,
          to the extent of any conflict with these Terms, governed by and
          subject to the terms of the open-source licenses under which they are
          distributed. You are responsible for complying with those licenses.
          Please refer to our website for more specific information regarding
          the Open-Source Components that we redistribute and the licenses that
          apply to them. You may not assume or infer that we endorse, or that we
          have reviewed, verified, or authenticated, any Open-Source Components
          or other third-party software that may be furnished with, available
          through, or used in connection with the Software or Cloud Application.
          Open-Source Components and any other third-party software, and the
          information or results provided by them, may be unreliable,
          inaccurate, incomplete, delayed, or otherwise defective. We make no
          representations, warranties, or guarantees in connection with any
          third-party software or the information or results provided by it. You
          acknowledge sole responsibility for and assume all risk arising from
          your use of or reliance on any third-party software.
        </span>
      </p>
      <p className="p4">&nbsp;</p>
      <p className="p3">
        <span className="s1">
          <strong>10. Verification and Audit </strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          We may (but are not required to) monitor the use of the Software and
          Cloud Application, including by tracking the device IDs and license
          key(s) associated with the Authorized Devices on which the Software is
          used, for purposes of verifying compliance with these Terms. In
          addition, you agree to track and keep records of the individual users
          and Authorized Device(s) using the Software and promptly notify us if
          you learn of any unlicensed use. At our written request, you will
          provide us with a certification signed by you (or, if you are an
          organization, by an officer of the organization) verifying that the
          Software and Cloud Application are being used in compliance with these
          Terms. We may, at any time during the term of your license or for one
          year thereafter, upon reasonable written notice, audit your use of the
          Software. We may use a third-party organization to assist us in
          conducting such an audit. You agree to cooperate with us in such audit
          and will promptly make available to us all information, equipment and
          materials reasonably required by us to conduct such an audit. If an
          audit reveals that you have underpaid any license or service fees for
          the period audited, you agree to promptly pay us for such underpaid
          fees based on our price list in effect at the time the audit is
          completed. If the underpaid fees exceed five percent (5%) of the fees
          you paid for the relevant period, then you also agree to pay our
          reasonable costs of conducting the audit.
        </span>
      </p>
      <p className="p4">&nbsp;</p>
      <p className="p3">
        <span className="s1">
          <strong>11. Termination </strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          Your rights under these Terms will expire at the end of the period(s)
          for which you have acquired a valid license from us for the Software.
          The Software may cease to function once the license has expired. Any
          license renewals or extensions will be subject to availability and
          your payment of all applicable fees as then in effect. We have no
          obligation to offer license renewals or extensions, and we may
          condition renewals upon your acceptance of revised or new terms and
          conditions. Your rights under these Terms will automatically
          terminate, and we may suspend or terminate your Account and your use
          of the Software and Cloud Application, immediately and without notice
          if you breach any of these Terms. In addition, we may suspend your
          Account and your use of the Software and Cloud Application as we deem
          appropriate to prevent, investigate, or otherwise address any
          suspected misuse of the Software or Cloud Application. Upon expiration
          or termination of these Terms or your Account, you agree to promptly
          and permanently delete all copies of the Software that are on the
          Authorized Device(s) or otherwise in your possession or control. The
          provisions of Sections 3, 4, 10, and 12 through 17 will survive any
          expiration or termination of these Terms.
        </span>
      </p>
      <p className="p4">&nbsp;</p>
      <p className="p3">
        <span className="s1">
          <strong>12. Disclaimers</strong>
        </span>
      </p>
      <p className="p11">
        <span className="s1">
          <strong>a. </strong>You understand and agree that the Software and the
          Cloud Application are provided to you &ldquo;AS IS&rdquo; and on an
          &ldquo;AS AVAILABLE&rdquo; basis. TO THE FULLEST EXTENT PERMISSIBLE
          UNDER APPLICABLE LAW, WE DISCLAIM ANY AND ALL WARRANTIES, WHETHER
          EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING ANY IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET
          ENJOYMENT OR NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF
          COURSE OF DEALING OR USAGE OF TRADE. We make no warranty that the
          Software or the Cloud Application will meet your requirements, will
          detect or prevent all security threats or vulnerabilities, or be
          available on an uninterrupted, secure, or error-free basis.
        </span>
      </p>
      <p className="p4">&nbsp;</p>
      <p className="p3">
        <span className="s1">
          {' '}
          <strong>b. </strong>Some states or jurisdictions do not allow the
          exclusion of express or implied warranties in certain circumstances,
          so the above disclaimer may not apply to you. IN THAT EVENT, SUCH
          EXPRESS OR IMPLIED WARRANTIES SHALL BE LIMITED IN DURATION TO THE
          MINIMUM PERIOD REQUIRED BY THE APPLICABLE LAW.
        </span>
      </p>
      <p className="p4">&nbsp;</p>
      <p className="p3">
        <span className="s1">
          <strong>13. Limitation of Liability</strong>
        </span>
      </p>
      <p className="p11">
        <span className="s1">
          <strong>a. </strong>OUR TOTAL LIABILITY TO YOU FROM ALL CAUSES OF
          ACTION AND UNDER ALL THEORIES OF LIABILITY WILL NOT EXCEED THE AMOUNT
          OF FEES YOU PAID US FOR THE SOFTWARE LICENSE. IF YOU HAVE AN
          EVALUATION OR OTHER NON-PAID LICENSE, WE WILL HAVE NO LIABILITY TO YOU
          WHATSOEVER; PROVIDED, HOWEVER, THAT WHERE LEGAL LIABILITY CANNOT BE
          EXCLUDED, BUT MAY BE LIMITED, OUR TOTAL LIABILITY SHALL INSTEAD BE
          LIMITED TO THE SUM OF FIFTY U.S. DOLLARS ($50).
        </span>
      </p>
      <p className="p4">&nbsp;</p>
      <p className="p11">
        <span className="s1">
          <strong>b. </strong>WE WILL NOT BE LIABLE TO YOU FOR ANY INDIRECT,
          INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR FOR COSTS
          OF SUBSTITUTE GOODS OR SERVICES, OR FOR LOSS OF PROFITS, DATA, USE,
          GOODWILL, OR OTHER INTANGIBLE LOSSES, ARISING IN ANY WAY OUT OF THESE
          TERMS OR RESULTING FROM YOUR ACCESS TO, USE OF, OR INABILITY TO ACCESS
          OR USE THE SOFTWARE AND/OR CLOUD APPLICATION, WHETHER BASED ON
          WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE) OR ANY OTHER LEGAL
          THEORY, WHETHER OR NOT WE HAVE BEEN INFORMED OF THE POSSIBILITY OF
          SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE
          FAILED OF ITS ESSENTIAL PURPOSE.
        </span>
      </p>
      <p className="p4">&nbsp;</p>
      <p className="p11">
        <span className="s1">
          <strong>c.</strong> You acknowledge that it is your responsibility to
          implement back-up plans and other safeguards appropriate for the value
          of the networks, devices, data, and systems with which you use the
          Software and Cloud Application and, accordingly, that the foregoing
          exclusions and limitations of damages and liability are reasonable.
          The allocation of risk described in this Section 13 is an essential
          basis of the bargain between you and us, and without it, the fees
          charged for the Software and Cloud Application would be significantly
          higher. To the extent, if any, that applicable local laws prohibit the
          exclusion or limitation of certain liabilities or damages, the
          provisions of this Section 13 will apply only to the maximum extent
          permitted by such laws.&nbsp;
        </span>
      </p>
      <p className="p4">&nbsp;</p>
      <p className="p3">
        <span className="s1">
          <strong>14. Maintenance and Support</strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          We have no software maintenance or technical support obligations under
          these Terms. We may from time to time offer maintenance and support
          services or other add-on services for the Software or Cloud
          Application for you to purchase. Any add-on services will be subject
          to such separate fees and contract terms and conditions as we may
          specify when offering those services.
        </span>
      </p>
      <p className="p4">&nbsp;</p>
      <p className="p3">
        <span className="s1">
          <strong>15. Export Control</strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          You agree to comply fully with all relevant export laws and
          regulations of the United States and other applicable jurisdictions to
          ensure that neither the Software nor Cloud Application, nor any direct
          product thereof, are: (i) downloaded or otherwise exported or
          re-exported directly or indirectly in violation of such export laws
          and regulations; or (ii) used for any purposes prohibited by the such
          export laws and regulations, including but not limited to nuclear,
          chemical, or biological weapons proliferation.
        </span>
      </p>
      <p className="p4">&nbsp;</p>
      <p className="p3">
        <span className="s1">
          <strong>16. U.S. Government End Users</strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          The Software and Cloud Application consist of &ldquo;commercial
          items,&rdquo; &ldquo;commercial computer software&rdquo; and
          &ldquo;commercial computer software documentation,&rdquo; as those
          terms are used in FAR Subpart 12.2, DFARS 227.7202 and other
          government acquisition regulations, as applicable. Any use,
          duplication, or disclosure of the Software or Cloud Application by the
          U.S. government is permitted solely as expressly provided in Section 2
          above and is subject to restrictions as set forth in these Terms.
        </span>
      </p>
      <p className="p4">&nbsp;</p>
      <p className="p3">
        <span className="s1">
          <strong>17. General</strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          These Terms will be governed by and construed in accordance with the
          laws of the State of California, without regard to or application of
          conflict of laws rules or principles. The parties to these Terms
          irrevocably consent to the jurisdiction of, and venue in, the state or
          federal courts located in the State of California for any disputes
          arising under these Terms. Our failure to enforce any right or
          provision of these Terms will not be deemed a waiver of such right or
          provision. In the event that any provision of these Terms is held to
          be invalid or unenforceable, the remaining provisions of these Terms
          will remain in full force and effect. You may not assign, delegate or
          transfer these Terms or any of your rights or obligations hereunder
          without our prior written consent, and any attempt to do so will be
          void. We may assign these Terms without your consent. These Terms
          constitute the entire agreement between you and us regarding the
          Software and Cloud Application, and they supersede and replace any
          prior agreements and understandings between you and us regarding the
          Software or Cloud Application.
        </span>
      </p>
      <p className="p4">&nbsp;</p>
      <p className="p12">
        <span className="s1">
          <strong>18. Contacting Us </strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          If you have any questions regarding these Terms, please contact us via
          email at:{' '}
        </span>
        <span className="s10">
          <a href="mailto:licensing@zimperium.com">licensing@zimperium.com</a>
        </span>
        <span className="s1">.</span>
      </p>
      <p className="p4">&nbsp;</p>
      <p className="p2">&nbsp;</p>
      <p className="p12">
        <span className="s1">Agreement version: October 2020</span>
      </p>
    </div>
  );
};

const styles = ({ palette }) => ({
  mainContent: {
    display: 'block',
    padding: 55,
    background: palette.background.default,
    paddingBottom: 200,
  },
});

export default withStyles(styles)(withRouter(TermsOfService));
