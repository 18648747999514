import React from 'react';

export default ({ color }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="52.000000pt"
    height="15.000000pt"
    viewBox="0 0 52.000000 15.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,15.000000) scale(0.100000,-0.100000)"
      fill={color}
      stroke={color}
    >
      <path
        d="M294 125 c-18 -18 -24 -35 -24 -70 l0 -45 55 0 55 0 0 40 c0 42 -21
84 -46 94 -9 3 -26 -5 -40 -19z m54 -12 c2 -8 -6 -13 -22 -13 -25 0 -33 10
-19 24 10 10 36 3 41 -11z m-8 -58 c0 -16 -6 -25 -15 -25 -9 0 -15 9 -15 25 0
16 6 25 15 25 9 0 15 -9 15 -25z"
      />
      <path
        d="M6 133 c-8 -8 -8 -96 0 -117 9 -22 24 -10 24 20 0 32 23 31 48 -3 25
-35 41 -23 18 12 -16 24 -16 28 -2 50 26 40 17 52 -13 18 -33 -39 -51 -42 -51
-9 0 26 -12 40 -24 29z"
      />
      <path
        d="M400 103 c0 -5 7 -17 15 -27 13 -17 13 -22 -2 -38 -24 -27 -7 -35 20
-10 l23 22 24 -25 c25 -27 51 -23 26 4 -20 22 -20 35 -1 51 8 7 12 17 9 22 -3
5 -16 0 -28 -11 -28 -26 -38 -26 -54 -1 -12 20 -32 27 -32 13z"
      />
      <path
        d="M137 93 c-11 -10 -8 -83 3 -83 6 0 10 16 10 35 0 33 2 35 35 35 33 0
35 -2 35 -34 0 -19 5 -38 10 -41 6 -4 10 13 10 44 l0 51 -48 0 c-27 0 -52 -3
-55 -7z"
      />
    </g>
  </svg>
);
