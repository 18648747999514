import React from 'react';
import withRouter from 'components/hocs/withRouter';

// components
import {
  TableFooter as MaterialTableFooter,
  TableRow as MaterialTableRow,
} from '@material-ui/core';

import TableColumnsContext from './TableColumnsContext';
import TablePagination from './TablePagination';

const TableFooter = props => {
  return (
    <TableColumnsContext.Consumer>
      {({ onChangePage, onChangeRows }) => {
        return (
          <MaterialTableFooter>
            <MaterialTableRow>
              <TablePagination
                totalResults={props.totalResults}
                onNavigationChange={onChangePage}
                syncUrl={props.syncUrl}
                withStore={props.withStore}
                onChangePage={onChangePage}
                onChangeRows={onChangeRows}
              />
            </MaterialTableRow>
          </MaterialTableFooter>
        );
      }}
    </TableColumnsContext.Consumer>
  );
};

TableFooter.defaultProps = {
  totalResults: 0,
};

export default withRouter(TableFooter);
