import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ISelectTRMPolicyTableData,
  PolicyChangeOptions,
  SeverityLevel,
} from '../models';

interface ISeveritySelectProps {
  rowData: ISelectTRMPolicyTableData;
  handleTRMPolicyRuleChange: (
    optionName: PolicyChangeOptions,
    value: string,
    rule: ISelectTRMPolicyTableData
  ) => void;
}

const SeveritySelect: React.FC<ISeveritySelectProps> = (props) => {
  const { rowData, handleTRMPolicyRuleChange } = props;
  const { t } = useTranslation();

  const { severity } = rowData;

  return (
    <Select
      disabled={false}
      value={severity}
      onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        handleTRMPolicyRuleChange(
          PolicyChangeOptions.Severity,
          e.target.value as string,
          rowData
        );
      }}
      input={<Input name="severity" id="severity-helper" />}
    >
      {Object.entries(SeverityLevel(t)).map(([value, label]) => (
        <MenuItem value={value}>{label}</MenuItem>
      ))}
    </Select>
  );
};

export default React.memo(SeveritySelect);
