import GenericCard from 'components/UI/GenericCard';
import Select from 'components/UI/input/Select';
import TextField from 'components/UI/input/TextField';
import {
  Field as FormikField,
  Form as FormikForm,
  useFormikContext,
} from 'formik';
import React from 'react';
import { IThemePreference } from '../../models';
import useStyles from './useStyles';

const themePreferences: IThemePreference[] = [
  {
    value: 'dark',
    label: 'Dark',
  },
  {
    value: 'light',
    label: 'Light',
  },
];

const ConsoleForm: React.FC = () => {
  const classes = useStyles();
  const { isSubmitting, setFieldValue } = useFormikContext();

  return (
    <FormikForm>
      <GenericCard className={classes.card}>
        <h2>Console & Module Names</h2>
        <p>
          This will optionally replace the names of the console and the modules
        </p>
        <div className={classes.horizontal}>
          <FormikField
            autoComplete="off"
            component={TextField}
            disabled={isSubmitting}
            label="Console Browser Tab Name"
            name="consoleTitle"
          />
          <FormikField
            autoComplete="off"
            component={TextField}
            disabled={isSubmitting}
            label="MTD"
            name="mtdModuleText"
          />
          <FormikField
            autoComplete="off"
            component={TextField}
            disabled={isSubmitting}
            label="zScan"
            name="zscanModuleText"
          />
          <FormikField
            autoComplete="off"
            component={TextField}
            disabled={isSubmitting}
            label="zDefend"
            name="zdefendModuleText"
          />
          <FormikField
            autoComplete="off"
            component={TextField}
            disabled={isSubmitting}
            label="zShield"
            name="zshieldModuleText"
          />
        </div>
        <h2>Default Color Theme</h2>
        <p>This can be overridden by the individual user</p>
        <FormikField
          autoComplete="off"
          component={Select}
          disabled={isSubmitting}
          multiple={false}
          name="themePreference"
          options={themePreferences}
          setFieldValue={setFieldValue}
          type="select"
        />
      </GenericCard>
    </FormikForm>
  );
};

export default ConsoleForm;
