import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

export default state => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      groups: {
        ...uiSettingsDefaults.groups,
        tableHeaders: uiSettingsDefaults.groups.tableHeaders,
      },
    },
  };
};
