import { withStyles } from '@material-ui/core/styles';
import MUIAddIcon from '@material-ui/icons/Add';
import { getActiveModalAtom } from 'atoms/modals';
import { selectedTeam as selectedTeamAtom } from 'atoms/teams';
import withRouter from 'components/hocs/withRouter';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import Button from 'components/UI/Button';
import Table from 'components/UI/Table';
import { ILocationQuery, TClassList } from 'components/UI/Table/models';
import React, { useCallback, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  MDMTableColumnChange,
  updateUISettings,
} from 'reducers/UiSettingsReducers';
import { getUISettingsWithout } from 'reducers/UiSettingsSelectors';
import { bindActionCreators, compose } from 'redux';
import useBackfill from 'utils/useBackfill';
import { withDirtyState } from 'utils/withDirtyState';
import withMemoizedProps from 'utils/withMemoizedProps';
import AddEditCloneEMMAuth, {
  AddEditCloneEMMAuth_TITLE,
} from '../../AddEditCloneEMMAuth';
import { savedIntuneFormData as savedIntuneFormDataAtom } from '../../AddEditCloneEMMAuth/IntuneForm/atoms';
import AddEditCloneEMMGroups from '../../AddEditCloneEMMGroups';
import SelectProvider, { SelectProvider_TITLE } from '../../SelectProvider';
import { ISelectProvider } from '../../SelectProvider/models';
import ViewAppConfig from '../../ViewAppConfig';
import MDMTableRowDetails from '../MDMTableRowDetails';
import {
  IHistory,
  ILocation,
  IMatch,
  IMDMRowData,
  IState,
  ITeam,
  ITeamsHash,
  IUpdateUrlProps,
} from './models';
import styles from './styles';
import tableQuery from './tableQuery';
import { getMDMColumnMapping, MDMTableHeaders } from './utils';
import { useTranslation } from 'react-i18next';

const activeModalAtom = getActiveModalAtom<ISelectProvider>();

interface IMDMProps {
  accountId: string;
  classes: TClassList;
  history: IHistory;
  jiggleDirtyState: () => void;
  location: ILocation;
  match: IMatch;
  MDMTableColumnChange: (...args: any) => void;
  MDMUISettings: { [key: string]: unknown };
  q: { [key: string]: unknown };
  rqps: string[];
  updateUrl: (props: IUpdateUrlProps) => void;
}

const MDM: React.FC<IMDMProps> = ({
  accountId,
  classes,
  history,
  jiggleDirtyState,
  location,
  MDMTableColumnChange,
  MDMUISettings,
  q: query,
  rqps,
  updateUrl,
}) => {
  useBackfill({
    query,
    requiredQueryParams: rqps,
    storedQueryParams: MDMUISettings,
    updateUrl: updateUrl,
  });
  const { t, ready } = useTranslation();

  const selectedTeamId = useRecoilValue(selectedTeamAtom) || undefined;
  const [savedIntuneFormData, setSavedIntuneFormData] = useRecoilState(
    savedIntuneFormDataAtom
  );

  const setActiveModal = useSetRecoilState(activeModalAtom);
  const teamsHash: ITeamsHash = useSelector(
    ({ teams }: IState) => teams?.available ?? {}
  );
  const availableTeams = Object.keys(teamsHash).map((teamId: string): ITeam => {
    return {
      label: teamsHash[teamId].name,
      value: teamId,
      disabled: false,
    };
  });


  const handleAddClick = useCallback(() => {
    // Clear out intune data and redirectUrl in case auth was interrupted
    setSavedIntuneFormData(undefined);
    localStorage.removeItem('redirectUrl');
    setActiveModal({ active: SelectProvider_TITLE });
  }, [setActiveModal, setSavedIntuneFormData]);

  useEffect(() => {
    if (
      !location?.query?.tenant ||
      !location?.query?.admin_consent ||
      !savedIntuneFormData?.mode
    ) {
      return;
    }

    // Reload auth model if in the process of authenticating MS Intune
    setActiveModal({
      active: AddEditCloneEMMAuth_TITLE,
      payload: {
        availableTeams,
        mode: savedIntuneFormData?.modalMode,
        msAuthResponse: {
          admin_consent: location.query.admin_consent,
          tenant: location.query.tenant,
        },
      },
    });
    updateUrl({
      admin_consent: undefined,
      error: undefined,
      error_description: undefined,
      error_uri: undefined,
      tenant: undefined,
    });
    localStorage.removeItem('redirectUrl');
  }, [
    availableTeams,
    history,
    location?.query?.admin_consent,
    location?.query?.tenant,
    savedIntuneFormData?.modalMode,
    savedIntuneFormData?.mode,
    setActiveModal,
    updateUrl,
  ]);

  useEffect(() => {
    window.addEventListener(`emm:reload-table`, jiggleDirtyState);

    return () => {
      window.removeEventListener(`emm:reload-table`, jiggleDirtyState);
    };
  }, [jiggleDirtyState]);

  const handleColumnChange = (...args: any) => {
    jiggleDirtyState();
    MDMTableColumnChange(...args);
  };

  if (!ready) {
    return null;
  }

  return (
    <div>
      <div className="view__header">
        <h1>{t('MTD.INTEGRATIONS.EMM_CONNECTIONS')}</h1>

        <ProtectedComponent allow={{ emm: 'manage' }}>
          <Button
            color="secondary"
            icon={MUIAddIcon}
            onClick={() => handleAddClick()}
            text={t('MTD.INTEGRATIONS.ADD_NEW_EMM')}
          />
        </ProtectedComponent>
      </div>
      <Table
        columnHeaders={MDMTableHeaders(t)}
        classList={classes}
        fetchTableData={() =>
          tableQuery(
            accountId,
            selectedTeamId
          )({
            id: 'MDM',
            dir: String((query as unknown as ILocationQuery).order),
          })
        }
        query={query as unknown as ILocationQuery}
        rowDetails={(rowData) => {
          return <MDMTableRowDetails rowData={rowData as IMDMRowData} />;
        }}
        rowExpandHeight={300}
        rowMapping={getMDMColumnMapping(availableTeams, t)}
        tableId="MDM"
        onColumnChange={handleColumnChange}
      />
      <ViewAppConfig />
      <AddEditCloneEMMAuth />
      <AddEditCloneEMMGroups />
      <SelectProvider />
    </div>
  );
};
const mapStateToProps = (state: IState) => ({
  accountId: state?.user?.account?.id ?? '',
  MDMUISettings: getUISettingsWithout(state, 'MDM', ['tableHeaders']),
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      MDMTableColumnChange,
      updateUISettings,
    },
    dispatch
  );

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withDirtyState()
)(
  withMemoizedProps(MDM, [
    'currentTableHeaders',
    'dirtyState',
    'MDMUISettings',
    'q',
    'updateUISettings',
  ])
);
