import {
  csvInjectionErrorMessage,
  csvInjectionRegex,
} from 'utils/componentUtils';
import * as Yup from 'yup';

const Schema = () => {
  const fields = {
    name: Yup.string()
      .min(1)
      .matches(csvInjectionRegex, csvInjectionErrorMessage)
      .required('Name is required.'),
    teamId: Yup.string().test('team', 'Team required.', (value, ctx) => {
      if (!!ctx?.parent?.selectedTeamId) {
        return true;
      }

      if (ctx?.parent?.bounds === 'global') {
        return true;
      }

      return !!value;
    }),
  };

  return Yup.object().shape({ ...fields });
};

export default Schema;
