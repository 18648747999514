export interface ITeam {
  id: string;
  name: string;
  accountId: string;
}

export interface IBrandingPolicy {
  id: string;
  accountId: string;
  teamId: string;
  name: string;
  created?: string;
  modified?: Date;
}

export enum BrandingLogo {
  HorizontalDark = 'logoHorizontalDark',
  VerticalDark = 'logoVerticalDark',
  HorizontalLight = 'logoHorizontalLight',
  VerticalLight = 'logoVerticalLight',
}

export interface IBrandingImages {
  logoHorizontalDark?: Blob | File;
  logoVerticalDark?: Blob | File;
  logoHorizontalLight?: Blob | File;
  logoVerticalLight?: Blob | File;
}

export interface IBrandingImagesResponse {
  logoHorizontalDark?: string;
  logoVerticalDark?: string;
  logoHorizontalLight?: string;
  logoVerticalLight?: string;
}

export interface IBrandingPolicyDetails extends IBrandingPolicy {
  buttonColorLight: string;
  buttonTextColorLight: string;
  buttonColorDark: string;
  buttonTextColorDark: string;
  images: IBrandingImages;
  licenseAgreementLocation: string;
  privacyPolicyLocation: string;
  lastModified?: Date;
  groups: { id: string; name: string }[];
}

export interface IBrandingPolicyDetailsResponse
  extends IBrandingPolicy,
    Omit<IBrandingPolicyDetails, 'images'> {
  images: IBrandingImagesResponse;
}

export interface IBrandingPolicyRequest {
  policy: {
    name: string;
    licenseAgreementLocation: string;
    privacyPolicyLocation: string;
    buttonColorLight?: string;
    buttonTextColorLight?: string;
    buttonColorDark?: string;
    buttonTextColorDark?: string;
  };
  horizontalLogoDark?: File;
  verticalLogoDark?: File;
  horizontalLogoLight?: File;
  verticalLogoLight?: File;
}
