import _ from 'lodash';
import ThreatCategoryMapping from 'utility/ThreatCategoryMapping';
import VectorMapping from 'utility/VectorMapping';
import { generateMultiSelectValue } from 'utils/componentUtils';
export function applyFacets(data, { cat, severity, platform, vector }) {
  let modified = data;

  if (cat) {
    const map = _.isArray(cat) ? cat.map((c) => parseInt(c)) : [parseInt(cat)];

    modified = modified.filter((item) => {
      let result = false;

      for (let i = 0; i < map.length; i += 1) {
        console.log([item.categoryRaw, map[i]]);
        if (item.categoryRaw === map[i]) {
          result = true;
          break;
        }
      }

      return result;
    });
  }

  if (platform) {
    const map = _.isArray(platform)
      ? platform.map((p) => parseInt(p))
      : [parseInt(platform)];

    modified = modified.filter((item) => {
      let result = false;

      for (let i = 0; i < map.length; i += 1) {
        if (item.platform.includes(map[i])) {
          result = true;
          break;
        }
      }

      return result;
    });
  }

  if (vector) {
    const map = _.isArray(vector) ? vector : [vector];

    modified = modified.filter((item) => {
      let result = false;

      for (let i = 0; i < map.length; i += 1) {
        if (item.vectorRaw === map[i]) {
          result = true;
        }
      }

      return result;
    });
  }

  if (severity) {
    const map = _.isArray(severity) ? severity : [severity];

    modified = modified.filter((item) => {
      for (let i = 0; i < map.length; i += 1) {
        if (item.severity === parseInt(map[i])) {
          return true;
        }
      }

      return false;
    });
  }

  return modified;
}

export const sortRules = (rules, field, order) => {
  return order === 'asc'
    ? _.sortBy(rules, [field])
    : _.sortBy(rules, [field]).reverse();
};

const getThreatTypesById = (threatTypesItems) => {
  return threatTypesItems.reduce((accum, value) => {
    return {
      ...accum,
      [value.id]: value,
    };
  }, {});
};

export const mergeRulesWithThreats = (
  rules,
  threats,
  mdmActions,
  threatNotifications
) => {
  const threatHash = getThreatTypesById(threats);
  const categories = [];
  const vectors = [];
  const table = _.intersectionWith(
    rules,
    threats,
    (rule, threat) => rule.threatTypeId === threat.id
  ).map((rule) => {
    let mitigationAction;
    if (rule.mdmMitigationAction) {
      mitigationAction = generateMultiSelectValue(
        rule.mdmMitigationTarget
          ? rule.mdmMitigationTarget
          : rule.mdmMitigationAction,
        mdmActions?.mitigationActions,
        'value'
      );
    }
    let threatAction;
    if (rule.mdmThreatAction) {
      threatAction = generateMultiSelectValue(
        rule.mdmThreatTarget ? rule.mdmThreatTarget : rule.mdmThreatAction, // if target... use target.
        mdmActions?.threatActions,
        'value'
      );
    }
    const ttid = rule.threatTypeId;
    const { category, vector, operatingSystems, serverName, name, description } = _.get(
      threatHash,
      ttid,
      {}
    );
    categories.push({
      value: category ? category.toString() : null,
      label: _.get(ThreatCategoryMapping[category], 'name'),
    });

    vectors.push({
      id: vector.toString(),
      label: VectorMapping[vector],
    });
    const threatNotification = threatNotifications.find(
      (threatNotification) => threatNotification.threatTypeId === ttid
    );
    return {
      ...rule,
      name: serverName || name,
      category: _.get(ThreatCategoryMapping[category], 'name'),
      categoryRaw: category,
      vector: VectorMapping[vector],
      vectorRaw: vector,
      description: description,
      // mdm
      mdmMitigationAction: mitigationAction && mitigationAction[0],
      mdmThreatAction: threatAction && threatAction[0],
      selectRow: rule.selectRow,
      platform: operatingSystems,
      os: operatingSystems
        .map((os) => (os === 1 ? 'Android' : 'iOS'))
        .join(' and '),
      threatNotification,
    };
  });

  return {
    categories: _.uniqBy(categories, 'value'),
    table,
    vectors: _.uniqBy(vectors, 'id'),
  };
};

function mdmActionGroupsHelper(groups) {
  return groups.targets
    .map((singleTarget) => {
      return {
        label: singleTarget.name,
        value: singleTarget.mdmId, // `${singleTarget.mdmId}-${singleSelection.name}`,
        grouping: groups.name,
        mdmId: groups.mdmId,
      };
    })
    .sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
}
export const MDMSelectionHelper = (selections, t) => {
  const threatActionArray = [];
  let threatTargetsArray = [];
  const mitigationActionArray = [];
  let mitigationTargetArray = [];

  selections.forEach((singleSelection) => {
    // find context and put it in each object
    // THREAT DETECTION . and THREAT MITIGATION
    //

    if (singleSelection.contexts.includes('THREAT_DETECTION')) {
      if (!singleSelection.targets?.length) {
        // if there are no targets we want to give a message in the dropdown
        const isAddDeviceFromGroups =
          singleSelection.mdmId === 'add-device-to-group';
        threatActionArray.push({
          label: isAddDeviceFromGroups
            ? t('MTD.POLICIES.THREAT.ERROR_NO_RESPONSE_GROUPS_CONFIGURED')
            : singleSelection.name,
          value: singleSelection.mdmId,
          disabled: isAddDeviceFromGroups,
          mdmId: singleSelection.mdmId,
        });
      }
      // groups
      else if (!_.isEmpty(singleSelection.targets)) {
        threatTargetsArray = mdmActionGroupsHelper(singleSelection);
      }
    }

    if (singleSelection.contexts.includes('THREAT_MITIGATION')) {
      if (!singleSelection.targets?.length) {
        const isRemoveDeviceFromGroups =
          singleSelection.mdmId === 'remove-device-from-group';
        mitigationActionArray.push({
          label: isRemoveDeviceFromGroups
            ? t('MTD.POLICIES.THREAT.ERROR_NO_RESPONSE_GROUPS_CONFIGURED')
            : singleSelection.name,
          value: singleSelection.mdmId,
          disabled: isRemoveDeviceFromGroups,
          mdmId: singleSelection.mdmId,
        });
      } else if (!_.isEmpty(singleSelection.targets)) {
        mitigationTargetArray = mdmActionGroupsHelper(singleSelection);
      }
      // groups
    }
  });

  return {
    threatActions: _.concat(threatActionArray, threatTargetsArray),
    mitigationActions: _.concat(mitigationActionArray, mitigationTargetArray),
  };
};

//

export function mdmActionPayloadHelper(rule) {
  // if a target/ group exists

  const threatTargetExists = _.get(rule, 'mdmThreatAction.grouping');
  const mitigationTargetExists = _.get(rule, 'mdmMitigationAction.grouping');
  // check for existing by testing if it is a string
  return {
    mdmThreatAction:
      _.get(rule, 'mdmThreatAction.mdmId') || rule.mdmThreatAction,
    mdmThreatTarget:
      (threatTargetExists && rule.mdmThreatAction.value) ||
      rule.mdmThreatTarget,
    mdmMitigationAction:
      _.get(rule, 'mdmMitigationAction.mdmId') || rule.mdmMitigationAction,
    mdmMitigationTarget:
      (mitigationTargetExists && _.get(rule, 'mdmMitigationAction.value')) ||
      rule.mdmMitigationTarget,
  };
}

// on mtd module hide and disable mdm action and device action columns in column selector

export function mtdTRMTableHeaderHelper(tableHeaders, mdmConnection = false) {
  return tableHeaders.map((header) => {
    if (!mdmConnection && header.mdm) {
      return {
        ...header,
        show: false,
        disable: true,
      };
    }
    if (mdmConnection && header.mdm) {
      return {
        ...header,
        show: true,
        disable: false,
      };
    }

    return { ...header };
  });
}
