import * as Yup from 'yup';

const timeRegEx = /^\d{2}:\d{2}$/;
const timeMessage = 'Must select a valid time';

const Schema = () => {
  return Yup.object().shape(
    {
      hourlyStartTime: Yup.string().when('hours', {
        is: (hours: number) => !!hours,
        then: Yup.string()
          .matches(timeRegEx, timeMessage)
          .required('Must select a starting time'),
        otherwise: Yup.string().matches(timeRegEx, timeMessage),
      }),
      hours: Yup.number().when('hourlyStartTime', {
        is: (hourlyStartTime: string) => !!hourlyStartTime,
        then: Yup.number()
          .min(1)
          .max(24)
          .required('Must enter a value for hours'),
        otherwise: Yup.number().min(1).max(24),
      }),
    },
    [['hours', 'hourlyStartTime']]
  );
};
export default Schema;
