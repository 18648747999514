import withRouter from 'components/hocs/withRouter';
import LayoutViewToggle, { EVIEW_TYPES_ENUM } from 'components/UI/LayoutViewToggle';
import React, { useCallback } from 'react';
import ProtectedRoute from 'routes/ProtectedRoute';
import ZShieldCardView from './CardView';
import ZShieldTableView from './Tableview';
import UploadAppButton from './UploadAppButton';
import EmailSupportButton from './EmailSupportButton';

const ZShieldLayoutButtons: React.FC = () => {
  const [layoutView, setLayoutView] = React.useState<EVIEW_TYPES_ENUM>(EVIEW_TYPES_ENUM.Table);

  const renderRoute = useCallback(() => {
    if (layoutView === EVIEW_TYPES_ENUM.Card) {
      return (
        <>
          <ProtectedRoute
            component={ZShieldCardView}
          />
        </>
      );
    }
    return (
      <ProtectedRoute
        component={ZShieldTableView}
        rqps={['page', 'size', 'orderBy', 'order']}
      />
    );
  }, [layoutView]);

  return (
    <LayoutViewToggle
      buttonComp={<><EmailSupportButton /><UploadAppButton /></>}
      handleToggleView={setLayoutView}
      view={layoutView}
      title={"Protected Apps"}
    >
      {renderRoute()}
    </LayoutViewToggle>
  );
};

export default withRouter(ZShieldLayoutButtons);
