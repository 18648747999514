import { fetchPotentialFraudIndicators } from 'api/ZDefendInsights';
import { IPotentialFraudIndicators } from './models';

export const fetchPotentialFraud = async (
  teamId?: string | undefined,
  zappId?: string | undefined
): Promise<IPotentialFraudIndicators> => {
  const result: { data: IPotentialFraudIndicators } = await fetchPotentialFraudIndicators({
    teamId,
    zappId,
  });

  return result?.data;
};
