import _ from 'lodash';
import React from 'react';
import * as Yup from 'yup';

// ui
import { Formik, Form, Field } from 'formik';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

// components
import ZButton from 'UI/Buttons/ZButton';
import {
  toggleModalDiffered,
  toggleModalDirect,
  openSnackBar,
} from 'utils/storeUtils';
import { publishEvent } from 'utils/eventUtils';
import { FormikTextField } from '../inputs/FormikTextField';
import { FormikPasswordField } from '../inputs/FormikPasswordField';
import { FormikMultiSelect } from '../inputs/FormikMultiSelect';

import {
  createIntegration,
  updateIntegration,
} from '../../dashboard/zDev/Integrations/TicketingIntegrations/integrations.service';
import { useTranslation } from 'react-i18next';

const initialValues = {
  issueManagementSystemId: [{ value: 'JIRA_7_6_1', label: 'Jira' }],
  url: '',
  name: '',
  authTypeBasicAuth: true,
  username: '',
  password: '',
  // refresh: [{ value: 'hour', label: '1 Hour' }],
};
let initialFormValues = initialValues;

export const IntegrationCreateEdit = (props) => {
  const { t, ready } = useTranslation();
  const editMode = !_.isEmpty(props.data);

  if (editMode) {
    initialFormValues = {
      issueManagementSystemId: [
        {
          value: props.data.issueManagementSystem.id,
          label: props.data.issueManagementSystem.system,
        },
      ],
      ..._.pick(props.data, [
        'url',
        'name',
        'username',
        'authTypeBasicAuth',
        'password',
      ]),
    };
  }

  const onSubmit = (values, actions) => {
    const operation = editMode
      ? updateIntegration({ integrationId: props.data.id }, values)
      : createIntegration({}, values);

    operation
      .then(() => {
        toggleModalDirect('IntegrationCreateEdit', false);
        openSnackBar(
          t('ZSCAN.INTEGRATIONS.CREATE_EDIT_INTEGRATION_SUCCESS', {
            integrationName: values.name,
            actionPerformed: t(
              (editMode && 'GLOBAL.UPDATED') || 'GLOBAL.CREATED'
            ),
          })
        );
        actions.setSubmitting(false);
        publishEvent('table:force-fetch-integrations');
      })
      .catch(({ response, ...error }) => {
        if (response && response.status === 409) {
          openSnackBar(
            t('ZSCAN.INTEGRATIONS.INTEGRATIONS_WITH_THE_SAME_NAME_ERROR', {
              integrationName: values.name,
            })
          );
        } else if (response && response.status === 400) {
          actions.setErrors({
            username: t('ZSCAN.INTEGRATIONS.AUTHORIZATION_ERROR'),
          });
        } else {
          openSnackBar(
            t('ZSCAN.INTEGRATIONS.CREATE_EDIT_INTEGRATION_FAIL', {
              actionPerformed: t(
                (editMode && 'GLOBAL.UPDATED') || 'GLOBAL.CREATED'
              ),
              integrationName: values?.name,
              errorBody: response?.data?.message ?? '',
            })
          );
        }

        actions.setSubmitting(false);
      });
  };

  if (!ready) {
    return null;
  }

  return (
    <DialogContent>
      <Formik
        initialValues={!editMode ? initialValues : initialFormValues}
        onSubmit={onSubmit}
        validationSchema={
          !editMode ? IntegrationSchemaCreate : IntegrationSchemaEdit
        }
      >
        {({ isSubmitting, isValid, dirty }) => {
          return (
            <Form>
              <Field
                name="issueManagementSystemId"
                label={t('ZSCAN.INTEGRATIONS.TICKETING_SYSTEM')}
                component={FormikMultiSelect}
                isMulti={false}
                options={initialValues.issueManagementSystemId}
              />

              <Field
                name="name"
                label={t('GLOBAL.NAME')}
                component={FormikTextField}
              />

              <Field name="url" label="URL" component={FormikTextField} />

              <Field
                name="username"
                label={t('GLOBAL.USERNAME')}
                component={FormikTextField}
                autocomplete="off"
              />

              <Field
                name="password"
                label={t('ZSCAN.INTEGRATIONS.ACCESS_TOKEN')}
                type="password"
                component={FormikPasswordField}
                autoComplete="new-password"
              />

              <DialogActions>
                <ZButton
                  action={toggleModalDiffered('IntegrationCreateEdit', false)}
                  buttonText={t('GLOBAL.CANCEL')}
                  color="secondary"
                  isDisabled={isSubmitting}
                  styleName="submit"
                />

                <ZButton
                  buttonType="submit"
                  buttonText={t('ZSCAN.INTEGRATIONS.SAVE_INTEGRATION')}
                  color="primary"
                  isDisabled={!isValid || isSubmitting || !dirty}
                  styleName="modalSave"
                />
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </DialogContent>
  );
};

const IntegrationSchemaCreate = Yup.object().shape({
  name: Yup.string().min(3).required(),
  url: Yup.string().url().required(),
  username: Yup.string().min(6).required(),
  password: Yup.string().min(6).required(),
});

const IntegrationSchemaEdit = Yup.object().shape({
  name: Yup.string().min(3).required(),
  url: Yup.string().url().required(),
  username: Yup.string().min(3).required(),
  password: Yup.string().min(6).required(),
});
