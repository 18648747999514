import { useState, useCallback } from 'react';
import useStyles from '../useStyles';
import { ILocationQuery } from 'components/UI/Table/models';
import Table from 'components/UI/Table';
import {
  showAffectedDevicesRowMapping,
  showAffectedDevicesHeader,
} from './affectedDevices.mapping';
import { useTranslation } from 'react-i18next';
import tableQuery from './tableQuery';
import { IAffectedApps } from '../models';
import SearchBox from 'components/UI/input/SearchBox';
import useFetchGroups from './useFetchGroups';
import { IQueryParams, ITableHeaderSort } from './models';
import { updateUISettings } from 'reducers/UiSettingsReducers';
import { tableHeaderHelper } from './utils';
import useAppCharacteristics from '../../../AppCharacteristicProvider';

interface IAffectedDevicesProps {
  hide: boolean;
  selectedApps: IAffectedApps[];
}
const storeKey = 'affectedDevicesTable';
const AffectedDevicesTable: React.FC<IAffectedDevicesProps> = ({
  selectedApps,
  hide,
}) => {
  const [pageNumber, setPageNumber] = useState<string | number | undefined>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [totalDataCount, setTotalDataCount] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [sortByQuery, setSortByQuery] = useState<string>('lastSeen,asc');
  const [tableheaders, setTableheaders] = useState(showAffectedDevicesHeader);
  const classes = useStyles();
  const { t } = useTranslation();
  const { groupsHash } = useFetchGroups();
  const appVersionIds = selectedApps.map((app) => app.id);
  const { deviceApiCall, affectedAppsPostDataPayload, appPolicyDetails } =
    useAppCharacteristics();

  const teamId = appPolicyDetails?.team?.id;

  const handleSearch = useCallback(
    (value: string) => {
      setSearchQuery(value);
    },
    [setSearchQuery]
  );
  const onPaginationChange = useCallback((update: IQueryParams) => {
    if ('page' in update) {
      setPageNumber(Number(update?.page ?? 0));
    }
    if ('size' in update) {
      setPageSize(Number(update?.size));
    }
    updateUISettings({
      domain: storeKey,
      update,
    });
  }, []);
  const handleColumnChange = (...args: unknown[]) => {
    const newTableheaders = tableHeaderHelper(args);
    setTableheaders(newTableheaders);
  };

  const handleSortQuery = (columnHeaderInfo: ITableHeaderSort) => {
    setSortByQuery(
      `${columnHeaderInfo.id},${columnHeaderInfo.dir === 1 ? 'asc' : 'desc'}`
    );
  };

  if (hide) {
    return null;
  }

  return (
    <div>
      <h2>{t('MTD.POLICIES.APP_POLICY.APP_CHAR.YOUR_TEAMS_POTENTIAL')}</h2>
      <div style={{ marginBottom: 10 }}>
        <SearchBox onInputChange={handleSearch} />
      </div>

      <Table
        height={450}
        serverSort={false}
        classList={classes}
        columnHeaders={tableheaders}
        footerComponents={() => (
          <div style={{ paddingBottom: '40px' }}>
            {totalDataCount + ' rows total'}
          </div>
        )}
        fetchTableData={() => {
          return tableQuery(
            { appVersionIds },
            setTotalDataCount,
            affectedAppsPostDataPayload,
            deviceApiCall,
            groupsHash,

            {
              page: Number(pageNumber) || '0',
              size: pageSize,
              sort: sortByQuery,
              search: searchQuery,
              teamId,
            }
          )({
            page: Number(pageNumber) || '0',
            size: pageSize,
            sort: sortByQuery,
            search: searchQuery,
            teamId,
          } as unknown as ILocationQuery);
        }}
        onPaginationChange={onPaginationChange}
        onColumnChange={handleColumnChange}
        onSortInfoChange={handleSortQuery}
        query={
          {
            page: Number(pageNumber) || '0',
            size: pageSize,
            sort: sortByQuery,
            teamId,
          } as unknown as ILocationQuery
        }
        rowMapping={showAffectedDevicesRowMapping(t)}
        tableId={storeKey}
      />
    </div>
  );
};

export default AffectedDevicesTable;
