import { ServiceHandlerFactory } from 'utils/serviceUtils';
import { fetchPlansParamsMapping } from 'mappings/services/plansServiceMapping';
import { Api } from 'config/axiosConfig';
import qs from 'qs';

export const fetchFeaturesByPlanIds = (planIds, accountId) => {
  return Api.get('api/auth/v2/accounts/features', {
    params: {
      ...planIds,
      accountId
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

export const fetchPlansPage = ServiceHandlerFactory({
  axios: {
    url: 'api/account/v1/plans/page?',
  },
  paramSchema: fetchPlansParamsMapping,
});

export const fetchShallowPlansList = ServiceHandlerFactory({
  axios: {
    url: 'api/account/v1/plans/',
  },
});

export const fetchPlanById = ServiceHandlerFactory({
  axios: {
    url: 'api/account/v1/plans/:planId',
  },
});

export const editSelectedPlan = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/plans/:planId',
    method: 'put',
  },
});

export const cloneSelectedPlan = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/plans/:planId/clone',
    method: 'post',
  },
});

export const deleteSelectedPlan = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/plans/:planId',
    method: 'delete',
  },
});
