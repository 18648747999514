import React from 'react';
import _ from 'lodash';
import { TextField } from 'components/inputs';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormError } from './FormError';

export const FormikTextField = ({ field, form, loading, ...props }) => {
  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <CircularProgress
          size={25}
          style={{
            position: 'absolute',
            right: 10,
            zIndex: 101,
          }}
        />
        <TextField {...field} {...props} />
      </div>
    );
  }
  return (
    <div style={{ position: 'relative' }}>
      <TextField {...field} {...props} />
      {form.touched[field.name] && form.errors[field.name] && (
        <FormError
          field={field}
          form={form}
          classlist={{ errors: _.get(props, 'classList.errorClass') }}
        />
      )}
    </div>
  );
};

/*
  {form.dirty && form.errors[field.name] && (
    */
