import {
  IZscanIndicator,
  FacetOptions,
  IZScanPolicyEntry,
  IZScanPolicyEntriesHash,
  IZScanPolicyLocationQuery,
  IZScanCVSSDataObject,
  IZScanCompliance,
} from './models';
import _ from 'lodash';

export function descriptionTextHelper(
  description: string | undefined | null
): string | null {
  if (!description || typeof description !== 'string') {
    return null;
  }
  return description.replace(
    /(\\")|(\n)/gim,
    (g1: string, g2: string | undefined) => {
      if (g2) {
        return '"';
      }
      if (g1) {
        return '<br />';
      }
      return ''; // Added to satisfy TypeScript return type
    }
  );
}
export const checkDefault = (param: string[], value: string): boolean => {
  if (_.isArray(param)) {
    return param.includes(value);
  }
  return param === value;
};

export const onFacetChange = (
  name: string,
  value: string,
  { updateUrl, q }: FacetOptions
): (() => void) => {
  return () => {
    if (!_.has(q, name)) {
      updateUrl({ [name]: value });
      return;
    }

    // deal with multiple values
    if (_.isArray(q[name])) {
      const newValues = [...q[name]];

      // remove the value
      if (q[name].includes(value)) {
        _.pull(newValues, value);
      } else {
        newValues.push(value);
      }

      updateUrl({ [name]: newValues });
    }

    if (_.isString(q[name])) {
      // remove value
      if (q[name] === value) {
        updateUrl({ [name]: undefined });
        return;
      }

      updateUrl({ [name]: [q[name], value] });
    }
  };
};

export const applyFacets = (
  indicators: IZscanIndicator[],
  query: IZScanPolicyLocationQuery
) => {
  let modified = indicators;
  const { compliance, type, platform } = query;

  // here check for cvss
  if (compliance) {
    const map = _.isArray(compliance)
      ? compliance.map((s) => ({ id: s.toUpperCase() }))
      : [{ id: compliance.toUpperCase() }];
    modified = modified?.filter((item) => {
      const matches = _.intersectionBy(item.compliance, map, 'id');

      const cvssFilter = map.find((a) => a.id === 'CVSS');

      if (cvssFilter && !_.isEmpty(item?.cvssData)) {
        matches.push(item as unknown as IZScanCompliance);
      }
      return Boolean(matches.length);
    });
  }

  if (platform) {
    const map = _.isArray(platform) ? platform : [platform];

    /* eslint-disable-next-line array-callback-return */
    modified = modified.filter((item) => {
      if (map.length === 2) {
        if (item.platform.length === 2) {
          return true;
        }

        return false;
      }

      for (let i = 0; i < map.length; i += 1) {
        if (item.platform.includes(map[i])) {
          return true;
        }
      }
    });
  }

  if (type) {
    modified = modified.filter((item: any) => item[type]);
  }
  return modified;
};

export const mergeCustomCVSSDataWithIndicatorCVSSData = (
  finding: IZscanIndicator,
  customCVSSHash: IZScanPolicyEntriesHash
): IZScanCVSSDataObject => {
  const existingCVSSData: IZScanCVSSDataObject = {} as IZScanCVSSDataObject;
  if (finding.cvss_2_0_score) {
    existingCVSSData.cvss_2_0_score = finding.cvss_2_0_score;
  }
  if (finding.cvss_2_0_vector) {
    existingCVSSData.cvss_2_0_vector = finding.cvss_2_0_vector;
  }
  if (finding.cvss_3_1_score) {
    existingCVSSData.cvss_3_1_score = finding.cvss_3_1_score;
  }
  if (finding.cvss_3_1_vector) {
    existingCVSSData.cvss_3_1_vector = finding.cvss_3_1_vector;
  }

  return {
    cvssData: customCVSSHash?.[finding?.id]
      ? { ...customCVSSHash?.[finding?.id] }
      : existingCVSSData,
    ...finding,
  };
};

export const mergeObjects = (
  obj1: IZScanPolicyEntry[],
  obj2: IZScanPolicyEntriesHash
): IZScanPolicyEntriesHash => {
  // Create a map to store merged objects based on their id
  const mergedMap = new Map<string, IZScanPolicyEntry>();

  // Merge objects from obj1
  obj1.forEach((item) => {
    const { findingId, enabled, severity } = item;
    const obj2Item = Object.values(obj2).find((o) => o.findingId === findingId);
    if (obj2Item) {
      const mergedItem: IZScanPolicyEntry = { ...obj2Item, enabled, severity };
      mergedMap.set(findingId, mergedItem);
    } else {
      mergedMap.set(findingId, item);
    }
  });

  // Merge objects from obj2 that are not in obj1
  Object.values(obj2).forEach((item) => {
    const { findingId } = item;
    if (!mergedMap.has(findingId)) {
      mergedMap.set(findingId, item);
    }
  });

  // Convert the map values to an array
  const mergedArray = Array.from(mergedMap.values());
  const mergeObject: IZScanPolicyEntriesHash = {};

  mergedArray.forEach((item: IZScanPolicyEntry) => {
    mergeObject[item.findingId as keyof IZScanPolicyEntry] = {
      findingId: item.findingId,
      enabled: item.enabled,
      severity: item.severity,
    };
  });

  return mergeObject;
};
