import { withStyles } from '@material-ui/core/styles';
import { Table, TableColumnsContext } from 'components/Tables';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  integrationsTableColumnChange,
  updateUISettings,
} from 'reducers/UiSettingsReducers';
import { bindActionCreators, compose } from 'redux';
import { zipReference } from 'utils/componentUtils';
import useBackfill from 'utils/useBackfill';
import withRouter from '../../../../components/hocs/withRouter';
import {
  getChangedTableHeadersHash,
  getUISettingsTableHeaders,
  getUISettingsWithout,
} from '../../../../reducers/UiSettingsSelectors';
import { PubSub } from '../../../../utils/eventUtils';
import { SET_INTEGRATIONS } from '../TicketingConfigurations/configuration.events.ts';
import { integrationTableColumns } from './integrations.mapping';
import { getIntegrations } from './integrations.service';
import { Toolbar } from '@material-ui/core';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import ZButton from 'UI/Buttons/ZButton';
import { toggleModalDiffered } from 'utils/storeUtils';
import AddIcon from 'components/UI/icons/AddIcon';

const IntegrationsListing = (props) => {
  useBackfill({
    query: props.q,
    requiredQueryParams: props.rqps,
    storedQueryParams: props.integrationsUiSettings,
    updateUrl: props.updateUrl,
  });

  function onPaginate(update) {
    props.updateUISettings({
      domain: 'integrations',
      update,
    });
  }

  function fetchTableData(query) {
    return new Promise((resolve) => {
      getIntegrations(query).then((resp) => {
        PubSub.publish(SET_INTEGRATIONS, zipReference(resp.data));
        return resolve({
          tableData: resp?.data,
          totalResults: resp?.data?.length,
        });
      });
    });
  }

  return (
    <>
      <Toolbar style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ProtectedComponent allow={{ zdev_apps: 'manage' }}>
          <ZButton
            action={toggleModalDiffered(
              'IntegrationCreateEdit',
              {},
              {
                title: 'ZSCAN.INTEGRATIONS.CREATE_NEW_INTEGRATION',
                disableBackdropClick: true,
              }
            )}
            buttonText="New Integration"
            color="secondary"
            icon={{ elem: AddIcon }}
          />
        </ProtectedComponent>
      </Toolbar>
      <TableColumnsContext.Provider
        value={{
          columnHeaders: props.currentTableHeaders,
          columnHeadersHash: props.columnHeadersHash,
          onColumnChange: props.integrationsTableColumnChange,
          onChangePage: onPaginate,
          onChangeRows: onPaginate,
          onSort: onPaginate,
          rowMapping: integrationTableColumns,
          rowProps: {
            theme: props.theme,
          },
        }}
      >
        <Table
          classList={props.classes}
          tableId="integrations"
          fetchTableData={fetchTableData}
        />
      </TableColumnsContext.Provider>
    </>
  );
};

const mapStateToProps = (state) => ({
  columnHeadersHash: getChangedTableHeadersHash(state, 'integrations'),
  currentTableHeaders: getUISettingsTableHeaders(state, 'integrations'),
  integrationsUiSettings: getUISettingsWithout(state, 'integrations', [
    'tableHeaders',
  ]),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      integrationsTableColumnChange,
      updateUISettings,
    },
    dispatch
  );

const styles = () => ({
  // statusCircle: {
  //   width: 12,
  //   height: 12,
  //   borderRadius: '50%',
  // },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, { withTheme: true })
)(IntegrationsListing);
