import { TFunction } from 'react-i18next';

export enum TableOptions {
  DeleteSelected = 'deleteSelected',
  ExportSelected = 'exportSelected',
  ExportTable = 'exportTable',
  ImportTable = 'importTable',
  ImportTableAppend = 'importTableAppend',
  DownloadExample = 'downloadExample',
  None = 'none',
}

export interface ITableAction {
  value: string;
  label: string;
}

export const options = (t: TFunction<'translation', undefined>): ITableAction[] => [
  {
    label: t('GLOBAL.SELECTED_ROWS'),
    value: TableOptions.None,
  },
  {
    label: t('GLOBAL.DELETE_ROWS'),
    value: TableOptions.DeleteSelected,
  },
  {
    label: t('GLOBAL.EXPORT_TO_CSV'),
    value: TableOptions.ExportSelected,
  },
  {
    label: t('GLOBAL.ENTIRE_TABLE'),
    value: TableOptions.None,
  },
  {
    label: t('GLOBAL.EXPORT_TO_CSV'),
    value: TableOptions.ExportTable,
  },
  {
    label: t('GLOBAL.IMPORT_CSV_REPLACE_ALL'),
    value: TableOptions.ImportTable,
  },
  {
    label: t('GLOBAL.IMPORT_CSV_APPEND_TABLE'),
    value: TableOptions.ImportTableAppend,
  },
  {
    label: t('MTD.ACTIVATIONS.DOWNLOAD_SAMPLE_CSV_FILE'),
    value: TableOptions.DownloadExample,
  },
];

export const exampleData = [
  {
    site: 'www.jio.com',
    CustomCategory: 'Phishing',
  },
  {
    site: 'www.fb.com',
    CustomCategory: 'Messaging',
  },
  {
    site: 'www.twitter.com',
    CustomCategory: 'Phishing',
  },
  {
    site: 'www.instagram.com',
    CustomCategory: 'Phishing',
  },
  {
    site: 'www.amazon.com',
    CustomCategory: 'Messaging',
  },
];

export const fileHeaders = ['Site', 'Default Category', 'Custom Category'];
export const sampleFileHeaders = ['Site', 'Custom Category'];
