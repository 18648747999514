import _ from 'lodash';
import moment from 'moment';
// enum files
import VectorMapping from 'utility/VectorMapping';
import ThreatCategoryMapping from 'utility/ThreatCategoryMapping';
import severitiesMapping from 'utility/Severities';
import ThreatTypes from 'utility/ThreatTypes';

const valueMaker = (ids) => {
  return ids.map((threatTypeId) => {
    return ThreatTypes[threatTypeId]?.name;
  });
};

const threatsDataFilterMappings = [
  {
    path: 'zappId',
    transform: ({ zappId }) => {
      if (zappId === 'All') {
        return null;
      }
      return {
        label: 'zappId',
        value: zappId,
      };
    },
  },
  {
    path: 'threatTypeName',
    transform: ({ threatTypeName }) => ({
      value: threatTypeName,
      label: 'Threat Name',
    }),
  },
  {
    path: 'vector',
    transform: ({ vector }) => {
      return {
        value: VectorMapping[Number(vector)],
        label: 'Threat Vector',
      };
    },
  },
  {
    path: 'categoryId',
    transform: ({ categoryId }) => ({
      value: _.get(
        ThreatCategoryMapping[Number(categoryId)],
        'name',
        categoryId
      ),
      label: 'Threat Category',
    }),
  },
  {
    path: 'severity',
    transform: ({ severity }) => {
      return {
        value: _.get(severitiesMapping[Number(severity)], 'name', severity),
        label: 'Threat Severity',
      };
    },
  },
  {
    path: 'state',
    transform: ({ state }) => ({
      value: state,
      label: 'Status',
    }),
  },
  {
    path: 'simulated',
    transform: ({ simulated }) => ({
      value: simulated,
      label: 'Simulation State',
    }),
  },
  {
    path: 'timestamp',
    transform: ({ timestamp }) => {
      if (timestamp) {
        return {
          value: `${moment(Number(timestamp)).format(
            'MM-DD-YYYY - HH:mm'
          )} (${moment(Number(timestamp)).fromNow()})`,
          label: 'Timestamp',
        };
      }
    },
  },
  {
    path: 'mitigatedAt',
    transform: ({ mitigatedAt }) => {
      if (mitigatedAt) {
        return {
          value: `${moment(Number(mitigatedAt)).format('MM-DD-YYYY - HH:mm')} `,
          label: 'Last Mitigated',
        };
      }
    },
  },

  {
    path: 'device.zdeviceId',
    transform: (locationProps) => ({
      value: locationProps['device.zdeviceId'],
      label: 'Device ID',
    }),
  },
  {
    path: 'deviceId',
    transform: (locationProps) => ({
      value: locationProps['deviceId'],
      label: 'Device ID',
    }),
  },
  {
    path: 'device.mdmDeviceId',
    transform: (locationProps) => ({
      value: locationProps['device.mdmDeviceId'],
      label: 'MDM ID',
    }),
  },
  {
    path: 'device.mamDeviceId',
    transform: (locationProps) => ({
      value: locationProps['device.mamDeviceId'],
      label: 'MAM ID',
    }),
  },
  {
    path: 'groupId',
    transform: (locationProps) => ({
      value: locationProps['groupId'],
      label: 'Group ID',
    }),
  },
  {
    path: 'zappInstance.externalTrackingId1',
    transform: (locationProps) => ({
      value: locationProps['zappInstance.externalTrackingId1'],
      label: 'Tracking ID 1',
    }),
  },
  {
    path: 'zappInstance.externalTrackingId2',
    transform: (locationProps) => ({
      value: locationProps['zappInstance.externalTrackingId2'],
      label: 'Tracking ID 2',
    }),
  },
  {
    path: 'threatTypeId',
    transform: ({ threatTypeId, dependentData }) => {
      let valueDisplay = threatTypeId && ThreatTypes[threatTypeId]?.name;
      if (Array.isArray(threatTypeId)) {
        valueDisplay = valueMaker(threatTypeId);
      }
      return {
        value: threatTypeId && valueDisplay,
        label: 'Threat Name',
      };
    },
  },
  {
    path: 'summary',
    transform: (props) => {
      return {
        value: _.get(
          props,
          `dependentData.
          ${props.threatTypeId}.description`
        ),
        label: 'Summary',
      };
    },
  },
  {
    path: 'zappInstance.id',
    transform: (locationProps) => ({
      value: locationProps['zappInstance.id'],
      label: 'App ID',
    }),
  },
  {
    path: 'zappInstance.bundleId',
    transform: (locationProps) => ({
      value: locationProps['zappInstance.bundleId'],
      label: 'Bundle ID',
    }),
  },
  {
    path: 'zappInstance.groupId',
    transform: (locationProps) => ({
      value: locationProps['zappInstance.groupId'],
      label: 'Group ID',
    }),
  },
  {
    path: 'zappInstance.name',
    transform: (locationProps) => ({
      value: locationProps['zappInstance.name'],
      label: 'App Name',
    }),
  },
  {
    path: 'zappInstance.version',
    transform: (locationProps) => {
      return {
        value: locationProps['zappInstance.version'],
        label: 'App Version',
      };
    },
  },
  {
    path: 'zappInstance.buildNumber',
    transform: (locationProps) => {
      return {
        value: locationProps['zappInstance.buildNumber'],
        label: 'App Build',
      };
    },
  },
  {
    path: 'zappInstance.zversion',
    transform: (locationProps) => {
      return {
        value: locationProps['zappInstance.zversion'],
        label: 'zDefend Version',
      };
    },
  },
  {
    path: 'zappInstance.zbuildNumber',
    transform: (locationProps) => {
      return {
        value: locationProps['zappInstance.zbuildNumber'],
        label: 'zDefend Build',
      };
    },
  },
  {
    path: 'os',
    transform: ({ os }) => {
      let displayValue = os;
      if (os === 'chrome_os') {
        displayValue = 'Chrome OS';
      }
      return {
        value: displayValue,
        label: 'OS',
      };

      // transform:
    },
  },
  {
    path: 'generalInfo.deviceIp',
    transform: (locationProps) => {
      return {
        value: locationProps['generalInfo.deviceIp'],
        label: 'Device IP',
      };
    },
  },
  {
    path: 'generalInfo.ssid',
    transform: (locationProps) => {
      return {
        value: locationProps['generalInfo.ssid'],
        label: 'Network SSID',
      };
    },
  },
  {
    path: 'generalInfo.bssid',
    transform: (locationProps) => {
      return {
        value: locationProps['generalInfo.bssid'],
        label: 'Network BSSID',
      };
    },
  },
  {
    path: 'generalInfo.attackerIp',
    transform: (locationProps) => ({
      value: locationProps['generalInfo.attackerIp'],
      label: 'Attacker IP',
    }),
  },
  {
    path: 'generalInfo.attackerMac',
    transform: (locationProps) => ({
      value: locationProps['generalInfo.attackerMac'],
      label: 'Attacker Mac',
    }),
  },
  {
    path: 'generalInfo.externalIp',
    transform: (locationProps) => ({
      value: locationProps['generalInfo.externalIp'],
      label: 'External IP',
    }),
  },
  {
    path: 'generalInfo.gatewayMac',
    transform: (locationProps) => ({
      value: locationProps['generalInfo.gatewayMac'],
      label: 'Gateway MAC',
    }),
  },
  {
    path: 'generalInfo.gatewayIp',
    transform: (locationProps) => ({
      value: locationProps['generalInfo.gatewayIp'],
      label: 'Gateway IP',
    }),
  },
  {
    path: 'sideLoadedAppInfo.appName',
    transform: (locationProps) => ({
      value: locationProps['sideLoadedAppInfo.appName'],
      label: 'Sideloaded App Name',
    }),
  },
  {
    path: 'sideLoadedAppInfo.developer',
    transform: (locationProps) => ({
      value: locationProps['sideLoadedAppInfo.developer'],
      label: 'Sideloaded App Developer',
    }),
  },
  {
    path: 'sideLoadedAppInfo.bundleId',
    transform: (locationProps) => ({
      value: locationProps['sideLoadedAppInfo.bundleId'],
      label: 'Sideloaded App Package',
    }),
  },
  {
    path: 'sideLoadedAppInfo.fileHash',
    transform: (locationProps) => ({
      value: locationProps['sideLoadedAppInfo.fileHash'],
      label: 'Sideloaded File Hash',
    }),
  },
  {
    path: 'malwareInfo.bundleId',
    transform: (locationProps) => ({
      value: locationProps['malwareInfo.bundleId'],
      label: 'Bundle ID',
    }),
  },
  {
    path: 'malwareInfo.processName',
    transform: (locationProps) => ({
      value: locationProps['malwareInfo.processName'],
      label: 'Process Name',
    }),
  },
  {
    path: 'malwareInfo.filename',
    transform: (locationProps) => ({
      value: locationProps['malwareInfo.filename'],
      label: 'File Name',
    }),
  },
  {
    path: 'malwareInfo.fileHash',
    transform: (locationProps) => ({
      value: locationProps['malwareInfo.fileHash'],
      label: 'File Hash',
    }),
  },
  {
    path: 'malwareInfo.matches.name',
    transform: (locationProps) => ({
      value: locationProps['malwareInfo.matches.name'],
      label: 'Malware List',
    }),
  },
  {
    path: 'malwareFamilyInfo.familyName',
    transform: (locationProps) => ({
      value: locationProps['malwareFamilyInfo.familyName'],
      label: 'Malware Family',
    }),
  },
  {
    path: 'zeventId',
    transform: (locationProps) => ({
      value: locationProps.zeventId,
      label: 'zEvent Id',
    }),
  },
  {
    path: 'activationName',
    transform: (locationProps) => ({
      value: locationProps.activationName,
      label: 'Activation Name',
    }),
  },
  {
    path: 'phishingUrl',
    transform: (suspiciousUrlInfoProps) => ({
      value: suspiciousUrlInfoProps['phishingUrl'],
      label: 'Phishing URL',
    }),
  },
  {
    path: 'contentCategoryName',
    transform: (suspiciousUrlInfoProps) => ({
      value: suspiciousUrlInfoProps['contentCategoryName'],
      label: 'Content Category Name',
    }),
  },
  {
    path: 'classificationName',
    transform: ({ classificationName }) => ({
      value: classificationName,
      label: 'Risk Status',
    }),
  },
  {
    path: 'os.version',
    transform: (locationProps) => ({
      value: locationProps['os.version'],
      label: 'OS Version',
    }),
  },
];
export default threatsDataFilterMappings;
