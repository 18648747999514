import { Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import AndroidIcon from '@material-ui/icons/Android';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import GenericError from 'components/UI/GenericErrorBox';
import AppleIcon from 'components/UI/icons/AppleIcon';
import { IColumnHeader, ITableRowMapping } from 'components/UI/Table/models';
import { toggleModalDiffered } from 'utils/storeUtils';
import {
  IFetchParamsMapping,
  EPROTECTION_STATUSES_ENUM,
  IzShieldOSType,
} from './models';
import { ProtectionStatus } from './ProtectionStatus';
import { showConfirmDeleteModal } from './utils';

export const defaultZShieldAppsTableHeaders: IColumnHeader[] = [
  {
    flex: 1,
    id: 'name',
    label: 'App',
    maxWidth: 200,
    minWidth: 200,
    path: 'name',
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    flex: 1,
    id: 'description',
    label: 'Description',
    maxWidth: 300,
    minWidth: 150,
    path: 'description',
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: false,
  },
  {
    flex: 1,
    id: 'status',
    label: 'Status',
    maxWidth: 300,
    minWidth: 150,
    path: 'status',
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: false,
  },
  {
    flex: 1,
    id: 'bundleId',
    label: 'Package / Bundle ID',
    maxWidth: 300,
    minWidth: 150,
    path: 'bundleId',
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: false,
  },
  {
    flex: 1,
    id: 'os',
    label: 'Platform',
    maxWidth: 150,
    minWidth: 100,
    path: 'os',
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: false,
  },
  {
    flex: 1,
    id: 'version',
    label: 'Version',
    maxWidth: 150,
    minWidth: 100,
    path: 'version',
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: false,
  },
  {
    flex: 0,
    id: 'actions',
    label: 'Actions',
    maxWidth: 150,
    minWidth: 150,
    resizable: true,
    show: true,
    showInContextMenu: false,
    sort: false,
  },
];

export const fetchProtectedAppsParamsMapping: IFetchParamsMapping[] = [
  {
    path: 'page',
  },
  {
    path: 'size',
  },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      if (orderBy && order) {
        return `${orderBy},${order}`;
      }

      return null;
    },
  },
];

export const zShieldAppsColumnMapping: ITableRowMapping[] = [
  { path: 'name' },
  { path: 'description' },
  {
    path: 'status',
    columnContent: fullData => {
      const { rowData } = fullData;
      const status = rowData?.status;
      const errorMessage = rowData?.errorMessage || EPROTECTION_STATUSES_ENUM.ERRORED.tooltip;
      if (status as unknown as string === 'ERRORED') {
        return (
          <div className="flex--start" style={{ zIndex: '99999' }}>
            <div>
              <ProtectionStatus status={status} hideTooltip />
            </div>
            <Tooltip
              arrow
              enterDelay={300}
              placement="top"
              title={<GenericError errorMessage={errorMessage} />}
            >
              <InfoOutlined
                color="primary"
                style={{ marginLeft: 3, padding: 3 }}
              />
            </Tooltip>
          </div>
        );
      }

      return (
        <>
          <ProtectionStatus status={rowData?.status} />
        </>
      );
    },
  },
  { path: 'bundleId' },
  {
    path: 'os',
    columnContent: fullData => {
      const { rowData } = fullData;
      if (rowData.os === IzShieldOSType.IOS) {
        return (
          <span style={{ marginRight: '5px' }} className="table__os-icon">
            <AppleIcon />
          </span>
        );
      }
      return <AndroidIcon style={{ marginRight: '10px' }} />;
    },
  },
  { path: 'version' },
  {
    path: 'actions',
    columnContent: fullData => {
      const { rowData } = fullData;
      const { id: appId, name: appName } = rowData;

      return (
        <ProtectedComponent allow={{ app_protections: 'manage' }}>
          <div className="flex--spaced" id="action-column">
            <Edit
              className="cursor--pointer"
              onClick={toggleModalDiffered(
                'ZShieldEditDesc',
                {
                  ...rowData,
                },
                { title: 'Edit Description' }
              )}
            />
            <Delete
              className="cursor--pointer"
              onClick={() => showConfirmDeleteModal({ appId, appName })}
            />
          </div>
        </ProtectedComponent>
      );
    },
  },
];

export const protectionCoverageMappings = {
  staticDexEncryption: 'Static Dex Encryption',
  signatureVerification: 'Signature Verification',
  resourceEncryption: 'Resource Encryption',
};
