import { columnHeaders } from './AppPolicy.mapping';
import { default as MUIEditIcon } from '@material-ui/icons/Edit';
import { dismissedNotice as dismissedNoticeAtom } from './atoms';
import { filterAndSortAppPolicyRules } from './utils';
import { IAppPolicyRule } from './models';
import { IQueryParams } from 'components/main/devices/common/models';
import { ISelectItem } from 'components/UI/input/Select';
import { ISelectRuleContent } from 'components/main/oocSelect/models';
import { toggleModalDirect } from 'utils/storeUtils';
import { TRowData } from 'components/UI/GenericTable/models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import AndroidIcon from 'components/UI/icons/AndroidIcon';
import AppleIcon from 'components/UI/icons/AppleIcon';
import Button from 'components/UI/Button';
import cc from 'classcat';
import Checkbox from 'components/UI/input/Checkbox';
import CrossIcon from 'components/UI/icons/CrossIcon';
import GenericTable from 'components/UI/GenericTable';
import useStyles from './useStyles';
import useAppCharacteristics from './AppCharacteristicProvider';

interface IAppPropertiesProps {
  module: string;
  policies: Array<ISelectItem & { created: string }> | undefined;
  policyDetails: IAppPolicyRule[];
  selectedPolicy: ISelectItem | undefined;
  selectedRules: ISelectRuleContent;
  updateUrl: (params: IQueryParams) => void;
}

const AppProperties: React.FC<IAppPropertiesProps> = ({
  module,
  policies,
  policyDetails,
  selectedPolicy,
  selectedRules,
  updateUrl,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { setAppPolicyTabSetting } = useAppCharacteristics();

  const [hideEmptyRules, setHideEmptyRules] = useState<boolean>(false);
  const [showAndroid, setShowAndroid] = useState<boolean>(true);
  const [showIOS, setShowIOS] = useState<boolean>(
    module === 'zdefend' ? false : true
  );

  useEffect(() => {
    setAppPolicyTabSetting(0);
  }, [setAppPolicyTabSetting]);

  const [dismissedNotice, setDismissedNotice] =
    useRecoilState(dismissedNoticeAtom);

  const sortedPolicyDetails = useMemo(
    () =>
      filterAndSortAppPolicyRules(policyDetails, selectedRules, {
        showAndroid,
        showIOS,
        hideEmptyRules,
      }),
    [hideEmptyRules, policyDetails, selectedRules, showAndroid, showIOS]
  );

  const handleEditRule = useCallback(
    (ruleType: string): void => {
      updateUrl({ search: undefined });
      toggleModalDirect(
        'OOCSelectModal',
        {
          variant: 'OOCSelectModal',
          ruleType,
          policyId: selectedPolicy?.value,
          module: module,
        },
        { largeModal: true }
      );
    },
    [module, selectedPolicy?.value, updateUrl]
  );

  return (
    <>
      {!policies?.length && (
        <div className={classes.empty}>
          {t('MTD.POLICIES.APP_POLICY.SELECT_OR_ADD_POLICY')}
        </div>
      )}

      {!!policyDetails?.length && (
        <>
          <div className={cc([classes.card, classes.cardBold])}>
            <div>{t('MTD.POLICIES.APP_POLICY.RULES_IN_THIS_POLICY')}</div>
          </div>
          <div className={classes.filterLabel}>
            {t('MTD.POLICIES.APP_POLICY.QUICK_FILTERS')}
          </div>
          <div>
            <Checkbox
              color="primary"
              label={t('MTD.POLICIES.APP_POLICY.HIDE_EMPTY_RULES')}
              name="ios"
              size="small"
              checked={hideEmptyRules}
              onClick={() => setHideEmptyRules(!hideEmptyRules)}
            />
            <Checkbox
              color="primary"
              label={t('MTD.POLICIES.APP_POLICY.SHOW_ANDROID')}
              name="android"
              size="small"
              checked={showAndroid}
              onClick={() => setShowAndroid(!showAndroid)}
            />
            {module !== 'zdefend' ? (
              <Checkbox
                color="primary"
                label={t('MTD.POLICIES.APP_POLICY.SHOW_IOS')}
                name="ios"
                size="small"
                disabled={module === 'zdefend' ? true : false}
                checked={showIOS}
                onClick={() => setShowIOS(!showIOS)}
              />
            ) : (
              <div></div>
            )}
          </div>
          {!dismissedNotice && (
            <div className={cc([classes.card, classes.cardOutlined])}>
              <div>{t('MTD.POLICIES.APP_POLICY.MARK_RULES')}</div>
              <Button
                variant="text"
                icon={CrossIcon}
                onClick={() => setDismissedNotice(true)}
              />
            </div>
          )}
          <GenericTable
            classList={classes}
            columnHeaders={columnHeaders(t)}
            rowMapping={[
              {
                path: 'actions',
                columnContent: (row) => (
                  <Button
                    variant="text"
                    icon={MUIEditIcon}
                    onClick={() => handleEditRule(row?.rowData?.name as string)}
                  />
                ),
              },
              {
                path: 'platform',
                columnContent: ({ classList, rowData }) => {
                  const platform = rowData?.platform ?? '';

                  return (
                    <div className={classList?.platform}>
                      {/^android$/i.test(platform as string) && <AndroidIcon />}
                      {/^ios$/i.test(platform as string) && <AppleIcon />}
                      {(platform as string)
                        .replace(/ios/i, 'iOS')
                        .replace(/android/i, 'Android')}
                    </div>
                  );
                },
              },
            ]}
            tableData={sortedPolicyDetails as unknown as TRowData[]}
            tableId="App Policy Rules"
          />
        </>
      )}
    </>
  );
};

export default AppProperties;
