import GenericTable from 'components/UI/GenericTable';
import { useTranslation } from 'react-i18next';
import { trmPolicyTableColumnHeaders } from './TRMPolicy.mappings';
import AlertUser from './TableComponents/AlertUser';
import DeviceActions from './TableComponents/DeviceActions';
import Enabled from './TableComponents/Enabled';
import OperatingSystems from './TableComponents/OperatingSystems';
import SeveritySelect from './TableComponents/SeveritySelect';
import TypeColumn from './TableComponents/TypeColumn';
import SelectColumn from './TableComponents/SelectColumn';
import {
  IOSResponseTypes,
  ISelectTRMPolicyTableData,
  PolicyChangeOptions,
} from './models';

interface ITRMPolicyTableProps {
  tableData: ISelectTRMPolicyTableData[];
  handleTRMPolicyRuleChange: (
    optionName: PolicyChangeOptions,
    value: unknown,
    rule: ISelectTRMPolicyTableData | string
  ) => void;
  osResponseTypes: IOSResponseTypes | undefined;
}

const TRMPolicyTable = ({
  tableData,
  handleTRMPolicyRuleChange,
  osResponseTypes,
}: ITRMPolicyTableProps) => {
  const { t } = useTranslation();
  return (
    <GenericTable
      columnHeaders={[
        {
          id: 'select',
          isSelectAllCheckbox: true,
          onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            handleTRMPolicyRuleChange(
              PolicyChangeOptions.EnableCheckbox,
              e.target.checked,
              'all'
            );
          },
        },
        ...trmPolicyTableColumnHeaders(t),
      ]}
      rowMapping={[
        {
          path: 'select',
          columnContent: ({ rowData }) => (
            <SelectColumn
              rowData={rowData as unknown as ISelectTRMPolicyTableData}
              handleTRMPolicyRuleChange={handleTRMPolicyRuleChange}
            />
          ),
        },
        {
          path: 'enabled',
          columnContent: ({ rowData }) => (
            <Enabled
              rowData={rowData as unknown as ISelectTRMPolicyTableData}
              handleTRMPolicyRuleChange={handleTRMPolicyRuleChange}
            />
          ),
        },
        {
          path: 'type',
          columnContent: ({ rowData }) => (
            <TypeColumn
              rowData={rowData as unknown as ISelectTRMPolicyTableData}
            />
          ),
        },
        {
          path: 'alertUser',
          columnContent: ({ rowData }) => (
            <AlertUser
              rowData={rowData as unknown as ISelectTRMPolicyTableData}
              handleTRMPolicyRuleChange={handleTRMPolicyRuleChange}
              showToggle={false}
            />
          ),
        },
        {
          path: 'platform',
          columnContent: ({ rowData }) => (
            <OperatingSystems
              rowData={rowData as unknown as ISelectTRMPolicyTableData}
            />
          ),
        },
        {
          path: 'severity',
          columnContent: ({ rowData }) => (
            <SeveritySelect
              rowData={rowData as unknown as ISelectTRMPolicyTableData}
              handleTRMPolicyRuleChange={handleTRMPolicyRuleChange}
            />
          ),
        },
        {
          path: 'deviceActions',
          columnContent: ({ rowData }) => (
            <DeviceActions
              rowData={rowData as unknown as ISelectTRMPolicyTableData}
              osResponseTypes={osResponseTypes}
              handleTRMPolicyRuleChange={handleTRMPolicyRuleChange}
            />
          ),
        },
      ]}
      tableData={tableData ? (tableData as any) : []}
      tableId="TRM Policy Table"
    />
  );
};

export default TRMPolicyTable;
