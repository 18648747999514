import { getActiveModalAtom } from 'atoms/modals';
import Button from 'components/UI/Button';
import InputError from 'components/UI/input/InputError';
import RadioGroup, { Radio } from 'components/UI/input/RadioGroup';
import TextField from 'components/UI/input/TextField';
import TeamSelectorRadioForm from 'components/main/policies/common/TeamSelectorRadioForm';
import {
  Formik,
  Field as FormikField,
  Form as FormikForm,
  FormikHelpers,
} from 'formik';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSetRecoilState } from 'recoil';
import { IDormancyCreateModalData } from '../models';
import { IDormancyCreateForm, IState } from './models';
import Schema from './schema';
import useStyles from './useStyles';

export interface IFormProps {
  cloningPolicy: boolean;
  defaultName?: string;
  handleSubmit: (
    form: IDormancyCreateForm,
    actions: FormikHelpers<IDormancyCreateForm>
  ) => void;
}
const activeModalAtom = getActiveModalAtom<IDormancyCreateModalData>();

const Form: React.FC<IFormProps> = ({
  cloningPolicy,
  defaultName,
  handleSubmit,
}) => {
  const scopeBounds = useSelector(
    (state: { user: { scopeBounds: string } }) => {
      const { scopeBounds } = state?.user ?? '';
      return scopeBounds;
    }
  );
  const setActiveModal = useSetRecoilState(activeModalAtom);

  const [showTeamSelector, setShowTeamSelector] = useState<boolean>(true);

  const classes = useStyles();

  const teamsHash = useSelector(({ teams }: IState) => teams?.available ?? []);
  const availableTeams = Object.keys(teamsHash).map((teamId) => {
    return {
      label: teamsHash[teamId].name,
      value: teamId,
    };
  });
  const selectedTeamId = useSelector(({ teams }: IState) => {
    return teams?.selected ?? null;
  });
  const selectedTeam = availableTeams.find(
    ({ value }) => value === selectedTeamId
  );

  const handleScopeChange = (value: string) => {
    if (!selectedTeamId && value !== 'global') {
      setShowTeamSelector(true);
      return;
    }

    setShowTeamSelector(false);
  };

  const handleTeamChange = useCallback(
    (teamId: string, setFieldValue: (field: string, value: string) => void) => {
      setFieldValue('teamId', teamId);
    },
    []
  );

  return (
    <Formik
      enableReinitialize
      validateOnBlur
      initialValues={{
        name: defaultName ?? '',
        bounds: 'team',
        teamId: selectedTeamId ?? '',
        selectedTeamId: selectedTeamId ?? '',
      }}
      onSubmit={handleSubmit}
      validationSchema={Schema()}
    >
      {({
        errors,
        handleSubmit: handleFormikSubmit,
        isSubmitting,
        isValid,
        setFieldValue,
        touched,
        values,
      }) => {
        return (
          <FormikForm>
            <FormikField type="hidden" name="selectedTeamId" />
            <FormikField
              name="name"
              label="Policy name"
              component={TextField}
            />
            <RadioGroup label="Policy Type">
              <FormikField
                component={Radio}
                disabled={isSubmitting || scopeBounds === 'TEAM_BOUNDED'}
                label="Global"
                name="bounds"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('bounds', e.currentTarget.value);
                  handleScopeChange(e.currentTarget.value);
                }}
                size="small"
                type="radio"
                value="global"
              />
              <FormikField
                component={Radio}
                disabled={isSubmitting || scopeBounds === 'TEAM_BOUNDED'}
                label={
                  selectedTeam?.label ? `Team (${selectedTeam.label})` : 'Team'
                }
                name="bounds"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('bounds', e.currentTarget.value);
                  handleScopeChange(e.currentTarget.value);
                }}
                size="small"
                type="radio"
                value="team"
              />
            </RadioGroup>

            {showTeamSelector && (
              <TeamSelectorRadioForm
                handleTeamChange={handleTeamChange}
                setFieldValue={setFieldValue}
                availableTeams={availableTeams}
                isRowDataPresent={false}
                values={values}
                selectedTeamId={selectedTeamId}
                scopeBounds={scopeBounds}
              />
            )}
            {!!touched?.teamId && !!errors?.teamId && (
              <InputError override="Team required" />
            )}

            <div className={classes.buttonPanel}>
              <Button
                color="secondary"
                disabled={isSubmitting}
                text="Cancel"
                onClick={() => setActiveModal(undefined)}
              />
              <Button
                color="primary"
                text={cloningPolicy ? 'Clone' : 'Create'}
                disabled={
                  !isValid ||
                  isSubmitting ||
                  (Object.keys(touched).length === 0 && !cloningPolicy)
                }
                isLoading={isSubmitting}
                onClick={() => handleFormikSubmit()}
              />
            </div>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default Form;
