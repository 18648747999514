import 'config/appConfig';
import store, { persistor } from 'config/storeConfig';
import { Provider } from 'react-redux';
import { RecoilRoot } from 'recoil';
import { PersistGate } from 'redux-persist/integration/react';
import AppThemeWrapper from './AppThemeWrapper';

const App = () => (
  <RecoilRoot>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppThemeWrapper />
      </PersistGate>
    </Provider>
  </RecoilRoot>
);

export default App;
