import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import withRouter from 'components/hocs/withRouter';
import {
  getUserScopesRoles,
  getSuperUserScopesRoles,
} from 'reducers/UserReducers';

import TabsNav from './TabsNav';

class Tabs extends React.Component {
  hasProtectedTab = false;

  constructor(props) {
    super(props);
    let { children } = props;

    // check if only 1 child was provided
    if (!_.isEmpty(props.children) && !_.isArray(props.children)) {
      children = [props.children];
    }

    const tabsKeys = children.map(child => {
      let canManage = false;
      let canView = true;
      if (child.props.allow) {
        const userPermissions = _.get(
          props.userScopes,
          `${child.props.scope}.permission`
        );
        canManage = userPermissions === 'manage';
        canManage = userPermissions.includes('manage');
        canView = child.props.allow.indexOf(userPermissions) !== -1;
        this.hasProtectedTab = true;
      }

      return {
        key: child.props.id,
        label: child.props.label,
        allow: child.props.allow,
        scope: child.props.scope,
        isSuper: Boolean(child.props.isSuper),
        shouldShow: child.props.shouldShow,
        isShowing: canManage || canView,
      };
    });

    let firstEligbleKey;
    const initalTabResults = _.find(tabsKeys, tab => {
      // save the first eligable tab to show incase we didn't find an inital tab
      if (!firstEligbleKey && tab.isShowing) {
        firstEligbleKey = tab.key;
      }

      // does the URL have a matching tab, use it
      if (props.location.query.tab && tab.key === props.location.query.tab) {
        return true;
      }

      return tab.key === props.initialTab;
    });

    this.state = {
      activeTab: (initalTabResults && initalTabResults.key) || firstEligbleKey,
      isReady: false,
      tabsKeys,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  render() {
    const { props, state } = this;
    let { children } = props;
    const classes = {
      ...props.classes,
      ...props.classList,
    };
    // check if only 1 child was provided
    if (!_.isEmpty(props.children) && !_.isArray(props.children)) {
      children = [props.children];
    }

    children = children.filter((child, i) => {
      return _.get(state.tabsKeys, `[${i}].isShowing`);
    });

    return (
      <div id="tabs" className={classes.tabs} style={props.style}>
        <TabsNav
          {...state}
          className={classes.tabsNav}
          parentClasses={props.classes}
          onHandleChange={this.handleChange}
          syncUrl={props.syncUrl}
        />
        {children.map(child => {
          if (!child.props.shouldShow) {
            return null;
          }
          if (props.renderHiddenTabs) {
            const styles = {
              display:
                (child.props.id === state.activeTab && 'block') || 'none',
            };

            return (
              <div key={child.props.id} style={styles} className="culprit">
                {child}
              </div>
            );
          }

          if (child.props.id === state.activeTab) {
            return (
              <div className={classes.childWrapper} key={child.props.id}>
                {child}
              </div>
            );
          }

          return null;
        })}
      </div>
    );
  }

  handleChange(event, value) {
    this.setState({
      activeTab: value,
    });

    if (_.isFunction(this.props.onHandleChange)) {
      this.props.onHandleChange(value);
    }
  }
}

Tabs.defaultProps = {
  tabsId: `tabs-${new Date().getTime() - _.random(0, 2000)}`,
  components: {},
  syncUrl: true,
  initialTab: '',
  renderHiddenTabs: false,
  style: {},
};

Tabs.propTypes = {
  tabsId: PropTypes.string.isRequired,
  components: PropTypes.objectOf(PropTypes.node),
  syncUrl: PropTypes.bool,
  initialTab: PropTypes.string,
};

const styles = theme => {
  const { palette } = theme;

  return {
    tabs: {
      height: '100%',
      '& > header': {
        marginBottom: 25,
      },
    },

    tabsAppBar: {
      background: 'none',
      boxShadow: 'none',
    },

    tabsNavSelected: {
      borderBottom: 'none',
      color: palette.zcolor,
    },
    childWrapper: {
      height: '100%',
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    userScopes: ownProps.isSuper
      ? getSuperUserScopesRoles(state)
      : getUserScopesRoles(state),
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps)
)(Tabs);
