import { getActiveModalAtom } from 'atoms/modals';
import Button from 'components/UI/Button';
import Modal from 'components/UI/Modal';
import { FormikProps } from 'formik';
import { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { getUnknownError } from 'utils/errorUtils';
import Form from './Form';
import { IGenericPromptModalData, IGenericPromptModalQuestion } from './models';
import useStyles from './useStyles';
import { useTranslation } from 'react-i18next';

export const GenericPromptModal_TITLE = 'GenericPrompt';

const activeModalAtom = getActiveModalAtom<
  IGenericPromptModalData & IGenericPromptModalQuestion
>();

const GenericPromptModal: React.FC = () => {
  const classes = useStyles();
  const activeModal = useRecoilValue(activeModalAtom);
  const [disabled, setDisabled] = useState<boolean>(false);

  const { t, ready } = useTranslation();

  const handleCancel = useCallback(
    () => activeModal?.payload?.onCancel?.(),
    [activeModal?.payload]
  );

  const handleSubmit = useCallback(
    async (answer: string) => {
      if (activeModal?.payload?.disableOnSubmit) {
        setDisabled(true);
      }

      try {
        await activeModal?.payload?.onConfirm(answer);
      } catch (error: unknown) {
        getUnknownError(error);
      } finally {
        setDisabled(false);
      }
    },
    [activeModal?.payload]
  );

  const renderButtonPanel = useCallback(
    (
      formikProps?: FormikProps<{
        answer: string;
      }>
    ) => (
      <div className={classes.buttonPanel}>
        {!activeModal?.payload?.onCancelHidden && (
          <Button
            color="secondary"
            disabled={
              disabled ||
              formikProps?.isSubmitting ||
              activeModal?.payload?.onCancelDisabled
            }
            onClick={activeModal?.payload?.onCancel}
            text={activeModal?.payload?.onCancelCaption ?? t('GLOBAL.CANCEL')}
          />
        )}
        <Button
          color="secondary"
          disabled={
            disabled ||
            formikProps?.isSubmitting ||
            activeModal?.payload?.onConfirmDisabled
          }
          isLoading={activeModal?.payload?.onConfirmLoading}
          onClick={() =>
            formikProps?.submitForm() ?? activeModal?.payload?.onConfirm()
          }
          text={activeModal?.payload?.onConfirmCaption ?? t('GLOBAL.CONFIRM')}
        />
      </div>
    ),
    [activeModal?.payload, classes.buttonPanel, disabled, t]
  );
  if (!ready) {
    return null;
  }

  return (
    <Modal
      caption={activeModal?.payload?.title ?? ''}
      onClose={!disabled ? handleCancel : undefined}
      title={GenericPromptModal_TITLE}
    >
      {!!activeModal?.payload?.message && (
        <div className={classes.description}>
          {activeModal?.payload?.message}
        </div>
      )}
      {!!activeModal?.payload?.question && (
        <>
          <Form
            defaultAnswer={activeModal?.payload?.question?.defaultAnswer}
            label={activeModal?.payload?.question?.label}
            onSubmit={handleSubmit}
            placeholder={activeModal?.payload?.question?.placeholder}
            renderButtonPanel={(formikProps) => renderButtonPanel(formikProps)}
            requiredCaption={activeModal?.payload?.question?.requiredCaption}
            schema={activeModal?.payload?.question?.schema}
          />
        </>
      )}
      {!activeModal?.payload?.question && renderButtonPanel()}
    </Modal>
  );
};

export default GenericPromptModal;
