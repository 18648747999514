import React from 'react';
import _ from 'lodash';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
// helpers
import {
  csvInjectionRegex,
  csvInjectionErrorMessage,
} from 'utils/componentUtils';

// components
import { FormikTextField } from 'components/inputs/FormikTextField';
import { Grid } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ZButton from 'UI/Buttons/ZButton';

import {
  openSnackBar,
  toggleModalDirect,
  toggleModalDiffered,
} from 'utils/storeUtils';

import { publishEvent } from 'utils/eventUtils';
import { createPartnerAPIKey, updatePartnerAPIKey } from 'api/PartnerService';

function PartnerAuthorizationEditModal(props) {
  const editMode = _.get(props, 'data.description');
  function handleSubmit({ description }, actions) {
    if (!editMode) {
      createPartnerAPIKey({}, { description })
        .then(resp => {
          publishEvent('table:force-fetch-partnerAuthorizations');
          toggleModalDirect('AuthorizationsSecretDisplay', resp.data, {
            title: 'API Access Key Generated',
          });
        })
        .catch(error => {
          toggleModalDirect('PartnerAuthorizationsCreateEdit', false);
          openSnackBar(`Error in creating an API Access Key: ${error}`);
        });
    } else {
      // edit just the description
      updatePartnerAPIKey(
        { partnerApiKeyId: _.get(props, 'data.id') },
        { description }
      )
        .then(() => {
          publishEvent('table:force-fetch-partnerAuthorizations');
          toggleModalDirect('PartnerAuthorizationsCreateEdit', false);
          openSnackBar(`Successfully Updated`);
        })
        .catch(error => {
          toggleModalDirect('PartnerAuthorizationsCreateEdit', false);
          openSnackBar(`Error in updating an API Access Key: ${error}`);
        });
    }
  }
  return (
    <DialogContent>
      {editMode && (
        <>
          <ZButton
            fullWidth
            color="primary"
            styleName="modalSave"
            buttonText="Regenerate Secret"
            action={toggleModalDiffered(
              'AuthorizationsRegenerateConfirm',
              {
                apiKeyId: _.get(props, 'data.id'),
                description: _.get(props, 'data.description'),
                partnerUi: true,
              },
              { title: 'Regenerate Api Access Key' }
            )}
          />
          <div className="or-divider">OR</div>
        </>
      )}
      <Formik
        initialValues={{
          description: _.get(props, 'data.description', ''),
        }}
        validationSchema={AuthSchema}
        validateOnBlur
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting, setFieldValue, values }) => {
          return (
            <Form>
              <Grid container>
                <Grid item xs>
                  <Field
                    label="Description"
                    name="description"
                    component={FormikTextField}
                  />
                </Grid>
              </Grid>

              <DialogActions>
                <ZButton
                  styleName="modalCancel"
                  action={toggleModalDiffered(
                    'PartnerAuthorizationEditModal',
                    false
                  )}
                  color="secondary"
                  buttonText="Cancel"
                />
                <ZButton
                  buttonType="submit"
                  color="primary"
                  styleName="modalSave"
                  buttonText="Save API Access"
                />
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </DialogContent>
  );
}

export const createModalConfig = {
  title: 'Add API Access Key',
  scrollable: true,
  fullWidth: true,
};

export const editModalConfig = {
  title: 'Edit API Access Key',
  scrollable: true,
  fullWidth: true,
};

const AuthSchema = Yup.object().shape({
  description: Yup.string()
    .matches(csvInjectionRegex, csvInjectionErrorMessage)
    .required('Description is required'),
});

export default PartnerAuthorizationEditModal;
