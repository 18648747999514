import storeV60 from './v60-state-reset';
import storeV61 from './v61-add-compliance-column';
import storeV62 from './v62-integrations-header-change';
import storeV63 from './v63-findings-table-reset';
import storeV64 from './v64-zdevPolicies-reset';
import storeV65 from './v65-findings-table-reset';
import storeV68 from './v68-dataexport-reset';
import storeV71 from './v71-zdefend-labels';
import storeV72 from './v72-groups-reset';
import storeV75 from './v75-trm-reset';
import storeV84 from './v84-devices-label-change';
import storeV86 from './v86-zscan-apps-reset';
import storeV88 from './v88-trm-reset-mtd';
import storeV89 from './v89-user-activations-zscan';
import storeV100 from './v100-trm-mtd-device-actions';
import storeV102 from './V102-managed-accounts';
import storeV111 from './V111-reset-accounts';
import storeV112 from './V112-change-data-export-num-exported-column';
import storeV113 from './v113-instances-reset';
import storeV114 from './V114-threats-reset';
import storeV115 from './v115-activations-created';
import storeV116 from './V116-EMM-Table-reset';
import storeV117 from './v117-groups-table-app-policy';
import storeV118 from './v118-roles-table';
import storev119 from './v119-appInventory-reset';
import storev121 from './v121-super-admin-apps';
import storev122 from './v122-reset-authorization';
import storev123 from './V123-device-threats-reset';
import storev124 from './v124-trm-policy-reset';
import storev125 from './v125-user-activations-reset';
import storev126 from './v126-devices-reset';
import storev127 from './v127-EMM-table-reset';
import storev128 from './v128-os-risk-reset';
import storev129 from './v129-ooc-select';
import storev130 from './v130-all-threats-reset';
import storev154 from './v154-devices-threats-full-reset';
import storev155 from './v155-super-partner-users-rest';
import storev157 from './v157-mtd-devices-reset';
import storev162 from './v162-zscan-apps-assessments-reset';
import storev163 from './v163-managed-accts';
import storev164 from './v164-integrations-and-configurations-header-change';
import storev165 from './v165-ticketed-column-infindings';
import storev166 from './v166-removed-support-from-account-management';

export default {
  60: storeV60,
  61: storeV61,
  62: storeV62,
  63: storeV63,
  64: storeV64,
  65: storeV65,
  66: storeV60,
  68: storeV68,
  72: storeV72,
  82: storeV75,
  83: storeV71,
  84: storeV84,
  85: storeV65,
  86: storeV86,
  87: storeV72,
  96: storeV88,
  97: storeV89,
  98: storeV89,
  99: storeV89,
  100: storeV100,
  110: storeV102,
  111: storeV111,
  112: storeV112,
  113: storeV113,
  114: storeV114,
  115: storeV115,
  116: storeV116,
  117: storeV117,
  118: storeV118,
  119: storev119,
  120: storev119,
  121: storev121,
  122: storev122,
  123: storev123,
  124: storev124,
  125: storev125,
  126: storev126,
  127: storev127,
  128: storev128,
  129: storev126,
  130: storev126,
  131: storeV114,
  132: storeV115,
  133: storev123,
  134: storev127,
  135: storev119,
  136: storeV117,
  137: storev119,
  138: storev119,
  139: storev126,
  140: storev127,
  141: storev125,
  142: storev126,
  143: storev126,
  144: storeV72,
  145: storev126,
  146: storev126,
  147: storev128,
  148: storeV114,
  149: storeV86,
  150: storev129,
  151: storev130,
  152: storeV111,
  153: storeV111,
  154: storev154,
  155: storev155,
  156: storev154,
  157: storev157,
  158: storeV117,
  159: storeV86,
  160: storeV86,
  161: storev119,
  162: storev162,
  163: storev163,
  164: storev162,
  165: storev119,
  166: storev162,
  167: storev162,
  168: storev162,
  169: storev126,
  170: storev164,
  171: storev165,
  172: storev166,
};
