import * as Sentry from '@sentry/browser';
import { APP_ENV } from '../config/appConfig';

export function debug(message = '', _tags = [], enableStack = true) {
  const tags = ['debugger', ..._tags];

  if (APP_ENV === 'local' || APP_ENV === 'development') {
    console.warn(`\n${message}`);
    console.warn(tags.join(', ').toUpperCase());

    if (enableStack) {
      console.trace();
    }

    return;
  }

  Sentry.captureEvent({ message });
}
