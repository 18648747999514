import React from 'react';
import ZButton from 'UI/Buttons/ZButton';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import { TextField } from '../../inputs';

const key = 'MICROSOFT_DEFENDER_ATP';

export const MicrosoftDefenderATP = props => {
  const { config } = props;

  const isLoggedIn = _.get(config, 'isLoggedIn._value', false);
  const isEditMode = !_.isEmpty(_.get(config, 'directoryId'));
  const buttonText = isLoggedIn
    ? 'Logged in to Microsoft'
    : 'Login to Microsoft';

  const getTenantIDField = () => {
    if (isLoggedIn || isEditMode) {
      return (
        <TextField
          required
          isDisabled
          label="Tenant ID"
          value={_.get(config, 'directoryId', 'undefined')}
        />
      );
    }
    return null;
  };

  const goToMicrosoft = () => {
    _.invoke(props, 'reduxWrite', {
      microsoft_login_pending: true,
      formState: props.formState,
    });
    window.location.replace(
      `https://login.windows.net/common/adminconsent?client_id=83f2e0a8-e173-4fbc-8e21-d7c78ae20ad9&redirect_uri=${window.location.origin}${window.location.pathname}`
    );
  };

  const getLogoutButton = () => {
    return (
      <ZButton
        isShowing={isLoggedIn}
        onClick={logoutMicrosoft}
        color="primary"
        styleName="modalCancel"
        buttonText="Logout from Microsoft"
      />
    );
  };

  const getLoginButton = () => {
    return (
      <ZButton
        isShowing={!(isEditMode && !isLoggedIn)}
        fullWidth={!isLoggedIn}
        onClick={goToMicrosoft}
        color="primary"
        isDisabled={isLoggedIn}
        styleName="modalSave"
        buttonText={buttonText}
      />
    );
  };
  const logoutMicrosoft = () => {
    _.invoke(props, 'reduxWrite', {
      microsoft_login_pending: false,
      formState: props.formState, // can probably make this empty as well, since it's logout
    });

    const newConfig = _.cloneDeep(config);
    _.update(newConfig, 'isLoggedIn._value', () => false);
    _.update(newConfig, 'directoryId', () => undefined);

    props.onChange(key, newConfig);
  };

  return (
    <div>
      {getTenantIDField()}
      <DialogActions>
        <Grid container spacing={12}>
          <Grid fullWidth item md={isLoggedIn ? 6 : 12}>
            {getLoginButton()}
          </Grid>
          <Grid item md={6}>
            {getLogoutButton()}
          </Grid>
        </Grid>
      </DialogActions>
    </div>
  );
};
