import AddIcon from '@material-ui/icons/Add';
import { fetchPartnerAuthorizationsPage } from 'api/PartnerService';
import withRouter from 'components/hocs/withRouter';
import { Table, TableColumnsContext } from 'components/Tables';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  partnerAuthorizationsTableColumnChange,
  updateUISettings,
} from 'reducers/UiSettingsReducers';
import {
  getChangedTableHeadersHash,
  getUISettingsTableHeaders,
  getUISettingsWithout,
} from 'reducers/UiSettingsSelectors';
import { bindActionCreators, compose } from 'redux';
import ZButton from 'UI/Buttons/ZButton';
import { toggleModalDiffered } from 'utils/storeUtils';
import useBackfill from 'utils/useBackfill';
import { partnerAuthorizationsColumnMapping } from './partnerAuthorizations.mapping';

function PartnerAuthorizations(props) {
  useBackfill({
    query: props.q,
    requiredQueryParams: props.rqps,
    storedQueryParams: props.partnerAuthorizationsUiSettings,
    updateUrl: props.updateUrl,
  });

  function fetchTableData(query) {
    if (_.isEmpty(query)) {
      return new Promise(() => {});
    }
    return new Promise((resolve, reject) => {
      fetchPartnerAuthorizationsPage(query)
        .then((resp) =>
          resolve({
            tableData: resp.data.content,
            totalResults: resp.data.totalElements,
          })
        )
        .catch((error) => reject(error));
    });
  }

  function onPaginationChange(update) {
    props.updateUISettings({
      domain: 'partnerAuthorizations',
      update,
    });
  }

  return (
    <div>
      <div className="view__header">
        <h1>Partner API Keys</h1>
        <ZButton
          action={toggleModalDiffered(
            'PartnerAuthorizationsCreateEdit',
            {},
            {
              title: 'Add Partner API Key',
              fullWidth: true,
            }
          )}
          buttonText="Add Partner API Key"
          color="secondary"
          styleName="titleBar"
          icon={{ elem: AddIcon }}
        />
      </div>

      <TableColumnsContext.Provider
        value={{
          columnHeaders: props.currentTableHeaders,
          columnHeadersHash: props.partnerAuthorizationsColumnHeadersHash,
          onColumnChange: props.partnerAuthorizationsTableColumnChange,
          rowMapping: partnerAuthorizationsColumnMapping,
          onChangePage: onPaginationChange,
          onChangeRows: onPaginationChange,
          onSort: onPaginationChange,
        }}
      >
        <Table
          tableId="partnerAuthorizations"
          fetchTableData={fetchTableData}
        />
      </TableColumnsContext.Provider>
    </div>
  );
}

const mapStateToProps = (state) => ({
  accountId: _.get(state, 'auth.partnerUser.accountId'),
  currentTableHeaders: getUISettingsTableHeaders(
    state,
    'partnerAuthorizations'
  ),
  partnerAuthorizationsColumnHeadersHash: getChangedTableHeadersHash(
    state,
    'partnerAuthorizations'
  ),
  partnerAuthorizationsUiSettings: getUISettingsWithout(
    state,
    'partnerAuthorizations',
    ['tableHeaders']
  ),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      partnerAuthorizationsTableColumnChange,
      updateUISettings,
    },
    dispatch
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(PartnerAuthorizations);
