import { fetchDeviceSecurityScore } from 'api/DevicesService';
import { fetchThreatTypes } from 'api/TRMPolicyService';
import { fetchCriticalRiskyEvents } from 'api/ThreatsService';
import {
  IRiskyCriticalEvents,
  ISecurityScore,
  IThreatType,
  TCriticalRiskyThreatType,
} from './models';

export const fetchCurrentSecurityScore = async (
  teamId?: string | undefined
) => {
  const result: { data: ISecurityScore } = await fetchDeviceSecurityScore({
    teamId,
  });

  return result?.data;
};

export const fetchCriticalRiskyThreatTypes = async (
  lang?: string | null | undefined
) => {
  const result: { data: IThreatType[] } = await fetchThreatTypes({ lang });

  return result?.data?.map(
    ({ id, serverName }) =>
      ({ id, friendlyName: serverName } as TCriticalRiskyThreatType)
  );
};

export const fetchTopCriticalRiskyEvents = async (
  teamId?: string | undefined
) => {
  const result: { data: IRiskyCriticalEvents } = await fetchCriticalRiskyEvents(
    {
      teamId,
    }
  );

  return result?.data;
};
