import { useEffect, useState } from 'react';

const useAsyncResult = <TAsyncResult = any>(
  asyncronousMethod: any,

  // Cannot use ...spread syntax inside useEffect dependencies
  arg1: unknown = undefined,
  arg2: unknown = undefined,
  arg3: unknown = undefined,
  arg4: unknown = undefined,
  delay: number | undefined = undefined,
) => {
  const [result, setResult] = useState<any>();

  useEffect(() => {
    const loadResult = async () => {
      let response = undefined;
      setResult(response);

      // No empty arg calls
      if (![arg1, arg2, arg3, arg4].includes(null)) {
        response = await asyncronousMethod(arg1, arg2, arg3, arg4);
      }

      if (!active) {
        return;
      }
      setResult(response);
    };

    let active = true;
    let timer: ReturnType<typeof setTimeout>

    if(delay) {
      timer = setTimeout(() => {
        loadResult()
      }, delay);
    } else {
      loadResult()
    }

  return () => {
      clearTimeout(timer);
      active = false;
    };
  }, [asyncronousMethod, arg1, arg2, arg3, arg4, delay]);

  return result as TAsyncResult;
};

export default useAsyncResult;
