import { getInstances } from 'api/zDevService';
import { IQueryParams } from './models';

export const fetchTableData = async (query: IQueryParams) => {
  let result; //: IZScanInstances | undefined = undefined;

  try {
    result = await getInstances({ ...query });
  } catch (e) {
    console.error(e);
  }
  return result;
};
