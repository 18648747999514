import React from 'react';
import _ from 'lodash';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import darktheme from 'themes/dark.theme';
import lighttheme from 'themes/light.theme';
import DrawerProvider from 'components/drawer/DrawerProvider';
import AppWrapper from './AppWrapper';

const themes = {
  dark: darktheme,
  light: lighttheme,
};

const AppThemeWrapper = props => (
  <MuiThemeProvider
    theme={{
      brandingPublicBackground: props.brandingPublicBackground,
      brandingPublicBackgroundDark: props.brandingPublicBackgroundDark,
      ...themes[props.theme],
    }}
  >
    <DrawerProvider>
      <AppWrapper />
    </DrawerProvider>
  </MuiThemeProvider>
);

const mapStateToProps = state => {
  return {
    theme: state.uiSettings.theme,
    brandingPublicBackground: _.get(
      state,
      'auth.branding.loginBackgroundImageUrl'
    ),
    brandingPublicBackgroundDark: _.get(state, 'auth.branding.darkModeLoginBackgroundImageUrl')
  };
};

export default connect(mapStateToProps)(AppThemeWrapper);
