import MUIListSubheader from '@material-ui/core/ListSubheader';
import MUIMenuItem from '@material-ui/core/MenuItem';
import { ITableActionGroup } from './TableActions';

export const renderSelectGroup = (group: ITableActionGroup) => {
  const items = group.actions.map((action) => (
    <MUIMenuItem
      value={action.value}
      style={{ marginLeft: '15px' }}
      key={action.value}
    >
      {action.label}
    </MUIMenuItem>
  ));

  return [<MUIListSubheader>{group.group}</MUIListSubheader>, items];
};
