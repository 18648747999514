import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    segment: {
      cursor: 'pointer',
      strokeWidth: 5,
      transition: 'stroke-width 100ms ease',

      '&:hover, &:focus-visible': {
        strokeWidth: 6,
      },
    },
  })
);

export default useStyles;
