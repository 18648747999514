import { formGroupListForSelect } from 'api/GroupService';
import { AxiosResponse } from 'axios';
import { IGroupsHash } from 'components/main/zShieldApps/ZShieldAppDrawer/models';
import { IPolicyGroup } from 'components/modals/GroupActivationsCreateEdit/Form/models';
import { Api } from 'config/axiosConfig';
import { useEffect, useState } from 'react';

const useApiData = () => {
  const [groupsHash, setGroupsHash] = useState<IGroupsHash>({});

  useEffect(() => {
    const fetchGroupsList = async () => {
      try {
        const response: AxiosResponse<IPolicyGroup> | undefined = await Api.get(
          'api/groups/v1/groups',
          {
            params: {
              module: 'ZIPS',
            },
          }
        );
        const groupsData = response?.data;
        const groupsHash = formGroupListForSelect(groupsData);
        return setGroupsHash(groupsHash);
      } catch (err) {
        console.error('Error fetching groups: ', err);
      }
    };

    fetchGroupsList();
  }, []);

  return {
    groupsHash,
  };
};

export default useApiData;
