import { IConnector } from '../../models';
import MUIInfoIcon from '@material-ui/icons/InfoOutlined';
import useStyles from './useStyles';
import { TFunction } from 'react-i18next';

export interface IPolicyGroupTooltipProps {
  connector?: IConnector;
  setShowGroupsTooltip: React.Dispatch<React.SetStateAction<boolean>>;
  showGroupsTooltip: boolean;
  t: TFunction<'translation', undefined>;
}
export const PolicyGroupTooltip: React.FC<IPolicyGroupTooltipProps> = ({
  connector,
  setShowGroupsTooltip,
  showGroupsTooltip,
  t,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.infoTooltip}>
      {connector?.id === 'GoogleAdminConnector' ? (
        <h2>{t('MTD.INTEGRATIONS.POLICY_ORGANIZATION_UNITS')}</h2>
      ) : (
        <h2>{t('MTD.INTEGRATIONS.POLICY_GROUPS')}</h2>
      )}
      <div
        className={classes.tooltip}
        onMouseEnter={() => setShowGroupsTooltip?.(true)}
        onMouseLeave={() => setShowGroupsTooltip?.(false)}
      >
        <MUIInfoIcon color="primary" />
        {showGroupsTooltip && (
          <div className={classes.tooltipText}>
            {connector?.id === 'GoogleAdminConnector'
              ? t('MTD.INTEGRATIONS.POLICY_ORGANIZATION_UNITS_DESCRIPTION')
              : t('MTD.INTEGRATIONS.POLICY_GROUPS_DESCRIPTION')}
          </div>
        )}
      </div>
    </div>
  );
};
export default PolicyGroupTooltip;
