import * as Sentry from '@sentry/browser';
import { UPDATE_PARTNER_USER, UPDATE_SUPER_USER } from 'reducers/UserReducers';
import { publishEvent } from 'utils/eventUtils';
import AT from 'actions/ActionTypes';

export const logout = () => {
  // delete local storage, though redux-persist will add it back
  // with the defaults due to the return statement below
  // localStorage.removeItem(`persist:${rootPersistorConfig.key}`);

  // tell authWorker user logged out
  publishEvent('auth:logout');

  return {
    type: AT.USER_LOGOUT,
  };
};

export const setCurrentUser = body => {
  // set current user in sentry
  Sentry.configureScope(scope => {
    scope.setUser({
      id: body.id,
    });
    scope.setExtra({
      organization: body.organization,
    });
  });
  return {
    type: AT.SET_CURRENT_USER,
    payload: body,
  };
};

export const updatePartnerUser = payload => {
  // set current user in sentry
  Sentry.configureScope(scope => {
    scope.setUser({
      id: payload.id,
    });
    scope.setExtra({
      organization: payload.organization,
    });
  });

  return {
    type: UPDATE_PARTNER_USER,
    payload,
  };
};

export const updateSuperUser = payload => {
  // set current user in sentry
  Sentry.configureScope(scope => {
    scope.setUser({
      id: payload.id,
    });
    scope.setExtra({
      organization: payload.organization,
    });
  });

  return {
    type: UPDATE_SUPER_USER,
    payload,
  };
};
