import { Api } from 'config/axiosConfig';
import { ServiceHandlerFactory } from 'utils/serviceUtils';


export const refreshAccessToken = (tokens) => {
  return Api.post('api/auth/v1/access', tokens)
}

// export function enterAccount(accountId) {
//   return Api.post(`api/auth/v1/accounts/${accountId}/enter`);
// }

export const enterAccount = ServiceHandlerFactory({
  axios: {
    url: 'api/auth/v1/accounts/:accountId/enter',
    method: 'post',
  },
  isCancelable: true,
});

export const checkSSO = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/sso/status',
  },
  isCancelable: true,
});

export const postSSOToken = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/sso/token',
    method: 'post',
  },
  isCancelable: true,
});

// export const getSystemProperties = ServiceHandlerFactory({
//   axios: {
//     url: '/api/auth/v1/system/properties',
//   },
//   isCancelable: true,
// });

export const getSystemProperties = Api.get('/api/auth/v1/system/properties');
