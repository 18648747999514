import moment from 'moment';
// ui
import MUICard from '@material-ui/core/Card';
import MUICardContent from '@material-ui/core/CardContent';
import MUIInputIcon from '@material-ui/icons/Input';
import React from 'react';
import { Link } from 'react-router-dom';
import ActivationCode from '../ActivationCode';
import ActivationLink from '../ActivationLink';
import MDMTableRowDetailsTabs from '../MDMTableRowDetailsTabs';
import useStyles from './useStyles';
import { BASIC_TABLE_CONFIG, DETAILS_TABLE_CONFIG } from '../MDM/utils';
import DetailTable from './DetailTable';
import { IMDMRowData } from '../MDM/models';
import { IBasicData, IDetailData } from './models';
import { useTranslation } from 'react-i18next';

interface IMDMTableRowDetailsProps {
  isShowing?: boolean;
  rowData: IMDMRowData;
}

const MDMTableRowDetails: React.FC<IMDMTableRowDetailsProps> = (props) => {
  const { rowData, isShowing = true } = props;
  const {
    auth,
    baseUrl,
    connectorApiVersion,
    connectorType,
    maxDeviceIdleDays,
    mode,
    modified,
    name: connectionName,
    provisionId,
    provisioned,
    syncInfo,
  } = rowData;

  const basicData: IBasicData = {
    apiKey: auth?.api_key,
    baseUrl,
    connectionName,
  };
  const detailData: IDetailData = {
    connectorApiVersion,
    maxDeviceIdleDays,
    mode,
    modified,
    provisionId,
    provisioned,
    proxy_config_id: auth?.proxy_config_id,
    status:
      syncInfo?.lastCompletedStatus && syncInfo?.lastCompletedAt
        ? `${syncInfo.lastCompletedStatus} - ${moment(
          syncInfo.lastCompletedAt
        ).fromNow()}`
        : ' ',
  };

  const { t } = useTranslation();
  const classes = useStyles();
  const microsoftFlag = (rowData?.connectorType?.id ?? 'no').includes('Intune');
  const hasGroupsLength = rowData?.connectedGroups?.length > 0;

  if (!isShowing) {
    return null;
  }

  return (
    <MUICard className={classes.cardDetails}>
      <MUICardContent className={classes.cardContentDetails}>
        <div style={{ width: '50%' }}>
          <div className={classes.logoWrapper}>
            <img
              alt={t('GLOBAL.EMM_LOGO')}
              className={classes.logo}
              src={`/api/emm/${connectorType.logo}`}
            />
            <div className={classes.displayName}>
              {connectorType.displayName}
            </div>
          </div>
          <DetailTable data={basicData} tableConfig={BASIC_TABLE_CONFIG(t)} />
        </div>
        <div className={classes.dataWrapper}>
          <DetailTable
            data={detailData}
            hiddenFields={
              microsoftFlag ? ['maxDeviceIdleDays', 'status'] : ['mode']
            }
            tableConfig={DETAILS_TABLE_CONFIG(t)}
          />
          {!microsoftFlag && (
            <>
              <ActivationLink
                emmConnectionId={rowData.id}
                isShowing={!microsoftFlag}
                t={t}
              />
              <ActivationCode
                connectorTypeId={rowData?.connectorType?.id ?? ''}
                emmConnectionId={rowData.id}
              />
            </>
          )}
        </div>
      </MUICardContent>
      {hasGroupsLength && (
        <>
          <div className={classes.linkWrapper}>
            <h4 className={classes.label}>{t('GLOBAL.GROUPS')}</h4>
            <Link
              className={classes.link}
              to={`/console/mtd/policies/groups?emmConnectionId=${rowData.id}`}
            >
              <MUIInputIcon />
            </Link>
          </div>
          <MDMTableRowDetailsTabs {...props} />
        </>
      )}
    </MUICard>
  );
};

export default MDMTableRowDetails;
