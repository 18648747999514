import { Api } from 'config/axiosConfig';
import Axios from 'axios';
import {
  IRawParsedZScanScript,
  ISubmitBuildFile,
  ISubmitUrl,
} from '../../models';
import { IZScanUrlSubmissionApp } from 'components/main/ZScanApps/models';

const { CancelToken } = Axios;
const source = CancelToken.source();

export const zScanTranscriptParser = (rawString: string) => {
  if (!rawString) {
    return [];
  }
  const parsedZScanScript = JSON.parse(rawString);
  // editing table data
  if (parsedZScanScript instanceof Array) {
    return parsedZScanScript;
  }
  // comes in raw from build data or file upload (getJsonString)
  const { zScanScript } = parsedZScanScript;
  if (!zScanScript) {
    return [];
  }
  return zScanScript.map((rowData: any, index: number) => {
    return {
      id: `${index}`,
      ...rowData,
    };
  });
};

export const submitBuildFile = ({
  fullBuildFile,
  notifyUploader,
  setFileLoaded,
}: ISubmitBuildFile) =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('buildFile', fullBuildFile);
    formData.append('notifyUploader', notifyUploader as string);
    Api.post('api/zdev-upload/v1/uploads/build', formData, {
      cancelToken: source.token,
      onUploadProgress: (ProgressEvent) => {
        setFileLoaded((ProgressEvent.loaded / ProgressEvent.total) * 100);
      },
    })
      .then(({ data }) => {
        const errorMessage = data?.zdevUploadResponse?.errorMessage;
        if (errorMessage) {
          resolve({ error: errorMessage });
        }
        resolve(data);
      })
      .catch((e) => {
        console.log('Error:', e);
        reject('Unknown error');
      });
  });


export const submitUrl = ({
  storeUrl,
  notifyUploader,
  shouldAutoUpdateFromAppStore,
  value: teamId,
}: ISubmitUrl): Promise<IZScanUrlSubmissionApp> =>
  new Promise<IZScanUrlSubmissionApp>((resolve, reject) => {
    const formData = new FormData();
    formData.append('url', storeUrl);
    formData.append('notifyUploader', notifyUploader as string);
    formData.append(
      'shouldAutoUpdateFromAppStore',
      shouldAutoUpdateFromAppStore as string
    );
    if (teamId) {
      formData.append('teamId', teamId);
    }
    Api.post('api/zdev-upload/v1/uploads/url', formData, {})
      .then((resp) => {
        resolve(resp as unknown as IZScanUrlSubmissionApp);
      })
      .catch((error) => {
        reject(error);
      });
  });

export function getParsedJSON(file: Blob): Promise<IRawParsedZScanScript> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = function () {
      const result = reader.result;
      resolve(JSON.parse(result as string));
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
      reject(error);
    };
  });
}

export function buildPayloadForZScanTranscript(tableData: any) {
  let payload = tableData;
  if (typeof payload === 'string') {
    payload = JSON.parse(tableData);
  }

  const { zScanScript } = payload;

  if (!zScanScript) {
    return {
      zScanScript: payload.map((field: any) => {
        const controlType = field?.controlType?.value || field?.controlType;
        return {
          ...field,
          controlType: controlType ?? ''
        };
      })
    };
  }
  return payload;

}