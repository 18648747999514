import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

export default state => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      managedAccounts: {
        ...uiSettingsDefaults.managedAccounts,
        tableHeaders: uiSettingsDefaults.managedAccounts.tableHeaders,
      },
    },
  };
};
