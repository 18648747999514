import { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import Spinner from 'components/main/common/Spinner';
import { postSSOToken } from 'api/AuthService';
import withRouter from 'components/hocs/withRouter';
import {  compose } from 'redux';


const SSOLogin = ({ user, superUser, partnerUser, history, location }) => {
  useEffect(() => {
    if (user?.isAuthenticated || superUser?.isAuthenticated) {
      history.replace('/console');
    } else if (partnerUser?.isAuthenticated) {
      history.replace('/partner');
    } else {
      const { token, ssoType } = location.query;
      submitToken({ token, ssoType });
    }
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const submitToken = useCallback((payload) => {
    postSSOToken({}, payload)
      .then(() => {
        history.replace('/admin');
      })
      .catch((error) => {
        console.error('Could not login via SSO', error);
        history.push({
          pathname: '/login',
          state: {
            error_type: 'sso',
            message: 'An error occurred when trying to login via SSO',
            status: error.response.status,
          },
        });
      });
  }, [])

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spinner />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  superUser: state.auth.superUser,
  partnerUser: state.auth.partnerUser,
});

export default compose(withRouter, connect(mapStateToProps))(SSOLogin);
