import React, { useContext } from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import { IZscanIndicator } from './models';
import { DrawerContext } from 'components/drawer/DrawerProvider';
import { IZScanPoliciesDrawerData } from './ZScanPolicySideDrawer/model';
import { IDrawerContext } from 'components/drawer/DrawerProvider/models';
import Drawer from './ZScanPolicySideDrawer';

interface IVisibilityIcon {
  rowData: IZscanIndicator;
  policyId: string;
}

const VisibilityButton = ({ rowData, policyId }: IVisibilityIcon) => {
  const { openDrawer } =
    useContext<Partial<IDrawerContext<IZScanPoliciesDrawerData>>>(
      DrawerContext
    );
  const handleOpenDrawer = async () => {
    if (!openDrawer) {
      return;
    }
    openDrawer({
      drawerProps: {
        rowData: { ...rowData, policyId },
        module: 'ZDEV',
        classList: '',
      },
      drawer: Drawer,
    });
  };
  return (
    <div onClick={handleOpenDrawer}>
      <Tooltip title="Policy Details" className="hover--link">
        <VisibilityIcon className="cursor--pointer" />
      </Tooltip>
    </div>
  );
};

export default VisibilityButton;
