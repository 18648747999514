import { updateUserProfilePassword } from 'api/PasswordPolicyService';
import Button from 'components/UI/Button';
import PasswordField from 'components/UI/input/PasswordField';
import { passwordErrorHelper } from 'components/entry/password.mappings';
import {
  Formik,
  Field as FormikField,
  Form as FormikForm,
  FormikHelpers,
} from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { openSnackBar } from 'utils/storeUtils';
import { IChangePassword } from '../models';
import Schema from './schema';

const Form: React.FC = () => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    (
      values: IChangePassword,
      { setErrors, setSubmitting, resetForm }: FormikHelpers<IChangePassword>
    ) => {
      updateUserProfilePassword({
        oldPassword: values.oldPassword,
        password: values.password,
        confirmedPassword: values.confirmedPassword,
      })
        .then(() => {
          openSnackBar(t('USER_SETTINGS.PASSWORD.SUCCESS'));
          resetForm();
        })
        .catch((error) => {
          setSubmitting(false);
          let newErrors: { [key: string]: string } = {
            oldPassword: t('USER_SETTINGS.ERRORS.UNKNOWN'),
          };

          if (error.response.status === 400) {
            const isPWRulesBrokenPresent = error?.response?.data.length > 0;
            if (isPWRulesBrokenPresent) {
              newErrors = {
                password: passwordErrorHelper(error?.response?.data, t),
              };
            } else {
              newErrors = {
                oldPassword: t('USER_SETTINGS.ERRORS.OLD_PASSWORD_INCORRECT'),
              };
            }
          }
          if (error.response.status === 409) {
            newErrors = {
              password:
                error?.response?.data ?? t('USER_SETTINGS.ERRORS.UNKNOWN'),
            };
          }
          return setErrors(newErrors);
        });
    },
    [t]
  );

  return (
    <Formik
      validationSchema={Schema(t)}
      initialValues={{ oldPassword: '', password: '', confirmedPassword: '' }}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ dirty, handleSubmit: handleFormikSubmit, isSubmitting, isValid }) => (
        <FormikForm>
          <FormikField
            component={PasswordField}
            label={t('USER_SETTINGS.PASSWORD.OLD_PASSWORD')}
            name="oldPassword"
            disabled={isSubmitting}
          />
          <FormikField
            component={PasswordField}
            label={t('USER_SETTINGS.PASSWORD.NEW_PASSWORD')}
            name="password"
            disabled={isSubmitting}
          />
          <FormikField
            component={PasswordField}
            label={t('USER_SETTINGS.PASSWORD.CONFIRM_NEW_PASSWORD')}
            name="confirmedPassword"
            disabled={isSubmitting}
          />
          <Button
            color="secondary"
            text={t('USER_SETTINGS.PASSWORD.CHANGE_PASSWORD')}
            disabled={!dirty || isSubmitting || !isValid}
            isLoading={isSubmitting}
            onClick={() => handleFormikSubmit()}
          />
        </FormikForm>
      )}
    </Formik>
  );
};

export default Form;
