import MUIDialog from '@material-ui/core/Dialog';
import MUIDialogActions from '@material-ui/core/DialogActions';
import MUIDialogContent from '@material-ui/core/DialogContent';
import MUIDialogTitle from '@material-ui/core/DialogTitle';
import MUIFormControl from '@material-ui/core/FormControl';
import MUIInputAdornment from '@material-ui/core/InputAdornment';
import MUIMenuItem from '@material-ui/core/MenuItem';
import MUISelect from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import { getActiveModalAtom } from 'atoms/modals';
import Button from 'components/UI/Button';
import GenericTable from 'components/UI/GenericTable';
import { GenericPromptModal_TITLE } from 'components/UI/Modals/GenericPrompt';
import { IGenericPromptModalData } from 'components/UI/Modals/GenericPrompt/models';
import CheckIcon from 'components/UI/icons/CheckIcon';
import DeleteIcon from 'components/UI/icons/DeleteIcon';
import ErrorIcon from 'components/UI/icons/ErrorIcon';
import RouterIcon from 'components/UI/icons/RouterIcon';
import SearchIcon from 'components/UI/icons/SearchIcon';
import Checkbox from 'components/UI/input/Checkbox';
import TextField from 'components/UI/input/TextField';
import JsFileInfoCard from 'components/fileuploader/FileInfoCard';
import JsUploader from 'components/fileuploader/Uploader';
import _ from 'lodash';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { openSnackBar } from 'utils/storeUtils';
import { searchTable } from 'utils/tableUtils';
import { renderSelectGroup } from './SelectGroup';
import { ITableActionGroup, TableActions, tableActions } from './TableActions';
import { arrayToCSV } from './arrayToCSV';
import { csvToArray } from './csvToArray';
import { downloadFile } from './downloadFile';
import { IWifiAccessPoint } from './models';
import useStyles from './useStyles';

const Uploader: any = JsUploader;
const FileInfoCard: any = JsFileInfoCard;

const fileHeaders = ['ssid', 'bssid', 'ipAddress'];

const exampleData = [
  {
    ssid: 'Dreamworks Warehouse WIFI',
    bssid: '10:D1:F2:65:12:21',
    accesspointIpAddress: '192.168.0.1',
  },
  {
    ssid: 'AcmeCorp Warehouse WIFI',
    bssid: '10:D1:F2:65:12:22',
    accesspointIpAddress: '192.168.0.2',
  },
  {
    ssid: 'SomeCorp Warehouse WIFI',
    bssid: '10:D1:F2:65:12:23',
    accesspointIpAddress: '192.168.0.3',
  },
  {
    ssid: 'OtherCorp Warehouse WIFI',
    bssid: '10:D1:F2:65:12:24',
    accesspointIpAddress: '192.168.0.4',
  },
];

const searchFields: (keyof IWifiAccessPoint)[] = [
  'ssid',
  'bssid',
  'accesspointIpAddress',
];

const activeModalAtom = getActiveModalAtom<IGenericPromptModalData>();

interface AddSsidModalProps {
  isOpen: boolean;
  onClose: () => void;
  wifiAccessPoints: IWifiAccessPoint[];
  onWifiAccessPointsChanged: (ssids: IWifiAccessPoint[]) => void;
}

const AddSsidModal: React.FC<AddSsidModalProps> = (props) => {
  const { isOpen, onClose, onWifiAccessPointsChanged, wifiAccessPoints } =
    props;

  const classes = useStyles();

  const { t, ready } = useTranslation();

  const setActiveModal = useSetRecoilState(activeModalAtom);

  const [searchTerm, setSearchTerm] = useState<string>();
  const [data, setData] = useState<IWifiAccessPoint[]>(wifiAccessPoints);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTableAction, setSelectedTableAction] =
    useState<TableActions>();

  useEffect(() => {
    if (
      (data.length > 0 || wifiAccessPoints.length > 0) &&
      !_.isEqual(data, wifiAccessPoints)
    ) {
      onWifiAccessPointsChanged(data);
    }
  }, [data, wifiAccessPoints, onWifiAccessPointsChanged]);

  const exportToCSV = useCallback((): void => {
    const isExample = selectedTableAction === TableActions.DownloadExample;

    let values;
    let fileName = '';

    if (isExample) {
      fileName = 'Example Wifi Access Points';
      values = exampleData;
    } else {
      fileName = `wifiAccessPoints-${new Date().toLocaleDateString()}.csv`;

      const newData =
        selectedTableAction === TableActions.ExportSelected
          ? data.filter(ssid => ssid.selected).map((ssid) => {
            return {
              ssid: ssid.ssid,
              bssid: ssid.bssid,
              accesspointIpAddress: ssid.accesspointIpAddress
            };
          })
          : [...data];

      values = newData.map(({ ssid, bssid, accesspointIpAddress }) => ({
        ssid,
        bssid,
        accesspointIpAddress,
      })) ?? [];
    }

    const csvData = arrayToCSV(fileHeaders, values);
    const blob = new Blob([csvData], { type: 'text/csv' });

    downloadFile(blob, fileName);

    setSelectedTableAction(undefined);
  }, [data, selectedTableAction, setSelectedTableAction]);

  const onSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setSearchTerm(value);
    },
    [setSearchTerm]
  );

  const onDeleteClick = useCallback(
    (item: any): void => {
      setActiveModal({
        active: GenericPromptModal_TITLE,
        payload: {
          title: t('MTD.POLICIES.NETWORK_POLICY.DELETE_SSID'),
          message: t('MTD.POLICIES.NETWORK_POLICY.CONFIRM_DELETE'),
          onCancel: () => setActiveModal(undefined),
          onConfirm: () => {
            const newData = [...data].filter((el) => el.id !== item.id);
            setData(newData);
            setActiveModal(undefined);
          },
        },
      });
    },
    [data, setActiveModal, t]
  );

  const onEnabledChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const { id } = event.target;

      const newData = _.cloneDeep(data);
      const index = newData.findIndex(
        (wifiAccessPoint) => wifiAccessPoint.id === id
      );
      newData[index].enabled = checked;

      setData(newData);
    },
    [data]
  );

  const onSelectCheckboxChange = useCallback(
    (wifiAccessPoint: IWifiAccessPoint): void => {
      const selectedIndex = data.findIndex(
        (el) => el.id === wifiAccessPoint.id
      );

      const newData = [...data];
      newData[selectedIndex] = {
        ...wifiAccessPoint,
        selected: !wifiAccessPoint.selected,
      };

      setData(newData);
    },
    [data]
  );

  const onTableActionChange = useCallback(
    (
      event: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ): void => {
      const { value } = event.target;
      setSelectedTableAction(value as TableActions);
    },
    [setSelectedTableAction]
  );

  const onGoClick = useCallback((): void => {
    switch (selectedTableAction) {
      case TableActions.DeleteSelected: {
        const newData = data.filter((el) => !el.selected);
        setData(newData);
        setSelectedTableAction(undefined);
        break;
      }
      case TableActions.ExportSelected:
      case TableActions.ExportTable:
      case TableActions.DownloadExample:
        exportToCSV();
        break;
      case TableActions.ImportTable:
      case TableActions.ImportTableAppend:
        setIsUploadOpen(true);
        break;
      default:
        break;
    }
  }, [data, selectedTableAction, exportToCSV]);

  const onUploadClose = useCallback((): void => {
    setIsUploadOpen(false);
  }, [setIsUploadOpen]);

  const setFileData = useCallback(
    (fileData: File): void => {
      setSelectedFile(fileData);
    },
    [setSelectedFile]
  );

  const removeFile = useCallback((): void => {
    setSelectedFile(undefined);
  }, [setSelectedFile]);

  const handleSubmitForm = useCallback((): void => {
    if (selectedFile) {
      try {
        setIsLoading(true);

        const fileReader = new FileReader();

        fileReader.onload = function (event) {
          if (event.target) {
            const text = event.target.result;

            if (text) {
              const headers: (keyof IWifiAccessPoint)[] = [
                'ssid',
                'bssid',
                'accesspointIpAddress',
              ];
              const wifiAccessPoints = csvToArray(text as string, headers, {
                enabled: true,
                selected: false,
              });

              if (wifiAccessPoints.length > 0) {
                const newData = wifiAccessPoints;

                if (
                  data.length > 0 &&
                  selectedTableAction === TableActions.ImportTableAppend
                ) {
                  newData.push(..._.cloneDeep(data));
                }

                setData(newData);
              }
            }
          }
        };

        fileReader.readAsText(selectedFile);

        setSelectedFile(undefined);
        setSelectedTableAction(undefined);
        openSnackBar(t('GLOBAL.SUCCESS_UPLOAD'));
        onUploadClose();
      } catch (error: any) {
        openSnackBar(
          error?.response?.data?.message || t('GLOBAL.SOMETHING_WRONG')
        );
      } finally {
        setIsLoading(false);
      }
    }
  }, [data, selectedTableAction, selectedFile, onUploadClose, t]);

  const selectGroup = useCallback(
    (group: ITableActionGroup) => renderSelectGroup(group),
    []
  );

  if (!ready) {
    return null;
  }

  return (
    <div>
      <MUIDialog open={isOpen} onClose={onClose} fullWidth maxWidth="xl">
        <MUIDialogTitle style={{ display: 'flex', justifyContent: 'center' }}>
          {t('MTD.POLICIES.NETWORK_POLICY.ALLOW_SSIDS')}
        </MUIDialogTitle>
        <MUIDialogContent>
          <div className={classes.dialogRoot}>
            <div className={classes.warningLabel}>
              <span style={{ marginRight: '30px' }}>
                {t(
                  'MTD.POLICIES.NETWORK_POLICY.SSID_BSSID_IP_ADDRESS_REQUIRED'
                )}
                <br />
                {t(
                  'MTD.POLICIES.NETWORK_POLICY.MITM_THREATS_SUPPRESSED_WHEN_DEVICE_CONNECTED_ACCESS_POINTS'
                )}
              </span>
              <ErrorIcon color="error" />
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: '50px',
                }}
              >
                <div className={classes.iconButton}>
                  <div className={classes.iconContainer}>
                    <RouterIcon />
                  </div>
                  <span style={{ fontSize: 'x-small' }}>SSID</span>
                </div>

                <div className={classes.iconButton}>
                  <div className={classes.iconContainer}>
                    <CheckIcon />
                  </div>
                  <span style={{ fontSize: 'x-small' }}>
                    {t('MTD.POLICIES.NETWORK_POLICY.SAFE')}
                  </span>
                </div>
              </div>

              <div>
                <MUIFormControl className={classes.selectFormControl}>
                  <MUISelect
                    displayEmpty
                    onChange={onTableActionChange}
                    value={selectedTableAction || ''}
                  >
                    <MUIMenuItem value="" disabled>
                      {t('GLOBAL.TABLE_ACTIONS')}
                    </MUIMenuItem>
                    {tableActions(t).map((group) => selectGroup(group))}
                  </MUISelect>
                </MUIFormControl>

                <Button
                  text={t('GLOBAL.GO')}
                  color="secondary"
                  style={{ height: 'min-content' }}
                  onClick={onGoClick}
                />
              </div>
            </div>

            <TextField
              name="search"
              placeholder={t('GLOBAL.SEARCH')}
              onChange={onSearchChange}
              InputProps={{
                endAdornment: (
                  <MUIInputAdornment
                    position="end"
                    classes={{ root: classes.searchIcon }}
                  >
                    <SearchIcon />
                  </MUIInputAdornment>
                ),
              }}
            />

            <GenericTable
              classList={classes}
              columnHeaders={[
                { id: 'select', label: '' },
                { id: 'ssid', label: 'SSID' },
                { id: 'bssid', label: 'BSSID' },
                {
                  id: 'accesspointIpAddress',
                  label: t(
                    'MTD.POLICIES.NETWORK_POLICY.ACCESS_POINT_IP_ADDRESS'
                  ),
                },
                { id: 'enabled', label: t('GLOBAL.ENABLED') },
                { id: 'actions', label: t('GLOBAL.ACTIONS') },
              ]}
              rowMapping={[
                {
                  path: 'select',
                  columnContent: ({ rowData }) => (
                    <Checkbox
                      key={rowData?.id}
                      checked={rowData?.selected as boolean}
                      onChange={() =>
                        onSelectCheckboxChange(
                          rowData as unknown as IWifiAccessPoint
                        )
                      }
                      color="primary"
                      className="table__checkbox"
                    />
                  ),
                },
                {
                  path: 'actions',
                  columnContent: ({ rowData }) => (
                    <Button
                      style={{ padding: 0, minWidth: 'auto' }}
                      variant="text"
                      icon={DeleteIcon}
                      onClick={() => onDeleteClick(rowData)}
                    />
                  ),
                },
                {
                  path: 'enabled',
                  columnContent: ({ rowData }) => (
                    <Switch
                      id={rowData?.id as string}
                      checked={rowData?.enabled as boolean}
                      onChange={onEnabledChange}
                    />
                  ),
                },
              ]}
              tableData={searchTable(searchTerm, data, searchFields) as any}
              tableId="Network Policy Rules"
            />
          </div>
        </MUIDialogContent>

        <MUIDialogActions>
          <Button text={t('GLOBAL.OK')} color="secondary" onClick={onClose} />
        </MUIDialogActions>
      </MUIDialog>

      <MUIDialog open={isUploadOpen} onClose={onUploadClose}>
        <MUIDialogTitle style={{ display: 'flex', justifyContent: 'center' }}>
          {t('GLOBAL.UPLOAD_CSV')}
        </MUIDialogTitle>
        <MUIDialogContent>
          {!selectedFile && (
            <div className={classes.formGroup}>
              <Uploader
                accept=".csv"
                isShowing={true}
                getFileData={setFileData}
              />
            </div>
          )}

          {selectedFile && (
            <FileInfoCard
              fileData={selectedFile}
              isShowing={!!selectedFile}
              removeFile={removeFile}
              noActionButtons
            />
          )}
        </MUIDialogContent>

        <MUIDialogActions>
          <Button
            text={t('GLOBAL.UPLOAD')}
            disabled={!selectedFile || isLoading}
            onClick={handleSubmitForm}
          />
        </MUIDialogActions>
      </MUIDialog>
    </div>
  );
};

export default React.memo(AddSsidModal);
