import {
  IEditableRowMapping,
  ISelectOption,
} from 'components/UI/EditableTable/models';
import {
  IGenericColumnHeader,
  IGenericFullData,
} from 'components/UI/GenericTable/models';
import { Dispatch, SetStateAction } from 'react';
import { TFunction } from 'react-i18next';

export const headers = (
  t: TFunction<'translation', undefined>,
  toggleRows: (checked: boolean) => void
): IGenericColumnHeader[] => {
  return [
    {
      id: 'select',
      label: 'checkbox',
      isSelectAllCheckbox: true,
      onCheckboxChange: (e) => {
        toggleRows(e.currentTarget.checked);
      },
    },
    {
      id: 'url',
      label: t('MTD.POLICIES.PHISHING_POLICY.SITE'),
    },
    {
      id: 'originalCategories',
      label: t('MTD.POLICIES.PHISHING_POLICY.DEFAULT_CATEGORY'),
    },
    {
      id: 'mappedCategory',
      label: t('MTD.POLICIES.PHISHING_POLICY.CUSTOM_CATEGORY'),
    },
  ];
};

export const rowMappings = (
  selectOpt: ISelectOption[],
  setSelectedRows: Dispatch<SetStateAction<string[]>>,
  selectedRows: string[],
  toggleCheckTableRow: (id: string) => void,
  handleEditMappedCategory: (
    id: string | undefined,
    newMappedCategory: string | number | null
  ) => void
): IEditableRowMapping[] => {
  return [
    {
      path: 'select',
      alwaysEnabled: true,
      inputType: 'checkbox',
      onChange: (
        e: React.ChangeEvent<HTMLInputElement>,
        checked: boolean,
        fullData: IGenericFullData | undefined
      ) => {
        if (!fullData?.rowData?.id) {
          return;
        }
        toggleCheckTableRow(fullData.rowData.id);
        if (checked) {
          setSelectedRows([...selectedRows, fullData.rowData.id]);
        } else {
          setSelectedRows(
            selectedRows.filter((id) => id !== fullData.rowData?.id)
          );
        }
      },
    },
    {
      path: 'url',
      inputType: 'text',
    },
    {
      path: 'originalCategories',
      inputType: 'text',
      columnContent: (fullData) => (
        <>{fullData?.rowData?.originalCategories || ''}</>
      ),
    },
    {
      path: 'mappedCategory',
      alwaysDisplayInput: true,
      inputType: 'select',
      options: selectOpt,
      searchable: true,
      alwaysEnabled: true,
      onChange: (value: string | number | null, id: string | undefined) => {
        handleEditMappedCategory(id, value);
      },
    },
  ];
};


