import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

// ui
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Checkbox, TextField } from 'components/inputs/index';
import Tooltip from '@material-ui/core/Tooltip';
import Info from '@material-ui/icons/Info';
import Grid from '@material-ui/core/Grid';
import { Radio } from 'components/UI/input/RadioGroup';
import RadioGroup from '@material-ui/core/RadioGroup';

// components
import ProtectedComponent from 'components/main/protected/ProtectedComponent';

import ZButton from 'UI/Buttons/ZButton';

// services
import {
  getSSOConfiguration,
  postSSOConfiguration,
  getSSOHostname,
} from 'api/SSOService';

// utils
import { fetchAndSetCurrentUser } from 'actions/UserActions';
import { openSnackBar } from '../../../utils/storeUtils';

const initialState = {
  entityId: '',
  metadata: '',
  type: 'NONE',
  hostname: '',
  disableLocalLogins: false,
};

class SSOConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.getSSOConfig();
  }

  render() {
    const { props, state } = this;

    return (
      <div>
        <div className="view__header">
          <h1>SSO Configuration</h1>
        </div>
        <Card>
          <CardContent>
            {!state.hostname ? (
              <p>
                Your account is not setup for configuring SSO. Contact your
                support team to enable this feature.
              </p>
            ) : (
              <Grid container>
                <Grid item md={6}>
                  <form onSubmit={this.handleSubmit}>
                    <TextField
                      id="entityId"
                      label="Entity ID"
                      margin="none"
                      value={state.entityId}
                      onChange={this.handleChange('entityId')}
                    />

                    <TextField
                      id="metadata"
                      label="Metadata"
                      multiline
                      rows="6"
                      margin="none"
                      value={state.metadata}
                      onChange={this.handleChange('metadata')}
                      inputProps={{
                        spellCheck: false,
                      }}
                    />
                    <div className="flex--spaced">
                      <div>
                        <div className={props.classes.radioLabel}>SSO Type</div>

                        <RadioGroup
                          aria-label="type"
                          className={props.classes.radioContainer}
                          name="type"
                          value={state.type || ''}
                          onChange={this.handleChange('type')}
                        >
                          <Radio
                            value="NONE"
                            label="None"
                          />
                          <Radio
                            value="SAML"
                            label="SAML"
                          />
                        </RadioGroup>
                      </div>
                      <div style={{ alignSelf: 'end' }}>
                        <Checkbox
                          name="disableLocalLogins"
                          label={
                            <div className="flex--start">
                              <span style={{ fontSize: 14 }}>
                                Disable Local Logins
                              </span>
                              <Tooltip
                                enterDelay={300}
                                placement="right"
                                title={
                                  <span
                                    style={{
                                      fontSize: 12,
                                      display: 'flex',
                                      flexDirection: 'column',
                                      textIndent: 5,
                                    }}
                                  >
                                    <span> Enabling this feature:</span>{' '}
                                    <span>
                                      - Prevents users from authenticating with
                                      a
                                    </span>
                                    <span style={{ paddingLeft: 5 }}>
                                      username and password in a local login.
                                    </span>{' '}
                                    <span>
                                      {' '}
                                      - Prevents users from resetting their
                                      password.
                                    </span>
                                    <span>
                                      - Prevents invited users from establishing
                                    </span>
                                    <span style={{ paddingLeft: 5 }}>
                                      an initial password.
                                    </span>
                                  </span>
                                }
                              >
                                <Info style={{ paddingLeft: 5 }} />
                              </Tooltip>
                            </div>
                          }
                          checked={state.disableLocalLogins}
                          onChange={this.handleCheckbox}
                        />
                      </div>
                    </div>

                    <div style={{ marginTop: 15 }}>
                      <ProtectedComponent allow={{ account: 'manage' }}>
                        <ZButton
                          buttonType="submit"
                          styleName="titleBar"
                          buttonText="Save Configuration"
                          color="primary"
                        />
                      </ProtectedComponent>
                    </div>
                  </form>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </div>
    );
  }

  getSSOConfig() {
    getSSOHostname()
      .then((resp) => {
        const hostname = _.get(resp, 'data.hostname', '');

        if (!hostname) {
          throw new Error('No hostname for account');
        }

        this.setState({ hostname });
        return getSSOConfiguration();
      })
      .then((resp) => {
        this.setState({
          ...resp.data.saml,
          type: resp.data.type,
          disableLocalLogins: resp.data.disableLocalLogins,
        });
      })
      .catch((error) => console.error(error));
  }

  handleChange(name) {
    return (event) => {
      console.log(`event.target`, event.target);
      this.setState({ [name]: event.target.value });
    };
  }

  handleCheckbox = () => {
    this.setState({ disableLocalLogins: !this.state.disableLocalLogins });
  };

  handleSubmit(event) {
    const { state } = this;

    event.preventDefault();

    const isTypeNoneAndSettingsEmpty =
      state.type === 'NONE' &&
      _.isEmpty(state.entityId) &&
      _.isEmpty(state.metadata);

    const payload = {
      saml: isTypeNoneAndSettingsEmpty
        ? undefined
        : _.pick(state, ['entityId', 'metadata']),
      type: state.type,
      disableLocalLogins: Boolean(state.disableLocalLogins),
    };
    postSSOConfiguration({}, payload)
      .then(() => {
        this.getSSOConfig();
      })
      .then(() => {
        openSnackBar('SSO Configuration was saved');
        // check me
        this.props.fetchAndSetCurrentUser();
      })
      .catch((error) => {
        const errorMessage = _.get(
          error,
          'response.data.errors[0].defaultMessage',
          'invalid'
        );
        const finalErrorMessage = `Entity ID or Metadata ${errorMessage}`;
        openSnackBar(`Failed to save SSO Configuration: ${finalErrorMessage}`);
      });
  }
}

const styles = ({ config }) => {
  return {
    radioLabel: {
      fontSize: config.textSizes.petite,
      marginTop: 15,
    },
    radioContainer: {
      flexDirection: 'row',
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchAndSetCurrentUser,
    },
    dispatch
  );
};

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps)
)(SSOConfiguration);
