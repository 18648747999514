import { selectedTeam as selectedTeamAtom } from 'atoms/teams';
import Tabs from 'components/UI/Tabs';
import ViewSelector from 'components/UI/ViewSelector';
import { ISelectItem } from 'components/UI/input/Select';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import useAsyncResult from 'utils/useAsyncResult';
import OperationalStatus from './OperationalStatus';
import ThreatStatus from './ThreatStatus';
import useStyles from './useStyles';
import { fetchAppsList } from './utils';

const Insights: React.FC = () => {
  const classes = useStyles();
  const selectedTeam = useRecoilValue(selectedTeamAtom);

  const handleViewSelectorChange = useCallback(
    (name: string, value: ISelectItem | undefined) => {
      setSelectedApp(value);
    },
    []
  );

  const appsList = useAsyncResult<ISelectItem[]>(
    fetchAppsList,
    selectedTeam === null ? '' : selectedTeam
  );

  const [selectedApp, setSelectedApp] = useState<
    ISelectItem | null | undefined
  >(appsList?.[0]);

  useEffect(() => {
    if (!selectedApp && appsList?.[0]) {
      setSelectedApp(appsList?.[0]);
    }
  }, [appsList, selectedApp]);

  if (!appsList || (appsList.length > 0 && !selectedApp)) {
    return null;
  }

  return (
    <div className={classes.zDefendInsights}>
      <ViewSelector
        defaultValue={selectedApp}
        interactable
        label="Select App to View"
        options={appsList}
        placeholder="No app selected"
        setFieldValue={handleViewSelectorChange}
      />
      <Tabs
        tabs={[
          {
            title: 'Threat Status',
            content: <ThreatStatus zappId={selectedApp?.value} />,
          },
          {
            title: 'Operational Status',
            content: <OperationalStatus zappId={selectedApp?.value} />,
          },
        ]}
      />
    </div>
  );
};

export default Insights;
