import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Tooltip from '@material-ui/core/Tooltip';
import Info from '@material-ui/icons/Info';
import cc from 'classcat';
import withRouter from 'components/hocs/withRouter';
import { FormikCheckbox } from 'components/inputs/FormikCheckbox';
import { Field, Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { hasChangesChkboxes as hasChangesAtom } from './atoms';
import { IAppPhishingPolicyData } from './models';
import PhishingFormsChangesCheck from './PhishingFormsChangesCheck';
import useStyles from './useStyles';

const initialState = {
  useUrlSharing: false,
  useLocalVpn: false,
  allowEndUserControl: false,
  phishingDetectionAction: false,
  useRemoteContentInspection: false,
  enableMessageFilterTutorial: false,
  enableSafariBrowserExtensionTutorial: false,
  enableDnsPhishingTutorial: false,
};

interface IPhishingPolicyFormProps {
  phishingPolicyData: IAppPhishingPolicyData;
  isEnhanced: boolean;
  phishingProtecType: string;
  formRefChk: HTMLFormElement;
}

const PhishingPolicyForm: React.FC<IPhishingPolicyFormProps> = (props) => {
  const setFormHasChanges = useSetRecoilState(hasChangesAtom);
  const classes = useStyles();
  const { t } = useTranslation();

  const checkForChanges = useCallback(
    (dirty: boolean) => {
      setFormHasChanges(dirty);
    },
    [setFormHasChanges]
  );

  return (
    <Card>
      <CardContent className={cc([classes.inputGroupBackground])}>
        <Formik
          initialValues={
            props.phishingPolicyData
              ? { ...props.phishingPolicyData }
              : { ...initialState }
          }
          onSubmit={() => {}}
          enableReinitialize
        >
          {({ values, resetForm, dirty }) => {
            if (
              !!props.formRefChk?.current &&
              !props.formRefChk.current.resetForm
            ) {
              props.formRefChk.current.resetForm = resetForm;
            }
            return (
              <Form id="phishingPolicyForm" className={classes.inputGroup}>
                <PhishingFormsChangesCheck
                  checkForChangesChkboxes={checkForChanges}
                  dirtyChkboxes={dirty}
                  formValues={values}
                />
                <div className={classes.inputGroupMargin}>
                  {props.isEnhanced && (
                    <div className={classes.inputGroupMarginTop}>
                      <div className="flex--start">
                        <span>
                          {t('MTD.POLICIES.PHISHING_POLICY.URL_INSPECTION')}
                        </span>
                        <Tooltip
                          enterDelay={300}
                          placement="right"
                          title={
                            <span className={classes.inputGroupText}>
                              {t(
                                'MTD.POLICIES.PHISHING_POLICY.URL_INSPECTION_INFO'
                              )}
                            </span>
                          }
                          className={classes.inputGroupTooltip}
                        >
                          <Info />
                        </Tooltip>
                      </div>
                      <Field
                        checked={values.useRemoteContentInspection}
                        className={classes.inputGroupField}
                        component={FormikCheckbox}
                        disabled={false}
                        label={t(
                          'MTD.POLICIES.PHISHING_POLICY.ALLOW_URL_INSPECTION'
                        )}
                        name="useRemoteContentInspection"
                        type="checkbox"
                      />
                    </div>
                  )}
                  <div className={classes.inputGroupMarginTop}>
                    <div className="flex--start">
                      <span>
                        {t('MTD.POLICIES.PHISHING_POLICY.ALLOW_URL_SHARING')}
                      </span>
                      <Tooltip
                        enterDelay={300}
                        placement="right"
                        title={
                          <span className={classes.inputGroupText}>
                            {t(
                              'MTD.POLICIES.PHISHING_POLICY.ALLOW_URL_SHARING_INFO'
                            )}
                          </span>
                        }
                        className={classes.inputGroupTooltip}
                      >
                        <Info />
                      </Tooltip>
                    </div>
                    <Field
                      checked={values.useUrlSharing}
                      className={classes.inputGroupField}
                      component={FormikCheckbox}
                      disabled={false}
                      label={t(
                        'MTD.POLICIES.PHISHING_POLICY.ENABLE_URL_SHARING'
                      )}
                      name="useUrlSharing"
                      type="checkbox"
                    />
                  </div>
                  <div className={classes.inputGroupMarginTop}>
                    <div className="flex--start">
                      <span>
                        {t('MTD.POLICIES.PHISHING_POLICY.USE_MESSAGE_FILTER')}
                      </span>
                      <Tooltip
                        enterDelay={300}
                        placement="right"
                        title={
                          <span className={classes.inputGroupText}>
                            {t(
                              'MTD.POLICIES.PHISHING_POLICY.USE_MESSAGE_FILTER_INFO'
                            )}
                          </span>
                        }
                        className={classes.inputGroupTooltip}
                      >
                        <Info />
                      </Tooltip>
                    </div>
                    <Field
                      checked={values.enableMessageFilterTutorial}
                      className={classes.inputGroupField}
                      component={FormikCheckbox}
                      disabled={false}
                      label={t(
                        'MTD.POLICIES.PHISHING_POLICY.ENABLE_MESSAGE_FILTER_TUTORIAL'
                      )}
                      name="enableMessageFilterTutorial"
                      type="checkbox"
                    />
                  </div>
                  <div style={{ marginLeft: 24, marginTop: 15 }}>
                    <div className="flex--start">
                      <span>
                        {t('MTD.POLICIES.PHISHING_POLICY.USE_SAFARI_EXTENSION')}
                      </span>
                      <Tooltip
                        enterDelay={300}
                        placement="right"
                        title={
                          <span className={classes.inputGroupText}>
                            {t(
                              'MTD.POLICIES.PHISHING_POLICY.USE_SAFARI_EXTENSION_INFO'
                            )}
                          </span>
                        }
                        className={classes.inputGroupTooltip}
                      >
                        <Info />
                      </Tooltip>
                    </div>
                    <Field
                      checked={values.enableSafariBrowserExtensionTutorial}
                      className={classes.inputGroupField}
                      component={FormikCheckbox}
                      disabled={false}
                      label={t(
                        'MTD.POLICIES.PHISHING_POLICY.ENABLE_SAFARI_EXTENSION_TUTORIAL'
                      )}
                      name="enableSafariBrowserExtensionTutorial"
                      type="checkbox"
                    />
                  </div>
                </div>
                <div style={{ marginLeft: 24, marginTop: 15 }}>
                  <div className="flex--start">
                    <span>
                      {t('MTD.POLICIES.PHISHING_POLICY.USE_LOCAL_VPN')}
                    </span>
                    <Tooltip
                      enterDelay={300}
                      placement="right"
                      title={
                        <span className={classes.inputGroupText}>
                          {t('MTD.POLICIES.PHISHING_POLICY.USE_LOCAL_VPN_INFO')}
                        </span>
                      }
                      className={classes.inputGroupTooltip}
                    >
                      <Info />
                    </Tooltip>
                  </div>
                  <Field
                    checked={values.useLocalVpn}
                    className={classes.inputGroupField}
                    component={FormikCheckbox}
                    disabled={false}
                    label={t(
                      'MTD.POLICIES.PHISHING_POLICY.CREATE_VPN_ACTIVATION'
                    )}
                    name="useLocalVpn"
                    type="checkbox"
                  />
                  <Field
                    checked={values.allowEndUserControl}
                    className={classes.inputGroupField}
                    component={FormikCheckbox}
                    disabled={false}
                    label={t(
                      'MTD.POLICIES.PHISHING_POLICY.ALLOW_END_USER_VPN_CONTROL'
                    )}
                    name="allowEndUserControl"
                    type="checkbox"
                  />
                  {props.isEnhanced && (
                    <Field
                      checked={values.phishingDetectionAction}
                      className={classes.inputGroupField}
                      component={FormikCheckbox}
                      disabled={
                        !values.useLocalVpn && !values.allowEndUserControl
                      }
                      label={t(
                        'MTD.POLICIES.PHISHING_POLICY.BLOCK_PHISHING_URLS'
                      )}
                      name="phishingDetectionAction"
                      type="checkbox"
                    />
                  )}

                  {props?.phishingPolicyData?.isDnsEnabled &&
                  props.phishingProtecType === 'REMOTE_AND_CONTENT_FILTER' ? (
                    <div style={{ marginTop: 15 }}>
                      <div className="flex--start">
                        <span>
                          {t(
                            'MTD.POLICIES.PHISHING_POLICY.USE_DNS_PROTECTION_SERVICE'
                          )}
                        </span>
                        <Tooltip
                          enterDelay={300}
                          placement="right"
                          title={
                            <span className={classes.inputGroupText}>
                              {t('MTD.POLICIES.PHISHING_POLICY.USE_DNS_INFO')}
                            </span>
                          }
                          className={classes.inputGroupTooltip}
                        >
                          <Info />
                        </Tooltip>
                      </div>
                      <Field
                        checked={values.enableDnsPhishingTutorial}
                        className={classes.inputGroupField}
                        component={FormikCheckbox}
                        disabled={false}
                        label={t(
                          'MTD.POLICIES.PHISHING_POLICY.REQUEST_FOR_DNS_CONFIGURATION'
                        )}
                        name="enableDnsPhishingTutorial"
                        type="checkbox"
                      />
                    </div>
                  ) : null}
                </div>
              </Form>
            );
          }}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default withRouter(PhishingPolicyForm);
