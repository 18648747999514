import React, { PureComponent } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';

import { ADMIN_ROUTE_MAPPING } from 'mappings/routes/routeMappings';

// material ui
import Snackbar from '@material-ui/core/Snackbar';

// components
import SideBar from 'components/admin/common/SideBar';
import GlobalHeader from 'components/admin/common/GlobalHeader';
import AdminProtectedRoute from 'routes/AdminProtectedRoute';

// selectors
import { getUserValid } from 'reducers/UserDetailsReducer';
import { getSnackbar, closeSnackbar } from 'reducers/SnackbarReducers';
import {
  getAvailableTeamsAsList,
  updateAvailableTeams,
} from 'reducers/TeamReducers';
import { getSelectedTeam } from 'reducers/UiSettingsReducers';

// actions
import withRouter from '../hocs/withRouter';
import { onAdminExitTenant } from 'reducers/AuthReducers';

class AdminDashboardRoutesWrapper extends PureComponent {
  componentDidMount() { }

  componentDidUpdate(prevProps) {
    const { props } = this;

    if (!!prevProps.authUser.accessToken && !!props.authUser.accessToken) {
      props.onAdminExitTenant();
    }

    if (!prevProps.authUser.accessToken && !!props.authUser.accessToken) {
      props.history.push('/console');
    }
  }

  render() {
    const { props } = this;
    const mainRootClass = classNames('main-root-wrapper', {
      takewarning: !props.userValid,
    });

    return (
      <div className={mainRootClass}>
        <GlobalHeader className="main-header-wrapper" />
        <div className="main-root-container">
          <SideBar className="main-sidebar-wrapper" />
          <div className="main-content">
            <div className="main-content__container">
              <Switch>
                {ADMIN_ROUTE_MAPPING.map((route, i) => {
                  return (
                    <AdminProtectedRoute key={`public-route-${i}`} {...route} />
                  );
                })}
                <Redirect to="/admin/accounts" />
              </Switch>
            </div>
            <Snackbar {...props.snackbar} onClose={props.closeSnackbar} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authUser: state.auth.user,
    userValid: getUserValid(state),
    snackbar: getSnackbar(state),
    availableTeams: getAvailableTeamsAsList(state),
    selectedTeam: getSelectedTeam(state),
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      closeSnackbar,
      updateAvailableTeams,
      onAdminExitTenant,
    },
    dispatch,
  );
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AdminDashboardRoutesWrapper),
);
