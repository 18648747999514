import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Papa from 'papaparse';

// ui
import DialogContent from '@material-ui/core/DialogContent';
import UploaderDropZone from 'components/fileuploader/UploaderDropZone';
import Uploader from 'components/UI/Uploader';

// utils
import { openSnackBar, toggleModalDiffered } from 'utils/storeUtils';
import BulkTableConfirm from 'components/main/activations/BulkTableConfirm';
import { reduceForMultiSelectWithGlobal } from 'utils/componentUtils';
import { fetchGroupsByTeamId } from 'api/GroupService';
import { useTranslation } from 'react-i18next';

const Activations = (props) => {
  // set data into form array
  const [csvData, setCsvData] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [mtdGroups, setMtdGroups] = useState([]);
  const { t, ready } = useTranslation();

  function populateMtdGroups() {
    fetchGroupsByTeamId()
      .then(({ data }) => {
        const multiSelectFormat = reduceForMultiSelectWithGlobal(data);
        setMtdGroups(multiSelectFormat);
      })
      .catch((error) => {
        console.error('Error in fetching groups:', error);
      });
  }

  useEffect(() => {
    populateMtdGroups();
    return () => {
      //  cleanup
    };
  }, []);

  function handleParseResult(result) {
    setSpinner(false);
    // if csv file brings in the tableheader and no entries
    if (result.data && result.data.length < 2) {
      return openSnackBar('Error: Empty CSV file.');
    }
    setCsvData(result.data);
  }

  function prepFileContent() {
    const headerString = 'Email, First Name, Last Name, Phone Number';
    const tableDataString =
      'user1@example.com,John1,Doe1,\n' +
      'user2@example.com,John2,Doe2,\n' +
      'user3@example.com,John3,Doe3,\n' +
      'user4@example.com,John4,Doe4,\n' +
      'user5@example.com,John5,Doe5,';

    return [headerString, '\n', tableDataString];
  }

  function downloadSampleCSV() {
    const fileContentArray = prepFileContent(props);
    const csvFile = new Blob(fileContentArray, { type: 'text/csv' });

    // Download link
    const downloadLink = document.createElement('a');
    // Make sure that the link is not displayed
    downloadLink.style.display = 'none';
    // File name
    downloadLink.download = 'bulk-sample.csv';
    // We have to create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile);
    // Add the link to your DOM
    document.body.appendChild(downloadLink);

    downloadLink.click();
  }

  function getFileData(data) {
    setSpinner(true);
    return Papa.parse(data, {
      skipEmptyLines: 'greedy',
      complete: handleParseResult,
    });
  }

  if (!ready) {
    return null;
  }

  return (
    <DialogContent style={{ overflowY: 'auto' }}>
      {!csvData && !spinner ? (
        <>
          <div style={{ minHeight: 20, width: '100%', paddingBottom: 20 }}>
            <span
              className={props.classes.downloadSample}
              onClick={downloadSampleCSV}
            >
              {t('MTD.ACTIVATIONS.DOWNLOAD_SAMPLE_CSV_FILE')}
              <br />
            </span>
            {t('MTD.ACTIVATIONS.DOWNLOAD_DESCRIPTION_IN_ACTIVATION')}
          </div>
          <UploaderDropZone
            isShowing
            handleCloseModal={toggleModalDiffered('ActivationsBulk', false)}
          >
            <Uploader getFileData={getFileData} accept=".csv" isShowing />
          </UploaderDropZone>
        </>
      ) : (
        <BulkTableConfirm
          csvData={csvData}
          spinner={spinner}
          mtdGroups={mtdGroups}
        />
      )}
    </DialogContent>
  );
};

const styles = ({ palette }) => {
  return {
    downloadSample: {
      color: palette.primary.main,
      cursor: 'pointer',
    },
    errorText: {
      display: 'flex',
      alignItems: 'center',
      color: palette.text.secondary,
    },

    errorOutline: {
      marginRight: 5,
      verticalAlign: 'bottom',
      color: palette.error.main,
    },
  };
};

export default withStyles(styles)(Activations);
