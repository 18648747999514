import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

export default (state) => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      mtdOSVersions: {
        ...uiSettingsDefaults.mtdOSVersions,
        tableHeaders: uiSettingsDefaults.mtdOSVersions.tableHeaders,
      },
      mtdOSRelatedCVEs: {
        ...uiSettingsDefaults.mtdOSRelatedCVEs,
        tableHeaders: uiSettingsDefaults.mtdOSRelatedCVEs.tableHeaders,
      },
    },
  };
};
