import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    authMethod: {
      alignItems: 'baseline',
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '10px',
      marginBottom: '6px',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: '25px',
      marginTop: '25px',
      width: '100%',
      gap: '20px',
      '& > button': { boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.38)' },
    },
    buttonBackgroundColor: {
      backgroundColor: palette.charts.icon,
      color: palette.common.white,
      '&:hover': {
        backgroundColor: palette.charts.icon,
      },
    },
    UploadWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
    },
    tooltip: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-start',
      position: 'relative',
      zIndex: 999,
    },
    tooltipText: {
      backgroundColor: palette.common.darkGreySoft,
      borderRadius: '3px',
      color: palette.common.white,
      left: '40px',
      overflow: 'hidden',
      padding: '6px 8px 6px 8px',
      position: 'absolute',
      width: '250px',
      fontSize: '12px',
    },
    uploaderDropZone: {
      width: '100%',
      WebkitTransition:
        'outline-offset .15s ease-in-out, background-color .15s linear',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: 250,
      outline: `2px dashed ${palette.primary.main}`,
      outlineOffset: '-10px',
      paddingTop: 10,
      transition:
        'outline-offset .15s ease-in-out, background-color .15s linear',
    },
    UploadContent: {
      display: 'flex',
      gap: '20px',
    },
    fileName: {
      display: 'flex',
      marginTop: '54px',
      width: 'auto',
    },
    disabled: {
      cursor: 'auto',
      opacity: '30%',
    },

    fileInfoDeleteButton: {
      alignSelf: 'baseline',
      background: 'none',
      border: 'none',
      color: 'inherit',
      cursor: 'pointer',
      font: 'inherit',
      margin: '0px',
      outline: 'inherit',
      padding: '0px',
    },
    fileNameTypography: {
      marginTop: '3px',
    },
  })
);

export default useStyles;
