import { Api } from 'config/axiosConfig';
import _ from 'lodash';
import { fetchUsersParamsMapping } from 'mappings/services/userServiceMapping';
import { ServiceHandlerFactory } from 'utils/serviceUtils';

export const fetchUsersTablePage = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/users',
    method: 'get',
  },
  paramSchema: fetchUsersParamsMapping,
});

/* users table */
export function fetchAllUsersTable() {
  return Api.get('/api/auth/v1/users');
}
export function fetchUserById(userId) {
  return Api.get(`/api/auth/v1/users/${userId}`);
}
/* create */
export const submitUserInvitation = user => {
  return Api.post('/api/auth/v1/users', user);
};
/* edit */
export const editSelectedUser = (userId, user) => {
  return Api.put(`/api/auth/v1/users/${userId}`, user);
};

/* delete */
export function deleteSelectedUser(userId) {
  return Api.delete(`/api/auth/v1/users/${userId}`);
}

export function resendUserInvitationEmail(userEmail) {
  return Api.post('api/auth/v1/resendUserInvitationEmail', userEmail);
}

export const serializeUsersTableResponse = usersRawData => {
  const responseObject = {};
  responseObject.users = usersRawData.content.map(user => {
    const teamNames = user.teams
      .map(n => {
        return n.name;
      })
      .join(', ');
    let role = {};
    if (!_.isEmpty(user.role)) {
      role = {
        name: _.get(user, 'role.name', null),
        value: _.get(user, 'role.id', null),
        accountBounded:
          _.get(user, 'role.scopeBounds', null) === 'ACCOUNT_BOUNDED',
      };
    }
    return {
      ...user,
      roleName: user.role ? user.role.name : '',
      role,
      teamName: teamNames,
      lastLogin: user.lastLogin || ' ',
    };
  });
  responseObject.usersTableMetadata = {};
  responseObject.usersTableMetadata.count = usersRawData.totalElements;
  return responseObject;
};

export const serializeSingleUserTableResponse = user => {
  const teamNames = user.teams
    .map(n => {
      return n.name;
    })
    .join(', ');

  const roleCopy = { ...user.role };

  return {
    ...user,
    roleName: roleCopy.name,
    role: roleCopy,
    teamName: teamNames,
    lastLogin: user.lastLogin ? user.lastLogin : ' ',
  };
};
