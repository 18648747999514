import memoizeTableQuery from 'components/UI/Table/utils/memoizeTableQuery';
import { fetchTableData } from './utils';

const memoizeQueryWithAccountId = (
  accountId: string,
  teamId?: string | null
) => {
  return memoizeTableQuery(async () => {
    // Fetch Data
    const data = await fetchTableData(accountId, teamId);
    return {
      data: data.tableData,
      count: data.tableData.length,
    };
  });
};
export default memoizeQueryWithAccountId;
