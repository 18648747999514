import _ from 'lodash';
import { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Moment from 'moment';

// material ui
import AppBar from '@material-ui/core/AppBar';
import MaterialTabs from '@material-ui/core/Tabs';

// components
import ProtectedRoute from 'routes/ProtectedRoute';
import { zipReference } from 'utils/componentUtils';
import { IntegrationsContext } from './store-integration-context';

import { PubSub } from '../../../utils/eventUtils';
import { SET_INTEGRATIONS } from './TicketingConfigurations/configuration.events.ts';
import { getIntegrations } from './TicketingIntegrations/integrations.service';
import IntegrationsListing from './TicketingIntegrations/IntegrationsListing';
import ConfigurationsListing from './TicketingConfigurations/ConfigurationsListing';
// import { ManageConfigurations } from './TicketStatusManagement/ManageConfigurations';
import IntegrationLinkTab from './IntegrationLinkTab';
import { useTranslation } from 'react-i18next';

const Integrations = withStyles({
  tabsAppBar: {
    marginTop: 15,
    marginBottom: 15,
    background: 'none',
    boxShadow: 'none',
    position: 'relative',
    zIndex: 1,
  },
})(({ match, ...props }) => {
  const { setIntegrations, ...references } = useGetReferences(props);
  const { configurations } = useSubscriptions(setIntegrations);
  const { t, ready } = useTranslation();
  let tabValue = match.params.tabId;

  if (
    match.path ===
    '/console/zscan/integrations/:integrationId/configurations/:configurationId'
  ) {
    tabValue = 'ticketing-configurations';
  }

  if (!ready) {
    return null;
  }

  return (
    <div>
      <div>
        <h1>{t('GLOBAL.INTEGRATIONS')}</h1>
      </div>
      <AppBar position="static" className={props.classes.tabsAppBar}>
        <MaterialTabs value={tabValue}>
          <IntegrationLinkTab
            disableRipple
            label={t('ZSCAN.INTEGRATIONS.TICKETING_INTEGRATIONS')}
            to="/console/zscan/integrations/ticketing-integrations"
            value="ticketing-integrations"
            allow={{ zdev_apps: 'view' }}
          />
          <IntegrationLinkTab
            disableRipple
            label={t('ZSCAN.INTEGRATIONS.TICKETING_CONFIGURATIONS')}
            to="/console/zscan/integrations/ticketing-configurations"
            value="ticketing-configurations"
            allow={{ zdev_apps: 'view' }}
          />
        </MaterialTabs>
      </AppBar>
      <IntegrationsContext.Provider
        value={{
          ...references,
          configurations,
        }}
      >
        {renderSubRoute(match.params)}
      </IntegrationsContext.Provider>
    </div>
  );
});

function renderSubRoute({ tabId, integrationId, configurationId }) {
  // // view related to managing jira statuses may be used later!
  // if (integrationId && configurationId) {
  //   return (
  //     <ProtectedRoute
  //       allow={{ zdev_apps: 'view' }}
  //       component={ManageConfigurations}
  //     />
  //   );
  // }

  switch (tabId) {
    case 'ticketing-configurations':
      return (
        <ProtectedRoute
          allow={{ zdev_apps: 'view' }}
          component={ConfigurationsListing}
        />
      );
    default:
      return (
        <ProtectedRoute
          allow={{ zdev_apps: 'view' }}
          component={IntegrationsListing}
        />
      );
  }
}

function useSubscriptions(setIntegrations) {
  const [configurations, setConfigurations] = useState([]);

  useEffect(() => {
    const onSetInt = PubSub.subscribe(SET_INTEGRATIONS, setIntegrations);

    return () => {
      onSetInt.remove();
    };
  }, [setIntegrations, setConfigurations]);

  return { configurations };
}

function useGetReferences(props) {
  const [cached, setCached] = useState(Moment());
  const [integrations, setIntegrations] = useState({});

  useEffect(() => {
    if (Moment().isAfter(cached)) {
      const promises = [];

      if (_.isEmpty(integrations)) {
        promises.push(getIntegrations());
      }

      Promise.all(promises)
        .then(([intResp]) => {
          if (intResp && intResp.data) {
            setIntegrations(zipReference(intResp.data.content));
          }
        })
        .catch(console.error);

      setCached(Moment().add(1, 'minute'));
    }
  }, [cached, integrations, props.location.pathname]);

  return {
    integrations,
    setIntegrations,
  };
}

export default Integrations;
