import {
  csvInjectionErrorMessageLocalized,
  csvInjectionRegex,
} from 'utils/componentUtils';
import * as Yup from 'yup';
import { TFunction } from 'react-i18next';

const Schema = (t: TFunction<'translation', undefined>) => {
  const basicFields = {
    activationLimit: Yup.number().min(
      0,
      t('MTD.VALIDATIONS.MIN_ACTIVATION_LIMIT_ERROR')
    ),
    description: Yup.string().matches(
      csvInjectionRegex,
      t(csvInjectionErrorMessageLocalized)
    ),
    group: Yup.mixed().required(t('MTD.VALIDATIONS.POLICY_GROUP_IS_REQUIRED')),
    name: Yup.string()
      .matches(csvInjectionRegex, t(csvInjectionErrorMessageLocalized))
      .required(t('MTD.VALIDATIONS.NAME_IS_REQUIRED')),
    team: Yup.mixed().test({
      name: 'team-required-if-all-teams',
      // function keyword used below because we need access to "this"
      test: function (value, ctx) {
        if (!!ctx?.parent?.selectedTeamId) {
          return true;
        }

        return !value
          ? this?.createError({
              message: t('MTD.VALIDATIONS.TEAM_IS_REQUIRED'),
              path: 'team',
            })
          : true;
      },
    }),
  };

  return Yup.object().shape(basicFields);
};

export default Schema;
