import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, typography }: any) =>
  createStyles({
    cardContentDetails: {
      background: palette.background.paper,
      display: 'flex',
      flexWrap: 'wrap',
      padding: 20,
      width: '100%',
      '&:last-child': {
        paddingBottom: 15,
      },
    },
    cardDetails: {
      background: palette.background.paper,
      margin: 0,
      outline: 'none',
      padding: 0,
    },
    critical: {
      background: palette.components.statusDot.critical,
    },
    csvDownloadButton: {
      padding: 0,
    },
    customDrawer: {
      width: '88% !important',
      height: '90% !important',
    },
    dataWrapper: {
      width: '50%',
    },
    deviceContent: {
      marginTop: 195,
    },
    drawer: {
      width: '100%',
    },
    elevated: {
      background: palette.components.statusDot.elevated,
    },
    expandIcon: {
      cursor: 'pointer',
    },
    label: {
      color: palette.text.secondary,
      fontWeight: 'bold',
      marginLeft: 5,
    },
    low: {
      background: palette.components.statusDot.low,
    },
    normal: {
      background: palette.components.statusDot.normal,
    },
    osCell: {
      alignItems: 'center',
      display: 'flex',
    },
    statusDot: {
      borderRadius: '50%',
      height: 12,
      marginRight: 6,
      width: 12,
    },
    toggle: {
      cursor: 'pointer',
      transform: 'rotate(180deg)',
    },
    warnIcon: {
      color: palette.warning.main,
      fontSize: typography.fontSize,
      verticalAlign: 'text-top',
    },
  })
);

export default useStyles;
