import { Api } from 'config/axiosConfig';
import { fetchSuperUserParamsMapping } from 'mappings/services/superUserServiceMapping';
import { ServiceHandlerFactory } from 'utils/serviceUtils';

export function fetchCurrentUser() {
  return Api.get('/api/auth/v1/me');
}

// performed this here w/o serviceHandlerFactory.  must be a race condition w/ the token/options?

export function fetchBranding() {
  return Api.get('/api/auth/v1/branding');
}
export function fetchCurrentUserTeams() {
  return Api.get('/api/auth/v1/teams').then(resp => {
    return {
      ...resp,
      data: {
        ...resp.data,
        teams: resp.data.content,
      },
    };
  });
}

export function RequestDeleteUser(userId) {
  return Api.delete(`/api/auth/v1/superusers/${userId}`);
}

// super user releated apis
export const fetchSuperUsers = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/superusers',
    method: 'get',
  },
  paramSchema: fetchSuperUserParamsMapping,
});

export function requestSuperUser(userId) {
  return Api.get(`/api/auth/v1/superusers/${userId}`);
}

export function createSuperUser(superUser) {
  return Api.post('/api/auth/v1/superusers', superUser);
}

export function updateSuperUser(userId, superUser) {
  return Api.put(`/api/auth/v1/superusers/${userId}`, superUser);
}

export function fetchSuperRoles() {
  return Api.get('/api/auth/v1/superroles');
}

export const checkUserInvitedValidationToken = token => {
  return Api.get(`/api/auth/v1/checkUserInvited/${token}`);
};

export const postValidationTokenPassword = (token, password) => {
  return Api.post(`/api/auth/v1/validateUserInvited/${token}`, password);
};