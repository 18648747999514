import React, { useState /*, useEffect*/ } from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Tabs, TabContent } from 'components/tabs';
import ExpansionRowDetails from 'components/Tables/ExpansionRowDetails';
import {
  cvss_2_0_metrics,
  cvss_3_1_metrics,
} from 'dashboard/zDev/cvssCalculator/cvssCalculator.mappings';
import ZButton from 'UI/Buttons/ZButton';
import CVSS31Function from 'utils/CVSS_3_1';
import CVSS2Function from 'utils/CVSS_2_0';
import {
  updateAppFindingCustomCvssData,
  updatePolicyCustomCvssData,
} from 'api/zDevService';
import {
  openSnackBar,
  toggleModalDiffered,
  toggleModalDirect,
} from 'utils/storeUtils';
import { publishEvent } from 'utils/eventUtils';
import { compose } from 'redux';
import { withRouter } from 'utility/routeUtils';

/* This feature is currently READ ONLY no changes can be made
 */

function ZDevCVSSCalculator(props) {
  const policyId = props?.location?.query?.policyId;
  const [selectedVersion, setSelectedVersion] = useState(props.data.version);
  // accordion
  const [expanded, setExpanded] = useState(null);
  const isCVSS2Active = !!props?.data?.cvss_2_0_score;
  const isCVSS31Active = !!props?.data?.cvss_3_1_score;
  const CVSS2 = CVSS2Function(isCVSS2Active);
  const CVSS31 = CVSS31Function(isCVSS31Active);

  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // setting score
  const [cvss_2_0_score, serCvss_2_0_score] = useState(
    _.get(props, 'data.cvss_2_0_score')
  );
  const [cvss_2_0_vector, serCvss_2_0_vector] = useState(
    _.get(props, 'data.cvss_2_0_vector')?.replace('(Modified)', '').trim()
  );
  const [cvss_3_1_score, serCvss_3_1_score] = useState(
    _.get(props, 'data.cvss_3_1_score')
  );
  const [cvss_3_1_vector, serCvss_3_1_vector] = useState(
    _.get(props, 'data.cvss_3_1_vector')?.replace('(Modified)', '').trim()
  );

  const [displayScore, setDisplayScore] = useState(
    selectedVersion === '2.0'
      ? cvss_2_0_score
      : selectedVersion === '3.1'
      ? cvss_3_1_score
      : ''
  );
  const [displayVector, setDisplayVector] = useState(
    selectedVersion === '2.0'
      ? cvss_2_0_vector
      : selectedVersion === '3.1'
      ? cvss_3_1_vector
      : ''
  );

  const cvssVectorToObject = (vector, version) => {
    const vectorObject = {};
    const keyValuePairs = _.split(vector, '/');
    for (let i = 0; i < keyValuePairs.length; i++) {
      const keyValuePair = _.split(keyValuePairs[i], ':');
      const key = keyValuePair[0];
      const value = keyValuePair[1];
      vectorObject[key] = value;
    }
    if (version === '3.1') {
      if (!_.hasIn(vectorObject, 'E')) {
        vectorObject.E = 'X';
      }
      if (!_.hasIn(vectorObject, 'RL')) {
        vectorObject.RL = 'X';
      }
      if (!_.hasIn(vectorObject, 'RC')) {
        vectorObject.RC = 'X';
      }
      if (!_.hasIn(vectorObject, 'MAV')) {
        vectorObject.MAV = 'X';
      }
      if (!_.hasIn(vectorObject, 'MAC')) {
        vectorObject.MAC = 'X';
      }
      if (!_.hasIn(vectorObject, 'MPR')) {
        vectorObject.MPR = 'X';
      }
      if (!_.hasIn(vectorObject, 'MUI')) {
        vectorObject.MUI = 'X';
      }
      if (!_.hasIn(vectorObject, 'MS')) {
        vectorObject.MS = 'X';
      }
      if (!_.hasIn(vectorObject, 'MC')) {
        vectorObject.MC = 'X';
      }
      if (!_.hasIn(vectorObject, 'MI')) {
        vectorObject.MI = 'X';
      }
      if (!_.hasIn(vectorObject, 'MA')) {
        vectorObject.MA = 'X';
      }
      if (!_.hasIn(vectorObject, 'CR')) {
        vectorObject.CR = 'X';
      }
      if (!_.hasIn(vectorObject, 'IR')) {
        vectorObject.IR = 'X';
      }
      if (!_.hasIn(vectorObject, 'AR')) {
        vectorObject.AR = 'X';
      }
    }

    if (version === '2.0') {
      if (!_.hasIn(vectorObject, 'E')) {
        vectorObject.E = 'ND';
      }
      if (!_.hasIn(vectorObject, 'RL')) {
        vectorObject.RL = 'ND';
      }
      if (!_.hasIn(vectorObject, 'RC')) {
        vectorObject.RC = 'ND';
      }
      if (!_.hasIn(vectorObject, 'CDP')) {
        vectorObject.CDP = 'ND';
      }
      if (!_.hasIn(vectorObject, 'TD')) {
        vectorObject.TD = 'ND';
      }
      if (!_.hasIn(vectorObject, 'CR')) {
        vectorObject.CR = 'ND';
      }
      if (!_.hasIn(vectorObject, 'IR')) {
        vectorObject.IR = 'ND';
      }
      if (!_.hasIn(vectorObject, 'AR')) {
        vectorObject.AR = 'ND';
      }
    }
    return vectorObject;
  };

  const objectToCvssVector = (version, vectorObject) => {
    const vectorString = [];
    _.forOwn(vectorObject, (value, key, object) => {
      if (
        (version === '2.0' && value !== 'ND') ||
        (version === '3.1' && value !== 'X')
      ) {
        vectorString.push(`${key}:${value}`);
      }
    });
    return vectorString.join('/');
  };

  const [cvss_2_0_vector_object, setCvss_2_0_vector_object] = useState(
    cvssVectorToObject(cvss_2_0_vector, '2.0')
  );
  const [cvss_3_1_vector_object, setCvss_3_1_vector_object] = useState(
    cvssVectorToObject(cvss_3_1_vector, '3.1')
  );

  const cvss_2_0_score_splitted =
    !_.isEmpty(CVSS2) &&
    CVSS2.computeScoresFromObject(CVSS2.vectorToObject(cvss_2_0_vector));
  const [cvss_2_0_base_score, setCvss_2_0_base_score] = useState(
    _.get(cvss_2_0_score_splitted, 'baseScore', 'N/A')
  );
  const [cvss_2_0_temporal_score, setCvss_2_0_temporal_score] = useState(
    _.get(cvss_2_0_score_splitted, 'temporalScore', 'N/A')
  );
  const [cvss_2_0_environmental_score, setCvss_2_0_environmental_score] =
    useState(_.get(cvss_2_0_score_splitted, 'environmentalScore', 'N/A'));

  const cvss_3_1_score_splitted = CVSS31.calculateCVSSFromVector(
    `CVSS:3.1/${cvss_3_1_vector}`
  );
  const [cvss_3_1_base_score, setCvss_3_1_base_score] = useState(
    _.get(cvss_3_1_score_splitted, 'baseMetricScore', 'N/A')
  );
  const [cvss_3_1_temporal_score, setCvss_3_1_temporal_score] = useState(
    _.get(cvss_3_1_score_splitted, 'temporalMetricScore', 'N/A')
  );
  const [cvss_3_1_environmental_score, setCvss_3_1_environmental_score] =
    useState(_.get(cvss_3_1_score_splitted, 'environmentalMetricScore', 'N/A'));

  const handleChange = (version) => {
    setSelectedVersion(version);
    if (version === '2.0') {
      setDisplayScore(cvss_2_0_score);
      setDisplayVector(cvss_2_0_vector);
    }
    if (version === '3.1') {
      setDisplayScore(cvss_3_1_score);
      setDisplayVector(cvss_3_1_vector);
    }
  };

  const handleScoreChange = (e, value) => {
    const name = _.get(e, 'currentTarget.name');
    const key = _.split(name, ':')[0];
    const version = _.split(name, ':')[1];

    if (version === '2.0') {
      const updatedVectorObject = {
        ...cvss_2_0_vector_object,
        [key]: value.value,
      };
      const vector = objectToCvssVector(version, updatedVectorObject);
      const score = CVSS2.computeScoresFromObject(CVSS2.vectorToObject(vector));

      setCvss_2_0_base_score(_.get(score, 'baseScore', -1));
      setCvss_2_0_temporal_score(_.get(score, 'temporalScore', -1));
      setCvss_2_0_environmental_score(_.get(score, 'environmentalScore', -1));

      serCvss_2_0_score(score.overallScore);
      serCvss_2_0_vector(vector);
      setCvss_2_0_vector_object(updatedVectorObject);

      setDisplayScore(score.overallScore);
      setDisplayVector(vector);
    }

    if (version === '3.1') {
      const updatedVectorObject = {
        ...cvss_3_1_vector_object,
        [key]: value.value,
      };
      const vector = objectToCvssVector(version, updatedVectorObject);
      const score = CVSS31.calculateCVSSFromVector(`CVSS:3.1/${vector}`);

      setCvss_3_1_base_score(_.get(score, 'baseMetricScore', -1));
      setCvss_3_1_temporal_score(_.get(score, 'temporalMetricScore', -1));
      setCvss_3_1_environmental_score(
        _.get(score, 'environmentalMetricScore', -1)
      );

      serCvss_3_1_score(score.overallScore);
      serCvss_3_1_vector(vector);
      setCvss_3_1_vector_object(updatedVectorObject);

      setDisplayScore(score.overallScore);
      setDisplayVector(vector);
    }
  };
  const handleCloseModal = () => {
    return toggleModalDirect('ZDevCVSSCalculator', false);
  };
  const onSubmit = () => {
    switch (props.data.source) {
      case 'policies':
        updatePolicyCustomCvssData(
          { policyId },
          {
            findingId: props.data.id,
            cvss_2_0_score: isCVSS2Active ? cvss_2_0_score : undefined,
            cvss_2_0_vector: isCVSS2Active ? cvss_2_0_vector : undefined,
            cvss_3_1_score: cvss_3_1_score.toString(),
            cvss_3_1_vector,
          }
        )
          .then(() => {
            openSnackBar(`Successfuly Saved`);
            toggleModalDirect('ZDevCVSSCalculator', false);
            publishEvent('table:force-fetch-zdevPolicies');
          })
          .catch((err) => {
            openSnackBar(err.message);
            toggleModalDirect('ZDevCVSSCalculator', false);
          });
        return;
      case 'findings':
        updateAppFindingCustomCvssData(
          { findingId: props.data.findingId },
          {
            appId: props.data.appId,
            cvss_2_0_score: isCVSS2Active ? cvss_2_0_score : undefined,
            cvss_2_0_vector: isCVSS2Active ? cvss_2_0_vector : undefined,
            cvss_3_1_score,
            cvss_3_1_vector,
          }
        )
          .then(() => {
            openSnackBar(`Successfuly Saved`);
            toggleModalDirect('ZDevCVSSCalculator', false);
            publishEvent('table:force-fetch-findings');
          })
          .catch((err) => {
            openSnackBar(err.message);
            toggleModalDirect('ZDevCVSSCalculator', false);
          });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className={props.classes.scoreHeader}>
        <h2>CVSS Calculator</h2>
        <h3>
          Overall Score: {displayScore}
          <br /> {displayVector}
        </h3>
      </div>
      <DialogContent className={props.classes.root}>
        <Tabs
          initialTab={_.get(props, 'data.version', '2.0')}
          syncUrl={false}
          onHandleChange={handleChange} // tells us what data to load
        >
          {!isCVSS2Active ? (
            <></>
          ) : (
            <TabContent label="2.0" id="2.0" disabled>
              <div className={props.classes.selectionContainer}>
                <Card>
                  <CardContent>
                    <div className="view__header">
                      <h2>Base Metrics</h2>
                      <h4>{cvss_2_0_base_score}</h4>
                    </div>
                    <ExpansionRowDetails
                      title="Exploitability Metrics"
                      config={cvss_2_0_metrics}
                      dataProperty="exploitability"
                      // here we can send the clean vector data for the dropdowns
                      data={{
                        ...cvss_2_0_vector_object,
                        expanded,
                        classList: { ...props.classes },
                        handleScoreChange,
                        handleAccordionChange,
                      }}
                      classList={{ ...props.classes }}
                    />

                    <ExpansionRowDetails
                      config={cvss_2_0_metrics}
                      title="Impact Metrics"
                      dataProperty="impact"
                      // here we can send the clean vector data for the dropdowns
                      data={{
                        ...cvss_2_0_vector_object,
                        expanded,
                        classList: { ...props.classes },
                        handleScoreChange,
                        handleAccordionChange,
                      }}
                      classList={{ ...props.classes }}
                    />
                  </CardContent>
                </Card>
                <Card>
                  <CardContent>
                    <div className="view__header">
                      <h2>Temporal Metrics</h2>
                      <h4>
                        {cvss_2_0_temporal_score === -1
                          ? '0.0'
                          : cvss_2_0_temporal_score}
                      </h4>
                    </div>
                    <ExpansionRowDetails
                      title=" "
                      config={cvss_2_0_metrics}
                      dataProperty="temporal"
                      // here we can send the clean vector data for the dropdowns
                      data={{
                        ...cvss_2_0_vector_object,
                        expanded,
                        classList: { ...props.classes },
                        handleScoreChange,
                        handleAccordionChange,
                      }}
                      classList={{ ...props.classes }}
                    />
                  </CardContent>
                </Card>
                <Card>
                  <CardContent>
                    <div className="view__header">
                      <h2>Environmental Metrics</h2>
                      <h4>
                        {cvss_2_0_environmental_score === -1
                          ? '0.0'
                          : cvss_2_0_environmental_score}
                      </h4>
                    </div>
                    <ExpansionRowDetails
                      title=" "
                      config={cvss_2_0_metrics}
                      dataProperty="environmental"
                      // here we can send the clean vector data for the dropdowns
                      data={{
                        ...cvss_2_0_vector_object,
                        expanded,
                        classList: { ...props.classes },
                        handleScoreChange,
                        handleAccordionChange,
                      }}
                      classList={{ ...props.classes }}
                    />
                  </CardContent>
                </Card>
              </div>
            </TabContent>
          )}

          {!isCVSS31Active ? (
            <></>
          ) : (
            <TabContent label="3.1" id="3.1">
              <div className={props.classes.selectionContainer}>
                <Card>
                  <CardContent>
                    <div className="view__header">
                      <h2>Base Metrics</h2>
                      <h4>
                        {cvss_3_1_base_score === -1
                          ? 'N/A'
                          : cvss_3_1_base_score}
                      </h4>
                    </div>

                    <ExpansionRowDetails
                      title="Exploitability Metrics"
                      config={cvss_3_1_metrics}
                      dataProperty="exploitability"
                      // here we can send the clean vector data for the dropdowns
                      data={{
                        ...cvss_3_1_vector_object,
                        expanded,
                        classList: { ...props.classes },
                        handleScoreChange,
                        handleAccordionChange,
                      }}
                      classList={{ ...props.classes }}
                    />

                    <ExpansionRowDetails
                      title="Impact Metrics"
                      config={cvss_3_1_metrics}
                      dataProperty="impact"
                      // here we can send the clean vector data for the dropdowns
                      data={{
                        ...cvss_3_1_vector_object,
                        expanded,
                        classList: { ...props.classes },
                        handleScoreChange,
                        handleAccordionChange,
                      }}
                      classList={{ ...props.classes }}
                    />
                    <ExpansionRowDetails
                      title="Scope"
                      config={cvss_3_1_metrics}
                      dataProperty="scope"
                      // here we can send the clean vector data for the dropdowns
                      data={{
                        ...cvss_3_1_vector_object,
                        expanded,
                        classList: { ...props.classes },
                        handleScoreChange,
                        handleAccordionChange,
                      }}
                      classList={{ ...props.classes }}
                    />
                  </CardContent>
                </Card>
                <Card>
                  <CardContent>
                    <div className="view__header">
                      <h2>Temporal Metrics</h2>
                      <h4>
                        {cvss_3_1_temporal_score === -1
                          ? 'N/A'
                          : cvss_3_1_temporal_score}
                      </h4>
                    </div>

                    <ExpansionRowDetails
                      title="Temporal Metrics"
                      config={cvss_3_1_metrics}
                      dataProperty="temporal"
                      data={{
                        ...cvss_3_1_vector_object,
                        expanded,
                        classList: { ...props.classes },
                        handleScoreChange,
                        handleAccordionChange,
                      }}
                      classList={{ ...props.classes }}
                    />
                  </CardContent>
                </Card>
                <Card>
                  <CardContent>
                    <div className="view__header">
                      <h2>Environmental Metrics</h2>
                      <h4>
                        {cvss_3_1_environmental_score === -1
                          ? 'N/A'
                          : cvss_3_1_environmental_score}
                      </h4>
                    </div>

                    <ExpansionRowDetails
                      title="Environmental Metrics"
                      config={cvss_3_1_metrics}
                      dataProperty="environmental"
                      data={{
                        ...cvss_3_1_vector_object,
                        expanded,
                        classList: { ...props.classes },
                        handleScoreChange,
                        handleAccordionChange,
                      }}
                      classList={{ ...props.classes }}
                    />

                    <ExpansionRowDetails
                      config={cvss_3_1_metrics}
                      dataProperty="modified"
                      // here we can send the clean vector data for the dropdowns
                      data={{
                        ...cvss_3_1_vector_object,
                        expanded,
                        classList: { ...props.classes },
                        handleScoreChange,
                        handleAccordionChange,
                      }}
                      classList={{ ...props.classes }}
                    />
                  </CardContent>
                </Card>
              </div>
            </TabContent>
          )}
        </Tabs>
        <DialogActions>
          <ZButton
            color="primary"
            buttonText="Close"
            onClick={toggleModalDiffered('ZDevCVSSCalculator', false)}
          />
        </DialogActions>
      </DialogContent>
    </>
  );
}

const styles = ({ palette }) => ({
  root: { overflowY: 'scroll' },
  labelValue: {
    // color: palette.tables.cell,
    paddingLeft: 8,
    fontSize: 13,
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'center',
    justifyContent: 'flex-end',
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    alignSelf: 'flex-start',
    flexDirection: 'column',
    backgroundColor: palette.background.paper,
  },
  label: {
    // color: palette.text.secondary,
    fontWeight: 'bold',
    width: '100%',
    alignSelf: 'center',
  },
  scoreHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
  },
  dataWrapper: {
    marginBottom: 20,
  },
  selectionContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  selectionBox: {
    borderColor: palette.primaryBorder,
    borderStyle: 'solid',
    padding: 15,
    width: '50%',
    height: '100%',
  },
  selectionBoxExploit: {
    borderColor: palette.primaryBorder,
    borderStyle: 'solid',
    padding: 15,
    width: '50%',
    height: '100%',
  },
  optionDesc: {
    display: 'block',
    textAlign: 'justify',
    padding: '0px 10px 0px 10px',
    width: '50%',
  },
  dropdownText: {
    display: 'flex',
    backgroundColor: palette.background.paper,
  },
  accordionRoot: {
    backgroundColor: palette.background.default,
    width: '100%',
    boxShadow: '0 0 black',
  },
  accordionDetails: {
    backgroundColor: palette.background.paper,
  },
  accordionSummary: {
    backgroundColor: palette.background.paper,
    margin: 0,
    height: 20,
    width: '500px',
    paddingBottom: 5,
  },
  accordionContent: {
    display: 'flex',
  },
  accordionContainer: {
    display: 'flex',
    backgroundColor: palette.background.paper,
    alignItems: 'baseline',
    justifyContent: 'space-between',
    height: 'auto',
    paddingBottom: 10,
  },
  accordionContainerExtended: {
    display: 'flex',
    backgroundColor: palette.background.paper,
    alignItems: 'baseline',
    justifyContent: 'space-between',
    paddingBottom: 10,
    height: 125,
  },
  accordionLabel: {
    paddingLeft: 10,
    color: palette.text.secondary,
  },

  //
  hideBorder: {},
  //
});

export default compose(withRouter, withStyles(styles))(ZDevCVSSCalculator);
