import { atom } from 'recoil';

interface IModalState<TPayload> {
  active?: string;
  payload?: TPayload;
}

export const getActiveModalAtom = <TPayload = {}>(
  active?: string,
  payload?: TPayload
) =>
  atom<IModalState<TPayload> | undefined>({
    key: 'activeModal',
    default: {
      active,
      payload,
    },
  });
