import { fetchAllPolicies, fetchPolicyById } from 'api/NetworkPolicyService';
import { ISelectItem } from 'components/UI/input/Select';
import { INetworkPolicy, INetworkPolicyRules } from './models';
import jsonCountries from './countries.json';

export const fetchPolicies = async (
  teamId?: string | null
): Promise<Array<ISelectItem & { created: string }>> => {
  const { data: policies }: { data: Partial<INetworkPolicy>[] } =
    await fetchAllPolicies(teamId);

  return policies.map(
    ({ name, id, created, ...rest }: Partial<INetworkPolicy>) =>
      ({ label: name, value: id, created, ...rest } as ISelectItem & {
        created: string;
      })
  );
};

export const fetchPolicyRulesById = async (
  id?: string | number | null
): Promise<INetworkPolicyRules> => {
  const policy: { data: INetworkPolicyRules } = await fetchPolicyById(id);

  return {
    ...policy.data,
    wifiAccessPoints: policy.data.wifiAccessPoints.map(
      ({ ...wifiAccessPoint }) => ({
        ...wifiAccessPoint,
        selected: false,
      })
    ),
    certificates: policy.data.certificates.map(certificate => ({
      ...certificate,
      selected: false,
    })),
    sinkholeSettings: {
      sinkholeAllow: policy.data.sinkholeSettings.sinkholeAllow,
      ipAddresses: policy.data.sinkholeSettings.ipAddresses.map(ip => ({
        ...ip,
        selected: false,
      })),
      domains: policy.data.sinkholeSettings.domains.map(domain => ({
        ...domain,
        selected: false,
      })),
      countries: policy.data.sinkholeSettings.countries.map(country => ({
        ...country,
        selected: false,
        country:
          jsonCountries.find(c => c.code === country.countryCode)?.country ||
          '',
      })),
    },
  };
};
