import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import reducers from '../reducers/reducers';

import migrations from '../migrations';

export const rootPersistorConfig = {
  key: 'root',
  version: 172,
  storage,
  debug: false,
  whitelist: [
    'uiSettings',
    'teams',
    'auth',
    'superUser',
    'partnerUser',
    'user',
    'dataExportStore',
    'MDMFormReducer',
  ],
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(migrations, { debug: false }),
};

const reducer = persistReducer(rootPersistorConfig, reducers);
const enhancers = composeWithDevTools(applyMiddleware(thunk));
const store = createStore(reducer, {}, enhancers);

export const persistor = persistStore(store);
export default store;
