import BCDMForm from './BCDMForm';
import GoogleForm from './GoogleForm';
import IntuneForm from './IntuneForm';
import MaasForm from './MaasForm';
import VMWareForm from './VMWareForm';
import { IConnectorFormMapping, IModalTitles } from './models';

// The following connector types share the StandardForm component:
// - UemConnector/BlackBerry (with Proxy field determined by prop)
// - MobileIronCoreConnector
// - MobileIronCloudConnector
// - Citrix
// - Jamf

// Any connectors not using the StandardForm should be listed in NON_STANDARD_CONNECTORS array.
export const NON_STANDARD_CONNECTORS: IConnectorFormMapping[] = [
  {
    id: 'IntuneUsGovConnector',
    component: IntuneForm,
  },
  {
    id: 'IntuneGlobalConnector',
    component: IntuneForm,
  },
  {
    id: 'WorkspaceOneConnector',
    component: VMWareForm,
  },
  {
    id: 'GoogleAdminConnector',
    component: GoogleForm,
  },
  {
    id: 'BCDMConnector',
    component: BCDMForm,
  },
  {
    id: 'Maas360Connector',
    component: MaasForm,
  },
];

export const MODAL_TITLES: IModalTitles = {
  ADD: 'MTD.INTEGRATIONS.CREATE_NEW_CONNECTION',
  CLONE: 'MTD.INTEGRATIONS.CLONE_CONNECTION',
  EDIT: 'MTD.INTEGRATIONS.EDIT_CONNECTION',
};

export const truncateString = (str: string) => {
  if (str?.length <= 20) {
    return str;
  }
  return str.slice(0, 20) + '...';
};
