import React, { Fragment } from 'react';
import _ from 'lodash';
import withRouter from 'components/hocs/withRouter';
import { useTranslation } from 'react-i18next';

import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import ZButton from 'UI/Buttons/ZButton';

import { updateAppSettings } from 'api/AppSettingsService';

import { openSnackBar, toggleModalDirect } from 'utils/storeUtils';

const AppSettingsSaveConfirmation = (props) => {
  const appsAttached = _.get(props, 'data.apps', []);
  const { t } = useTranslation();

  return (
    <Fragment>
      <DialogTitle id="simple-dialog-title">
        {props.data.headerText}
      </DialogTitle>
      <DialogContent>
        <p>
          {t('MTD.POLICIES.POLICY_CONFIRM_CHANGES', { policyName: _.get(props, 'data.name', ' ') })}
        </p>
        {appsAttached.length > 0 ? (
          <p>
            {t('MTD.POLICIES.YOUR_CHANGES_PUBLISH_FOLLOWING_APPS')}
            <ul>
              {props.data.apps.map((app) => {
                return <li key={app.id}>{app.name}</li>;
              })}
            </ul>
          </p>
        ) : null}
        <DialogActions>
          <ZButton
            styleName="modalCancel"
            action={() => handleClose()}
            color="secondary"
            buttonText={t('GLOBAL.CANCEL')}
          />
          <ZButton
            action={() => handleSubmit()}
            buttonType="submit"
            color="primary"
            styleName="modalSave"
            buttonText={t('GLOBAL.SAVE')}
          />
        </DialogActions>
      </DialogContent>
    </Fragment>
  );

  function handleClose() {
    toggleModalDirect('AppSettingsSaveConfirmation', false);
  }

  function handleSubmit() {
    const phishingDetectionAction = _.get(
      props,
      'data.payload.phishingDetectionAction',
      false
    )
      ? 'BLOCK'
      : 'WARN';

    const finalPayload = {
      ...props.data.payload,
      ...props.data.name,
      phishingDetectionAction,
    };

    const id = _.get(props, 'data.policySelected.value', null);

    updateAppSettings({ id }, finalPayload)
      .then(() => {
        toggleModalDirect('AppSettingsSaveConfirmation', false);
        props.data.setEditMode(false);
        props.updateUrl({ appsettings: id });
        openSnackBar(t('MTD.POLICIES.APP_SETTINGS.APP_SETTINGS_SUCCESS_SAVE'));
        props.setAppSettingsName(finalPayload.name);
      })
      .catch((error) => console.error(error));
  }
};

export default withRouter(AppSettingsSaveConfirmation);
