import { useEffect, useState, useCallback } from 'react';
import { ISelectPolicy } from '../ZScanApps/ZScanFindings/models';
import { ISelectItem } from 'components/UI/input/Select';
import { getPolicies } from 'api/zDevService';
import { zScanPolicyListAtom } from './atoms';
import { useRecoilState } from 'recoil';

export default (policyId?: string | unknown) => {
  const [policyList, setPolicyList] = useState<ISelectItem[]>();
  const [, setzScanPolicyList] = useRecoilState(zScanPolicyListAtom);
  const fetchPolicyList = useCallback(async () => {
    const { data } = await getPolicies();

    const selectPolicyLens: ISelectItem[] = data?.map(
      ({ id, name, teamId, defaultPolicy }: ISelectPolicy) => {
        return {
          label: name,
          value: id,
          accountBounded: !teamId,
          defaultPolicy,
          teamId,
        };
      }
    );
    setzScanPolicyList(selectPolicyLens);

    return setPolicyList(selectPolicyLens);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setzScanPolicyList, policyId]);
  useEffect(() => {
    fetchPolicyList();
  }, [fetchPolicyList]);

  return { policyList, fetchPolicyList };
};
