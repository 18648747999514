import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    hyperlink: {
      cursor: 'pointer',
      backgroundColor: palette.components.circleIcon.error,
    },
    customDrawer: {
      width: '88% !important',
      height: '90% !important',
    },
    tableCellWrapper: {
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'flex-start',
    },
    inputIcon: {
      cursor: 'pointer',
      paddingRight: '4px',
    },
    actionText: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

export default useStyles;
