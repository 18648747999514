import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// ui
import Typography from '@material-ui/core/Typography';
import ZButton from 'UI/Buttons/ZButton';

import {
  checkPasswordResetValidationToken,
  postPasswordResetValidationToken,
} from 'api/SignupService';

import { fetchPasswordPolicyForResetPassword } from 'api/PasswordPolicyService';

// components
import PublicComponentWrapper from 'components/wrappers/PublicComponentWrapper';
import { FormikPasswordField } from 'components/inputs/FormikPasswordField';
import PasswordRulesBox from 'components/main/common/PasswordRulesBox';
// import {
//   cleanupCustomRules,
//   createCustomPasswordSchema,
// } from './passwordValidation';
import { passwordErrorHelper } from './password.mappings';
import safePropsToken from 'utils/safePropsToken';

const ConfirmPasswordReset = (props) => {
  const [firstName, setFirstName] = useState('');

  const [lastName, setLastName] = useState('');
  const [customPasswordRules, setCustomPasswordRules] = useState({});
  const [tokenError, setTokenError] = useState(false);

  const { t } = useTranslation();

  const urlToken = safePropsToken(props);

  useEffect(() => {
    checkPasswordResetValidationToken(urlToken)
      .then(({ data }) => {
        setFirstName(_.get(data, 'firstName', ''));
        setLastName(_.get(data, 'lastName'));
      })
      .catch(() => setTokenError(true));
  }, [urlToken]);

  useEffect(() => {
    fetchPasswordPolicyForResetPassword(safePropsToken(props))
      .then(({ data }) => {
        setCustomPasswordRules(data);
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
  }, [props]);

  const handleSubmit = (values, { setErrors }) => {
    postPasswordResetValidationToken(safePropsToken(props), {
      password: values.password,
      confirmedPassword: values.confirmPassword,
    })
      .then(() => props.history.push('/login'))
      .catch((error) => {
        // catch errors here setErrors

        const defaultDisplayedErrors =
          'Please contact your Zimperium Customer Success team.';
        let errors = _.get(error, 'response.data', defaultDisplayedErrors);
        if (error.response.status === 400) {
          errors = passwordErrorHelper(errors, t);
        }
        if (error.response.status === 500) {
          return setErrors({ password: defaultDisplayedErrors });
        }
        return setErrors({ password: errors });
      });
  };

  // if token expired
  if (tokenError) {
    return (
      <PublicComponentWrapper>
        <Typography variant="body2" color="error" align="center">
          Your Validation Token has expired. Unable to reset your password.
        </Typography>
      </PublicComponentWrapper>
    );
  }
  // const cleanedRules = cleanupCustomRules(customPasswordRules);
  // const customSchema = createCustomPasswordSchema(cleanedRules, {
  //   confirmPassword: true,
  // });

  return (
    <PublicComponentWrapper>
      <Formik
        validationSchema={ConfirmPasswordSchema}
        validateOnBlur
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={{ password: '' }}
      >
        {({ dirty, isSubmitting, values }) => {
          return (
            <div>
              <Typography
                variant="body1"
                color="inherit"
                align="center"
                paragraph
              >
                Hello {firstName} {lastName}! Please set your password.
              </Typography>

              <Form>
                <Field
                  name="password"
                  label="Password"
                  component={FormikPasswordField}
                  classes={{ label: 'entry-label' }}
                />

                <Field
                  name="confirmPassword"
                  label="Confirm Password"
                  component={FormikPasswordField}
                  classes={{ label: 'entry-label' }}
                />

                <ZButton
                  fullWidth
                  buttonType="submit"
                  styleName="login"
                  color="primary"
                  buttonText="Reset Password"
                />
              </Form>
            </div>
          );
        }}
      </Formik>
      <PasswordRulesBox customPasswordRules={customPasswordRules} />
    </PublicComponentWrapper>
  );
};

const ConfirmPasswordSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref('password', 'Passwords must match'), ''],
      'Passwords must be the same'
    )
    .required('Password confirm is required'),
});

export default withRouter(ConfirmPasswordReset);
