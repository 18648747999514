/* eslint-disable max-lines-per-function */
import { default as MuiAccordion } from '@material-ui/core/Accordion';
import { default as MuiAccordionDetails } from '@material-ui/core/AccordionDetails';
import { default as MuiAccordionSummary } from '@material-ui/core/AccordionSummary';
import { default as MUIArrowForwardIosIcon } from '@material-ui/icons/ArrowForwardIos';
import Tooltip from '@material-ui/core/Tooltip';
import cc from 'classcat';
import { DrawerContext } from 'components/drawer/DrawerProvider';
import { IDrawerContext } from 'components/drawer/DrawerProvider/models';
import DrawerHeader from 'components/UI/DrawerHeader';
import DrawerSkeleton from 'components/UI/DrawerSkeleton';
import GenericCard from 'components/UI/GenericCard';
import Moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Button from 'components/UI/Button';
import { IZScanAppsDrawerData } from './models';
import useStyles from './useStyles';
import ProtectedLink from 'components/main/protected/ProtectedLink';
import CircleIcon from '../CircleComponent';
import MUIIconButton from '@material-ui/core/IconButton';
import MUIContentCopyIcon from '@material-ui/icons/FilterNone';
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard'; // TODO: Fix this import. I'm not sure if we want to add these types yet
import { openSnackBarDiffered } from 'utils/storeUtils';
import { truncateString } from 'components/main/integrations/emm/AddEditCloneEMMAuth/utils';
import ZScanUploadEditButton from '../ZScanEditButton';
import AndroidIcon from 'components/UI/icons/AndroidIcon';
import AppleIcon from 'components/UI/icons/AppleIcon';
import SeverityCircle from '../SeverityCircle';
import { riskScoreToValue } from '../utils';

interface IDrawerProps {}

const Drawer: React.FC<IDrawerProps> = () => {
  const [complianceExpanded, setComplianceExpanded] = useState<boolean>(true);
  const [appsExpanded, setAppsExpanded] = useState<boolean>(true);
  const [fileHashesExpanded, setFileHashesExpanded] = useState<boolean>(true);
  const classes = useStyles();

  const { drawerData, closeDrawer } =
    useContext<Partial<IDrawerContext<IZScanAppsDrawerData>>>(DrawerContext);

  const { rowData }: any = drawerData ?? null;

  const closeDrawerOnLogout = useCallback(() => {
    if (!closeDrawer) {
      return;
    }
    closeDrawer();
  }, [closeDrawer]);

  useEffect(() => {
    window.addEventListener(`auth:logout`, closeDrawerOnLogout);

    return () => {
      window.removeEventListener(`auth:logout`, closeDrawerOnLogout);
    };
  }, [closeDrawerOnLogout]);

  const handleClose = () => {
    if (!closeDrawer) {
      return;
    }
    closeDrawer();
  };

  if (!drawerData) {
    return (
      <div>
        <DrawerSkeleton
          onClose={handleClose}
          os={rowData?.platform}
          title={rowData?.name ?? ''}
        />
      </div>
    );
  }

  return (
    <>
      <DrawerHeader
        onClose={handleClose}
        os={rowData?.platform}
        title={rowData?.name ?? ''}
      />
      <GenericCard noPadding>
        <div className={classes.drawerMainContent}>
          <div className={classes.drawerLogo}>
            {rowData?.platform?.toLowerCase() === 'android' ? (
              <div
                className={cc([
                  classes.backgroundCircleAndroid,
                  classes.backgroundCircle,
                ])}
              >
                <AndroidIcon />
              </div>
            ) : (
              <div
                className={cc([
                  classes.backgroundCircleIos,
                  classes.backgroundCircle,
                ])}
              >
                <AppleIcon />
              </div>
            )}
          </div>
          <table
            className={cc([
              classes.detailsTable,
              classes.detailsTableWithPadding,
            ])}
          >
            <tr>
              <td>Package/Bundle:</td>
              <td>{rowData?.bundleId ?? ''}</td>
            </tr>
            <tr>
              <td>Version:</td>
              <td>{rowData?.lastAssessmentStatistics?.version ?? ''}</td>
            </tr>
            <tr>
              <td>Build:</td>
              <td>{rowData?.lastAssessmentStatistics?.build ?? ''}</td>
            </tr>
            <tr>
              <td>Assesment Date:</td>
              <td>
                {Moment(
                  rowData?.lastAssessmentStatistics?.scanDate ?? ''
                ).format('DD-MMM-YYYY')}
              </td>
            </tr>
            <tr>
              <td>Privacy Risk:</td>
              <td className={classes.summaryAssessment}>
                {
                  <SeverityCircle
                    severity={riskScoreToValue(
                      rowData?.lastAssessmentStatistics?.privacyScore
                    )}
                    showLabel={true}
                  />
                }
              </td>
            </tr>
            <tr>
              <td>Security Risk:</td>
              <td className={classes.summaryAssessment}>
                {
                  <SeverityCircle
                    severity={riskScoreToValue(
                      rowData?.lastAssessmentStatistics?.securityScore
                    )}
                    showLabel={true}
                  />
                }
              </td>
            </tr>
          </table>
          <div className={classes.drawerEditIcon}>
            <ZScanUploadEditButton data={rowData} />
          </div>
          <div className={classes.drawerViewButton}>
            <ProtectedLink
              allow={{ zdev_apps: 'view' }}
              to={`/console/zscan/assessments?appId.keyword=${rowData?.id}`}
            >
              <Button
                onClick={() => {
                  handleClose();
                }}
                text="VIEW ASSESSMENTS"
                color="secondary"
              />
            </ProtectedLink>
          </div>
        </div>
      </GenericCard>

      <MuiAccordion className={classes.accordion} expanded={appsExpanded}>
        <MuiAccordionSummary
          onClick={() => setAppsExpanded(!appsExpanded)}
          className={classes.accordionHeader}
          expandIcon={<MUIArrowForwardIosIcon />}
        >
          App Findings ({rowData?.findingCount})
        </MuiAccordionSummary>

        <MuiAccordionDetails
          classes={{
            root: classes.circleStyle,
          }}
        >
          <div
            onClick={() => {
              handleClose();
            }}
          >
            <div>
              <ProtectedLink
                allow={{ zdev_apps: 'view' }}
                to={`/console/zscan/assessment/${rowData?.lastAssessmentId}/findings?security=true&orderBy=severityOrdinal`}
              >
                <CircleIcon
                  data={rowData?.lastAssessmentStatistics?.securityCount ?? 0}
                  borderColor="#af453f"
                />
              </ProtectedLink>
              <div>Security</div>
            </div>
            <div>
              <ProtectedLink
                allow={{ zdev_apps: 'view' }}
                to={`/console/zscan/assessment/${rowData?.lastAssessmentId}/findings?privacy=true&orderBy=severityOrdinal`}
              >
                <CircleIcon
                  data={rowData?.lastAssessmentStatistics?.privacyCount ?? 0}
                  borderColor="#a3732c"
                />
              </ProtectedLink>
              <div>Privacy</div>
            </div>
            <div>
              <ProtectedLink
                allow={{ zdev_apps: 'view' }}
                to={`/console/zscan/assessment/${rowData?.lastAssessmentId}/findings?severity=CRITICAL`}
              >
                <CircleIcon
                  data={rowData?.lastAssessmentStatistics?.criticalCount ?? 0}
                  borderColor="#ad4641"
                />
              </ProtectedLink>
              <div>Best Practices Recommended</div>
            </div>
            <div>
              <ProtectedLink
                allow={{ zdev_apps: 'view' }}
                to={`/console/zscan/assessment/${rowData?.lastAssessmentId}/findings?severity=BEST_PRACTICES`}
              >
                <CircleIcon
                  data={
                    rowData?.lastAssessmentStatistics?.bestPracticesCount ?? 0
                  }
                  borderColor="#3e794f"
                />
              </ProtectedLink>
              <div>Best Practices Implemented</div>
            </div>
          </div>
        </MuiAccordionDetails>
      </MuiAccordion>
      <MuiAccordion className={classes.accordion} expanded={complianceExpanded}>
        <MuiAccordionSummary
          onClick={() => setComplianceExpanded(!complianceExpanded)}
          className={classes.accordionHeader}
          expandIcon={<MUIArrowForwardIosIcon />}
        >
          Compliance (
          {rowData?.lastAssessmentStatistics?.complianceStatistics
            ?.complianceFindingCount ?? 0}
          )
        </MuiAccordionSummary>

        <MuiAccordionDetails
          classes={{
            root: classes.circleStyle,
          }}
        >
          <div
            onClick={() => {
              handleClose();
            }}
          >
            <div>
              <ProtectedLink
                allow={{ zdev_apps: 'view' }}
                to={`/console/zscan/assessment/${rowData?.lastAssessmentId}/findings?compliance.id.keyword=CWE&orderBy=severityOrdinal`}
              >
                <CircleIcon
                  data={
                    rowData?.lastAssessmentStatistics?.complianceStatistics
                      ?.cwe ?? 0
                  }
                />
              </ProtectedLink>
              <span>CWE</span>
            </div>
            <div>
              <ProtectedLink
                allow={{ zdev_apps: 'view' }}
                to={`/console/zscan/assessment/${rowData?.lastAssessmentId}/findings?compliance.id.keyword=CVE&orderBy=severityOrdinal`}
              >
                <CircleIcon
                  data={
                    rowData?.lastAssessmentStatistics?.complianceStatistics
                      ?.cve ?? 0
                  }
                />
              </ProtectedLink>
              <span>CVE</span>
            </div>
            <div>
              <ProtectedLink
                allow={{ zdev_apps: 'view' }}
                to={`/console/zscan/assessment/${rowData?.lastAssessmentId}/findings?compliance.id.keyword=OWASP&orderBy=severityOrdinal`}
              >
                <CircleIcon
                  data={
                    rowData?.lastAssessmentStatistics?.complianceStatistics
                      ?.owasp ?? 0
                  }
                />
              </ProtectedLink>
              <span>OWASP</span>
            </div>
            <div>
              <ProtectedLink
                allow={{ zdev_apps: 'view' }}
                to={`/console/zscan/assessment/${rowData?.lastAssessmentId}/findings?compliance.id.keyword=MASVS&orderBy=severityOrdinal`}
              >
                <CircleIcon
                  data={
                    rowData?.lastAssessmentStatistics?.complianceStatistics
                      ?.masvs ?? 0
                  }
                />
              </ProtectedLink>
              <span>MASVS</span>
            </div>
          </div>
          <div
            onClick={() => {
              handleClose();
            }}
          >
            <div>
              <ProtectedLink
                allow={{ zdev_apps: 'view' }}
                to={`/console/zscan/assessment/${rowData?.lastAssessmentId}/findings?compliance.id.keyword=GDPR&orderBy=severityOrdinal`}
              >
                <CircleIcon
                  data={
                    rowData?.lastAssessmentStatistics?.complianceStatistics
                      ?.gdpr ?? 0
                  }
                />
              </ProtectedLink>
              <span>GDPR</span>
            </div>
            <div>
              <ProtectedLink
                allow={{ zdev_apps: 'view' }}
                to={`/console/zscan/assessment/${rowData?.lastAssessmentId}/findings?compliance.id.keyword=HIPAA&orderBy=severityOrdinal`}
              >
                <CircleIcon
                  data={
                    rowData?.lastAssessmentStatistics?.complianceStatistics
                      ?.hipaa ?? 0
                  }
                />
              </ProtectedLink>
              <span>HIPAA</span>
            </div>
            <div>
              <ProtectedLink
                allow={{ zdev_apps: 'view' }}
                to={`/console/zscan/assessment/${rowData?.lastAssessmentId}/findings?compliance.id.keyword=PCI&orderBy=severityOrdinal`}
              >
                <CircleIcon
                  data={
                    rowData?.lastAssessmentStatistics?.complianceStatistics
                      ?.pci ?? 0
                  }
                />
              </ProtectedLink>
              <span>PCI</span>
            </div>
            <div>
              <ProtectedLink
                allow={{ zdev_apps: 'view' }}
                to={`/console/zscan/assessment/${rowData?.lastAssessmentId}/findings?compliance.id.keyword=NIAP&orderBy=severityOrdinal`}
              >
                <CircleIcon
                  data={
                    rowData?.lastAssessmentStatistics?.complianceStatistics
                      ?.niap ?? 0
                  }
                />
              </ProtectedLink>
              <span>NIAP</span>
            </div>
          </div>
        </MuiAccordionDetails>
      </MuiAccordion>
      <MuiAccordion className={classes.accordion} expanded={fileHashesExpanded}>
        <MuiAccordionSummary
          onClick={() => setFileHashesExpanded(!fileHashesExpanded)}
          className={classes.accordionHeader}
          expandIcon={<MUIArrowForwardIosIcon />}
        >
          File Hashes
        </MuiAccordionSummary>
        <MuiAccordionDetails
          classes={{
            root: classes.accordionDetails,
          }}
        >
          <GenericCard noPadding>
            <table
              className={cc([
                classes.detailsTableFile,
                classes.detailsTableWithPadding,
              ])}
            >
              <tr>
                <td>Hash Type</td>
                <td>Value</td>
              </tr>
              <tr>
                <td style={{ width: 75 }}>MD5:</td>
                <td>
                  {rowData?.lastAssessmentStatistics?.md5 && (
                    <CopyToClipboard
                      onCopy={openSnackBarDiffered('MD5 copied to clipboard')}
                      text={rowData?.lastAssessmentStatistics?.md5}
                    >
                      <MUIIconButton>
                        <MUIContentCopyIcon />
                      </MUIIconButton>
                    </CopyToClipboard>
                  )}
                  <Tooltip title={rowData?.lastAssessmentStatistics?.md5}>
                    <span>
                      {truncateString(
                        rowData?.lastAssessmentStatistics?.md5 ?? ''
                      )}
                    </span>
                  </Tooltip>
                </td>
              </tr>
              <tr>
                <td style={{ width: 75 }}>SHA-1:</td>
                <td>
                  {rowData?.lastAssessmentStatistics?.sha1 && (
                    <CopyToClipboard
                      onCopy={openSnackBarDiffered('SHA-1 copied to clipboard')}
                      text={rowData?.lastAssessmentStatistics?.sha1}
                    >
                      <MUIIconButton>
                        <MUIContentCopyIcon />
                      </MUIIconButton>
                    </CopyToClipboard>
                  )}
                  <Tooltip title={rowData?.lastAssessmentStatistics?.sha1}>
                    <span>
                      {truncateString(
                        rowData?.lastAssessmentStatistics?.sha1 ?? ''
                      )}
                    </span>
                  </Tooltip>
                </td>
              </tr>

              <tr>
                <td style={{ width: 75 }}>SHA-256:</td>
                <td>
                  {rowData?.lastAssessmentStatistics?.sha256 && (
                    <CopyToClipboard
                      onCopy={openSnackBarDiffered(
                        'SHA-256 copied to clipboard'
                      )}
                      text={rowData?.lastAssessmentStatistics?.sha256}
                    >
                      <MUIIconButton>
                        <MUIContentCopyIcon />
                      </MUIIconButton>
                    </CopyToClipboard>
                  )}
                  <Tooltip title={rowData?.lastAssessmentStatistics?.sha256}>
                    <span>
                      {truncateString(
                        rowData?.lastAssessmentStatistics?.sha256 ?? ''
                      )}
                    </span>
                  </Tooltip>
                </td>
              </tr>
            </table>
          </GenericCard>
        </MuiAccordionDetails>
      </MuiAccordion>
    </>
  );
};
export default Drawer;
