import withRouter from 'components/hocs/withRouter';
import React, { useCallback, useState, useEffect } from 'react';
import { IColumnHeader, ILocationQuery } from 'components/UI/Table/models';
import {
  ILocation,
  IQueryParams,
  IZScanPolicyEntriesHash,
  IZscanIndicator,
} from './models';
import {
  ZScanPoliciesColumnMapping,
  zScanPoliciesTableHeaders,
} from './zScanPolicies.mappings';
import { zdevPoliciesTableColumnChange } from 'reducers/UiSettingsReducers';
import { getUISettingsWithout } from 'reducers/UiSettingsSelectors';
import useStyles from './useStyles';
import { withBackfill_DEPRECATED } from 'components/hocs/withBackfill';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SearchBox from 'components/UI/input/SearchBox';
import MUICard from '@material-ui/core/Card';
import GenericCard from 'components/UI/GenericCard';
import { updateZScanPolicy } from 'api/zDevService';
import Table from 'components/UI/Table';
import { bindActionCreators, compose } from 'redux';
import useBackfill from 'utils/useBackfill';
import { withDirtyState } from 'utils/withDirtyState';
import withMemoizedProps from 'utils/withMemoizedProps';
import TableActions from './TableActions';
import { TableAction } from './TableActions/MenuItemMapping';
import useZScanPolicyIndicators from './useZScanPolicyIndicators';
import ZScanPoliciesFacetedSearch from './ZScanPoliciesFacetedSearch';
import { useRecoilState } from 'recoil';
import { zScanPolicyEntriesHash, zScanPolicyListAtom } from './atoms';
import useZScanPolicyFetchById from './useZScanPolicyFetchById';
import {
  applyFacets,
  mergeCustomCVSSDataWithIndicatorCVSSData,
  mergeObjects,
} from './utils';

import ZScanPolicyPickerBar from './ZScanPolicyPickerBar/zScanPolicyPickerBar';
import AddClonePolicyButton from './AddClonePolicyButton';
import ProtectedComponent from '../protected/ProtectedComponent';
import AddClonePolicyGeneric from './ZScanCreateClonePolicy';
import { ISelectItem } from 'components/UI/input/Select';

import { openSnackBar } from 'utils/storeUtils';
import useZScanPolicyList from './useZscanPolicyList';
import { ISelectOption } from 'components/UI/EditableTable/models';

interface IZScanPolicies {
  location: ILocation;
  zdevPoliciesTableColumnChange: (...args: unknown[]) => void;
  buildsUiSettings: { [key: string]: unknown };
  currentTableHeaders: IColumnHeader[];
  jiggleDirtyState: (...args: any) => void;
  q: { [key: string]: unknown };
  rqps: string[];
  match: any;
  updateUISettings: (...args: unknown[]) => void;
  updateUrl: (params: IQueryParams) => void;
}
const storeKey = 'zScanPolicies';
const ZScanPolicies: React.FC<IZScanPolicies> = ({
  zdevPoliciesTableColumnChange,
  currentTableHeaders,
  jiggleDirtyState,
  q: query,
  rqps,
  buildsUiSettings,
  updateUrl,
  // match,
}) => {
  useBackfill({
    query,
    requiredQueryParams: rqps,
    storedQueryParams: buildsUiSettings,
    updateUrl,
  });

  // atom for policy entry

  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [selectedTableAction, setSelectedTableAction] = useState<TableAction>();

  const { ready } = useTranslation();
  const classes = useStyles();

  const { indicatorTableData } = useZScanPolicyIndicators(query);
  const [zScanPolicyEntry, setzScanPolicyEntry] = useRecoilState(
    zScanPolicyEntriesHash
  );

  /**
   * NEED to asyncResult the tableData here and set it to the atom.  then pass it into table query.
   * maybe tableQuery can just be an async function?
   * we can use another hook to grabe the customPolicyentries (getPolicy(id)) and then pass the result to the atom and the
   * fetch table function.  i think we should do that.
   */

  const { policyList: selectPolicyLens } = useZScanPolicyList(query?.policyId);
  useEffect(() => {
    if (query?.policyId == '' || query?.policyId === undefined) {
      updateUrl({ policyId: selectPolicyLens?.[0]?.value });
    }
    //eslint-disable-next-line
  }, [query?.policyId, selectPolicyLens?.[0]]);
  /**
   * Right now, the "selected policy" will be the first one on the list until we have
   * a selector to swap them out
   **/
  const [zScanPolicyList] = useRecoilState(zScanPolicyListAtom);

  const { policyData: customPolicyData } = useZScanPolicyFetchById(
    query?.policyId
  );

  const tableData: IZscanIndicator[] = React.useMemo(() => {
    const zScanPolicyEntryHash =
      customPolicyData?.policyData?.policyEntries.reduce(
        (accum: any, value: any) => {
          return {
            ...accum,
            [value.findingId]: { ...value },
          };
        },
        {} as IZScanPolicyEntriesHash
      );

    const customPolicyDataHash: IZScanPolicyEntriesHash = {
      ...zScanPolicyEntryHash,
      ...zScanPolicyEntry,
    };
    const customCVSSPolicyDatahash =
      customPolicyData?.policyData?.policyCustomCvssData.reduce(
        (accum: any, value: any) => {
          return {
            [value.findingId]: { ...value, modified: true },
            ...accum,
          };
        },
        {}
      );

    let tableResults = indicatorTableData?.map(({ description, ...doc }) => {
      const cvssData = mergeCustomCVSSDataWithIndicatorCVSSData(
        doc,
        customCVSSPolicyDatahash
      );
      return {
        ...doc,
        description, //descriptionTextHelper(description),
        enabled: customPolicyDataHash?.[doc.id]?.enabled ?? true,
        severity: customPolicyDataHash?.[doc.id]?.severity ?? doc.severity,
        categoryName: doc?.category?.name,
        subcategoryName: doc?.subcategory?.name,
        ...cvssData,
      };
    });
    if (query?.severity) {
      const { severity } = query;
      // const modified = resultWithFacets;

      const map = Array.isArray(severity)
        ? severity.map((s) => s.toUpperCase())
        : [typeof severity === 'string' && severity?.toUpperCase()];

      /* eslint-disable-next-line array-callback-return */
      tableResults = tableResults?.filter((item) => {
        item.severity =
          customPolicyDataHash?.[item.id]?.severity ?? item.severity;
        for (let i = 0; i < map.length; i += 1) {
          if (item.severity === map[i]) {
            return true;
          }
        }
      });
    }

    const appliedFacetsTableData = applyFacets(
      tableResults as IZscanIndicator[],
      query
    );

    return appliedFacetsTableData;
  }, [
    customPolicyData?.policyData?.policyEntries,
    indicatorTableData,
    zScanPolicyEntry,
    query,
    customPolicyData?.policyData?.policyCustomCvssData,
  ]);

  const handleColumnChange = (...args: unknown[]) => {
    zdevPoliciesTableColumnChange(...args);
    jiggleDirtyState();
  };
  const handleSelectionChange = (selectedRows: any) => {
    setSelectedRow(Object.values(selectedRows));
  };

  const handleSearch = useCallback(
    (value: string) => {
      updateUrl({ search: value ?? '' });
    },
    [updateUrl]
  );
  const handleEditMode = useCallback(() => {
    setEditMode(!editMode);
  }, [setEditMode, editMode]);

  const onGoClick = useCallback((): void => {
    switch (selectedTableAction) {
      case TableAction.EnableAllSelected: {
        const enabledObject = selectedRow.map((e: IZscanIndicator) => {
          return {
            findingId: e.id,
            enabled: true,
            severity: zScanPolicyEntry[e?.id]?.severity ?? e.severity,
          };
        });
        const mergeObject: IZScanPolicyEntriesHash = mergeObjects(
          enabledObject,
          zScanPolicyEntry
        );
        setzScanPolicyEntry({
          ...mergeObject,
        });
        setSelectedTableAction(undefined);
        break;
      }
      case TableAction.DisableAllSelected: {
        const disabledObject = selectedRow.map((e: IZscanIndicator) => {
          return {
            findingId: e.id,
            enabled: false,
            severity: zScanPolicyEntry[e?.id]?.severity ?? e.severity,
          };
        });
        const mergeObject: IZScanPolicyEntriesHash = mergeObjects(
          disabledObject,
          zScanPolicyEntry
        );
        setzScanPolicyEntry({
          ...mergeObject,
        });
        setSelectedTableAction(undefined);
        break;
      }
      case TableAction.Informational: {
        const informationalObject = selectedRow.map((e: IZscanIndicator) => {
          return {
            findingId: e.id,
            enabled: zScanPolicyEntry[e?.id]?.enabled ?? e.enabled,
            severity: 'INFORMATIONAL',
          };
        });
        const mergeObject: IZScanPolicyEntriesHash = mergeObjects(
          informationalObject,
          zScanPolicyEntry
        );
        setzScanPolicyEntry({
          ...mergeObject,
        });
        setSelectedTableAction(undefined);
        break;
      }
      case TableAction.Low: {
        const lowObject = selectedRow.map((e: IZscanIndicator) => {
          return {
            findingId: e.id,
            enabled: zScanPolicyEntry[e?.id]?.enabled ?? e.enabled,
            severity: 'LOW',
          };
        });

        const mergeObject: IZScanPolicyEntriesHash = mergeObjects(
          lowObject,
          zScanPolicyEntry
        );

        setzScanPolicyEntry({
          ...mergeObject,
        });
        setSelectedTableAction(undefined);
        break;
      }
      case TableAction.Medium: {
        const mediumObject = selectedRow.map((e: IZscanIndicator) => {
          return {
            findingId: e.id,
            enabled: zScanPolicyEntry[e?.id]?.enabled ?? e.enabled,
            severity: 'MEDIUM',
          };
        });

        const mergeObject: IZScanPolicyEntriesHash = mergeObjects(
          mediumObject,
          zScanPolicyEntry
        );
        setzScanPolicyEntry({
          ...mergeObject,
        });
        setSelectedTableAction(undefined);
        break;
      }
      case TableAction.High: {
        const highObject = selectedRow.map((e: IZscanIndicator) => {
          return {
            findingId: e.id,
            enabled: zScanPolicyEntry[e?.id]?.enabled ?? e.enabled,
            severity: 'HIGH',
          };
        });

        const mergeObject: IZScanPolicyEntriesHash = mergeObjects(
          highObject,
          zScanPolicyEntry
        );
        setzScanPolicyEntry({
          ...mergeObject,
        });
        setSelectedTableAction(undefined);
        break;
      }
      case TableAction.Critical: {
        const criticalObject = selectedRow.map((e: IZscanIndicator) => {
          return {
            findingId: e.id,
            enabled: zScanPolicyEntry[e?.id]?.enabled ?? e.enabled,
            severity: 'CRITICAL',
          };
        });

        const mergeObject: IZScanPolicyEntriesHash = mergeObjects(
          criticalObject,
          zScanPolicyEntry
        );
        setzScanPolicyEntry({
          ...mergeObject,
        });
        setSelectedTableAction(undefined);
        break;
      }
      default:
        break;
    }
  }, [selectedTableAction, selectedRow, zScanPolicyEntry, setzScanPolicyEntry]);

  const handleSavePolicy = async (name: string) => {
    const policyEntries = Object.values(zScanPolicyEntry);
    setIsSaving(true);
    const policySelected = selectPolicyLens.find(
      (policy: ISelectOption) => policy.value === query?.policyId
    );
    try {
      await updateZScanPolicy(
        { policyId: query?.policyId },
        {
          name,
          policyEntries,
          teamId: policySelected?.teamId,
        }
      );
      setIsSaving(false);
      setEditMode(!editMode);
      openSnackBar('Success');
    } catch (e) {
      console.log('Error in updating policy', e);
      openSnackBar('Error in saving');
      setIsSaving(false);
      setEditMode(!editMode);
    }
  };
  const handlePolicyChange = useCallback(
    (_, value: ISelectItem) => {
      const policyId = value?.value ?? '';
      updateUrl({
        policyId,
      });
    },

    [updateUrl]
  );

  if (!ready) {
    return null;
  }
  return (
    <>
      {' '}
      <div className="view__header">
        <h1>zScan Policies</h1>
        <ProtectedComponent allow={{ zdev_policies: 'manage' }}>
          <AddClonePolicyButton policyId={query?.policyId ?? ''} />
        </ProtectedComponent>
      </div>
      <div>
        <ZScanPolicyPickerBar
          isSaving={isSaving}
          editMode={editMode}
          handleEditMode={handleEditMode}
          policy={query?.policyId ?? selectPolicyLens?.[0]?.value}
          selectPolicyLens={zScanPolicyList ?? selectPolicyLens}
          handleSavePolicy={handleSavePolicy}
          handlePolicyChange={handlePolicyChange}
        />
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '12vw', marginRight: 25 }}>
          <ZScanPoliciesFacetedSearch />
        </div>
        <div style={{ width: '100%' }}>
          <GenericCard noPadding>
            <div className={classes.mainContainer}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '50vw' }}>
                  <MUICard>
                    <SearchBox onInputChange={handleSearch} />
                  </MUICard>
                </div>
                <TableActions
                  disabled={!editMode}
                  setSelectedTableAction={setSelectedTableAction}
                  selectedTableAction={selectedTableAction}
                  onGoClick={onGoClick}
                />
              </div>

              <Table
                rowHeight={60}
                minHeight={1000}
                serverSort={false}
                classList={classes}
                columnHeaders={currentTableHeaders}
                footerComponents={() => (
                  <div>
                    {(!!tableData?.length ? tableData?.length : 0) +
                      ' rows total'}
                  </div>
                )}
                fetchTableData={() => (!!tableData?.length ? tableData : [])}
                pagination={false}
                onColumnChange={handleColumnChange}
                query={
                  {
                    page: 0 || '0',
                    size: 100,
                    order: 'desc',
                  } as unknown as ILocationQuery
                }
                rowMapping={ZScanPoliciesColumnMapping(editMode, query)}
                tableId={storeKey}
                heightBuffer={290}
                checkboxColumn
                onSelectionChange={handleSelectionChange}
              />
            </div>
          </GenericCard>
        </div>
        <AddClonePolicyGeneric />
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  buildsUiSettings: getUISettingsWithout(state, storeKey, ['tableHeaders']),
  currentTableHeaders: zScanPoliciesTableHeaders,
  columnHeadersHash: ZScanPoliciesColumnMapping,
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      zdevPoliciesTableColumnChange,
    },
    dispatch
  );
};

export default compose(
  withRouter,
  withDirtyState(),
  withBackfill_DEPRECATED('uiSettings.zScanPolicies'),
  connect(mapStateToProps, mapDispatchToProps)
)(
  withMemoizedProps(ZScanPolicies, [
    'currentTableHeaders',
    'dirtyState',
    'q',
    'updateUrl',
  ])
);
