import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const ConsoleLanding = props => {
  const { modules } = useSelector(state => state.auth.user);

  if (modules.ZIAP) {
    return <Redirect to="/console/zdefend" />;
  }

  if (modules.ZDEV) {
    return <Redirect to="/console/zscan/apps" />;
  }

  if (modules.ZIPS) {
    return <Redirect to="/console/mtd" />;
  }

  if (modules.ZSHIELD) {
    return <Redirect to="/console/zshield" />;
  }

  return <Redirect to="/console/user-settings" />;
};
