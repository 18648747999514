import React, { Component } from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
// ui
import Divider from '@material-ui/core/Divider';
/* Helpers */
import { phishingConfig } from '../ThreatForensics';
import BuildsAnalysisDetails from '../../../../dashboard/zDev/buildsAnalysis/BuildsAnalysisDetails';

const styles = ({ palette }) => {
  return {
    wrapper: {
      display: 'flex',
      alignItems: 'stretch',
      width: '100%',
    },
    dataWrapper: {
      display: 'flex',
      flexDirection: 'row',
      padding: 5,
      justifyContent: 'space-between',
    },
    label: {
      color: palette.text.secondary,
      fontWeight: 'bold',
      width: '25%',
    },
    labelValue: {
      width: '75%',
      display: 'flex',
    },
    divider: {
      paddingBottom: '10px',
    },
  };
};

class ThreatsPhishing extends Component {
  render() {
    const { props } = this;
    const suspiciousUrlInfo = _.get(props, 'threat.suspiciousUrlInfo', []);
    if (_.isEmpty(suspiciousUrlInfo)) {
      return null;
    }
    return (
      <>
        <div className={props.classes.wrapper}>
          <BuildsAnalysisDetails
            dataProperty="column1"
            config={phishingConfig}
            buildsFindingsData={{ phishingUrl: suspiciousUrlInfo?.url,
              contentCategoryName: suspiciousUrlInfo?.contentCategoryName ?? 'N/A' }}
          />
        </div>
        <div className={props.classes.divider}>
          <Divider />
        </div>
      </>
    );
  }
}

export default withStyles(styles)(ThreatsPhishing);
