import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { phishingCategoryValues, phishingFormValues } from './atoms';
import { IFormCategory, IPhishingCheckboxFormData } from './models';

interface IPhishingFormsChangesCheck {
  checkForChangesChkboxes?: (dirty: boolean) => void;
  checkForChangesCateg?: (dirty: boolean) => void;
  dirtyChkboxes?: boolean | undefined;
  dirtyCateg?: boolean;
  catValues?: IFormCategory;
  formValues?: IPhishingCheckboxFormData;
}

const PhishingFormsChangesCheck: React.FC<IPhishingFormsChangesCheck> = ({
  checkForChangesChkboxes,
  checkForChangesCateg,
  dirtyChkboxes,
  dirtyCateg,
  catValues,
  formValues,
}) => {
  const setFormValues = useSetRecoilState<
    IPhishingCheckboxFormData | undefined
  >(phishingFormValues);
  const setCatValues = useSetRecoilState<IFormCategory>(phishingCategoryValues);

  useEffect(() => {
    if(!!checkForChangesChkboxes && typeof dirtyChkboxes === "boolean") {
      checkForChangesChkboxes(dirtyChkboxes);
    }
    if(!!checkForChangesCateg && typeof dirtyCateg === "boolean") {
      checkForChangesCateg(dirtyCateg)
    }
    if (!!catValues) {
      setCatValues(catValues);
    }
    if (!!formValues) {
      setFormValues(formValues);
    }
  }, [
    checkForChangesChkboxes,
    checkForChangesCateg,
    dirtyChkboxes,
    dirtyCateg,
    catValues,
    formValues,
    setCatValues,
    setFormValues,
  ]);

  return null;
};

export default PhishingFormsChangesCheck;
