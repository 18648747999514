// import { getSystemProperties } from 'api/AuthService';
import { Api } from 'config/axiosConfig';

// system properties
export const setSystemProperties = () => dispatch => {
  return Api.get('/api/auth/v1/system/properties').then(resp => {
    dispatch({
      type: 'SET_SYSTEM_PROPERTIES',
      payload: resp.data,
    });
  });
};
