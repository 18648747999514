import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

export default state => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      partnerUsers: {
        ...uiSettingsDefaults.partnerUsers,
        tableHeaders: uiSettingsDefaults.partnerUsers.tableHeaders,
      },
      superUsers: {
        ...uiSettingsDefaults.superUsers,
        tableHeaders: uiSettingsDefaults.superUsers.tableHeaders,
      },
    },
  };
};
