import {
  fetchAllGroupsByTeamId,
  fetchDevicesPage,
  fetchDevicesStats,
} from 'api/apis';
import { fetchiPhoneMapping } from 'api/DevicesService';
import { fetchGroupsByTeamId, formGroupListForSelect } from 'api/GroupService';
import { ISelectItem } from 'components/UI/input/Select';
import APPLE_NAME_MAPPING from 'utility/AppleCommericalNames';
import { IBucket, IDeviceStats, IQueryParams } from '../common/models';
import { IAppleNameMapping, IDevice } from './models';

export const fetchTableData = async (
  query: IQueryParams,
  module: string,
  availableTeams: ISelectItem[]
) => {
  if (query.page) {
    const fetchGroups =
      module !== 'mtd'
        ? fetchAllGroupsByTeamId()
        : fetchGroupsByTeamId({ module: 'ZIPS' });

    const fetchDevices =
      module !== 'mtd'
        ? fetchDevicesPage(query)
        : fetchDevicesPage({ module: 'ZIPS', ...query });

    const fetchIphoneMapping = fetchiPhoneMapping().catch(
      () => APPLE_NAME_MAPPING as IAppleNameMapping
    );

    return Promise.all([fetchGroups, fetchDevices, fetchIphoneMapping]).then(
      ([groups, devicesData, iPhoneMappingResponse]) => {
        const groupsHash = formGroupListForSelect(groups.data);
        const iPhoneModelMap =
          (iPhoneMappingResponse as { data: unknown })?.data ?? // Not sure if this check is needed, but typed as unknown and kept it just in case
          iPhoneMappingResponse;

        const tableData = devicesData.data.content.map((device: IDevice) => {
          let groupNameSet;
          if (device.zappInstance) {
            groupNameSet =
              groupsHash?.[`${[device.zappInstance.groupId]}.name`] ??
              'Unknown';
          }
          if (device?.groupId) {
            groupNameSet = groupsHash?.[device.groupId]?.name ?? 'Unknown';
          }
          const teamId = device?.teamId ?? null;
          const teamName =
            availableTeams.find(({ value }) => value === teamId)?.label ?? '';

          return {
            ...device,
            deviceId: device?.mdmDeviceId ?? device?.zdeviceId ?? 'Unknown',
            groupName: groupNameSet,
            teamName,
            iPhoneModelMap,
            zappInstance: device?.zappInstance,
          };
        });

        return {
          data: tableData,
          count: devicesData.data.totalElements,
          isLoading: false,
        };
      }
    );
  }
  return new Promise(() => ({ data: [], count: 0, isLoading: false }));
};

export const fetchStats = async (
  module: string,
  query: { [key: string]: unknown }
): Promise<IDeviceStats> => {
  let result = {
    deviceCounts: {
      'chrome os': 0,
      android: 0,
      ios: 0,
      total: 0,
    },
    deviceAggregates: [],
    deviceCountsByVersion: {
      'chrome os': [],
      android: [],
      ios: [],
    },
  };
  try {
    const response = await fetchDevicesStats(
      module === 'mtd' ? { module: 'ZIPS', ...query } : query
    );
    result = {
      deviceAggregates:
        response?.data?.aggregations?.['filter#time range']?.[
          'date_histogram#os distribution by time'
        ]?.buckets ?? [],
      deviceCounts: {
        'chrome os':
          response?.data?.aggregations?.['filter#time range']?.[
            'sterms#os distribution'
          ]?.buckets?.find(({ key }: IBucket) => key === 'chrome os')
            ?.doc_count ?? 0,
        android:
          response?.data?.aggregations?.['filter#time range']?.[
            'sterms#os distribution'
          ]?.buckets?.find(({ key }: IBucket) => key === 'android')
            ?.doc_count ?? 0,
        ios:
          response?.data?.aggregations?.['filter#time range']?.[
            'sterms#os distribution'
          ]?.buckets?.find(({ key }: IBucket) => key === 'ios')?.doc_count ?? 0,
        total:
          response?.data?.aggregations?.['filter#time range']?.doc_count ?? 0,
      },
      deviceCountsByVersion: {
        'chrome os':
          response?.data?.aggregations?.['filter#time range']?.[
            'sterms#os distribution'
          ]?.buckets
            ?.find(({ key }: IBucket) => key === 'chrome os')
            ?.['sterms#os version']?.buckets?.map(
              ({ key, doc_count }: IBucket) => ({
                version: key,
                count: doc_count,
              })
            ) ?? 0,
        android:
          response?.data?.aggregations?.['filter#time range']?.[
            'sterms#os distribution'
          ]?.buckets
            ?.find(({ key }: IBucket) => key === 'android')
            ?.['sterms#os version']?.buckets?.map(
              ({ key, doc_count }: IBucket) => ({
                version: key,
                count: doc_count,
              })
            ) ?? 0,
        ios:
          response?.data?.aggregations?.['filter#time range']?.[
            'sterms#os distribution'
          ]?.buckets
            ?.find(({ key }: IBucket) => key === 'ios')
            ?.['sterms#os version']?.buckets?.map(
              ({ key, doc_count }: IBucket) => ({
                version: key,
                count: doc_count,
              })
            ) ?? 0,
      },
    };
  } catch (e) {
    console.log(e);
  }
  return result;
};
