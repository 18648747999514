/* eslint-disable max-lines-per-function */
import { useCallback, useState, useEffect } from 'react';
import { Card, Divider } from '@material-ui/core';
import {
  IAPCFormData,
  ITrigger,
  IAffectedAppsPostPayload,
  IActiveModalTrigger,
} from '../models';
import RadioGroup, { Radio } from 'components/UI/input/RadioGroup';
import { Field as FormikField, Form as FormikForm, Formik } from 'formik';

import { useSetRecoilState } from 'recoil';
import { getActiveModalAtom } from 'atoms/modals';
import { useTranslation } from 'react-i18next';
import cc from 'classcat';
import Button from 'components/UI/Button';
import TextField from 'components/UI/input/TextField';
import Schema from './schema';
import useStyles from './useStyles';
import GenericCard from 'components/UI/GenericCard';
import SearchBox from 'components/UI/input/SearchBox';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RuleCard from 'components/main/oocSelect/RuleCard';
import getRuleEffectCount from '../getRuleEffectCount';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { TriggerCard } from './TriggerCard';
import { useSelector } from 'react-redux';
import useFilterAppCharacteristicsList from './useFilterAppCharacteristicsList';
import { AffectedAppsAndDevices_TITLE } from '../../AffectedAppsDevicesModal';
import useAppCharacteristics from '../../../AppCharacteristicProvider';
import FilteredAccordion from './FilteredAccordian';

const activeModalAtom = getActiveModalAtom<IActiveModalTrigger>();

export interface IFormProps {
  handleClose: () => void;
  ruleData?: IActiveModalTrigger;
}

const Form: React.FC<IFormProps> = ({ handleClose, ruleData }) => {
  const { t } = useTranslation();

  const isViewOnly = ruleData?.action === 'viewOnly';
  const {
    appCharacteristicsList,
    appPolicyDetails,
    appCharFormData,
    setAppCharFormData,
  } = useAppCharacteristics();

  const classes = useStyles();
  const [categoriesCheckboxes, setCategoriesCheckboxes] = useState<ITrigger[]>(
    ruleData?.listOfAllCategoriesToBeChecked ?? []
  );
  const [allTriggers, setAllTriggers] = useState<ITrigger[]>(
    (ruleData?.all as unknown as ITrigger[]) ?? []
  );
  const [anyTriggers, setAnyTriggers] = useState<ITrigger[]>(
    (ruleData?.any as unknown as ITrigger[]) ?? []
  );

  const [disabledCheckboxes, setDisabledCheckboxes] = useState<string[]>(
    ruleData?.listOfAllTriggersToBeDisabled ?? []
  );

  const [selectedAllTriggersCheckbox, setSelectedAllTriggersCheckbox] =
    useState<ITrigger[]>([]);
  const [preSelectedAllTriggersCheckbox, setPreSelectedAllTriggersCheckbox] =
    useState<ITrigger[]>([]);
  const [preSelectedAnyTriggersCheckbox, setPreSelectedAnyTriggersCheckbox] =
    useState<ITrigger[]>([]);
  const [selectedAnyTriggersCheckbox, setSelectedAnyTriggersCheckbox] =
    useState<ITrigger[]>([]);
  const [exceededMaxApps, setExceededMaxApps] = useState<boolean>(false);
  const [appsCount, setAppsCount] = useState<number>(0);
  const [devicesCount, setDevicesCount] = useState<number>(0);
  const setActiveModal = useSetRecoilState(activeModalAtom);

  const [apcFilter, setAPCFilter] = useState<string>('common');
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const { filteredAPCList } = useFilterAppCharacteristicsList({
    apcFilter,
    searchQuery,
  });

  const { fullTriggerRules: triggerRules, setAffectedAppsPostDataPayload } =
    useAppCharacteristics();

  const [modifiedRuleData, setModifiedRuleData] = useState<
    IActiveModalTrigger | undefined
  >(ruleData);
  const { email } = useSelector(({ user }: any) => user);

  const teamId = appPolicyDetails?.team?.id;

  const handleChangeCategoryCheckbox = useCallback(
    (trigger: ITrigger) => {
      const isChecked = categoriesCheckboxes.some(
        (checkbox) => checkbox?.name === trigger?.name
      );
      if (isChecked) {
        setCategoriesCheckboxes((prevSelectedCheckboxes) =>
          prevSelectedCheckboxes.filter(
            (checkbox) => checkbox?.name !== trigger?.name
          )
        );
      } else {
        setCategoriesCheckboxes((prevSelectedCheckboxes) => [
          ...prevSelectedCheckboxes,
          trigger,
        ]);

        setDisabledCheckboxes((prevDisabledCheckboxes) =>
          prevDisabledCheckboxes?.filter((name) => name !== trigger?.name)
        );
      }
    },
    [categoriesCheckboxes]
  );
  const handleChangeAllTriggers = useCallback(
    (trigger: ITrigger) => {
      const isChecked = selectedAllTriggersCheckbox?.some(
        (checkbox) => checkbox?.name === trigger?.name
      );

      if (isChecked) {
        setSelectedAllTriggersCheckbox((prevSelectedCheckboxes) =>
          prevSelectedCheckboxes?.filter(
            (checkbox) => checkbox?.name !== trigger?.name
          )
        );
      } else {
        setSelectedAllTriggersCheckbox((prevSelectedCheckboxes) => [
          ...prevSelectedCheckboxes,
          trigger,
        ]);
      }
    },
    [selectedAllTriggersCheckbox]
  );
  const handleChangeAnyTriggers = useCallback(
    (trigger: ITrigger) => {
      const isChecked = selectedAnyTriggersCheckbox?.some(
        (checkbox) => checkbox?.name === trigger?.name
      );

      if (isChecked) {
        setSelectedAnyTriggersCheckbox((prevSelectedCheckboxes) =>
          prevSelectedCheckboxes?.filter(
            (checkbox) => checkbox?.name !== trigger?.name
          )
        );
      } else {
        setSelectedAnyTriggersCheckbox((prevSelectedCheckboxes) => [
          ...prevSelectedCheckboxes,
          trigger,
        ]);
      }
    },
    [selectedAnyTriggersCheckbox]
  );
  const handleClickBackwardAllTriggers = useCallback(() => {
    const selectedId = [
      ...selectedAllTriggersCheckbox,
      ...preSelectedAllTriggersCheckbox,
    ].map((checked) => checked?.name);
    const filterCheckBox = allTriggers?.filter(
      (checkbox) => !selectedId?.includes(checkbox?.name)
    );
    const isChecked = categoriesCheckboxes?.some((checkbox) =>
      selectedId?.includes(checkbox?.name)
    );
    setAllTriggers(filterCheckBox);
    if (isChecked) {
      setCategoriesCheckboxes((prevCheckboxes) =>
        prevCheckboxes?.filter((checkbox) =>
          [...filterCheckBox, ...anyTriggers]?.some(
            (selected) => selected?.name === checkbox?.name
          )
        )
      );
    }

    setDisabledCheckboxes(
      [...filterCheckBox, ...anyTriggers]?.map((checkbox) => checkbox?.name)
    );
  }, [
    preSelectedAllTriggersCheckbox,
    selectedAllTriggersCheckbox,
    categoriesCheckboxes,
    allTriggers,
    anyTriggers,
  ]);

  const handleClickBackwardAnyTriggers = useCallback(() => {
    const selectedId = [
      ...selectedAnyTriggersCheckbox,
      ...preSelectedAnyTriggersCheckbox,
    ].map((checked) => checked?.name);

    const filterCheckBox = anyTriggers?.filter(
      (checkbox) => !selectedId?.includes(checkbox?.name)
    );
    const isChecked = categoriesCheckboxes.some((checkbox) =>
      selectedId?.includes(checkbox?.name)
    );
    setAnyTriggers(filterCheckBox);

    if (isChecked) {
      setCategoriesCheckboxes((prevCheckboxes) =>
        prevCheckboxes?.filter((checkbox) =>
          [...filterCheckBox, ...allTriggers]?.some(
            (selected) => selected?.name === checkbox?.name
          )
        )
      );
    }

    setDisabledCheckboxes(
      [...filterCheckBox, ...allTriggers]?.map((checkbox) => checkbox?.name)
    );
  }, [
    preSelectedAnyTriggersCheckbox,
    selectedAnyTriggersCheckbox,
    categoriesCheckboxes,
    allTriggers,
    anyTriggers,
  ]);

  const handleClickForwardAllTriggers = useCallback(() => {
    // Shift each selected checkbox individually

    categoriesCheckboxes.forEach((checkbox) => {
      if (
        !allTriggers?.some(
          (shiftedCheckbox) => shiftedCheckbox?.name === checkbox?.name
        ) &&
        !disabledCheckboxes?.includes(checkbox?.name)
      ) {
        setPreSelectedAllTriggersCheckbox(selectedAllTriggersCheckbox);
        setSelectedAllTriggersCheckbox([]);
        setAllTriggers((prevallTriggers) =>
          [...prevallTriggers, checkbox]?.filter(
            (x) => !anyTriggers?.includes(x)
          )
        );
      }
    });
    setDisabledCheckboxes(
      categoriesCheckboxes.map((checkbox) => checkbox?.name)
    );
  }, [
    allTriggers,
    setPreSelectedAllTriggersCheckbox,
    selectedAllTriggersCheckbox,
    anyTriggers,
    categoriesCheckboxes,
    disabledCheckboxes,
  ]);

  const handleClickForwardAnyTriggers = useCallback(() => {
    categoriesCheckboxes.forEach((checkbox) => {
      if (
        !anyTriggers?.some(
          (uniqueCheckbox) => uniqueCheckbox?.name === checkbox?.name
        ) &&
        !disabledCheckboxes?.includes(checkbox?.name)
      ) {
        setPreSelectedAnyTriggersCheckbox(selectedAnyTriggersCheckbox);
        setSelectedAnyTriggersCheckbox([]);
        setAnyTriggers((prevallTriggers) =>
          [...prevallTriggers, checkbox]?.filter(
            (trigger) => !allTriggers?.includes(trigger)
          )
        );
      }
    });
    setDisabledCheckboxes(
      categoriesCheckboxes.map((checkbox) => checkbox?.name)
    );
  }, [
    allTriggers,
    setPreSelectedAnyTriggersCheckbox,
    anyTriggers,
    selectedAnyTriggersCheckbox,
    categoriesCheckboxes,
    disabledCheckboxes,
  ]);

  const checkExceededAppCount = useCallback(async () => {
    if (!triggerRules) {
      return;
    }
    try {
      const postData = {
        all: allTriggers?.map((trigger) => trigger?.name),
        any: anyTriggers?.map((trigger) => trigger?.name),
      };
      if (!!allTriggers?.length || !!anyTriggers?.length) {
        const data = await getRuleEffectCount(postData, teamId);
        setExceededMaxApps(data?.exceededMaxApps);
        setAppsCount(data?.appCount);
        setDevicesCount(data?.deviceCount);
        const activeRuleIndex = triggerRules.findIndex(
          (rule) => rule.id === ruleData?.id
        );
        const newTriggerRules = triggerRules.slice();
        const modifiedRule = {
          ...newTriggerRules[activeRuleIndex],
          appCount: data?.appCount ?? 'n/a',
          deviceCount: data?.deviceCount ?? 'n/a',
        };
        setModifiedRuleData(modifiedRule);
      }
    } catch (error) {
      console.error('Error updating app count:', error);
    }
  }, [allTriggers, anyTriggers, triggerRules, ruleData?.id, teamId]);

  useEffect(() => {
    if (!allTriggers?.length && !anyTriggers?.length) {
      setAppsCount(0);
      setDevicesCount(0);
      setExceededMaxApps(false);
    }
    checkExceededAppCount();
  }, [allTriggers, anyTriggers, checkExceededAppCount]);

  const handleSubmit = useCallback(
    async (formData: IAPCFormData) => {
      const postData: IAffectedAppsPostPayload = {
        all: allTriggers?.map((e) => e?.name),
        any: anyTriggers?.map((e) => e?.name),
      };
      setAppCharFormData({
        name: formData?.name,
        description: formData?.description,
      });

      setAffectedAppsPostDataPayload(postData);
      setActiveModal({
        active: AffectedAppsAndDevices_TITLE,
        payload: {
          ...modifiedRuleData,
          formData,
          allTriggers,
          anyTriggers,
          email,
          appsCount,
          devicesCount,
          action: ruleData?.action,
        },
      });
    },
    [
      allTriggers,
      anyTriggers,
      email,
      setAffectedAppsPostDataPayload,
      ruleData?.action,
      setActiveModal,
      modifiedRuleData,
      appsCount,
      devicesCount,
      setAppCharFormData,
    ]
  );
  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: appCharFormData?.name ?? ruleData?.name ?? '',
        description:
          appCharFormData?.description ?? ruleData?.description ?? '',
      }}
      onSubmit={handleSubmit}
      validationSchema={!isViewOnly && Schema(t, ruleData)}
    >
      {({ isSubmitting, setFieldValue }) => {
        return (
          <FormikForm>
            <div className={classes.parentHeader}>
              <Card className={classes.cardHeaderLogo}>
                <RuleCard os={'ooc'} />
              </Card>
              <div className={classes.fieldStyling}>
                <FormikField
                  name="name"
                  label={t('MTD.POLICIES.APP_POLICY.APP_CHAR.RULE_NAME')}
                  component={TextField}
                  disabled={isViewOnly}
                />
                <FormikField
                  name="description"
                  label={t('GLOBAL.DESCRIPTION')}
                  component={TextField}
                  disabled={isViewOnly}
                />
              </div>
            </div>
            <Divider />
            <div className={classes.subHeader}>
              <div>
                <GenericCard noPadding>
                  <div className={classes.leftBoxStyling}>
                    <RadioGroup className={classes.radioGroupStyling}>
                      <FormikField
                        component={Radio}
                        disabled={isSubmitting}
                        label={t(
                          'MTD.POLICIES.APP_POLICY.APP_CHAR.COMMON_CHARACTERISTICS'
                        )}
                        name="apcFilter"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setAPCFilter(e.target.value);
                          setFieldValue(
                            'charaterictics',
                            e.currentTarget.value
                          );
                        }}
                        size="small"
                        type="radio"
                        value="common"
                        checked={apcFilter === 'common'}
                      />
                      <FormikField
                        component={Radio}
                        disabled={isSubmitting}
                        label={t(
                          'MTD.POLICIES.APP_POLICY.APP_CHAR.ALL_CHARACTERISTICS'
                        )}
                        name="apcFilter"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setAPCFilter(e.target.value);
                          setFieldValue(
                            'charaterictics',
                            e.currentTarget.value
                          );
                        }}
                        checked={apcFilter === 'all'}
                        size="small"
                        type="radio"
                        value="all"
                      />
                    </RadioGroup>
                    <div className={classes.searchBox}>
                      {appCharacteristicsList?.length ? (
                        <SearchBox onInputChange={setSearchQuery} />
                      ) : null}
                    </div>
                    {!appCharacteristicsList?.length ? (
                      <div className={classes.noAppTitleHeader}>
                        <p className={classes.noAppTitle}>
                          {t('MTD.POLICIES.APP_POLICY.APP_CHAR.NO_APPS_DESC')}
                        </p>
                      </div>
                    ) : null}
                    <div className={classes.checkBoxListStyling}>
                      {filteredAPCList?.length ? (
                        <FilteredAccordion
                          filteredAPCList={filteredAPCList}
                          categoriesCheckboxes={categoriesCheckboxes}
                          handleChangeCategoryCheckbox={
                            handleChangeCategoryCheckbox
                          }
                          disabledCheckboxes={disabledCheckboxes}
                          isViewOnly={isViewOnly}
                        />
                      ) : (
                        <div className={classes.noAppTitleHeader}>
                          <p className={classes.noAppTitle}>
                            {t(
                              'MTD.POLICIES.APP_POLICY.APP_CHAR.NO_SEARCH_RESULT'
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </GenericCard>
                <p className={classes.rulesStyling}>
                  {t('MTD.POLICIES.APP_POLICY.APP_CHAR.RULES_DESC')}
                </p>
              </div>
              <div>
                <div className={classes.topButtonStyling}>
                  <Button
                    className={classes.buttonStyling}
                    color="secondary"
                    icon={<ArrowForwardIcon />}
                    onClick={handleClickForwardAllTriggers}
                  />
                  <br />
                  <Button
                    className={classes.buttonStyling}
                    color="secondary"
                    onClick={handleClickBackwardAllTriggers}
                    disabled={allTriggers?.length == 0}
                    icon={<ArrowBackIcon />}
                  />
                </div>
                <div className={classes.bottomButtonStyling}>
                  <Button
                    className={classes.buttonStyling}
                    color="secondary"
                    onClick={handleClickForwardAnyTriggers}
                    icon={<ArrowForwardIcon />}
                  />
                  <br />
                  <Button
                    className={classes.buttonStyling}
                    color="secondary"
                    onClick={handleClickBackwardAnyTriggers}
                    disabled={anyTriggers.length == 0}
                    icon={<ArrowBackIcon />}
                  />
                </div>
              </div>
              <div className={classes.rightBoxStyling}>
                <div>
                  <span className={classes.rightboxHeader}>
                    {t('MTD.POLICIES.APP_POLICY.APP_CHAR.ALL_OF_THESE')}
                  </span>
                  <TriggerCard
                    triggers={allTriggers}
                    handleChange={handleChangeAllTriggers}
                    isViewOnly={isViewOnly}
                  />
                </div>
                <div>
                  <span className={classes.rightboxHeader}>
                    {t('MTD.POLICIES.APP_POLICY.APP_CHAR.ANY_OF_THESE')}
                  </span>
                  <TriggerCard
                    triggers={anyTriggers}
                    handleChange={handleChangeAnyTriggers}
                    isViewOnly={isViewOnly}
                  />
                </div>
                {exceededMaxApps ? (
                  <div
                    className={cc([classes.exceededCard, classes.cardOutlined])}
                  >
                    <span className={classes.warningIcon}>
                      <ReportProblemOutlinedIcon />
                    </span>
                    <p>
                      {t(
                        'MTD.POLICIES.APP_POLICY.APP_CHAR.WARNING_APP_EXCEEDS'
                      )}
                    </p>
                  </div>
                ) : (
                  <p className={classes.ruleMatchCardStyle}>
                    {t('MTD.POLICIES.APP_POLICY.APP_CHAR.APPS_COUNT', {
                      appsCount,
                      deviceCount: devicesCount,
                    })}
                  </p>
                )}
              </div>
            </div>

            <div className={classes.buttonPanel}>
              <Button
                color="secondary"
                text={t('GLOBAL.CANCEL')}
                onClick={handleClose}
              />
              <Button
                color="primary"
                disabled={
                  isSubmitting ||
                  exceededMaxApps ||
                  (!allTriggers.length && !anyTriggers.length)
                }
                text={t('GLOBAL.NEXT')}
                type="submit"
              />
            </div>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default Form;
