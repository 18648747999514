import React, { Fragment, useCallback } from 'react';
import _ from 'lodash';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import withRouter from 'components/hocs/withRouter';

import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { FormikRadioGroup } from 'components/inputs/FormikRadioGroup';
import { FormikTextField } from 'components/inputs/FormikTextField';
import Button from 'components/UI/Button';
import {
  csvInjectionRegex,
} from 'utils/componentUtils';

import { toggleModalDirect, openSnackBar } from 'utils/storeUtils';

import {
  cloneSelectedAppSettings,
  createAppSettings,
} from 'api/AppSettingsService';
import TeamSelectorRadioForm from 'components/main/policies/common/TeamSelectorRadioForm';

const AppSettingsCreateClone = (props) => {
  const { t } = useTranslation();
  const scopeOptions = [
    { value: 'global', label: t('GLOBAL.GLOBAL') },
    {
      value: 'team',
      label: props.data.selectedTeam.selectedTeamName
        ? `Team (${props.data.selectedTeam.selectedTeamName})`
        : 'Team',
    },
  ];

  function handleSubmit(form, actions) {
    const { name } = form;
    const payload = {
      name,
      teamId: form.bounds !== 'global' ? form.teamId : null,
    };

    const id = _.get(props, 'data.policySelected.value', null);

    if (id) {
      cloneSelectedAppSettings({ id }, payload)
        .then(({ data }) => {
          props.updateUrl({ appsettings: data.id });
          toggleModalDirect('AppSettingsCreateClone', false);
          openSnackBar(t('MTD.POLICIES.POLICY_SUCCESS_CLONE', { policyName: form.name }));
        })
        .catch(({ response }) => {
          actions.setSubmitting(false);
          if (response.status === 409) {
            actions.setErrors({
              name: t('MTD.POLICIES.THIS_POLICY_EXIST'),
            });
          }
          console.error(response);
        });
    } else {
      createAppSettings({}, payload)
        .then(({ data }) => {
          props.updateUrl({ appsettings: data.id });
          toggleModalDirect('AppSettingsCreateClone', false);
          openSnackBar(t('MTD.POLICIES.POLICY_SUCCESS_CREATE', { policyName: form.name }));
        })
        .catch(({ response }) => {
          actions.setSubmitting(false);
          if (response.status === 409) {
            actions.setErrors({
              name: t('MTD.POLICIES.THIS_POLICY_EXIST'),
            });
          }
          console.error(response);
        });
    }
  }

  function handleClose() {
    toggleModalDirect('AppSettingsCreateClone', false);
  }

  const handleTeamChange = useCallback((teamId, setFieldValue, values) => {
    setFieldValue('teamId', teamId);
  }, []);

  const defaultName = _.get(props, 'data.policySelected.label');

  return (
    <Fragment>
      <DialogTitle id="simple-dialog-title">
        {props.data.headerText}
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            name: defaultName ? `${defaultName}(cloned)` : '',
            bounds: 'team',
            teamId: props?.data?.selectedTeam?.selectedTeam ?? '',
            selectedTeamId: props?.data?.selectedTeam?.selectedTeam ?? '',
          }}
          onSubmit={handleSubmit}
          validationSchema={AppSettingsSchema(t)}
          enableReinitialize
        >
          {({
            errors,
            values,
            setFieldValue,
            isValid,
            isSubmitting,
            touched,
          }) => {
            return (
              <Form>
                <Field
                  name="name"
                  label={t('GLOBAL.NAME')}
                  required
                  component={FormikTextField}
                />
                <Field
                  name="bounds"
                  label={`${t('GLOBAL.POLICY_SCOPE')}*`}
                  component={FormikRadioGroup}
                  options={scopeOptions}
                  disabled={props?.data?.scopeBounds === 'TEAM_BOUNDED'}
                />
                <TeamSelectorRadioForm
                  handleTeamChange={handleTeamChange}
                  setFieldValue={setFieldValue}
                  availableTeams={props?.data?.selectedTeam?.availableTeams}
                  isRowDataPresent={false}
                  values={values}
                  selectedTeamId={props?.data?.selectedTeam?.selectedTeam}
                  scopeBounds={props?.data?.scopeBounds}
                />
                <DialogActions>
                  <Button
                    onClick={() => handleClose()}
                    color="secondary"
                    text={t('GLOBAL.CANCEL')}
                  />
                  <Button
                    type="submit"
                    color="primary"
                    disabled={
                      !isValid ||
                      isSubmitting ||
                      (Object.keys(touched).length === 0 &&
                        props?.data?.buttonText !== 'Clone')
                    }
                    text={props?.data?.buttonText ?? ''}
                  />
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Fragment>
  );
};

const AppSettingsSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .min(1)
      .matches(csvInjectionRegex, t('GLOBAL.CSV_INJECTION_ERROR'))
      .required(t('GLOBAL.REQUIRED_NAME')),
    teamId: Yup.string().test('team', t('GLOBAL.REQUIRED_TEAM'), (value, ctx) => {
      if (!!ctx?.parent?.selectedTeamId) {
        return true;
      }

      if (ctx?.parent?.bounds === 'global') {
        return true;
      }

      return !!value;
    }),
  });
};

export default withRouter(AppSettingsCreateClone);
