import { publishEvent } from 'utils/eventUtils';
import axios from 'axios';
import { IVMWareFormData, IVMWareAuth } from './models';
import { openSnackBar } from 'utils/storeUtils';
import { Api } from 'config/axiosConfig';
import { IAuthPayload, IAuthForGroupsCreate } from '../../models';
import { FormikHelpers } from 'formik';
import { TFunction } from 'react-i18next';

const createAuthPayload = (
  formData: IVMWareFormData
): IAuthPayload<IVMWareAuth> => {
  return {
    authData: {
      auth: {
        api_key: formData?.api_key,
        auth_method_name: formData?.auth_method_name,
        certificate:
          formData?.auth_method_name === 'certificate'
            ? formData?.certificate
            : undefined,
        client_id:
          formData?.auth_method_name === 'oauth'
            ? formData?.client_id
            : undefined,
        client_secret:
          formData?.auth_method_name === 'oauth'
            ? formData?.client_secret
            : undefined,
        passphrase:
          formData?.auth_method_name === 'certificate'
            ? formData?.password
            : undefined,
        password:
          formData?.auth_method_name === 'basic'
            ? formData?.password
            : undefined,
        user_id:
          formData?.auth_method_name === 'basic'
            ? formData?.user_id
            : undefined,
        provider_id:
          formData?.auth_method_name === 'oauth' &&
          formData?.custom_token_type === 'default'
            ? (formData?.provider?.value as string)
            : undefined,
        oauth_url:
          formData?.auth_method_name === 'oauth' &&
          formData?.custom_token_type === 'custom'
            ? formData?.oauth_url
            : undefined,
      },
      baseUrl: formData?.baseUrl,
      connectorTypeId: formData?.connectorTypeId,
    },
    mode: formData?.mode,
    name: formData?.name,
  };
};

export const createConnection = async (
  formData: IVMWareFormData,
  formikHelpers: FormikHelpers<IVMWareFormData>,
  setAuthError: React.Dispatch<React.SetStateAction<string>>,
  goToNextPage: (
    authForGroupsCreate: IAuthForGroupsCreate<IVMWareAuth>
  ) => void,
  t: TFunction<'translation', undefined>
) => {
  const authPayload = createAuthPayload(formData);
  const apiUrl = 'api/emm/v1/provision/connection/authenticate';
  try {
    const result = await Api.post(apiUrl, authPayload);
    goToNextPage({
      ...authPayload,
      id: result?.data?.connectionId ?? '',
      teamId:
        typeof formData?.team?.value === 'string' ? formData?.team?.value : '',
    });
  } catch (e) {
    if (axios.isAxiosError(e) && e?.response?.status === 409) {
      setAuthError(t('MTD.INTEGRATIONS.ERROR_ACCOUNT_ALREADY_EXIST'));
    } else {
      setAuthError(t('MTD.INTEGRATIONS.ERROR_AUTHENTICATION'));
    }
    console.error(e);
    formikHelpers.setSubmitting(false);
  }
};

export const updateConnection = async (
  formData: IVMWareFormData,
  formikHelpers: FormikHelpers<IVMWareFormData>,
  setAuthError: React.Dispatch<React.SetStateAction<string>>,
  closeModal: () => void,
  t: TFunction<'translation', undefined>
) => {
  if (!formData?.connectionId) {
    return;
  }
  const authPayload = createAuthPayload(formData);
  const apiUrl = `api/emm/v1/provision/connection/${formData.connectionId}/auth-data`;
  try {
    await Api.patch(apiUrl, authPayload);
    closeModal();
    openSnackBar(t('MTD.INTEGRATIONS.SUCCESSFULLY_UPDATED_MDM_CONNECTION.'));
    publishEvent('emm:reload-table');
  } catch (e) {
    if (axios.isAxiosError(e) && e?.response?.status === 409) {
      setAuthError(t('MTD.INTEGRATIONS.ERROR_ACCOUNT_ALREADY_EXIST'));
    } else {
      setAuthError(t('MTD.INTEGRATIONS.ERROR_AUTHENTICATION'));
    }
    formikHelpers.setSubmitting(false);
    console.error(e);
  }
};
