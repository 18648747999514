import React, { PureComponent } from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import withRouter from 'components/hocs/withRouter';

// ui
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { MultiSelect } from 'components/Selects';
import ZButton from 'UI/Buttons/ZButton';

// components
import { GlobalMenuOption } from 'components/Selects/MultiSelect/GlobalMenuOption';
import { TextField } from 'components/inputs';

// services
import { getPolicies, updateApp } from 'api/zDevService';

// utils
import { publishEvent } from 'utils/eventUtils';
import { prepForSelect } from 'utils/componentUtils';

import {
  toggleModalDirect,
  openSnackBar,
  toggleModalDiffered,
} from 'utils/storeUtils';
import GlobalMultiSelectButton from '../Selects/MultiSelect/GlobalMultiSelectButton';
import Checkbox from 'components/inputs/Checkbox';
import GenericTable from 'components/UI/GenericTable';
import EditableTable from 'components/UI/EditableTable';

const initialState = {
  name: '',
  bundleId: '',
  availablePolicies: [],
  selectedPolicy: [],
  distributionList: '',
  metadata: [],
};

class ZDevAppsUpdate extends PureComponent {
  constructor(props) {
    super(props);

    const policy = props.data.rowData.zdevPolicyId
      ? [
          {
            label: props.data.rowData.policyName,
            value: props.data.rowData.zdevPolicyId,
          },
        ]
      : [];

    this.state = {
      ...initialState,
      name: props.data.rowData.name,
      bundleId: props.data.rowData.bundleId,
      selectedPolicy: policy,
      distributionList: _.replace(
        props.data.rowData.distributionList,
        /,/g,
        '\n'
      ),
      marketLink: _.get(props.data.rowData, 'marketLink', null),
      appAutoUpdate: _.get(props.data.rowData, 'appAutoUpdate', false),
      metadata: props.data.rowData.metadata
        ? props.data.rowData.metadata.map((item) => ({
            id: `${new Date().getTime() - _.random(0, 2000)}`,
            key: item.key,
            value: item.value,
            rowEditable: true,
          }))
        : [],
    };

    this.getPolicies = this.getPolicies.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onMetadataCreate = this.onMetadataCreate.bind(this);
    this.onMetadataUpdate = this.onMetadataUpdate.bind(this);
    this.onMetadataDelete = this.onMetadataDelete.bind(this);
  }

  componentDidMount() {
    this.getPolicies();
  }

  validateMetadataKeys(metadata) {
    return new Set(metadata.map((item) => item.key)).size === metadata.length;
  }

  onMetadataCreate(rowData) {
    const newMetadata = [
      ...this.state.metadata,
      { ...rowData, id: new Date().getTime().toString() },
    ];

    if (this.validateMetadataKeys(newMetadata)) {
      this.setState({ metadata: newMetadata });
    }
  }

  onMetadataUpdate(rowData) {
    const { key, value, id } = rowData;

    const newMetadata = this.state.metadata.filter((item) => item.id !== id);
    newMetadata.push(rowData);

    if (this.validateMetadataKeys(newMetadata)) {
      this.setState({ metadata: newMetadata });
    }
  }

  onMetadataDelete(rowData) {
    const { id } = rowData;

    const newMetadata = this.state.metadata.filter((item) => item.id !== id);

    if (this.validateMetadataKeys(newMetadata)) {
      this.setState({ metadata: newMetadata });
    }
  }

  render() {
    const { props, state } = this;

    return (
      <DialogContent className={props.classes.content}>
        <form onSubmit={this.handleSubmit} className={props.classes.form}>
          <TextField label="App Name" value={state.name} disabled />
          <TextField label="Bundle ID" value={state.bundleId} disabled />
          <MultiSelect
            label="zScan Policy"
            name="selectedPolicy"
            isMulti={false}
            buttonPlaceholder="Select zScan Policy"
            components={{
              Option: GlobalMenuOption,
              DropDownButton: GlobalMultiSelectButton,
            }}
            options={state.availablePolicies}
            onChange={this.handleSelect}
            values={state.selectedPolicy}
          />
          {state.marketLink && (
            <div>
              <TextField
                label="Market link"
                value={state.marketLink}
                disabled
              />
              <Checkbox
                checked={state.appAutoUpdate}
                onChange={() =>
                  this.setState({ appAutoUpdate: !state.appAutoUpdate })
                }
                label={'Auto Check App Store For Updates'}
              />
            </div>
          )}
          <TextField
            label="Notification Email(s)"
            multiline
            rows="6"
            value={state.distributionList}
            onChange={(e) => this.handleChange(e, 'distributionList')}
            helperText={'Hit "Enter" to input multiple emails'}
          />

          <h2>App Custom Metadata</h2>

          <EditableTable
            columnHeaders={[
              { id: 'key', label: 'Key' },
              { id: 'value', label: 'Value' },
            ]}
            rowMapping={[
              { path: 'key', inputType: 'text' },
              { path: 'value', inputType: 'text' },
            ]}
            onCreate={this.onMetadataCreate}
            onUpdate={this.onMetadataUpdate}
            onDelete={this.onMetadataDelete}
            tableData={state.metadata}
            editVisible={true}
            showNoDataAddButton={true}
            noDataAddButtonText="+"
            tableId="AppMetadata"
          />

          <DialogActions>
            <ZButton
              styleName="modalCancel"
              action={toggleModalDiffered('ZDevAppsUpdate', false)}
              color="secondary"
              buttonText="Cancel"
            />
            <ZButton
              buttonType="submit"
              color="primary"
              styleName="modalSave"
              buttonText="Save"
            />
          </DialogActions>
        </form>
      </DialogContent>
    );
  }

  getPolicies() {
    return getPolicies()
      .then(({ data }) => {
        const options = prepForSelect(data, [
          {
            key: 'accountBounded',
          },
        ]);

        this.setState({ availablePolicies: options });
      })
      .catch((error) => console.log(error));
  }

  handleSelect(name, selectedOption) {
    const option = _.isArray(selectedOption)
      ? selectedOption
      : [selectedOption];
    this.setState({ [name]: option });
  }

  handleChange(e, field) {
    this.setState({
      [field]: e.target.value,
    });
  }

  handleSubmit(event) {
    const { props, state } = this;

    event.preventDefault();

    const payload = {
      zdevPolicyId: _.get(state, 'selectedPolicy[0].value'),
      distributionList: _.filter(
        _.split(_.get(state, 'distributionList'), '\n'),
        (e) => !_.isEmpty(e)
      ),
      appAutoUpdate: state.appAutoUpdate,
      metadata: state.metadata.map((item) => ({
        key: item.key,
        value: item.value,
      })),
    };

    updateApp({ appId: props.data.rowData.id }, payload)
      .then(() => {
        publishEvent('table:force-fetch-buildsApps');
        toggleModalDirect('ZDevAppsUpdate', false);
        openSnackBar(`${state.name} was successfully updated`);
      })
      .catch(() => {
        toggleModalDirect('ZDevAppsUpdate', false);
        openSnackBar(`Updating ${state.name} Failed`);
      });
  }
}

const styles = () => ({
  content: {
    width: '600px',
  },
  metadata: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default withRouter(
  withStyles(styles, { withTheme: true })(ZDevAppsUpdate)
);
