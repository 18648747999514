import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    calendar: {
      boxShadow: '0px 5px 10px 0px rgba(0,0,0,.12)',
      position: 'absolute',
      right: 0,
      top: 36,
      zIndex: 1000,
    },

    dropdownWrapper: {
      alignItems: 'center',
      display: 'flex',
      position: 'relative',

      '& .select--button': {
        minWidth: 95,
        minHeight: 38,
        padding: '10px 40px 10px 10px',
        position: 'relative',
      },

      '& .select--button:hover': {
        cursor: 'pointer',
      },

      '&.is-loading.shimmer': {
        border: `1px solid ${palette.tables.shimmer}`,
      },
    },

    icon: {
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
    },
  })
);

export default useStyles;
