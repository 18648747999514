import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    caption: {
      fill: palette.text.secondary,
      fontSize: '32px',
    },
    high: {
      stroke: `${palette.charts.brightGreen} !important`,
    },
    low: {
      stroke: `${palette.charts.tomato} !important`,
    },
    medium: {
      stroke: `${palette.charts.orange} !important`,
    },
    notApplicable: {
      opacity: 0.33,
    },
    rpm: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      overflow: 'hidden',
      position: 'relative',

      '& circle': {
        stroke: palette.charts.background,
        strokeDashoffset: 0,
        strokeWidth: '25px',
        transform: 'rotate(180deg)',
        transformOrigin: '50% 50%',
        transition: 'stroke-dashoffset 1s linear',
      },
    },
    stripe: {
      stroke: palette.charts.background,
    },
  })
);

export default useStyles;
