import { useTheme } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { Margin } from 'recharts/types/util/types';
import { TMultiBarChartData, TMultiBarChartDataFill } from './models';
import useStyles from './useStyles';
import MultiLineTick from './MultiLineTick';
import { Redirect } from 'react-router-dom';

export interface IMultiBarChartProps {
  data: TMultiBarChartData[];
  fill: TMultiBarChartDataFill;
  height?: string | number | undefined;
  margin?: Margin;
  width?: string | number | undefined;
  link?: string;
}

const MultiBarChart: React.FC<IMultiBarChartProps> = ({
  data,
  fill,
  height = 250,
  margin = { bottom: 30, left: 0, right: 30, top: 20 },
  width = '100%',
  link,
}) => {
  const [deepLinked, setDeepLinked] = useState<boolean>(false);
  const [linkQuery, setLinkquery] = useState<string>('');
  const classes = useStyles();
  const theme = useTheme();

  const redirectMaybe = useMemo(
    () => !!link && deepLinked && <Redirect to={`${link}${linkQuery}`} />,
    [deepLinked, link, linkQuery]
  );

  const dataKeys = useMemo(
    () =>
      Array.from(
        new Set(data.reduce<string[]>((a, c) => [...a, ...Object.keys(c)], []))
      ),
    [data]
  );

  const bars = useMemo(
    () =>
      dataKeys.map((dataKey) =>
        ['name', 'subName'].includes(dataKey) ? null : (
          <Bar
            barSize={8}
            dataKey={dataKey}
            fill={fill[dataKey] ?? 'black'}
            key={dataKey}
            radius={4}
            stackId="a"
            className={classes.bar}
          />
        )
      ),
    [dataKeys, fill, classes]
  );

  return (
    <div className={classes.multiBarChart}>
      <ResponsiveContainer height={height} width={width}>
        <BarChart className={classes.chart} data={data} margin={margin}>
          <XAxis
            axisLine={false}
            dataKey="name"
            fontSize={13}
            onClick={(data: any) => {
              setDeepLinked(true);
              const items = data.value?.split(' ');
              if (items?.length > 1) {
                setLinkquery(`osVersion==${items[0]};osPatchDate==${items[1]}`);
              } else if (items?.length === 1) {
                setLinkquery(`osVersion==${items[0]}`);
              }
            }}
            tick={<MultiLineTick linkBase={link} />}
            tickLine={false}
            minTickGap={-1000}
          />
          <YAxis
            axisLine={false}
            fontSize={13}
            tick={{ fill: theme.palette.text.primary }}
            tickLine={false}
          />
          {bars}
        </BarChart>
      </ResponsiveContainer>
      {redirectMaybe}
    </div>
  );
};

export default MultiBarChart;
