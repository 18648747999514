import _ from 'lodash';
import { updatePrivacyPolicy } from 'api/apis';
import { IPrivacyPolicyCategoryRule, IPrivacyPolicyData, IPrivacyPolicyResponse } from './model';
import { openSnackBar } from 'utils/storeUtils';
import { TFunction } from 'react-i18next';

export const updatePrivacyPolicyData = (
  t: TFunction<'translation', undefined>,
  privacySelected: IPrivacyPolicyData,
  data: {
    selectedPrivacyRules?: IPrivacyPolicyCategoryRule,
    name?: string,
    locationAccuracy?: number | string,
  },
  onSuccess?: (policy: IPrivacyPolicyData) => void,
  onFailure?: () => void,
) => {
  const { selectedPrivacyRules, name, locationAccuracy } = data;
  const rulesMap = Object.keys(selectedPrivacyRules ?? {}).map(key => {
    return selectedPrivacyRules?.[key];
  });
  const rules = _.flatten(rulesMap);
  const payload = {
    id: privacySelected.value,
    name,
    rules,
    locationAccuracy,
    team: !privacySelected.global ? privacySelected.team : null,
  }

  updatePrivacyPolicy(
    { policyId: privacySelected.value },
    payload,
  ).then(({ data }: IPrivacyPolicyResponse) => {
    openSnackBar(t('MTD.POLICIES.APP_POLICY.YOUR_CHANGES_SAVED_THIS_POLICY'));
    onSuccess?.(data);
  })
  .catch(() => {
    openSnackBar(t('GLOBAL.SOMETHING_WRONG'));
    onFailure?.();
  });
};
