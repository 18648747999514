import { ServiceHandlerFactory } from 'utils/serviceUtils';
import { fetchAuthorizationParamsMapping } from 'mappings/services/authorizationServiceMapping';

export const fetchApiKeysPage = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/api_keys',
  },
  paramSchema: fetchAuthorizationParamsMapping,
});

export const createAPIAccessKey = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/api_keys',
    method: 'post',
  },
});

export const updateAPIAccessKey = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/api_keys/:apiKey',
    method: 'put',
  },
});
export const deleteApiKey = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/api_keys/:apiKey',
    method: 'delete',
  },
});

export const regenerateSecret = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/api_keys/regenerate/:apiKey',
    method: 'put',
  },
});
