import { ServiceHandlerFactory } from 'utils/serviceUtils';

export const createDormancyPolicy = ServiceHandlerFactory({
  axios: {
    url: 'api/devices/v1/dormancy/policies',
    method: 'post',
  },
});

export const cloneDormacyPolicy = ServiceHandlerFactory({
  axios: {
    url: 'api/devices/v1/dormancy/policies/:id/clone',
    method: 'post',
  },
});
