import React, { Fragment } from 'react';
import MaterialTableBody from '@material-ui/core/TableBody';

import { TableColumnsContext, withLoader } from 'components/Tables';
import InAppsTableRow from './InAppsTableRow';

class TableBody extends React.Component {
  render() {
    const { props } = this;

    return (
      <Fragment>
        <TableColumnsContext.Consumer>
          {({ rowMapping, rowHandlers, rest }) => {
            return (
              <MaterialTableBody>
                {withLoader(
                  props,
                  this.renderTableContent(props, rowMapping, rowHandlers, rest),
                )}
              </MaterialTableBody>
            );
          }}
        </TableColumnsContext.Consumer>
      </Fragment>
    );
  }

  renderTableContent(props, rowMapping = {}, rowHandlers = {}, rest = {}) {
    return () => {
      return props.tableData.map(data => {
        return (
          <Fragment key={`row-frag-${data.id}`}>
            <InAppsTableRow
              rowData={data}
              rowMapping={rowMapping}
              handlers={rowHandlers}
              classList={props.classList}
              fetchTableData={props.fetchTableData}
              rest={rest}
            />
          </Fragment>
        );
      });
    };
  }
}

export default TableBody;
