import { createStyles, makeStyles } from '@material-ui/styles';

// Usage of 'any' inside this style decorator is discouraged; however, our theme
// currently does not conform to MUI's theme structure standards. So this will
// be a followup fix for later.
const useStyles = makeStyles(({ config }: any) =>
  createStyles({
    publicFooter: {
      alignItems: 'center',
      background: '#2E6689',
      bottom: '0',
      color: '#fff',
      display: 'flex',
      fontSize: config?.textSizes?.petite ?? 'inherit',
      height: 'min(5%, 40px)',
      justifyContent: 'center',
      position: 'fixed',
      width: '100%',

      '& > *': {
        margin: '0 5px',
      },
    },

    footerText: {
      color: 'white',
      fontFamily: "'Roboto', sans-serif",
      textAlign: 'center',
    },
  })
);

export default useStyles;
