import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    riskiestDeviceModels: {
      justifyContent: 'center',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',

      '& > div.riskiest-device-model-item': {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '15px',
        textAlign: 'left',
        width: '100%',
      },
    },
    riskiestDeviceModelsCard: {
      maxWidth: 500,
    },
    icon: {
      marginRight: '10px',
    },
    title: {
      fontWeight: 'bold',
      width: '50%',
    },
  })
);

export default useStyles;
