import 'chartjs-plugin-labels';
import { Doughnut as CJSDoughnut } from 'react-chartjs-2';
import { ICJSGraph, IGraphOptions } from '../common/models';
import useStyles from './useStyles';
import { defaultOptions } from './utils';

// This type is asserted to help create layer until we upgrade to Chart.js 3.x
const CJSDoughnutGraph = CJSDoughnut as unknown as React.FC<ICJSGraph>;

interface IDoughnutGraph {
  data: unknown;
  getElementAtEvent?: (elems: Element[]) => void;
  height?: number;
  hide?: boolean;
  icon?: JSX.Element;
  labels: string[];
  onGraphClick?: (...args: unknown[]) => void;
  options?: IGraphOptions;
  pieText?: string | JSX.Element;
  ref?: string;
  width?: number;
}

const DoughnutGraph: React.FC<IDoughnutGraph> = props => {
  const {
    data,
    getElementAtEvent,
    height,
    hide,
    icon,
    labels,
    onGraphClick,
    pieText,
    ref,
    width,
  } = props;

  const classes = useStyles();

  // Merge options
  const pluginLabels = {
    ...(defaultOptions?.plugins?.labels ?? {}),
    ...(props.options?.plugins?.labels ?? {}),
  };
  const options = {
    ...defaultOptions,
    ...props.options,
    plugins: { labels: { ...pluginLabels } },
    onClick: (e: React.MouseEventHandler<HTMLButtonElement>, activeEls: ChartDataSet) => {
      return typeof props?.onGraphClick === 'function' && props?.onGraphClick(e, activeEls);
    }
  };

  if (hide) {
    return null;
  }

  return (
    <aside className={classes.pieChart}>
      <figure className={classes.pieLabel}>
        <div>{icon}</div>
        <figcaption className={classes.pieText}>{pieText}</figcaption>
      </figure>
      <CJSDoughnutGraph
        data={{ datasets: data as unknown[], labels }}
        getElementAtEvent={getElementAtEvent}
        height={height}
        onGraphClick={onGraphClick}
        options={options}
        ref={ref}
        width={width}
      />
    </aside>
  );
};

export default DoughnutGraph;
