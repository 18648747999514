import { makeStyles } from '@material-ui/styles';

// Usage of 'any' inside this style decorator is discouraged; however, our theme
// currently does not conform to MUI's theme structure standards. So this will
// be a followup fix for later.
const useStyles = makeStyles(() => ({
  detail: {
    fontSize: 14,
  },
  label: {
    fontSize: 12,
    paddingRight: '30px',
  },
}));

export default useStyles;
