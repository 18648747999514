import { default as MUICard } from '@material-ui/core/Card';
import cc from 'classcat';
import React from 'react';
import useStyles from './useStyles';

export interface IGenericCardProps {
  children: React.ReactNode;
  className?: string;
  classTitle?: string;
  isLoading?: boolean;
  loadingHeight?: number;
  noPadding?: boolean;
  title?: React.ReactNode;
}

const GenericCard: React.FC<IGenericCardProps> = ({
  children,
  className,
  classTitle,
  isLoading,
  loadingHeight,
  noPadding,
  title,
}) => {
  const classes = useStyles();

  return (
    <MUICard className={cc([classes.genericCard, className])}>
      {!!title && (
        <div className={cc([classes.title, classTitle])}>{title}</div>
      )}
      <div
        className={cc([
          classes.content,
          { [classes.noPadding]: !!noPadding },
          { [classes.isLoading]: !!isLoading },
          { 'is-loading': !!isLoading },
          { 'shimmer': !!isLoading },
        ])}
        style={{
          minHeight:
            'number' === typeof loadingHeight && isLoading
              ? loadingHeight
              : 'auto',
        }}
      >
        {children}
      </div>
    </MUICard>
  );
};

export default GenericCard;
