import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    platform: {
      '& svg': {
        fontSize: 20,
        marginRight: 10,
        minHeight: 20,
        minWidth: 20,
      },
      alignItems: 'center',
      display: 'flex',
    },
    globalIndWrapper: {
      paddingBottom: 10,
    },
    searchBar: {
      marginBottom: 16,
    },
    created: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    mainContainer: {
      padding: '15px',
    },
    detailsDataWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    detailsData: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: '30px',
      margin: '3px 0px',
    },
    detailsLabel: {
      color: palette.text.secondary,
    },
    selectStyle: {
      '& ::placeholder': {
        color: palette.text.secondary,
        opacity: 1,
      },
    },
    detailsLabelValue: {
      color: palette.tables.cell,
      fontSize: '16px',
    },
    detailsDataContainer: {
      display: 'flex',
      gap: '100px',
    },
    headerContainer: {
      display: 'flex',
      gap: '30px',
    },

    appNameHeader: {
      paddingLeft: '6px',
    },

    header: {
      alignItems: 'center',
      backgroundColor: palette.tables.header.background,
      color: palette.text.secondary,
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0px 10px 0px 10px',
    },
    accordion: {
      boxShadow: 'none',
      color: palette.text.secondary,
    },
    accordionHeader: {
      backgroundColor: palette.tables.header.background,
      border: `1px solid ${palette.tables.border}`,
      color: palette.text.secondary,
      minHeight: 'none',
      '& .MuiAccordionSummary-expandIcon': {
        '&.Mui-expanded': {
          transform: 'rotate(90deg)',
        },
      },
      '& svg': {
        width: 14,
      },
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
    circleStyle: {
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
      flexWrap: 'wrap',
      padding: '1rem',
      width: '515px',
      overflowX: 'scroll',
      marginLeft: '10px',
      '  &::-webkit-scrollbar ': {
        height: 5,
      },

      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
        borderRadius: '10px',
      },

      ' &::-webkit-scrollbar-thumb': {
        backgroundColor: '#8e8e8e',
        outline: '1px solid #8e8e8e',
        borderRadius: '10px',
      },

      textAlign: 'center',
      '&>div>div': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: palette.text.secondary,
        fontSize: '12px',
        fontWeight: 500,
        gap: '10px',
      },
      '&>div>div>span': {
        color: palette.text.secondary,
        fontSize: '12px',
        fontWeight: 500,
      },
      '&>div': {
        display: 'flex',
        width: '515px',
        justifyContent: 'space-evenly',
      },
    },

    findingDetailsContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      padding: '16px',
    },
    detailsSubContianer: {
      display: 'flex',
      gap: '5px',
      flexWrap: 'wrap',
    },
    detailsDescriptions: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: 14,
      gap: '20px',
      '& div span': { color: palette.text.secondary },
      '& div p': { fontSize: 13, lineHeight: 1.5 },
    },
    tickets: {
      marginTop: '13px',
      display: 'flex',
      flexDirection: 'column',
      '& > a': {
        gap: '20px',
        color: palette.primary.main,
        fontSize: 13,
        lineHeight: 1.5,
      },
    },
    yes: {
      color: palette.primary.main,
    },
    formatDetails: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      fontWeight: 'bold',
    },
    formatDetailValues: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
    details: {
      display: 'flex',
      fontSize: 12,
      padding: 15,
    },
    detailsTable: {
      fontSize: 14,
      alignSelf: 'baseline',
      marginBottom: '10px',
    },
    detailsTableWithPadding: {
      '& tr': {
        '& td:first-child': {
          paddingRight: 15,
          color: palette.text.secondary,
        },
      },
    },
    activeRowIndicator: {
      border: '2px solid #8e8e8e',
      backgroundColor: '#747c80',
      opacity: 0.5,
    },
  })
);

export default useStyles;
