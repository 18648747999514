import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    copyField: {
      marginLeft: 10,
      width: 362,
    },
    marginTop10: {
      marginTop: 10,
    },
    message: {
      paddingBottom: 20,
      width: 500,
    },
    title: {
      color: palette.text.primary,
      fontWeight: 'bold',
    },
  })
);

export default useStyles;
