import Typography from '@material-ui/core/Typography';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import React, { useCallback } from 'react';
import useStyles from './useStyles';

interface ICategoryTreeProps extends TreeItemProps {
  bgColor?: string;
  color?: string;
  labelInfo: React.ReactNode;
  labelText?: string;
}

const CategoryTreeItem: React.FC<ICategoryTreeProps> = ({
  labelText,
  labelInfo,
  ...other
}) => {
  const classes = useStyles();

  const labelClicked = useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
  },[]);

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot} onClick={labelClicked}>
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography
            variant="caption"
            color="inherit"
            className={classes.labelInfo}
          >
            {labelInfo}
          </Typography>
        </div>
      }
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
};

export default CategoryTreeItem;
