import MUICard from '@material-ui/core/Card';
import MUICardContent from '@material-ui/core/CardContent';
import { CloudDownload } from '@material-ui/icons';
import {
  downloadThreatsCsv,
  downloadThreatsCsvCount,
} from 'api/ThreatsService';
import Table from 'components/UI/Table';
import {
  IColumnHeader,
  IFilterEditor,
  ILocationQuery,
} from 'components/UI/Table/models';
import Tabs from 'components/UI/Tabs';
import { IGraphData } from 'components/UI/graphs/common/models';
import SearchBox from 'components/UI/input/SearchBox';
import { DrawerContext } from 'components/drawer/DrawerProvider';
import { IDrawerContext } from 'components/drawer/DrawerProvider/models';
import withRouter from 'components/hocs/withRouter';
import { IAppleNameMapping } from 'components/main/devices/Devices/models';
import threatsDataFilterMappings from 'mappings/dataFilterMappings/threatsDataFilterMappings';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  mtdThreatsTableColumnChange,
  threatsTableColumnChange,
  updateUISettings,
} from 'reducers/UiSettingsReducers';
import {
  getUISettingsTableHeaders,
  getUISettingsWithout,
} from 'reducers/UiSettingsSelectors';
import { bindActionCreators, compose } from 'redux';
import { toggleModalDiffered } from 'utils/storeUtils';
import useAsyncResult from 'utils/useAsyncResult';
import useBackfill from 'utils/useBackfill';
import { withDirtyState } from 'utils/withDirtyState';
import withMemoizedProps from 'utils/withMemoizedProps';
import FilterChipBox from '../../common/FilterChipBox';
import { IFilterMapping } from '../../common/FilterChipBox/models';
import ThreatDetailsDrawer from '../ThreatDetailsDrawer';
import ThreatsAppBar from '../ThreatsAppBar';
import { IVector } from '../ThreatsAppBar/models';
import { fetchVectors } from '../ThreatsAppBar/utils';
import ThreatsByAppGraph from '../ThreatsByAppGraph';
import ThreatsTimelineGraph from '../ThreatsTimelineGraph';
import UniqueDevicesGraph from '../UniqueDevicesGraph';
import { ILocation, IQueryParams } from '../common/models';
import { resolveFilters, threatsColumnMapping } from '../threatMappings';
import {
  IFilterValues,
  IGroup,
  IRowData,
  IThreatDrawerData,
  IThreatStats,
  IThreatTypesFromApi,
  IThreatsUISettings,
} from './models';
import tableQuery from './tableQuery';
import useStyles from './useStyles';
import {
  fetchGroups,
  fetchIPhoneMap,
  fetchStats,
  fetchThreatTypesFromApi,
  fetchThreatsByApp,
  fetchUniqueDevices,
} from './utils';
import { LanguagePayloadMapping } from 'models/language';

const INITIAL_FILTER_VALUES: IFilterValues = {
  activationName: '',
  os: '',
  severity: '',
  state: '',
  threatTypeId: '',
  teamName: '',
  vector: '',
  zappName: '',
};

interface IThreatsProps {
  location: ILocation;
  threatsUiSettings: IThreatsUISettings;
  mtdThreatsUiSettings: IThreatsUISettings;
  threatsTableColumnChange: (...args: unknown[]) => void;
  mtdThreatsTableColumnChange: (...args: unknown[]) => void;
  jiggleDirtyState: (...args: any) => void;
  module: string;
  q: { [key: string]: unknown; };
  rqps: string[];
  updateUISettings: (...args: unknown[]) => void;
  updateUrl: (params: IQueryParams) => void;
  currentMtdThreatsTableHeaders: IColumnHeader[];
  currentThreatsTableHeaders: IColumnHeader[];
}

const Threats: React.FC<IThreatsProps> = ({
  threatsTableColumnChange,
  mtdThreatsTableColumnChange,
  threatsUiSettings,
  mtdThreatsUiSettings,
  jiggleDirtyState,
  location,
  q: query,
  updateUISettings,
  updateUrl,
  module,
  rqps,
  currentMtdThreatsTableHeaders,
  currentThreatsTableHeaders,
}) => {
  const classes = useStyles();
  const { t, i18n, ready } = useTranslation();
  const { openDrawer } =
    useContext<Partial<IDrawerContext<IThreatDrawerData>>>(DrawerContext);

  const createCSVTableHeaders = useCallback(() => {
    return module === 'mtd'
      ? currentMtdThreatsTableHeaders
      : currentThreatsTableHeaders;
  }, [module, currentMtdThreatsTableHeaders, currentThreatsTableHeaders]);

  const [filterValues, setFilterValues] = useState<IFilterValues>(
    INITIAL_FILTER_VALUES
  );
  const filters = useMemo(
    () => resolveFilters(filterValues, t),
    [filterValues, t]
  );

  const threatStats: IThreatStats | undefined = useAsyncResult(
    fetchStats,
    module,
    query,
    filters,
  );

  const vectors: IVector[] | undefined = useAsyncResult(
    fetchVectors,
    module,
    query,
    filters,
  );

  const uniqueDevicesGraphData: IGraphData | undefined = useAsyncResult(
    fetchUniqueDevices,
    module,
    query,
    filters,
  );

  const threatsTypesFromApi: IThreatTypesFromApi | undefined = useAsyncResult(
    fetchThreatTypesFromApi,
    !!i18n.language
      ? LanguagePayloadMapping[
      i18n.language?.toLowerCase() as keyof typeof LanguagePayloadMapping
      ]
      : null
  );

  const iPhoneModelMap: IAppleNameMapping | undefined =
    useAsyncResult(fetchIPhoneMap);

  const groups: IGroup[] = useAsyncResult(fetchGroups, module);

  const threatsByAppGraphData: IGraphData | undefined = useAsyncResult(
    fetchThreatsByApp,
    module,
    query,
    threatsTypesFromApi
  );

  useBackfill({
    query,
    requiredQueryParams: rqps,
    storedQueryParams:
      module === 'mtd' ? mtdThreatsUiSettings : threatsUiSettings,
    updateUrl,
  });

  const handleSearch = useCallback(
    (value: string) => {
      updateUrl({ search: value ?? '' });
    },
    [updateUrl]
  );

  const handleDeleteQueryParam = useCallback(
    (paramName: string) => {
      const queryParams = location.query;
      if (paramName === 'appVersionId') {
        updateUrl({
          ...queryParams,
          [paramName]: undefined,
          appName: undefined,
          appVersion: undefined,
        });
      } else {
        updateUrl({ ...queryParams, [paramName]: undefined });
      }
    },
    [updateUrl, location.query]
  );

  const handleColumnChange = useCallback(
    (...args: unknown[]) => {
      module === 'mtd'
        ? mtdThreatsTableColumnChange(...args)
        : threatsTableColumnChange(...args);
      jiggleDirtyState();
    },
    [
      jiggleDirtyState,
      module,
      mtdThreatsTableColumnChange,
      threatsTableColumnChange,
    ]
  );

  const handleOpenDrawer = (rowProps: { data: IRowData; }) => {
    if (!openDrawer) {
      return;
    }
    openDrawer({
      drawerProps: {
        rowData: rowProps?.data,
        module,
        classList: classes.customDrawer,
      },
      drawer: ThreatDetailsDrawer,
    });
  };

  const timelineGraphData = useMemo(
    () =>
      threatStats?.labels &&
        threatStats?.critical &&
        threatStats?.elevated &&
        threatStats?.parameters &&
        threatStats?.threatCounts
        ? {
          labels: threatStats.labels,
          datasets: [
            {
              key: 'critical',
              label: t('MTD.THREATS.CRITICAL_THREATS'),
              data: threatStats.critical,
              parameters: threatStats.parameters,
              threatCounts: threatStats.threatCounts,
            },
            {
              key: 'elevated',
              label: t('MTD.THREATS.ELEVATED_THREATS'),
              data: threatStats.elevated,
              parameters: threatStats.parameters,
              threatCounts: threatStats.threatCounts,
            },
          ],
        }
        : undefined,
    [threatStats, t]
  );

  const handlePaginationChange = useCallback(
    (update: IQueryParams) => {
      updateUISettings({
        domain: module === 'mtd' ? 'mtdThreats' : 'threats',
        update,
      });

      updateUrl(update);
    },
    [updateUrl, updateUISettings, module]
  );

  const handleFilterChange = useCallback((filterEditors: IFilterEditor[]) => {
    const newFilterValues: IFilterValues = {};
    filterEditors.forEach(({ name, value }) => {
      newFilterValues[name] = value;
    });
    setFilterValues(newFilterValues);
  }, []);

  if (!ready) {
    return null;
  }

  return (
    <>
      <ThreatsAppBar
        module={module}
        query={location.query}
        updateUISettings={updateUISettings}
        updateUrl={updateUrl}
        vectors={vectors}
      />
      <div className={classes.deviceContent}>
        <MUICard>
          <MUICardContent>
            <Tabs
              tabs={[
                {
                  title: t('MTD.THREATS.TIMELINE'),
                  content: (
                    <ThreatsTimelineGraph
                      graphData={timelineGraphData}
                      updateUrl={updateUrl}
                      query={location.query}
                    />
                  ),
                },
                {
                  title: t('MTD.THREATS.UNIQUE_DEVICES'),
                  content: (
                    <UniqueDevicesGraph
                      graphData={uniqueDevicesGraphData}
                      updateUrl={updateUrl}
                      query={location.query}
                    />
                  ),
                },
                {
                  title: t('MTD.THREATS.THREATS_BY_APP'),
                  content: (
                    <ThreatsByAppGraph
                      graphData={threatsByAppGraphData}
                      updateUrl={updateUrl}
                      query={location.query}
                    />
                  ),
                },
              ]}
            />
          </MUICardContent>
        </MUICard>

        <MUICard>
          <SearchBox onInputChange={handleSearch} />
        </MUICard>
        <MUICard>
          <FilterChipBox
            mapping={threatsDataFilterMappings as IFilterMapping[]}
            onDeleteChip={handleDeleteQueryParam}
            queryParams={location.query}
          />
        </MUICard>

        <Table
          columnHeaders={
            module === 'mtd'
              ? currentMtdThreatsTableHeaders
              : currentThreatsTableHeaders
          }
          tableId="threats"
          onFilterValueChange={handleFilterChange as () => void}
          filters={filters}
          serverSort={false}
          classList={classes}
          footerComponents={() => (
            <CloudDownload
              style={{ cursor: 'pointer', fontSize: 30 }}
              onClick={toggleModalDiffered(
                'CsvExport',
                {
                  columnHeaders: createCSVTableHeaders(),
                  download: downloadThreatsCsv,
                  downloadCount: downloadThreatsCsvCount,
                  downloadParams: location.query,
                  filename: 'threats.csv',
                  module: module === 'mtd' && 'ZIPS',
                },
                {
                  title: 'Export to CSV',
                  disableBackdropClick: true,
                }
              )}
            />
          )}
          fetchTableData={() =>
            tableQuery(
              module,
              filters,
              location.query,
              threatsTypesFromApi,
              iPhoneModelMap,
              groups
            )(location.query as unknown as ILocationQuery)
          }
          onColumnChange={handleColumnChange}
          onPaginationChange={handlePaginationChange}
          onRowClick={handleOpenDrawer}
          query={query as unknown as ILocationQuery}
          rowMapping={threatsColumnMapping(t)}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    currentMtdThreatsTableHeaders: getUISettingsTableHeaders(
      state,
      'mtdThreats'
    ),
    currentThreatsTableHeaders: getUISettingsTableHeaders(state, 'threats'),
    threatsUiSettings: getUISettingsWithout(state, 'threats', ['tableHeaders']),
    mtdThreatsUiSettings: getUISettingsWithout(state, 'mtdThreats', [
      'tableHeaders',
    ]),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      threatsTableColumnChange,
      mtdThreatsTableColumnChange,
      updateUISettings,
    },
    dispatch
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withDirtyState()
)(
  withMemoizedProps(Threats, [
    'threatsUiSettings',
    'mtdThreatsUiSettings',
    'dirtyState',
    'q',
    'updateUISettings',
    'updateUrl',
  ])
);
