import _ from 'lodash';

export function cleanUpScopesForSubmit(scopeOptions) {
  const scopeArray = [];
  Object.keys(scopeOptions).forEach(scope => {
    Object.keys(scopeOptions[scope]).forEach(permission => {
      const optionVal = scopeOptions[scope][permission];
      if (optionVal) {
        scopeArray.push(`${scope}:${permission}`);
      }
    });
  });
  return scopeArray;
}

export function swapScopeAndLabel(obj) {
  const swappedScopes = {};
  Object.keys(obj).forEach(key => {
    swappedScopes[obj[key]] = key;
  });
  return swappedScopes;
}

export function formatDefaultScopeOptions(availableScopes) {
  return _.reduce(
    availableScopes,
    (result, value, key) => {
      return {
        ...result,
        [key]: value.reduce(
          (reduceObj, valueToDefault) => ({
            ...reduceObj,
            [valueToDefault]: false,
          }),
          {}
        ),
      };
    },
    {}
  );
}
