import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    devicePoolCard: { maxWidth: 400 },
    pending: {
      fontSize: 48,
      left: '50%',
      opacity: 0.125,
      position: 'absolute',
      top: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
    },
    pendingNonEnglish: {
      fontSize: 26,
    },
  })
);

export default useStyles;
