import { default as MUIDialogActions } from '@material-ui/core/DialogActions';
import { default as MUIDialogContent } from '@material-ui/core/DialogContent';
import { default as MUIDialogTitle } from '@material-ui/core/DialogTitle';
import { Field as FormikField, Form as FormikForm, Formik } from 'formik';
import { IDeviceAlertData, ThreatItem } from './models';
import { Grid, Typography } from '@material-ui/core';
import {
  openSnackBar,
  toggleModalDiffered,
  toggleModalDirect,
} from 'utils/storeUtils';
import { getAlertMessages, updateAlertMessages } from 'api/TRMPolicyService';
import _ from 'lodash';
import Button from 'components/UI/Button';
import GenericError from 'components/UI/GenericErrorBox';
import Languages from 'utility/Languages';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'components/UI/input/Select';
import TextField from 'components/UI/input/TextField';
import ThreatAlertHeader from './ThreatAlertHeader';
import ThreatAlertSkeleton from './ThreatAlertSkeleton';
import useStyles from './useStyles';

interface IFormProps {
  data: Partial<IDeviceAlertData>;
  handleSubmitForm: () => void;
}

const Form: React.FC<IFormProps> = ({ data }) => {
  const classes = useStyles();
  const [language, setLanguage] = useState({
    label: 'English',
    value: 'eng',
  });
  const [alertMessages, setAlertMessages] = useState<ThreatItem[]>([]);
  const [originAlertMessages, setOriginAlertMessages] = useState<ThreatItem[]>(
    []
  ); // used to determine changes of messages
  const [showSaveError, setShowSaveError] = useState<boolean>(false);
  const { t } = useTranslation();

  const getVisibleTotalRow = useMemo(() => {
    const length = alertMessages.filter((item) => item.showItem).length;
    return t(`GLOBAL.ROW${length > 1 ? 'S' : ''}_TOTAL`, {
      dataLength: length,
    });
  }, [alertMessages, t]);

  const { variant, trmPolicyId, policyData, editThreatTypeId } = data;
  const tableName = variant || 'DeviceAlert';

  useEffect(() => {
    const getAlertMessageData = async () => {
      const res = await getAlertMessages({
        id: trmPolicyId,
        language: language.value,
      });

      if (res.data) {
        const newAlertMessages = policyData?.map((item: any) => {
          const { threatTypeId, name } = item;
          const s: ThreatItem = res.data.find(
            (item: ThreatItem) => item.threatTypeId === threatTypeId
          );

          if (s) {
            // has alert message
            s.threatName = name;
            if (editThreatTypeId) {
              // user selected threat on the individual row
              s.showItem = editThreatTypeId === threatTypeId;
            } else {
              // user selected from table actions
              s.showItem = item.selectRow;
            }
            return s;
          }

          // don't have alert message
          const newItem: ThreatItem = {
            threatTypeId,
            threatName: name,
            languageCode: language.value,
            showButton: true,
            description: item.description,
          };

          if (editThreatTypeId) {
            newItem.showItem = editThreatTypeId === threatTypeId;
          } else {
            newItem.showItem = item.selectRow;
          }
          return newItem;
        });
        setAlertMessages(newAlertMessages);
        setOriginAlertMessages(_.cloneDeep(newAlertMessages));
      }
    };

    // init to get new messages based on new language
    setAlertMessages([]);
    setOriginAlertMessages([]);

    getAlertMessageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, language]);

  const checkMessageChanges = () => {
    if (originAlertMessages.length > 0) {
      const diff = _.differenceWith(
        alertMessages,
        originAlertMessages,
        _.isEqual
      );

      if (diff && diff.length > 0) {
        setShowSaveError(true);
        return true;
      }
    }

    setShowSaveError(false);
    return false;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={() => {
        updateAlertMessages(
          { id: trmPolicyId, language: language.value },
          alertMessages
        );
        toggleModalDirect(tableName, false);
        openSnackBar(t('GLOBAL.SUCCESS_UPDATE'));
      }}
      validateOnBlur
    >
      {({ isSubmitting, handleSubmit: handleFormikSubmit }) => (
        <div className={classes.form}>
          <FormikForm>
            <MUIDialogContent>
              <Grid container justifyContent="center">
                <MUIDialogTitle>
                  {t(
                    'MTD.POLICIES.THREAT.THREAT_POLICY_DEVICE_ALERT_MANAGEMENT'
                  )}
                </MUIDialogTitle>
              </Grid>
              <Grid container alignItems="center">
                <Grid className={classes.languageWrapper}>
                  <FormikField
                    component={Select}
                    multiple={false}
                    name="language"
                    options={Languages}
                    label={t('MTD.POLICIES.THREAT.CURRENT_LANGUAGE')}
                    value={language}
                    setFieldValue={(field: string, value: any) => {
                      if (!checkMessageChanges() && !!value) {
                        setLanguage(value);
                      }
                    }}
                    type="select"
                    placeholder={t('MTD.POLICIES.THREAT.SELECT_LANGUAGE')}
                  />
                </Grid>
                {showSaveError && (
                  <GenericError
                    customClass={classes.errorBox}
                    errorMessage={t(
                      'MTD.POLICIES.THREAT.SAVE_CHANGES_BEFORE_SELECT_NEW_LANGUAGE'
                    )}
                    showCloseIcon
                    onClose={() => setShowSaveError(false)}
                  />
                )}
              </Grid>
              {alertMessages.length === 0 ? (
                <ThreatAlertSkeleton />
              ) : (
                <>
                  <ThreatAlertHeader />
                  {/* FieldArray of Formik is not used due to the version mismatch error between react and typescript */}
                  {alertMessages.map((item, index) => {
                    if (item.showItem) {
                      return (
                        <Grid
                          container
                          spacing={1}
                          className={classes.alertItemWrapper}
                          key={index}
                        >
                          <Grid item xs={3}>
                            <Typography className={classes.label}>
                              {item.threatName}
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <TextField
                              name="message"
                              value={item.message}
                              placeholder={item.description}
                              onChange={(e) => {
                                item.message = e.target.value;
                                setAlertMessages([...alertMessages]);
                              }}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <TextField
                              name="buttonText"
                              value={item.buttonText}
                              onChange={(e) => {
                                item.buttonText = e.target.value;
                                setAlertMessages([...alertMessages]);
                              }}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <TextField
                              name="linkText"
                              value={item.linkText}
                              onChange={(e) => {
                                item.linkText = e.target.value;
                                setAlertMessages([...alertMessages]);
                              }}
                            />
                          </Grid>
                        </Grid>
                      );
                    } else {
                      return <></>;
                    }
                  })}
                  <Grid container spacing={1} className={classes.bottomWrapper}>
                    <Typography className={classes.headerLabel}>
                      {getVisibleTotalRow}
                    </Typography>
                  </Grid>
                </>
              )}
              <MUIDialogActions>
                <Button
                  onClick={toggleModalDiffered(tableName, false)}
                  color="secondary"
                  text={t('GLOBAL.CANCEL')}
                />
                <Button
                  onClick={() => handleFormikSubmit()}
                  disabled={isSubmitting}
                  color="primary"
                  text={t('GLOBAL.SAVE')}
                />
              </MUIDialogActions>
            </MUIDialogContent>
          </FormikForm>
        </div>
      )}
    </Formik>
  );
};

export default Form;
