import _ from 'lodash';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withRouter from 'components/hocs/withRouter';

// components
import { Table, TableColumnsContext } from 'components/Tables';
import ZButton from 'UI/Buttons/ZButton';
import { createModalConfig } from 'components/modals/AppsCreateEdit';
import AddIcon from '@material-ui/icons/Add';

// actions services

import { inappsTableColumnChange } from 'actions/AppsActions';

import { fetchAllGroupsByTeamId } from 'api/apis';
import { formGroupListForSelect } from 'api/GroupService';
import { openSnackbar } from 'reducers/SnackbarReducers';

import { getSelectedTeam, updateUISettings } from 'reducers/UiSettingsReducers';

// selectors
import {
  getUISettingsTableHeaders,
  getChangedTableHeadersHash,
  getUISettingsWithout,
} from 'reducers/UiSettingsSelectors';
import { inAppsMapping } from 'mappings/inAppsMappings';
import { toggleModalDiffered, toggleModalDirect } from 'utils/storeUtils';
import { downloadZappsCsv } from 'api/InAppsService';
import ProtectedComponent from '../protected/ProtectedComponent';
import { fetchAppsPage, cleanInAppsTable } from '../../../api/InAppsService';
import GlobalIndicator from '../common/GlobalIndicatorWrapper';
import VersionTable from './VersionTable';
import InAppsTableFooter from './InAppsTableFooter';
import InAppsTableBody from './InAppsTableBody';
import InAppsTableHeaders from './InAppsTableHeader';

class ViewApps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupsHash: {},
      selectedApp: {},
      loaded: false,
    };
    this.handleSelectedRow = this.handleSelectedRow.bind(this);
    this.handleCopyClipBoardSnackbar = this.handleCopyClipBoardSnackbar.bind(
      this
    );
    this.handleOnSort = this.handleOnSort.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  componentDidMount() {
    const { props } = this;
    const initialTeamfilterUrl = this.props.selectedTeamsAppsTable.map(
      team => team.value
    );
    const storedParams = {
      page: 0,
      size: props.inAppsUiSettings.size,
      orderBy: props.inAppsUiSettings.orderBy,
      order: props.inAppsUiSettings.order,
      teamfilter: initialTeamfilterUrl,
    };
    if (!_.isEmpty(storedParams)) {
      props.updateUrl(storedParams);
    }
    // allows the url to be backfilled w/o making table.js fetchTableData calls
    this.setState({ loaded: true });
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    // check length and if the length and the value
    if (
      prevProps.selectedTeamsAppsTable.length !==
      this.props.selectedTeamsAppsTable.length ||
      _.get(prevProps, 'selectedTeamsAppsTable[0].value') !==
      _.get(this.props, 'selectedTeamsAppsTable[0].value')
    ) {
      const changedTeamfilterUrl = this.props.selectedTeamsAppsTable.map(
        team => team.value
      );
      this.props.handleTeamFilterChange(this.props.selectedTeamsAppsTable);
      this.props.updateUrl({ teamfilter: changedTeamfilterUrl });
    }
    if (_.isEmpty(props.q?.page)) {
      const initialTeamfilterUrl = this.props.selectedTeamsAppsTable.map(
        team => team.value
      );
      const storedParams = {
        page: 0,
        size: props.inAppsUiSettings.size,
        orderBy: props.inAppsUiSettings.orderBy,
        order: props.inAppsUiSettings.order,
        teamfilter: initialTeamfilterUrl,
      };
      if (!_.isEmpty(storedParams)) {
        props.updateUrl(storedParams);
      }
      // allows the url to be backfilled w/o making table.js fetchTableData calls
      // this.setState({ loaded: true });
    }
  }

  render() {
    const { classes } = this.props;
    if (!this.state.loaded) {
      return null;
    }
    return (
      <div>
        <h1>App Activations</h1>
        <div className="view__header" style={{ marginBottom: 12 }}>
          <GlobalIndicator isGlobal inline />
          <ProtectedComponent allow={{ zapps: 'manage' }}>
            <ZButton
              action={toggleModalDiffered(
                'AppsCreateEdit',
                { team: this.props?.selctedTeam },
                createModalConfig
              )}
              buttonText="Add New App"
              color="secondary"
              styleName="titleBar"
              icon={{ elem: AddIcon }}
            />
          </ProtectedComponent>
        </div>
        <TableColumnsContext.Provider
          value={{
            columnHeaders: this.props.currentTableHeaders,
            columnHeadersHash: this.props.inAppsColumnHeadersHash,
            rowMapping: inAppsMapping,
            onColumnChange: this.props.inappsTableColumnChange,
            onChangePage: this.onPaginationChange,
            onChangeRows: this.onPaginationChange,
            onSort: this.handleOnSort,
            handleDownload: this.handleDownload,
            rest: {
              groupsHash: { ...this.state.groupsHash },
              selectedHash: { ...this.props.selectedAppState },
              showDeselectButton: this.props.showDeselectButton,
              handleSelectedApp: this.handleSelectedApp,
              handleSelectedRow: this.handleSelectedRow,
              handleCopyClipBoardSnackbar: this.handleCopyClipBoardSnackbar,
              handleClearSelectedApps: this.props.handleClearSelectedApps,
              toggleModalDiffered,
              systemProperties: this.props.systemProperties,
            },
          }}
        >
          <Table
            tableId="InApps"
            classList={classes}
            components={{
              TableHeader: InAppsTableHeaders,
              TableBody: InAppsTableBody,
              TableFooter: InAppsTableFooter,
            }}
            fetchTableData={this.fetchTableData}
          />
        </TableColumnsContext.Provider>

        <h1>Versions</h1>
        <VersionTable selectedApps={this.props.selectedAppState} />
      </div>
    );
  }

  fetchTableData = query => {
    if (query.page && query.order) {
      const fetchGroups = fetchAllGroupsByTeamId(query.teamfilter);
      const fetchApps = fetchAppsPage(query);
      return Promise.all([fetchGroups, fetchApps]).then(([groups, apps]) => {
        const groupsHash = formGroupListForSelect(groups.data);
        const tableData = cleanInAppsTable(apps.data, groupsHash);
        this.setState({ appsLength: tableData.length, groupsHash });
        return {
          tableData: tableData.length ? tableData : [],
          totalResults: apps.data.totalElements,
          isLoading: false,
        };
      });
    }
    return new Promise(() => { });
  };

  onPaginationChange = update => {
    this.props.updateUISettings({
      domain: 'inapps',
      update,
    });
  };

  handleOnSort(property, newOrder) {
    const update = { orderBy: property, order: newOrder };
    this.props.updateUISettings({ domain: 'inapps', update });
  }

  handleSelectedApp = selectedApp => {
    this.setState(
      {
        selectedApp,
      },
      () => {
        toggleModalDirect('AppsCreateEdit', selectedApp);
      }
    );
  };

  // handled by the parent to keep the selected state from erasing when refetching
  handleSelectedRow(selectedAppId) {
    this.props.handleSelectedApps(selectedAppId);
  }

  handleCopyClipBoardSnackbar() {
    this.props.openSnackbar('License key copied to clipboard.');
  }

  handleDownload(filename) {
    return () => {
      const { props } = this;

      const tableHeaders = props.currentTableHeaders
        .filter(column => column.id !== 'actions')
        .filter(tableHeader => {
          return tableHeader.show;
        })
        .map(tableHeader => tableHeader.id);

      downloadZappsCsv(props.location.query, tableHeaders)
        .then(resp => {
          const link = document.createElement('a');
          link.href = resp.data;
          link.setAttribute('download', `${filename}`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(error => console.error(error));
    };
  }
}

const styles = ({ palette, config, typography }) => {
  return {
    titleBar: {
      background: palette.background.paper,
      boxShadow: 'none',
    },

    titleToolBarVersions: {
      paddingLeft: '15px',
      paddingRight: '15px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    flex: {
      flex: 1,
    },
    linkWrapper: {
      textDecoration: 'none',
    },
    buttonGroup: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    // copy button for jwt
    copyButton: {
      padding: 0,
      fontFamily: config.fonts.stackMono,
      fontWeight: 300,
      fontSize: 12,
      // color: tables.copyButton,
      width: '10vw',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'inline-block',
    },
    copyIcon: {
      // color: tables.copyIcon,
      fontSize: '20px',
    },
    copyIconButton: {
      minWidth: 'auto',
      margin: 0,
      padding: 0,
    },
    editIcon: {
      cursor: 'pointer',
    },
    selectedRow: {
      height: 40,
      background: palette.tables.selectedRow,
      backgroundColor: palette.tables.selectedRow,
    },

    footerButtonGroup: {
      display: 'flex',
      paddingLeft: 0,
      paddingTop: 10,
      width: '14vw',
      border: 'none',
    },

    // deselect all button
    clearSelectedApp: {
      textTransform: 'none',
      backgroundColor: palette.tables.selectedRow,
      marginRight: 'auto',
      fontSize: '0.75rem',
      fontWeight: '400',
      fontFamily: typography.fontFamily,
      lineHeight: '1.375em',
    },
    clearSelectedAppWrapper: {
      display: 'flex',
      paddingLeft: 14,
    },
    FilterIcon: {
      fontSize: 18,
      verticalAlign: 'middle',
      cursor: 'pointer',
    },
    multiSelect: {
      width: 200,
    },
  };
};

const mapStateToProps = state => {
  return {
    currentTableHeaders: getUISettingsTableHeaders(state, 'inapps'),
    inAppsColumnHeadersHash: getChangedTableHeadersHash(state, 'inapps'),
    selectedTeamsAppsTable: state.teams.selectedTeamsAppsTable,
    inAppsUiSettings: getUISettingsWithout(state, 'inapps', ['tableHeaders']),
    systemProperties: getUISettingsWithout(state, 'systemProperties', []),
    selectedTeam: getSelectedTeam(state)
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      inappsTableColumnChange,
      updateUISettings,
      openSnackbar,
    },
    dispatch
  );
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ViewApps)
  )
);
