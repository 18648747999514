import React from 'react';
import MuiCheckbox from '@material-ui/core/Checkbox';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';

const CheckBox = ({ label, formLabelProps, ...props }) => {
  const color = props.disabled ? 'secondary' : 'primary';
  if (props.isShowing === false) {
    return null;
  }
  if (label) {
    return (
      <MuiFormControlLabel
        label={label}
        control={<MuiCheckbox color={color} {...props} />}
        {...formLabelProps}
      />
    );
  }

  return <MuiCheckbox color={color} {...props} />;
};

CheckBox.defaultProps = {
  disableRipple: true,
  formLabelProps: {},
};

export default CheckBox;
