import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    buildButtons: {
      float: 'right',
    },
    disabled: {
      opacity: '30%',
    },
    iconButton: {
      background: 'none',
      border: 'none',
      color: 'inherit',
      cursor: 'pointer',
      font: 'inherit',
      margin: '0px',
      outline: 'inherit',
      padding: '0px',
    },
  })
);

export default useStyles;
