import _ from 'lodash';
import { useSelector } from 'react-redux';
import { hoistStatics } from 'recompose';

export function withBackfill_DEPRECATED(statePath) {
  return (WrappedComponent) => {
    const C = ({ rqps = [], q = {}, ...props }) => {
      const stored = useSelector((state) => _.get(state, statePath, {}));
      const missing = _.difference(rqps || [], _.keys(q));

      if (!_.isEmpty(missing) && !_.isEmpty(stored)) {
        props.updateUrl(_.pick(stored, missing));
      }

      return <WrappedComponent {...props} q={q} />;
    };

    C.displayName = `withBackfill(${
      WrappedComponent.displayName || WrappedComponent.name
    })`;
    C.wrappedComponent = WrappedComponent;

    return hoistStatics(C, WrappedComponent);
  };
}
