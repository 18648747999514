export const fetchAvailableEntitlements = () => {
  return new Promise(resolve =>
    resolve([
      // { value: 1, label: 'Detection only after login' },
      // { value: 2, label: "Don't send zBLB query" },
      // { value: 4, label: 'Default TRM consumer' },
      // { value: 8, label: 'Consumer Event' },
      { value: 16, label: "Don't send threat" },
      // { value: 32, label: 'Default TRM privacy' },
      // { value: 64, label: "Don't use Cogito" },
      { value: 128, label: 'Run app scan local only' },
    ])
  );
};
