import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    platform: {
      '& svg': {
        fontSize: 20,
        marginRight: 10,
        minHeight: 20,
        minWidth: 20,
      },
      alignItems: 'center',
      display: 'flex',
    },
    packageIdStyle: { fontSize: 16, color: palette.text.secondary },
    searchBar: {
      marginBottom: 16,
    },
    mainContainer: {
      padding: '15px',
    },
    created: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    risk: {
      '& span': {
        marginRight: 10,
      },
      alignItems: 'center',
      display: 'flex',
      textTransform: 'capitalize',
    },

    appNameHeader: {
      paddingLeft: '6px',
    },
    titleHeader: {
      display: 'flex',
      gap: '10px',
      maxWidth: '40%',
    },
  })
);

export default useStyles;
