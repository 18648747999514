import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    card: {
      '& > div': {
        paddingTop: 5,
        color: palette.common.white,
        backgroundColor: palette.common.marineBlue,
        border: `1px solid ${palette.primary.border}`,
      },
    },
    green: {
      backgroundColor: palette.common.green,
      fontSize: 13,
      padding: '8px 15px',
      textTransform: 'uppercase',

      '&:hover': {
        backgroundColor: palette.common.avocado,
      },
    },
    img: { margin: '0 85px' },
    navigationWarning: {
      alignItems: 'center',
      display: 'flex',

      '& svg': {
        color: palette.common.tomato,
        height: 64,
        margin: '0 24px',
        minHeight: 64,
        minWidth: 64,
        width: 64,
      },
    },
    wrapper: { width: 1024 },
  })
);

export default useStyles;
