import { fetchOperationalStatus } from "api/ZDefendInsights";
import { IDevicePool } from "./models";

export const fetchDevicePool = async (
  teamId?: string | undefined,
  zappId?: string | undefined
): Promise<IDevicePool> => {
  const result: { data: IDevicePool } = await fetchOperationalStatus({
    teamId,
    zappId,
  });

  return result?.data;
};
