import defaultMappings from 'components/RDGTables/default.mappings';

export const fetchUsersParamsMapping = [
  ...defaultMappings,
  {
    path: 'selectedTeamIds',
    paramKey: 'teams',
    multiKey: true,
    transform: ({ selectedTeamIds = [] }) => selectedTeamIds.join(','),
  },
];
