import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    buttonPanel: {
      alignItems: 'flex-start',
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '20px 0',
    },
    checkbox: {
      paddingTop: '8px',
    },
    grid: {
      columnGap: '30px',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '100%',
    },
  })
);

export default useStyles;
