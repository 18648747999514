import MUIEmailIcon from '@material-ui/icons/Email';
import { subscribeToThreatNotifications } from 'api/TRMPolicyService';
import { ISelectItem } from 'components/UI/input/Select';
import React, { useCallback, useEffect, useState } from 'react';
import { openSnackBar } from 'utils/storeUtils';
import { ISelectTRMPolicyTableData } from '../models';
import useStyles from '../useStyles';

interface EmailIconProps {
  rule: ISelectTRMPolicyTableData;
  selectedTRMPolicy: ISelectItem | undefined;
  onNotificationChange: (rule: ISelectTRMPolicyTableData) => void;
}

const SubscribeToNotifications: React.FC<EmailIconProps> = (props) => {
  const { rule, selectedTRMPolicy, onNotificationChange } = props;

  const classes = useStyles();

  const [emailToggle, setEmailToggle] = useState<boolean | undefined>(
    rule.threatNotification?.sendEmail
  );

  useEffect(() => {
    setEmailToggle(rule.threatNotification?.sendEmail);
  }, [rule.threatNotification]);

  const onClick = useCallback(async () => {
    try {
      const payload = {
        sendEmail: emailToggle === undefined ? true : !emailToggle,
        threatTypeIds: [rule.threatTypeId],
      };
      await subscribeToThreatNotifications(
        { trmId: selectedTRMPolicy?.value },
        payload
      );

      const newRule: ISelectTRMPolicyTableData = { ...rule };
      newRule.threatNotification = payload.sendEmail
        ? {
            sendEmail: !emailToggle,
            trmId: selectedTRMPolicy?.value as string,
            threatTypeId: rule.threatTypeId,
            userId: '',
            sendSms: false,
          }
        : undefined;

      onNotificationChange(newRule);
      openSnackBar(
        `Successfully ${!emailToggle ? 'subscribed.' : 'unsubscribed.'}`
      );
    } catch (e) {
      openSnackBar('Error subscribing to threat notification.');
    }
  }, [rule, emailToggle, selectedTRMPolicy?.value, onNotificationChange]);

  return (
    <div className={classes.tableColumn}>
      <MUIEmailIcon
        className={emailToggle ? classes.toggledMailIcon : classes.mailIcon}
        onClick={onClick}
      />
    </div>
  );
};

export default React.memo(SubscribeToNotifications);
