import Form from './Form';
import { handleSubmit } from './utils';
import { IData } from './models';
import { DialogContent as MUIDialogContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface IGroupsCreateEditProps {
  data: IData;
}

const GroupsCreateEdit: React.FC<IGroupsCreateEditProps> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <MUIDialogContent>
      <Form data={data} handleSubmit={(...args) => handleSubmit(...args, t)} />
    </MUIDialogContent>
  );
};

export default GroupsCreateEdit;
