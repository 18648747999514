import { updateUserProfileAccount } from 'actions/ProfileActions';
import { fetchAndSetCurrentUser } from 'actions/UserActions';
import GenericCard from 'components/UI/GenericCard';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getUser } from 'reducers/UserReducers';
import { Dispatch, bindActionCreators } from 'redux';
import { openSnackBar } from 'utils/storeUtils';
import Form from './Form';
import { IUserAccountProfile, TUpdateUserProfileAccount } from './models';
import useStyles from './useStyles';

interface IEditAccountProfileProps {
  account: IUserAccountProfile;
  fetchAndSetCurrentUser: () => void;
  updateUserProfileAccount: TUpdateUserProfileAccount;
}

const EditAccountProfile: React.FC<IEditAccountProfileProps> = ({
  account,
  fetchAndSetCurrentUser,
  updateUserProfileAccount,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [fetchedCurrentUser, setFetchedCurrentUser] = useState<boolean>(false);

  useEffect(() => {
    if (!fetchedCurrentUser && !!fetchAndSetCurrentUser) {
      fetchAndSetCurrentUser();
      setFetchedCurrentUser(true);
    }
  }, [fetchAndSetCurrentUser, fetchedCurrentUser]);

  const handleSubmit = useCallback(
    (values: IUserAccountProfile) => {
      updateUserProfileAccount(
        {
          ...values,
          languagePreference: values.languagePreference.value,
        },
        () => {
          fetchAndSetCurrentUser();
          openSnackBar(
            t('USER_SETTINGS.PERSONAL_INFO.UPDATED', {
              firstName: values.firstName,
              lastName: values.lastName,
            })
          );
        },
        (resp) => {
          console.error('Error when updating user profile: ', resp);
          openSnackBar(`${t('GLOBAL.ERROR')}: ${resp?.data}`);
        }
      );
    },
    [fetchAndSetCurrentUser, t, updateUserProfileAccount]
  );

  return (
    <GenericCard>
      <div className={classes.formWrapper}>
        <Form account={account} onSubmit={handleSubmit} />
      </div>
    </GenericCard>
  );
};

const mapStateToProps = (state: { account: IUserAccountProfile }) => {
  return {
    account: getUser(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<{
    fetchAndSetCurrentUser: () => void;
    updateUserProfileAccount: TUpdateUserProfileAccount;
  }>
) => {
  return bindActionCreators(
    {
      fetchAndSetCurrentUser,
      updateUserProfileAccount,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAccountProfile);
