import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    osRiskCard: {
      maxWidth: 400,
      minWidth: 356,
      '& h2': {
        marginBottom: '20px !important',
      },
    },
    osRiskChart: {
      marginRight: 25,
      '& span': {
        fontSize: 12,
      },
    },
    osRiskChartAndroid: {
      '& .chart-item:nth-of-type(odd)': {
        backgroundColor: palette.charts.brightGreen,
      },
      '& .chart-item:nth-of-type(even)': {
        backgroundColor: palette.charts.darkGreen,
      },
    },
    osRiskChartIos: {
      '& .chart-item:nth-of-type(odd)': {
        backgroundColor: palette.charts.brightBlue,
      },
      '& .chart-item:nth-of-type(even)': {
        backgroundColor: palette.charts.darkBlue,
      },
    },
    osRiskIcon: {
      backgroundColor: palette.charts.icon,
      color: palette.common.white,
      borderRadius: 72,
      height: 72,
      padding: 8,
      width: 72,

      '& > svg': { height: '100%', width: '100%' },
    },
    osRiskIconAndroid: { border: `1px solid ${palette.charts.brightGreen}` },
    osRiskIconIos: { border: `1px solid ${palette.charts.brightBlue}` },
    osRiskRow: {
      flex: 0,
    },
    appleIcon: {
      fill: 'currentColor',
      minHeight: '25px',
      minWidth: '25px',
      stroke: 'currentColor',
    },
  })
);

export default useStyles;
