import AddIcon from '@material-ui/icons/Add';
import { superUserColumnMapping } from 'components/admin/superusers/superUser.mapping';
import withRouter from 'components/hocs/withRouter';
import Button from 'components/UI/Button';
import Table from 'components/UI/Table';
import {
  IColumnHeader,
  IFilterEditor,
  ILocationQuery,
} from 'components/UI/Table/models';
import useStyles from 'console/GlobalHeader/useStyles';
import { SCOPE_BOUNDS } from 'mappings/scopeMapping';
import { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
  superUsersTableColumnChange,
  updateUISettings,
} from 'reducers/UiSettingsReducers';
import {
  getUISettingsTableHeaders,
  getUISettingsWithout,
} from 'reducers/UiSettingsSelectors';
import { bindActionCreators, compose } from 'redux';
import ReactToCSV from 'utility/ReactCSVDownloader';
import { toggleModalDirect } from 'utils/storeUtils';
import useBackfill from 'utils/useBackfill';
import { withDirtyState } from 'utils/withDirtyState';
import withMemoizedProps from 'utils/withMemoizedProps';
import { IFilterValues } from './models';
import { resolveFilters } from './superUser.mapping';
import tableQuery from './tableQuery';
const INITIAL_FILTER_VALUES: IFilterValues = {
  firstName: '',
  lastName: '',
  email: '',
  accountPersonasCount: null,
};
interface ISuperUserProps {
  superUsersTableColumnChange: (...args: unknown[]) => void;
  superUsersUiSettings: { [key: string]: unknown };
  currentTableHeaders: IColumnHeader[];
  definedUser?: string;
  jiggleDirtyState: (...args: any) => void;
  q: { [key: string]: unknown };
  rqps: string[];
  scopes: { [key: string]: string[] };
  updateUISettings: (...args: any) => void;
  updateUrl: (...args: any) => void;
  scopeBounds: string;
}

const SuperUsers: React.FC<ISuperUserProps> = ({
  superUsersTableColumnChange,
  superUsersUiSettings,
  currentTableHeaders,
  definedUser,
  jiggleDirtyState,
  q: query,
  rqps,
  updateUISettings,
  updateUrl,
  scopeBounds,
}) => {
  useBackfill({
    query,
    requiredQueryParams: rqps,
    storedQueryParams: superUsersUiSettings,
    updateUrl: updateUrl,
  });
  const classes = useStyles();
  const [filterValues, setFilterValues] = useState<IFilterValues>(
    INITIAL_FILTER_VALUES
  );
  const filters = useMemo(() => resolveFilters(filterValues), [filterValues]);

  const handleColumnChange = useCallback(
    (...args: any) => {
      jiggleDirtyState();
      superUsersTableColumnChange(...args);
    },
    [jiggleDirtyState, superUsersTableColumnChange]
  );
  const onPaginationChange = useCallback(
    (update: Partial<ILocationQuery>) => {
      updateUISettings({
        domain: 'superUsers',
        update,
      });

      updateUrl(update);
    },
    [updateUISettings, updateUrl]
  );

  const handleFilterChange = useCallback((filterEditors: IFilterEditor[]) => {
    const newFilterValues: IFilterValues = {};
    filterEditors.forEach(({ name, value }) => {
      newFilterValues[name] = value;
    });
    setFilterValues(newFilterValues);
  }, []);

  return (
    <div>
      <div className="view__header">
        <h1>Super Users</h1>
        <Button
          onClick={() => {
            toggleModalDirect(
              'SuperUsersCreateEdit',
              {},
              { title: 'Add Super User', fullWidth: true }
            );
          }}
          text="Add Super User"
          color="secondary"
          icon={AddIcon}
        />
      </div>

      <Table
        classList={classes}
        columnHeaders={
          scopeBounds === SCOPE_BOUNDS.team
            ? currentTableHeaders.filter((column) => column.id !== 'email')
            : currentTableHeaders
        }
        filters={filters}
        onFilterValueChange={handleFilterChange as () => void}
        definedUser={definedUser}
        footerComponents={() => (
          <ReactToCSV
            buttonText="exportCSV"
            dataMap={superUserColumnMapping}
            fileName="super-users-table"
            header={currentTableHeaders.filter(
              (column) => column.id !== 'actions'
            )}
            table={() =>
              tableQuery(filters)(query as unknown as ILocationQuery)
            }
          />
        )}
        fetchTableData={() =>
          tableQuery(filters)(query as unknown as ILocationQuery)
        }
        onColumnChange={handleColumnChange}
        onPaginationChange={onPaginationChange}
        query={query as unknown as ILocationQuery}
        rowMapping={superUserColumnMapping}
        tableId="SuperUsers"
      />
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  currentTableHeaders: getUISettingsTableHeaders(state, 'superUsers'),
  superUsersUiSettings: getUISettingsWithout(state, 'superUsers', [
    'tableHeaders',
  ]),
  createEditModal: state.ui.modals.SuperUsersCreateEdit,
  scopes: state.auth.user.scopes,
  scopeBounds: state.user.scopeBounds,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      superUsersTableColumnChange,
      updateUISettings,
    },
    dispatch
  );

export default compose(
  withRouter,
  withDirtyState(),
  connect(mapStateToProps, mapDispatchToProps)
)(
  withMemoizedProps(SuperUsers, [
    'createEditModal',
    'currentTableHeaders',
    'dirtyState',
    'q',
    'superUsersUiSettings',
    'updateUISettings',
  ])
);
