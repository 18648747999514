export interface IProtectionStatuses {
  [index: string]: IProtectionStatusesMapping | undefined;
  ERRORED: IProtectionStatusesMapping;
  IN_PROGRESS: IProtectionStatusesMapping;
  OUTDATED_ZSHIELD_VERSION: IProtectionStatusesMapping;
  QUEUED: IProtectionStatusesMapping;
  READY: IProtectionStatusesMapping;
  READY_TO_PROCESS: IProtectionStatusesMapping;
  SUCCESS: IProtectionStatusesMapping;
  UPLOADED: IProtectionStatusesMapping;
}

export interface IZShieldAppSorted {
  Unprotected: IZShieldAppCard[];
  "Update Available": IZShieldAppCard[];
  "Protection in Progress": IZShieldAppCard[];
  Protected: IZShieldAppCard[];
  "N/A": IZShieldAppCard[];
}

export interface IZShieldAppCard {
  errorMessage: null | string;
  bundleId: string;
  os: string;
  zshieldVersion: string;
  status: string;
  description: string;
  version: string;
  name: string;
  id: string;
  isLastElement: boolean;
  page: number;
}

interface IProtectionStatusesMapping {
  name: string;
  color: string;
  tooltip: string;
  value: string;
}

export const EPROTECTION_STATUSES_ENUM: IProtectionStatuses = {
  ERRORED: { name: 'Protection error', color: 'red', tooltip: 'There was an unexpected error while protecting your application and we are investigating the problem.', value: 'ERRORED' },
  IN_PROGRESS: { name: 'In Progress', color: 'blue', tooltip: 'The file is currently being protected by the zShield worker.', value: 'IN_PROGRESS' },
  OUTDATED_ZSHIELD_VERSION: { name: 'Protection update available', color: '#f7a10b', tooltip: 'A zShield Protection update is now available.', value: 'OUTDATED_ZSHIELD_VERSION' },
  QUEUED: { name: 'In Progress', color: 'blue', tooltip: 'The file has been transferred to the zShield worker for protection.', value: 'QUEUED' },
  READY: { name: 'Protected', color: '#66FF99', tooltip: 'The app has been protected and is ready for users to download.', value: 'READY' },
  READY_TO_PROCESS: { name: 'In Progress', color: 'blue', tooltip: 'This app is currently being processed', value: 'READY_TO_PROCESS' },
  SUCCESS: { name: 'In Progress', color: 'blue', tooltip: 'The app has been protected and needs to be downloaded.', value: 'SUCCESS' },
  UPLOADED: { name: 'In Progress', color: 'blue', tooltip: 'The file has been uploaded and protection has been initiated', value: 'UPLOADED' },

};

export interface IAppDetailMapping {
  formatFn?: (
    arg: string | number | boolean | undefined
  ) => string | number | boolean | undefined;
  id: number;
  key: string;
  label: string;
}

export interface ITransformParams {
  order: string;
  orderBy: string;
}

export interface IFetchParamsMapping {
  path?: string;
  paramKey?: string;
  transform?: (params: ITransformParams) => void;
}

export enum IzShieldOSType {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
}

export interface ITableQueryFunction {
  (type?: any, update?: any, querySize?: any): any;
}

export interface IDeleteInfo {
  appId?: string;
  appName?: string;
  buildId?: string;
}

export interface IZShieldApp {
  errorMessage: string | null;
  bundleId: string;
  os: string;
  zshieldVersion: string;
  status: string;
  description: string;
  version: string;
  name: string;
  id: string;
  isLastElement: boolean;
  page: number;
}

export interface IZShieldRowData {
  bundleId: string;
  description: string;
  errorMessage: string;
  id: string;
  name: string;
  os: string;
  status: string;
  version: string;
  zshieldVersion: string;
}

export interface ITableQuery {
  teamId: string;
  teamfilter?: string[];
  zappId?: string;
  page: string;
  size: string;
  orderBy: string;
  order: string;
}