/* eslint-disable max-lines-per-function */
import { default as MUIAddIcon } from '@material-ui/icons/Add';
import { default as MUIDeleteIcon } from '@material-ui/icons/Delete';
import { default as MUIDoneIcon } from '@material-ui/icons/Done';
import { default as MUIEditIcon } from '@material-ui/icons/Edit';
import { deleteAppPolicy, updateAppPolicy } from 'api/AppPolicyService';
import { headerActions as headerActionsAtom } from 'atoms/header';
import { getActiveModalAtom } from 'atoms/modals';
import {
  checkedRules as checkedRulesAtom,
  deployDateAtom,
  hasChanges as hasChangesAtom,
  originSelectedRules as originSelectedRulesAtom,
  selectedRules as selectedRulesAtom,
} from 'atoms/policies';
import { selectedTeam as selectedTeamAtom } from 'atoms/teams';
import axios from 'axios';
import { GenericPromptModal_TITLE } from 'components/UI/Modals/GenericPrompt';
import { IGenericPromptModalData } from 'components/UI/Modals/GenericPrompt/models';
import ViewSelector from 'components/UI/ViewSelector';
import AlertIcon from 'components/UI/icons/AlertIcon';
import CopyIcon from 'components/UI/icons/CopyIcon';
import { ISelectItem } from 'components/UI/input/Select';
import withRouter from 'components/hocs/withRouter';
import GlobalIndicatorWrapper from 'components/main/common/GlobalIndicatorWrapper';
import { IQueryParams } from 'components/main/devices/common/models';
import { IOOCSelect } from 'components/main/oocSelect/models';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { isEmptyObj } from 'utils';
import asyncSleep from 'utils/asyncSleep';
import { openSnackBar } from 'utils/storeUtils';
import useAsyncResult from 'utils/useAsyncResult';
import useForceReflow from 'utils/useForceReflow';
import ConfirmDeleteMessage from '../common/ConfirmDeleteMessage';
import CustomPolicyOption from '../common/CustomPolicyOption';
import LastDeployedBox from '../common/LastDeployedBox';
import AddClonePolicy, { AddClonePolicy_TITLE } from './AddClonePolicy';
import { IAppPolicyCreateModalData } from './AddClonePolicy/models';
import { IPolicyRulesResult, TpolicyMap } from './models';
import { fetchPolicies, fetchPolicyRulesById } from './tableQuery';
import useStyles from './useStyles';
import AppCharacteristics from './AppCharacteristics';
import AppProperties from './AppProperties';
import Tabs from 'components/UI/Tabs';
import useAppCharacteristics from './AppCharacteristicProvider';

interface IAppPolicyProps {
  module: string;
  location: {
    query: {
      apps: string;
    };
  };
  mtdModule: string;
  updateUrl: (params: IQueryParams) => void;
}

enum AppPolicyAction {
  ADDCLONE = 'ADDCLONE',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
  REFLOW = 'REFLOW',
}

const activeModalAtom = getActiveModalAtom<
  IAppPolicyCreateModalData | IGenericPromptModalData
>();

const AppPolicy: React.FC<IAppPolicyProps> = (props) => {
  const classes = useStyles();
  const [lastAction, setLastAction] = useState<string | undefined>();
  const [headerActions, setHeaderActions] = useRecoilState(headerActionsAtom);
  const [hasChanges, setHasChanges] = useRecoilState(hasChangesAtom);
  const [selectedRules, setSelectedRules] = useRecoilState(selectedRulesAtom);
  const [originSelectedRules, setOriginSelectedRules] = useRecoilState(
    originSelectedRulesAtom
  );
  const setCheckedRulesInRecoil = useSetRecoilState(checkedRulesAtom);
  const {
    fullTriggerRules: triggerRulesTableData,
    setFullTriggerRules,
    appPolicyTabSetting,
    setAppPolicyDetails,
  } = useAppCharacteristics();
  const selectedTeam = useRecoilValue(selectedTeamAtom);
  const setActiveModal = useSetRecoilState(activeModalAtom);

  const { reflowIndex, forceReflow } = useForceReflow();
  const { t, ready } = useTranslation();

  const policies = useAsyncResult<
    Array<ISelectItem & { created: string }> | undefined
  >(fetchPolicies, selectedTeam === null ? '' : selectedTeam, reflowIndex);

  const [selectedPolicy, setSelectedPolicy] = useState<ISelectItem | undefined>(
    policies?.[0]
  );
  const setDeployDate = useSetRecoilState(deployDateAtom);

  const navigationPrompt = useMemo(
    () =>
      ({
        message = (
          <div className={classes.navigationWarning}>
            {t('MTD.POLICIES.APP_POLICY.POLICY_LEAVE_PAGE_CONFIRMATION', {
              policyName: TpolicyMap['App'],
            })}
            <AlertIcon />
          </div>
        ),
        onCancelCaption = t('MTD.POLICIES.APP_POLICY.LOSE_CHANGES'),
        onConfirmCaption = t('MTD.POLICIES.APP_POLICY.STAY_HERE'),
      }: Partial<IGenericPromptModalData> = {}): Partial<
        Omit<IGenericPromptModalData, 'onCancel' | 'onConfirm'>
      > => ({
        title: t('MTD.POLICIES.APP_POLICY.PENDING_POLICY_UPDATE'),
        message: <span>{message}</span>,
        onCancelCaption,
        onConfirmCaption,
      }),
    [classes, t]
  );

  useEffect(() => {
    if (selectedPolicy === undefined && policies?.[0]) {
      if (props.location?.query?.apps) {
        const preSelectedPolicy = policies.find(
          (p) => p.value === props.location.query.apps
        );
        setSelectedPolicy(preSelectedPolicy);
      } else {
        setSelectedPolicy(
          lastAction === AppPolicyAction.ADDCLONE
            ? policies.sort((a, b) =>
                new Date(a.created) > new Date(b.created) ? -1 : 1
              )[0]
            : policies[0]
        );
      }
      setLastAction(undefined);
      setHasChanges(false);
    }
  }, [
    props.location.query.apps,
    lastAction,
    selectedPolicy,
    setHasChanges,
    setSelectedPolicy,
    policies,
  ]);

  const {
    policyDetails = [],
    triggerRules = [],
    modified,
    groups,
  } = useAsyncResult<IPolicyRulesResult>(
    fetchPolicyRulesById,
    selectedPolicy?.value || null,
    reflowIndex
  ) ?? {};

  setDeployDate(modified);

  useEffect(() => {
    if (!selectedPolicy) {
      return;
    }
    setFullTriggerRules(triggerRules);
    setAppPolicyDetails(selectedPolicy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerRules.toString(), setFullTriggerRules, selectedPolicy]);

  useEffect(() => {
    const newSelectedRules = Object.fromEntries(
      (
        policyDetails?.map(({ name, criteria }) => [
          name,
          criteria?.map(({ id, ...rest }, index) => ({
            ...rest,
            id: !!id ? id : `${index + 4490}`,
          })),
        ]) ?? []
      ).filter((values) => values?.[1]?.length)
    );

    if (Object.keys(newSelectedRules).length) {
      const rules = Object.fromEntries(
        (
          policyDetails?.map(({ name, criteria }) => [
            name,
            criteria?.map(({ id, ...rest }, index) => ({
              ...rest,
              id: !!id ? id : `${index + 4490}`,
            })),
          ]) ?? []
        ).filter((values) => values?.[1]?.length)
      );
      setSelectedRules(rules);
      setOriginSelectedRules(rules);
    } else {
      if (!isEmptyObj(selectedRules)) {
        setSelectedRules({});
      }
      if (!isEmptyObj(originSelectedRules)) {
        setOriginSelectedRules({});
      }
    }
    // do not use selectedRules/originSelectedRules as dependency to avoid infinite loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyDetails, setOriginSelectedRules, setSelectedRules]);

  useEffect(() => {
    setCheckedRulesInRecoil(
      Object.fromEntries(
        (
          policyDetails?.map(({ name, criteria }) => [name, criteria]) ?? []
        ).filter((values) => values?.[1]?.length)
      )
    );
  }, [policyDetails, setCheckedRulesInRecoil]);

  const resetForms = useCallback(() => {
    setActiveModal(undefined);
    setHasChanges(false);
    setSelectedRules({});
    setCheckedRulesInRecoil({});
    setFullTriggerRules([]);
  }, [
    setActiveModal,
    setHasChanges,
    setSelectedRules,
    setCheckedRulesInRecoil,
    setFullTriggerRules,
  ]);

  const deploySavedChanges = useCallback(
    async (changeNameOnly?: string): Promise<boolean> => {
      let result = false;

      const savedChanges = !!changeNameOnly
        ? policyDetails
        : policyDetails.map(({ name, criteria, ...rest }) => ({
            name,
            criteria: (selectedRules?.[name] ?? criteria)?.map((criteria) => {
              const newCriteria: Omit<IOOCSelect, 'id'> & { id?: string } = {
                ...criteria,
              };
              delete newCriteria.id;

              return newCriteria;
            }),
            ...rest,
          }));

      const newTriggerRulesTableData = triggerRulesTableData?.map(
        (triggerRules) => {
          return {
            ...triggerRules,
            id: triggerRules.existingTriggerRule ? triggerRules?.id : undefined,
            existingTriggerRule: undefined,
          };
        }
      );

      try {
        await updateAppPolicy(
          { id: selectedPolicy?.value },
          {
            name: changeNameOnly ?? selectedPolicy?.label,
            rules: savedChanges.map(({ criteria, ...rest }) => ({
              criteria: criteria.map(({ uuid, ...rest }) => {
                const result = { ...rest } as IOOCSelect;

                if (!!uuid) {
                  result.id = uuid;
                }

                return result;
              }),
              ...rest,
            })),
            triggerRules: newTriggerRulesTableData ?? [],
            teamId: selectedPolicy?.team?.id,
          }
        );

        if (!changeNameOnly) {
          setHasChanges(false);
          setLastAction(AppPolicyAction.REFLOW);
          forceReflow();

          setActiveModal({
            active: GenericPromptModal_TITLE,
            payload: {
              disableOnSubmit: true,
              title: t('MTD.POLICIES.APP_POLICY.DEPLOYED_POLICY'),
              message: (
                <div className={classes.policyDeployed}>
                  <div>
                    <MUIDoneIcon />
                  </div>
                  {t('MTD.POLICIES.APP_POLICY.YOUR_CHANGES_SAVED_THIS_POLICY')}
                </div>
              ),
              onCancelHidden: true,
              onConfirm: () => setActiveModal(undefined),
              onConfirmCaption: t('GLOBAL.OK'),
            },
          });
        }

        result = true;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          openSnackBar(
            error?.response?.status === 409 ||
              error?.response?.status === 400 ||
              error?.response?.status === 404
              ? error?.response?.data
              : t('GLOBAL.UNKNOWN_ERROR_TRY_AGAIN')
          );
        }
      }

      return result;
    },
    [
      classes.policyDeployed,
      forceReflow,
      policyDetails,
      selectedPolicy,
      selectedRules,
      setActiveModal,
      setHasChanges,
      t,
      triggerRulesTableData,
    ]
  );

  const handleSettingHeaderActions = useCallback(() => {
    setHeaderActions({
      clearNavigationPreventingChanges: () => {
        setSelectedRules({});
        setCheckedRulesInRecoil({});
        setHasChanges(false);
        setFullTriggerRules([]);
      },
      preventNavigation: true,
      navigationPrompt: navigationPrompt({
        title: t('MTD.POLICIES.APP_POLICY.PENDING_POLICY_UPDATE'),
      }),
      buttons: [
        {
          className: classes.green,
          color: 'inherit',
          disabled: false,
          onClick: () => deploySavedChanges(),
          text: t('MTD.POLICIES.APP_POLICY.DEPLOY_POLICY_CHANGES'),
        },
      ],
    });
  }, [
    setHeaderActions,
    navigationPrompt,
    t,
    classes.green,
    setSelectedRules,
    setCheckedRulesInRecoil,
    setHasChanges,
    setFullTriggerRules,
    deploySavedChanges,
  ]);

  useEffect(() => {
    if (hasChanges && !headerActions) {
      handleSettingHeaderActions();
    }

    return () => {
      if (headerActions !== undefined) {
        setHeaderActions(undefined);
      }
    };
  }, [handleSettingHeaderActions, hasChanges, headerActions, setHeaderActions]);

  useEffect(
    () => () => {
      setCheckedRulesInRecoil({});
      setHasChanges(false);
      setSelectedRules({});
      setFullTriggerRules([]);
    },
    [
      setCheckedRulesInRecoil,
      setHasChanges,
      setSelectedRules,
      setFullTriggerRules,
    ]
  );

  const handleViewSelectorChange = useCallback(
    (name: string, value: ISelectItem | undefined) => {
      const changeSelectedPolicy = () => {
        setSelectedPolicy(value);
        resetForms();
      };

      if (hasChanges) {
        setActiveModal({
          active: GenericPromptModal_TITLE,
          payload: {
            ...navigationPrompt(),
            onCancel: () => {
              changeSelectedPolicy();
            },
            onConfirm: () => {
              setActiveModal(undefined);
            },
          },
        });
      } else {
        changeSelectedPolicy();
      }
    },
    [hasChanges, navigationPrompt, resetForms, setActiveModal]
  );

  const handleEditNameClick = useCallback(() => {
    const editPolicyName = () => {
      if (hasChanges) {
        resetForms();
        forceReflow();
      }

      setActiveModal({
        active: GenericPromptModal_TITLE,
        payload: {
          disableOnSubmit: true,
          title: t('MTD.POLICIES.APP_POLICY.EDIT_POLICY_NAME'),
          question: {
            label: t('MTD.POLICIES.APP_POLICY.POLICY_NAME'),
            defaultAnswer: selectedPolicy?.label,
          },
          onCancel: () => {
            setActiveModal(undefined);
          },
          onConfirm: async (answer) => {
            if (await deploySavedChanges(answer)) {
              const newSelectedPolicy = {
                ...selectedPolicy,
                label: answer,
              } as ISelectItem;

              setHasChanges(false);
              setSelectedPolicy(undefined);
              setLastAction(AppPolicyAction.REFLOW);
              forceReflow();
              setSelectedPolicy(newSelectedPolicy);
              setActiveModal(undefined);
            }
          },
        },
      });
    };

    if (hasChanges) {
      setActiveModal({
        active: GenericPromptModal_TITLE,
        payload: {
          ...navigationPrompt({
            onCancelCaption: t('GLOBAL.CONTINUE'),
            onConfirmCaption: t('GLOBAL.CANCEL'),
          }),
          onCancel: () => {
            editPolicyName();
          },
          onConfirm: () => {
            setActiveModal(undefined);
          },
        },
      });
    } else {
      editPolicyName();
    }
  }, [
    deploySavedChanges,
    forceReflow,
    hasChanges,
    navigationPrompt,
    resetForms,
    selectedPolicy,
    setActiveModal,
    setHasChanges,
    t,
  ]);

  const handleAddCloneClick = useCallback(
    (cloningPolicy?: string) => {
      const addClonePolicy = () => {
        if (hasChanges) {
          resetForms();
          forceReflow();
        }

        setActiveModal({
          active: AddClonePolicy_TITLE,
          payload: {
            policyType: 'App',
            cloningPolicy,
            defaultName: cloningPolicy
              ? selectedPolicy?.label + ' (cloned)'
              : '',
            onCreateSuccess: () => {
              setLastAction(AppPolicyAction.ADDCLONE);
              forceReflow();
              setSelectedPolicy(undefined);
            },
          },
        });
      };

      if (hasChanges) {
        setActiveModal({
          active: GenericPromptModal_TITLE,
          payload: {
            ...navigationPrompt(),
            onCancel: () => {
              addClonePolicy();
            },
            onConfirm: () => {
              setActiveModal(undefined);
            },
          },
        });
      } else {
        addClonePolicy();
      }
    },
    [
      hasChanges,
      forceReflow,
      selectedPolicy,
      navigationPrompt,
      resetForms,
      setActiveModal,
    ]
  );

  const handleDeleteClick = useCallback(
    (policyId: string) => {
      const deletePolicy = () => {
        if (hasChanges) {
          resetForms();
          forceReflow();
        }

        setActiveModal({
          active: GenericPromptModal_TITLE,
          payload: {
            title: t('MTD.POLICIES.APP_POLICY.DELETE_POLICY'),
            message: <ConfirmDeleteMessage groups={groups} policyType="App" />,
            onConfirmDisabled: groups && groups.length > 0,
            onCancel: () => {
              setActiveModal(undefined);
            },
            onConfirm: async () => {
              await deleteAppPolicy({ id: policyId });
              await asyncSleep(800);
              forceReflow();
              setSelectedPolicy(undefined);
              setLastAction(AppPolicyAction.DELETE);
              setActiveModal(undefined);
            },
          },
        });
      };

      if (hasChanges) {
        setActiveModal({
          active: GenericPromptModal_TITLE,
          payload: {
            ...navigationPrompt(),
            onCancel: () => {
              deletePolicy();
            },
            onConfirm: () => {
              setActiveModal(undefined);
            },
          },
        });
      } else {
        deletePolicy();
      }
    },
    [
      hasChanges,
      setActiveModal,
      t,
      groups,
      resetForms,
      forceReflow,
      navigationPrompt,
    ]
  );

  const getTabs = useMemo(
    () => [
      {
        title: 'App Properties',
        content: (
          <AppProperties
            module={props.module}
            policies={policies}
            policyDetails={policyDetails}
            selectedPolicy={selectedPolicy}
            selectedRules={selectedRules}
            updateUrl={props.updateUrl}
          />
        ),
      },
      ...(props.module === 'mtd'
        ? [
            {
              title: 'App Characteristics',
              content: <AppCharacteristics setHasChanges={setHasChanges} />,
            },
          ]
        : []),
    ],
    [
      policies,
      policyDetails,
      props.module,
      props.updateUrl,
      selectedPolicy,
      selectedRules,
      setHasChanges,
    ]
  );

  if (!ready) {
    return null;
  }

  if (!policies || (policies.length > 0 && !selectedPolicy)) {
    return null;
  }

  return (
    <>
      <div>
        <div>
          <GlobalIndicatorWrapper isGlobal inline />
          <ViewSelector
            interactable
            value={selectedPolicy}
            label={t('MTD.POLICIES.APP_POLICY.SELECT_POLICY')}
            options={policies ?? []}
            placeholder={t('MTD.POLICIES.APP_POLICY.NO_POLICY_SELECTED')}
            setFieldValue={handleViewSelectorChange}
            customOption={CustomPolicyOption as React.FC}
            deployDateComp={LastDeployedBox as React.FC}
            actions={[
              {
                disabled: !selectedPolicy?.value,
                icon: MUIEditIcon,
                onClick: () => handleEditNameClick(),
              },
              {
                disabled: !selectedPolicy?.value,
                icon: CopyIcon,
                onClick: () =>
                  handleAddCloneClick(selectedPolicy?.value as string),
              },
              {
                disabled: !selectedPolicy?.value,
                icon: MUIDeleteIcon,
                onClick: () =>
                  handleDeleteClick(selectedPolicy?.value as string),
              },
              {
                icon: MUIAddIcon,
                onClick: () => handleAddCloneClick(),
              },
            ]}
          />
        </div>
        <Tabs tabs={getTabs} value={appPolicyTabSetting} />
      </div>
      <AddClonePolicy />
    </>
  );
};

export default withRouter(AppPolicy);
