import * as React from 'react';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import useStyles from './useStyles';
import { useTranslation } from 'react-i18next';

const HorizontalLinearStepper = ({
  status,
  labelId,
}: {
  status: number;
  labelId?: any;
}) => {
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const steps = [
    'MTD.INTEGRATIONS.CHOOSE_EMM',
    'GLOBAL.SETTINGS',
    `${
      labelId === 'MTD.INTEGRATIONS.GoogleAdminConnector'
        ? 'MTD.INTEGRATIONS.POLICY_ORGANIZATION_UNITS'
        : 'MTD.INTEGRATIONS.POLICY_AND_RESPONSE_GROUPS'
    }`,
  ];

  if (!ready) {
    return null;
  }

  return (
    <Stepper className={classes.stepperConnector} activeStep={status}>
      {steps.map((label) => {
        const stepProps: { completed?: boolean } = {};
        const labelProps: {
          optional?: React.ReactNode;
        } = {};
        return (
          <Step
            className={classes.stepperIconLabelColor}
            key={label}
            {...stepProps}
          >
            <StepLabel {...labelProps}>{t(label)}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};
export default HorizontalLinearStepper;
