import {
  csvInjectionErrorMessage,
  csvInjectionRegex,
} from 'utils/componentUtils';
import * as Yup from 'yup';

const Schema = () => {
  const fields = {
    group: Yup.mixed().required('Group is required.'),
    team: Yup.mixed().required('Team is required.'),
    description: Yup.string()
      .min(3)
      .max(255)
      .matches(csvInjectionRegex, csvInjectionErrorMessage),
    protectionCoverages: Yup.array()
      .required('You must select at least one coverage option.')
      .min(1),
  };

  return Yup.object().shape({ ...fields });
};
export default Schema;
