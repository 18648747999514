import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import Button from 'components/UI/Button';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModalDiffered, toggleModalDirect } from 'utils/storeUtils';

const UploadAppButton: React.FC = () => {
  const dispatch = useDispatch();
  const showUploadReminder = useSelector(
    (state: any) =>
      state?.uiSettings?.reminderSettings.showUploadReminder ?? true
  );

  const hideUploadReminder = useCallback(() => {
    dispatch({ type: 'HIDE_UPLOAD_REMINDER' });
  }, [dispatch]);

  const handleUpload = useCallback(() => {
    if (showUploadReminder) {
      return toggleModalDirect(
        'GenericReminder',
        {
          confirmButtonText: 'Upload',
          doNotRemindText: "Don't remind me again when uploading app",
          headerText: 'Remember to Sign',
          messageText:
            'Remember that you will have to re-sign your app after Zimperium has finished securing it.',
          onCancel: () => toggleModalDirect('ZShieldBuildDeleteConfirm', false),
          onConfirm: toggleModalDiffered(
            'ZShieldUploadApp',
            {},
            { fullWidth: true }
          ),
          onDisableReminder: () => hideUploadReminder(),
        },
        {}
      );
    }
    return toggleModalDiffered(
      'ZShieldUploadApp',
      {},
      { fullWidth: true }
    )();
  }, [hideUploadReminder, showUploadReminder]);

  return (
    <ProtectedComponent allow={{ app_protections: 'manage', zdev_builds: 'upload' }}>
      <Button
        color="secondary"
        onClick={handleUpload}
        text="Upload New App"
      />
    </ProtectedComponent>
  );
};

export default UploadAppButton;