import { fetchApiKeysPage } from 'api/AuthorizationService';
import { memoizeTableQuery } from 'components/RDGTables/utils';
import _ from 'lodash';

export default memoizeTableQuery(async query => {
  // Fetch Data
  const rawApiKeysTableData = await fetchApiKeysPage(query);

  return {
    data: _.get(rawApiKeysTableData, 'data.content', []),
    count: _.get(rawApiKeysTableData, 'data.totalElements', 0),
  };
});
