import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Color from 'color';

const styles = themeSelected => {
  const { palette } = themeSelected;
  return {
    root: {
      maxHeight: 38,
      padding: '11.2px 0 11.2px 4px',
      position: 'relative',
      backgroundColor: palette.background.default,
      border: `1px solid ${palette.primary.border}`,
      textAlign: 'left',
      textTransform: 'none',
      lineHeight: 1,
      fontWeight: 400,

      '&:hover': {
        background: Color(palette.background.default)
          .darken(0.1)
          .string(),
      },
    },
    label: {
      display: 'block',
      paddingRight: 40,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  };
};
export function MultiSelectButton(props) {
  return (
    <Button
      fullWidth
      onClick={props.toggleOpen}
      classes={{
        root: `${props.className} ${props.classes.root}`,
        label: `${props.classes.label} button-label`,
      }}
    >
      {props.children}
    </Button>
  );
}
export default withStyles(styles)(MultiSelectButton);
