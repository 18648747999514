import { trimAndAddAsterisk } from 'utility/QueryUtility';

export const fetchFindingsStatsParamsMapping = [
  {
    path: 'search',
    transform: ({ search = '' }) => {
      if (!search) {
        return null;
      }

      return trimAndAddAsterisk(search);
    },
  },
  {
    path: 'id',
  },
  {
    path: 'accountId',
  },
  {
    path: 'appId',
    paramKey: 'appId.keyword',
    transform: ({ appId }) => {
      if (appId === 'All') {
        return null;
      }
      return appId;
    },
  },
  {
    path: 'appName',
  },
  {
    path: 'findingId',
  },
  {
    path: 'subcategoryId',
  },
  {
    path: 'categoryId',
  },
  {
    path: 'name',
  },
  {
    path: 'subcategoryName',
  },
  {
    path: 'categoryName',
  },
  {
    path: 'lastSightedVersion',
  },
  {
    path: 'lastSightedBuild',
  },
  {
    path: 'description',
  },
  {
    path: 'label',
  },
  {
    path: 'security',
  },
  {
    path: 'privacy',
  },
  {
    path: 'platform',
  },
  {
    path: 'locationCount',
  },
  {
    path: 'accepted',
  },
  {
    path: 'severity',
    paramKey: 'severity',
  },
  {
    path: 'created',
    paramKey: 'created',
  },
  {
    path: 'modified',
  },
  {
    path: 'issue.issueStatus',
    paramKey: 'issue.issueStatus.keyword',
  },
];
export const fetchFindingsParamsMapping = [
  {
    path: 'page',
  },
  {
    path: 'size',
  },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      switch (orderBy) {
        case 'appName':
        case 'name':
          return `${orderBy}.sort,${order}`;
        case 'ticketed':
        case 'lastSightedBuild':
        case 'lastSightedVersion':
        case 'accepted':
        case 'description':
        case 'issue.issueStatus':
        case 'severity':
          return `${orderBy}.keyword,${order}`;
        case 'created':
        case 'issue.issueId':
          return `created,${order}`;
        case 'modified':
          return `${orderBy},${order}`;
        default:
          if (orderBy && order) {
            return `${orderBy},${order}`;
          }
          return null;
      }
    },
  },
  {
    path: 'search',
    transform: ({ search = '' }) => {
      if (!search) {
        return null;
      }

      return trimAndAddAsterisk(search);
    },
  },
  {
    path: 'id',
  },
  {
    path: 'accountId',
  },
  {
    path: 'appId',
    paramKey: 'appId.keyword',
    transform: ({ appId }) => {
      if (appId === 'All') {
        return null;
      }
      return appId;
    },
  },
  {
    path: 'appName',
  },
  {
    path: 'findingId',
  },
  {
    path: 'subcategoryId',
  },
  {
    path: 'categoryId',
  },
  {
    path: 'name',
  },
  {
    path: 'subcategoryName',
  },
  {
    path: 'categoryName',
  },
  {
    path: 'lastSightedVersion',
  },
  {
    path: 'lastSightedBuild',
  },
  {
    path: 'description',
  },
  {
    path: 'label',
  },
  {
    path: 'security',
  },
  {
    path: 'privacy',
  },
  {
    path: 'platform',
  },
  {
    path: 'locationCount',
  },
  {
    path: 'accepted',
  },
  {
    path: 'severity',
  },
  {
    path: 'created',
  },
  {
    path: 'modified',
  },
  {
    path: 'issue.issueStatus',
    paramKey: 'issue.issueStatus.keyword',
  },
  {
    path: 'issue.externalIssueId',
    paramKey: 'issue.externalIssueId.keyword',
  },
  {
    path: 'accepted',
  },
];
