import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    card: {
      padding: 1,
    },

    cardContent: {
      position: 'relative',
      overflow: 'hidden',
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
      '& .inovua-react-toolkit-menu.inovua-react-toolkit-menu--theme-default-light.inovua-react-toolkit-menu--ltr.inovua-react-toolkit-menu--depth-0.inovua-react-toolkit-menu--has-overflow.inovua-react-toolkit-menu--shadow.inovua-react-toolkit-menu--has-scroll':
        {
          zIndex: 110000,
          position: 'absolute',
          top: '40px !important',
          right: '10px !important',
          left: 'auto !important',
          transform: 'none !important',
        },
    },

    contextMenuItem: {
      paddingRight: 9,
    },

    footer: {
      position: 'absolute',
      bottom: 8,
      left: 16,
    },
    table: {
      '& .InovuaReactDataGrid__column-header__filter': {
        backgroundColor: 'transparent',
        color: palette.text.secondary,
      },
      '& .InovuaReactDataGrid__column-header__filter--number > .inovua-react-toolkit-numeric-input__input':
        {
          backgroundColor: 'transparent',
          color: palette.text.secondary,
        },
      '& .InovuaReactDataGrid__column-header__filter--date  > .inovua-react-toolkit-date-input__input ':
        {
          backgroundColor: 'transparent',
          color: palette.text.secondary,
        },
      '&  .InovuaReactDataGrid__column-header__filter--select': {
        color: palette.text.highlighter,
      },
      '& .inovua-react-toolkit-flex.inovua-react-toolkit-menu__scroll-container.inovua-react-toolkit-arrow-scroller.inovua-react-toolkit-arrow-scroller--direction-vertical.inovua-react-toolkit-arrow-scroller--native-scroll.inovua-react-toolkit-arrow-scroller--theme-default-light.inovua-react-toolkit-flex-v3--align-items-start.inovua-react-toolkit-flex-v3--wrap.inovua-react-toolkit-flex-v3--row.inovua-react-toolkit-flex-v3--display-flex':
        {
          maxHeight: '50vh',
        },
    },
    tableCell: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  })
);

export default useStyles;
