import cc from 'classcat';
import GenericCard from 'components/UI/GenericCard';
import AndroidIcon from 'components/UI/icons/AndroidIcon';
import AppleIcon from 'components/UI/icons/AppleIcon';
import RadioGroup, { Radio } from 'components/UI/input/RadioGroup';
import TextField from 'components/UI/input/TextField';
import {
  Field as FormikField,
  Form as FormikForm,
  useFormikContext,
} from 'formik';
import React, { useCallback, useMemo } from 'react';
import { ESupportEmailAddressType, IPartnerBranding } from '../../models';
import useStyles from './useStyles';

interface IEmailFormProps {
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean | undefined
  ) => void;
  values: IPartnerBranding;
}

const EmailForm: React.FC<IEmailFormProps> = ({ setFieldValue, values }) => {
  const classes = useStyles();
  const { isSubmitting } = useFormikContext();

  const setSupportEmailAddress = useCallback(
    (newValue: string) => {
      setFieldValue('supportEmailAddress', newValue);
    },
    [setFieldValue]
  );

  const clearSupportEmailAddress = useCallback(() => {
    setSupportEmailAddress('');
  }, [setSupportEmailAddress]);

  const fillDefaultSupportEmailAddress = useCallback(() => {
    setSupportEmailAddress('support@zimperium.com');
  }, [setSupportEmailAddress]);

  const supportEmailDisabled = useMemo(
    () =>
      isSubmitting ||
      values?.supportEmailAddressType !== ESupportEmailAddressType.CUSTOM,
    [isSubmitting, values?.supportEmailAddressType]
  );

  return (
    <FormikForm>
      <GenericCard className={classes.card}>
        <div className={classes.horizontal}>
          <div>
            <FormikField
              autoComplete="off"
              component={TextField}
              disabled={isSubmitting}
              label="Mobile App Name"
              name="mobileAppName"
            />
            <FormikField
              autoComplete="off"
              component={TextField}
              disabled={isSubmitting}
              label="Terms & Conditions Link"
              name="termsAndConditionsLink"
            />
            <FormikField
              autoComplete="off"
              component={TextField}
              disabled={isSubmitting}
              label="Privacy Policy Link"
              name="privacyPolicyLink"
            />
            <FormikField
              autoComplete="off"
              component={TextField}
              disabled={isSubmitting}
              label="Documentation Link"
              name="documentationLink"
            />
          </div>
          <div>
            <FormikField
              autoComplete="off"
              component={TextField}
              disabled={isSubmitting}
              label="Reply-To Email Address"
              name="replyToEmailAddress"
            />
            <FormikField
              autoComplete="off"
              component={TextField}
              disabled={isSubmitting}
              label="From Email Address"
              name="fromEmailAddress"
            />
            <div className={classes.radios}>
              <RadioGroup
                label="Support Email Address"
                defaultValue={
                  values?.supportEmailAddressType as ESupportEmailAddressType
                }
              >
                <FormikField
                  component={Radio}
                  disabled={isSubmitting}
                  label={'None'}
                  name="supportEmailAddressType"
                  size="small"
                  type="radio"
                  value={ESupportEmailAddressType.NONE}
                  onClick={clearSupportEmailAddress}
                />
                <FormikField
                  component={Radio}
                  disabled={isSubmitting}
                  label={'Default - support@zimperium.com'}
                  name="supportEmailAddressType"
                  size="small"
                  type="radio"
                  value={ESupportEmailAddressType.DEFAULT}
                  onClick={fillDefaultSupportEmailAddress}
                />
                <FormikField
                  component={Radio}
                  disabled={isSubmitting}
                  label={'Custom'}
                  name="supportEmailAddressType"
                  size="small"
                  type="radio"
                  value={ESupportEmailAddressType.CUSTOM}
                />
              </RadioGroup>
            </div>
            <FormikField
              autoComplete="off"
              component={TextField}
              disabled={supportEmailDisabled}
              name="supportEmailAddress"
            />
          </div>
        </div>
      </GenericCard>
      <GenericCard className={classes.card}>
        <h2>App Store Links</h2>
        <div className={classes.horizontal}>
          <span
            className={cc([classes.appLinkIcon, classes.appLinkIconAndroid])}
          >
            <AndroidIcon />
          </span>
          <div>
            <FormikField
              autoComplete="off"
              component={TextField}
              disabled={isSubmitting}
              name="appStoreAndroidLink"
            />
          </div>
          <span className={cc([classes.appLinkIcon, classes.appLinkIconIos])}>
            <AppleIcon />
          </span>
          <div>
            <FormikField
              autoComplete="off"
              component={TextField}
              disabled={isSubmitting}
              name="appStoreIosLink"
            />
          </div>
        </div>
      </GenericCard>
    </FormikForm>
  );
};

export default EmailForm;
