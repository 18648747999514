import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';
import { simpleStateMerge } from 'utils/reducerUtils';

// action types
import AT from 'actions/ActionTypes';

// utils
import { publishEvent } from 'utils/eventUtils';

export const AUTH_SET_AUTHENTICATION = 'AUTH_SET_AUTHENTICATION';
export const AUTH_CLEAR_USER = 'AUTH_CLEAR_USER';
export const ON_ADMIN_EXIT_TENANT = 'ON_ADMIN_EXIT_TENANT';
export const ON_PARTNER_EXIT_TENANT = 'ON_PARTNER_EXIT_TENANT';
export const CLEAR_USER_SCOPES = 'CLEAR_USER_SCOPES';
export const CLEAR_PARTNER_USER = 'CLEAR_PARTNER_USER';
export const CLEAR_TFA_SETUP = 'CLEAR_TFA_SETUP';
export const SET_BRANDING_PROPERTIES = 'SET_BRANDING_PROPERTIES';
export const SET_SSO_PROPERTIES = 'SET_SSO_PROPERTIES';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

// actions
export const setAuthentication = createAction(AUTH_SET_AUTHENTICATION);
export const clearAuthUser = createAction(AUTH_CLEAR_USER);
export const clearUserScopes = createAction(CLEAR_USER_SCOPES);
export const clearTfaSetup = createAction(CLEAR_TFA_SETUP);
export const clearPartnerUser = createAction(CLEAR_PARTNER_USER);
export const setBrandingProperties = createAction(SET_BRANDING_PROPERTIES);
export const setSSOProperties = createAction(SET_SSO_PROPERTIES);
export const refreshTokenAction = createAction(REFRESH_TOKEN);

// helper to create a default dashboard redirect route on a logged in user entering the domain w/o routes . i.e. zdefend.zimperium.com

function setDashboardHelper(stateCopy) {
  let dashboard = '/console';

  if (stateCopy.user.tfaRequired) {
    dashboard = '/console/2fa';
    return;
  }
  if (_.isEmpty(stateCopy.user.modules)) {
    return dashboard;
  }

  if (stateCopy.user.modules.ZIAP === true) {
    dashboard = '/console/zdefend';
  } else if (stateCopy.user.modules.ZDEV === true) {
    dashboard = '/console/zscan';
  }
  return dashboard;
}

// created on an admin logout (back button to admin) to get rid of teams in dashboard
// all else is the same with clearing an auth user
export const onAdminExitTenant = () => {
  return dispatch => {
    dispatch(clearAuthUser());
    dispatch({ type: ON_ADMIN_EXIT_TENANT });
  };
};

export const onPartnerExitTenant = () => {
  return dispatch => {
    dispatch(clearAuthUser());
    dispatch({ type: ON_PARTNER_EXIT_TENANT });
  };
};
// reducers
const initialState = {
  user: {
    isAuthenticated: false,
    scopes: {},
    accessToken: null,
    refreshToken: null,
    modules: {},
    // partner: false,
  },
  superUser: {
    isAuthenticated: false,
    scopes: {},
    accessToken: null,
    refreshToken: null,
  },
  partnerUser: {
    isAuthenticated: false,
    scopes: {},
    accessToken: null,
    refreshToken: null,
    accountId: null,
  },
  sso: {},
};

const AuthReducer = handleActions(
  {
    AUTH_SET_AUTHENTICATION: (state, action) => {
      const stateCopy = {
        ...state,
        user: {
          ...state.user,
          accessToken: null,
          isAuthenticated: false,
        },
        dashboardRoute: setDashboardHelper(state),
      };
      if (action.user) {
        stateCopy.user.modules = {};
      }
      return action.error ? state : simpleStateMerge(stateCopy, action);
    },

    REFRESH_TOKEN: (state, action) => {
      const stateCopy = {
        ...state,
        dashboardRoute: setDashboardHelper(state),
      };
      if (action.user) {
        stateCopy.user.modules = {};
      }
      return action.error ? state : simpleStateMerge(stateCopy, action);
    },

    CLEAR_USER_SCOPES: state => {
      return {
        ...state,
        user: {
          ...state.user,
          tfaRequired: false,
          scopes: {},
        },
      };
    },
    CLEAR_TFA_SETUP: state => {
      return {
        ...state,
        user: {
          ...state.user,
          otpAuthUrl: undefined,
        },
      };
    },

    CLEAR_PARTNER_USER: state => {
      publishEvent('auth:remove-token', {
        refreshToken: state.partnerUser.refreshToken,
      });

      return {
        ...state,
        partnerUser: initialState.partnerUser,
        //  branding: initialState.branding,
      };
    },

    AUTH_CLEAR_USER: state => {
      publishEvent('auth:remove-token', {
        refreshToken: state.user.refreshToken,
      });

      return {
        ...state,
        user: initialState.user,
        // branding: initialState.branding,
      };
    },

    SET_BRANDING_PROPERTIES: (state, action) => {
      return {
        ...state,
        branding: action.payload,
      };
    },

    SET_SSO_PROPERTIES: (state, action) => {
      return {
        ...state,
        sso: action.payload,
      };
    },

    [AT.USER_LOGOUT]: state => {
      return { branding: { ...state.branding }, ...initialState };
    },
  },
  initialState
);

export default AuthReducer;

const brandingScope = 'branding_policy';

export const selectIsBrandingSubscribed = (state) => 
  Object.keys(state.auth.user.scopes).includes(brandingScope);

export const selectCanManageBranding = (state) => 
  state.auth.user.scopes[brandingScope].includes('manage');
