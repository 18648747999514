import { IGenericColumnHeader } from 'components/UI/GenericTable/models';
import { TFunction } from 'react-i18next';

export const mtdTRMPolicyTableColumnHeaders = (t?: TFunction<'translation', undefined>): IGenericColumnHeader[] => [
  {
    id: 'enabled',
    label: t ? t('GLOBAL.ENABLED') : 'Enabled',
  },
  {
    id: 'type',
    label: t ? t('GLOBAL.TYPE') : 'Type',
  },
  {
    id: 'severity',
    label: t ? t('GLOBAL.SEVERITY') : 'Severity',
  },
  {
    id: 'name',
    label: t ? t('GLOBAL.THREAT') : 'Threat',
    sort: true,
  },
  {
    id: 'platform',
    label: t ? t('GLOBAL.PLATFORM') : 'Platform',
    sort: true,
  },
  {
    id: 'vector',
    label: t ? t('GLOBAL.VECTOR') : 'Vector',
    sort: true,
  },
  {
    id: 'alertUser',
    label: t ? t('GLOBAL.ALERT_USER') : 'Alert User',
  },
  {
    id: 'deviceActions',
    label: t ? t('GLOBAL.DEVICE_ACTIONS') : 'Device Actions',
  },
  {
    id: 'notifications',
    label: t ? t('GLOBAL.NOTIFICATIONS') : 'Notifications',
  },
];

export const mdmColumnHeaders = (t: TFunction<'translation', undefined>): IGenericColumnHeader[] => [
  {
    id: 'mdmActions',
    label: t('GLOBAL.THREAT_ACTIONS'),
  },
  {
    id: 'mdmMitigations',
    label: t('GLOBAL.MITIGATION_ACTIONS'),
  },
];

export const trmPolicyTableColumnHeaders = (t?: TFunction<'translation', undefined>): IGenericColumnHeader[] => [
  {
    id: 'enabled',
    label: t ? t('GLOBAL.ENABLED') : 'Enabled',
  },
  {
    id: 'type',
    label: t ? t('GLOBAL.TYPE') : 'Type',
  },
  {
    id: 'severity',
    label: t ? t('GLOBAL.SEVERITY') : 'Severity',
  },
  {
    id: 'name',
    label: t ? t('GLOBAL.THREAT') : 'Threat',
    sort: true,
  },
  {
    id: 'alertUser',
    label: t ? t('GLOBAL.ALERT_USER') : 'Alert User',
  },
  {
    id: 'vector',
    label: t ? t('GLOBAL.VECTOR') : 'Vector',
    sort: true,
  },
  {
    id: 'platform',
    label: t ? t('GLOBAL.PLATFORM') : 'Platform',
    sort: true,
  },
  {
    id: 'deviceActions',
    label: t ? t('GLOBAL.DEVICE_ACTIONS') : 'Device Actions',
  },
];
