import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    content: {
      backgroundColor: palette.background.default,
      padding: '0px 20px 20px 20px',
    },
    noPadding: {
      padding: '0',
    },
    genericCard: {
      marginTop: 19,
    },
    isLoading: {
      '& > *': {
        opacity: 0,
      },
    },
    title: {
      alignItems: 'center',
      background: palette.secondary.main,
      color: palette.primary.contrastText,
      display: 'flex',
      fontSize: 20,
      height: 30,
      justifyContent: 'center',
      textTransform: 'uppercase',
    },
  })
);

export default useStyles;
