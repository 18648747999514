import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import _ from 'lodash';
import React from 'react';
import momentHelper from 'utils/momentUtils';
import { toggleModalDiffered } from 'utils/storeUtils';

export const defaultTeamsTableHeaders = [
  {
    flex: 1,
    id: 'name',
    label: 'Name',
    minWidth: 250,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    flex: 1,
    id: 'description',
    label: 'Description',
    minWidth: 250,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    flex: 1,
    id: 'created',
    label: 'Date Created',
    minWidth: 150,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    flex: 1,
    id: 'modified',
    label: 'Date Modified',
    minWidth: 150,
    resizable: true,
    numeric: false,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    flex: 1,
    id: 'userCount',
    label: 'Users',
    minWidth: 125,
    numeric: false,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: false,
  },
  {
    flex: 1,
    id: 'actions',
    isProtected: true,
    label: 'Actions',
    minWidth: 125,
    numeric: false,
    scope: 'teams',
    show: true,
    showInContextMenu: true,
    sort: false,
  },
];

export const teamsColumnMapping = [
  { path: 'name' },
  { path: 'description' },
  {
    path: 'created',
    columnContent: ({ rowData }) => {
      const created = _.get(rowData, 'created');
      return created ? momentHelper(created) : '';
    },
  },
  {
    path: 'modified',
    columnContent: ({ rowData }) => {
      const modified = _.get(rowData, 'modified');
      return modified ? momentHelper(modified) : '';
    },
  },
  {
    path: 'userCount',
  },
  {
    path: 'actions',
    columnContent: ({ rowData }) => (
      <div className="flex--spaced">
        <Tooltip title="Edit Team">
          <EditIcon
            className="hover--link"
            onClick={toggleModalDiffered(
              'TeamsCreateEdit',
              {
                rowData,
              },
              {
                title: 'Edit Team',
              }
            )}
          />
        </Tooltip>
        <ProtectedComponent allow={{ teams: 'manage' }}>
          <Tooltip title="Delete Team">
            <DeleteIcon
              className="hover--link"
              onClick={toggleModalDiffered(
                'TeamsDeleteConfirm',
                {
                  rowData,
                },
                {
                  title: 'Delete Team',
                }
              )}
            />
          </Tooltip>
        </ProtectedComponent>
      </div>
    ),
  },
];
