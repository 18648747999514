import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { usersTableColumnChange } from 'actions/UsersTableActions';
import withRouter from 'components/hocs/withRouter';
import GlobalIndicator from 'components/main/common/GlobalIndicatorWrapper';
import { createModalConfig } from 'components/modals/UserCreateEdit';
import Table from 'components/RDGTables';
import _ from 'lodash';
import { useState } from 'react';
import { connect } from 'react-redux';
import {
  getAvailableTeamsAsMultiSelectList,
  updateTeamsFilter,
} from 'reducers/TeamReducers';
import { updateUISettings } from 'reducers/UiSettingsReducers';
import {
  getUISettingsTableHeaders,
  getUISettingsWithout,
} from 'reducers/UiSettingsSelectors';
import { bindActionCreators, compose } from 'redux';
import ZButton from 'UI/Buttons/ZButton';
import ReactToCSV from 'utility/ReactCSVDownloader';
import { toggleModalDiffered } from 'utils/storeUtils';
import useBackfill from 'utils/useBackfill';
import { withDirtyState } from 'utils/withDirtyState';
import withMemoizedProps from 'utils/withMemoizedProps';
import ProtectedComponent from '../../protected/ProtectedComponent';
import tableQuery from './tableQuery';
import { usersColumnMapping } from './users.mapping';

const Users = ({
  availableTeams,
  classes,
  currentTableHeaders,
  definedUser,
  jiggleDirtyState,
  q: query,
  rqps,
  updateUISettings,
  updateUrl,
  usersTableColumnChange,
  usersUiSettings,
}) => {
  useBackfill({
    query,
    requiredQueryParams: rqps,
    storedQueryParams: usersUiSettings,
    updateUrl: updateUrl,
  });

  const [teamsFilterValue, setTeamsFilterValue] = useState([]);

  const handleColumnChange = (...args) => {
    jiggleDirtyState();
    usersTableColumnChange(...args);
  };

  const handleFilterChange = (filters) => {
    const containsTeam = _.get(filters, '[0].name') === 'teamName';

    if (containsTeam) {
      const values = _.get(filters, '[0].value', []);

      setTeamsFilterValue(values || []);
    }
  };

  const queryWithTeams = {
    ...query,
    selectedTeamIds: teamsFilterValue,
  };

  const filters = [
    {
      name: 'teamName',
      operator: 'inlist',
      type: 'select',
      value: teamsFilterValue,
      filterEditorProps: {
        dataSource: availableTeams.map(({ label, value }) => ({
          id: value,
          label,
        })),
      },
    },
  ];

  const onPaginationChange = (update) => {
    updateUISettings({
      domain: 'superUsers',
      update,
    });

    updateUrl(update);
  };

  return (
    <div>
      <div className="view__header">
        <h1>User Management</h1>
        <ProtectedComponent allow={{ users: 'manage' }}>
          <ZButton
            styleName="titleBar"
            action={toggleModalDiffered(
              'UserCreateEdit',
              {
                fetchUsersTablePage: () => tableQuery(queryWithTeams),
              },
              createModalConfig
            )}
            buttonText="Add New User"
            color="secondary"
            icon={{ elem: AddIcon }}
          />
        </ProtectedComponent>
      </div>
      <div style={{ marginBottom: 15 }}>
        <GlobalIndicator isGlobal inline />
      </div>
      <Table
        classList={classes}
        columnHeaders={currentTableHeaders}
        filters={filters}
        footerComponents={() => (
          <ReactToCSV
            buttonText="exportCSV"
            dataMap={usersColumnMapping}
            fileName="users-table"
            header={currentTableHeaders.filter(
              (column) => column.id !== 'actions'
            )}
            table={() => tableQuery(queryWithTeams, true)}
          />
        )}
        heightBuffer={330}
        definedUser={definedUser}
        fetchTableData={() => tableQuery(queryWithTeams)}
        isSuperUser={definedUser === 'superAdminUser'}
        onColumnChange={handleColumnChange}
        onFilterValueChange={handleFilterChange}
        onPaginationChange={onPaginationChange}
        query={query}
        rowMapping={usersColumnMapping}
        tableId="Users"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  availableTeams: getAvailableTeamsAsMultiSelectList(state),
  createEditModal: _.get(state, 'ui.modals.UserCreateEdit'),
  currentTableHeaders: getUISettingsTableHeaders(state, 'users'),
  usersUiSettings: getUISettingsWithout(state, 'users', ['tableHeaders']),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      usersTableColumnChange,
      updateTeamsFilter,
      updateUISettings,
    },
    dispatch
  );

const styles = () => {
  return {
    multiSelect: {
      width: 200,
    },

    actionIcon: {
      cursor: 'pointer',
    },

    editLink: {
      textDecoration: 'none',
      color: 'inherit',
    },
  };
};

export default compose(
  withStyles(styles),
  withRouter,
  withDirtyState(),
  connect(mapStateToProps, mapDispatchToProps)
)(
  withMemoizedProps(Users, [
    'availableTeams',
    'createEditModal',
    'currentTableHeaders',
    'dirtyState',
    'q',
    'updateUISettings',
    'usersUiSettings',
  ])
);
