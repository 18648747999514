import { fetchBrandingByAccountId } from 'api/BrandingService';
import axios from 'axios';
import Button from 'components/UI/Button';
import GenericCard from 'components/UI/GenericCard';
import { ISelectItem } from 'components/UI/input/Select';
import { IUploadError } from 'components/modals/ZShieldUploadApp/Form/models';
import { formatErrorMessage } from 'components/modals/ZShieldUploadApp/utils';
import { Api } from 'config/axiosConfig';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { looksGenerallyLikeAnEmailRegex } from 'utils/componentUtils';
import { openSnackBar } from 'utils/storeUtils';
import useAsyncResult from 'utils/useAsyncResult';
import ConsoleForm from './Forms/Console';
import EmailForm from './Forms/Email';
import ImageForm, { getRemoveFieldName } from './Forms/Image';
import initialValues from './initialValues';
import { ESupportEmailAddressType, IPartnerBranding } from './models';
import Schema from './schema';
import useStyles from './useStyles';

const PartnerBrandingForm: React.FC = () => {
  const classes = useStyles();

  const [initialFormData, setInitialFormData] = useState<
    Partial<IPartnerBranding>
  >({});

  const [hasCustomImages, setHasCustomImages] = useState<{
    [key: string]: boolean;
  }>({});

  const brandingFromApi = useAsyncResult<{ data: IPartnerBranding }>(
    fetchBrandingByAccountId
  );

  const initializeForm = useCallback(
    (brandingFromApi: { data: IPartnerBranding }) => {
      setInitialFormData({
        ...(brandingFromApi?.data ?? {}),
        consoleLogo: brandingFromApi?.data?.consoleLogoUrl as string,
        darkModeConsoleLogo: brandingFromApi?.data
          ?.darkModeConsoleLogoUrl as string,
        darkModeLoginBackgroundImage: brandingFromApi?.data
          ?.darkModeLoginBackgroundImageUrl as string,
        darkModeLoginLogo: brandingFromApi?.data
          ?.darkModeLoginLogoUrl as string,
        darkModePartnerLogo: brandingFromApi?.data
          ?.darkModePartnerLogo as string,
        emailLogo: brandingFromApi?.data?.emailLogoUrl as string,
        favicon: brandingFromApi?.data?.faviconUrl as string,
        loginBackgroundImage: brandingFromApi?.data
          ?.loginBackgroundImageUrl as string,
        loginLogo: brandingFromApi?.data?.loginLogoUrl as string,
        partnerLogo: brandingFromApi?.data?.partnerLogoUrl as string,
      });
      setHasCustomImages({
        consoleLogo:
          (brandingFromApi?.data?.consoleLogoUrl as string)?.length > 0,
        darkModeConsoleLogo:
          (brandingFromApi?.data?.darkModeConsoleLogoUrl as string)?.length > 0,
        darkModeLoginBackgroundImage:
          (brandingFromApi?.data?.darkModeLoginBackgroundImageUrl as string)
            ?.length > 0,
        darkModeLoginLogo:
          (brandingFromApi?.data?.darkModeLoginLogoUrl as string)?.length > 0,
        darkModePartnerLogo:
          (brandingFromApi?.data?.darkModePartnerLogoUrl as string)?.length > 0,
        emailLogo: (brandingFromApi?.data?.emailLogoUrl as string)?.length > 0,
        favicon: (brandingFromApi?.data?.faviconUrl as string)?.length > 0,
        loginBackgroundImage:
          (brandingFromApi?.data?.loginBackgroundImageUrl as string)?.length >
          0,
        loginLogo: (brandingFromApi?.data?.loginLogoUrl as string)?.length > 0,
        partnerLogo:
          (brandingFromApi?.data?.partnerLogoUrl as string)?.length > 0,
      });
    },
    []
  );

  useEffect(() => {
    if (brandingFromApi) {
      initializeForm(brandingFromApi);
    }
  }, [brandingFromApi, initializeForm]);

  const initialFormValues = useMemo(() => {
    const data = initialValues(initialFormData) as unknown as IPartnerBranding;
    if (data.supportEmailAddressType === ESupportEmailAddressType.DEFAULT) {
      data.supportEmailAddress = 'support@zimperium.com';
    }
    return data;
  }, [initialFormData]);

  const handleSave = useCallback(
    async (values: IPartnerBranding) => {
      const formData = new FormData();
      if (
        values.supportEmailAddressType === ESupportEmailAddressType.CUSTOM &&
        !looksGenerallyLikeAnEmailRegex.test(values.supportEmailAddress)
      ) {
        openSnackBar('Support Email Address is required');
        return;
      }

      for (const key in values) {
        if (
          [
            'consoleLogo',
            'darkModeConsoleLogo',
            'darkModeLoginBackgroundImage',
            'darkModeLoginLogo',
            'emailLogo',
            'favicon',
            'loginBackgroundImage',
            'loginLogo',
          ].includes(key)
        ) {
          if (values[key] instanceof File) {
            formData.set(key, values[key] as File);
            formData.set(getRemoveFieldName(key), 'false');
          }
        } else if ((values[key] as unknown as ISelectItem)?.label) {
          formData.set(
            key,
            (values[key] as unknown as ISelectItem).value as string
          );
        } else {
          formData.set(key, values[key] as string);
        }
      }

      for (const key in hasCustomImages) {
        if (hasCustomImages[key] && !values[key]) {
          formData.delete(key);
          formData.set(getRemoveFieldName(key), 'true');
        }
      }

      formData.set(
        'disableTemplateContactBlock',
        values?.supportEmailAddress?.length ? 'false' : 'true'
      );

      try {
        const brandingFromApi = await Api.put('api/auth/v1/branding', formData);
        openSnackBar('Updated Branding');
        initializeForm(brandingFromApi);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          const status = e?.response?.status ?? -1;
          const statusText = e?.response?.statusText ?? 'Upload Error';
          const errorData: IUploadError = e?.response?.data;
          const dataString =
            typeof errorData?.message === 'string'
              ? errorData.message
              : 'Please Try Again';
          console.log(e?.response);
          openSnackBar(formatErrorMessage(statusText, dataString, status));
        } else {
          console.log(e);
          openSnackBar(formatErrorMessage('Upload Error', 'Please Try Again'));
        }
      }
    },
    [hasCustomImages, initializeForm]
  );

  if (!initialFormValues) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialFormValues as unknown as IPartnerBranding}
      onSubmit={handleSave}
      validateOnBlur
      validationSchema={Schema}
    >
      {({
        dirty,
        handleSubmit: handleFormikSubmit,
        isSubmitting,
        isValid,
        resetForm,
        values,
        setFieldValue,
      }) => {
        return (
          <div className={classes.wrapper}>
            {dirty && (
              <GenericCard className={classes.card}>
                <p>There are currently unsaved changes to the form below.</p>
                <Button
                  color="secondary"
                  disabled={!dirty || !isValid || isSubmitting}
                  onClick={() => handleFormikSubmit()}
                  text="Update Branding"
                />
                <Button
                  color="secondary"
                  disabled={!dirty || !isValid || isSubmitting}
                  onClick={() => resetForm()}
                  text="Reset"
                />
              </GenericCard>
            )}
            <EmailForm setFieldValue={setFieldValue} values={values} />
            <ConsoleForm />
            <ImageForm />
          </div>
        );
      }}
    </Formik>
  );
};

export default PartnerBrandingForm;
