import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  cloneSelectedPhishingPolicy,
  createPhishingPolicy
} from 'api/PhishingPolicyService';
import {
  availableTeams as availableTeamsAtom,
  selectedTeam as selectedTeamAtom
} from 'atoms/teams';
import withRouter from 'components/hocs/withRouter';
import { FormikRadioGroup } from 'components/inputs/FormikRadioGroup';
import { FormikTextField } from 'components/inputs/FormikTextField';
import TeamSelectorRadioForm from 'components/main/policies/common/TeamSelectorRadioForm';
import Button from 'components/UI/Button';
import { Field, Form, Formik } from 'formik';
import _ from 'lodash';
import { Fragment, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import {
  csvInjectionErrorMessage,
  csvInjectionRegex
} from 'utils/componentUtils';
import { openSnackBar, toggleModalDirect } from 'utils/storeUtils';
import * as Yup from 'yup';

const PhishingPolicyCreateClone = (props) => {
  const selectedTeamId = useRecoilValue(selectedTeamAtom) || undefined;
  const availableTeams = useRecoilValue(availableTeamsAtom) || [];
  const scopeBounds = useSelector((state) => {
    const { scopeBounds } = state?.user ?? '';
    return scopeBounds;
  });

  const scopeOptions = [
    { value: 'global', label: 'Global' },
    {
      value: 'team',
      label: props.data.selectedTeam.selectedTeamName
        ? `Team (${props.data.selectedTeam.selectedTeamName})`
        : 'Team',
    },
  ];

  function handleSubmit(form, actions) {
    const payload = {
      name: form.name,
      teamId: form.bounds === 'team' ? form.teamId : '',
    };

    const id = _.get(props, 'data.policySelected.value', null);

    if (id) {
      cloneSelectedPhishingPolicy({ id }, payload)
        .then(({ data }) => {
          props.updateUrl({ phishing: data.id });
          toggleModalDirect('PhishingPolicyCreateClone', false);
          openSnackBar(`${form.name} Successfully Cloned`);
        })
        .catch(({ response }) => {
          actions.setSubmitting(false);
          console.log('Error Phishing Policy Clone: ', response);
          if (response.status === 409) {
            actions.setErrors({
              name: 'A Policy with this name already exists.',
            });
          } else {
            toggleModalDirect('PhishingPolicyCreateClone', false);
            openSnackBar(
              `An error has occurred: ${_.get(
                response,
                'data.message',
                'Unknown Error'
              )}`
            );
          }
          console.error(response);
        });
    } else {
      createPhishingPolicy({}, payload)
        .then(({ data }) => {
          props.updateUrl({ phishing: data.id });
          toggleModalDirect('PhishingPolicyCreateClone', false);
          openSnackBar(`${form.name} Successfully Created`);
        })
        .catch(({ response }) => {
          actions.setSubmitting(false);
          console.log('Error Phishing Policy Clone: ', response);
          if (response.status === 409) {
            actions.setErrors({
              name: 'A Policy with this name already exists.',
            });
          } else {
            toggleModalDirect('PhishingPolicyCreateClone', false);
            openSnackBar(
              `An error has occurred: ${_.get(
                response,
                'data.message',
                'Unknown Error'
              )}`
            );
          }
          console.error(response);
        });
    }
  }

  function handleClose() {
    toggleModalDirect('PhishingPolicyCreateClone', false);
  }

  const handleTeamChange = useCallback((teamId, setFieldValue, values) => {
    setFieldValue('teamId', teamId);
  }, []);

  const defaultName = _.get(props, 'data.policySelected.label');

  return (
    <Fragment>
      <DialogTitle id="simple-dialog-title">
        {props.data.headerText}
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            name: defaultName ? `${defaultName}(cloned)` : '',
            bounds: 'team',
            selectedTeamId: props?.data?.selectedTeam?.selectedTeam ?? '',
            teamId: props?.data?.selectedTeam?.selectedTeam ?? '',
          }}
          onSubmit={handleSubmit}
          validationSchema={PhishingPolicySchema}
          enableReinitialize
          validateOnMount={true}
        >
          {({ values, setFieldValue, isValid, isSubmitting, touched }) => {
            return (
              <Form>
                <Field name="name" label="Name" component={FormikTextField} />
                <Field
                  name="bounds"
                  label="Policy Scope*"
                  component={FormikRadioGroup}
                  options={scopeOptions}
                  disabled={scopeBounds === 'TEAM_BOUNDED'}
                />
                <TeamSelectorRadioForm
                  handleTeamChange={handleTeamChange}
                  setFieldValue={setFieldValue}
                  availableTeams={availableTeams}
                  isRowDataPresent={false}
                  values={values}
                  selectedTeamId={selectedTeamId}
                  scopeBounds={scopeBounds}
                />
                <DialogActions>
                  <Button
                    onClick={() => handleClose()}
                    color="secondary"
                    text="Cancel"
                  />
                  <Button
                    type="submit"
                    color="primary"
                    disabled={
                      !isValid ||
                      isSubmitting ||
                      (Object.keys(touched).length === 0 &&
                        props.data.buttonText !== 'Clone')
                    }
                    text={props.data.buttonText}
                  />
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Fragment>
  );
};

const PhishingPolicySchema = Yup.object().shape({
  name: Yup.string()
    .min(1)
    .matches(csvInjectionRegex, csvInjectionErrorMessage)
    .required('Name is required.'),
  teamId: Yup.string().test('team', 'Team required.', (value, ctx) => {
    if (!!ctx?.parent?.selectedTeamId) {
      return true;
    }

    if (ctx?.parent?.bounds === 'global') {
      return true;
    }

    return !!value;
  }),
});

export default withRouter(PhishingPolicyCreateClone);
