import React from 'react';
import RadioControlGroup from 'components/inputs/RadioControl';
import { FormError } from './FormError';

export const FormikRadioGroup = ({ field, form, ...props }) => {
  return (
    <div style={{ position: 'relative' }}>
      <RadioControlGroup {...field} {...props} />
      {form.touched[field.name] && form.errors[field.name] && (
        <FormError field={field} form={form} />
      )}
    </div>
  );
};
