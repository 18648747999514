import { useEffect, useState } from 'react';
import { getAppById } from 'api/zDevService';
import { IZScanAppById } from './models';

export default (appId: string | undefined) => {
  const [zScanApp, setZScanApp] = useState<IZScanAppById | null>(null);

  useEffect(() => {
    async function zScanAppById() {
      if (!appId) {
        return;
      }
      const { data } = await getAppById({ appId });
      setZScanApp(data || '[]');
    }
    zScanAppById();
  }, [appId]);

  return { zScanApp };
};
