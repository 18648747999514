import { ServiceHandlerFactory } from 'utils/serviceUtils';
import {
  buildIntegrationPayload,
  buildIntegrationsParams,
} from './integrations.mapping';

const integrationServiceBaseURL = '/api/zdev-app/v1';

export const getIntegrations = ServiceHandlerFactory({
  axios: {
    url: `${integrationServiceBaseURL}/integrations`,
  },
  paramSchema: buildIntegrationsParams,
});

export const createIntegration = ServiceHandlerFactory({
  axios: {
    url: `${integrationServiceBaseURL}/integrations`,
    method: 'post',
  },
  payloadSchema: buildIntegrationPayload,
});

export const updateIntegration = ServiceHandlerFactory({
  axios: {
    url: `${integrationServiceBaseURL}/integrations/:integrationId`,
    method: 'put',
  },
  payloadSchema: buildIntegrationPayload,
});

export const deleteIntegration = ServiceHandlerFactory({
  axios: {
    url: `${integrationServiceBaseURL}/integrations/:integrationId`,
    method: 'delete',
  },
});

export const getIntegrationProjects = ServiceHandlerFactory({
  axios: {
    url: `${integrationServiceBaseURL}/integrations/:integrationId/projects`,
  },
});

// export const getProjectIssuesAndStatuses = ServiceHandlerFactory({
//   axios: {
//     url: `${integrationServiceBaseURL}/integrations/:integrationId/projects/:projectId/issue-types`,
//   },
// });
