import { createStyles, makeStyles } from '@material-ui/styles';

// Usage of 'any' inside this style decorator is discouraged; however, our theme
// currently does not conform to MUI's theme structure standards. So this will
// be a followup fix for later.
const useStyles = makeStyles(({ palette, palette: { text, accounts } }: any) =>
  createStyles({
    buttonPanel: {
      display: 'flex',
      margin: '20px 0',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
    },
    form: {},
    legacyNotice: {
      alignItems: 'center',
      color: palette.primary.contrastText,
      display: 'flex',
      fontSize: 15,
      margin: '7px 0 0',
      padding: '0 10px',

      '&:before': {
        backgroundColor: palette.error.main,
        borderRadius: '50%',
        content: '""',
        display: 'inline-block',
        height: 12,
        marginBottom: 3,
        marginRight: 5,
        width: 12,
      },
    },
    required: {
      '& span.MuiFormControlLabel-label': {
        margin: '20px 0 5px 0',
        fontSize: 15,
        color: palette.text.secondary,
      },
    },
    twoColumnGroup: {
      display: 'grid',
      gridAutoFlow: 'row',
      gridGap: '5px',
      gridTemplateColumns: '50% 50%',
      gridTemplateRows: 'auto auto',
      padding: '0 10px',
      alignItems: 'flex-end',
    },
    skewedTwoColumnGroup: {
      display: 'grid',
      gridAutoFlow: 'row',
      gridGap: '5px',
      gridTemplateColumns: '70% 30%',
      gridTemplateRows: 'auto auto',
      padding: '0 10px',
      alignItems: 'center',

      '& > span': {
        color: palette.text.secondary,
        fontSize: 15,
        marginTop: 19,
      },
    },
    subscriptionEnd: {
      width: '40%',

      '& label': {
        margin: '5px 0 0 0',
        fontSize: 10,
        color: text.placeholder,
      },

      '& ::-webkit-calendar-picker-indicator': {
        filter: accounts.inputs.svgFilter,
      },
    },
  })
);

export default useStyles;
