import MUIAddIcon from '@material-ui/icons/Add';
import MUIDeleteIcon from '@material-ui/icons/Delete';
import MUIReorderIcon from '@material-ui/icons/Reorder';
import useStyles from './useStyles';
interface InoDataTableContent {
  onHandleClick?: () => void;
}

const NoDataTableContent: React.FC<InoDataTableContent> = ({
  onHandleClick,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.noDataTableContent}>
      <MUIDeleteIcon className={classes.disableIconStyle} />
      <MUIReorderIcon className={classes.disableIconStyle} />
      <MUIAddIcon className={classes.addIconStyle} onClick={onHandleClick} />
    </div>
  );
};
export default NoDataTableContent;
