import { appCharacteristicsColumnHeaders } from './appCharacteristics.mapping';
import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Moment from 'moment';
import { IGenericRowActions } from 'components/UI/GenericTable/GenericRowActions/models';
import { getActiveModalAtom } from 'atoms/modals';
import { IActiveModalTrigger } from './APCCreatePolicyModal/models';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import cc from 'classcat';
import GenericTable from 'components/UI/GenericTable';
import Switch from '@material-ui/core/Switch';
import useParentStyles from '../useStyles';
import useStyles from './useStyles';
import APCCreateModal, {
  APCCreateNewPolicy_TITLE,
} from './APCCreatePolicyModal';
import GenericRowActions from 'components/UI/GenericTable/GenericRowActions';
import EmptyTableData from './EmptyTableData';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AffectedAppsAndDevices from './AffectedAppsDevicesModal';
import useAppCharacteristics from '../AppCharacteristicProvider';
import { TRowData } from 'components/UI/GenericTable/models';

const activeModalAtom = getActiveModalAtom<IActiveModalTrigger>();
interface IAppCharProps {
  setHasChanges: (value: boolean) => void;
}
const AppCharacteristics: React.FC<IAppCharProps> = ({ setHasChanges }) => {
  const classes = useStyles();
  const parentClasses = useParentStyles();
  const { scopes } = useSelector(({ auth }: any) => auth.user);
  const isAllowedToManage = scopes?.policies.includes('manage');
  const { t } = useTranslation();
  const setActiveModal = useSetRecoilState(activeModalAtom);
  const {
    fullTriggerRules,
    setFullTriggerRules,
    setAppPolicyTabSetting,
    setAppCharFormData,
  } = useAppCharacteristics();

  const appTableData = useMemo(() => {
    // save the user tab setting
    setAppPolicyTabSetting(1);

    try {
      const lastRowId = fullTriggerRules?.length
        ? fullTriggerRules[fullTriggerRules?.length - 1]?.id
        : 0;
      return { tableData: fullTriggerRules, lastRowId };
    } catch (err) {
      console.error('Error in fetching app characteristics: ', err);
      return { tableData: [], lastRowId: 0 };
    }
  }, [fullTriggerRules, setAppPolicyTabSetting]);

  const onHandleAddClick = useCallback(() => {
    setActiveModal({ active: APCCreateNewPolicy_TITLE });
  }, [setActiveModal]);

  const onToggleEnableClick = useCallback(
    (rule) => {
      const modifiedRules = fullTriggerRules?.map((existingRule) => {
        if (existingRule.id === rule.id) {
          return { ...existingRule, enabled: !existingRule.enabled };
        }
        return existingRule;
      });

      setFullTriggerRules(modifiedRules ?? []);
      setHasChanges(true);
    },
    [setFullTriggerRules, fullTriggerRules, setHasChanges]
  );

  const handleCopyRule = useCallback(
    (index) => {
      setAppCharFormData(undefined);
      const timeStamp = new Date();
      const timeStampNumeric = timeStamp.valueOf();
      if (!fullTriggerRules) {
        return;
      }

      const clonedRule = {
        ...fullTriggerRules[index],
        name: `${fullTriggerRules[index]?.name} (copy)`,
        id: timeStampNumeric.toString(),
      };

      setActiveModal({
        active: APCCreateNewPolicy_TITLE,
        payload: { ...clonedRule, action: 'clone' },
      });
    },

    [fullTriggerRules, setActiveModal, setAppCharFormData]
  );
  const handleEditRule = useCallback(
    (index, viewOnly = false) => {
      if (!fullTriggerRules) {
        return;
      }
      setAppCharFormData(undefined);
      const editRule = {
        ...fullTriggerRules[index],
        name: `${fullTriggerRules[index]?.name}`,
        existingRule: true,
      };
      setActiveModal({
        active: APCCreateNewPolicy_TITLE,
        payload: { ...editRule, action: viewOnly ? 'viewOnly' : 'edit' },
      });
    },

    [fullTriggerRules, setActiveModal, setAppCharFormData]
  );
  const handleDeleteRule = useCallback(
    (index) => {
      if (!fullTriggerRules) {
        return;
      }
      const triggerRuleSelected = fullTriggerRules[index];
      if (!triggerRuleSelected) {
        return;
      }
      const modifiedRules = fullTriggerRules.filter(
        (rule) => rule.id !== triggerRuleSelected.id
      );
      setFullTriggerRules(modifiedRules);
      setHasChanges(true);
    },

    [setFullTriggerRules, fullTriggerRules, setHasChanges]
  );

  return (
    <>
      <div className={cc([parentClasses.card, parentClasses.cardBold])}>
        <div>{t('MTD.POLICIES.APP_POLICY.RULES_IN_THIS_POLICY')}</div>
      </div>
      <GenericTable
        classList={classes}
        columnHeaders={appCharacteristicsColumnHeaders(t)}
        noDataTableContent={<EmptyTableData onHandleClick={onHandleAddClick} isAllowedToManage={isAllowedToManage} />}
        rowMapping={[
          {
            path: 'enabled',
            columnContent: ({ rowData }) => (
              <Switch
                checked={!!rowData?.enabled}
                onChange={() => onToggleEnableClick(rowData)}
                disabled={!isAllowedToManage}
              />
            ),
          },
          {
            path: 'modified',
            columnContent: ({ rowData }) =>
              rowData?.modified
                ? Moment(rowData?.modified as string).format('DD-MMM-YYYY')
                : '',
          },
          {
            path: 'policyAction',
            columnContent: () => <span>Out of Compliance</span>,
          },
          {
            path: 'actions',
            columnContent: (_, rowIndex, tableData) => {
              const isLastRow =
                !!tableData?.[0] && rowIndex === tableData.length - 1;
              const defaultActions: IGenericRowActions[] = [
                {
                  type: 'edit',
                  disabled: !isAllowedToManage,
                  hidden: !isAllowedToManage,
                  actionFn: () => handleEditRule(rowIndex),
                },
                {
                  icon: <VisibilityIcon />,
                  disabled: false,
                  hidden: isAllowedToManage,
                  actionFn: () => handleEditRule(rowIndex, 'viewOnly'),
                },
                {
                  type: 'copy',
                  disabled: !isAllowedToManage,

                  hidden: !isAllowedToManage,
                  actionFn: () => handleCopyRule(rowIndex),
                },
                {
                  type: 'delete',
                  disabled: !isAllowedToManage,
                  hidden: !isAllowedToManage,
                  actionFn: () => handleDeleteRule(rowIndex),
                },
                {
                  type: 'add',
                  hidden: !isAllowedToManage,
                  actionFn: () => {
                    setAppCharFormData(undefined);
                    setActiveModal({ active: APCCreateNewPolicy_TITLE });
                  },

                  onlyLastRow: true,
                },
              ];

              return (
                <GenericRowActions
                  actions={defaultActions}
                  isLastRow={isLastRow}
                />
              );
            },
          },
        ]}
        tableData={appTableData.tableData as unknown as TRowData[]}
        tableId="AppCharacteristicsTable"
      />
      <APCCreateModal />
      <AffectedAppsAndDevices />
    </>
  );
};

export default AppCharacteristics;
