import { requestTeamsListing } from 'api/apis';
import { memoizeTableQuery } from 'components/RDGTables/utils';
import _ from 'lodash';

export default memoizeTableQuery(async query => {
  // Fetch Data
  const rawTeamsTableData = await requestTeamsListing(query);

  return {
    data: _.get(rawTeamsTableData, 'data.content', []),
    count: _.get(rawTeamsTableData, 'data.totalElements', 0),
  };
});
