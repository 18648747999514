import React from 'react';
import withRouter from 'components/hocs/withRouter';

import AppBar from '@material-ui/core/AppBar';
import MaterialTab from '@material-ui/core/Tab';
import MaterialTabs from '@material-ui/core/Tabs';

const TabsNav = props => {
  return (
    <AppBar
      position="static"
      classes={{
        root: props.parentClasses.tabsAppBar,
      }}
    >
      <MaterialTabs
        value={props.activeTab}
        scrollButtons="auto"
        variant="scrollable"
        onChange={props.onHandleChange}
      >
        {props.tabsKeys.map(({ key, label, isShowing, shouldShow }) => {
          if (!shouldShow) {
            return null;
          }
          if (!isShowing) {
            return null;
          }

          if (props.syncUrl) {
            return (
              <MaterialTab
                onClick={_handleClick(key, props)}
                className="tabs-label"
                disableRipple
                key={key}
                label={label}
                value={key}
              />
            );
          }

          return (
            <MaterialTab
              className="tabs-label"
              disableRipple
              key={key}
              label={label}
              value={key}
            />
          );
        })}
      </MaterialTabs>
    </AppBar>
  );
};

function _handleClick(key, props) {
  return function handleClick() {
    props.updateUrl({ tab: key });
  };
}

export default withRouter(TabsNav);
