import React, { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

// mui
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { FormikTextField } from 'components/inputs/FormikTextField';

// services
import {
  verifyMfa,
  resendMfa,
  resendEmail,
  subscribe,
  // deleteSubscription,
} from 'api/SubscriptionService';

import ZButton from 'UI/Buttons/ZButton';

import { toggleModalDirect } from 'utils/storeUtils';

const useStyles = makeStyles(({ palette, shape }) => ({
  input: {
    borderRadius: 3,
    height: '3vh',
    backgroundColor: '#e0ebf2',
    alignItems: 'right',
  },

  label: {
    marginTop: '2vh',
    marginBottom: '0.25vh',
    color: '#757575',
    fontSize: '14px',
    fontFamily: "'Roboto', sans-serif",
  },

  textBody: {
    marginTop: '2vh',
    marginBottom: '0.25vh',
    color: '#757575',
    fontSize: '14px',
    fontFamily: "'Roboto', sans-serif",
  },

  resend: {
    marginTop: '2vh',
    marginBottom: '0.25vh',
    color: '#2196f3',
    fontSize: '12px',
    fontFamily: "'Roboto', sans-serif",
  },
}));

function SubscriptionMFA(props) {
  // eslint-disable-next-line
  const [invalidCode, setInvalidCode] = useState(false);
  // eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState('');
  const [isResendMfaClicked, setIsResendMfaClicked] = useState(false);
  // eslint-disable-next-line
  const [generalNetworkError, setGeneralNetworkError] = useState('');
  const styles = useStyles();
  const { t /*, i18n*/ } = useTranslation();

  const OtpValidationSchema = Yup.object().shape({
    otp: Yup.number()
      .typeError(t('STC.MUSTBEANUMBER'))
      .required(t('STC.REQUIRED')),
  });

  const responseData = _.get(props, 'data.subscriptionState');

  const RESEND_TEXT = t('STC.VALIDATIONCODERESEND');

  function handleResendMFA(e) {
    e.preventDefault();

    resendMfa({}, responseData)
      .then(({ data }) => {
        console.log('Resent MFA');
        setIsResendMfaClicked(true);
      })
      .catch((error) => console.error(error));
  }

  function handleResendActivation(e) {
    e.preventDefault();
    resendEmail({}, responseData)
      .then(({ subscriptionResponse }) => {
        console.log('subscriptionResponse', subscriptionResponse);
        toggleModalDirect(
          'SubscriptionText',
          {
            children: (
              <Typography
                variant="body2"
                className={styles.textBody}
                align="left"
              >
                {t('STC.SUBSCRIPTIONSUCCESSMESSAGE')}
              </Typography>
            ),
          },
          {
            title: t('STC.SUBSCRIPTIONSUCCESS'),
            scroll: 'paper',
          }
        );

        console.log('Subscription activation email successfully resent');
        // setTimeout(function () {
        //     window.location.replace(
        //         `${window.location.protocol}//${window.location.host}/subscription`
        //     );
        // }, 5000);
      })
      .catch((error) => {
        toggleErrorResponseModal(error.response.status);
        console.error(
          'Error occurred during resending email for a subscription'
        );
      });
  }

  function handleSubmit(values) {
    console.log('submit', values);

    const payload = {
      otp: values.otp,
      subscriptionState: { ...responseData },
    };

    verifyMfa({}, payload)
      .then(({ data }) => {
        // create the subscription here
        subscribe({}, data)
          .then(({ subscriptionResponse }) => {
            console.log('subscriptionResponse', subscriptionResponse);
            toggleModalDirect(
              'SubscriptionText',
              {
                children: (
                  <Typography
                    variant="body2"
                    className={styles.textBody}
                    align="left"
                  >
                    {t('STC.SUBSCRIPTIONSUCCESSMESSAGE')}
                  </Typography>
                ),
              },
              {
                title: t('STC.SUBSCRIPTIONSUCCESS'),
                scroll: 'paper',
              }
            );

            console.log('Subscription was successfully created');
            // setTimeout(function () {
            //     window.location.replace(
            //       `${window.location.protocol}//${window.location.host}/`
            //     );
            // }, 5000);
          })
          .catch((error) => {
            toggleErrorResponseModal(error.response.status);
            console.error('Error occurred during creating a subscription');
          });
      })
      .catch((error) => {
        console.error(error);
        toggleModalDirect(
          'SubscriptionText',
          {
            children: (
              <>
                <Typography
                  variant="body2"
                  className={styles.textBody}
                  align="left"
                >
                  {t('STC.INVALIDCODE')}
                </Typography>
              </>
            ),
            link: {
              href: '#',
              onClick: (e) => {
                e.preventDefault();
                window.open(
                  'https://www.stc.com.sa/wps/wcm/connect/arabic/helpAndSupport/contact',
                  '_blank'
                );
              },
              text: t('STC.TECHNICALSUPPORTLINKMESSAGE'),
            },
          },
          {
            title: t('STC.VALIDATIONCODE'),
            scroll: 'paper',
          }
        );
      });
  }

  function toggleErrorResponseModal(status) {
    if (status === 404) {
      toggleModalDirect(
        'SubscriptionText',
        {
          children: (
            <>
              <Typography
                variant="body2"
                className={styles.textBody}
                align="left"
              >
                {t('STC.ACCOUNTNOTFOUNDMESSAGE')}
              </Typography>
            </>
          ),
          link: {
            href: '#',
            onClick: (e) => {
              e.preventDefault();
              window.open(
                'https://www.stc.com.sa/wps/wcm/connect/arabic/helpAndSupport/contact',
                '_blank'
              );
            },
            text: t('STC.TECHNICALSUPPORTLINKMESSAGE'),
          },
        },
        {
          title: t('STC.ACCOUNTNOTFOUND'),
          scroll: 'paper',
        }
      );
    } else if (status === 408) {
      toggleModalDirect(
        'SubscriptionText',
        {
          children: (
            <>
              <Typography
                variant="body2"
                className={styles.textBody}
                align="left"
              >
                {t('STC.SUBSCRIPTIONFAILEDMESSAGE')}
              </Typography>
            </>
          ),
          link: {
            href: '#',
            onClick: (e) => {
              e.preventDefault();
              window.open(
                'https://www.stc.com.sa/wps/wcm/connect/arabic/helpAndSupport/contact',
                '_blank'
              );
            },
            text: t('STC.TECHNICALSUPPORTLINKMESSAGE'),
          },
        },
        {
          title: t('STC.SUBSCRIPTIONFAILED'),
          scroll: 'paper',
        }
      );
    } else if (status === 409) {
      toggleModalDirect(
        'SubscriptionText',
        {
          children: (
            <>
              <Typography
                variant="body2"
                className={styles.textBody}
                align="left"
              >
                {t('STC.ALREADYREGISTEREDMESSAGE')}
              </Typography>
            </>
          ),
          link: {
            href: '#',
            onClick: handleResendActivation,
            text: t('STC.RESENDACTIVATIONEMAIL'),
          },
        },
        {
          title: t('STC.ALREADYREGISTERED'),
          scroll: 'paper',
        }
      );
    } else {
      toggleModalDirect(
        'SubscriptionText',
        {
          children: (
            <>
              <Typography
                variant="body2"
                className={styles.textBody}
                align="left"
              >
                {t('STC.UNEXPECTEDERROR')}
              </Typography>
            </>
          ),
        },
        {
          title: t('STC.UNEXPECTEDERRORTITLE'),
          scroll: 'paper',
        }
      );
    }
  }

  return (
    <DialogContent>
      <Formik
        enableReinitialize
        initialValues={{ otp: '' }}
        validationSchema={OtpValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => {
          return (
            <>
              <Typography
                variant="body2"
                className={styles.textBody}
                align="left"
              >
                {invalidCode && t('STC.INVALIDCODE')}
                {!invalidCode && t('STC.ENTERVALIDATIONCODEMESSAGE')}
              </Typography>
              <Grid container spacing={2} direction="row" wrap="nowrap">
                <Grid item justify="flex-start">
                  {!invalidCode && (
                    <Typography
                      variant="body2"
                      className={styles.textBody}
                      align="left"
                    >
                      {t('STC.ENTERCODE')}
                    </Typography>
                  )}
                  <Typography
                    variant="body2"
                    className={styles.resend}
                    align="left"
                  >
                    <a
                      className={styles.resend}
                      href="#"
                      onClick={handleResendMFA}
                    >
                      {isResendMfaClicked && t('STC.CODERESENT')}
                      {!isResendMfaClicked && RESEND_TEXT}
                    </a>
                  </Typography>
                </Grid>
                <Grid item justify="flex-end">
                  <Form id="subscriptionMfa">
                    <Field
                      name="otp"
                      type="text"
                      label={t('STC.VALIDATIONCODE')}
                      component={FormikTextField}
                      classlist={{ label: styles.label, input: styles.input }}
                    />

                    <ZButton
                      fullWidth={false}
                      buttonType="submit"
                      styleName="rightAlign"
                      color="secondary"
                      buttonText={t('STC.BUTTONTEXTNEXT')}
                    />

                    {!_.isEmpty(generalNetworkError) && (
                      <Typography variant="body1" color="error" align="center">
                        {generalNetworkError}
                      </Typography>
                    )}
                  </Form>
                </Grid>
              </Grid>
            </>
          );
        }}
      </Formik>
    </DialogContent>
  );
}

export default SubscriptionMFA;
