import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, config }: any) =>
  createStyles({
    contentContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '0px 20px 20px 20px',
      border: `1px solid ${config.colors.darkGreySoft}`,
    },
    itemContainer: {
      padding: '20px 30px 0px 30px',
      width: '25%',
    },
    itemContents: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      color: palette.text.secondary,
      fontSize: '12px',
      whiteSpace: 'nowrap',
    },
    value: {
      color: palette.text.secondary,
      fontSize: '38px',
      padding: '6px 0px 4px 0px',
    },
    appleIcon: {
      fill: 'currentColor',
      minHeight: '25px',
      minWidth: '25px',
      maxWidth: '50px',
      stroke: 'currentColor',
    },
    iosIcon: {
      '& > svg': { height: '100%', width: '100%' },
    },
    title: {
      color: palette.text.secondary,
      backgroundColor: palette.background.default,
      border: `1px solid ${config.colors.darkGreySoft}`,
    },
  })
);

export default useStyles;
