import AddIcon from '@material-ui/icons/Add';
import { downloadCSV, getCSVCount } from 'api/AppsInventoryService';
import { DrawerContext } from 'components/drawer/DrawerProvider';
import { IDrawerContext } from 'components/drawer/DrawerProvider/models';
import withRouter from 'components/hocs/withRouter';
import Button from 'components/UI/Button';
import Table from 'components/UI/Table';
import {
  IColumnHeader,
  IFilterEditor,
  ILocationQuery,
} from 'components/UI/Table/models';
import { SCOPE_BOUNDS } from 'mappings/scopeMapping';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  appsInventoryTableColumnChange,
  updateUISettings,
} from 'reducers/UiSettingsReducers';
import {
  getUISettingsTableHeaders,
  getUISettingsWithout,
} from 'reducers/UiSettingsSelectors';
import { bindActionCreators, compose } from 'redux';
import ReactToCSV from 'utility/ReactCSVDownloader';
import { toggleModalDirect } from 'utils/storeUtils';
import useBackfill from 'utils/useBackfill';
import { withDirtyState } from 'utils/withDirtyState';
import withMemoizedProps from 'utils/withMemoizedProps';
import { default as OldSearchBox } from '../../common/SearchBox';
import { IRowProps } from '../../zShieldApps/ZShieldAppDrawer/models';
import {
  appsInventoryColumnMapping,
  resolveFilters,
} from './appsInventory.mappings';
import Drawer from './drawer';
import { IFilterValues } from './models';
import tableQuery from './tableQuery';
import useStyles from './useStyles';
import { INITIAL_FILTER_VALUES } from './utils';
import { useTranslation } from 'react-i18next';

const SearchBox: any = OldSearchBox;

interface IAppsInventoryProps {
  appsInventoryTableColumnChange: (...args: any) => void;
  appsInventoryUiSettings: { [key: string]: unknown };
  currentTableHeaders: IColumnHeader[];
  definedUser?: string;
  jiggleDirtyState: (...args: any) => void;
  q: { [key: string]: unknown };
  rqps: string[];
  scopes: { [key: string]: string[] };
  updateUISettings: (...args: any) => void;
  updateUrl: (...args: any) => void;
  scopeBounds: string;
}

const AppsInventory: React.FC<IAppsInventoryProps> = ({
  appsInventoryTableColumnChange,
  appsInventoryUiSettings,
  currentTableHeaders,
  definedUser,
  jiggleDirtyState,
  q: query,
  rqps,
  scopes,
  updateUISettings,
  updateUrl,
  scopeBounds,
}) => {
  useBackfill({
    query,
    requiredQueryParams: rqps,
    storedQueryParams: appsInventoryUiSettings,
    updateUrl: updateUrl,
  });

  const { ready, t } = useTranslation();

  const [filterValues, setFilterValues] = useState<IFilterValues>(
    INITIAL_FILTER_VALUES
  );
  const filters = resolveFilters(filterValues, t);
  const { closeDrawer = null, openDrawer } =
    useContext<Partial<IDrawerContext<IRowProps>>>(DrawerContext);

  const classes = useStyles();

  const handleColumnChange = (...args: any) => {
    jiggleDirtyState();
    appsInventoryTableColumnChange(...args);
  };

  const handleOpenDrawer = (rowProps: IRowProps) => {
    if (!openDrawer) {
      return;
    }
    openDrawer({ drawerProps: rowProps, drawer: Drawer });
  };

  const reloadTable = useCallback(() => {
    jiggleDirtyState(); // This will force a reload without relying on the Table component.
    if (typeof closeDrawer === 'function') {
      closeDrawer();
    }
  }, [jiggleDirtyState, closeDrawer]);

  useEffect(() => {
    window.addEventListener(`AppsInventory:reload-tables`, reloadTable);

    return () => {
      window.removeEventListener(`AppsInventory:reload-tables`, reloadTable);
    };
  }, [reloadTable]);

  const onPaginationChange = (update: any) => {
    updateUISettings({
      domain: 'appsInventory',
      update,
    });

    updateUrl(update);
  };

  if (!ready) {
    return null;
  }

  const handleCSVDownload = () => {
    toggleModalDirect(
      'CsvExport',
      {
        columnHeaders: currentTableHeaders,
        download: downloadCSV,
        downloadCount: getCSVCount,
        downloadParams: query,
        module: 'MTD',
        filename: 'app-inventory.csv',
      },
      {
        title: t('GLOBAL.EXPORT_TO_CSV'),
        disableBackdropClick: true,
      }
    );
  };

  const handleFilterChange = (filterEditors: IFilterEditor[]) => {
    const newFilterValues: IFilterValues = {};
    filterEditors.forEach(({ name, value }) => {
      newFilterValues[name] = value;
    });
    setFilterValues(newFilterValues);
  };

  return (
    <div>
      <div className="view__header">
        <h1>{t('MTD.APP_INVENTORY.APPS_INVENTORY')}</h1>
        {scopes?.app_inventory?.includes('manage') && (
          <Button
            onClick={() => {
              toggleModalDirect('UploadApp', {
                variant: 'UploadApp',
              });
            }}
            text={t('MTD.APP_INVENTORY.TABLE_VIEW.UPLOAD_APPS')}
            color="secondary"
            // styleName="titleBar"
            icon={AddIcon}
          />
        )}
      </div>
      <div className={classes.searchBar}>
        <SearchBox fullWidth />
      </div>
      <Table
        serverSort={false}
        classList={classes}
        columnHeaders={
          query.teamId || scopeBounds === SCOPE_BOUNDS.team
            ? currentTableHeaders.filter(
                (column) => column.id !== 'deviceCount'
              )
            : currentTableHeaders
        }
        footerComponents={() => (
          <ReactToCSV
            buttonText="exportCSV"
            dataMap={appsInventoryColumnMapping(t)}
            fileName="appsInventory-table"
            header={currentTableHeaders.filter(
              (column) => column.id !== 'actions'
            )}
            table={() =>
              tableQuery(filters)(query as unknown as ILocationQuery)
            }
            handleCSVDownload={handleCSVDownload}
          />
        )}
        filters={filters}
        onFilterValueChange={handleFilterChange as () => void}
        definedUser={definedUser}
        fetchTableData={() =>
          tableQuery(filters)(query as unknown as ILocationQuery)
        }
        onColumnChange={handleColumnChange}
        onPaginationChange={onPaginationChange}
        onRowClick={handleOpenDrawer}
        query={query as unknown as ILocationQuery}
        rowMapping={appsInventoryColumnMapping(t)}
        tableId="AppsInventory"
        heightBuffer={360}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  appsInventoryUiSettings: getUISettingsWithout(state, 'appsInventory', [
    'tableHeaders',
  ]),
  currentTableHeaders: getUISettingsTableHeaders(state, 'appsInventory'),
  scopes: state.auth.user.scopes,
  scopeBounds: state.user.scopeBounds,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      appsInventoryTableColumnChange,
      updateUISettings,
    },
    dispatch
  );

export default compose(
  withRouter,
  withDirtyState(),
  connect(mapStateToProps, mapDispatchToProps)
)(
  withMemoizedProps(AppsInventory, [
    'currentTableHeaders',
    'dirtyState',
    'q',
    'updateUISettings',
  ])
);
