import MUICard from '@material-ui/core/Card';
import MUICardContent from '@material-ui/core/CardContent';
import MUIToolbar from '@material-ui/core/Toolbar';
import AppDropdown from 'components/main/common/AppDropdown';
import DataCard from 'components/main/common/DataCard';
import RangeSelector from 'components/UI/RangeSelector';
import StickyAppBar from 'components/UI/StickyAppBar';
import { useEffect, useState } from 'react';
import { IDeviceCountsData, IQueryParams } from '../common/models';
import useStyles from './useStyles';
import { useTranslation } from 'react-i18next';

interface IDevicesAppBarProps {
  deviceCounts?: IDeviceCountsData;
  matchUrl: string;
  module: string;
  query: IQueryParams;
  updateUISettings: (...args: unknown[]) => void;
  updateUrl: (params: IQueryParams) => void;
}

const DevicesAppBar: React.FC<IDevicesAppBarProps> = ({
  deviceCounts,
  matchUrl,
  module,
  query,
  updateUISettings,
  updateUrl,
}) => {
  const classes = useStyles();
  const { t, ready } = useTranslation();

  const [initialized, setInitialized] = useState<boolean>(false);

  const [counts, setCounts] = useState<IDeviceCountsData>({
    total: 0,
    ios: 0,
    android: 0,
    'chrome os': 0,
  });

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }
    if (!!deviceCounts) {
      setCounts(deviceCounts);
    }
  }, [setCounts, deviceCounts, initialized, setInitialized]);

  if (!ready) {
    return null;
  }

  return (
    <StickyAppBar>
      <MUIToolbar className="view__header">
        <div>
          <h1>{t('GLOBAL.DEVICES')}</h1>
          {module !== 'mtd' && (
            <AppDropdown
              query={query}
              storeKey="devices"
              updateUISettings={updateUISettings}
              updateUrl={updateUrl}
            />
          )}
        </div>
        <RangeSelector
          additionalRangeTypes={[{ name: 'GLOBAL.ALL_TIME', ordinal: 9 }]}
          query={query}
          storeKey="devices"
          updateUISettings={updateUISettings}
          updateUrl={updateUrl}
        />
      </MUIToolbar>
      <MUICard>
        <MUICardContent className={classes.devicesSummary}>
          {!!counts && (
            <>
              <DataCard
                data={{
                  os: 'total',
                  count: counts.total,
                  label: t('GLOBAL.TOTAL_DEVICES'),
                }}
                matchUrl={matchUrl}
                query={query}
              />
              <DataCard
                data={{
                  os: 'ios',
                  count: counts?.ios ?? 0,
                  label: t('GLOBAL.IOS'),
                }}
                matchUrl={matchUrl}
                query={query}
              />
              <DataCard
                data={{
                  os: 'android',
                  count: counts?.android ?? 0,
                  label: t('GLOBAL.ANDROID'),
                }}
                matchUrl={matchUrl}
                query={query}
              />
              <DataCard
                hide={module !== 'mtd' || counts?.['chrome os'] === 0}
                data={{
                  os: 'chrome os',
                  count: counts?.['chrome os'] ?? 0,
                  label: t('GLOBAL.CHROME_OS'),
                }}
                matchUrl={matchUrl}
                query={query}
              />
            </>
          )}
        </MUICardContent>
      </MUICard>
    </StickyAppBar>
  );
};

export default DevicesAppBar;
