import React, { useState } from 'react';

// services
import { deleteAccount } from 'api/AdminAccountService';

// actions
import { publishEvent } from 'utils/eventUtils';

// components
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ZButton from 'UI/Buttons/ZButton';
import Typography from '@material-ui/core/Typography';
import {
  openSnackBar,
  toggleModalDirect,
  toggleModalDiffered,
} from '../../utils/storeUtils';

const AccountDeleteConfirm = ({ data }) => {
  const [deleteError, setDeleteError] = useState(null);
  return (
    <DialogContent>
      <p>Are you sure you want to delete the account?</p>
      <div style={{ width: 300 }}>
        <Typography type="body1" align="left" color="error">
          {deleteError && deleteError}
        </Typography>
      </div>
      <DialogActions>
        <ZButton
          styleName="modalCancel"
          action={toggleModalDiffered('AccountsDeleteConfirm', false)}
          color="secondary"
          buttonText="Cancel"
        />
        <ZButton
          action={handleSubmit(data.id)}
          buttonType="submit"
          color="primary"
          styleName="modalSave"
          buttonText="Delete Account"
        />
      </DialogActions>
    </DialogContent>
  );

  function handleSubmit(accountId) {
    return () => {
      deleteAccount({ accountId })
        .then(() => {
          toggleModalDirect('AccountsDeleteConfirm', false);
          openSnackBar('Account Successfully Deleted');
          publishEvent('table:force-fetch-Accounts');
        })
        .catch(error => {
          // toggleModalDirect('AccountsDeleteConfirm', false);
          setDeleteError(error.response.data);
          openSnackBar(`Failed to delete account: ${error.response.data}`);
          console.log(error);
        });
    };
  }
};
AccountDeleteConfirm.defaultProps = {
  data: {},
};

export default AccountDeleteConfirm;
