import React from 'react';
import _ from 'lodash';

/**
 * Use Sparingly!
 *
 * Performs a DEEP comparison on prev/next props.
 * React.memo only performs a shallow comparison by default.
 */
export default (component, props = []) =>
  React.memo(component, (prevProps, nextProps) => {
    const prevMappedProps = props.map(prop => _.get(prevProps, prop));
    const nextMappedProps = props.map(prop => _.get(nextProps, prop));

    return prevMappedProps.every((prevMappedProp, index) =>
      _.isEqual(prevMappedProp, nextMappedProps[index])
    );
  });
