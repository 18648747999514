import { ISelectItem } from 'components/UI/input/Select';
import memoizeTableQuery from 'components/UI/Table/utils/memoizeTableQuery';
import { IQueryParams } from '../common/models';
import { fetchTableData } from './utils';

const memoizeQuery = (
  query: IQueryParams,
  module: string,
  availableTeams: ISelectItem[]
) => {
  return memoizeTableQuery(async () => {
    // Fetch Data
    const tableData: any = await fetchTableData(query, module, availableTeams);
    return {
      data: tableData?.data ?? 0,
      count: tableData?.count
    };
  });
};
export default memoizeQuery;
