const devicesDataFilterMapping = (t) => [
  {
    path: 'zdeviceId',
    transform: ({ zdeviceId }) => ({ label: t('GLOBAL.DEVICE_ID'), value: zdeviceId }),
  },
  {
    path: 'mdmDeviceId',
    transform: ({ mdmDeviceId }) => ({
      label: t('MTD.DEVICES.EMM_DEVICE_ID'),
      value: mdmDeviceId,
    }),
  },
  {
    path: 'mamDeviceId',
    transform: ({ mamDeviceId }) => ({
      label: t('MTD.DEVICES.MAM_ID'),
      value: mamDeviceId,
    }),
  },
  {
    path: 'groupId',
    transform: ({ groupId }) => ({
      label: t('GLOBAL.GROUP_ID'),
      value: groupId,
    }),
  },
  {
    path: 'model',
    transform: ({ model }) => ({ label: t('GLOBAL.MODEL'), value: model }),
  },

  {
    path: 'zappInstance.externalTrackingId1',
    transform: (locationProps) => ({
      value: locationProps['zappInstance.externalTrackingId1'],
      label: 'External Tracking Id 1',
    }),
  },
  {
    path: 'zappInstance.externalTrackingId2',
    transform: (locationProps) => ({
      value: locationProps['zappInstance.externalTrackingId2'],
      label: 'External Tracking Id 2',
    }),
  },
  {
    path: 'zappInstance.zappName',
    transform: (locationProps) => ({
      value: locationProps['zappInstance.zappName'],
      label: t('GLOBAL.NAME'),
    }),
  },
  {
    path: 'zappInstance.bundleId',
    transform: (locationProps) => ({
      value: locationProps['zappInstance.bundleId'],
      label: t('GLOBAL.BUNDLE_ID'),
    }),
  },
  {
    path: 'zappInstance.buildNumber',
    transform: (locationProps) => ({
      value: locationProps['zappInstance.buildNumber'],
      label: 'App Build Number',
    }),
  },
  {
    path: 'zappInstance.version',
    transform: (locationProps) => ({
      value: locationProps['zappInstance.version'],
      label: t('GLOBAL.VERSION'),
    }),
  },
  {
    path: 'zappInstance.groupId',
    transform: (locationProps) => ({
      value: locationProps['zappInstance.groupId'],
      label: t('GLOBAL.GROUP_ID'),
    }),
  },
  {
    path: 'zappInstance.zversion',
    transform: (locationProps) => ({
      value: locationProps['zappInstance.zversion'],
      label: t('GLOBAL.Z_DEFEND_VERSION'),
    }),
  },
  {
    path: 'zappInstance.zbuildNumber',
    transform: (locationProps) => ({
      value: locationProps['zappInstance.zbuildNumber'],
      label: t('GLOBAL.Z_DEFEND_BUILD'),
    }),
  },
  {
    path: 'zappInstance.isUnknownSourceEnabled',
    transform: (locationProps) => ({
      value: locationProps['zappInstance.isUnknownSourceEnabled'],
      label: t('GLOBAL.3RD_PARTY_APP_STORE'),
    }),
  },
  {
    path: 'os.name',
    transform: (locationProps) => ({
      value: locationProps['os.name'],
      label: t('GLOBAL.OS'),
    }),
  },
  {
    path: 'os.policyCompliant',
    transform: (locationProps) => ({
      value: locationProps['os.policyCompliant'],
      label: 'Compliant',
    }),
  },
  {
    path: 'os.version',
    transform: (locationProps) => ({
      value: locationProps['os.version'],
      label: t('GLOBAL.OS_VERSION'),
    }),
  },
  {
    path: 'os.patchDate.keyword',
    transform: (locationProps) => ({
      value: locationProps['os.patchDate.keyword'],
      label: t('GLOBAL.PATCH_DATE'),
    }),
  },
  {
    path: 'appVersionId',
    transform: ({ appName, appVersion }) => ({
      value: appName && appVersion ? `${appName}/${appVersion}` : undefined,
      label: 'Name/Version',
    }),
  },
  {
    path: 'isJailbroken',
    transform: ({ isJailbroken }) => ({
      value: isJailbroken,
      label: 'Jailbroken',
    }),
  },
  {
    path: 'isUsbDebuggingEnabled',
    transform: ({ isUsbDebuggingEnabled }) => ({
      value: isUsbDebuggingEnabled,
      label: 'USB Debugging',
    }),
  },
  {
    path: 'lockScreenUnprotected',
    transform: ({ lockScreenUnprotected }) => ({
      value: lockScreenUnprotected,
      label: 'Lock Screen Unprotected',
    }),
  },
  {
    path: 'stagefrightVulnerable',
    transform: ({ stagefrightVulnerable }) => ({
      value: stagefrightVulnerable,
      label: 'Stagefright Vulnerable',
    }),
  },
  {
    path: 'developerOptionsOn',
    transform: ({ developerOptionsOn }) => ({
      value: developerOptionsOn,
      label: t('GLOBAL.DEVELOPER_MODE'),
    }),
  },
  {
    path: 'diskNotEncrypted',
    transform: ({ diskNotEncrypted }) => ({
      value: diskNotEncrypted,
      label: 'Device Encyption',
    }),
  },
  {
    path: 'activationName',
    transform: ({ activationName }) => ({
      value: activationName,
      label: t('MTD.THREATS.ACTIVATION_NAME'),
    }),
  },
  {
    path: 'deviceOwner.email',
    transform: (locationProps) => ({
      value: locationProps['deviceOwner.email'],
      label: t('GLOBAL.DEVICE_OWNER'),
    }),
  },
  {
    path: 'upgradeable',
    transform: ({ upgradeable }) => ({
      value: upgradeable,
      label: t('GLOBAL.UPGRADEABLE'),
    }),
  },
  {
    path: 'vulnerable',
    transform: ({ vulnerable }) => ({
      value: vulnerable,
      label: 'Vulnerable',
    }),
  },
  {
    path: 'cve',
    transform: ({ cve }) => ({
      value: cve,
      label: 'CVE',
    }),
  },
  {
    path: 'appStatus',
    transform: ({ appStatus }) => ({
      value: appStatus,
      label: t('GLOBAL.APP_STATUS'),
    }),
  },
  {
    path: 'riskyThreats',
    transform: ({ riskyThreats }) => ({
      value: riskyThreats,
      label: 'Risky Threats',
    }),
  },
  {
    path: 'criticalThreats',
    transform: ({ criticalThreats }) => ({
      value: criticalThreats,
      label: t('MTD.THREATS.CRITICAL_THREATS'),
    }),
  },
];

export default devicesDataFilterMapping;
