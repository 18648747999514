import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ config, palette }: any) =>
  createStyles({
    drawerCard: {
      backgroundColor: config.colors.marineBlue,
      borderRadius: '4px',
      color: config.colors.almostWhite,
    },
    eventCard: {
      margin: '30px 40px 0px 40px',
      padding: 30,
    },
    eventContent: {
      display: 'flex',
      justifyContent: 'flex-start',
      paddingTop: 20,
      width: '100%',
    },
    eventDescription: {
      fontSize: 18,
      paddingRight: 50,
      width: '37%',
    },
    eventHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      borderBottom: '1px solid white',
      '& h3': {
        margin: 0,
        padding: 0,
        paddingBottom: 5,
      },
    },
    green: {
      background: config.colors.avocado,
    },
    red: {
      background: palette.components.statusDot.critical,
    },
    statusDot: {
      borderRadius: '50%',
      height: 12,
      marginRight: 6,
      width: 12,
    },
    tabs: {
      padding: '10px 15px 30px 15px',
    },
    topCardMargin: {
      margin: '30px 10px 0px 10px',
    },
    topSection: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 30,
      width: '100%',
      overflow: 'auto',
    },
    appleIcon: {
      fill: palette.components.globalHeader.contrastText,
      height: '25px',
      width: '25px',
      stroke: palette.calendar.native.floatingNav,
    },
    chromeIcon: {
      fill: palette.components.globalHeader.contrastText,
      height: '25px',
      width: '25px',
    },
    androidIcon: {
      color: palette.components.globalHeader.contrastText,
      minHeight: '25px',
      minWidth: '25px',
    },
  })
);

export default useStyles;
