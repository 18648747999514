export type TSeverity =
  | 'UNKNOWN'
  | 'BEST_PRACTICES'
  | 'INFORMATIONAL'
  | 'LOW'
  | 'MEDIUM'
  | 'HIGH'
  | 'CRITICAL';

export enum ESeverity {
  UNKNOWN = 'UNKNOWN',
  BEST_PRACTICES = 'BEST_PRACTICES',
  INFORMATIONAL = 'INFORMATIONAL',
  LOW = 'LOW',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  CRITICAL = 'CRITICAL',
}
