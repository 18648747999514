import { useTheme } from '@material-ui/core';
import { IGraphData } from 'components/UI/graphs/common/models';
import LineGraph from 'components/UI/graphs/LineGraph';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { IDeviceAggregate, IQueryParams } from '../common/models';
import useStyles from './useStyles';
import { calcDaysToAdd, GRAPH_OPTIONS, prepareGraphData } from './utils';
import { useTranslation } from 'react-i18next';

interface IDevicesTimelineGraphProps {
  deviceAggregates?: IDeviceAggregate[];
  query: IQueryParams;
  updateUrl: (params: IQueryParams) => void;
}

const DevicesTimelineGraph: React.FC<IDevicesTimelineGraphProps> = ({
  deviceAggregates,
  query,
  updateUrl,
}) => {
  const classes = useStyles();
  const theme: any = useTheme(); // This is any because we are not yet able to conform to MUI's them standards
  const { t, ready } = useTranslation();

  const [initialized, setInitialized] = useState<boolean>(false);

  const [graphData, setGraphData] = useState<IGraphData | undefined>();

  const handleClickDataPoint = useCallback(
    (elems) => {
      if (!elems?.[0]) {
        return;
      }

      const el = elems[0];
      const timestamp =
        el?._chart?.data?.datasets?.[el._datasetIndex]?.parameters?.[
          el._index
        ] ?? '';

      updateUrl({
        from: String(moment(timestamp).unix()),
        to: String(
          moment(timestamp)
            .add(calcDaysToAdd(Number(query?.duration ?? 0)), 'd')
            .unix()
        ),
        duration: undefined,
      });
    },
    [query?.duration, updateUrl]
  );

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }
    if (!!deviceAggregates && !!query) {
      setGraphData(prepareGraphData(deviceAggregates, query, t));
    }
  }, [deviceAggregates, query, initialized, t]);

  if (!ready) {
    return null;
  }

  return (
    <div className={classes.graph}>
      <LineGraph
        clickHandler={handleClickDataPoint}
        colors={{
          android: theme.palette.common.android,
          ios: theme.palette.common.ios,
          chromeos: theme.palette.common.chromeos,
        }}
        graphData={graphData}
        height={51}
        options={GRAPH_OPTIONS}
      />
    </div>
  );
};

export default DevicesTimelineGraph;
