import cc from 'classcat';
import LiteralProportionChart from 'components/UI/Chart/LiteralProportion';
import GenericCard from 'components/UI/GenericCard';
import { useMemo } from 'react';
import { IRiskyCriticalEvents, TCriticalRiskyThreatType } from '../models';
import { default as useSharedStyles } from '../useStyles';
import { ERiskyCriticalEvent } from './models';
import useStyles from './useStyles';
import { useTranslation } from 'react-i18next';

interface ITopEvents {
  eventType: ERiskyCriticalEvent;
  topRiskyCriticalEvents: IRiskyCriticalEvents;
  threatTypes: TCriticalRiskyThreatType[];
}

const TopEvents: React.FC<ITopEvents> = ({
  eventType,
  topRiskyCriticalEvents,
  threatTypes,
}) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const { t, ready, i18n } = useTranslation();

  const topEvents = useMemo(
    () =>
      (topRiskyCriticalEvents?.[eventType] ?? []).map(
        ({ count, threatTypeId }) => ({
          count,
          label:
            threatTypes?.find(({ id }) => id === threatTypeId)?.friendlyName ??
            'Unknown',
          link: `/console/mtd/threats/?duration=8&threatTypeId=${threatTypeId}`,
          suffix: t('GLOBAL.EVENTS'),
        })
      ),
    [eventType, threatTypes, topRiskyCriticalEvents, t]
  );

  if (!ready) {
    return null;
  }

  return (
    <GenericCard className={cc([sharedClasses.card, classes.topEventsCard])}>
      <h2>
        {eventType === ERiskyCriticalEvent.CRITICAL
          ? t('MTD.INSIGHTS.TOP_CRITICAL_EVENTS')
          : t('MTD.INSIGHTS.TOP_RISKY_EVENTS')}
      </h2>
      <div className={sharedClasses.column}>
        {topEvents?.length > 1 ? (
          <LiteralProportionChart columns={topEvents} />
        ) : (
          <div
            className={cc([
              {
                [classes.pendingNonEnglish]:
                  i18n.language?.toLowerCase() !== 'en-us',
              },
              classes.pending,
            ])}
          >
            {t('GLOBAL.NOT_AVAILABLE')}
          </div>
        )}
      </div>
    </GenericCard>
  );
};

export default TopEvents;
