import { IFilterEditor } from 'components/UI/Table/models';
import memoizeTableQuery from 'components/UI/Table/utils/memoizeTableQuery';
import { IAppleNameMapping } from 'components/main/devices/Devices/models';
import { IQueryParams } from '../common/models';
import { IGroup, IThreatTypesFromApi } from './models';
import { ammendFiltersFromQuery, fetchTableData } from './utils';

const memoizeQuery = (
  module: string,
  rawFilters: IFilterEditor[],
  rawQuery: IQueryParams,
  threatTypesFromApi?: IThreatTypesFromApi,
  iPhoneModelMap?: IAppleNameMapping,
  groups?: IGroup[]
) => {
  return memoizeTableQuery(async () => {
    const { filters, query } = ammendFiltersFromQuery(rawFilters, rawQuery);

    const tableData: any = await fetchTableData(
      module,
      filters,
      query,
      threatTypesFromApi,
      iPhoneModelMap,
      groups
    );

    return {
      data: tableData?.content ?? [],
      count: tableData?.data.totalElements ?? 0,
    };
  });
};
export default memoizeQuery;
