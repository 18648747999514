import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    buttonPanel: {
      alignItems: 'flex-start',
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '10px 0 20px',
    },

    description: {
      marginBottom: 24,
      marginTop: 24,
    },
  })
);

export default useStyles;
