export const URL_REGEX =
  /^(?:(?:https?):\/\/)(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i;

export const PROTOCOL_HOSTNAME_REGEX = /^(https?:\/\/)?.*\.{1,}.*\//i;

export const validateAndSplitHref = (href: string): string[] | undefined => {
  if (!URL_REGEX.test(href)) {
    return;
  }

  const domainWithProtocol = href.match(PROTOCOL_HOSTNAME_REGEX)?.[0];

  if (!domainWithProtocol) {
    return;
  }

  const path = href.split(PROTOCOL_HOSTNAME_REGEX)?.pop() ?? '';

  return [domainWithProtocol, path];
};

export const DOMAIN_REGEX = new RegExp(
  /^(((?!-))?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*?([a-z0-9-]{1,61}|[a-z0-9-]{1,30})\.[a-z]{2,}$/
);

export const checkIfDomainIsValid = (value: string) => {
  const prefix = 'www.';

  if (value.startsWith(prefix)) {
    const split = value.split(prefix);
    return DOMAIN_REGEX.test(split[1]);
  }

  return DOMAIN_REGEX.test(value);
};

export const isValidHttpUrl = (urlString: string) => {
  try {
    const url = new URL(urlString);
    return checkIfDomainIsValid(url.hostname);
  } catch (_) {
    return false;
  }
};
