import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import withRouter from 'components/hocs/withRouter';
import _ from 'lodash';
import { Formik, Field, Form } from 'formik';
import Tooltip from '@material-ui/core/Tooltip';
import Info from '@material-ui/icons/Info';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { FormikCheckbox } from 'components/inputs/FormikCheckbox';

import { fetchAppSettingsById } from 'api/AppSettingsService';
import { getAllAppsByGroupIds } from 'api/InAppsService';
import LastDeployedBox from '../common/LastDeployedBox';

const initialState = {
  cogitoEnabled: true,
  dangerzoneEnabled: false,
  detectionEnabled: true,
  phishingEnabled: true,
  phishingLocalClassifierEnabled: false,
  appRiskLookupEnabled: false,
  autoBatteryOptimizationEnabled: false,
  privacySummaryEnabled: false,
  forensicAnalysisEnabled: false,
};

const AppSettingsForm = (props) => {
  const policyId = props?.selectedAppSettings?.value ?? '';

  const { t } = useTranslation();

  const grabAttachedZapps = (groups) => {
    if (_.isEmpty(groups)) {
      return props.setAttachedApps([]);
    }
    const groupIdList = groups.map((group) => {
      return group.id;
    });
    if (!_.isEmpty(groupIdList)) {
      getAllAppsByGroupIds(groupIdList)
        .then((resp) => {
          props.setAttachedApps(resp.data);
        })
        .catch((error) => {
          props.setAttachedApps([]);
          console.log('error fetching app by groups', error);
        });
    } else {
      return [];
    }
  };
  useEffect(() => {
    if (!_.isEmpty(policyId))
      fetchAppSettingsById({ id: policyId })
        .then(({ data }) => {
          props.setAppSettingsData(data);
          grabAttachedZapps(_.get(data, 'groups', []));
        })
        .catch((error) => console.error(error));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [policyId]);

  if (!policyId) {
    return (
      <Card>
        <CardContent>
          {t('MTD.POLICIES.APP_SETTINGS.NO_APP_CREATE_NEW_APP_SETTINGS')}
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      <LastDeployedBox
        lastDeployedTime={props?.appSettingsData?.modified}
        isBox
      />
      <Card>
        <CardContent>
          <Formik
            initialValues={{
              ...initialState,
              ...props.appSettingsData,
            }}
            onSubmit={props.save}
            enableReinitialize
          >
            {({ values, resetForm }) => {
              if (!values.phishingEnabled) {
                values.phishingLocalClassifierEnabled = false;
              }
              if (
                !!props.formRef?.current &&
                !props.formRef.current.resetForm
              ) {
                props.formRef.current.resetForm = resetForm;
              }
              return (
                <Form id="appSettingsForm">
                  <div className="flex--start">
                    <Field
                      disabled={!props.editMode}
                      name="cogitoEnabled"
                      label={t(
                        'MTD.POLICIES.APP_SETTINGS.ENABLE_Z9_MACHINE_LEARNING'
                      )}
                      component={FormikCheckbox}
                      type="checkbox"
                      checked={values.cogitoEnabled}
                    />
                    <Tooltip
                      enterDelay={300}
                      placement="right"
                      title={
                        <span style={{ fontSize: 12 }}>
                          {t(
                            'MTD.POLICIES.APP_SETTINGS.CHECK_ENABLE_Z9_MACHINE_LEARNING'
                          )}
                        </span>
                      }
                    >
                      <Info />
                    </Tooltip>
                  </div>
                  <div className="flex--start">
                    <Field
                      disabled={!props.editMode}
                      name="detectionEnabled"
                      label={t(
                        'MTD.POLICIES.APP_SETTINGS.ENABLE_DETECTION_ENGINE'
                      )}
                      component={FormikCheckbox}
                      type="checkbox"
                      checked={values.detectionEnabled}
                    />
                    <Tooltip
                      enterDelay={300}
                      placement="right"
                      title={
                        <span style={{ fontSize: 12 }}>
                          {t(
                            'MTD.POLICIES.APP_SETTINGS.CHECK_ENABLE_DETECTION_ENGINE'
                          )}
                        </span>
                      }
                    >
                      <Info />
                    </Tooltip>
                  </div>
                  <div className="flex--start">
                    <Field
                      disabled={!props.editMode}
                      name="dangerzoneEnabled"
                      label={t('MTD.POLICIES.APP_SETTINGS.ENABLE_DANGER_ZONE')}
                      component={FormikCheckbox}
                      type="checkbox"
                      checked={values.dangerzoneEnabled}
                    />{' '}
                    <Tooltip
                      enterDelay={300}
                      placement="right"
                      title={
                        <span style={{ fontSize: 12 }}>
                          {t(
                            'MTD.POLICIES.APP_SETTINGS.CHECK_ENABLE_DANGER_ZONE'
                          )}
                        </span>
                      }
                    >
                      <Info />
                    </Tooltip>
                  </div>
                  <div className="flex--start">
                    <Field
                      disabled={!props.editMode}
                      name="phishingEnabled"
                      label={t(
                        'MTD.POLICIES.APP_SETTINGS.ENABLE_PHISHING_DETECTION'
                      )}
                      component={FormikCheckbox}
                      type="checkbox"
                      checked={values.phishingEnabled}
                    />{' '}
                    <Tooltip
                      enterDelay={300}
                      placement="right"
                      title={
                        <span style={{ fontSize: 12 }}>
                          {t(
                            'MTD.POLICIES.APP_SETTINGS.CHECK_ENABLE_PHISHING_DETECTION'
                          )}
                        </span>
                      }
                    >
                      <Info />
                    </Tooltip>
                  </div>
                  <div style={{ marginLeft: 24 }}>
                    <Field
                      disabled={!props.editMode || !values.phishingEnabled}
                      name="phishingLocalClassifierEnabled"
                      label={t(
                        'MTD.POLICIES.APP_SETTINGS.ENABLE_PHISHING_MACHINE_LEARNING'
                      )}
                      component={FormikCheckbox}
                      type="checkbox"
                      checked={values.phishingLocalClassifierEnabled}
                    />
                  </div>
                  <div className="flex--start">
                    {' '}
                    <Field
                      disabled={!props.editMode}
                      name="appRiskLookupEnabled"
                      label={t(
                        'MTD.POLICIES.APP_SETTINGS.ENABLE_APP_RISK_LOOKUP'
                      )}
                      component={FormikCheckbox}
                      type="checkbox"
                      checked={values.appRiskLookupEnabled}
                    />
                    <Tooltip
                      enterDelay={300}
                      placement="right"
                      title={
                        <span style={{ fontSize: 12 }}>
                          {t(
                            'MTD.POLICIES.APP_SETTINGS.CHECK_ENABLE_APP_RISK_LOOKUP'
                          )}
                        </span>
                      }
                    >
                      <Info />
                    </Tooltip>
                  </div>
                  <div className="flex--start">
                    {' '}
                    <Field
                      disabled={!props.editMode}
                      name="autoBatteryOptimizationEnabled"
                      label={t(
                        'MTD.POLICIES.APP_SETTINGS.ENABLE_ANDROID_BATTERY_OPTIMIZATION'
                      )}
                      component={FormikCheckbox}
                      type="checkbox"
                      checked={values.autoBatteryOptimizationEnabled}
                    />
                    <Tooltip
                      enterDelay={300}
                      placement="right"
                      title={
                        <span style={{ fontSize: 12 }}>
                          {t(
                            'MTD.POLICIES.APP_SETTINGS.CHECK_ENABLE_ANDROID_BATTERY_OPTIMIZATION'
                          )}
                        </span>
                      }
                    >
                      <Info />
                    </Tooltip>
                  </div>
                  <div className="flex--start">
                    {' '}
                    <Field
                      disabled={!props.editMode}
                      name="privacySummaryEnabled"
                      label={t(
                        'MTD.POLICIES.APP_SETTINGS.ENABLE_PRIVACY_SUMMARY'
                      )}
                      component={FormikCheckbox}
                      type="checkbox"
                      checked={values.privacySummaryEnabled}
                    />
                    <Tooltip
                      enterDelay={300}
                      placement="right"
                      title={
                        <span style={{ fontSize: 12 }}>
                          {t(
                            'MTD.POLICIES.APP_SETTINGS.CHECK_ENABLE_PRIVACY_SUMMARY'
                          )}
                        </span>
                      }
                    >
                      <Info />
                    </Tooltip>
                  </div>
                  <div className="flex--start">
                    {' '}
                    <Field
                      disabled={!props.editMode}
                      name="forensicAnalysisEnabled"
                      label={t(
                        'MTD.POLICIES.APP_SETTINGS.ENABLE_FORENSIC_ANALYSIS'
                      )}
                      component={FormikCheckbox}
                      type="checkbox"
                      checked={values.forensicAnalysisEnabled}
                    />
                    <Tooltip
                      enterDelay={300}
                      placement="right"
                      title={
                        <span style={{ fontSize: 12 }}>
                          {t(
                            'MTD.POLICIES.APP_SETTINGS.CHECK_ENABLE_FORENSIC_ANALYSIS' //TODO:add tooltip text for forensicAnalysisEnabled
                          )}
                        </span>
                      }
                    >
                      <Info />
                    </Tooltip>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </CardContent>
      </Card>
    </>
  );
};

export default withRouter(AppSettingsForm);
