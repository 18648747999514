import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingBottom: '25px',
      paddingTop: '25px',
      width: '100%',
      '& > button': { boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.38)' },
    },
    buttonBackgroundColor: {
      backgroundColor: palette.charts.icon,
      color: palette.common.white,
      '&:hover': {
        backgroundColor: palette.charts.icon,
      },
    },
    maxIdleSkeleton: {
      '& div > div': {
        width: '120px',
      },
    },
    nestedGroups: {
      alignItems: 'center',
      display: 'flex',
      fontSize: '12px',
      '& > label > span:nth-child(2)': {
        color: palette.text.primary,
        whiteSpace: 'nowrap',
      },
      justifyItems: 'flex-start',
      marginTop: '12px',
    },
    policiesSkeleton: {
      height: 115,
    },
    policiesSkeletonWrapper: {
      marginTop: 25,
    },
    skeletonWithSpace: {
      '& div > div': {
        width: '410px',
      },
    },
    skeletonWithDeviceSpace: {
      '& div > div': {
        width: '300px',
      },
    },
    skeletonContainer: {
      display: 'flex',
      gap: '20px',
      justifyContent: 'space-between',
    },
    selectGroupContainer: {
      dispaly: 'flex',
      flexDirection: 'column',
    },

    skeletonGroups: {
      '& div > div': {
        width: '800px',
      },
    },
    titleStyles: {
      color: palette.text.primary,
      fontFamily: 'Roboto',
      fontSize: ' 20px',
      fontStyle: 'normal',
      fontweight: '700',
      lineHeight: ' 38px',
      letterSpacing: '0.028px',
    },
    mainSkeletonGroups: {
      display: 'flex',
    },
    policySkeleton: {
      '& div > div': {
        width: '400px',
      },
    },
    dividerStyle: {
      background: palette.background.public,
    },
  })
);

export default useStyles;
