import _ from 'lodash';
import React from 'react';
import MaterialTableRow from '@material-ui/core/TableRow';
import MaterialTableCell from '@material-ui/core/TableCell';

import TableRow from './TableRow';

/**
 * Name: withLoader
 * Description: wraps the render of table rows adding the ability to show
 * a loader or a No Data state.
 *
 *  @param props - {object} props from the component calling the function
 *  @param parentRender - {function} the method called to render the actual table data
 */
export const withLoader = (props, parentRender, t) => {
  if (props.children) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }

  if (props.isLoading) {
    return Array(6)
      .fill(null)
      .map((val, i) => {
        return (
          <TableRow
            key={`loading-row-${i}`}
            classList={props.classList}
            isLoading={props.isLoading}
          />
        );
      });
  }

  // no data state
  if (!props.isLoading && _.isEmpty(props.tableData)) {
    return (
      <MaterialTableRow className={props.classList.tableRow} padding="none">
        <MaterialTableCell
          colSpan="20"
          className={props.classList.tableCellNoData}
          style={{ textAlign: 'center' }}
        >
          {!!t ? t('GLOBAL.NO_DATA') : 'No Data'}
        </MaterialTableCell>
      </MaterialTableRow>
    );
  }

  return parentRender(props);
};
