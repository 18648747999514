import React, { Fragment } from 'react';

// components
import ZButton from 'UI/Buttons/ZButton';

// ui
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {
  toggleModalDiffered,
  toggleModalDirect,
  openSnackBar,
} from 'utils/storeUtils';

// actions
import { publishEvent } from 'utils/eventUtils';
import { deleteGroupActivation } from 'api/GroupActivationsService';
import { useTranslation } from 'react-i18next';

function GroupActivationsDeleteConfirm(props) {
  const { t, ready } = useTranslation();

  function handleSubmit() {
    deleteGroupActivation({ id: props.data.rowData.id })
      .then(() => {
        toggleModalDirect('GroupActivationsDeleteConfirm', false);
        openSnackBar(
          t('MTD.ACTIVATIONS.GROUP_ACTIVATION_SUCCESSFULLY_DELETED')
        );
        publishEvent('table:force-fetch-groupActivations');
      })
      .catch((error) => {
        toggleModalDirect('GroupActivationsDeleteConfirm', false);
        openSnackBar(t('MTD.ACTIVATIONS.UNABLE_TO_DELETE_GROUP_ACTIVATION'));
        console.log(error);
      });
  }

  if (!ready) {
    return null;
  }

  return (
    <Fragment>
      <DialogContent>
        <p>
          {t('GLOBAL.CONFIRM_DELETE_VALUE', {
            valueToDelete: props.data.rowData.name,
          })}
        </p>
        <DialogActions>
          <ZButton
            styleName="modalCancel"
            buttonText={t('GLOBAL.CANCEL')}
            color="secondary"
            action={toggleModalDiffered('GroupActivationsDeleteConfirm', false)}
          />
          <ZButton
            buttonType="submit"
            styleName="modalDelete"
            buttonText={t('GLOBAL.DELETE')}
            color="primary"
            action={handleSubmit}
          />
        </DialogActions>
      </DialogContent>
    </Fragment>
  );
}

export default GroupActivationsDeleteConfirm;
