import MUIFormControl from '@material-ui/core/FormControl';
import MUIFormLabel from '@material-ui/core/FormLabel';
import MUIRadioGroup from '@material-ui/core/RadioGroup';
import {
  deletePolicy as deleteNetworkPolicy,
  updatePolicy as updateNetworkPolicy,
} from 'api/NetworkPolicyService';
import cc from 'classcat';
import Button from 'components/UI/Button';
import GenericTable from 'components/UI/GenericTable';
import DeleteIcon from 'components/UI/icons/DeleteIcon';
import EditIcon from 'components/UI/icons/EditIcon';
import { Radio } from 'components/UI/input/RadioGroup';
import { ISelectItem } from 'components/UI/input/Select';
import withRouter from 'components/hocs/withRouter';
import { TabContent as TabContentJs, Tabs } from 'components/tabs';
import _ from 'lodash';
import React, { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyPolicies from '../common/EmptyPolicies';
import PolicySelector from '../common/PolicySelector';
import AddCertificateModal from './AddCertificateModal';
import AddSsidModal from './AddSSIDModal';
import SinkholeCountries from './SinkholeCountries';
import SinkholeDomains from './SinkholeDomains';
import SinkholeIpAdresses from './SinkholeIpAdresses';
import {
  ICertificate,
  INetworkPolicyRequest,
  INetworkPolicyRules,
  IWifiAccessPoint,
} from './models';
import { fetchPolicies, fetchPolicyRulesById } from './tableQuery';
import useStyles from './useStyles';

const TabContent: any = TabContentJs;

enum RuleType {
  Certificate = 'Certificate',
  WifiAccessPoint = 'Wi-Fi Access Point',
}

const defaultPolicy: INetworkPolicyRules = {
  id: '',
  accountId: '',
  team: undefined,
  name: '',
  created: undefined,
  modified: undefined,
  sinkholeSettings: {
    sinkholeAllow: false,
    ipAddresses: [],
    domains: [],
    countries: [],
  },
  certificates: [],
  wifiAccessPoints: [],
  groups: [],
};

interface INetworkPolicyProps {
  location: {
    query: {
      network: string;
    };
  };
}

const NetworkPolicy: React.FC<INetworkPolicyProps> = (props): ReactElement => {
  const classes = useStyles();
  const { t, ready } = useTranslation();

  const [isCertificatesOpen, setIsCertificatesOpen] = useState(false);
  const [isSsidOpen, setIsSsidOpen] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState<ISelectItem>();
  const [currentPolicy, setCurrentPolicy] = useState(defaultPolicy);
  const [policyDetails, setPolicyDetails] = useState(defaultPolicy);

  const getRequestObject = (
    policy?: INetworkPolicyRules
  ): INetworkPolicyRequest | undefined => {
    if (!policy) {
      return undefined;
    }

    return {
      ...policy,
      certificates: _.sortBy(
        policy.certificates.map(({ id, enabled }) => ({
          id,
          enabled,
        })),
        (cert) => cert.id
      ),
      wifiAccessPoints: _.sortBy(
        policy.wifiAccessPoints.map(({ ...ssid }) => ssid),
        (ssid) => ssid.ssid
      ),
      sinkholeSettings: {
        ...policy.sinkholeSettings,
        ipAddresses: _.sortBy(
          policy.sinkholeSettings.ipAddresses.map(({ ipAddress, ipMask }) => ({
            ipAddress: ipAddress.trim(),
            ipMask: ipMask.trim(),
          })),
          (ip) => ip.ipAddress
        ),
        domains: _.sortBy(
          policy.sinkholeSettings.domains.map(({ domain }) => ({
            domain,
          })),
          (obj) => obj.domain
        ),
        countries: _.sortBy(
          policy.sinkholeSettings.countries.map(({ countryCode }) => ({
            countryCode,
          })),
          (obj) => obj.countryCode
        ),
      },
    };
  };

  function onRulesChanged<
    Key extends keyof Omit<
      INetworkPolicyRules['sinkholeSettings'],
      'sinkholeAllow'
    >
  >(key: Key, value: INetworkPolicyRules['sinkholeSettings'][Key]): void {
    const newValues = { ...currentPolicy };
    newValues.sinkholeSettings[key] = value;

    setCurrentPolicy(newValues);
  }

  const onCertificatesChanged = useCallback(
    (certificates: ICertificate[]): void => {
      if (!_.isEqual(certificates, currentPolicy.certificates)) {
        const newCurrentPolicy = {
          ...currentPolicy,
          certificates: [...certificates],
        };

        setCurrentPolicy(newCurrentPolicy);
      }
    },
    [currentPolicy]
  );

  const onWifiAccessPointsChanged = useCallback(
    (wifiAccessPoints: IWifiAccessPoint[]): void => {
      if (!_.isEqual(wifiAccessPoints, currentPolicy.wifiAccessPoints)) {
        const newCurrentPolicy = {
          ...currentPolicy,
          wifiAccessPoints: [...wifiAccessPoints],
        };

        setCurrentPolicy(newCurrentPolicy);
      }
    },
    [currentPolicy]
  );

  const onModalClose = (): void => {
    setIsCertificatesOpen(false);
    setIsSsidOpen(false);
  };

  const onEditRuleClick = (item: any): void => {
    switch (item.ruleType) {
      case RuleType.Certificate:
        setIsCertificatesOpen(true);
        break;
      case RuleType.WifiAccessPoint:
        setIsSsidOpen(true);
        break;
      default:
        break;
    }
  };

  const onRuleActionchange = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, value: string): void => {
      const isAllow = value === 'allow';

      setCurrentPolicy({
        ...currentPolicy,
        sinkholeSettings: {
          ...currentPolicy.sinkholeSettings,
          sinkholeAllow: isAllow,
        },
      });
    },
    [currentPolicy]
  );

  if (!ready) {
    return <></>;
  }

  return (
    <div className={classes.root} style={{ gap: '40px' }}>
      <PolicySelector
        query={props.location.query.network}
        policyType="Network"
        canDeploy={() => true}
        getRequestObject={getRequestObject}
        fetchPolicies={fetchPolicies}
        fetchPolicyDetails={fetchPolicyRulesById}
        updatePolicy={updateNetworkPolicy}
        deletePolicy={deleteNetworkPolicy}
        selectedPolicy={selectedPolicy}
        setSelectedPolicy={setSelectedPolicy}
        currentPolicy={currentPolicy}
        setCurrentPolicy={setCurrentPolicy}
        policyDetails={policyDetails}
        setPolicyDetails={setPolicyDetails}
      />

      {!policyDetails.id.trim() && <EmptyPolicies />}

      {selectedPolicy && policyDetails && (
        <>
          <div className={classes.card}>
            <div className={cc([classes.cardTitle, classes.cardBold])}>
              <span>
                {t('MTD.POLICIES.NETWORK_POLICY.NETWORK_POLICY_RULES')}
              </span>
            </div>

            <div className={classes.cardContent}>
              <GenericTable
                columnHeaders={[
                  {
                    id: 'ruleType',
                    label: t('MTD.POLICIES.NETWORK_POLICY.RULE_TYPE'),
                  },
                  {
                    id: 'ruleAction',
                    label: t('MTD.POLICIES.NETWORK_POLICY.RULE_ACTION'),
                  },
                  { id: 'numRows', label: `# ${t('GLOBAL.ROWS')}` },
                  { id: 'actions', label: t('GLOBAL.ACTIONS') },
                ]}
                rowMapping={[
                  {
                    path: 'actions',
                    columnContent: ({ rowData }) => (
                      <>
                        <Button
                          style={{ padding: 0, minWidth: 'auto' }}
                          variant="text"
                          icon={EditIcon}
                          onClick={() => onEditRuleClick(rowData)}
                        />
                        <Button
                          style={{
                            padding: 0,
                            minWidth: 'auto',
                            display: 'none',
                          }}
                          variant="text"
                          icon={DeleteIcon}
                        />
                      </>
                    ),
                  },
                ]}
                tableData={
                  [
                    {
                      ruleType: RuleType.Certificate,
                      ruleAction: 'Allow',
                      numRows: currentPolicy.certificates.filter(
                        (cert) => cert.enabled
                      ).length,
                    },
                    {
                      ruleType: RuleType.WifiAccessPoint,
                      ruleAction: 'Allow',
                      numRows: currentPolicy.wifiAccessPoints.filter(
                        (ssid) => ssid.enabled
                      ).length,
                    },
                  ] as any
                }
                tableId="Network Policy Rules"
              />
            </div>
          </div>
        </>
      )}

      {selectedPolicy && policyDetails && (
        <div className={classes.card}>
          <div className={cc([classes.cardTitle, classes.cardBold])}>
            <div>
              {t('MTD.POLICIES.NETWORK_POLICY.NETWORK_SINKHOLE_RESPONSE')}
            </div>
          </div>

          <div className={classes.cardContent}>
            <span style={{ fontSize: 'small' }}>
              {t('MTD.POLICIES.NETWORK_POLICY.NETWORK_THREAT_EVENTS_CONFIGURE')}
            </span>

            <MUIFormControl style={{ width: 'fit-content' }}>
              <MUIFormLabel component="legend" style={{ fontSize: 'small' }}>
                {t('MTD.POLICIES.NETWORK_POLICY.SET_DEFAULT_ACTION')}
              </MUIFormLabel>
              <MUIRadioGroup
                value={
                  currentPolicy.sinkholeSettings.sinkholeAllow
                    ? 'allow'
                    : 'block'
                }
                onChange={onRuleActionchange}
              >
                <Radio
                  classList={classes}
                  value="allow"
                  label={t(
                    'MTD.POLICIES.NETWORK_POLICY.BLOCK_OUTGOING_NETWORK_TRAFFIC'
                  )}
                />
                <Radio
                  value="block"
                  classList={classes}
                  label={t(
                    'MTD.POLICIES.NETWORK_POLICY.ALLOW_OUTGOING_NETWORK_TRAFFIC'
                  )}
                />
              </MUIRadioGroup>
            </MUIFormControl>

            <Tabs initialTab="ipAdresses">
              <TabContent
                label={t('MTD.POLICIES.NETWORK_POLICY.IP_ADDRESSES')}
                id="ipAdresses"
              >
                <SinkholeIpAdresses
                  key={`ip-addresses-${currentPolicy.name}`}
                  onIpAddressesChanged={onRulesChanged}
                  ipAddresses={currentPolicy.sinkholeSettings.ipAddresses}
                />
              </TabContent>
              <TabContent
                label={t('MTD.POLICIES.NETWORK_POLICY.DOMAINS')}
                id="domains"
              >
                <SinkholeDomains
                  key={`domains-${currentPolicy.name}`}
                  onDomainsChanged={onRulesChanged}
                  domains={currentPolicy.sinkholeSettings.domains}
                />
              </TabContent>
              <TabContent
                label={t('MTD.POLICIES.NETWORK_POLICY.COUNTRIES')}
                id="countries"
              >
                <SinkholeCountries
                  key={`countries-${currentPolicy.name}`}
                  onCountriesChanged={onRulesChanged}
                  countries={currentPolicy.sinkholeSettings.countries}
                />
              </TabContent>
            </Tabs>
          </div>
        </div>
      )}

      {selectedPolicy &&
        policyDetails.id === currentPolicy.id &&
        selectedPolicy.value === currentPolicy.id && (
          <>
            <AddCertificateModal
              isOpen={isCertificatesOpen}
              onClose={onModalClose}
              onCertificatesChanged={onCertificatesChanged}
              certificates={currentPolicy.certificates}
              policyId={policyDetails.id}
            />
            <AddSsidModal
              isOpen={isSsidOpen}
              onClose={onModalClose}
              onWifiAccessPointsChanged={onWifiAccessPointsChanged}
              wifiAccessPoints={currentPolicy.wifiAccessPoints}
            />
          </>
        )}
    </div>
  );
};

export default withRouter(NetworkPolicy);
