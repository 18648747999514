import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ContentCopyIcon from '@material-ui/icons/FilterNone';
import GlobalIndicatorWrapper from 'components/main/common/GlobalIndicatorWrapper';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import { editModalConfig } from 'components/modals/RolesCreateEdit';
import _ from 'lodash';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { openSnackBarDiffered, toggleModalDiffered } from 'utils/storeUtils';

export const defaultRolesTableHeaders = [
  {
    flex: 1,
    id: 'id',
    label: 'Role ID',
    minWidth: 100,
    numeric: false,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: false,
  },
  {
    flex: 1,
    id: 'name',
    label: 'Name',
    minWidth: 250,
    numeric: false,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    flex: 1,
    id: 'description',
    label: 'Description',
    minWidth: 250,
    numeric: false,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    flex: 0,
    id: 'actions',
    isProtected: true,
    label: 'Actions',
    minWidth: 125,
    numeric: false,
    scope: 'roles',
    show: true,
    showInContextMenu: true,
    sort: false,
  },
];

export const rolesColumnMapping = [
  {
    path: 'id',
    columnContent: ({ rowData }) => {
      const id = _.get(rowData, 'id', '');

      return (
        <CopyToClipboard
          text={id}
          onCopy={openSnackBarDiffered('Role Id copied to clipboard')}
        >
          <span
            className="flex--spaced cursor--pointer"
            style={{ maxWidth: 200 }}
          >
            {' '}
            <span className="text__ellipsis">{id}</span>
            <ContentCopyIcon />
          </span>
        </CopyToClipboard>
      );
    },
  },
  {
    path: 'name',
    columnContent: ({ rowData }) => {
      const name = _.get(rowData, 'name', '');
      const bounds = _.get(rowData, 'bounds');

      return (
        <GlobalIndicatorWrapper
          isGlobal={bounds === 'Account'}
          text={name}
          inline
        />
      );
    },
  },
  {
    path: 'description',
  },
  {
    path: 'actions',
    columnContent: ({ rowData }) => {
      const defaultScopes = _.get(rowData, 'defaultScopes');
      const viewOnlyScope = _.get(rowData, 'viewOnlyScope');

      return (
        <>
          <ProtectedComponent allow={{ roles: 'view' }}>
            <EditIcon
              className="cursor--pointer"
              onClick={toggleModalDiffered(
                'RolesCreateEdit',
                {
                  defaultScopes,
                  viewOnlyScope,
                  rowData,
                },
                editModalConfig
              )}
            />
          </ProtectedComponent>
          <ProtectedComponent allow={{ roles: 'manage' }}>
            <DeleteIcon
              className="cursor--pointer"
              onClick={toggleModalDiffered(
                'RolesDeleteConfirm',
                {
                  rowData,
                },
                { title: 'Delete Role' }
              )}
            />
          </ProtectedComponent>
        </>
      );
    },
  },
];
