import _ from 'lodash';
import { Assessment, AssessmentHash, Query, TableData } from './models';

export const prepareCompareBuilds = ({
  setTotalDataCount,
  query,
  rawTableData,
}: {
  setTotalDataCount: React.Dispatch<React.SetStateAction<number>>;
  query: Query;
  rawTableData: {
    combined: AssessmentHash;
    shared: AssessmentHash;
    fullUniqFindingsAssessment1: AssessmentHash;
    fullUniqFindingsAssessment2: AssessmentHash;
  };
}): TableData => {
  const combinedTableData = cleanUpForCombinedLocations(rawTableData.combined);

  const sharedTableData = cleanUpForSharedLocations(rawTableData.shared);

  const unq1 =
    cleanUniqForLocations(rawTableData.fullUniqFindingsAssessment1, 1) ?? [];

  const unq2 =
    cleanUniqForLocations(rawTableData.fullUniqFindingsAssessment2, 2) ?? [];

  switch (query.cbo) {
    case 'combined':
      setTotalDataCount(combinedTableData?.length);
      return {
        tableData: sortByQuery(query, combinedTableData) ?? [],
        totalResults: combinedTableData?.length || 0,
      };
    case 'shared':
      setTotalDataCount(sharedTableData?.length);
      return {
        tableData: sortByQuery(query, sharedTableData) ?? [],
        totalResults: sharedTableData?.length || 0,
      };
    case 'unq1':
      setTotalDataCount(unq1?.length);
      return {
        tableData: sortByQuery(query, unq1) ?? [],
        totalResults: unq1?.length || 0,
      };
    case 'unq2':
      setTotalDataCount(unq2?.length);
      return {
        tableData: sortByQuery(query, unq2) ?? [],
        totalResults: unq2?.length || 0,
      };
    default:
      setTotalDataCount(sharedTableData?.length);
      return {
        tableData: sharedTableData,
        totalResults: sharedTableData?.length || 0,
      };
  }
};

const sortByQuery = (query: Query, data: any[]): any[] => {
  let inversion = -1;
  if (query.order === 'asc') {
    inversion = 1;
  }

  if (query.orderBy === 'severityOrdinal' && inversion === -1) {
    return data.sort((a, b) => a.severityOrdinal - b.severityOrdinal).reverse();
  }
  if (query.orderBy === 'severityOrdinal' && inversion === 1) {
    return data.sort((a, b) => a.severityOrdinal - b.severityOrdinal);
  }
  return data.sort((a, b) => {
    return a[query.orderBy]?.localeCompare(b[query.orderBy]) * inversion;
  });
};

export const compareFindingAndLocations = (
  assessmentOne: Assessment[],
  assessmentTwo: Assessment[]
): any => {
  const assessmentOneById: any = assessmentOne.reduce((accum, a) => {
    return {
      ...accum,
      [a.findingId]: a,
    };
  }, {});
  const assessmentTwoById: any = assessmentTwo.reduce((accum, a) => {
    return {
      ...accum,
      [a.findingId]: a,
    };
  }, {});

  const uniqFindingsAssessment1 = assessmentTwo.reduce((accum, assessment) => {
    if (assessmentOneById[assessment.findingId] === undefined) {
      return {
        ...accum,
        [assessment.findingId]: assessment,
      };
    }
    return accum;
  }, {});
  const uniqFindingsAssessment2 = assessmentOne.reduce((accum, assessment) => {
    if (assessmentTwoById[assessment.findingId] === undefined) {
      return {
        ...accum,
        [assessment.findingId]: assessment,
      };
    }
    return accum;
  }, {});

  const allAssessmentsById = assessmentTwo.reduce(
    (accum, assessment) => {
      const previousAssessment = accum.combined[assessment.findingId];
      if (previousAssessment === undefined) {
        return {
          ...accum,
          combined: {
            ...accum.combined,
            [assessment.findingId]: assessment,
          },
        };
      }

      const combinedLocations = _.unionBy(
        previousAssessment.locations,
        assessment.locations,
        'locationId'
      );

      const assessmentWithCombinedLocations = {
        ...assessment,
        locations: combinedLocations,
        totalLocationsCount: combinedLocations.length,
      };

      const sharedLocations = _.intersectionBy(
        previousAssessment.locations,
        assessment.locations,
        'locationId'
      );

      const uniqLocationsBuild2 = _.differenceBy(
        previousAssessment.locations,
        assessment.locations,
        'locationId'
      );

      const uniqLocationsBuild1 = _.differenceBy(
        assessment.locations,
        previousAssessment.locations,
        'locationId'
      );

      const assessmentWithSharedLocations = {
        ...assessment,
        locationsShared: sharedLocations,
        uniqLocationsBuild1,
        uniqLocationsBuild2,
        totalLocationsCount: sharedLocations.length,
      };

      const sharedObj: any = {
        ...accum.shared,
      };

      sharedObj[assessment.findingId] = assessmentWithSharedLocations;

      return {
        ...accum,
        combined: {
          ...accum.combined,
          [assessment.findingId]: assessmentWithCombinedLocations,
        },
        shared: sharedObj,
        fullUniqFindingsAssessment1: {
          ...accum.fullUniqFindingsAssessment1,
          ...uniqFindingsAssessment1,
          ...sharedObj,
        },
        fullUniqFindingsAssessment2: {
          ...accum.fullUniqFindingsAssessment2,
          ...uniqFindingsAssessment2,
          ...sharedObj,
        },
      };
    },
    {
      combined: assessmentOneById,
      shared: {},
      fullUniqFindingsAssessment1: {},
      fullUniqFindingsAssessment2: {},
    }
  );

  return allAssessmentsById;
};

const cleanUniqForLocations = (
  assessmentHash: AssessmentHash,
  comparisonNumber: number
): any[] => {
  return Object.keys(assessmentHash)
    .map((key) => {
      const fullAssessment: any = assessmentHash[key];

      if (fullAssessment[`uniqLocationsBuild${comparisonNumber.toString()}`]) {
        return {
          ...fullAssessment,
          locations:
            fullAssessment[`uniqLocationsBuild${comparisonNumber.toString()}`],
          totalLocationsCount:
            fullAssessment[`uniqLocationsBuild${comparisonNumber.toString()}`]
              .length,
        };
      }
      return null;
    })
    .filter((assessment) => assessment);
};

const cleanUpForSharedLocations = (assessmentHash: AssessmentHash): any[] => {
  return Object.keys(assessmentHash)
    .map((key) => {
      const fullAssessment: any = assessmentHash[key];
      if (fullAssessment.locationsShared.length > 0) {
        return {
          ...fullAssessment,
          locations: fullAssessment.locationsShared,
          totalLocationsCount: fullAssessment.locationsShared.length,
        };
      }
      return null;
    })
    .filter((assessment) => assessment);
};

const cleanUpForCombinedLocations = (assessmentHash: AssessmentHash): any[] => {
  return Object.keys(assessmentHash)
    .map((key) => {
      const fullAssessment = assessmentHash[key];
      if (fullAssessment.locations.length > 0) {
        return {
          ...fullAssessment,
          locations: fullAssessment.locations,
          totalLocationsCount: fullAssessment.locations.length,
        };
      }
      return null;
    })
    .filter((assessment) => assessment);
};

// const metaDataFindingFilter = (a: any) => {
//   if (_.isEmpty(a)) {
//     return false;
//   }
//   const locations = _.get(a, 'locations', []);
//   const issueStatus = _.get(a, 'issue_status', 'OPEN');
//   const policyInclusion = _.get(a, 'policy_inclusion', false);
//   const acceptedStatus = _.get(a, 'accepted_status', false);

//   if (locations.length === 0) {
//     return false;
//   }
//   if (issueStatus === 'CLOSED') {
//     return false;
//   }
//   if (!policyInclusion) {
//     return false;
//   }
//   if (!acceptedStatus) {
//     return true;
//   }

//   return false;
// };
