import React, { useCallback } from 'react';
import CustomOption, {
  SeverityOptions,
} from '../../../../console/zdev/policies/mappings/CustomOption';
import SeverityCircle from 'components/main/ZScanApps/SeverityCircle';
import { useTranslation } from 'react-i18next';
import { ESeverity } from 'components/main/ZScanApps/SeverityCircle/models';
import { MenuItem, Select } from '@material-ui/core';
import { useRecoilState } from 'recoil';
import { zScanPolicyEntriesHash } from '../atoms';
import { IZscanIndicator } from '../models';

const getOptions = () =>
  Object.values(SeverityOptions).map((value) => {
    return (
      <MenuItem value={value} key={value} id="action-column">
        <SeverityCircle severity={value as ESeverity} showLabel />
      </MenuItem>
    );
  });

interface ISeveritySelectorProps {
  finding: IZscanIndicator;
  disabled?: boolean;
  onFindingUpdate?: (rowData: unknown, key: string) => void;
  policyId?: string;
}

const SeveritySelector: React.FC<ISeveritySelectorProps> = (props) => {
  const { finding, disabled } = props;
  const [zScanPolicyEntry, setzScanPolicyEntry] = useRecoilState(
    zScanPolicyEntriesHash
  );
  const findingSeverity =
    zScanPolicyEntry[finding?.id]?.severity ?? finding?.severity;

  const { t } = useTranslation();

  const getValue = useCallback(() => {
    const translatedLabelValue = t(`ZSCAN.SEVERITY.${findingSeverity}`);
    return { label: translatedLabelValue, value: findingSeverity };
  }, [findingSeverity, t]);

  const onSeverityChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const stringValue = e?.target?.value;
      // eslint-disable-next-line no-debugger
      setzScanPolicyEntry({
        ...zScanPolicyEntry,
        [finding?.id as any]: {
          severity: stringValue,
          enabled: finding?.enabled,
          findingId: finding?.id,
        },
      });
    },
    [finding?.enabled, finding?.id, setzScanPolicyEntry, zScanPolicyEntry]
  );
  return (
    <div style={{ width: '200px' }}>
      {disabled ? (
        <SeverityCircle
          severity={findingSeverity as ESeverity}
          showLabel={true}
        />
      ) : (
        <Select
          value={getValue().value}
          customOption={CustomOption}
          StartAdornment={CustomOption}
          onChange={onSeverityChange}
          // interactable={true}
        >
          {getOptions()}
        </Select>
      )}
    </div>
  );
};

export default React.memo(SeveritySelector);
