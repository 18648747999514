import React from 'react';
// import _ from 'lodash';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';

import { instancesByFindingIdTableHeaders as instancesTableHeaders } from './ZScanCompareAssessments.mappings';
import useStyles from './useStyles';
import { CompareInstancesByFindingIdTableProps } from './models';
import GenericCard from 'components/UI/GenericCard';
// Define the type for table headers
interface TableHeader {
  id: string;
  label: string;
  show: boolean;
}

// Define the type for the VersionTableHead component props
interface VersionTableHeadProps {
  currentTableHeaders: TableHeader[];
}

// VersionTableHead component
const VersionTableHead = (props: VersionTableHeadProps) => {
  const { currentTableHeaders } = props;

  return (
    <TableHead>
      <TableRow>
        {currentTableHeaders.map((column) => {
          if (column.show) {
            return (
              <TableCell key={column.id} padding="normal">
                {column.label}
              </TableCell>
            );
          }
          return null;
        })}
      </TableRow>
    </TableHead>
  );
};

// InstancesByFindingIdTable component
const CompareInstancesByFindingIdTable = ({
  locations,
}: CompareInstancesByFindingIdTableProps) => {
  const classes = useStyles();

  return (
    <GenericCard noPadding>
      <div className={classes.cardContent}>
        <Table stickyHeader>
          <VersionTableHead currentTableHeaders={instancesTableHeaders} />
          <TableBody>
            {Object.keys(locations).map((key, index) => {
              const n = locations[key];
              return (
                <TableRow key={`${n.id} ${index}`} className="classes.tableRow">
                  <Tooltip title={n.value}>
                    <TableCell className="text__ellipsis" padding="normal">
                      <Tooltip title={n.value}>
                        <>{n.value}</>
                      </Tooltip>
                    </TableCell>
                  </Tooltip>
                  {/* <TableCell padding="normal">
                    {_.upperFirst(n.accepted_status.toString())}
                  </TableCell> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </GenericCard>
  );
};
export default CompareInstancesByFindingIdTable;
