import { Form as FormikForm, Formik } from 'formik';
import { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { IDormancyPolicyForm } from './models';
import Schema from './schema';
import ThreatWarningCard from './ThreatWarningCard';

interface IFormProps {
  formRef?: MutableRefObject<HTMLFormElement | undefined>;
  resolvedInitialValues: IDormancyPolicyForm;
  reloadingPolicy: boolean;
  setReloadingPolicy: React.Dispatch<React.SetStateAction<boolean>>;
}

const Form: React.FC<IFormProps> = ({
  reloadingPolicy,
  resolvedInitialValues,
  setReloadingPolicy,
}) => {
  const { t } = useTranslation();
  return (
    <Formik
      enableReinitialize
      initialValues={resolvedInitialValues}
      onSubmit={() => {}} // This is handled up one level through deploy button
      validateOnBlur
      validationSchema={Schema()}
    >
      {({ dirty, isSubmitting, isValid, values, setFieldValue, resetForm }) => (
        <FormikForm id="policyDetailsForm">
          <input name="initialName" type="hidden" />
          <ThreatWarningCard
            dirty={dirty}
            isSubmitting={isSubmitting}
            isValid={isValid}
            resetForm={resetForm}
            reloadingPolicy={reloadingPolicy}
            setFieldValue={setFieldValue}
            setReloadingPolicy={setReloadingPolicy}
            title={t(
              'MTD.POLICIES.DEVICE_INACTIVITY.PENDING_ACTIVATION_THREAT_WARNINGS'
            )}
            suffix="PA"
            values={values}
          />
          <ThreatWarningCard
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
            title={t(
              'MTD.POLICIES.DEVICE_INACTIVITY.INACTIVE_APP_THREAT_WARNINGS'
            )}
            suffix="IA"
            showNotificationCheckbox
            values={values}
          />
        </FormikForm>
      )}
    </Formik>
  );
};

export default Form;
