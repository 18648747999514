const MalwareFamily = {
  0: { id: 0, name: 'Exploit' },
  1: { id: 2, name: 'HackTool' },
  2: { id: 3, name: 'Spyware' },
  3: { id: 3, name: 'Riskware' },
  4: { id: 4, name: 'Banker' },
  5: { id: 5, name: 'Adware' },
  6: { id: 6, name: 'Trojan' },
  7: { id: 7, name: 'Generic Malware' },
};

export default MalwareFamily;
