import { FormControlLabelProps as IMUIFormControlLabelProps } from '@material-ui/core';
import Popper, { PopperProps } from '@material-ui/core/Popper';
import HeadlessInfiniteScroll from 'components/UI/HeadlessInfiniteScroll';
import Checkbox from 'components/UI/input/Checkbox';
import Select, { ISelectItem } from 'components/UI/input/Select';
import { FieldProps as IFormikFieldProps } from 'formik';
import { useCallback, useMemo, useRef, useState } from 'react';
import { IFetchOptionsParams, IFetchOptionsResult } from './models';
import useStyles from './useStyles';

export interface IInfiniteSelectProps extends Partial<IFormikFieldProps> {
  disabled?: boolean;
  defaultValue?: ISelectItem;
  disableClearable: boolean;

  // This initialOptions prop is used because options are handled by this component.
  // If we were to allow options, the purpose of this component would be overridden.
  initialOptions: ISelectItem[];

  // Normally a 0 or 1, but (if not starting on 1st page) can be anything <= last page
  initialPage: number;
  label?: IMUIFormControlLabelProps | string;

  // User must calculate lastPage. This is to account for pages that start at either 0 or 1.
  // For pages that start at 0, this should be totalPages - 1
  // For pages that start with 1, this should be totalPages
  lastPage: number;
  name?: string;
  fetchOptions?: (params: IFetchOptionsParams) => Promise<IFetchOptionsResult>;
  optionValuesToExclude: string[];

  // pageOnSearch should only be set to true if totalElements and totalPages fields from
  // API change when passing in a value for search/query on API endpoint. If these values
  // stay they same whether or not you pass in search/query, you MUST leave this param off
  // or set it to false.
  pageOnSearch?: boolean;
  placeholder?: string;
  popperWidth: string;
  renderPopper?: (props: PopperProps) => JSX.Element;
  setFieldValue?: (
    field: string,
    value: ISelectItem,
    shouldValidate?: boolean | undefined
  ) => void;
  value?: ISelectItem;
  variant?: 'large';
  StartAdornment?: React.FC<ISelectItem | undefined>;
}

const InfiniteSelect: React.FC<IInfiniteSelectProps> = ({
  initialOptions,
  initialPage,
  fetchOptions,
  lastPage: lastPageFromProps,
  optionValuesToExclude,
  pageOnSearch = false,
  StartAdornment,
  ...rest
}) => {
  const classes = useStyles();
  const [baseOptions, setBaseOptions] = useState<ISelectItem[]>(initialOptions);
  const [apiFilteredOptions, setApiFilteredOptions] = useState<ISelectItem[]>(
    []
  );
  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const [lastPage, setLastPage] = useState<number>(lastPageFromProps);
  const [query, setQuery] = useState<string>('');

  const timeout = useRef<NodeJS.Timeout | null>(null);

  const addOptions = useCallback(
    (newOptions: ISelectItem[]) => {
      (!!query ? setApiFilteredOptions : setBaseOptions)((currOptions) => [
        ...currOptions,
        ...newOptions,
      ]);
    },
    [query]
  );

  const replaceOptions = useCallback(
    (newOptions: ISelectItem[], newQuery?: string) => {
      (!!newQuery ? setApiFilteredOptions : setBaseOptions)(newOptions);
    },
    [setBaseOptions, setApiFilteredOptions]
  );

  const options = useMemo(() => {
    if (!!query && apiFilteredOptions?.[0]) {
      return apiFilteredOptions.filter(
        (groupOption) =>
          !optionValuesToExclude?.includes(groupOption.value as string)
      );
    }
    return baseOptions.filter(
      (groupOption) =>
        !optionValuesToExclude?.includes(groupOption.value as string)
    );
  }, [baseOptions, apiFilteredOptions, query, optionValuesToExclude]);

  const handleFetchOptions = useCallback(
    async ({ newQuery, newPage }: { newQuery?: string; newPage?: number }) => {
      if (typeof fetchOptions === 'function') {
        const result = await fetchOptions({
          page: newPage ?? currentPage,
          query: newQuery || query,
        });
        setCurrentPage(result.page);
        setLastPage(result.lastPage);

        // Explicitly check for undefined because newPage===0 would give the wrong result otherwise
        (newPage !== undefined ? addOptions : replaceOptions)(
          optionValuesToExclude?.[0]
            ? result.options.filter(
                (option) =>
                  !optionValuesToExclude.includes(option.value as string)
              )
            : result.options,
          newQuery
        );
      }
    },
    [
      addOptions,
      currentPage,
      fetchOptions,
      optionValuesToExclude,
      query,
      replaceOptions,
    ]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<{}>, value: string) => {
      clearTimeout(timeout.current as unknown as number);
      setQuery(value);

      if (!value) {
        setApiFilteredOptions([]);
        return;
      }

      // Wait to fetch until user stops typing
      timeout.current = setTimeout(async () => {
        handleFetchOptions({ newQuery: value });
      }, 500);
    },
    [setQuery, handleFetchOptions]
  );

  const handleScrollEnd = useCallback(async () => {
    // Only fetch if no search query or if paging is allowed on search
    if (!query || pageOnSearch) {
      handleFetchOptions({ newPage: currentPage + 1 });
    }
  }, [handleFetchOptions, currentPage, query, pageOnSearch]);

  return (
    <Select
      name="MDMGroups"
      clearOnBlur={false} // False so that query state matches dropdown textbox content
      disableCloseOnSelect
      inputValue={query}
      onInputChange={handleInputChange}
      options={options}
      freeSolo={true}
      multiple={true}
      renderOption={(option, { selected }: { selected: boolean }) => (
        <Checkbox label={option.label} checked={selected} />
      )}
      renderPopper={(props: PopperProps) => {
        return (
          <div className={classes.popper}>
            <Popper disablePortal placement="bottom" {...props}>
              <HeadlessInfiniteScroll
                onScrollEnd={handleScrollEnd}
                hasMore={currentPage < lastPage}
              >
                {props.children}
              </HeadlessInfiniteScroll>
            </Popper>
          </div>
        );
      }}
      StartAdornment={StartAdornment}
      {...rest}
    />
  );
};

export default InfiniteSelect;
