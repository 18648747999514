import { getRuleEffectCount } from 'api/AppPolicyService';

export default async (
  postData: { all: string[]; any: string[] },
  teamId?: string
) => {
  try {
    const { data } = await getRuleEffectCount({ teamId }, postData);
    return data;
  } catch (err) {
    console.log('Error in AppCount fetch', err);
  }
};
