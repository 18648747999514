import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    authButtons: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '20px',
      '& .MuiButton-root + button': {
        marginLeft: '0px',
      },
    },
    modalButtons: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: '25px',
      marginTop: '25px',
      width: '100%',
      gap: '20px',
      '& > button': { boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.38)' },
    },
    buttonBackgroundColor: {
      backgroundColor: palette.charts.icon,
      color: palette.common.white,
      '&:hover': {
        backgroundColor: palette.charts.icon,
      },
    },
  })
);

export default useStyles;
