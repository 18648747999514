import { fetchAppsInventoryParamsMapping } from 'components/main/appsInventory/AppsInventoryTab/appsInventory.mappings';
import { ServiceHandlerFactory } from '../utils/serviceUtils';

const AppsInventoryBase = 'api/devices/v1';

export const fetchAppsInventory = ServiceHandlerFactory({
  axios: {
    url: `${AppsInventoryBase}/appVersions`,
    method: 'get',
  },
  paramSchema: fetchAppsInventoryParamsMapping,
});

export const fetchAppInventory = ServiceHandlerFactory({
  axios: {
    url: `${AppsInventoryBase}/appVersions/:appId`,
    method: 'get',
  },
  paramSchema: fetchAppsInventoryParamsMapping,
});

export const downloadCSV = ServiceHandlerFactory({
  axios: {
    url: `${AppsInventoryBase}/appVersions/csv`,
    method: 'post',
  },
  paramSchema: fetchAppsInventoryParamsMapping,
});

export const getCSVCount = ServiceHandlerFactory({
  axios: {
    url: `${AppsInventoryBase}/appVersions/csv/count`,
    method: 'post',
  },
  paramSchema: fetchAppsInventoryParamsMapping,
});

export const getStats = ServiceHandlerFactory({
  axios: {
    url: `${AppsInventoryBase}/appVersions/stats`,
    method: 'get',
  },
  // paramSchema: fetchAppsInventoryParamsMapping,
});
