import { IColumnHeader } from 'components/UI/Table/models';
import { TFunction } from 'react-i18next';
import RowCheckbox from './RowCheckbox';
import ContentSelector from './drawer/ContentSelector';
import { IOOCSelectRowMapping } from './models';

const tableTitle = (
  t: TFunction<'translation', undefined>
): Record<string, string> => ({
  developer: t('MTD.POLICIES.APP_POLICY.SELECT_DEVELOPERS'),
  package: t('MTD.POLICIES.APP_POLICY.SELECT_PACKAGES'),
  bundle: t('MTD.POLICIES.APP_POLICY.SELECT_BUNDLES'),
  app: t('MTD.POLICIES.APP_POLICY.SELECT_APPS'),
});

export const tableColumnData = (
  t: TFunction<'translation', undefined>
): Record<string, IColumnHeader> => ({
  developer: {
    id: 'developer',
    label: t('MTD.POLICIES.APP_POLICY.DEVELOPER_SIGNATURE'),
    show: true,
    path: 'developer',
    sort: true,
    flex: 1,
    resizable: true,
    showInContextMenu: true,
  },
  name: {
    id: 'name',
    label: t('MTD.POLICIES.APP_POLICY.APP_NAME'),
    show: true,
    path: 'name', // use same path
    sort: true,
    flex: 1,
    resizable: true,
    showInContextMenu: true,
  },
  developerName: {
    id: 'name',
    label: t('MTD.POLICIES.APP_POLICY.DEVELOPER_NAME'),
    show: true,
    path: 'name', // use same path
    sort: true,
    flex: 1,
    resizable: true,
    showInContextMenu: true,
  },
  package: {
    id: 'package',
    label: t('MTD.POLICIES.APP_POLICY.PACKAGE_ID'),
    show: true,
    path: 'package',
    sort: true,
    flex: 1,
    resizable: true,
    showInContextMenu: true,
  },
  version: {
    id: 'version',
    label: t('MTD.POLICIES.APP_POLICY.VERSION'),
    show: true,
    path: 'version',
    sort: true,
    flex: 1,
    resizable: true,
    showInContextMenu: true,
  },
  applySuspicious: {
    id: 'applySuspicious',
    label: t('MTD.POLICIES.APP_POLICY.APPLY_TO_SUSPICIOUS'),
    show: true,
    path: 'applySuspicious',
    sort: false,
    flex: 1,
    resizable: true,
    showInContextMenu: true,
  },
  applySideloaded: {
    id: 'applySideloaded',
    label: t('MTD.POLICIES.APP_POLICY.APPLY_TO_SIDELOADED'),
    show: true,
    path: 'applySideloaded',
    sort: false,
    flex: 1,
    resizable: true,
    showInContextMenu: true,
  },
  applyOOC: {
    id: 'applyOOC',
    label: t('MTD.POLICIES.APP_POLICY.APPLY_TO_OOC'),
    show: true,
    path: 'applyOOC',
    sort: false,
    flex: 1,
    resizable: true,
    showInContextMenu: true,
  },
  actions: {
    id: 'actions',
    label: t('GLOBAL.ACTIONS'),
    show: true,
    sort: false,
    maxWidth: 100,
    flex: 0,
    resizable: false,
    showInContextMenu: false,
  },
});

export const oocSelectTableTitle = (
  ruleType: string,
  t: TFunction<'translation', undefined>
): string => {
  return tableTitle(t)[ruleType] || tableTitle(t)['app'];
};

export const oocSelectTableHeaders = (
  ruleType: string,
  t: TFunction<'translation', undefined>
): IColumnHeader[] => {
  const parts = ruleType?.split('_') ?? [];
  const items = ruleType?.split('_')?.slice(2);
  let headers: IColumnHeader[] = [];
  if (items?.length === 1 && items[0] === 'developer') {
    // when the ruleType is only asking for "developer", the column will be Developer Name for iOS, Developer Name & Signature for android
    // ex. ios_allow_developer
    if (parts[0] === 'ios') {
      headers = [tableColumnData(t).developerName];
    } else if (parts[0] === 'android') {
      headers = [
        tableColumnData(t).developerName,
        tableColumnData(t).developer,
      ];
    }
  } else {
    headers = [tableColumnData(t).name];
    items?.forEach((item) => headers.push(tableColumnData(t)[item]));
  }

  if (parts[1] === 'allow' && ruleType !== 'ios_allow_developer') {
    // apply only for allow rules except ios_allow_developer rule
    if (parts[0] === 'ios') {
      headers.push(tableColumnData(t).applySuspicious);
      headers.push(tableColumnData(t).applyOOC);
    } else {
      headers.push(tableColumnData(t).applySuspicious);
      headers.push(tableColumnData(t).applySideloaded);
      headers.push(tableColumnData(t).applyOOC);
    }
  }
  headers.push(tableColumnData(t).actions);
  return headers;
};

export const oocSelectColumnMapping = (
  ruleType: string,
  isBottomTable?: boolean
): IOOCSelectRowMapping[] => [
  { path: 'name' },
  { path: 'developer' },
  { path: 'package' },
  { path: 'version' },
  {
    path: 'applySuspicious',
    columnContent: ({ rowData, classList }) => (
      <div className={classList.checkBoxColumn}>
        <RowCheckbox
          ruleType={ruleType}
          rowData={rowData}
          path="SUSPICIOUS"
          disabled={!!isBottomTable}
        />
      </div>
    ),
  },
  {
    path: 'applySideloaded',
    columnContent: ({ rowData, classList }) => (
      <div className={classList.checkBoxColumn}>
        <RowCheckbox
          ruleType={ruleType}
          rowData={rowData}
          path="SIDELOADED"
          disabled={!!isBottomTable}
        />
      </div>
    ),
  },
  {
    path: 'applyOOC',
    columnContent: ({ rowData, classList }) => (
      <div className={classList.checkBoxColumn}>
        <RowCheckbox
          ruleType={ruleType}
          rowData={rowData}
          path="OUT_OF_COMPLIANCE"
          disabled={!!isBottomTable}
        />
      </div>
    ),
  },
  {
    path: 'actions',
    columnContent: ({ rowData }) => (
      <ContentSelector rowData={rowData} ruleType={ruleType} />
    ),
  },
];
