import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    citrixImageBox: {
      paddingBottom: '12px',
    },

    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingBottom: '25px',
      paddingTop: '25px',
      gap: '20px',
      width: '100%',
      '& > button': { boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.38)' },
    },
    buttonsMargin: {
      display: 'flex',
      justifyContent: 'flex-end',
      position: 'absolute',
      left: '-24px',
      bottom: '25px',
      gap: '20px',
      width: '100%',
      '& > button': { boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.38)' },
    },
    horizontalStepper: {
      padding: '0px 155px',
      width: '100%',
    },
    description: {
      color: palette.text.primary,
      fontSize: '16px',
      marginTop: '20px',
      marginLeft: '25px',
    },
    imageBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '125px',
      marginTop: '25px',
      width: '125px',
    },
    image: {
      objectFit: 'contain',
      width: '100%',
    },
    logoSection: {
      alignItems: 'center',
      display: 'flex',
      marginBottom: '10px',
    },
    columnContent: {
      alignItems: 'center',
      display: 'flex',
    },
    tooltip: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-start',
      position: 'relative',
      zIndex: 999,
    },
    tooltipText: {
      backgroundColor: palette.common.darkGreySoft,
      borderRadius: '3px',
      color: palette.common.white,
      left: '40px',
      overflow: 'hidden',
      padding: '6px 8px 6px 8px',
      position: 'absolute',
      width: '250px',
      fontSize: '12px',
    },
    infoTooltip: {
      display: 'flex',
      gap: '6px',
      alignSelf: 'end',
    },
    noDataTableContent: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'end',
      width: '100%',
    },
    modal: {
      '& .MuiDialog-paper': {
        width: '1280px',
        height: ' 756px',
        maxWidth: '1280px',
        maxHeight: '756px',
        overflow: 'hidden',
      },
    },
    styleActionHeader: {
      width: '0px',
      paddingRight: '36px',
    },
    actionTooltip: {
      marginTop: '14px',
    },
    policyGroupHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '10px',
    },
    disableIconStyle: {
      opacity: '30%',
    },
    addIconStyle: {
      cursor: 'pointer',
    },
    buttonBackgroundColor: {
      backgroundColor: palette.charts.icon,
      color: palette.common.white,
      '&:hover': {
        backgroundColor: palette.charts.icon,
      },
    },
  })
);

export default useStyles;
