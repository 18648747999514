import { default as MUIDialogActions } from '@material-ui/core/DialogActions';
import { default as MUIDialogContent } from '@material-ui/core/DialogContent';
// TODO: Replace later
import { LinearProgress } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Delete from '@material-ui/icons/Delete';
import GenericError from 'components/UI/GenericErrorBox';
import Uploader from 'components/UI/Uploader';
import { Api } from 'config/axiosConfig';
import React, { useState, useCallback } from 'react';
import { default as OldZButton } from 'UI/Buttons/ZButton';
import {
  openSnackBar,
  toggleModalDiffered,
  toggleModalDirect,
} from 'utils/storeUtils';
import { FileData, IBomUploadProps } from './models';
import useStyles from './useStyles';

// TODO: Replace later
const ZButton: any = OldZButton;
const BomUpload: React.FC<IBomUploadProps> = ({ data }) => {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState<FileData>({ name: '' });
  const [fileLoaded, setFileLoaded] = useState<number>(0);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const tableName = data.variant || 'BomUpload';

  const handleCloseModal = useCallback(() => {
    toggleModalDirect(tableName, false);
    openSnackBar('Upload Successful');
  }, [tableName]);

  const getFileData = useCallback((filedata: FileData) => {
    setSelectedFile(filedata);
  }, []);

  const handleRemoveFile = useCallback(() => {
    setErrorMessage(null);
    setSelectedFile({ name: '' });
  }, []);

  const handleSubmitFile = useCallback(() => {
    setErrorMessage(null);
    const formData = new FormData();
    formData.append('assessmentId', data.assessmentId ?? '');
    formData.append('bomFile', selectedFile as any);
    Api.post('api/zdev-app/v1/sboms/upload', formData, {
      onUploadProgress: (ProgressEvent) => {
        setFileLoaded((ProgressEvent.loaded / ProgressEvent.total) * 100);
      },
    })
      .then(() => {
        setFileLoaded(0);
        handleCloseModal();
      })
      .catch((e) => {
        console.error('Error Submitting SBOM: ', e);
        setErrorMessage('Unable to parse SBOM');
        setFileLoaded(0);
      });
  }, [data?.assessmentId, handleCloseModal, selectedFile]);

  return (
    <div>
      <MUIDialogContent>
        {!selectedFile.name && (
          <div className={classes.formGroup}>
            <Uploader
              accept=".json"
              getFileData={getFileData}
              isShowing={!selectedFile.name}
            />
          </div>
        )}

        {!!selectedFile.name && (
          <>
            <Card className={classes.fileCard}>
              <div className="text__ellipsis">{selectedFile.name}</div>
              <Delete
                style={{ cursor: 'pointer' }}
                onClick={handleRemoveFile}
              />
            </Card>
            {(fileLoaded ?? 0) > 1 && (
              <LinearProgress variant="determinate" value={fileLoaded} />
            )}
            <GenericError errorMessage={errorMessage} />
            <MUIDialogActions>
              <ZButton
                action={toggleModalDiffered(tableName, false)}
                color="secondary"
                buttonText="Cancel"
              />
              <ZButton
                action={handleSubmitFile}
                color="primary"
                buttonText="Upload"
              />
            </MUIDialogActions>
          </>
        )}
      </MUIDialogContent>
    </div>
  );
};

export default BomUpload;
