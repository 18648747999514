import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ config, palette }: any) =>
  createStyles({
    container: {
      backgroundColor: config.colors.marineBlue,
      padding: '20px 24px',
    },
    columnContent: {
      margin: '4px 0',
      color: palette.text.primary,
    },
  })
);

export default useStyles;
