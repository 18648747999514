import { IFilterEditor, ILocationQuery } from 'components/UI/Table/models';
import memoizeTableQuery from 'components/UI/Table/utils/memoizeTableQuery';
import { fetchTableData } from '../../../../utils/tableUtils';

const url = 'api/devices/v1/cves/stats?';

const memoizeQueryWithAccountId = (
  accountId: string,
  filters: IFilterEditor[],
  selectedTeamId: string | null
) => {
  return memoizeTableQuery(async (query: ILocationQuery) => {
    return await fetchTableData(query, filters, url, accountId, selectedTeamId);
  });
};
export default memoizeQueryWithAccountId;
