import GenericDataFilter from 'components/UI/GenericDataFilter';
import CopyIcon from 'components/UI/icons/CopyIcon';
import { IDetail } from './models';
import useStyles from './useStyles';
import { useTranslation } from 'react-i18next';

interface IDetailTableProps {
  onFilterClick?: () => void;
  details: IDetail[];
}

const DetailTable: React.FC<IDetailTableProps> = ({
  details,
  onFilterClick,
}) => {
  const classes = useStyles();
  const { t, ready } = useTranslation();

  if (!details?.[0]) {
    return null;
  }

  if (!ready) {
    return null;
  }

  return (
    <div>
      <table className={classes.table}>
        <tbody>
          {details.map(
            ({
              canCopy,
              content,
              filterable,
              filterValue,
              id,
              label,
              path,
              prefixContent,
              showTableValue,
            }) => (
              <>
                {!!content && (
                  <tr key={id}>
                    <td className={classes.labelCell}>
                      <div>{t(label)}</div>
                    </td>
                    <td>{prefixContent ?? ''}</td>
                    <td>
                      <div className={classes.valueField}>
                        <div>
                          {!!filterable ? (
                            <GenericDataFilter
                              onClick={onFilterClick}
                              filterValue={filterValue}
                              path={path ?? id}
                              showTableValue={showTableValue}
                              tableValue={content}
                            />
                          ) : (
                            content
                          )}
                        </div>
                        {canCopy && <CopyIcon />}
                      </div>
                    </td>
                  </tr>
                )}
              </>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DetailTable;
