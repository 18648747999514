import { useState } from 'react';

const useForceReflow = (): { reflowIndex: number; forceReflow: () => void } => {
  const [reflowIndex, setReflowIndex] = useState<number>(0);

  return {
    reflowIndex,
    forceReflow: () => {
      setReflowIndex(reflowIndex + 1);
    },
  };
};

export default useForceReflow;
