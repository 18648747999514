import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    actionsContainer: {
      display: 'flex',
    },
    blue: {
      '& svg': {
        color: palette.primary.main,
      },
    },
    default: {
      '& svg': {
        color: 'inherit',
      },
    },
    disabled: {
      opacity: '30%',
    },
    iconButton: {
      background: 'none',
      border: 'none',
      color: 'inherit',
      cursor: 'pointer',
      font: 'inherit',
      margin: '0px',
      outline: 'inherit',
      padding: '0px',
    },
  })
);

export default useStyles;
