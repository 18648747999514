import MUIInputAdornment from '@material-ui/core/InputAdornment';
import { getActiveModalAtom } from 'atoms/modals';
import { MultiSelect as JsMultiSelect } from 'components/Selects';
import Button from 'components/UI/Button';
import GenericTable from 'components/UI/GenericTable';
import { GenericPromptModal_TITLE } from 'components/UI/Modals/GenericPrompt';
import { IGenericPromptModalData } from 'components/UI/Modals/GenericPrompt/models';
import DeleteIcon from 'components/UI/icons/DeleteIcon';
import SearchIcon from 'components/UI/icons/SearchIcon';
import TextField from 'components/UI/input/TextField';
import _ from 'lodash';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { searchTable } from 'utils/tableUtils';
import jsonCountries from './countries.json';
import { ICountry } from './models';
import useStyles from './useStyles';

const MultiSelect: any = JsMultiSelect;

const defaultCountry: ICountry = {
  id: '',
  countryCode: '',
  country: '',
  selected: false,
};

const searchFields: (keyof ICountry)[] = ['country'];

interface ISinkholeCountriesPops {
  onCountriesChanged: (key: 'countries', value: ICountry[]) => void;
  countries: ICountry[];
}

const activeModalAtom = getActiveModalAtom<IGenericPromptModalData>();

const SinkholeCountries: React.FC<ISinkholeCountriesPops> = (props) => {
  const { onCountriesChanged, countries } = props;

  const classes = useStyles();

  const { t, ready } = useTranslation();

  const setActiveModal = useSetRecoilState(activeModalAtom);

  const [searchTerm, setSearchTerm] = useState<string>();
  const [data, setData] = useState(countries);
  const [newCountry, setNewCountry] = useState(defaultCountry);

  useEffect(() => {
    if (!_.isEqual(data, countries)) {
      onCountriesChanged('countries', data);
    }
  }, [data, countries, onCountriesChanged]);

  const newCountryExist = useCallback(() => {
    const results = searchTable(newCountry.country, data, searchFields);
    return results.length > 0;
  }, [data, newCountry]);

  const onDeleteClick = useCallback(
    (item: any): void => {
      setActiveModal({
        active: GenericPromptModal_TITLE,
        payload: {
          title: t('MTD.POLICIES.NETWORK_POLICY.DELETE_COUNTRY'),
          message: t('MTD.POLICIES.NETWORK_POLICY.CONFIRM_DELETE'),
          onCancel: () => setActiveModal(undefined),
          onConfirm: () => {
            const newData = [...data].filter(
              (el) => el.countryCode !== item.countryCode
            );
            setData(newData);
            setActiveModal(undefined);
          },
        },
      });
    },
    [data, setActiveModal, t]
  );

  const onSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setSearchTerm(value);
    },
    [setSearchTerm]
  );

  const onSelectCountryChange = useCallback(
    (_: any, country: any): void => {
      setNewCountry({
        ...newCountry,
        countryCode: country.value,
        country: country.label,
      });
    },
    [newCountry, setNewCountry]
  );

  const onAddClick = useCallback((): void => {
    if (newCountry.country) {
      const newData = [...data, { ...newCountry }];
      setData(newData);
      setNewCountry(defaultCountry);
    }
  }, [data, newCountry]);

  const getCountries = useMemo(() => {
    return jsonCountries.map((c) => ({
      label: c.country,
      value: c.code,
    }));
  }, []);

  if (!ready) {
    return null;
  }

  return (
    <div className={classes.root} style={{ gap: '15px', width: '50%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          alignItems: 'end',
        }}
      >
        <MultiSelect
          buttonPlaceholder={t('MTD.POLICIES.NETWORK_POLICY.SELECT_COUNTRY')}
          isMulti={false}
          options={getCountries}
          onChange={onSelectCountryChange}
          fullWidth
        />
        <Button
          text={t('GLOBAL.ADD')}
          color="secondary"
          style={{ height: 'min-content' }}
          onClick={onAddClick}
          disabled={newCountryExist()}
        />
      </div>

      <TextField
        name="search"
        placeholder={t('GLOBAL.SEARCH')}
        onChange={onSearchChange}
        InputProps={{
          endAdornment: (
            <MUIInputAdornment
              position="end"
              classes={{ root: classes.searchIcon }}
            >
              <SearchIcon />
            </MUIInputAdornment>
          ),
        }}
      />

      <GenericTable
        columnHeaders={[
          { id: 'country', label: t('MTD.POLICIES.NETWORK_POLICY.COUNTRY') },
          { id: 'actions', label: t('GLOBAL.ACTIONS') },
        ]}
        rowMapping={[
          {
            path: 'actions',
            columnContent: ({ rowData }) => (
              <Button
                style={{ padding: 0, minWidth: 'auto' }}
                variant="text"
                icon={DeleteIcon}
                onClick={() => onDeleteClick(rowData)}
              />
            ),
          },
        ]}
        tableData={searchTable(searchTerm, data, searchFields) as any}
        tableId="Countries Rules"
      />
    </div>
  );
};

export default SinkholeCountries;
