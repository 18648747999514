import { checkboxData } from './checkboxesData';
import useStyles from '../useStyles';
import { Card, CardContent } from '@material-ui/core';
import CheckBox from 'components/UI/input/Checkbox';
import withRouter from 'components/hocs/withRouter';
import { onFacetChange, checkDefault } from '../utils';

const FacetedSearch = (props: any) => {
  const classes = useStyles();
  return (
    <div>
      {checkboxData?.map((data) => {
        return (
          <>
            <h4 className={classes.facetedSearchHeader}>{data?.group}</h4>
            <Card>
              <CardContent>
                {data?.options?.map((e) => (
                  <CheckBox
                    {...e}
                    label={e.label}
                    onChange={onFacetChange(`${e.name}`, `${e.value}`, props)}
                    defaultChecked={checkDefault(props.q[e.name], e.value)}
                  />
                ))}
              </CardContent>
            </Card>
          </>
        );
      })}
    </div>
  );
};

const ZScanPoliciesFacetedSearch = withRouter(FacetedSearch);
export default ZScanPoliciesFacetedSearch;
