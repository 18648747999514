import { ISelectItem } from 'components/UI/input/Select';
import { Api } from 'config/axiosConfig';
import { openSnackBar } from 'utils/storeUtils';
import { IDormancyPolicyForm } from './Form/models';
import { convertUnitToSeconds } from './Form/utils';
import {
  IDormancyPolicy,
  IDormancyPolicyOption,
  IDormancySelectItem,
  IDormancyUpdate,
} from './models';

export const fetchPolicies = async (
  teamId?: string
): Promise<IDormancySelectItem[]> => {
  let data: IDormancySelectItem[] = [];
  const baseUrl = 'api/devices/v1/dormancy/policies';
  const apiUrl = `${baseUrl}${
    teamId === 'global' || !teamId ? '' : `?teamId=${teamId}`
  }`;
  try {
    const result = await Api.get(apiUrl);
    if (!!result?.data) {
      data = result.data.map(({ id, name, teamId }: IDormancyPolicyOption) => ({
        accountBounded: !teamId,
        label: name,
        teamId,
        value: id,
        team: teamId ? { id: teamId } : null,
      }));
    }
  } catch (e) {
    // continue regardless of error
  }
  return data;
};

export const fetchPolicyById = async (
  id: string
): Promise<IDormancyPolicy | undefined> => {
  let data: IDormancyPolicy | undefined;
  const apiUrl = `api/devices/v1/dormancy/policies/${id}`;
  try {
    const result = await Api.get(apiUrl);
    if (!!result?.data) {
      data = result.data;
    }
  } catch (e) {
    console.error(e);
  }
  return data;
};

export const deletePolicy = async (
  id: string,
  onDeleteSuccess?: () => void
) => {
  if (!id) {
    return;
  }

  const baseUrl = 'api/devices/v1/dormancy/policies';
  const apiUrl = `${baseUrl}/${id}`;
  try {
    await Api.delete(apiUrl);
    onDeleteSuccess && onDeleteSuccess();
    openSnackBar('Dormancy policy successfully deleted.');
  } catch (e) {
    openSnackBar('Error deleting policy. Please try again.');
    console.error(e);
  }
};

export const updatePolicy = async (
  policyDetails: IDormancyPolicy | undefined,
  onSuccess: (policy: ISelectItem) => void,
  formData: IDormancyPolicyForm,
  editedPolicyName?: string
) => {
  const data: IDormancyUpdate = {
    accountId: policyDetails?.accountId,
    global: policyDetails?.global,
    id: policyDetails?.id,
    inactiveAppSettings: {
      enabled: formData?.enabledIA,
      maxWarningsCount: formData?.maxWarningsCountIA,
      sendEmailAndroid: formData?.sendEmailAndroidIA,
      notifyDevicesIos: formData?.notifyDevicesIosIA,
      notifyDevicesAndroid: formData?.notifyDevicesAndroidIA,
      sendEmailIos: formData?.sendEmailIosIA,
      timeBeforeWarningDisplayUnits:
        (formData?.timeBeforeWarningDisplayUnitsIA?.value as string) ?? null,
      timeBeforeWarningSeconds: convertUnitToSeconds(
        formData?.timeBeforeWarningIA ?? null,
        (formData?.timeBeforeWarningDisplayUnitsIA?.value as string) ?? null
      ),
      timeBetweenWarningsDisplayUnits:
        (formData?.timeBetweenWarningsDisplayUnitsIA?.value as string) ?? null,
      timeBetweenWarningsSeconds: convertUnitToSeconds(
        formData?.timeBetweenWarningsIA ?? null,
        (formData?.timeBetweenWarningsDisplayUnitsIA?.value as string) ?? null
      ),
    },
    name: editedPolicyName ?? formData?.initialName,
    pendingActivationSettings: {
      enabled: formData?.enabledPA,
      maxWarningsCount: formData?.maxWarningsCountPA,
      sendEmailAndroid: formData?.sendEmailAndroidPA,
      sendEmailIos: formData?.sendEmailIosPA,
      timeBeforeWarningDisplayUnits:
        (formData?.timeBeforeWarningDisplayUnitsPA?.value as string) ?? null,
      timeBeforeWarningSeconds: convertUnitToSeconds(
        formData?.timeBeforeWarningPA ?? null,
        (formData?.timeBeforeWarningDisplayUnitsPA?.value as string) ?? null
      ),
      timeBetweenWarningsDisplayUnits:
        (formData?.timeBetweenWarningsDisplayUnitsPA?.value as string) ?? null,
      timeBetweenWarningsSeconds: convertUnitToSeconds(
        formData?.timeBetweenWarningsPA ?? null,
        (formData?.timeBetweenWarningsDisplayUnitsPA?.value as string) ?? null
      ),
    },
    teamId: policyDetails?.team?.id ?? policyDetails?.teamId,
  };
  const baseUrl = 'api/devices/v1/dormancy/policies';
  const apiUrl = `${baseUrl}/${policyDetails?.id}`;
  try {
    await Api.put(apiUrl, data);
    openSnackBar('Policy successfully updated.');
    onSuccess({
      label: editedPolicyName ?? formData?.initialName,
      value: policyDetails?.id ?? '',
    });
  } catch (e) {
    openSnackBar('Error saving policy. Please check the form and try again.');
    console.error(e);
  }
};
