import _ from 'lodash';
import { trimAndAddAsterisk } from 'utility/QueryUtility';
import SeverityCircle from '../SeverityCircle';

export const serverityColors = (rowData: any): any => {
  switch (rowData.severity) {
    case 'INFORMATIONAL':
      return <div className="pill low">{rowData.severity}</div>;
    case 'LOW':
      return <div className="pill moderate">{rowData.severity}</div>;
    case 'MEDIUM':
      return <div className="pill substantial">{rowData.severity}</div>;
    case 'HIGH':
      return <div className="pill elevated">{rowData.severity}</div>;
    case 'CRITICAL':
      return <div className="pill critical">{rowData.severity}</div>;
    case 'BEST_PRACTICES':
      return (
        <div className="pill best-practices">
          {_.replace(rowData.severity, '_', ' ')}
        </div>
      );
    default:
      return rowData.severity;
  }
};

export const compareBuildsTableHeader = [
  {
    id: 'severity',
    label: 'Severity',
    show: true,
    sort: true,
    resizable: true,
    flex: 1,
    minWidth: 175,
    maxWidth: 175,
    showInContextMenu: true,
  },
  {
    id: 'categoryName',
    label: 'Category',
    show: true,
    sort: true,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    id: 'subcategoryName',
    label: 'Subcategory',
    show: true,
    sort: true,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    id: 'name',
    label: 'Finding Name',
    show: true,
    sort: true,
    resizable: true,
    minWidth: 300,
    flex: 1,
    showInContextMenu: true,
  },

  {
    id: 'locationCount',
    label: 'Instances',
    show: true,
    sort: false,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
];

export const compareBuildsColumnMapping = [
  {
    path: 'severity',
    columnContent: ({ rowData }: any) => {
      return <SeverityCircle severity={rowData?.severity} />;
    },
  },
  {
    path: 'categoryName',
  },
  {
    path: 'subcategoryName',
  },
  {
    path: 'name',
  },

  {
    path: 'locationCount',
    columnContent: ({ rowData }: any) => (
      <div className="table-cell__ellipsis">{rowData?.locations?.length}</div>
    ),
  },
];

// table inside the build compare
export const instancesByFindingIdTableHeaders = [
  {
    id: 'value',
    label: 'Location',
    show: true,
    sort: false,
  },
  // {
  //   id: 'accepted',
  //   label: 'Accepted',
  //   show: true,
  //   sort: false,
  // },
];

export const zScanCompareFindingsParamsMapping = [
  {
    path: 'policyId',
  },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }: { order: string; orderBy: string }) => {
      switch (orderBy) {
        case 'severity':
        case 'categoryName':
        case 'subcategoryName':
        case 'severityOrdinal':
        case 'locationCount':
        case 'value':
          return `${orderBy}.keyword,${order}`;
        case 'created':
        case 'modified':
          return `${orderBy},${order}`;
        default:
          if (orderBy && order) {
            return `${orderBy},${order}`;
          }
          return null;
      }
    },
  },
  {
    path: 'search',
    transform: ({ search = '' }) => {
      if (!search) {
        return null;
      }

      return trimAndAddAsterisk(search);
    },
  },
];
