import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AndroidIcon from '@material-ui/icons/Android';
import ChromeOSIcon from 'UI/Icons/ChromeOSIcon';
import KnoxIcon from 'UI/Icons/KnoxIcon';
import { Radio } from 'components/UI/input/RadioGroup';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import CollectibleMapping from 'utility/CollectibleMapping';
import PrivacyCategoryMapping from 'utility/PrivacyCategoryMapping';
import AppleIcon from '../../../../UI/Icons/AppleIcon';
import PrivacyPolicySkeleton from './PrivacyPolicySkeleton';
import useStyles from './useStyles';

const PrivacyPolicyTableHead = (props) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>{PrivacyCategoryMapping[props.category]}</TableCell>
        <TableCell align={props.category.numeric ? 'right' : 'left'}>
          {' '}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const PrivacyPolicyTable = ({
  handleLocationChange,
  handleTableChange,
  module,
  privacyPolicyCollectibles,
  selectedPrivacyPolicy,
  selectedPrivacyPolicyRulesByCategory,
  showEmmFields,
}) => {
  const classes = useStyles();

  const { t, ready } = useTranslation();

  const renderCollectibleName = (collectibleId) => {
    return t(CollectibleMapping[collectibleId]);
  };

  const renderSwitch = (rule, disabled, category) => {
    return (
      <Checkbox
        disabled={disabled}
        disableRipple
        checked={rule.shouldCollect}
        color="primary"
        onChange={() => {
          handleTableChange(
            'shouldCollect',
            !rule.shouldCollect,
            rule,
            category
          );
        }}
        value={toString(rule.shouldCollect)}
      />
    );
  }

  const renderOperatingSystems = (id) => {
    const { byCollectibleId } = privacyPolicyCollectibles;

    const osArray = byCollectibleId[id].operatingSystems;

    return osArray.map((os) => {
      if (os === 1) {
        return <AndroidIcon key={`${id}-android`} />;
      }
      if (os === 2) {
        return (
          <span key={`${id}-apple`} className="table__os-icon">
            <AppleIcon />
          </span>
        );
      }
      if (os === 3) {
        return (
          <span key={`${id}-chrome`} className="table__os-icon">
            <ChromeOSIcon />
          </span>
        );
      }
      if (os === 4) {
        return (
          <span key={`${id}-knox`} className="table__os-icon">
            <KnoxIcon />
          </span>
        );
      }
      return null;
    });
  }

  const renderCard = (category) => {
    let disabled = false;
    return (
      <div key={category} className={classes.item}>
        <Card key={category}>
          <CardContent>
            <Table>
              <PrivacyPolicyTableHead category={category} />
              <TableBody>
                {selectedPrivacyPolicyRulesByCategory[category] &&
                  selectedPrivacyPolicyRulesByCategory[category].map(
                    (collectible) => {
                      // Operating System, Model
                      disabled =
                        collectible.collectibleId === 12 ||
                        collectible.collectibleId === 13;
                      return (
                        <TableRow key={collectible.id}>
                          <TableCell>
                            {renderSwitch(collectible, disabled, category)}
                            {renderCollectibleName(
                              collectible.collectibleId
                            )}
                          </TableCell>
                          <TableCell>
                            <div>
                              {renderOperatingSystems(
                                collectible.collectibleId
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
        {category === 2 && showEmmFields ? (
          <div style={{ paddingLeft: '1.5em', textIndent: '-1.5em' }}>
            * &nbsp; &nbsp; {t('MTD.POLICIES.PRIVACY_POLICY.NOTE_APPS_COLLECT')}
          </div>
        ) : null}
        {category === 3 && module ? (
          <div
            style={{
              paddingLeft: '1.5em',
              paddingBottom: '0.5em',
              textIndent: '-1.5em',
            }}
          >
            * &nbsp; &nbsp; {t('MTD.POLICIES.PRIVACY_POLICY.NOTE_PROFILES_COLLECT')}
          </div>
        ) : null}
      </div>
    );
  }

  if (!ready) {
    return null;
  }

  const thirdCategoryOrder = [0, 3, 8]; // Device, Device Owner, Browser Extensions
  const disabled = false;
  if (
    selectedPrivacyPolicyRulesByCategory &&
    privacyPolicyCollectibles
  ) {
    return (
      <div className={classes.container}>
        <div className={classes.column}>
          <div className={classes.item}>
            <Card key="location">
              <CardContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('MTD.POLICIES.PRIVACY_POLICY.LOCATION')}</TableCell>
                      <TableCell> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ borderBottom: 'none' }}>
                        <RadioGroup
                          aria-label="Location"
                          name="location"
                          value={
                            selectedPrivacyPolicy.locationAccuracy !== null
                              ? selectedPrivacyPolicy.locationAccuracy.toString()
                              : 'null'
                          }
                          onChange={(e) => {
                            handleLocationChange(e.target.value);
                          }}
                        >
                          <Radio
                            disabled={disabled}
                            value="null"
                            label={t('GLOBAL.NONE')}
                          />
                          <Radio
                            disabled={disabled}
                            value="0"
                            label={t('MTD.POLICIES.PRIVACY_POLICY.STREET')}
                          />
                          <Radio
                            disabled={disabled}
                            value="1"
                            label={t('MTD.POLICIES.PRIVACY_POLICY.CITY')}
                          />
                          <Radio
                            disabled={disabled}
                            value="2"
                            label={t('MTD.POLICIES.NETWORK_POLICY.COUNTRY')}
                          />
                        </RadioGroup>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </div>
          {/* Applications */}
          {renderCard(2)}
        </div>
        <div className={classes.column}>
          {/* Network */}
          {renderCard(1)}
        </div>
        <div className={classes.column}>
          {thirdCategoryOrder.map((category) => {
            if (
              _.isEmpty(selectedPrivacyPolicyRulesByCategory[category])
            )
              return <Card />;
            return renderCard(category);
          })}
        </div>
      </div>
    );
  }
  return <PrivacyPolicySkeleton />;
}

export default PrivacyPolicyTable;
