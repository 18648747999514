import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    citrixImageBox: {
      paddingBottom: '12px',
    },
    description: {
      color: palette.text.primary,
      fontSize: '16px',
      marginTop: '20px',
    },
    stepperForm: {
      width: '100%',
      marginRight: '72px',
    },
    imageBox: {
      backgroundColor: palette.common.white,
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      width: '190px',
      height: '150px',
      margin: '19px',
      padding: '8px',
    },
    image: {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
    },
    logoSection: {
      display: 'flex',
      gap: '75px',
      marginBottom: '10px',
    },
    label: {
      color: palette.common.black,
      fontSize: '12px',
      margin: '0px',
      paddingTop: '10px',
      textAlign: 'center',
    },
    imgBox: {
      width: '80px',
      height: '80px',
      margin: 'auto',
      marginTop: '10px',
    },
    modal: {
      '& .MuiDialog-container > div': {
        height: '619px',
        width: '1185px',
        maxWidth: '1185px',
        minWidth: '600px',
      },
    },
  })
);

export default useStyles;
