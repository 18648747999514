import cc from 'classcat';
import GenericCard from 'components/UI/GenericCard';
import CircleIcon from 'components/UI/icons/CircleIcon';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import useAsyncResult from 'utils/useAsyncResult';
import { IInsightsChart } from '../models';
import { default as useSharedStyles } from '../useStyles';
import { IMTDPolicyStatus } from './models';
import useStyles from './useStyles';
import {
  fetchMDMIntegrationConfigured,
  fetchMTDPolicyStatus,
  fetchSIEMIntegrationConfigured,
} from './utils';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import { useTranslation } from 'react-i18next';

const KeyFeatures: React.FC<IInsightsChart> = ({ selectedTeamId }) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const { t, ready } = useTranslation();

  const mdmIntegration = useAsyncResult<boolean>(
    fetchMDMIntegrationConfigured,
    selectedTeamId ?? ''
  );
  const mtdStatus = useAsyncResult<IMTDPolicyStatus>(
    fetchMTDPolicyStatus,
    selectedTeamId ?? ''
  );
  const siemIntegration = useAsyncResult<boolean>(
    fetchSIEMIntegrationConfigured
  );

  const keyFeatures = useMemo(
    () =>
      new Map([
        [
          t('MTD.INSIGHTS.MDM_INTEGRATION'),
          { enabled: mdmIntegration, link: '/console/mtd/mdm', scope: 'emm' },
        ],
        [
          t('MTD.INSIGHTS.SIEM_INTEGRATION'),
          {
            enabled: siemIntegration,
            link: '/console/manage/data-export',
            scope: 'data_exports',
          },
        ],
        [
          t('MTD.INSIGHTS.ADVANCED_APP_ANALYSIS'),
          {
            enabled: true,
            link: '/console/mtd/apps-inventory/apps-inventory',
            scope: 'app_inventory',
          },
        ],
        [
          t('MTD.INSIGHTS.PHISHING_DETECTION'),
          {
            enabled: mtdStatus?.phishingPolicy,
            link: '/console/mtd/policies/phishing',
            scope: 'phishing',
          },
        ],
        [
          t('MTD.INSIGHTS.APP_POLICY'),
          {
            enabled: mtdStatus?.appPolicy,
            link: '/console/mtd/policies/apps',
            scope: 'app_settings',
          },
        ],
      ]),
    [
      mdmIntegration,
      mtdStatus?.appPolicy,
      mtdStatus?.phishingPolicy,
      siemIntegration,
      t,
    ]
  );

  const elements = useMemo(
    () =>
      Array.from(keyFeatures).map(([key, { enabled, link, scope }]) => (
        <React.Fragment key={key}>
          <ProtectedComponent allow={{ [`${scope}`]: 'view' }}>
            <div className={cc(['key-feature-item', classes.status])}>
              <CircleIcon
                className={classes.icon}
                error={!enabled}
                noExclamation
                success={enabled}
              />
              {enabled
                ? t('MTD.INSIGHTS.CONFIGURED')
                : t('MTD.INSIGHTS.NOT_CONFIGURED')}
            </div>
            <div className={cc(['key-feature-item', classes.title])}>
              {link ? <Link to={link}>{key}</Link> : key}
            </div>
          </ProtectedComponent>
        </React.Fragment>
      )),
    [classes.icon, classes.status, classes.title, keyFeatures, t]
  );

  if (!ready) {
    return null;
  }

  return (
    <GenericCard className={cc([sharedClasses.card, classes.keyFeaturesCard])}>
      <h2>{t('MTD.INSIGHTS.KEY_FEATURES')}</h2>
      <div className={sharedClasses.column}>
        <div className={classes.keyFeatures}>{elements}</div>
      </div>
    </GenericCard>
  );
};

export default KeyFeatures;
