import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import {
  IActions,
  ISelectTRMPolicyTableData,
  PolicyChangeOptions,
} from '../models';
import useStyles from '../useStyles';
import { useTranslation } from 'react-i18next';

interface IMdmMitigationsProps {
  rowData: ISelectTRMPolicyTableData;
  mdmMitigationActions: IActions[] | undefined;
  handleTRMPolicyRuleChange: (
    optionName: PolicyChangeOptions,
    value: IActions | null,
    rule: ISelectTRMPolicyTableData
  ) => void;
}

const MdmMitigations: React.FC<IMdmMitigationsProps> = (props) => {
  const { rowData, mdmMitigationActions, handleTRMPolicyRuleChange } = props;

  const classes = useStyles();

  const [value, setValue] = useState<IActions | null>(null);

  const { t, ready } = useTranslation();

  useEffect(() => {
    if (rowData.mdmMitigationTarget && mdmMitigationActions) {
      const index = mdmMitigationActions.findIndex(
        (t) => t.value === rowData.mdmMitigationTarget
      );

      setValue(mdmMitigationActions[index]);
    } else {
      setValue(null);
    }
  }, [rowData.mdmMitigationTarget, mdmMitigationActions]);

  if (!ready) {
    return null;
  }

  return (
    <Autocomplete
      id="trm-mdm-mitigation-action"
      className={classes.mdmAutoComplete}
      options={mdmMitigationActions ? mdmMitigationActions : []}
      getOptionDisabled={(option) => !!option?.disabled}
      groupBy={(option: IActions) => option.grouping}
      getOptionLabel={(option: IActions) => option.label || ''}
      value={value}
      openText={t('GLOBAL.OPEN')}
      closeText={t('GLOBAL.CLOSE')}
      clearText={t('GLOBAL.CLEAR')}
      onChange={(_, value) => {
        handleTRMPolicyRuleChange(
          PolicyChangeOptions.MdmMitigationTarget,
          value,
          rowData
        );
      }}
      classes={{
        groupLabel: classes.mdmGroupLabel,
      }}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
    />
  );
};

export default React.memo(MdmMitigations);
