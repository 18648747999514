export default {
  "iPhone13,4": {
    "modelName": "iPhone13,4",
    "commercialName": "iPhone 12 Pro Max",
  },
  "iPhone13,3": {
    "modelName": "iPhone13,3",
    "commercialName": "iPhone 12 Pro",
  },
  "iPhone13,2": {
    "modelName": "iPhone13,2",
    "commercialName": "iPhone 12",
  },
  "iPhone13,1": {
    "modelName": "iPhone13,1",
    "commercialName": "iPhone 12 mini",
  },
  "iPhone6,1": {
    "modelName": "iPhone6,1",
    "commercialName": "iPhone 5s (GSM)",
  },
  "iPhone6,2": {
    "modelName": "iPhone6,2",
    "commercialName": "iPhone 5s (Global)",
  },
  "iPhone2,1": {
    "modelName": "iPhone2,1",
    "commercialName": "iPhone 3G[S]",
  },
  "iPad8,12": {
    "modelName": "iPad8,12",
    "commercialName": "iPad Pro 4 (12.9-inch, Cellular)",
  },
  "iPad8,11": {
    "modelName": "iPad8,11",
    "commercialName": "iPad Pro 4 (12.9-inch, WiFi)",
  },
  "iPad8,10": {
    "modelName": "iPad8,10",
    "commercialName": "iPad Pro 4 (11-inch, Cellular)",
  },
  "iPad7,6": {
    "modelName": "iPad7,6",
    "commercialName": "iPad 6 (Cellular)",
  },
  "iPod4,1": {
    "modelName": "iPod4,1",
    "commercialName": "iPod touch 4",
  },
  "iPad7,5": {
    "modelName": "iPad7,5",
    "commercialName": "iPad 6 (WiFi)",
  },
  "iPad7,4": {
    "modelName": "iPad7,4",
    "commercialName": "iPad Pro (10.5-inch, Cellular)",
  },
  "iPad7,3": {
    "modelName": "iPad7,3",
    "commercialName": "iPad Pro (10.5-inch, WiFi)",
  },
  "iPad7,2": {
    "modelName": "iPad7,2",
    "commercialName": "iPad Pro 2 (12.9-inch, Cellular)",
  },
  "iPad3,6": {
    "modelName": "iPad3,6",
    "commercialName": "iPad 4 (Global)",
  },
  "iPad7,1": {
    "modelName": "iPad7,1",
    "commercialName": "iPad Pro 2 (12.9-inch, WiFi)",
  },
  "iPad3,5": {
    "modelName": "iPad3,5",
    "commercialName": "iPad 4 (GSM)",
  },
  "iPad3,4": {
    "modelName": "iPad3,4",
    "commercialName": "iPad 4 (WiFi)",
  },
  "iPad3,3": {
    "modelName": "iPad3,3",
    "commercialName": "iPad 3 (GSM)",
  },
  "iPad3,2": {
    "modelName": "iPad3,2",
    "commercialName": "iPad 3 (CDMA)",
  },
  "iPad3,1": {
    "modelName": "iPad3,1",
    "commercialName": "iPad 3 (WiFi)",
  },
  "iPad14,1": {
    "modelName": "iPad14,1",
    "commercialName": "iPad Mini 6 (WiFi)",
  },
  "iPad14,2": {
    "modelName": "iPad14,2",
    "commercialName": "iPad Mini 6 (Cellular)",
  },
  "iPhone12,1": {
    "modelName": "iPhone12,1",
    "commercialName": "iPhone 11",
  },
  "iPhone9,4": {
    "modelName": "iPhone9,4",
    "commercialName": "iPhone 7 Plus (GSM)",
  },
  "iPhone9,2": {
    "modelName": "iPhone9,2",
    "commercialName": "iPhone 7 Plus (Global)",
  },
  "iPhone12,5": {
    "modelName": "iPhone12,5",
    "commercialName": "iPhone 11 Pro Max",
  },
  "iPhone9,3": {
    "modelName": "iPhone9,3",
    "commercialName": "iPhone 7 (GSM)",
  },
  "iPhone12,3": {
    "modelName": "iPhone12,3",
    "commercialName": "iPhone 11 Pro",
  },
  "iPhone5,4": {
    "modelName": "iPhone5,4",
    "commercialName": "iPhone 5c (Global)",
  },
  "iPhone9,1": {
    "modelName": "iPhone9,1",
    "commercialName": "iPhone 7 (Global)",
  },
  "iPhone5,2": {
    "modelName": "iPhone5,2",
    "commercialName": "iPhone 5 (Global)",
  },
  "iPhone5,3": {
    "modelName": "iPhone5,3",
    "commercialName": "iPhone 5c (GSM)",
  },
  "iPhone5,1": {
    "modelName": "iPhone5,1",
    "commercialName": "iPhone 5 (GSM)",
  },
  "iPhone1,2": {
    "modelName": "iPhone1,2",
    "commercialName": "iPhone 3G",
  },
  "iPad7,12": {
    "modelName": "iPad7,12",
    "commercialName": "iPad 7 (Cellular)",
  },
  "iPhone1,1": {
    "modelName": "iPhone1,1",
    "commercialName": "iPhone 2G",
  },
  "iPhone12,8": {
    "modelName": "iPhone12,8",
    "commercialName": "iPhone SE (2020)",
  },
  "iPod7,1": {
    "modelName": "iPod7,1",
    "commercialName": "iPod touch 6",
  },
  "iPad4,9": {
    "modelName": "iPad4,9",
    "commercialName": "iPad Mini 3 (China)",
  },
  "iPod3,1": {
    "modelName": "iPod3,1",
    "commercialName": "iPod touch 3",
  },
  "iPad8,5": {
    "modelName": "iPad8,5",
    "commercialName": "iPad Pro 3 (12.9-inch, WiFi)",
  },
  "iPad4,8": {
    "modelName": "iPad4,8",
    "commercialName": "iPad Mini 3 (Cellular)",
  },
  "iPad8,4": {
    "modelName": "iPad8,4",
    "commercialName": "iPad Pro 3 (11-inch, Cellular, 1TB Model)",
  },
  "iPad4,7": {
    "modelName": "iPad4,7",
    "commercialName": "iPad Mini 3 (WiFi)",
  },
  "iPad8,3": {
    "modelName": "iPad8,3",
    "commercialName": "iPad Pro 3 (11-inch, Cellular)",
  },
  "iPad4,6": {
    "modelName": "iPad4,6",
    "commercialName": "iPad Mini 2 (China)",
  },
  "iPad11,1": {
    "modelName": "iPad11,1",
    "commercialName": "iPad Mini 5 (WiFi)",
  },
  "iPad8,2": {
    "modelName": "iPad8,2",
    "commercialName": "iPad Pro 3 (11-inch, WiFi, 1TB Model)",
  },
  "iPad4,5": {
    "modelName": "iPad4,5",
    "commercialName": "iPad Mini 2 (Cellular)",
  },
  "iPad8,1": {
    "modelName": "iPad8,1",
    "commercialName": "iPad Pro 3 (11-inch, WiFi)",
  },
  "iPad4,4": {
    "modelName": "iPad4,4",
    "commercialName": "iPad Mini 2 (WiFi)",
  },
  "iPad4,3": {
    "modelName": "iPad4,3",
    "commercialName": "iPad Air (China)",
  },
  "iPad4,2": {
    "modelName": "iPad4,2",
    "commercialName": "iPad Air (Cellular)",
  },
  "iPad8,9": {
    "modelName": "iPad8,9",
    "commercialName": "iPad Pro 4 (11-inch, WiFi)",
  },
  "iPad8,8": {
    "modelName": "iPad8,8",
    "commercialName": "iPad Pro 3 (12.9-inch, Cellular, 1TB Model)",
  },
  "iPad8,7": {
    "modelName": "iPad8,7",
    "commercialName": "iPad Pro 3 (12.9-inch, Cellular)",
  },
  "iPad8,6": {
    "modelName": "iPad8,6",
    "commercialName": "iPad Pro 3 (12.9-inch, WiFi, 1TB Model)",
  },
  "iPad7,11": {
    "modelName": "iPad7,11",
    "commercialName": "iPad 7 (WiFi)",
  },
  "iPad4,1": {
    "modelName": "iPad4,1",
    "commercialName": "iPad Air (WiFi)",
  },
  "iPad11,6": {
    "modelName": "iPad11,6",
    "commercialName": "iPad 8 (WiFi)",
  },
  "iPad11,7": {
    "modelName": "iPad11,7",
    "commercialName": "iPad 8 (Cellular)",
  },
  "iPad11,2": {
    "modelName": "iPad11,2",
    "commercialName": "iPad Mini 5 (Cellular)",
  },
  "iPad11,3": {
    "modelName": "iPad11,3",
    "commercialName": "iPad Air 3 (WiFi)",
  },
  "iPad11,4": {
    "modelName": "iPad11,4",
    "commercialName": "iPad Air 3 (Cellular)",
  },
  "iPhone11,2": {
    "modelName": "iPhone11,2",
    "commercialName": "iPhone XS",
  },
  "iPhone15,2": {
    "modelName": "iPhone15,2",
    "commercialName": "iPhone 14 Pro",
  },
  "iPhone11,6": {
    "modelName": "iPhone11,6",
    "commercialName": "iPhone XS Max",
  },
  "iPhone8,4": {
    "modelName": "iPhone8,4",
    "commercialName": "iPhone SE",
  },
  "iPhone8,1": {
    "modelName": "iPhone8,1",
    "commercialName": "iPhone 6s",
  },
  "iPhone11,4": {
    "modelName": "iPhone11,4",
    "commercialName": "iPhone XS Max (China)",
  },
  "iPhone8,2": {
    "modelName": "iPhone8,2",
    "commercialName": "iPhone 6s+",
  },
  "iPhone4,1": {
    "modelName": "iPhone4,1",
    "commercialName": "iPhone 4[S]",
  },
  "iPhone11,8": {
    "modelName": "iPhone11,8",
    "commercialName": "iPhone XR",
  },
  "iPhone15,3": {
    "modelName": "iPhone15,3",
    "commercialName": "iPhone 14 Pro Max",
  },
  "iPad5,4": {
    "modelName": "iPad5,4",
    "commercialName": "iPad Air 2 (Cellular)",
  },
  "iPad5,3": {
    "modelName": "iPad5,3",
    "commercialName": "iPad Air 2 (WiFi)",
  },
  "iPad13,10": {
    "modelName": "iPad13,10",
    "commercialName": "iPad Pro (12.9-inch) (5th generation)",
  },
  "iPad5,2": {
    "modelName": "iPad5,2",
    "commercialName": "iPad Mini 4 (Cellular)",
  },
  "iPad5,1": {
    "modelName": "iPad5,1",
    "commercialName": "iPad Mini 4 (WiFi)",
  },
  "iPod2,1": {
    "modelName": "iPod2,1",
    "commercialName": "iPod touch 2G",
  },
  "iPad6,11": {
    "modelName": "iPad6,11",
    "commercialName": "iPad 5 (WiFi)",
  },
  "iPad6,12": {
    "modelName": "iPad6,12",
    "commercialName": "iPad 5 (Cellular)",
  },
  "iPad1,1": {
    "modelName": "iPad1,1",
    "commercialName": "iPad 1",
  },
  "iPad12,1": {
    "modelName": "iPad12,1",
    "commercialName": "iPad 9 (WiFi)",
  },
  "iPad12,2": {
    "modelName": "iPad12,2",
    "commercialName": "iPad 9 (Cellular)",
  },
  "iPhone10,3": {
    "modelName": "iPhone10,3",
    "commercialName": "iPhone X (Global)",
  },
  "iPhone10,2": {
    "modelName": "iPhone10,2",
    "commercialName": "iPhone 8 Plus (Global)",
  },
  "iPhone10,1": {
    "modelName": "iPhone10,1",
    "commercialName": "iPhone 8 (Global)",
  },
  "iPhone14,3": {
    "modelName": "iPhone14,3",
    "commercialName": "iPhone 13 Pro Max",
  },
  "iPhone14,2": {
    "modelName": "iPhone14,2",
    "commercialName": "iPhone 13 Pro",
  },
  "iPhone10,6": {
    "modelName": "iPhone10,6",
    "commercialName": "iPhone X (GSM)",
  },
  "iPhone7,2": {
    "modelName": "iPhone7,2",
    "commercialName": "iPhone 6",
  },
  "iPhone10,5": {
    "modelName": "iPhone10,5",
    "commercialName": "iPhone 8 Plus (GSM)",
  },
  "iPhone10,4": {
    "modelName": "iPhone10,4",
    "commercialName": "iPhone 8 (GSM)",
  },
  "iPhone7,1": {
    "modelName": "iPhone7,1",
    "commercialName": "iPhone 6+",
  },
  "iPhone3,2": {
    "modelName": "iPhone3,2",
    "commercialName": "iPhone 4 (GSM / 2012)",
  },
  "iPhone3,3": {
    "modelName": "iPhone3,3",
    "commercialName": "iPhone 4 (CDMA)",
  },
  "iPhone3,1": {
    "modelName": "iPhone3,1",
    "commercialName": "iPhone 4 (GSM)",
  },
  "iPhone14,7": {
    "modelName": "iPhone14,7",
    "commercialName": "iPhone 14",
  },
  "iPhone14,6": {
    "modelName": "iPhone14,6",
    "commercialName": "iPhone SE (3rd generation)",
  },
  "iPhone14,5": {
    "modelName": "iPhone14,5",
    "commercialName": "iPhone 13",
  },
  "iPad13,11": {
    "modelName": "iPad13,11",
    "commercialName": "iPad Pro (12.9-inch) (5th generation)",
  },
  "iPhone14,4": {
    "modelName": "iPhone14,4",
    "commercialName": "iPhone 13 mini",
  },
  "iPad13,17": {
    "modelName": "iPad13,17",
    "commercialName": "iPad Air 5 (Cellular)",
  },
  "iPod9,1": {
    "modelName": "iPod9,1",
    "commercialName": "iPod touch 7",
  },
  "iPad13,16": {
    "modelName": "iPad13,16",
    "commercialName": "iPad Air 5 (WiFi)",
  },
  "iPhone14,8": {
    "modelName": "iPhone14,8",
    "commercialName": "iPhone 14 Plus",
  },
  "iPad6,7": {
    "modelName": "iPad6,7",
    "commercialName": "iPad Pro 12.9-inch (WiFi)",
  },
  "iPod5,1": {
    "modelName": "iPod5,1",
    "commercialName": "iPod touch 5",
  },
  "iPad6,4": {
    "modelName": "iPad6,4",
    "commercialName": "iPad Pro 9.7-inch (Cellular)",
  },
  "iPad6,3": {
    "modelName": "iPad6,3",
    "commercialName": "iPad Pro 9.7-inch (WiFi)",
  },
  "iPad2,7": {
    "modelName": "iPad2,7",
    "commercialName": "iPad Mini (Global)",
  },
  "iPad2,6": {
    "modelName": "iPad2,6",
    "commercialName": "iPad Mini (GSM)",
  },
  "iPad2,5": {
    "modelName": "iPad2,5",
    "commercialName": "iPad Mini (WiFi)",
  },
  "iPod1,1": {
    "modelName": "iPod1,1",
    "commercialName": "iPod touch 1G",
  },
  "iPad2,4": {
    "modelName": "iPad2,4",
    "commercialName": "iPad 2 (Mid 2012)",
  },
  "iPad6,8": {
    "modelName": "iPad6,8",
    "commercialName": "iPad Pro 12.9-inch (Cellular)",
  },
  "iPad13,8": {
    "modelName": "iPad13,8",
    "commercialName": "iPad Pro (12.9-inch) (5th generation)",
  },
  "iPad13,9": {
    "modelName": "iPad13,9",
    "commercialName": "iPad Pro (12.9-inch) (5th generation)",
  },
  "iPad13,4": {
    "modelName": "iPad13,4",
    "commercialName": "iPad Pro (11-inch) (3rd generation)",
  },
  "iPad2,3": {
    "modelName": "iPad2,3",
    "commercialName": "iPad 2 (CDMA)",
  },
  "iPad13,5": {
    "modelName": "iPad13,5",
    "commercialName": "iPad Pro (11-inch) (3rd generation)",
  },
  "iPad2,2": {
    "modelName": "iPad2,2",
    "commercialName": "iPad 2 (GSM)",
  },
  "iPad13,6": {
    "modelName": "iPad13,6",
    "commercialName": "iPad Pro (11-inch) (3rd generation)",
  },
  "iPad2,1": {
    "modelName": "iPad2,1",
    "commercialName": "iPad 2 (WiFi)",
  },
  "iPad13,7": {
    "modelName": "iPad13,7",
    "commercialName": "iPad Pro (11-inch) (3rd generation)",
  },
  "iPad13,1": {
    "modelName": "iPad13,1",
    "commercialName": "iPad Air 4 (WiFi)",
  },
  "iPad13,2": {
    "modelName": "iPad13,2",
    "commercialName": "iPad Air 4 (Cellular)",
  }
};