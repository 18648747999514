import _ from 'lodash';
import store from 'config/storeConfig';
import jwt from 'utils/jwtUtils';

import { refreshTokenAction } from 'reducers/AuthReducers';
import { refreshAccessToken } from 'api/apis';

const INTERVAL_SECONDS = 15;
let _tokens = [];
let refreshInterval = null;

/*
  _tokens = Array
  _token = {
    refreshToken: --{ refreshTokenHere }--,
    expires: parsedExpiryTime in seconds,
    accessToken: --{ accessTokenHere }---
  }
*/

const AuthWorker = {
  init() {
    const _store = store.getState();
    const { auth } = _store;

    // hydrate _tokens with tokens from the store
    _tokens = Object.keys(auth)
      .filter(
        key =>
          auth[key].accessToken && auth[key].refreshToken && auth[key].expires
      )
      .map(key => ({
        accessToken: auth[key].accessToken,
        refreshToken: auth[key].refreshToken,
        expires: auth[key].expires,
      }));

    if (_tokens.length) {
      // check the tokens we have to see if any expire soon
      this.checkTokens();
      // spin up the interval for future checking
      this.initInterval();
    }

    this.bindEvents();
  },

  bindEvents() {
    window.addEventListener('auth:add-token', this.addToken.bind(this));
    window.addEventListener('auth:remove-token', this.removeToken.bind(this));
    window.addEventListener('auth:logout', this.cleanup.bind(this));
  },

  checkTokens() {
    const hasExpiringAccess = this.willTokensExpire(_tokens, _expireIteratee);

    if (hasExpiringAccess) {
      const expiringTokens = _.pullAllBy(_tokens, _expireIteratee);
      this.refreshAccessTokens(expiringTokens);
    }
  },

  willTokensExpire(tokens, iteratee) {
    return !_.chain(tokens)
      .filter(iteratee)
      .isEmpty()
      .value();
  },

  refreshAccessTokens(expiringTokens) {
    Promise.all(_createRequests(expiringTokens))
      .then(resps => resps.map(_decodeAccessToken))
      .then(decodePromises => Promise.all(decodePromises))
      .then(decodedResults => decodedResults.map(_formatDecodedToken))
      .then(refreshedTokens => {
        // update the current user's auth
        for (let i = 0; i < refreshedTokens.length; i += 1) {
          const data = refreshedTokens[i];
          store.dispatch(
            refreshTokenAction({
              [data.storePath]: {
                accessToken: data.accessToken,
                expires: data.expires,
                modules: data.modules,
              },
            })
          );
        }

        // overwrite tokens removing duplicates
        _tokens = _.unionBy(refreshedTokens, _tokens, 'refreshToken');
      })
      .catch(error => console.log(error));
  },

  // do not rename the "detail" param, it's a reserved key for custom events

  addToken({ detail }) {
    const duplicateToken = _.find(_tokens, {
      refreshToken: detail.refreshToken,
    });

    if (!duplicateToken) {
      _tokens = [..._tokens, detail];
    }

    if (!refreshInterval) {
      this.initInterval();
    }
  },

  removeToken({ detail }) {
    let tokenIndex;

    // this is a temporary hack to stop the login redirect
    if (_.isEmpty(detail)) {
      tokenIndex = _.findIndex(_tokens, {
        storePath: 'user',
      });
    } else {
      tokenIndex = _.findIndex(_tokens, {
        refreshToken: detail.refreshToken,
      });
    }

    if (tokenIndex) {
      _tokens.splice(tokenIndex, 1);
    }

    if (!_tokens.length) {
      this.cleanup();
    }
  },

  initInterval() {
    refreshInterval = setInterval(
      this.checkTokens.bind(this),
      INTERVAL_SECONDS * 1000
    );
  },

  cleanup() {
    clearInterval(refreshInterval);
    _tokens = [];
    refreshInterval = null;
  },
};

function _expireIteratee(token) {
  const newTime = new Date().getTime() / 1000;
  const shiftedExpireTime = token.expires - INTERVAL_SECONDS * 2;

  return newTime >= shiftedExpireTime;
}

function _createRequests(tokens) {
  return tokens.map(token => refreshAccessToken(token.refreshToken));
}

function _decodeAccessToken(resp) {
  const accessToken = _.get(resp, 'data.accessToken');

  return new Promise(resolve =>
    jwt.decodeAndPullPayload(accessToken).then(payload =>
      resolve({
        payload,
        refreshToken: resp.data.refreshToken,
        accessToken: resp.data.accessToken,
      })
    )
  );
}

function _formatDecodedToken({ accessToken, payload, refreshToken }) {
  const modules = payload.modules
    ? payload.modules.reduce((acc, mod) => ({ ...acc, [mod]: true }), {})
    : {};
  let storePath = 'user';
  if (payload.super) {
    storePath = 'superUser';
  } else if (payload.partner) {
    storePath = 'partnerUser';
  }
  return {
    storePath, // : payload.super ? 'superUser' : 'user',
    expires: payload.exp,
    refreshToken,
    accessToken,
    modules,
  };
}

export default AuthWorker;
