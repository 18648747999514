import { makeStyles } from '@material-ui/styles';

// Usage of 'any' inside this style decorator is discouraged; however, our theme
// currently does not conform to MUI's theme structure standards. So this will
// be a followup fix for later.
export const useStyles = makeStyles(({ palette }: any) => ({
  accordionSummaryCard: {
    backgroundColor: palette.common.darkGreySoft,
    color: palette.primary.contrastText,
    fontSize: 15,
    '& .MuiAccordionSummary-expandIcon': {
      color: palette.primary.contrastText,
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  currentBuildSection: {
    paddingTop: '20px',
  },
  errorWrapper: {
    paddingTop: 15
  }
}));
