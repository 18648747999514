import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
/* Helpers */
import { detailsConfig } from 'utility/AccountDetails';
import _ from 'lodash';

const styles = themeSelected => {
  const {
    palette: { tables },
  } = themeSelected;
  return {
    wrapper: {
      display: 'flex',
      alignSelf: 'flex-start',
      flexDirection: 'column',
      width: '100%',
    },
    dataWrapper: {
      display: 'flex',
      flexDirection: 'row',
      padding: '0 5px 10px 5px',
      justifyContent: 'space-between',
    },
    label: {
      color: tables.headerCell,
      fontWeight: 'bold',
      fontSize: '13px',
      width: '25%',
    },
    labelValue: {
      color: tables.cell,
      fontSize: '13px',
      width: '75%',
      display: 'flex',
    },
  };
};

class AccountDetailsData extends Component {
  render() {
    const { classes, account, dataProperty } = this.props;
    return (
      <div className={classes.wrapper}>
        {detailsConfig[dataProperty]
          && detailsConfig[dataProperty].map((configObj, index) => {
            const path = configObj.path ? configObj.path : configObj.key;
            let value = configObj.defaultValue;

            if (_.has(account, path)) {
              value = _.get(account, path);
            }

            if (typeof configObj.fetch === 'function') {
              value = configObj.fetch(account[path]);
            }
            return (
              <div key={`${value}-${index}`} className={classes.dataWrapper}>
                <div className={classes.label}>{configObj.label}</div>
                <div className={classes.labelValue}>{value}</div>
              </div>
            );
          })}
      </div>
    );
  }
}

export default withStyles(styles)(AccountDetailsData);
