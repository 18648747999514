import memoizeTableQuery from 'components/UI/Table/utils/memoizeTableQuery';
import { IAffectedAppsPostPayload } from '../APCCreatePolicyModal/models';
import { IQueryParams } from './models';
import { fetchTableData } from './utils';

const tableQuery = (
  affectedAppsPostDataPayload: IAffectedAppsPostPayload,
  setTotalDataCount: (count: number) => void,
  query: IQueryParams
) => {
  return memoizeTableQuery(async () => {
    const { data } = await fetchTableData(
      query,
      affectedAppsPostDataPayload,
      setTotalDataCount
    );
    return {
      data: data?.content ?? [],
      count: data?.totalElements ?? 0,
    };
  });
};

export default tableQuery;
