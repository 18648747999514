import { Tooltip } from '@material-ui/core';
import { IProtectionStatuses, EPROTECTION_STATUSES_ENUM } from './models';

interface Props {
  status: IProtectionStatuses | string;
  hideTooltip?: boolean;
}
export const ProtectionStatus = ({ status, hideTooltip }: Props) => {
  const statusCircleColor: string = EPROTECTION_STATUSES_ENUM[status as unknown as keyof IProtectionStatuses]?.color || 'transparent';
  const tooltipString: string | IProtectionStatuses = EPROTECTION_STATUSES_ENUM[status as unknown as keyof IProtectionStatuses]?.tooltip || status;

  if (hideTooltip) {

    return (<div className='flex--start'>
      <span
        style={{
          background: statusCircleColor,
          width: 12,
          height: 12,
          borderRadius: '50%',
          marginRight: 6,
        }}
      />
      <span className='table-cell__ellipsis'>
        {EPROTECTION_STATUSES_ENUM[status as unknown as keyof IProtectionStatuses]?.name || status}
      </span>
    </div>);
  }
  return (
    <Tooltip
      arrow
      enterDelay={300}
      placement="top"
      title={<span style={{ fontSize: 12 }}>{tooltipString}</span>}>

      <div className='flex--start'>
        <span
          style={{
            background: statusCircleColor,
            width: 12,
            height: 12,
            borderRadius: '50%',
            marginRight: 6,
          }}
        />
        <span className='table-cell__ellipsis'>
          {EPROTECTION_STATUSES_ENUM[status as unknown as keyof IProtectionStatuses]?.name || status}
        </span>
      </div>
    </Tooltip>
  );
};