import React from 'react';
import _ from 'lodash';

// actions
import { publishEvent } from 'utils/eventUtils';

// components
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/UI/Button';

// services
import { deleteZScanAssessment } from 'api/zDevService';
import {
  toggleModalDiffered,
  openSnackBar,
  toggleModalDirect,
} from '../../utils/storeUtils';

interface ZscanAssessmentDeleteConfirmProps {
  data: {
    rowData: {
      name: string;
      platform: string;
      id: string;
    };
  };
}

const ZscanAssessmentDeleteConfirm: React.FC<
  ZscanAssessmentDeleteConfirmProps
> = ({ data }) => {
  const handleSubmit = () => {
    deleteZScanAssessment({ assessmentId: _.get(data, 'rowData.id') })
      .then(() => {
        publishEvent('table:force-fetch-buildsApps');
        toggleModalDirect('ZscanAssessmentDeleteConfirm', false);
        openSnackBar(`Your assessment was successfully deleted`);
      })
      .catch(() => {
        toggleModalDirect('ZscanAssessmentDeleteConfirm', false);
        openSnackBar(`Deleted Failed`);
      });
  };


  return (
    <DialogContent>
      <p>
        Deleting the assessment will permanently remove all
        findings and statistics from zScan.
        <br />
        <br /> Click the Delete Assessment button to proceed.
      </p>
      <DialogActions>
        <Button
          onClick={toggleModalDiffered('ZscanAssessmentDeleteConfirm', false)}
          color="secondary"
          text="Cancel"
        />
        <Button
          onClick={handleSubmit}
          type="submit"
          color="primary"
          text="Delete Assessment"
        />
      </DialogActions>
    </DialogContent>
  );
};

export default ZscanAssessmentDeleteConfirm;
