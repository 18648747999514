import React, { PureComponent } from 'react';

// services
import { deleteSelectedRole } from 'api/apis';

// components
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ZButton from 'UI/Buttons/ZButton';

// utils
import { publishEvent } from 'utils/eventUtils';
import {
  toggleModalDirect,
  openSnackBar,
  toggleModalDiffered,
} from '../../utils/storeUtils';

class RolesDeleteConfirm extends PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const { props } = this;

    return (
      <DialogContent>
        <p>
          Are you sure you want to delete the role "{props.data.rowData.name}" ?
        </p>
        <DialogActions>
          <ZButton
            styleName="modalCancel"
            action={toggleModalDiffered('RolesDeleteConfirm', false)}
            color="secondary"
            buttonText="Cancel"
          />
          <ZButton
            action={this.handleSubmit}
            buttonType="submit"
            color="primary"
            styleName="modalSave"
            buttonText="Delete Role"
          />
        </DialogActions>
      </DialogContent>
    );
  }

  handleSubmit() {
    const { props } = this;

    deleteSelectedRole(props.data.rowData.id)
      .then(() => {
        toggleModalDirect('RolesDeleteConfirm', false);
        openSnackBar('Role successfully deleted');
        publishEvent('table:force-fetch-roles');
      })

      .catch(error => {
        toggleModalDirect('RolesDeleteConfirm', false);
        openSnackBar(`Failed to delete role: ${error.response.data}`);
      });
  }
}

RolesDeleteConfirm.defaultProps = {
  data: {},
};

export default RolesDeleteConfirm;
