import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, sizing }: any) => {
  const sidebarOffset = sizing.sidebar.maxWidth + sizing.mainContent.padding;

  return createStyles({
    titleBar: {
      background: palette.background.paper,
      boxShadow: 'none',
      left: sidebarOffset,
      top: 64,
      width: `calc(100% - ${sidebarOffset + sizing.mainContent.padding}px)`,
      zIndex: 2,

      '&:before': {
        background: palette.background.paper,
        bottom: 0,
        content: '""',
        height: '100%',
        left: '-5px',
        position: 'absolute',
        right: '-5px',
        width: '101%',
      },

      '& > div': {
        position: 'relative',
      },
    },
  });
});

export default useStyles;
