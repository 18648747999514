import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) => {
  return createStyles({
    formGroup: {
      outline: `2px dashed ${palette.primary.main}`,
      outlineOffset: '-10px',
      WebkitTransition:
        'outline-offset .15s ease-in-out, background-color .15s linear',
      transition:
        'outline-offset .15s ease-in-out, background-color .15s linear',
      height: 250,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    dropLabel: {
      marginTop: -100,
    },
    errorContainer: {
      marginLeft: 15,
      marginRight: 15
    },
    expansionContainer: {
      marginLeft: 10,
      marginRight: 10
    },
    notificationEmailContainer: {
      marginLeft: '30px',
    },
    spinnerWrapper: {
      display: 'flex',
      justifyContent: "center",
    },
    modal: {
      '& .MuiDialog-container > div': {
        maxWidth: '1186px',
        width: '1186px',
        overflow: 'scroll'
      },
    },

  });
});

export default useStyles;
