import _ from 'lodash';
import React from 'react';
import withRouter from 'components/hocs/withRouter';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// ui
import MaterialTableCell from '@material-ui/core/TableCell';
import MaterialTableRow from '@material-ui/core/TableRow';
import MaterialTableHead from '@material-ui/core/TableHead';

import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import ZTableFilterMenu from 'UI/Menus/ZTableFilterMenu';

// utils
import ColumnSelector from 'utility/ColumnSelector';
// selectors
import {
  getAvailableTeamsAsMultiSelectList,
  updateTeamsFilter,
} from 'reducers/TeamReducers';
// components
import { TableColumnsContext } from 'components/Tables';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import { MultiSelect } from 'components/Selects/index';

class TableHeader extends React.Component {
  constructor(props) {
    super(props);
    const selectedTeamsList = _.get(
      this.props.location,
      'query.teamfilter',
      []
    );

    const selectedTeamsForMulti = selectedTeamsList.map(
      teamId => this.props.availableTeamsHash[teamId]
    );

    this.state = {
      anchorEl: null,
      columnAnchorEl: null,
      columnFilter: false,
      filterable: false,
      selectedTeamsForMulti,
    };

    this.createSortHandler = this.createSortHandler.bind(this);
    this.handleClickColumn = this.handleClickColumn.bind(this);
    this.handleCloseColumnFilter = this.handleCloseColumnFilter.bind(this);
    this.handleFilterOpen = this.handleFilterOpen.bind(this);
    this.handleCloseFilter = this.handleCloseFilter.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location.query.teamfilter &&
      !_.isEqual(
        prevProps.location.query.teamfilter,
        this.props.location.query.teamfilter
      )
    ) {
      const updatedTeamsList = _.get(
        this.props.location,
        'query.teamfilter',
        []
      );
      const selectedTeamsForMulti = updatedTeamsList.map(
        teamId => this.props.availableTeamsHash[teamId]
      );
      this.setState({ selectedTeamsForMulti });
    }
    // if the team filter turns to empty
    if (
      !this.props.location.query.teamfilter &&
      !_.isEqual(
        prevProps.location.query.teamfilter,
        this.props.location.query.teamfilter
      )
    ) {
      this.setState({ selectedTeamsForMulti: [] });
    }
  }

  render() {
    const { props, state } = this;
    const { order, orderBy } = props.location.query;

    return (
      <TableColumnsContext.Consumer>
        {({ columnHeaders, onColumnChange, onSort }) => {
          return (
            <MaterialTableHead className={props.classList.tableHeader}>
              <MaterialTableRow>
                {columnHeaders.map(c => {
                  if (!c.show) {
                    return null;
                  }

                  const sortKeyword = c.id;
                  if (c.filterable && c.show) {
                    return (
                      <MaterialTableCell
                        key={c.id}
                        padding="dense"
                        className={props.classList.tableHeaderCell}
                      >
                        {c.label}
                        <FilterListIcon
                          className={props.classList.FilterIcon}
                          aria-owns={
                            this.state.filterBox ? 'render-props-popover' : null
                          }
                          aria-haspopup="true"
                          variant="contained"
                          onClick={event => {
                            this.handleFilterOpen(event.currentTarget);
                          }}
                        />
                        <ZTableFilterMenu
                          id="render-props-popover"
                          open={this.state.filterBox}
                          onClose={this.handleCloseFilter}
                          anchorEl={this.state.columnAnchorEl}
                        >
                          <div className={props.classList.multiSelect}>
                            <MultiSelect
                              name="teams"
                              isMulti
                              buttonPlaceholder="Search"
                              options={this.props.availableTeams}
                              onChange={this.handleSelect}
                              values={this.state.selectedTeamsForMulti}
                            />
                          </div>
                        </ZTableFilterMenu>
                      </MaterialTableCell>
                    );
                  }
                  // actions
                  if (c.isProtected && c.show) {
                    return (
                      <ProtectedComponent
                        key={c.id}
                        allow={{ zapps: 'manage' }}
                      >
                        <MaterialTableCell
                          align="left"
                          padding="dense"
                          classes={{ root: props.classList.tableHeaderCell }}
                        >
                          {c.label}
                        </MaterialTableCell>
                      </ProtectedComponent>
                    );
                  }
                  if (!c.sort && c.show) {
                    // groups
                    return (
                      <MaterialTableCell
                        key={c.id}
                        padding="dense"
                        className={props.classList.tableHeaderCell}
                      >
                        {c.label}
                      </MaterialTableCell>
                    );
                  }

                  return (
                    <MaterialTableCell
                      key={c.id}
                      align={c.numeric ? 'right' : 'left'}
                      padding="dense"
                      classes={{ root: props.classList.tableHeaderCell }}
                    >
                      <Tooltip
                        title="Sort"
                        enterDelay={300}
                        placement={c.numeric ? 'bottom-end' : 'bottom-start'}
                      >
                        <TableSortLabel
                          active={orderBy === c.id || orderBy === sortKeyword}
                          direction={order}
                          onClick={this.createSortHandler(sortKeyword, onSort)}
                        >
                          {c.label}
                        </TableSortLabel>
                      </Tooltip>
                    </MaterialTableCell>
                  );
                })}

                <MaterialTableCell
                  align="right"
                  padding="none"
                  classes={{ root: props.classList.actionsTableHeaderCell }}
                >
                  <ColumnSelector
                    defaultOptions={columnHeaders}
                    open={state.columnFilter}
                    anchorEl={state.anchorEl}
                    columnChange={onColumnChange}
                    toOpen={this.handleClickColumn}
                    onClose={this.handleCloseColumnFilter}
                  />
                </MaterialTableCell>
              </MaterialTableRow>
            </MaterialTableHead>
          );
        }}
      </TableColumnsContext.Consumer>
    );
  }

  handleCloseColumnFilter() {
    this.setState({
      anchorEl: null,
      columnFilter: false,
    });
  }

  handleClickColumn(event) {
    this.setState({
      anchorEl: event.currentTarget,
      columnFilter: !this.state.columnFilter,
    });
  }

  createSortHandler(property, onSort) {
    return () => {
      const { props } = this;
      let newOrder = 'asc';

      if (
        _.eq(props.location.query.orderBy, property) &&
        _.eq(props.location.query.order, 'asc')
      ) {
        newOrder = 'desc';
      }

      props.updateUrl({
        page: 0, // need to send them back to first page
        order: newOrder,
        orderBy: property,
      });
      if (_.isFunction(onSort)) {
        onSort(property, newOrder);
      }
    };
  }

  handleFilterOpen(e) {
    if (e) {
      this.setState({
        filterBox: !this.state.filterBox,
        columnAnchorEl: e,
      });
    }
  }

  handleCloseFilter() {
    this.setState({
      columnAnchorEl: undefined,
      filterBox: false,
    });
  }

  handleSelect(name, values) {
    this.props.updateTeamsFilter({ table: 'Apps', values });
    const changedTeamfilterUrl = values.map(team => team.value);
    this.props.updateUrl({ teamfilter: changedTeamfilterUrl });
  }
}

const mapStateToProps = state => {
  const availTeams = getAvailableTeamsAsMultiSelectList(state);
  const availableTeamsHash = availTeams.reduce((accum, value) => {
    return {
      ...accum,
      [value.value]: value,
    };
  }, {});
  return {
    availableTeams: getAvailableTeamsAsMultiSelectList(state),
    availableTeamsHash,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateTeamsFilter,
    },
    dispatch
  );
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TableHeader)
);
