import MUICard from '@material-ui/core/Card';
import MUICardContent from '@material-ui/core/CardContent';
import MUIToolbar from '@material-ui/core/Toolbar';
import RangeSelector from 'components/UI/RangeSelector';
import StickyAppBar from 'components/UI/StickyAppBar';
import AppDropdown from 'components/main/common/AppDropdown';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import VectorCard from '../VectorCard';
import { IQueryParams } from '../common/models';
import { IVector } from './models';
import useStyles from './useStyles';
import { EMPTY_VECTORS } from './utils';

interface IThreatsAppBarProps {
  vectors?: IVector[];
  module: string;
  query: IQueryParams;
  updateUISettings: (...args: unknown[]) => void;
  updateUrl: (params: IQueryParams) => void;
}

const ThreatsAppBar: React.FC<IThreatsAppBarProps> = ({
  vectors: threatVectors,
  module,
  query,
  updateUISettings,
  updateUrl,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [initialized, setInitialized] = useState<boolean>(false);

  const [vectors, setVectors] = useState<IVector[]>(EMPTY_VECTORS);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }
    if (!!threatVectors?.[0]) {
      setVectors(threatVectors);
    }
  }, [setVectors, threatVectors, initialized, setInitialized]);

  return (
    <StickyAppBar>
      <MUIToolbar className="view__header">
        <div>
          <h1>{t('GLOBAL.THREATS')}</h1>
          {module !== 'mtd' && (
            <AppDropdown
              query={query}
              storeKey="threats"
              updateUISettings={updateUISettings}
              updateUrl={updateUrl}
            />
          )}
        </div>
        <RangeSelector
          query={query}
          storeKey="threats"
          updateUISettings={updateUISettings}
          updateUrl={updateUrl}
        />
      </MUIToolbar>
      <MUICard className={classes.threatsSummaryContainer}>
        <MUICardContent>
          {!!vectors?.[0] &&
            vectors.map((vector) => (
              <VectorCard key={vector.name} vector={vector} query={query} />
            ))}
        </MUICardContent>
      </MUICard>
    </StickyAppBar>
  );
};

export default ThreatsAppBar;
