import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    accordion: {
      boxShadow: 'none',
      margin: '10px -10px 0 -10px !important',
      color: palette.text.secondary,
    },
    accordionHeader: {
      backgroundColor: palette.tables.header.background,
      border: `1px solid ${palette.tables.border}`,
      color: palette.text.secondary,
      minHeight: 'none',
      '& .MuiAccordionSummary-expandIcon': {
        '&.Mui-expanded': {
          transform: 'rotate(90deg)',
        },
      },
      '& svg': {
        width: 14,
      },
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
    circleStyle: {
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
      flexWrap: 'wrap',
      padding: '1rem',
      width: '515px',
      overflowX: 'scroll',
      marginLeft: '10px',
      '  &::-webkit-scrollbar ': {
        height: 5,
      },

      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
        borderRadius: '10px',
      },

      ' &::-webkit-scrollbar-thumb': {
        backgroundColor: '#8e8e8e',
        outline: '1px solid #8e8e8e',
        borderRadius: '10px',
      },

      textAlign: 'center',
      '&>div>div': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: palette.text.secondary,
        fontSize: '12px',
        fontWeight: 500,
        gap: '10px',
      },
      '&>div>div>span': {
        color: palette.text.secondary,
        fontSize: '12px',
        fontWeight: 500,
      },
      '&>div': {
        display: 'flex',
        width: '515px',
        justifyContent: 'space-evenly',
      },
    },

    findingDetailsContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      padding: '16px',
    },
    detailsSubContianer: {
      display: 'flex',
      gap: '5px',
      flexWrap: 'wrap',
    },
    detailsDescriptions: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: 14,
      gap: '20px',
      '& div span': { color: palette.text.secondary },
      '& div p': { fontSize: 13, lineHeight: 1.5 },
    },

    detailsTable: {
      fontSize: 14,
      alignSelf: 'baseline',
      marginBottom: '10px',
    },
    detailsTableWithPadding: {
      '& tr': {
        '& td:first-child': {
          paddingRight: 15,
          color: palette.text.secondary,
        },
      },
    },
  })
);

export default useStyles;
