import React from 'react';
import { withTheme } from '@material-ui/core/styles';

const PublicLogo = props => {
  return props.theme.palette.type === 'dark' ? (
    <img
      className="public-logo"
      src={props.logoUrl ? props.logoUrl : 'ZconsoleLogoWhite.svg'}
      alt="zAPP Logo"
    />
  ) : (
    <img
      className="public-logo"
      src={props.logoUrl ? props.logoUrl : 'ZconsoleLogoBlack.svg'}
      alt="zAPP Logo"
    />
  );
};

export default withTheme(PublicLogo);
