import _ from 'lodash';

export const getUISettingsWithout = (state, component, omitted = []) => {
  const stateSlice = _.get(state.uiSettings, component);
  const wantedKeys = _.difference(Object.keys(stateSlice), omitted);

  return _.pick(stateSlice, wantedKeys);
};

export const getUISettingsTableHeaders = (state, component) =>
  _.get(state.uiSettings, `${component}.tableHeaders`) || [];
export const getChangedTableHeadersHash = (state, component) => {
  const tableHeaders = _.get(state.uiSettings, `${component}.tableHeaders`);

  return !tableHeaders
    ? {}
    : tableHeaders.reduce(
        (accum, value) => ({
          ...accum,
          [value.id]: value,
        }),
        {}
      );
};
