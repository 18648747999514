import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    form: {
      width: '100%',
    },
    languageWrapper: {
      width: 200,
      marginBottom: 20,
      marginRight: 20,
    },
    alertHeaderWrapper: {
      backgroundColor: palette.tables.header.background,
      borderWidth: 0.5,
      borderColor: palette.common.mediumGrey,
      borderStyle: 'solid',
      padding: '0px 30px',
    },
    alertItemWrapper: {
      borderWidth: '0px 0.5px 0.5px 0.5px',
      borderColor: palette.common.mediumGrey,
      borderStyle: 'solid',
      padding: '15px 30px',
      alignItems: 'center'
    },
    bottomWrapper: {
      backgroundColor: palette.tables.header.background,
      borderWidth: 0.5,
      borderColor: palette.common.mediumGrey,
      borderStyle: 'solid',
      padding: '10px 15px',
    },
    headerLabel: {
      color: palette.text.secondary,
    },
    label: {
    },
    skeletonItem: {
      width: '100%',
    },
    errorBox: {
      marginTop: 20,
      height: 36,
    },
  })
);

export default useStyles;
