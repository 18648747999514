import { fetchApps } from 'api/ZDefendInsights';
import { ISelectItem } from 'components/UI/input/Select';
import { IZShieldApp } from './models';

export const fetchAppsList = async (
  teamId?: string | undefined
): Promise<ISelectItem[]> => {
  const result: { data: { content: IZShieldApp[] } } = await fetchApps({
    teamId,
    size: 10000,
  });

  return [
    { label: `All Apps (${(result?.data?.content ?? []).length})`, value: null },
    ...(result?.data?.content ?? []).map(({ id, name }) => ({
      label: name,
      value: id,
    })),
  ];
};
