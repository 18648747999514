import * as Yup from 'yup';

const testWarningSetting = (units: string, value?: number) => {
  if (typeof value === 'number') {
    switch (units) {
      case 'MINUTES':
        return value < 15;
      case 'HOURS':
        return value < 0.25;
      case 'DAYS':
        return value < 0.00347222;
    }
  }

  return false;
};

const Schema = () => {
  return Yup.object().shape({
    maxWarningsCountIA: Yup.number()
      .min(0, 'Maxmium warning count must be greater than or equal to 0.')
      .required('Maximum warning count required.'),
    maxWarningsCountPA: Yup.number()
      .min(0, 'Maxmium warning count must be greater than or equal to 0.')
      .required('Maximum warning count required.'),
    timeBeforeWarningIA: Yup.number()
      .required('Allowed inactivity time required.')
      .test({
        name: 'time-before-warning-ia',
        // function keyword used below because we need access to "this"
        test: function (value, ctx) {
          const units =
            ctx?.parent?.timeBeforeWarningDisplayUnitsIA?.value ?? 'MINUTES';
          const message =
            'Allowed inactivity time must be greater than or equal to 15 minutes.';
          const result = testWarningSetting(units, value);

          return result
            ? this?.createError({
                message,
                path: 'timeBeforeWarningIA',
              })
            : true;
        },
      }),
    timeBeforeWarningPA: Yup.number()
      .required('Allowed inactivity time required.')
      .test({
        name: 'time-before-warning-pa',
        // function keyword used below because we need access to "this"
        test: function (value, ctx) {
          const units =
            ctx?.parent?.timeBeforeWarningDisplayUnitsPA?.value ?? 'MINUTES';
          const message =
            'Allowed inactivity time must be greater than or equal to 15 minutes.';
          const result = testWarningSetting(units, value);

          return result
            ? this?.createError({
                message,
                path: 'timeBeforeWarningPA',
              })
            : true;
        },
      }),
    timeBetweenWarningsIA: Yup.number()
      .required('Warning interval time required.')
      .test({
        name: 'time-between-warnings-ia',
        // function keyword used below because we need access to "this"
        test: function (value, ctx) {
          const units =
            ctx?.parent?.timeBetweenWarningsDisplayUnitsIA?.value ?? 'MINUTES';
          const message =
            'Warning interval time must be greater than or equal to 15 minutes.';
          const result = testWarningSetting(units, value);

          return result
            ? this?.createError({
                message,
                path: 'timeBetweenWarningsIA',
              })
            : true;
        },
      }),
    timeBetweenWarningsPA: Yup.number()
      .required('Warning interval time required.')
      .test({
        name: 'time-between-warnings-pa',
        // function keyword used below because we need access to "this"
        test: function (value, ctx) {
          const units =
            ctx?.parent?.timeBetweenWarningsDisplayUnitsPA?.value ?? 'MINUTES';
          const message =
            'Warning interval time must be greater than or equal to 15 minutes.';
          const result = testWarningSetting(units, value);

          return result
            ? this?.createError({
                message,
                path: 'timeBetweenWarningsPA',
              })
            : true;
        },
      }),
  });
};

export default Schema;
