import { deleteProtectedApp, deleteProtectedBuild } from 'api/zShieldService';
import moment from 'moment';
import { publishEvent } from 'utils/eventUtils';
import { openSnackBar, toggleModalDirect } from 'utils/storeUtils';
import { IAppDetailMapping, IDeleteInfo } from './models';

export const APP_DETAIL_MAPPINGS: IAppDetailMapping[] = [
  {
    id: 1,
    key: 'bundleId',
    label: 'Package / Bundle ID',
  },
  {
    id: 2,
    key: 'version',
    label: 'Version',
  },
  {
    id: 3,
    key: 'description',
    label: 'Description',
  },
  {
    id: 4,
    key: 'protectedOn',
    label: 'Protection Date',
    formatFn: (date: string | number | boolean | undefined) => {
      if (typeof date === 'string') {
        return moment(new Date(date)).format('DD-MMM-YYYY');
      }
      return date;
    },
  },
  {
    id: 5,
    key: 'status',
    label: 'Status',
  },
  {
    id: 6,
    key: 'zshieldVersion',
    label: 'zShield Version',
  },
];

export const deleteBuild = ({ appId, appName, buildId }: IDeleteInfo) => {
  if (!appId && !buildId) {
    return;
  }

  const deleteFn = !!appId
    ? () => deleteProtectedApp({ appId })
    : () => deleteProtectedBuild({ buildId });

  deleteFn()
    .then(() => {
      openSnackBar(`Successfully deleted "${appName}"`);
      publishEvent('zshield:reload-tables');
      toggleModalDirect('GenericConfirm', false);
    })
    .catch((e: any) => {
      publishEvent('zshield:reload-tables');
      toggleModalDirect('GenericConfirm', false);
      openSnackBar(
        `Failed to delete ${appName}. ${
          JSON.stringify(e?.response?.data) ?? ''
        }`
      );
    });
};

export const showConfirmDeleteModal = (deleteInfo: IDeleteInfo) => {
  toggleModalDirect(
    'GenericConfirm',
    {
      confirmButtonText: 'Delete',
      confirmMessageText: `Are you sure you want to delete "${
        deleteInfo?.appName ?? ''
      }"?`,
      onConfirm: () => deleteBuild(deleteInfo),
      onCancel: () => toggleModalDirect('GenericConfirm', false),
    },
    { title: 'Delete Build Confirmation' }
  );
};
const valuesToFilter = ['bitcode', 'Bitcode', 'armv7', 'ARMv7'];
const filterMultipleValues = (array: any, valuesToFilter: string[]) => {
  return array.filter((item: string) => !valuesToFilter.includes(item));
};
export const extractEnabledApplicationsFromArray = (
  errorMessages: string[]
) => {
  const enabledApplications: string[] = [];
  const supportedTags = new Set();

  errorMessages.forEach((errorMessage) => {
    const matches = errorMessage.match(/\[(.*?)\]/);

    if (matches) {
      const tags = matches[1]
        .split(',')
        .map((tag) => tag.trim().replace(/'/g, ''));
      tags.forEach((tag) => supportedTags.add(tag));
    } else {
      enabledApplications.push(errorMessage);
    }
  });
  const unsupportedTags = filterMultipleValues(
    [...supportedTags],
    valuesToFilter
  );

  supportedTags.forEach((tag) => {
    if (tag == 'bitcode' || tag == 'Bitcode') {
      enabledApplications.push(
        `Apple Embedded Bitcode must be enabled in XCode.`
      );
    } else if (tag == 'ARMv7' || tag == 'armv7') {
      enabledApplications.push(
        `The ARMv7 architecture is not supported for iOS.`
      );
    }
  });

  if (unsupportedTags.length) {
    enabledApplications.push(
      `Detected unsupported framework(s) [ ${unsupportedTags.join(', ')} ].`
    );
  }
  return enabledApplications;
};
