import { OS_NAMES } from 'components/main/ZScanApps/utils';
import { TFunction } from 'react-i18next';
import AndroidIcon from 'components/UI/icons/AndroidIcon';
import AppleIcon from 'components/UI/icons/AppleIcon';
import ChromeOSIcon from 'components/UI/icons/ChromeOSIcon';
import Moment from 'moment';
import cc from 'classcat';
import { IRiskPostures } from './models';

export const RISK_POSTURES: IRiskPostures = {
  0: { name: 'GLOBAL.NORMAL', className: 'normal' },
  1: { name: 'GLOBAL.LOW', className: 'low' },
  2: { name: 'GLOBAL.ELEVATED', className: 'elevated' },
  3: { name: 'GLOBAL.CRITICAL', className: 'critical' },
};
export const showAffectedDevicesHeader = [
  {
    id: 'os.type.keyword',
    flex: 1,
    label: 'Platform',
    minWidth: 125,
    maxWidth: 200,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'os.version.keyword',
    flex: 1,
    label: 'OS Version',
    minWidth: 125,
    maxWidth: 200,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'model.keyword',
    flex: 1,
    label: 'Device Model',
    minWidth: 125,
    maxWidth: 200,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'deviceOwner.email.keyword',
    flex: 1,
    idCSV: 'deviceOwner',
    label: 'GLOBAL.DEVICE_OWNER',
    minWidth: 150,
    maxWidth: 200,
    resizable: true,
    showInContextMenu: true,
    sort: true,
    show: true,
  },
  {
    id: 'riskPosture',
    flex: 1,
    sort: true,
    label: 'MTD.DEVICES.RISK_POSTURE',
    idCSV: 'riskPosture',
    minWidth: 150,
    maxWidth: 200,
    resizable: true,
    showInContextMenu: true,
    show: true,
  },
  {
    id: 'groupName',
    flex: 1,
    label: 'Device Group',
    minWidth: 150,
    maxWidth: 200,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: false,
  },
  {
    id: 'zdeviceId.keyword',
    flex: 1,
    label: 'zDeviceId',
    minWidth: 300,
    maxWidth: 400,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'mdmDeviceId.keyword',
    flex: 1,
    label: 'MDMDeviceId',
    minWidth: 300,
    maxWidth: 400,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'mamDeviceId.keyword',
    flex: 1,
    label: 'MAMDeviceId',
    minWidth: 300,
    maxWidth: 400,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'lastSeen',
    flex: 1,
    label: 'Last Seen',
    minWidth: 200,
    maxWidth: 400,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
];

export const showAffectedDevicesRowMapping = (
  t: TFunction<'translation', undefined>
) => {
  return [
    {
      path: 'os.type.keyword',
      columnContent: ({ classList, rowData }: any) => {
        if (!rowData?.os?.type) {
          return <span className={classList?.osCell ?? ''} />;
        }
        const osLowerCaseName = rowData?.os?.type?.toLowerCase() ?? '';
        const osName = OS_NAMES?.[osLowerCaseName] ?? '';

        const icon =
          osLowerCaseName === 'ios' ? (
            <AppleIcon />
          ) : ['chrome os', 'chrome_os'].includes(osLowerCaseName) ? (
            <div className="table__os-icon">
              <ChromeOSIcon />
            </div>
          ) : osLowerCaseName === 'chrome_os' ? ( // change this when BE renames all references of 'chrome_os' to 'chrome os' or viceversa
            <div className="table__os-icon">
              <ChromeOSIcon />
            </div>
          ) : (
            <AndroidIcon />
          );

        return (
          <div className="table__os-icon flex--spaced">
            {icon}
            <div
              className="table-cell__ellipsis"
              style={{ paddingLeft: '5px' }}
            >
              {osName}
            </div>
          </div>
        );
      },
    },
    {
      path: 'os.version.keyword',
      columnContent: ({ rowData }: any) => (
        <span>{rowData?.os?.version ?? ''}</span>
      ),
    },
    {
      path: 'model.keyword',
      columnContent: ({ rowData }: any) => <span>{rowData?.model ?? ''}</span>,
    },
    {
      path: 'deviceOwner.email.keyword',
      columnContent: (fullData: any) => (
        <span className="table-cell__ellipsis">
          {fullData?.rowData?.deviceOwner?.email ?? 'N/A'}
        </span>
      ),
    },
    {
      path: 'zdeviceId.keyword',
      columnContent: ({ rowData }: any) => (
        <span>{rowData?.zdeviceId ?? ''}</span>
      ),
    },
    {
      path: 'mdmDeviceId.keyword',
      columnContent: ({ rowData }: any) => (
        <span>{rowData?.mdmDeviceId ?? ''}</span>
      ),
    },
    {
      path: 'mamDeviceId.keyword',
      columnContent: ({ rowData }: any) => (
        <span>{rowData?.mamDeviceId ?? ''}</span>
      ),
    },
    {
      path: 'riskPosture',
      columnContent: (fullData: any) => {
        const riskPosture = fullData?.rowData?.riskPosture;
        const colorClassName =
          typeof riskPosture === 'number'
            ? RISK_POSTURES?.[riskPosture]?.className ?? ''
            : '';

        const colorClass = fullData?.classList?.[colorClassName] ?? '';

        return (
          <div className="table__os-icon flex--spaced">
            <div
              className={cc([fullData?.classList?.statusDot ?? '', colorClass])}
            />
            <div className="table-cell__ellipsis">
              {typeof riskPosture === 'number'
                ? t(RISK_POSTURES?.[riskPosture]?.name) ?? ''
                : ''}
            </div>
          </div>
        );
      },
    },
    {
      path: 'deviceGroup',
      columnContent: ({ rowData }: any) => (
        <span>{rowData?.groupName ?? ''}</span>
      ),
    },
    {
      path: 'lastSeen',
      columnContent: ({ rowData }: any) =>
        rowData?.lastSeen
          ? Moment(rowData?.lastSeen).format('DD-MMM-YYYY')
          : '',
    },
  ];
};
