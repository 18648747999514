import { trimAndAddAsterisk } from 'utility/QueryUtility';
import Moment from 'moment';

export const fetchBuildsParamsMapping = [
  {
    path: 'after',
    transform: ({ after }) => (after ? after.toISOString() : null),
  },
  {
    path: 'before',
    transform: ({ before }) =>
      before ? before.toISOString() : new Date().toISOString(),
  },
  {
    path: 'from',
    paramKey: 'after',
    transform: ({ from, to }) => {
      if (!from) {
        return null;
      }

      return from === to
        ? Moment.unix(from)
            .startOf('day')
            .toISOString()
        : Moment.unix(from).toISOString();
    },
  },
  {
    path: 'to',
    paramKey: 'before',
    transform: ({ to, from }) => {
      if (!to) {
        return null;
      }

      return from === to
        ? Moment.unix(to)
            .endOf('day')
            .toISOString()
        : Moment.unix(to).toISOString();
    },
  },
  {
    path: 'page',
  },
  {
    path: 'size',
  },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      switch (orderBy) {
        case 'zdevMetadata.bundleName':
        case 'zdevMetadata.bundleIdentifier':
        case 'zdevMetadata.bundleVersion':
        case 'zdevMetadata.osName':
        case 'zdevMetadata.analysis':
        case 'zdevMetadata.appName':
        case 'zdevMetadata.appVersion':
        case 'zdevMetadata.appBuildVersion':
          return `${orderBy}.keyword,${order}`;
        default:
          if (orderBy && order) {
            return `${orderBy},${order}`;
          }
          return null;
      }
    },
  },
  {
    path: 'search',
    transform: ({ search = '' }) => {
      if (!search) {
        return null;
      }

      return trimAndAddAsterisk(search);
    },
  },
  {
    path: 'zdevAppId',
    paramKey: 'zdevAppId.keyword',
  },
  {
    path: 'zdevMetadata.appName',
    paramKey: 'zdevMetadata.appName.keyword',
  },
  {
    path: 'zdevMetadata.bundleIdentifier',
    paramKey: 'zdevMetadata.bundleIdentifier.keyword',
  },
  {
    path: 'zdevMetadata.appVersion',
    paramKey: 'zdevMetadata.appVersion.keyword',
  },
  {
    path: 'zdevMetadata.appBuildVersion',
    paramKey: 'zdevMetadata.appBuildVersion.keyword',
  },
  {
    path: 'zdevMetadata.osName',
    paramKey: 'zdevMetadata.osName.keyword',
  },
  {
    path: 'unresolvedPrivacyFindingsCount',
  },
  {
    path: 'unresolvedSecurityFindingsCount',
  },
  {
    path: 'zdevMetadata.analysis',
    paramKey: 'zdevMetadata.analysis.keyword',
  },
  {
    path: 'buildFindingsCount',
  },
];
