import React from 'react';
import { Checkbox } from 'components/inputs';
import { FormError } from './FormError';

export const FormikCheckbox = ({ field, form, ...props }) => {
  if (props.isShowing === false) {
    return null;
  }
  return (
    <div style={{ position: 'relative' }}>
      <Checkbox {...field} {...props} />
      {form.touched[field.name] && form.errors[field.name] && (
        <FormError field={field} form={form} />
      )}
    </div>
  );
};
