import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

export default state => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      deviceActivations: {
        ...state.uiSettings.deviceActivations,
        tableHeaders: uiSettingsDefaults.deviceActivations.tableHeaders,
      },
    },
  };
};
