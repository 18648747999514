import React from 'react';
import JSONTree from 'react-json-tree';
import severitiesMapping from 'utility/Severities';
import _ from 'lodash';
// ui
import Button from '@material-ui/core/Button';
import ContentCopyIcon from '@material-ui/icons/FilterNone';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CloudDownload from '@material-ui/icons/CloudDownload';
// utilities
import momentHelper from 'utils/momentUtils';
import { THREAT_STATUSES_DEPRECATED } from 'components/main/threats/threatMappings';
import { TextField } from 'components/inputs';
import VectorMapping from '../../../utility/VectorMapping';
import ThreatCategoryMapping, {
  getCategoryIdByThreatId,
} from '../../../utility/ThreatCategoryMapping';
import { openSnackBarDiffered, openSnackBar } from '../../../utils/storeUtils';

const defaultValue = 'N/A';
export const forensicsConfig = {
  device: [
    {
      key: 'threatTypeId',
      label: 'Threat Name',
      defaultValue,
      filterable: true,
      fetch: (threatTypeName, props) => {
        return _.get(props, 'threat.threatServerName', 'Unknown');
      },
    },
    {
      key: 'vector',
      label: 'Threat Vector',
      defaultValue,
      fetch: (vectorId) => VectorMapping[vectorId],
      filterable: true,
    },
    {
      key: 'categoryId',
      label: 'Threat Category',
      defaultValue,
      fetch: (categoryId, props) => {
        if (categoryId) {
          return (
            THREAT_STATUSES_DEPRECATED.find(({ id }) => id === categoryId)
              ?.name ?? 'N/A'
          );
        }
        return _.get(
          ThreatCategoryMapping[
            getCategoryIdByThreatId(props.threat.threatTypeId)
          ],
          'name',
          'N/A'
        );
      },
      filterable: true,
    },
    {
      key: 'severity',
      label: 'Threat Severity',
      defaultValue,
      fetch: (severity) => _.get(severitiesMapping[severity], 'name'),
      filterable: true,
    },
    {
      key: 'state',
      label: 'Status',
      defaultValue,
      fetch: (state) => _.get(threatStateMapping, `${state}`, 'N/A'),
      filterable: true,
    },
    {
      key: 'mitigatedAt',
      label: 'Last Mitigated',
      defaultValue,
      fetch: (mitigatedAt) => {
        if (!mitigatedAt) return 'N/A';
        return momentHelper(mitigatedAt);
      },
      filterable: false,
    },
    {
      key: 'simulated',
      label: 'Simulation State',
      defaultValue,
      fetch: (simulated) => (simulated ? 'Simulated Threat' : 'Real Threat'),
      filterable: true,
    },
    {
      key: 'timestamp',
      label: 'Timestamp',
      defaultValue,
      fetch: (timestamp) => {
        return momentHelper(timestamp);
      },
      filterable: true,
    },
    {
      key: 'device.zdeviceId',
      label: 'Device ID',
      defaultValue,
      filterable: true,
    },
    {
      key: 'device.mamDeviceId',
      label: 'MAM ID',
      defaultValue,
      filterable: true,
    },
    {
      key: 'externalTrackingId1',
      label: 'Tracking ID 1',
      defaultValue,
      path: 'zappInstance.externalTrackingId1',
      filterable: true,
    },
    {
      key: 'externalTrackingId2',
      label: 'Tracking ID 2',
      defaultValue,
      path: 'zappInstance.externalTrackingId2',
      filterable: true,
    },
    {
      key: 'timeStamp',
      label: 'Summary',
      defaultValue,
      fetch: (typeId, props) => {
        return _.get(props, 'threat.threatDescription', 'N/A');
      },
      filterable: false,
    },
    {
      key: 'activationName',
      label: 'Activation Name',
      defaultValue: 'N/A',
      filterable: true,
    },
  ],
  zappInstance: [
    {
      key: 'id',
      label: 'Event ID',
      defaultValue,
      filterable: false,
    },
    {
      key: 'appId',
      label: 'App ID',
      defaultValue,
      path: 'zappInstance.id',
      filterable: true,
    },
    {
      key: 'bundleId',
      label: 'Bundle ID',
      defaultValue,
      path: 'zappInstance.bundleId',
      filterable: true,
    },
    {
      key: 'zappName',
      label: 'App Name',
      path: 'zappInstance.name',
      defaultValue,
      filterable: true,
    },
    {
      key: 'appVersion',
      label: 'App Version',
      defaultValue,
      path: 'zappInstance.version',
      filterable: true,
    },
    {
      key: 'zVersion',
      label: 'zDefend Version',
      defaultValue,
      path: 'zappInstance.zversion',
      filterable: true,
    },
    {
      key: 'zeventId',
      label: 'zEvent Id',
      defaultValue,
      path: 'zeventId',
      filterable: true,
    },
    {
      key: 'zbuildNumber',
      label: 'zDefend Build',
      defaultValue,
      path: 'zappInstance.zbuildNumber',
      filterable: true,
    },
    {
      key: 'os',
      label: 'OS',
      defaultValue,
      fetch: (os) =>
        os === 'ios' ? 'iOS' : os === 'chrome_os' ? 'Chrome OS' : 'Android',
      filterable: true,
    },
  ],
  generalInfo: [
    {
      key: 'timeInterval',
      label: 'Time Interval',
      defaultValue,
      path: 'generalInfo.timeInterval',
      filterable: false,
    },
    {
      key: 'deviceIp',
      label: 'Device IP',
      defaultValue,
      path: 'generalInfo.deviceIp',
      filterable: true,
    },
    {
      key: 'ssid',
      label: 'Network SSID',
      defaultValue,
      path: 'generalInfo.ssid',
      filterable: true,
    },
    {
      key: 'bssid',
      label: 'Network BSSID',
      defaultValue,
      path: 'generalInfo.bssid',
      filterable: true,
    },
    {
      key: 'actionTriggered',
      label: 'Action Triggered',
      defaultValue,
      path: 'generalInfo.actionTriggered',
      filterable: false,
    },
    {
      key: 'externalIp',
      label: 'External IP',
      defaultValue,
      path: 'generalInfo.externalIp',
      filterable: true,
    },
    {
      key: 'gatewayMac',
      label: 'Gateway MAC',
      defaultValue,
      path: 'generalInfo.gatewayMac',
      filterable: true,
    },
    {
      key: 'gatewayIp',
      label: 'Gateway IP',
      defaultValue,
      path: 'generalInfo.gatewayIp',
      filterable: true,
    },
    {
      key: 'attackerIp',
      label: 'Attacker IP',
      defaultValue,
      path: 'generalInfo.attackerIp',
      filterable: true,
    },
    {
      key: 'attackerMac',
      label: 'Attacker Mac',
      defaultValue,
      path: 'generalInfo.attackerMac',
      filterable: true,
    },

    {
      key: 'baseStation',
      label: 'Base Station',
      defaultValue,
      path: 'generalInfo.baseStation',
      filterable: false,
    },
    {
      key: 'deviceTimestamp',
      label: 'Device Time',
      defaultValue,
      path: 'generalInfo.deviceTimestamp',
      filterable: false,
      fetch: (timestamp) => {
        return momentHelper(timestamp);
      },
    },
    {
      key: 'jailbreakReasons',
      label: 'Jailbreak Reasons',
      defaultValue,
      path: 'generalInfo.jailbreakReasons',
      fetch: (reasons) => {
        if (reasons) {
          const regexMatch = reasons.match(/\[\s"\[(.*)]"\s]/);

          let withoutQuotes = reasons;
          if (regexMatch) {
            withoutQuotes = regexMatch[1].replace(/["\\]/g, '');
          }

          const reasonsArray = withoutQuotes.split(',');

          return reasonsArray.join('\r\n');
        }
        return reasons;
      },
      filterable: false,
    },
    {
      key: 'appTamperingReasons',
      label: 'App Tampering Reasons',
      defaultValue,
      path: 'generalInfo.appTamperingReasons',
      fetch: (reasons) => {
        if (reasons) {
          const regexMatch = reasons.match(/\[\s"\[(.*)]"\s]/);

          let withoutQuotes = reasons;
          if (regexMatch) {
            withoutQuotes = regexMatch[1].replace(/["\\]/g, '');
          }

          const reasonsArray = withoutQuotes.split(',');

          return reasonsArray.join('\r\n');
        }
        return reasons;
      },
      filterable: false,
    },
    {
      key: 'fileSystemChangeEvent',
      label: 'Event',
      defaultValue,
      path: 'generalInfo.fileSystemChangeEvent',
      filterable: true,
    },
    {
      key: 'fileSystemChangeFullPath',
      label: 'Full Path',
      defaultValue,
      path: 'generalInfo.fileSystemChangeFullPath',
      filterable: false,
    },
    {
      key: 'fileSystemChangeType',
      label: 'Change Type',
      defaultValue,
      path: 'generalInfo.fileSystemChangeType',
      fetch: (changeType) =>
        _.isString(changeType)
          ? changeType.toLowerCase().replace('_', ' ')
          : null,
      filterable: false,
    },
    {
      key: 'vulnerableOsVersion',
      label: 'Vulnerable OS Version',
      path: 'generalInfo.vulnerableOsVersion',
      filterable: true,
    },
    {
      key: 'expectedOsVersion',
      label: 'Expected OS Version',
      path: 'generalInfo.expectedOsVersion',
      filterable: true,
    },
    {
      key: 'vulnerableSecurityPatch',
      label: 'Vulnerable Security Patch',
      path: 'generalInfo.vulnerableSecurityPatch',
      filterable: true,
    },
    {
      key: 'expectedSecurityPatch',
      label: 'Expected Security Patch',
      path: 'generalInfo.expectedSecurityPatch',
      filterable: true,
    },
    {
      key: 'deviceManufacturer',
      label: 'Device Manufacturer',
      path: 'generalInfo.deviceManufacturer',
      filterable: true,
    },
    {
      key: 'deviceModel',
      label: 'Device Model',
      path: 'device.model',
      filterable: true,
    },
    {
      key: 'buildInformation',
      label: 'Build Information',
      path: 'generalInfo.buildInformation',
      filterable: true,
    },
  ],
  sideLoadedAppInfo: [
    {
      key: 'appName',
      label: 'Sideloaded App Name',
      defaultValue,
      path: 'sideLoadedAppInfo.appName',
      filterable: true,
    },
    {
      key: 'developer',
      label: 'Sideloaded App Developer',
      defaultValue,
      path: 'sideLoadedAppInfo.developer',
      filterable: true,
    },
    {
      key: 'bundleId',
      label: 'Sideloaded App Package',
      defaultValue,
      path: 'sideLoadedAppInfo.bundleId',
      filterable: true,
    },
    {
      key: 'fileHash',
      label: 'Sideloaded File Hash',
      defaultValue,
      path: 'sideLoadedAppInfo.fileHash',
      filterable: true,
    },
  ],
  malwareInfo: [
    {
      key: 'bundleId',
      label: 'Bundle ID',
      defaultValue,
      path: 'malwareInfo.bundleId',
      filterable: true,
    },
    {
      key: 'processName',
      label: 'Process Name',
      defaultValue,
      path: 'malwareInfo.processName',
      filterable: true,
    },
    {
      key: 'filename',
      label: 'File Name',
      defaultValue,
      path: 'malwareInfo.filename',
      filterable: true,
    },
    {
      key: 'fileHash',
      label: 'File Hash',
      defaultValue,
      path: 'malwareInfo.fileHash',
      filterable: true,
    },
  ],
  location: [
    {
      key: 'locationInfo',
      label: 'Lat, Long',
      defaultValue,
      fetch: (locationInfo) => {
        return `${locationInfo.geoPoint.lat}, ${locationInfo.geoPoint.lon}`;
      },
    },
    {
      key: 'source',
      label: 'Location Source',
      defaultValue,
      path: 'locationInfo.source',
    },
  ],
  forensicsInfo: [
    {
      key: 'forensicsInfo',
      label: 'Forensics Data',
      defaultValue,
      fetch: (forensicsInfo) => {
        try {
          const data = JSON.parse(forensicsInfo.data);

          return (
            <JSONTree
              style={{ width: '100%' }}
              data={data}
              theme={{
                tree: {
                  width: '100%',
                  padding: 20,
                },
              }}
            />
          );
        } catch (error) {
          return (
            <TextField
              multiline
              fullWidth
              containerClass="fullwidth"
              name="forensics data"
              value={forensicsInfo.data}
            />
          );
        }
      },
    },
  ],
};

const handleDownloadCert = (certData, issuerName) => {
  const textFile = new Blob([certData], { type: 'text/plain;charset=utf-8' });
  const downloadLink = document.createElement('a');
  // Make sure that the link is not displayed
  downloadLink.style.display = 'none';
  // File name
  downloadLink.download = `${issuerName}.crt`;
  // We have to create a link to the file
  downloadLink.href = window.URL.createObjectURL(textFile);
  // Add the link to your DOM
  document.body.appendChild(downloadLink);

  // Lanzamos
  downloadLink.click();
  openSnackBar('Certificate Downloaded');
};
export const certificatesConfig = {
  column1: [
    {
      key: 'issuer.commonName',
      label: 'Issuer',
    },
    {
      key: 'serialNumber',
      label: 'Serial Number',
    },
    {
      key: 'subject.commonName',
      label: 'Subject',
    },
    {
      key: 'subject.organizationUnit',
      label: 'Organization Unit',
    },
  ],
  column2: [
    {
      key: 'thumbprintSha1',
      label: 'Thumbprint',
    },
    {
      key: 'raw',
      label: 'Raw Certificate',
      fetch: (raw, { issuer }) => {
        return (
          <div
            className="table-cell__ellipsis cursor--pointer"
            style={{ width: '25%' }}
            onClick={() =>
              handleDownloadCert(raw, _.get(issuer, 'commonName', 'SSL'))
            }
          >
            <CloudDownload
              style={{
                minWidth: 'auto',
                margin: 0,
                padding: 0,
              }}
            />
            <span style={{ paddingLeft: 8, verticalAlign: 'super' }}>
              Download Certificate
            </span>
          </div>
        );
      },
    },
    {
      key: 'signature',
      label: 'Signature',
      fetch: (signature) => {
        return (
          <div className="table-cell__ellipsis" style={{ width: '25%' }}>
            <CopyToClipboard text={signature}>
              <Button
                style={{ minWidth: 'auto', margin: 0, padding: 0 }}
                onClick={openSnackBarDiffered('Signature copied to clipboard')}
              >
                <ContentCopyIcon style={{ fontSize: '20px' }} />{' '}
                {signature || ''}
              </Button>
            </CopyToClipboard>
          </div>
        );
      },
    },
  ],
};
export const phishingConfig = {
  column1: [
    {
      key: 'phishingUrl',
      label: 'Phishing Url',
      filterable: true,
    },
    {
      key: 'contentCategoryName',
      label: 'Content Category Name',
      filterable: true,
    },
  ],
};
