import React, { Component } from 'react';
import _ from 'lodash';

// components
import ViewApps from './ViewApps';

class InApps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAppVersions: {},
    };
    this.handleSelectedApps = this.handleSelectedApps.bind(this);
    this.handleClearSelectedApps = this.handleClearSelectedApps.bind(this);
    this.handleTeamFilterChange = this.handleTeamFilterChange.bind(this);
  }

  render() {
    return (
      <ViewApps
        selectedAppState={this.state.selectedAppVersions}
        showDeselectButton={!_.isEmpty(this.state.selectedAppVersions)}
        handleSelectedApps={this.handleSelectedApps}
        handleClearSelectedApps={this.handleClearSelectedApps}
        handleTeamFilterChange={this.handleTeamFilterChange}
      />
    );
  }

  handleClearSelectedApps() {
    this.setState({
      selectedAppVersions: {},
    });
  }
  // structure of this state is {appid: {teamId: true} we need to take away the selected apps that are taken away from the teams filter

  handleSelectedApps(selectedAppId) {
    const updatedSelectedAppVersions = { ...this.state.selectedAppVersions };
    const teamId = _.get(selectedAppId, 'team.id', 'no team');
    if (updatedSelectedAppVersions[selectedAppId.id]) {
      delete updatedSelectedAppVersions[selectedAppId.id];
    } else {
      updatedSelectedAppVersions[selectedAppId.id] = { teamId };
    }
    this.setState({
      selectedAppVersions: updatedSelectedAppVersions,
    });
  }

  // making sure the teams filter matches the apps associated w/ the teams
  handleTeamFilterChange(teamFilterChange) {
    const newTeamFilterArray = teamFilterChange.map(team => team.value);
    const newAppVersionState = _.reduce(
      this.state.selectedAppVersions,
      (accum, value, key) => {
        const returnAccum = {
          ...accum,
        };
        if (_.indexOf(newTeamFilterArray, value.teamId) >= 0) {
          returnAccum[key] = value;
        }
        return returnAccum;
      },
      {},
    );
    this.setState({ selectedAppVersions: newAppVersionState });
  }
}

export default InApps;
