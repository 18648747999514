import cc from 'classcat';
import { IHorizontalLegendItem } from 'components/UI/Chart/HorizontalLegend/models';
import NestedDoughnutChart from 'components/UI/Chart/NestedDoughnut';
import GenericCard from 'components/UI/GenericCard';
import { useMemo } from 'react';
import { IRiskyCriticalEvents } from '../models';
import { default as useSharedStyles } from '../useStyles';
import useStyles from './useStyles';
import { useTranslation } from 'react-i18next';

interface ICurrentDeviceRiskStatusProps {
  topRiskyCriticalEvents: IRiskyCriticalEvents;
}

const CurrentDeviceRiskStatus: React.FC<ICurrentDeviceRiskStatusProps> = ({
  topRiskyCriticalEvents,
}) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const { i18n, t, ready } = useTranslation();

  const deviceRiskStatus: IHorizontalLegendItem[] = useMemo(
    () => [
      {
        color: '#f50057',
        label: t('GLOBAL.CRITICAL'),
        link: '/console/mtd/threats/?duration=8&classificationName=CRITICAL',
        value:
          topRiskyCriticalEvents?.currentThreatenedDevicesCount
            ?.criticalCount ?? 0,
      },
      {
        color: '#ff9f1e',
        label: t('GLOBAL.RISKY'),
        link: '/console/mtd/threats/?duration=8&classificationName=RISKY',
        value:
          topRiskyCriticalEvents?.currentThreatenedDevicesCount?.riskyCount ??
          0,
      },
    ],
    [topRiskyCriticalEvents, t]
  );

  const hasData = useMemo(
    () =>
      topRiskyCriticalEvents?.currentThreatenedDevicesCount?.criticalCount ||
      topRiskyCriticalEvents?.currentThreatenedDevicesCount?.riskyCount,
    [topRiskyCriticalEvents?.currentThreatenedDevicesCount]
  );

  if (!ready) {
    return null;
  }

  return (
    <GenericCard className={cc([sharedClasses.card, classes.deviceRiskCard])}>
      <h2>{t('MTD.INSIGHTS.CURRENT_DEVICE_RISK_STATUS')}</h2>
      <div className={sharedClasses.column}>
        {hasData ? (
          <NestedDoughnutChart
            forceProportionate
            segments={deviceRiskStatus}
            withLegend
          />
        ) : (
          <div
            className={cc([
              {
                [classes.pendingNonEnglish]:
                  i18n.language?.toLowerCase() !== 'en-us',
              },
              classes.pending,
            ])}
          >
            {t('GLOBAL.NOT_AVAILABLE')}
          </div>
        )}
      </div>
    </GenericCard>
  );
};

export default CurrentDeviceRiskStatus;
