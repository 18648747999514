import {
  csvInjectionErrorMessage,
  csvInjectionRegex,
} from 'utils/componentUtils';
import * as Yup from 'yup';

const Schema = () => {
  const fields = {
    description: Yup.string()
      .min(3)
      .max(255)
      .matches(csvInjectionRegex, csvInjectionErrorMessage),
  };

  return Yup.object().shape({ ...fields });
};
export default Schema;
