import { ServiceHandlerFactory } from 'utils/serviceUtils';
import { fetchDeviceParamsMapping } from 'mappings/services/deviceServiceMappings';

const deviceBase = '/api/devices/v1';
const v2DeviceBase = '/api/devices/v2';

export const fetchDeviceSecurityScore = ServiceHandlerFactory({
  axios: {
    url: `${deviceBase}/insights/security-score`
  },
  paramSchema: [{ path: 'teamId', paramKey: 'teamId.keyword' }],
});

export const fetchDevicePool = ServiceHandlerFactory({
  axios: {
    url: `${deviceBase}/insights/device-pool`,
  },
  paramSchema: [{ path: 'teamId', paramKey: 'teamId.keyword' }],
});

export const fetchDevicesPage = ServiceHandlerFactory({
  axios: {
    url: `${v2DeviceBase}/devices`,
  },
  isCancelable: true,
  paramSchema: fetchDeviceParamsMapping,
});

export const fetchDevicesStats = ServiceHandlerFactory({
  axios: {
    url: `${deviceBase}/stats`,
  },
  isCancelable: true,
  paramSchema: fetchDeviceParamsMapping,
});

export const fetchDevicesStatsByAccount = ServiceHandlerFactory({
  axios: {
    url: `${deviceBase}/devices/stats/?accountId=:accountId`,
  },
  isCancelable: true,
  paramSchema: fetchDeviceParamsMapping,
});

export const downloadDevicesCsv = ServiceHandlerFactory({
  axios: {
    url: `${deviceBase}/csv`,
    method: 'post',
  },
  isCancelable: true,
  paramSchema: fetchDeviceParamsMapping,
});

export const downloadDevicesCsvCount = ServiceHandlerFactory({
  axios: {
    url: `${deviceBase}/csv/count`,
    method: 'post',
  },
  isCancelable: true,
  paramSchema: fetchDeviceParamsMapping,
});

export const deleteDevice = ServiceHandlerFactory({
  axios: {
    url: `${deviceBase}/devices/:deviceId`,
    method: 'delete',
  },
});

export const uploadAppUrl = ServiceHandlerFactory({
  axios: {
    url: `${deviceBase}/appVersions/upload/url`,
    method: 'post',
  },
  isCancelable: true,
});

export const uploadAppBinary = ServiceHandlerFactory({
  axios: {
    url: `${deviceBase}/appVersions/upload/binary`,
    method: 'post',
  },
  isCancelable: true,
});

export const fetchiPhoneMapping = ServiceHandlerFactory({
  axios: {
    url: `${deviceBase}/devices/apple-device-commercial-names-map`,
    method: 'get',
  },
  isCancelable: true,
});
