import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import AceEditor from 'react-ace';
import CopyToClipboard from 'react-copy-to-clipboard';
// ui
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import ContentCopyIcon from '@material-ui/icons/FilterNone';
import Tooltip from '@material-ui/core/Tooltip';
import ZButton from 'UI/Buttons/ZButton';
import { toggleModalDiffered, openSnackBar } from 'utils/storeUtils';

import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/src-noconflict/theme-solarized_light';
import 'ace-builds/src-noconflict/theme-github';

function codeSnippetHelper(codeSnippetData) {
  if (codeSnippetData.length < 1) return;
  const snippetCodeCopyBlock = codeSnippetData
    .map((lineObject) => {
      return `${lineObject.line}${lineObject.value}`;
    })
    .join('\n');
  const snippetCodeBlock = codeSnippetData
    .map((lineObject) => {
      return `${lineObject.value}`;
    })
    .join('\n');

  return {
    startingLine: _.get(codeSnippetData[0], 'line'),
    snippetCodeBlock,
    snippetCodeCopyBlock,
  };
}

const ZScanSnippets = (props) => {
  const theme = useSelector(({ uiSettings }) => uiSettings.theme);
  const codeSnippetsArray = _.get(props, 'data.rowData.codeSnippets', []);
  const { startingLine, snippetCodeBlock, snippetCodeCopyBlock } =
    codeSnippetHelper(codeSnippetsArray);

  function handleSnackbar() {
    openSnackBar('Snippet copied to clipboard');
  }

  const startHighlightRow =
    _.get(props, 'data.rowData.lineNumbers[0]', 0) - startingLine;
  return (
    <>
      <div className="flex--spaced">
        <DialogTitle>
          <Tooltip title={_.get(props, 'data.rowData.value', 'Location')}>
            <span className="table-cell__ellipsis" style={{ width: 200 }}>
              {' '}
              {_.get(props, 'data.rowData.value', 'Location')}
            </span>
          </Tooltip>
        </DialogTitle>{' '}
        <div style={{ marginRight: 24 }} className="cursor--pointer">
          <Tooltip title="Copy to clipboard">
            <CopyToClipboard text={snippetCodeCopyBlock}>
              <ContentCopyIcon
                className="table__os-icon"
                style={{ marginTop: 10 }}
                onClick={handleSnackbar}
              />
            </CopyToClipboard>
          </Tooltip>
        </div>
      </div>
      <DialogContent>
        <AceEditor
          mode="java"
          theme={theme === 'dark' ? 'solarized_dark' : 'github'}
          name="instanceCodeSnippet"
          editorProps={{ $blockScrolling: true }}
          value={snippetCodeBlock}
          highlight
          width={550}
          minLines={12}
          maxLines={15}
          markers={[
            {
              startRow: startHighlightRow,

              endRow: startHighlightRow + 1,
              className: props.classes.errorMarker,
              type: 'text',
            },
          ]}
          readOnly
          setOptions={{
            enableSnippets: true,
            showLineNumbers: true,
            tabSize: 4,
            firstLineNumber: startingLine,
            highlightActiveLine: false,
          }}
        />
      </DialogContent>
      <DialogActions style={{ marginRight: 20, paddingBottom: 20 }}>
        <ZButton
          styleName="modalCancel"
          action={toggleModalDiffered('ZScanSnippets', false)}
          color="secondary"
          buttonText="Close"
        />
      </DialogActions>
    </>
  );
};

const styles = ({ palette, theme }) => {
  return {
    errorMarker: {
      position: 'absolute',
      backgroundColor: '#F1F177',
    },
  };
};

export default withStyles(styles)(ZScanSnippets);
