export const SCOPE_BOUNDS = {
  team: 'TEAM_BOUNDED',
  account: 'ACCOUNT_BOUNDED',
};

// scopes
export default {
  account: 'Account',
  app_inventory: 'App Inventory',
  app_protections: 'App Protections',
  app_settings: 'App Settings',
  api_keys: 'API Keys',
  data_exports: 'Data Exports',
  devices: 'Devices',
  emm: 'MDM Connections',
  extension_inventory: 'Extension Inventory',
  os_risk: 'OS Risk',
  phishing: 'Phishing',
  policies: 'Policies',
  roles: 'Roles',
  teams: 'Teams',
  threats: 'Threats',
  users: 'Users',
  zapps: 'In-Apps/Activations',
  audit_log: 'Audit Log',
  zdev_apps: 'zScan Apps',
  zdev_assessments: 'zScan Assessments',
  zdev_builds: 'zScan Builds',
  zdev_policies: 'zScan Policies',
  branding_policy: 'MTD Branding Policy',
};

export const common = {
  account: 'Account',
  api_keys: 'API Keys',
  data_exports: 'Data Exports',
  roles: 'Roles',
  teams: 'Teams',
  users: 'Users',
  audit_log: 'Audit Log',
};

export const zDefend = {
  app_settings: 'App Settings',
  devices: 'Devices',
  os_risk: 'OS Risk',
  phishing: 'Phishing',
  policies: 'Policies',
  threats: 'Threats',
  zapps: 'In-Apps/Activations',
};

export const MTD = {
  app_settings: 'App Settings',
  devices: 'Devices',
  policies: 'Policies',
  threats: 'Threats',
  emm: 'MDM Connections',
  zapps: 'In-Apps/Activations',
  app_inventory: 'App Inventory',
  extension_inventory: 'Extension Inventory',
  os_risk: 'OS Risk',
  phishing: 'Phishing',
  branding_policy: 'MTD Branding Policy',
};

export const zScan = {
  zdev_apps: 'zScan Apps',
  zdev_assessments: 'zScan Assessments',
  zdev_builds: 'zScan Builds',
  zdev_policies: 'zScan Policies',
};

export const zShield = {
  app_protections: 'App Protections',
};
