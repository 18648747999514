import React, { useEffect, useState } from 'react';
import withRouter from 'components/hocs/withRouter';
import _ from 'lodash';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import GlobalIndicatorWrapper from 'components/main/common/GlobalIndicatorWrapper';
// Components
import CheckBox from 'components/inputs/Checkbox';
import AddClonePolicyButton from '../AddClonePolicyButton';
import Select from 'components/UI/input/Select';
import Button from 'components/UI/Button';
import TextField from 'components/UI/input/TextField';
import CustomPolicyOption from 'components/main/policies/common/CustomPolicyOption';
import { openSnackBar, toggleModalDiffered } from 'utils/storeUtils';
import useStyles from '../useStyles';
import { setPolicyDefault } from 'api/zDevService';
import { IZScanPolicy } from '../models';
interface IZScanPolicyPickerBarProps {
  isSaving: boolean;
  editMode: boolean;
  selectPolicyLens: IZScanPolicy;
  handleSavePolicy: () => void;
  handlePolicyChange: () => void;
  handleEditMode: () => void;
}

const ZScanPolicyPickerBar: React.FC<IZScanPolicyPickerBarProps> = ({
  isSaving,
  editMode,
  policy,
  selectPolicyLens,
  handleSavePolicy,
  handlePolicyChange,
  handleEditMode,
}: any) => {
  const classes = useStyles();

  const selectedValues = React.useMemo(
    () => selectPolicyLens?.find((e: any) => e.value === policy),
    [selectPolicyLens, policy]
  );

  const [policyName, setPolicyName] = useState<string>(selectedValues?.label);

  useEffect(() => {
    setPolicyName(selectedValues?.label);
  }, [selectedValues]);

  const isAccountBounded = selectedValues?.accountBounded;

  const onSetDefaultChange = async () => {
    try {
      await setPolicyDefault({ policyId: policy });
      openSnackBar('Policy successfully updated');
    } catch (err) {
      openSnackBar('Something went wrong');
    }
  };

  return (
    <>
      {_.isEmpty(policy) ? (
        <Card>
          <CardContent>
            No policies found, please create a policy to continue
          </CardContent>
        </Card>
      ) : (
        <>
          <div style={{ marginBottom: 15 }}>
            <GlobalIndicatorWrapper inline isGlobal />
            <div />
          </div>
          <Card>
            <CardContent>
              <Grid container alignItems="center">
                <Grid item md={4}>
                  <div className={classes.pickerContainer}>
                    <div style={{ width: '30%' }}>
                      {!editMode && (
                        <Select
                          interactable
                          disableClearable
                          placeholder={
                            selectedValues?.label ??
                            selectPolicyLens?.[0]?.label
                          }
                          options={selectPolicyLens}
                          setFieldValue={handlePolicyChange}
                          className={classes.selectStyle}
                          customOption={CustomPolicyOption as React.FC}
                        />
                      )}
                      <TextField
                        id="name"
                        required
                        value={policyName}
                        onChange={(e) => setPolicyName(e.target.value)}
                        disabled={isSaving}
                        isHidden={!editMode}
                      />
                    </div>
                    <Button
                      onClick={handleEditMode}
                      color="secondary"
                      disabled={isSaving}
                      text={editMode ? 'Cancel' : 'Edit'}
                    />
                  </div>
                </Grid>
                <Grid item md={8}>
                  <Grid container justify="flex-end">
                    {isAccountBounded ? (
                      <CheckBox
                        label="Default Policy"
                        checked={selectedValues?.defaultPolicy}
                        onChange={onSetDefaultChange}
                        formLabelProps={null}
                      />
                    ) : null}
                    {!!policy && !editMode && (
                      <Button
                        onClick={toggleModalDiffered(
                          'ZDevPolicyDeleteConfirm',
                          selectedValues,
                          {
                            title: 'Delete Policy',
                          }
                        )}
                        color="secondary"
                        text="Delete"
                        disabled={false}
                        icon={DeleteIcon}
                      />
                    )}

                    <AddClonePolicyButton
                      defaultName={`${selectedValues?.label}(cloned)`}
                      cloningPolicy
                      policyId={selectedValues?.value}
                    />

                    {!!policy && editMode && (
                      <Button
                        onClick={() => handleSavePolicy(policyName)}
                        color="primary"
                        text={isSaving ? 'Saving...' : 'Save'}
                        disabled={isSaving}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
};

export default withRouter(ZScanPolicyPickerBar);
