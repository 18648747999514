import { TFunction } from 'react-i18next';

export enum TableActions {
  DeleteSelected = 'deleteSelected',
  ExportSelected = 'exportSelected',
  ExportTable = 'exportTable',
  ImportTable = 'importTable',
  ImportTableAppend = 'importTableAppend',
  DownloadExample = 'downloadExample',
}

export interface ITableActionGroup {
  group: string;
  actions: { value: string; label: string }[];
}

export const tableActions = (t: TFunction<'translation', undefined>): ITableActionGroup[] => [
  {
    group: t('GLOBAL.SELECTED_ROWS'),
    actions: [
      {
        value: TableActions.DeleteSelected,
        label: t('GLOBAL.DELETE_ROWS'),
      },
      {
        value: TableActions.ExportSelected,
        label: t('GLOBAL.EXPORT_TO_CSV'),
      },
    ],
  },
  {
    group: t('GLOBAL.ENTIRE_TABLE'),
    actions: [
      {
        value: TableActions.ExportTable,
        label: t('GLOBAL.EXPORT_TO_CSV'),
      },
      {
        value: TableActions.ImportTable,
        label: t('GLOBAL.IMPORT_CSV_REPLACE_ALL'),
      },
      {
        value: TableActions.ImportTableAppend,
        label: t('GLOBAL.IMPORT_CSV_APPEND_TABLE'),
      },
      {
        value: TableActions.DownloadExample,
        label: t('GLOBAL.DOWNLOAD_EXAMPLE_FILE'),
      },
    ],
  },
];
