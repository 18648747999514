import MUICircularProgress from '@material-ui/core/CircularProgress';
import MUIGrid from '@material-ui/core/Grid';
import {
  fetchActivationLink,
  regenerateActivationLink,
} from 'api/AcceptorService';
import { AxiosResponse } from 'axios';
import Button from 'components/UI/Button';
import CopyField from 'components/UI/input/CopyField';
import TextField from 'components/UI/input/TextField';
// @ts-ignore
import QRCode from 'qrcode.react'; // TODO: Fix this import. QRCode is deprecated, but I'm not sure if we want to upgrade yet
import React, { useCallback, useEffect, useState } from 'react';
import { openSnackBar, openSnackBarDiffered } from 'utils/storeUtils';
import { IActivationResponse } from './models';
import useStyles from './useStyles';
import { TFunction } from 'react-i18next';

interface IActivationLinkProps {
  emmConnectionId: string;
  isShowing: boolean;
  t: TFunction<'translation', undefined>;
}

const ActivationLink: React.FC<IActivationLinkProps> = ({
  emmConnectionId,
  isShowing,
  t,
}) => {
  const [deviceId, setDeviceId] = useState('');
  const [activationLink, setActivationLink] = useState('');
  const [stoken, setShortToken] = useState<string | null>(null);
  const [tokenExpiry, setTokenExpiry] = useState('');
  const [regeneratingLink, setRegeneratingLink] = useState(false);
  const [hidden, setHidden] = useState(true);

  const classes = useStyles();

  const setLink = useCallback(() => {
    if (!stoken) {
      fetchActivationLink({ emmConnectionId })
        .then((response: AxiosResponse<IActivationResponse>) => {
          setHidden(false);
          setShortToken(response?.data?.id ?? stoken);

          const tokenExpiry = new Date(response?.data?.expiresAt ?? '');
          let month: string | number = tokenExpiry.getMonth() + 1; // since getMonth() returns  (0–11)
          month =
            month < 10 ? `0${tokenExpiry.getMonth()}` : tokenExpiry.getMonth();
          const date =
            tokenExpiry.getDate() < 10
              ? `0${tokenExpiry.getDate()}`
              : tokenExpiry.getDate();
          setTokenExpiry(`${month}/${date}/${tokenExpiry.getUTCFullYear()}`);
        })
        .catch(() => {
          setHidden(true);
        });
    }
  }, [emmConnectionId, stoken]);

  useEffect(() => {
    setLink();
  }, [setLink]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.name !== 'device-id') {
      return;
    }
    const mdmDeviceId = e.target.value;
    const activationLink = `${window.location.protocol}//${window.location.host}/api/acceptor/v1/activation?stoken=${stoken}&mdmId=${mdmDeviceId}&redirect_uri=mtd`;

    setDeviceId(mdmDeviceId);
    setActivationLink(activationLink);
  };

  const regenerateLink = () => {
    setRegeneratingLink(true);
    regenerateActivationLink({ emmConnectionId })
      .then((response: AxiosResponse<IActivationResponse>) => {
        setRegeneratingLink(false);
        const tokenExpiry = new Date(response?.data?.expiresAt ?? '');
        const month =
          tokenExpiry.getMonth() < 10
            ? `0${tokenExpiry.getMonth()}`
            : tokenExpiry.getMonth();
        const date =
          tokenExpiry.getDate() < 10
            ? `0${tokenExpiry.getDate()}`
            : tokenExpiry.getDate();
        setTokenExpiry(`${month}/${date}/${tokenExpiry.getUTCFullYear()}`);
        openSnackBar('Link regenerated');
      })
      .catch(() => {
        setRegeneratingLink(false);
        openSnackBar('Some error occurred in regenerating link');
      });
  };
  if (hidden || isShowing === false) {
    return null;
  }

  return (
    <div>
      <div className={classes.title}>
        {t('MTD.ACTIVATIONS.ACTIVATION_LINK')}
      </div>
      <div className={classes.message}>
        {t('GLOBAL.SCAN_AND_SHARE_QR_CODE_DESCRIPTION')}
        <TextField
          name="device-id"
          label={t('MTD.INTEGRATIONS.ENTER_DEVICE_ID')}
          onChange={handleChange}
        />
        {deviceId && (
          <MUIGrid container className={classes.marginTop10}>
            <MUIGrid item>
              <QRCode value={activationLink || ''} includeMargin />
            </MUIGrid>

            <MUIGrid item className={classes.copyField}>
              <CopyField
                name=""
                onCopy={openSnackBarDiffered(
                  t('GLOBAL.MESSAGE_ACTIVATION_LINK_COPIED')
                )}
                value={activationLink}
              />
              <div>{t('GLOBAL.LINK_EXPIRES_ON', { tokenExpiry })}</div>
              <Button
                color="secondary"
                disabled={regeneratingLink}
                icon={() => {
                  if (regeneratingLink) {
                    return <MUICircularProgress size={15} />;
                  }
                  return <></>;
                }}
                onClick={() => regenerateLink()}
                text={
                  regeneratingLink
                    ? t('GLOBAL.REGENERATING')
                    : t('GLOBAL.REGENERATE_LINK')
                }
              />
            </MUIGrid>
          </MUIGrid>
        )}
      </div>
    </div>
  );
};

export default ActivationLink;
