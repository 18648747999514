import { ServiceHandlerFactory } from 'utils/serviceUtils';

const baseUrl = 'api/useractivitylog/v1';

export const fetchAuditLogParamsMapping = [
  {
    path: 'page',
  },
  {
    path: 'size',
  },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      if (orderBy && order) {
        return `${orderBy},${order}`;
      }

      return null;
    },
  },
];
export const auditLogParamsMapping = [
  {
    path: 'after',
    transform: ({ after }) => (after ? after.toISOString() : null),
  },
  {
    path: 'before',
    transform: ({ before }) =>
      before ? before.toISOString() : new Date().toISOString(),
  },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      if (orderBy && order) {
        return `${orderBy},${order}`;
      }

      return null;
    },
  },
];

export const downloadAuditLogCsv = ServiceHandlerFactory({
  axios: {
    url: `${baseUrl}/csv`,
    method: 'post',
  },
  isCancelable: true,
  paramSchema: auditLogParamsMapping,
});

export const fetchAuditLog = ServiceHandlerFactory({
  axios: {
    url: `${baseUrl}/activity`,
  },
  paramSchema: fetchAuditLogParamsMapping,
});
