import { default as MUIDialogActions } from '@material-ui/core/DialogActions';
import { default as MUIDialogContent } from '@material-ui/core/DialogContent';
import { default as MUIDialogTitle } from '@material-ui/core/DialogTitle';
import { toggleModalDiffered } from 'utils/storeUtils';
import Button from 'components/UI/Button';
import React from 'react';

interface ConfirmDialogProps {
  data: { title: string; handleConfirm: () => void };
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  data: { title, handleConfirm },
}) => {
  return (
    <MUIDialogContent>
      <MUIDialogTitle>{title}</MUIDialogTitle>
      <MUIDialogActions>
        <Button
          onClick={toggleModalDiffered('ConfirmDialog', false)}
          color="secondary"
          text="Cancel"
        />
        <Button
          onClick={handleConfirm}
          type="submit"
          color="primary"
          text="Confirm"
        />
      </MUIDialogActions>
    </MUIDialogContent>
  );
};

export default ConfirmDialog;
