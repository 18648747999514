import memoizeTableQuery from 'components/UI/Table/utils/memoizeTableQuery';
import { IQueryParams, IZScanFindings } from './models';
import { fetchTableData } from './utils';

const memoizeQuery = (
  activeIndex: number,
  preSelectFirstRowData: boolean,
  setPreSelectFirstRowData: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectFinding: React.Dispatch<
    React.SetStateAction<IZScanFindings | undefined>
  >,
  setTotalDataCount: React.Dispatch<React.SetStateAction<number>>,
  query: IQueryParams,
  assessmentId: string
) => {
  return memoizeTableQuery(async () => {
    const data: any = await fetchTableData(
      activeIndex,
      preSelectFirstRowData,
      setPreSelectFirstRowData,
      setSelectFinding,
      setTotalDataCount,
      query,
      assessmentId
    );

    return {
      data: data?.data || [],
      count: data?.data?.length ?? 0,
    };
  });
};
export default memoizeQuery;
