import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

// material-ui
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// actions
import { editSelectedRole, postNewRole } from 'api/apis';
// components
import ZButton from 'UI/Buttons/ZButton';
// utils
import ScopeMapping, {
  common,
  zDefend,
  zScan,
  MTD,
  zShield,
} from 'mappings/scopeMapping';
import { FormikTextField } from 'components/inputs/FormikTextField';
import {
  csvInjectionRegex,
  csvInjectionErrorMessage,
} from 'utils/componentUtils';
import { publishEvent } from 'utils/eventUtils';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import GenericErrorBox from 'components/inputs/GenericErrorBox';
import {
  toggleModalDiffered,
  toggleModalDirect,
  openSnackBar,
} from '../../utils/storeUtils';
// mappings
import RadioControl from '../inputs/RadioControl';
import ScopeOptions from '../inputs/ScopeOptions';
import {
  cleanUpScopesForSubmit,
  formatDefaultScopeOptions,
} from '../../utils/scopeUtils';

const accountTeamOptions = [
  { value: 'Account', label: 'Account' },
  { value: 'Team', label: 'Team' },
];

const initialState = {
  roleName: '',
  roleDescription: '',
  bounds: 'Team',
  clone: false,
  systemRole: false,
  viewOnlyScope: false,
};

class RolesCreateEdit extends Component {
  constructor(props) {
    super(props);

    this.isViewOnly =
      _.get(this.props.data, 'viewOnlyScope') ||
      _.get(this.props.data, 'rowData.systemRole');

    this.sortedDefaultScopes = Object.keys(props.availableScopes)
      .map(scope => _.get(ScopeMapping, scope, 'Unknown Scope'))
      .sort((a, b) => a.localeCompare(b));

    this.defaultScopeOptions = formatDefaultScopeOptions(props.availableScopes);
    let scopeOptions = this.defaultScopeOptions;

    if (_.get(props, 'data.rowData.scopes')) {
      scopeOptions = props.data.rowData.scopes.reduce(
        (acc, string) => {
          const [key, prop] = string.split(':');
          acc[key][prop] = true;

          return acc;
        },
        { ...this.defaultScopeOptions }
      );
    }

    this.state = {
      initialState,
      disableChanges: this.isViewOnly,
      scopeOptions,
      bounds: 'Team',
      viewOnlyScope: _.get(this.props.data, 'viewOnlyScope', false),
      systemRole: _.get(this.props.data, 'rowData.systemRole', false),
    };
    this.handleToggleSwitch = this.handleToggleSwitch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { props } = this;
    if (props.data.rowData) {
      this.setState({
        roleName: props.data.rowData.name,
        roleDescription: props.data.rowData.description,
        clone: false,
        bounds:
          this.props.data.rowData.bounds === 'Account' ? 'Account' : 'Team',
      });
    }
  }

  render() {
    const { props, state } = this;

    return (
      <DialogContent>
        <Formik
          initialValues={{
            roleDescription: _.get(props, 'data.rowData.description'),
            roleName: _.get(props, 'data.rowData.name'),
          }}
          validationSchema={RoleSchema}
          validateOnBlur
          enableReinitialize
          onSubmit={this.handleSubmit}
        >
          {({ values, errors }) => {
            return (
              <Form>
                <Field
                  name="roleName"
                  label="Role Name"
                  component={FormikTextField}
                  disabled={state.disableChanges}
                  autoComplete="off"
                  classList={{ errorClass: props.classes.errorClass }}
                />
                <Field
                  name="roleDescription"
                  label="Role Description"
                  component={FormikTextField}
                  disabled={state.disableChanges}
                  autoComplete="off"
                  classList={{ errorClass: props.classes.errorClass }}
                />
                <div className={props.classes.scopeOption}>
                  <RadioControl
                    name="bounds"
                    label="Role Scope"
                    disabled={state.disableChanges}
                    value={state.bounds}
                    options={accountTeamOptions}
                    handleChange={this.handleChange('bounds')}
                  />
                </div>
                <div className="flex--spaced">
                  <div className={props.classes.rowContainer}>
                    {!state.disableChanges && <h4>Configure Permissions</h4>}
                    {state.systemRole && (
                      <h6>
                        *These permissions cannot be modified because this is a
                        system role. You can clone it and modify as needed.
                      </h6>
                    )}
                  </div>
                </div>

                <ScopeOptions
                  title="Common"
                  availableScopes={state.scopeOptions}
                  disable={state.disableChanges}
                  handleToggleSwitch={this.handleToggleSwitch}
                  sortedDefaultScopes={this.sortedDefaultScopes}
                  scopeMapping={common}
                  show={props.availableModules.hasOwnProperty.call(
                    props.availableModules,
                    'COMMON'
                  )}
                />
                <ScopeOptions
                  title="zDefend"
                  availableScopes={state.scopeOptions}
                  disable={state.disableChanges}
                  handleToggleSwitch={this.handleToggleSwitch}
                  sortedDefaultScopes={this.sortedDefaultScopes}
                  // zdefend
                  scopeMapping={zDefend}
                  show={props.availableModules.hasOwnProperty.call(
                    props.availableModules,
                    'ZIAP'
                  )}
                />
                <ScopeOptions
                  title="MTD"
                  availableScopes={state.scopeOptions}
                  disable={state.disableChanges}
                  handleToggleSwitch={this.handleToggleSwitch}
                  sortedDefaultScopes={this.sortedDefaultScopes}
                  // MTD
                  scopeMapping={MTD}
                  show={props.availableModules.hasOwnProperty.call(
                    props.availableModules,
                    'ZIPS'
                  )}
                />

                <ScopeOptions
                  title="zScan"
                  availableScopes={state.scopeOptions}
                  disable={state.disableChanges}
                  handleToggleSwitch={this.handleToggleSwitch}
                  sortedDefaultScopes={this.sortedDefaultScopes}
                  // zscan
                  scopeMapping={zScan}
                  show={props.availableModules.hasOwnProperty.call(
                    props.availableModules,
                    'ZDEV'
                  )}
                />

                <ScopeOptions
                  title="zShield"
                  availableScopes={state.scopeOptions}
                  disable={state.disableChanges}
                  handleToggleSwitch={this.handleToggleSwitch}
                  sortedDefaultScopes={this.sortedDefaultScopes}
                  // zshield
                  scopeMapping={zShield}
                  show={props.availableModules.hasOwnProperty.call(
                    props.availableModules,
                    'ZSHIELD'
                  )}
                />

                <GenericErrorBox
                  errorMessage={
                    !_.isEmpty(errors) && 'One or more field(s) are required.'
                  }
                />

                <DialogActions>
                  <ZButton
                    styleName="submit"
                    action={toggleModalDiffered('RolesCreateEdit', false)}
                    color="secondary"
                    buttonText="Cancel"
                  />
                  <ProtectedComponent allow={{ roles: 'manage' }}>
                    <ZButton
                      isShowing={
                        state.clone || !_.get(props.data, 'rowData.systemRole')
                      }
                      styleName="modalSave"
                      buttonType="Submit"
                      buttonText="Save"
                      color="primary"
                    />

                    {!state.clone && _.get(props.data, 'rowData') && (
                      <ZButton
                        styleName="modalSave"
                        buttonText="Clone"
                        color="primary"
                        action={this.handleClone}
                      />
                    )}
                  </ProtectedComponent>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    );
  }

  handleSubmit(values) {
    const { props, state } = this;
    const payload = {
      name: values.roleName,
      description: values.roleDescription,
      systemRole: false,
      scopes: cleanUpScopesForSubmit(state.scopeOptions),
      scopeBounds: state.bounds === 'Team' ? 'TEAM_BOUNDED' : 'ACCOUNT_BOUNDED',
      teamBounded: state.bounds === 'Team',
      accountBounded: state.bounds === 'Account',
    };

    if (props.data.rowData && !state.clone) {
      payload.id = props.data.rowData.id;

      editSelectedRole(payload)
        .then(() => {
          toggleModalDirect('RolesCreateEdit', false);
          openSnackBar('Role was updated');
          publishEvent('table:force-fetch-roles');
        })
        .catch(error => {
          toggleModalDirect('RolesCreateEdit', false);
          openSnackBar(`Failed to edit role: ${error.response.data}`);
        });
    } else {
      postNewRole(payload)
        .then(() => {
          toggleModalDirect('RolesCreateEdit', false);
          openSnackBar('Role was created');
          publishEvent('table:force-fetch-roles');
        })
        .catch(error => {
          toggleModalDirect('RolesCreateEdit', false);
          openSnackBar(`Failed to create role: ${error.response.data}`);
        });
    }

    this.setState({
      clone: false,
    });
  }

  handleChange(name) {
    return event => {
      this.setState({ [name]: event.target.value });
    };
  }

  handleToggleSwitch(scope, permission) {
    return () => {
      const { state } = this;
      this.setState({
        scopeOptions: {
          ...this.state.scopeOptions,
          [scope]: {
            ...this.state.scopeOptions[scope],
            [permission]: !state.scopeOptions[scope][permission],
          },
        },
      });
    };
  }

  handleClone = () => {
    this.setState({
      clone: true,
      roleName: `${this.state.roleName} (clone)`,
      cardHeader: 'Clone Role',
      disableChanges: false,
    });
  };
}

export const createModalConfig = {
  title: 'Create New Roles',
  scrollable: true,
  fullWidth: true,
};

export const editModalConfig = {
  title: 'Edit Role',
  scrollable: true,
  fullWidth: true,
};

const styles = ({ config, palette, shape }) => {
  return {
    scopeLabel: {
      fontSize: config.textSizes.petite,
      marginTop: 15,
    },
    scopeOption: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    permissionLabel: {
      paddingTop: 15,
      paddingLeft: 15,
    },
    rowContainer: {
      width: '100%',
    },
    checkBoxContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    box: {
      marginLeft: '15px',
    },
    errorClass: {
      minWidth: 150,
      padding: '7px 10px',
      marginTop: 19,
      marginLeft: 10,
      position: 'absolute',
      zIndex: 1,
      transform: 'translateY(-50%)',
      background: palette.error.light,
      color: palette.error.contrastText,
      borderRadius: shape.borderRadius,
    },
  };
};

const RoleSchema = Yup.object().shape({
  roleName: Yup.string()
    .min(2, 'Must be at least 2 characters')
    .required('Required')
    .matches(csvInjectionRegex, csvInjectionErrorMessage),
  roleDescription: Yup.string()
    // .min(2)
    .required('Required')
    .matches(csvInjectionRegex, csvInjectionErrorMessage),
});

const mapStateToProps = state => {
  return {
    availableScopes: _.get(state, 'auth.user.scopes', []),
    availableModules: _.get(state, 'auth.user.modules', {}),
  };
};

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    null
  )(RolesCreateEdit)
);
