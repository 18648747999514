import { IGraphOptions } from 'components/UI/graphs/common/models';
import { TFunction } from 'react-i18next';
import { graphTickFormatter } from 'utils/RangeUtils';
import { IDeviceAggregate, IQueryParams } from '../common/models';
import { IDataset } from './models';

export const DEVICE_TYPES = [
  { key: 'android', label: 'Android Devices' },
  { key: 'ios', label: 'iOS Devices' },
  { key: 'chromeos', label: 'Chrome OS Devices' },
];

export const GRAPH_OPTIONS = (
  t: TFunction<'translation', undefined>
): IGraphOptions => ({
  tooltips: {
    callbacks: {
      title: function formatTitle(tooltipItems, { datasets }) {
        if (!tooltipItems.length) {
          return '';
        }
        const { datasetIndex, index, xLabel } = tooltipItems[0];
        const dataset = (datasets as IDataset[])?.[datasetIndex];

        return `${xLabel}: ${dataset?.deviceCounts?.[index] ?? ''} ${t(
          'GLOBAL.DEVICES'
        )}`;
      },
    },
  },
});

export const prepareGraphData = (
  deviceAggregates: IDeviceAggregate[],
  query: IQueryParams
) => {
  const deviceCounts = deviceAggregates.map(({ doc_count }) => doc_count ?? 0);

  const parameters = deviceAggregates.map(({ key }) => key);

  const datasets: IDataset[] = DEVICE_TYPES.map(({ key, label }) => ({
    data: deviceAggregates.map((item) =>
      !!item?.['sterms#os name'].buckets?.[0]
        ? item['sterms#os name'].buckets.find((bucket) => bucket?.key === key)
            ?.doc_count ?? 0
        : 0
    ),
    deviceCounts,
    key,
    label,
    parameters,
  }));

  return {
    labels: deviceAggregates.map(({ key }) =>
      graphTickFormatter(query?.duration, query?.from, query?.to)(key)
    ),
    datasets,
  };
};

export const calcDaysToAdd = (duration: number) => {
  if (duration <= 4 || duration >= 9) {
    return 0;
  }

  return 2 * duration - 9; // y = mx + b, rough estimate of days to add, possibly change
};
