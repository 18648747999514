import { atom } from 'recoil';

export interface IPolicyFinding {
  id?: string;
  findingId?: string;
  accountId?: string;
  enabled?: boolean;
  severity: string;
  policyId?: string;
  [key: string]: unknown;
}

export const findingUpdatesAtom = atom<IPolicyFinding[] | null>({
  key: 'findingUpdates',
  default: null,
});
