import _ from 'lodash';
import React from 'react';

// ui
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ZButton from 'UI/Buttons/ZButton';

// services
import {
  toggleModalDirect,
  openSnackBar,
  toggleModalDiffered,
} from 'utils/storeUtils';
import { publishEvent } from 'utils/eventUtils';
import { deleteManagedAccount } from 'api/PartnerService';

export const PartnerAccountsDeleteConfirm = ({ data }) => {
  const accountId = _.get(data, 'rowData.id');
  function handleDelete() {
    deleteManagedAccount({ accountId })
      .then(() => {
        openSnackBar(`Successfully deleted ${_.get(data, 'rowData.name')}`);
        publishEvent('table:force-fetch-managedAccounts');
        toggleModalDirect('PartnerUserDeleteConfirm', false);
      })
      .catch(error => {
        openSnackBar(
          `Failed to delete ${_.get(data, 'rowData.name')}. ${_.get(
            error,
            'response.data',
            ''
          )}`
        );
      });
  }
  return (
    <DialogContent>
      <p>Are you sure you want to delete {_.get(data, 'rowData.name')}?</p>
      <DialogActions>
        <ZButton
          styleName="modalCancel"
          action={toggleModalDiffered('PartnerAccountsDeleteConfirm', false)}
          color="secondary"
          buttonText="Cancel"
        />
        <ZButton
          action={handleDelete}
          buttonType="submit"
          color="primary"
          styleName="modalSave"
          buttonText="Delete"
        />
      </DialogActions>
    </DialogContent>
  );
};

PartnerAccountsDeleteConfirm.defaultProps = {
  data: {},
};

export default PartnerAccountsDeleteConfirm;
