import { fetchGroupPolicyMetadata } from 'api/MTDPolicy';
import { TClassList } from 'components/UI/Table/models';
import { ITabProps } from 'components/UI/Tabs';
import OSLabelIcon from 'components/UI/icons/OSLabelIcon';
import {
  IDetail,
  IPolicyMetaData,
} from 'components/main/common/DetailTable/models';
import { VECTOR_MAPPING } from 'components/main/threats/ThreatsAppBar/utils';
import moment from 'moment';
import ThreatCategoryMapping from 'utility/ThreatCategoryMapping';
import { IRowData } from '../Threats/models';
import OldARPTables from '../forensics/ARPTables';
import AdditionalForensic from '../forensics/AdditionalForensic';
import OldCertificates from '../forensics/Certficates';
import OldForensicsInfo from '../forensics/ForensicsInfo';
import OldGeneralInfo from '../forensics/GeneralInfo';
import OldLocationSource from '../forensics/LocationSource';
import OldMalware from '../forensics/Malware';
import OldNearbyNetworks from '../forensics/NearbyNetworks';
import OldNetworkStatus from '../forensics/NetworkStatus';
import OldPhishing from '../forensics/Phishing';
import OldProcessList from '../forensics/ProcessList';
import PublicForensic from '../forensics/PublicForensic';
import OldRoutingTables from '../forensics/RoutingTables';
import OldSideLoadedAppInfo from '../forensics/SideLoadedAppInfo';
import { SEVERITIES, THREAT_STATUSES } from '../threatMappings';
import { ILabelMapping, IResolvedData } from './models';
import { TFunction } from 'react-i18next';

// Remove this table model once the tabs/tables are ported to TS
export interface IThreatDetailTabProps {
  threat: IRowData;
}

// Remove these temporary definitions once every tab is ported to TS
const ARPTables = OldARPTables as unknown as React.FC<IThreatDetailTabProps>;
const Certficates =
  OldCertificates as unknown as React.FC<IThreatDetailTabProps>;
const ForensicsInfo =
  OldForensicsInfo as unknown as React.FC<IThreatDetailTabProps>;
const GeneralInfo =
  OldGeneralInfo as unknown as React.FC<IThreatDetailTabProps>;
const LocationSource =
  OldLocationSource as unknown as React.FC<IThreatDetailTabProps>;
const Malware = OldMalware as unknown as React.FC<IThreatDetailTabProps>;
const NearbyNetworks =
  OldNearbyNetworks as unknown as React.FC<IThreatDetailTabProps>;
const NetworkStatus =
  OldNetworkStatus as unknown as React.FC<IThreatDetailTabProps>;
const Phishing = OldPhishing as unknown as React.FC<IThreatDetailTabProps>;
const ProcessList =
  OldProcessList as unknown as React.FC<IThreatDetailTabProps>;
const RoutingTables =
  OldRoutingTables as unknown as React.FC<IThreatDetailTabProps>;
const SideLoadedAppInfo =
  OldSideLoadedAppInfo as unknown as React.FC<IThreatDetailTabProps>;

export const fetchPolicyMetadata = async (groupId: string) => {
  let result: IPolicyMetaData | undefined;
  try {
    const response = await fetchGroupPolicyMetadata({ groupId: groupId });
    result = response?.data ?? [];
  } catch (e) {
    console.log(e);
  }
  return result;
};

// Note that if the content field evaluates to undefined, the item will be removed from the table
export const getAllDeviceDetails = (
  rowData: IRowData,
  classes: TClassList,
  t: TFunction<'translation', undefined>
): IDetail[] => [
  {
    id: 'model',
    label: t('GLOBAL.MODEL'),
    content: rowData?.device?.model,
  },
  {
    id: 'device.zdeviceId',
    label: t('GLOBAL.DEVICE_ID'),
    content: rowData?.device?.zdeviceId,
    filterable: true,
  },
  {
    id: 'os',
    label: t('GLOBAL.OS'),
    content: <OSLabelIcon os={rowData?.os} classList={classes} />,
    filterValue: rowData?.os,
    filterable: true,
  },
  {
    id: 'os.version',
    label: t('GLOBAL.OS_VERSION'),
    content: rowData?.device?.os?.version,
    filterable: true,
  },
  {
    id: 'os.patchDate',
    label: t('GLOBAL.PATCH_DATE'),
    content: rowData?.device?.os?.patchDate
      ? moment(rowData?.device?.os?.patchDate).format('l')
      : undefined,
  },
  {
    id: 'device.mdmDeviceId',
    label: t('MTD.THREATS.MDM_ID'),
    content: rowData?.device?.mdmDeviceId,
    filterable: true,
  },
  {
    id: 'device.mamDeviceId',
    label: t('MTD.THREATS.MAM_ID'),
    content: rowData?.device?.mamDeviceId,
    filterable: true,
  },
  {
    id: 'teamName',
    label: t('GLOBAL.TEAM_NAME'),
    content: rowData?.teamName,
  },
  {
    id: 'groupName',
    label: t('GLOBAL.GROUP_NAME'),
    content: rowData?.groupName,
  },
  {
    id: 'groupId',
    label: t('GLOBAL.GROUP_ID'),
    content: rowData?.groupId,
    filterable: true,
  },
];

/**
 * Unused Below -- Leaving commented just incase.
 *
 * export const PERMISSION_LABELS: ILabelMapping = {
 *   CAMERA: 'Allow Camera Access',
 *   DEVICE_LOCATION_STATE: 'Device Location',
 *   LOCATION: 'Allow Location Access',
 *   NOTIFICATION: 'Enable Notifications',
 *   PRECICE_LOCATION: 'Precise Location',
 *   VPN: 'Allow VPN Configuration',
 *   VPN_STATE: 'VPN State',
 * };
 */

/**
 * Unused Below -- Leaving commented just incase.
 *
 * export const STATUS_LABELS: ILabelMapping = {
 *   ACTIVE: 'Active',
 *   ALLOW: 'Allow',
 *   ALLOW_WHILE_USING_APP: 'Allow',
 *   DISABLED: 'Disabled',
 *   ENABLED: 'Enabled',
 *   INACTIVE: 'Inactive',
 *   UNKNOWN: 'Unknown',
 * };
 */

export const getLocalizedPolicyLabels = (
  t: TFunction<'translation', undefined>
): ILabelMapping => ({
  'Threat Android': t('GLOBAL.THREAT_POLICY'),
  'Threat iOS': t('GLOBAL.THREAT_POLICY'),
  Privacy: t('GLOBAL.PRIVACY_POLICY'),
  'App Settings': t('GLOBAL.APP_SETTINGS'),
  Phishing: t('GLOBAL.PHISHING_POLICY'),
  'App Policy Android v2': t('MTD.INSIGHTS.APP_POLICY'),
  'App Policy iOS v2': t('MTD.INSIGHTS.APP_POLICY'),
});

export const getAllAppDetails = (
  rowData: IRowData,
  policyMetadata: IPolicyMetaData[],
  t: TFunction<'translation', undefined>
): IDetail[] => [
  {
    id: 'zappInstance.name',
    label: t('MTD.THREATS.MTD_APP_NAME'),
    content: rowData?.zappInstance?.name,
    showInAppFocusSection: true,
    filterable: true,
  },
  {
    id: 'zappInstance.version',
    label: t('GLOBAL.APP_VERSION'),
    content: rowData?.zappInstance?.version,
    showInAppFocusSection: true,
    filterable: true,
  },
  {
    id: 'zappInstance.buildNumber',
    label: t('GLOBAL.APP_BUILD'),
    content: rowData?.zappInstance?.buildNumber,
    showInAppFocusSection: true,
    filterable: true,
  },
  {
    id: 'zappInstance.bundleId',
    label: t('GLOBAL.BUNDLE_ID'),
    content: rowData?.zappInstance?.bundleId,
    showInAppFocusSection: true,
    filterable: true,
  },
  {
    id: 'zappInstance.lastSeen',
    label: t('GLOBAL.APP_LAST_SEEN'),
    content: moment(rowData?.zappInstance?.lastSeen).format('LLL'),
    showInAppFocusSection: true,
  },
  {
    id: 'zappInstance.groupId',
    label: t('GLOBAL.GROUP_ID'),
    content: rowData?.zappInstance?.groupId,
    filterable: true,
  },
  {
    id: 'zappInstance.externalTrackingId1',
    label: t('GLOBAL.TRACKING_ID_1'),
    content: rowData?.zappInstance?.externalTrackingId1,
    filterable: true,
  },
  {
    id: 'zappInstance.externalTrackingId2',
    label: t('GLOBAL.TRACKING_ID_2'),
    content: rowData?.zappInstance?.externalTrackingId2,
    filterable: true,
  },
  {
    id: 'zappInstance.zversion',
    label: t('GLOBAL.Z_DEFEND_VERSION'),
    content: rowData?.zappInstance?.zversion,
    filterable: true,
  },
  {
    id: 'zappInstance.zbuildNumber',
    label: t('GLOBAL.Z_DEFEND_BUILD'),
    content: rowData?.zappInstance?.zbuildNumber,
    filterable: true,
  },
  ...((rowData?.policiesInfo
    ?.map((policyInfo, index) => {
      const metaData = policyMetadata.find(
        ({ jsonHash, type }) =>
          jsonHash === policyInfo.hash && type === policyInfo.type
      );

      const POLICY_LABELS = getLocalizedPolicyLabels(t);

      return {
        id: POLICY_LABELS?.[policyInfo.type] ?? 'policy-' + index,
        label: POLICY_LABELS?.[policyInfo.type] ?? policyInfo.type,
        content:
          policyInfo?.hash === metaData?.jsonHash
            ? t('MTD.THREATS.UPDATED_POLICY', {
                policy: policyInfo?.deployedAt
                  ? moment(policyInfo.deployedAt).format('LLL')
                  : '',
              })
            : t('MTD.THREATS.DEPLOYED_POLICY', {
                policy: policyInfo?.downloadedAt
                  ? moment(policyInfo.downloadedAt).format('LLL')
                  : '',
              }),
      };
    })
    .filter((item) => item) as IDetail[]) ?? []),
];

export const getEventDetails = (
  classes: TClassList,
  t: TFunction<'translation', undefined>,
  rowData?: IRowData
): IDetail[] => [
  {
    id: 'severity',
    label: t('GLOBAL.SEVERITY'),
    content: t(
      SEVERITIES.find(({ id }) => id === rowData?.severity)?.name ?? ''
    ),
    filterable: true,
    filterValue: rowData?.severity,
  },
  {
    id: 'timestamp',
    label: t('GLOBAL.TIMESTAMP'),
    content: rowData?.timestamp
      ? moment(rowData?.timestamp).format('LLL')
      : undefined,
    filterable: true,
    filterValue: rowData?.timestamp,
  },
  {
    id: 'threatTypeId',
    label: t('MTD.THREATS.THREAT_TYPE'),
    content: rowData?.serverName ?? '',
    filterable: true,
    filterValue: rowData?.threatTypeId,
  },
  {
    id: 'vector',
    label: t('MTD.THREATS.THREAT_VECTOR'),
    content:
      VECTOR_MAPPING.find(({ vectorType }) => vectorType === rowData?.vector)
        ?.name ?? '',
    filterable: true,
    filterValue: rowData?.vector,
  },
  {
    id: 'categoryId',
    label: t('MTD.THREATS.THREAT_CATEGORY'),
    content: rowData?.categoryId
      ? ThreatCategoryMapping[rowData.categoryId]?.name ?? undefined
      : undefined,
    filterable: true,
    filterValue: rowData?.categoryId,
  },
  {
    id: 'network',
    label: t('GLOBAL.NETWORK'),
    content: '', // TODO: Fix this once you get network data in threat rowData
    // filterable: true,
  },
  {
    id: 'state',
    label: t('GLOBAL.STATUS'),
    content: t(
      THREAT_STATUSES.find(({ id }) => id === rowData?.state)?.name ?? ''
    ),
    filterable: true,
    filterValue: rowData?.state,
  },
  {
    id: 'simulated',
    label: t('MTD.THREATS.SIMULATION_STATE'),
    content: !!rowData?.simulated
      ? t('MTD.THREATS.SIMULATED_THREAT')
      : t('MTD.THREATS.REAL_THREAT'),
    filterable: true,
    filterValue: rowData?.simulated,
  },
  {
    id: 'mitigatedAt',
    label: t('MTD.THREATS.LAST_MITIGATED'),
    content: rowData?.mitigatedAt
      ? moment(rowData?.mitigatedAt).format('LLL')
      : 'N/A',
    filterable: !!rowData?.mitigatedAt,
    filterValue: rowData?.mitigatedAt,
  },
];

export const getAllDetails = (
  classes: TClassList,
  t: TFunction<'translation', undefined>,
  rowData?: IRowData,
  policyMetadata?: IPolicyMetaData[]
): IResolvedData => {
  if (!rowData) {
    return {
      allAppDetails: [],
      allDeviceDetails: [],
      eventDetails: [],
      topSectionDeviceDetails: [],
      topSectionFocusAppDetails: [],
      topSectionCombinedDetails: [],
    };
  }

  const allAppDetails = getAllAppDetails(rowData, policyMetadata ?? [], t);
  const allDeviceDetails = getAllDeviceDetails(rowData, classes, t);

  const appDetailsForCombined =
    allAppDetails.filter(
      ({ showInAppFocusSection }) => !showInAppFocusSection
    ) ?? [];

  const deviceDetailsForCombined =
    allDeviceDetails.filter(({ isPolicy }) => isPolicy) ?? [];

  return {
    allAppDetails,
    allDeviceDetails,
    eventDetails: getEventDetails(classes, t, rowData),
    topSectionDeviceDetails: allDeviceDetails.filter(
      ({ hideFromTopSection, isDeviceStatus, isVulnerability, isPolicy }) =>
        !hideFromTopSection && !isDeviceStatus && !isVulnerability && !isPolicy
    ),
    topSectionFocusAppDetails:
      allAppDetails.filter(
        ({ showInAppFocusSection }) => showInAppFocusSection
      ) ?? [],
    topSectionCombinedDetails: [
      ...appDetailsForCombined,
      ...deviceDetailsForCombined,
    ],
  };
};

export const getTabs = (
  classes: TClassList,
  t: TFunction<'translation', undefined>,
  rowData?: IRowData
): ITabProps[] => {
  if (rowData?.publicForensicJson?.data) {
    const forensics = JSON.parse(rowData.publicForensicJson.data);

    const tabs = Object.keys(forensics).sort(); // General or High Risk Permissions
    return tabs.map((tab) => ({
      title: tab,
      show: true,
      content: <PublicForensic configObj={forensics[tab]} />,
    }));
  }

  return (
    [
      {
        title: t('MTD.THREATS.ARP_TABLES'),
        show: Object.keys(rowData?.arpTablesInfo || {}).length > 0,
        content: !!rowData?.arpTablesInfo && <ARPTables threat={rowData} />,
      },
      {
        title: t('GLOBAL.CONTENT'),
        show: false,
        content: null,
      },
      {
        title: t('GLOBAL.CERTIFICATES'),
        show: !!rowData?.certificates,
        content: !!rowData?.certificates && <Certficates threat={rowData} />,
      },
      {
        title: t('MTD.THREATS.FORENSICS_INFO'),
        show: !!rowData?.forensicsInfo,
        content: !!rowData?.forensicsInfo && <ForensicsInfo threat={rowData} />,
      },
      {
        title: t('GLOBAL.GENERAL'),
        show: !!rowData?.generalInfo,
        content: !!rowData?.generalInfo && <GeneralInfo threat={rowData} />,
      },
      {
        title: t('GLOBAL.LOCATION'),
        show: !!rowData?.locationInfo,
        content: !!rowData?.locationInfo && <LocationSource threat={rowData} />,
      },
      {
        title: t('GLOBAL.MALWARE'),
        show: !!rowData?.malwareInfo,
        content: !!rowData?.malwareInfo && <Malware threat={rowData} />,
      },
      {
        title: t('MTD.THREATS.NEARBY_NETWORKS'),
        show: !!rowData?.nearByNetworks?.length,
        content: !!rowData?.nearByNetworks?.length && (
          <NearbyNetworks threat={rowData} />
        ),
      },
      {
        title: t('MTD.THREATS.NETWORK_STATUS'),
        show: !!rowData?.networkStatistics,
        content: !!rowData?.networkStatistics && (
          <NetworkStatus threat={rowData} />
        ),
      },
      {
        title: t('GLOBAL.POLICY'),
        show: false,
        content: undefined,
      },
      {
        title: t('GLOBAL.PHISHING'),
        show: Object.keys(rowData?.suspiciousUrlInfo || {}).length > 0,
        content: !!rowData?.suspiciousUrlInfo && <Phishing threat={rowData} />,
      },
      {
        title: t('MTD.THREATS.PROCESS_LIST'),
        show: !!rowData?.processList,
        content: !!rowData?.processList && <ProcessList threat={rowData} />,
      },
      {
        title: t('MTD.THREATS.ROUTING_TABLES'),
        show: !!rowData?.routingTables,
        content: !!rowData?.routingTables && <RoutingTables threat={rowData} />,
      },
      {
        title: t('MTD.THREATS.SIDELOADED_APP_INFO'),
        show: !!rowData?.sideLoadedAppInfo,
        content: !!rowData?.sideLoadedAppInfo && (
          <SideLoadedAppInfo threat={rowData} />
        ),
      },
      {
        title: t('GLOBAL.FORENSICS'),
        show: !!rowData?.additionalPublicForensics,
        content: !!rowData?.additionalPublicForensics && (
          <AdditionalForensic tableData={rowData.additionalPublicForensics} />
        ),
      },
    ].filter(({ show }) => show) ?? []
  );
};
