import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { createBulkUserActivation } from 'api/UserActivationsService';
import { FormikCheckbox } from 'components/inputs/FormikCheckbox';
import { FormikTextField } from 'components/inputs/FormikTextField';
import Spinner from 'components/main/common/Spinner';
import CustomPolicyOption from 'components/main/policies/common/CustomPolicyOption';
import Select from 'components/UI/input/Select';
import { Field, Form, Formik } from 'formik';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import ZButton from 'UI/Buttons/ZButton';
import { publishEvent } from 'utils/eventUtils';
import {
  openSnackBar,
  toggleModalDiffered,
  toggleModalDirect,
} from 'utils/storeUtils';
import { processCsvTableData } from './DeviceActivations.mapping';
import { useTranslation } from 'react-i18next';

const BulkTableConfirm = (props) => {
  const selectedGroup = {
    value: _.get(props, 'mtdGroups[0].value'),
    label: _.get(props, 'mtdGroups[0].label'),
  };

  const teamsHash = useSelector(({ teams }) => teams?.available ?? []);
  const availableTeams = Object.keys(teamsHash).map((teamId) => {
    return {
      label: teamsHash[teamId].name,
      value: teamId,
    };
  });
  const sidebarSelectedTeamId = useSelector(({ teams }) => {
    return teams?.selected ?? null;
  });
  const sidebarSelectedTeam =
    availableTeams.find(({ value }) => value === sidebarSelectedTeamId) ?? {};
  const [groups, setGroups] = useState(
    props.mtdGroups.filter(
      ({ team }) => team === null || team?.id === sidebarSelectedTeam.value
    ) ?? []
  );

  const tableHeaders = _.get(props, 'csvData[0]', []);
  const tableData = _.slice(props.csvData, 1, props.csvData.length);
  // count minus the tableheader of the csv file
  const totalRecords = tableData.length || 0;
  // show the first 8 rows
  const displayTableData = _.slice(tableData, 0, 10 || totalRecords);
  function handleSubmit(values, actions) {
    const payload = processCsvTableData(values, tableData);
    createBulkUserActivation({}, payload)
      .then(({ data }) => {
        toggleModalDirect('UserActivationsCreateEdit', false);
        openSnackBar(
          t('MTD.ACTIVATIONS.SUCCESSFULLY_CREATED_ACTIVATION', {
            size: data.length,
          })
        );
        publishEvent('table:force-fetch-userActivations');
      })
      .catch((error) => {
        console.log('Error in csv upload: ', error);
        // setting error on group to display table (import) error
        actions.setErrors({
          group: t('MTD.ACTIVATIONS.INVALID_CSV_FORMAT'),
        });
      });
  }

  const handleTeamChange = useCallback(
    (formTeam, setFieldValue) => {
      setGroups(
        props.mtdGroups.filter(
          ({ team }) => team === null || team?.id === formTeam?.value
        ) ?? []
      );
      setFieldValue('team', formTeam);
    },
    [setGroups, props?.mtdGroups]
  );

  const { classes } = props;
  if (props.spinner) {
    return (
      <DialogContent>
        <div className={classes.container}>
          <Card className="is-loading shimmer" style={{ maxHeight: 450 }}>
            <CardContent>
              <Spinner />
            </CardContent>
          </Card>
        </div>
        <DialogActions>
          <ZButton
            styleName="submit"
            action={toggleModalDiffered('ActivationsBulk', false)}
            color="secondary"
            buttonText="Cancel"
          />

          <ZButton
            buttonType="submit"
            color="primary"
            styleName="modalSave"
            buttonText="Create"
            proLabel
          />
        </DialogActions>
      </DialogContent>
    );
  }

  const { t, ready } = useTranslation();

  if (!ready) {
    return null;
  }

  return (
    <DialogContent>
      <div className={classes.container}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableRow}>
              {tableHeaders.map((tableHeader, index) => {
                return (
                  <TableCell
                    className={classes.tableHeaderCell}
                    key={tableHeader + index}
                  >
                    {tableHeader}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayTableData.map((row, index) => {
              return (
                <TableRow key={index} className={classes.tableRow}>
                  {row.map((rowData, index2) => {
                    return (
                      <TableCell
                        key={rowData + index2}
                        className={classes.tableCell}
                      >
                        {rowData}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
            <TableRow>
              <td colSpan={4} style={{ textAlign: 'center' }}>
                <MoreHoriz />
              </td>
            </TableRow>
            <TableRow>
              <td colSpan={4} style={{ textAlign: 'center' }}>
                {t('MTD.ACTIVATIONS.TOTAL_NUMBER_OF_RECORDS', { totalRecords })}
              </td>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <Formik
        initialValues={{
          activationLimit: 10,
          group: selectedGroup || [],
          team: sidebarSelectedTeam || null,
          sendEmailInvite: _.get(props, 'data.rowData.sendEmailInvite', false),
          sendSmsInvite: false,
        }}
        // validationSchema={ActivationGroupSchema}
        validateOnBlur
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting, values, setFieldValue }) => {
          return (
            <Form>
              <Field
                buttonPlaceholder="Select Team"
                classlist={{ errorClass: props.classes.errorClass }}
                component={Select}
                disableClearable
                isMulti={false}
                label={t('GLOBAL.TEAM')}
                name="team"
                setFieldValue={(name, optionSelected) =>
                  handleTeamChange(optionSelected, setFieldValue)
                }
                options={availableTeams}
              />

              <Field
                buttonPlaceholder="Select Group"
                classlist={{ errorClass: props.classes.errorClass }}
                component={Select}
                customOption={CustomPolicyOption}
                isMulti={false}
                label={t('GLOBAL.GROUP')}
                name="group"
                setFieldValue={(name, optionSelected) => {
                  setFieldValue('group', optionSelected);
                }}
                options={groups}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Field
                  name="activationLimit"
                  label={t('MTD.ACTIVATIONS.ACTIVATION_LIMIT')}
                  component={FormikTextField}
                  autoComplete="offl"
                  type="number"
                  inputProps={{
                    min: 0,
                  }}
                  style={{ width: 100 }}
                />
                <div className="flex--start">
                  <span style={{ paddingRight: 10 }}>
                    {t('MTD.ACTIVATIONS.SEND_INVITES_THROUGH')}
                  </span>
                  <Field
                    name="sendEmailInvite"
                    label={t('GLOBAL.EMAIL')}
                    component={FormikCheckbox}
                    type="checkbox"
                    checked={values.sendEmailInvite}
                  />
                </div>
              </div>
              <DialogActions>
                <ZButton
                  styleName="submit"
                  action={toggleModalDiffered('ActivationsBulk', false)}
                  color="secondary"
                  buttonText="Cancel"
                />

                <ZButton
                  buttonType="submit"
                  color="primary"
                  isDisabled={!values?.team?.value}
                  styleName="modalSave"
                  buttonText="Invite"
                  proLabel
                />
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </DialogContent>
  );
};

const styles = ({ palette, config, shape }) => {
  return {
    container: {
      maxHeight: 450,
      overflow: 'auto',
    },
    wrapper: {
      background: palette.background.paper,
      outline: 'none',
      maxWidth: '50vw',
      maxHeight: 400,
      overflowY: 'auto',
    },
    tableCell: {
      borderBottomColor: 'transparent',
    },
    tableHeaderCell: {
      color: palette.text.secondary,
      fontWeight: 'bold',
      borderBottomColor: 'transparent',
    },
    tableRow: {
      height: config.textSizes.paragon,
    },
    table: {
      background: palette.background.default,
    },

    emptyText: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '5vh',
    },
    errorClass: {
      top: '-21%',
      left: '0%',
      minWidth: 150,
      padding: '7px 10px',
      marginTop: 0,
      position: 'absolute',
      zIndex: 1,
      background: palette.error.light,
      color: palette.error.contrastText,
      borderRadius: shape.borderRadius,
    },
  };
};

export default withStyles(styles)(BulkTableConfirm);
