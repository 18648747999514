import { useMemo } from 'react';
import useAppCharacteristics from '../../../AppCharacteristicProvider';

const useAppCharacteristicsList = ({
  apcFilter,
  searchQuery,
}: {
  apcFilter: string | null;
  searchQuery: string | null;
}) => {
  const { appCharacteristicsList } = useAppCharacteristics();

  const filteredAPCList = useMemo(() => {
    let apcList = appCharacteristicsList;
    if (apcFilter === 'common') {
      apcList = appCharacteristicsList
        ?.map((appChar) => {
          const revisedTriggers = appChar.triggers.filter(
            (trigger) => trigger.common
          );
          return { name: appChar.name, triggers: revisedTriggers };
        })
        .filter((appChar) => appChar.triggers.length > 0);
    }
    if (!!searchQuery) {
      apcList = apcList
        ?.map((category) => ({
          ...category,
          triggers: category.triggers.filter((trigger) =>
            trigger.description
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
          ),
        }))
        .filter((category) => category.triggers.length > 0);
    }
    apcList?.filter((apcList) => apcList?.triggers);
    return apcList;
  }, [apcFilter, searchQuery, appCharacteristicsList]);
  return {
    filteredAPCList,
  };
};

export default useAppCharacteristicsList;
