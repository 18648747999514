import { IColumnHeader, IFilterEditor } from 'components/UI/Table/models';
import Tooltip from '@material-ui/core/Tooltip';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Input from '@material-ui/icons/Input';
import Favorite from '@material-ui/icons/Star';
import { setAccount } from 'actions/AccountActions';
import { enterAccount } from 'api/apis';
import { enterPartnerAccount } from 'api/PartnerService';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import defaultMappings from 'components/RDGTables/default.mappings';
import store from 'config/storeConfig';
import _ from 'lodash';
import Moment from 'moment';
import { Fragment } from 'react';
import { toggleModalDirect } from 'utils/storeUtils';
import {
  IAccountRowContent,
  IAccountsRowMapping,
  IFilterValues,
  IResolveFiltersFn,
} from './models';

export const adminAccountTableHeaders: IColumnHeader[] = [
  {
    id: 'favorite',
    label: '',
    path: 'id',
    maxWidth: 100,
    flex: 0,
    resizable: false
  },
  {
    id: 'name',
    label: 'Account Name',
    filterEditor: 'StringFilter',
    show: true,
    path: 'name',
    sort: true,
    minWidth: 150,
    maxWidth: 500,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    id: 'planName',
    label: 'Plan',
    sort: false,
    show: true,
    minWidth: 150,
    maxWidth: 200,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    id: 'id',
    filterEditor: 'StringFilter',
    label: 'Account Id',
    sort: true,
    show: false,
    minWidth: 150,
    maxWidth: 200,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    id: 'description',
    filterEditor: 'StringFilter',
    label: 'Description',
    show: true,
    sort: true,
    minWidth: 150,
    maxWidth: 500,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    id: 'created',
    label: 'Date Created',
    show: true,
    sort: true,
    minWidth: 150,
    flex: 1,
    showInContextMenu: true,
  },
  {
    id: 'subscription.subscriptionEnd',
    label: 'Ends',
    show: true,
    sort: true,
    minWidth: 150,
    flex: 1,
    showInContextMenu: true,
  },
  {
    id: 'businessPartner',
    filterEditor: 'StringFilter',
    label: 'Partner',
    sort: true,
    show: true,
    minWidth: 100,
    maxWidth: 100,
    flex: 0,
    showInContextMenu: true,
  },
  {
    id: 'managingAccount',
    label: 'Managing Account',
    sort: true,
    show: true,
    minWidth: 175,
    maxWidth: 300,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    id: 'actions',
    label: 'Actions',
    show: true,
    sort: false,
    minWidth: 75,
    maxWidth: 75,
    flex: 0,
    showInContextMenu: false,
  },
  {
    id: 'dd',
    label: '',
    show: true,
    sort: false,
    minWidth: 35,
    maxWidth: 35,
    flex: 0,
    showInContextMenu: false,
  },
];

export const fetchAdminAccountParamsMapping = defaultMappings;
const handleChange = (rowData: any, handleChangeProp: any) => {
  return () => handleChangeProp(rowData);
};

export const accountsRowMapping: IAccountsRowMapping[] = [
  {
    path: 'favorite',
    columnContent: (args: IAccountRowContent) => {
      const accountPersonaId = args.rowData?.accountPersona?.id;
      const favoriteStatus = args.rowData?.accountPersona?.favorite;
      return (
        <Fragment>
          {accountPersonaId && (
            <Favorite
              className={
                favoriteStatus ? args.classList?.favorite : args.classList.icon
              }
              onClick={handleChange(args.rowData, args?.miscHandler)}
            />
          )}
        </Fragment>
      );
    },
  },
  {
    path: 'name',
    columnContent: (args: IAccountRowContent) => {
      const accountPersonaId = args.rowData.accountPersona?.id;
      const ableToEnter =
        accountPersonaId || args.definedUser === 'superAdminUser';
      return (
        <Fragment>
          {ableToEnter && (
            <Tooltip
              title="Enter tenant"
              style={{ position: 'relative', top: '2px', marginRight: '10px' }}
            >
              <Input
                classes={{ root: args.classList.enterAccount }}
                onClick={() => {
                  enterAccount({ accountId: args.rowData.id });
                  store.dispatch(setAccount(args.rowData));
                }}
              />
            </Tooltip>
          )}
          {args.rowData.name}
        </Fragment>
      );
    },
  },

  {
    path: 'planName',
    columnContent: (args: IAccountRowContent) => (
      <span className="table-cell__ellipsis">
        {args.rowData.subscription?.plans
          .map((singlePlan) => {
            return singlePlan.name;
          })
          .join(',')}
      </span>
    ),
  },
  {
    path: 'id',
  },

  {
    path: 'description',
  },
  {
    path: 'created',
    columnContent: (args: IAccountRowContent) =>
      Moment(args.rowData.created).fromNow(),
  },
  {
    path: 'subscription.subscriptionEnd',
    columnContent: (args: IAccountRowContent) => {
      const endDate = args.rowData.subscription?.subscriptionEnd || '';

      if (Moment(endDate).isBefore()) {
        return `Expired - ${Moment(endDate).fromNow()}`;
      }
      return Moment(endDate).fromNow();
    },
  },
  {
    path: 'businessPartner',
    columnContent: (args: IAccountRowContent) => {
      const ableToEnter =
        args.rowData.businessPartner &&
        (args.definedUser === 'superAdminUser' ||
          args.rowData.accountPersona != null);

      if (args.rowData.businessPartner) {
        if (ableToEnter) {
          return (
            <Tooltip title="Enter partner dashboard">
              <Input
                onClick={() =>
                  enterPartnerAccount({ accountId: args.rowData.id })
                }
                classes={{ root: args.classList['enterAccount'] }}
              />
            </Tooltip>
          );
        }
        return 'Yes';
      }
      return 'No';
    },
  },
  {
    path: 'managingAccount',
    columnContent: (args: IAccountRowContent) =>
      args.rowData.managingAccount?.name,
  },
  {
    path: 'actions',
    columnContent: (args: IAccountRowContent) => {
      return args.rowData.accountPersona ||
        args.superUser?.includes('assign') ? (
        <div className="flex--spaced">
          <Edit
            className="cursor--pointer"
            onClick={() =>
              toggleModalDirect(
                'AccountsCreateEdit',
                {
                  ...args.rowData,
                  selectedAccountId: args.rowData.id,
                },
                {
                  title: 'Edit Account',
                  fullWidth: true,
                }
              )
            }
          />
          <ProtectedComponent allow={{ root: 'view' }} isSuper>
            <Delete
              className="cursor--pointer"
              onClick={() =>
                toggleModalDirect(
                  'AccountsDeleteConfirm',
                  {
                    id: args.rowData.id,
                    ...args.rowData,
                  },
                  {
                    title: 'Delete Account',
                    fullWidth: true,
                  }
                )
              }
            />
          </ProtectedComponent>
        </div>
      ) : (
        ''
      );
    },
  },
  {
    path: 'dd',
    columnContent: ({
      classList,
      toggleDetails,
      isShowingDetails,
      rowData,
      superUser,
    }) => {
      const expandProps = {
        className: isShowingDetails ? classList.toggle : classList.icon,
        onClick: () => toggleDetails(rowData.id),
      };

      return _.get(rowData, 'accountPersona') ||
        superUser?.includes('assign', 'create', 'enter') ? (
        isShowingDetails ? (
          <ExpandLess {...expandProps} />
        ) : (
          <ExpandMore {...expandProps} />
        )
      ) : null;
    },
  },
];
export const resolveFilters: IResolveFiltersFn = (
  filterValues: IFilterValues
): IFilterEditor[] => {
  return [
    {
      name: 'name',
      operator: 'eq',
      type: 'string',
      value: filterValues?.name ?? '',
    },

    {
      name: 'id',
      operator: 'eq',
      type: 'string',
      value: filterValues?.id ?? '',
    },
    {
      name: 'description',
      operator: 'eq',
      type: 'string',
      value: filterValues?.description ?? '',
    },
  ];
};
