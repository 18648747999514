import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    button: {
      marginLeft: '0px',
      marginTop: '10px',
    },
    checkIconStyle: {
      fontSize: 18,
      marginRight: 2,
    },
    errorIcon: {
      alignSelf: 'center',
      color: palette.error.dark,
      marginRight: 5,
    },
    errorText: {
      color: palette.error.dark,
      fontWeight: 'bold',
    },
    successText: {
      color: palette.primary.main,
      fontWeight: 'bold',
      fontSize: 14,
    },
    successTextWrapper: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '10px',
      marginTop: '20px',
    },
  })
);

export default useStyles;
