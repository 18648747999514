import { getActiveModalAtom } from 'atoms/modals';
import AuthButton from 'components/UI/AuthButton';
import Button from 'components/UI/Button';
import InputError from 'components/UI/input/InputError';
import Select from 'components/UI/input/Select';
import TextField from 'components/UI/input/TextField';
import {
  Field as FormikField,
  Form as FormikForm,
  Formik,
  FormikHelpers,
} from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { AddEditCloneEMMGroups_TITLE } from '../../AddEditCloneEMMGroups';
import { IAddEditCloneEMMGroups } from '../../AddEditCloneEMMGroups/models';
import { IAuthForGroupsCreate } from '../../models';
import { IAuthFormProps } from '../models';
import { savedIntuneFormData as savedIntuneFormDataAtom } from './atoms';
import { initialValues } from './initialValues';
import { IAADAppsConsent, IIntuneAuth, IIntuneFormData } from './models';
import Schema from './schema';
import useStyles from './useStyles';
import { createConnection, updateConnection } from './utils';
import { useTranslation } from 'react-i18next';

const activeModalAtom =
  getActiveModalAtom<IAddEditCloneEMMGroups<IIntuneAuth>>();

const IntuneForm: React.FC<IAuthFormProps> = ({
  availableTeams,
  connector,
  data,
  handleBackClick,
  handleCancelClick,
  mode = 'ADD',
  msAuthResponse,
  selectedTeamId,
}) => {
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const [authError, setAuthError] = useState<string>('');
  const setActiveModal = useSetRecoilState(activeModalAtom);
  const [savedIntuneFormData, setSavedIntuneFormData] = useRecoilState(
    savedIntuneFormDataAtom
  );

  const resolvedInitialValues = useMemo(
    () =>
      initialValues(
        mode,
        data?.rowData,
        savedIntuneFormData,
        msAuthResponse,
        connector,
        availableTeams,
        selectedTeamId
      ),
    [
      availableTeams,
      connector,
      data?.rowData,
      mode,
      msAuthResponse,
      savedIntuneFormData,
      selectedTeamId,
    ]
  );

  const handleSubmit = useCallback(
    (
      formData: IIntuneFormData,
      formikHelpers: FormikHelpers<IIntuneFormData>
    ) => {
      switch (mode) {
        case 'ADD':
          createConnection(
            formData,
            formikHelpers,
            setAuthError,
            (authForGroupsCreate: IAuthForGroupsCreate<IIntuneAuth>) => {
              setActiveModal({
                active: AddEditCloneEMMGroups_TITLE,
                payload: { authForGroupsCreate, connector, mode },
              });
            },
            t
          );
          break;
        case 'EDIT':
          updateConnection(
            formData,
            formikHelpers,
            setAuthError,
            () =>
              setActiveModal(() => ({
                active: AddEditCloneEMMGroups_TITLE,
                payload: {
                  cloneInfoFromAuth: {
                    connectionIdToClone: data?.rowData?.id ?? '',
                    name: formData?.name ?? '',
                    teamId: (formData?.team?.value as string) ?? '',
                  },
                  connector,
                  data,
                  mode,
                },
              })),
            t
          );
          break;
        case 'CLONE':
          setActiveModal(undefined); // First clear out data so it doesn't show up in groups table.
          setActiveModal(() => ({
            active: AddEditCloneEMMGroups_TITLE,
            payload: {
              cloneInfoFromAuth: {
                connectionIdToClone: data?.rowData?.id ?? '',
                name: formData?.name ?? '',
                teamId: (formData?.team?.value as string) ?? '',
              },
              connector,
              data: undefined,
              mode,
            },
          }));
          break;
      }
    },
    [mode, setActiveModal, connector, data, setAuthError, t]
  );

  const handleAuthButtonClick = useCallback(
    (item: IAADAppsConsent, values: IIntuneFormData) => {
      setSavedIntuneFormData({
        ...values,
        aad_apps_consent: values?.aad_apps_consent.map((currItem) => {
          if (currItem?.id !== item?.id) {
            return currItem;
          }

          return {
            ...currItem,
            admin_consent: undefined,
            authenticated: false,
            authenticating: true,
            tenant: undefined,
          };
        }),
      });
      localStorage.setItem('redirectUrl', `/console/mtd/mdm`);
      window.location.href = `${item?.request_uri ?? ''}=${
        window.location.origin
      }`;
    },
    [setSavedIntuneFormData]
  );

  const isAuthReady = useCallback(
    (isSubmitting: boolean, values: IIntuneFormData) =>
      isSubmitting ||
      !values?.name ||
      !values?.team ||
      !(
        values?.aad_apps_consent?.[0] &&
        values.aad_apps_consent.every(({ authenticated }) => authenticated)
      ),
    []
  );

  if (!ready) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={resolvedInitialValues}
      onSubmit={handleSubmit}
      validateOnBlur
      validationSchema={Schema(t)}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <FormikForm>
          <FormikField
            component={TextField}
            disabled={isSubmitting}
            label={t('MTD.INTEGRATIONS.CONNECTION_NAME')}
            name="name"
          />
          <FormikField
            component={Select}
            disableClearable={true}
            disabled={mode === 'EDIT'}
            label={t('GLOBAL.TEAM')}
            multiple={false}
            name="team"
            options={availableTeams ?? []}
            setFieldValue={setFieldValue}
            type="select"
          />
          <div className={classes.authButtons}>
            {!!values?.aad_apps_consent?.[0] &&
              values.aad_apps_consent.map((item) => {
                if (!item?.id || !item?.request_uri) {
                  return null;
                }

                return (
                  <AuthButton
                    authenticated={!!item?.authenticated}
                    buttonText={t('MTD.INTEGRATIONS.ADD_TO_AZURE_DIRECTORY', {
                      data: item.name ?? 'NA',
                    })}
                    disabled={isSubmitting}
                    onClick={() => handleAuthButtonClick(item, values)}
                    successText={t('ADDED_TO_AZURE_DIRECTORY', {
                      data: item.name ?? 'NA',
                    })}
                  />
                );
              })}
          </div>
          {!!authError && <InputError override={authError} />}
          <div className={classes.modalButtons}>
            {mode === 'ADD' && (
              <Button
                className={classes.buttonBackgroundColor}
                disabled={isSubmitting}
                onClick={handleBackClick}
                text={t('GLOBAL.BACK')}
                type="button"
              />
            )}
            <Button
              className={classes.buttonBackgroundColor}
              disabled={isSubmitting}
              onClick={handleCancelClick}
              text={t('GLOBAL.CANCEL')}
              type="button"
            />
            <Button
              color="secondary"
              disabled={isAuthReady(isSubmitting, values)}
              text={t('GLOBAL.NEXT')}
              type="submit"
            />
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default IntuneForm;
