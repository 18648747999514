import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { ReferenceOptions } from 'yup/lib/Reference';

const Schema = (t: TFunction<'translation', undefined>) =>
  Yup.object().shape({
    oldPassword: Yup.string().required(
      t('USER_SETTINGS.ERRORS.OLD_PASSWORD_REQUIRED')
    ),
    password: Yup.string()
      .min(
        8,
        t('USER_SETTINGS.PASSWORD_RULES.MIN_LENGTH_PASSWORD_REQUIRED', {
          length: 8,
        })
      )
      .test(
        'notSameAsOldPassword',
        t('USER_SETTINGS.ERRORS.NEW_PASSWORD_UNIQUE'),
        function (password) {
          const { oldPassword } = this.parent;
          if (password === oldPassword) {
            return false;
          }
          return true;
        }
      )
      .required(t('USER_SETTINGS.ERRORS.PASSWORD_REQUIRED')),
    confirmedPassword: Yup.string()
      .min(
        8,
        t('USER_SETTINGS.PASSWORD_RULES.MIN_LENGTH_CONFIRM_PASSWORD_REQUIRED', {
          length: 8,
        })
      )
      .oneOf(
        [
          Yup.ref(
            'password',
            t(
              'USER_SETTINGS.ERRORS.PASSWORDS_MUST_MATCH'
            ) as ReferenceOptions<unknown>
          ),
          '',
        ],
        t('USER_SETTINGS.ERRORS.PASSWORDS_MUST_MATCH')
      )
      .required(t('USER_SETTINGS.ERRORS.PASSWORD_CONFIRM_REQUIRED')),
  });

export default Schema;
