import { trimAndAddAsterisk } from 'utility/QueryUtility';
import Moment from 'moment';

export const fetchThreatsParamsMapping = [
  {
    path: 'after',
    transform: ({ after }) => (after ? after.toISOString() : null),
  },
  {
    path: 'before',
    transform: ({ before }) =>
      before ? before.toISOString() : new Date().toISOString(),
  },
  {
    path: 'from',
    paramKey: 'after',
    transform: ({ from, to }) => {
      if (!from) {
        return null;
      }

      return from === to
        ? Moment.unix(from).startOf('day').toISOString()
        : Moment.unix(from).toISOString();
    },
  },
  {
    path: 'to',
    paramKey: 'before',
    transform: ({ to, from }) => {
      if (!to) {
        return null;
      }

      return from === to
        ? Moment.unix(to).endOf('day').toISOString()
        : Moment.unix(to).toISOString();
    },
  },
  {
    path: 'teamId',
    paramKey: 'teamId.keyword',
  },
  {
    path: 'page',
  },
  {
    path: 'size',
  },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      switch (orderBy) {
        case 'zappInstance.version':
        case 'os':
        case 'device.model':
        case 'deviceId':
        case 'device.zdeviceId':
        case 'device.mdmDeviceId':
        case 'zappInstance.zversion':
        case 'zappInstance.zbuildNumber':
        case 'device.os.version':
        case 'device.mamDeviceId':
        case 'teamName':
        case 'zappInstance.externalTrackingId1':
        case 'zappInstance.externalTrackingId2':
        case 'deviceOwner':
          return `${orderBy}.keyword,${order}`;
        case 'activationName':
          return `activationName.keyword,${order}`;
        case 'timestamp':
          return `timestampInfo.timestamp,${order}`;
        case 'zappName':
          return `zappInstance.name.keyword,${order}`;
        default:
          if (orderBy && order) {
            return `${orderBy},${order}`;
          }
          return null;
      }
    },
  },
  {
    path: 'search',
    transform: ({ search = '' }) => {
      if (!search) {
        return null;
      }

      return trimAndAddAsterisk(search);
    },
  },
  {
    path: 'zappId',
    paramKey: ({ zappId }) => {
      return zappId !== 'All' ? 'zappId.keyword' : 'zappId';
    },
    transform: ({ zappId }) => {
      if (zappId !== 'All') {
        return zappId;
      }

      return undefined;
    },
  },
  // Service Mappings for search fields
  {
    path: 'threatTypeName',
    paramKey: 'threatTypeName.keyword',
  },
  {
    path: 'vector',
  },
  {
    path: 'categoryId',
  },
  {
    path: 'severity',
    transform: ({ severity }) => {
      if (severity === 'Normal') return '0';
      return severity;
    },
  },
  {
    path: 'state',
  },
  {
    path: 'simulated',
  },
  {
    path: 'timestamp',
    paramKey: 'timestampInfo.timestamp',
  },
  {
    path: 'mitigatedAt',
  },
  {
    path: 'threatTypeId',
  },
  {
    path: 'summary',
    paramKey: 'threatTypeId',
  },
  {
    path: 'deviceId',
    paramKey: 'deviceId.keyword',
  },
  {
    path: 'device.zdeviceId',
    paramKey: 'device.zdeviceId.keyword',
  },
  {
    path: 'device.mdmDeviceId',
    paramKey: 'device.mdmDeviceId.keyword',
  },
  {
    path: 'device.mamDeviceId',
    paramKey: 'device.mamDeviceId.keyword',
  },
  {
    path: 'groupId',
    paramKey: 'groupId.keyword',
  },
  {
    path: 'zappInstance.externalTrackingId1',
    paramKey: 'zappInstance.externalTrackingId1.keyword',
  },
  {
    path: 'zappInstance.externalTrackingId2',
    paramKey: 'zappInstance.externalTrackingId2.keyword',
  },
  {
    path: 'id',
    paramKey: 'id.keyword',
  },
  {
    path: 'zappInstance.id',
    paramKey: 'zappInstance.id.keyword',
  },
  {
    path: 'zappInstance.bundleId',
    paramKey: 'zappInstance.bundleId.keyword',
  },
  {
    path: 'zappInstance.groupId',
    paramKey: 'zappInstance.groupId.keyword',
  },
  {
    path: 'zappInstance.name',
    paramKey: 'zappInstance.name.keyword',
  },
  {
    path: 'zappInstance.version',
    paramKey: 'zappInstance.version.keyword',
  },
  {
    path: 'zappInstance.buildNumber',
    paramKey: 'zappInstance.buildNumber.keyword',
  },
  {
    path: 'zappInstance.zversion',
    paramKey: 'zappInstance.zversion.keyword',
  },
  {
    path: 'zappInstance.zbuildNumber',
    paramKey: 'zappInstance.zbuildNumber.keyword',
  },
  {
    path: 'os',
    paramKey: 'os.keyword',
  },
  {
    path: 'generalInfo.timeInterval',
  },
  {
    path: 'generalInfo.deviceIp',
  },
  {
    path: 'generalInfo.ssid',
    paramKey: 'generalInfo.ssid.keyword',
  },
  {
    path: 'generalInfo.bssid',
    paramKey: 'generalInfo.bssid.keyword',
  },
  {
    path: 'generalInfo.attackerIp',
  },
  {
    path: 'generalInfo.externalIp',
  },
  {
    path: 'generalInfo.gatewayMac',
    paramKey: 'generalInfo.gatewayMac.keyword',
  },
  {
    path: 'generalInfo.gatewayIp',
  },
  {
    path: 'generalInfo.attackerMac',
  },
  {
    path: 'generalInfo.deviceTimestamp',
  },
  {
    path: 'sideLoadedAppInfo.appName',
  },
  {
    path: 'sideLoadedAppInfo.developer',
    paramKey: 'sideLoadedAppInfo.developer.keyword',
  },
  {
    path: 'sideLoadedAppInfo.bundleId',
  },
  {
    path: 'sideLoadedAppInfo.fileHash',
  },
  {
    path: 'malwareInfo.bundleId',
    paramKey: 'malwareInfo.bundleId.keyword',
  },
  {
    path: 'malwareInfo.processName',
    paramKey: 'malwareInfo.processName.keyword',
  },
  {
    path: 'malwareInfo.filename',
    paramKey: 'malwareInfo.filename.keyword',
  },
  {
    path: 'malwareInfo.fileHash',
    paramKey: 'malwareInfo.fileHash.keyword',
  },
  {
    path: 'malwareFamilyInfo.familyName',
  },
  {
    path: 'malwareInfo.matches.name',
  },
  {
    path: 'zeventId',
    paramKey: 'zeventId.keyword',
  },
  {
    path: 'activationName',
    paramKey: 'activationName.keyword',
  },
  {
    path: 'module',
  },
  {
    path: 'phishingUrl',
    paramKey: 'suspiciousUrlInfo.url.keyword',
  },
  {
    path: 'contentCategoryName',
    paramKey: 'suspiciousUrlInfo.contentCategoryName.keyword',
  },
  {
    path: 'classificationName',
  },
  {
    path: 'os.version',
    paramKey: 'device.os.version.keyword',
  },
  {
    path: 'query',
  },
];
