import { ISelectItem } from 'components/UI/input/Select';
import { ILocationQuery, TClassList } from 'components/UI/Table/models';
import { IColumnHeader } from 'components/UI/Table/models';
import { IAADAppsConsent } from '../../AddEditCloneEMMAuth/IntuneForm/models';
import { IConnector } from '../../models';

export interface IUpdateUrlProps {
  admin_consent: boolean | undefined;
  error: string | undefined;
  error_description: string | undefined;
  error_uri: string | undefined;
  tenant: string | undefined;
}

export interface IEMMLocationQuery extends ILocationQuery {
  admin_consent?: string;
  tenant?: string;
  error?: string;
}

export interface ILocation {
  hash: string;
  key: string;
  path: string;
  query: IEMMLocationQuery;
  search: string;
}

export interface IParams {
  aap: string;
  edit: string;
}

export interface IMatch {
  isExact: boolean;
  params: IParams;
  path: string;
  url: string;
}

export interface IHistory {
  replace: (route: string) => void;
}

export interface IAuth {
  aad_apps_consent: IAADAppsConsent[];
  access_key?: string;
  api_key: string;
  app_id?: string;
  app_version?: string;
  auth_method_name: string;
  billing_id?: string;
  client_id?: string;
  cloud_connect_required: boolean;
  cloud?: string;
  device_space_id: string;
  oauth_url?: string;
  platform_id?: string;
  provider_id?: string;
  proxy_config_id: string;
  user_id: string;
}

export interface ISingleGroupItem extends ISelectItem {
  description?: string;
}

export interface ISingleGroup {
  appSettings: ISingleGroupItem;
  dormancyPolicy: ISingleGroupItem;
  groupIdsToSync: ISingleGroupItem;
  phishingPolicy: ISingleGroupItem;
  privacyPolicy: ISingleGroupItem;
  trmPolicy: ISingleGroupItem;
  networkPolicy: ISingleGroupItem;
  osRiskPolicy: ISingleGroupItem;
}

export interface IConnectedGroupData {
  id: string;
  index: number;
  singleGroup: ISingleGroup;
}

export interface IGroupToSync {
  [index: string]: string;
  appSettingsId: string;
  mdmGroupDescription: string;
  mdmGroupId: string;
  mdmGroupName: string;
  phishingPolicyId: string;
  privacyId: string;
  dormancyPolicyId: string;
  trmId: string;
}
export interface IActionGroupsToSync {
  mdmGroupId: string;
  mdmGroupName: string;
}
export interface ISyncInfo {
  lastCompletedAt: string;
  lastCompletedStatus: string;
}

export interface IMDMRowData {
  accountId: string;
  auth: IAuth;
  baseUrl: string;
  connectedGroups: IConnectedGroupData[];
  connectorApiVersion: string;
  connectorType: IConnector;
  connectorVersion: string;
  created: string;
  displayName: string;
  groupsArray: string[];
  groupsToSync: IGroupToSync[];
  connectedActionGroupsToSync: IConnectedGroupData[];
  id: string;
  includeNestedGroups: boolean;
  allowDevicesWithNoUser: boolean;
  maxDeviceIdleDays: number;
  mode: IEMMConnectionMode;
  modified: string;
  name: string;
  provisioned: string;
  provisionId: string;
  serverVersion: string;
  syncCronSchedule: string;
  syncEnabled: boolean;
  syncInfo: ISyncInfo;
  teamId?: string;
  secret_key?: string;
  dm_code?: string;
  certificate?: string;
}

export interface IFullData {
  classList: TClassList;
  classes: TClassList;
  definedUser: string;
  isShowingDetails: boolean;
  omitTableColumns: string[];
  rowData: IMDMRowData;
  superUser: string[];
  tableId: string;
  toggleDetails: (id: string | number) => void;
}

export interface IMDMFormReducer {
  groupIdsToSync: string[];
}

export interface ITableSetting {
  count: number;
  duration: number;
  order: string;
  orderBy: string;
  page: number;
  size: number;
  tableHeaders: IColumnHeader[];
}

export interface ITeam {
  label: string;
  value: string;
  disabled: boolean;
}

export interface ITeamsHash {
  [key: string]: { name: string; };
}

export interface ITeamsState {
  available: ITeamsHash;
  selected: string;
}

export type TUISettings = {
  [key: string]: ITableSetting;
};

export interface IAccount {
  id: string;
}

export interface IUser {
  account: IAccount;
}

export interface IState {
  MDMFormReducer: IMDMFormReducer;
  uiSettings: TUISettings;
  user: IUser;
  teams: ITeamsState;
}

// https://github.com/Zimperium/svc-common-messaging/blob/master/src/main/java/com/zimperium/server/messaging/events/emm/ConnectionMode.java
export enum IEMMConnectionMode {
  MDM_MODE = 'MDM_MODE',
  MAM_MODE = 'MAM_MODE',
  MDM_MAM_MODE = 'MDM_MAM_MODE',
  MAM_WITH_OTHER_MDM_MODE = 'MAM_WITH_OTHER_MDM_MODE',
}
