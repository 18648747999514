export enum EOsRuleType {
  Latest = 'LATEST',
  VersionBack = 'VERSIONS_BACK',
  VersionBackPatched = 'VERSIONS_BACK_PATCHED',
  MinVersion = 'MIN_VERSION',
  MinVersionPatched = 'MIN_VERSION_PATCHED',
}

export interface IRiskRules {
  osRuleType: EOsRuleType;
  gracePeriodInDays: string | null;
  minimumAllowedVersion: string | null;
  numAllowedPriorMajorVersions: string | null;
}

export interface IOsRiskPolicy {
  id: string;
  accountId: string;
  groups: { id: string; name: string }[];
  name: string;
  created?: string;
  modified?: Date;
  osRiskPolicyRules: {
    iosRiskRules: IRiskRules;
    androidRiskRules: IRiskRules;
  };
  staticFilesWritten?: Date;
  team?: any;
  teamId?: string | null;
  assigned?: boolean;
  global?: boolean;
}

export interface IOsRiskPolicyRequest {
  name: string;
  osRules: {
    iosRiskRules: IRiskRules;
    androidRiskRules: IRiskRules;
  };
  teamId?: string | null;
}

export type IValues = Record<EOsRuleType, Omit<IRiskRules, 'osRuleType'>>;
