import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ config }: any) =>
  createStyles({
    publicFooter: {
      display: 'flex',
      marginBottom: 15,
      position: 'absolute',
      bottom: '0',
      left: '50%',
      fontSize: config.textSizes.petite,
      transform: 'translateX(-50%)',
  
      '& > *': {
        margin: '0 10px',
      },
    },
  })
);

export default useStyles;
