import { Divider } from '@material-ui/core';
import React from 'react';
import useStyles from './useStyles';
import { IZShieldApp } from '../models';
import AppCard from './AppCard';

interface ICategorySectionProps {
  categoryApps: IZShieldApp[];
  category: string;
  activeCard: string;
  setActiveCard: (app: IZShieldApp) => void;
}

const CategorySection: React.FC<ICategorySectionProps> = ({ categoryApps, category, activeCard, setActiveCard }) => {
  const classes = useStyles();
  if (!categoryApps?.length) {
    return null;
  }
  return (
    <>
      <span className={classes.categorySectionContainer}>{category}</span>
      <Divider classes={{ root: classes.categoryDivider }} />
      <div className={classes.categoryWrapper}>
        {categoryApps?.map((app: IZShieldApp) => {
          return (
            <AppCard app={app} key={app?.id} activeCard={activeCard} setActiveCard={setActiveCard} />
          );
        })
        }
      </div>
    </>
  );
};

export default CategorySection;