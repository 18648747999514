/* eslint-disable max-lines-per-function */
import { default as MuiAccordion } from '@material-ui/core/Accordion';
import { default as MuiAccordionDetails } from '@material-ui/core/AccordionDetails';
import { default as MuiAccordionSummary } from '@material-ui/core/AccordionSummary';
import { default as MUIArrowForwardIosIcon } from '@material-ui/icons/ArrowForwardIos';
import cc from 'classcat';
import { DrawerContext } from 'components/drawer/DrawerProvider';
import { IDrawerContext } from 'components/drawer/DrawerProvider/models';
import DrawerHeader from 'components/UI/DrawerHeader';
import DrawerSkeleton from 'components/UI/DrawerSkeleton';
import GenericCard from 'components/UI/GenericCard';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import useStyles from './useStyles';
import { useTranslation } from 'react-i18next';
import CircleIcon from '../../ZScanApps/CircleComponent/index';
import ComplianceTable from 'dashboard/zDev/Compliance/ComplianceTable';
import SeverityCircle from 'components/main/ZScanApps/SeverityCircle';
import { countFrequency } from 'components/main/ZScanApps/ZScanFindings/utils';
import AppleIcon from 'components/UI/icons/AppleIcon';
import AndroidIcon from '@material-ui/icons/Android';
import { IZScanPoliciesDrawerData } from './model';
import Input from '@material-ui/icons/Input';
import _ from 'lodash';
import { toggleModalDiffered } from 'utils/storeUtils';
import { IZscanIndicator } from '../models';
import { zScanPolicyEntriesHash } from '../atoms';
import { useRecoilState } from 'recoil';

interface IDrawerProps {}

const Drawer: React.FC<IDrawerProps> = () => {
  const [complianceExpanded, setComplianceExpanded] = useState<boolean>(true);
  const [appsExpanded, setAppsExpanded] = useState<boolean>(true);
  const [zScanPolicyEntry] = useRecoilState(zScanPolicyEntriesHash);

  const classes = useStyles();
  const { ready } = useTranslation();
  const { drawerData, closeDrawer } =
    useContext<Partial<IDrawerContext<IZScanPoliciesDrawerData>>>(
      DrawerContext
    );
  const { rowData }: any = drawerData ?? null;
  const complianceCount = countFrequency(rowData?.compliance);
  const closeDrawerOnLogout = useCallback(() => {
    if (!closeDrawer) {
      return;
    }
    closeDrawer();
  }, [closeDrawer]);

  useEffect(() => {
    window.addEventListener(`auth:logout`, closeDrawerOnLogout);

    return () => {
      window.removeEventListener(`auth:logout`, closeDrawerOnLogout);
    };
  }, [closeDrawerOnLogout]);

  const handleClose = () => {
    if (!closeDrawer) {
      return;
    }
    closeDrawer();
  };

  if (!ready) {
    return null;
  }
  if (!drawerData) {
    return (
      <div>
        <DrawerSkeleton
          onClose={handleClose}
          os={rowData?.platform}
          title={rowData?.name ?? ''}
        />
      </div>
    );
  }
  const PlatformIconMapping = (platforms: any) => {
    return platforms.map((os: string, index: number) => {
      if (!os) {
        return null;
      }
      return os === 'ios' ? (
        <span style={{ marginRight: '5px' }} className="table__os-icon">
          <AppleIcon />
        </span>
      ) : (
        <AndroidIcon key={`${os}-${index}`} style={{ marginRight: '10px' }} />
      );
    });
  };

  const CvssCalculator = (version: string, data: IZscanIndicator) => {
    const cvss_2_0_score = _.get(data, 'cvss_2_0_score');
    const cvss_2_0_vector = _.get(data, 'cvss_2_0_vector');
    const cvss_3_1_score = _.get(data, 'cvss_3_1_score');
    const cvss_3_1_vector = _.get(data, 'cvss_3_1_vector');

    return (
      <span
        style={{
          overflowWrap: 'anywhere',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        {version === '2.0' ? cvss_2_0_score : cvss_3_1_score}{' '}
        <Input
          onClick={toggleModalDiffered(
            'ZDevCVSSCalculator',
            {
              ...data,
              // findingId: drawerData?.rowData?.id,
              source: 'policies',
              version,
              cvss_2_0_score,
              cvss_2_0_vector,
              cvss_3_1_score,
              cvss_3_1_vector,
            },
            { fullWidth: true, maxWidth: 'l' }
          )}
          // classes={{ root: data.classList.enterAccount }}
        />{' '}
        {version === '2.0' ? cvss_2_0_vector : cvss_3_1_vector}
        {_.get(data, 'cvssData.modified') && ' (modified)'}
      </span>
    );
  };
  return (
    <>
      <DrawerHeader
        onClose={handleClose}
        os={rowData?.platform}
        title={rowData?.name ?? ''}
      />

      <MuiAccordion className={classes.accordion} expanded={appsExpanded}>
        <MuiAccordionSummary
          onClick={() => setAppsExpanded(!appsExpanded)}
          className={classes.accordionHeader}
          expandIcon={<MUIArrowForwardIosIcon />}
        >
          Finding Details
        </MuiAccordionSummary>

        <MuiAccordionDetails
          classes={{
            root: classes.accordionDetails,
          }}
        >
          <GenericCard noPadding>
            <div className={classes.findingDetailsContainer}>
              <div className={classes.detailsSubContianer}>
                <table
                  className={cc([
                    classes.detailsTable,
                    classes.detailsTableWithPadding,
                  ])}
                >
                  <tr>
                    <td>Finding Name:</td>
                    <td>{rowData?.name ?? ''}</td>
                  </tr>
                  <tr>
                    <td>Platform:</td>
                    <td>{PlatformIconMapping(rowData?.platform)}</td>
                  </tr>
                  <tr>
                    <td>Severity:</td>
                    <td>
                      <SeverityCircle
                        severity={
                          zScanPolicyEntry[rowData?.id]?.severity ??
                          rowData.severity
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Category:</td>
                    <td>{rowData?.categoryName ?? ''}</td>
                  </tr>
                  <tr>
                    <td>Subcategory:</td>
                    <td>{rowData?.subcategoryName ?? ''}</td>
                  </tr>
                  {rowData?.cvss_2_0_score ? (
                    <tr>
                      <td>CVSS 2.0:</td>
                      <td>{CvssCalculator('2.0', rowData)}</td>
                    </tr>
                  ) : null}
                  {rowData?.cvss_3_1_score ? (
                    <tr>
                      <td>CVSS 3.1:</td>

                      <td>{CvssCalculator('3.1', rowData)}</td>
                    </tr>
                  ) : null}
                </table>
              </div>
              <div className={classes.detailsDescriptions}>
                <div>
                  <span>Description</span>
                  <p>{rowData?.findingDescription ?? ''}</p>
                </div>
                <div>
                  <span>Business Impact</span>
                  <p>{rowData?.businessImpact ?? ''}</p>
                </div>
                <div>
                  <span>Recommendation</span>
                  <p>{rowData?.recommendation ?? ''}</p>
                </div>
              </div>
            </div>
          </GenericCard>
        </MuiAccordionDetails>
      </MuiAccordion>
      <MuiAccordion className={classes.accordion} expanded={complianceExpanded}>
        <MuiAccordionSummary
          onClick={() => setComplianceExpanded(!complianceExpanded)}
          className={classes.accordionHeader}
          expandIcon={<MUIArrowForwardIosIcon />}
        >
          Compliance
        </MuiAccordionSummary>

        <MuiAccordionDetails
          classes={{
            root: classes.circleStyle,
          }}
        >
          <div>
            <div>
              <CircleIcon data={complianceCount?.CWE} />
              <span>CWE</span>
            </div>
            <div>
              <CircleIcon data={complianceCount?.CVE} />
              <span>CVE</span>
            </div>
            <div>
              <CircleIcon data={complianceCount?.OWASP} />
              <span>OWASP</span>
            </div>
            <div>
              <CircleIcon data={complianceCount?.MASVS} />
              <span>MASVS</span>
            </div>
          </div>
          <div>
            <div>
              <CircleIcon data={complianceCount?.GDPR} />
              <span>GDPR</span>
            </div>
            <div>
              <CircleIcon data={complianceCount?.HIPAA} />
              <span>HIPAA</span>
            </div>
            <div>
              <CircleIcon data={complianceCount?.PCI} />
              <span>PCI</span>
            </div>
            <div>
              <CircleIcon data={complianceCount?.NIAP} />
              <span>NIAP</span>
            </div>
          </div>
          <div
            style={{
              marginTop: '-18px',
            }}
          >
            <ComplianceTable complianceArray={rowData?.compliance} />
          </div>
        </MuiAccordionDetails>
      </MuiAccordion>
    </>
  );
};
export default Drawer;
