import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import _ from 'lodash';

const styles = ({ palette, config }) => {
  return {
    wrapper: {
      background: palette.background.paper,
      outline: 'none',
      maxWidth: '50vw',
      maxHeight: 400,
      overflowY: 'scroll',
    },
    tableCell: {
      borderBottomColor: 'transparent',
    },
    tableHeaderCell: {
      color: palette.text.secondary,
      fontWeight: 'bold',
      borderBottomColor: 'transparent',
    },
    tableRow: {
      height: config.textSizes.paragon,
    },
    emptyText: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '5vh',
    },
  };
};

class ThreatsProcessList extends Component {
  render() {
    const { classes, threat } = this.props;
    const noData = 'Process List data not available';
    return (
      <div className={classes.wrapper}>
        <Table>
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableHeaderCell}>
                Process Name
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>PID</TableCell>
              <TableCell className={classes.tableHeaderCell}>PPID</TableCell>
              <TableCell className={classes.tableHeaderCell}>
                Service Name
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>User</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!_.isEmpty(threat.processList) ? (
              threat.processList.map((process, index) => {
                return (
                  <TableRow key={index} className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>
                      {process.processName}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {process.processId}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {process.parentProcessId}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {process.service}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {process.user}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow className={classes.tableRow}>
                <TableCell
                  className={classes.tableCell}
                  padding="dense"
                  colSpan={5}
                >
                  <span className={classes.emptyText}>{noData}</span>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default withStyles(styles)(ThreatsProcessList);
