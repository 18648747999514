import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    maxIdle: {
      '& .MuiInputBase-root': {
        width: '120px',
      },
    },
    nestedGroups: {
      alignItems: 'center',
      display: 'flex',
      fontSize: '12px',
      '& > label > span:nth-child(2)': {
        color: palette.text.primary,
        whiteSpace: 'nowrap',
      },
      justifyItems: 'flex-start',
    },
    SyncModeStyles: {
      width: '410px',
    },
    tooltip: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-start',
      position: 'relative',
      marginLeft: ' -10px',
    },
    tooltipText: {
      right: '40px',
      backgroundColor: palette.common.darkGreySoft,
      borderRadius: '3px',
      color: palette.common.white,
      overflow: 'hidden',
      padding: '6px 8px 6px 8px',
      position: 'absolute',
      width: '293px',
    },
    mainContainerIntune: {
      display: 'flex',
      gap: '10px',
    },
    nestedGroup: {
      alignSelf: 'center',
    },
    mainNestedGroup: {
      display: 'flex',
      flexDirection: 'column',
    },
  })
);

export default useStyles;
