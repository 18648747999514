import { ISelectItem } from 'components/UI/input/Select';
import GlobalIndicator from './GlobalIndicator';

const CustomPolicyOption: React.FC<ISelectItem & { showLabel?: boolean }> = (
  option
) => {
  return (
    <GlobalIndicator
      inline
      isGlobal={option?.accountBounded}
      isEmmConnection={!!option?.connection?.id}
      label={option.label}
      showLabel={option.showLabel}
    />
  );
};

export default CustomPolicyOption;
