import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import Button from 'components/UI/Button';
import React, { useCallback, useEffect } from 'react';
import { getActiveModalAtom } from 'atoms/modals';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ZScanUploadApp_TITLE } from 'components/modals/ZScanUploadApp';
import { toggleModalDirect } from 'utils/storeUtils';
import { IZScanApp } from './models';
import { useTranslation } from 'react-i18next';
import { reminderToBeHiddenAtom } from './atoms/modals';

interface IUpdateAppButtonProps {
  buttonText?: string;
}
const activeModalAtom = getActiveModalAtom<IZScanApp>();
const UploadAppButton: React.FC<IUpdateAppButtonProps> = ({ buttonText }) => {
  const setActiveModal = useSetRecoilState(activeModalAtom);
  const [reminderToBeHidden, setReminderToBeHidden] = useRecoilState(
    reminderToBeHiddenAtom
  );

  useEffect(() => {
    const clearReminderOnLogout = () => {
      setReminderToBeHidden(false);
    };

    window.addEventListener('auth:logout', clearReminderOnLogout, {
      once: true,
    });
  }, [setReminderToBeHidden]);

  const { t, ready } = useTranslation();

  if (!ready) {
    return null;
  }

  const handleConfirmation = useCallback(() => {
    if (!reminderToBeHidden) {
      return toggleModalDirect(
        'GenericReminder',
        {
          confirmButtonText: t('ZSCAN.AGREE'),
          doNotRemindText: t('ZSCAN.UPLOAD_APP_REMINDER_TEXT'),
          headerText: t('ZSCAN.UPLOAD_APP'),
          messageText: t('ZSCAN.UPLOAD_APP_CONFIRM_DESCRIPTION'),
          onCancel: () => toggleModalDirect('GenericConfirm', false),
          onConfirm: () => {
            setActiveModal({ active: ZScanUploadApp_TITLE });
            toggleModalDirect('GenericConfirm', false);
          },
          onDisableReminder: () => setReminderToBeHidden(true),
        },
        {}
      );
    }
    
    setActiveModal({ active: ZScanUploadApp_TITLE });
    return toggleModalDirect('GenericConfirm', false);
  }, [reminderToBeHidden, setReminderToBeHidden, setActiveModal, t]);

  return (
    <ProtectedComponent allow={{ zdev_builds: 'upload' }}>
      <Button
        color="secondary"
        onClick={handleConfirmation}
        text={buttonText ?? t('ZSCAN.UPLOAD_NEW_APP')}
      />
    </ProtectedComponent>
  );

};

export default UploadAppButton;