import React, { useCallback } from 'react';
import Form from './Form';
import { getActiveModalAtom } from 'atoms/modals';
import { useRecoilState } from 'recoil';
import Modal from 'components/UI/Modal';
import { IZScanApp } from 'components/main/ZScanApps/models';

export const ZScanUploadApp_TITLE = 'ZScanUploadApp';
const activeModalAtom =
  getActiveModalAtom<IZScanApp>();

const ZScanUploadApp: React.FC = () => {
  const [activeModal, setActiveModal] = useRecoilState(activeModalAtom);

  const handleClose = useCallback(() => {
    if (activeModal) {
      setActiveModal(undefined);
    }
  }, [setActiveModal, activeModal]);

  return (
    <Modal
      caption={'Upload Build/App'}
      onClose={handleClose}
      scrollable={true}
      title={ZScanUploadApp_TITLE}
    >
      <Form />
    </Modal>
  );
};

export default ZScanUploadApp;
