import { ServiceHandlerFactory } from '../utils/serviceUtils';

const baseUrl = 'api/email/v1';

// Get your account's SMTP settings
export const fetchSmtpSettings = ServiceHandlerFactory({
  axios: {
    url: `${baseUrl}/smtp-settings`,
  },
});

// Saves your account SMTP settings
export const saveSmtpSettings = ServiceHandlerFactory({
  axios: {
    url: `${baseUrl}/smtp-settings`,
    method: 'put',
  },
});

// Delete your account's SMTP settings
export const deleteSmtpSettings = ServiceHandlerFactory({
  axios: {
    url: `${baseUrl}/smtp-settings`,
    method: 'delete',
  },
});

// Test SMTP settings
export const testSmtpSettingsConnection = ServiceHandlerFactory({
  axios: {
    url: `${baseUrl}/smtp-settings/test`,
    method: 'post',
  },
});

// Send test email using provided SMTP settings
export const sendTestEmail = ServiceHandlerFactory({
  axios: {
    url: `${baseUrl}/smtp-settings/test-email`,
    method: 'post',
  },
});
