import cc from 'classcat';
import React, { useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { IAbstractProportionChartColumn } from '../models';
import useStyles from './useStyles';

interface IBarProps extends IAbstractProportionChartColumn {
  baseline: number;
}

const Bar: React.FC<IBarProps> = ({ count, suffix, baseline, link }) => {
  const classes = useStyles();

  const [deepLinked, setDeepLinked] = useState<boolean>(false);

  const safeCount = useMemo(
    () => (typeof count === 'number' ? count : 0),
    [count]
  );

  const redirectMaybe = useMemo(
    () => !!link && deepLinked && <Redirect to={link} />,
    [deepLinked, link]
  );

  return (
    <div
      className={cc([classes.item, 'chart-item'])}
      onClick={() => setDeepLinked(true)}
      style={{
        minWidth: `${Math.min(100, (safeCount / baseline) * 33)}%`,
      }}
    >
      {`${count ?? 'N/A'} ${suffix}`}
      {redirectMaybe}
    </div>
  );
};

export default Bar;
