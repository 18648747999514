import { TFunction } from 'react-i18next';

export const appCharacteristicsColumnHeaders = (
  t: TFunction<'translation', undefined>
) => [
  { id: 'enabled', label: t('GLOBAL.ENABLED') },
  { id: 'name', label: t('GLOBAL.NAME') },
  { id: 'policyAction', label: 'Policy Action' },
  {
    id: 'appCount',
    label: 'Global Apps Affected',
  },
  {
    id: 'deviceCount',
    label: 'Device Count',
  },
  { id: 'modifiedBy', label: 'Modified By' },
  { id: 'modified', label: 'Modified Date' },
  { id: 'actions', label: t('GLOBAL.ACTIONS') },
];
