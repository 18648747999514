import { TFunction } from 'react-i18next';

export const SeverityLevel = (
  t: TFunction<'translation', undefined>
): Record<number, string> => ({
  0: t('MTD.POLICIES.THREAT.NORMAL'),
  1: t('GLOBAL.LOW'),
  2: t('MTD.POLICIES.THREAT.ELEVATED'),
  3: t('GLOBAL.CRITICAL'),
});

export enum TRMTableActions {
  EnableAlert = 'enable-alert',
  DeviceAlert = 'device-alert',
  EnableDetection = 'enable-detection',
  DisableDetection = 'disable-detection',
  SubscribeNotifications = 'subscribe-notifications',
  UnsubscribeNotifications = 'unsubscribe-notifications',
}

export enum PolicyChangeOptions {
  AlertUser = 'alertUser',
  ShouldCollect = 'shouldCollect',
  Severity = 'severity',
  MdmThreatAction = 'mdmThreatAction',
  MdmThreatTarget = 'mdmThreatTarget',
  MdmMitigationAction = 'mdmMitigationAction',
  MdmMitigationTarget = 'mdmMitigationTarget',
  SelectRow = 'selectRow',
  EditAlertUser = 'editAlertUser',
  EnableCheckbox = 'enableCheckbox',
  EnableNotifications = 'enableNotifications',
  DisableNotifications = 'disableNotifications',
  DeviceActions = 'customResponses',
  Visible = 'visible',
  Enabled = 'enabled',
}

export enum DeviceActionTypes {
  Redirect = 'REDIRECT_URL',
  Label = 'FUNCTION_LABEL',
  ShutDown = 'SHUT_DOWN',
}

export enum DeviceActions {
  NoAction = 'No action',
  ShutDown = 'Shut down the app',
  Redirect = 'Redirect the user to this link',
  Label = 'Define a label that will trigger any linked API functions',
}

export interface IUserThreatNotification {
  trmId: string;
  threatTypeId: number;
  userId: string;
  sendEmail: boolean;
  sendSms: boolean;
}

export interface IActions {
  label: string;
  value: string;
  mdmId: string;
  grouping: string;
  disabled?: boolean;
}

export interface ISelectTRMPolicyTableData {
  id: string;
  threatTypeId: number;
  shouldCollect: boolean;
  severity: number;
  severityString: string;
  alertUser: boolean;
  legacyMdmThreatAction: string | null;
  legacyMdmMitigationAction: string | null;
  mdmThreatTarget: unknown;
  mdmMitigationTarget: unknown;
  mdmThreatAction: unknown;
  mdmMitigationAction: unknown;
  responses: [];
  name: string;
  category: string;
  categoryRaw: 1;
  vector: 'Host' | 'Malware' | 'Network';
  vectorRaw: number;
  selectRow: boolean;
  platform: number[];
  os: string;
  threatNotification?: IUserThreatNotification;
  customResponses: ICustomResponse[] | null;
  description?: string;
}

export interface ITRMRules {
  [key: string]: ITRMRule;
}

export interface ITRMRule {
  id: string;
  threatTypeId: number;
  shouldCollect: boolean;
  selectRow?: boolean;
  severity: number;
  severityString: string;
  alertUser: true;
  legacyMdmThreatAction: unknown;
  legacyMdmMitigationAction: unknown;
  mdmThreatTarget: unknown;
  mdmMitigationTarget: unknown;
  responses: [];
  mdmThreatAction: unknown;
  mdmMitigationAction: unknown;
}

export interface IMDMThreatActions {
  label: string;
  value: string;
  grouping: string;
  mdmId: string;
}

export interface IMDMMitigationActions extends IMDMThreatActions {}

export interface ITRMGroups {
  id: string;
  accountId: string;
  name: string;
  description: string;
  emmConnectionId: string;
  emmGroupId: string;
  emmPriority: number;
  created: Date;
  modified: Date;
  staticFilesWritten: Date;
  zapps: [];
  userActivations: [];
  groupActivations: [];
  global: boolean;
  emm: boolean;
}

export interface ICategory {
  label: string;
  value: string;
}

export interface IVector {
  id: string;
  label: string;
}

export interface ITableHeaderCheckbox {
  shouldCollect?: boolean;
  selectRow?: boolean;
  alertUser?: boolean;
}

interface IUserActivation {
  id: string;
}

export interface IMDMActionsList {
  threatActions: IActions[];
  mitigationActions: IActions[];
}

export interface ICustomResponse {
  type: DeviceActionTypes;
  argument: string | null;
  id?: string;
}

export interface ITRMPolicy {
  id: string;
  accountId: string;
  groups: {
    id: string;
    accountId: string;
    name: string;
    description: string;
    created: Date;
    modified: Date;
    staticFilesWritten: Date;
    zapps: unknown;
    userActivations: IUserActivation[];
    groupActivations: unknown;
    global: boolean;
    emm: boolean;
  }[];
  name: string;
  rules: ISelectTRMPolicyTableData[];
  created?: Date;
  modified?: Date;
  staticFilesWritten?: Date;
  androidJsonHash: string;
  androidProtoHash: string;
  iosJsonHash: string;
  iosProtoHash: string;
  isDeployed: boolean;
  deploymentDate?: Date;
  emm: boolean;
  assigned: boolean;
  global: boolean;
  connection: {
    actions: IMDMActionsList[];
  };
}

export interface IOSResponse {
  id: number;
  status: number;
  categories: number[];
  operatingSystems: number[];

  [key: string]: number | number[];
}
export interface IOSResponseTypes {
  androidBucket: IOSResponse;
  iosBucket: IOSResponse;
  knoxBucket: IOSResponse;
  linuxBucket: IOSResponse;
  extensionsBucket: IOSResponse;

  [key: string]: IOSResponse;
}
