export enum EInsightsDashboardOSType {
  ANDROID = 'Android',
  IOS = 'iOS',
}

export interface IDeviceStats {
  androidNotUpgradeableCount: number;
  androidUpgradeableCount: number;
  androidVulnerableDeviceCount: number;
  iosNotUpgradeableCount: number;
  iosUpgradeableCount: number;
  iosVulnerableDeviceCount: number;
  totalAndroidDeviceCount: number;
  totalIosDeviceCount: number;
  totalNotUpgradeableCount: number;
  totalUndeterminedUpgradeable: number;
  totalUpgradeableCount: number;
  totalVulnerableDeviceCount: number;
}
