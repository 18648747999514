import { IHorizontalLegendItem } from 'components/UI/Chart/HorizontalLegend/models';
import { useTranslation } from 'react-i18next';
import useAsyncResult from 'utils/useAsyncResult';
import RiskCount from './RiskCount';
import TopApps from './TopApps';
import { Stats } from './models';
import useStyles from './useStyles';
import { fetchStats } from './utils';

const AppInventoryDashboardTab: React.FC = () => {
  const classes = useStyles();
  const { t, ready } = useTranslation();

  const stats = useAsyncResult<Stats>(fetchStats);

  if (!ready) {
    return null;
  }

  const generateSegments = ({
    highMediumLowValues,
    platformValues,
  }: {
    platformValues?: {
      ios: number;
      android: number;
    };
    highMediumLowValues?: {
      high: number;
      medium: number;
      low: number;
    };
  }): IHorizontalLegendItem[] => {
    return highMediumLowValues
      ? [
          {
            color: '#f50057',
            label: t('GLOBAL.HIGH'),
            value: highMediumLowValues?.high || 0,
          },
          {
            color: '#ff9f1e',
            label: t('GLOBAL.MEDIUM'),
            value: highMediumLowValues?.medium || 0,
          },
          {
            color: '#4cae50',
            label: t('GLOBAL.LOW'),
            value: highMediumLowValues?.low || 0,
          },
        ]
      : [
          {
            color: '#4a90e2',
            label: t('GLOBAL.IOS'),
            value: platformValues?.ios || 0,
          },
          {
            color: '#7ed321',
            label: t('GLOBAL.ANDROID'),
            value: platformValues?.android || 0,
          },
        ];
  };

  const privacyRiskStats: IHorizontalLegendItem[] = generateSegments({
    highMediumLowValues: {
      high: stats?.privacyHighCount,
      medium: stats?.privacyMediumCount,
      low: stats?.privacyLowCount,
    },
  });

  const securityRiskStats: IHorizontalLegendItem[] = generateSegments({
    highMediumLowValues: {
      high: stats?.securityHighCount,
      medium: stats?.securityMediumCount,
      low: stats?.securityLowCount,
    },
  });

  const maliciousAppsStats: IHorizontalLegendItem[] = generateSegments({
    platformValues: {
      ios: stats?.iosMaliciousAppsCount,
      android: stats?.androidMaliciousAppsCount,
    },
  });

  const highRiskAppsStats: IHorizontalLegendItem[] = generateSegments({
    platformValues: {
      ios: stats?.highPrivacyOrSecurityApps?.ios,
      android: stats?.highPrivacyOrSecurityApps?.android,
    },
  });

  const devicesWithMaliciousAppsStats: IHorizontalLegendItem[] =
    generateSegments({
      platformValues: {
        ios: stats?.devicesWithMaliciousApps?.ios,
        android: stats?.devicesWithMaliciousApps?.android,
      },
    });

  /* NOTE: uncomment to show the DEVICES_WITH_HIGH_RISK_APPS widget */
  // const devicesWithHighRiskAppsStats: IHorizontalLegendItem[] =
  //   generateSegments({
  //     platformValues: {
  //       ios: stats?.devicesWithHighRiskApps?.ios,
  //       android: stats?.devicesWithHighRiskApps?.android,
  //     },
  //   });

  return (
    <div className={classes.row}>
      <div className={classes.column}>
        <RiskCount
          riskCategory={t('MTD.APP_INVENTORY.DASHBOARD.PRIVACY_RISK')}
          riskStats={privacyRiskStats}
          stats={stats}
          summarySuffix={t('GLOBAL.APPS')}
        />
      </div>
      <div className={classes.column}>
        <RiskCount
          riskCategory={t('MTD.APP_INVENTORY.DASHBOARD.SECURITY_RISK')}
          riskStats={securityRiskStats}
          stats={stats}
          summarySuffix={t('GLOBAL.APPS')}
        />
      </div>
      <div className={classes.column}>
        <RiskCount
          riskCategory={t('MTD.APP_INVENTORY.DASHBOARD.MALICIOUS_APPS')}
          riskStats={maliciousAppsStats}
          stats={stats}
          summarySuffix={t('GLOBAL.APPS')}
        />
      </div>
      <div className={classes.column}>
        <RiskCount
          riskCategory={t('MTD.APP_INVENTORY.DASHBOARD.HIGH_RISK_APPS')}
          riskStats={highRiskAppsStats}
          stats={stats}
          summarySuffix={t('GLOBAL.APPS')}
        />
      </div>
      <div className={classes.column}>
        <RiskCount
          riskCategory={t(
            'MTD.APP_INVENTORY.DASHBOARD.DEVICES_WITH_MALICIOUS_APPS'
          )}
          riskStats={devicesWithMaliciousAppsStats}
          stats={stats}
          summarySuffix={t('GLOBAL.DEVICES')}
        />
      </div>
      {/* NOTE: uncomment to show the DEVICES_WITH_HIGH_RISK_APPS widget */}
      {/* <div className={classes.column}}>
        <RiskCount
          riskCategory={t(
            'MTD.APP_INVENTORY.DASHBOARD.DEVICES_WITH_HIGH_RISK_APPS'
          )}
          riskStats={devicesWithHighRiskAppsStats}
          stats={stats}
          summarySuffix={t('GLOBAL.DEVICES')}
        />
      </div> */}
      <div className={classes.column}>
        <TopApps
          topAppsByCategory={stats?.topFiveMaliciousApps}
          category={t('MTD.APP_INVENTORY.DASHBOARD.TOP_MALICIOUS_APPS')}
        />
      </div>

      <div className={classes.column}>
        <TopApps
          topAppsByCategory={stats?.topFiveHighSecurityRiskApps}
          category={t('MTD.APP_INVENTORY.DASHBOARD.TOP_SECURITY_RISK_APPS')}
        />
      </div>

      <div className={classes.column}>
        <TopApps
          topAppsByCategory={stats?.topFiveHighPrivacyRiskApps}
          category={t('MTD.APP_INVENTORY.DASHBOARD.TOP_PRIVACY_RISK_APPS')}
        />
      </div>
    </div>
  );
};

export default AppInventoryDashboardTab;
