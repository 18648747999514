import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ config }: any) =>
  createStyles({
    root: {
      alignItems: 'center',
      backgroundColor: config.colors.darkGreySoft,
      borderRadius: '50%',
      display: 'flex',
      height: '53px',
      justifyContent: 'center',
      padding: '5px',
      width: '53px',
    },
    noBackground: {
      backgroundColor: 'transparent',
    },
    blueBorder: {
      border: `1px solid ${config.colors.ios}`,
    },
    darkGreenBorder: {
      border: `1px solid ${config.colors.green}`,
    },
    grayIcon: {
      color: 'gray',
    },
    greenBorder: {
      border: `1px solid ${config.colors.android}`,
    },
    icon: {
      height: '100%',
      width: '100%',
    },
    orangeBorder: {
      border: `1px solid ${config.colors.elevated}`,
    },
    redBorder: {
      border: `1px solid ${config.colors.critical}`,
    },
    whiteIcon: {
      color: 'white',
    },
  })
);

export default useStyles;
