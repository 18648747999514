import React from 'react';

// console
import AuditLog from 'components/main/manage/AuditLog/AuditLog';
import Authorization from 'components/main/manage/Authorization/Authorization';
import DataExport from 'components/main/manage/DataExport';
import EditAccountProfile from 'components/main/manage/EditAccountProfile';
import PasswordPolicy from 'components/main/manage/PasswordPolicy';
import SmtpSettings from 'components/main/manage/SmtpSettings';
import SSOConfiguration from 'components/main/manage/SSOConfiguration';
import Users from 'components/main/users/Users';

// icons
import AuditIcon from '@material-ui/icons/FormatAlignLeft';
import AuthIcon from '@material-ui/icons/Fingerprint';
import DataExportIcon from '@material-ui/icons/ImportExport';
import GeneralIcon from '@material-ui/icons/ViewList';
import LockIcon from '@material-ui/icons/Lock';
import MailIcon from '@material-ui/icons/Mail';
import SSOIcon from '@material-ui/icons/VpnKey';
import SupportIcon from '@material-ui/icons/ContactSupport';
import UsersIcon from '@material-ui/icons/SupervisorAccount';

const rqps = ['page', 'size', 'order', 'orderBy'];

export const CONSOLE_MANAGE_ROUTES = [
  {
    path: '/console/manage',
    component: EditAccountProfile,
    allow: { account: 'view' },
    exact: true,
    nav: {
      name: 'General',
      order: 1,
      icon: <GeneralIcon />,
    },
  },
  {
    path: '/console/manage/users/:tabId/new',
    component: Users,
    exact: true,
    allow: { users: 'view' },
  },
  {
    path: '/console/manage/users/:tabId/:action?',
    component: Users,
    allow: { users: 'view', roles: 'view', teams: 'view' },
  },
  {
    path: '/console/manage/users/users',
    component: Users,
    allow: { users: 'view' },
    exact: true,
    nav: {
      name: 'Users',
      order: 2,
      icon: <UsersIcon />,
    },
  },
  {
    path: '/console/manage/authorizations',
    component: Authorization,
    exact: true,
    allow: { api_keys: 'view' },
    rqps,
    nav: {
      name: 'Authorizations',
      order: 3,
      icon: <AuthIcon />,
    },
  },
  {
    path: '/console/manage/sso',
    component: SSOConfiguration,
    exact: true,
    allow: { account: 'manage' },
    nav: {
      name: 'SSO',
      order: 4,
      icon: <SSOIcon />,
    },
  },
  {
    path: '/console/manage/data-export',
    component: DataExport,
    exact: true,
    allow: { data_exports: 'view' },
    rqps,
    nav: {
      name: 'Data Export',
      order: 5,
      icon: <DataExportIcon />,
    },
  },
  {
    path: '/console/manage/smtp-settings',
    component: SmtpSettings,
    exact: true,
    allow: { account: 'manage' },
    nav: {
      name: 'SMTP',
      order: 6,
      icon: <MailIcon />,
    },
  },
  {
    path: '/console/manage/password-policy',
    component: PasswordPolicy,
    exact: true,
    allow: { account: 'manage' },
    nav: {
      name: 'Password Policy',
      order: 7,
      icon: <LockIcon />,
    },
  },
  {
    path: '/console/manage/audit-log',
    component: AuditLog,
    exact: true,
    allow: { audit_log: 'view' },
    rqps,
    nav: {
      name: 'Audit Log',
      order: 8,
      icon: <AuditIcon />,
    },
  },
  // {
  //   path: '/console/manage/mdm',
  //   component: MDM,
  //   exact: true,
  //   allow: { emm: 'view' },
  //   nav: {
  //     name: 'EMM',
  //     order: 10,
  //     icon: <DataExportIcon />,
  //   },
  // },
];
