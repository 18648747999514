import { Theme } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import classnames from 'classnames';
import { ja as dateFnsJa } from 'date-fns/locale';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteCalendar, { Calendar, withRange } from 'react-infinite-calendar';
import { IRangeSelectEvent } from './models';
import useStyles from './useStyles';

interface IRangeCalendarProps {
  className: string;
  end: Date;
  onPresetSelect: (value: number) => void;
  onRangeInitialSelect: (range: { from: Date | null; to: Date | null }) => void;
  onRangeSelect: (range: { from: Date | null; to: Date | null }) => void;
  rangePresets: { name: string; ordinal: number }[];
  rootCalendarProps: object;
  selectedPreset: number;
  start: Date;
}
const CalendarWithRange = withRange(Calendar);

const RangeCalendar: React.FC<IRangeCalendarProps> = ({
  className,
  end,
  onPresetSelect,
  onRangeInitialSelect,
  onRangeSelect,
  rangePresets: presets = [],
  rootCalendarProps = {},
  selectedPreset,
  start,
}) => {
  const initialClasses = useStyles();
  const isAllTime = selectedPreset === 9;
  const classes = classnames(className, initialClasses.root, {
    [initialClasses.isAllTime]: isAllTime,
  });
  const { t, i18n, ready } = useTranslation();

  const theme: Theme & { palette: { calendar: { native: unknown } } } =
    useTheme();

  const handleRangeSelect = useCallback(
    (event: IRangeSelectEvent) => {
      if (event.eventType === 1 && typeof onRangeInitialSelect === 'function') {
        onRangeInitialSelect({
          from: event.start,
          to: null,
        });
      }

      if (event.eventType !== 3) {
        return;
      }

      if (typeof onRangeSelect === 'function') {
        onRangeSelect({
          from: event.start,
          to: event.end,
        });
      }
    },
    [onRangeSelect, onRangeInitialSelect]
  );

  const handlePresetSelect = useCallback(
    (value: number) => {
      if (typeof onPresetSelect === 'function') {
        onPresetSelect(value);
      }
    },
    [onPresetSelect]
  );

  const dateFn = useMemo(
    () =>
      (i18n?.language?.substring(0, 2) ?? 'en') === 'ja'
        ? dateFnsJa
        : undefined,
    [i18n?.language]
  );

  if (!ready) {
    return null;
  }

  return (
    <div className={classes}>
      <div className="calendar-container">
        <InfiniteCalendar
          Component={CalendarWithRange}
          onSelect={handleRangeSelect}
          selected={isAllTime ? false : { start, end }}
          theme={theme?.palette?.calendar?.native as undefined} // poorly defined interface in @types/react-infinite-calendar
          {...rootCalendarProps}
          locale={{
            blank: t('RANGE_PICKER.SELECT_A_DATE'),
            weekdays: [
              t('RANGE_PICKER.DAYS_MIN.SUN'),
              t('RANGE_PICKER.DAYS_MIN.MON'),
              t('RANGE_PICKER.DAYS_MIN.TUE'),
              t('RANGE_PICKER.DAYS_MIN.WED'),
              t('RANGE_PICKER.DAYS_MIN.THU'),
              t('RANGE_PICKER.DAYS_MIN.FRI'),
              t('RANGE_PICKER.DAYS_MIN.SAT'),
            ],
            todayLabel: {
              long: t('GLOBAL.TODAY'),
              short: t('GLOBAL.TODAY'),
            } as { long: string }, // poorly defined interface in @types/react-infinite-calendar
            locale: dateFn as undefined, // poorly defined interface in @types/react-infinite-calendar
          }}
        />
      </div>

      {!!presets.length && (
        <div className={initialClasses.presets}>
          {presets.map((preset) => (
            <button
              key={preset.ordinal}
              className={
                preset.ordinal === selectedPreset
                  ? initialClasses.selectedPreset
                  : undefined
              }
              onClick={() => handlePresetSelect(preset.ordinal)}
            >
              {t(preset.name)}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default RangeCalendar;
