import { selectedTeam as selectedTeamAtom } from 'atoms/teams';
import React from 'react';
import { useRecoilValue } from 'recoil';
import useStyles from '../useStyles';
import DevicesThreats from './DevicesThreats';
import PotentialFraudIndicators from './PotentialFraudIndicators';
import RiskiestDeviceModels from './RiskiestDeviceModels';
import TopFiveThreats from './TopFiveThreats';

interface IThreatStatusProps {
  zappId: string | null | number | undefined;
}

const ThreatStatus: React.FC<IThreatStatusProps> = ({ zappId }) => {
  const classes = useStyles();
  const teamId = useRecoilValue(selectedTeamAtom);

  return (
    <div className={classes.dashboard}>
      <div className={classes.row}>
        <DevicesThreats teamId={teamId} zappId={zappId} />
        <RiskiestDeviceModels teamId={teamId} zappId={zappId} />
      </div>
      <div className={classes.row}>
        <PotentialFraudIndicators teamId={teamId} zappId={zappId} />
      </div>
      <div className={classes.row}>
        <TopFiveThreats teamId={teamId} zappId={zappId} />
      </div>
    </div>
  );
};

export default ThreatStatus;
