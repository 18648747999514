import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

// material ui
import AppBar from '@material-ui/core/AppBar';
import MaterialTab from '@material-ui/core/Tab';
import MaterialTabs from '@material-ui/core/Tabs';

import ProtectedRoute from 'routes/ProtectedRoute';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import DeviceActivations from './DeviceActivations';
import GroupActivations from './GroupActivations';
import { useTranslation } from 'react-i18next';

// components

const LinkTab = ({ allow, ...route }) => {
  return (
    <ProtectedComponent allow={allow}>
      <MaterialTab className="tabs-label" component={Link} {...route} />
    </ProtectedComponent>
  );
};

const Activations = withStyles({
  tabsAppBar: {
    marginTop: 15,
    marginBottom: 15,
    background: 'none',
    boxShadow: 'none',
    position: 'relative',
    zIndex: 1,
  },
})(({ match, ...props }) => {

  const { t, ready } = useTranslation();
  
   if (!ready) {
    return null;
  }

  return (
    <div>
      <AppBar position="static" className={props.classes.tabsAppBar}>
        <MaterialTabs value={match.params.tabId}>
          <LinkTab
            disableRipple
            key="device-activations-link-1"
            label={t("MTD.ACTIVATIONS.END_USER_ACTIVATIONS")}
            to="/console/mtd/activations/device-activations"
            value="device-activations"
            allow={{ zapps: 'view' }}
          />
          <LinkTab
            disableRipple
            key="group-activations-link-2"
            label={t("MTD.ACTIVATIONS.GROUP_ACTIVATIONS")}
            to="/console/mtd/activations/group-activations"
            value="group-activations"
            allow={{ zapps: 'view' }}
          />
        </MaterialTabs>
      </AppBar>
      {renderSubRoute(match.params.tabId)}
    </div>
  );
});

function renderSubRoute(tabId) {
  switch (tabId) {
    case 'device-activations':
      return (
        <ProtectedRoute
          allow={{ zapps: 'view' }}
          component={DeviceActivations}
          rqps={['page', 'size', 'orderBy', 'order']}
        />
      );
    case 'group-activations':
      return (
        <ProtectedRoute
          allow={{ zapps: 'view' }}
          component={GroupActivations}
          rqps={['page', 'size', 'orderBy', 'order']}
        />
      );
    default:
      return (
        <ProtectedRoute
          allow={{ zapps: 'view' }}
          component={DeviceActivations}
        />
      );
  }
}

export default Activations;
