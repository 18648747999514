import cc from 'classcat';
import DoughnutChart from 'components/UI/Chart/Doughnut';
import { IHorizontalLegendItem } from 'components/UI/Chart/HorizontalLegend/models';
import GenericCard from 'components/UI/GenericCard';
import { useMemo } from 'react';
import useAsyncResult from 'utils/useAsyncResult';
import { default as useSharedStyles } from '../../useStyles';
import { IDevicePool } from './models';
import useStyles from './useStyles';
import { fetchDevicePool } from './utils';

interface IDevicePoolProps {
  teamId: string | null | undefined;
  zappId: string | null | number | undefined;
}

const DevicePool: React.FC<IDevicePoolProps> = ({ teamId, zappId }) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  const devicePoolData = useAsyncResult<IDevicePool>(
    fetchDevicePool,
    teamId ?? '',
    zappId ?? ''
  );

  const devicePool: IHorizontalLegendItem[] = useMemo(
    () => [
      {
        color: '#2a95cc',
        label: 'iOS',
        link: `/console/zdefend/devices/?duration=8&os.name=iOS&zappId=${
          zappId ?? 'All'
        }`,
        order: 0,
        value: devicePoolData?.devicePoolSdk?.iosDevices ?? 0,
      },
      {
        color: '#4cae50',
        label: 'Android',
        link: `/console/zdefend/devices/?duration=8&os.name=Android&zappId=${
          zappId ?? 'All'
        }`,
        order: 1,
        value: devicePoolData?.devicePoolSdk?.androidDevices ?? 0,
      },
    ],
    [devicePoolData, zappId]
  );

  const hasData = useMemo(
    () =>
      typeof devicePoolData?.devicePoolSdk?.iosDevices === 'number' &&
      typeof devicePoolData?.devicePoolSdk?.androidDevices === 'number' &&
      (devicePoolData.devicePoolSdk.iosDevices > 0 ||
        devicePoolData.devicePoolSdk.androidDevices > 0),
    [devicePoolData]
  );

  return (
    <GenericCard className={cc([sharedClasses.card, classes.devicePoolCard])}>
      <h2>Device Pool</h2>
      <div className={sharedClasses.column}>
        {hasData ? (
          <DoughnutChart
            forceProportionate
            order
            segments={devicePool}
            summarySuffix="devices"
            withLegend
          />
        ) : (
          <div className={sharedClasses.pending}>N/A</div>
        )}
      </div>
    </GenericCard>
  );
};

export default DevicePool;
