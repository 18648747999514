import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    tree: {
      height: 264,
      flexGrow: 1,
      marginTop: 15,
    },
    root: {
      color: palette.text.secondary,
      '&:hover > $content': {
        backgroundColor: palette.action.hover,
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
    },
    content: {
      color: palette.text.secondary,
      '$expanded > &': {
        fontWeight: 'bold',
      },
    },
    group: {
      marginLeft: 0,
      '& $content': {
        paddingLeft: '10px',
      },
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: '2px 0',
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
    },
    labelInfo: {
      width: '170px',
    }
  })
);

export default useStyles;
