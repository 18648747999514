import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
// import Tooltip from '@material-ui/core/Tooltip';
import GlobalIndicatorWrapper from 'components/main/common/GlobalIndicatorWrapper';
import { useTranslation } from 'react-i18next';

const styles = ({ palette, config }) => {
  return {
    wrapper: {
      background: palette.background.paper,
      outline: 'none',
      maxWidth: '70vw',
      maxHeight: 400,
      overflowY: 'auto',
    },
    tableCell: {
      borderBottomColor: 'transparent',
    },
    tableHeaderCell: {
      color: palette.text.secondary,
      fontWeight: 'bold',
      borderBottomColor: 'transparent',
    },
    tableRow: {
      height: config.textSizes.paragon,
    },
    emptyText: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '5vh',
    },
  };
};

function MDMGroupsInfo(props) {
  const { classes, groupsInfo } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <Table>
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableHeaderCell}>
              {t('GLOBAL.GROUP_NAME')}
            </TableCell>
            <TableCell className={classes.tableHeaderCell}>
              {t('GLOBAL.THREAT_POLICY')}
            </TableCell>
            <TableCell className={classes.tableHeaderCell}>
              {t('GLOBAL.PRIVACY_POLICY')}
            </TableCell>
            <TableCell className={classes.tableHeaderCell}>
             {t('GLOBAL.PHISHING_POLICY')}
            </TableCell>
            <TableCell className={classes.tableHeaderCell}>
              {t('GLOBAL.APP_SETTINGS')}
            </TableCell>
            <TableCell className={classes.tableHeaderCell}>
              {t('GLOBAL.DEVICE_INACTIVITY_POLICY')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groupsInfo.rowData.connectedGroups.map((group, index) => {
            return (
              <TableRow key={index} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>
                  {_.get(group, 'singleGroup.groupIdsToSync.label', 'N/A')}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Link
                    to={`/console/mtd/policies/trm?trm=${_.get(
                      group,
                      'singleGroup.trmPolicy.value',
                      'N/A'
                    )}`}
                    style={{ color: 'inherit' }}
                  >
                    <div className="cardtable-cell__ellipsis">
                      <GlobalIndicatorWrapper
                        // isGlobal={rowData.trmGlobal}
                        text={_.get(
                          group,
                          'singleGroup.trmPolicy.label',
                          'N/A'
                        )}
                      />
                    </div>
                  </Link>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Link
                    to={`/console/mtd/policies/privacy?privacy=${_.get(
                      group,
                      'singleGroup.privacyPolicy.value',
                      'N/A'
                    )}`}
                    style={{ color: 'inherit' }}
                  >
                    <div className="cardtable-cell__ellipsis">
                      <GlobalIndicatorWrapper
                        // isGlobal={rowData.trmGlobal}
                        text={_.get(
                          group,
                          'singleGroup.privacyPolicy.label',
                          'N/A'
                        )}
                      />
                    </div>
                  </Link>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Link
                    to={`/console/mtd/policies/phishing?phishing=${_.get(
                      group,
                      'singleGroup.phishingPolicy.value',
                      'N/A'
                    )}`}
                    style={{ color: 'inherit' }}
                  >
                    <div className="cardtable-cell__ellipsis">
                      <GlobalIndicatorWrapper
                        // isGlobal={rowData.trmGlobal}
                        text={_.get(
                          group,
                          'singleGroup.phishingPolicy.label',
                          'N/A'
                        )}
                      />
                    </div>
                  </Link>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Link
                    to={`/console/mtd/policies/appsettings?appsettings=${_.get(
                      group,
                      'singleGroup.appSettings.value',
                      'N/A'
                    )}`}
                    style={{ color: 'inherit' }}
                  >
                    <div className="cardtable-cell__ellipsis">
                      <GlobalIndicatorWrapper
                        // isGlobal={rowData.trmGlobal}
                        text={_.get(
                          group,
                          'singleGroup.appSettings.label',
                          'N/A'
                        )}
                      />
                    </div>
                  </Link>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Link
                    to={`/console/mtd/policies/dormancy?dormancy=${_.get(
                      group,
                      'singleGroup.dormancyPolicy.value',
                      'N/A'
                    )}`}
                    style={{ color: 'inherit' }}
                  >
                    <div className="cardtable-cell__ellipsis">
                      <GlobalIndicatorWrapper
                        // isGlobal={rowData.trmGlobal}
                        text={_.get(
                          group,
                          'singleGroup.dormancyPolicy.label',
                          'N/A'
                        )}
                      />
                    </div>
                  </Link>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

export default withStyles(styles)(MDMGroupsInfo);
