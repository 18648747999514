import { useEffect, useState } from 'react';
import { fetchPolicyGroups } from './Form/utils';
import { ISelectItem } from 'components/UI/input/Select';
import { IGroupsHash } from 'components/main/zShieldApps/ZShieldAppDrawer/models';

export default (teamId?: string) => {
  const [groupList, setGroupList] = useState<ISelectItem[]>([]);
  const [groupsHash, setGroupsHash] = useState<IGroupsHash>({});

  useEffect(() => {
    const fetchAvailableGroupsByTeamId = async () => {
      if (!teamId) {
        return setGroupList([]);
      }
      const data = await fetchPolicyGroups(teamId);
      const groupsHash = data?.reduce((accum, singleGroup) => ({ ...accum, [singleGroup.value]: singleGroup?.label }), {});
      setGroupsHash(groupsHash ?? {});
      return setGroupList(data || []);
    };
    fetchAvailableGroupsByTeamId();
  }, [teamId]);

  return { availableGroups: groupList, groupsHash };
};
