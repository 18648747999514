import React, { PureComponent } from 'react';
import _ from 'lodash';

// services
import { deleteSelectedUser } from 'api/UserTableService';

// components
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ZButton from 'UI/Buttons/ZButton';

// utils
import {
  toggleModalDirect,
  openSnackBar,
  toggleModalDiffered,
} from 'utils/storeUtils';

class UserDeleteConfirm extends PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const { props } = this;

    const username = `${props.data.rowData.firstName} ${
      props.data.rowData.lastName
    }`;

    return (
      <DialogContent>
        <p>Are you sure you want to delete the user "{username}" ?</p>
        <DialogActions>
          <ZButton
            styleName="modalCancel"
            action={toggleModalDiffered('UserDeleteConfirm', false)}
            color="secondary"
            buttonText="Cancel"
          />
          <ZButton
            action={this.handleSubmit}
            buttonType="submit"
            color="primary"
            styleName="modalSave"
            buttonText="Delete User"
          />
        </DialogActions>
      </DialogContent>
    );
  }

  handleSubmit() {
    const { props } = this;

    deleteSelectedUser(props.data.rowData.id)
      .then(() => {
        toggleModalDirect('UserDeleteConfirm', false);
        openSnackBar('User successfully deleted');
        props.data.fetchUsersTablePage();
      })
      .catch(error => {
        toggleModalDirect('UserDeleteConfirm', false);
        openSnackBar(`Failed to delete user: ${_.get(error, 'response.data')}`);
      });
  }
}

UserDeleteConfirm.defaultProps = {
  data: {},
};

export default UserDeleteConfirm;
