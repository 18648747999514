import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${palette.background.default}`,
    },
    cardTitle: {
      alignItems: 'center',
      backgroundColor: palette.background.default,
      display: 'flex',
      flexDirection: 'column',
      fontWeight: 'bold',
      justifyContent: 'center',
      padding: '15px 0',
    },
    cardBold: {
      fontSize: 22,
      fontWeight: 'bold',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      padding: '15px 10px',
      gap: '20px',
    },
    header: {
      color: palette.text.contrast,
    },
    dialogRoot: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      gap: '15px',
    },
    ruleTypeLabel: {
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },
    tableActions: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },
    warningLabel: {
      border: '2px solid #2EBDBD',
      padding: '3px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    label: {
      fontSize: 'small',
    },
    iconButton: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '5px',
      cursor: 'pointer',
    },
    iconContainer: {
      borderRadius: '50%',
      border: '1px solid #2EBDBD',
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '65px',
      height: '100%',
    },
    searchIcon: {
      height: '100%',
      maxHeight: 'initial',
      width: 55,
      padding: '0 5px',
      marginLeft: 0,
      justifyContent: 'center',
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translateY(-50%)',
      background: palette.background.paper,
      border: `1px solid ${palette.primary.border}`,

      '& svg': {
        fontSize: 32,
      },
    },
    formGroup: {
      outline: `2px dashed ${palette.primary.main}`,
      outlineOffset: '-10px',
      WebkitTransition:
        'outline-offset .15s ease-in-out, background-color .15s linear',
      transition:
        'outline-offset .15s ease-in-out, background-color .15s linear',
      height: 200,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    selectFormControl: {
      minWidth: 220,
    },
    errorText: {
      color: palette.text.secondary,
    },
    modalActionsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      height: '80px',
    },
    modalActionsIcons: {
      display: 'flex',
      gap: '50px',
      height: '100%',
    },
  })
);

export default useStyles;
