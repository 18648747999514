import {
  csvInjectionErrorMessage,
  csvInjectionRegex,
  looksGenerallyLikeAnEmailRegex,
  looksGenerallyLikeAnEmailRegexErrorMessage,
} from 'utils/componentUtils';
import * as Yup from 'yup';

const Schema = Yup.object().shape({
  appStoreAndroidLink: Yup.string()
    .url()
    .matches(csvInjectionRegex, csvInjectionErrorMessage),
  appStoreIosLink: Yup.string()
    .url()
    .matches(csvInjectionRegex, csvInjectionErrorMessage),
  consoleTitle: Yup.string()
    .min(1)
    .matches(csvInjectionRegex, csvInjectionErrorMessage),
  documentationLink: Yup.string()
    .url()
    .matches(csvInjectionRegex, csvInjectionErrorMessage),
  fromEmailAddress: Yup.string()
    .matches(
      looksGenerallyLikeAnEmailRegex,
      looksGenerallyLikeAnEmailRegexErrorMessage
    )
    .matches(csvInjectionRegex, csvInjectionErrorMessage),
  // mobileAppLink: Yup.string()
  //   .url()
  //   .matches(csvInjectionRegex, csvInjectionErrorMessage),
  mobileAppName: Yup.string()
    .min(1)
    .matches(csvInjectionRegex, csvInjectionErrorMessage),
  privacyPolicyLink: Yup.string()
    .url()
    .matches(csvInjectionRegex, csvInjectionErrorMessage),
  replyToEmailAddress: Yup.string()
    .email()
    .matches(csvInjectionRegex, csvInjectionErrorMessage),
  supportEmailAddress: Yup.string()
    .matches(
      looksGenerallyLikeAnEmailRegex,
      looksGenerallyLikeAnEmailRegexErrorMessage
    )
    .matches(csvInjectionRegex, csvInjectionErrorMessage),
  termsAndConditionsLink: Yup.string()
    .url()
    .matches(csvInjectionRegex, csvInjectionErrorMessage),
  zdefendModuleText: Yup.string()
    .min(1)
    .matches(csvInjectionRegex, csvInjectionErrorMessage),
  mtdModuleText: Yup.string()
    .min(1)
    .matches(csvInjectionRegex, csvInjectionErrorMessage),
  zscanModuleText: Yup.string()
    .min(1)
    .matches(csvInjectionRegex, csvInjectionErrorMessage),
  zshieldModuleText: Yup.string()
    .min(1)
    .matches(csvInjectionRegex, csvInjectionErrorMessage),
});

export default Schema;
