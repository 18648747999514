import _ from 'lodash';

export const tableQueryToLocationQuery = (type, update, querySize) => {
  const result = {};

  if (type === 'limit') {
    result.size = update;
  } else if (type === 'sort') {
    result.orderBy = update.id;
    result.order = update.dir === 1 ? 'asc' : 'desc';
  } else if (type === 'skip') {
    result.page = update / parseInt(querySize);
  }

  return result;
};

/**
 * @param {function} method: the method to wrap w/ memoized functionality
 * @param {number} TTL: time to live in milliseconds
 *
 * If method is called multiple times with the same query within the TTL
 * then the memoized result will be returned. This prevents multiple api
 * calls. It can be overriden by calling the wrapper function with TTL = 0,
 * or calling the memoized function with overrideTTL set to 0.
 */
export const memoizeTableQuery = (method, TTL = 100) => {
  const memoized = {
    query: '',
    result: [],
    timestamp: new Date().getTime(),
  };

  /**
   * @param {object} query: the api query parameters
   * @param {boolean} returnMemoizedResult: force the memoized result to return
   * @param {number} overrideTTL: overrides the TTL set w/ wrapping this method
   */
  return (query, returnMemoizedResult, overrideTTL) => {
    const finalTTL = 'number' === typeof overrideTTL ? overrideTTL : TTL;
    const currentTime = new Date().getTime();

    if (returnMemoizedResult) {
      return memoized.result;
    }

    if (
      finalTTL > 0 &&
      currentTime - memoized.timestamp <= finalTTL &&
      _.isEqual(memoized.query, query)
    ) {
      memoized.timestamp = currentTime;
      return memoized.result;
    }

    memoized.query = query;
    memoized.result = method(query);
    memoized.timestamp = currentTime;

    return memoized.result;
  };
};

export const getNumVisibleColumns = (columnDefs = []) =>
  columnDefs.filter(({ visible } = {}) => visible).length;

export const getLastVisibleColumnIndex = (columnDefs = []) => {
  let result = 0;

  [...columnDefs].reverse().some(({ visible } = {}, index) => {
    result = index;
    return visible;
  });

  return columnDefs.length - result - 1;
};
