import classnames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(({ palette }) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'baseline',
      position: 'relative',
      paddingLeft: 5,

      '&:before': {
        content: '""',
        width: 12,
        height: 12,
        marginRight: 5,
        marginBottom: 3,
        borderRadius: '50%',
        backgroundColor: palette.success.main,
      },
    },

    absolute: {
      '&:before': {
        position: 'absolute',
        right: '100%',
        transform: 'translate(-1px, 1px)',
      },
    },

    noMarker: {
      '&:before': {
        display: 'none',
      },
    },
  };
});
const GlobalIndicator = (props) => {
  const baseClasses = useStyles();
  const classes = classnames(baseClasses.root, {
    [baseClasses.absolute]: !props.inline,
    [baseClasses.noMarker]: !props.isGlobal,
  });
  const { t, i18n } = useTranslation();
  if (props?.children) {
    return <div className={classes}>{props.children}</div>;
  }

  return (
    <span className={classes}>{props?.showText ? t(props?.text) : ''}</span>
  );
};

GlobalIndicator.defaultProps = {
  inline: false,
  isGlobal: false,
  showMarker: true,
  showText: true,
  text: 'GLOBAL.GLOBAL',
};

export default GlobalIndicator;
