import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    inDropdown: {
      display: 'flex',
      textAlign: 'center',
      marginRight: '-30px',
      alignItems: 'center',
      fontSize: '14px',
    },
    ddIcon: {
      cursor: 'pointer',
      marginLeft: '15px',
    },
    cardRoot: {
      padding: 10,
      width: '40%',
      textAlign: 'center',
    },
  })
);

export default useStyles;
