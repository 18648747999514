import {
  getAffectedDevicesByApps,
  getAffectedDevicesByTriggers,
} from 'api/AppPolicyService';
import { IQueryParams, TTableHeaderHelperArgList } from './models';
import { IAffectedDevicesPostPayload } from './models';
import { IAffectedAppsPostPayload } from '../../APCCreatePolicyModal/models';

export const fetchTableDataByApps = async (
  query: IQueryParams,
  affectedDevicesPostDataPayload: IAffectedDevicesPostPayload,
  setTotalDataCount: (count: number) => void
) => {
  let result;

  try {
    result = await getAffectedDevicesByApps(
      { ...query },
      affectedDevicesPostDataPayload
    );
    setTotalDataCount(result?.data?.content?.length);
  } catch (e) {
    console.error(e);
  }
  return result;
};
export const fetchTableDataByTriggers = async (
  query: IQueryParams,
  affectedAppsPostDataPayload: IAffectedAppsPostPayload,
  setTotalDataCount: (count: number) => void
) => {
  let result;

  try {
    result = await getAffectedDevicesByTriggers(
      { ...query },
      affectedAppsPostDataPayload
    );
    setTotalDataCount(result?.data?.content?.length);
  } catch (e) {
    console.error(e);
  }
  return result;
};

export const tableHeaderHelper = (argumentList: TTableHeaderHelperArgList) => {
  const columnData = argumentList[2];
  const indexChosen = argumentList[1];
  const value = argumentList[0];
  const replacedColumns = columnData.slice();
  replacedColumns[indexChosen].show = value;
  return replacedColumns;
};
