import { cloneAppPolicy, createAppPolicy } from 'api/AppPolicyService';
import {
  clonePolicy as cloneBranding,
  createPolicy as createBranding,
} from 'api/BrandingPolicyService';
import {
  cloneDormacyPolicy,
  createDormancyPolicy,
} from 'api/DormancyPolicyService';
import {
  clonePolicy as cloneNetwork,
  createPolicy as createNetwork,
} from 'api/NetworkPolicyService';
import {
  clonePolicy as cloneOsRisk,
  createPolicy as createOsRisk,
} from 'api/OSRiskService';
import {
  cloneSelectedPhishingPolicy,
  createPhishingPolicy,
} from 'api/PhishingPolicyService';
import {
  cloneSelectedPrivacyPolicy,
  createPrivacyPolicy,
} from 'api/PrivacyPolicyService';
import { getActiveModalAtom } from 'atoms/modals';
import AlertIcon from 'components/UI/icons/AlertIcon';
import Modal from 'components/UI/Modal';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { openSnackBar } from 'utils/storeUtils';
import Form from './Form';
import { IAppPolicyCreateForm, IAppPolicyCreateModalData } from './models';
import useStyles from './useStyles';
import { cloneSelectedTRMPolicy, createTRMPolicy } from 'api/TRMPolicyService';

export const AddClonePolicy_TITLE = 'AddClonePolicy';

const activeModalAtom = getActiveModalAtom<IAppPolicyCreateModalData>();

const AddClonePolicy: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [externalError, setExternalError] = useState<string | unknown | any>();
  const [activeModal, setActiveModal] = useRecoilState(activeModalAtom);

  const handleClose = useCallback(() => {
    setExternalError(undefined);
    setActiveModal(undefined);
  }, [setExternalError, setActiveModal]);

  const handleSubmit = async ({
    name,
    teamId,
    bounds,
  }: IAppPolicyCreateForm) => {
    try {
      const policyType = activeModal?.payload?.policyType;
      const cloningPolicy = activeModal?.payload?.cloningPolicy;
      const payload = {
        name,
        teamId: bounds === 'team' ? teamId : '',
      };
      switch (policyType) {
        case 'App':
          if (cloningPolicy) {
            await cloneAppPolicy({ policyId: cloningPolicy }, payload);
          } else {
            await createAppPolicy(null, payload);
          }
          break;
        case 'Privacy':
          if (cloningPolicy) {
            await cloneSelectedPrivacyPolicy({ id: cloningPolicy }, payload);
          } else {
            await createPrivacyPolicy({}, payload);
          }
          break;
        case 'Network':
          if (cloningPolicy) {
            cloneNetwork(cloningPolicy, payload);
          } else {
            createNetwork(payload);
          }
          break;
        case 'Branding':
          if (cloningPolicy) {
            await cloneBranding(cloningPolicy, payload);
          } else {
            await createBranding(payload);
          }
          break;
        case 'OsRisk':
          if (cloningPolicy) {
            await cloneOsRisk({ ...payload, policyIdToClone: cloningPolicy });
          } else {
            await createOsRisk(payload);
          }
          break;
        case 'Phishing':
          if (cloningPolicy) {
            await cloneSelectedPhishingPolicy({ id: cloningPolicy }, payload);
          } else {
            await createPhishingPolicy({}, payload);
          }
          break;
        case 'Threat':
          if (cloningPolicy) {
            await cloneSelectedTRMPolicy(
              {
                id: cloningPolicy,
              },
              payload
            );
          } else {
            await createTRMPolicy({}, payload);
          }
          break;
        case 'Dormancy':
          if (cloningPolicy) {
            await cloneDormacyPolicy({ id: cloningPolicy }, payload);
          } else {
            await createDormancyPolicy({}, payload);
          }
          break;
        default:
          break;
      }

      if (cloningPolicy) {
        openSnackBar(
          t('MTD.POLICIES.POLICY_SUCCESS_CLONE', {
            policyName: t('GLOBAL.POLICY'),
          })
        );
      } else {
        openSnackBar(
          t('MTD.POLICIES.POLICY_SUCCESS_CREATE', {
            policyName: t('GLOBAL.POLICY'),
          })
        );
      }
      activeModal?.payload?.onCreateSuccess(name);
      setActiveModal(undefined);
    } catch (error: any) {
      setExternalError(
        error?.response?.status === 409 || error?.response?.status === 400
          ? error?.response?.data
          : t('GLOBAL.UNKNOWN_ERROR_TRY_AGAIN')
      );
    }
  };

  return (
    <Modal
      caption={
        activeModal?.payload?.cloningPolicy
          ? t('MTD.POLICIES.CLONE_EXISTING')
          : t('MTD.POLICIES.CREATE_NEW_POLICY')
      }
      title={AddClonePolicy_TITLE}
      onClose={handleClose}
    >
      {!!externalError && (
        <div className={classes.errors}>
          <span>
            {externalError?.error.match(/conflict/i)
              ? t('MTD.POLICIES.THERE_IS_ALREADY_POLICY_SELECT_NEW')
              : externalError?.message ?? externalError}
          </span>
          <AlertIcon />
        </div>
      )}
      <Form
        cloningPolicy={activeModal?.payload?.cloningPolicy}
        defaultName={activeModal?.payload?.defaultName}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
    </Modal>
  );
};

export default AddClonePolicy;
