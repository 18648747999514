import _ from 'lodash';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import GenericError from 'components/UI/GenericErrorBox';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Uploader extends Component {
  state = {
    errorMessage: null,
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  render() {
    const { t } = this.props;
    const { state } = this;
    const {
      label = t('GLOBAL.CHOOSE_FILE'),
      uploadPrompt = t('GLOBAL.OR_DROP_FILE'),
      getFileData: getFileDataFromProps,
      ...props
    } = this.props;
    const classes = {
      ...props.classes,
      ...props.classList,
    };

    return (
      <div className={classes.container}>
        <Typography className={classes.fileButton}>{label}</Typography>
        <Typography color="primary" className={classes.uploadPrompt}>{uploadPrompt}</Typography>
        {!state.errorMessage && (
          <input
            {...props}
            type="file"
            className={classes.hideFile}
            onChange={this.onChange}
            onDrop={this.onDrop}
          />
        )}
        {!!state.errorMessage && (
          <div className={classes.errorBox}>
            <GenericError
              errorMessage={state.errorMessage}
              showCloseIcon
              onClose={() => this.setState({ errorMessage: null })}
            />
          </div>
        )}
      </div>
    );
  }

  onChange(event) {
    const { props } = this;
    const file = _.get(event.target, 'files[0]', { name: '' });
    const extension = this.getFileExtension(file);
    const isValidExtension = this.validateExtension(extension);

    if (!isValidExtension) {
      return;
    }

    if (props.accept === '.json') {
      const reader = new FileReader();
      reader.readAsText(event.target.files[0]);
      reader.onload = (e) => {
        props.getFileData(file.name, JSON.parse(e.target.result));
      };
    } else {
      props.getFileData(file);
    }
  }

  onDrop(event) {
    event.preventDefault();
    event.stopPropagation();

    const { props } = this;
    const droppedFiledata = _.get(event.dataTransfer, 'files[0]', { name: '' });
    const extension = this.getFileExtension(droppedFiledata);
    const isValidExtension = this.validateExtension(extension);

    if (!isValidExtension) {
      return;
    }

    if (props.accept === '.json') {
      const reader = new FileReader();
      reader.readAsText(event.dataTransfer.files[0]);
      reader.onload = (e) => {
        props.getFileData(droppedFiledata.name, JSON.parse(e.target.result));
      };
    } else {
      props.getFileData(droppedFiledata);
    }
  }

  getFileExtension(file) {
    return file.name.match(/\.[A-Z'-]+$/im)[0];
  }

  getFileData() {
    this.props.getFileData(this.state);
  }

  validateExtension(extension) {
    const { accept, t } = this.props;

    if (!extension) {
      this.setState({
        errorMessage: t('GLOBAL.UNABLE_DETERMINE_FILE_TYPE_MISSING_EXTENSION'),
      });
      return;
    }

    if (!this.isAcceptedExtension(extension)) {
      this.setState({
        errorMessage: `${t('GLOBAL.UNSUPPORTED_FILE_TYPE')} ${accept}`,
      });
      return;
    }

    return true;
  }

  isAcceptedExtension(extension) {
    const { props } = this;

    if (props.accept) {
      return props.accept.includes(`${extension}`);
    }

    return true;
  }
}

const styles = ({ palette, shape }) => {
  return {
    container: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      position: 'relative',
      width: '100%',
      textAlign: 'center',
    },
    errorText: {
      display: 'flex',
      alignItems: 'center',
      color: palette.text.secondary,
    },

    errorOutline: {
      marginRight: 5,
      verticalAlign: 'bottom',
      color: palette.error.main,
    },

    fileButton: {
      backgroundColor: palette.primary.main,
      borderRadius: 3,
      color: palette.text.secondary,
      cursor: 'pointer',
      display: 'flex',
      fontSize: '0.875rem',
      fontWeight: 400,
      justifyContent: 'center',
      lineHeight: 1.75,
      marginBottom: 20,
      minWidth: 64,
      padding: '6px 16px',
    },

    hideFile: {
      cursor: 'pointer',
      fontSize: 24,
      height: '100%',
      opacity: 0,
      position: 'absolute',
      width: '100%',
      zIndex: '1000',
    },
    errorBox: {
      position: 'absolute',
      bottom: '9px',
      width: '100%',
      padding: '0px 10px',
    },
  };
};

Uploader.propTypes = {
  classes: PropTypes.object,
  getFileData: PropTypes.func.isRequired,
};

Uploader.defaultProps = {
  accept: '',
  classes: {},
};

export default withStyles(styles)(withTranslation()(Uploader));
