import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import MaterialTab from '@material-ui/core/Tab';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';

const integrationTabsLabelStyling = ({ palette }) => ({
  integrationTabsLabel: {
    textTransform: 'none',
    color: palette.text.secondary,
    minWidth: '120px',
  },
});
const IntegrationMaterialTab = ({route, ...props}) => (
  <MaterialTab className={props.classes.integrationTabsLabel} component={Link} {...route} />
);

const StyledIntegrationTabs = withStyles(integrationTabsLabelStyling)(IntegrationMaterialTab);

const IntegrationLinkTab = ({ allow, ...route }) => (
  <ProtectedComponent allow={allow}>
    <StyledIntegrationTabs route={route} />
  </ProtectedComponent>
);

export default IntegrationLinkTab;
