import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

export default (state) => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      zScanApps: {
        ...uiSettingsDefaults.zScanApps,
      },
      zScanAssessments: {
        ...state.uiSettings.zScanAssessments,
        tableHeaders: uiSettingsDefaults.zScanAssessments.tableHeaders,
      },
      zScanCompareBuilds: {
        page: 0,
        size: 25,
        order: 'desc',
        orderBy: 'severityOrdinal',
        cbo: 'combined',
        ...state.uiSettings.zScanCompareBuilds,
        tableHeaders: uiSettingsDefaults.zScanCompareBuilds.tableHeaders,
      },
      zScanFindings: {
        ...state.uiSettings.zScanFindings,
        tableHeaders: uiSettingsDefaults.zScanFindings.tableHeaders,
      },
      zScanInstances: {
        ...state.uiSettings.zScanInstances,
        tableHeaders: uiSettingsDefaults.zScanInstances.tableHeaders,
      },
      appsInventory: {
        ...uiSettingsDefaults.appsInventory
      }
    },
  };
};
