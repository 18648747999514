import { DrawerContext } from 'components/drawer/DrawerProvider';
import { IDrawerContext } from 'components/drawer/DrawerProvider/models';
import { withBackfill_DEPRECATED } from 'components/hocs/withBackfill';
import withRouter from 'components/hocs/withRouter';
import Table from 'components/UI/Table';
import { IColumnHeader, ILocationQuery } from 'components/UI/Table/models';
import React, { useCallback, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  updateUISettings,
  zShieldAppsTableColumnChange
} from 'reducers/UiSettingsReducers';
import {
  getChangedTableHeadersHash,
  getUISettingsTableHeaders,
  getUISettingsWithout
} from 'reducers/UiSettingsSelectors';
import { bindActionCreators, compose } from 'redux';
import { withDirtyState } from 'utils/withDirtyState';
import withMemoizedProps from 'utils/withMemoizedProps';
import ZShieldAppDrawer from '../../ZShieldAppDrawer';
import { IRowProps } from '../../ZShieldAppDrawer/models';
import { ITableQueryFunction } from '../models';
import tableQuery from './tableQuery';
import { zShieldAppsColumnMapping } from './zShieldApps.mappings';
import { useRecoilValue } from 'recoil';
import { selectedTeam as selectedTeamAtom } from 'atoms/teams';

interface IZShieldAppsProps {
  currentTableHeaders: IColumnHeader[];
  jiggleDirtyState: () => void;
  q: ILocationQuery;
  updateUISettings: (...args: any) => void;
  updateUrl: (...args: any) => void;
  zShieldAppsTableColumnChange: (...args: any) => void;
}

const ZShieldTableView: React.FC<IZShieldAppsProps> = ({
  currentTableHeaders,
  jiggleDirtyState,
  q: query,
  updateUISettings,
  updateUrl,
  zShieldAppsTableColumnChange,
}) => {
  const selectedTeamId = useRecoilValue(selectedTeamAtom) || undefined;

  const { closeDrawer = null, openDrawer } =
    useContext<Partial<IDrawerContext<IRowProps>>>(DrawerContext);

  const handleColumnChange = (...args: any) => {
    jiggleDirtyState();
    zShieldAppsTableColumnChange(...args);
  };

  const onPaginationChange = (update: any) => {
    updateUISettings({
      domain: 'zShieldApps',
      update,
    });

    updateUrl(update);
  };

  const handleOpenDrawer = (rowProps: any) => {
    if (!openDrawer) {
      return;
    }
    openDrawer({ drawerProps: rowProps?.data, drawer: ZShieldAppDrawer });
  };

  const reloadTable = useCallback(() => {
    jiggleDirtyState(); // This will force a reload without relying on the Table component.
    if (typeof closeDrawer === 'function') {
      closeDrawer();
    }
  }, [jiggleDirtyState, closeDrawer]);

  useEffect(() => {
    window.addEventListener(`zshield:reload-tables`, reloadTable);

    return () => {
      window.removeEventListener(`zshield:reload-tables`, reloadTable);
    };
  }, [reloadTable]);

  return (
    <div>
      <Table
        columnHeaders={currentTableHeaders}
        fetchTableData={() => (tableQuery as ITableQueryFunction)(query, selectedTeamId)}
        onColumnChange={handleColumnChange}
        onPaginationChange={onPaginationChange}
        onRowClick={handleOpenDrawer}
        query={query}
        rowMapping={zShieldAppsColumnMapping}
        tableId="zShieldApps"
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    zShieldAppsColumnHeadersHash: getChangedTableHeadersHash(
      state,
      'zShieldApps'
    ),
    currentTableHeaders: getUISettingsTableHeaders(state, 'zShieldApps'),
    protectedAppsUiSettings: getUISettingsWithout(state, 'zShieldApps', [
      'tableHeaders',
    ]),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      zShieldAppsTableColumnChange,
      updateUISettings,
    },
    dispatch
  );
};

export default compose(
  withRouter,
  withBackfill_DEPRECATED('uiSettings.zShieldApps'),
  withDirtyState(),
  connect(mapStateToProps, mapDispatchToProps)
)(
  withMemoizedProps(ZShieldTableView, [
    'ZShieldUploadApp',
    'currentTableHeaders',
    'dirtyState',
    'q',
    'updateUISettings',
    'protectedAppsUiSettings',
  ])
);
