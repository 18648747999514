import MUIFormControl from '@material-ui/core/FormControl';
import MUIRadioGroup from '@material-ui/core/RadioGroup';
import cc from 'classcat';
import AndroidIcon from 'components/UI/icons/AndroidIcon';
import { Radio } from 'components/UI/input/RadioGroup';
import TextField from 'components/UI/input/TextField';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { onlyNumbersRegex } from 'utils/regexUtils';
import EditableRadio from './EditableRadio';
import { EOsRuleType, IOsRiskPolicy, IRiskRules, IValues } from './models';
import useStyles from './useStyles';

interface IAndroidRulesProps {
  rules: IRiskRules;
  values: IValues;
  onRuleTypeChange: (
    _: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => void;
  onRuleChange: <
    Os extends keyof IOsRiskPolicy['osRiskPolicyRules'],
    Key extends keyof Omit<IRiskRules, 'osRuleType'>
  >(
    os: Os,
    key: Key,
    value: unknown
  ) => void;
}

const AndroidRules: React.FC<IAndroidRulesProps> = (props) => {
  const { rules, values, onRuleTypeChange, onRuleChange } = props;

  const classes = useStyles();

  const { t } = useTranslation();

  const getRule = useCallback(() => {
    return rules.osRuleType;
  }, [rules]);

  const checkRule = useCallback(
    (type: EOsRuleType) => {
      return getRule() === type;
    },
    [getRule]
  );

  const getValue = useCallback(
    <Key extends keyof Omit<IRiskRules, 'osRuleType'>>(
      key: Key,
      type: EOsRuleType
    ) => {
      return checkRule(type) ? values[type][key] : '';
    },
    [values, checkRule]
  );

  const isGracePeriodError = useCallback(
    (type: EOsRuleType) => {
      const value = getValue('gracePeriodInDays', type);

      return value ? !onlyNumbersRegex.test(value) : false;
    },
    [getValue]
  );

  return (
    <div className={classes.androidRules}>
      <div className={cc([classes.osRiskIcon, classes.osRiskIconAndroid])}>
        <AndroidIcon />
      </div>

      <div className={classes.rulesForm}>
        <span style={{ fontSize: 'small' }}>
          {t('MTD.POLICIES.OS_RISK_POLICY.REQUIRE_COMPLIANT_ANDROID_VERSION')}
        </span>

        <MUIRadioGroup value={getRule()} onChange={onRuleTypeChange}>
          <MUIFormControl style={{ width: 'fit-content' }}>
            <Radio
              classList={classes}
              value={EOsRuleType.Latest}
              label={t(
                'MTD.POLICIES.OS_RISK_POLICY.REQUIRE_LATEST_OS_VERSION_SECURITY_PATCH'
              )}
            />
          </MUIFormControl>

          <TextField
            disabled={!checkRule(EOsRuleType.Latest)}
            label={t(
              'MTD.POLICIES.OS_RISK_POLICY.DAYS_GRACE_PERIOD_BEFORE_THREAT_CREATE'
            )}
            placeholder={t('MTD.POLICIES.OS_RISK_POLICY.NO_GRACE_PERIOD')}
            value={getValue('gracePeriodInDays', EOsRuleType.Latest)}
            style={{ minWidth: '180px' }}
            onChange={(e) =>
              onRuleChange(
                'androidRiskRules',
                'gracePeriodInDays',
                e.target.value
              )
            }
            error={isGracePeriodError(EOsRuleType.Latest)}
          />

          <EditableRadio
            disabledField={!checkRule(EOsRuleType.VersionBack)}
            preText={t('MTD.POLICIES.OS_RISK_POLICY.ALLOW_UP_TO')}
            afterText={t('MTD.POLICIES.OS_RISK_POLICY.MAJOR_OS_VERSION_BACK')}
            radioValue={EOsRuleType.VersionBack}
            fieldValue={getValue(
              'numAllowedPriorMajorVersions',
              EOsRuleType.VersionBack
            )}
            onFieldChange={(e) =>
              onRuleChange(
                'androidRiskRules',
                'numAllowedPriorMajorVersions',
                e.target.value
              )
            }
            fieldError={
              !onlyNumbersRegex.test(
                getValue(
                  'numAllowedPriorMajorVersions',
                  EOsRuleType.VersionBack
                ) || ''
              )
            }
          />

          <EditableRadio
            disabledField={!checkRule(EOsRuleType.VersionBackPatched)}
            preText={t('MTD.POLICIES.OS_RISK_POLICY.ALLOW_UP_TO')}
            afterText={`${t(
              'MTD.POLICIES.OS_RISK_POLICY.MAJOR_OS_VERSION_BACK'
            )}, ${t(
              'MTD.POLICIES.OS_RISK_POLICY.REQUIRE_LATEST_SECURITY_PATCH_BE_COMPLIANT'
            )}`}
            radioValue={EOsRuleType.VersionBackPatched}
            fieldValue={getValue(
              'numAllowedPriorMajorVersions',
              EOsRuleType.VersionBackPatched
            )}
            onFieldChange={(e) =>
              onRuleChange(
                'androidRiskRules',
                'numAllowedPriorMajorVersions',
                e.target.value
              )
            }
            fieldError={
              !onlyNumbersRegex.test(
                getValue(
                  'numAllowedPriorMajorVersions',
                  EOsRuleType.VersionBackPatched
                ) || ''
              )
            }
          />

          <TextField
            disabled={!checkRule(EOsRuleType.VersionBackPatched)}
            label={t(
              'MTD.POLICIES.OS_RISK_POLICY.DAYS_GRACE_PERIOD_BEFORE_THREAT_CREATE'
            )}
            placeholder={t('MTD.POLICIES.OS_RISK_POLICY.NO_GRACE_PERIOD')}
            value={getValue(
              'gracePeriodInDays',
              EOsRuleType.VersionBackPatched
            )}
            style={{ minWidth: '180px' }}
            onChange={(e) =>
              onRuleChange(
                'androidRiskRules',
                'gracePeriodInDays',
                e.target.value
              )
            }
            error={isGracePeriodError(EOsRuleType.VersionBackPatched)}
          />

          <MUIFormControl style={{ width: 'fit-content' }}>
            <Radio
              classList={classes}
              value={EOsRuleType.MinVersion}
              label={t('MTD.POLICIES.OS_RISK_POLICY.MINIMUM_ALLOW_VERSION')}
            />
          </MUIFormControl>

          <TextField
            disabled={!checkRule(EOsRuleType.MinVersion)}
            value={getValue('minimumAllowedVersion', EOsRuleType.MinVersion)}
            style={{ minWidth: '180px' }}
            onChange={(e) =>
              onRuleChange(
                'androidRiskRules',
                'minimumAllowedVersion',
                e.target.value
              )
            }
          />

          <MUIFormControl style={{ width: 'fit-content' }}>
            <Radio
              classList={classes}
              value={EOsRuleType.MinVersionPatched}
              label={`${t(
                'MTD.POLICIES.OS_RISK_POLICY.MINIMUM_ALLOW_VERSION'
              )}, ${t(
                'MTD.POLICIES.OS_RISK_POLICY.REQUIRE_LATEST_SECURITY_PATCH_FOR_COMPLIANCE'
              )}`}
            />
          </MUIFormControl>

          <TextField
            disabled={!checkRule(EOsRuleType.MinVersionPatched)}
            value={getValue(
              'minimumAllowedVersion',
              EOsRuleType.MinVersionPatched
            )}
            style={{ minWidth: '180px' }}
            onChange={(e) =>
              onRuleChange(
                'androidRiskRules',
                'minimumAllowedVersion',
                e.target.value
              )
            }
          />

          <TextField
            disabled={!checkRule(EOsRuleType.MinVersionPatched)}
            value={getValue('gracePeriodInDays', EOsRuleType.MinVersionPatched)}
            label={t(
              'MTD.POLICIES.OS_RISK_POLICY.DAYS_GRACE_PERIOD_BEFORE_THREAT_CREATE'
            )}
            placeholder={t('MTD.POLICIES.OS_RISK_POLICY.NO_GRACE_PERIOD')}
            style={{ minWidth: '180px' }}
            onChange={(e) =>
              onRuleChange(
                'androidRiskRules',
                'gracePeriodInDays',
                e.target.value
              )
            }
            error={isGracePeriodError(EOsRuleType.MinVersionPatched)}
          />
        </MUIRadioGroup>
      </div>
    </div>
  );
};

export default AndroidRules;
