import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    dropDownWrapper: {
      width: '14rem',
      alignItems: 'center',
      flexBasis: '50%',
      marginLeft: 15,
      marginRight: 'auto',

      '&.is-loading.shimmer': {
        border: `1px solid ${palette.tables.shimmer}`,
      },
    },
  })
);

export default useStyles;
