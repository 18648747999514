import { TFunction } from 'react-i18next';
import { IDormancyPolicy } from '../models';
import { IDormancyPolicyForm } from './models';
import { convertSecondsToUnit, INTERVAL_OPTIONS } from './utils';

const initialValues = (t: TFunction<'translation', undefined>, data?: IDormancyPolicy): IDormancyPolicyForm => {
  const result = {
    enabledIA: data?.inactiveAppSettings?.enabled ?? false,
    enabledPA: data?.pendingActivationSettings?.enabled ?? false,
    initialName: data?.name || '',
    maxWarningsCountIA: data?.inactiveAppSettings?.maxWarningsCount ?? null,
    maxWarningsCountPA:
      data?.pendingActivationSettings?.maxWarningsCount ?? null,
    notifyDevicesIosIA: data?.inactiveAppSettings?.notifyDevicesIos ?? false,
    notifyDevicesAndroidIA:
      data?.inactiveAppSettings?.notifyDevicesAndroid ?? false,
    sendEmailAndroidIA: data?.inactiveAppSettings?.sendEmailAndroid ?? false,
    sendEmailAndroidPA:
      data?.pendingActivationSettings?.sendEmailAndroid ?? false,
    sendEmailIosIA: data?.inactiveAppSettings?.sendEmailIos ?? false,
    sendEmailIosPA: data?.pendingActivationSettings?.sendEmailAndroid ?? false,
    timeBeforeWarningDisplayUnitsIA:
      INTERVAL_OPTIONS(t).find(
        ({ value }) =>
          value === data?.inactiveAppSettings?.timeBeforeWarningDisplayUnits
      ) ?? null,
    timeBeforeWarningDisplayUnitsPA:
      INTERVAL_OPTIONS(t).find(
        ({ value }) =>
          value ===
          data?.pendingActivationSettings?.timeBeforeWarningDisplayUnits
      ) ?? null,
    timeBeforeWarningIA: convertSecondsToUnit(
      data?.inactiveAppSettings?.timeBeforeWarningSeconds ?? null,
      data?.inactiveAppSettings?.timeBeforeWarningDisplayUnits ?? null
    ),
    timeBeforeWarningPA: convertSecondsToUnit(
      data?.pendingActivationSettings?.timeBeforeWarningSeconds ?? null,
      data?.pendingActivationSettings?.timeBeforeWarningDisplayUnits ?? null
    ),
    timeBetweenWarningsDisplayUnitsIA:
      INTERVAL_OPTIONS(t).find(
        ({ value }) =>
          value === data?.inactiveAppSettings?.timeBetweenWarningsDisplayUnits
      ) ?? null,
    timeBetweenWarningsDisplayUnitsPA:
      INTERVAL_OPTIONS(t).find(
        ({ value }) =>
          value ===
          data?.pendingActivationSettings?.timeBetweenWarningsDisplayUnits
      ) ?? null,
    timeBetweenWarningsIA: convertSecondsToUnit(
      data?.inactiveAppSettings?.timeBetweenWarningsSeconds ?? null,
      data?.inactiveAppSettings?.timeBetweenWarningsDisplayUnits ?? null
    ),
    timeBetweenWarningsPA: convertSecondsToUnit(
      data?.pendingActivationSettings?.timeBetweenWarningsSeconds ?? null,
      data?.pendingActivationSettings?.timeBetweenWarningsDisplayUnits ?? null
    ),
  };

  return result;
};

export default initialValues;
