import React from 'react';
import { useTranslation } from 'react-i18next';

// mui
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';

// utils
import { Form, Formik } from 'formik';

// components
import ZButton from 'UI/Buttons/ZButton';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { toggleModalDirect } from '../../../utils/storeUtils';

const useStyles = makeStyles(({ palette, shape }) => ({
  input: {
    borderRadius: 3,
    marginBottom: 15,
    position: 'relative',
    alignItems: 'flex-end',
    float: 'right',
    textTransform: 'none',
    marginTop: '1vh',
  },

  link: {
    marginTop: '2vh',
    marginBottom: '0.25vh',
    color: '#2196f3',
    fontSize: '12px',
    fontFamily: "'Roboto', sans-serif",
  },
}));

function SubscriptionText(props) {
  const styles = useStyles();
  const { t /*, i18n*/ } = useTranslation();

  function handleSubmit(values) {
    // close modal
    toggleModalDirect('SubscriptionText', false);
  }

  return (
    <DialogContent>
      <Formik
        enableReinitialize
        initialValues={{}}
        onSubmit={handleSubmit}
      >
        {({ values }) => {
          return (
            <>
              {props.data.children}
              <Grid container spacing={2} direction="row" wrap="nowrap">
                <Grid item xl>
                  {props.data.link && (
                    <Typography
                      variant="body2"
                      className={styles.link}
                      align="left"
                    >
                      <a
                        href={props.data.link.href}
                        onClick={props.data.link.onClick}
                        className={styles.link}
                      >
                        {props.data.link.text}
                      </a>
                    </Typography>
                  )}
                </Grid>
                <Grid item xs>
                  <Form>
                    <ZButton
                      fullWidth={false}
                      buttonType="submit"
                      styleName="rightAlign"
                      color="secondary"
                      buttonText={t('STC.BUTTONTEXTCONFIRM')}
                    />
                  </Form>
                </Grid>
              </Grid>
            </>
          );
        }}
      </Formik>
    </DialogContent>
  );
}

export default SubscriptionText;
