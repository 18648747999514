import MUIInputAdornment from '@material-ui/core/InputAdornment';
import {
  deletePolicy as deleteBrandingPolicy,
  updatePolicy as updateBrandingPolicy,
} from 'api/BrandingPolicyService';
import { getActiveModalAtom } from 'atoms/modals';
import Button from 'components/UI/Button';
import { GenericPromptModal_TITLE } from 'components/UI/Modals/GenericPrompt';
import { IGenericPromptModalData } from 'components/UI/Modals/GenericPrompt/models';
import { ISelectItem } from 'components/UI/input/Select';
import TextField from 'components/UI/input/TextField';
import withRouter from 'components/hocs/withRouter';
import { ChangeEvent, ReactElement, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSetRecoilState } from 'recoil';
import { selectCanManageBranding } from 'reducers/AuthReducers';
import { HexColorRegex, LinkRegex } from 'utils/regexUtils';
import { IAppPolicyCreateModalData } from '../appPolicy/AddClonePolicy/models';
import EmptyPolicies from '../common/EmptyPolicies';
import PolicySelector from '../common/PolicySelector';
import UploadLogo from './UploadLogo';
import {
  BrandingLogo,
  IBrandingPolicyDetails,
  IBrandingPolicyRequest,
} from './models';
import { fetchPolicies, fetchPolicyDetails } from './tableQuery';
import useStyles from './useStyles';

const activeModalAtom = getActiveModalAtom<
  IGenericPromptModalData | IAppPolicyCreateModalData
>();

const defaultPolicy: IBrandingPolicyDetails = {
  id: '',
  accountId: '',
  name: '',
  teamId: '',
  buttonColorLight: '',
  buttonTextColorLight: '',
  buttonColorDark: '',
  buttonTextColorDark: '',
  images: {},
  licenseAgreementLocation: '',
  privacyPolicyLocation: '',
  groups: [],
};

interface IBrandingPolicyProps {
  location: {
    query: {
      branding: string;
    };
  };
}

const defaultButtonColor = '#5FAB5A';
const defaultButtonTextColor = '#FFFFFF';

const BrandingPolicy: React.FC<IBrandingPolicyProps> = (
  props
): ReactElement => {
  const classes = useStyles();

  const setActiveModal = useSetRecoilState(activeModalAtom);

  const canManageBranding = useSelector(selectCanManageBranding);

  const [selectedPolicy, setSelectedPolicy] = useState<ISelectItem>();
  const [currentPolicy, setCurrentPolicy] = useState(defaultPolicy);
  const [policyDetails, setPolicyDetails] = useState(defaultPolicy);
  const [isValidButtonColorLight, setIsValidButtonColorLight] = useState(true);
  const [isValidButtonTextColorLight, setIsValidButtonTextColorLight] =
    useState(true);
  const [isValidButtonColorDark, setIsValidButtonColorDark] = useState(true);
  const [isValidButtonTextColorDark, setIsValidButtonTextColorDark] =
    useState(true);
  const [isValidEulaLink, setIsvalidEulaLink] = useState(true);
  const [isValidPrivacyLink, setIsvalidPrivacyLink] = useState(true);

  const getRequestObject = useCallback(
    (policy?: IBrandingPolicyDetails): IBrandingPolicyRequest | undefined => {
      if (!policy) {
        return undefined;
      }

      return {
        policy: {
          name: policy.name,
          licenseAgreementLocation: policy.licenseAgreementLocation,
          privacyPolicyLocation: policy.privacyPolicyLocation,
          buttonColorLight: policy.buttonColorLight?.trim()?.length
            ? policy.buttonColorLight
            : undefined,
          buttonTextColorLight: policy.buttonTextColorLight?.trim()?.length
            ? policy.buttonTextColorLight
            : undefined,
          buttonColorDark: policy.buttonColorDark?.trim()?.length
            ? policy.buttonColorDark
            : undefined,
          buttonTextColorDark: policy.buttonTextColorDark?.trim()?.length
            ? policy.buttonTextColorDark
            : undefined,
        },
        horizontalLogoDark: policy.images.logoHorizontalDark as File,
        verticalLogoDark: policy.images.logoVerticalDark as File,
        horizontalLogoLight: policy.images.logoHorizontalLight as File,
        verticalLogoLight: policy.images.logoVerticalLight as File,
      };
    },
    []
  );

  const getRequestFormData = useCallback(
    (requestObject: IBrandingPolicyRequest): FormData => {
      const formData = new FormData();

      Object.entries(requestObject).forEach(([key, value]) => {
        if (value) {
          if (key === 'policy') {
            const header = { type: 'application/json' };
            const jsonValue = JSON.stringify(value);
            const blob = new Blob([jsonValue], header);

            formData.append(key, blob);
          } else {
            formData.append(key, value);
          }
        }
      });

      return formData;
    },
    []
  );

  const areZeroOrAllLogos = useCallback(() => {
    return (
      (currentPolicy.images.logoHorizontalDark !== undefined &&
        currentPolicy.images.logoVerticalDark !== undefined &&
        currentPolicy.images.logoHorizontalLight !== undefined &&
        currentPolicy.images.logoVerticalLight !== undefined) ||
      (currentPolicy.images.logoHorizontalDark === undefined &&
        currentPolicy.images.logoVerticalDark === undefined &&
        currentPolicy.images.logoHorizontalLight === undefined &&
        currentPolicy.images.logoVerticalLight === undefined)
    );
  }, [currentPolicy]);

  const canDeploy = useCallback((): boolean => {
    return (
      isValidButtonColorLight &&
      isValidButtonTextColorLight &&
      isValidButtonColorDark &&
      isValidButtonTextColorDark &&
      currentPolicy.buttonColorLight !== '' &&
      currentPolicy.buttonTextColorLight !== '' &&
      currentPolicy.buttonColorDark !== '' &&
      currentPolicy.buttonTextColorDark !== '' &&
      isValidEulaLink &&
      isValidPrivacyLink &&
      areZeroOrAllLogos()
    );
  }, [
    isValidButtonColorLight,
    isValidButtonTextColorLight,
    isValidButtonColorDark,
    isValidButtonTextColorDark,
    isValidEulaLink,
    isValidPrivacyLink,
    currentPolicy,
    areZeroOrAllLogos,
  ]);

  const setFileData = useCallback(
    (fileData: File, logo: BrandingLogo) => {
      const newPolicy = {
        ...currentPolicy,
        images: {
          ...currentPolicy.images,
          [logo]: fileData,
        },
      };

      setCurrentPolicy(newPolicy);
    },
    [currentPolicy, setCurrentPolicy]
  );

  const onEulaLinkChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      setCurrentPolicy({
        ...currentPolicy,
        licenseAgreementLocation: value,
      });

      if (value) {
        setIsvalidEulaLink(LinkRegex.test(value));
      } else {
        setIsvalidEulaLink(true);
      }
    },
    [currentPolicy]
  );

  const onPrivacyLinkChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      setCurrentPolicy({
        ...currentPolicy,
        privacyPolicyLocation: value,
      });

      if (value) {
        setIsvalidPrivacyLink(LinkRegex.test(value));
      } else {
        setIsvalidPrivacyLink(true);
      }
    },
    [currentPolicy]
  );

  const onButtonColorLightChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      setCurrentPolicy({
        ...currentPolicy,
        buttonColorLight: value.toUpperCase(),
      });

      if (value) {
        setIsValidButtonColorLight(HexColorRegex.test(value));
      } else {
        setIsValidButtonColorLight(true);
      }
    },
    [currentPolicy]
  );

  const onButtonTextColorLightChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      setCurrentPolicy({
        ...currentPolicy,
        buttonTextColorLight: value.toUpperCase(),
      });

      if (value) {
        setIsValidButtonTextColorLight(HexColorRegex.test(value));
      } else {
        setIsValidButtonTextColorLight(true);
      }
    },
    [currentPolicy]
  );

  const onButtonColorDarkChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      setCurrentPolicy({
        ...currentPolicy,
        buttonColorDark: value.toUpperCase(),
      });

      if (value) {
        setIsValidButtonColorDark(HexColorRegex.test(value));
      } else {
        setIsValidButtonColorDark(true);
      }
    },
    [currentPolicy]
  );

  const onButtonTextColorDarkChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      setCurrentPolicy({
        ...currentPolicy,
        buttonTextColorDark: value.toUpperCase(),
      });

      if (value) {
        setIsValidButtonTextColorDark(HexColorRegex.test(value));
      } else {
        setIsValidButtonTextColorDark(true);
      }
    },
    [currentPolicy]
  );

  const onRemoveLogoClick = useCallback(
    (logo: BrandingLogo) => {
      setActiveModal({
        active: GenericPromptModal_TITLE,
        payload: {
          title: 'Remove Logo',
          message: 'Are you sure you want to remove?',
          onCancel: () => setActiveModal(undefined),
          onConfirm: () => {
            const newPolicy = {
              ...currentPolicy,
              images: {
                ...currentPolicy.images,
                [logo]: undefined,
              },
            };

            setCurrentPolicy(newPolicy);
            setActiveModal(undefined);
          },
        },
      });
    },
    [currentPolicy, setActiveModal]
  );

  const getColor = useCallback(
    (
      key: keyof Pick<
        IBrandingPolicyDetails,
        | 'buttonColorDark'
        | 'buttonColorLight'
        | 'buttonTextColorDark'
        | 'buttonTextColorLight'
      >
    ) => {
      if (currentPolicy[key].trim().length) {
        return `#${currentPolicy[key]}`;
      } else if (policyDetails[key].trim().length > 0) {
        return `#${policyDetails[key]}`;
      } else {
        return key.includes('Text')
          ? defaultButtonTextColor
          : defaultButtonColor;
      }
    },
    [currentPolicy, policyDetails]
  );

  return (
    <div className={classes.root}>
      <PolicySelector
        query={props.location.query.branding}
        policyType="Branding"
        canManage={!canManageBranding}
        canDeploy={canDeploy}
        getRequestObject={getRequestObject}
        fetchPolicies={fetchPolicies}
        fetchPolicyDetails={fetchPolicyDetails}
        updatePolicy={updateBrandingPolicy}
        deletePolicy={deleteBrandingPolicy}
        selectedPolicy={selectedPolicy}
        setSelectedPolicy={setSelectedPolicy}
        currentPolicy={currentPolicy}
        setCurrentPolicy={setCurrentPolicy}
        policyDetails={policyDetails}
        setPolicyDetails={setPolicyDetails}
        getRequestBody={getRequestFormData}
      />

      {!policyDetails.id.trim() && <EmptyPolicies />}

      {selectedPolicy &&
        policyDetails.id === currentPolicy.id &&
        selectedPolicy.value === currentPolicy.id && (
          <div className={classes.content}>
            <div className={classes.inputCard}>
              <h2 className={classes.noMargin}>
                Custom License Agreement HTML Link
              </h2>
              <span>
                This will optionally replace the link to the standard EULA in
                the mobile app
              </span>
              <TextField
                name="eulaLink"
                helperText={
                  !isValidEulaLink
                    ? 'Invalid Licence Agreement HTML Link'
                    : undefined
                }
                value={currentPolicy.licenseAgreementLocation}
                error={!isValidEulaLink}
                onChange={onEulaLinkChange}
                disabled={!canManageBranding}
              />
            </div>

            <div className={classes.inputCard}>
              <h2 className={classes.noMargin}>
                Custom Privacy Policy HTML Link
              </h2>
              <span>
                This will optionally replace the link to the standard privacy
                policy in the mobile app
              </span>
              <TextField
                name="privacyLink"
                helperText={
                  !isValidPrivacyLink
                    ? 'Invalid Privacy Policy HTML Link'
                    : undefined
                }
                value={currentPolicy.privacyPolicyLocation}
                error={!isValidPrivacyLink}
                onChange={onPrivacyLinkChange}
                disabled={!canManageBranding}
              />
            </div>

            <div className={classes.colorButtonContainerDark}>
              <div className={classes.darkInputCard} style={{ border: 'none' }}>
                <h2 className={classes.darkTitle}>
                  Mobile App Button Color - Dark Mode
                </h2>
                <span className={classes.darkTitle}>
                  You may optionally change the primary button color in the
                  mobile app
                </span>

                <div className={classes.colorInputsDark}>
                  <TextField
                    name="buttonColorDark"
                    label="Button Color (Hex)"
                    onChange={onButtonColorDarkChange}
                    helperText={
                      !isValidButtonColorDark
                        ? 'Invalid Button Color'
                        : undefined
                    }
                    value={currentPolicy.buttonColorDark}
                    error={!isValidButtonColorDark}
                    disabled={!canManageBranding}
                    InputProps={{
                      startAdornment: (
                        <MUIInputAdornment position="start">
                          <span>#</span>
                        </MUIInputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    name="buttonTextColorDark"
                    label="Button Text Color (Hex)"
                    onChange={onButtonTextColorDarkChange}
                    helperText={
                      !isValidButtonTextColorDark
                        ? 'Invalid Button Text Color'
                        : undefined
                    }
                    value={currentPolicy.buttonTextColorDark}
                    error={!isValidButtonTextColorDark}
                    disabled={!canManageBranding}
                    InputProps={{
                      startAdornment: (
                        <MUIInputAdornment position="start">
                          <span>#</span>
                        </MUIInputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>

              <div className={classes.exampleButtonContainer}>
                <div className={classes.exampleButton}>
                  <Button
                    text="Example button"
                    style={{
                      width: '100%',
                      borderRadius: '50px',
                      color: getColor('buttonTextColorDark'),
                      backgroundColor: getColor('buttonColorDark'),
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={classes.colorButtonContainer}>
              <div
                className={classes.lightInputCard}
                style={{ border: 'none' }}
              >
                <h2 className={classes.lightTitle}>
                  Mobile App Button Color - Light Mode
                </h2>
                <span className={classes.lightTitle}>
                  You may optionally change the primary button color in the
                  mobile app
                </span>

                <div className={classes.colorInputs}>
                  <TextField
                    name="buttonColor"
                    label="Button Color (Hex)"
                    onChange={onButtonColorLightChange}
                    helperText={
                      !isValidButtonColorLight
                        ? 'Invalid Button Color'
                        : undefined
                    }
                    value={currentPolicy.buttonColorLight}
                    error={!isValidButtonColorLight}
                    disabled={!canManageBranding}
                    InputProps={{
                      startAdornment: (
                        <MUIInputAdornment position="start">
                          <span>#</span>
                        </MUIInputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    name="buttonTextColor"
                    label="Button Text Color (Hex)"
                    onChange={onButtonTextColorLightChange}
                    helperText={
                      !isValidButtonTextColorLight
                        ? 'Invalid Button Text Color'
                        : undefined
                    }
                    value={currentPolicy.buttonTextColorLight}
                    error={!isValidButtonTextColorLight}
                    disabled={!canManageBranding}
                    InputProps={{
                      startAdornment: (
                        <MUIInputAdornment position="start">
                          <span>#</span>
                        </MUIInputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>

              <div className={classes.exampleButtonContainer}>
                <div className={classes.exampleButton}>
                  <Button
                    text="Example button"
                    style={{
                      width: '100%',
                      borderRadius: '50px',
                      color: getColor('buttonTextColorLight'),
                      backgroundColor: getColor('buttonColorLight'),
                    }}
                  />
                </div>
              </div>
            </div>

            {!areZeroOrAllLogos() && (
              <div className={classes.requiredLogosError}>
                <span>
                  To add a custom logo, you must provide all four styles. The
                  Branding Policy can only be deployed with either zero logos or
                  all four.
                </span>
              </div>
            )}

            <UploadLogo
              isDark
              disabled={!canManageBranding}
              id={BrandingLogo.HorizontalDark}
              logo={currentPolicy.images.logoHorizontalDark}
              title="Logo - Dark Mode"
              onUpload={setFileData}
              onRemoveClick={onRemoveLogoClick}
            />
            <UploadLogo
              isDark
              disabled={!canManageBranding}
              id={BrandingLogo.VerticalDark}
              logo={currentPolicy.images.logoVerticalDark}
              title="Logo Icon Only - Dark Mode"
              onUpload={setFileData}
              onRemoveClick={onRemoveLogoClick}
            />
            <UploadLogo
              disabled={!canManageBranding}
              id={BrandingLogo.HorizontalLight}
              logo={currentPolicy.images.logoHorizontalLight}
              title="Logo - Light Mode"
              onUpload={setFileData}
              onRemoveClick={onRemoveLogoClick}
            />
            <UploadLogo
              disabled={!canManageBranding}
              id={BrandingLogo.VerticalLight}
              logo={currentPolicy.images.logoVerticalLight}
              title="Logo Icon Only - Light Mode"
              onUpload={setFileData}
              onRemoveClick={onRemoveLogoClick}
            />
          </div>
        )}
    </div>
  );
};

export default withRouter(BrandingPolicy);
