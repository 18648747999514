import React from 'react';

import GenericTable from 'components/UI/GenericTable';
import useStyles from './useStyles';
import { IAdditionalForensicItem } from './models';
import { TRowData } from 'components/UI/GenericTable/models';

interface IAdditionalForensicProps {
  tableData: IAdditionalForensicItem[];
}

const columnHeaders = [
  { id: 'key', label: 'Threat Forensic' },
  { id: 'value', label: 'Details' },
];

const AdditionalForensic: React.FC<IAdditionalForensicProps> = ({
  tableData,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <GenericTable
        classList={classes}
        columnHeaders={columnHeaders}
        rowMapping={[
          {
            path: 'key',
            columnContent: ({ rowData }) => (
              <div className={classes.columnContent}>{rowData.key}</div>
            ),
          },
          {
            path: 'value',
            columnContent: ({ rowData }) => (
              <div className={classes.columnContent}>{rowData.value}</div>
            ),
          },
        ]}
        tableData={tableData as unknown as TRowData[]}
        tableId="Additional Forensic"
      />
    </div>
  );
};

export default AdditionalForensic;
