import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withRouter from 'components/hocs/withRouter';
import classnames from 'classnames';

import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core';
import InvertColors from '@material-ui/icons/InvertColors';
import { toggleTheme } from 'reducers/UiSettingsReducers';

import LogoHeader from 'components/admin/common/LogoHeader';
import HeaderMenu from 'components/admin/common/HeaderMenu';

class GlobalHeader extends Component {
  render() {
    const { classes, organization } = this.props;
    const companyClasses = classnames('roboto-light', classes.company);
    const headerClasses = classnames('main-header', classes.header);

    return (
      <AppBar className={headerClasses}>
        <Toolbar className={classes.toolbar}>
          <LogoHeader />
          <Typography variant="h6" className={companyClasses}>
            {organization}
          </Typography>
          <div className={classes.filler} />
          <HeaderMenu />
          <IconButton onClick={this.props.toggleTheme}>
            <InvertColors className={classes.iconStyle} />
          </IconButton>
        </Toolbar>
      </AppBar>
    );
  }
}

GlobalHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    organization: state.superUser.organization,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      toggleTheme,
    },
    dispatch
  );
};

const styles = ({ palette }) => {
  return {
    header: {
      display: 'flex',
      width: '100%',
      height: 64,
      position: 'fixed',
      top: 0,
      right: 0,
      zIndex: 999,
      boxShadow: 'none',
      backgroundColor: palette.components.globalHeader.background,
    },
    filler: {
      flexGrow: 1,
    },
    iconStyle: {
      color: palette.components.globalHeader.contrastText,
    },
    company: {
      paddingLeft: 10,
      paddingRight: 10,
      color: palette.components.globalHeader.contrastText,
    },
    toolbar: {
      paddingLeft: 0,
      paddingRight: 30,
    },
  };
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(GlobalHeader)
  )
);
