import { getActiveModalAtom } from 'atoms/modals';
import Button from 'components/UI/Button';
import InputError from 'components/UI/input/InputError';
import PasswordField from 'components/UI/input/PasswordField';
import Select from 'components/UI/input/Select';
import TextField from 'components/UI/input/TextField';
import {
  Field as FormikField,
  Form as FormikForm,
  Formik,
  FormikHelpers,
} from 'formik';
import { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { AddEditCloneEMMGroups_TITLE } from '../../AddEditCloneEMMGroups';
import { IAddEditCloneEMMGroups } from '../../AddEditCloneEMMGroups/models';
import { IAuthForGroupsCreate } from '../../models';
import { IAuthFormProps } from '../models';
import { initialValues } from './initialValues';
import Schema from './schema';
import useStyles from './useStyles';
import { createConnection, updateConnection } from './utils';
import { useTranslation } from 'react-i18next';
import { IMaasAuth, IMaasFormData } from './models';

const activeModalAtom = getActiveModalAtom<IAddEditCloneEMMGroups<IMaasAuth>>();

const MaasForm: React.FC<IAuthFormProps> = ({
  availableTeams,
  connector,
  data,
  handleBackClick,
  handleCancelClick,
  mode = 'ADD',
  selectedTeamId,
}) => {
  const { t, ready } = useTranslation();
  const classes = useStyles();
  const [authError, setAuthError] = useState<string>('');
  const setActiveModal = useSetRecoilState(activeModalAtom);

  const resolvedInitialValues = initialValues(
    mode,
    data?.rowData,
    connector,
    availableTeams,
    selectedTeamId
  );

  const handleSubmit = useCallback(
    (formData: IMaasFormData, formikHelpers: FormikHelpers<IMaasFormData>) => {
      switch (mode) {
        case 'ADD':
          createConnection(
            formData,
            formikHelpers,
            setAuthError,
            (authForGroupsCreate: IAuthForGroupsCreate<IMaasAuth>) =>
              setActiveModal({
                active: AddEditCloneEMMGroups_TITLE,
                payload: { authForGroupsCreate, connector, mode },
              }),
            t
          );
          break;
        case 'EDIT':
          updateConnection(
            formData,
            formikHelpers,
            setAuthError,
            () =>
              setActiveModal(() => ({
                active: AddEditCloneEMMGroups_TITLE,
                payload: {
                  cloneInfoFromAuth: {
                    connectionIdToClone: data?.rowData?.id ?? '',
                    name: formData?.name ?? '',
                    teamId: (formData?.team?.value as string) ?? '',
                  },
                  connector,
                  data,
                  mode,
                },
              })),
            t
          );
          break;
        case 'CLONE':
          setActiveModal(undefined); // First clear out data so it doesn't show up in groups table.
          setActiveModal(() => ({
            active: AddEditCloneEMMGroups_TITLE,
            payload: {
              cloneInfoFromAuth: {
                connectionIdToClone: data?.rowData?.id ?? '',
                name: formData?.name ?? '',
                teamId: (formData?.team?.value as string) ?? '',
              },
              connector,
              data: undefined,
              mode,
            },
          }));
          break;
      }
    },
    [mode, setActiveModal, connector, data, setAuthError, t]
  );

  if (!ready) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={resolvedInitialValues}
      onSubmit={handleSubmit}
      validateOnBlur
      validationSchema={Schema(t)}
    >
      {({ isSubmitting, setFieldValue }) => (
        <FormikForm>
          <input name="connectorId" type="hidden" />
          <FormikField
            component={TextField}
            disabled={isSubmitting}
            label={t('MTD.INTEGRATIONS.CONNECTION_NAME')}
            name="name"
          />
          <FormikField
            component={Select}
            disableClearable={true}
            disabled={mode === 'EDIT'}
            label={t('GLOBAL.TEAM')}
            multiple={false}
            name="team"
            options={availableTeams ?? []}
            setFieldValue={setFieldValue}
            type="select"
          />
          <FormikField
            component={TextField}
            disabled={isSubmitting || mode === 'CLONE'}
            label={t('GLOBAL.URL')}
            name="baseUrl"
          />
          <FormikField
            component={TextField}
            disabled={isSubmitting || mode === 'CLONE'}
            label={t('MTD.INTEGRATIONS.MDM_USERNAME')}
            name="user_id"
          />
          <FormikField
            component={PasswordField}
            disabled={isSubmitting || mode === 'CLONE'}
            label={t('MTD.INTEGRATIONS.MDM_PASSWORD')}
            name="password"
          />
          <FormikField
            component={TextField}
            disabled={isSubmitting || mode === 'CLONE'}
            label={t('MTD.INTEGRATIONS.APP_ACCESS_KEY')}
            name="access_key"
          />
          <FormikField
            component={TextField}
            disabled={isSubmitting || mode === 'CLONE'}
            label={t('MTD.INTEGRATIONS.BILLING_ID')}
            name="billing_id"
          />
          <FormikField
            component={TextField}
            disabled={isSubmitting || mode === 'CLONE'}
            label={t('MTD.INTEGRATIONS.APP_ID')}
            name="app_id"
          />
          <FormikField
            component={TextField}
            disabled={isSubmitting || mode === 'CLONE'}
            label={t('MTD.INTEGRATIONS.APP_VERSION')}
            name="app_version"
          />
          <FormikField
            component={TextField}
            disabled={isSubmitting || mode === 'CLONE'}
            label={t('MTD.INTEGRATIONS.PLATFORM_ID')}
            name="platform_id"
          />
          {!!authError && <InputError override={authError} />}
          <div className={classes.buttons}>
            {mode === 'ADD' && (
              <Button
                className={classes.buttonBackgroundColor}
                disabled={isSubmitting}
                onClick={handleBackClick}
                text={t('GLOBAL.BACK')}
                type="button"
              />
            )}
            <Button
              className={classes.buttonBackgroundColor}
              disabled={isSubmitting}
              onClick={handleCancelClick}
              text={t('GLOBAL.CANCEL')}
              type="button"
            />
            <Button
              color="secondary"
              disabled={isSubmitting}
              text={t('GLOBAL.NEXT')}
              type="submit"
            />
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default MaasForm;
