import { IHourlySyncSchedule } from './models';

export const initialValues = (
  data: Partial<IHourlySyncSchedule>
): IHourlySyncSchedule => {
  return {
    hourlyStartTime: data?.hourlyStartTime ?? '',
    hours: data.hours,
  };
};
