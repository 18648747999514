import moment from 'moment';
import _ from 'lodash';
import 'moment/min/locales';
// needed to set different date formats/language based on browser language
moment.locale(_.get(window, 'navigator.language', 'en'));

const momentHelper = timeStamp => {
  return moment(timeStamp).format('lll');
};

export default momentHelper;
