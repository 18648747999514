import { ISelectItem } from "components/UI/input/Select";
import { IzShieldOSType } from "components/main/zShieldApps/ZShieldApps/models";

export interface IAppDataBase {
  appHardening: boolean;
  appIntegrity: boolean;
  description: string;
  os: string;
}

export interface IAppData extends IAppDataBase {
  [index: string]: string | boolean | undefined; // This is used to allow access to below fields via string variable
  resourceEncryption: string | boolean | undefined;
  signatureVerification: string | boolean | undefined;
  staticDexEncryption: string | boolean | undefined;
}

export interface IProtectionCoverage {
  displayName: string;
  fieldName: string;
  id: number;
  tooltip: string;
  disabled?: boolean;
  osType?: IzShieldOSType;
}

export interface IFormSubmit {
  appHardening: boolean;
  appId?: string;
  appVersion?: string;
  buildId: string | undefined;
  platform: string;
  name: string | boolean | undefined;
  bundleId?: string;
  buildNumber?: string;
  appIntegrity: boolean;
  description?: string;
  version: string;
  group?: ISelectItem;
  groupId?: string;
  team?: ISelectItem;
  teamId?: string;
  protectionCoverages: string[];
  // protection coverages 
  resourceEncryption?: boolean;
  staticDexEncryption?: boolean;
  signatureVerification?: boolean;
  runtimeProtection?: boolean;
  metadataEncryption?: boolean;
  codeObfuscation?: boolean;
}

export enum EZShieldDisclaimer {
  IOS = 'Apple Embedded Bitcode must be enabled in XCode',
  ANDROID = 'Note: Apps signed with APK v1 are not supported',
  STATIC = 'Static DEX encryption Currently Available for API 21+ Only'
}

export interface IPolicyGroup {
  id: string;
  name: string;
  team: {
    name: string;
    id: string;
  };
}