import memoizeTableQuery from 'components/UI/Table/utils/memoizeTableQuery';
import {
  IAffectedDevice,
  IAffectedDevicesPostPayload,
  IQueryParams,
} from './models';
import { fetchTableDataByApps, fetchTableDataByTriggers } from './utils';
import { IAffectedAppsPostPayload } from '../../APCCreatePolicyModal/models';
import { IGroupsHash } from 'components/main/zShieldApps/ZShieldAppDrawer/models';

const tableQuery = (
  affectedDevicesPostDataPayload: IAffectedDevicesPostPayload,
  setTotalDataCount: (count: number) => void,
  affectedAppsPostDataPayload: IAffectedAppsPostPayload,
  toggleDeviceApiCall: boolean,
  groupsHash: IGroupsHash,
  query: IQueryParams
) => {
  return memoizeTableQuery(async () => {
    const { data } = toggleDeviceApiCall
      ? await fetchTableDataByTriggers(
          { ...query },
          affectedAppsPostDataPayload,
          setTotalDataCount
        )
      : await fetchTableDataByApps(
          { ...query },
          affectedDevicesPostDataPayload,
          setTotalDataCount
        );
    let modifiedTableDataWithGroupName = [];

    modifiedTableDataWithGroupName = data?.content.map(
      (singleRow: IAffectedDevice) => {
        return {
          ...singleRow,
          groupName: groupsHash[singleRow.groupId]?.name ?? 'N/A',
        };
      }
    );

    return {
      data: modifiedTableDataWithGroupName ?? [],
      count: data?.totalElements ?? 0,
    };
  });
};

export default tableQuery;
