import { withStyles } from '@material-ui/core/styles';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { checkSSO } from 'api/AuthService';
import { fetchBrandingByDomain } from 'api/BrandingService';
import Footer from 'components/entry/Footer';
import LogoHeader from 'components/entry/LogoHeader';
import withRouter from 'components/hocs/withRouter';
import { setBrandingProperties, setSSOProperties } from 'reducers/AuthReducers';
import { useSafeBranding } from 'components/partner/branding/utils';

const PublicComponentWrapper = (props) => {
  const dispatch = useDispatch();
  const branding = useSafeBranding();
  const theme = useSelector(({ uiSettings }) => uiSettings.theme);

  const logoUrl = useMemo(
    () => {
      if (theme === 'dark') {
        return branding?.darkModeLoginLogoUrl;
      }
      return branding?.loginLogoUrl;
    },
    [branding, theme]
  );

  useEffect(() => {
    checkSSO()
      .then((resp) => {
        if (resp.data) {
          dispatch(setSSOProperties(resp.data));
        }
      })
      .catch((error) => console.error(error));
  }, [dispatch]);
  useEffect(() => {
    fetchBrandingByDomain()
      .then(({ data }) => {
        if (!_.isEmpty(data)) {
          dispatch(setBrandingProperties(data));
        }
      })
      .catch((error) => {
        console.log('Error in fetching branding by domain: ', error);
      });
  }, [dispatch]);

  return (
    <>
      <main className={props.classes.publicContentWrapper}>
        <div className={props.classes.publicContentContainer}>
          <LogoHeader logoUrl={logoUrl} />
          <section className={props.classes.publicSection}>
            {props.children}
          </section>
        </div>
      </main>
      <Footer
        privacyPolicyLink={branding?.privacyPolicyLink}
        termsLink={branding?.termsAndConditionsLink}
      />
    </>
  );
};

const backgroundImages = {
  dark: 'public-dark',
  light: 'public-light',
};

const styles = (theme) => {
  const { palette, brandingPublicBackground, brandingPublicBackgroundDark } =
    theme;

  let backgroundImage = `${palette.background.public} url(/${backgroundImages[palette.type]
    }.jpg) no-repeat`;

  if (brandingPublicBackgroundDark && palette.type === 'dark') {
    backgroundImage = `${palette.background.public} url(${brandingPublicBackgroundDark
      }) no-repeat`;
  }
  if (brandingPublicBackground && palette.type === 'light') {
    backgroundImage = `${palette.background.public} url(${brandingPublicBackground
      }) no-repeat`;
  }

  return {
    publicContentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'inherit',
      backgroundSize: 'cover',
      background: backgroundImage,

      '& .public-logo': {
        marginBottom: 40,
        maxWidth: 400,
      },
    },

    publicContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 500,
    },

    publicSection: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '& a': {
        textDecoration: 'underline',
      },
    },
  };
};

export default withStyles(styles)(withRouter(PublicComponentWrapper));
