import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import ZButton from 'UI/Buttons/ZButton';
import GetAppIcon from '@material-ui/icons/GetApp';

import Success from '@material-ui/icons/CheckCircleOutline';

const UploadSuccess = ({
  message,
  errorMessage,
  handleCloseModal,
  isShowing = false,
  download,
  classes,
}) => {
  // need a spinner as well.  at times the progresss hits 100 but hangs on response from service
  if (!isShowing) {
    return null;
  }
  return (
    <Fragment>
      <p>
        <Success className={classes.checkCircleOutline} />
        <span className={classes.successText}>{errorMessage}</span>
        <span className={classes.successText}>{message}</span>
      </p>
      {download && (
        <div className="flex--column--spaced">
          <ZButton
            action={download}
            color="primary"
            styleName="modalSave"
            buttonText="Download"
            icon={{ elem: GetAppIcon }}
          />
        </div>
      )}
      <DialogActions>
        <ZButton
          action={handleCloseModal}
          color="primary"
          styleName="modalSave"
          buttonText="Close"
        />
      </DialogActions>
    </Fragment>
  );
};

const styles = ({ palette }) => {
  return {
    checkCircleOutline: {
      color: palette.primary.main,
      verticalAlign: 'bottom',
    },
    successText: {
      color: palette.text.secondary,
      whiteSpace: 'pre',
    },
  };
};

UploadSuccess.propTypes = {
  isShowing: PropTypes.bool,
  message: PropTypes.string,
  handleCloseModal: PropTypes.func,
};

UploadSuccess.defaultProps = {
  isShowing: false,
  message: 'Your upload is a success!',
};

export default withStyles(styles)(UploadSuccess);
