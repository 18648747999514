import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

// components
import { GlobalMenuOption } from 'components/Selects/MultiSelect/GlobalMenuOption';
import ZButton from 'UI/Buttons/ZButton';
import { FormikTextField } from 'components/inputs/FormikTextField';
import { FormikMultiSelect } from 'components/inputs/FormikMultiSelect';

// ui
import Tooltip from '@material-ui/core/Tooltip';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';

// service
import { fetchGroupsByTeamId } from 'api/GroupService';
import { createNewApp, editSelectedApp } from 'api/InAppsService';

// utils
import {
  toggleModalDiffered,
  openSnackBar,
  toggleModalDirect,
} from 'utils/storeUtils';

// actions
import { publishEvent } from 'utils/eventUtils';

// selectors
import {
  getAvailableTeamsAsSelectList,
  getAvailableTeams,
} from 'reducers/TeamReducers';

import { getUISettingsWithout } from 'reducers/UiSettingsSelectors';

import { getSelectedTeam, updateUISettings } from 'reducers/UiSettingsReducers';
import { FormikCheckbox } from 'components/inputs/FormikCheckbox';
import {
  csvInjectionRegex,
  csvInjectionErrorMessage,
  generateMultiSelectValue,
} from 'utils/componentUtils';
import { fetchAvailableEntitlements } from 'api/ZappService';
import { GlobalMultiSelectButton } from '../Selects/MultiSelect/GlobalMultiSelectButton';

const initialState = {
  name: '',
  bundleId: '',
  description: '',
  customHostname: window.location.hostname,
  group: [],
  team: [],
  devModeEnabled: false,
  customHostnameTextFieldEnabled: false,
  activationLimit: null,
  errors: {
    generalNetworkError: '',
  },
  groupOptions: [],
};

class AppsCreateEdit extends PureComponent {
  state = initialState;

  editMode = false;

  initialTeamId = null;

  constructor(props) {
    super(props);
    const teamId = _.get(props.data, 'team.id') || props.selectedTeam;

    this.initialTeamId = teamId;
    this.editMode = props.data?.id?.length;

    // only show on creating an app AND a singleTenant
    this.showCustomHostname =
      _.get(props, 'systemProperties.singleTenantMode', 'false') === 'true' &&
      this.editMode === false;

    /* will show in edit mode if custom hostname exists.   checkbox not to be toggled in edit mode
    user must delete app and re-create */
    this.showCustomHostnameReadOnly = !_.isEmpty(props.data.customHostname);
    this.state = this.editMode
      ? {
          ...initialState,
          ..._.pick(props.data, [
            'name',
            'bundleId',
            'description',
            'devModeEnabled',
            'activationLimit',
            'callbackUrl',
            'customHostname',
          ]),
          group: {
            label: props.data.groupName,
            value: props.data.groupId,
          },

          team: {
            label: props?.availableTeams[teamId]?.name,
            value: teamId,
          },
        }
      : {
          ...initialState,
          team: {
            label: props?.availableTeams[teamId]?.name,
            value: teamId,
          },
          group: this.state.groupOptions[0],
        };

    this.fetchGroups = this.fetchGroups.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.success = this.success.bind(this);
    this.error = this.error.bind(this);
  }

  componentDidMount() {
    fetchAvailableEntitlements().then(availableEntitlementValues =>
      this.setState(
        {
          availableEntitlementValues,
        },
        () => {
          // call setState in callback after setting availableEntitlementValues since selectedEntitlementOptions depends on it
          this.setState({
            selectedEntitlementOptions: this.getSelectedEntitlementOptions(),
          });
        }
      )
    );
    if (!_.isEmpty(this.props.data)) {
      return this.initialEditModeFetchGroups();
    }
    return this.fetchGroups();
  }

  getSelectedEntitlementOptions = () => {
    if (!this.editMode)
      return this.state.availableEntitlementValues.filter(
        ent => ent.value === 128
      );
    const entitlementValue = _.get(
      this.props,
      'data.originalEntitlementValue',
      _.get(this.props, 'data.entitlements', 0)
    );

    const enabledMask = _.reduce(
      this.state.availableEntitlementValues,
      (acc, e) => e.value + acc,
      0
    );

    // eslint-disable-next-line no-bitwise
    const mask = (parseInt(entitlementValue) - 1) & enabledMask;
    let c = 0;
    const returnList = [];
    let t = mask;
    while (t > 0) {
      // eslint-disable-next-line no-bitwise
      const n = 1 & t;
      const ent = _.find(
        this.state.availableEntitlementValues,
        // eslint-disable-next-line no-loop-func,no-bitwise
        e => e.value === n << c
      );
      if (ent) {
        returnList.push(ent);
      }
      c += 1;
      // eslint-disable-next-line no-bitwise
      t >>= 1;
    }

    return returnList;
  };

  render() {
    const { state, props } = this;
    return (
      <DialogContent>
        <Formik
          initialValues={{
            name: _.get(props, 'data.name', ''),
            bundleId: _.get(props, 'data.bundleId', ''),
            description: _.get(props, 'data.description', ''),
            devModeEnabled: _.get(props, 'data.devModeEnabled', false),
            activationLimit: _.get(props, 'data.activationLimit', ''),
            callbackUrl: _.get(props, 'data.callbackUrl', ''),
            customHostname: _.get(props, 'data.customHostname', ''),
            customHostnameTextFieldEnabled: false,
            teamId: state.team,
            groupId: state.group,
            selectedEntitlementOptions: state.selectedEntitlementOptions,
          }}
          validationSchema={AppSchema}
          validateOnBlur
          enableReinitialize
          onSubmit={this.handleSubmit}
        >
          {({ dirty, isSubmitting, setFieldValue, values }) => {
            return (
              <Form>
                <Field
                  name="name"
                  label="App Name"
                  component={FormikTextField}
                />
                <Field
                  name="bundleId"
                  label="Bundle ID"
                  component={FormikTextField}
                />

                <Field
                  name="customHostnameTextFieldEnabled"
                  isShowing={this.showCustomHostname}
                  checked={values.customHostnameTextFieldEnabled}
                  component={FormikCheckbox}
                  // type="checkbox"
                  label={
                    <Tooltip
                      title="Only define a custom callback if you're using a separate load balancer with SSL"
                      enterDelay={200}
                    >
                      <span> Enable Custom Domain</span>
                    </Tooltip>
                  }
                  onChange={e =>
                    this.handleCustomHostnameCheckbox(e, setFieldValue, values)
                  }
                />

                <Field
                  isShowing={
                    this.showCustomHostname || this.showCustomHostnameReadOnly
                  }
                  disabled={!values.customHostnameTextFieldEnabled}
                  name="customHostname"
                  label="Custom Domain"
                  component={FormikTextField}
                />
                <Field
                  name="description"
                  label="Description"
                  component={FormikTextField}
                />
                <Field
                  name="selectedEntitlementOptions"
                  label="Entitlements"
                  isMulti
                  component={FormikMultiSelect}
                  options={state.availableEntitlementValues}
                  onChange={(name, optionSelected) => {
                    setFieldValue('selectedEntitlementOptions', optionSelected);
                  }}
                />
                <Grid container spacing={8}>
                  <Grid item md={6}>
                    <Field
                      name="teamId"
                      label="Teams"
                      isMulti={false}
                      component={FormikMultiSelect}
                      options={props.availableTeamsSelectList}
                      onChange={(name, optionSelected) => {
                        setFieldValue('teamId', optionSelected);
                        this.fetchGroups(true, optionSelected, setFieldValue);
                      }}
                      // fetch when changed
                    />
                  </Grid>
                  {state.groupOptions && (
                    <Grid item md={6}>
                      <Field
                        label="Group"
                        name="groupId"
                        component={FormikMultiSelect}
                        components={{
                          Option: GlobalMenuOption,
                          DropDownButton: GlobalMultiSelectButton,
                        }}
                        isMulti={false}
                        options={state.groupOptions}
                        onChange={(name, optionSelected) => {
                          setFieldValue('groupId', optionSelected);
                        }}
                      />
                    </Grid>
                  )}
                </Grid>

                <Field
                  name="activationLimit"
                  label="Activation Limit"
                  component={FormikTextField}
                  type="number"
                  inputProps={{
                    min: 0,
                  }}
                />

                <Field
                  name="devModeEnabled"
                  label="Enable Dev Mode"
                  component={FormikCheckbox}
                  type="checkbox"
                  checked={values.devModeEnabled}
                />
                {state.generalNetworkError && (
                  <Typography variant="body1" color="error" align="center">
                    <p>Error: {state.generalNetworkError}</p>
                  </Typography>
                )}

                {this.editMode &&
                  !_.isEqual(
                    this.initialTeamId,
                    _.get(state, 'team.value')
                  ) && (
                    <p>
                      NOTE: Existing threat data for this app will NOT be
                      migrated to the selected team.
                    </p>
                  )}

                <DialogActions>
                  <ZButton
                    styleName="submit"
                    action={toggleModalDiffered('AppsCreateEdit', false)}
                    color="secondary"
                    buttonText="Cancel"
                  />

                  <ZButton
                    buttonType="submit"
                    color="primary"
                    styleName="modalSave"
                    buttonText="Save App"
                    proLabel
                  />
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    );
  }

  initialEditModeFetchGroups() {
    const { props } = this;
    const teamId = _.get(props.data, 'team.id') || props.selectedTeam;
    fetchGroupsByTeamId({ teamId }).then(response => {
      const groupOptions = response.data.map(group => ({
        label: group.name,
        value: group.id,
        accountBounded: !group.team,
      }));
      const group = generateMultiSelectValue(
        _.get(props, 'data.groupId'),
        groupOptions
      );
      this.setState({
        groupOptions,

        group: group[0] || [],
      });
    });
  }

  fetchGroups(teamChange = false, teamSelected, setFieldValue) {
    /* when the team dropdown changes, the groups need
    to be fetched with the teamId and repopulate the dropdown
    */
    const { state } = this;
    const teamId = teamSelected
      ? _.get(teamSelected, 'value')
      : state.team.value;

    fetchGroupsByTeamId({ teamId }).then(response => {
      const groupOptions = response.data.map(group => ({
        label: group.name,
        value: group.id,
        accountBounded: !group.team,
      }));

      this.setState(
        {
          groupOptions,
          ...(!this.editMode && { group: groupOptions[0] }),
          // ...(this.editMode && {group: })
        },
        () => {
          return setFieldValue && setFieldValue('groupId', groupOptions[0]);
        }
      );

      // setFieldValue
      // setFieldValue && setFieldValue('groupId', groupOptions[0]);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  handleCustomHostnameCheckbox(event, setFieldValue, values) {
    setFieldValue(
      'customHostnameTextFieldEnabled',
      !values.customHostnameTextFieldEnabled
    );
    if (event.target.value === 'true') {
      setFieldValue('customHostname', '');
    }
  }

  handleSubmit(values) {
    const { props } = this;

    // "1 + ..." because DETECTION_ONLY_AFTER_LOGIN(1) is always set -> ENG-937
    values.entitlements =
      1 +
      _.reduce(
        values.selectedEntitlementOptions,
        (sum, obj) => sum + obj.value,
        0
      );

    if (this.editMode) {
      const updateFnc = () => {
        editSelectedApp({ appId: props.data.id }, values)
          .then(() => {
            openSnackBar(`App ${values.name} Successfully Updated`);
            publishEvent('table:force-fetch-InApps');
            this.success();
          })
          .catch(this.error);
      };

      // Show confirmation before updating entitlements...
      if (props.data.entitlements !== values.entitlements) {
        toggleModalDirect('AppsChangeEntitlements', {
          originalData: props.data,
          formValues: values,
          updateFnc,
        });
      } else {
        updateFnc();
      }
    } else {
      /* only on creation of an app --if customHostname is entered and different from window.location.hostname then include in payload */
      createNewApp(
        {},
        {
          ...values,
          customHostname:
            !_.isEmpty(values.customHostname) &&
            values.customHostname !== window.location.hostname
              ? values.customHostname
              : undefined,
        }
      )
        .then(() => {
          openSnackBar(`App ${values.name} Created`);
          publishEvent('table:force-fetch-InApps');
          this.success();
        })
        .catch(error => this.error(error));
    }
  }

  success() {
    // modal
    toggleModalDirect('AppsCreateEdit', false);
    // save user settings
    this.props.updateUISettings({
      domain: 'inapps',
      update: { orderBy: 'created', order: 'desc' },
    });
  }

  error(error) {
    const generalNetworkError = _.get(
      error,
      'response.data.errors[0].defaultMessage',
      'Unexpected Error'
    );

    this.setState({
      generalNetworkError,
    });
  }
}

AppsCreateEdit.propTypes = {
  data: PropTypes.shape({
    bundleId: PropTypes.string,
    created: PropTypes.string,
    description: PropTypes.string,
    groupId: PropTypes.string,
    groupName: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    team: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  }),
};

export const createModalConfig = {
  title: 'Add New App',
  fullWidth: true,
};

export const editModalConfig = {
  title: 'Edit App',
  fullWidth: true,
};

const AppSchema = Yup.object().shape({
  bundleId: Yup.string()
    .matches(csvInjectionRegex, csvInjectionErrorMessage)
    .required('Bundle Id is required field.'),
  customHostname: Yup.string().when('customHostnameTextFieldEnabled', {
    is: true,
    then: Yup.string()
      .matches(
        /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/,
        'Must be a valid domain'
      )
      .required(),
  }),
  customHostnameTextFieldEnabled: Yup.boolean(),
  description: Yup.string().matches(
    csvInjectionRegex,
    csvInjectionErrorMessage
  ),
  groupId: Yup.array().min(1, 'Must select at least one group.').nullable(),
  name: Yup.string()
    .matches(csvInjectionRegex, csvInjectionErrorMessage)
    .required('App Name is a required field.'),
});

const mapStateToProps = state => {
  return {
    availableTeams: getAvailableTeams(state),
    availableTeamsSelectList: getAvailableTeamsAsSelectList(state),
    selectedTeam: getSelectedTeam(state),
    selectedTeamIds: state.teams.selectedTeamsAppsTable,
    systemProperties: getUISettingsWithout(state, 'systemProperties', [
      'tableHeaders',
    ]),
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateUISettings,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppsCreateEdit);
