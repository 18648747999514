import AddIcon from '@material-ui/icons/Add';
import { partnerUsersRowMapping } from 'components/admin/partnerUsers/partnerUsers.mapping';
import withRouter from 'components/hocs/withRouter';
import ReactToCSV from 'utility/ReactCSVDownloader';
import { withDirtyState } from 'utils/withDirtyState';
import withMemoizedProps from 'utils/withMemoizedProps';

import Table from 'components/UI/Table';
import {
  IColumnHeader,
  IFilterEditor,
  ILocationQuery,
} from 'components/UI/Table/models';
import useStyles from 'console/GlobalHeader/useStyles';
import { connect } from 'react-redux';
import {
  partnerUsersTableColumnChange,
  updateUISettings,
} from 'reducers/UiSettingsReducers';

import { useCallback, useMemo, useState } from 'react';
import {
  getChangedTableHeadersHash,
  getUISettingsTableHeaders,
  getUISettingsWithout,
} from 'reducers/UiSettingsSelectors';
import { bindActionCreators, compose } from 'redux';
import { toggleModalDirect } from 'utils/storeUtils';
import useBackfill from 'utils/useBackfill';

import Button from 'components/UI/Button';
import { IFilterValues } from './models';
import { resolveFilters } from './partnerUsers.mapping';
import tableQuery from './tableQuery';

const INITIAL_FILTER_VALUES: IFilterValues = {
  firstName: '',
  lastName: '',
  email: '',
};
interface IPartnerUserProps {
  accountId?: string;
  partnerUsersTableColumnChange: (...args: unknown[]) => void;
  partnerUsersUiSettings: { [key: string]: unknown };
  currentTableHeaders: IColumnHeader[];
  classes: any;
  definedUser?: string;
  jiggleDirtyState: (...args: any) => void;
  q: { [key: string]: unknown };
  rqps: string[];
  scopes: { [key: string]: string[] };
  updateUISettings: (...args: any) => void;
  updateUrl: (...args: any) => void;
  scopeBounds: string;
}
const PartnerUsers: React.FC<IPartnerUserProps> = ({
  accountId,
  partnerUsersTableColumnChange,
  partnerUsersUiSettings,
  currentTableHeaders,
  definedUser,
  jiggleDirtyState,
  q: query,
  rqps,
  updateUISettings,
  updateUrl,
}) => {
  useBackfill({
    query,
    requiredQueryParams: rqps,
    storedQueryParams: partnerUsersUiSettings,
    updateUrl: updateUrl,
  });
  const classes = useStyles();

  const handleColumnChange = useCallback(
    (...args: any) => {
      jiggleDirtyState();
      partnerUsersTableColumnChange(...args);
    },
    [jiggleDirtyState, partnerUsersTableColumnChange]
  );
  const [filterValues, setFilterValues] = useState<IFilterValues>(
    INITIAL_FILTER_VALUES
  );
  const filters = useMemo(() => resolveFilters(filterValues), [filterValues]);

  const onPaginationChange = useCallback(
    (update: Partial<ILocationQuery>) => {
      updateUISettings({
        domain: 'partnerUsersAdmin',
        update,
      });

      updateUrl(update);
    },
    [updateUISettings, updateUrl]
  );
  const handleFilterChange = useCallback((filterEditors: IFilterEditor[]) => {
    const newFilterValues: IFilterValues = {};
    filterEditors.forEach(({ name, value }) => {
      newFilterValues[name] = value;
    });
    setFilterValues(newFilterValues);
  }, []);

  return (
    <div>
      <div className="view__header">
        <h1>Partner Users</h1>
        <Button
          onClick={() =>
            toggleModalDirect(
              'PartnerUsersCreateEdit',
              {
                accountId: accountId && accountId,
              },
              {
                title: 'Add Partner User',
                fullWidth: true,
              }
            )
          }
          text="Add Partner User"
          color="secondary"
          icon={AddIcon}
        />
      </div>
      <Table
        
       classList={classes}
      
        columnHeaders={currentTableHeaders}
        filters={filters}
        onFilterValueChange={handleFilterChange as () => void}
        definedUser={definedUser}
        fetchTableData={() =>
          tableQuery(filters)(query as unknown as ILocationQuery)
        }
        onColumnChange={handleColumnChange}
        onPaginationChange={onPaginationChange}
        query={query as unknown as ILocationQuery}
        rowMapping={partnerUsersRowMapping}
        tableId="PartnerUsers"
        footerComponents={() => (
          <ReactToCSV
            buttonText="exportCSV"
            dataMap={partnerUsersRowMapping}
            fileName="partner-users-table"
            header={currentTableHeaders.filter(
              (column) => column.id !== 'actions'
            )}
            table={() =>
              tableQuery(filters)(query as unknown as ILocationQuery)
            }
          />
        )}
      />
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  accountId: state.auth.partnerUser.accountId,
  currentTableHeaders: getUISettingsTableHeaders(state, 'partnerUsers'),
  partnerUsersColumnHeadersHash: getChangedTableHeadersHash(
    state,
    'partnerUsers'
  ),
  partnerUsersUiSettings: getUISettingsWithout(state, 'partnerUsers', [
    'tableHeaders',
  ]),
  scopes: state.auth.user.scopes,
  scopeBounds: state.user.scopeBounds,
});
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      partnerUsersTableColumnChange,
      updateUISettings,
    },
    dispatch
  );

export default compose(
  withRouter,
  withDirtyState(),
  connect(mapStateToProps, mapDispatchToProps)
)(
  withMemoizedProps(PartnerUsers, [
    'partnerUsersUiSettings',
    'createEditModal',
    'currentTableHeaders',
    'dirtyState',
    'q',
    'updateUISettings',
  ])
);
