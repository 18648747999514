import { DialogTitle, withStyles } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ZButton from 'UI/Buttons/ZButton';
import { deleteAppPolicy } from 'api/AppPolicyService';
import { deleteAppSettings } from 'api/AppSettingsService';
import { deletePhishingPolicy } from 'api/PhishingPolicyService';
import { deletePrivacyPolicy } from 'api/PrivacyPolicyService';
import AlertIcon from 'components/UI/icons/AlertIcon';
import withRouter from 'components/hocs/withRouter';
import _ from 'lodash';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import {
  openSnackBar,
  toggleModalDiffered,
  toggleModalDirect,
} from 'utils/storeUtils';
import { PubSub } from '../../utils/eventUtils';

class PolicyDeleteConfirm extends Component {
  constructor(props) {
    super(props);
    this.handleDeletePolicy = this.handleDeletePolicy.bind(this);
  }

  render() {
    const { props } = this;
    const { t } = this.props;
    const groupsAttached = _.get(props, 'data.groups', []);

    if (!groupsAttached.length) {
      return (
        <>
          <DialogTitle>
            {props.data.headerText}
          </DialogTitle>
          <DialogContent>
            <div className={props.classes.navigationWarning}>
              <p>
                {t('MTD.POLICIES.POLICY_CONFIRM_DELETE')}
              </p>
              <AlertIcon />
            </div>

            <DialogActions>
              <ZButton
                styleName="modalCancel"
                action={toggleModalDiffered('PolicyDeleteConfirm', false)}
                buttonText={t('MTD.POLICIES.NO_KEEP_POLICY')}
                color="primary"
              />
              <ZButton
                buttonType="submit"
                styleName="modalDelete"
                action={this.handleDeletePolicy}
                buttonText={t('MTD.POLICIES.YES_DELETE_POLICY')}
                color="primary"
              />
            </DialogActions>
          </DialogContent>
        </>
      );
    }

    return (
        <>
          <DialogTitle>
            {props.data.headerText}
          </DialogTitle>
          <DialogContent>
            <div className={props.classes.navigationWarning}>
              <div>
                <p>
                  {t('MTD.POLICIES.CAN_NOT_DELETE_POLICY_FOLLOWING_GROUPS', {
                    policyName: props.data.policyType,
                  })}
                </p>
                {groupsAttached.map(group => (
                  <li key={group.id}>{group.name}</li>
                ))}
              </div>
              <AlertIcon />
            </div>
          <DialogActions>
            <ZButton
              styleName="modalCancel"
              action={toggleModalDiffered('PolicyDeleteConfirm', false)}
              buttonText={t('GLOBAL.CANCEL')}
              color="primary"
            />
          </DialogActions>
        </DialogContent>
      </>
    );
  }

  async handleDeletePolicy() {
    const { props } = this;
    const { t } = this.props;
    const policyType = _.get(props, 'data.policyType', null);
    const id = _.get(props, 'data.policySelected.value', null);

    try {
      switch (policyType) {
        case 'Privacy':
          await deletePrivacyPolicy({ id });
          this.props.updateUrl({ privacy: undefined });
          PubSub.publish('PRIVACY_POLICY_UPDATED');
          break;
        case 'Phishing':
          await deletePhishingPolicy({ id });
          this.props.history.push(props.location.pathname);
          break;
        case 'AppSettings':
          await deleteAppSettings({ id });
          this.props.history.push(propswithStylescation.pathname);
          break;
        case 'App':
          await deleteAppPolicy({ id });
          this.props.history.push(props.location.pathname);
          break;
        default:
          break;
      }

      if (props.data.onSucess) {
        await props.data.onSucess();
      }

      openSnackBar(t('MTD.POLICIES.POLICY_SUCCESS_DELETE', {
        policyName: policyType,
      }));
    } catch (error) {
      console.log(`Error in Deleting ${policyType} Policy: `, error);
    } finally {
      toggleModalDirect('PolicyDeleteConfirm', false);
    }
  }
}

const styles = ({ palette }) => ({
  navigationWarning: {
    alignItems: 'center',
    display: 'flex',

    '& svg': {
      color: palette.common.tomato,
      height: 64,
      margin: '0 24px',
      minHeight: 64,
      minWidth: 64,
      width: 64,
    },
  },
});

export default compose(
  withStyles(styles),
  withRouter,
  withTranslation()
)(PolicyDeleteConfirm);
