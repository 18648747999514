import { atom } from 'recoil';
import { IZScanPolicyEntriesHash } from './models';

import { ISelectItem } from 'components/UI/input/Select';

export const zScanPolicyEntriesHash = atom<IZScanPolicyEntriesHash>({
  key: 'zscan-policy-entries',
  default: {},
});

export const zScanActivePolicyId = atom<string>({
  key: 'zscan-policyId-active',
  default: '',
});

export const zScanPolicyListAtom = atom<ISelectItem[]>({
  key: 'zscan-policy-list',
  default: undefined,
});
