import {
  Paper as MUIPaper,
  Table as MUITable,
  TableBody as MUITableBody,
  TableCell as MUITableCell,
  TableContainer as MUITableContainer,
  TableHead as MUITableHead,
  TableRow as MUITableRow,
  TableSortLabel as MUITableSortLabel,
} from '@material-ui/core';
import { TClassList } from '../Table/models';
import { IGenericColumnHeader, IGenericRowMapping, TRowData } from './models';
import useStyles from './useStyles';
import cc from 'classcat';
import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import ColumnHeaderCell from './ColumnHeaderCell';
import { useTranslation } from 'react-i18next';

interface IGenericTableProps {
  classList?: TClassList;
  columnHeaders: IGenericColumnHeader[];
  rowMapping: IGenericRowMapping[];
  tableData: TRowData[];
  tableId: string;
  noDataTableContent?: JSX.Element;
  draggable?: boolean;
  handleDragStart?: (rowIndex?: number | null | undefined) => void;
}

const GenericTable: React.FC<IGenericTableProps> = ({
  classList: parentClasses,
  columnHeaders: headers,
  noDataTableContent,
  rowMapping: rowMappings,
  tableData,
  draggable,
  handleDragStart,
  ...rest
}) => {
  const tableClasses = useStyles();
  const classes = { ...tableClasses, ...parentClasses };
  const { ready, t } = useTranslation();
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('');
  const [data, setData] = useState(tableData);

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const onSortClick = useCallback(
    (headerName: string) => {
      const newOrder = order === 'asc' ? 'desc' : 'asc';
      const sortedData = _.orderBy(data, [headerName], [newOrder]);

      setOrderBy(headerName);
      setOrder(newOrder);
      setData(sortedData);
    },
    [order, data]
  );
  if (!ready) {
    return null;
  }

  return (
    <MUIPaper>
      <MUITableContainer className={classes.tableContainer}>
        <MUITable>
          <MUITableHead className={classes.headerRow}>
            <MUITableRow>
              {headers.map((headerRow) => (
                <MUITableCell
                  key={`header-${headerRow.id}`}
                  className={cc([
                    classes.headerCell,
                    headerRow?.styleHeader,
                    {
                      [classes.checkboxCell]: headerRow?.isSelectAllCheckbox,
                    },
                  ])}
                  sortDirection={orderBy === headerRow.id ? order : false}
                >
                  {typeof headerRow?.sort === 'boolean' ? (
                    <MUITableSortLabel
                      active={orderBy === headerRow.id}
                      direction={orderBy === headerRow.id ? order : 'asc'}
                      onClick={() =>
                        headerRow?.sort ? onSortClick(headerRow.id) : undefined
                      }
                    >
                      <ColumnHeaderCell
                        headerRow={headerRow}
                        parentClasses={parentClasses}
                      />
                    </MUITableSortLabel>
                  ) : (
                    <ColumnHeaderCell
                      headerRow={headerRow}
                      parentClasses={parentClasses}
                    />
                  )}
                </MUITableCell>
              ))}
            </MUITableRow>
          </MUITableHead>
          <MUITableBody>
            {!data?.[0] && headers?.[0] && !!noDataTableContent && (
              <MUITableRow>
                <MUITableCell colSpan={headers.length}>
                  {noDataTableContent}
                </MUITableCell>
              </MUITableRow>
            )}
            {data?.map((rowData, rowIndex) => (
              <MUITableRow
                key={`row-${rowIndex}`}
                draggable={draggable}
                onMouseDown={() => handleDragStart?.(rowIndex)}
              >
                {headers.map((header) => {
                  const rowMapping = rowMappings.find(
                    ({ path }) => path === header.id
                  );
                  const value = rowData[header.id] ?? '';
                  const cellContent = !!rowMapping?.columnContent
                    ? rowMapping.columnContent(
                        {
                          // Passing in relevant table props for access in columnContent.
                          // Add more as necessary as table evolves.
                          classes,
                          classList: parentClasses,
                          rowData,
                          ...rest,
                        },
                        rowIndex,
                        data
                      )
                    : String(value !== 'Comment' ? value : '');

                  return (
                    <MUITableCell
                      key={`cell-${header.id}`}
                      className={cc([
                        classes.cell,
                        {
                          [classes.checkboxCell]: header?.isSelectAllCheckbox,
                        },
                      ])}
                    >
                      {cellContent}
                    </MUITableCell>
                  );
                })}
              </MUITableRow>
            ))}
          </MUITableBody>
        </MUITable>
      </MUITableContainer>
      <div className={classes.footer}>
        {t(`GLOBAL.ROW${data.length > 1 ? 'S' : ''}_TOTAL`, {
          dataLength: data.length,
        })}
      </div>
    </MUIPaper>
  );
};

export default GenericTable;
