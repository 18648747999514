import { DialogContent as MUIDialogContent } from '@material-ui/core';
import useAsyncResult from 'utils/useAsyncResult';
import Form from './Form';
import { IPolicyGroupOption } from './Form/models';
import { fetchPolicyGroups } from './Form/utils';
import { IData } from './models';
import { handleSubmit } from './utils';
import { useTranslation } from 'react-i18next';

interface IUserActivationsCreateEditProps {
  data: IData;
}

const GroupActivationsCreateEdit: React.FC<IUserActivationsCreateEditProps> = ({
  data,
}) => {
  const allPolicyGroups: IPolicyGroupOption[] =
    useAsyncResult(fetchPolicyGroups, data?.selectedTeamId || '') ?? [];

  const { t } = useTranslation();

  return (
    <MUIDialogContent>
      <Form
        allPolicyGroups={allPolicyGroups}
        data={data}
        handleSubmit={(...args) => handleSubmit(...args, t)}
      />
    </MUIDialogContent>
  );
};

export default GroupActivationsCreateEdit;
