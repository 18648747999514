import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette, shape }) => ({
  intune_error: {
    minWidth: 150,
    // padding: '7px 10px',
    marginTop: 19,
    // marginLeft: 10,
    // position: 'absolute',
    // top: '90%',
    // left: '40%',
    zIndex: 1,
    transform: 'translateY(-50%)',
    background: palette.error.light,
    color: palette.error.contrastText,
    borderRadius: shape.borderRadius,
    whiteSpace: 'pre-wrap',
  },
  errorBox: {
    background: palette.error.light,
    padding: '7px 10px',
  },
}));
export default function GenericErrorBox({ errorMessage, classList }) {
  const defaultClasses = useStyles();
  if (!errorMessage) return null;
  const classes = {
    ...defaultClasses,
    ...classList,
  };
  return (
    <div className={classes.errorBox}>
      <span className={classes.intune_error}>{errorMessage}</span>
    </div>
  );
}
