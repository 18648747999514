import { TClassList } from 'components/UI/Table/models';
import {
  IPolicyInfo,
  IZAppInstance,
} from 'components/main/devices/Devices/models';
import { IAdditionalForensicItem } from '../forensics/AdditionalForensic/models';

export interface IOS {
  id: number;
  name: string;
  patchDate: string;
  version: string;
}

export interface IDevice {
  id: string;
  mamDeviceId: string;
  mdmDeviceId: string;
  model: string;
  os: IOS;
  zdeviceId: string;
}

export interface ITimestampInfo {
  timestamp: number;
  toTheDay: number;
  toTheHour: number;
  toTheMinute: number;
  toTheSecond: number;
}

export interface IRowData {
  accountId: string;
  activationName: string;
  additionalPublicForensics: IAdditionalForensicItem[];
  arpTablesInfo: unknown;
  agentType: number;
  categoryId: number;
  certificates: unknown;
  device: IDevice;
  deviceId: string;
  deviceOwner: string;
  externalTrackingId1: string;
  externalTrackingId2: string;
  forensicsInfo: unknown;
  generalInfo: unknown;
  groupId: string;
  groupName: string;
  id: string;
  lastModified: number;
  locationInfo: unknown;
  mamDeviceId: string;
  malwareInfo: string;
  mitigatedAt: number;
  mitigationEvents: unknown[];
  nearByNetworks: unknown[];
  networkStatistics: unknown;
  os: string;
  policiesInfo: IPolicyInfo[];
  processList: unknown;
  publicForensicJson: {
    data: string;
  };
  routingTables: unknown;
  severity: number;
  severityName: string;
  sideLoadedAppInfo: unknown;
  simulated: boolean;
  state: number;
  suspiciousUrlInfo: unknown;
  teamId: string;
  teamName: string;
  threatDescription: string;
  threatServerName: string;
  threatTypeId: number;
  threatTypeName: string;
  timestamp: number;
  timestampInfo: ITimestampInfo;
  vector: number;
  vectorName: string;
  zappId: string;
  zappInstance: IZAppInstance;
  zeventId: string;
  serverName?: string;
  description?: string;
}

export interface IResponseData {
  content: IRowData[];
}

export interface IThreatsResponse {
  data: IResponseData;
  totalElements: number;
}

export interface IThreatDrawerData {
  classList: string;
  module: string;
  rowData: IRowData;
}

export interface IFullData {
  classList: TClassList;
  classes: TClassList;
  isShowingDetails: boolean;
  rowData: IRowData;
  tableId: string;
}

export interface IThreatsUISettings {
  [key: string]: number | string;
  duration: number;
  order: string;
  orderBy: string;
  page: number;
  size: number;
  tab: string;
  zappId: string;
}

export interface ISeverityBuckets {
  [key: number | string]: { doc_count: number; };
}

export interface ISeverityFilter {
  buckets: ISeverityBuckets;
}

export interface ITimeBucket {
  doc_count: number;
  key: number;
  'filters#severities': ISeverityFilter;
  'cardinality#appInstances': { value: number; };
}

export interface IThreatStats {
  critical: number[];
  elevated: number[];
  labels: string[];
  parameters: number[];
  threatCounts: number[];
}

export interface IMITRETactic {
  id: number;
  mitreId: string;
  name: string;
  url: string;
}

export interface IMappingThreatType {
  category: number;
  classification: number | null;
  color: string;
  defaultSholdCollect: boolean;
  description: string;
  distributions: number[];
  friendlyName: string;
  fullName: string;
  id: number;
  isServer: boolean;
  langCode: string;
  mitigation: string | null;
  mitreTactics: IMITRETactic[];
  name: string;
  operatingSystems: number[];
  serverName: string;
  severity: number;
  shortLangCode: string;
  shortName: string;
  status: number;
  vector: number;
}

export interface IThreatTypesFromApi {
  [key: number]: IMappingThreatType;
}

export interface IGroup {
  emmConnectionId: string | null;
  id: string;
  name: string;
  team: { name: string; id: string | null; };
}

export interface IFilterValues {
  [index: string]: number | string | undefined | null | Date;
  activationName?: '';
  os?: '';
  severity?: '';
  state?: '';
  threatTypeId?: '';
  teamName?: '';
  vector?: '';
  zappName?: '';
}

export const PLATFORMS = [
  {
    label: 'Android',
    value: 'android',
  },
  {
    label: 'iOS',
    value: 'ios',
  },
  {
    label: 'Chrome OS',
    value: 'chrome os'
  }
];
