import { IFilterEditor, ILocationQuery } from 'components/UI/Table/models';
import memoizeTableQuery from 'components/UI/Table/utils/memoizeTableQuery';
import { fetchTableData } from 'utils/tableUtils';
import { IAccountPlan, IManagedAccount } from './model';

const url = 'api/auth/v2/managed_accounts?';

const memoizeQuery = (filters: IFilterEditor[]) => {
  return memoizeTableQuery(async (query: ILocationQuery) => {
    const rawTableData = await fetchTableData(query, filters, url, undefined, false);

    const tableData: IManagedAccount[] = rawTableData?.data?.map((managedAccount: IManagedAccount) => ({
      ...managedAccount,
      planName: (managedAccount?.subscription?.plans ?? [])
        .map(({ name }: IAccountPlan) => name)
        .join(', '),
    }));

    return {
      data: tableData ?? [],
      count: tableData?.length ?? 0
    }
  });
};

export default memoizeQuery;
