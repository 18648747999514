import { IFilterEditor, ILocationQuery } from 'components/UI/Table/models';
import memoizeTableQuery from 'components/UI/Table/utils/memoizeTableQuery';
import { fetchTableData } from '../../../utils/tableUtils';
const url = '/api/auth/v1/superusers?';
const memoizeQuery = (filters: IFilterEditor[]) => {
  return memoizeTableQuery(async (query: ILocationQuery) => {
    return await fetchTableData(query, filters, url, undefined, false);
  });
};

export default memoizeQuery;
