import Devices from 'components/main/devices/Devices';
import Policies from 'components/main/policies/Policies';
import InApps from 'components/main/in-apps/InApps';
import Threats from 'components/main/threats/Threats';

// icons
import ActivationsIcon from '@material-ui/icons/TouchApp';
import DashboardIcon from '@material-ui/icons/BarChart';
import DeviceIcon from '@material-ui/icons/PhonelinkRing';
import PolicyIcon from '@material-ui/icons/VerifiedUser';
import ThreatIcon from '@material-ui/icons/Camera';
import ZDefendInsights from '../../components/main/ZDefendInsights';

export const CONSOLE_ZIAP_ROUTES = [
  {
    path: '/console/zdefend',
    component: ZDefendInsights,
    exact: true,
    allow: 'any',
    rqps: ['teamId', 'zappId', 'duration'],
    nav: {
      name: 'Dashboard',
      order: 1,
      icon: <DashboardIcon />,
    },
  },
  {
    path: '/console/zdefend/apps',
    component: InApps,
    exact: true,
    allow: { zapps: 'view' },
    nav: {
      name: 'Activations',
      order: 2,
      icon: <ActivationsIcon />,
    },
  },
  {
    path: '/console/zdefend/devices',
    component: Devices,
    exact: true,
    allow: { devices: 'view' },
    rqps: ['page', 'size', 'zappId', 'order', 'orderBy', 'duration'],
    nav: {
      name: 'Devices',
      order: 3,
      icon: <DeviceIcon />,
    },
  },
  {
    path: '/console/zdefend/threats',
    component: Threats,
    exact: true,
    allow: { threats: 'view' },
    rqps: ['page', 'size', 'zappId', 'order', 'orderBy', 'duration'],
    nav: {
      name: 'Threats',
      order: 4,
      icon: <ThreatIcon />,
    },
  },
  {
    path: '/console/zdefend/policies/:tabId',
    component: Policies,
    allow: { policies: 'view' },
  },
  {
    path: '/console/zdefend/policies',
    component: Policies,
    exact: true,
    allow: { policies: 'view' },
    nav: {
      name: 'Policies',
      order: 5,
      icon: <PolicyIcon />,
    },
  },
];
