import React, { useMemo } from 'react';
import useStyles from './useStyles';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { IMultiLinePlotChartSegment } from './models';
import HorizontalLegend from '../HorizontalLegend';

export interface IMultiLinePlotChartProps {
  graduations: string[];
  height?: number;
  segments: IMultiLinePlotChartSegment[];
  withLegend?: boolean;
}

const MultiLinePlotChart: React.FC<IMultiLinePlotChartProps> = ({
  graduations,
  height,
  segments,
  withLegend,
}) => {
  const classes = useStyles();

  const chartData = useMemo(() => {
    return graduations.map((graduation, index) => ({
      name: graduation,
      ...Object.fromEntries(
        new Map<string, number>(
          segments.map(({ label, plot }) => [label, plot[index]])
        )
      ),
    }));
  }, [graduations, segments]);

  const chartLines = useMemo(
    () =>
      segments.map(({ label, color }, index) => (
        <Line
          key={`${label}-${index}`}
          dot={false}
          type="linear"
          dataKey={label}
          stroke={color}
        />
      )),
    [segments]
  );

  const legendItems = useMemo(
    () =>
      withLegend
        ? segments.map(({ color, label, plot }) => ({
            color,
            label,
            value: Math.trunc(plot.reduce((a, c) => a + c) / plot.length),
          }))
        : [],
    [segments, withLegend]
  );

  return (
    <div className={classes.multiLinePlot}>
      <div className={classes.section}>
        <ResponsiveContainer width="100%" height={height ?? 225}>
          <LineChart
            data={chartData}
            height={300}
            margin={{ bottom: 5, left: 20, right: 30, top: 5 }}
            width={500}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              axisLine={false}
              dataKey="name"
              tickLine={{ stroke: 'rgba(0,0,0,0)' }}
              tickMargin={15}
            />
            <YAxis
              axisLine={false}
              tickLine={{ stroke: 'rgba(0,0,0,0)' }}
              tickMargin={30}
            />
            {chartLines}
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className={classes.section}>
        {!!withLegend && <HorizontalLegend items={legendItems} />}
      </div>
    </div>
  );
};

export default MultiLinePlotChart;
