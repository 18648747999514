import { IConnector } from 'components/main/integrations/emm/models';
import { ISelectItem } from 'components/UI/input/Select';
import { IMDMRowData } from '../../emmTable/MDM/models';
import { IStandardFormData } from './models';
import { TModalMode } from '../../models';

export const initialValues = (
  modalMode: TModalMode,
  rowData?: IMDMRowData,
  connector?: IConnector,
  proxyOptions?: ISelectItem[],
  availableTeams?: ISelectItem[],
  selectedTeamId?: string
): IStandardFormData => {
  return {
    baseUrl: rowData?.baseUrl ?? '',
    connectionId: rowData?.id ?? '',
    connectorTypeId: connector?.id ?? '',
    modalMode,
    mode: connector?.supportedModes?.[0] ?? '',
    name:
      modalMode === 'CLONE' ? `${rowData?.name} (Cloned)` : rowData?.name ?? '',
    password: '',
    proxyConfig: !!proxyOptions?.[0]
      ? proxyOptions.find(
          ({ value }) => value === rowData?.auth?.proxy_config_id
        ) ?? { label: 'None', value: null }
      : undefined,
    team: !!availableTeams?.[0]
      ? availableTeams.find(
          ({ value }) => value === (rowData?.teamId ?? selectedTeamId)
        )
      : undefined,
    user_id: rowData?.auth?.user_id ?? '',
  };
};
