import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
/* component */
import {
  fetchAdminAccountById,
  serializeAdminAccount,
} from 'api/AdminAccountService';
import AccountDetailsData from './AccountDetailsData';

const styles = themeSelected => {
  const {
    palette: { background },
  } = themeSelected;
  return {
    card: {
      background: background.paper,
      outline: 'none',
      padding: '0',
    },
    cardContent: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      padding: 8,
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    dataWrapper: {
      width: '50%',
    },
  };
};

class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    fetchAdminAccountById(this.props.account.id).then(response => {
      const readOnlyState = serializeAdminAccount(response.data);
      this.setState({
        ...readOnlyState,
      });
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.dataWrapper}>
            <AccountDetailsData account={this.state} dataProperty="column1" />
          </div>
          <div className={classes.dataWrapper}>
            <AccountDetailsData account={this.state} dataProperty="column2" />
          </div>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(AccountDetails);
