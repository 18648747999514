export enum TableAction {
  DeleteSelected = 'deleteSelected',
}

export interface ITableActionGroup {
  group: string;
  actions: { value: string; label: string; isDisabled?: boolean }[];
}

export const tableActions: ITableActionGroup[] = [
  {
    group: 'GLOBAL.SELECTED_ROWS',
    actions: [
      {
        value: TableAction.DeleteSelected,
        label: 'GLOBAL.DELETE_ROWS',
        isDisabled: false,
      },
    ],
  },
];
