import _ from 'lodash';
// ui
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ZButton from 'UI/Buttons/ZButton';
import Typography from '@material-ui/core/Typography';

// services
import {
  toggleModalDirect,
  openSnackBar,
  toggleModalDiffered,
} from 'utils/storeUtils';
import { deleteMDMConnection } from '../../api/MDMService';
import withRouter from 'components/hocs/withRouter';
import { useTranslation } from 'react-i18next';

const DeviceGroupsWarningBlock = ({ classList, deviceGroups }) => {
  if (!deviceGroups?.length) {
    return null;
  }
  const { t, ready } = useTranslation();

  if (!ready) {
    return null;
  }

  return (
    <>
      <p>{t('MTD.INTEGRATIONS.ATTACHED_DEVICE_GROUP')}</p>
      <ul className={classList}>
        {deviceGroups.map((group) => {
          return (
            <li key={_.get(group, 'singleGroup.groupIdsToSync.value')}>
              {' '}
              <Typography variant="body1">
                {_.get(group, 'singleGroup.groupIdsToSync.label')}
              </Typography>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export const MDMDeleteConfirm = (props) => {
  const { data } = props;
  const connectionId = _.get(data, 'rowData.id');
  const name = _.get(data, 'rowData.name');
  function handleDelete() {
    deleteMDMConnection({ connectionId })
      .then(() => {
        openSnackBar(`Successfully deleted ${name}`);
        props.updateUrl({ mdmId: Math.floor(Math.random() * 100) });
        toggleModalDirect('MDMDeleteConfirm', false);
      })
      .catch((error) => {
        openSnackBar(
          `Failed to delete ${data.name}. ${_.get(error, 'response.data', '')}`
        );
      });
  }

  const deviceGroups = _.get(data, 'rowData.connectedGroups');
  const { t, ready } = useTranslation();

  if (!ready) {
    return null;
  }
  return (
    <DialogContent>
      <p>
        {t('MTD.INTEGRATIONS.CONFIRM_DELETE_ROW_DATA', {
          data: data.rowData.name,
        })}{' '}
      </p>
      <DeviceGroupsWarningBlock
        classList={props?.data.classList.groupList}
        deviceGroups={deviceGroups}
      />
      <DialogActions>
        <ZButton
          styleName="modalCancel"
          action={toggleModalDiffered('MDMDeleteConfirm', false)}
          color="secondary"
          buttonText={t('GLOBAL.CANCEL')}
        />
        <ZButton
          action={handleDelete}
          buttonType="submit"
          color="primary"
          styleName="modalSave"
          buttonText={t('GLOBAL.DELETE')}
        />
      </DialogActions>
    </DialogContent>
  );
};

MDMDeleteConfirm.defaultProps = {
  data: {},
};

export default withRouter(MDMDeleteConfirm);