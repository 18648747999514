import { createStyles, makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    severityCircleWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    risk: {
      '& span': {
        marginRight: 10,
      },
      alignItems: 'center',
      display: 'flex',
      textTransform: 'capitalize',
    },
    icon: {
      borderRadius: 1000,
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 800,
    },
    bestPractices: {
      backgroundColor: palette.components.severityIcon.bestPractices,
    },
    error: {
      backgroundColor: palette.components.circleIcon.error,
    },
    success: {
      backgroundColor: palette.components.circleIcon.success,
    },
    warning: {
      backgroundColor: palette.components.circleIcon.warning,
    },
    low: {
      backgroundColor: palette.components.severityIcon.low,
    },
    medium: {
      backgroundColor: palette.components.circleIcon.warning,
    },
    high: {
      backgroundColor: palette.components.severityIcon.high,
    },
    informational: {
      backgroundColor: palette.components.severityIcon.informational,
    },
    unknown: {
      backgroundColor: 'transparent',
      borderStyle: 'dashed',
      borderColor: palette.components.circleIcon.unknown,
      borderWidth: 1,
    },
  })
);
