import { trimAndAddAsterisk } from 'utility/QueryUtility';

export const columnHeaders = (t) => ([ 
  { id: 'platform', label: t('GLOBAL.PLATFORM') },
  { id: 'action', label: t('GLOBAL.ACTION') },
  { id: 'criteria', label: t('GLOBAL.CRITERIA') },
  { id: 'numRows', label: `# ${t('GLOBAL.ROWS')}` },
  { id: 'actions', label: t('GLOBAL.ACTIONS') },
]);

export const fetchAppPolicyParamsMapping = [
  {
    path: 'page',
  },
  {
    path: 'size',
  },
  {
    path: 'ruleType',
  },
  {
    path: 'search',
    transform: ({ search = '' }) => {
      if (!search) {
        return null;
      }

      return trimAndAddAsterisk(search);
    },
  },
];
