import { getActiveModalAtom } from 'atoms/modals';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import Modal from 'components/UI/Modal';
import Form from './Form/index';
import useStyles from './useStyles';
import useAppCharacteristics from '../../AppCharacteristicProvider';
import { IActiveCreateTrigger } from './models';
export const APCCreateNewPolicy_TITLE = 'APCCreateNewPolicy';

const activeModalAtom = getActiveModalAtom<IActiveCreateTrigger>();

const APCCreateModal: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { appCharacteristicsList, setAppCharFormData } =
    useAppCharacteristics();
  const [data, setActiveModal] = useRecoilState(activeModalAtom);

  const appCharacteristicsListHash = useMemo(() => {
    let triggerHash = {};
    appCharacteristicsList?.map((singleCategory) => {
      triggerHash = singleCategory?.triggers?.reduce(
        (triggerAccum, triggerObject) => {
          return {
            ...triggerHash,
            ...triggerAccum,
            [triggerObject.name]: triggerObject,
          };
        },
        {}
      );
    });
    return triggerHash;
  }, [appCharacteristicsList]);

  const mapCategoryStringToFullCategory = useCallback(
    (keys) => {
      return keys?.map((singleCategoryString: string) => {
        if (appCharacteristicsListHash?.[singleCategoryString]) {
          return { ...appCharacteristicsListHash[singleCategoryString] };
        }
        return null;
      });
    },
    [appCharacteristicsListHash]
  );

  const handleClose = useCallback(() => {
    setActiveModal(undefined);
    setAppCharFormData(undefined);
  }, [setActiveModal, setAppCharFormData]);

  const payloadData = useMemo(() => {
    if (!data?.payload) {
      return;
    }

    const allTriggers = !data?.payload?.all?.length
      ? []
      : mapCategoryStringToFullCategory(data?.payload?.all);
    const anyTriggers = !data?.payload?.any?.length
      ? []
      : mapCategoryStringToFullCategory(data?.payload?.any);
    let listOfAllTriggersToBeDisabled = [];
    if (!data?.payload.listOfAllTriggersToBeDisabled) {
      listOfAllTriggersToBeDisabled = [...allTriggers, ...anyTriggers]?.map(
        (trigger) => trigger?.name
      );
    }
    const listOfAllCategoriesToBeChecked = [...allTriggers, ...anyTriggers];

    return {
      ...data?.payload,
      all: allTriggers,
      any: anyTriggers,
      listOfAllTriggersToBeDisabled,
      listOfAllCategoriesToBeChecked,
    };
  }, [data?.payload, mapCategoryStringToFullCategory]);

  const modalCaption = useMemo(() => {
    if (data?.payload?.action === 'clone') {
      return t('MTD.POLICIES.APP_POLICY.CLONE_EXISTING_RULE');
    }
    if (data?.payload?.action === 'edit') {
      return t('MTD.POLICIES.APP_POLICY.EDIT_RULE');
    }

    return t('MTD.POLICIES.APP_POLICY.CREATE_NEW_RULE');
  }, [data?.payload?.action, t]);

  return (
    <Modal
      caption={modalCaption}
      title={APCCreateNewPolicy_TITLE}
      onClose={handleClose}
      className={classes.modal}
    >
      <Form handleClose={handleClose} ruleData={payloadData} />
    </Modal>
  );
};

export default APCCreateModal;
