import React from 'react';
import _ from 'lodash';
import { TextField, PasswordField } from '../../inputs';
import { CopyField } from 'components/inputs/index';

const key = 'KINESIS';

export const AmazonKinesis = props => {
  const handleChange = field => event => {
    let { value } = event.target;
    if (
      _.includes(['assumedRoleArn', 'secretAccessKey', 'accessKeyId'], field) &&
      value === ''
    ) {
      value = null;
    }
    props.onChange(
      key,
      _.update(_.cloneDeep(props.config), field, () => value)
    );
  };

  const { config } = props;

  const usingArn = () =>
    config.assumedRoleArn && config.assumedRoleArn.length > 0;

  return (
    <div>
      <TextField
        required
        label="AWS Region Name"
        value={config.regionName}
        onChange={handleChange('regionName')}
      />

      <TextField
        required={!usingArn()}
        isDisabled={!usingArn()}
        label="AWS Access Key ID"
        autoComplete="new-password"
        value={config.accessKeyId}
        onChange={handleChange('accessKeyId')}
      />

      <PasswordField
        required={!usingArn()}
        isDisabled={!usingArn()}
        label="AWS Secret Access Key"
        autoComplete="new-password"
        value={config.secretAccessKey}
        onChange={handleChange('secretAccessKey')}
      />

      <TextField
        required
        label="Stream Name"
        value={config.streamName}
        onChange={handleChange('streamName')}
      />
      <TextField
        required={usingArn()}
        isDisabled={usingArn()}
        label="Assumed Role ARN"
        value={config.assumedRoleArn}
        onChange={handleChange('assumedRoleArn')}
      />
      <CopyField isDisabled label="External Id" value={config.externalId} />
    </div>
  );
};
