import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card } from '@material-ui/core';
import { default as MUICard } from '@material-ui/core/Card';
import useStyles from './useStyles';
import { default as MuiAccordion } from '@material-ui/core/Accordion';
import { default as MuiAccordionDetails } from '@material-ui/core/AccordionDetails';
import { default as MuiAccordionSummary } from '@material-ui/core/AccordionSummary';
import { default as MUIArrowForwardIosIcon } from '@material-ui/icons/ArrowForwardIos';
import GenericCard from 'components/UI/GenericCard';
import cc from 'classcat';
import CircleIcon from '../../ZScanApps/CircleComponent/index';
import ZScanInstances from './zScanInstances';
import Button from 'components/UI/Button';
import GenericDataFilter from 'components/UI/GenericDataFilter';
import PlatformIcon from '../PlatformIcon';
import { countFrequency } from './utils';
import ComplianceTable from 'dashboard/zDev/Compliance/ComplianceTable';
import SeverityCircle from '../SeverityCircle';
import { getFindingById } from 'api/zDevService';
import { IFindingDetails, IFindingProps } from './models';
import { openSnackBar } from 'utils/storeUtils';
import { AxiosResponse } from 'axios';

const FindingSideBar: React.FC<IFindingProps> = ({
  rerenderKey,
  selectFinding: buildFindings,
  assessmentId,
}) => {
  const classes = useStyles();
  const [commentTagHistory, setCommentTagHistory] = useState<boolean>(true);
  const [complianceExpanded, setComplianceExpanded] = useState<boolean>(true);
  const [instancesExpanded, setInstancesExpanded] = useState<boolean>(true);
  const [appsExpanded, setAppsExpanded] = useState<boolean>(true);
  const [findingData, setFindingData] = useState<IFindingDetails>();
  const complianceCount = countFrequency(buildFindings?.compliance ?? []);

  const ticketLinks = useMemo(() => {
    if (findingData && findingData.tickets) {
      return findingData.tickets.map((e: { ticketLink: string }) => {
        return (
          <a href={String(e?.ticketLink)} target="_blank" key={e?.ticketLink}>
            {e?.ticketLink?.split('/')[e?.ticketLink?.split('/').length - 1]}
          </a>
        );
      });
    }
    return [];
  }, [findingData]);

  const getfindingDetails = useCallback(async () => {
    try {
      const response: AxiosResponse = await getFindingById({
        id: buildFindings?.id,
      });
      setFindingData(response?.data);
    } catch (error) {
      openSnackBar('Unable to fetch finding details, please try again.');
    }
  }, [buildFindings?.id]);

  useEffect(() => {
    getfindingDetails();
  }, [getfindingDetails, rerenderKey]);

  return (
    <div style={{ overflow: 'hidden' }}>
      <Card>
        {' '}
        <MUICard className={classes.header}>
          <div style={{ display: 'flex' }}>
            <div style={{ marginTop: '5px' }}>
              <PlatformIcon
                iconType={buildFindings?.platform[0]?.toLowerCase() ?? ''}
              />
            </div>
            <h3 className={classes.appNameHeader}>{buildFindings?.name}</h3>
          </div>
        </MUICard>
        <MuiAccordion className={classes.accordion} expanded={appsExpanded}>
          <MuiAccordionSummary
            onClick={() => setAppsExpanded(!appsExpanded)}
            className={classes.accordionHeader}
            expandIcon={<MUIArrowForwardIosIcon />}
          >
            Finding Details
          </MuiAccordionSummary>

          <MuiAccordionDetails
            classes={{
              root: classes.accordionDetails,
            }}
          >
            <GenericCard noPadding>
              <div className={classes.findingDetailsContainer}>
                <div className={classes.detailsSubContianer}>
                  <table
                    className={cc([
                      classes.detailsTable,
                      classes.detailsTableWithPadding,
                    ])}
                  >
                    <tr>
                      <td>Severity:</td>

                      <td>
                        <GenericDataFilter
                          tableValue={buildFindings?.severity ?? ''}
                          showTableValue={false}
                          path="severity"
                        >
                          <SeverityCircle
                            severity={buildFindings?.severity ?? ''}
                          />
                        </GenericDataFilter>
                      </td>
                    </tr>
                    <tr>
                      <td>Category:</td>
                      <td>
                        <GenericDataFilter
                          tableValue={buildFindings?.categoryName ?? ''}
                          showTableValue={false}
                          path="categoryName"
                        >
                          {buildFindings?.categoryName ?? ''}
                        </GenericDataFilter>
                      </td>
                    </tr>
                    <tr>
                      <td>Subcategory:</td>
                      <td>
                        {' '}
                        <GenericDataFilter
                          tableValue={buildFindings?.subcategoryName ?? ''}
                          showTableValue={false}
                          path="subcategoryName"
                        >
                          {buildFindings?.subcategoryName ?? ''}
                        </GenericDataFilter>
                      </td>
                    </tr>
                    <tr>
                      <td>CVSS 3.1:</td>
                      <td>{buildFindings?.cvss31Score ?? 'No data'}</td>
                    </tr>
                  </table>
                </div>
                <div className={classes.detailsDescriptions}>
                  <div>
                    <span>Description</span>
                    <p>{buildFindings?.findingDescription ?? ''}</p>
                  </div>
                  <div>
                    <span>Business Impact</span>
                    <p>{buildFindings?.businessImpact ?? ''}</p>
                  </div>
                  <div>
                    <span>Recommendation</span>
                    <p>{buildFindings?.recommendation ?? ''}</p>
                  </div>
                  {findingData?.tickets?.length ? (
                    <div>
                      <span>Associated Tickets </span>
                      <div className={classes.tickets}>{ticketLinks}</div>
                    </div>
                  ) : null}
                </div>
              </div>
            </GenericCard>
          </MuiAccordionDetails>
        </MuiAccordion>
        <MuiAccordion
          className={classes.accordion}
          expanded={instancesExpanded}
        >
          <MuiAccordionSummary
            onClick={() => setInstancesExpanded(!instancesExpanded)}
            className={classes.accordionHeader}
            expandIcon={<MUIArrowForwardIosIcon />}
          >
            Instances
          </MuiAccordionSummary>

          <MuiAccordionDetails>
            <div>
              {buildFindings?.findingId && assessmentId && (
                <div style={{ width: '500px', height: '400px' }}>
                  <ZScanInstances
                    findingId={buildFindings?.findingId}
                    assessmentId={assessmentId}
                  />
                </div>
              )}
            </div>
          </MuiAccordionDetails>
        </MuiAccordion>
        <MuiAccordion
          className={classes.accordion}
          expanded={complianceExpanded}
        >
          <MuiAccordionSummary
            onClick={() => setComplianceExpanded(!complianceExpanded)}
            className={classes.accordionHeader}
            expandIcon={<MUIArrowForwardIosIcon />}
          >
            Compliance
          </MuiAccordionSummary>

          <MuiAccordionDetails
            classes={{
              root: classes.circleStyle,
            }}
          >
            <div>
              <div>
                <CircleIcon data={complianceCount?.CWE} />
                <span>CWE</span>
              </div>
              <div>
                <CircleIcon data={complianceCount?.CVE} />
                <span>CVE</span>
              </div>
              <div>
                <CircleIcon data={complianceCount?.OWASP} />
                <span>OWASP</span>
              </div>
              <div>
                <CircleIcon data={complianceCount?.MASVS} />
                <span>MASVS</span>
              </div>
            </div>
            <div>
              <div>
                <CircleIcon data={complianceCount?.GDPR} />
                <span>GDPR</span>
              </div>
              <div>
                <CircleIcon data={complianceCount?.HIPAA} />
                <span>HIPAA</span>
              </div>
              <div>
                <CircleIcon data={complianceCount?.PCI} />
                <span>PCI</span>
              </div>
              <div>
                <CircleIcon data={complianceCount?.NIAP} />
                <span>NIAP</span>
              </div>
            </div>
            <div
              style={{
                marginTop: '-18px',
              }}
            >
              <ComplianceTable complianceArray={buildFindings?.compliance} />
            </div>
          </MuiAccordionDetails>
        </MuiAccordion>
        <div style={{ display: 'none' }}>
          <MuiAccordion
            className={classes.accordion}
            expanded={commentTagHistory}
          >
            <MuiAccordionSummary
              onClick={() => setCommentTagHistory(!commentTagHistory)}
              className={classes.accordionHeader}
              expandIcon={<MUIArrowForwardIosIcon />}
            >
              Comment and Tag History
            </MuiAccordionSummary>
            <MuiAccordionDetails
              classes={{
                root: classes.accordionDetails,
              }}
            >
              <GenericCard noPadding>
                <table
                  className={cc([
                    classes.detailsTable,
                    classes.detailsTableWithPadding,
                  ])}
                >
                  <h4>Comment History</h4>
                  <tr>
                    <td>User & Date:</td>
                    <td>DATA</td>
                  </tr>
                  <tr>
                    <td>Comment:</td>
                    <td>Comment</td>
                  </tr>
                </table>
              </GenericCard>
              <GenericCard noPadding>
                <table
                  className={cc([
                    classes.detailsTable,
                    classes.detailsTableWithPadding,
                  ])}
                >
                  {' '}
                  <h4>Tag History</h4>
                  <tr>
                    <td>User & Date:</td>
                    <td>DATA</td>
                  </tr>
                  <tr>
                    <td>Tag:</td>
                    <td>TAG</td>
                  </tr>
                </table>
              </GenericCard>
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  justifyContent: 'flex-end',
                }}
              >
                <Button text="ADD NEW COMMENT" color="secondary" />
                <Button text="ADD NEW TAG" color="secondary" />
              </div>
            </MuiAccordionDetails>
          </MuiAccordion>
        </div>
      </Card>
    </div>
  );
};
export default FindingSideBar;
