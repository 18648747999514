import AT from 'actions/ActionTypes';
import * as api from 'api/apis';

export const updateUserProfileAccount = (
  account,
  successCallback,
  errorCallback
) => {
  return dispatch => {
    api
      .updateUserProfile(account)
      .then(resp => {
        // const responseData = JSON.parse(resp.config.data);
        if (resp.status === 200) {
          dispatch({
            type: AT.UPDATE_CURRENT_USER_SUCCEEDED,
            payload: account,
          });
          successCallback(resp);
        }
      })
      .catch(error => {
        console.log('Caught error: ', error.response);
        errorCallback(error.response);
        dispatch({
          type: AT.UPDATE_CURRENT_USER_FAILURE,
          payload: account,
        });
      });
  };
};
