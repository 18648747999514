import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    platform: {
      '& svg': {
        fontSize: 20,
        marginRight: 10,
        minHeight: 20,
        minWidth: 20,
      },
      alignItems: 'center',
      display: 'flex',
    },
    mainContainer: {
      padding: '15px',
    },

    searchBar: {
      marginBottom: 16,
    },
    created: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    empty: {
      alignItems: 'center',
      border: `1px solid ${palette.text.primary}`,
      display: 'flex',
      fontSize: 36,
      height: 'calc(100vh - 290px)',
      justifyContent: 'center',
    },
    risk: {
      '& span': {
        marginRight: 10,
      },
      alignItems: 'center',
      display: 'flex',
      textTransform: 'capitalize',
    },
    selectFormControl: {
      minWidth: 220,
    },
    tableActionsStyle: {
      display: 'flex',
      gap: '5px',
      justifyContent: 'flex-end',
      marginBottom: '5px',
    },
    osRiskIcon: {
      backgroundColor: palette.charts.icon,
      color: palette.common.white,
      borderRadius: 72,
      height: 50,
      padding: 11,
      width: 50,

      '& > svg': { height: '100%', width: '100%' },
    },
    osRiskIconAndroid: { border: `1px solid ${palette.charts.brightGreen}` },
  })
);

export default useStyles;
