import { createStyles, makeStyles } from '@material-ui/styles';

// Usage of 'any' inside this style decorator is discouraged; however, our theme
// currently does not conform to MUI's theme structure standards. So this will
// be a followup fix for later.
const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    container: {
      borderTopLeftRadius: '3px',
      borderTopRightRadius: '3px',
      margin: '0px -24px 0px -24px',
      padding: '0px',
      position: 'relative',
      top: '-20px',
      width: '500px',
    },
    content: {
      margin: '15px 30px 0px 30px',
      '& p': {
        marginBlockStart: '0px',
      },
    },
    emptyCard: {
      height: 137,
      marginTop: 25,
    },
    errorIcon: {
      color: palette.error.main,
      marginRight: '5px',
    },
    errorMessage: {
      alignItems: 'center',
      display: 'flex',
      marginTop: '28px',
    },
    errorText: {
      color: palette.text.secondary,
    },
    header: {
      backgroundColor: palette.common.darkGreySoft,
      borderRadius: '3px',
      padding: '18px 0px 16px 0px',
      textAlign: 'center',
      width: '100%',
    },
    headerText: {
      color: palette.primary.contrastText,
      fontWeight: 400,
      margin: '0px',
      padding: '0px',
      textTransform: 'uppercase',
      width: '100%',
    },
    icon: {
      display: 'inline-block',
      margin: '0px 2px 0px 2px',
    },
    manualNotice: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      fontSize: '12px',
      justifyContent: 'flex-start',
    },
    radioGroupLabel: {
      color: palette.text.primary,
      fontSize: 12,
      marginTop: 15,
      order: 0,
      paddingBottom: 5,
    },
    syncSelection: {
      marginBottom: '20px',
    },
  })
);

export default useStyles;
