import { fetchOSRiskTopTenImpactful } from 'api/OSRiskService';
import { ITopTenImpactfulCVEs } from './models';

export const fetchTopTenImpactful = async (
  teamId?: string | undefined,
  zappId?: string | undefined
): Promise<ITopTenImpactfulCVEs[]> => {
  const result: { data: ITopTenImpactfulCVEs[] } =
    await fetchOSRiskTopTenImpactful({
      teamId,
      zappId,
    });

  return result?.data;
};
