import { ButtonBase as MUIButtonBase } from '@material-ui/core';
import { default as MUICard } from '@material-ui/core/Card';
import { default as MUIAndroidIcon } from '@material-ui/icons/Android';
import { default as MUICloseIcon } from '@material-ui/icons/Close';
import React from 'react';
import AppleIcon from 'components/UI/icons/AppleIcon';
import useStyles from './useStyles';
import { IzShieldOSType } from 'components/main/zShieldApps/ZShieldApps/models';
import Tooltip from '@material-ui/core/Tooltip';

interface IDrawerHeaderProps {
  onClose: () => void;
  os: string;
  title: string;
}

const DrawerHeader: React.FC<IDrawerHeaderProps> = ({ os, title, onClose }) => {
  const classes = useStyles();

  return (
    <MUICard className={classes.header}>
      <div className={classes.leftSection}>
        {Array.isArray(os) ? (
          os.map((e) =>
            e.toUpperCase() === IzShieldOSType.ANDROID ? (
              <MUIAndroidIcon />
            ) : (
              <AppleIcon />
            )
          )
        ) : os.toUpperCase() === IzShieldOSType.ANDROID ? (
          <MUIAndroidIcon />
        ) : (
          <AppleIcon />
        )}
        <Tooltip title={title}>
          <h3 className={classes.appNameHeader}>
            {title.length <= 40 ? title : title.slice(0, 40) + '...'}
          </h3>
        </Tooltip>
      </div>
      <div>
        <MUIButtonBase onClick={onClose}>
          <MUICloseIcon />
        </MUIButtonBase>
      </div>
    </MUICard>
  );
};

export default DrawerHeader;
