import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

export default state => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      threats: {
        ...state.uiSettings.threats,
        tableHeaders: uiSettingsDefaults.threats.tableHeaders,
      },
      devices: {
        ...state.uiSettings.devices,
        tableHeaders: uiSettingsDefaults.devices.tableHeaders,
      },
      mtdThreats: {
        ...state.uiSettings.threats,
        tableHeaders: uiSettingsDefaults.mtdThreats.tableHeaders,
      },
      mtdDevices: {
        ...state.uiSettings.devices,
        tableHeaders: uiSettingsDefaults.mtdDevices.tableHeaders,
      },
    },
  };
};
