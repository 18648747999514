import { IDailySyncSchedule } from './models';

export const initialValues = (
  data: Partial<IDailySyncSchedule>
): IDailySyncSchedule => {
  return {
    dailyStartTime: data?.dailyStartTime ?? '',
    days: data?.days ?? [],
  };
};
