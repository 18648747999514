export type TLanguageOption = 'en-us' | 'ja-jp';

export enum ELanguageLabel {
  'en-us' = 'English',
  'ja-jp' = 'Japanese'
}

export enum LanguagePayloadMapping {
  'en-us' = 'en',
  'ja-jp' = 'ja'
}
