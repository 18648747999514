import Moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './useStyles';

interface IFooterProps { }

const Footer: React.FC<IFooterProps> = () => {
  const classes = useStyles();
  const { t, ready } = useTranslation();

  return !ready ? null : (
    <footer className={classes.publicFooter}>
      <a
        href="https://www.zimperium.com/zimperium-eula/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={classes.footerText}>{t('STC.TERMSANDCONDITIONS')}</div>
      </a>
      |
      <a
        href="https://www.zimperium.com/zimperium-eula/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={classes.footerText}>{t('STC.PRIVACYPOLICY')}</div>
      </a>
      |{' '}
      <div className={classes.footerText}>{`${t(
        'STC.COPYRIGHTPRE'
      )}-${Moment().year()} ${t('STC.COPYRIGHTPOST')}`}</div>
    </footer>
  );
};

export default Footer;
