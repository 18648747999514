import cc from 'classcat';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Bar from './Bar';
import { ILiteralProportionChartColumn } from './models';
import useStyles from './useStyles';

interface ILiteralProportionChartProps {
  baselineOverride?: number;
  ceilingOverride?: number;
  className?: string;
  columns: ILiteralProportionChartColumn[];
  showSingleMetric?: boolean;
}

const LiteralProportionChart: React.FC<ILiteralProportionChartProps> = ({
  baselineOverride,
  ceilingOverride,
  className,
  columns,
  showSingleMetric,
}) => {
  const classes = useStyles();

  const baseline = useMemo(
    () =>
      baselineOverride ??
      Math.min(
        ...columns.map(({ count }) => (typeof count === 'number' ? count : 0))
      ),
    [baselineOverride, columns]
  );

  const ceiling = useMemo(
    () =>
      ceilingOverride ??
      columns.reduce((a, { count: c }) => ((c ?? 0) > a ? c ?? 0 : a), 0),
    [ceilingOverride, columns]
  );

  const chart = useMemo(
    () => (
      <>
        {columns.map((column, index) => (
          <div className={classes.row} key={index}>
            <div>
              <span>
                {!!column.link ? (
                  <Link to={column.link}>{column.label}</Link>
                ) : (
                  column.label
                )}
              </span>
            </div>
            <div>
              <Bar baseline={baseline} ceiling={ceiling} {...column} />
            </div>
          </div>
        ))}
      </>
    ),
    [baseline, classes.row, ceiling, columns]
  );

  if (!columns.length || (columns.length === 1 && !showSingleMetric)) {
    return null;
  }

  return (
    <div className={cc([classes.literalProportion, className])}>{chart}</div>
  );
};

export default LiteralProportionChart;
