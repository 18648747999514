import { createStyles, makeStyles } from '@material-ui/styles';

// Usage of 'any' inside this style decorator is discouraged; however, our theme
// currently does not conform to MUI's theme structure standards. So this will
// be a followup fix for later.
const useStyles = makeStyles(() =>
  createStyles({
    buttonPanel: {
      alignItems: 'flex-start',
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '30px 0px 10px 0px',
    },
    errorMessage: {
      marginTop: '15px',
    },
  })
);

export default useStyles;
