import { ITableActionGroup } from './models';

export enum TableAction {
  CreateTicketForSelectedRows = 'createTicketForSelectedRows',
}

export const tableActions: ITableActionGroup[] = [
  {
    group: 'GLOBAL.SELECTED_ROWS',
    actions: [
      {
        value: TableAction.CreateTicketForSelectedRows,
        label: 'CreateTicketForSelectedRows',
      },
    ],
  },
];
