import { default as MuiAccordion } from '@material-ui/core/Accordion';
import { default as MuiAccordionDetails } from '@material-ui/core/AccordionDetails';
import { default as MuiAccordionSummary } from '@material-ui/core/AccordionSummary';
import MUIAddIcon from '@material-ui/icons/Add';
import { default as MUIArrowForwardIosIcon } from '@material-ui/icons/ArrowForwardIos';
import MUICloseIcon from '@material-ui/icons/Close';
import MUIDeleteIcon from '@material-ui/icons/Delete';
import MUIEditIcon from '@material-ui/icons/Edit';
import { getAllAppsByGroupIds } from 'api/InAppsService';
import {
  deletePhishingPolicy,
  fetchPhishingPolicyById,
  fetchSiteCategoryCodes,
  updateAcl,
  updatePhishingPolicy,
} from 'api/PhishingPolicyService';
import { headerActions as headerActionsAtom } from 'atoms/header';
import { getActiveModalAtom } from 'atoms/modals';
import { deployDateAtom } from 'atoms/policies';
import cc from 'classcat';
import { GenericPromptModal_TITLE } from 'components/UI/Modals/GenericPrompt';
import { IGenericPromptModalData } from 'components/UI/Modals/GenericPrompt/models';
import ViewSelector from 'components/UI/ViewSelector';
import AlertIcon from 'components/UI/icons/AlertIcon';
import CopyIcon from 'components/UI/icons/CopyIcon';
import { Radio } from 'components/UI/input/RadioGroup';
import { ISelectItem } from 'components/UI/input/Select';
import withRouter from 'components/hocs/withRouter';
import GlobalIndicatorWrapper from 'components/main/common/GlobalIndicatorWrapper';
import { IState } from 'components/main/integrations/emm/emmTable/MDM/models';
import _ from 'lodash';
import { LanguagePayloadMapping } from 'models/language';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { reduceForMultiSelectWithGlobal } from 'utils/componentUtils';
import { openSnackBar } from 'utils/storeUtils';
import useAsyncResult from 'utils/useAsyncResult';
import useForceReflow from 'utils/useForceReflow';
import AddClonePolicy, {
  AddClonePolicy_TITLE,
} from '../appPolicy/AddClonePolicy';
import { IAppPolicyCreateModalData } from '../appPolicy/AddClonePolicy/models';
import ConfirmDeleteMessage from '../common/ConfirmDeleteMessage';
import CustomPolicyOption from '../common/CustomPolicyOption';
import LastDeployedBox from '../common/LastDeployedBox';
import PhishingAccessControlList from './AccessControlList/AccesControlList';
import CategoryHandlingList from './CategoryHandling';
import PhishingPolicyForm from './PhishingPolicyForm';
import PhishingSiteCategoryCheck from './PhishingSiteCatCheck';
import {
  aclDetailshasChanges as aclDetailsHasChangesAtom,
  phishingAclItemValue as aclListItemAtom,
  hasChangesCategories as hasChangesCategoriesAtom,
  hasChangesChkboxes as hasChangesChkboxesAtom,
  phishingAclControlListEntriesArr as phishingAclControlListEntriesArrAtom,
  phishingAclHasChanges as phishingAclHasChangesAtom,
  phishingCategoryValues,
  phishingFormValues,
  resetCatHandling,
} from './atoms';
import {
  IACLListItem,
  IAppPhishingPolicyData,
  ICategories,
  IFormRefChckboxes,
} from './models';
import useStyles from './useStyles';
import { buildCategoryGroups, fetchPolicies } from './utils';

interface IPhishingPolicyProps {
  location: {
    query: {
      teamId: string;
      phishing: string;
    };
  };
  mtdModule: boolean;
  updateUrl: (val: { phishing: string | number }) => void;
}

const activeModalAtom = getActiveModalAtom<
  IGenericPromptModalData | IAppPolicyCreateModalData
>();

const PhishingPolicy: React.FC<IPhishingPolicyProps> = (props) => {
  const classes = useStyles();

  const [phishingProtecType, setPhishingProtectType] =
    useState<string>('DISABLED');
  const [firstPhishingProtectType, setFirstPhishingProtectType] =
    useState<string>();
  const [phishingPolicyData, setPhishingPolicyData] =
    useState<IAppPhishingPolicyData>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [phishingPolicyName, setPhishingPolicyName] = useState<string>();
  const [selectedPhishingPolicy, setSelectedPhishingPolicy] =
    useState<ISelectItem>();
  const [attachedApps, setAttachedApps] = useState<IAppPhishingPolicyData[]>();
  const [phishingPolicyList, setPhishingPolicyList] = useState<ISelectItem[]>(
    []
  );
  const [customAccessExpanded, setCustomAccessExpanded] =
    useState<boolean>(false);
  const [siteCategoryCodes, setSiteCategoryCodes] = useState<ICategories>();

  const [hasChangesCheckboxes, setHasChkboxesChanges] = useRecoilState(
    hasChangesChkboxesAtom
  );
  const [hasChangesCateg, setHasCategChanges] = useRecoilState(
    hasChangesCategoriesAtom
  );
  const [radioChanges, setRadioChanges] = useState<boolean>(false);

  const [isRecommendedSettings, setIsRecommendedSettings] =
    useRecoilState(resetCatHandling);
  const [loaded, setLoaded] = useState<boolean>(true);

  const setHeaderActions = useSetRecoilState(headerActionsAtom);

  const categoryValues = useRecoilValue(phishingCategoryValues);
  const formValues = useRecoilValue(phishingFormValues);
  const accountId = useSelector((state: IState) => state?.user?.account?.id);

  const [persistSelectedAcl, setPersistedSelectedAcl] =
    useRecoilState(aclListItemAtom);
  const [aclFromPolicy, setAclFromPolicy] = useState<IACLListItem>();
  const [aclHasChanges, setAclHasChanges] = useRecoilState(
    phishingAclHasChangesAtom
  );
  const [aclDetailsHasChanges, setAclDetailsHasChanges] = useRecoilState(
    aclDetailsHasChangesAtom
  );
  const [tempSelectedOption, setTempSelectedOption] = useState<ISelectItem>();
  const [policyHasChanges, setPolicyHasChanges] = useState<boolean>(false);
  const setActiveModal = useSetRecoilState(activeModalAtom);
  const controlListEntries = useRecoilValue(
    phishingAclControlListEntriesArrAtom
  );
  const setDeployDate = useSetRecoilState(deployDateAtom);
  const formRefChk = useRef<IFormRefChckboxes>({});

  const { t, ready, i18n } = useTranslation();

  const { updateUrl } = props;

  const navigationPrompt = useMemo(
    () =>
      ({
        message = (
          <div className={classes.navigationWarning}>
            {t('MTD.POLICIES.APP_POLICY.POLICY_LEAVE_PAGE_CONFIRMATION', {
              policyName: 'phishing',
            })}
            <AlertIcon />
          </div>
        ),
        onCancelCaption = t('MTD.POLICIES.APP_POLICY.LOSE_CHANGES'),
        onConfirmCaption = t('MTD.POLICIES.APP_POLICY.STAY_HERE'),
      }: Partial<IGenericPromptModalData> = {}): Partial<
        Omit<IGenericPromptModalData, 'onCancel' | 'onConfirm'>
      > => ({
        title: t('MTD.POLICIES.APP_POLICY.PENDING_POLICY_UPDATE'),
        message: <span>{message}</span>,
        onCancelCaption,
        onConfirmCaption,
      }),
    [classes, t]
  );

  const handleProtectionType = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      e.preventDefault();
      setPhishingProtectType(e.target.value);
    },
    [setPhishingProtectType]
  );

  useEffect(() => {
    if (
      firstPhishingProtectType === phishingProtecType ||
      phishingPolicyList.length <= 0
    ) {
      setRadioChanges(false);
    } else if (firstPhishingProtectType !== undefined) {
      setRadioChanges(true);
    }
  }, [
    phishingProtecType,
    setRadioChanges,
    firstPhishingProtectType,
    phishingPolicyList,
  ]);

  const deployChanges = useCallback(async () => {
    const contentCategoryActionList = buildCategoryGroups(categoryValues);
    let newPhishingDetectionAction: string;
    formValues?.phishingDetectionAction === false
      ? (newPhishingDetectionAction = 'WARN')
      : (newPhishingDetectionAction = 'BLOCK');
    const newFormValues = {
      ...formValues,
      phishingDetectionAction: newPhishingDetectionAction,
      name: selectedPhishingPolicy?.label,
    };
    const id = selectedPhishingPolicy?.value;
    const aclId = !!persistSelectedAcl?.id
      ? { id: persistSelectedAcl.id }
      : null;

    const finalPayload = {
      id,
      accountId,
      groups: [],
      ...newFormValues,
      phishingPolicyType: phishingProtecType,
      contentCategoryActionList,
      accessControlList: aclId,
    };

    if (aclDetailsHasChanges) {
      const payload = {
        name: persistSelectedAcl?.name,
        controlListEntries,
      };
      try {
        await updateAcl({ id: persistSelectedAcl?.id }, payload);
      } catch (error) {
        console.log(error);
      }
    }

    setPhishingPolicyData(finalPayload as IAppPhishingPolicyData);
    updatePhishingPolicy({ id }, finalPayload);
    setFirstPhishingProtectType(phishingProtecType);
    setRadioChanges(false);
    setHasChkboxesChanges(false);
    if (formRefChk.current && formRefChk.current.resetForm) {
      formRefChk.current.resetForm();
    }
    setHasCategChanges(false);
    setIsRecommendedSettings(false);
    setAclHasChanges(false);
    setAclDetailsHasChanges(false);
    setPolicyHasChanges(false);
    openSnackBar(t('MTD.POLICIES.APP_POLICY.YOUR_CHANGES_SAVED_THIS_POLICY'));
  }, [
    accountId,
    aclDetailsHasChanges,
    categoryValues,
    controlListEntries,
    formValues,
    persistSelectedAcl,
    phishingProtecType,
    selectedPhishingPolicy,
    setAclDetailsHasChanges,
    setAclHasChanges,
    setHasCategChanges,
    setHasChkboxesChanges,
    setIsRecommendedSettings,
    setPolicyHasChanges,
    t,
  ]);

  const { reflowIndex, forceReflow } = useForceReflow();

  const policies = useAsyncResult<
    Array<ISelectItem & { created: string }> | undefined
  >(
    fetchPolicies,
    props?.location?.query?.teamId === null
      ? ''
      : props?.location?.query?.teamId,
    reflowIndex,
    undefined,
    undefined,
    500
  );

  useEffect(() => {
    if (policies && policies.length > 0) {
      setPersistedSelectedAcl(null);
      setPhishingPolicyList(reduceForMultiSelectWithGlobal(policies));
    }
  }, [policies, setPersistedSelectedAcl]);

  useEffect(() => {
    fetchSiteCategoryCodes({
      module: 'ZIPS',
      lang: LanguagePayloadMapping[
        i18n.language as keyof typeof LanguagePayloadMapping
      ],
    })
      .then(({ data }: { data: ICategories }) => setSiteCategoryCodes(data))
      .catch(() =>
        openSnackBar('Unable to fetch site category codes, please try again.')
      );
  }, [i18n.language]);

  useEffect(() => {
    if (!_.isEmpty(props.location.query.phishing)) {
      const newSelectedPhishingPolicy = phishingPolicyList?.find(
        (el: ISelectItem) => el.value === props.location?.query?.phishing
      );
      setTempSelectedOption(newSelectedPhishingPolicy);
      setSelectedPhishingPolicy(newSelectedPhishingPolicy);
      setPhishingPolicyName(newSelectedPhishingPolicy?.label ?? '');
    } else {
      if (phishingPolicyList?.[0]?.value) {
        updateUrl({
          phishing: phishingPolicyList?.[0]?.value ?? '',
        });
      }
    }
  }, [props.location.query.phishing, phishingPolicyList, updateUrl]);

  useEffect(() => {
    if (
      hasChangesCheckboxes ||
      hasChangesCateg ||
      radioChanges ||
      isRecommendedSettings ||
      aclHasChanges ||
      aclDetailsHasChanges ||
      policyHasChanges
    ) {
      setHeaderActions({
        clearNavigationPreventingChanges: () => {
          setHasChkboxesChanges(false);
          setHasCategChanges(false);
          setRadioChanges(false);
          setAclHasChanges(false);
          setAclDetailsHasChanges(false);
          setPolicyHasChanges(false);
        },
        preventNavigation: true,
        navigationPrompt: navigationPrompt({
          title: t('MTD.POLICIES.APP_POLICY.PENDING_POLICY_UPDATE'),
        }),
        buttons: [
          {
            className: classes.green,
            color: 'inherit',
            disabled: false,
            onClick: () => deployChanges(),
            text: t('MTD.POLICIES.APP_POLICY.DEPLOY_POLICY_CHANGES'),
          },
        ],
      });
    }

    return () => setHeaderActions(undefined);
  }, [
    classes.green,
    deployChanges,
    hasChangesCheckboxes,
    hasChangesCateg,
    isRecommendedSettings,
    navigationPrompt,
    setHasChkboxesChanges,
    setHasCategChanges,
    setHeaderActions,
    radioChanges,
    aclHasChanges,
    aclDetailsHasChanges,
    policyHasChanges,
    setAclHasChanges,
    setAclDetailsHasChanges,
    setPolicyHasChanges,
    t,
  ]);

  const handleChange = useCallback(
    (name: string, selectedOption: ISelectItem) => {
      setActiveModal(undefined);
      if (
        hasChangesCheckboxes ||
        hasChangesCateg ||
        radioChanges ||
        isRecommendedSettings ||
        aclHasChanges ||
        aclDetailsHasChanges ||
        policyHasChanges
      ) {
        setActiveModal({
          active: GenericPromptModal_TITLE,
          payload: {
            title: t('MTD.POLICIES.APP_POLICY.PENDING_POLICY_UPDATE'),
            message: (
              <div className={classes.navigationWarning}>
                {t('MTD.POLICIES.APP_POLICY.POLICY_LEAVE_PAGE_CONFIRMATION', {
                  policyName: 'phishing',
                })}
                <AlertIcon />
              </div>
            ),
            onConfirmCaption: t('GLOBAL.PROCEED'),
            onConfirm: () => {
              setPhishingPolicyName(selectedOption.label);
              setSelectedPhishingPolicy(selectedOption);
              updateUrl({ phishing: selectedOption.value ?? '' });
              setActiveModal(undefined);
            },
            onCancel: () => {
              setPhishingPolicyName(() => tempSelectedOption?.label);
              setSelectedPhishingPolicy(tempSelectedOption);
              updateUrl({ phishing: tempSelectedOption?.value ?? '' });
              setActiveModal(undefined);
            },
          },
        });
      } else {
        setPhishingPolicyName(selectedOption.label);
        setSelectedPhishingPolicy(selectedOption);
        updateUrl({ phishing: selectedOption.value ?? '' });
      }
    },
    [
      updateUrl,
      setActiveModal,
      tempSelectedOption,
      classes,
      hasChangesCheckboxes,
      hasChangesCateg,
      radioChanges,
      isRecommendedSettings,
      aclHasChanges,
      aclDetailsHasChanges,
      policyHasChanges,
      t,
    ]
  );
  const policyId = props.location?.query?.phishing;

  const grabAttachedZapps = useCallback(
    (groups = []) => {
      if (!!groups) {
        return setAttachedApps([]);
      }
      const groupIdList = groups.map(({ id }: { id: string }) => {
        return id;
      });
      getAllAppsByGroupIds(groupIdList)
        .then((resp) => {
          setAttachedApps(resp.data);
        })
        .catch((error) => {
          setAttachedApps([]);
          console.log('error fetching app by groups', error);
        });
    },
    [setAttachedApps]
  );

  useEffect(() => {
    if (!!policyId) {
      fetchPhishingPolicyById({ id: policyId })
        .then(({ data }: { data: IAppPhishingPolicyData }) => {
          const phishingDetectionAction =
            data.phishingDetectionAction === 'BLOCK';
          const aclListItem = data.accessControlList;
          setFirstPhishingProtectType(data?.phishingPolicyType);
          setPhishingProtectType(data?.phishingPolicyType);
          setPhishingPolicyData({
            ...data,
            phishingDetectionAction,
          });
          setDeployDate(data.modified || '');
          setPersistedSelectedAcl(aclListItem);
          setAclFromPolicy(aclListItem);
          grabAttachedZapps(data.groups ?? []);
          setIsRecommendedSettings(false);
        })
        .catch((error: unknown) => console.error(error));
    }
  }, [
    policyId,
    grabAttachedZapps,
    setPhishingPolicyData,
    setIsRecommendedSettings,
    setPersistedSelectedAcl,
    setDeployDate,
  ]);

  const selectedTeam = useSelector((state: IState) => {
    const { available } = state.teams;

    return {
      selectedTeam: state.teams.selected,
      selectedTeamName: _.get(available[state.teams.selected], 'name'),
    };
  });

  const newProps = {
    ...props,
    attachedApps,
    formRefChk,
    isEnhanced: phishingProtecType === 'ON_DEVICE',
    phishingProtecType,
    phishingPolicyData,
    phishingPolicyName,
    selectedPhishingPolicy,
    selectedTeam,
    setAttachedApps,
    setPhishingPolicyData,
    setPhishingPolicyName,
    setSelectedPhishingPolicy,
  };

  const handleEdit = useCallback(() => {
    setEditMode(true);
    setActiveModal(undefined);
    setActiveModal({
      active: GenericPromptModal_TITLE,
      payload: {
        title: t('MTD.POLICIES.APP_POLICY.EDIT_POLICY_NAME'),
        question: {
          label: t('MTD.POLICIES.APP_POLICY.POLICY_NAME'),
          defaultAnswer: selectedPhishingPolicy?.label,
        },
        onCancel: () => {
          setActiveModal(undefined);
          setEditMode(false);
        },
        onConfirm: async (answer) => {
          setPhishingPolicyName(answer);
          setTempSelectedOption({
            ...tempSelectedOption,
            label: answer,
            name: answer,
          } as ISelectItem);
          setSelectedPhishingPolicy({
            ...selectedPhishingPolicy,
            label: answer,
            name: answer,
          } as ISelectItem);
          const stringPhishingDetectionAction =
            !!phishingPolicyData?.phishingDetectionAction ? 'BLOCK' : 'WARN';
          updatePhishingPolicy(
            { id: phishingPolicyData?.id },
            {
              ...phishingPolicyData,
              name: answer,
              phishingDetectionAction: stringPhishingDetectionAction,
            }
          );
          forceReflow();
          setEditMode(false);
          setActiveModal(undefined);
        },
      },
    });
  }, [
    forceReflow,
    setEditMode,
    setActiveModal,
    selectedPhishingPolicy,
    tempSelectedOption,
    setPhishingPolicyName,
    phishingPolicyData,
    t,
  ]);

  const handleDelete = useCallback(() => {
    setActiveModal({
      active: GenericPromptModal_TITLE,
      payload: {
        title: t('MTD.POLICIES.APP_POLICY.DELETE_POLICY'),
        message: (
          <ConfirmDeleteMessage
            groups={phishingPolicyData?.groups}
            policyType="Phishing"
          />
        ),
        onConfirmDisabled:
          phishingPolicyData?.groups && phishingPolicyData.groups.length > 0,
        onCancel: () => setActiveModal(undefined),
        onConfirm: async () => {
          if (
            await deletePhishingPolicy({ id: selectedPhishingPolicy?.value })
          ) {
            setLoaded(false);
            setLoaded(true);
            setPhishingPolicyName(undefined);
            setSelectedPhishingPolicy(undefined);
            openSnackBar(t('MTD.POLICIES.APP_POLICY.DELETED_POLICY'));
          }
          setActiveModal(undefined);
        },
      },
    });
  }, [setActiveModal, selectedPhishingPolicy, phishingPolicyData, t]);

  const handleAddClone = useCallback(
    (cloningPolicy?: string) => {
      setActiveModal({
        active: AddClonePolicy_TITLE,
        payload: {
          policyType: 'Phishing',
          cloningPolicy,
          defaultName: cloningPolicy
            ? selectedPhishingPolicy?.label + ' (cloned)'
            : '',
          onCreateSuccess: () => {
            updateUrl({
              phishing: '',
            });
          },
        },
      });
    },
    [selectedPhishingPolicy, setActiveModal, updateUrl]
  );

  const warnPolicyChanges = useCallback(
    (clonePol?: string) => {
      setActiveModal(undefined);
      hasChangesCheckboxes ||
      hasChangesCateg ||
      radioChanges ||
      isRecommendedSettings ||
      aclHasChanges ||
      aclDetailsHasChanges ||
      policyHasChanges
        ? setActiveModal({
            active: GenericPromptModal_TITLE,
            payload: {
              title: t('MTD.POLICIES.APP_POLICY.PENDING_POLICY_UPDATE'),
              message: (
                <div className={classes.navigationWarning}>
                  {t('MTD.POLICIES.APP_POLICY.POLICY_LEAVE_PAGE_CONFIRMATION', {
                    policyName: 'phishing',
                  })}
                  <AlertIcon />
                </div>
              ),
              onConfirmCaption: t('GLOBAL.PROCEED'),
              onConfirm: () => {
                handleAddClone(clonePol);
                setActiveModal(undefined);
              },
              onCancel: () => {
                setActiveModal(undefined);
              },
            },
          })
        : handleAddClone(clonePol);
    },
    [
      setActiveModal,
      classes,
      hasChangesCheckboxes,
      hasChangesCateg,
      radioChanges,
      isRecommendedSettings,
      aclHasChanges,
      policyHasChanges,
      aclDetailsHasChanges,
      handleAddClone,
      t,
    ]
  );

  if (!ready) {
    return null;
  }

  if (
    !phishingPolicyList ||
    (phishingPolicyList.length > 0 && !selectedPhishingPolicy)
  ) {
    return null;
  }
  return (
    <div>
      <div className={classes.mainContainerMargin}>
        <div>
          <GlobalIndicatorWrapper isGlobal inline />
          <ViewSelector
            editFieldText={phishingPolicyName}
            value={tempSelectedOption}
            interactable
            defaultValue={selectedPhishingPolicy}
            label={t('MTD.POLICIES.APP_POLICY.SELECT_POLICY')}
            placeholder={t('MTD.POLICIES.APP_POLICY.NO_POLICY_SELECTED')}
            setFieldValue={handleChange}
            customOption={CustomPolicyOption as React.FC}
            deployDateComp={LastDeployedBox as React.FC}
            actions={[
              {
                onClick: () => handleEdit(),
                icon: editMode ? MUICloseIcon : MUIEditIcon,
                disabled: phishingPolicyList.length <= 0,
              },
              {
                onClick: () =>
                  warnPolicyChanges(selectedPhishingPolicy?.value as string),
                icon: CopyIcon,
                disabled: phishingPolicyList.length <= 0,
              },
              {
                onClick: () => handleDelete(),
                icon: MUIDeleteIcon,
                disabled: phishingPolicyList.length <= 0,
              },
              {
                onClick: () => warnPolicyChanges(),
                icon: MUIAddIcon,
              },
            ]}
            options={phishingPolicyList}
          />
          <AddClonePolicy />
        </div>
      </div>
      {phishingPolicyList.length <= 0 ? (
        <div className={classes.noPolicy}>
          <h1 className={classes.noPolicyTitle}>
            {t('MTD.POLICIES.APP_POLICY.SELECT_OR_ADD_POLICY')}
          </h1>
        </div>
      ) : (
        <div className={classes.inputGroup}>
          <div className={classes.mainColumn}>
            <div className={cc([classes.card, classes.cardBold])}>
              {t('MTD.POLICIES.PHISHING_POLICY.SETTINGS_FOR_THIS_POLICY')}
            </div>
            <span className={classes.radioGroupTitle}>
              {t(
                'MTD.POLICIES.PHISHING_POLICY.PHISHING_PROTECTION_WEB_CONTENT_FILTERING'
              )}
            </span>
            <div className={classes.radioGroup}>
              <Radio
                checked={phishingProtecType === 'DISABLED'}
                color="primary"
                label={t('MTD.POLICIES.PHISHING_POLICY.DISABLED')}
                name="syncType"
                size="small"
                value="DISABLED"
                onChange={handleProtectionType}
              />
              <Radio
                checked={phishingProtecType === 'ON_DEVICE'}
                color="primary"
                label={t('MTD.POLICIES.PHISHING_POLICY.PHISHING_PROTECTION')}
                name="syncType"
                size="small"
                value="ON_DEVICE"
                onChange={handleProtectionType}
              />
              <Radio
                checked={phishingProtecType === 'REMOTE_AND_CONTENT_FILTER'}
                color="primary"
                label={t(
                  'MTD.POLICIES.PHISHING_POLICY.ENHANCED_PHISHING_PROTECTION_WEB_CONTENT_FILTERING'
                )}
                name="syncType"
                size="small"
                value="REMOTE_AND_CONTENT_FILTER"
                onChange={handleProtectionType}
              />
            </div>
            {phishingProtecType === 'ON_DEVICE' ||
            phishingProtecType === 'REMOTE_AND_CONTENT_FILTER' ? (
              <>
                <PhishingPolicyForm {...newProps} />
                <MuiAccordion
                  className={classes.accordion}
                  expanded={customAccessExpanded}
                >
                  <MuiAccordionSummary
                    onClick={() =>
                      setCustomAccessExpanded(!customAccessExpanded)
                    }
                    className={classes.accordionHeader}
                    expandIcon={<MUIArrowForwardIosIcon />}
                  >
                    {t(
                      'MTD.POLICIES.PHISHING_POLICY.CUSTOM_ACCESS_CONTROL_LIST'
                    )}
                  </MuiAccordionSummary>
                  <MuiAccordionDetails
                    classes={{
                      root: classes.accordionDetails,
                    }}
                  >
                    <div className={classes.accessText}>
                      {t('MTD.POLICIES.PHISHING_POLICY.CREATE_CUSTOM_SITES')}
                      <br />
                      {t('MTD.POLICIES.PHISHING_POLICY.SITE_LIST_DESCRIPTION')}
                    </div>
                    <PhishingSiteCategoryCheck
                      siteCategCodes={siteCategoryCodes}
                    />
                    {loaded && (
                      <PhishingAccessControlList
                        setLoaded={setLoaded}
                        aclFromPolicy={aclFromPolicy}
                        categories={siteCategoryCodes}
                      />
                    )}
                  </MuiAccordionDetails>
                </MuiAccordion>
              </>
            ) : null}
          </div>
          <CategoryHandlingList
            show={phishingProtecType === 'REMOTE_AND_CONTENT_FILTER'}
            categories={siteCategoryCodes}
            categoriesFromApi={phishingPolicyData?.contentCategoryActionList}
          ></CategoryHandlingList>
        </div>
      )}
    </div>
  );
};

export default withRouter(PhishingPolicy);
