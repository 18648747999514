import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    searchBox: {
      borderBottom: 'none',
      position: 'relative',

      '&:before': {
        borderBottom: 'none !important',
        display: 'none',
        position: 'initial',
      },
      '&:after': {
        borderBottom: 'none',
        display: 'none',
        position: 'initial',
      },
      '&:hover': {
        borderBottom: 'none',
      },

      '& > input': {
        padding: 15,
      },
    },

    searchBoxWrapper: {
      width: '100%',
    },

    searchIcon: {
      background: palette.background.paper,
      border: `1px solid ${palette.primary.border}`,
      height: '100%',
      justifyContent: 'center',
      marginLeft: 0,
      maxHeight: 'initial',
      padding: '0 5px',
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      width: 55,

      '& svg': {
        fontSize: 32,
      },
    },
  })
);

export default useStyles;
