import _ from 'lodash';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
// ui
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ZButton from 'UI/Buttons/ZButton';
import Typography from '@material-ui/core/Typography';

// services
import { toggleModalDirect, toggleModalDiffered } from 'utils/storeUtils';
import { MultiSelect } from 'components/Selects/index';
import Spinner from '../main/common/Spinner';

const styles = () => {
  return {
    buttonWrapper: {
      paddingLeft: 20,
      flex: '0 0 50%',
      flexDirection: 'column',
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: 15,
      paddingBottom: 20,
    },
  };
};

export const MDMCronJobModal = props => {
  const { data } = props;
  // const connectionId = _.get(data, 'rowData.id');
  // const name = _.get(data, 'rowData.name');
  function handleCronJob() {
    toggleModalDirect('MDMCronJob', false);

    // deleteMDMConnection({ connectionId })
    //   .then(() => {
    //     openSnackBar(`Successfully deleted ${name}`);
    //     publishEvent('table:force-fetch-MDM');
    //   })
    //   .catch(error => {
    //     openSnackBar(
    //       `Failed to delete ${data.name}. ${_.get(error, 'response.data', '')}`
    //     );
    //   });
  }

  const deviceGroups = _.get(data, 'rowData.connectedGroups');

  return (
    <>
      <DialogContent>
        <p>Sync {data.rowData.name} to the following schedule: </p>
        <MultiSelect
          required
          name="scheduleCron"
          label="When to execute"
          isMulti={false}
          buttonPlaceholder="Select an interval"
          options={[
            { label: '4h', value: '4h' },
            { label: '8h', value: '8h' },
            { label: '12h', value: '12h' },
            { label: '24h', value: '24h' },
            { label: '7d', value: '7d' },
          ]}
          // onChange={handleSelect}
          // values={config.messageType}
        />
        <p>They are attached to the following device groups:</p>
        <ul className={props.data.classList.groupList}>
          {deviceGroups ? (
            deviceGroups.map(group => {
              return (
                <li key={_.get(group, 'singleGroup.groupIdsToSync.value')}>
                  {' '}
                  <Typography variant="body1">
                    {_.get(group, 'singleGroup.groupIdsToSync.label')}
                  </Typography>
                </li>
              );
            })
          ) : (
            <div className={props.data.classList.emptyText}>
              <Spinner />
            </div>
          )}
        </ul>
      </DialogContent>
      <div className={props.classes.buttonWrapper}>
        <DialogActions>
          <ZButton
            styleName="modalCancel"
            action={toggleModalDiffered('MDMCronJob', false)}
            color="secondary"
            buttonText="Cancel"
          />
          <ZButton
            action={handleCronJob}
            buttonType="submit"
            color="primary"
            styleName="modalSave"
            buttonText="Submit"
          />
        </DialogActions>
      </div>
    </>
  );
};

MDMCronJobModal.defaultProps = {
  data: {},
};

export const MDMCronJob = withStyles(styles)(MDMCronJobModal);
