import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    card: {
      alignItems: 'center',
      backgroundColor: palette.background.default,
      display: 'flex',
    },
    disableIconStyle: {
      opacity: '30%',
    },
    addIconStyle: {
      cursor: 'pointer',
    },
    noDataTableContent: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'end',
      width: '100%',
    },
  })
);

export default useStyles;
