import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    column: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      margin: '5px',
    },
    row: {
      width: '100vw',
      paddingRight: '18px',
      display: 'grid',
      gridAutoFlow: 'column',
      gridAutoColumns: '1fr',
      columnGap: '15px',
    },
  })
);

export default useStyles;
