import cc from 'classcat';
import React, { useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { ILiteralProportionChartColumn } from '../models';
import useStyles from './useStyles';

interface IBarProps extends ILiteralProportionChartColumn {
  baseline: number;
  ceiling: number;
}

const Bar: React.FC<IBarProps> = ({
  backgroundColor,
  baseline,
  ceiling,
  color,
  count,
  link,
  suffix,
}) => {
  const classes = useStyles();

  const [deepLinked, setDeepLinked] = useState<boolean>(false);

  const safeCount = useMemo(
    () => (typeof count === 'number' ? count : 0),
    [count]
  );

  const redirectMaybe = useMemo(
    () => !!link && deepLinked && <Redirect to={link} />,
    [deepLinked, link]
  );

  return (
    <div
      className={cc([classes.item, 'chart-item'])}
      onClick={() => setDeepLinked(true)}
      style={{
        ...(backgroundColor ? { backgroundColor } : {}),
        ...(color ? { color } : {}),
        minWidth: `${Math.min(
          100,
          ((safeCount - baseline) / (ceiling - baseline)) * 100
        )}%`,
      }}
    >
      {`${count ?? 'N/A'} ${suffix}`}
      {redirectMaybe}
    </div>
  );
};

export default Bar;
