import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import withRouter from 'components/hocs/withRouter';
import { Table, TableColumnsContext } from 'components/Tables';
import _ from 'lodash';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  getAvailableTeams, getAvailableTeamsAsSelectList
} from 'reducers/TeamReducers';
import {
  groupActivationsTableColumnChange,
  updateUISettings
} from 'reducers/UiSettingsReducers';
import { bindActionCreators, compose } from 'redux';
import ZButton from 'UI/Buttons/ZButton';
import { toggleModalDiffered } from 'utils/storeUtils';


import { fetchAllGroupActivations } from 'api/GroupActivationsService';
import { fetchGroupsByTeamId, formGroupListForSelect } from 'api/GroupService';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import {
  getChangedTableHeadersHash,
  getUISettingsTableHeaders,
  getUISettingsWithout
} from 'reducers/UiSettingsSelectors';
import useBackfill from 'utils/useBackfill';
import { GroupActivationsColumnMapping } from './GroupActivations.mapping';
import { useTranslation } from 'react-i18next';

function GroupActivations(props) {
  const {
    availableTeams = [],
    selectedTeam: selectedTeamId,
    updateUrl,
    q,
  } = props;

  useBackfill({
    query: props.q,
    requiredQueryParams: props.rqps,
    storedQueryParams: props.groupActivationsUiSettings,
    updateUrl: props.updateUrl,
  });

  // This useEffect hack to prevent multiple data calls should be removed once we can port this to TS (JB)
  useEffect(() => {
    // Note that teamId is EXPLICITLY checked for here because it means we're not ready to fetch activations.
    // See below comment in fetchTableData()
    if (selectedTeamId === null && q?.teamId === undefined) {
      // Add teamId to query params on first load when "All Teams" is selected
      updateUrl(Object.fromEntries([['teamId', selectedTeamId || '']]));
    }
  }, [selectedTeamId, updateUrl, q?.teamId]);

  function fetchTableData() {
    const fetchGroups = fetchGroupsByTeamId();
    const fetchGroupActivations = fetchAllGroupActivations(props.q);

    // This hack to prevent multiple data calls should be removed once we can port this to TS (JB)
    //
    // Note that we EXPLICITLY check for undefined below because:
    //   * If teamId===undefined, we should NOT fetch activations because this means that the url params
    //     haven't been updated yet.
    //   * If teamId==="" or teamId===null, we SHOULD fetch activations because this means that the url
    //     params have been updated.
    //
    if (props?.q?.teamId === undefined) return Promise.all([]);
    return Promise.all([fetchGroups, fetchGroupActivations]).then(
      ([groups, groupActivationsData]) => {
        const groupsHash = formGroupListForSelect(groups.data);
        // const userActivationIdList = [];
        const tableData = groupActivationsData.data.content.map(
          (groupActivation) => {
            const groupName = _.get(
              groupsHash[groupActivation.groupId],
              'name',
              'Unknown'
            );
            const teamId = groupActivation?.teamId ?? null;
            const team =
              availableTeams.find(({ value }) => value === teamId) ?? {};

            return {
              ...groupActivation,
              groupName,
              team: team?.label
                ? {
                    id: teamId,
                    name: team.label,
                  }
                : {
                    id: null,
                    name: 'N/A',
                  },
            };
          }
        );
        return {
          tableData,
          totalResults: groupActivationsData.data.totalElements,
          isLoading: false,
        };
      }
    );
  }

  const { t, ready } = useTranslation();

  if (!ready) {
    return null;
  }

  return (
    <div>
      <div className="view__header">
        <h1>{t('MTD.ACTIVATIONS.GROUP_ACTIVATIONS')}</h1>
        <ProtectedComponent allow={{ zapps: 'manage' }}>
          <ZButton
            buttonText={t('MTD.ACTIVATIONS.CREATE_GROUP_ACTIVATION')}
            color="secondary"
            styleName="titleBar"
            icon={{ elem: AddIcon }}
            action={toggleModalDiffered(
              'GroupActivationsCreateEdit',
              {
                availableTeams,
                selectedTeamId,
              },
              {
                title: t('MTD.ACTIVATIONS.CREATE_GROUP_ACTIVATION'),
                fullWidth: true,
              }
            )}
          />
        </ProtectedComponent>
      </div>

      <TableColumnsContext.Provider
        value={{
          columnHeaders: props.currentTableHeaders,
          columnHeadersHash: props.columnHeadersHash,
          rowMapping: GroupActivationsColumnMapping(t),
          onColumnChange: props.groupActivationsTableColumnChange,
          onChangePage: onPaginationChange,
          onChangeRows: onPaginationChange,
          onSort: onPaginationChange,
          rowHandlers: {
            availableTeams,
            selectedTeamId,
          },
        }}
      >
        <Table tableId="groupActivations" fetchTableData={fetchTableData} />
      </TableColumnsContext.Provider>
    </div>
  );
}

const mapStateToProps = (state) => ({
  columnHeadersHash: getChangedTableHeadersHash(state, 'groupActivations'),
  currentTableHeaders: getUISettingsTableHeaders(state, 'groupActivations'),
  groupActivationsUiSettings: getUISettingsWithout(state, 'groupActivations', [
    'tableHeaders',
  ]),
  availableTeamsHash: getAvailableTeams(state),
  availableTeams: getAvailableTeamsAsSelectList(state),
  selectedTeam: _.get(state, 'teams.selected'),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      groupActivationsTableColumnChange,
      updateUISettings,
    },
    dispatch
  );

function onPaginationChange(update) {
  updateUISettings({
    domain: 'groupActivations',
    update,
  });
}

export default compose(
  withStyles({}, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(GroupActivations);
