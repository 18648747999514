import { useEffect } from 'react';

const useBackfill = ({
  query = {},
  requiredQueryParams = [],
  storedQueryParams = {},
  updateUrl,
}: {
  query: { [key: string]: unknown };
  requiredQueryParams: string[];
  storedQueryParams: { [key: string]: unknown };
  updateUrl: (...args: any) => void;
}): void => {
  useEffect(() => {
    const missing = requiredQueryParams.filter(
      (param) => !(Object.keys(query) || ([] as string[])).includes(param)
    );

    if (missing.length && Object.values(storedQueryParams).length) {
      updateUrl(
        Object.fromEntries(
          missing.map((item) => [item, storedQueryParams?.[item]])
        )
      );
    }
  }, [query, requiredQueryParams, storedQueryParams, updateUrl]);
};

export default useBackfill;
