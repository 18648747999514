import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    tooltipText: {
      fontSize: 12,
    },
    icon: {
      width: '19px',
      height: '19px',
    },
    tooltipPopper: {
      zIndex: 1000000001,
    },
  })
);

export default useStyles;
