import _ from 'lodash';
import React, { Fragment, useContext } from 'react';

// components

// helpers
import { DrawerContext } from 'components/drawer/DrawerProvider';
import { forensicsConfig } from 'components/main/threats/ThreatForensics';
import GenericDataFilter from 'components/UI/GenericDataFilter';
import moment from 'moment';
import { graphTickFormatter } from 'utils/RangeUtils';
export function getMissingAndStoredParams(props, storeKeys = []) {
  const missingParams = _.difference(
    props.requiredQueryParams,
    Object.keys(props.location.query)
  );

  const storedParams = _.reduce(
    storeKeys,
    (acc, key) => {
      return {
        ...acc,
        ...props[key],
      };
    },
    {}
  );

  return {
    missingParams,
    storedParams,
  };
}

export function backfillUrl(props, storeKeys = []) {
  const { missingParams, storedParams } = getMissingAndStoredParams(
    props,
    storeKeys
  );

  // update the URL
  if (missingParams.length && !_.isEmpty(storedParams)) {
    props.updateUrl(storedParams);
  }

  return {
    missingParams,
    storedParams,
  };
}

export function prepForSelect(
  array,
  additionalFields = [],
  value = 'id',
  label = 'name'
) {
  return array.map((item) => {
    return additionalFields.reduce(
      (acc, field) => ({
        ...acc,
        [field.key]: _.isFunction(field.transform)
          ? field.transform(_.get(item, field.path))
          : _.get(item, field.path),
      }),
      {
        label: item[label],
        value: item[value],
      }
    );
  });
}

export function reduceForMultiSelect(array) {
  return array.reduce((accum, value) => {
    const { id, name } = value;
    accum.push({
      label: name,
      value: id,
      disabled: value.deprecated === true,
      partnerScopes: value.partnerScopes,
    });

    return accum;
  }, []);
}

export function zipReference(
  array = [],
  labelKey = 'name',
  valueKey = 'id',
  additionalFields = []
) {
  return array.reduce(
    (acc, item) => ({
      ...acc,
      [item[valueKey]]: _.pick(item, [labelKey, ...additionalFields]),
    }),
    {}
  );
}

export function unzipReference(
  object = {},
  label = 'name',
  valueKey = 'value',
  additionalFields = []
) {
  const [path, labelKey] = label.split(',');

  return Object.keys(object)
    .filter((key) => key !== 'cached')
    .map((key) => ({
      [labelKey || 'label']: object[key][path],
      [valueKey]: key,
      ..._.pick(object[key], additionalFields),
    }));
}

export function reduceForMultiSelectWithGlobal(array) {
  return array.reduce((accum, value) => {
    const { id, name, team } = value;
    let accountBounded = false;
    if (!team && !team?.id) {
      accountBounded = true;
    }
    accum.push({
      label: name,
      value: id,
      accountBounded,
      ...value,
    });
    return accum;
  }, []);
}
export function reduceForMultiSelectWithGlobalForZScan(array) {
  return array.reduce((accum, value) => {
    const { id, name, teamId } = value;
    let accountBounded = false;
    if (!teamId) {
      accountBounded = true;
    }
    accum.push({
      label: name,
      value: id,
      accountBounded,
      ...value,
    });
    return accum;
  }, []);
}

const RenderForensicsComponent = (props) => {
  const { closeDrawer } = useContext(DrawerContext);

  return (
    <div
      key={`${props.configObj.key}-${props.index}`}
      className={props.classes.dataWrapper}
    >
      <div className={props.classes.label}>{props.configObj.label}</div>
      <div className={props.classes.labelValue}>
        {props.configObj.filterable ? (
          <GenericDataFilter
            tableValue={props.value}
            filterValue={props.filterValue}
            path={props.path}
            onClick={closeDrawer}
          />
        ) : (
          <Fragment>{props.value}</Fragment>
        )}
      </div>
    </div>
  );
};

export function renderForensics(forensicKey, props) {
  return _.has(props.threat, forensicKey)
    ? forensicsConfig[forensicKey].map((configObj, index) => {
        const path = configObj.path ? configObj.path : configObj.key;
        let value = configObj.defaultValue;
        let filterValue;
        const doNotRenderIfEmpty = new Set([
          'sideLoadedAppInfo',
          'generalInfo.vulnerableOsVersion',
          'generalInfo.expectedOsVersion',
          'generalInfo.vulnerableSecurityPatch',
          'generalInfo.expectedSecurityPatch',
          'generalInfo.deviceManufacturer',
          'generalInfo.deviceModel',
          'generalInfo.buildInformation',
        ]);

        if (_.has(props.threat, path)) {
          value = _.get(props.threat, path);
          filterValue = value === null ? undefined : value;
          if (_.isBoolean(filterValue)) {
            filterValue = filterValue.toString();
          }
        }
        if (typeof configObj.fetch === 'function') {
          value = configObj.fetch(_.get(props.threat, path), props);
        }
        if (doNotRenderIfEmpty.has(forensicKey) && _.isEmpty(value)) {
          return null;
        }
        if (doNotRenderIfEmpty.has(path) && _.isEmpty(value)) {
          return null;
        }
        /*
  'threatTypeId' param is required to add a chip for 'Threat Name' field
  so updating the path name for 'Summary' from 'threatTypeId' to 'summary'
*/
        // if (_.isEqual(path, 'threatTypeId')) {
        //   path = 'summary';
        // }
        return (
          <RenderForensicsComponent
            configObj={configObj}
            classes={props.classes}
            index={index}
            value={value}
            filterValue={filterValue}
            path={path}
          />
        );
      })
    : null;
}

export function formatTimestampAndUpdateUrl(timestamp, props) {
  const { duration } = props.location.query;
  let start = timestamp;
  let end = start;

  // Adding days to the end date to form a range
  let daysToAdd = 0;

  if (duration) {
    switch (Number(duration)) {
      // Rough estimate of days to add, possibly change
      case 5:
        daysToAdd = 1;
        break;
      case 6:
        daysToAdd = 3;
        break;
      case 7:
        daysToAdd = 5;
        break;
      case 8:
        daysToAdd = 7;
        break;
      default:
        daysToAdd = 0;
    }
  } else {
    daysToAdd = 1;
  }

  start = moment(timestamp).unix();
  end = moment(timestamp).add(daysToAdd, 'd').unix();

  props.updateUrl({ from: start, to: end, duration: undefined });
}

export function formatTimeStamp(timestamp, props) {
  const { duration } = props.location.query;
  let start = timestamp;
  let end = start;

  // Adding days to the end date to form a range
  let daysToAdd = 0;

  if (duration) {
    switch (Number(duration)) {
      // Rough estimate of days to add, possibly change
      case 5:
        daysToAdd = 1;
        break;
      case 6:
        daysToAdd = 3;
        break;
      case 7:
        daysToAdd = 5;
        break;
      case 8:
        daysToAdd = 7;
        break;
      default:
        daysToAdd = 0;
    }
  } else {
    daysToAdd = 1;
  }

  start = moment(timestamp).unix();
  end = moment(timestamp).add(daysToAdd, 'd').unix();
  return { start, end };
}

export function formatXAxisTick(timestamp, props) {
  const range = Number(props.location.query.duration);
  const { from, to } = props.location.query;
  const value = graphTickFormatter(range, from, to)(timestamp);
  return value;
}

export function generateMultiSelectValue(value, list, keyToMatch = 'value') {
  if (_.isArray(value)) {
    // match values with array here
    const result = list.filter((option) => {
      return value.includes(option[keyToMatch]);
    });
    return result;
  }
  return [_.find(list, { [keyToMatch]: value })];
}

export const looksGenerallyLikeAnEmailRegex = /^.*@.*\..{2,}$/;

//TODO: delete this const when its totally localized
export const looksGenerallyLikeAnEmailRegexErrorMessage =
  'Must be a valid email address.';

export const csvInjectionRegex = /^[^\=+\-@\'\"\r,\;]/;

//TODO: delete this const when its totally localized
export const csvInjectionErrorMessage =
  'Must not begin with special characters.';

export const csvInjectionErrorMessageLocalized =
  'MTD.VALIDATIONS.MUST_NOT_BEGIN_WITH_SPECIAL_CHARACTERS';

export const passwordMessageErrorHelper = (errorData) => {
  return errorData.join('\n');
};

export function getModuleContext(pathname) {
  const parts = pathname.split('/');
  return parts[2];
}

export const generateJsonFile = (fileName, data) => {
  const link = document.createElement('a');
  link.href = `data:text/json;charset=utf-8,${encodeURIComponent(
    JSON.stringify(data)
  )}`;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
