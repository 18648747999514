import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
// utils
import { renderForensics } from 'utils/componentUtils';

const styles = themeSelected => {
  const { palette } = themeSelected;
  return {
    wrapper: {
      display: 'flex',
      alignSelf: 'flex-start',
      flexDirection: 'column',
      width: '60%',
    },
    dataWrapper: {
      display: 'flex',
      flexDirection: 'row',
      padding: 5,
      justifyContent: 'space-between',
    },
    label: {
      color: palette.text.secondary,
      fontWeight: 'bold',
      fontSize: '13px',
      width: '25%',
    },
    labelValue: {
      // color: tables.cell,
      fontSize: '13px',
      width: '75%',
      display: 'flex',
    },
  };
};

class SideLoadedAppInfo extends Component {
  render() {
    const { props } = this;
    return (
      <div className={props.classes.wrapper}>
        {renderForensics('sideLoadedAppInfo', props)}
      </div>
    );
  }
}

export default withStyles(styles)(SideLoadedAppInfo);
