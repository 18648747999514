import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

/* Components */
import ZTableFilterMenu from 'UI/Menus/ZTableFilterMenu';

// ui
import SettingsIcon from '@material-ui/icons/Settings';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ProtectedComponent from '../components/main/protected/ProtectedComponent';
import { withTranslation } from 'react-i18next';

/**
 * @description prop scope is passed to show a particular column on a table.
 * to remove in table header, You must remove from each tableHeader right now.
 */
const styles = () => {
  return {
    SettingsIcon: {
      cursor: 'pointer',
    },
    MenuItem: {
      fontSize: '12px',
      padding: 6,
    },
  };
};

class ColumnSelector extends Component {
  /* Filter out any items that are EXPLICITLY marked to not show in context menu */
  options = this.props.defaultOptions.filter(
    ({ showInContextMenu }) => showInContextMenu !== false
  );
  render() {
    const { defaultOptions, open, classes, columnChange, t } = this.props;
    defaultOptions.map((value) => value.id);
    return (
      <div>
        <SettingsIcon
          id={this.props.id}
          action={this.props.action}
          className={classes.SettingsIcon}
          onClick={this.props.toOpen}
        />
        <div className="testtesttest">
          <ZTableFilterMenu
            id="render-props-popover"
            open={open}
            onClose={this.props.onClose}
            anchorEl={this.props.anchorEl}
            overflowAuto
          >
            {this.options.map((option, index) => {
              if (option.scope) {
                return (
                  <ProtectedComponent
                    allow={{
                      [option.scope]: option.isProtected ? 'manage' : 'view',
                    }}
                    key={option.id}
                  >
                    <MenuItem
                      className={classes.MenuItem}
                      onClick={() =>
                        columnChange(!option.show, index, this.options)
                      }
                    >
                      <Checkbox color="primary" checked={option.show} />
                      {option.columnSelectorLabel
                        ? t(option.columnSelectorLabel)
                        : t(option.label)}
                    </MenuItem>
                  </ProtectedComponent>
                );
              }
              return (
                <MenuItem
                  className={classes.MenuItem}
                  key={option.id}
                  disabled={option.disable}
                  onClick={() =>
                    columnChange(!option.show, index, this.options)
                  }
                >
                  <Checkbox color="primary" checked={option.show} />
                  {option.columnSelectorLabel
                    ? option.columnSelectorLabel
                    : t(option.label)}
                </MenuItem>
              );
            })}
          </ZTableFilterMenu>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation()(ColumnSelector));
