import _ from 'lodash';
import React, { PureComponent } from 'react';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import classNames from 'classnames';

// material ui
import Snackbar from '@material-ui/core/Snackbar';

// components
// import GlobalHeader from 'components/main/common/GlobalHeader';
import ProtectedRoute from 'routes/ProtectedRoute';
import Profile from 'components/main/profile/Profile';

// selectors
import { getUserValid } from 'reducers/UserDetailsReducer';
import { getSnackbar, closeSnackbar } from 'reducers/SnackbarReducers';
import { getUserScopesRoles } from 'reducers/UserReducers';
import {
  getAvailableTeamsAsList,
  updateAvailableTeams,
} from 'reducers/TeamReducers';
import {
  getSelectedTeam,
  updateSelectedTeam,
} from 'reducers/UiSettingsReducers';

// actions
import { requestAllTeams } from 'api/apis';
import { CONSOLE_MANAGE_ROUTES } from 'console/manage/manage.routes';
import { CONSOLE_ZDEV_ROUTES } from 'console/zdev/zdev.routes';
import { CONSOLE_ZIAP_ROUTES } from 'console/ziap/ziap.routes';
import { CONSOLE_ZSHIELD_ROUTES } from 'console/zshield/zshield.routes';

// services
import { ConsoleLanding } from 'console/ConsoleLanding';
import { ConsoleNotFound } from 'console/ConsoleNotFound';
import ConsoleSidebar from 'console/ConsoleSidebar';
import GlobalHeader from 'console/GlobalHeader';
import withRouter from '../hocs/withRouter';

import { CONSOLE_MTD_ROUTES } from '../../console/mtd/mtd.routes';

class DashboardRoutesWrapper extends PureComponent {
  componentDidMount() {
    const { props } = this;
    // populate available & selected teams if the user has scope permissions
    if (!_.isEmpty(props.scopePermissions)) {
      requestAllTeams().then((resp) => {
        if (resp.data.length) {
          props.updateAvailableTeams(resp.data);

          // if we have it in the store && if it's in available teams that came back ^^^^
          // don't update the store
          // updateSelectTeam
          if (
            props.selectedTeam &&
            _.find(resp.data, { id: props.selectedTeam })
          ) {
            props.updateSelectedTeam(props.selectedTeam);
          } else if (props.selectedTeam !== null) {
            // If selected team id exists, and "All Teams" (represented by null) is NOT selected,
            // select the first team in the dropdown. Leave dropdown alone if "All Teams" selected.
            props.updateSelectedTeam(_.head(resp.data).id);
          }
        }
      });
    }
  }

  render() {
    const { props } = this;
    const mainRootClass = classNames('main-root-wrapper', {
      takewarning: !props.userValid,
    });

    return (
      <div className={mainRootClass}>
        <GlobalHeader className="main-header-wrapper" />
        <div className="main-root-container">
          <ConsoleSidebar />
          <div className="main-content">
            <div className="main-content__container">
              <Switch>
                {[
                  {
                    path: '/console/user-settings',
                    component: Profile,
                    exact: true,
                  },
                  {
                    path: '/console',
                    component: ConsoleLanding,
                    exact: true,
                    rqps: ['teamId', 'zappId', 'duration'],
                  },
                  ...CONSOLE_MANAGE_ROUTES,
                  ...CONSOLE_ZIAP_ROUTES,
                  ...CONSOLE_ZDEV_ROUTES,
                  ...CONSOLE_MTD_ROUTES,
                  ...CONSOLE_ZSHIELD_ROUTES,
                ].map((route, i) => {
                  return (
                    <ProtectedRoute key={`public-route-${i}`} {...route} />
                  );
                })}
                <ProtectedRoute path="*" component={ConsoleNotFound} />
              </Switch>
            </div>
          </div>
          <Snackbar {...props.snackbar} onClose={props.closeSnackbar} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userValid: getUserValid(state),
    snackbar: getSnackbar(state),
    scopePermissions: getUserScopesRoles(state),
    availableTeams: getAvailableTeamsAsList(state),
    selectedTeam: getSelectedTeam(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeSnackbar,
      updateAvailableTeams,
      updateSelectedTeam,
    },
    dispatch
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DashboardRoutesWrapper);
