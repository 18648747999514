import MUIWifiTetheringIcon from '@material-ui/icons/WifiTethering';
import Numeral from 'config/numeral/Numeral';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { IQueryParams } from '../common/models';
import { IVector } from '../ThreatsAppBar/models';
import useStyles from './useStyles';
import { generateQuery, ICON_MAP } from './utils';
import { useTranslation } from 'react-i18next';

interface IVectorCardProps {
  hide?: boolean;
  matchUrl?: string;
  query: IQueryParams;
  vector: IVector;
}

const VectorCard: React.FC<IVectorCardProps> = ({
  hide = false,
  query,
  vector,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const ELEVATED = 2;
  const CRITICAL = 3;

  const icon = useMemo(
    () =>
      !!vector?.vectorType &&
      Object.keys(ICON_MAP).includes(String(vector.vectorType)) ? (
        ICON_MAP[vector.vectorType]
      ) : (
        <MUIWifiTetheringIcon />
      ),
    [vector]
  );

  if (hide) {
    return null;
  }

  return (
    <div className={classes.vectorCard}>
      <div className={classes.backgroundCircle}>
        <div className={classes.vectorIcon}>{icon}</div>
      </div>
      <div className={classes.vectorText}>
        <div className={classes.vectorCount}>
          <Link
            to={generateQuery(query, '', vector?.vectorType)}
            className={classes.vectorCount}
            title={String(vector?.count ?? '0')}
          >
            {Numeral(vector?.count ?? '0').format('a')}
          </Link>
        </div>

        <div className={classes.vectorDescription}>
          {t(
            vector?.name
              ? `MTD.THREATS.${vector.name.toUpperCase()}_THREATS`
              : 'GLOBAL.THREATS'
          )}
        </div>
      </div>
      {vector?.count !== 0 && (
        <div className={classes.severityCounts}>
          <div className={classes.critical}>
            <Link
              to={generateQuery(query, '', vector?.vectorType, CRITICAL)}
              title={String(vector?.critical ?? '0')}
              className={classes.critical}
            >
              {Numeral(vector?.critical ?? '0').format('a')}{' '}
              {t('GLOBAL.CRITICAL')}
            </Link>
          </div>

          <div className={classes.elevated}>
            <Link
              to={generateQuery(query, '', vector?.vectorType, ELEVATED)}
              title={String(vector?.elevated ?? '0')}
              className={classes.elevated}
            >
              {Numeral(vector?.elevated ?? '0').format('a')}{' '}
              {t('GLOBAL.ELEVATED')}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default VectorCard;
