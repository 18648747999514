import * as Yup from 'yup';
import {
  csvInjectionRegex,
  csvInjectionErrorMessage,
} from 'utils/componentUtils';
import { TFunction } from 'react-i18next';

const Schema = (t: TFunction<'translation', undefined>) => {
  return Yup.object().shape({
    baseUrl: Yup.string()
      .matches(csvInjectionRegex, csvInjectionErrorMessage)
      .required(t('MTD.VALIDATIONS.URL_IS_REQUIRED')),
    name: Yup.string()
      .matches(csvInjectionRegex, csvInjectionErrorMessage)
      .required(t('GLOBAL.REQUIRED_NAME')),
    password: Yup.string().when('modalMode', {
      is: 'ADD',
      then: Yup.string().required(
        t('MTD.VALIDATIONS.MDM_PASSWORD_IS_REQUIRED')
      ),
    }),
    proxy_config_id: Yup.string().when('connectorId', {
      is: 'UemConnector',
      then: Yup.mixed().required(
        t('MTD.VALIDATIONS.BLACK_BERRY_PROXY_IS_REQUIRED')
      ),
    }),
    api_key: Yup.string().when('connectorId', {
      is: 'MobiControlConnector',
      then: Yup.mixed().required(t('MTD.VALIDATIONS.API_KEY_IS_REQUIRED')),
    }),
    user_id: Yup.string()
      .matches(csvInjectionRegex, csvInjectionErrorMessage)
      .required(t('MTD.VALIDATIONS.MDM_USERNAME_IS_REQUIRED')),
    team: Yup.mixed().test({
      name: 'team-required-if-all-teams',
      // function keyword used below because we need access to "this"
      test: function (value, ctx) {
        if (!!ctx?.parent?.selectedTeamId) {
          return true;
        }

        return !value
          ? this?.createError({
              message: t('GLOBAL.REQUIRED_TEAM'),
              path: 'team',
            })
          : true;
      },
    }),
  });
};

export default Schema;
