import MUIAndroidIcon from '@material-ui/icons/Android';
import ProtectedLink from 'components/main/protected/ProtectedLink';
import AppleIcon from 'components/UI/icons/AppleIcon';
import {
  IColumnHeader,
  IFilterEditor,
  ITableRowMapping,
} from 'components/UI/Table/models';
import { Link } from 'react-router-dom';
import { IFullData } from '../common/models';
import {
  IFilterValues,
  IOSRelatedCVEsRowData,
  TCVESecurityMappings,
} from './models';
import { AVAILABLE_OSES, AVAILABLE_SEVERITIES } from './utils';
import { TFunction } from 'react-i18next';

export const defaultMtdOSRelatedCVEsTableHeaders: IColumnHeader[] = [
  {
    id: 'id',
    label: 'MTD.OS_RISK.CVE',
    show: true,
    path: 'id',
    sort: 1,
    minWidth: 130,
    maxWidth: 230,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    filterEditor: 'SelectFilter',
    filterEditorProps: { multiple: true, wrapMultiple: false },
    id: 'severity',
    label: 'MTD.OS_RISK.CVE_SEVERITY',
    show: true,
    path: 'severity',
    sort: 1,
    minWidth: 130,
    maxWidth: 230,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    id: 'type',
    label: 'MTD.OS_RISK.CVE_TYPE',
    show: true,
    path: 'type',
    sort: 1,
    minWidth: 130,
    maxWidth: 230,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    filterEditor: 'SelectFilter',
    filterEditorProps: { multiple: true, wrapMultiple: false },
    id: 'oses',
    label: 'GLOBAL.PLATFORM',
    show: true,
    path: 'oses',
    sort: 1,
    minWidth: 130,
    maxWidth: 230,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    filterEditor: 'NumberFilter',
    id: 'osvCount',
    label: 'MTD.OS_RISK.VERSION_AFFECTED',
    show: true,
    path: 'osvCount',
    sort: 1,
    minWidth: 130,
    maxWidth: 230,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    filterEditor: 'NumberFilter',
    id: 'deviceCount',
    label: 'GLOBAL.DEVICE_COUNT',
    show: true,
    path: 'deviceCount',
    sort: 1,
    minWidth: 130,
    maxWidth: 230,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    filterEditor: 'NumberFilter',
    id: 'upgradeableCount',
    label: 'GLOBAL.UPGRADEABLE',
    show: true,
    path: 'upgradeableCount',
    sort: 1,
    minWidth: 130,
    maxWidth: 230,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    filterEditor: 'NumberFilter',
    id: 'nonUpgradeableCount',
    label: 'GLOBAL.NOT_UPGRADEABLE',
    show: true,
    path: 'nonUpgradeableCount',
    sort: 1,
    minWidth: 130,
    maxWidth: 230,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    filterEditor: 'SelectFilter',
    filterEditorProps: { multiple: true, wrapMultiple: false },
    id: 'activeExploit',
    label: 'GLOBAL.ACTIVE_EXPLOIT',
    show: true,
    path: 'activeExploit',
    sort: 1,
    minWidth: 130,
    maxWidth: 230,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    id: 'dd',
    label: '',
    show: true,
    sort: 0,
    minWidth: 35,
    maxWidth: 35,
    flex: 0,
    showInContextMenu: false,
  },
];

export const COLUMN_MAPPINGS = (
  t: TFunction<'translation', undefined>
): ITableRowMapping[] => [
  {
    path: 'id',
    columnContent: (fullData: IFullData<IOSRelatedCVEsRowData>) => {
      const id = fullData?.rowData?.id ?? '';
      return (
        <a
          className={fullData.classList.link}
          href={`https://nvd.nist.gov/vuln/detail/${id}`}
          rel="noreferrer"
          target="_blank"
        >
          {id}
        </a>
      );
    },
  },
  {
    path: 'severity',
    columnContent: (fullData: IFullData<IOSRelatedCVEsRowData>) => {
      const {
        classList,
        rowData: { severity },
      } = fullData;

      if (!severity) {
        return '';
      }

      const severityStatuses: TCVESecurityMappings = {
        low: { class: classList.green, name: 'Low' },
        medium: { class: classList.orange, name: 'Medium' },
        elevated: { class: classList.orange, name: 'Medium' },
        high: { class: classList.red, name: 'High' },
        critical: { class: classList.red, name: 'Critical' },
      };

      const riskStatus = severityStatuses[severity.toLowerCase()];

      let statusClasses = classList.statusCircle + ' ';
      statusClasses += riskStatus?.class ?? '';

      return (
        <div className="flex--spaced">
          <div className={statusClasses} />
          {t(`GLOBAL.${riskStatus?.name.toUpperCase()}`) ?? ''}
        </div>
      );
    },
  },
  { path: 'type' },
  {
    path: 'oses',
    columnContent: (fullData: IFullData<IOSRelatedCVEsRowData>) => {
      const {
        rowData: { oses },
      } = fullData;

      if (!oses || oses.length === 0) {
        return '';
      }

      return oses.map((os) => {
        const osOptions = ['Android', 'iOS'];
        const platform = osOptions[os - 1];

        const Icon = os === 2 ? AppleIcon : MUIAndroidIcon;

        return (
          <div className={fullData.classList.osWrapper}>
            <div className={fullData.classList.iconWrapper}>
              <Icon />
            </div>
            {t(`GLOBAL.${platform.toUpperCase()}`)}
          </div>
        );
      });
    },
  },
  { path: 'osvCount' },
  {
    path: 'deviceCount',
    columnContent: (fullData: IFullData<IOSRelatedCVEsRowData>) => {
      const { id: cve } = fullData.rowData;
      const params = new URLSearchParams({
        duration: '9',
        cve,
      });

      return (
        <ProtectedLink
          allow={{ devices: 'view' }}
          to={`/console/mtd/devices/?${params.toString()}`}
        >
          {fullData?.rowData?.deviceCount ?? ''}
        </ProtectedLink>
      );
    },
  },
  {
    path: 'upgradeableCount',
    columnContent: (fullData: IFullData<IOSRelatedCVEsRowData>) => {
      const { id: cve } = fullData.rowData;
      const params = new URLSearchParams({
        duration: '9',
        cve,
        upgradeable: 'true',
      });

      return (
        <ProtectedLink
          allow={{ devices: 'view' }}
          to={`/console/mtd/devices/?${params.toString()}`}
        >
          {fullData?.rowData?.upgradeableCount ?? ''}
        </ProtectedLink>
      );
    },
  },
  {
    path: 'nonUpgradeableCount',
    columnContent: (fullData: IFullData<IOSRelatedCVEsRowData>) => {
      const { id: cve } = fullData.rowData;
      const params = new URLSearchParams({
        duration: '9',
        cve,
        upgradeable: 'false',
      });

      return (
        <ProtectedLink
          allow={{ devices: 'view' }}
          to={`/console/mtd/devices/?${params.toString()}`}
        >
          {fullData?.rowData?.nonUpgradeableCount ?? ''}
        </ProtectedLink>
      );
    },
  },
  {
    path: 'activeExploit',
    columnContent: (fullData: IFullData<IOSRelatedCVEsRowData>) => {
      const { url, activeExploit } = fullData.rowData;
      if (!activeExploit) {
        return t('GLOBAL.NO');
      }
      return (
        <>
          🔥{' '}
          {url ? (
            <Link to={{ pathname: url }} target="_blank">
              <>{t('GLOBAL.YES')}</>
            </Link>
          ) : (
            t('GLOBAL.YES')
          )}
        </>
      );
    },
  },
];

export const resolveFilters = (
  filterValues: IFilterValues,
  t: TFunction<'translation', undefined>
): IFilterEditor[] => {
  return [
    {
      name: 'id',
      operator: 'eq',
      type: 'string',
      value: filterValues?.id ?? '',
    },
    {
      name: 'severity',
      operator: 'eq',
      type: 'select',
      value: filterValues?.severity ?? '',
      filterEditorProps: {
        dataSource: AVAILABLE_SEVERITIES.map(({ label, value }) => ({
          id: value,
          label: t(`GLOBAL.${label.toUpperCase()}`),
        })),
      },
    },
    {
      name: 'type',
      operator: 'eq',
      type: 'string',
      value: filterValues?.type ?? '',
    },
    {
      name: 'oses',
      operator: 'inlist',
      type: 'select',
      value: filterValues?.oses ?? null,
      customRSQLOperator: 'filterArray',
      filterEditorProps: {
        dataSource: AVAILABLE_OSES.map(({ label, value }) => ({
          id: value,
          label: t(`GLOBAL.${label.toUpperCase()}`),
        })),
      },
    },
    {
      name: 'osvCount',
      operator: 'eq',
      type: 'number',
      value: filterValues?.osvCount ?? null,
      filterEditorProps: { minValue: 0 },
    },
    {
      name: 'deviceCount',
      operator: 'eq',
      type: 'number',
      value: filterValues?.deviceCount ?? null,
      filterEditorProps: { minValue: 0 },
    },
    {
      name: 'upgradeableCount',
      operator: 'eq',
      type: 'number',
      value: filterValues?.upgradeableCount ?? null,
      filterEditorProps: { minValue: 0 },
    },
    {
      name: 'nonUpgradeableCount',
      operator: 'eq',
      type: 'number',
      value: filterValues?.nonUpgradeableCount ?? null,
      filterEditorProps: { minValue: 0 },
    },
    {
      name: 'activeExploit',
      operator: 'inlist',
      type: 'select',
      value: filterValues?.activeExploit,
      filterEditorProps: {
        dataSource: [
          { label: t('GLOBAL.YES'), id: true },
          { label: t('GLOBAL.NO'), id: 'false' },
        ],
      },
    },
  ];
};
