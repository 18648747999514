import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette: { components } }: any) =>
  createStyles({
    checkbox: {},
    label: {
      fontSize: 15,
    },
    switch: {
      cursor: 'pointer',
      display: 'inline-block',
      height: 12,
      margin: '12px 20px 12px 0',
      position: 'relative',
      width: 28,

      '& input': {
        height: 0,
        opacity: 0,
        width: 0,
      },
    },
    slider: {
      backgroundColor: components?.checkbox?.trackColor,
      borderRadius: 34,
      bottom: 0,
      left: 0,
      pointerEvents: 'none',
      position: 'absolute',
      right: 0,
      top: 0,
      transition: '.4s',

      '&::before': {
        backgroundColor: components?.checkbox?.pipColor ?? 'white',
        borderRadius: '50%',
        content: '""',
        height: 18,
        left: 0,
        position: 'absolute',
        transform: 'translateY(-15%)',
        transition: '.4s',
        width: 18,
      },
    },
    sliderChecked: {
      backgroundColor: components?.checkbox?.activeColor ?? 'green',

      '&::before': {
        content: '""',
        transform: 'translateX(11px) translateY(-15%)',
      },
    },
    sliderDisabled: {
      opacity: 0.33,
    },
  })
);

export default useStyles;
