import _ from 'lodash';
import { ServiceHandlerFactory } from 'utils/serviceUtils';
import { fetchBuildsParamsMapping } from 'mappings/services/buildsServiceMappings';
import {
  fetchAppStatsParamMapping,
  fetchDashboardAggParamMapping,
} from 'mappings/services/zDevServiceMappings';
import {
  fetchFindingsParamsMapping,
  fetchFindingsStatsParamsMapping,
} from 'mappings/services/findingsServiceMappings';
import { fetchAppParamMapping } from 'mappings/buildsAppsMapping';
import { fetchInstancesParamsMapping } from 'mappings/services/instancesServiceMapping';
import { Api } from 'config/axiosConfig';
import { zScanAssessmentParams } from 'components/main/ZScanApps/ZScanAssessments/utils';
import { zScanFindingsParamsMapping } from 'components/main/ZScanApps/ZScanFindings/utils';
import { zScanCompareFindingsParamsMapping } from 'components/main/ZScanApps/ZScanCompareAssessments/ZScanCompareAssessments.mappings';

// helper func to find which assessements have findings
export const cleanBuildsTable = (tableContent) => {
  return tableContent.map((assessment) => ({
    ...assessment,
    buildFindingsId: assessment.buildFindings && assessment.id,
  }));
};

export const zDevUploadBuildErrorHelper = (e) => {
  // upload times out
  if (e?.response?.status === 401) {
    return 'Unauthorized - Session Timeout';
  }
  if (e?.response?.status === 503) {
    return 'A Bad Gateway (503) error was generated during upload';
  }
  if (e?.response?.status === 504) {
    return 'A request timeout was generated during upload.';
  }

  const errorStatus = _.get(e, 'response.statusText', 'Upload Error');
  const responseData = _.get(e, 'response.data', 'Please Try Again');
  if (errorStatus === 'Conflict') {
    return 'File already exists';
  }
  return `${errorStatus} - ${responseData}`;
};

const policyBase = '/api/zdev-app/v1';
const appsBase = '/api/zdev-app/v1/apps';
const findingsBase = 'api/zdev-app/v1/findings';
const assessmentBase = '/api/zdev-app/v1/assessments';
const downloadBase = 'api/zdev-upload/v1/download';
const instancesBase = 'api/zdev-app/v1/locations';
const scriptBase = 'api/zdev-app/v1/emulatorscript';
const sbomBase = 'api/zdev-app/v1/sboms';

// findings
export const getFindings = ServiceHandlerFactory({
  axios: {
    url: `${findingsBase}`,
  },
  isCancelable: true,
  paramSchema: fetchFindingsParamsMapping,
});

export const getPolicyCustomCvssData = ServiceHandlerFactory({
  axios: {
    url: `${policyBase}/policies/:policyId/customCvssData`,
  },
  isCancelable: true,
});

export const updatePolicyCustomCvssData = ServiceHandlerFactory({
  axios: {
    url: `${policyBase}/policies/:policyId/customCvssData`,
    method: 'put',
  },
  isCancelable: true,
});

export const updateAppFindingCustomCvssData = ServiceHandlerFactory({
  axios: {
    url: `${findingsBase}/:findingId/customCvssData`,
    method: 'put',
  },
  isCancelable: true,
});

export const getFindingsStats = ServiceHandlerFactory({
  axios: {
    url: `${findingsBase}/stats`,
  },
  isCancelable: true,
  paramSchema: fetchFindingsStatsParamsMapping,
});

export const getFindingsGraphStats = ServiceHandlerFactory({
  axios: {
    url: `${findingsBase}/graph`,
  },
  isCancelable: true,
  paramSchema: fetchFindingsStatsParamsMapping,
});

export const getFindingsAggregations = ServiceHandlerFactory({
  axios: {
    url: `${findingsBase}/dashboard?accepted=false`,
  },
  isCancelable: true,
  paramSchema: fetchDashboardAggParamMapping,
  // paramSchema: fetchFindingsAggregationsParamsMapping,
});

export const acceptFindings = ServiceHandlerFactory({
  axios: {
    url: `${findingsBase}/accept`,
    method: 'post',
  },
});

export const unacceptFindings = ServiceHandlerFactory({
  axios: {
    url: `${findingsBase}/unaccept`,
    method: 'post',
  },
});

export const updateStatusFindings = ServiceHandlerFactory({
  axios: {
    url: `${findingsBase}/updateStatus`,
    method: 'post',
  },
});

export const createTicketFinding = ServiceHandlerFactory({
  axios: {
    url: `${findingsBase}/createTicket`,
    method: 'post',
  },
  isCancelable: true,
});

export const createTicketForFinding = ServiceHandlerFactory({
  axios: {
    url: `${policyBase}/tickets`,
    method: 'post',
  },
  isCancelable: true,
});

export const getIntegrationConfigsData = ServiceHandlerFactory({
  axios: {
    url: `${policyBase}/integration-configs?teamId=:teamId`,
    method: 'GET',
  },
});

// get a single finding for instances

export const getFindingById = ServiceHandlerFactory({
  axios: {
    url: `${findingsBase}/:id`,
  },
  isCancelable: true,
});

// instances

export const getInstances = ServiceHandlerFactory({
  axios: {
    url: `${instancesBase}`,
  },
  isCancelable: true,
  paramSchema: fetchInstancesParamsMapping,
});

export const acceptInstances = ServiceHandlerFactory({
  axios: {
    url: `${instancesBase}/accept`,
    method: 'post',
  },
});

export const unacceptInstances = ServiceHandlerFactory({
  axios: {
    url: `${instancesBase}/unaccept`,
    method: 'post',
  },
});

// builds
export const getBuilds = ServiceHandlerFactory({
  axios: {
    url: `${assessmentBase}`,
  },
  isCancelable: true,
  paramSchema: fetchBuildsParamsMapping,
});

export const deleteSelectedZScanAssessment = ServiceHandlerFactory({
  axios: {
    url: `${assessmentBase}`,
    method: 'delete',
  },
  isCancelable: true,
  paramSchema: [{ path: 'assessmentId' }],
});

export const getZScanAssessments = ServiceHandlerFactory({
  axios: {
    url: `${assessmentBase}`,
  },
  isCancelable: true,
  paramSchema: zScanAssessmentParams,
});

export const getZScanFindings = ServiceHandlerFactory({
  axios: {
    url: `${assessmentBase}/:assessmentId/findings`,
  },
  isCancelable: true,
  paramSchema: zScanFindingsParamsMapping,
});

export const createBuild = ServiceHandlerFactory({
  axios: {
    url: '/api/builds/v1/builds',
    method: 'post',
  },
});

// assessments
export const getAssessmentDetails = ServiceHandlerFactory({
  axios: {
    url: `${assessmentBase}/:id`,
  },
  // we do not want this to be cancelable because we need two quick calls for build compare
  // isCancelable: true,
});

export const getLatestAssessment = ServiceHandlerFactory({
  axios: {
    url: `${assessmentBase}/latest`,
  },
});

export const pinAssessment = ServiceHandlerFactory({
  axios: {
    url: `${assessmentBase}/:id/pin`,
    method: 'put',
  },
});

export const unpinAssessment = ServiceHandlerFactory({
  axios: {
    url: `${assessmentBase}/:id/unpin`,
    method: 'put',
  },
});

export const generateAssessmentPDFReport = ServiceHandlerFactory({
  axios: {
    url: `${assessmentBase}/:id/report`,
  },
});

export const generateAssessmentJSONReportByAppId = ServiceHandlerFactory({
  axios: {
    url: `${appsBase}/:id/json`,
  },
});

export const generateAssessmentJSONReportByAssessmentId = ServiceHandlerFactory(
  {
    axios: {
      url: `${assessmentBase}/:id/json`,
    },
    isCancelable: true,
    paramSchema: [{ path: 'policyId' }],
  }
);
export const generateFindingsByAssessmentId = ServiceHandlerFactory({
  axios: {
    url: `${assessmentBase}/:id/findings`,
  },
  isCancelable: true,
  paramSchema: zScanCompareFindingsParamsMapping,
});
// policies
export const getPolicies = ServiceHandlerFactory({
  axios: {
    url: `${policyBase}/policies`,
  },
  isCancelable: true,
});

export const getPolicy = ServiceHandlerFactory({
  axios: {
    url: `${policyBase}/policies/:policyId`,
  },
  isCancelable: true,
});

export const createPolicy = ServiceHandlerFactory({
  axios: {
    url: `${policyBase}/policies`,
    method: 'post',
  },
});

export const updatePolicy = ServiceHandlerFactory({
  axios: {
    url: `${policyBase}/policies/:policyId`,
    method: 'put',
  },
});

export const setPolicyDefault = ServiceHandlerFactory({
  axios: {
    url: `${policyBase}/policies/:policyId/default`,
    method: 'put',
  },
});

export const clonePolicy = ServiceHandlerFactory({
  axios: {
    url: `${policyBase}/policies/:policyId/clone`,
    method: 'post',
  },
});

export const deletePolicy = ServiceHandlerFactory({
  axios: {
    url: `${policyBase}/policies/:policyId`,
    method: 'delete',
  },
});

export const getIndicators = ServiceHandlerFactory({
  axios: {
    url: `${policyBase}/findings`,
  },
  paramSchema: [
    {
      path: 'category',
      paramKey: 'category.id.keyword',
    },
    {
      path: 'subcategory',
      paramKey: 'subcategory.id.keyword',
    },
    {
      paramKey: 'sort',
      transform: ({ orderBy, order }) => {
        switch (orderBy) {
          case 'name':
            return `${orderBy}.sort,${order}`;
          case 'category.name':
          case 'subcategory.name':
          case 'severity':
            return `${orderBy}.keyword,${order}`;
          default:
            if (orderBy && order) {
              return `${orderBy},${order}`;
            }
            return null;
        }
      },
    },
  ],
});

export const getPolicyFindings = ServiceHandlerFactory({
  axios: {
    url: `${policyBase}/findings`,
  },
});

export const getIndicatorsByQuery = ServiceHandlerFactory({
  axios: {
    url: `${policyBase}/indicators/query`,
  },
  // isCancelable: true,
  paramSchema: [
    {
      path: 'search',
      transform: ({ search }) => (search ? `${search}*` : undefined),
    },
    {
      paramKey: 'sort',
      transform: ({ orderBy, order }) => {
        switch (orderBy) {
          case 'name':
            return `${orderBy}.sort,${order}`;
          case 'category.name':
          case 'subcategory.name':
          case 'severity':
            return `${orderBy}.keyword,${order}`;
          default:
            if (orderBy && order) {
              return `${orderBy},${order}`;
            }
            return null;
        }
      },
    },
  ],
});
// to be renamed
export const updateZScanPolicy = ServiceHandlerFactory({
  axios: {
    url: `${policyBase}/policies/:policyId`,
    method: 'PUT',
  },
});

export const searchIndicators = ServiceHandlerFactory({
  axios: {
    url: `${policyBase}/findings/query`,
  },
  isCancelable: true,
  paramSchema: [
    {
      path: 'search',
      transform: ({ search }) => (search ? `${search}*` : undefined),
    },
    {
      paramKey: 'sort',
      transform: ({ orderBy, order }) => {
        switch (orderBy) {
          case 'name':
            return `${orderBy}.sort,${order}`;
          case 'category.name':
          case 'subcategory.name':
          case 'severity':
            return `${orderBy}.keyword,${order}`;
          default:
            if (orderBy && order) {
              return `${orderBy},${order}`;
            }
            return null;
        }
      },
    },
  ],
});

// stats
export const fetchAppStats = ServiceHandlerFactory({
  axios: {
    url: 'api/zdev-app/v1/stats',
  },
  isCancelable: true,
  paramSchema: fetchAppStatsParamMapping,
});

export const cleanFindingsTable = (tableContent, policies = {}) => {
  const displayName = _.get(
    tableContent,
    'tableContent.metadata.zdevMetadata.appName',
    'N/A'
  );

  const metadata = {
    ...tableContent,
    displayName,
    zdevPolicyName: _.get(
      policies,
      `[${tableContent.zdevPolicyId}].name`,
      'n/a'
    ),
  };

  return {
    metadata,
    buildFindingsData: tableContent.buildFindings,
  };
};

export const getZDevDownloads = ServiceHandlerFactory({
  axios: {
    url: `${downloadBase}/`,
  },
});

export const getZDevDownloadByFilename = ServiceHandlerFactory({
  axios: {
    url: `${downloadBase}/:filename`,
    responseType: 'blob',
  },
});

// apps
export const getAllZDevApps = ServiceHandlerFactory({
  axios: {
    url: `${appsBase}/all`,
  },
  isCancelable: true,
});

export const getApps = ServiceHandlerFactory({
  axios: {
    url: appsBase,
  },
  isCancelable: true,
  paramSchema: fetchAppParamMapping,
});

export const getAppsById = ServiceHandlerFactory({
  axios: {
    url: `${appsBase}/:appId`,
  },
  isCancelable: true,
  paramSchema: fetchAppParamMapping,
});

export const getAvailableApps = ServiceHandlerFactory({
  axios: {
    url: `${appsBase}/available`,
  },
  isCancelable: false,
  paramSchema: [{ path: 'configId' }],
});

export const getAppById = ServiceHandlerFactory({
  axios: {
    url: `${appsBase}/:appId`,
  },
  isCancelable: true,
});

export const updateApp = ServiceHandlerFactory({
  axios: {
    url: `${appsBase}/:appId`,
    method: 'put',
  },
  isCancelable: true,
});

export const checkStoreUpdate = ServiceHandlerFactory({
  axios: {
    url: `${appsBase}/:appId/checkStoreUpdate`,
    method: 'post',
  },
  isCancelable: true,
});

export const deleteZScanApp = ServiceHandlerFactory({
  axios: {
    url: `${appsBase}/:appId`,
    method: 'delete',
  },
  isCancelable: true,
});
export const deleteZScanAssessment = ServiceHandlerFactory({
  axios: {
    url: `${assessmentBase}/:assessmentId`,
    method: 'delete',
  },
  isCancelable: true,
});
export const acceptFinding = ServiceHandlerFactory({
  axios: {
    url: `${assessmentBase}/:assessmentId/findings/:findingId/accept`,
    method: 'put',
  },
  isCancelable: true,
});

export const acceptLocation = ServiceHandlerFactory({
  axios: {
    url: `${assessmentBase}/:assessmentId/findings/:findingId/locations/:locationId/accept`,
    method: 'put',
  },
  isCancelable: true,
});

export const generateAppPDFReport = ServiceHandlerFactory({
  axios: {
    url: `${appsBase}/:appId/report`,
  },
  isCancelable: true,
});

export const getEmulatorScript = (id) => Api.get(`${scriptBase}/${id}/json`);
// validate coming soon url: `${scriptBase}/validate`,

export const getSBOM = ServiceHandlerFactory({
  axios: {
    url: `${sbomBase}/json/?assessmentId=:assessmentId&includeVulnerabilities=:includeVulnerabilities`,
    method: 'get',
  },
  isCancelable: true,
});

export const generateSBOMPDF = ({ assessmentId, includeVulnerabilities }) =>
  Api.get(
    `${sbomBase}/pdf?assessmentId=${assessmentId}&includeVulnerabilities=${includeVulnerabilities}`
  );

export const addSBOM = ServiceHandlerFactory({
  axios: {
    url: `${sbomBase}`,
    method: 'post',
  },
  isCancelable: true,
});

export const completeZScanUpload = ServiceHandlerFactory({
  axios: {
    url: '/api/zdev-app/v1/apps/:appId/upload',
    method: 'put',
  },
  isCancelable: true,
});
