import { createStyles } from '@material-ui/core';
const styles = ({ palette, config }: any) =>
  createStyles({
    iconButton: {
      backgroundColor: 'transparent',
      border: 'none',
      color: palette.text.primary,
      cursor: 'pointer',
    },
    copyIcon: {
      height: '22px',
      width: '22px',
    },
    paleGreen: {
      background: config.colors.paleGreen,
    },
    syncStatusCircle: {
      borderRadius: '50%',
      display: 'flex',
      height: 12,
      marginRight: 12,
      width: 12,
    },
    tomato: {
      background: config.colors.tomato,
    },
    connectorTypeTableCell: {
      display: 'flex',
      alignItems: 'center',
    },
    connectorTypeLabelWrapper: {
      paddingLeft: 5,
      maxWidth: '60%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    connectorTypeImageWrapper: {
      width: 25
    }
  });

export default styles;
