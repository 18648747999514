import { ISelectItem } from 'components/UI/input/Select';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const hasChanges = atom<boolean>({
  key: 'privacyPolicy-changes',
  default: false,
});

export const preselectedPolicy = atom<ISelectItem | undefined>({
  key: 'privacyPolicy-preselectedPolicy',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
