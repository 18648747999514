import { Fragment, useState, useMemo } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import UIDataElementExpansion from './uiDataElementExpansion/UIDataElementExpansion';
import useStyles from './useStyles';
import Button from 'components/UI/Button';
import { IZScanUploadFormValues } from '../models';
import EditableTable from 'components/UI/EditableTable';
import { ISelectItem } from 'components/UI/input/Select';
import Select from 'components/UI/input/Select';
import {
  Field as FormikField,
  Form,
  Formik,
} from 'formik';
import TextField from 'components/UI/input/TextField';
import Schema from './schema';
import { useTranslation } from 'react-i18next';
import InputError from 'components/UI/input/InputError';

interface IZScanFormProps {
  availableTeams: ISelectItem[];
  handleSubmit: (values: IZScanUploadFormValues) => void;
  handleCloseModal: () => void;
  rawJSONZScanTranscript?: any;//string;
  metadataFromRow: any;
  appName: string;
  bundleId: string;
  teamId?: string;
  distributionList: string | string[];
}

const ZScanForm: React.FC<IZScanFormProps> = ({
  availableTeams,
  appName,
  bundleId,
  teamId,
  distributionList,
  rawJSONZScanTranscript,
  handleSubmit,
  handleCloseModal,
  metadataFromRow = [],
}: IZScanFormProps) => {
  const initialMetadata = useMemo(() => {
    if (!metadataFromRow) {
      return [];
    }
    return metadataFromRow.map((item: any) => ({
      id: `${new Date().getTime() - 3}`,
      key: item.key,
      value: item.value,
      rowEditable: true,
    }));

  }, [metadataFromRow]);
  const selectedTeam = availableTeams
    ? availableTeams.find(({ value }) => value === teamId)
    : null;


  const [uiElementsExpanded, setUIElementsExpanded] = useState<boolean>(false);
  const [metadata, setMetadata] = useState<any>(initialMetadata);
  const classes = useStyles();
  const { t } = useTranslation();

  const validateMetadataKeys = (metadata: any) => {
    return new Set(metadata.map((item: any) => item.key)).size === metadata.length;
  };

  const onMetadataCreate = (rowData: any) => {
    const newMetadata = [
      ...metadata,
      { ...rowData, id: new Date().getTime().toString() },
    ];

    if (validateMetadataKeys(newMetadata)) {
      setMetadata(newMetadata);
    }
  };

  const onMetadataUpdate = (rowData: any) => {
    const { key, value, id } = rowData;
    console.log('key, value', key, value);

    const newMetadata = metadata.filter((item: any) => item.id !== id);
    newMetadata.push(rowData);

    if (validateMetadataKeys(newMetadata)) {
      setMetadata(newMetadata);
    }
  };

  const onMetadataDelete = (rowData: any) => {
    const { id } = rowData;

    const newMetadata = metadata.filter((item: any) => item.id !== id);

    if (validateMetadataKeys(newMetadata)) {
      setMetadata(newMetadata);
    }
  };

  return (
    <Fragment>
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            rawJSONData: rawJSONZScanTranscript,
            metadata: initialMetadata,
            team: selectedTeam,
            distributionList: distributionList?.length && typeof distributionList === 'object' ? distributionList.join(",").replace(/,/g, '\n') : '',
            bundleId,
            appName,
            teamId: teamId ?? ''
          }}
          onSubmit={handleSubmit}
          validateOnBlur
          validationSchema={Schema(t)}
        >
          {({ values, setFieldValue, errors, }) => {
            return (
              <Form>
                <div className={classes.expansionContainer}>
                  <FormikField
                    component={TextField}
                    label="App Name"
                    name="appName"
                    disabled

                  />
                  <FormikField
                    component={TextField}
                    label="Bundle ID"
                    name="bundleId"
                    disabled

                  />
                  <FormikField
                    component={Select}
                    disableClearable={true}
                    label="Team"
                    multiple={false}
                    name="team"
                    options={availableTeams ?? []}
                    setFieldValue={setFieldValue}
                    type="select"
                  />
                  {!!errors?.team && (
                    <InputError override="Team required" />
                  )}
                  <FormikField
                    component={TextField}
                    label="Notification Email(s)"
                    multiline
                    name="distributionList"
                    rows="6"
                    helperText={'Hit "Enter" to input multiple emails'}
                  />

                  <h2>App Custom Metadata</h2>
                  <EditableTable
                    columnHeaders={[
                      { id: 'key', label: 'Key' },
                      { id: 'value', label: 'Value' },
                    ]}
                    rowMapping={[
                      { path: 'key', inputType: 'text' },
                      { path: 'value', inputType: 'text' },
                    ]}
                    onCreate={onMetadataCreate}
                    onUpdate={onMetadataUpdate}
                    onDelete={onMetadataDelete}
                    tableData={metadata}
                    editVisible={true}
                    showNoDataAddButton={true}
                    noDataAddButtonText="+"
                    tableId="AppMetadata"
                  />
                  <UIDataElementExpansion
                    expanded={uiElementsExpanded}
                    handleAccordion={setUIElementsExpanded}
                    handleFormData={(revisedJSONZScanTranscript: string) => setFieldValue('rawJSONData', revisedJSONZScanTranscript)}
                    rawJSONZScanTranscript={values.rawJSONData}
                  />
                </div>
                <DialogActions>
                  <Button
                    onClick={() => {
                      handleCloseModal();
                    }}
                    color="secondary"
                    text="Cancel"
                  />
                  <Button
                    color="primary"
                    disabled={!values.team}
                    onClick={() => handleSubmit({ ...values, metadata })}
                    text="Ok"
                  />
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Fragment>
  );
};

export default ZScanForm;
