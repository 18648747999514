// import _ from 'lodash';
import { generateMultiSelectValue } from 'utils/componentUtils';

export const availableMessageTypes = [
  { value: 'SYSLOG', label: 'Syslog' },
  { value: 'CEF', label: 'CEF' }, // need this for WS1
];

export const availablePayloadTypes = [
  { value: 'JSON', label: 'Json' },
  { value: 'CEF', label: 'CEF' }, // need this for WS1
];

export const availableTransportTypes = [
  { value: 'TCP', label: 'TCP' },
  { value: 'UDP', label: 'UDP' },
  { value: 'TCP_TLS', label: 'TCP/TLS' },
];

export const availableSyslogSeverity = [
  { value: 'EMERGENCY', label: 'Emergency' },
  { value: 'ALERT', label: 'Alert' },
  { value: 'CRITICAL', label: 'Critical' },
  { value: 'ERROR', label: 'Error' },
  { value: 'WARNING', label: 'Warning' },
  { value: 'NOTICE', label: 'Notice' },
  { value: 'INFORMATIONAL', label: 'Informational' },
  { value: 'DEBUG', label: 'Debug' },
];

export const availableSyslogFacility = [
  { value: 'LOCAL0', label: 'local0' },
  { value: 'LOCAL1', label: 'local1' },
  { value: 'LOCAL2', label: 'local2' },
  { value: 'LOCAL3', label: 'local3' },
  { value: 'LOCAL4', label: 'local4' },
  { value: 'LOCAL5', label: 'local5' },
  { value: 'LOCAL6', label: 'local6' },
  { value: 'LOCAL7', label: 'local7' },
];

export const availableProtocolTypes = [{ value: 'HTTPS', label: 'HTTPS' }];

export const availableAuthorizationTypes = [
  { value: 'BASIC', label: 'Basic' },
  { value: 'API_KEY', label: 'API Key' },
];

export const availableApiKeyFormat = [
  { value: 'SPLUNK', label: 'Splunk' },
  { value: 'CUSTOM', label: 'Custom' },
];

export const destinationTypes = [
  {
    value: 'MICROSOFT_DEFENDER_ATP',
    label: 'Microsoft Defender ATP',
    configKey: 'microsoftDefenderConfiguration',
  },
  {
    value: 'SIEM',
    label: 'General SIEM',
    configKey: 'siemConfiguration',
  },
  {
    value: 'KINESIS',
    label: 'Amazon Kinesis',
    configKey: 'awsConfiguration',
  },
  {
    value: 'MICROSOFT_SENTINEL',
    label: 'Microsoft Azure Sentinel',
    configKey: 'sentinelConfiguration',
  },
  {
    value: 'ATT_ALIEN_VAULT',
    label: 'Alienvault USM Anywhere',
    configKey: 'siemConfiguration', // this is same as SIEM on the micro-service side for alienvault
  },
  {
    value: 'WORKSPACE_ONE',
    label: 'VMware Workspace ONE Intelligence',
    configKey: 'siemConfiguration', // this is same as SIEM on the micro-service side for alienvault
  },
  {
    value: 'REST_ENDPOINT',
    label: 'REST Endpoint',
    configKey: 'restEndpointConfiguration', // this is same as SIEM on the micro-service side for alienvault
  },
  {
    value: 'OKTA',
    label: 'Okta',
    configKey: 'oktaConfiguration',
  },
];

export const dataTypes = [
  {
    value: 'THREATS',
    label: 'Threats',
  },
  {
    value: 'AUDIT',
    label: 'Console User Activity',
  },
];

export const azureCloudType = [
  { value: 'COMMERCIAL', label: 'Commercial' },
  { value: 'GOV', label: 'Gov' },
];

export function configureAzureCloudValue(acValue) {
  return generateMultiSelectValue(acValue, azureCloudType);
}
