import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import withRouter from 'components/hocs/withRouter';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

// mui
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Grid } from '@material-ui/core';

// components
import { GlobalMenuOption } from 'components/Selects/MultiSelect/GlobalMenuOption';
import { GlobalMultiSelectButton } from 'components/Selects/MultiSelect/GlobalMultiSelectButton';
import ZButton from 'UI/Buttons/ZButton';
import { FormikTextField } from 'components/inputs/FormikTextField';
import { FormikMultiSelect } from 'components/inputs/FormikMultiSelect';

// utils
import {
  toggleModalDiffered,
  toggleModalDirect,
  openSnackBar,
} from 'utils/storeUtils';

// reducers
import { getAvailableTeamsAsSelectList } from 'reducers/TeamReducers';
// import { getAvailableRolesAsSelectList } from 'reducers/RoleReducers';
import {
  submitUserInvitation,
  editSelectedUser,
  resendUserInvitationEmail,
} from 'api/UserTableService';
import {
  csvInjectionRegex,
  csvInjectionErrorMessage,
  looksGenerallyLikeAnEmailRegex,
  looksGenerallyLikeAnEmailRegexErrorMessage,
} from 'utils/componentUtils';
import { fetchAllRoles, getAvailableRolesAsSelectList } from 'api/RolesService';

class UserCreateEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: !_.isEmpty(props.data.rowData),
      role: [],
      teams: [],
      errors: {},
      availableRoles: [],
    };

    this.handleResendValidationEmail =
      this.handleResendValidationEmail.bind(this);
    // this.handleSelect = this.handleSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillUnmount() {
    this.props.updateUrl({ userId: undefined });
  }

  async componentDidMount() {
    const { props, state } = this;
    let availableRoles = [];
    const { data } = await fetchAllRoles();
    if (data) {
      availableRoles = getAvailableRolesAsSelectList(data);
      this.setState({ availableRoles });
    }
    if (state.editMode) {
      const role = _.get(props.data, 'rowData.role');

      this.setState({
        ..._.pick(props.data.rowData, ['role', 'teams']),
        role: !_.isEmpty(role)
          ? [
              {
                label: role.name,
                value: role.value,
                accountBounded: role.accountBounded,
              },
            ]
          : [],
        teams: props.data.rowData.teams.map(({ name, id }) => ({
          label: name,
          value: id,
        })),
      });
    }
  }

  render() {
    const { props, state } = this;

    return (
      <DialogContent>
        <Formik
          initialValues={{
            firstName: _.get(props, 'data.rowData.firstName', ''),
            lastName: _.get(props, 'data.rowData.lastName', ''),
            email: _.get(props, 'data.rowData.email', ''),
            notificationEmail: _.get(
              props,
              'data.rowData.notificationEmail',
              ''
            ),
            phone: _.get(props, 'data.rowData.phone', ''),
            role: state.role,
            teams: state.teams,
          }}
          validationSchema={UserSchema}
          // validateOnBlur
          enableReinitialize
          onSubmit={this.handleSubmit}
        >
          {({ dirty, isSubmitting, values }) => {
            return (
              <Form>
                <Field
                  name="firstName"
                  label="First Name"
                  component={FormikTextField}
                  autoComplete="off"
                />

                <Field
                  name="lastName"
                  label="Last Name"
                  component={FormikTextField}
                  autoComplete="off"
                />
                <Field
                  name="email"
                  label="Email"
                  component={FormikTextField}
                  autoComplete="off"
                />
                <Field
                  name="notificationEmail"
                  label="Notification Email"
                  component={FormikTextField}
                  autoComplete="off"
                />

                <Grid container spacing={24}>
                  <Grid item xs={6}>
                    <Field
                      name="role"
                      label="Role"
                      component={FormikMultiSelect}
                      components={{
                        Option: GlobalMenuOption,
                        DropDownButton: GlobalMultiSelectButton,
                      }}
                      isMulti={false}
                      options={state.availableRoles}
                      values={values.role}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="teams"
                      label="Teams"
                      component={FormikMultiSelect}
                      isShowing={!_.get(values, 'role[0].accountBounded', true)}
                      options={props.availableTeams}
                    />
                  </Grid>
                </Grid>
                <DialogActions>
                  <ZButton
                    styleName="submit"
                    action={toggleModalDiffered('UserCreateEdit', false)}
                    color="secondary"
                    buttonText="Cancel"
                  />
                  <ZButton
                    styleName="modalCancel"
                    buttonText="Resend Invite"
                    color="primary"
                    action={() => this.handleResendValidationEmail(values)}
                    isShowing={!_.get(props, 'data.rowData.validated', true)}
                  />
                  <ZButton
                    // isDisabled={!isRequiredFieldsFilled}
                    styleName="modalSave"
                    buttonType="Submit"
                    buttonText="Save"
                    color="primary"
                  />
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    );
  }

  handleResendValidationEmail(values) {
    const { props } = this;
    const fullName = `${values.firstName} ${values.lastName}`;
    const payload = {
      ..._.pick(values, [
        'firstName',
        'lastName',
        'email',
        'notificationEmail',
        'teams',
      ]),
      roleId: _.get(values, 'role[0].value'),
      teams: _.get(values, 'teams', []).map((team) => team.value),
    };
    editSelectedUser(props.data.rowData.id, payload)
      .then(() => {
        toggleModalDirect('UserCreateEdit', false);
        props.data.fetchUsersTablePage();
      })
      .catch((error) => {
        toggleModalDirect('UserCreateEdit', false);
        openSnackBar(
          `Failed to edit user: ${_.get(
            error,
            'response.data.messasge',
            'Bad Request'
          )}`
        );
      });
    resendUserInvitationEmail(props.data.rowData.email)
      .then(() => {
        openSnackBar(`Resent invite to ${fullName}`);
        props.data.fetchUsersTablePage();
      })
      .catch((error) => {
        toggleModalDirect('UserCreateEdit', false);
        openSnackBar(
          `Failed to resend invite for user:${_.get(
            error,
            'response.data.messasge',
            'Bad Request'
          )}`
        );
      });
  }

  handleSubmit(values) {
    const { props, state } = this;
    const fullName = `${values.firstName} ${values.lastName}`;
    const payload = {
      ..._.pick(values, [
        'firstName',
        'lastName',
        'email',
        'notificationEmail',
        'teams',
      ]),
      roleId: _.get(values, 'role[0].value'),
      teams: _.get(values, 'teams', []).map((team) => team.value),
    };
    if (state.editMode) {
      editSelectedUser(props.data.rowData.id, payload)
        .then(() => {
          toggleModalDirect('UserCreateEdit', false);
          openSnackBar(`User ${fullName} was updated`);
          props.data.fetchUsersTablePage();
        })
        .catch((error) => {
          openSnackBar(`Failed to edit user: ${error.response.data}`);
        });
    } else {
      submitUserInvitation(payload)
        .then(() => {
          toggleModalDirect('UserCreateEdit', false);
          openSnackBar(`${fullName} was created`);
          props.data.fetchUsersTablePage();
        })
        .catch((error) => {
          openSnackBar(`Failed to create user: ${error.response.data}`);
        });
    }
  }

  handleError({ data, status }) {
    const { state } = this;

    if (data === 'No team(s) provided but are required!') {
      this.setState({
        errors: {
          ...state.errors,
          team: 'A team must be selected.',
        },
      });
      return;
    }

    if (status === 409 || status === 403) {
      this.setState({
        errors: { ...state.errors, email: data },
      });
    } else if (status === 400) {
      this.setState({
        errors: { ...state.errors, role: 'A role must be selected' },
      });
    }
  }
}

const UserSchema = Yup.object().shape({
  // role: Yup.array()
  //   .min(1)
  //   .required('Required'),
  teams: Yup.array().when('role', {
    is: (role) => role[0]?.accountBounded === false,
    then: Yup.array().min(1, 'A team must be selected with a team role'),
  }),
  firstName: Yup.string()
    .min(1)
    .matches(csvInjectionRegex, csvInjectionErrorMessage)
    .required('Required'),
  lastName: Yup.string()
    .min(1)
    .matches(csvInjectionRegex, csvInjectionErrorMessage)
    .required('Required'),
  email: Yup.string()
    .matches(
      looksGenerallyLikeAnEmailRegex,
      looksGenerallyLikeAnEmailRegexErrorMessage
    )
    .matches(csvInjectionRegex, csvInjectionErrorMessage)
    .required('Required'),
  notificationEmail: Yup.string()
    .matches(
      looksGenerallyLikeAnEmailRegex,
      looksGenerallyLikeAnEmailRegexErrorMessage
    )
    .matches(csvInjectionRegex, csvInjectionErrorMessage)
    .required('Required'),
});

export const createModalConfig = {
  title: 'Create New User',
  fullWidth: true,
};

export const editModalConfig = {
  title: 'Edit User',
  fullWidth: true,
};

const mapStateToProps = (state) => {
  return {
    availableTeams: getAvailableTeamsAsSelectList(state),
    // availableRoles: getAvailableRolesAsSelectList(state),
  };
};

export default withRouter(connect(mapStateToProps, null)(UserCreateEdit));
