import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    item: {
      backgroundColor: "blue", // Set by consumer
      borderRadius: "6px",
      color: palette.common.white,
      cursor: 'pointer',
      maxWidth: "100%",
      padding: "10px 15px",
      transition: 'transform 100ms ease',
      whiteSpace: "nowrap",

      '&:hover, &:focus-visible': {
        transform: 'scale(1.125)',
      },
    }
  })
);

export default useStyles;
