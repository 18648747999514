import React, { useMemo, useCallback, ChangeEvent } from 'react';

import MUISelect from '@material-ui/core/Select';
import MUIMenuItem from '@material-ui/core/MenuItem';
import MUIFormControl from '@material-ui/core/FormControl';
import Button from '../../UI/Button';
import useStyles from './useStyles';
import { RenderSelectGroup } from './SelectGroup';
import { ITableActionGroup, TableAction, tableActions } from './TableAction';
import { useTranslation } from 'react-i18next';

interface ITableActionProps {
  setSelectedTableAction: React.Dispatch<
    React.SetStateAction<TableAction | undefined>
  >;
  selectedTableAction: TableAction | undefined;
  onGoClick: () => void;
}

const TableActions: React.FC<ITableActionProps> = ({
  setSelectedTableAction,
  selectedTableAction,
  onGoClick,
}) => {
  const classes = useStyles();
  const { ready, t } = useTranslation();

  const selectGroup = useCallback(
    (group: ITableActionGroup) => RenderSelectGroup(group),
    []
  );

  const memoizedTableActions = useMemo(() => tableActions, []);

  const onTableActionChange = useCallback(
    (
      event: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ): void => {
      const { value } = event.target;
      setSelectedTableAction(value as TableAction);
    },
    [setSelectedTableAction]
  );

  if (!ready) {
    return null;
  }

  return (
    <div className={classes.tableActionsStyle}>
      <MUIFormControl className={classes.selectFormControl}>
        <MUISelect
          displayEmpty
          onChange={onTableActionChange}
          value={selectedTableAction || ''}
        >
          <MUIMenuItem value="" disabled>
            {t('GLOBAL.TABLE_ACTIONS')}
          </MUIMenuItem>
          {/* Render the memoized selectGroup for each group in memoizedTableActions */}
          {memoizedTableActions.map((group) => selectGroup(group))}
        </MUISelect>
      </MUIFormControl>

      <Button
        text={t('GLOBAL.GO')}
        color="secondary"
        style={{ height: 'min-content' }}
        onClick={onGoClick}
      />
    </div>
  );
};
export default TableActions;
