import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    chart: {
      flex: 1,
    },
    multiBarChart: {
      alignItems: 'center',
      display: 'flex',
      flex: '1 1',
      justifyContent: 'center',

      '& *': {
        flex: '1 1',
      }
    },
    bar: {
      '&:hover': {
        cursor: 'pointer'
      }
    }
  })
);

export default useStyles;
