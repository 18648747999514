import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: '25px',
      paddingBottom: '25px',
    },
    modal: {
      '& .MuiDialog-container > div': {
        maxWidth: '800px',
        minWidth: '800px',
        width: '800px',
      },
    },
    note: {
      marginTop: '25px',
      fontSize: '15px',
    },
    table: {
      '& td:nth-child(1)': {
        width: '190px',
        minWidth: '190px',
        maxWidth: '190px',
      },
      '& td:nth-child(3)': {
        width: '120px',
        minWidth: '120px',
        maxWidth: '120px',
      },
    },
  })
);

export default useStyles;
