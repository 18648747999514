import { fetchDataExportTablePage } from 'api/DataExportService';
import { memoizeTableQuery } from 'components/RDGTables/utils';
import _ from 'lodash';

export default memoizeTableQuery(async query => {
  // Fetch Data
  const rawDataExport = await fetchDataExportTablePage(query);

  return {
    data: _.get(rawDataExport, 'data.content', []),
    count: _.get(rawDataExport, 'data.totalElements', 0),
  };
});
