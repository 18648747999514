import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      fontSize: 15,
      paddingRight: 10,
    },
    radio: {
      display: 'flex',
      flexDirection: 'row',
    },
  })
);

export default useStyles;
