import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    potentialFraudIndicators: {
      flex: 1,
      '& > div': {
        textAlign: "center",

        '& > h2': {
          marginBottom: 30,
        },
      }
    },
    sections: {
      display: 'flex',
      flexDirection: 'row',
    },
    section: {
      padding: '0 20px',
      textAlign: 'center',
      flex: 1,

      '& > h2': {
        marginBottom: 50,
      },
    },
  })
);

export default useStyles;
