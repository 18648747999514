import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    cardContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    cardRoot: {
      margin: '10px 20px 10px 0px',
      padding: 10,
      backgroundColor: palette.background.zShieldCard,
      width: 385,
      height: 121,
      '&:hover': {
        cursor: 'pointer',
        boxShadow: '2px 2px 2px 2px black',
      },
      "&.activeCard": {
        backgroundColor: palette.background.zShieldCardActive,
      },
    },
    categorySectionContainer: {
      color: palette.common.primary,// "#c8f3fb"  is the color on mockup
      paddingTop: 10
    },
    categoryDivider: {
      color: palette.common.primary,
      background: palette.common.primary,
      width: '100%',
      marginTop: 5
    },
    categoryWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
    },
    appContentContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      height: 80,
    },
    appLogo: {
      alignSelf: 'flex-start',
      width: '50px',
      height: '45px',
      display: 'flex',
      justifyContent: 'center',
      marginTop: 3,
      overflow: 'hidden'
    },
    nameContainer: {
      width: '70%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      flexDirection: 'column',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    nameWrapper: {
      fontSize: 30,
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    protectionStatusWrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      zIndex: 9999,
      fontSize: 14,
    },
    protectionStatusInfoIcon: {
      marginLeft: 3,
      padding: 3

    },
    empty: {
      border: `1px dashed ${palette.text.primary}`,
      height: 'calc(100vh - 290px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 36,
      marginTop: '20px',
      width: '100%'
    },
    protectionStatus: {
      marginLeft: 5
    },
    spinnerStatus: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh'
    },
    statusWrapper: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    updateButton: {
      color: palette.text.secondary,
      width: 100,
      height: 25,
      fontSize: 10,
      fontWeight: 'bold',
      padding: 0,
      background: palette.background.paper,
      '&:hover': {
        background: palette.background.paper,
        color: palette.text.secondary,
      },
    },
    versionText: {
      paddingTop: 10,
      fontSize: 12
    }
  })
);

export default useStyles;
