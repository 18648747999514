import CustomPolicyOption from 'components/main/policies/common/CustomPolicyOption';
import Button from 'components/UI/Button';
import Checkbox from 'components/UI/input/Checkbox';
import Select, { ISelectItem } from 'components/UI/input/Select';
import TextField from 'components/UI/input/TextField';
import {
  Field as FormikField,
  Form as FormikForm,
  Formik,
  FormikHelpers,
} from 'formik';
import { useMemo, useState } from 'react';
import { toggleModalDirect } from 'utils/storeUtils';
import { IData } from '../models';
import { initialValues } from './initialValues';
import { IFormData, IPolicyGroupOption } from './models';
import Schema from './schema';
import useStyles from './useStyles';
import { useTranslation } from 'react-i18next';

interface IFormProps {
  allPolicyGroups: IPolicyGroupOption[];
  data: IData;
  handleSubmit: (
    formData: IFormData,
    formikHelpers: FormikHelpers<IFormData>
  ) => void;
}

const Form: React.FC<IFormProps> = ({
  allPolicyGroups,
  data,
  handleSubmit,
}) => {
  const classes = useStyles();
  const { t, ready } = useTranslation();

  const [teamIdToFilterGroups, setTeamIdToFilterGroups] = useState<
    string | null
  >(data?.rowData?.teamId || data?.selectedTeamId || '');

  const policyGroups = useMemo(
    () =>
      allPolicyGroups
        .filter(
          ({ teamId }) => teamId === '' || teamId === teamIdToFilterGroups
        )
        .map((group) => ({ ...group, accountBounded: !group?.teamId })) ?? [],
    [allPolicyGroups, teamIdToFilterGroups]
  );

  const resolvedInitialValues = initialValues(
    data.rowData,
    data?.selectedTeamId,
    allPolicyGroups
  );

  const showTeamSelector = !data?.selectedTeamId;

  if (!ready) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={resolvedInitialValues}
      onSubmit={handleSubmit}
      validateOnBlur
      validationSchema={Schema(t)}
    >
      {({ isSubmitting, setFieldValue }) => {
        return (
          <FormikForm>
            <FormikField type="hidden" name="editMode" />
            <FormikField type="hidden" name="id" />
            <FormikField type="hidden" name="selectedTeamId" />
            <FormikField
              component={TextField}
              disabled={isSubmitting}
              label={t('GLOBAL.EMAIL_ADDRESS')}
              name="email"
            />
            <div className={classes.grid}>
              {/* Below fields are wrapped in divs so error components don't throw off grid. */}
              <div>
                <FormikField
                  component={TextField}
                  disabled={isSubmitting}
                  label={t('USER_SETTINGS.PERSONAL_INFO.FIRST_NAME')}
                  name="firstName"
                />
              </div>
              <div>
                <FormikField
                  component={TextField}
                  disabled={isSubmitting}
                  label={t('USER_SETTINGS.PERSONAL_INFO.LAST_NAME')}
                  name="lastName"
                />
              </div>
              <div>
                <FormikField
                  component={TextField}
                  disabled={isSubmitting}
                  label={t('GLOBAL.PHONE_NUMBER')}
                  name="phoneNumber"
                />
              </div>
              <div>
                <FormikField
                  component={TextField}
                  disabled={isSubmitting}
                  inputProps={{
                    min: 0,
                  }}
                  label={t('MTD.ACTIVATIONS.ACTIVATION_LIMIT')}
                  name="activationLimit"
                  type="number"
                />
              </div>
            </div>
            {showTeamSelector && (
              <FormikField
                component={Select}
                disableClearable
                disabled={isSubmitting}
                label={t('GLOBAL.TEAM')}
                multiple={false}
                name="team"
                options={data?.availableTeams ?? []}
                setFieldValue={(field: string, value: ISelectItem) => {
                  setTeamIdToFilterGroups(value?.value as string);
                  setFieldValue(field, value);
                  setFieldValue('group', null);
                }}
                type="select"
              />
            )}
            <FormikField
              component={Select}
              customOption={CustomPolicyOption}
              disableClearable
              disabled={isSubmitting}
              label={t('GLOBAL.POLICY_GROUP')}
              multiple={false}
              name="group"
              options={policyGroups ?? []}
              setFieldValue={setFieldValue}
              type="select"
            />
            <div className={classes.checkbox}>
              <FormikField
                color="primary"
                component={Checkbox}
                label={t('MTD.ACTIVATIONS.SEND_INVITES_THROUGH_EMAIL')}
                name="sendEmailInvite"
                type="checkbox"
              />
            </div>
            <div className={classes.buttonPanel}>
              <Button
                color="secondary"
                disabled={isSubmitting}
                onClick={() =>
                  toggleModalDirect('UserActivationsCreateEdit', false)
                }
                text={t('GLOBAL.CANCEL')}
              />
              <Button
                color="primary"
                disabled={isSubmitting}
                isLoading={isSubmitting}
                text={t('GLOBAL.SAVE')}
                type="submit"
              />
            </div>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default Form;
