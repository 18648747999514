import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    accordion: {
      boxShadow: 'none',
      margin: '10px -10px 0 -10px !important',
    },
    accordianHeader: {
      backgroundColor: palette?.tables?.header?.background,
      border: `1px solid ${palette?.tables?.border}`,
      color: palette.white,
      minHeight: 'none',
      '& .MuiAccordionSummary-content': {
        fontSize: 14,
      },
      '& svg': {
        width: 14,
      },
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    details: {
      display: 'flex',
      fontSize: 12,
      padding: 15,
    },
    elementDropdownWrapper: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    permissions: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: 12,
      padding: 15,
      '& div': {
        marginBottom: 5,
      }
    },
    heading: {
      color: 'white',
      paddingRight: 15,
    },
    risk: {
      '& span': {
        marginRight: 10,
      },
      alignItems: 'center',
      display: 'flex',
      textTransform: 'capitalize',
    },
  })
);


export default useStyles;