import { getZScanAssessments } from 'api/zDevService';
import { trimAndAddAsterisk } from 'utility/QueryUtility';
import { ILocationQuery } from 'components/UI/Table/models';

export const fetchTableData = async (query: ILocationQuery) => {
  let result; //: IZScanAssessmentResponseData | undefined = undefined;

  try {
    result = await getZScanAssessments({
      ...query,
    });
  } catch (e) {
    console.error(e);
  }
  return result;
};

export const zScanAssessmentParams = [
  {
    path: 'appId.keyword',
  },

  {
    path: 'page',
  },
  {
    path: 'size',
  },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }: { order: string; orderBy: string }) => {
      switch (orderBy) {
        case 'updatedAt':
        case 'scanDate':
        case 'buildSecurityFindingsCount':
        case 'buildPrivacyFindingsCount':
        case 'bestPracticesCount':
        case 'criticalFindingsCount':
        case 'securityScore':
        case 'privacyScore':
        case 'buildFindingsCount':
        case 'source':
        case 'categoryName':
        case 'name':
        case 'severity':
        case 'severityOrdinal':
        case 'subcategoryName':
        case 'locationCount':
          return `${orderBy},${order}`;
        default:
          if (orderBy && order) {
            return `${orderBy}.keyword,${order}`;
          }
          return null;
      }
    },
  },

  {
    path: 'search',
    transform: ({ search = '' }) => {
      if (!search) {
        return null;
      }

      return trimAndAddAsterisk(search);
    },
  },
];
