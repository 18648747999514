import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { trimAndAddAsterisk } from 'utility/QueryUtility';
// import { toggleModalDiffered } from 'utils/storeUtils';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AppleIcon from 'UI/Icons/AppleIcon';
import AndroidIcon from '@material-ui/icons/Android';
import InputIcon from '@material-ui/icons/Input';
import Tooltip from '@material-ui/core/Tooltip';
import Moment from 'moment';
import { toggleModalDiffered } from '../utils/storeUtils';

export const fetchAppParamMapping = [
  {
    path: 'page',
  },
  {
    path: 'size',
  },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      switch (orderBy) {
        case 'name':
        case 'bundleId':
        case 'platform':
        case 'appSource':
        case 'policyName':
        case 'findingCount':
        case 'hasEmulatorScript':
        case 'zdevPolicyId.keyword':
          return `${orderBy}.keyword,${order}`;
        case 'created':
        case 'modified':
          return `${orderBy},${order}`;
        default:
          if (orderBy && order) {
            return `${orderBy},${order}`;
          }
          return null;
      }
    },
  },
  {
    path: 'search',
    transform: ({ search = '' }) => {
      if (!search) {
        return null;
      }

      return trimAndAddAsterisk(search);
    },
  },
  {
    path: 'name',
    paramKey: 'name.keyword',
    transform: ({ name }) => name,
  },
  {
    path: 'bundleId',
    paramKey: 'bundleId.keyword',
    transform: ({ bundleId }) => bundleId,
  },
  {
    path: 'platform',
    paramKey: 'platform.keyword',
    transform: ({ platform }) => platform,
  },
  {
    path: 'appSource',
    paramKey: 'appSource.keyword',
    transform: ({ appSource }) => appSource,
  },
  {
    path: 'policyName',
    paramKey: 'zdevPolicyId.keyword',
    transform: ({ policyName }) => policyName,
  },
  {
    path: 'query',
  },
  {
    path: 'teamId',
  },
];

export const buildsAppsTableHeaders = [
  {
    id: 'name',
    label: 'App Name',
    show: true,
    sort: true,
  },
  {
    id: 'bundleId',
    label: 'Bundle ID',
    show: true,
    sort: true,
  },
  {
    id: 'platform',
    label: 'Platform',
    show: true,
    sort: false,
  },
  {
    id: 'appSource',
    label: 'Source',
    show: true,
    sort: false,
  },
  {
    id: 'policyName',
    label: 'zScan Policy',
    show: true,
    sort: false,
  },
  {
    id: 'hasEmulatorScript',
    label: 'Scan Arguments',
    show: true,
    sort: false,
  },
  {
    id: 'created',
    label: 'Date Created',
    show: true,
    sort: true,
  },
  {
    id: 'modified',
    label: 'Date Modified',
    show: true,
    sort: true,
  },
  {
    id: 'actions',
    label: 'Actions',
    show: true,
    sort: false,
  },
];

export const buildsAppsColumnMapping = [
  {
    path: 'name',
    columnContent: (name) => (
      <Tooltip title={name}>
        <div className="table-cell__ellipsis">{name}</div>
      </Tooltip>
    ),
  },
  {
    path: 'bundleId',
    columnContent: (bundleId) => (
      <div className="table-cell__ellipsis">{bundleId}</div>
    ),
  },
  {
    path: 'platform',
    columnContent: (platform) => {
      if (platform === 'ios') {
        return (
          <span style={{ marginRight: '5px' }} className="table__os-icon">
            <AppleIcon />
          </span>
        );
      }
      return <AndroidIcon style={{ marginRight: '10px' }} />;
    },
  },
  {
    path: 'appSource',
    columnContent: (appSource) => (!!appSource ? appSource : 'Unknown'),
  },
  {
    path: 'policyName',
  },
  {
    path: 'hasEmulatorScript',
    columnContent: (hasEmulatorScript) =>
      !!hasEmulatorScript ? 'Enabled' : null,
  },
  {
    path: 'created',
    columnContent: (timestamp) => Moment(timestamp).fromNow(),
    csvContent: (timestamp) => Moment(timestamp).format(),
  },
  {
    path: 'modified',
    columnContent: (timestamp) => Moment(timestamp).fromNow(),
    csvContent: (timestamp) => Moment(timestamp).format(),
  },
  {
    path: 'actions',
    columnContent: (id, { rowData }) => {
      return (
        <Fragment>
          <EditIcon
            className="hover--link"
            onClick={toggleModalDiffered(
              'ZDevAppsUpdate',
              {
                rowData,
              },
              {
                title: 'Edit App',
              }
            )}
          />
          <DeleteIcon
            className="cursor--pointer"
            onClick={toggleModalDiffered(
              'ZscanAppsDeleteConfirm',
              {
                rowData,
              },
              { title: 'Delete App' }
            )}
          />
        </Fragment>
      );
    },
  },
  {
    path: '',
    columnContent: (id, { rowData }) => (
      <Tooltip title="App Details" className="hover--link">
        <Link
          style={{
            display: 'flex',
            alignItems: 'center',
            textAlign: 'right',
          }}
          to={`/console/zscan/apps/${rowData.id}/findings`}
        >
          <InputIcon />
        </Link>
      </Tooltip>
    ),
  },
];
