import Button from 'components/UI/Button';
import GenericCard from 'components/UI/GenericCard';
import Uploader from 'components/UI/Uploader';
import React from 'react';
import useStyles from './useStyles';

interface IImageCardProps {
  btnRemoveText?: string;
  currentImage: React.ReactNode;
  disabled?: boolean;
  onImageUpload: (data: File) => void;
  onRemoveImage: () => void;
  removeDisabled?: boolean;
  title: React.ReactNode;
}

const ImageCard: React.FC<IImageCardProps> = ({
  btnRemoveText,
  currentImage,
  disabled,
  onImageUpload,
  onRemoveImage,
  removeDisabled,
  title,
}) => {
  const classes = useStyles();

  return (
    <GenericCard className={classes.card}>
      <h2>{title}</h2>
      <div className={classes.cardContent}>
        <Uploader
          classList={classes}
          disabled={disabled}
          isShowing
          accept=".png, .jpeg, .jpg"
          getFileData={onImageUpload}
          text={
            <>
              Drag and drop the file here or click button.
              <br />
              File must be in PNG, JPG, JPEG format, maximum 500k size.
              {disabled && (
                <>
                  <br />
                  (The Customer Success team must set the Hostname
                  <br /> field on the account before you can upload this file.)
                </>
              )}
            </>
          }
        />
        <div className={classes.imgWrapper}>{currentImage}</div>
        <Button
          className={classes.btnRemove}
          disabled={disabled || removeDisabled}
          variant="text"
          text={btnRemoveText ?? 'Click To Remove Logo'}
          onClick={onRemoveImage}
        />
      </div>
    </GenericCard>
  );
};

export default ImageCard;
