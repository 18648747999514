import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    devicesSummary: {
      height: 108,
      background: palette.background.default,
      display: 'flex',
      justifyContent: 'space-around',
    },
  })
);

export default useStyles;
