import MUIAddIcon from '@material-ui/icons/Add';
import MUIDeleteIcon from '@material-ui/icons/Delete';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GenericTable from '../GenericTable';
import {
  IGenericColumnHeader,
  IGenericRowMapping,
  TRowData,
} from '../GenericTable/models';
import { TClassList } from '../Table/models';
import { IEditableRowMapping, TEditableRowData } from './models';
import useStyles from './useStyles';
import {
  ACTIONS_HEADER,
  generateColumnContentFn,
  generateEmptyRow,
  getActionsMapping,
} from './utils';

interface IEditableTableProps {
  classList?: TClassList;
  columnHeaders: IGenericColumnHeader[];
  disabledRows?: string[];
  editVisible?: boolean;
  noDataAddButtonColor?: 'inherit' | 'primary' | 'secondary' | 'default'; // Only applicable if not passing in noDataTableContent
  noDataAddButtonText?: string; // Only applicable if not passing in noDataTableContent
  noDataTableContent?:
    | JSX.Element
    | ((
        addItem: (e: React.ChangeEvent<HTMLInputElement | unknown>) => void
      ) => JSX.Element);
  onCreate?: (rowData: Partial<TEditableRowData>) => void; // Partial because id is excluded
  onDelete?: (rowData: TEditableRowData) => void;
  onUpdate?: (rowData: TEditableRowData) => void;
  rowMapping: IEditableRowMapping[];
  showNoDataAddButton: boolean;
  tableData: TRowData[];
  tableId: string;
  onCancelEdit: () => void;
}

const EditableTable: React.FC<IEditableTableProps> = ({
  columnHeaders: headers,
  disabledRows,
  editVisible = true,
  noDataTableContent,
  onCreate,
  onDelete,
  onUpdate,
  rowMapping: initialRowMappings,
  showNoDataAddButton = false,
  tableData: initialTableData,
  tableId,
  onCancelEdit,
  ...rest
}) => {
  const [editableRow, setEditableRow] = useState<TEditableRowData | null>(null);
  const [addingNewRow, setAddingNewRow] = useState<boolean>(false);

  const classes = useStyles();
  const { ready, t } = useTranslation();

  const rowMappings: IGenericRowMapping[] = useMemo(() => {
    return headers.map((header) => {
      const rowMapping = initialRowMappings.find(
        ({ path }) => path === header.id
      );
      const columnContent = generateColumnContentFn({
        headerId: header.id,
        rowMapping,
        tableId,
      });

      return {
        path: header.id,
        columnContent,
      };
    });
  }, [headers, initialRowMappings, tableId]);

  // Inject disabledRows and editableRow info into each relevant tableData row
  const tableData = useMemo(() => {
    return initialTableData.map((rowData) => {
      // Inject editableRow into relevant tableData row
      const newRowData =
        !!editableRow && !addingNewRow && rowData?.id === editableRow?.id
          ? editableRow
          : rowData;

      return {
        ...newRowData,
        rowDisabled:
          rowData?.rowDisabled ??
          (!!disabledRows?.[0] && disabledRows.includes(rowData.id)),
        rowEditable: rowData?.id === editableRow?.id, // This field injected here to trigger edit mode
      };
    });
  }, [initialTableData, editableRow, addingNewRow, disabledRows]);

  const emptyEditableRow = useMemo(() => generateEmptyRow(headers), [headers]);

  const handleAdd = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | unknown>) => {
      e.preventDefault();
      setEditableRow(emptyEditableRow);
      setAddingNewRow(true);
    },
    [setEditableRow, setAddingNewRow, emptyEditableRow]
  );

  const handleCancel = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | unknown>) => {
      e.preventDefault();
      setEditableRow(null);
      setAddingNewRow(false);
      onCancelEdit ? onCancelEdit() : '';
    },
    [onCancelEdit]
  );

  const handleDelete = useCallback(
    async (
      e: React.ChangeEvent<HTMLInputElement | unknown>,
      rowData: TEditableRowData
    ) => {
      e.preventDefault();
      if (onDelete) {
        onDelete(rowData);
      }
    },
    [onDelete]
  );

  const handleEdit = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement | unknown>,
      rowData: TEditableRowData
    ) => {
      e.preventDefault();
      setEditableRow(rowData);
      setAddingNewRow(false);
    },
    []
  );

  const handleSave = useCallback(
    async (
      e: React.ChangeEvent<HTMLInputElement | unknown>,
      rowData: TEditableRowData
    ) => {
      e.preventDefault();
      if (addingNewRow && onCreate) {
        // id is needed.  please do not remove this
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
        const { id, ...rest } = rowData;
        onCreate(rest);
      }

      if (!addingNewRow && onUpdate) {
        onUpdate(rowData);
      }

      setEditableRow(null);
      setAddingNewRow(false);
    },
    [onCreate, onUpdate, setEditableRow, addingNewRow]
  );

  const actionsMapping = getActionsMapping({
    editVisible,
    onAdd: handleAdd,
    onCancel: handleCancel,
    onDelete: handleDelete,
    onEdit: handleEdit,
    onSave: handleSave,
  });

  const updateEditableFields = useCallback(
    (e) => {
      if (
        !e?.detail?.rowMappingId ||
        !e?.detail?.rowDataId ||
        !(
          typeof e?.detail?.value === 'string' ||
          typeof e?.detail?.value === 'number' ||
          typeof e?.detail?.value === 'boolean' ||
          (typeof e?.detail?.value?.label === 'string' &&
            typeof e?.detail?.value?.value === 'string')
        )
      ) {
        return;
      }

      if (!!editableRow && e.detail.rowDataId === editableRow?.id) {
        // Update row state if row is in edit mode
        const newRowData = { ...editableRow };
        newRowData[e.detail.rowMappingId] = e.detail.value;
        setEditableRow(newRowData);
        return;
      }
    },
    [editableRow]
  );

  const resolvedNoDataTableContent = useMemo(
    () =>
      typeof noDataTableContent === 'function'
        ? noDataTableContent(handleAdd)
        : noDataTableContent,
    [noDataTableContent, handleAdd]
  );

  useEffect(() => {
    const event = `table:item-changed-${tableId}`;
    window.addEventListener(event, updateEditableFields);

    return () => {
      window.removeEventListener(event, updateEditableFields);
    };
  }, [tableId, updateEditableFields]);

  if (!ready) {
    return null;
  }

  return (
    <GenericTable
      classList={classes}
      columnHeaders={[...headers, ACTIONS_HEADER(t)]}
      noDataTableContent={
        showNoDataAddButton
          ? resolvedNoDataTableContent ?? (
              <div className={classes.noDataTableContent}>
                <MUIDeleteIcon className={classes.disableIconStyle} />
                <MUIAddIcon
                  className={classes.addIconStyle}
                  onClick={handleAdd}
                />
              </div>
            )
          : undefined
      }
      rowMapping={[...rowMappings, actionsMapping]}
      tableData={
        addingNewRow && !!editableRow
          ? [...tableData, editableRow] // If adding new row, inject editableRow at end of array
          : tableData
      }
      tableId={tableId}
      {...rest}
    />
  );
};

export default EditableTable;
