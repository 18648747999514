import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ config, palette }: any) =>
  createStyles({
    content: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'space-evenly',
      width: '100%',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    shimmerContent: {
      width: '150px',
      height: '150px',
    },
    imageButton: {
      backgroundColor: palette.common.white,
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      border: '0px',
      cursor: 'pointer',
      width: '150px',
      height: '150px',
      margin: '19px',
      padding: '6px',
      '&:focus': {
        border: '2px solid #4CAF50',
        boxShadow: '0px 8px 8px 0px rgba(0, 0, 0, 0.50)',
      },
      '&:hover': {
        border: '2px solid #4CAF50',
        boxShadow: '0px 8px 8px 0px rgba(0, 0, 0, 0.50)',
      },
    },
    imgBox: {
      width: '80px',
      height: '80px',
      margin: 'auto',
    },
    buttonStyle: {
      marginTop: '10px',
      alignSelf: 'end',
      '& > button': { boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.38)' },
    },
    buttonBackgroundColor: {
      backgroundColor: palette.charts.icon,
      color: palette.common.white,
      '&:hover': {
        backgroundColor: palette.charts.icon,
      },
    },
    modal: {
      '& .MuiDialog-paper': {
        maxWidth: '1185px',
        height: '619px',
      },
    },
    horizontalStepper: {
      padding: '0px 155px',
      width: '100%',
    },
    label: {
      color: palette.common.black,
      fontSize: '12px',
      margin: '0px',
      paddingTop: '10px',
      textAlign: 'center',
    },
    providerContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    stepperIconLabelColor: {
      '& .MuiStepIcon-root.MuiStepIcon-completed, & .MuiStepIcon-root.MuiStepIcon-active':
        {
          color: config.colors.green,
        },
      '& .MuiStepIcon-root': {
        color: palette.primary.main,
      },
      '& .MuiStepLabel-label.MuiStepLabel-active, & .MuiStepLabel-label.MuiStepLabel-completed':
        {
          color: palette.text.primary,
        },
    },
    selected: {
      border: '2px solid #4CAF50',
    },
    stepperConnector: {
      '&.MuiStepper-root ': {
        width: '100%',
        padding: '24px 24px 24px 0px',
      },
      '& .MuiStepConnector-line': {
        width: '100%',
      },
    },
  })
);

export default useStyles;
