import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    form: {},
    formGroup: {
      outline: `2px dashed ${palette.primary.main}`,
      outlineOffset: '-10px',
      WebkitTransition:
        'outline-offset .15s ease-in-out, background-color .15s linear',
      transition:
        'outline-offset .15s ease-in-out, background-color .15s linear',
      height: 200,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    radioGroup: {
      flexDirection: 'column',
      marginBottom: 16,
    },
  })
);

export default useStyles;
