import useStyles from './useStyles';

export interface IAlertIcon {}

const AlertIcon: React.FC<IAlertIcon> = () => {
  const classes = useStyles();

  return (
    <svg className={classes.icon} viewBox="0 0 24 24">
      <g transform="matrix(0.6,0,0,0.6,-2.4,-2.4)">
        <path d="M24,34C24.467,34 24.858,33.842 25.175,33.525C25.492,33.208 25.65,32.817 25.65,32.35C25.65,31.883 25.492,31.492 25.175,31.175C24.858,30.858 24.467,30.7 24,30.7C23.533,30.7 23.142,30.858 22.825,31.175C22.508,31.492 22.35,31.883 22.35,32.35C22.35,32.817 22.508,33.208 22.825,33.525C23.142,33.842 23.533,34 24,34ZM22.65,26.35L25.65,26.35L25.65,13.7L22.65,13.7L22.65,26.35ZM24,44C21.267,44 18.683,43.475 16.25,42.425C13.817,41.375 11.692,39.942 9.875,38.125C8.058,36.308 6.625,34.183 5.575,31.75C4.525,29.317 4,26.717 4,23.95C4,21.217 4.525,18.633 5.575,16.2C6.625,13.767 8.058,11.65 9.875,9.85C11.692,8.05 13.817,6.625 16.25,5.575C18.683,4.525 21.283,4 24.05,4C26.783,4 29.367,4.525 31.8,5.575C34.233,6.625 36.35,8.05 38.15,9.85C39.95,11.65 41.375,13.767 42.425,16.2C43.475,18.633 44,21.233 44,24C44,26.733 43.475,29.317 42.425,31.75C41.375,34.183 39.95,36.308 38.15,38.125C36.35,39.942 34.233,41.375 31.8,42.425C29.367,43.475 26.767,44 24,44Z" />
      </g>
    </svg>
  );
};

export default AlertIcon;
