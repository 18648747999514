import { createStyles, makeStyles } from '@material-ui/styles';

// Usage of 'any' inside this style decorator is discouraged; however, our theme
// currently does not conform to MUI's theme structure standards. So this will
// be a followup fix for later.
const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',

      '& button': {
        alignSelf: 'flex-end',
        textTransform: 'uppercase',
      },

      '& input[type=email], & input[type=text]': {
        backgroundColor: '#e0ebf2',
        borderColor: '#d4d4d4',
        borderRadius: 3,
        height: '25px',
      },

      '& label > span': {
        color: '#757575 !important',
        fontFamily: "'Roboto', sans-serif",
        fontSize: '14px',
        userSelect: 'none',
      },

      '& > span': {
        width: 'fit-content',
        fontSize: 10,
      },

      '& .MuiButton-contained.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.26)',
        boxShadow: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
      },

      '& .MuiButton-contained': {
        boxShadow:
          '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
      },

      '& .MuiButton-containedSecondary': {
        color: '#fff',
        backgroundColor: '#607d8b',
      },
    },

    errorOutline: {
      '& input.MuiInput-input': {
        borderColor: palette?.error?.light ?? 'red',
      },
    },

    modal: { maxWidth: 250 },

    spacer: { height: '15px' },

    textBody: {
      color: 'black',
      fontFamily: "'Roboto', sans-serif",
      fontSize: '14px',
      marginBottom: '0.25vh',
      marginTop: '2vh',
    },
  })
);

export default useStyles;
