import { createStyles, makeStyles } from '@material-ui/styles';

// Usage of 'any' inside this style decorator is discouraged; however, our theme
// currently does not conform to MUI's theme structure standards. So this will
// be a followup fix for later.
const useStyles = makeStyles(() =>
  createStyles({
    logo: {
      alignSelf: 'flex-start',
      height: '35px',
      marginLeft: 20,
      marginTop: 0,
      width: 'auto',

      '@media screen and (min-width: 649px)': {
        alignSelf: 'center',
        height: 'auto',
        marginTop: 80,
        width: 'inherit',
      },
    },

    publicContent: {
      padding: '0 20px 20px 20px',
    },

    publicContentContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: '128px 0 20px 0',
      width: 340,
    },

    publicContentWrapper: {
      background: '#fff',
      display: 'block',
      width: '100%',

      '& .public-logo': {
        marginBottom: 40,
        maxWidth: 400,
      },
    },

    publicSection: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',

      '& a': {
        textDecoration: 'underline',
      },
    },
  })
);

export default useStyles;
