import { ISelectItem } from 'components/UI/input/Select';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { IDormancyChanges } from './models';

const { persistAtom } = recoilPersist();

export const dormancyChanges = atom<IDormancyChanges>({
  key: 'dormancyPolicy-dormancyChanges',
  default: { hasChanges: false },
  effects_UNSTABLE: [persistAtom],
});

export const dormancyFormValid = atom<boolean | undefined>({
  key: 'dormancyPolicy-dormancyFormValid',
  default: true,
  effects_UNSTABLE: [persistAtom],
});

export const preselectedPolicy = atom<ISelectItem | undefined>({
  key: 'dormancyPolicy-preselectedPolicy',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
