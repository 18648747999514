import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = (scrollable?: boolean, large?: boolean) => {
  const scrollableStyles: { [key: string]: any } = scrollable
    ? {
        root: {
          overflowY: 'auto',
        },

        container: {},

        scrollPaper: {
          alignItems: 'center',
        },

        paperScrollPaper: {
          overflowY: 'auto',
          overflowX: 'auto',
        },
      }
    : {};

  const largeStyles: { [key: string]: any } = large
    ? {
        largeModal: {
          '& .MuiDialog-paper': {
            width: '80%',
            maxWidth: 'none',
          },
        },
      }
    : {};

  return makeStyles(() =>
    createStyles({
      ...scrollableStyles,
      ...largeStyles,
      container: {
        padding: '0 24px',
      },
    })
  );
};

export const useOtherStyles = makeStyles(({ palette }: any) =>
  createStyles({
    content: {
      padding: '0 24px',
      minWidth: 480,
    },

    title: {
      margin: 0,
      backgroundColor: palette.background.default,

      '& > h2': {
        textTransform: 'uppercase',
        textAlign: 'center',
        fontSize: 24,
      },
    },
    zScanModalStyle: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    zScanModalCloseStyle: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

export default useStyles;
