import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    chip: {
      borderRadius: 3,
      fontWeight: 500,
      margin: 2,
      paddingRight: 30,
      position: 'relative',
    },
    deleteIcon: {
      position: 'absolute',
      right: 0,
    },
    filterBoxWrapper: {
      alignItems: 'center',
      background: 'transparent',
      display: 'flex',
      flexWrap: 'wrap',
      minHeight: 48,
      padding: 5,
      width: '100%',

      '&:empty:before': {
        color: palette.text.placeholder,
        content: 'attr(data-placeholder)',
        paddingLeft: 10,
      },
    },
    label: {
      display: 'block',
      maxWidth: 300,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
);

export default useStyles;
