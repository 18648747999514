import _ from 'lodash';
import store from 'config/storeConfig';

const ProtectedMapping = mappings => {
  const { scopes } = store.getState().auth.user;

  const userScopes = Object.keys(scopes);

  return mappings.filter(
    mapping => !mapping.scope || _.includes(userScopes, mapping.scope)
  );
};

export default ProtectedMapping;
