import { makeStyles } from '@material-ui/styles';

// Usage of 'any' inside this style decorator is discouraged; however, our theme
// currently does not conform to MUI's theme structure standards. So this will
// be a followup fix for later.
const useStyles = makeStyles(({ palette }: any) => {
  return {
    drawer: {
      overflowX: 'hidden',
      borderRadius: 4,
      height: '80%',
      marginTop: 73,
      padding: '0px 10px 0px 10px',
      width: '525px',
      zIndex: 99,
      '&::-webkit-scrollbar': {
        width: '10px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        background: palette.common.almostWhite,
        borderRadius: '10px',
      },
    },
  };
});
export default useStyles;
