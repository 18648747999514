import _ from 'lodash';
import Moment from 'moment';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { toggleModalDiffered } from 'utils/storeUtils';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';

// import { Link } from 'react-router-dom';
// import Tooltip from '@material-ui/core/Tooltip';
// import ConfigurationIcon from '@material-ui/icons/ExitToApp';

export const configurationsTableHeaders = [
  {
    id: 'name',
    label: 'ZSCAN.CONFIGURATIONS.CONFIGURATION_NAME',
    show: true,
    sort: true,
  },
  {
    id: 'integrationName',
    label: 'ZSCAN.CONFIGURATIONS.INTEGRATION',
    show: true,
    sort: true,
  },
  {
    id: 'teamName',
    label: 'GLOBAL.TEAM',
    show: true,
    sort: true,
  },
  {
    id: 'projectName',
    label: 'ZSCAN.CONFIGURATIONS.PROJECT_NAME',
    show: true,
    sort: true,
  },
  {
    id: 'issueType',
    label: 'ZSCAN.CONFIGURATIONS.ISSUE_TYPE',
    show: true,
    sort: true,
  },
  {
    id: 'modified',
    label: 'GLOBAL.LAST_MODIFIED',
    show: true,
    sort: true,
  },
  {
    id: 'rowActions',
    label: 'GLOBAL.ACTIONS',
    show: true,
  },
];

export const configurationsTableColumns = [
  {
    path: 'name',
  },
  {
    path: 'integration.name',
  },
  {
    path: 'teamName',
  },
  {
    path: 'projectName',
  },
  {
    path: 'issueType',
  },
  {
    path: 'modified',
    columnContent: (timestamp) => Moment(timestamp).fromNow(),
    csvContent: (timestamp) => Moment(timestamp).toISOString(),
  },
  {
    path: 'rowActions',
    cellInlineStyle: { justifyContent: 'flex-start', display: 'flex' },
    columnContent: (_, { rowData, isLastRow }) => {
      return (
        <ProtectedComponent allow={{ zdev_apps: 'manage' }}>
          <EditIcon
            className="hover--link"
            onClick={toggleModalDiffered('ConfigurationCreateEdit', rowData, {
              title: 'ZSCAN.CONFIGURATIONS.EDIT_CONFIGURATION',
              disableBackdropClick: true,
            })}
          />
          <DeleteIcon
            className="hover--link"
            onClick={toggleModalDiffered(
              'ConfigurationDeleteConfirm',
              rowData,
              {
                title: 'ZSCAN.CONFIGURATIONS.DELETE_CONFIGURATION',
                disableBackdropClick: true,
                // scrollable: true,
              }
            )}
          />
          {isLastRow && (
            <AddIcon
              className="hover--link"
              onClick={toggleModalDiffered(
                'ConfigurationCreateEdit',
                {},
                {
                  title: 'ZSCAN.CONFIGURATIONS.CREATE_NEW_CONFIGURATION',
                  disableBackdropClick: true,
                }
              )}
            />
          )}
        </ProtectedComponent>
      );
    },
  },
  // {
  //   path: 'id',
  //   columnContent: (configurationId, { rowData }) => {
  //     return (
  //       <ProtectedComponent allow={{ zdev_apps: 'manage' }}>
  //         <Tooltip
  //           title="Manage Configuration"
  //           enterDelay={200}
  //           placement="top"
  //         >
  //           <Link
  //             to={`/console/zscan/integrations/${rowData.integration.id}/configurations/${configurationId}`}
  //           >
  //             <ConfigurationIcon />
  //           </Link>
  //         </Tooltip>
  //       </ProtectedComponent>
  //     );
  //   },
  // },
];

export const buildConfigurationsParams = [
  {
    paramKey: 'page',
    transform: ({ page }) => {
      if (page) {
        return page;
      }
      return 0;
    },
  },
  {
    paramKey: 'size',
    transform: ({ size }) => {
      if (size) {
        return size;
      }
      return 25; // Todo: magic number make a global default for page size!
    },
  },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      if (orderBy && order) {
        return `${orderBy},${order}`;
      }
      return null;
    },
  },
];

export const createConfigurationPayload = [
  {
    path: 'name',
  },
  {
    path: 'integrationId',
    transform: (payload) => _.get(payload, 'integrationId[0].value'),
  },
  {
    path: 'project',
    key: 'projectId',
    transform: (payload) => _.get(payload, 'project[0].value'),
  },
  {
    path: 'project',
    key: 'projectName',
    transform: (payload) => _.get(payload, 'project[0].label'),
  },
  {
    path: 'issueType',
    key: 'issueTypeId',
    transform: (payload) => _.get(payload, 'issueType[0].value'),
  },
  {
    path: 'issueType',
    transform: (payload) => _.get(payload, 'issueType[0].label'),
  },
  {
    path: 'teamId',
    transform: (payload) => _.get(payload, 'team[0].value'),
  },
];

export const updateConfigurationPayload = [
  ...createConfigurationPayload,
  // {
  //   path: 'acceptedExternalStatus',
  //   transform: (payload) => {
  //     return {
  //       externalStatusId: _.get(payload, 'acceptedExternalStatus[0].value'),
  //       externalStatus: _.get(payload, 'acceptedExternalStatus[0].label'),
  //     };
  //   },
  // },
  // {
  //   path: 'statusMappingList',
  //   transform: (payload) =>
  //     Object.keys(payload.statusMappingList)
  //       .filter((s) => s !== 'UNASSIGNED')
  //       .map((status) => ({
  //         status,
  //         externalStatusList: payload.statusMappingList[status].map(
  //           ({ id, content }) => ({
  //             externalStatusId: id.replace('-s', ''),
  //             externalStatus: content,
  //           })
  //         ),
  //       })),
  // },
  // {
  //   path: 'zdevAppIds',
  //   transform: ({ zdevAppIds }) => zdevAppIds.map(({ value }) => value),
  // },
];
