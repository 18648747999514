import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';

const styles = themeSelected => {
  const {
    palette: { background },
  } = themeSelected;
  return {
    popOver: {
      background: background.default,
      fontSize: '8px',
    },
    paperRoot: {
      overflow: 'visible',
    },
  };
};
class ZTableFilterMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      anchorEl: this.props.anchorel,
    };
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
    this.props.onClose();
  };

  render() {
    const { anchorEl, classes, overflowAuto } = this.props;
    return (
      <Popover
        PaperProps={{
          classes: { root: !overflowAuto && classes.paperRoot },
        }}
        id="render-props-popover"
        open={Boolean(this.props.anchorEl)}
        onClose={this.handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {this.props.children}
      </Popover>
    );
  }
}
export default withStyles(styles)(ZTableFilterMenu);
