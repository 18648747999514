import { InputAdornment, TextField as MUITextField } from '@material-ui/core';
import MUISearchIcon from '@material-ui/icons/Search';
import { useCallback, useRef, useState } from 'react';
import useStyles from './useStyles';
import { useTranslation } from 'react-i18next';
import withRouter from 'components/hocs/withRouter';

interface ISearchBoxProps {
  debounceTime?: number;
  onInputChange: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
}

const SearchBox: React.FC<ISearchBoxProps> = ({
  debounceTime = 400,
  placeholder = 'GLOBAL.SEARCH',
  onInputChange,
  disabled,
}) => {
  const searchValue = new URLSearchParams(location.search).get('search');
  const [value, setValue] = useState<string>(searchValue ?? '');
  const { t, ready } = useTranslation();
  const classes = useStyles();

  const timeout = useRef<NodeJS.Timeout | null>(null);

  const search = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // Wait to call method until user stops typing
      const value = e.currentTarget.value;
      timeout.current = setTimeout(() => {
        onInputChange(value);
      }, debounceTime);
    },
    [debounceTime, onInputChange]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      clearTimeout(timeout.current as unknown as number);
      setValue(e.currentTarget.value);
      search(e);
    },
    [setValue, search]
  );

  if (!ready) {
    return null;
  }

  return (
    <MUITextField
      disabled={disabled}
      className={classes.searchBoxWrapper}
      InputProps={{
        placeholder: t(placeholder),
        classes: {
          root: classes.searchBox,
        },
        endAdornment: (
          <InputAdornment
            classes={{
              root: classes.searchIcon,
            }}
            position="end"
          >
            <MUISearchIcon />
          </InputAdornment>
        ),
      }}
      onChange={handleInputChange}
      value={value}
    />
  );
};

export default withRouter(SearchBox);
