import useStyles from './useStyles';

interface IInputSkeletonProps {
  label: string;
}

const InputSkeleton: React.FC<IInputSkeletonProps> = ({ label }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.wrapper}>
        <label className={classes.label}>{label}</label>
        <div className={`is-loading shimmer ${classes.loader}`} />
      </div>
    </>
  );
};

export default InputSkeleton;
