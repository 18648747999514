import { IGraphOptions } from '../common/models';
import { IChartElement, IGenericGraphHandlerFn } from './models';

export const defaultOptions: IGraphOptions = {
  legend: {
    display: false,
  },
  cutoutPercentage: 75,
  responsive: true,
  options: {
    events: ['click']
  },
  onHover: (event, chartElement) => {
    if (event?.target?.style?.cursor) {
      event.target.style.cursor = chartElement?.[0] ? 'pointer' : 'default';
    }
  },
  plugins: {
    labels: {
      fontColor: '#fff',
      fontSize: 11,
      outsidePadding: 40,
      position: 'outside',
      render: 'label',
      textMargin: 10,
    },
  },
};

export function genericGraphHandler(handler: IGenericGraphHandlerFn) {
  return (elems: IChartElement[]) => {
    if (!elems.length) {
      return;
    }

    const elem = elems[0];
    const elemIndex = elem._index;
    const dataSetIndex = elem._datasetIndex;
    const dataset = elem._chart.data.datasets[dataSetIndex];
    const { data } = dataset;

    handler(elems, {
      dataSetIndex,
      data,
      dataset,
      elem,
      elemIndex,
      label: elem._chart.data.labels[elemIndex],
      value: data[elemIndex],
    });
  };
}
