import React from 'react';
import _ from 'lodash';
import momentHelper from 'utils/momentUtils';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import { toggleModalDiffered } from 'utils/storeUtils';

// super ui partner users table
export const partnerUsersTableHeaders = [
  {
    id: 'partnerRole.name',
    label: 'Partner Role',
    show: true,
    sort: true,
  },
  {
    id: 'email',
    label: 'Email',
    show: true,
    sort: true,
  },
  {
    id: 'firstName',
    label: 'First Name',
    show: true,
    sort: true,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    show: true,
    sort: true,
  },
  {
    id: 'created',
    label: 'Created',
    show: true,
    sort: true,
  },
  {
    id: 'modified',
    label: 'Modified',
    show: true,
    sort: true,
  },
  {
    id: 'accountPersonasCount',
    label: 'Persona Count',
    show: true,
    sort: false,
  },
  {
    id: 'actions',
    numeric: false,
    label: 'Actions',
    sort: false,
    show: true,
    // isProtected: true,
    // scope: 'teams',
  },
];

export const partnerUsersColumnMapping = [
  { path: 'partnerRole.name' },
  {
    path: 'email',
    columnContent: email => (
      <span className="table-cell__ellipsis">{email}</span>
    ),
  },
  { path: 'firstName' },
  { path: 'lastName' },
  { path: 'created', columnContent: timestamp => momentHelper(timestamp) },
  { path: 'modified', columnContent: timestamp => momentHelper(timestamp) },
  { path: 'accountPersonasCount' },
  {
    path: 'actions',
    csvContent: null,
    columnContent: (id, { rowData, handlers }) => {
      if (!rowData.editable) return null;
      return (
        <div className="flex--spaced">
          <Tooltip title="Edit Role or Add/Remove Accounts">
            <EditIcon
              className="hover--link"
              onClick={toggleModalDiffered(
                'PartnerUserEdit',
                {
                  rowData,
                  accountId: rowData.id,
                  // because this is a shared modal with super ui and partner ui
                  // - check which table to refresh
                  partnerUI: _.get(handlers, 'partnerUI'),
                },
                {
                  title: 'Edit Partner User',
                  fullWidth: true,
                }
              )}
            />
          </Tooltip>
          <Tooltip title="Disable user from being a partner">
            <DeleteIcon
              className="hover--link"
              onClick={toggleModalDiffered(
                'PartnerUserDeleteConfirm',
                {
                  rowData,
                  partnerUI: _.get(handlers, 'partnerUI'),
                },
                {
                  title: 'Remove Partner User',
                }
              )}
            />
          </Tooltip>
        </div>
      );
    },
  },
  { path: '' }, // hack to get the table row 100%
];
