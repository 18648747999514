import React, { Component, RefObject } from 'react';

interface RequiredSelectWrapperProps {
  value?: string;
  onChange: (value: string, actionMeta: any) => void;
  SelectComponent: any;
  required?: boolean;
  isDisabled?: boolean;
}

interface RequiredSelectWrapperState {
  value: string;
}

class RequiredSelectWrapper extends Component<
  RequiredSelectWrapperProps,
  RequiredSelectWrapperState
> {
  state: RequiredSelectWrapperState = {
    value: this.props.value || '',
  };

  selectRef: RefObject<any> = React.createRef();

  setSelectRef = (ref: any) => {
    this.selectRef = ref;
  };

  onChange = (value: string, actionMeta: any) => {
    this.props.onChange(value, actionMeta);
    this.setState({ value });
  };

  getValue = (): string => {
    if (this.props.value !== undefined) {
      return this.props.value;
    }
    return this.state.value || '';
  };

  render() {
    const { SelectComponent, required, isDisabled, ...props } = this.props;
    const enableRequired = !isDisabled;
    return (
      <div>
        <SelectComponent
          {...props}
          ref={this.setSelectRef}
          onChange={this.onChange}
        />
        {enableRequired && (
          <input
            tabIndex={-1}
            autoComplete="off"
            style={{
              opacity: 0,
              width: '50%',
              height: 0,
              position: 'absolute',
            }}
            value={this.getValue()}
            onFocus={() => this.selectRef.current}
            required={required}
          />
        )}
      </div>
    );
  }
}

export default RequiredSelectWrapper;
