import axios from 'axios';
import { IAuthForGroupsCreate } from 'components/main/integrations/emm/models';
import { ISelectItem } from 'components/UI/input/Select';
import { Api } from 'config/axiosConfig';
import { publishEvent } from 'utils/eventUtils';
import { openSnackBar } from 'utils/storeUtils';
import { IIntuneAuth } from '../../AddEditCloneEMMAuth/IntuneForm/models';
import { IStandardAuth } from '../../AddEditCloneEMMAuth/StandardForm/models';
import { IVMWareAuth } from '../../AddEditCloneEMMAuth/VMWareForm/models';
import { IGroupToSync } from '../../emmTable/MDM/models';
import { IFormData, IResponseFormData } from './Form/models';
import {
  IGroupCreatePayload,
  IGroupEditPayload,
  IGroupPolicyMapping,
  IIntuneFields,
  IPolicyNameMapping,
  IResponseGroupMapping,
} from './models';

export const getNextId = (
  groupPolicyMappings: IGroupPolicyMapping[]
): number => {
  return (
    groupPolicyMappings.reduce((acc, curr) => {
      return Number(curr.id) > acc ? Number(curr.id) : acc;
    }, 0) + 1
  );
};
export const getNextResponseId = (
  groupPolicyMappings: IResponseGroupMapping[]
): number => {
  return (
    groupPolicyMappings.reduce((acc, curr) => {
      return Number(curr.id) > acc ? Number(curr.id) : acc;
    }, 0) + 1
  );
};
export const createGroupRows = (
  values: IFormData,
  nextId: number
): IGroupPolicyMapping[] => {
  if (!values?.groups?.[0]) {
    return [];
  }
  return values.groups.map((group, index) => ({
    id: `${nextId + index}`,
    group,
    appSettings: values?.appSettings,
    deviceSpace: values?.deviceSpace,
    dormancyPolicy: values?.dormancyPolicy,
    phishingPolicy: values?.phishingPolicy,
    privacyPolicy: values?.privacyPolicy,
    trmPolicy: values?.trmPolicy,
    networkPolicy: values?.networkPolicy,
    osRiskPolicy: values?.osRiskPolicy

  }));
};
export const createResponseGroupRows = (
  values: IResponseFormData,
  nextId: number
): IResponseGroupMapping[] => {
  if (!values?.groups?.[0]) {
    return [];
  }

  return values.groups.map((group, index) => ({
    id: `${nextId + index}`,
    group,
  }));
};

export const POLICY_NAME_MAPPING: IPolicyNameMapping[] = [
  { fieldName: 'appSettings', apiName: 'appSettingsId' },
  { fieldName: 'dormancyPolicy', apiName: 'dormancyPolicyId' },
  { fieldName: 'group', apiName: 'mdmGroupId' },
  { fieldName: 'phishingPolicy', apiName: 'phishingPolicyId' },
  { fieldName: 'privacyPolicy', apiName: 'privacyId' },
  { fieldName: 'trmPolicy', apiName: 'trmId' },
  { fieldName: 'networkPolicy', apiName: 'networkPolicyId' },
  { fieldName: 'osRiskPolicy', apiName: 'osRiskPolicyId' }

  // osRisk
];

const prepareGroupsForApi = (
  groupPolicyMappings: IGroupPolicyMapping[] | IResponseGroupMapping[]
) => {

  return groupPolicyMappings.map((policyMapping) => {
    const apiMapping: Partial<IGroupToSync> = {};
    const fieldNames = Object.keys(policyMapping);
    fieldNames.forEach((currentTableName) => {
      if (
        !POLICY_NAME_MAPPING.some(
          ({ fieldName }) => fieldName === currentTableName
        )
      ) {
        return;
      }

      const apiName = POLICY_NAME_MAPPING.find(
        ({ fieldName }) => fieldName === currentTableName
      )?.apiName;
      const value = String(
        (policyMapping[currentTableName] as ISelectItem)?.value ?? ''
      );

      if (!!apiName && !!value) {
        apiMapping[apiName] = value;
      }
    });
    return apiMapping;
  });
};
const isBoolValuePresent = [true, false];
export const createConnectionWithGroups = async (
  groupPolicyMappings: IGroupPolicyMapping[],
  responseGroupMappings: IResponseGroupMapping[],
  handleClose: () => void,
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
  setSubmissionError: React.Dispatch<React.SetStateAction<string>>,
  authForGroupsCreate?: IAuthForGroupsCreate<
    IStandardAuth | IVMWareAuth | IIntuneAuth
  >,
  intuneFields?: IIntuneFields,
  thirdPartyMDMSelected?: boolean,
  isSOTIConnector?: boolean,
  isGoogleConnector?: boolean
) => {
  if (!authForGroupsCreate?.authData?.auth) {
    return;
  }

  const authWithSpaceId = {
    ...authForGroupsCreate,
    authData: {
      ...authForGroupsCreate.authData,
      auth: {
        ...authForGroupsCreate?.authData?.auth,
        device_space_id: groupPolicyMappings?.[0]?.deviceSpace?.value as string,
      },
    },
  };

  let includeNestedGroups = undefined;
  let allowDevicesWithNoUser = undefined;

  if (
    !thirdPartyMDMSelected &&
    isBoolValuePresent.includes(!!intuneFields?.includeNestedGroups)
  ) {
    includeNestedGroups = intuneFields?.includeNestedGroups;
  }
  if (
    !thirdPartyMDMSelected &&
    isBoolValuePresent.includes(!!intuneFields?.allowDevicesWithNoUser)
  ) {
    allowDevicesWithNoUser = intuneFields?.allowDevicesWithNoUser;
  }

  if (isSOTIConnector || isGoogleConnector) {
    includeNestedGroups = intuneFields?.includeNestedGroups;
  }

  const payload: IGroupCreatePayload<
    IStandardAuth | IVMWareAuth | IIntuneAuth
  > = {
    ...authWithSpaceId,
    groupsToSync: prepareGroupsForApi(groupPolicyMappings),
    actionGroupsToSync: prepareGroupsForApi(responseGroupMappings),
    includeNestedGroups,
    allowDevicesWithNoUser,
    maxDeviceIdleDays: intuneFields?.maxDeviceIdleDays,
    mode: intuneFields?.mode ?? authForGroupsCreate?.mode,
  };

  const apiUrl = 'api/emm/v1/provision/connection';
  try {
    await Api.post(apiUrl, payload);
    handleClose();
    openSnackBar('Successfully created MDM connection.');
    publishEvent('emm:reload-table');
  } catch (e) {
    if (axios.isAxiosError(e) && e?.response?.status === 409) {
      setSubmissionError(
        'One or more groups already used in another connection.'
      );
    } else {
      setSubmissionError('Failed to create MDM connection.');
    }
    setIsSubmitting(false);
    console.error(e);
  }
};

export const updateGroups = async (
  groupPolicyMappings: IGroupPolicyMapping[],
  responseGroupMappings: IResponseGroupMapping[],
  handleClose: () => void,
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
  setSubmissionError: React.Dispatch<React.SetStateAction<string>>,
  connectionId?: string,
  initialDeviceSpaceId?: string, // Falls back to this if no new groups selected
  teamId?: string,
  intuneFields?: IIntuneFields,
  thirdPartyMDMSelected?: boolean,
  isSOTIConnector?: boolean,
  isGoogleConnector?: boolean
) => {
  if (!teamId || !connectionId) {
    return;
  }
  const spaceIdFromAnyNewGroup = groupPolicyMappings.find(
    ({ deviceSpace }) => !!deviceSpace?.value
  )?.deviceSpace?.value;

  let includeNestedGroups = undefined;
  let allowDevicesWithNoUser = undefined;

  if (
    !thirdPartyMDMSelected &&
    isBoolValuePresent.includes(!!intuneFields?.includeNestedGroups)
  ) {
    includeNestedGroups = intuneFields?.includeNestedGroups;
  }
  if (
    !thirdPartyMDMSelected &&
    isBoolValuePresent.includes(!!intuneFields?.allowDevicesWithNoUser)
  ) {
    allowDevicesWithNoUser = intuneFields?.allowDevicesWithNoUser;
  }

  if (isSOTIConnector || isGoogleConnector) {
    includeNestedGroups = intuneFields?.includeNestedGroups;
  }

  const payload: IGroupEditPayload = {
    groupAuth: {
      device_space_id:
        (spaceIdFromAnyNewGroup as string) ?? initialDeviceSpaceId,
    },
    groupsToSync: !thirdPartyMDMSelected
      ? prepareGroupsForApi(groupPolicyMappings)
      : undefined,
    includeNestedGroups,
    allowDevicesWithNoUser,
    actionGroupsToSync: prepareGroupsForApi(responseGroupMappings),
    maxDeviceIdleDays: intuneFields?.maxDeviceIdleDays,
    mode: intuneFields?.mode,
    teamId,
  };

  const apiUrl = `api/emm/v1/provision/connection/${connectionId}/sync-groups`;

  try {
    await Api.patch(apiUrl, payload);
    handleClose();
    openSnackBar('Successfully updated EMM groups.');
    publishEvent('emm:reload-table');
  } catch (e) {
    if (axios.isAxiosError(e) && e?.response?.status === 409) {
      setSubmissionError(
        'One or more groups already used in another connection.'
      );
    } else {
      setSubmissionError('Failed to update EMM groups.');
    }
    setIsSubmitting(false);
    console.error(e);
  }
};

export const cloneConnection = async (
  groupPolicyMappings: IGroupPolicyMapping[],
  responseGroupMappings: IResponseGroupMapping[],
  handleClose: () => void,
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
  setSubmissionError: React.Dispatch<React.SetStateAction<string>>,
  connectionIdToClone?: string,
  teamId?: string,
  name?: string,
  intuneFields?: IIntuneFields,
  thirdPartyMDMSelected?: boolean,
  isSOTIConnector?: boolean,
  isGoogleConnector?: boolean
) => {
  if (!connectionIdToClone || !teamId) {
    return;
  }

  let includeNestedGroups = undefined;
  let allowDevicesWithNoUser = undefined;

  if (
    !thirdPartyMDMSelected &&
    isBoolValuePresent.includes(!!intuneFields?.includeNestedGroups)
  ) {
    includeNestedGroups = intuneFields?.includeNestedGroups;
  }
  if (
    !thirdPartyMDMSelected &&
    isBoolValuePresent.includes(!!intuneFields?.allowDevicesWithNoUser)
  ) {
    allowDevicesWithNoUser = intuneFields?.allowDevicesWithNoUser;
  }

  if (isSOTIConnector || isGoogleConnector) {
    includeNestedGroups = intuneFields?.includeNestedGroups;
  }

  const payload = {
    connectionIdToClone,
    groupsToSync: !thirdPartyMDMSelected
      ? prepareGroupsForApi(groupPolicyMappings)
      : undefined,
    actionGroupsToSync: prepareGroupsForApi(responseGroupMappings),
    name,
    teamId,
    includeNestedGroups,
    allowDevicesWithNoUser,
    maxDeviceIdleDays: intuneFields?.maxDeviceIdleDays,
    mode: intuneFields?.mode,
  };

  const apiUrl = `api/emm/v1/provision/clone`;
  try {
    await Api.post(apiUrl, payload);
    handleClose();
    openSnackBar('Successfully cloned MDM connection.');
    publishEvent('emm:reload-table');
  } catch (e) {
    if (axios.isAxiosError(e) && e?.response?.status === 409) {
      setSubmissionError(
        'One or more groups already used in another connection.'
      );
    } else {
      setSubmissionError('Failed to clone MDM connection.');
    }
    setIsSubmitting(false);
    console.error(e);
  }
};
