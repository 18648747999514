import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

export default state => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      instances: {
        ...state.uiSettings.instances,
        tableHeaders: uiSettingsDefaults.instances.tableHeaders,
      },
    },
  };
};
