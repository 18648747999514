import _ from 'lodash';
import { generateMultiSelectValue } from 'utils/componentUtils';

// converts groups to list items on the modal card

export const cleanActionGroupsToSync = (groupList) => {
  return groupList.map((group, index) => {
    return {
      id: group.mdmGroupId,
      singleGroup: {
        groupIdsToSync: {
          label: group.mdmGroupName,
          value: group.mdmGroupId,
          description: group.mdmGroupDescription,
        },
        index,
      },
    };
  });
};
export const cleanGroupsToSync = (
  groupList,
  privacyList,
  trmList,
  phishingList,
  dormancyList,
  appSettingsList
) => {
  return groupList.map((group, index) => {
    const trmLabel = generateMultiSelectValue(group.trmId, trmList);
    const privacyLabel = generateMultiSelectValue(group.privacyId, privacyList);
    const phishingLabel = generateMultiSelectValue(
      _.get(group, 'phishingPolicyId'),
      phishingList
    );

    const dormancyLabel = generateMultiSelectValue(
      group.dormancyPolicyId,
      dormancyList
    );

    const appSettingsLabel = generateMultiSelectValue(
      _.get(group, 'appSettingsId'),
      appSettingsList
    );
    return {
      id: group.mdmGroupId,
      singleGroup: {
        groupIdsToSync: {
          label: group.mdmGroupName,
          value: group.mdmGroupId,
          description: group.mdmGroupDescription,
        },
        trmPolicy: {
          label: _.get(trmLabel[0], 'label', 'N/A'),
          value: group.trmId,
        },
        privacyPolicy: {
          label: _.get(privacyLabel[0], 'label', 'N/A'),
          value: group.privacyId,
        },
        phishingPolicy: {
          label: _.get(phishingLabel[0], 'label', 'N/A'),
          value: _.get(group, 'phishingPolicyId'),
        },
        dormancyPolicy: {
          label: _.get(dormancyLabel[0], 'label', 'N/A'),
          value: _.get(group, 'dormancyPolicyId'),
        },
        appSettings: {
          label: _.get(appSettingsLabel[0], 'label', 'N/A'),
          value: _.get(group, 'appSettingsId'),
        },
        networkPolicy: {
          label: 'label', // todo connect this it is not shown yet
          value: _.get(group, 'networkPolicyId')
        },
        osRiskPolicy: {
          label: 'label', // todo connect this ... it
          value: _.get(group, 'osRiskPolicyId')
        }
      },
      index,
    };
  });
};

export const cleanMDMTableData = ({
  trmPolicyList,
  privacyPolicyList,
  phishingPolicyList,
  appSettingsPolicyList,
  dormancyList,
  tableData,
}) => {
  return tableData.map((connection) => {
    const connectorTypeDisplayName = _.get(
      connection,
      'connectorType.description',
      'N/A'
    );
    return {
      connectedGroups: cleanGroupsToSync(
        connection.groupsToSync,
        privacyPolicyList,
        trmPolicyList,
        phishingPolicyList,
        dormancyList,
        appSettingsPolicyList
      ),
      connectedActionGroupsToSync: cleanActionGroupsToSync(
        connection.actionGroupsToSync
      ),
      groupsArray: connection.groupsToSync.map((group) => group.mdmGroupId),
      ...connection,
      connectorType: {
        displayName: connectorTypeDisplayName,
        ...connection.connectorType,
      },
    };
  });
};
