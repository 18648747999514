import React, { useEffect } from 'react';
import _ from 'lodash';
import Color from 'color';
import { withStyles } from '@material-ui/core/styles';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { debug } from 'utils/debugUtils';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

// actions
import { fetchZiapConsoleVersion } from 'actions/ActionCreators';

// components
import DashboardRouter from 'routes/DashboardRouter';
import DashboardRoutesWrapper from 'components/wrappers/DashboardRoutesWrapper';

import { PUBLIC_ROUTE_MAPPING } from 'mappings/routes/routeMappings';

import ModalContainer from 'components/modals/ModalContainer';

// workers
import AuthWorker from 'workers/authWorker';
import PartnerDashboardRoutesWrapper from 'components/wrappers/PartnerDashboardWrapper';
import PartnerDashboardRouter from 'routes/PartnerDashboardRouter';
import { DrawerContainer } from 'components/drawer/DrawerContainer';
import AdminDashboardRoutesWrapper from './components/wrappers/AdminDashboardWrapper';
import AdminDashboardRouter from './routes/AdminDashboardRouter';
import SubscriptionPublicRoute from './routes/SubscriptionPublicRoute';
import Subscription from './components/Subscription';
import { setSystemProperties } from './reducers/SystemPropertiesReducers';
import Modals from './components/UI/Modals';
import { useTranslation } from 'react-i18next';
import { ConsoleNotFound } from './console/ConsoleNotFound';
import { useSafeBranding } from 'components/partner/branding/utils';
import moment from 'moment';

const SafeRoute = ({ component: RouteComponent, ...rest }) => (
  <Route render={(props) => <RouteComponent {...props} {...rest} />} />
);

const AppWrapper = (props) => {
  const { i18n } = useTranslation();

  const branding = useSafeBranding();

  // Temporary solution until this wrapper is re-factored to TS/X and the
  // user reducer is ported to Atom. - RL
  useEffect(() => {
    if (
      !!props?.languagePreference &&
      !!i18n?.language &&
      i18n.language.toLowerCase() !== props.languagePreference.toLowerCase()
    ) {
      i18n.changeLanguage(props.languagePreference.toLowerCase());
    }
  }, [i18n, props?.languagePreference]);

  useEffect(() => {
    AuthWorker.init();
    props.fetchZiapConsoleVersion();
    props.setSystemProperties();
    if (props.brandingFavicon) {
      document.getElementById('favicon').href = props.brandingFavicon;
    } else {
      document.getElementById('favicon').href = 'favicon.ico';
    }
    document.title = branding?.consoleTitle;
  }, [props]);

  moment.locale(i18n.language);

  return (
    <BrowserRouter>
      <Route
        component={(routeProps) => {
          if (routeProps.location.pathname === '/') {
            // if redirected from intune or others
            const redirectUrl = localStorage.getItem('redirectUrl');
            if (redirectUrl) {
              window.location.href = `${
                window.location.origin
              }${redirectUrl}${_.get(routeProps, 'location.search', '')}`;

              setTimeout(() => localStorage.removeItem('redirectUrl'), 1000);
            }

            debug('Redirect from App.js: props.location.pathname was /');
            return <Redirect to={props.dashboardRoute} />;
          }

          return (
            <React.Fragment>
              <Switch>
                {PUBLIC_ROUTE_MAPPING.map((route, i) => {
                  return <SafeRoute key={`public-route-${i}`} {...route} />;
                })}
                <PartnerDashboardRouter
                  path="/partner"
                  component={PartnerDashboardRoutesWrapper}
                />
                <AdminDashboardRouter
                  path="/admin"
                  component={AdminDashboardRoutesWrapper}
                />
                <DashboardRouter
                  path="/console"
                  component={DashboardRoutesWrapper}
                />
                <SubscriptionPublicRoute
                  path="/subscription"
                  component={Subscription}
                />
                <Route path="*" component={ConsoleNotFound} />
              </Switch>
              <ModalContainer />
              <Modals />
              <DrawerContainer />
            </React.Fragment>
          );
        }}
      />
    </BrowserRouter>
  );
};

const globalStyles = ({ palette, sizing, shape, ...theme }) => {
  const primaryText = palette.text.primary;
  const secondaryText = palette.text.secondary;
  return {
    '@global': {
      body: {
        background: palette.background.default,
        color: primaryText,
        fontFamily: theme.typography.fontFamily,
        '-webkit-font-smoothing': 'antialiased',
      },

      a: {
        color: secondaryText,
        textDecoration: 'none',
      },

      h1: {
        fontSize: theme.config.textSizes.canon,
        fontWeight: theme.typography.fontWeightLight,
        color: palette.text.secondary,
        '-webkit-font-smoothing': 'antialiased',
        fontSmooth: 'always',
      },

      h2: {
        fontSize: theme.config.textSizes.paragon,
        color: palette.text.secondary,
      },

      '.list--unstyled': {
        paddingLeft: 0,
        margin: 0,
        listStyle: 'none',
      },

      '.list-item--flex': {
        display: 'flex',
        alignItems: 'center',
      },

      '.roboto-light': {
        fontWeight: theme.typography.fontWeightRegular,
        '-webkit-font-smoothing': 'antialiased',
        fontSmooth: 'always',
      },

      '.inline-flex': {
        display: 'inline-flex',
      },
      '.text-capitalize': {
        textTransform: 'lowercase',
        '&:first-letter': {
          textTransform: 'uppercase',
        },
      },
      '.flex--spaced': {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      '.flex--column--spaced': {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
      '.flex--start': {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
      },
      '.view__header': {
        display: 'flex',
        padding: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline',

        '& > div': {
          display: 'flex',
          alignItems: 'center',
        },
      },

      '.main-root-wrapper': {
        width: '100%',
        minHeight: '100%',
        background: palette.background.paper,
      },

      '.main-root-container': {
        display: 'flex',
        width: '100%',
        minHeight: '100%',
        paddingTop: 61,
        background: palette.background.paper,
      },

      '.main-content__container': {
        flexBasis: '100%',
        flexGrow: 1,
        padding: '0 20px 20px 20px',
        background: palette.background.paper,
        paddingLeft: sizing.sidebar.maxWidth + 20,
      },

      '.main-content': {
        display: 'flex',
        flex: 1,
        flexGrow: 1,
        flexBasis: '100%',
        flexDirection: 'column',
        overflowY: 'auto',

        '& > *': {
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'flex-start',
          paddingTop: 20,
        },
      },
      // entry label
      '.entry-label': {
        color: palette.text.secondary,
        fontWeight: 600,
        paddingTop: 10,
      },

      '.select--button': {
        background: palette.background.default,
        border: `1px solid ${palette.common.lightBlueGrey}`,
        borderRadius: shape.borderRadius,
        color: palette.text.primary,
        fontSize: theme.typography.fontSize,

        '&:before': {
          display: 'none',
        },

        '&:after': {
          display: 'none',
        },
      },
      '.tabs-label': {
        textTransform: 'none',
        color: palette.text.secondary,
        minWidth: '120px',
        width: 'min-content',
      },
      '.table__os-icon': {
        color: palette.text.primary,
        fill: palette.text.primary,
      },

      '.table-row__expandable:hover': {
        cursor: 'pointer',
      },

      '.table-cell__ellipsis': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
      },
      '.text__ellipsis, .overflow__ellipsis': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '.cardtable-cell__ellipsis': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },

      '.is-loading.shimmer': {
        position: 'relative',
        overflowX: 'hidden',
        background: palette.background.default,

        '&:before': {
          content: '""',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '0',
          left: '0',
          backgroundColor: palette.background.default,
          background: `linear-gradient(to right, transparent 1%, ${palette.tables.shimmer} 18%, transparent 40%)`,
          transform: 'translateX(-50%)',

          animationDuration: '1.75s',
          animationFillMode: 'forwards',
          animationIterationCount: 'infinite',
          animationName: 'block-shimmer',
        },

        '& > *': {
          opacity: 0,
        },
      },
      '.is-loading.shimmer-paper': {
        position: 'relative',
        overflowX: 'hidden',
        background: palette.background.paper,

        '&:before': {
          content: '""',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '0',
          left: '0',
          backgroundColor: palette.background.paper,
          background: `linear-gradient(to right, transparent 1%, ${palette.tables.shimmer} 18%, transparent 40%)`,
          transform: 'translateX(-50%)',

          animationDuration: '1.75s',
          animationFillMode: 'forwards',
          animationIterationCount: 'infinite',
          animationName: 'block-shimmer',
        },

        '& > *': {
          opacity: 0,
        },
      },

      '.input-group': {
        marginTop: 15,
      },

      '.or-divider': {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',

        '&:before': {
          content: '""',
          width: '45%',
          height: 1,
          background: palette.text.primary,
          marginRight: 10,
        },

        '&:after': {
          content: '""',
          width: '45%',
          height: 1,
          background: palette.text.primary,
          marginLeft: 10,
        },
      },

      '.accordion-header__checkbox': {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
      },

      '.accordion-header__checkbox__label': {
        margin: 0,
      },
      '.cursor--pointer': {
        '&:hover': {
          cursor: 'pointer',
        },
      },
      '.hover--link': {
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      '.link--primary': {
        cursor: 'pointer',
        color: palette.primary.main,

        '&:hover': {
          textDecoration: 'underline',
        },
      },
      '[class*="callout--"]': {
        display: 'inline-flex',
        padding: 7,
        borderRadius: 3,
      },
      '.callout--warning': {
        background: theme.config.colors.lightCanery,
        color: theme.config.colors.darkCanery,

        '& a': {
          color: Color(theme.config.colors.darkCanery).darken(0.35).string(),
        },
      },
      '.callout--primary': {
        background: palette.primary.main,
        color: palette.primary.contrastText,
      },
      '.pill': {
        padding: 5,
        minWidth: 61,
        display: 'inline-block',
        textAlign: 'center',
        borderRadius: 3,
        borderWidth: 1,
        borderStyle: 'solid',

        '&.low': {
          borderColor: theme.config.colors.low,
          backgroundColor: theme.config.colors.low,
          color: theme.config.colors.white,
        },
        '&.moderate': {
          borderColor: theme.config.colors.moderate,
          backgroundColor: theme.config.colors.moderate,
          color: theme.config.colors.white,
        },
        '&.substantial': {
          borderColor: theme.config.colors.substantial,
          backgroundColor: theme.config.colors.substantial,
          color: theme.config.colors.white,
        },
        '&.elevated': {
          borderColor: theme.config.colors.elevated,
          backgroundColor: theme.config.colors.elevated,
          color: theme.config.colors.white,
        },
        '&.critical': {
          borderColor: theme.config.colors.critical,
          backgroundColor: theme.config.colors.critical,
          color: theme.config.colors.white,
        },
        '&.neutral': {
          borderColor: primaryText,
          backgroundColor: 'transparent',
          color: primaryText,
        },
        '&.best-practices': {
          borderColor: theme.config.colors.bestPractices,
          backgroundColor: theme.config.colors.bestPractices,
          color: theme.config.colors.white,
        },
      },
      '.fullwidth': {
        width: '100%',
      },
      '.inovua-react-toolkit-menu__scroll-container': {
        color: palette.text.secondary,
        backgroundColor: palette.background.paper,
      },
      '.InovuaReactDataGrid--theme-default-light.InovuaReactDataGrid--show-hover-rows .InovuaReactDataGrid__row--odd:not(.InovuaReactDataGrid__row--scrolling):not(.InovuaReactDataGrid__row--empty) .InovuaReactDataGrid__row-hover-target:hover':
        {
          backgroundColor: palette.tables.selectedRow,
        },
      '.InovuaReactDataGrid--theme-default-light.InovuaReactDataGrid--show-hover-rows .InovuaReactDataGrid__row--even:not(.InovuaReactDataGrid__row--scrolling):not(.InovuaReactDataGrid__row--empty) .InovuaReactDataGrid__row-hover-target:hover':
        {
          backgroundColor: palette.tables.selectedRow,
        },
      '.InovuaReactDataGrid__cell': {
        backgroundColor: 'transparent',
        color: palette.text.primary,
      },
      '.InovuaReactDataGrid__cell__content': {
        display: 'flex',
      },
      '.InovuaReactDataGrid__column-header__content': {
        color: palette.text.secondary,
      },
      '.inovua-react-pagination-toolbar__region': {
        color: palette.text.primary,
      },
      '.inovua-react-pagination-toolbar.inovua-react-pagination-toolbar--theme-default-light .inovua-react-pagination-toolbar__page-size-combo':
        {
          backgroundColor: 'transparent',
          color: palette.text.primary,
        },
      '.InovuaReactDataGrid--theme-default-light .inovua-react-pagination-toolbar.inovua-react-pagination-toolbar--theme-default-light':
        {
          backgroundColor: 'transparent',
        },
      '.InovuaReactDataGrid__row--odd': {
        backgroundColor: 'transparent',
      },
      '.InovuaReactDataGrid__row--even': {
        backgroundColor: 'transparent',
      },
      '.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__column-header__filter-wrapper':
        {
          borderTop: 'none',
          backgroundColor: 'transparent',
        },
      '.InovuaReactDataGrid__row--last .InovuaReactDataGrid__cell': {
        borderBottom: 'none',
      },
      '.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__row--odd':
        {
          backgroundColor: 'transparent',
        },
      '.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__row--even':
        {
          backgroundColor: 'transparent',
        },
      '.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__row--odd.InovuaReactDataGrid__row--selected':
        {
          backgroundColor: palette.tables.selectedRow,
        },
      '.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__row--even.InovuaReactDataGrid__row--selected':
        {
          backgroundColor: palette.tables.selectedRow,
        },
      '.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__row.InovuaReactDataGrid__row--show-horizontal-borders .InovuaReactDataGrid__row-details':
        {
          backgroundColor: 'transparent',
        },
      '.InovuaReactDataGrid--theme-default-light': {
        backgroundColor: 'transparent',
        border: 'none',
        margin: '16px',
        width: 'calc(100% - 32px) !important',
      },
      '.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__header':
        {
          backgroundColor: 'transparent',
        },
      '.InovuaReactDataGrid--theme-default-light.InovuaReactDataGrid--show-border-right.InovuaReactDataGrid--direction-ltr .InovuaReactDataGrid__cell--last:not(.InovuaReactDataGrid__cell--hidden)':
        {
          borderRight: 'none',
        },
      '.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__column-header__resize-wrapper--show-border-right':
        {
          borderRight: 'none',
        },
      '.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__column-header__resize-wrapper--show-border-left':
        {
          borderColor: palette.text.primary,
        },
      '.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__header-wrapper__fill':
        {
          backgroundColor: 'transparent',
        },
      '.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__header-wrapper__fill__filters':
        {
          backgroundColor: 'transparent',
          borderTop: 'none',
        },
      '.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__column-header--show-border-right':
        {
          borderRight: 'none',
        },
      '.inovua-react-pagination-toolbar--theme-default-light > div > div > div':
        {
          display: 'flex',
          'align-items': 'flex-end',
        },
      '.inovua-react-pagination-toolbar--theme-default-light > div > div > div > .inovua-react-scroll-container__view:first-child':
        {
          top: '2px',
        },
      '.InovuaReactDataGrid__column-header__filter-settings': {
        display: 'none',
      },
    },
  };
};

const mapStateToProps = (state) => {
  let userLangugaePreference = state?.user?.languagePreference;
  const accountPreference = state?.user?.account?.languagePreference ?? 'en-us';
  const isSuperUser = state?.auth?.superUser.isAuthenticated;
  // if a super user comes in  --- the language should always and ONLY be en-us
  if (isSuperUser) {
    userLangugaePreference = 'en-us';
  }
  return {
    consoleVersion: state.uiSettings.version,
    dashboardRoute: _.get(state, 'auth.dashboardRoute', '/login'),
    brandingFavicon: _.get(state, 'auth.branding.faviconUrl'),
    languagePreference: userLangugaePreference ?? accountPreference,

    // isAuthenticated: _.get(state, 'auth.user.isAuthenticated'),
    // hasSuperAccessToken: Boolean(
    //   _.get(state, 'auth.superUser.accessToken', false)
    // ),
    // hasPartnerAccessToken: Boolean(
    //   _.get(state, 'auth.partnerUser.accessToken', false)
    // ),
    // hasUserAccessToken: Boolean(_.get(state, 'auth.user.accessToken')),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchZiapConsoleVersion,
      setSystemProperties,
    },
    dispatch
  );
};

export default compose(
  withStyles(globalStyles),
  connect(mapStateToProps, mapDispatchToProps)
)(AppWrapper);
