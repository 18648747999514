import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import {
  deleteSmtpSettings,
  fetchSmtpSettings,
  saveSmtpSettings,
  sendTestEmail,
  testSmtpSettingsConnection,
} from 'api/EmailService';
import { openSnackBar } from 'utils/storeUtils';

// ui
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormikCheckbox } from 'components/inputs/FormikCheckbox';
import { FormikPasswordField } from 'components/inputs/FormikPasswordField';
import { FormikRadioGroup } from 'components/inputs/FormikRadioGroup';
import { FormikTextField } from 'components/inputs/FormikTextField';
import Grid from '@material-ui/core/Grid';
import {
  csvInjectionRegex,
  csvInjectionErrorMessage,
  looksGenerallyLikeAnEmailRegex,
  looksGenerallyLikeAnEmailRegexErrorMessage,
} from 'utils/componentUtils';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import ZButton from 'UI/Buttons/ZButton';

const protocolOptions = [
  { value: 'NONE', label: 'None' },
  { value: 'SSL', label: 'SSL' },
  { value: 'TLS', label: 'TLS' },
];

const initialState = {
  host: '',
  port: '',
  username: '',
  password: '',
  enabled: true,
  protocol: 'NONE',
};

const SmtpSettings = (props) => {
  const [settings, setSettings] = useState({});
  useEffect(() => {
    fetchSmtpSettings()
      .then((resp) => setSettings(resp.data))
      .catch(() =>
        openSnackBar('Unable to fetch account SMTP settings, please try again.')
      );
  }, [setSettings]);

  return <SmtpSettingsForm {...props} settings={settings} />;
};

const SmtpSettingsForm = ({ classes, settings }) => {
  const [editMode, setEditMode] = useState(false);
  const [testConnectionPending, setTestConnectionPending] = useState(false);
  const [testEmailPending, setEmailPending] = useState(false);
  function onSubmit(values, actions) {
    setEditMode(false);

    saveSmtpSettings({}, { ...values })
      .then(() =>
        openSnackBar('Successfully saved SMTP settings for this account.')
      )
      .catch(() =>
        openSnackBar(
          'Unable to save SMTP settings for this account, please check your settings.'
        )
      );

    actions.setSubmitting(false);
  }

  function handleDelete(resetForm) {
    deleteSmtpSettings()
      .then(() => {
        openSnackBar('SMTP settings have been deleted.');
        resetForm(initialState);
      })
      .catch(() =>
        openSnackBar('Unable to delete SMTP settings, please try again.')
      );
  }

  function handleTestConnection({ host, port, username, password, protocol }) {
    const payload = { host, port, username, password, protocol };
    setTestConnectionPending(true);
    testSmtpSettingsConnection({}, payload)
      .then(() => {
        openSnackBar(`Successful connection using provided SMTP settings.`);
        setTestConnectionPending(false);
      })
      .catch(() => {
        openSnackBar(
          'Unable to connect using provided SMTP settings, please check your settings.'
        );
        setTestConnectionPending(false);
      });
  }

  function handleTestEmail({ email, fromEmail }, setFieldError) {
    if (_.isEmpty(email)) {
      setFieldError(
        'email',
        'Please enter an email for testing SMTP settings.'
      );

      return;
    }
    setEmailPending(true);
    sendTestEmail({}, { email, fromEmail })
      .then(() => {
        openSnackBar(
          `Test email has sent to ${email}, please check your email to verify your SMTP settings.`
        );
        setEmailPending(false);
      })
      .catch(() => {
        openSnackBar(
          'Unable to send test email using provided SMTP settings, please check your settings.'
        );
        setEmailPending(false);
      });
  }

  return (
    <div>
      <div className="view__header">
        <h1>SMTP Settings</h1>
      </div>
      <Card>
        <CardContent>
          <Formik
            initialValues={{
              ...initialState,
              enabled: _.get(settings, 'enabled', initialState.enabled),
              protocol: _.get(settings, 'protocol', initialState.protocol),
              ...settings,
            }}
            onSubmit={onSubmit}
            validationSchema={SmtpSettingsSchema}
            enableReinitialize
          >
            {({ dirty, values, setFieldError, resetForm }) => {
              return (
                <Form>
                  <Grid container justify="space-between" spacing="1">
                    <Grid item style={{ width: '49%' }}>
                      <Field
                        disabled={!editMode}
                        name="host"
                        label="Host"
                        component={FormikTextField}
                      />
                      <Field
                        disabled={!editMode}
                        name="port"
                        label="Port"
                        component={FormikTextField}
                      />
                      <Field
                        disabled={!editMode}
                        name="username"
                        label="Username"
                        component={FormikTextField}
                        autoComplete="off"
                        placeholder=""
                      />
                      <Field
                        disabled={!editMode}
                        name="password"
                        label="Password (enter ONLY if updating password)"
                        component={FormikPasswordField}
                        autoComplete="new-password"
                        placeholder=""
                      />
                      <Field
                        disabled={!editMode}
                        name="protocol"
                        label="Protocol"
                        value={values.protocol}
                        component={FormikRadioGroup}
                        options={protocolOptions}
                      />
                      <Field
                        disabled={!editMode}
                        name="enabled"
                        label="Enabled"
                        component={FormikCheckbox}
                        type="checkbox"
                        checked={values.enabled}
                      />
                      <div
                        style={{
                          marginTop: 15,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <ProtectedComponent allow={{ account: 'manage' }}>
                          {!editMode && (
                            <Fragment>
                              <EditIcon
                                className={classes.actionIcon}
                                onClick={() => setEditMode(!editMode)}
                              />
                              <DeleteIcon
                                className={classes.actionIcon}
                                onClick={() => handleDelete(resetForm)}
                              />
                            </Fragment>
                          )}
                          {editMode && (
                            <Fragment>
                              <ZButton
                                buttonText="Cancel"
                                color="secondary"
                                onClick={() => setEditMode(false)}
                              />
                              <ZButton
                                buttonType="submit"
                                buttonText="Save SMTP Settings"
                                color="primary"
                                styleName="modalSave"
                              />
                            </Fragment>
                          )}
                        </ProtectedComponent>
                      </div>
                    </Grid>
                    <Grid item style={{ width: '49%' }}>
                      <Field
                        disabled={editMode}
                        name="email"
                        label="To Email"
                        component={FormikTextField}
                        classList={{ errorClass: classes.errorClass }}
                      />
                      <Field
                        disabled={editMode}
                        name="fromEmail"
                        label="From Email"
                        component={FormikTextField}
                        autoComplete="off"
                        classList={{ errorClass: classes.errorClass }}
                      />
                      <div style={{ marginTop: 15 }}>
                        <ZButton
                          action={() => handleTestConnection(values)}
                          buttonText={
                            <div
                              style={{
                                paddingLeft: testConnectionPending ? 12 : 0,
                              }}
                            >
                              {!testConnectionPending
                                ? 'Test Connection'
                                : 'Testing'}
                            </div>
                          }
                          color="secondary"
                          styleName="titleBar"
                          isDisabled={testConnectionPending}
                          icon={{
                            elem: () => {
                              if (testConnectionPending) {
                                return (
                                  <CircularProgress
                                    size={15}
                                    styles={{ paddingRight: 15 }}
                                  />
                                );
                              }
                              return null;
                            },
                          }}
                        />
                        <ZButton
                          action={() => handleTestEmail(values, setFieldError)}
                          buttonText={
                            <div
                              style={{
                                paddingLeft: testEmailPending ? 12 : 0,
                              }}
                            >
                              {!testEmailPending ? 'Test Email' : 'Testing'}
                            </div>
                          }
                          color="secondary"
                          styleName="titleBar"
                          isDisabled={testEmailPending}
                          icon={{
                            elem: () => {
                              if (testEmailPending) {
                                return (
                                  <CircularProgress
                                    size={15}
                                    styles={{ paddingRight: 15 }}
                                  />
                                );
                              }
                              return null;
                            },
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </CardContent>
      </Card>
    </div>
  );
};

const SmtpSettingsSchema = Yup.object().shape({
  host: Yup.string(),
  port: Yup.number(),
  username: Yup.string().nullable(),
  password: Yup.string().nullable(),
  enabled: Yup.boolean(),
  email: Yup.string()
    .matches(
      looksGenerallyLikeAnEmailRegex,
      looksGenerallyLikeAnEmailRegexErrorMessage
    )
    .matches(csvInjectionRegex, csvInjectionErrorMessage),
});

const styles = ({ palette, shape }) => ({
  root: {
    flexGrow: 1,
  },
  actionIcon: {
    cursor: 'pointer',
  },
  errorClass: {
    minWidth: 150,
    padding: '7px 10px',
    marginTop: 19,
    marginLeft: 10,
    position: 'absolute',
    zIndex: 1,
    transform: 'translateY(-50%)',
    background: palette.error.light,
    color: palette.error.contrastText,
    borderRadius: shape.borderRadius,
  },
});

export default compose(withStyles(styles))(SmtpSettings);
