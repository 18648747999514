import { createStyles } from '@material-ui/core';

const styles = ({ config }: any) =>
  createStyles({
    green: {
      background: config.colors.green,
    },
    iconWrapper: {
      display: 'inline-block',
      paddingRight: '5px',
      verticalAlign: 'middle',
    },
    orange: {
      background: config.colors.elevated,
    },
    osWrapper: {
      display: 'inline-block',
      marginRight: '5px',
      verticalAlign: 'middle',
    },
    red: {
      background: config.colors.critical,
    },
    statusCircle: {
      borderRadius: '50%',
      display: 'flex',
      height: 12,
      marginRight: 12,
      width: 12,
    },
    tableWrapper: {
      paddingTop: '20px',
    },
  });

export default styles;
