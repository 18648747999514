import { TabProps as TMUITabProps } from '@material-ui/core';
import { default as MUITab } from '@material-ui/core/Tab';
import { default as MUITabs } from '@material-ui/core/Tabs';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import React from 'react';
import { Link } from 'react-router-dom';
import ProtectedRoute from 'routes/ProtectedRoute';
import { ILinkTabBase, TLinkTab } from './models';
import useStyles from './useStyles';

export type TLinkTabProps = ILinkTabBase & TMUITabProps;

const LinkTab: React.FC<TLinkTabProps> = ({
  allow,
  classes,
  label,
  to,
  value,
}) => {
  return (
    <ProtectedComponent allow={allow}>
      <MUITab
        allow={allow}
        classes={classes}
        component={Link as React.ElementType}
        disableRipple
        label={label}
        to={to}
        value={value}
      />
    </ProtectedComponent>
  );
};

export interface ILinkTabsProps {
  hideTabs?: boolean;
  tabs: TLinkTab[];
  value: string;
  allowedScope: string;
}

const LinkTabs: React.FC<ILinkTabsProps> = ({
  hideTabs,
  tabs,
  value,
  allowedScope,
}) => {
  const tabSetClasses = useStyles();
  const selectedTab = tabs.find((tab) => tab.value === value);

  return (
    <>
      {!hideTabs && (
        <MUITabs value={value}>
          {tabs.map((tab, idx) => {
            const {
              allow,
              classes: tabClasses,
              disabled,
              label,
              to,
              value,
            } = tab;

            return (
              <LinkTab
                allow={allow}
                classes={{ ...tabSetClasses, ...tabClasses }}
                disabled={!!disabled}
                key={`tab-${idx}`}
                label={label}
                to={to}
                value={value}
              />
            );
          })}
        </MUITabs>
      )}
      {!!selectedTab?.component && (
        <ProtectedRoute
          allow={{ [`${allowedScope}`]: 'view' }}
          component={selectedTab.component}
          rqps={['page', 'size', 'orderBy', 'order']}
        />
      )}
    </>
  );
};

export default LinkTabs;
