import { Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { IProtectionCoverage } from './models';
import { IzShieldOSType } from '../ZShieldApps/models';

export const PROTECTION_COVERAGE_FIELDS: IProtectionCoverage[] = [
  {
    id: 1,
    displayName: 'Static DEX encryption',
    fieldName: 'staticDexEncryption',
    tooltip: 'Encrypts most of the .dex files of your app to provide protection against static analysis. It means that these files will be stored in encrypted form and decrypted only at runtime when they are used. Note that some common Android and Kotlin packages will not be encrypted.',
    osType: IzShieldOSType.ANDROID
  },
  {
    id: 2,
    fieldName: 'resourceEncryption',
    displayName: 'Resource encryption',
    tooltip: 'Encrypts most resources in your app’s assets directory, such as text, XML, image, video, and music files. These protected resources will be stored in encrypted form and decrypted only at runtime when they are actually used.',
    osType: IzShieldOSType.ANDROID
  },
  {
    id: 3,
    displayName: 'Signature verification',
    fieldName: 'signatureVerification',
    tooltip: 'Protects the app against tampering, including re-signing with a different key, which is often done by hackers to distribute the app illegally.',
    osType: IzShieldOSType.ANDROID
  },

  {
    id: 4,
    displayName: 'Runtime Protection',
    fieldName: 'runtimeProtection',
    tooltip: 'Secure with Group Policies',
    osType: IzShieldOSType.ANDROID
  },
];

export const PROTECTION_COVERAGE_FIELDS_IOS: IProtectionCoverage[] = [
  {
    id: 1,
    displayName: 'Metadata encryption',
    fieldName: 'metadataEncryption',
    tooltip: 'Encrypts app metadata to hide sensitive information from reverse engineering. The encrypted data will be decrypted only once the protected app is loaded.',
    osType: IzShieldOSType.IOS
  },
  {
    id: 2,
    fieldName: 'signatureVerification',
    displayName: 'Signature encryption',
    tooltip: 'Prevents app cloning and re-signing attacks.',
    osType: IzShieldOSType.IOS
  },
  {
    id: 3,
    displayName: 'Code Obfuscation',
    fieldName: 'codeObfuscation',
    tooltip: 'Transforms code so that it becomes more difficult to analyze by various hacker tools.',
    osType: IzShieldOSType.IOS
  },
  {
    id: 4,
    displayName: 'Runtime Protection',
    fieldName: 'runtimeProtection',
    tooltip: 'Secure with Group Policies',
    osType: IzShieldOSType.IOS
  },
];

export const ProtectionLabel = ({ displayName, tooltip }: { displayName: string, tooltip: string; }) => {
  return (
    <div className='flex--start'>
      <div>{displayName}</div>
      <Tooltip
        style={{ zIndex: '1301' }}
        arrow
        enterDelay={300}
        placement="left"
        title={<span style={{ fontSize: 12 }}>{tooltip}</span>}
      >
        <InfoOutlined color="primary" style={{ marginLeft: 3, padding: 3 }} />
      </Tooltip>
    </div>
  );
};