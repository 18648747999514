import cc from 'classcat';
import GenericCard from 'components/UI/GenericCard';
import React, { useMemo } from 'react';
import useAsyncResult from 'utils/useAsyncResult';
import { default as useSharedStyles } from '../../useStyles';

import LiteralProportionChart from 'components/UI/Chart/LiteralProportion';
import { IPotentialFraudIndicators } from './models';
import useStyles from './useStyles';
import { fetchPotentialFraud } from './utils';

interface IPotentialFraudIndicatorsProps {
  teamId: string | null | undefined;
  zappId: string | null | number | undefined;
}

const PotentialFraudIndicators: React.FC<IPotentialFraudIndicatorsProps> = ({
  teamId,
  zappId,
}) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  const potentialFraudIndicators = useAsyncResult<IPotentialFraudIndicators>(
    fetchPotentialFraud,
    teamId ?? '',
    zappId ?? ''
  );

  const baselineOverride = useMemo(
    () =>
      Math.min(
        ...[
          ...(potentialFraudIndicators?.accountTakeoverCounts?.map(
            ({ count }) => count
          ) ?? []),
          ...(potentialFraudIndicators?.newAccountFraudCounts?.map(
            ({ count }) => count
          ) ?? []),
          ...(potentialFraudIndicators?.piiDataLossCounts?.map(
            ({ count }) => count
          ) ?? []),
        ]
      ),
    [potentialFraudIndicators]
  );

  const ceilingOverride = useMemo(
    () =>
      Math.max(
        ...[
          ...(potentialFraudIndicators?.accountTakeoverCounts?.map(
            ({ count }) => count
          ) ?? []),
          ...(potentialFraudIndicators?.newAccountFraudCounts?.map(
            ({ count }) => count
          ) ?? []),
          ...(potentialFraudIndicators?.piiDataLossCounts?.map(
            ({ count }) => count
          ) ?? []),
        ]
      ),
    [potentialFraudIndicators]
  );

  const getThreatsPageLink = (
    threatTypeIds: number[],
    zappId: null | number | undefined | string
  ): string =>
    `/console/zdefend/threats/?duration=8&zappId=${zappId ?? 'All'}${threatTypeIds?.length
      ? `&threatTypeId=${threatTypeIds.join('&threatTypeId=')}`
      : ''
    }`;

  const accountTakeover = useMemo(
    () =>
      (potentialFraudIndicators?.accountTakeoverCounts ?? []).map(
        ({ category, count, threatTypeIds }) => ({
          backgroundColor: '#ffd464',
          color: 'black',
          count,
          label: category,
          link: getThreatsPageLink(threatTypeIds, zappId),
          suffix: '',
        })
      ),
    [potentialFraudIndicators, zappId]
  );

  const newAccountFraud = useMemo(
    () =>
      (potentialFraudIndicators?.newAccountFraudCounts ?? []).map(
        ({ category, count, threatTypeIds }) => ({
          backgroundColor: '#fc6f93',
          color: 'black',
          count,
          label: category,
          link: getThreatsPageLink(threatTypeIds, zappId),
          suffix: '',
        })
      ),
    [potentialFraudIndicators, zappId]
  );

  const piiDataLoss = useMemo(
    () =>
      (potentialFraudIndicators?.piiDataLossCounts ?? []).map(
        ({ category, count, threatTypeIds }) => ({
          backgroundColor: '#cc6152',
          count,
          label: category,
          link: getThreatsPageLink(threatTypeIds, zappId),
          suffix: '',
        })
      ),
    [potentialFraudIndicators, zappId]
  );

  const hasData = useMemo(
    () =>
      accountTakeover.length || newAccountFraud.length || piiDataLoss.length,
    [accountTakeover, newAccountFraud, piiDataLoss]
  );

  return (
    <GenericCard
      className={cc([sharedClasses.card, classes.potentialFraudIndicators])}
    >
      <div className={sharedClasses.column}>
        <h2>Threat Count - Potential Fraud Indicators</h2>
        {hasData ? (
          <div className={classes.sections}>
            <div className={classes.section}>
              <h2>Account Takeover</h2>
              {accountTakeover?.length ? (
                <LiteralProportionChart
                  baselineOverride={baselineOverride}
                  ceilingOverride={ceilingOverride}
                  columns={accountTakeover}
                  showSingleMetric
                />
              ) : (
                <div className={sharedClasses.pending}>N/A</div>
              )}
            </div>
            <div className={classes.section}>
              <h2>New Account Fraud</h2>
              {newAccountFraud?.length ? (
                <LiteralProportionChart
                  baselineOverride={baselineOverride}
                  ceilingOverride={ceilingOverride}
                  columns={newAccountFraud}
                  showSingleMetric
                />
              ) : (
                <div className={sharedClasses.pending}>N/A</div>
              )}
            </div>
            <div className={classes.section}>
              <h2>PII Data Loss</h2>
              {piiDataLoss?.length ? (
                <LiteralProportionChart
                  baselineOverride={baselineOverride}
                  ceilingOverride={ceilingOverride}
                  columns={piiDataLoss}
                  showSingleMetric
                />
              ) : (
                <div className={sharedClasses.pending}>N/A</div>
              )}
            </div>
          </div>
        ) : (
          <div className={sharedClasses.pending}>N/A</div>
        )}
      </div>
    </GenericCard>
  );
};

export default PotentialFraudIndicators;
