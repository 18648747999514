import GenericTable from 'components/UI/GenericTable';
import { ISelectItem } from 'components/UI/input/Select';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  mdmColumnHeaders,
  mtdTRMPolicyTableColumnHeaders,
} from './TRMPolicy.mappings';
import AlertUser from './TableComponents/AlertUser';
import Enabled from './TableComponents/Enabled';
import MdmActions from './TableComponents/MdmActions';
import MdmMitigations from './TableComponents/MdmMitigations';
import OperatingSystems from './TableComponents/OperatingSystems';
import SelectColumn from './TableComponents/SelectColumn';
import SeveritySelect from './TableComponents/SeveritySelect';
import SubscribeToNotifications from './TableComponents/SubscribeToNotifications';
import TypeColumn from './TableComponents/TypeColumn';
import MtdDeviceActions from './TableComponents/MtdDeviceActions';
import {
  IActions,
  IOSResponseTypes,
  ISelectTRMPolicyTableData,
  PolicyChangeOptions,
} from './models';

interface IMtdTRMPolicyTableProps {
  tableData: ISelectTRMPolicyTableData[];
  emmConnections: boolean;
  handleTRMPolicyRuleChange: (
    optionName: PolicyChangeOptions,
    value: unknown,
    rule: ISelectTRMPolicyTableData | string
  ) => void;
  selectedTRMPolicy: ISelectItem | undefined;
  mdmThreatActions: IActions[] | undefined;
  mdmMitigationActions: IActions[] | undefined;
  osResponseTypes: IOSResponseTypes | undefined;
  onNotificationChange: (rule: ISelectTRMPolicyTableData) => void;
}

const MtdTRMPolicyTable = ({
  tableData,
  emmConnections,
  handleTRMPolicyRuleChange,
  selectedTRMPolicy,
  mdmThreatActions,
  mdmMitigationActions,
  osResponseTypes,
  onNotificationChange,
}: IMtdTRMPolicyTableProps) => {
  const { t } = useTranslation();
  const [currentHeaders, setCurrentHeaders] = useState(
    mtdTRMPolicyTableColumnHeaders(t)
  );

  const setColumnHeaders = useCallback(() => {
    const headers = [...mtdTRMPolicyTableColumnHeaders(t)];
    if (emmConnections) {
      headers.splice(6, 0, ...mdmColumnHeaders(t));
      setCurrentHeaders(headers);
    } else {
      setCurrentHeaders(headers);
    }
  }, [emmConnections, t]);

  useEffect(() => {
    setColumnHeaders();
  }, [setColumnHeaders]);

  const transformVector = (
    vector: string | number | boolean | object | null | undefined
  ) => {
    if (typeof vector == 'string') {
      if (vector.toLowerCase() == 'network') {
        return t('GLOBAL.NETWORK');
      } else if (vector.toLowerCase() == 'device') {
        return t('GLOBAL.DEVICE');
      } else if (vector.toLowerCase() == 'malware') {
        return t('GLOBAL.MALWARE');
      }
    }
  };

  return (
    <GenericTable
      columnHeaders={[
        {
          id: 'select',
          isSelectAllCheckbox: true,
          onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            handleTRMPolicyRuleChange(
              PolicyChangeOptions.EnableCheckbox,
              e.target.checked,
              'all'
            );
          },
        },
        ...currentHeaders,
      ]}
      rowMapping={[
        {
          path: 'select',
          columnContent: ({ rowData }) => (
            <SelectColumn
              rowData={rowData as unknown as ISelectTRMPolicyTableData}
              handleTRMPolicyRuleChange={handleTRMPolicyRuleChange}
            />
          ),
        },
        {
          path: 'vector',
          columnContent: ({ rowData }) => (
            <span>{transformVector(rowData?.vector)}</span>
          ),
        },
        {
          path: 'type',
          columnContent: ({ rowData }) => (
            <TypeColumn
              rowData={rowData as unknown as ISelectTRMPolicyTableData}
            />
          ),
        },
        {
          path: 'enabled',
          columnContent: ({ rowData }) => (
            <Enabled
              rowData={rowData as unknown as ISelectTRMPolicyTableData}
              handleTRMPolicyRuleChange={handleTRMPolicyRuleChange}
            />
          ),
        },
        {
          path: 'alertUser',
          columnContent: ({ rowData }) => (
            <AlertUser
              rowData={rowData as unknown as ISelectTRMPolicyTableData}
              handleTRMPolicyRuleChange={handleTRMPolicyRuleChange}
              showToggle
            />
          ),
        },
        {
          path: 'platform',
          columnContent: ({ rowData }) => (
            <OperatingSystems
              rowData={rowData as unknown as ISelectTRMPolicyTableData}
            />
          ),
        },
        {
          path: 'severity',
          columnContent: ({ rowData }) => (
            <SeveritySelect
              rowData={rowData as unknown as ISelectTRMPolicyTableData}
              handleTRMPolicyRuleChange={handleTRMPolicyRuleChange}
            />
          ),
        },
        {
          path: 'deviceActions',
          columnContent: ({ rowData }) => (
            <MtdDeviceActions
              rowData={rowData as unknown as ISelectTRMPolicyTableData}
              osResponseTypes={osResponseTypes}
              handleTRMPolicyRuleChange={handleTRMPolicyRuleChange}
            />
          ),
        },
        {
          path: 'notifications',
          columnContent: ({ rowData }) => (
            <SubscribeToNotifications
              rule={rowData as unknown as ISelectTRMPolicyTableData}
              selectedTRMPolicy={selectedTRMPolicy}
              onNotificationChange={onNotificationChange}
            />
          ),
        },
        {
          path: 'mdmActions',
          columnContent: ({ rowData }) => (
            <MdmActions
              rowData={rowData as unknown as ISelectTRMPolicyTableData}
              handleTRMPolicyRuleChange={handleTRMPolicyRuleChange}
              mdmThreatActions={mdmThreatActions}
            />
          ),
        },
        {
          path: 'mdmMitigations',
          columnContent: ({ rowData }) => (
            <MdmMitigations
              rowData={rowData as unknown as ISelectTRMPolicyTableData}
              handleTRMPolicyRuleChange={handleTRMPolicyRuleChange}
              mdmMitigationActions={mdmMitigationActions}
            />
          ),
        },
      ]}
      tableData={tableData ? (tableData as any) : []}
      tableId="Mtd TRM Policy Table"
    />
  );
};

export default MtdTRMPolicyTable;
