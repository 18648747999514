//Review file: if it is no longer in use delete
import { getActiveModalAtom } from 'atoms/modals';
import { ISelectItem } from 'components/UI/input/Select';
import Modal from 'components/UI/Modal';
import { useRecoilState } from 'recoil';
import Form from './Form';
import { IDormancyCreateModalData } from './models';
import { getHandleCloneFn, getHandleCreateFn } from './utils';

export interface IDormancyCreateCloneProps {
  data: IDormancyCreateModalData;
}
 export const DormancyPolicyCreateClone_TITLE = 'DormancyPolicyCreateClone';
  const activeModalAtom = getActiveModalAtom<IDormancyCreateModalData>();


const DormancyCreateClone: React.FC = () => {
  const activeModal = useRecoilState(activeModalAtom)[0];
  
  const onSuccess = activeModal?.payload?.onSuccess as ((policy: ISelectItem) => void);
  const cloningPolicy:boolean = activeModal?.payload?.cloningPolicy !== undefined ? activeModal?.payload?.cloningPolicy : false;
  const policyId = activeModal?.payload?.policyId;
  const defaultName = activeModal?.payload?.defaultName;
  return (
    <>
    <Modal title={DormancyPolicyCreateClone_TITLE} caption={`${cloningPolicy ? 'Clone Existing' : 'Create New'} Policy`}>
      <Form
          cloningPolicy={cloningPolicy}  
          defaultName={defaultName}
          handleSubmit={
            cloningPolicy && !!policyId
              ? getHandleCloneFn(onSuccess, policyId)
              : getHandleCreateFn(onSuccess)
          }
        />
      </Modal>
    
    </>
  );
};

export default DormancyCreateClone;
