import { Api } from 'config/axiosConfig';
import { IAppConfigItem } from './models';

export const fetchAppConfig = async (connectionId: string) => {
  let data: IAppConfigItem[] = [];

  const baseUrl = 'api/emm/v1/provision/connection';
  const apiUrl = `${baseUrl}/${connectionId}/app-config`;

  try {
    const result = await Api.get(apiUrl);
    if (!!result?.data?.appConfiguration?.[0]) {
      data = result?.data?.appConfiguration;
    }
  } catch (e) {
    console.log(e);
  }

  return data.map((row, index) => ({
    ...row,
    id: `${index}`,
  }));
};
