import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MUIAddIcon from '@material-ui/icons/Add';
import MUIDeleteIcon from '@material-ui/icons/Delete';
import MUIEditIcon from '@material-ui/icons/Edit';
import MUIVisibilityIcon from '@material-ui/icons/Visibility';
import {
  createAcl,
  deleteAcl,
  fetchAccessControlLists,
  fetchPoliciesUsingAcl,
  updateAcl,
} from 'api/PhishingPolicyService';
import { getActiveModalAtom } from 'atoms/modals';
import { GenericPromptModal_TITLE } from 'components/UI/Modals/GenericPrompt';
import { IGenericPromptModalData } from 'components/UI/Modals/GenericPrompt/models';
import ViewSelector from 'components/UI/ViewSelector';
import CopyIcon from 'components/UI/icons/CopyIcon';
import { ISelectItem } from 'components/UI/input/Select';
import withRouter from 'components/hocs/withRouter';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { openSnackBar } from 'utils/storeUtils';
import {
  phishingAclItemValue as aclListItemAtom,
  phishingAclControlListEntriesArr as phishingAclControlListEntriesArrAtom,
  phishingAclHasChanges as phishingAclHasChangesAtom,
  phishingAclList as phishingAclListAtom,
} from '../atoms';
import { IACLListItem, ICategories } from '../models';
import AclTable from './ACLTable/ACLTable';
import AclInUseModal from './AclInUseModal';
import TableActions from './TableActions';
import useStyles from './useStyles';

interface IPhishingAccessControlListProps {
  setLoaded: (arg: boolean) => void;
  aclFromPolicy: IACLListItem;
  categories: {
    categoryGroups: ICategories[];
  };
}

const activeModalAtom = getActiveModalAtom<IGenericPromptModalData>();

const PhishingAccessControlList: React.FC<IPhishingAccessControlListProps> = ({
  aclFromPolicy,
  setLoaded,
  categories,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [aclList, setAclList] = useState<IACLListItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [policiesUsingAcl, setPoliciesUsingAcl] = useState<
    { id: string; name: string }[]
  >([]);
  const [persistSelectedAcl, setPersistedSelectedAcl] =
    useRecoilState(aclListItemAtom);
  const [persistedAclList, setPersistedAclList] =
    useRecoilState(phishingAclListAtom);

  const setActiveModal = useSetRecoilState(activeModalAtom);
  const setAclHasChanges = useSetRecoilState(phishingAclHasChangesAtom);
  const controlListEntries = useRecoilValue(
    phishingAclControlListEntriesArrAtom
  );

  const getNewAclAfterCreation = useCallback(
    (aclList1: IACLListItem[], aclList2: IACLListItem[]) =>
      aclList1.filter((obj1) => !aclList2.some((obj2) => obj1.id === obj2.id)),
    []
  );

  const res = useMemo(
    () => getNewAclAfterCreation(aclList, persistedAclList || []),
    [aclList, persistedAclList, getNewAclAfterCreation]
  );

  useEffect(() => {
    if (persistedAclList && persistedAclList.length > 0) {
      setPersistedSelectedAcl(res[0]);
    }
  }, [persistedAclList, setPersistedSelectedAcl, res]);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchAccessControlLists().then(({ data }: { data: IACLListItem[] }) => {
        setAclList(data);
        setIsLoading(false);
      });
    }, 500);
    return () => clearTimeout(timer);
  }, [isLoading]);

  const aclListNames = useMemo(
    () =>
      aclList.map((el: IACLListItem) => ({
        label: el.name,
        value: el.id,
      })),
    [aclList]
  );

  useEffect(() => {
    const id = persistSelectedAcl?.id;
    if (persistSelectedAcl) {
      fetchPoliciesUsingAcl({ id }).then(
        ({ data }: { data: { id: string; name: string }[] }) => {
          setPoliciesUsingAcl(data);
        }
      );
    }
  }, [persistSelectedAcl]);

  const handleChange = useCallback(
    (name: string, selectedOption: ISelectItem) => {
      const option = aclList.find(
        (el: IACLListItem) => el.name === selectedOption.label
      );
      setPersistedSelectedAcl(option);
      setAclHasChanges(aclFromPolicy?.id !== option?.id);
    },
    [aclList, setPersistedSelectedAcl, aclFromPolicy, setAclHasChanges]
  );

  const updateACLName = useCallback(
    async (name: string | undefined) => {
      const id = persistSelectedAcl?.id;
      const payload = {
        name,
        controlListEntries: [...controlListEntries],
      };

      try {
        const res = await updateAcl({ id }, payload);
        openSnackBar(t('MTD.POLICIES.PHISHING_POLICY.EDITED_LIST'));
        return res;
      } catch (error) {
        console.log(error);
      }
    },
    [persistSelectedAcl, controlListEntries, t]
  );

  const handleEditName = useCallback(() => {
    setActiveModal(undefined);
    setActiveModal({
      active: GenericPromptModal_TITLE,
      payload: {
        title: t('MTD.POLICIES.PHISHING_POLICY.EDIT_LIST_NAME'),
        question: {
          label: t('MTD.POLICIES.PHISHING_POLICY.LIST_NAME'),
          defaultAnswer: persistSelectedAcl?.name,
        },
        onCancel: () => {
          setActiveModal(undefined);
        },
        onConfirm: async (answer) => {
          if (await updateACLName(answer)) {
            setPersistedSelectedAcl({
              ...persistSelectedAcl,
              name: answer,
            } as IACLListItem);
            setLoaded(false);
            setLoaded(true);
          }
          setActiveModal(undefined);
        },
      },
    });
  }, [
    persistSelectedAcl,
    setPersistedSelectedAcl,
    setActiveModal,
    updateACLName,
    setLoaded,
    t,
  ]);

  const createNewAcl = useCallback(
    async (name: string, clone: boolean) => {
      const payload = {
        name,
        controlListEntries: clone ? [...controlListEntries] : [],
      };
      await createAcl({}, payload).then(() =>
        openSnackBar(t('MTD.POLICIES.PHISHING_POLICY.CREATED_LIST'))
      );
    },
    [controlListEntries, t]
  );

  const handleAddClone = useCallback(
    (cloningList: boolean, cloneName?: string) => {
      setPersistedAclList(undefined);
      setActiveModal(undefined);
      setActiveModal({
        active: GenericPromptModal_TITLE,
        payload: {
          title: t('MTD.POLICIES.PHISHING_POLICY.ADD_NEW_LIST'),
          question: {
            label: t('MTD.POLICIES.PHISHING_POLICY.ENTER_LIST_NAME'),
            defaultAnswer: cloneName || '',
          },
          onCancel: () => {
            setActiveModal(undefined);
          },
          onConfirm: async (answer) => {
            if (answer) {
              await createNewAcl(answer, cloningList);
              setActiveModal(undefined);
              setLoaded(false);
              setLoaded(true);
            }
            setPersistedAclList(aclList);
          },
        },
      });
    },
    [setActiveModal, createNewAcl, setLoaded, aclList, setPersistedAclList, t]
  );

  const deleteAclById = useCallback(
    async (id: string | undefined) => {
      try {
        await deleteAcl({ id });
        openSnackBar(t('MTD.POLICIES.PHISHING_POLICY.DELETED_LIST'));
        return true;
      } catch (error) {
        console.log(error);
        openSnackBar(t('MTD.POLICIES.PHISHING_POLICY.CAN_NOT_DELETE_LIST'));
        return false;
      }
    },
    [t]
  );

  const handleDelete = useCallback(() => {
    setActiveModal(undefined);
    setActiveModal({
      active: GenericPromptModal_TITLE,
      payload: {
        title: t('MTD.POLICIES.PHISHING_POLICY.DELETE_LIST'),
        message: t('MTD.POLICIES.NETWORK_POLICY.CONFIRM_DELETE'),
        onCancel: () => setActiveModal(undefined),
        onConfirm: async () => {
          if (await deleteAclById(persistSelectedAcl?.id)) {
            const filtered = aclList.filter(
              (el) => el.id !== persistSelectedAcl?.id
            );
            setAclList([...filtered]);
            setPersistedSelectedAcl(undefined);

            setLoaded(false);
            setLoaded(true);
          }
          setActiveModal(undefined);
        },
      },
    });
  }, [
    setActiveModal,
    setLoaded,
    setPersistedSelectedAcl,
    persistSelectedAcl,
    aclList,
    deleteAclById,
    t,
  ]);

  const updateForAddAndDeleteAclEntries = useCallback(
    async (controlListEntries) => {
      const payload = {
        name: persistSelectedAcl?.name,
        controlListEntries,
      };

      try {
        const res = await updateAcl({ id: persistSelectedAcl?.id }, payload);
        openSnackBar(t('MTD.POLICIES.PHISHING_POLICY.EDITED_LIST'));
        return res;
      } catch (error) {
        console.log(error);
      }
    },
    [persistSelectedAcl, t]
  );

  const listInUseInfo = () => {
    setActiveModal(undefined);
    setActiveModal({
      active: GenericPromptModal_TITLE,
      payload: {
        title: t('MTD.POLICIES.PHISHING_POLICY.LIST_IN_USE'),
        message: <AclInUseModal policiesUsingAcl={policiesUsingAcl} />,
        onConfirmCaption: t('GLOBAL.OK'),
        onConfirm: () => setActiveModal(undefined),
        onCancel: () => {},
        onCancelHidden: true,
      },
    });
  };

  if (!aclList || (aclList.length > 0 && isLoading)) {
    return null;
  }
  return (
    <Card className={classes.aclCard}>
      <CardContent className={classes.groupBackground}>
        <div>
          <ViewSelector
            editFieldText={persistSelectedAcl?.name}
            interactable
            value={{
              label: persistSelectedAcl?.name ?? '',
              value: persistSelectedAcl?.id ?? '',
            }}
            label={t('MTD.POLICIES.PHISHING_POLICY.SELECT_CUSTOM_LIST')}
            placeholder={t('GLOBAL.SELECT')}
            setFieldValue={handleChange}
            actions={[
              {
                onClick: () => handleEditName(),
                icon: MUIEditIcon,
                disabled: !persistSelectedAcl?.id,
              },
              {
                onClick: () => handleAddClone(true, persistSelectedAcl?.name),
                icon: CopyIcon,
                disabled: !persistSelectedAcl?.id,
              },
              {
                onClick: () => handleDelete(),
                icon: MUIDeleteIcon,
                disabled: !persistSelectedAcl?.id,
              },
              {
                onClick: () => handleAddClone(false),
                icon: MUIAddIcon,
              },
            ]}
            options={aclListNames as ISelectItem[]}
          />
        </div>
        {persistSelectedAcl && (
          <>
            <div className={classes.actionsContainer}>
              <div className={classes.polNumberContainer}>
                <span className={classes.polNumber}>
                  {t('MTD.POLICIES.PHISHING_POLICY.POLICIES_USING_THIS_LIST')}:{' '}
                  {policiesUsingAcl.length}{' '}
                  {policiesUsingAcl.length! > 1 ? 'policies' : 'policy'}
                </span>
                <div className={classes.polNumberDetails}>
                  <MUIVisibilityIcon onClick={listInUseInfo} />
                </div>
              </div>
              <TableActions
                editMode={true}
                updateAcl={updateForAddAndDeleteAclEntries}
                categoriesFromApi={categories}
              />
            </div>
            <AclTable
              categoriesFromApi={categories}
              policiesUsingAcl={policiesUsingAcl}
              updateForAddAndDelete={updateForAddAndDeleteAclEntries}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default withRouter(PhishingAccessControlList);
