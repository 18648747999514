import AppleIcon from 'components/UI/icons/AppleIcon';
import AndroidIcon from '@material-ui/icons/Android';
import SeveritySelector from './SeveritySelector/SeveritySelector';
import ZScanEnabled from './ZScanEnabled';
import VisibilityButton from './VisibilityButton';
import {
  IZScanPolicyLocationQuery,
  IZScanPolicyRowMapping,
  IZscanIndicator,
} from './models';

export const zScanPoliciesTableHeaders = [
  {
    id: 'enabled',
    label: 'Enabled',
    flex: 1,
    show: true,
    sort: true,
    showInContextMenu: true,
    minWidth: 125,
    resizable: true,
  },
  {
    id: 'name',
    label: 'Name',
    flex: 1,
    show: true,
    sort: true,
    showInContextMenu: true,
    minWidth: 225,
    resizable: true,
  },
  {
    id: 'categoryName',
    label: 'Category',
    flex: 1,
    show: true,
    sort: true,
    showInContextMenu: true,
    minWidth: 150,
    resizable: true,
  },
  {
    id: 'subcategoryName',
    label: 'Subcategory',
    flex: 1,
    show: true,
    sort: true,
    showInContextMenu: true,
    minWidth: 150,
    resizable: true,
  },
  {
    id: 'severityOrdinal',
    label: 'Severity',
    flex: 1,
    show: true,
    sort: true,
    showInContextMenu: true,
    minWidth: 220,
    resizable: true,
  },
  {
    id: 'platform',
    label: 'Platform',
    flex: 1,
    show: true,
    sort: false,
    resizable: true,
    minWidth: 125,
    showInContextMenu: true,
  },
  {
    id: 'actions',
    label: 'Actions',
    flex: 1,
    show: true,
    sort: false,
    resizable: true,
    minWidth: 125,
    showInContextMenu: true,
  },
];

export const ZScanPoliciesColumnMapping = (
  editMode: boolean,
  query: IZScanPolicyLocationQuery
): IZScanPolicyRowMapping[] => [
  {
    path: 'enabled',
    columnContent: ({ rowData }) => (
      <ZScanEnabled disabled={!editMode} data={rowData as IZscanIndicator} />
    ),
  },
  {
    path: 'name',
  },
  {
    path: 'categoryName',
  },
  {
    path: 'subcategoryName',
  },
  {
    path: 'severityOrdinal',
    columnContent: ({ rowData }) => {
      return (
        <div style={{ width: '250px ' }}>
          <SeveritySelector
            disabled={!editMode}
            finding={rowData as IZscanIndicator}
          />
        </div>
      );
    },
  },
  {
    path: 'platform',
    columnContent: ({ rowData }) => {
      const platforms = rowData?.platform ?? [];
      if (Array.isArray(platforms)) {
        return platforms?.map((os: string, index: number) => {
          if (!os) {
            return null;
          }
          return os === 'ios' ? (
            <span
              key={`os-${index}`}
              style={{ marginRight: '5px' }}
              className="table__os-icon"
            >
              <span id="action-column">
                <AppleIcon />
              </span>
            </span>
          ) : (
            <AndroidIcon
              id="action-column"
              key={`os-${index}`}
              style={{ marginRight: '10px' }}
            />
          );
        });
      }
      return platforms === 'ios' ? <AppleIcon /> : <AndroidIcon />;
    },
  },
  {
    path: 'actions',
    columnContent: ({ rowData }) => {
      return (
        <VisibilityButton
          rowData={rowData as unknown as IZscanIndicator}
          policyId={query?.policyId}
        />
      );
    },
  },
];
