// helper that creates and dispatches a custom event
export const publishEvent = (eventName, detail = {}, $elem = window) => {
  if (typeof eventName !== 'string') {
    throw new Error('publishEvent: eventName is required');
  }

  // create new event
  const _event = new CustomEvent(eventName, { detail });

  // dispatch event
  $elem.dispatchEvent(_event);
};

export class PubSubModule {
  topics = {};

  /**
   * subscribe
   * @param topic {string}
   * @param listener {function}
   */
  subscribe(topic, listener) {
    const self = this;

    // create the topic
    if (!this.topics.hasOwnProperty.call(this.topics, topic)) {
      this.topics[topic] = [];
    }

    // add the listener to queue
    const index = this.topics[topic].push(listener) - 1;

    // provide handle back for removal of topic
    return {
      remove() {
        delete self.topics[topic][index];
      },
    };
  }

  /**
   * publish
   * @param topic {string}
   * @param data {any - default object}
   */
  publish(topic, data) {
    // if the topic doesn't exist bail
    if (!this.topics.hasOwnProperty.call(this.topics, topic)) {
      return;
    }

    // cycle through topics queue and fire!
    this.topics[topic].forEach(item => {
      item(data);
    });
  }
}

export const PubSub = new PubSubModule();
