import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    cardContentDetails: {
      background: palette.background.paper,
      display: 'flex',
      justifyContent: 'flex-start',
      padding: 20,
      width: '100%',
      '&:last-child': {
        paddingBottom: 15,
      },
    },
    cardDetails: {
      background: palette.background.paper,
      margin: 0,
      outline: 'none',
      padding: 0,
    },
    dataWrapper: {
      width: '50%',
    },
    displayName: {
      fontSize: '13px',
      paddingTop: 10,
    },
    label: {
      color: palette.text.secondary,
      fontWeight: 'bold',
    },
    link: {
      paddingLeft: 20,
      paddingTop: 15,
    },
    linkWrapper: {
      display: 'flex',
      paddingLeft: 20,
    },
    logo: {
      display: 'block',
      width: 80,
    },
    logoWrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '30px',
      marginTop: '10px',
      maxWidth: '400px',
    },
  })
);

export default useStyles;
