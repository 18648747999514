import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    appNameHeader: {
      paddingLeft: '6px',
    },
    header: {
      alignItems: 'center',
      backgroundColor: palette.tables.header.background,
      color: palette.text.secondary,
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0px -10px 0px -10px',
      padding: '0px 10px 0px 10px',
    },
    leftSection: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
  })
);

export default useStyles;
