import { fetchPasswordPolicy } from 'api/PasswordPolicyService';
import GenericCard from 'components/UI/GenericCard';
import PasswordRulesBox from 'components/main/common/PasswordRulesBox';
import React, { useEffect, useState } from 'react';
import useStyles from '../EditUserAccountProfile/useStyles';
import Form from './Form';
import { IPasswordRules } from './models';

const ChangePassword: React.FC = (props: unknown) => {
  const classes = useStyles();
  const [customPasswordRules, setCustomPasswordRules] =
    useState<IPasswordRules>({});

  useEffect(() => {
    fetchPasswordPolicy()
      .then(({ data }) => {
        setCustomPasswordRules(data);
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
  }, [props]);

  return (
    <GenericCard>
      <div className={classes.formWrapper}>
        <Form />
      </div>
      <PasswordRulesBox customPasswordRules={customPasswordRules} />
    </GenericCard>
  );
};

export default ChangePassword;
