import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { checkSiteForPhishing } from 'api/PhishingPolicyService';
import withRouter from 'components/hocs/withRouter';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import Button from 'components/UI/Button';
import TextField from 'components/UI/input/TextField';
import { Field, Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { openSnackBar } from 'utils/storeUtils';
import { IPhishingSiteCheckResponse } from './models';
import useStyles from './useStyles';
import { getSubCategoriesArray } from './utils';

interface IPhishingSiteCategoryCheckProps {
  siteCategCodes: {
    categoryGroups: [];
  };
}

const PhishingSiteCategoryCheck: React.FC<IPhishingSiteCategoryCheckProps> = (
  props
) => {
  const [category, setCategory] = useState<string | undefined>('');
  const [reqError, setReqError] = useState<string>('');
  const classes = useStyles();
  const { t } = useTranslation();

  const findCategory = useCallback(
    (category: number) => {
      const flatCategArray = getSubCategoriesArray(
        props.siteCategCodes.categoryGroups
      );
      const found = flatCategArray.find((cat) => cat.categoryId === category);
      if (found) {
        setCategory(found.name);
      }
    },
    [props.siteCategCodes]
  );

  const handleSubmit = useCallback(
    (values: { url: string }): void => {
      const payload = { url: values.url };
      checkSiteForPhishing({}, payload)
        .then(
          ({
            data,
            status,
          }: {
            data: IPhishingSiteCheckResponse;
            status: number;
          }) => {
            const zcat = data;
            if (status === 200) {
              findCategory(zcat.zcat[0]);
              setReqError('');
            }
          }
        )
        .catch((err: any) => {
          openSnackBar(t('MTD.POLICIES.PHISHING_POLICY.UNABLE_CHECK_URL'));
          const error = err.message;
          setReqError(error);
        });
    },
    [findCategory, t]
  );

  return (
    <Card className={classes.siteCatCard}>
      <CardHeader
        className={classes.inputGroupBackground}
        title={t('MTD.POLICIES.PHISHING_POLICY.SITE_CATEGORY_CHECK')}
      ></CardHeader>
      <CardContent className={classes.inputGroupBackground}>
        <Formik
          initialValues={{ url: '' }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values }) => {
            return (
              <>
                <Form>
                  <span>
                    {t('MTD.POLICIES.PHISHING_POLICY.PASTE_URL_DOMAIN')}
                  </span>
                  <div className={classes.categoryGroup}>
                    <Field
                      component={TextField}
                      disabled={false}
                      label="  "
                      name="url"
                      placeholder={t('GLOBAL.URL')}
                      type="text"
                    />
                    <ProtectedComponent allow={{ policies: 'manage' }}>
                      <Button
                        color="primary"
                        form="phishingPolicyForm"
                        onClick={() => handleSubmit(values)}
                        text={t('MTD.POLICIES.PHISHING_POLICY.CHECK')}
                      />
                    </ProtectedComponent>
                  </div>
                </Form>
                {reqError.length > 0 ? (
                  <div className={classes.siteCatCardText}>
                    {t('MTD.POLICIES.PHISHING_POLICY.ENTER_VALID_URL')}
                  </div>
                ) : (
                  category && (
                    <div className={classes.siteCatCardText}>
                      Default category: {category}
                    </div>
                  )
                )}
              </>
            );
          }}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default withRouter(PhishingSiteCategoryCheck);
