import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Edit from '@material-ui/icons/Edit';
import withRouter from 'components/hocs/withRouter';
import { TextField } from 'components/inputs';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import { MultiSelect } from 'components/Selects';
import { GlobalMenuOption } from 'components/Selects/MultiSelect/GlobalMenuOption';
import { GlobalMultiSelectButton } from 'components/Selects/MultiSelect/GlobalMultiSelectButton';
import _ from 'lodash';
import { SCOPE_BOUNDS } from 'mappings/scopeMapping';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import ZButton from 'UI/Buttons/ZButton';
import { toggleModalDiffered } from 'utils/storeUtils';

const AppSettingsSelector = (props) => {
  const { appSettingsList } = props;
  const { t } = useTranslation();
  const cloneButtonText = t('GLOBAL.CLONE');
  const deleteButtonText = t('GLOBAL.DELETE');
  const saveButtonText = t('GLOBAL.SAVE');

  const scopeBounds = _.get(props, 'appSettingsData.global', true)
    ? SCOPE_BOUNDS.account
    : SCOPE_BOUNDS.team;

  function handleChange(name, selectedOption) {
    props.setAppSettingsName(selectedOption.label);
    props.setSelectedAppSettings(selectedOption);
  }

  return (
    <Card style={{ marginTop: 15 }}>
      <CardContent>
        <div className="flex--spaced">
          <Grid container spacing={16}>
            {props.editMode ? (
              <Grid item lg={5}>
                <TextField
                  id="name"
                  required
                  value={props.appSettingsName}
                  onChange={(e) => props.setAppSettingsName(e.target.value)}
                />
              </Grid>
            ) : (
              <Fragment>
                <Grid item lg={5}>
                  <MultiSelect
                    name="appsettings"
                    isMulti={false}
                    buttonPlaceholder={t('MTD.POLICIES.APP_SETTINGS.SELECT_APP_SETTINGS')}
                    options={appSettingsList}
                    onChange={handleChange}
                    values={props.selectedAppSettings}
                    components={{
                      Option: GlobalMenuOption,
                      DropDownButton: GlobalMultiSelectButton,
                    }}
                  />
                </Grid>
                <Grid
                  item
                  lg={1}
                  className="flex--spaced"
                  style={{ justifyContent: 'flex-start' }}
                >
                  <ProtectedComponent
                    allow={{ scopeBounds, policies: 'manage' }}
                  >
                    <div style={{ marginLeft: 10 }} />
                    <Edit
                      className="table__os-icon"
                      style={{ cursor: 'pointer' }}
                      onClick={() => props.setEditMode(!props.editMode)}
                    />
                  </ProtectedComponent>
                </Grid>
              </Fragment>
            )}
          </Grid>
          {props.editMode ? (
            <div className="flex--spaced">
              <ProtectedComponent allow={{ scopeBounds, policies: 'manage' }}>
                <ZButton
                  buttonText={saveButtonText}
                  color="primary"
                  styleName="modalSave"
                  buttonType="submit"
                  form="appSettingsForm"
                />
              </ProtectedComponent>

              <ProtectedComponent allow={{ scopeBounds, policies: 'manage' }}>
                <ZButton
                  action={() => {
                    props.setEditMode(false);
                    props.formRef?.current?.resetForm();
                  }}
                  styleName="menu"
                  buttonText={t('GLOBAL.CANCEL')}
                  color="secondary"
                />
              </ProtectedComponent>
            </div>
          ) : (
            <div className="flex--spaced">
              <ProtectedComponent allow={{ scopeBounds, policies: 'manage' }}>
                <ZButton
                  action={toggleModalDiffered('AppSettingsCreateClone', {
                    headerText: t('MTD.POLICIES.APP_SETTINGS.CLONE_EXISTING_APP_SETTINGS'),
                    buttonText: t('GLOBAL.CLONE'),
                    policySelected: props.selectedAppSettings,
                    scopeBounds: props?.scopeBounds,
                    selectedTeam: props.selectedTeam,
                    mtdModule: props.mtdModule,
                  })}
                  buttonText={cloneButtonText}
                  color="primary"
                  styleName="menu"
                  form="appSettingsForm"
                />
              </ProtectedComponent>

              <ProtectedComponent allow={{ scopeBounds, policies: 'manage' }}>
                <ZButton
                  action={toggleModalDiffered('PolicyDeleteConfirm', {
                    headerText: t('MTD.POLICIES.APP_SETTINGS.DELETE_APP_SETTINGS'),
                    ableToDelete: true,
                    groups: _.get(props.appSettingsData, 'groups', []),
                    policyType: 'AppSettings',
                    policySelected: props.selectedAppSettings,
                  })}
                  buttonText={deleteButtonText}
                  color="primary"
                  styleName="menu"
                />
              </ProtectedComponent>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default withRouter(AppSettingsSelector);
