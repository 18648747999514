import {
  deletePolicy as deleteOsRiskPolicy,
  updatePolicy as updateOsRiskPolicy,
} from 'api/OSRiskService';
import cc from 'classcat';
import { ISelectItem } from 'components/UI/input/Select';
import withRouter from 'components/hocs/withRouter';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { onlyNumbersRegex } from 'utils/regexUtils';
import EmptyPolicies from '../common/EmptyPolicies';
import PolicySelector from '../common/PolicySelector';
import AndroidRules from './AndroidRules';
import IosRules from './IosRules';
import {
  EOsRuleType,
  IOsRiskPolicy,
  IOsRiskPolicyRequest,
  IRiskRules,
  IValues,
} from './models';
import { fetchPolicies, fetchPolicyDetails } from './tableQuery';
import useStyles from './useStyles';

const defaultPolicy: IOsRiskPolicy = {
  id: '',
  accountId: '',
  name: '',
  osRiskPolicyRules: {
    iosRiskRules: {
      osRuleType: EOsRuleType.Latest,
      gracePeriodInDays: null,
      minimumAllowedVersion: null,
      numAllowedPriorMajorVersions: null,
    },
    androidRiskRules: {
      osRuleType: EOsRuleType.Latest,
      gracePeriodInDays: null,
      minimumAllowedVersion: null,
      numAllowedPriorMajorVersions: null,
    },
  },
  groups: [],
};

const defaultRiskValues: Omit<IRiskRules, 'osRuleType'> = {
  gracePeriodInDays: null,
  minimumAllowedVersion: null,
  numAllowedPriorMajorVersions: null,
};

const defaultValues: IValues = {
  [EOsRuleType.Latest]: defaultRiskValues,
  [EOsRuleType.VersionBack]: defaultRiskValues,
  [EOsRuleType.VersionBackPatched]: defaultRiskValues,
  [EOsRuleType.MinVersion]: defaultRiskValues,
  [EOsRuleType.MinVersionPatched]: defaultRiskValues,
};

interface IOsRiskPolicyProps {
  location: {
    query: {
      osrisk: string;
    };
  };
}

const OsRiskPolicy: React.FC<IOsRiskPolicyProps> = (props) => {
  const classes = useStyles();

  const [selectedPolicy, setSelectedPolicy] = useState<ISelectItem>();
  const [currentPolicy, setCurrentPolicy] = useState(defaultPolicy);
  const [policyDetails, setPolicyDetails] = useState(defaultPolicy);
  const [androidValues, setAndroidValues] = useState(defaultValues);
  const [iosValues, setIosValues] = useState(defaultValues);

  const { t, ready } = useTranslation();

  useEffect(() => {
    if (policyDetails) {
      const { androidRiskRules, iosRiskRules } =
        policyDetails.osRiskPolicyRules;

      setAndroidValues({
        ...defaultValues,
        [androidRiskRules.osRuleType]: {
          gracePeriodInDays: androidRiskRules.gracePeriodInDays,
          minimumAllowedVersion: androidRiskRules.minimumAllowedVersion,
          numAllowedPriorMajorVersions:
            androidRiskRules.numAllowedPriorMajorVersions,
        },
      });
      setIosValues({
        ...defaultValues,
        [iosRiskRules.osRuleType]: {
          gracePeriodInDays: iosRiskRules.gracePeriodInDays,
          minimumAllowedVersion: iosRiskRules.minimumAllowedVersion,
          numAllowedPriorMajorVersions:
            iosRiskRules.numAllowedPriorMajorVersions,
        },
      });
    }
  }, [policyDetails]);

  const getRequestObject = useCallback(
    (policy?: IOsRiskPolicy): IOsRiskPolicyRequest | undefined => {
      if (!policy) {
        return undefined;
      }

      return {
        name: policy.name,
        osRules: { ...policy.osRiskPolicyRules },
        teamId: selectedPolicy?.team?.id,
      };
    },
    [selectedPolicy?.team?.id]
  );

  const validateRules = useCallback((rules: IRiskRules, isAndroid: boolean) => {
    let isValid = false;

    switch (rules.osRuleType) {
      case EOsRuleType.Latest:
        isValid = !rules.gracePeriodInDays?.trim().length
          ? true
          : onlyNumbersRegex.test(rules.gracePeriodInDays);
        break;
      case EOsRuleType.VersionBack:
        isValid =
          !!rules.numAllowedPriorMajorVersions &&
          onlyNumbersRegex.test(rules.numAllowedPriorMajorVersions);
        break;
      case EOsRuleType.VersionBackPatched:
        isValid =
          !!rules.numAllowedPriorMajorVersions &&
          isAndroid &&
          onlyNumbersRegex.test(rules.numAllowedPriorMajorVersions) &&
          (!rules.gracePeriodInDays?.trim().length
            ? true
            : onlyNumbersRegex.test(rules.gracePeriodInDays));
        break;
      case EOsRuleType.MinVersion:
        isValid = !!rules.minimumAllowedVersion;
        break;
      case EOsRuleType.MinVersionPatched:
        isValid =
          !!rules.minimumAllowedVersion &&
          isAndroid &&
          (!rules.gracePeriodInDays?.trim().length
            ? true
            : onlyNumbersRegex.test(rules.gracePeriodInDays));
        break;
      default:
        break;
    }

    return isValid;
  }, []);

  const canDeploy = useCallback((): boolean => {
    return (
      validateRules(currentPolicy.osRiskPolicyRules.androidRiskRules, true) &&
      validateRules(currentPolicy.osRiskPolicyRules.iosRiskRules, false)
    );
  }, [currentPolicy, validateRules]);

  const onAndroidRuleTypeChange = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
      setCurrentPolicy({
        ...currentPolicy,
        osRiskPolicyRules: {
          ...currentPolicy.osRiskPolicyRules,
          androidRiskRules: {
            ...androidValues[value as EOsRuleType],
            osRuleType: value as EOsRuleType,
          },
        },
      });
    },
    [androidValues, currentPolicy]
  );

  const onIosRuleTypeChange = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
      setCurrentPolicy({
        ...currentPolicy,
        osRiskPolicyRules: {
          ...currentPolicy.osRiskPolicyRules,
          iosRiskRules: {
            ...iosValues[value as EOsRuleType],
            osRuleType: value as EOsRuleType,
          },
        },
      });
    },
    [iosValues, currentPolicy]
  );

  const onRuleChange = <
    Os extends keyof IOsRiskPolicy['osRiskPolicyRules'],
    Key extends keyof Omit<IRiskRules, 'osRuleType'>
  >(
    os: Os,
    key: Key,
    value: unknown
  ) => {
    const currentRuleType = currentPolicy.osRiskPolicyRules[os].osRuleType;

    if (os === 'androidRiskRules') {
      setAndroidValues({
        ...androidValues,
        [currentRuleType]: {
          ...defaultValues[currentRuleType],
          [key]: value,
        },
      });
    } else {
      setIosValues({
        ...iosValues,
        [currentRuleType]: {
          ...defaultValues[currentRuleType],
          [key]: value,
        },
      });
    }

    setCurrentPolicy({
      ...currentPolicy,
      osRiskPolicyRules: {
        ...currentPolicy.osRiskPolicyRules,
        [os]: {
          ...currentPolicy.osRiskPolicyRules[os],
          [key]: value,
        },
      },
    });
  };

  if (!ready) {
    return null;
  }

  return (
    <div className={classes.root}>
      <PolicySelector
        query={props.location.query.osrisk}
        policyType="OsRisk"
        canDeploy={canDeploy}
        getRequestObject={getRequestObject}
        fetchPolicies={fetchPolicies}
        fetchPolicyDetails={fetchPolicyDetails}
        updatePolicy={updateOsRiskPolicy}
        deletePolicy={deleteOsRiskPolicy}
        selectedPolicy={selectedPolicy}
        setSelectedPolicy={setSelectedPolicy}
        currentPolicy={currentPolicy}
        setCurrentPolicy={setCurrentPolicy}
        policyDetails={policyDetails}
        setPolicyDetails={setPolicyDetails}
      />

      {!policyDetails.id.trim() && <EmptyPolicies />}

      {selectedPolicy &&
        policyDetails.id === currentPolicy.id &&
        selectedPolicy.value === currentPolicy.id && (
          <div className={classes.content}>
            <div className={classes.card}>
              <div className={cc([classes.cardTitle, classes.cardBold])}>
                <div>
                  {t(
                    'MTD.POLICIES.OS_RISK_POLICY.COMPLIANCE_RULES_IN_THIS_POLICY'
                  )}
                </div>
              </div>

              <div className={classes.cardContent}>
                <span>
                  {t(
                    'MTD.POLICIES.OS_RISK_POLICY.THIS_POLICY_CONTROL_OUT_OF_COMPLIANCE'
                  )}
                </span>

                <div className={classes.rulesContent}>
                  <AndroidRules
                    rules={currentPolicy.osRiskPolicyRules.androidRiskRules}
                    values={androidValues}
                    onRuleTypeChange={onAndroidRuleTypeChange}
                    onRuleChange={onRuleChange}
                  />

                  <IosRules
                    rules={currentPolicy.osRiskPolicyRules.iosRiskRules}
                    values={iosValues}
                    onRuleTypeChange={onIosRuleTypeChange}
                    onRuleChange={onRuleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default withRouter(OsRiskPolicy);
