import axios from 'axios';
import withRouter from 'components/hocs/withRouter';
import React, { useState } from 'react';
import { publishEvent } from 'utils/eventUtils';
import { openSnackBar, toggleModalDirect } from 'utils/storeUtils';
import Form from './Form';
import { IFormSubmit } from './Form/models';
import { formatErrorMessage } from './Form/utils';
import { updateZShieldApp } from './utils';
import { FormikHelpers } from 'formik';

interface IZShieldUploadAppProps {
  data: Partial<IFormSubmit>;
}

const ZShieldUploadAppForm: React.FC<IZShieldUploadAppProps> = ({
  data,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const editMode = !!data && Object.keys(data).length !== 0;

  const resetModal = () => {
    setErrorMessage('');
  };

  const handleSubmit = async (values: IFormSubmit, formikHelpers: FormikHelpers<IFormSubmit>) => {
    try {
      await updateZShieldApp(values, formikHelpers);
      publishEvent('zshield:reload-tables'); // This will force a reload without relying on the Table component.
      toggleModalDirect('ZShieldRegisterApp', false);
      openSnackBar('Your application is currently being processed, please check the status of your app by refreshing the page after some time. You will also receive email(s) regarding updates');
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const status = e?.response?.status ?? -1;
        let statusText = e?.response?.statusText ?? 'Unknown Error';
        if (status === 403) {
          statusText = 'Unauthorized Error';
        }
        if (status === 404) {
          statusText = 'Build is not found';
        }
        const dataString =
          typeof e?.response?.data === 'string'
            ? e.response.data
            : 'Please Try Again';
        console.log(e?.response);
        setErrorMessage(formatErrorMessage(statusText, dataString, status));
      } else {
        console.log(e);
        setErrorMessage(formatErrorMessage('Upload Error', 'Please Try Again'));
      }
    }
  };

  return (
    <Form
      data={data}
      editMode={editMode}
      errorMessage={errorMessage}
      handleSubmit={handleSubmit}
      resetModal={resetModal}
    />
  );
};

export default withRouter(ZShieldUploadAppForm);
