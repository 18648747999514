import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import withRouter from 'components/hocs/withRouter';

const PartnerProtectedRoute = ({
  component: Component,
  scopes,
  requiredScope,
  requiredQueryParams,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      let result = false;
      if (!requiredScope) {
        result = true;
      } else {
        const permissions = Object.keys(requiredScope);
        for (const permission of permissions) {
          const usersPermissionsForThisScope = scopes?.[permission] ?? [];

          if (
            usersPermissionsForThisScope.includes('manage') ||
            usersPermissionsForThisScope.includes(requiredScope[permission])
          ) {
            result = true;
            break;
          }
        }
      }

      if (result) {
        return <Component {...props} rqps={requiredQueryParams} />;
      }

      return <Redirect to="/partner/accounts" />;
    }}
  />
);

const mapStateToProps = ({ auth }) => {
  return {
    scopes: auth.partnerUser.scopes,
  };
};

export default withRouter(connect(mapStateToProps)(PartnerProtectedRoute));
