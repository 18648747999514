import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ config, palette }: any) =>
  createStyles({
    appleIcon: {
      color: palette.secondary.text,
      fontSize: config.textSizes.canon,
      padding: '5px',
    },
    devicesOverview: {
      padding: '35px 0px',
      alignItems: 'center',
      color: palette.secondary.text,
      display: 'flex',
      fontSize: config.textSizes.petite,
      justifyContent: 'space-around',
      overflow: 'auto',
      width: '100%',
      '& aside': {
        width: 300,
      },
    },
    donutLegendText: {
      fontSize: 10,
    },
    donutLegendWrapper: {
      width: 50,
    },
  })
);

export default useStyles;
