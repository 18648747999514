import _ from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Field, Form } from 'formik';
// services

// actions
import { publishEvent } from 'utils/eventUtils';

// components
import { Grid } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ZButton from 'UI/Buttons/ZButton';
import { reduceForMultiSelect } from 'utils/componentUtils';
import { FormikMultiSelect } from 'components/inputs/FormikMultiSelect';
import {
  enablePartnerUserForPartnerUI,
  // fetchAllAccountsNotManagedByPartner,
  fetchAllManagedAccountsByPartner,
  fetchAllManagedAccountsByPartnerForPartnerUI,
  fetchAllPartnerRoles,
  fetchPartnerUserDetails,
} from 'api/PartnerService';
import { FormikTextField } from 'components/inputs/FormikTextField';
import {
  openSnackBar,
  toggleModalDirect,
  toggleModalDiffered,
} from '../../utils/storeUtils';

function PartnerUserEdit(props) {
  const initialRole = _.get(props, 'data.rowData.partnerRole');
  const [partnerRoles, setPartnerRoles] = useState([]);
  const [managedAccounts, setManagedAccounts] = useState([]);
  const [showManagedAccounts, setShowMangedAccounts] = useState(false);
  // populate user accounts who are customer manager
  const [accountValues, setAccountValues] = useState([]);

  function populatePartnerRoles() {
    fetchAllPartnerRoles()
      .then(response => {
        const rawRoles = _.get(response, 'data', []);
        const options = reduceForMultiSelect(rawRoles);
        setPartnerRoles(options);
        const initialRoleSelected = options.find((item) => item.label === initialRole.name);
        if (!initialRoleSelected.partnerScopes?.includes('partner:unbounded')) {
          populateManagedAccounts();
        }
      })
      .catch(error => {
        console.log('error in Fetching Partner Accounts', error);
      });
  }

  const populateManagedAccounts = useCallback(() => {
    setShowMangedAccounts(true);
    // api call for partner ui

    const accountId = _.get(props, 'data.rowData.account.id');
    if (!props.data.partnerUI) {
      return fetchAllManagedAccountsByPartner({ accountId })
        .then(response => {
          const rawAccounts = _.get(response, 'data', []);
          const options = reduceForMultiSelect(rawAccounts);
          setManagedAccounts(options);
        })
        .catch(error => {
          console.log('error in Fetching Possible Accounts', error);
        });
    }

    return fetchAllManagedAccountsByPartnerForPartnerUI({ accountId })
      .then(response => {
        const rawAccounts = _.get(response, 'data', []);
        const options = reduceForMultiSelect(rawAccounts);
        setManagedAccounts(options);
      })
      .catch(error => {
        console.log('error in Fetching Possible Accounts', error);
      });
  }, [props]);

  function handlePartnerRoleSelect({ values, optionSelected, setFieldValue }) {
    setFieldValue('partnerRole', optionSelected);
    if (!optionSelected.partnerScopes?.includes('partner:unbounded')) {
      populateManagedAccounts();
    } else {
      setFieldValue('accounts', []);
      setShowMangedAccounts(false);
    }
  }

  useEffect(() => {
    populatePartnerRoles();
    const accountId = _.get(props, 'data.accountId');
    fetchPartnerUserDetails({ userId: accountId })
      .then(({ data }) => {
        const accounts = _.get(data, 'accountPersonas', []);
        const multiSelectValues = accounts.map(singlePersona => {
          return {
            label: singlePersona.accountName,
            value: singlePersona.accountId,
          };
        });
        setAccountValues(multiSelectValues);
      })
      .catch(error => {
        console.log('Error in fetching partner user details:', error);
      });
    // if (accounts.length)
  }, [initialRole.name, props]);

  function handleSubmit({ partnerRole, accounts }) {
    let accountArray;
    if (accounts) {
      accountArray = accounts.map(singleAccount => singleAccount.value);
    }
    const userId = _.get(props, 'data.accountId');
    const payload = {
      userId,
      partnerRoleId: _.get(partnerRole, 'value'),
      accounts: accountArray,
    };
    // edit
    enablePartnerUserForPartnerUI({ userId }, payload)
      .then(data => {
        // because this is a shared modal with super ui and partner ui
        // - check which table to refresh
        const event = props.data.partnerUI
          ? 'table:force-fetch-partnerUsersForPartnerUI'
          : 'table:force-fetch-partnerUsers';
        toggleModalDirect('PartnerUserEdit', false);
        openSnackBar('Partner User Updated');

        publishEvent(event);
      })
      .catch(error => {
        console.log('Error in enabling partner user ', error);
      });
  }
  return (
    <DialogContent>
      <Formik
        initialValues={{
          partnerRole: {
            label: _.get(initialRole, 'name'),
            value: _.get(initialRole, 'id'),
          },
          accounts: accountValues,
        }}
        validateOnBlur
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting, values, setFieldValue }) => {
          return (
            <Form>
              <Grid container spacing={12}>
                <Grid item xs={12}>
                  <Field
                    component={FormikTextField}
                    disabled
                    label="First Name"
                    value={_.get(props, 'data.rowData.firstName')}
                  />
                  <Field
                    component={FormikTextField}
                    disabled
                    label="Last Name"
                    value={_.get(props, 'data.rowData.lastName')}
                  />
                  <Field
                    component={FormikTextField}
                    disabled
                    label="Email"
                    value={_.get(props, 'data.rowData.email')}
                  />

                  <Field
                    label="Partner Role"
                    name="partnerRole"
                    isMulti={false}
                    buttonPlaceholder="Select Role"
                    options={partnerRoles}
                    component={FormikMultiSelect}
                    onChange={(name, optionSelected) => {
                      handlePartnerRoleSelect({
                        setFieldValue,
                        values,
                        optionSelected,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label="Accounts"
                    name="accounts"
                    buttonPlaceholder="Select Accounts"
                    options={managedAccounts}
                    component={FormikMultiSelect}
                    isShowing={showManagedAccounts}
                    onChange={(name, optionSelected) => {
                      setFieldValue('accounts', optionSelected);
                    }}
                  />
                </Grid>
              </Grid>

              <DialogActions>
                <ZButton
                  styleName="modalCancel"
                  action={toggleModalDiffered('SuperUsersCreateEdit', false)}
                  color="secondary"
                  buttonText="Cancel"
                />
                <ZButton
                  buttonType="Submit"
                  color="primary"
                  styleName="modalSave"
                  buttonText="Save Partner User"
                />
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </DialogContent>
  );
}

const styles = ({ palette, shape }) => ({
  errorClass: {
    minWidth: 150,
    padding: '7px 10px',
    marginTop: 19,
    marginLeft: 10,
    position: 'absolute',
    zIndex: 1,
    transform: 'translateY(-50%)',
    background: palette.error.light,
    color: palette.error.contrastText,
    borderRadius: shape.borderRadius,
  },
});

export default withStyles(styles)(PartnerUserEdit);
