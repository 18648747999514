import { Card as MUICard } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { default as MUIArrowForwardIosIcon } from '@material-ui/icons/ArrowForwardIos';
import {
  downloadProtectedFile,
  fetchProtectedBuildsPage,
} from 'api/zShieldService';
import { selectedTeam as selectedTeamAtom } from 'atoms/teams';
import { AxiosResponse } from 'axios';
import classnames from 'classnames';
import DrawerCard from 'components/UI/DrawerCard';
import DrawerHeader from 'components/UI/DrawerHeader';
import DrawerSkeleton from 'components/UI/DrawerSkeleton';
import GenericError from 'components/UI/GenericErrorBox';
import { DrawerContext } from 'components/drawer/DrawerProvider';
import { IDrawerContext } from 'components/drawer/DrawerProvider/models';
import { IState, ITeamsHash } from 'components/main/integrations/emm/emmTable/MDM/models';
import useGroupsByTeamId from 'components/modals/ZShieldRegisterApp/useGroupsByTeamId';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import { toggleModalDirect } from 'utils/storeUtils';
import ProtectionCoverage from '../ProtectionCoverage';
import { EPROTECTION_STATUSES_ENUM, IzShieldOSType } from '../ZShieldApps/models';
import { showConfirmDeleteModal } from '../ZShieldApps/utils';
import BuildButtons from './BuildButtons';
import DetailTable from './DetailTable';
import { IAppBuildsData, IBuildData, IZShieldAppDrawerData } from './models';
import { useStyles } from './useStyles';
import { protectionCoverageSelectionHelper } from './utils';


const ZShieldAppDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const selectedTeamId = useRecoilValue(selectedTeamAtom) || undefined;
  const [loading, setLoading] = useState(true);
  const [mostRecentBuild, setMostRecentBuild] = useState<IBuildData | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [olderVersions, setOlderVersions] = useState<IBuildData[]>([]);
  const showDownloadReminder = useSelector(
    (state: any) =>
      state?.uiSettings?.reminderSettings.showDownloadReminder ?? true
  );
  const enableDownloadsStatusButton = [EPROTECTION_STATUSES_ENUM.OUTDATED_ZSHIELD_VERSION.value, EPROTECTION_STATUSES_ENUM.READY.value];
  // need to match up the teamId and groupId w/ the actual name

  const { groupsHash } = useGroupsByTeamId(selectedTeamId);
  const teamsHash: ITeamsHash = useSelector(
    ({ teams }: IState) => teams?.available ?? {}
  );
  const classes: any = useStyles();
  const { drawerData, closeDrawer } =
    useContext<Partial<IDrawerContext<IZShieldAppDrawerData>>>(DrawerContext);
  const protectedAppRowData: IZShieldAppDrawerData | null = drawerData ?? null;
  const buildOs = protectedAppRowData?.os ?? IzShieldOSType.ANDROID;
  const mostRecentCoverages = protectionCoverageSelectionHelper(mostRecentBuild);
  const closeDrawerOnLogout = useCallback(() => {
    if (!closeDrawer) {
      return;
    }
    closeDrawer();
  }, [closeDrawer]);

  useEffect(() => {
    if (!protectedAppRowData?.id) {
      return;
    }
    // TODO Improve this
    // fetches builds data here.  do we need page query params?
    // they are defaulted in the zshieldService.js

    fetchProtectedBuildsPage({
      appId: protectedAppRowData.id,
      teamId: selectedTeamId
    }).then((response: AxiosResponse<IAppBuildsData>) => {
      setLoading(false);
      // the first build given is the most recents
      if (response?.data?.content?.[0]) {
        const builds = response.data.content.map((build) => {
          return {
            ...build,
            appName: protectedAppRowData?.name,
            bundleId: protectedAppRowData?.bundleId,
            teamName: teamsHash[`${build?.teamId}`]?.name ?? 'N/A',
            groupName: groupsHash[`${build?.groupId}`] as unknown as string ?? 'N/A'
          };
        });

        setMostRecentBuild(builds[0]);
        const currentBuildStatus = builds[0]?.state;
        if (currentBuildStatus === EPROTECTION_STATUSES_ENUM.ERRORED.value) {
          const errorMessage = drawerData?.data?.errorMessage || EPROTECTION_STATUSES_ENUM.ERRORED.tooltip;
          setErrorMessage(errorMessage);
        }
        setOlderVersions(builds.splice(1));
      }
    });
  }, [protectedAppRowData, drawerData?.data?.errorMessage, selectedTeamId, groupsHash, teamsHash]);

  useEffect(() => {
    window.addEventListener(`auth:logout`, closeDrawerOnLogout);

    return () => {
      window.removeEventListener(`auth:logout`, closeDrawerOnLogout);
    };
  }, [closeDrawerOnLogout]);

  const hideDownloadReminder = () => {
    dispatch({ type: 'HIDE_DOWNLOAD_REMINDER' });
  };

  const handleDelete = (mostRecentBuild: IBuildData) => {
    if (!closeDrawer) {
      return;
    }
    closeDrawer();
    showConfirmDeleteModal({
      buildId: mostRecentBuild.id,
      appName: mostRecentBuild.appName,
    });
  };

  const handleDownload = async (build: IBuildData): Promise<any> => {
    if (!closeDrawer) {
      return;
    }
    closeDrawer();
    if (showDownloadReminder) {
      toggleModalDirect(
        'GenericReminder',
        {
          confirmButtonText: 'Download',
          doNotRemindText: "Don't remind me again when downloading app",
          headerText: 'Remember to Sign',
          messageText:
            'Re-sign your app now that Zimperium has finished securing it.',
          onCancel: () => toggleModalDirect('GenericReminder', false),
          onConfirm: () => downloadBuild(build),
          onDisableReminder: () => hideDownloadReminder(),
        },
        {}
      );
    } else {
      downloadBuild(build);
    }
  };

  const downloadBuild = async (build: IBuildData): Promise<any> => {
    try {
      const response = await downloadProtectedFile({ id: build.id });
      const link = document.createElement('a');
      link.href = response.data.url;
      link.setAttribute('download', response.data.name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.log(e);
    } finally {
      toggleModalDirect('GenericReminder', false);
    }
  };

  const handleClose = () => {
    if (!closeDrawer) {
      return;
    }
    closeDrawer();
  };

  if (loading) {
    return (
      <DrawerSkeleton
        onClose={handleClose}
        os={IzShieldOSType.ANDROID}
        title={protectedAppRowData?.name ?? ''}
      />
    );
  }

  if (!mostRecentBuild) {
    return (
      <>
        <DrawerHeader
          onClose={handleClose}
          os={buildOs}
          title={protectedAppRowData?.name ?? ''}
        />
        <div style={{ marginTop: 15 }}>
          <DrawerCard title="No Builds"></DrawerCard>
        </div>
      </>
    );
  }

  return (
    <>
      <DrawerHeader
        onClose={handleClose}
        os={buildOs}
        title={protectedAppRowData?.name ?? ''}
      />
      <div className={classes.currentBuildSection}>
        <BuildButtons
          downloadDisabled={!enableDownloadsStatusButton.includes(mostRecentBuild?.status)}
          onDelete={() => handleDelete(mostRecentBuild)}
          onDownload={() => handleDownload(mostRecentBuild)}
        />
        <DetailTable data={mostRecentBuild} />
        <div className={classnames({ [classes.errorWrapper]: !!errorMessage })} >
          <GenericError errorMessage={errorMessage} />
        </div>
        <DrawerCard title="Protection Coverage">
          <ProtectionCoverage checkBoxValues={mostRecentCoverages} buildOs={buildOs} />
        </DrawerCard>
      </div>
      {olderVersions && olderVersions.length > 0 && (
        <Accordion
          style={{
            margin: '20px -10px 0px -10px', // These margins won't apply through classes or className
          }}
        >
          <MUICard classes={{ root: classes.accordionSummaryCard }}>
            <AccordionSummary expandIcon={<MUIArrowForwardIosIcon />}>
              Older Versions ({olderVersions.length})
            </AccordionSummary>
          </MUICard>
          <AccordionDetails classes={{ root: classes.accordionDetails }}>
            {olderVersions.map((singleBuild) => {
              const singleBuildCoverages = protectionCoverageSelectionHelper(singleBuild);
              return (
                <DrawerCard
                  insideAccordian
                  key={singleBuild.id}
                  title={`Version ${singleBuild.version}`}
                >
                  <BuildButtons
                    downloadDisabled={!enableDownloadsStatusButton.includes(mostRecentBuild?.status)}
                    onDelete={() => handleDelete(singleBuild)}
                    onDownload={() => handleDownload(singleBuild)}
                  />
                  <DetailTable data={singleBuild} />
                  <div style={{ paddingTop: '10px' }}>
                    <ProtectionCoverage checkBoxValues={singleBuildCoverages} buildOs={buildOs} />
                  </div>
                </DrawerCard>
              );
            })}
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};
export default ZShieldAppDrawer;