import { ServiceHandlerFactory } from 'utils/serviceUtils';
import { fetchThreatsParamsMapping } from 'mappings/services/threatServiceMappings';

const SVCThreatBase = 'api/threats/v1';

export const fetchCriticalRiskyEvents = ServiceHandlerFactory({
  axios: {
    url: `${SVCThreatBase}/stats/critical-risky-events`,
    method: 'get',
  },
  paramSchema: [{ path: 'teamId', paramKey: 'teamId.keyword' }],
});

export const fetchThreatsPage = ServiceHandlerFactory({
  axios: {
    url: `${SVCThreatBase}/threats`,
  },
  isCancelable: true,
  paramSchema: fetchThreatsParamsMapping,
});

export const fetchThreatsStats = ServiceHandlerFactory({
  axios: {
    url: `${SVCThreatBase}/stats`,
  },
  isCancelable: true,
  paramSchema: fetchThreatsParamsMapping,
});

export const fetchThreatVectors = ServiceHandlerFactory({
  axios: {
    url: `${SVCThreatBase}/stats/vectors`,
  },
  isCancelable: true,
  paramSchema: fetchThreatsParamsMapping,
});

export const fetchThreatsByAppByTime = ServiceHandlerFactory({
  axios: {
    url: `${SVCThreatBase}/stats/threats/byapp`,
  },
  isCancelable: true,
  paramSchema: fetchThreatsParamsMapping,
});

export const fetchAggrByAppInstances = ServiceHandlerFactory({
  axios: {
    url: `${SVCThreatBase}/stats/threats/byappinstances`,
  },
  isCancelable: true,
  paramSchema: fetchThreatsParamsMapping,
});

export const downloadThreatsCsv = ServiceHandlerFactory({
  axios: {
    url: `${SVCThreatBase}/threats/csv`,
    method: 'post',
  },
  isCancelable: true,
  paramSchema: fetchThreatsParamsMapping,
});

export const downloadThreatsCsvCount = ServiceHandlerFactory({
  axios: {
    url: `${SVCThreatBase}/threats/csv/count`,
    method: 'post',
  },
  isCancelable: true,
  paramSchema: fetchThreatsParamsMapping,
});

export const manualUpdateThreat = ServiceHandlerFactory({
  axios: {
    url: `${SVCThreatBase}/threats/:threatId/status`,
    method: 'put',
  },
});

export const auditLogRequest = ServiceHandlerFactory({
  axios: {
    url: `${SVCThreatBase}/threats?module=ZIPS&search=:eventId&after=:after`,
  },
});
