import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const styles = ({ palette, config }) => {
  return {
    wrapper: {
      background: palette.background.paper,
      outline: 'none',
      minWidth: '50vw',
      maxHeight: 400,
      overflowY: 'scroll',
    },
    tableCell: {
      borderBottomColor: 'transparent',
    },
    tableHeaderCell: {
      color: palette.text.secondary,
      fontWeight: 'bold',
      borderBottomColor: 'transparent',
    },
    tableRow: {
      height: config.textSizes.paragon,
    },
    emptyText: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '5vh',
    },
  };
};

class NetworkStatus extends Component {
  render() {
    const { classes, threat } = this.props;
    const noData = 'Network Status data not available';
    return (
      <div className={classes.wrapper}>
        <Table>
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableHeaderCell}>Proto</TableCell>
              <TableCell className={classes.tableHeaderCell}>Send-Q</TableCell>
              <TableCell className={classes.tableHeaderCell}>
                Local Address
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>State</TableCell>
              <TableCell className={classes.tableHeaderCell}>
                Foreign Address
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>Recv-Q</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {threat && threat.networkStatistics ? (
              threat.networkStatistics.map((networkStat, index) => {
                return (
                  <TableRow key={index} className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>
                      {networkStat.protocol}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {networkStat.sendQ}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {networkStat.localAddress}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {networkStat.state}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {networkStat.foreignAddress}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {networkStat.recvQ}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow className={classes.tableRow}>
                <TableCell
                  className={classes.tableCell}
                  padding="dense"
                  colSpan={6}
                >
                  <span className={classes.emptyText}>{noData}</span>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default withStyles(styles)(NetworkStatus);
