import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

export default state => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      devices: {
        ...state.uiSettings.devices,
        tableHeaders: uiSettingsDefaults.devices.tableHeaders,
      },
    },
  };
};
