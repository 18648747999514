import React, { Component } from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';

class ThreatLocation extends Component {
  render() {
    const { lng, lat, zoom } = this.props;
    return (
      <GoogleMap defaultZoom={zoom} defaultCenter={{ lat, lng }}>
        <Marker position={{ lat, lng }} />
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(ThreatLocation));
