//import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import Visibility from '@material-ui/icons/Visibility';
import { truncateString } from 'components/main/integrations/emm/AddEditCloneEMMAuth/utils';
import { toggleModalDiffered } from 'utils/storeUtils';

export const ZScanInstancesColumnMapping = [
  {
    path: 'value',
    coulmnContent: ({ rowData }: any) => {
      <Tooltip title={rowData?.value} className="hover--link">
        <div>{truncateString(rowData?.value)}</div>
      </Tooltip>;
    },
  },
  {
    path: 'actions',
    columnContent: ({ rowData }: any) => {
      if (!rowData?.codeSnippets?.length) {
        return null;
      }
      return (
        <>
          <Tooltip title="View Code Snippet">
            <span style={{ paddingLeft: 23 }}>
              <Visibility
                className="table__os-icon cursor--pointer"
                onClick={toggleModalDiffered(
                  'ZScanSnippets',
                  { rowData },
                  {
                    fullWidth: true,
                  }
                )}
              />
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

export const zScanInstancesTableHeaders = [
  {
    id: 'value',
    label: 'Location',
    show: true,
    path: 'value',
    sort: true,
    minWidth: 300,
    maxWidth: 250,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    id: 'actions',
    label: 'Actions',
    show: true,
    path: 'actions',
    sort: true,
    minWidth: 125,
    resizable: true,
    flex: 1,
  },
];
