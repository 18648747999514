import { getActiveModalAtom } from 'atoms/modals';
import AlertIcon from 'components/UI/icons/AlertIcon';
import Modal from 'components/UI/Modal';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import Form from './Form';
import { IZScanPolicyCreateCloneModalData } from './models';
import useStyles from './useStyles';

export const AddClonePolicyZScan_TITLE = 'AddClonePolicyZScan';

const activeModalAtom = getActiveModalAtom<IZScanPolicyCreateCloneModalData>();

const AddClonePolicyGeneric: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [externalError, setExternalError] = useState<string | unknown | any>();
  const [activeModal, setActiveModal] = useRecoilState(activeModalAtom);

  const handleSubmit = activeModal?.payload?.onSubmit;
  const handleClose = useCallback(() => {
    setExternalError(undefined);
    setActiveModal(undefined);
  }, [setExternalError, setActiveModal]);

  return (
    <Modal
      caption={
        activeModal?.payload?.cloningPolicy
          ? t('MTD.POLICIES.CLONE_EXISTING')
          : t('MTD.POLICIES.CREATE_NEW_POLICY')
      }
      title={AddClonePolicyZScan_TITLE}
      onClose={handleClose}
    >
      {!!externalError && (
        <div className={classes.errors}>
          <span>
            {externalError?.error.match(/conflict/i)
              ? t('MTD.POLICIES.THERE_IS_ALREADY_POLICY_SELECT_NEW')
              : externalError?.message ?? externalError}
          </span>
          <AlertIcon />
        </div>
      )}
      <Form
        cloningPolicy={!!activeModal?.payload?.cloningPolicy}
        defaultName={activeModal?.payload?.defaultName}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
    </Modal>
  );
};

export default AddClonePolicyGeneric;
