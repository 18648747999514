import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    parentHeader: {
      display: 'flex',
      gap: '20px',
      marginBottom: '20px',
    },
    cardHeaderLogo: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '25px',
      width: '120px',
      height: '128px',
      padding: '20px 0px 10px 30px',
      alignItems: 'center',
    },
    fieldStyling: {
      width: '100%',
      '&>label>span': {
        color: palette.text.secondary,
      },
    },
    subHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      height: '472px',
      gap: '30px',
    },
    leftBoxStyling: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      height: '406px',
      minWidth: '415px',
      maxWidth: '450px',
    },
    radioGroupStyling: {
      display: 'flex',
      flexDirection: 'row',
      padding: '10px',
      marginTop: '-25px',
    },
    checkBoxListStyling: {
      margin: '0px 10px',
      height: '270px',
      padding: '0px 10px',
      overflowY: 'scroll',
      '  &::-webkit-scrollbar ': {
        width: 5,
      },

      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
        borderRadius: '10px',
      },

      ' &::-webkit-scrollbar-thumb': {
        backgroundColor: '#8e8e8e',
        outline: '1px solid #8e8e8e',
        borderRadius: '10px',
      },
    },
    exceededCard: {
      alignItems: 'center',
      color: palette.text.secondary,
      backgroundColor: palette.background.default,
      border: `1px solid ${palette.primary.border}`,
      display: 'flex',
      fontSize: '12px',
      marginTop: '-20px',
      position: 'relative',
      marginBottom: '20px',
    },
    subCheckBoxStyling: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 'auto',
    },
    ruleMatchCardStyle: {
      display: 'flex',
      marginTop: '-20px',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '12px',
      marginBottom: '20px',
      color: palette.text.secondary,
      border: `1px solid ${palette.common.android}`,
      padding: '10px',
      backgroundColor: palette.background.default,
    },
    buttonPanel: {
      alignItems: 'flex-start',
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '20px 0 20px',
    },
    topButtonStyling: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '80px',
    },
    bottomButtonStyling: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '100px',
    },
    rightBoxStyling: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      marginTop: '20px',
    },
    subRightBoxSize: {
      width: '505px',
      height: '155px',
      overflowY: 'scroll',
      padding: '0 10px',
      '  &::-webkit-scrollbar ': {
        width: 5,
        height: 5,
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
        borderRadius: '10px',
      },
      ' &::-webkit-scrollbar-thumb': {
        backgroundColor: '#8e8e8e',
        outline: '1px solid #8e8e8e',
        borderRadius: '10px',
      },
    },
    warningIcon: {
      margin: '0px 10px 0px 5px',
      '& > svg': {
        fill: `${palette.common.critical}`,
      },
    },
    cardOutlined: {
      border: `1px solid ${palette.common.critical}`,

      '& > button': {
        color: palette.common.critical,
        cursor: 'pointer',
        minWidth: 0,
        position: 'absolute',
        right: 0,
        userSelect: 'none',

        '& svg': {
          margin: 0,
        },
      },
    },
    accordion: {
      boxShadow: 'none',
      // margin: '10px -10px 0 -10px !important',
      marginBottom: '10px',
      color: palette.text.secondary,
    },
    noAppTitle: {
      maxWidth: '42ch',
      fontSize: '14px',
      lineHeight: 1.5,
    },
    noAppTitleHeader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '100px',
    },
    accordionHeader: {
      backgroundColor: palette.tables.header.background,
      border: `1px solid ${palette.tables.border}`,
      color: palette.text.secondary,
      padding: '10px',
      minHeight: '10px !important',
      '& .MuiAccordionSummary-expandIcon': {
        '&.Mui-expanded': {
          transform: 'rotate(90deg)',
        },
      },
      '& svg': {
        width: 14,
      },
    },
    accordionDetails: {
      backgroundColor: palette.background.default,
      display: 'flex',
      flexDirection: 'column',
      padding: '0px 16px',
    },
    disableIconStyle: {
      opacity: '30%',
      '& > svg': { minWidth: '20px', maxWidth: '20px' },
    },
    searchBox: {
      padding: '0px 20px',
      '& > div > div > input': { height: '0px' },
    },
    buttonStyling: {
      borderRadius: '56%',
      padding: '12px',
      background: palette.common.darkGreySoft,
    },
    rulesStyling: {
      fontSize: '12px',
      color: palette.text.secondary,
      textAlign: 'center',
      paddingTop: '10px',
    },
    rightboxHeader: {
      fontSize: '14px',
      fontWeight: 'bold',
      color: palette.text.secondary,
      marginBottom: '-2px',
      '& ~ div': {
        marginTop: '10px',
      },
    },
    markTitle: {
      marginBottom: '0px',
      fontSize: '14px',
      color: palette.text.secondary,
    },
    appleIcon: {
      '& > svg': {
        fill: palette.common.lightCyan,
        stroke: palette.common.lightCyan,
        minWidth: '20px',
        maxWidth: '20px',
      },
    },
    androidIcon: {
      '& > svg': {
        fill: palette.common.android,
        minWidth: '20px',
        maxWidth: '20px',
      },
    },
  })
);

export default useStyles;
