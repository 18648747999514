import AppsIcon from '@material-ui/icons/Apps';
import DashboardIcon from '@material-ui/icons/BarChart';
import ThreatIcon from '@material-ui/icons/Camera';
import DeviceIcon from '@material-ui/icons/PhonelinkRing';
import MUIWarningIcon from '@material-ui/icons/ReportProblemOutlined';
import IntegrationsIcon from '@material-ui/icons/SettingsInputComposite';
import ActivationsIcon from '@material-ui/icons/TouchApp';
import PolicyIcon from '@material-ui/icons/VerifiedUser';
import Activations from 'components/main/activations/Activations';
import AppsInventory from 'components/main/appsInventory/AppsInventory';
import Devices from 'components/main/devices/Devices';
import MTDInsights from 'components/main/MTDInsights';
import MDM from 'components/main/integrations/emm/emmTable/MDM';
import OSRisk from 'components/main/os-risk/OSRisk';
import Policies from 'components/main/policies/Policies';
import Threats from 'components/main/threats/Threats';
// components

export const CONSOLE_MTD_ROUTES = [
  {
    path: '/console/mtd',
    component: MTDInsights,
    exact: true,
    allow: 'any',
    rqps: ['zappId', 'duration'],
    nav: {
      name: 'GLOBAL.DASHBOARD',
      order: 1,
      icon: <DashboardIcon />,
    },
  },
  {
    path: '/console/mtd/activations/:tabId',
    component: Activations,
    exact: true,
    allow: { zapps: 'view' },
  },
  {
    path: '/console/mtd/activations/device-activations',
    component: Activations,
    exact: true,
    allow: { zapps: 'view' },
    rqps: ['page', 'size', 'order', 'orderBy'],
    nav: {
      name: 'GLOBAL.ACTIVATIONS',
      order: 2,
      icon: <ActivationsIcon />,
    },
  },
  {
    path: '/console/mtd/apps-inventory/:tabId',
    component: AppsInventory,
    exact: true,
    allow: { app_inventory: 'view' },
  },
  {
    path: '/console/mtd/apps-inventory/dashboard',
    component: AppsInventory,
    exact: true,
    allow: { app_inventory: 'view' },
    rqps: ['page', 'size', 'order', 'orderBy'],
    nav: {
      name: 'GLOBAL.APPS',
      order: 3,
      icon: <AppsIcon />,
    },
  },
  {
    path: '/console/mtd/devices',
    component: Devices,
    exact: true,
    allow: { devices: 'view' },
    rqps: ['page', 'size', 'zappId', 'order', 'orderBy', 'duration'],
    nav: {
      name: 'GLOBAL.DEVICES',
      order: 4,
      icon: <DeviceIcon />,
    },
  },
  {
    path: '/console/mtd/threats',
    component: Threats,
    exact: true,
    allow: { threats: 'view' },
    rqps: ['page', 'size', 'zappId', 'order', 'orderBy', 'duration'],
    nav: {
      name: 'GLOBAL.THREATS',
      order: 5,
      icon: <ThreatIcon />,
    },
  },
  {
    path: '/console/mtd/policies/:tabId',
    component: Policies,
    allow: { policies: 'view' },
  },
  {
    path: '/console/mtd/policies',
    component: Policies,
    exact: true,
    allow: { policies: 'view' },
    nav: {
      name: 'GLOBAL.POLICIES',
      order: 6,
      icon: <PolicyIcon />,
    },
  },
  {
    path: '/console/mtd/mdm',
    component: MDM,
    exact: true,
    allow: { emm: 'view' },
    nav: {
      name: 'GLOBAL.INTEGRATIONS',
      order: 10,
      icon: <IntegrationsIcon />,
    },
    requiredQueryParams: ['page', 'size', 'order', 'orderBy'],
  },
  {
    path: '/console/mtd/os-risk/:tabId',
    component: OSRisk,
    exact: true,
    allow: { os_risk: 'view' },
  },
  {
    path: '/console/mtd/os-risk/dashboard',
    component: OSRisk,
    exact: true,
    allow: { os_risk: 'view' },
    nav: {
      name: 'GLOBAL.OS_RISK',
      order: 11,
      icon: <MUIWarningIcon />,
    },
    requiredQueryParams: ['page', 'size', 'order', 'orderBy'],
  },
];
