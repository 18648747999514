import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import MaterialTab from '@material-ui/core/Tab';
import MaterialTabs from '@material-ui/core/Tabs';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import RolesTable from 'components/main/users/roles/RolesTable';
import ViewTeams from 'components/main/users/teams/ViewTeams';
import ViewUsers from 'components/main/users/users/ViewUsers';
import React from 'react';
import { Link } from 'react-router-dom';
import ProtectedRoute from 'routes/ProtectedRoute';
import withMemoizedProps from 'utils/withMemoizedProps';

const LinkTab = ({ allow, ...route }) => {
  return (
    <ProtectedComponent allow={allow}>
      <MaterialTab className="tabs-label" component={Link} {...route} />
    </ProtectedComponent>
  );
};

const Users = withStyles({
  tabsAppBar: {
    marginTop: 15,
    marginBottom: 15,
    background: 'none',
    boxShadow: 'none',
    position: 'relative',
    zIndex: 1,
  },
})(({ match, classes }) => (
  <div>
    <AppBar position="static" className={classes.tabsAppBar}>
      <MaterialTabs value={match.params.tabId}>
        <LinkTab
          disableRipple
          key="users-link-roles-1"
          label="Users"
          to="/console/manage/users/users"
          value="users"
          allow={{ users: 'view' }}
        />
        <LinkTab
          disableRipple
          key="users-link-roles-2"
          label="Roles"
          to="/console/manage/users/roles"
          value="roles"
          allow={{ roles: 'view' }}
        />
        <LinkTab
          disableRipple
          key="users-link-teams-3"
          label="Teams"
          to="/console/manage/users/teams"
          value="teams"
          allow={{ teams: 'view' }}
        />
      </MaterialTabs>
    </AppBar>
    {renderSubRoute(match.params.tabId)}
  </div>
));

function renderSubRoute(tabId) {
  switch (tabId) {
    case 'roles':
      return (
        <ProtectedRoute
          allow={{ roles: 'view' }}
          component={RolesTable}
          rqps={['page', 'size', 'orderBy', 'order']}
        />
      );
    case 'teams':
      return (
        <ProtectedRoute
          allow={{ teams: 'view' }}
          component={ViewTeams}
          rqps={['page', 'size', 'orderBy', 'order']}
        />
      );
    default:
      return (
        <ProtectedRoute
          allow={{ users: 'view' }}
          rqps={['page', 'size', 'orderBy', 'order']}
          component={ViewUsers}
        />
      );
  }
}

export default withMemoizedProps(Users, ['match.params', 'classes']);
