import { Api } from 'config/axiosConfig';

export const fetchPasswordPolicyForPasswordExpiry = email => {
  return Api.get(`/api/auth/v1/password_policy/passwordExpiry?email=${email}`);
};

export const fetchPasswordPolicyForCreateAccount = token => {
  return Api.get(
    `/api/auth/v1/password_policy/signup?validationToken=${token}`
  );
};

export const fetchPasswordPolicyForJoinAccount = token => {
  return Api.get(`api/auth/v1/password_policy/join?validationToken=${token}`);
};

export const fetchPasswordPolicyForResetPassword = validationToken => {
  return Api.get(
    `/api/auth/v1/password_policy/validatePasswordReset/${validationToken}`
  );
};

export const fetchPasswordPolicyForUserInvited = validationToken => {
  return Api.get(
    `/api/auth/v1/password_policy/validateUserInvited/${validationToken}`
  );
};

export const fetchPasswordPolicy = () => {
  return Api.get('/api/auth/v1/password_policy');
};

export const updateUserProfilePassword = account => {
  return Api.post('api/auth/v1/me/password', JSON.stringify(account));
};

export const updatePasswordPolicy = policy => {
  return Api.put('api/auth/v1/password_policy', policy);
};
