import { IColumnHeader, IFilterEditor } from 'components/UI/Table/models';
import {
  IFilterValues,
  IPartnerUserRowMapping,
  IResolveFiltersFn,
} from './models';

import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import momentHelper from 'utils/momentUtils';
import { toggleModalDirect } from 'utils/storeUtils';

export const partnerUsersAdminTableHeaders: IColumnHeader[] = [
  {
    id: 'partnerRole.name',
    label: 'Partner Role',
    show: true,
    flex: 1,
    idCSV: 'partnerRole.name',
    showInContextMenu: true,
    sort: true,
  },
  {
    filterEditor: 'StringFilter',
    flex: 1,
    id: 'email',
    showInContextMenu: true,
    label: 'Email',
    show: true,
    sort: true,
  },
  {
    filterEditor: 'StringFilter',
    flex: 1,
    id: 'firstName',
    label: 'First Name',
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    filterEditor: 'StringFilter',
    flex: 1,
    id: 'lastName',
    label: 'Last Name',
    showInContextMenu: true,
    show: true,
    sort: true,
  },
  {
    flex: 1,
    id: 'created',
    label: 'Created',
    showInContextMenu: true,
    show: true,
    sort: true,
  },
  {
    flex: 1,
    id: 'modified',
    showInContextMenu: true,
    label: 'Modified',
    show: true,
    sort: true,
  },
  {
    filterEditor: 'NumberFilter',
    flex: 1,
    showInContextMenu: true,
    id: 'accountPersonasCount',
    label: 'Persona Count',
    show: true,
    sort: false,
  },
  {
    id: 'actions',
    flex: 1,
    label: 'Actions',
    sort: false,
    show: true,
  },
];

export const partnerUsersRowMapping: IPartnerUserRowMapping[] = [
  {
    path: 'partnerRole.name',
    columnContent: (args) => {
      return (
        <span className="table-cell__ellipsis">
          {args.rowData?.partnerRole?.name ?? ''}
        </span>
      );
    },
  },
  {
    path: 'email',
    columnContent: (args) => (
      <span className="table-cell__ellipsis">{args.rowData.email}</span>
    ),
  },
  { path: 'firstName' },
  { path: 'lastName' },
  {
    path: 'created',
    columnContent: (args) => momentHelper(args.rowData.created),
  },
  {
    path: 'modified',
    columnContent: (args) => momentHelper(args.rowData.modified),
  },
  { path: 'accountPersonasCount' },
  {
    path: 'actions',
    columnContent: (args) => {
      return (
        <div className="flex--spaced">
          <Tooltip title="Edit Role or Add/Remove Accounts">
            <EditIcon
              className="hover--link"
              onClick={() =>
                toggleModalDirect(
                  'PartnerUserEdit',
                  {
                    rowData: args.rowData,
                    accountId: args.rowData.id,
                  },
                  {
                    title: 'Edit Partner User',
                    fullWidth: true,
                  }
                )
              }
            />
          </Tooltip>
          <Tooltip title="Disable user from being a partner">
            <DeleteIcon
              className="hover--link"
              onClick={() =>
                toggleModalDirect(
                  'PartnerUserDeleteConfirm',
                  {
                    rowData: args.rowData,
                    ...args.rowData,
                  },
                  {
                    title: 'Remove Partner User',
                    fullWidth: true,
                  }
                )
              }
            />
          </Tooltip>
        </div>
      );
    },
  },
];

export const resolveFilters: IResolveFiltersFn = (
  filterValues: IFilterValues
): IFilterEditor[] => {
  return [
    {
      name: 'email',
      operator: 'eq',
      type: 'string',
      value: filterValues?.email ?? '',
    },
    {
      name: 'firstName',
      operator: 'eq',
      type: 'string',
      value: filterValues?.firstName ?? '',
    },
    {
      name: 'lastName',
      operator: 'eq',
      type: 'string',
      value: filterValues?.lastName ?? '',
    },
  ];
};
