import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    isAllTime: {
      '& .calendar-container': {
        position: 'relative',
      },

      '& .calendar-container:before': {
        color: '#263238',
        // content: '"Disabled by Time Preset"',    // TODO uncomment the code when have an idea for it's translation
        left: '50%',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 20,
      },

      '& .calendar-container:after': {
        background: palette.calendar.presetBackground,
        content: '""',
        height: '100%',
        left: 0,
        opacity: 0.75,
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: 10,
      },
    },

    presets: {
      borderRight: `1px solid ${palette.calendar.borderColor}`,
      display: 'flex',
      flexDirection: 'column',
      maxHeight: 402,
      minWidth: 175,
      overflow: 'auto',
      padding: '10px 0 0 0',

      '& > button': {
        background: 'inherit',
        border: 0,
        boxShadow: 'none',
        color: palette.text.primary,
        fontSize: 16,
        margin: 0,
        padding: 10,
        paddingLeft: 20,
        textAlign: 'left',
      },

      '& > button:hover': {
        background: palette.calendar.presetHoverBackground,
        color: palette.calendar.presetHoverText,
        cursor: 'pointer',
      },
    },

    root: {
      background: palette.calendar.presetBackground,
      border: `1px solid ${palette.calendar.borderColor}`,
      borderRadius: 3,
      display: 'inline-flex',
      flexDirection: 'row-reverse',

      '& .Cal__Header__root': {
        padding: 10,
        minHeight: 'auto',
      },

      '& .Cal__Header__day': {
        fontSize: 24,
      },

      '& .Cal__Header__year': {
        fontSize: 16,
      },

      '& .Cal__Header__dateWrapper': {
        color: palette.calendar.headerText,
      },
    },

    selectedPreset: {
      background: palette.calendar.presetHoverBackground,
      color: palette.calendar.presetHoverText,
    },
  })
);

export default useStyles;
