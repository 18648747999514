import React, { PureComponent } from 'react';

// components
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ZButton from 'UI/Buttons/ZButton';

// utils
import { toggleModalDirect } from 'utils/storeUtils';

class CompareBuildsSelectTwo extends PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <DialogContent>
        <p>Please select two builds.</p>
        <DialogActions>
          <ZButton
            action={this.handleSubmit}
            buttonType="submit"
            color="primary"
            styleName="modalSave"
            buttonText="Close"
          />
        </DialogActions>
      </DialogContent>
    );
  }

  handleSubmit() {
    toggleModalDirect('CompareBuildsSelectTwo', false);
  }
}

CompareBuildsSelectTwo.defaultProps = {
  data: {},
};

export default CompareBuildsSelectTwo;
