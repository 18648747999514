import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

export default (state) => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      oocSelect: {
        ...uiSettingsDefaults.oocSelect,
        tableHeaders: uiSettingsDefaults.oocSelect.tableHeaders,
      },
    },
  };
};
