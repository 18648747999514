import { useTheme } from '@material-ui/core';
import React, { useMemo } from 'react';

interface IMultiLineTickProps {
  payload?: { value: string };
  x?: number;
  y?: number;
  linkBase?: string;
}

const MultiLineTick: React.FC<IMultiLineTickProps> = ({
  payload,
  x,
  y,
  // linkBase,
}) => {
  const theme = useTheme();

  const payloadIsMultiLine = useMemo(
    () => /\s/.test(payload?.value ?? ''),
    [payload]
  );

  const lineOne = useMemo(
    () =>
      payloadIsMultiLine
        ? payload?.value.split(/\s/)?.[0] ?? ''
        : payload?.value,
    [payload, payloadIsMultiLine]
  );

  const lineTwo = useMemo(
    () => (payloadIsMultiLine ? payload?.value.split(/\s/)?.[1] : ''),
    [payload, payloadIsMultiLine]
  );

  // const link = `${linkBase}${payload?.value.split(' ')[0]}`;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        fontSize={13}
        fill={theme.palette.text.primary}
        style={{ cursor: 'pointer' }}
      >
        <tspan textAnchor="middle" x="0">
          {lineOne}
        </tspan>
      </text>
      <text x={0} y={12} dy={16} fontSize={9} fill={theme.palette.text.primary}>
        <tspan textAnchor="middle" x="0" dy="20">
          {lineTwo}
        </tspan>
      </text>
    </g>
  );
};

export default MultiLineTick;
