/* eslint-disable max-lines-per-function */
import withRouter from 'components/hocs/withRouter';
import MUICard from '@material-ui/core/Card';
import Table from 'components/UI/Table';
import {
  IColumnHeader,
  IFilterEditor,
  ILocationQuery,
} from 'components/UI/Table/models';
import _ from 'lodash';
// import { SCOPE_BOUNDS } from 'mappings/scopeMapping';
import Select, { ISelectItem } from 'components/UI/input/Select';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { findingsTableColumnChange } from 'reducers/UiSettingsReducers';
import {
  getUISettingsTableHeaders,
  getUISettingsWithout,
} from 'reducers/UiSettingsSelectors';
import { bindActionCreators, compose } from 'redux';
import useBackfill from 'utils/useBackfill';
import { withDirtyState } from 'utils/withDirtyState';
import withMemoizedProps from 'utils/withMemoizedProps';
import { zScanFindingsColumnMapping } from './zScanFindings.mappings';
import useAsyncResult from 'utils/useAsyncResult';
import {
  IFilterValues,
  ILocation,
  IQueryParams,
  ISelectPolicy,
  IZScanFindings,
} from './models';
import tableQuery from './tableQuery';
import useStyles from './useStyles';
import { zScanFindingsDataFilterMappings } from './utils';

import Button from 'components/UI/Button';
import { Link } from 'react-router-dom';
import useAssessmentDetails from './useAssessmentDetails';
import FindingsAssessmentsDetailsCard from './FindingsAssessmentsDetailsCard';
import FindingSideBar from './FindingSidebar';
import FilterChipBox from '../../common/FilterChipBox';
import GenericCard from 'components/UI/GenericCard';

import GlobalIndicatorWrapper from 'components/main/common/GlobalIndicatorWrapper';
// import Checkbox from 'components/UI/input/Checkbox';
import ActionReport from 'components/shared/ActionReport';
import {
  createTicketForFinding,
  generateAssessmentJSONReportByAppId,
  generateAssessmentPDFReport,
  generateSBOMPDF,
  getIntegrationConfigsData,
  getSBOM,
} from 'api/zDevService';
import { generateJsonFile } from 'utils/componentUtils';
import { openSnackBar } from 'utils/storeUtils';
import { getPolicies } from 'api/zDevService';
import { IFilterMapping } from 'components/main/common/FilterChipBox/models';

import SearchBox from 'components/UI/input/SearchBox';
import { truncateString } from 'components/main/integrations/emm/AddEditCloneEMMAuth/utils';
import { Tooltip } from '@material-ui/core';
import PlatformIcon from '../PlatformIcon';
import CustomPolicyOption from 'components/main/policies/common/CustomPolicyOption';
import { useTranslation } from 'react-i18next';
import FindingsTableAction from './FindingsTableAction';
import { TableAction } from './FindingsTableAction/utils';
import CreateTicket, {
  CreateTicket_TITLE,
} from './createTicket/CreateTicketModal';
import { getActiveModalAtom } from 'atoms/modals';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AxiosResponse } from 'axios';
import { IintegrationConfiguration } from './createTicket/models';
import { appTeamId } from '../atoms/appTeamId';

interface IZScanFindingsProps {
  location: ILocation;
  findingsTableColumnChange: (...args: unknown[]) => void;
  findingsUiSettings: { [key: string]: unknown };
  currentTableHeaders: IColumnHeader[];
  definedUser?: string;
  jiggleDirtyState: (...args: any) => void;
  q: { [key: string]: unknown };
  rqps: string[];
  scopes: { [key: string]: string[] };
  updateUrl: (params: IQueryParams) => void;
  scopeBounds: string;
  match: any; // IZScanAssessmentMatch;
}

interface TRowData {
  businessImpact: string;
  categoryId: string;
  categoryName: string;
  compliance: any[];
  created: number;
  cvss20Score: string;
  cvss31Score: string;
  findingDescription: string;
  findingId: string;
  id: string;
  isTicketed: boolean;
  locations: any[];
  modified: number;
  name: string;
  platform: string[];
  privacy: boolean;
  recommendation: string;
  security: boolean;
  severity: string;
  severityOrdinal: number;
  subcategoryId: string;
  subcategoryName: string;
}
const ZScanFindings: React.FC<IZScanFindingsProps> = ({
  findingsTableColumnChange,
  findingsUiSettings,
  currentTableHeaders,
  definedUser,
  jiggleDirtyState,
  q: query,
  location,
  rqps,
  updateUrl,
  match,
  // scopeBounds,
}) => {
  useBackfill({
    query,
    requiredQueryParams: rqps,
    storedQueryParams: findingsUiSettings,
    updateUrl,
  });

  const { ready, t } = useTranslation();
  const [selectedTableAction, setSelectedTableAction] = useState<TableAction>();
  const [totalDataCount, setTotalDataCount] = useState<number>(0);
  const assessmentId: string = match?.params?.assessmentId;
  const { assessmentDetails } = useAssessmentDetails(assessmentId);
  const [reportPending, setReportPending] = useState<boolean>(false);
  const [selectFinding, setSelectFinding] = useState<
    IZScanFindings | undefined
  >();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [preSelectFirstRowData, setPreSelectFirstRowData] =
    useState<boolean>(true);
  interface ISelectProvider {}
  const activeModalAtom = getActiveModalAtom<ISelectProvider>();
  const setActiveModal = useSetRecoilState(activeModalAtom);

  const classes = useStyles();
  const getPolicyLens = useAsyncResult(getPolicies);
  const selectPolicyLens: ISelectItem[] | undefined = getPolicyLens?.data?.map(
    ({ id, name, teamId }: ISelectPolicy) => {
      return { label: name, value: id, accountBounded: !teamId };
    }
  );
  const [selected, setSelected] = useState<{
    [key: string | number]: TRowData;
  }>({});
  // const [selectedRow, setSelectedRow] = useState<TRowData[]>([]);
  const [configurations, setConfigurations] = useState<
    IintegrationConfiguration[]
  >([]);
  const [renderFindingDetails, setRrenderFindingDetails] = useState<number>(1);

  const updateRenderFindingDetails = () => {
    setRrenderFindingDetails((prevCounter) => prevCounter + 1);
  };

  // on a refresh we do not want the policy ID to stay in the url
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => updateUrl({ policyId: '' }), []);

  const handleColumnChange = (...args: unknown[]) => {
    findingsTableColumnChange(...args);
    jiggleDirtyState();
  };

  const teamIdFromRecoil = useRecoilValue(appTeamId);

  const fetchIntegrationConfigsData = useCallback(async () => {
    try {
      const response: AxiosResponse = await getIntegrationConfigsData({
        teamId: teamIdFromRecoil,
      });
      setConfigurations(response.data.length ? response?.data : []);
    } catch (error) {
      console.error('Error in fetching integration configs data: ', error);
    }
  }, [teamIdFromRecoil]);

  useEffect(() => {
    fetchIntegrationConfigsData();
  }, [fetchIntegrationConfigsData]);

  const handleDownloadReport = useCallback(
    (json = false) => {
      if (json) {
        const id = assessmentDetails?.appId;
        setReportPending(true);
        return generateAssessmentJSONReportByAppId({
          id,
          policyId: query?.policyId ?? '',
        })
          .then(({ data }: any) => {
            setReportPending(false);
            const packageName = _.get(data, 'basic_info.name', 'package-');
            const appVersion = _.get(data, 'basic_info.version', 'version');
            const checkSum = _.get(data, 'basic_info.md5', 'report');
            const fileName = `${packageName}-${appVersion}-${checkSum}.json`;
            generateJsonFile(fileName, data);
          })
          .catch((err: any) => console.log('err', err));
      }
      setReportPending(true);
      generateAssessmentPDFReport({
        id: assessmentDetails?.id,
        policyId: query?.policyId ?? '',
      })
        .then(({ data }: any) => {
          setReportPending(false);
          window.open(data.cdn_link, '_blank');
        })
        .catch((err: any) => console.log('err', err));
    },
    [assessmentDetails?.appId, assessmentDetails?.id, query?.policyId]
  );

  const handleSBOMDownloadReport = useCallback(
    (assessmentId: string, includeVulnerabilities: boolean) => {
      setReportPending(true);
      // policy id does not apply here
      generateSBOMPDF({ assessmentId, includeVulnerabilities })
        .then(({ data }) => {
          setReportPending(false);
          window.open(data.cdn_link, '_blank');
        })
        .catch((err) => {
          setReportPending(false);
          openSnackBar('Something went wrong. SBOM not found.');
          console.log('err', err);
        });
    },
    []
  );

  const handleActionReport: any = useCallback(
    async (actionType: any) => {
      if (!assessmentId) {
        return;
      }

      switch (actionType) {
        case 'download-sbom':
          try {
            setReportPending(true);
            const resSBOM = await getSBOM({
              assessmentId,
              includeVulnerabilities: false,
            });

            if (resSBOM.status === 200 && resSBOM.data) {
              generateJsonFile('bom.json', resSBOM.data);
            }
          } catch (e) {
            openSnackBar('Can not find sbom');
          } finally {
            setReportPending(false);
          }
          break;
        case 'download-e-sbom':
          try {
            setReportPending(true);
            const resESBOM = await getSBOM({
              assessmentId,
              includeVulnerabilities: true,
            });

            if (resESBOM.status === 200 && resESBOM.data) {
              generateJsonFile('bom-enriched.json', resESBOM.data);
            }
          } catch (e) {
            openSnackBar('Can not find sbom');
          } finally {
            setReportPending(false);
          }
          break;
        case 'download-pdf':
          handleDownloadReport();
          break;
        case 'download-json':
          handleDownloadReport(true);
          break;
        case 'download-sbom-pdf':
          handleSBOMDownloadReport(assessmentId, false);
          break;
        case 'download-e-sbom-pdf':
          handleSBOMDownloadReport(assessmentId, true);
          break;
        default:
          break;
      }
    },
    [assessmentId, handleDownloadReport, handleSBOMDownloadReport]
  );

  const handleSearch = useCallback(
    (value: string) => {
      updateUrl({ search: value ?? '' });
      setActiveIndex(0);
      setPreSelectFirstRowData(true);
    },
    [updateUrl]
  );

  const handleDeleteQueryParam = useCallback(
    (paramName: string) => {
      const queryParams = location.query;

      updateUrl({ ...queryParams, [paramName]: undefined });
      setActiveIndex(0);
      setPreSelectFirstRowData(true);
    },
    [updateUrl, location.query]
  );

  const requestToCreateTicket = useCallback(
    async (integrationConfigId: string) => {
      await createTicketForFinding(
        {},
        {
          appId: assessmentDetails?.appId,
          integrationConfigId: integrationConfigId,
          assessmentId: assessmentId,
          findingIds: Object.values(selected).map((row) => String(row.id)),
        }
      )
        .then(() => {
          openSnackBar('Created ticket successfully');
          setSelected({});
          updateRenderFindingDetails();
        })
        .catch(() => {
          openSnackBar('There was an error while creating ticket!');
          setSelected({});
        });
    },
    [assessmentDetails?.appId, assessmentId, selected]
  );

  const onGoClick = useCallback(async () => {
    switch (selectedTableAction) {
      case TableAction.CreateTicketForSelectedRows: {
        if (Object.values(selected).length > 50) {
          openSnackBar(
            "Can't create ticket for more then 50 findings in one ticket."
          );
        } else if (configurations.length === 1) {
          requestToCreateTicket(configurations[0].id);
        } else {
          setActiveModal({
            active: CreateTicket_TITLE,
            payload: {},
          });
        }
        break;
      }
      default:
        break;
    }
  }, [
    configurations,
    requestToCreateTicket,
    selected,
    selectedTableAction,
    setActiveModal,
  ]);

  const handleFilterChange = useCallback((filterEditors: IFilterEditor[]) => {
    const newFilterValues: IFilterValues = {};
    filterEditors.forEach(({ name, value }) => {
      newFilterValues[name] = value;
    });
  }, []);

  const handleSelectRow = useCallback((fullRow: any) => {
    setSelectFinding(fullRow.data);
  }, []);

  // const handleSelectionChange = useCallback((selectedRows: any) => {
  //   setSelectedRow(Object.values(selectedRows));
  // }, []);

  const handleChange = useCallback(
    (_, value: ISelectItem) => {
      if (value?.value) {
        updateUrl({
          policyId: String(value?.value) ?? '',
        });
        setActiveIndex(0);
        setPreSelectFirstRowData(true);
      } else {
        updateUrl({
          policyId: '',
        });
        setActiveIndex(0);
        setPreSelectFirstRowData(true);
      }
    },
    [updateUrl]
  );

  const isGoButtonDisable = useCallback(() => {
    switch (selectedTableAction) {
      case TableAction.CreateTicketForSelectedRows:
        return Object.values(selected).length < 1; // Explicitly return boolean
      default:
        return true;
    }
  }, [selected, selectedTableAction]);

  const isCreateTicketDisable = useCallback(() => {
    if (Object.values(selected).length < 1 || configurations.length === 0) {
      return true;
    } else {
      return false;
    }
  }, [configurations.length, selected]);

  const clearSelectedRows = useCallback(() => {
    setSelected({});
  }, []);

  if (!ready) {
    return null;
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className={classes.headerContainer}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
            }}
          >
            <div>
              <PlatformIcon
                iconType={
                  assessmentDetails?.zdevMetadata?.osName?.toLowerCase() ?? ''
                }
              />
            </div>
            <h1>
              {
                <Tooltip title={assessmentDetails?.zdevMetadata?.appName ?? ''}>
                  <span>
                    {truncateString(
                      assessmentDetails?.zdevMetadata?.appName + ' ' ?? ''
                    )}
                  </span>
                </Tooltip>
              }
              - Findings
            </h1>
          </div>
          <FindingsAssessmentsDetailsCard
            assessmentDetails={assessmentDetails}
          />
        </div>
        <div
          style={{
            display: 'flex',
            gap: '10px',
            alignSelf: 'end',
          }}
        >
          <ActionReport
            handleActionReport={handleActionReport}
            reportPending={reportPending}
          />
          <Link
            to={`/console/zscan/assessments?appId.keyword=${assessmentDetails?.appId}`}
          >
            <Button
              text="GO BACK TO ASSESSMENTS"
              color="secondary"
              // icon={CloudUpload}
            />
          </Link>
        </div>
      </div>
      <GenericCard noPadding>
        <div className={classes.mainContainer}>
          <div
            className="table and panel wrapper"
            style={{ display: 'flex', gap: '20px' }}
          >
            <div style={{ width: '100%' }}>
              <div>
                <div
                  style={{
                    fontSize: '16px',
                    marginBottom: '10px',
                  }}
                >
                  <div className={classes.globalIndWrapper}>
                    <GlobalIndicatorWrapper isGlobal inline />
                  </div>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div style={{ width: '400px' }}>
                      <Select
                        interactable
                        placeholder="Select Policy Lens"
                        options={selectPolicyLens}
                        setFieldValue={handleChange}
                        className={classes.selectStyle}
                        customOption={CustomPolicyOption as React.FC}
                      />
                    </div>
                    <FindingsTableAction
                      setSelectedTableAction={setSelectedTableAction}
                      selectedTableAction={selectedTableAction}
                      onGoClick={onGoClick}
                      isDisabled={isGoButtonDisable()}
                      tableActionsFromParent={[
                        {
                          group: t('GLOBAL.SELECTED_ROWS'),
                          actions: [
                            {
                              value: TableAction.CreateTicketForSelectedRows,
                              label: 'Create Ticket',
                              isDisabled: isCreateTicketDisable(),
                            },
                          ],
                        },
                      ]}
                    />
                  </div>

                  {/* <Checkbox
                  label="Also show  accepted findings"
                  color="primary"
                /> */}
                </div>
              </div>
              <MUICard>
                <SearchBox onInputChange={handleSearch} />
              </MUICard>
              <MUICard>
                <FilterChipBox
                  mapping={zScanFindingsDataFilterMappings as IFilterMapping[]}
                  onDeleteChip={handleDeleteQueryParam}
                  queryParams={location.query}
                />
              </MUICard>

              <div>
                <Table
                  serverSort={false}
                  classList={classes}
                  columnHeaders={currentTableHeaders}
                  footerComponents={() => (
                    <div>{totalDataCount + ' rows total'}</div>
                  )}
                  onFilterValueChange={handleFilterChange as () => void}
                  definedUser={definedUser}
                  fetchTableData={() =>
                    tableQuery(
                      activeIndex,
                      preSelectFirstRowData,
                      setPreSelectFirstRowData,
                      setSelectFinding,
                      setTotalDataCount,
                      location.query,
                      assessmentId
                    )(location.query as unknown as ILocationQuery)
                  }
                  selected={selected}
                  checkboxColumn
                  pagination={false}
                  onColumnChange={handleColumnChange}
                  onRowClick={handleSelectRow}
                  query={query as unknown as ILocationQuery}
                  rowMapping={zScanFindingsColumnMapping()}
                  tableId="zScanFindings"
                  heightBuffer={290}
                  onSelectionChange={setSelected}
                  activeIndex={activeIndex}
                  onActiveIndexChange={setActiveIndex}
                  activeRowStyle={classes.activeRowIndicator}
                />
                <CreateTicket
                  configurations={configurations}
                  requestToCreateTicket={requestToCreateTicket}
                  clearSelectedRows={clearSelectedRows}
                />
              </div>
            </div>
            {!_.isEmpty(selectFinding) && Number(totalDataCount) ? (
              <div
                className="card wrapper"
                style={{
                  width: '35%',
                  boxShadow: '10px 10px 10px 10px rgb(0,0,0,0.258)',
                  border: ' 1px solid black',
                  borderRadius: '4px',
                }}
              >
                <FindingSideBar
                  assessmentId={assessmentId}
                  selectFinding={selectFinding}
                  rerenderKey={renderFindingDetails}
                />
              </div>
            ) : null}
          </div>
        </div>
      </GenericCard>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  findingsUiSettings: getUISettingsWithout(state, 'zScanFindings', [
    'tableHeaders',
  ]),
  currentTableHeaders: getUISettingsTableHeaders(state, 'zScanFindings'),
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      findingsTableColumnChange,
    },
    dispatch
  );

export default compose(
  withRouter,
  withDirtyState(),
  connect(mapStateToProps, mapDispatchToProps)
)(
  withMemoizedProps(ZScanFindings, [
    'currentTableHeaders',
    'dirtyState',
    'q',
    'updateUrl',
  ])
);
