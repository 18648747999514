import MUIListSubheader from '@material-ui/core/ListSubheader';
import MUIMenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import { ITableActionGroup } from './models';
import { useMemo } from 'react';

export const RenderSelectGroup = (group: ITableActionGroup) => {
  const { t } = useTranslation();

  const items = useMemo(() => {
    return group.actions.map((action) => (
      <MUIMenuItem
        value={action.value}
        style={{ marginLeft: '15px' }}
        key={action.value}
        disabled={action.isDisabled}
      >
        {t(action.label)}
      </MUIMenuItem>
    ));
  }, [group.actions, t]);

  return [<MUIListSubheader>{t(group.group)}</MUIListSubheader>, items];
};
