import { selectedTeam as selectedTeamAtom } from 'atoms/teams';
import { LanguagePayloadMapping } from 'models/language';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import useAsyncResult from 'utils/useAsyncResult';
import { IState } from '../os-risk/common/models';
import CurrentDeviceRiskStatus from './CurrentDeviceRiskStatus';
import CurrentOsRisk from './CurrentOsRisk';
import CurrentSecurityScore from './CurrentSecurityScore';
import DevicePool from './DevicePool';
import KeyFeatures from './KeyFeatures';
import SecurityScoreTrend from './SecurityScoreTrend';
import TopEvents from './TopEvents';
import { ERiskyCriticalEvent } from './TopEvents/models';
import {
  IRiskyCriticalEvents,
  ISecurityScore,
  TCriticalRiskyThreatType,
} from './models';
import useStyles from './useStyles';
import {
  fetchCriticalRiskyThreatTypes,
  fetchCurrentSecurityScore,
  fetchTopCriticalRiskyEvents,
} from './utils';

const Insights: React.FC = () => {
  const classes = useStyles();
  const selectedTeamId = useRecoilValue(selectedTeamAtom);
  const { i18n } = useTranslation();

  const accountId = useSelector(
    (state: IState) => state?.user?.account?.id ?? null
  );
  const securityScoreData = useAsyncResult<ISecurityScore>(
    fetchCurrentSecurityScore,
    selectedTeamId ?? ''
  );
  const threatTypes = useAsyncResult<TCriticalRiskyThreatType[]>(
    fetchCriticalRiskyThreatTypes,
    !!i18n.language
      ? LanguagePayloadMapping[
          i18n.language?.toLowerCase() as keyof typeof LanguagePayloadMapping
        ]
      : null
  );
  const topRiskyCriticalEvents = useAsyncResult<IRiskyCriticalEvents>(
    fetchTopCriticalRiskyEvents,
    selectedTeamId ?? ''
  );

  return (
    <div className={classes.dashboard}>
      <div className={classes.row}>
        <DevicePool selectedTeamId={selectedTeamId} />
        <CurrentDeviceRiskStatus
          topRiskyCriticalEvents={topRiskyCriticalEvents}
        />
        <CurrentOsRisk accountId={accountId} selectedTeamId={selectedTeamId} />
        <CurrentSecurityScore securityScoreData={securityScoreData} />
      </div>
      <div className={classes.row}>
        <KeyFeatures selectedTeamId={selectedTeamId} />
        <TopEvents
          eventType={ERiskyCriticalEvent.CRITICAL}
          topRiskyCriticalEvents={topRiskyCriticalEvents}
          threatTypes={threatTypes}
        />
        <TopEvents
          eventType={ERiskyCriticalEvent.RISKY}
          topRiskyCriticalEvents={topRiskyCriticalEvents}
          threatTypes={threatTypes}
        />
      </div>
      <div className={classes.row}>
        <SecurityScoreTrend securityScoreData={securityScoreData} />
      </div>
    </div>
  );
};

export default Insights;
