import Checkbox from '@material-ui/core/Checkbox';
import MUIDialog from '@material-ui/core/Dialog';
import MUIDialogActions from '@material-ui/core/DialogActions';
import MUIDialogContent from '@material-ui/core/DialogContent';
import MUIDialogTitle from '@material-ui/core/DialogTitle';
import MUIInputAdornment from '@material-ui/core/InputAdornment';
import Switch from '@material-ui/core/Switch';
import { addCertificate } from 'api/NetworkPolicyService';
import { getActiveModalAtom } from 'atoms/modals';
import Button from 'components/UI/Button';
import GenericTable from 'components/UI/GenericTable';
import { GenericPromptModal_TITLE } from 'components/UI/Modals/GenericPrompt';
import { IGenericPromptModalData } from 'components/UI/Modals/GenericPrompt/models';
import CertificateIcon from 'components/UI/icons/CertificateIcon';
import CheckIcon from 'components/UI/icons/CheckIcon';
import DeleteIcon from 'components/UI/icons/DeleteIcon';
import ErrorIcon from 'components/UI/icons/ErrorIcon';
import SearchIcon from 'components/UI/icons/SearchIcon';
import TextField from 'components/UI/input/TextField';
import JsFileInfoCard from 'components/fileuploader/FileInfoCard';
import JsUploader from 'components/fileuploader/Uploader';
import _ from 'lodash';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { openSnackBar } from 'utils/storeUtils';
import { searchTable } from 'utils/tableUtils';
import { ICertificate } from './models';
import useStyles from './useStyles';

const Uploader: any = JsUploader;
const FileInfoCard: any = JsFileInfoCard;

interface CertFile {
  name: string;
  file: File;
}

const searchFields: (keyof ICertificate)[] = [
  'issuerName',
  'cname',
  'serialNumber',
  'validFromDate',
  'validToDate',
];

const activeModalAtom = getActiveModalAtom<IGenericPromptModalData>();

interface IAddCertificateModalProps {
  isOpen: boolean;
  onClose: () => void;
  certificates: ICertificate[];
  onCertificatesChanged: (certificates: ICertificate[]) => void;
  policyId: string;
}

const AddCertificateModal: React.FC<IAddCertificateModalProps> = (props) => {
  const { isOpen, onClose, onCertificatesChanged, certificates, policyId } =
    props;

  const classes = useStyles();

  const { t, ready } = useTranslation();

  const setActiveModal = useSetRecoilState(activeModalAtom);

  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<CertFile>();
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [data, setData] = useState<ICertificate[]>(certificates);

  useEffect(() => {
    if (
      (data.length > 0 || certificates.length > 0) &&
      !_.isEqual(data, certificates)
    ) {
      onCertificatesChanged(data);
    }
  }, [data, certificates, onCertificatesChanged]);

  const onUploadClose = useCallback((): void => {
    setIsUploadOpen(false);
  }, [setIsUploadOpen]);

  const onAddCertClick = useCallback((): void => {
    setIsUploadOpen(true);
  }, [setIsUploadOpen]);

  const onDeleteClick = useCallback(
    (item: any): void => {
      setActiveModal({
        active: GenericPromptModal_TITLE,
        payload: {
          title: t('MTD.POLICIES.NETWORK_POLICY.DELETE_CERTIFICATE'),
          message: t('MTD.POLICIES.NETWORK_POLICY.CONFIRM_DELETE'),
          onCancel: () => setActiveModal(undefined),
          onConfirm: () => {
            const newData = [...data].filter((el) => el.id !== item.id);
            setData(newData);
            setActiveModal(undefined);
          },
        },
      });
    },
    [data, setActiveModal, t]
  );

  const setFileData = useCallback(
    (fileData: File): void => {
      setSelectedFile({ name: fileData.name, file: fileData });
    },
    [setSelectedFile]
  );

  const removeFile = useCallback((): void => {
    setSelectedFile(undefined);
  }, [setSelectedFile]);

  const onSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setSearchTerm(value);
    },
    [setSearchTerm]
  );

  const onEnabledChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const certId = event.target.id;

      const newData = _.cloneDeep(data);
      const index = newData.findIndex((cert) => cert.id === certId);
      newData[index].enabled = checked;

      setData(newData);
    },
    [data]
  );

  const onSelectionChange = useCallback(
    (certificate: ICertificate): void => {
      const index = data.findIndex((el) => el.id === certificate.id);

      const newData = [...data];
      newData[index] = { ...certificate, selected: !certificate.selected };

      setData(newData);
    },
    [data]
  );

  const handleSubmitForm = useCallback(async (): Promise<void> => {
    if (selectedFile) {
      try {
        setIsLoading(true);

        const formData = new FormData();
        formData.append('cert', selectedFile.file || '');

        const { data: uploadedCert } = await addCertificate(policyId, formData);

        const newData = [uploadedCert] as ICertificate[];

        if (data.length > 0) {
          newData.push(..._.cloneDeep(data));
        }

        setData(newData);
        setSelectedFile(undefined);
        openSnackBar(t('GLOBAL.SUCCESS_UPLOAD'));
        onUploadClose();
      } catch (error: any) {
        openSnackBar(
          error?.response?.data?.message || t('GLOBAL.SOMETHING_WRONG')
        );
      } finally {
        setIsLoading(false);
      }
    }
  }, [data, selectedFile, policyId, onUploadClose, t]);

  if (!ready) {
    return null;
  }

  return (
    <div>
      <MUIDialog open={isOpen} onClose={onClose} fullWidth maxWidth="xl">
        <MUIDialogTitle style={{ display: 'flex', justifyContent: 'center' }}>
          {t('MTD.POLICIES.NETWORK_POLICY.ALLOW_CERTIFICATES')}
        </MUIDialogTitle>
        <MUIDialogContent>
          <div className={classes.dialogRoot}>
            <div className={classes.warningLabel}>
              <span style={{ marginRight: '30px' }}>
                {t(
                  'MTD.POLICIES.NETWORK_POLICY.MITM_THREATS_SUPPRESSED_WHEN_DEVICE_CONNECTED_PROXY'
                )}
              </span>
              <ErrorIcon color="error" />
            </div>

            <div className={classes.modalActionsContainer}>
              <div className={classes.modalActionsIcons}>
                <div className={classes.iconButton}>
                  <div className={classes.iconContainer}>
                    <CertificateIcon />
                  </div>
                  <span style={{ fontSize: 'x-small' }}>
                    {t('GLOBAL.CERTIFICATE')}
                  </span>
                </div>

                <div className={classes.iconButton}>
                  <div className={classes.iconContainer}>
                    <CheckIcon />
                  </div>
                  <span style={{ fontSize: 'x-small' }}>
                    {t('MTD.POLICIES.NETWORK_POLICY.SAFE')}
                  </span>
                </div>
              </div>

              <div>
                <Button color="secondary" text="+" onClick={onAddCertClick} />
              </div>
            </div>

            <TextField
              name="search"
              placeholder={t('GLOBAL.SEARCH')}
              onChange={onSearchChange}
              InputProps={{
                endAdornment: (
                  <MUIInputAdornment
                    position="end"
                    classes={{ root: classes.searchIcon }}
                  >
                    <SearchIcon />
                  </MUIInputAdornment>
                ),
              }}
            />

            <GenericTable
              classList={classes}
              columnHeaders={[
                {
                  id: 'issuerName',
                  label: t('MTD.POLICIES.NETWORK_POLICY.ISSUER_COMMON_NAME'),
                },
                {
                  id: 'cname',
                  label: t('MTD.POLICIES.NETWORK_POLICY.SUBJECT_COMMON_NAME'),
                },
                {
                  id: 'serialNumber',
                  label: `${t(
                    'MTD.POLICIES.NETWORK_POLICY.CERTIFICATE_SERIAL'
                  )} #`,
                },
                {
                  id: 'validFromDate',
                  label: t('MTD.POLICIES.NETWORK_POLICY.VALID_FROM'),
                },
                {
                  id: 'validToDate',
                  label: t('MTD.POLICIES.NETWORK_POLICY.VALID_TO'),
                },
                {
                  id: 'enabled',
                  label: t('GLOBAL.ENABLED'),
                },
                {
                  id: 'actions',
                  label: t('GLOBAL.ACTIONS'),
                },
              ]}
              rowMapping={[
                {
                  path: 'select',
                  columnContent: ({ rowData }) => (
                    <Checkbox
                      key={rowData.id}
                      checked={rowData.selected as boolean}
                      onChange={() =>
                        onSelectionChange(rowData as unknown as ICertificate)
                      }
                      color="primary"
                      className="table__checkbox"
                    />
                  ),
                },
                {
                  path: 'actions',
                  columnContent: ({ rowData }) => (
                    <Button
                      style={{ padding: 0, minWidth: 'auto' }}
                      variant="text"
                      icon={DeleteIcon}
                      onClick={() => onDeleteClick(rowData)}
                    />
                  ),
                },
                {
                  path: 'enabled',
                  columnContent: ({ rowData }) => (
                    <Switch
                      id={rowData.id as string}
                      checked={rowData.enabled as boolean}
                      onChange={onEnabledChange}
                    />
                  ),
                },
              ]}
              tableData={searchTable(searchTerm, data, searchFields) as any}
              tableId="Network Policy Rules"
            />
          </div>
        </MUIDialogContent>

        <MUIDialogActions>
          <Button text={t('GLOBAL.OK')} color="secondary" onClick={onClose} />
        </MUIDialogActions>
      </MUIDialog>

      <MUIDialog open={isUploadOpen} onClose={onUploadClose}>
        <MUIDialogTitle style={{ display: 'flex', justifyContent: 'center' }}>
          {t('MTD.POLICIES.NETWORK_POLICY.UPLOAD_CERTIFICATE')}
        </MUIDialogTitle>
        <MUIDialogContent>
          {!selectedFile && (
            <div className={classes.formGroup}>
              <Uploader
                accept=".cert,.pem,.ca-bundle,.crt"
                isShowing={true}
                getFileData={setFileData}
              />
            </div>
          )}

          {selectedFile && (
            <FileInfoCard
              fileData={selectedFile}
              isShowing={!!selectedFile}
              removeFile={removeFile}
              noActionButtons
            />
          )}
        </MUIDialogContent>

        <MUIDialogActions>
          <Button
            text={t('GLOBAL.UPLOAD')}
            disabled={!selectedFile || isLoading}
            onClick={handleSubmitForm}
          />
        </MUIDialogActions>
      </MUIDialog>
    </div>
  );
};

export default React.memo(AddCertificateModal);
