import { default as MUIFormControlLabel } from '@material-ui/core/FormControlLabel';
import InputError from 'components/UI/input/InputError';
import { FieldProps as IFormikFieldProps } from 'formik';
import React from 'react';
import useStyles from './useStyles';

export interface IGenericGroupProps extends Partial<IFormikFieldProps> {
  label?: string;
  children: React.ReactNode;
}

const GenericGroup: React.FC<IGenericGroupProps> = ({
  children,
  label,
  field,
  form,
}) => {
  const classes = useStyles();

  return (
    <>
      <MUIFormControlLabel
        label={label}
        classes={{ root: classes.root, label: classes.label }}
        control={<div className={classes.children}>{children}</div>}
      />
      <InputError form={form} field={field} />
    </>
  );
};

export default GenericGroup;
