import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const dismissedNotice = atom<boolean>({
  key: 'appPolicy-dismissedNotice',
  default: false,
  effects_UNSTABLE: [persistAtom],
});
