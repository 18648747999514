import MUIAndroidIcon from '@material-ui/icons/Android';
import GenericCard from 'components/UI/GenericCard';
import GenericTable from 'components/UI/GenericTable';
import AlertIcon from 'components/UI/icons/AlertIcon';
import AppleIcon from 'components/UI/icons/AppleIcon';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import useAsyncResult from 'utils/useAsyncResult';
import { selectedTeam as selectedTeamAtom } from '../../../../atoms/teams';
import { IDeviceStatus } from '../DashboardTab/models';
import { IState } from '../common/models';
import { ITopTenImpactfulCVEs, Severity } from './models';
import useStyles from './useStyles';
import { fetchTopTenImpactful } from './utils';
import { useTranslation } from 'react-i18next';

interface IOSStatusCardProps {
  title: string;
  data?: IDeviceStatus;
}

const TopTenImpactful: React.FC<IOSStatusCardProps> = ({ title }) => {
  const classes = useStyles();
  const { t, ready } = useTranslation();

  const teamId = useRecoilValue(selectedTeamAtom);
  const accountId = useSelector((state: IState) => state.user.account.id);

  const topTenImpactful = useAsyncResult<ITopTenImpactfulCVEs[]>(
    fetchTopTenImpactful,
    teamId ?? '',
    accountId ?? ''
  );

  const tableData = useMemo(
    () =>
      topTenImpactful
        ? topTenImpactful.map((el) => ({ ...el, id: el.cve }))
        : [],
    [topTenImpactful]
  );

  const tableHeaders = [
    { id: 'cve', label: t('MTD.OS_RISK.CVE') },
    { id: 'severity', label: t('GLOBAL.SEVERITY') },
    { id: 'platform', label: t('GLOBAL.PLATFORM') },
    { id: 'deviceCount', label: t('GLOBAL.DEVICE_COUNT') },
    { id: 'upgradeable', label: t('GLOBAL.UPGRADEABLE') },
    { id: 'nonUpgradeable', label: t('GLOBAL.NON_UPGRADEABLE') },
    { id: 'exploitPocUrl', label: t('MTD.OS_RISK.ACTIVE_EXPLOIT') },
  ];

  if (!ready) {
    return null;
  }

  return (
    <GenericCard
      title={title}
      classTitle={classes.mainTitle}
      className={classes.tableContainer}
      noPadding
    >
      <GenericTable
        columnHeaders={tableHeaders}
        tableData={tableData}
        classList={classes}
        rowMapping={[
          {
            path: 'severity',
            columnContent: ({ rowData }) => {
              return (
                <span className={classes.severityCell}>
                  <AlertIcon />
                  <p>
                    {t(
                      `GLOBAL.${Severity[
                        rowData?.severity as keyof typeof Severity
                      ].toUpperCase()}`
                    )}
                  </p>
                </span>
              );
            },
          },
          {
            path: 'platform',
            columnContent: ({ rowData }) => {
              const Icon =
                rowData?.platform === 'Apple' ? AppleIcon : MUIAndroidIcon;
              return (
                <div className={classes.withIcon}>
                  <Icon className={classes.osIcon} />
                  <span>{rowData?.platform}</span>
                </div>
              );
            },
          },
          {
            path: 'deviceCount',
            columnContent: ({ rowData }) => {
              const countLink = `/console/mtd/devices/?cve=${rowData?.cve}&duration=9`;
              return <a href={countLink}>{rowData?.deviceCount}</a>;
            },
          },
          {
            path: 'upgradeable',
            columnContent: ({ rowData }) => {
              const upgradeableLink = `/console/mtd/devices/?cve=${rowData?.cve}&upgradeable=true&duration=9`;
              return <a href={upgradeableLink}>{rowData?.upgradeable}</a>;
            },
          },
          {
            path: 'nonUpgradeable',
            columnContent: ({ rowData }) => {
              const nonUpgradeableLink = `/console/mtd/devices/?cve=${rowData?.cve}&upgradeable=false&duration=9`;
              return <a href={nonUpgradeableLink}>{rowData?.nonUpgradeable}</a>;
            },
          },
          {
            path: 'exploitPocUrl',
            columnContent: ({ rowData }) => {
              return rowData?.exploitPocUrl === null ? (
                <p>{t('GLOBAL.NO')}</p>
              ) : (
                <a href={rowData?.exploitPocUrl as string}>{'Yes'}</a>
              );
            },
          },
        ]}
        tableId="topTenImpactful"
      ></GenericTable>
    </GenericCard>
  );
};

export default TopTenImpactful;
