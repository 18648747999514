import _ from 'lodash';
import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRouter from 'components/hocs/withRouter';

// ui
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ZButton from 'UI/Buttons/ZButton';

// components
import { TextField } from 'components/inputs';

// services
import {
  toggleModalDirect,
  openSnackBar,
  toggleModalDiffered,
} from 'utils/storeUtils';
import { createPolicy, clonePolicy } from 'api/zDevService';

import { publishEvent } from '../../utils/eventUtils';
import {
  POLICY_LIST_REQUEST_UPDATE,
  POLICY_SELECTED,
} from '../../dashboard/zDev/buildsPolicies/zDevEvents';

class ZDevPolicyCreateClone extends PureComponent {
  state = {
    name: '',
    loading: false,
  };

  constructor(props) {
    super(props);

    this.isCloning = !_.isEmpty(props.data);

    if (this.isCloning) {
      const name = _.get(props.data, 'name', 'zDev Policy');
      this.state = {
        name: `${name} (cloned)`,
      };
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const { props, state } = this;
    const { classes } = props;

    return (
      <DialogContent>
        <form onSubmit={this.handleSubmit} className={classes.form}>
          <TextField
            label="Policy Name"
            value={state.name}
            required
            onChange={this.handleChange('name')}
          />

          <DialogActions>
            <ZButton
              styleName="modalCancel"
              action={toggleModalDiffered('zDevPolicyCreateClone', false)}
              color="secondary"
              buttonText="Cancel"
              isDisabled={state.loading}
            />
            <ZButton
              buttonType="submit"
              color="primary"
              styleName="modalSave"
              buttonText={state.loading ? 'Cloning...' : 'Save'}
              isDisabled={state.loading}
            />
          </DialogActions>
        </form>
      </DialogContent>
    );
  }

  handleChange(field) {
    return (event) => {
      this.setState({
        [field]: event.target.value,
      });
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    const { props, state } = this;
    const payload = {
      name: state.name,
    };

    if (this.isCloning) {
      return clonePolicy({ policyId: props.data.id }, payload)
        .then((response) => {
          this.setState({ loading: true });
          setTimeout(() => {
            publishEvent(POLICY_LIST_REQUEST_UPDATE);
            publishEvent(POLICY_SELECTED, response.data.id);
            toggleModalDirect('zDevPolicyCreateClone', false);
            openSnackBar(`${state.name} Successfuly Cloned`);
            this.setState({ loading: false });
          }, 1000);
        })
        .catch(() => {
          toggleModalDirect('zDevPolicyCreateClone', false);
          openSnackBar(`${state.name} Cloning Failed`);
        });
    }

    createPolicy({}, payload)
      .then((response) => {
        publishEvent(POLICY_LIST_REQUEST_UPDATE);
        publishEvent(POLICY_SELECTED, response.data.id);
        toggleModalDirect('zDevPolicyCreateClone', false);
        openSnackBar(`${state.name} Successfuly Created`);
      })
      .catch(() => {
        toggleModalDirect('zDevPolicyCreateClone', false);
        openSnackBar(`Failed to Create ${state.name}`);
      });
  }
}

ZDevPolicyCreateClone.defaultProps = {
  data: {},
};

const styles = ({ config }) => {
  return {
    radioContainer: {
      flexDirection: 'row',
      paddingLeft: 10,
    },
    scopeLabel: {
      fontSize: config.textSizes.petite,
      marginTop: 15,
    },
  };
};

export default withRouter(withStyles(styles)(ZDevPolicyCreateClone));
