import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  container: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const ConsoleNotFound = withStyles(styles)(props => {
  return (
    <div className={props.classes.container}>
      <img src="zdev-logo.svg" alt="zConsole Logo" width={50} />
      <h1>Not Found</h1>
      We could not find the route you requested{' '}
      <strong>{props.location.pathname}</strong>
    </div>
  );
});
