import React from 'react';
import { Route } from 'react-router-dom';
import hoistStatics from 'hoist-non-react-statics';
import qs from 'qs';

export function withRouter(Component) {
  const C = props => {
    const { wrappedComponentRef, ...rest } = props;

    return (
      <Route
        children={({ location, ...routeRest }) => {
          let query;

          if (location.search) {
            query = qs.parse(location.search, { ignoreQueryPrefix: true });
          }

          const modifiedProps = {
            ...routeRest,
            location: {
              ...location,
              query,
            },
          };

          return (
            <Component {...rest} {...modifiedProps} ref={wrappedComponentRef} />
          );
        }}
      />
    );
  };

  C.displayName = `withRouter(${Component.displayName || Component.name})`;
  C.wrappedComponent = Component;

  return hoistStatics(C, Component);
}
