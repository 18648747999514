import { Api as client } from 'config/axiosConfig';
import qs from 'qs';

export { fetchActivations } from './ActivationsService';

export {
  addNewAccount,
  fetchAllAccounts, // fetchSuperUsersByAccount,
} from './AdminAccountService';

export { enterAccount, refreshAccessToken } from './AuthService';

export { fetchDevicesPage, fetchDevicesStats } from './DevicesService';

export {
  fetchAllPrivacyPolicies,
  updatePrivacyPolicy,
} from './PrivacyPolicyService';

export {
  fetchThreatsPage,
  fetchThreatsStats,
  fetchThreatVectors,
  fetchThreatsByAppByTime,
  fetchAggrByAppInstances,
} from './ThreatsService';

export {
  fetchCurrentUser,
  fetchCurrentUserTeams,
  RequestDeleteUser,
  createSuperUser,
  fetchSuperRoles,
  requestSuperUser,
  updateSuperUser,
  fetchBranding,
} from './UserService';

export {
  requestTeamsListing,
  requestAllTeams,
  createNewTeam,
  editSelectedTeam,
  deleteSelectedTeam,
} from './TeamsService';

/*
 * ZAPP app related APIs
 */
export function fetchAllAppsByTeamId(teamId) {
  return client.get(`/api/zapp/v1/zapps?teamId=${teamId}`);
}

export function fetchAllAppsByGroupId(groupId) {
  return client.get(`/api/zapp/v1/zapps?groupId=${groupId}`);
}

export function fetchAppsPage(selectedTeamIds, page, size, order, orderBy) {
  const params = {
    page,
    size,
    sort: `${orderBy},${order}`,
    teamId: selectedTeamIds,
  };
  return client.get('/api/zapp/v1/zapps/page?', {
    params,
    paramsSerializer(_params) {
      return qs.stringify(_params, { arrayFormat: 'repeat' });
    },
  });
}

export function fetchAppVersionsCount(zappId) {
  return client.get(`/api/zapp/v1/zapps/${zappId}/versions/count`);
}

export function deleteSelectedApp(zappId) {
  return client.delete(`/api/zapp/v1/zapps/${zappId}`);
}

/*
 * Downloads zIAP SDK related APIs
 */
export function fetchIosZiapSdkUrl() {
  return client.get('/api/zapp/v1/sdk/ios/url');
}

export function fetchIosRcZiapSdkUrl() {
  return client.get('/api/zapp/v1/sdk/ios/rc/url');
}

export function fetchIosZiapFullSdkUrl() {
  return client.get('/api/zapp/v1/fullsdk/ios/url');
}

export function fetchIosZiapFullXcSdkUrl() {
  return client.get('/api/zapp/v1/fullxcsdk/ios/url');
}

export function fetchIosRcZiapFullSdkUrl() {
  return client.get('/api/zapp/v1/fullsdk/ios/rc/url');
}

export function fetchIosRcZiapFullXcSdkUrl() {
  return client.get('/api/zapp/v1/fullxcsdk/ios/rc/url');
}

export function fetchIosZiapBitcodeSdkUrl() {
  return client.get('/api/zapp/v1/bitcode-sdk/ios/url');
}

export function fetchIosRcZiapBitcodeSdkUrl() {
  return client.get('/api/zapp/v1/bitcode-sdk/ios/rc/url');
}

export function fetchAndroidZiapSdkUrl() {
  return client.get('/api/zapp/v1/sdk/android/url');
}

export function fetchAndroidRcZiapSdkUrl() {
  return client.get('/api/zapp/v1/sdk/android/rc/url');
}

export function fetchAndroidZiapFullSdkUrl() {
  return client.get('/api/zapp/v1/fullsdk/android/url');
}

export function fetchAndroidRcZiapFullSdkUrl() {
  return client.get('/api/zapp/v1/fullsdk/android/rc/url');
}

export function fetchDownloadsMeta() {
  return client.get('api/zapp/v1/sdk');
}

export function fetchIosZiapSampleUrl() {
  return client.get('/api/zapp/v1/sample/ios/url');
}

export function fetchAndroidZiapSampleUrl() {
  return client.get('/api/zapp/v1/sample/android/url');
}

export function fetchIosZiapScriptUrl() {
  return client.get('/api/zapp/v1/script/ios/url');
}

export function fetchAndroidZiapScriptUrl() {
  return client.get('/api/zapp/v1/script/android/url');
}

export function fetchCordovaPluginUrl() {
  return client.get('/api/zapp/v1/plugin/cordova/url');
}

export function fetchCordovaSampleUrl() {
  return client.get('/api/zapp/v1/sample/cordova/url');
}

export function fetchFlutterPluginUrl() {
  return client.get('/api/zapp/v1/plugin/flutter/url');
}

export function fetchFlutterSampleUrl() {
  return client.get('/api/zapp/v1/sample/flutter/url');
}

export function fetchReactNativePluginUrl() {
  return client.get('/api/zapp/v1/plugin/react-native/url');
}

export function fetchReactNativeSampleUrl() {
  return client.get('/api/zapp/v1/sample/react-native/url');
}

export function fetchXamarinPluginUrl() {
  return client.get('/api/zapp/v1/plugin/xamarin/url');
}

export function fetchXamarinSampleUrl() {
  return client.get('/api/zapp/v1/sample/xamarin/url');
}

/*
 * Login / Signup related APIs
 */
export function postSignup(signup) {
  return client.post('/api/auth/v1/signup', JSON.stringify(signup));
}

export function postSignUpValidationToken(token) {
  return client.post(`/api/auth/v1/validateSignUp/${token}`);
}
/*
 * Threat / Activations related APIs
 */

export function fetchAllZappsSeverityAggregates(
  teamId,
  after,
  before = new Date()
) {
  return client.get(
    `/api/threats/v1/threats/severity/bytime?teamId=${teamId}&after=${after.toISOString()}&before=${before.toISOString()}`
  );
}

export function fetchVectorCounts(zappId, after, before = new Date()) {
  return client.get(
    // `/api/threats/v1/vectors/zapp/${zappId}/counts?after=${after.toISOString()}&before=${before.toISOString()}`
    `/api/threats/v1/vectors/counts?zappId=${zappId}&after=${after.toISOString()}&before=${before.toISOString()}`
  );
}

export function fetchAllVectorCounts(after, before = new Date()) {
  return client.get(
    `/api/threats/v1/vectors/counts?after=${after.toISOString()}&before=${before.toISOString()}`
  );
}

/* Account */
export function fetchAccount() {
  return client.get('/api/account/v1/account');
}

export function postAccount(account) {
  return client.post('/api/account/v1/account', JSON.stringify(account));
}

/* Edit User Profile */

export function updateUserProfile(account) {
  return client.post('api/auth/v1/me', JSON.stringify(account));
}

/* Roles Table */
export function fetchAllScopes() {
  return client.get('api/auth/v1/scopes/map');
}

export function postNewRole(role) {
  return client.post('api/auth/v1/roles', JSON.stringify(role));
}

export function editSelectedRole(payload) {
  return client.put(`api/auth/v1/roles/${payload.id}`, JSON.stringify(payload));
}

export function deleteSelectedRole(id) {
  return client.delete(`/api/auth/v1/roles/${id}`);
}

/* role table meta */
export function fetchRolesTablePage(page, size, order, orderBy) {
  return client.get(
    `/api/auth/v1/roles/search?page=${page}&size=${size}&sort=${orderBy},${order}`
  );
}

/* groups */

export function fetchAllGroups() {
  return client.get('api/groups/v1/groups');
}
export function fetchAllGroupsByTeamId(teamIds) {
  const params = {
    teamId: teamIds,
  };
  return client.get('api/groups/v1/groups', {
    params,
    paramsSerializer(_params) {
      return qs.stringify(_params, { arrayFormat: 'repeat' });
    },
  });
}

export function postNewGroup(payload) {
  return client.post('api/groups/v1/groups', JSON.stringify(payload));
}
export function editSelectedGroup(payload, id) {
  return client.put(`api/groups/v1/groups/${id}`, JSON.stringify(payload));
}
export function deleteSelectedGroup(id) {
  return client.delete(`/api/groups/v1/groups/${id}`);
}

export function fetchAttachedZapps(groupId) {
  return client.get(`/api/zapp/v1/zapps?groupId=${groupId}`);
}

/* TRM Policy */
export function fetchAllTRMPolicies(teamId) {
  return client.get('api/trm/v1/trms', { params: { teamId } });
}
// delete when actions are gone
export function createNewTRMPolicy(payload) {
  return client.post('api/trm/v1/trms', JSON.stringify(payload));
}
// to do : delete when actions are deleted
export function cloneSelectedTRMPolicy(id, payload) {
  return client.post(`api/trm/v1/trms/${id}/clone`, JSON.stringify(payload));
}
export function fetchResponseTypes() {
  return client.get('api/trm/v1/response-types');
}
export function deleteSelectedTRMPolicy(id) {
  return client.delete(`api/trm/v1/trms/${id}`);
}

export function fetchPrivacyPolicyById(id) {
  return client.get(`api/privacy/v1/policies/${id}`);
}
export function fetchAllCollectibles() {
  return client.get('api/privacy/v1/collectibles');
}

export function createNewPrivacyPolicy(name) {
  return client.post('api/privacy/v1/policies', JSON.stringify(name));
}
export function cloneSelectedPrivacyPolicy(id, payload) {
  return client.post(
    `api/privacy/v1/policies/${id}/clone`,
    JSON.stringify(payload)
  );
}
export function deleteSelectedPrivacyPolicy(id) {
  return client.delete(`api/privacy/v1/policies/${id}`);
}
/*
threat-types
response-types
*/

/*
 * Threats specific to a device
 */
export function fetchThreatsByDeviceIdAndZappId(
  deviceId,
  zappId,
  after,
  before = new Date(),
  page,
  size,
  order,
  orderBy
) {
  return client.get(
    `/api/threats/v1/threats?deviceId=${deviceId}&appId=${zappId}&before=${before.toISOString()}&after=${after.toISOString()}&page=${page}&size=${size}&sort=${orderBy},${order}`
  );
}

export function fetchZiapConsoleVersion() {
  return client.get('/ziapConsoleVersion');
}
