import MUIChip from '@material-ui/core/Chip';
import MUIPaper from '@material-ui/core/Paper';
import { useMemo } from 'react';
import { IFilterChip, IFilterMapping } from './models';
import useStyles from './useStyles';
import { createChipsFromQueryParams } from './utils';
import { IQueryParams } from 'components/main/devices/common/models';
import { useTranslation } from 'react-i18next';

interface IFilterChipBoxProps {
  mapping: IFilterMapping[];
  onDeleteChip: (paramName: string) => void;
  placeholder?: string;
  queryParams: IQueryParams;
}

const FilterChipBox: React.FC<IFilterChipBoxProps> = ({
  mapping,
  onDeleteChip,
  placeholder = 'GLOBAL.APPLIED_FILTERS',
  queryParams,
}) => {
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const chips: IFilterChip[] = useMemo(
    () => createChipsFromQueryParams(mapping, queryParams),
    [mapping, queryParams]
  );

  if (!ready) {
    return null;
  }

  return (
    <MUIPaper
      className={classes.filterBoxWrapper}
      data-placeholder={t(placeholder)}
    >
      {chips.map(({ name, label }) => (
        <MUIChip
          classes={{
            root: classes.chip,
            label: classes.label,
            deleteIcon: classes.deleteIcon,
          }}
          key={name}
          label={label}
          onDelete={() => onDeleteChip(name)}
        />
      ))}
    </MUIPaper>
  );
};

export default FilterChipBox;
