import MUIIconButton from '@material-ui/core/IconButton';
import MUIContentCopyIcon from '@material-ui/icons/FilterNone';
import {
  IGenericColumnHeader,
  IGenericFullData,
} from 'components/UI/GenericTable/models';
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard'; // TODO: Fix this import. I'm not sure if we want to add these types yet
import { TFunction } from 'react-i18next';

export const headers = (
  t: TFunction<'translation', undefined>
): IGenericColumnHeader[] => [
  { id: 'keyName', label: t('MTD.INTEGRATIONS.KEY_NAME') },
  { id: 'keyValue', label: t('MTD.INTEGRATIONS.KEY_VALUE') },
  { id: 'valueType', label: t('MTD.INTEGRATIONS.VALUE_TYPE') },
];

export const rowMapping = [
  {
    path: 'keyName',
    columnContent: (fullData: IGenericFullData) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>{fullData?.rowData?.keyName ?? ''}</div>
        {(fullData?.rowData?.valueType ?? '') !== 'Comment' ? (
          <CopyToClipboard text={fullData?.rowData?.keyName ?? ''}>
            <MUIIconButton>
              <MUIContentCopyIcon />
            </MUIIconButton>
          </CopyToClipboard>
        ) : null}
      </div>
    ),
  },
  {
    path: 'keyValue',
    columnContent: (fullData: IGenericFullData) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>{fullData?.rowData?.keyValue ?? ''}</div>
        {(fullData?.rowData?.valueType ?? '') !== 'Comment' ? (
          <CopyToClipboard text={fullData?.rowData?.keyValue ?? ''}>
            <MUIIconButton>
              <MUIContentCopyIcon />
            </MUIIconButton>
          </CopyToClipboard>
        ) : null}
      </div>
    ),
  },
  { path: 'valueType' },
];
