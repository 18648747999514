import { fetchAllAppPolicies, fetchAppPolicyById } from 'api/AppPolicyService';
import { ISelectItem } from 'components/UI/input/Select';
import { IAppPolicy, IAppPolicyTriggerRule } from './models';

export const fetchPolicies = async (
  teamId?: string | undefined
): Promise<Array<ISelectItem & { created: string }>> => {
  const { data: policies }: { data: Partial<IAppPolicy>[] } =
    await fetchAllAppPolicies({
      teamId,
    });

  return policies.map(({ name, id, created, team }: Partial<IAppPolicy>) => {
    return {
      label: name,
      value: id,
      created,
      accountBounded: !team?.id,
      team,
    } as ISelectItem & { created: string };
  });
};

export const fetchPolicyRulesById = async (id?: string | undefined) => {
  const policy: { data: IAppPolicy } = await fetchAppPolicyById({ id });
  const modified = policy?.data?.modified;
  const groups = policy?.data?.groups;
  const policyDetails = policy?.data?.rules?.map(({ criteria, ...rest }) => ({
    ...rest,
    criteria: criteria.map(({ id, ...rest }) => {
      const result = { ...rest };

      if (!!id) {
        result.uuid = id;
      }

      return result;
    }),
  }));
  const triggerRules: IAppPolicyTriggerRule[] =
    policy?.data?.triggerRules.map((rule) => ({
      ...rule,
      existingTriggerRule: true,
    })) ?? [];
  return { policyDetails, triggerRules, modified, groups };
};
