import cc from 'classcat';
import { getActiveModalAtom } from 'atoms/modals';
import {
  availableTeams as availableTeamsAtom,
  selectedTeam as selectedTeamAtom,
} from 'atoms/teams';
import Modal from 'components/UI/Modal';
import { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { SelectProvider_TITLE } from '../SelectProvider';
import { savedIntuneFormData as savedIntuneFormDataAtom } from './IntuneForm/atoms';
import { IAddEditCloneEMMAuth } from './models';
import StandardForm from './StandardForm';
import useStyles from './useStyles';
import { MODAL_TITLES, NON_STANDARD_CONNECTORS } from './utils';
import HorizontalLinearStepper from '../SelectProvider/stepper';
import { useTranslation } from 'react-i18next';
// The following connector types share the StandardForm component:
// - UemConnector/BlackBerry (with Proxy field determined by prop)
// - MobileIronCoreConnector
// - MobileIronCloudConnector
// - Citrix
// - Jamf

// Any connectors not using the StandardForm should be listed in NON_STANDARD_CONNECTORS array in the utils.tsx file.

export const AddEditCloneEMMAuth_TITLE = 'AddEditCloneEMMAuth';

const activeModalAtom = getActiveModalAtom<IAddEditCloneEMMAuth>();

const handleOnLoad = () => {
  document
    .querySelector('.is-loading')
    ?.classList.remove('is-loading', 'shimmer');
};

const AddEditCloneEMMAuth: React.FC = () => {
  const classes = useStyles();
  const { t, ready } = useTranslation();

  const [savedIntuneFormData, setSavedIntuneFormData] = useRecoilState(
    savedIntuneFormDataAtom
  ); // Used to clear Intune auth data when modal closed
  const [activeModal, setActiveModal] = useRecoilState(activeModalAtom);
  const selectedTeamId = useRecoilValue(selectedTeamAtom) || undefined;
  const availableTeams = useRecoilValue(availableTeamsAtom) || [];
  const connector =
    savedIntuneFormData?.connector ?? activeModal?.payload?.connector;
  const logoUrl = !!connector?.logo ? `api/emm/${connector.logo}` : '';

  const handleClose = useCallback(() => {
    setSavedIntuneFormData(undefined);
    setActiveModal(undefined);
  }, [setActiveModal, setSavedIntuneFormData]);

  const Form =
    NON_STANDARD_CONNECTORS.find(({ id }) => id === connector?.id)?.component ??
    StandardForm;

  const handleBackClick = useCallback(() => {
    if (activeModal?.payload?.mode === 'ADD') {
      setActiveModal({ active: SelectProvider_TITLE });
      return;
    }
  }, [activeModal?.payload?.mode, setActiveModal]);
  const handleCancelClick = useCallback(() => {
    setActiveModal({
      active: undefined,
    });
  }, [setActiveModal]);

  if (!availableTeams?.[0]) {
    return null;
  }

  if (!ready) {
    return null;
  }

  // This function should be called in handleSubmit of each form.
  // This is here instead of in the forms so that modal managment is handled in one place.
  return (
    <Modal
      caption={t(
        activeModal?.payload?.mode &&
          Object.keys(MODAL_TITLES).includes(activeModal.payload.mode)
          ? MODAL_TITLES[activeModal.payload.mode]
          : MODAL_TITLES['ADD']
      )}
      className={classes.modal}
      onClose={handleClose}
      scrollable={true}
      title={AddEditCloneEMMAuth_TITLE}
    >
      <div className={classes.logoSection}>
        <div
          className={cc([
            'is-loading shimmer',
            classes.imageBox,
            { [classes.citrixImageBox]: connector?.id === 'CitrixConnector' },
          ])}
        >
          <div className={classes.imgBox}>
            <img
              alt={connector?.description ?? 'connector logo'}
              className={classes.image}
              src={logoUrl}
              onLoad={handleOnLoad}
            />
          </div>
          <p className={classes.label}>{connector?.description ?? ''}</p>
        </div>
        <div className={classes.stepperForm}>
          <HorizontalLinearStepper labelId={connector?.id} status={1} />
          <Form
            availableTeams={availableTeams}
            connector={connector}
            data={activeModal?.payload?.data}
            handleBackClick={handleBackClick}
            handleCancelClick={handleCancelClick}
            mode={activeModal?.payload?.mode}
            msAuthResponse={activeModal?.payload?.msAuthResponse} // Sends back Intune auth data (admin_consent/tenant)
            selectedTeamId={selectedTeamId ?? ''}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddEditCloneEMMAuth;
