import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    card: {
      alignItems: 'center',
      backgroundColor: palette.background.default,
      border: `1px solid ${palette.primary.border}`,
      display: 'flex',
      justifyContent: 'center',
      padding: '4px 0',
      marginBottom: 20,
    },
    cardBold: {
      fontSize: 22,
      fontWeight: 'bold',
      marginBottom: 0,
    },
    inputGroupBackground: {
      color: palette.text.contrast,
      backgroundColor: palette.components.checkboxInputGroup.base,
    },
    inputGroup: {
      display: 'flex',
      flexDirection: 'row',
    },
    inputGroupMargin: {
      marginRight: 60,
    },
    inputGroupMarginTop: {
      marginLeft: 24,
      marginTop: 15,
    },
    inputGroupText: {
      fontSize: 12,
    },
    inputGroupTooltip: {
      marginLeft: 10,
    },
    inputGroupField: {
      justifyContent: 'flex-start',
      marginLeft: 10,
    },
    accordion: {
      boxShadow: 'none',
      margin: '10px 0 !important',
    },
    accordionHeader: {
      backgroundColor: palette.background.default,
      border: `1px solid ${palette.primary.border}`,
      color: palette.white,
      minHeight: '65px',
      '& .MuiAccordionSummary-expandIcon': {
        '&.Mui-expanded': {
          transform: 'rotate(90deg)',
        },
      },
      '& svg': {
        width: 14,
      },
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
    },
    accessText: {
      margin: '10px -10px',
      lineHeight: '40px',
    },
    categoryGroupTitle: {
      fontSize: 35,
      fontWeight: 'bold',
      padding: '15px 0',
      marginBottom: 0,
    },
    categoryGroup: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      gap: 4,
      justifyContent: 'space-between',
      width: '100%',

      '& > *:first-child': {
        flex: 1,
        marginBottom: 10,
        marginRight: 12,
      },
      '& > button': {
        alignSelf: 'flex-end',
        display: 'inline-flex',
        flex: 0,
        margin: 0,
        marginBottom: 12,
      },
    },
    categoryListPadding: {
      padding: '20px',
    },
    categoryListColumnTitle: {
      backgroundColor: palette.background.paper,
      fontSize: 18,
      color: palette.text.contrast,
      fontWeight: 'bold',
      marginTop: '25px',
      marginBottom: 0,
      justifyContent: 'flex-start',
      '& > *:first-child': {
        marginLeft: '20px',
        width: '70%',
      },
      '& > *:last-child': {
        width: '30%',
        marginLeft: '20px',
      },
    },
    mainColumn: {
      flex: 'auto',
    },
    categoryList: {
      width: '40%',
      marginLeft: '20px',
      backgroundColor: palette.background.default,
      border: `1px solid ${palette.primary.border}`,
      height: '800px',
      maxHeight: '900px',
      overflow: 'scroll',
      flexDirection: 'column',
      display: 'none',
    },
    categoryListShow: {
      display: 'flex',
    },
    siteCatCard: {
      margin: '0 -10px',
    },
    siteCatCardText: {
      marginTop: '20px',
    },
    mainContainerMargin: {
      marginBottom: 22,
    },
    green: {
      backgroundColor: palette.common.green,
      fontSize: 13,
      padding: '8px 15px',
      textTransform: 'uppercase',

      '&:hover': {
        backgroundColor: palette.common.avocado,
      },
    },
    navigationWarning: {
      alignItems: 'center',
      display: 'flex',

      '& svg': {
        color: palette.common.tomato,
        height: 64,
        margin: '0 24px',
        minHeight: 64,
        minWidth: 64,
        width: 64,
      },
    },
    radioGroupTitle: {
      padding: '5px',
      marginTop: '10px',
      display: 'flex',
      maxWidth: 'fit-content',
      flexDirection: 'column',
    },
    radioGroup: {
      margin: '10px',
      display: 'flex',
      maxWidth: 'fit-content',
      flexDirection: 'column',
      "& label": {
        maxWidth: 'fit-content',
        paddingRight: 0,
      }
    },
    noPolicy: {
      border: '1px solid white',
      width: '100%',
      height: '75vh',
      display: 'flex',
      justifyContent: 'center'
    },
    noPolicyTitle: {
      marginTop: 400
    },
  })
);

export default useStyles;
