import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRouter from 'components/hocs/withRouter';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';

class GenericDataFilter extends Component {
  constructor(props) {
    super(props);
    this.handleSearchHandler = this.handleSearchHandler.bind(this);
  }

  handleSearchHandler() {
    const { props } = this;
    if (props.filterValue) {
      return props.updateUrl({ [props.path]: props.filterValue, page: 0 });
    }
    return props.updateUrl({ [props.path]: props.tableValue, page: 0 });
  }

  render() {
    const { props } = this;

    return (
      <Tooltip
        classes={{ tooltip: props.classes.tooltip }}
        title={<AddCircleOutline className={props.classes.circleIcon} />}
        placement="right"
      >
        <div className="hover--link" onClick={this.handleSearchHandler}>
          {props.showTableValue && props.tableValue}
          {props.children}
        </div>
      </Tooltip>
    );
  }
}

const styles = ({ palette }) => {
  return {
    tooltip: {
      background: 'transparent',
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    circleIcon: {
      fontSize: '16px',
      color: palette.text.primary,
    },
  };
};

GenericDataFilter.defaultProps = {
  showTableValue: true,
  tableValue: '',
};

export default withRouter(withStyles(styles)(GenericDataFilter));
