import { Api } from 'config/axiosConfig';
import { ServiceHandlerFactory } from 'utils/serviceUtils';
import _ from 'lodash';
import qs from 'qs';
import {
  createEditAppPayloadMapping,
  fetchZappsParamsMapping,
  fetchZappVersionsParamsMapping,
} from 'mappings/services/inAppsServiceMapping';

export function fetchAppsPage({ page, size, order, orderBy, teamfilter }) {
  const params = {
    page,
    size,
    sort: `${orderBy},${order}`,
    teamId: teamfilter,
  };
  return Api.get('/api/zapp/v1/zapps/page?', {
    params,
    paramsSerializer(_params) {
      return qs.stringify(_params, { arrayFormat: 'repeat' });
    },
  });
}

export const createNewApp = ServiceHandlerFactory({
  axios: {
    url: '/api/zapp/v1/zapps',
    method: 'post',
  },
  payloadSchema: createEditAppPayloadMapping,
});

export const editSelectedApp = ServiceHandlerFactory({
  axios: {
    url: '/api/zapp/v1/zapps/:appId',
    method: 'put',
  },
  payloadSchema: createEditAppPayloadMapping,
});

export const fetchAppVersionsPage = ServiceHandlerFactory({
  axios: {
    url: '/api/zapp/v1/zappVersions',
  },
  isCancelable: true,
});

export function fetchAllAppVersionsCount(teamId) {
  return Api.get('/api/zapp/v1/zapps/versions/count', {
    params: { teamId },
  });
}

export function fetchAppVersionsCount(zappId) {
  return Api.get(`/api/zapp/v1/zapps/${zappId}/versions/count`);
}

// sanitize data

export function cleanInAppsTable({ content }, groupsHash) {
  return content.map(app => {
    const newApp = { ...app };
    const groupName = _.get(groupsHash, `${app.groupId}.name`, 'not given');
    newApp.groupName = groupName;
    return newApp;
  });
}

/**
 * Fetch all apps by group id(s) - this api call gets a
 * list of all apps to which changes to a policy would be published to
 */
export function getAllAppsByGroupIds(groupIds) {
  const params = {
    groupId: groupIds,
  };
  return Api.get('/api/zapp/v1/zapps', {
    params,
    paramsSerializer(_params) {
      return qs.stringify(_params, { arrayFormat: 'repeat' });
    },
  });
}

export const downloadZappsCsv = ServiceHandlerFactory({
  axios: {
    url: '/api/zapp/v1/csv/zapps',
    method: 'post',
  },
  isCancelable: true,
  paramSchema: fetchZappsParamsMapping,
});

export const downloadZappVersionsCsv = ServiceHandlerFactory({
  axios: {
    url: '/api/zapp/v1/csv/zappVersions',
    method: 'post',
  },
  isCancelable: true,
  paramSchema: fetchZappVersionsParamsMapping,
});
