import { ILocationQuery } from 'components/UI/Table/models';
import memoizeTableQuery from 'components/UI/Table/utils/memoizeTableQuery';
import { fetchTableData } from './utils';
import { IZScanAssessmentResponseData } from './models';

const memoizeQuery = () => {
  return memoizeTableQuery(async (query: ILocationQuery) => {
    const data: IZScanAssessmentResponseData | undefined = await fetchTableData(
      query
    );

    return {
      data: data?.data?.content ?? [],
      count: data?.data?.totalElements ?? 0,
    };
  });
};
export default memoizeQuery;
