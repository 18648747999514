import MUIInfoIcon from '@material-ui/icons/InfoOutlined';
import Checkbox from 'components/UI/input/Checkbox';
import Select, { ISelectItem } from 'components/UI/input/Select';
import TextField from 'components/UI/input/TextField';
import { Field as FormikField } from 'formik';
import { useCallback, useState } from 'react';
import { syncModeSelections } from '../../../../emmTable/MDM/utils';
import { IIntuneFields } from '../../models';
import { IFormData } from '../models';
import useStyles from './useStyles';
import { TFunction } from 'react-i18next';

interface IIntuneFieldsProps {
  t: TFunction<'translation', undefined>;
  clearGroupTable: () => void;
  isSubmitting: boolean;
  setIntuneFields: React.Dispatch<React.SetStateAction<IIntuneFields>>;
  setFieldValue: (field: string, value: any) => void; // Formik setFieldValue (which uses any value)
  setThirdPartyMDMSelected: React.Dispatch<React.SetStateAction<boolean>>;
  thirdPartyMDMSelected: boolean;
  values: IFormData;
  isSOTIConnector: boolean;
  isGoogleConnector: boolean;
}

const IntuneFields: React.FC<IIntuneFieldsProps> = ({
  t,
  clearGroupTable,
  isSubmitting,
  setIntuneFields,
  setFieldValue,
  setThirdPartyMDMSelected,
  thirdPartyMDMSelected,
  values,
  isSOTIConnector,
  isGoogleConnector,
}) => {
  const [showNestedGroupsTooltip, setShowNestedGroupsTooltip] =
    useState<boolean>(false);
  const [showNestedGroupsTooltipAffinity, setShowNestedGroupsTooltipAffinity] =
    useState<boolean>(false);

  const classes = useStyles();

  const handleSyncModeChange = useCallback(
    (field: string, value: ISelectItem) => {
      setFieldValue(field, value);
      if (value?.value === 'MAM_WITH_OTHER_MDM_MODE') {
        clearGroupTable();
      }
      setThirdPartyMDMSelected(value?.value === 'MAM_WITH_OTHER_MDM_MODE');
      if (typeof setIntuneFields === 'function') {
        setIntuneFields((prevState) => ({
          ...prevState,
          mode: value.value as string,
        }));
      }
    },
    [clearGroupTable, setThirdPartyMDMSelected, setIntuneFields, setFieldValue]
  );

  const handleMaxIdleDaysChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue('maxDeviceIdleDays', Number(e.currentTarget.value));
      if (typeof setIntuneFields === 'function') {
        setIntuneFields((prevState) => ({
          ...prevState,
          maxDeviceIdleDays: Number(e.currentTarget.value),
        }));
      }
    },
    [setIntuneFields, setFieldValue]
  );

  const handleCheckNestedGroups = useCallback(
    (value: boolean) => {
      if (typeof setIntuneFields === 'function') {
        setIntuneFields((prevState) => ({
          ...prevState,
          includeNestedGroups: value,
        }));
      }
    },
    [setIntuneFields]
  );
  const handleDeviceWithoutUserAffinity = useCallback(
    (value: boolean) => {
      if (typeof setIntuneFields === 'function') {
        setIntuneFields((prevState) => ({
          ...prevState,
          allowDevicesWithNoUser: value,
        }));
      }
    },
    [setIntuneFields]
  );

  if (isSOTIConnector || isGoogleConnector) {
    return (
      <FormikField
        color="primary"
        checked={values?.includeNestedGroups}
        component={Checkbox}
        disabled={isSubmitting}
        label={
          isGoogleConnector
            ? t('MTD.INTEGRATIONS.NESTED_ORG_UNITS')
            : t('MTD.INTEGRATIONS.INCLUDE_NESTED_GROUPS')
        }
        name="includeNestedGroups"
        onClick={() => {
          handleCheckNestedGroups(!values?.includeNestedGroups);
        }}
      />
    );
  }
  return (
    <>
      <div className={classes.mainContainerIntune}>
        <div className={classes.SyncModeStyles}>
          <FormikField
            component={Select}
            disableClearable
            disabled={isSubmitting}
            label={t('MTD.INTEGRATIONS.SYNC_MODE')}
            multiple={false}
            name="syncMode"
            options={syncModeSelections(t)}
            setFieldValue={(field: string, value: ISelectItem) =>
              handleSyncModeChange(field, value)
            }
          />
        </div>

        <FormikField
          component={TextField}
          className={classes.maxIdle}
          disabled={isSubmitting}
          label={t('MTD.INTEGRATIONS.MAX_IDLE_OF_DAYES')}
          name="maxDeviceIdleDays"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleMaxIdleDaysChange(e);
          }}
          type="number"
        />
        <div className={classes.nestedGroup}>
          {!thirdPartyMDMSelected && (
            <div className={classes.mainNestedGroup}>
              <div className={classes.nestedGroups}>
                <FormikField
                  color="primary"
                  checked={values?.includeNestedGroups}
                  component={Checkbox}
                  disabled={isSubmitting}
                  label={t('MTD.INTEGRATIONS.INCLUDE_NESTED_GROUPS')}
                  name="includeNestedGroups"
                  onClick={() => {
                    handleCheckNestedGroups(!values?.includeNestedGroups);
                  }}
                />
                {/* This was used instead of Tooltip because it wasn't working inside a modal.
                        The tooltipText was being added to the DOM, but it wasn't visible, even
                        after setting zIndex. */}
                <div
                  className={classes.tooltip}
                  onMouseEnter={() => setShowNestedGroupsTooltip(true)}
                  onMouseLeave={() => setShowNestedGroupsTooltip(false)}
                >
                  <MUIInfoIcon color="primary" />
                  {showNestedGroupsTooltip && (
                    <div className={classes.tooltipText}>
                      {t('MTD.INTEGRATIONS.INCLUDE_NESTED_GROUP_TOOLTIP')}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.nestedGroups}>
                <FormikField
                  color="primary"
                  checked={values?.allowDevicesWithNoUser}
                  component={Checkbox}
                  disabled={isSubmitting}
                  label={t('MTD.INTEGRATIONS.ALLOW_DEVICES_AFFINITY')}
                  name="allowDevicesWithNoUser"
                  onClick={() => {
                    handleDeviceWithoutUserAffinity(
                      !values?.allowDevicesWithNoUser
                    );
                  }}
                />
                {/* This was used instead of Tooltip because it wasn't working inside a modal.
                        The tooltipText was being added to the DOM, but it wasn't visible, even
                        after setting zIndex. */}
                <div
                  className={classes.tooltip}
                  onMouseEnter={() => setShowNestedGroupsTooltipAffinity(true)}
                  onMouseLeave={() => setShowNestedGroupsTooltipAffinity(false)}
                >
                  <MUIInfoIcon color="primary" />
                  {showNestedGroupsTooltipAffinity && (
                    <div className={classes.tooltipText}>
                      {t('MTD.INTEGRATIONS.AFFINITY_TOOLTIP')}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default IntuneFields;
