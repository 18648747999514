import _ from 'lodash';
import Moment from 'moment';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { toggleModalDiffered } from 'utils/storeUtils';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';

// import { Link } from 'react-router-dom';
// import Tooltip from '@material-ui/core/Tooltip';
// import ConfigurationIcon from '@material-ui/icons/Ballot';

export const integrationTableHeaders = [
  {
    id: 'issueManagementSystem.name',
    label: 'ZSCAN.INTEGRATIONS.TICKETING_SYSTEM',
    show: true,
    sort: true,
  },
  {
    id: 'name',
    label: 'GLOBAL.NAME',
    show: true,
    sort: false,
  },
  {
    id: 'url',
    label: 'URL',
    show: true,
    sort: true,
  },
  {
    id: 'modified',
    label: 'GLOBAL.LAST_MODIFIED',
    show: true,
    sort: true,
  },
  {
    id: 'rowActions',
    label: 'GLOBAL.ACTIONS',
    show: true,
  },
];

export const integrationTableColumns = [
  {
    path: 'issueManagementSystem.name',
  },
  {
    path: 'name',
  },
  {
    path: 'url',
  },
  {
    path: 'modified',
    columnContent: (timestamp) => Moment(timestamp).fromNow(),
    csvContent: (timestamp) => Moment(timestamp).toISOString(),
  },
  {
    path: 'rowActions',
    cellInlineStyle: { justifyContent: 'flex-start', display: 'flex' },
    columnContent: (_, { rowData, isLastRow }) => {
      return (
        <ProtectedComponent allow={{ zdev_apps: 'manage' }}>
          <EditIcon
            className="hover--link"
            onClick={toggleModalDiffered('IntegrationCreateEdit', rowData, {
              title: 'ZSCAN.INTEGRATIONS.EDIT_INTEGRATION',
              disableBackdropClick: true,
            })}
          />
          <DeleteIcon
            className="hover--link"
            onClick={toggleModalDiffered('IntegrationDeleteConfirm', rowData, {
              title: 'ZSCAN.INTEGRATIONS.DELETE_INTEGRATION',
              disableBackdropClick: true,
            })}
          />
          {isLastRow && (
            <AddIcon
              className="hover--link"
              onClick={toggleModalDiffered(
                'IntegrationCreateEdit',
                {},
                {
                  title: 'ZSCAN.INTEGRATIONS.CREATE_NEW_INTEGRATION',
                  disableBackdropClick: true,
                }
              )}
            />
          )}
        </ProtectedComponent>
      );
    },
  },
  // {
  //   path: 'id',
  //   columnContent: (integrationId, { rowData }) => {
  //     return (
  //       <Tooltip
  //         title={`${rowData.name} Configurations`}
  //         enterDelay={200}
  //         placement="top"
  //       >
  //         <Link
  //           style={{ lineHeight: 0, marginRight: 5 }}
  //           to={`/console/zscan/integrations/configurations?iid=${integrationId}`}
  //         >
  //           <ConfigurationIcon />
  //         </Link>
  //       </Tooltip>
  //     );
  //   },
  // },
  // {
  //   path: 'status',
  //   columnContent: (status, { classList, theme }) => {
  //     return (
  //       <div
  //         className={classList.statusCircle}
  //         style={{
  //           background: status
  //             ? theme.palette.common.paleGreen
  //             : theme.palette.common.tomato,
  //         }}
  //       />
  //     );
  //   },
  // },
];

export const buildIntegrationsParams = [
  {
    paramKey: 'page',
    transform: ({ page }) => {
      if (page) {
        return page;
      }
      return 0;
    },
  },
  {
    paramKey: 'size',
    transform: ({ size }) => {
      if (size) {
        return size;
      }
      return 25; // Todo: magic number make a global default for page size!
    },
  },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      if (orderBy && order) {
        return `${orderBy},${order}`;
      }
      return null;
    },
  },
];

export const buildIntegrationPayload = [
  'name',
  'url',
  'authTypeBasicAuth',
  'username',
  'password',
  {
    path: 'issueManagementSystemId',
    transform: (payload) => _.get(payload, 'issueManagementSystemId[0].value'),
  },
];
