import * as Yup from 'yup';
import {
  csvInjectionRegex,
  csvInjectionErrorMessage,
} from 'utils/componentUtils';
import { TFunction } from 'react-i18next';

const Schema = (t: TFunction<'translation', undefined>) => {
  return Yup.object().shape({
    aad_apps_consent: Yup.array().test({
      name: 'all-apps-authenticated',
      // function keyword used below because we need access to "this"
      test: function (value) {
        return !!value?.[0] && value.some(({ authenticated }) => !authenticated)
          ? this?.createError({
              message: t(
                'MTD.VALIDATIONS.ALL_CONNECTIONS_TO_AZURE_DIRECTORY_ARE_REQUIRED'
              ),
              path: 'aad_apps_consent',
            })
          : true;
      },
    }),
    name: Yup.string()
      .matches(csvInjectionRegex, csvInjectionErrorMessage)
      .required(t('MTD.VALIDATIONS.NAME_IS_REQUIRED')),
    team: Yup.mixed().test({
      name: 'team-required-if-all-teams',
      // function keyword used below because we need access to "this"
      test: function (value, ctx) {
        if (!!ctx?.parent?.selectedTeamId) {
          return true;
        }

        return !value
          ? this?.createError({
              message: t('GLOBAL.REQUIRED_TEAM'),
              path: 'team',
            })
          : true;
      },
    }),
  });
};

export default Schema;
