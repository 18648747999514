import { isArray, isFunction, get } from 'lodash';
import React from 'react';
import { MultiSelect } from 'components/Selects';
import { FormError } from './FormError';

export const FormikMultiSelect = ({ field, form, options, ...props }) => {
  function onChange(name, values) {
    form.setFieldValue(name, isArray(values) ? values : [values]);
    form.setTouched({ [name]: true });

    if (isFunction(props.onChange)) {
      props.onChange(name, values);
    }
  }
  return (
    <div style={{ position: 'relative' }}>
      <MultiSelect
        {...field}
        {...props}
        options={options}
        values={field.value}
        onChange={onChange}
      />
      {form.touched[field.name] && form.errors[field.name] && (
        <FormError
          field={field}
          form={form}
          classlist={{ errors: get(props, 'classlist.errorClass') }}
        />
      )}
    </div>
  );
};
