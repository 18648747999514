import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    teamSelector: {
      paddingTop: '20px',
      color: palette.text.secondary,
    },
  })
);

export default useStyles;
