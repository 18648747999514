import { IFilterValues } from './models';

export const AVAILABLE_SEVERITIES = [
  {
    label: 'Critical',
    value: 'critical',
  },
  {
    label: 'High',
    value: 'high',
  },
  {
    label: 'Medium',
    value: 'medium',
  },
  {
    label: 'Low',
    value: 'low',
  },
];

export const AVAILABLE_OSES = [
  {
    label: 'Android',
    value: 1,
  },
  {
    label: 'iOS',
    value: 2,
  },
];

export const INITIAL_FILTER_VALUES: IFilterValues = {
  deviceCount: null,
  id: '',
  nonUpgradeableCount: null,
  oses: null,
  osvCount: null,
  severity: '',
  type: '',
  upgradeableCount: null,
};
