import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ config, palette }: any) =>
  createStyles({
    appSection: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '20px',
      marginTop: '20px',
      width: '100%',
    },
    blue: {
      background: palette.components.statusDot.normal,
    },
    divider: {
      borderRight: '2px solid white',
      marginRight: '30px',
      paddingRight: '30px',
    },
    drawerCard: {
      backgroundColor: config.colors.marineBlue,
      borderRadius: '4px',
      color: config.colors.almostWhite,
    },
    elevated: {
      background: palette.components.statusDot.elevated,
    },
    firstApp: {
      marginLeft: 30,
    },
    green: {
      background: config.colors.avocado,
    },
    lastApp: {
      marginRight: 30,
    },
    multipleApps: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      justifyContent: 'space-between',
      overflowX: 'auto',
    },
    multipleAppsHeader: {
      color: palette.text.primary,
      backgroundColor: palette.background.default,
      fontSize: 18,
      margin: '10px 30px 20px 30px',
      padding: '10px 0px 10px 0px',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    multipleAppsSection: {
      width: '100%',
    },
    permissionsTab: {
      padding: 30,
    },
    red: {
      background: palette.components.statusDot.critical,
    },
    statusCard: {
      margin: '30px 40px 0px 40px',
      '& > div': {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        padding: '15px 0px 30px 0px',
        width: '100%',
      },
      '& > div > div': {
        marginLeft: '40px',
        marginRight: '40px',
        width: '33%',
      },
      '& h3': {
        borderBottom: '1px solid white',
        paddingBottom: 7,
        width: '100%',
      },
      '& table': { width: '100%' },
      '& table > tbody > tr > td > div': {
        marginRight: 0,
        paddingRight: 0,
        width: '100%',
      },
    },
    statusDot: {
      borderRadius: '50%',
      height: 12,
      marginRight: 6,
      width: 12,
    },
    tabMargin: {
      marginTop: 20,
    },
    tabs: {
      padding: '10px 15px 30px 15px',
    },
    topCardMargin: {
      margin: '30px 10px 0px 10px',
    },
    topSection: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 30,
      width: '100%',
      overflow: 'auto',
    },
    yellow: {
      background: palette.components.statusDot.low,
    },
    threatsTooltip: {
      position: 'relative',
      top: '2px',
      marginRight: '10px',
    },
    threatsLink: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'right',
      color: 'inherit',
    },
    inputIcon: {
      color: palette.components.globalHeader.contrastText,
    },
    appleIcon: {
      fill: palette.components.globalHeader.contrastText,
      height: '25px',
      width: '25px',
      stroke: palette.calendar.native.floatingNav,
    },
    androidIcon: {
      color: palette.components.globalHeader.contrastText,
      minHeight: '25px',
      minWidth: '25px',
    },
    chromeIcon: {
      fill: palette.components.globalHeader.contrastText,
      height: '25px',
      width: '25px',
    },
  })
);

export default useStyles;
