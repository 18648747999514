import { fetchAllPolicies, fetchPolicyById } from 'api/OSRiskService';
import { ISelectItem } from 'components/UI/input/Select';
import { IOsRiskPolicy, IRiskRules } from './models';

export const fetchPolicies = async (
  teamId?: string | null
): Promise<Array<ISelectItem & { created: string }>> => {
  const { data: policies }: { data: Partial<IOsRiskPolicy>[] } =
    await fetchAllPolicies(teamId);

  return policies.map(
    ({ name, id, created, ...rest }: Partial<IOsRiskPolicy>) =>
      ({ label: name, value: id, created, ...rest } as ISelectItem & {
        created: string;
      })
  );
};

const convertToString = (rules: IRiskRules): IRiskRules => {
  return {
    osRuleType: rules.osRuleType,
    gracePeriodInDays: rules.gracePeriodInDays
      ? rules.gracePeriodInDays.toString()
      : null,
    minimumAllowedVersion: rules.minimumAllowedVersion
      ? rules.minimumAllowedVersion.toString()
      : null,
    numAllowedPriorMajorVersions: rules.numAllowedPriorMajorVersions
      ? rules.numAllowedPriorMajorVersions.toString()
      : null,
  };
};

export const fetchPolicyDetails = async (
  id?: string | number | null
): Promise<IOsRiskPolicy> => {
  const response = await fetchPolicyById(id);
  return {
    ...response.data,
    osRiskPolicyRules: {
      iosRiskRules: convertToString(
        response.data.osRiskPolicyRules.iosRiskRules
      ),
      androidRiskRules: convertToString(
        response.data.osRiskPolicyRules.androidRiskRules
      ),
    },
  };
};
