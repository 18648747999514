import { FieldProps as IFormikFieldProps } from 'formik';
import React from 'react';
import useStyles from './useStyles';

export interface IInputErrorProps extends Partial<IFormikFieldProps> {
  override?: string;
}

const InputError: React.FC<IInputErrorProps> = ({ form, field, override }) => {
  const classes = useStyles();

  const error =
    (!!form?.touched[field?.name ?? ''] && form?.errors[field?.name ?? '']) ||
    !!override;

  return !!error ? (
    <span className={classes.inputError}>{!!override ? override : error}</span>
  ) : null;
};

export default InputError;
