import { default as MUIAddIcon } from '@material-ui/icons/Add';
import { default as MUIDeleteIcon } from '@material-ui/icons/Delete';
import { default as MUIEditIcon } from '@material-ui/icons/Edit';
import { default as MUIContentCopyIcon } from '@material-ui/icons/FilterNone';
import useStyles from './useStyles';
interface InoDataTableContent {
  onHandleClick?: () => void;
  isAllowedToManage?: boolean;
}

const EmptyTableData: React.FC<InoDataTableContent> = ({ isAllowedToManage, onHandleClick }) => {
  const classes = useStyles();
  return isAllowedToManage ? (
    <div className={classes.noDataTableContent}>
      <MUIEditIcon className={classes.disableIconStyle} />
      <MUIContentCopyIcon className={classes.disableIconStyle} />
      <MUIDeleteIcon className={classes.disableIconStyle} />
      <MUIAddIcon className={classes.addIconStyle} onClick={onHandleClick} />
    </div>
  ) : (<div className={classes.noDataTableContent}></div>);
};

export default EmptyTableData;
