import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    errorBox: {
      alignItems: 'center',
      background: palette.background.default,
      borderColor: palette.error.main,
      borderStyle: 'double',
      borderWidth: 1.5,
      display: 'flex',
      justifyContent: 'space-between',
      padding: '2px 10px',

    },
    intuneError: {
      lineHeight: 1.5,
      textAlign: 'center',
      minWidth: 150,
      whiteSpace: 'pre-wrap',
      color: palette.text.primary
    },
  })
);

export default useStyles;
