import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { capitalizeFirstLetter } from 'utils';

type DataPair = {
  [key: string]: string[];
};

interface IPublicForensic {
  configObj: DataPair;
}

const PublicForensic: React.FC<IPublicForensic> = ({ configObj }) => {
  const classes = useStyles();

  return (
    <>
      {Object.keys(configObj)
        .sort()
        .map((key: string, index: number) => (
          <div key={`${index}`} className={classes.dataWrapper}>
            <div className={classes.label}>{capitalizeFirstLetter(key)}</div>
            {Array.isArray(configObj[key]) ? (
              <div className={classes.arrWrapper}>
                {configObj[key].map((subValue: string, subIndex: number) => (
                  <div key={`${subIndex}`}>{subValue}</div>
                ))}
              </div>
            ) : (
              <div>{configObj[key]}</div>
            )}
          </div>
        ))}
    </>
  );
};

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    dataWrapper: {
      display: 'flex',
      padding: 5,
    },
    arrWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      color: palette.text.secondary,
      fontWeight: 'bold',
      width: '25%',
    },
  })
);

export default PublicForensic;
