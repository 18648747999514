import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

export default state => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      adminAccounts: {
        ...uiSettingsDefaults.adminAccounts,
        tableHeaders: uiSettingsDefaults.adminAccounts.tableHeaders,
      },
    },
  };
};
