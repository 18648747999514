import AT from 'actions/ActionTypes';
import _ from 'lodash';

const PrivacyPolicyDefault = {
  loading: 'false',
  privacyPolicies: [],
  selectedPrivacyPolicy: null,
  privacyPolicyCollectibles: [],
  error: null,
  privacyPolicyModalOpen: false,
  modalContent: '',
  pristine: true,
  privacyEditMode: false,
};

export const getPrivacyPolicyModalContent = state =>
  state.PrivacyPolicy.modalContent;
export const getPrivacyPolicyModalOpen = state =>
  state.PrivacyPolicy.privacyPolicyModalOpen;
export const getPrivacyPolicies = state => state.PrivacyPolicy.privacyPolicies;
export const getSelectedPrivacyPolicy = state =>
  state.PrivacyPolicy.selectedPrivacyPolicy;
export const getPrivacyPolicyCollectibles = state =>
  state.PrivacyPolicy.privacyPolicyCollectibles;
export const getSelectedPrivacyPolicyRulesByCategory = state => {
  const { PrivacyPolicy } = state;
  const { privacyPolicyCollectibles, selectedPrivacyPolicy } = PrivacyPolicy;
  if (privacyPolicyCollectibles && selectedPrivacyPolicy) {
    const { rules } = selectedPrivacyPolicy;
    const { byCollectibleId } = privacyPolicyCollectibles;
    const categorizedRules = rules.reduce((accum, value) => {
      const collectible = byCollectibleId[value.collectibleId];

      if (collectible) {
        const { category } = collectible;
        const newValue = {
          ...value,
        };
        if (accum[category]) {
          // if the category exists ?  push   else {  accum [category] = [];   .push(newvalue)}
          accum[category].push(newValue);
        } else {
          /* eslint-disable no-param-reassign */
          accum[category] = [];
          accum[category].push(newValue);
          /* eslint-enable no-param-reassign */
        }
      }
      return accum;
    }, {});
    return categorizedRules;
  }
  return null;
};

export const getAllGlobalPrivacyPolicies = state => {
  return _.filter(state.PrivacyPolicy.privacyPolicies, { team: null });
};

export const getPrivacyPristine = state => state.PrivacyPolicy.pristine;
export const getPrivacyEditMode = state => state.PrivacyPolicy.privacyEditMode;
export const PrivacyPolicy = (state = PrivacyPolicyDefault, action) => {
  switch (action.type) {
    case AT.FETCH_PRIVACY_COLLECTIBLES_REQUEST:
      return {
        ...state,
        loading: true,
        privacyPolicyCollectibles: null,
      };
    case AT.FETCH_PRIVACY_COLLECTIBLES_SUCCEEDED:
      const categorizedCollectibles = action.payload.reduce(
        (accum, value) => {
          const { category } = value;
          if (category === 0) {
            accum.device.push(value);
          }
          if (category === 1) {
            accum.network.push(value);
          }
          if (category === 2) {
            accum.applications.push(value);
          }

          /* eslint-disable no-param-reassign */
          accum.byCollectibleId[value.id] = value;
          /* eslint-enable no-param-reassign */

          return accum;
        },
        {
          applications: [],
          network: [],
          device: [],
          byCollectibleId: {},
        }
      );
      return {
        ...state,
        loading: false,
        privacyPolicyCollectibles: categorizedCollectibles,
      };
    case AT.FETCH_PRIVACY_COLLECTIBLES_FAILURE:
      return {
        ...state,
        loading: false,
        privacyPolicyCollectibles: [],
        error: 'error',
      };
    case AT.FETCH_PRIVACY_POLICIES_REQUEST:
      return {
        ...state,
        loading: true,
        // selectedPrivacyPolicy: null
      };
    case AT.FETCH_PRIVACY_POLICIES_SUCCEEDED:
      return {
        ...state,
        loading: false,
        privacyPolicies: action.payload,
      };
    case AT.FETCH_PRIVACY_POLICIES_FAILURE:
      return {
        ...state,
        loading: false,
        privacyPolicies: [],
        error: 'error',
      };
    case AT.SET_SELECTED_PRIVACY_POLICY_REQUEST:
      return {
        ...state,
        loading: true,
        // selectedPrivacyPolicy: null
      };
    case AT.SET_SELECTED_PRIVACY_POLICY_SUCCEEDED:
      return {
        ...state,
        loading: true,
        selectedPrivacyPolicy: action.payload,
        pristine: true,
      };

    case AT.CLEAR_SELECTED_PRIVACY_POLICY:
      return {
        ...state,
        selectedPrivacyPolicy: null,
      };
    case AT.HANDLE_CHANGE_SELECTED_PRIVACY_POLICY:
      const { ruleToAdd, value, option } = action.payload;
      ruleToAdd[option] = value;
      const finalOptionToChange = state.selectedPrivacyPolicy.rules.map(
        rule => {
          if (rule.id === ruleToAdd.id) {
            return ruleToAdd;
          }
          return rule;
        }
      );
      const objCopy = { ...state.selectedPrivacyPolicy };

      objCopy.rules = finalOptionToChange;
      return {
        ...state,
        selectedPrivacyPolicy: objCopy,
        pristine: false,
      };

    case AT.HANDLE_CHANGE_LOCATION_SELECTED_PRIVACY_POLICY:
      const objCopyLocation = { ...state.selectedPrivacyPolicy };

      objCopyLocation.locationAccuracy = action.payload;
      return {
        ...state,
        selectedPrivacyPolicy: objCopyLocation,
        pristine: false,
      };
    case AT.HANDLE_CHANGE_NAME_SELECTED_PRIVACY_POLICY:
      const objNameCopy = { ...state.selectedPrivacyPolicy };

      objNameCopy.name = action.payload;
      return {
        ...state,
        selectedPrivacyPolicy: objNameCopy,
        pristine: false,
      };

    case AT.UPDATE_SELECTED_PRIVACY_POLICY_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case AT.UPDATE_SELECTED_PRIVACY_POLICY_SUCCEEDED:
      const newPrivacyListUpdate = state.privacyPolicies.slice(0);
      _.remove(
        newPrivacyListUpdate,
        element => element.id === action.payload.id
      );
      newPrivacyListUpdate.push({
        name: action.payload.name,
        id: action.payload.id,
        team: action.payload.team,
      });
      const privacySortedArray = _.sortBy(newPrivacyListUpdate, [
        privacyPolicy => privacyPolicy.name.toLowerCase(),
      ]);
      return {
        ...state,
        privacyPolicies: privacySortedArray,
        loading: false,
        pristine: true,
      };
    case AT.UPDATE_SELECTED_PRIVACY_POLICY_FAILURE:
      return {
        ...state,
        error: 'error',
      };
    case AT.CREATE_NEW_PRIVACY_POLICY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AT.CREATE_NEW_PRIVACY_POLICY_SUCCEEDED:
      const newPrivacyList = [...state.privacyPolicies];
      newPrivacyList.push({
        name: action.payload.name,
        id: action.payload.id,
        team: action.payload.team,
      });
      const sortedPrivacyArray = _.sortBy(newPrivacyList, [
        element => {
          return element.name.toLowerCase();
        },
      ]);
      return {
        ...state,
        loading: false,
        privacyPolicies: [...sortedPrivacyArray],
        selectedPrivacyPolicy: action.payload,
      };
    case AT.CREATE_NEW_PRIVACY_POLICY_FAILURE:
      return {
        ...state,
        error: 'error',
      };
    case AT.DELETE_PRIVACY_POLICY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AT.DELETE_PRIVACY_POLICY_SUCCEEDED:
      const privacyPolicyListToDelete = state.privacyPolicies.slice(0);
      _.remove(privacyPolicyListToDelete, privacyPolicyToDelete => {
        return privacyPolicyToDelete.id === action.payload;
      });
      return {
        ...state,
        privacyPolicies: privacyPolicyListToDelete,
        selectedPrivacyPolicy: null,
        loading: false,
      };
    case AT.DELETE_PRIVACY_POLICY_FAILURE:
      return {
        ...state,
        error: 'error',
      };
    case AT.PRIVACY_POLICY_MODAL_OPEN:
      return {
        ...state,
        privacyPolicyModalOpen: true,
        modalContent: action.payload,
      };
    case AT.PRIVACY_POLICY_MODAL_CLOSE:
      return {
        ...state,
        privacyPolicyModalOpen: false,
        modalContent: 'delete',
      };
    case AT.PRIVACY_POLICY_EDIT_MODE:
      return {
        ...state,
        privacyEditMode: action.payload,
      };
    case AT.USER_LOGOUT:
      return {
        ...state,
        groups: [],
        loading: false,
        selectedPrivacyPolicy: null,
        privacyPolicies: [],
      };

    default:
      return state;
  }
};
