import { TextField } from '@material-ui/core';
import cc from 'classcat';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import React from 'react';
import Button, { IButtonProps } from '../Button';
import Select, { ISelectItem, ISelectProps } from '../input/Select';
import useStyles from './useStyles';

interface IViewSelectorAction extends Omit<IButtonProps, 'text' | 'isLoading'> {
  onClick: () => void;
  disabled?: boolean;
}

interface IViewSelectorProps extends Omit<ISelectProps, 'disableClearable'> {
  actions?: IViewSelectorAction[];
  editFieldText?: string;
  editMode?: boolean;
  label: string;
  customOption?: React.FC<ISelectItem>;
  deployDateComp?: React.FC<string | Date>;
  onEditFieldChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ViewSelector: React.FC<IViewSelectorProps> = ({
  actions = [],
  editFieldText = '',
  editMode = false,
  label,
  onEditFieldChange,
  customOption,
  deployDateComp,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div
      className={cc([classes.wrapper, { [classes.noMargin]: !actions.length }])}
    >
      {editMode && (
        <TextField
          className={classes.textField}
          defaultValue={editFieldText}
          label={label}
          name="name"
          onChange={onEditFieldChange}
        />
      )}
      {!editMode && (
        <Select
          disableClearable={true}
          label={label}
          customOption={customOption}
          StartAdornment={customOption}
          EndAdornment={deployDateComp}
          {...rest}
          variant="large"
        />
      )}

      <ProtectedComponent allow={{ policies: 'manage' }}>
        {actions.map((action, index) => (
          <Button key={index} variant="text" {...action} />
        ))}
      </ProtectedComponent>
    </div>
  );
};

export default ViewSelector;
