import { getActiveModalAtom } from 'atoms/modals';
import Button from 'components/UI/Button';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { AddClonePolicyZScan_TITLE } from './ZScanCreateClonePolicy';
import { openSnackBar } from 'utils/storeUtils';
import { clonePolicy, createPolicy } from 'api/zDevService';
import AddIcon from '@material-ui/icons/Add';
import CloneIcon from '@material-ui/icons/FileCopy';
import {
  IZScanPolicyCreateCloneForm,
  IZScanPolicyCreateCloneModalData,
} from './ZScanCreateClonePolicy/models';
import { zScanPolicyListAtom } from './atoms';

const activeModalAtom = getActiveModalAtom<IZScanPolicyCreateCloneModalData>();

interface IAddClonePolicyButtonProps {
  defaultName?: string;
  cloningPolicy?: boolean;
  policyId?: string | unknown;
}

export default function AddClonePolicyButton({
  defaultName,
  cloningPolicy = false,
  policyId,
}: IAddClonePolicyButtonProps) {
  const setActiveModal = useSetRecoilState(activeModalAtom);
  const [zScanPolicyList, setzScanPolicyList] =
    useRecoilState(zScanPolicyListAtom);

  const handleSubmit = async ({
    name,
    teamId,
    bounds,
  }: IZScanPolicyCreateCloneForm) => {
    const payload = {
      name,
      teamId: bounds !== 'global' ? teamId : undefined,
      bounds,
    };
    if (cloningPolicy) {
      clonePolicy({ policyId }, { ...payload })
        .then((data: any) => {
          let accountBounded = false;
          if (!teamId) {
            accountBounded = true;
          }
          setzScanPolicyList(
            zScanPolicyList.concat({
              label: name,
              value: data.data.id,
              accountBounded,
            })
          );
          openSnackBar(`Successfuly Cloned`);
        })
        .catch((e: any) => openSnackBar(e.errorMessage));
    } else {
      createPolicy({}, { ...payload })
        .then((data: any) => {
          let accountBounded = false;
          if (!teamId) {
            accountBounded = true;
          }
          setzScanPolicyList(
            zScanPolicyList.concat({
              label: name,
              value: data.data.id,
              accountBounded,
            })
          );
          openSnackBar(`Successfuly Created`);
        })
        .catch((e: any) => openSnackBar(e.errorMessage));
    }
    setActiveModal(undefined);
  };

  return (
    <Button
      text={cloningPolicy ? 'Clone' : 'Add New zScan Policy'}
      color="secondary"
      icon={cloningPolicy ? CloneIcon : AddIcon}
      onClick={() => {
        return setActiveModal({
          active: AddClonePolicyZScan_TITLE,
          payload: {
            cloningPolicy,
            defaultName,
            onSubmit: handleSubmit,
          },
        });
      }}
    />
  );
}
