import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    abstractProportion: {
      alignItems: "flex-start",
      color: palette.text.primary,
      display: "flex",
      flex: 1,
      flexDirection: "column",
      fontSize: 14,
      justifyContent: "center",
      marginBottom: 15,
      position: "relative",

      '& > span': {
        margin: "10px 0 15px",
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',

        "& :last-child": {
          margin: "10px 0 0",
        },

        '& > a:link, & > a:active, > a:visited': {
          color: 'inherit',
        },
  
        '& > a:hover': {
          textDecoration: 'underline',
        },
      },
    },
  })
);

export default useStyles;
