import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    card: {
      borderRadius: '7px',
      flex: 1,
      margin: 0,
      minHeight: 250,
      minWidth: 300,
      padding: '20px',
      position: 'relative',

      '& > div': {
        borderRadius: '7px',
        height: '100%',

        '& > h2': { margin: 0, padding: 0 },
      },
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',

      '& > a': {
        color: palette.primary.main,
        display: 'block',
        marginTop: 40,
        textAlign: 'center',
      },

      '& > span': { fontSize: 12, marginTop: 40, textAlign: 'center' },
    },
    dashboard: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      gap: 20,
      height: '100%',
      paddingTop: 20,
      minWidth: 1333,
    },
    pending: {
      fontSize: 48,
      left: '50%',
      opacity: 0.125,
      position: 'absolute',
      top: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
    },
    row: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: 20,
      maxWidth: 1600,
    },
    zDefendInsights: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 1333,
      maxWidth: 1600,

      '> div:first-child': {
        flexGrow: 0,
      }
    }
  })
);

export default useStyles;
