const EVENTROOT = 'zdev_policies:';

// Policy List
export const POLICY_LIST_REQUEST_UPDATE = `${EVENTROOT}list-request-update`;
export const POLICY_LIST_UPDATED = `${EVENTROOT}list-updated`;

// Individual Policy
export const POLICY_DELETED = `${EVENTROOT}successful-delete-policy`;
export const POLICY_RECEIVED = `${EVENTROOT}policy-received`;
export const POLICY_REQUEST_SAVE = `${EVENTROOT}request-save-policy`;
export const POLICY_SAVED = `${EVENTROOT}policy-saved`;
export const POLICY_SELECTED = `${EVENTROOT}policy-selected`;
export const POLICY_TOGGLE_EDIT = `${EVENTROOT}toggle-edit`;
export const POLICY_UPDATE = `${EVENTROOT}update-policy`;
export const POLICY_EDIT_CANCELED = `${EVENTROOT}cancel-edit-policy`;
export const FINDING_UPDATE = `${EVENTROOT}update-finding`;
