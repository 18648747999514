import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

export default state => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      threats: {
        ...state.uiSettings.threats,
        tableHeaders: uiSettingsDefaults.threats.tableHeaders,
      },
    },
  };
};
