import { fetchAppPolicyCriteria } from 'api/AppPolicyService';
import { ILocationQuery } from 'components/UI/Table/models';
import { IOOCSelect, IOOCSelectResponse, ITableQuery } from './models';
import memoizeTableQuery from 'components/UI/Table/utils/memoizeTableQuery';

const memoizeQuery = (tableQeury: ITableQuery) => {
  return memoizeTableQuery(async (query: ILocationQuery) => {
    const result: IOOCSelect[] =
      (
        (await fetchAppPolicyCriteria({
          ...query,
          id: tableQeury.policyId,
          ruleType: tableQeury.ruleType,
        })) as IOOCSelectResponse
      )?.data ?? [];

    const tableData: IOOCSelect[] = [];
    result.forEach((item: IOOCSelect, index: number) => {
      if (!tableQeury.selectItems?.some((i: IOOCSelect) => i.name === item.name && i.package === item.package && i.version === item.version)) {
        tableData.push({
          ...item,
          id: (index + 10).toString(),
        });
      }
    });

    return {
      data: tableData,
      count: tableData.length,
    };
  });
};

export default memoizeQuery;
