import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    background: {
      strokeWidth: 2,
      stroke: palette.text.primary,
    },
    doughnut: {
      flex: 0,
    },
    legend: {
      flex: 1,
    },
    section: {
      display: "flex",
      position: "relative",

      '&:first-child': {
        flex: "1",
      },
    },
    summary: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)',

      '& > h1, & > h2': {
        margin: 0,
        color: palette.common.white,
        fontSize: 42,
        textAlign: 'center',
      },

      '& > h2': {
        color: palette.text.primary,
        fontSize: 18,
        fontWeight: 'normal',
      },
    },
  })
);

export default useStyles;
