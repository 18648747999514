module.exports = {
  0: 'GLOBAL.DEVICE',
  1: 'GLOBAL.NETWORK',
  2: 'MTD.POLICIES.PRIVACY_POLICY.CARRIER_INFORMATION',
  3: 'MTD.POLICIES.PRIVACY_POLICY.APP_THREAT_FORENSICS',
  4: 'User Details',
  5: 'MTD.POLICIES.PRIVACY_POLICY.RUNNING_PROCESSES',
  6: 'MTD.POLICIES.PRIVACY_POLICY.ATTACKER_NETWORK',
  7: 'MTD.POLICIES.PRIVACY_POLICY.BROWSER_HISTORY',
  8: 'MTD.POLICIES.PRIVACY_POLICY.ATTACKING_FILE',
  9: 'MTD.POLICIES.PRIVACY_POLICY.ATTACKING_SMS',
  10: 'MTD.POLICIES.PRIVACY_POLICY.APP_BINARIES',
  11: 'MTD.POLICIES.PRIVACY_POLICY.APP_INVENTORY',
  12: 'MTD.POLICIES.PRIVACY_POLICY.OPERATING_SYSTEM',
  13: 'GLOBAL.MODEL',
  14: 'MTD.POLICIES.NETWORK_POLICY.IP_ADDRESS',
  15: 'MTD.POLICIES.PRIVACY_POLICY.MAC_ADDRESS',
  16: 'SSID',
  17: 'BSSID',
  18: 'MTD.POLICIES.PRIVACY_POLICY.DEVICE_IP_ADDRESS',
  19: 'MTD.POLICIES.PRIVACY_POLICY.GATEWAY_IP',
  20: 'MTD.POLICIES.PRIVACY_POLICY.GATEWAY_MAC',
  21: 'MTD.POLICIES.PRIVACY_POLICY.NEARBY_WIFI_NETWORKS',
  22: 'MTD.POLICIES.PRIVACY_POLICY.ARP_TABLE',
  23: 'MTD.POLICIES.PRIVACY_POLICY.ROUTING_TABLE',
  24: 'MTD.POLICIES.PRIVACY_POLICY.ATTACKER_IP_MAC',
  25: 'MTD.POLICIES.PRIVACY_POLICY.ATTACKER_MAC',
  26: 'MTD.POLICIES.PRIVACY_POLICY.RISKY_UNAPPROVED_SITES',
  27: 'IMEI',
  28: 'MTD.POLICIES.PRIVACY_POLICY.EMM_DEVICE_OWNER_NAME',
  29: 'MTD.POLICIES.PRIVACY_POLICY.EMM_DEVICE_OWNER_EMAIL',
  30: 'MTD.POLICIES.PRIVACY_POLICY.DEVICE_OWNER_PHONE_NUMBER',
  31: 'MTD.POLICIES.PRIVACY_POLICY.EMM_APP_INVENTORY_ANDROID',
  32: 'MTD.POLICIES.PRIVACY_POLICY.EMM_APP_INVENTORY_IOS',
  33: 'MTD.POLICIES.PRIVACY_POLICY.EMM_PROFILE_INVENTORY',
  34: 'MTD.POLICIES.PRIVACY_POLICY.BROWSER_EXTENSIONS_INVENTORY',
  35: 'MTD.POLICIES.PRIVACY_POLICY.BROWSER_EXTENSIONS_FORENSICS',
};
