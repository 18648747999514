/* eslint-disable max-lines-per-function */
import { headerActions as headerActionsAtom } from 'atoms/header';
import { getActiveModalAtom } from 'atoms/modals';
import { deployDateAtom } from 'atoms/policies';
import { selectedTeam as selectedTeamAtom } from 'atoms/teams';
import { GenericPromptModal_TITLE } from 'components/UI/Modals/GenericPrompt';
import { IGenericPromptModalData } from 'components/UI/Modals/GenericPrompt/models';
import ViewSelector from 'components/UI/ViewSelector';
import AddIcon from 'components/UI/icons/AddIcon';
import AlertIcon from 'components/UI/icons/AlertIcon';
import CopyIcon from 'components/UI/icons/CopyIcon';
import DeleteIcon from 'components/UI/icons/DeleteIcon';
import EditIcon from 'components/UI/icons/EditIcon';
import { ISelectItem } from 'components/UI/input/Select';
import GlobalIndicatorWrapper from 'components/main/common/GlobalIndicatorWrapper';
import _ from 'lodash';
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import asyncSleep from 'utils/asyncSleep';
import { reduceForMultiSelectWithGlobal } from 'utils/componentUtils';
import { getUnknownError } from 'utils/errorUtils';
import { openSnackBar } from 'utils/storeUtils';
import AddClonePolicy, {
  AddClonePolicy_TITLE,
} from '../appPolicy/AddClonePolicy';
import { IAppPolicyCreateModalData } from '../appPolicy/AddClonePolicy/models';
import { TPolicy, TpolicyMap } from '../appPolicy/models';
import ConfirmDeleteMessage, {
  IGroupBase,
} from '../common/ConfirmDeleteMessage';
import CustomPolicyOption from '../common/CustomPolicyOption';
import LastDeployedBox from '../common/LastDeployedBox';
import useStyles from './useStyles';

const activeModalAtom = getActiveModalAtom<
  IGenericPromptModalData | IAppPolicyCreateModalData
>();

interface IPolicyBase {
  id: string;
  name: string;
  modified?: Date;
  groups: IGroupBase[];
}

interface IPolicySelectorProps<TCurrent extends IPolicyBase, TRequest, TBody> {
  query?: string;
  policyType: TPolicy;
  canManage?: boolean;
  canDeploy: () => boolean;
  getRequestObject: (obj?: TCurrent) => TRequest | undefined;
  fetchPolicies: (
    team?: string | null,
    mtdModule?: boolean
  ) => Promise<ISelectItem[]>;
  fetchPolicyDetails: (id?: string | number | null) => Promise<TCurrent>;
  updatePolicy: (id: string, policy: TBody | TRequest) => Promise<unknown>;
  deletePolicy: (id: string) => Promise<unknown>;
  selectedPolicy?: ISelectItem;
  setSelectedPolicy: Dispatch<SetStateAction<ISelectItem | undefined>>;
  currentPolicy: TCurrent;
  setCurrentPolicy: Dispatch<SetStateAction<TCurrent>>;
  policyDetails: TCurrent;
  setPolicyDetails: Dispatch<SetStateAction<TCurrent>>;
  getRequestBody?: (obj: TRequest) => TBody;
  mtdModule?: boolean;
}

const PolicySelector = <TCurrent extends IPolicyBase, TRequest, TBody>(
  props: IPolicySelectorProps<TCurrent, TRequest, TBody>
): ReactElement => {
  const {
    query,
    policyType,
    canManage,
    canDeploy,
    getRequestObject,
    fetchPolicies,
    fetchPolicyDetails,
    updatePolicy,
    deletePolicy,
    selectedPolicy,
    setSelectedPolicy,
    currentPolicy,
    setCurrentPolicy,
    policyDetails,
    setPolicyDetails,
    getRequestBody,
    mtdModule,
  } = props;

  const classes = useStyles();

  const { t, ready } = useTranslation();

  const setHeaderActions = useSetRecoilState(headerActionsAtom);
  const setActiveModal = useSetRecoilState(activeModalAtom);
  const setDeployDate = useSetRecoilState(deployDateAtom);
  const selectedTeam = useRecoilValue(selectedTeamAtom);

  const preSelectedPolicy = useRef<string | undefined>(query);

  const [hasChanges, setHasChanges] = useState(false);
  const [policies, setPolicies] = useState<ISelectItem[]>([]);

  const navigationPrompt = useMemo(
    () =>
      ({
        message = (
          <div className={classes.navigationWarning}>
            {t('MTD.POLICIES.APP_POLICY.POLICY_LEAVE_PAGE_CONFIRMATION', {
              policyName: TpolicyMap[policyType],
            })}
            <AlertIcon />
          </div>
        ),
        onCancelCaption = t('MTD.POLICIES.APP_POLICY.LOSE_CHANGES'),
        onConfirmCaption = t('MTD.POLICIES.APP_POLICY.STAY_HERE'),
      }: Partial<IGenericPromptModalData> = {}): Partial<
        Omit<IGenericPromptModalData, 'onCancel' | 'onConfirm'>
      > => ({
        title: t('MTD.POLICIES.APP_POLICY.PENDING_POLICY_UPDATE'),
        message: <span>{message}</span>,
        onCancelCaption,
        onConfirmCaption,
      }),
    [classes, policyType, t]
  );

  const requestPolicies = useCallback(
    async (team?: string | null, mtdModule?: boolean): Promise<void> => {
      const data = await fetchPolicies(team, mtdModule);
      const policiesWithGlobal = reduceForMultiSelectWithGlobal(data);
      setPolicies(policiesWithGlobal);

      if (data.length <= 0) {
        setDeployDate('');
      }
    },
    [fetchPolicies, setDeployDate]
  );

  const deployChanges = useCallback(async (): Promise<void> => {
    try {
      const requestObject = getRequestObject(currentPolicy);
      let requestBody: TBody | TRequest | undefined = requestObject;

      if (getRequestBody && requestObject) {
        requestBody = getRequestBody(requestObject);
      }

      if (requestBody) {
        await updatePolicy(currentPolicy.id, requestBody);

        setActiveModal({
          active: GenericPromptModal_TITLE,
          payload: {
            title: t('MTD.POLICIES.APP_POLICY.DEPLOYED_POLICY'),
            message: (
              <div className={classes.policyDeployed}>
                <div>
                  <EditIcon />
                </div>
                {t('MTD.POLICIES.APP_POLICY.YOUR_CHANGES_SAVED_THIS_POLICY')}
              </div>
            ),
            onCancelHidden: true,
            onConfirmCaption: t('GLOBAL.OK'),
            onCancel: () => {},
            onConfirm: () => {
              setActiveModal(undefined);
              setHasChanges(false);
              setPolicyDetails(_.cloneDeep(currentPolicy));
            },
          },
        });
      }
    } catch (error: unknown) {
      openSnackBar(getUnknownError(error));
    }
  }, [
    currentPolicy,
    getRequestBody,
    setActiveModal,
    classes.policyDeployed,
    getRequestObject,
    setPolicyDetails,
    t,
    updatePolicy,
  ]);

  useEffect(() => {
    requestPolicies(selectedTeam, mtdModule).catch(console.error);
  }, [selectedTeam, mtdModule, requestPolicies]);

  useEffect(() => {
    if (policies.length > 0) {
      if (preSelectedPolicy.current) {
        let index = policies.findIndex(
          (p) => p.label === preSelectedPolicy.current
        );

        if (index >= 0) {
          setSelectedPolicy(policies[index]);
          preSelectedPolicy.current = undefined;
        } else {
          index = policies.findIndex(
            (p) => p.value === preSelectedPolicy.current
          );

          if (index >= 0) {
            setSelectedPolicy(policies[index]);
            preSelectedPolicy.current = undefined;
          }
        }
      } else {
        setSelectedPolicy(policies[0]);
      }
    }
  }, [policies, preSelectedPolicy, setSelectedPolicy]);

  useEffect(() => {
    const requestDetails = async (): Promise<void> => {
      if (selectedPolicy) {
        const details = await fetchPolicyDetails(selectedPolicy.value);
        setPolicyDetails(details);
      }
    };

    requestDetails().catch(console.error);
  }, [selectedPolicy, fetchPolicyDetails, setPolicyDetails]);

  useEffect(() => {
    if (
      policyDetails &&
      selectedPolicy &&
      policyDetails.id === selectedPolicy.value
    ) {
      setCurrentPolicy(_.cloneDeep(policyDetails));
      setDeployDate(policyDetails.modified || '');
    }
  }, [policyDetails, selectedPolicy, setCurrentPolicy, setDeployDate]);

  useEffect(() => {
    if (
      selectedPolicy &&
      policyDetails.id === currentPolicy.id &&
      selectedPolicy.value === currentPolicy.id
    ) {
      const isEqual = _.isEqual(
        getRequestObject(policyDetails),
        getRequestObject(currentPolicy)
      );

      setHasChanges(!isEqual);
    }
  }, [
    currentPolicy,
    policyDetails,
    selectedPolicy,
    canDeploy,
    getRequestObject,
  ]);

  useEffect(() => {
    if (hasChanges) {
      setHeaderActions({
        clearNavigationPreventingChanges: () => {
          setHasChanges(false);
        },
        preventNavigation: true,
        navigationPrompt: navigationPrompt(),
        buttons: [
          {
            className: classes.green,
            color: 'inherit',
            disabled: !canDeploy(),
            onClick: () => deployChanges(),
            text: t('MTD.POLICIES.APP_POLICY.DEPLOY_POLICY_CHANGES'),
          },
        ],
      });
    } else {
      setHeaderActions(undefined);
    }
  }, [
    hasChanges,
    classes.green,
    canDeploy,
    deployChanges,
    navigationPrompt,
    setHeaderActions,
    t,
  ]);

  const onSelectPolicyChange = useCallback(
    (name: string, value: ISelectItem | undefined) => {
      const changeSelectedPolicy = () => {
        setSelectedPolicy(value);
      };

      if (hasChanges) {
        setActiveModal({
          active: GenericPromptModal_TITLE,
          payload: {
            ...navigationPrompt(),
            onCancel: () => {
              changeSelectedPolicy();
              setActiveModal(undefined);
            },
            onConfirm: () => {
              setActiveModal(undefined);
            },
          },
        });
      } else {
        changeSelectedPolicy();
      }
    },
    [hasChanges, navigationPrompt, setActiveModal, setSelectedPolicy]
  );

  const onAddClonePolicyClick = useCallback(
    (cloningPolicy?: string) => {
      const addClonePolicy = () => {
        setActiveModal({
          active: AddClonePolicy_TITLE,
          payload: {
            cloningPolicy,
            policyType,
            defaultName: cloningPolicy
              ? selectedPolicy?.label + ` (${t('GLOBAL.CLONE')})`
              : '',
            onCreateSuccess: async (newPolicyName) => {
              preSelectedPolicy.current = newPolicyName;
              await asyncSleep(800);
              await requestPolicies(selectedTeam);
            },
          },
        });
      };

      if (hasChanges) {
        setActiveModal({
          active: GenericPromptModal_TITLE,
          payload: {
            ...navigationPrompt(),
            onCancel: () => {
              addClonePolicy();
            },
            onConfirm: () => {
              setActiveModal(undefined);
            },
          },
        });
      } else {
        addClonePolicy();
      }
    },
    [
      hasChanges,
      selectedTeam,
      selectedPolicy,
      policyType,
      navigationPrompt,
      setActiveModal,
      requestPolicies,
      t,
    ]
  );

  const onEditPolicyNameClick = useCallback(() => {
    const editPolicyName = () => {
      setActiveModal({
        active: GenericPromptModal_TITLE,
        payload: {
          disableOnSubmit: true,
          title: t('MTD.POLICIES.APP_POLICY.EDIT_POLICY_NAME'),
          question: {
            label: t('MTD.POLICIES.APP_POLICY.POLICY_NAME'),
            defaultAnswer: selectedPolicy?.label,
          },
          onCancel: () => {
            setActiveModal(undefined);
          },
          onConfirm: async (newName) => {
            const newPolicy = {
              ...currentPolicy,
              name: newName || '',
            };
            const requestObject = getRequestObject(newPolicy);

            let requestBody: TRequest | TBody | undefined = requestObject;

            if (getRequestBody && requestObject) {
              requestBody = getRequestBody(requestObject);
            }

            if (requestBody) {
              await updatePolicy(currentPolicy.id, requestBody);

              preSelectedPolicy.current = newName;
              await asyncSleep(800);
              await requestPolicies(selectedTeam);

              openSnackBar(t('MTD.POLICIES.APP_POLICY.POLICY_NAME_UPDATED'));
            }

            setActiveModal(undefined);
          },
        },
      });
    };

    if (hasChanges) {
      setActiveModal({
        active: GenericPromptModal_TITLE,
        payload: {
          ...navigationPrompt({
            onCancelCaption: t('GLOBAL.CONTINUE'),
            onConfirmCaption: t('GLOBAL.CANCEL'),
          }),
          onCancel: () => {
            editPolicyName();
          },
          onConfirm: () => {
            setActiveModal(undefined);
          },
        },
      });
    } else {
      editPolicyName();
    }
  }, [
    hasChanges,
    selectedPolicy,
    currentPolicy,
    selectedTeam,
    getRequestBody,
    navigationPrompt,
    setActiveModal,
    getRequestObject,
    requestPolicies,
    t,
    updatePolicy,
  ]);

  const onDeletePolicyClick = useCallback(
    (policyId: string) => {
      const deletePolicyFn = () => {
        setActiveModal({
          active: GenericPromptModal_TITLE,
          payload: {
            title: t('MTD.POLICIES.APP_POLICY.DELETE_POLICY'),
            message: (
              <ConfirmDeleteMessage
                groups={currentPolicy.groups}
                policyType={policyType}
              />
            ),
            onConfirmDisabled: currentPolicy.groups.length > 0,
            onCancel: () => {
              setActiveModal(undefined);
            },
            onConfirm: async () => {
              await deletePolicy(policyId);
              await asyncSleep(800);
              await requestPolicies(selectedTeam);
              openSnackBar(t('MTD.POLICIES.APP_POLICY.DELETED_POLICY'));
              setActiveModal(undefined);
            },
          },
        });
      };

      if (hasChanges) {
        setActiveModal({
          active: GenericPromptModal_TITLE,
          payload: {
            ...navigationPrompt(),
            onCancel: () => {
              deletePolicyFn();
            },
            onConfirm: () => {
              setActiveModal(undefined);
            },
          },
        });
      } else {
        deletePolicyFn();
      }
    },
    [
      currentPolicy,
      policyType,
      hasChanges,
      selectedTeam,
      deletePolicy,
      navigationPrompt,
      requestPolicies,
      setActiveModal,
      t,
    ]
  );

  if (!ready) {
    return <></>;
  }

  return (
    <>
      <div>
        <GlobalIndicatorWrapper isGlobal inline />
        <ViewSelector
          key={`selector-${selectedPolicy?.label}`}
          interactable
          value={selectedPolicy}
          label={t('MTD.POLICIES.APP_POLICY.SELECT_POLICY')}
          options={policies}
          placeholder={t('MTD.POLICIES.APP_POLICY.NO_POLICY_SELECTED')}
          setFieldValue={onSelectPolicyChange}
          customOption={CustomPolicyOption as React.FC}
          deployDateComp={LastDeployedBox as React.FC}
          actions={[
            {
              disabled:
                (canManage !== undefined && canManage === true) ||
                !selectedPolicy?.value,
              icon: EditIcon,
              onClick: onEditPolicyNameClick,
            },
            {
              disabled:
                (canManage !== undefined && canManage === true) ||
                !selectedPolicy?.value,
              icon: CopyIcon,
              onClick: () =>
                onAddClonePolicyClick(selectedPolicy?.value as string),
            },
            {
              disabled:
                (canManage !== undefined && canManage === true) ||
                !selectedPolicy?.value,
              icon: DeleteIcon,
              onClick: () =>
                onDeletePolicyClick(selectedPolicy?.value as string),
            },
            {
              disabled: canManage !== undefined && canManage === true,
              icon: AddIcon,
              onClick: () => onAddClonePolicyClick(),
            },
          ]}
        />
      </div>

      <AddClonePolicy />
    </>
  );
};

export default PolicySelector;
