import React, { PureComponent } from 'react';

// services
import { deleteApiKey } from 'api/AuthorizationService';

// components
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ZButton from 'UI/Buttons/ZButton';

// utils
import {
  toggleModalDirect,
  openSnackBar,
  toggleModalDiffered,
} from 'utils/storeUtils';

import { publishEvent } from 'utils/eventUtils';
import { deletePartnerApiKey } from 'api/PartnerService';

class AuthorizationsDeleteConfirm extends PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const { props } = this;
    return (
      <DialogContent>
        <p>Are you sure you want to delete {props.data.description} ?</p>
        <DialogActions>
          <ZButton
            styleName="modalCancel"
            action={toggleModalDiffered('AuthorizationsDeleteConfirm', false)}
            color="secondary"
            buttonText="Cancel"
          />
          <ZButton
            action={this.handleSubmit}
            buttonType="submit"
            color="primary"
            styleName="modalSave"
            buttonText="Delete Key"
          />
        </DialogActions>
      </DialogContent>
    );
  }

  handleSubmit() {
    const { props } = this;
    if (props.data.partnerUi) {
      return deletePartnerApiKey({ partnerApiKeyId: props.data.id })
        .then(() => {
          toggleModalDirect('AuthorizationsDeleteConfirm', false);
          openSnackBar('Api Access Key successfully deleted');
          publishEvent('table:force-fetch-partnerAuthorizations');
        })
        .catch(error => {
          toggleModalDirect('AuthorizationsDeleteConfirm', false);
          openSnackBar(`Failed to delete user: ${error.response.data}`);
        });
    }
    deleteApiKey({ apiKey: props.data.id })
      .then(() => {
        toggleModalDirect('AuthorizationsDeleteConfirm', false);
        openSnackBar('Api Access Key successfully deleted');
        publishEvent('table:force-fetch-authorization');
      })
      .catch(error => {
        toggleModalDirect('AuthorizationsDeleteConfirm', false);
        openSnackBar(`Failed to delete user: ${error.response.data}`);
      });
  }
}

AuthorizationsDeleteConfirm.defaultProps = {
  data: {},
};

export default AuthorizationsDeleteConfirm;
