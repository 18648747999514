export const defaultGroupsTableHeaders = [
  {
    id: 'name',
    numeric: false,
    label: 'Group Name',
    sort: true,
    show: true,
  },
  {
    id: 'description',
    numeric: false,
    label: 'Description',
    sort: true,
    show: false,
  },
  {
    id: 'privacyName',
    numeric: false,
    label: 'Privacy Policy',
    sort: false,
    show: true,
  },
  {
    id: 'trmName',
    numeric: false,
    label: 'Threat Policy',
    sort: false,
    show: true,
  },
  {
    id: 'phishingName',
    numeric: false,
    label: 'Phishing Policy',
    sort: false,
    show: true,
    isProtected: true,
    scope: 'phishing'
  },
  {
    id: 'appSettingsName',
    numeric: false,
    label: 'App Settings',
    sort: false,
    show: true,
    isProtected: true,
    scope: 'app_settings'
  },
  {
    id: 'appPolicyName',
    numeric: false,
    label: 'App Policy',
    sort: false,
    show: true,
  },
  {
    id: 'osRiskPolicyName',
    numeric: false,
    label: 'OS Risk Policy',
    sort: false,
    show: true,
  },
  {
    id: 'created',
    numeric: false,
    label: 'Created',
    sort: true,
    show: false,
  },
  {
    id: 'modified',
    numeric: false,
    label: 'Modified',
    sort: true,
    show: false,
  },

  {
    id: 'actions',
    numeric: false,
    label: 'Actions',
    sort: false,
    show: true,
    isProtected: true,
    scope: 'policies',
  },
];

export const groupsServiceMapping = [
  {
    path: 'page',
  },
  {
    path: 'size',
  },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      if (orderBy && order) {
        return `${orderBy},${order}`;
      }

      return null;
    },
  },
  {
    path: 'teamId',
  },
  {
    path: 'module',
  },
];

export const groupsEmmConnectionServiceMapping = [
  {
    path: 'page',
  },
  {
    path: 'size',
  },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      if (orderBy && order) {
        return `${orderBy},${order}`;
      }

      return null;
    },
  },
  {
    path: 'emmConnectionId',
    transform: ({ emmConnectionId }) => {
      if (!emmConnectionId) return null;
      return emmConnectionId.toString();
    },
  },
];

export const filterTeamPolicies = (policies = []) => {
  return policies.filter(policy => {
    return policy.accountBounded;
  });
};
