import React from 'react';

// material-ui
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

// components
import ZButton from 'UI/Buttons/ZButton';

// helpers
import { toggleModalDiffered } from 'utils/storeUtils';

const NearlyThere = () => (
  <DialogContent>
    <p>
      zDefend is waiting for your <b>Account Administrator </b>
      to set your unique privileges. Once that is set, you are on your way to
      protecting your apps.
    </p>
    <DialogActions>
      <ZButton
        styleName="modalSave"
        action={toggleModalDiffered('NearlyThere', false)}
        color="primary"
        buttonText="Got it!"
      />
    </DialogActions>
  </DialogContent>
);

export default NearlyThere;
