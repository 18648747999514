import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { useStyles } from './useStyles';
interface CircleIconProps {
  data?: number | string;
  borderColor?: string;
}

const CircleIcon: React.FC<CircleIconProps> = ({ data, borderColor }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.circleIcon}
      style={{ borderColor: `${borderColor}` }}
    >
      <Avatar className={classes.avtarStyle}>{data}</Avatar>
    </div>
  );
};

export default CircleIcon;
