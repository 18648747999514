import _ from 'lodash';
import React, { useMemo, useState, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import withRouter from 'components/hocs/withRouter';

// components
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import GlobalTeamSelector from 'components/main/common/GlobalTeamSelector';

// material ui
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MuiPopover from '@material-ui/core/Popover';

// icons
import InvertColors from '@material-ui/icons/InvertColors';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SettingsIcon from '@material-ui/icons/Build';
import UserIcon from '@material-ui/icons/AccountCircle';
import LogoutIcon from '@material-ui/icons/FirstPage';

import { toggleTheme } from 'reducers/UiSettingsReducers';
import { CONSOLE_ZDEV_ROUTES } from './zdev/zdev.routes';
import { CONSOLE_ZIAP_ROUTES } from './ziap/ziap.routes';
import { CONSOLE_MANAGE_ROUTES } from './manage/manage.routes';
import { CONSOLE_MTD_ROUTES } from './mtd/mtd.routes';
import { CONSOLE_ZSHIELD_ROUTES } from './zshield/zshield.routes';
import { useTranslation } from 'react-i18next';

const ConsoleSidebar = ({ location, ...props }) => {
  const $userMenuRef = useRef(null);
  const moduleContext = getModuleContext(location.pathname);
  const routes = useMemo(
    () => getContextualRoutes(moduleContext),
    [moduleContext]
  );
  const { t, ready } = useTranslation();
  const { firstName, lastName } = useSelector(({ user }) => user);
  const { scopes } = useSelector(({ auth }) => auth.user);
  const [popover, setPopover] = useState({ open: false });

  const hasDocsLink = props.branding?.documentationLink?.length;
  const hasTermsLink = props.branding?.termsAndConditionsLink?.length;
  const hasPrivacyPolicyLink = props.branding?.privacyPolicyLink?.length;

  function togglePopOver() {
    setPopover({
      open: !popover.open,
      anchorEl: $userMenuRef.current,
    });
  }

  if (!ready) {
    return null;
  }

  if (_.isEmpty(scopes)) {
    return (
      <div className={props.classes.sidebarContainer}>
        <div className={props.classes.mainNav}>
          <nav>
            <ListItem button component={NavLink} to="/logout">
              <ListItemIcon style={{ marginRight: 0 }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText disableTypography secondary="Logout" />
            </ListItem>
          </nav>
        </div>
      </div>
    );
  }

  return (
    <div className={props.classes.sidebarContainer}>
      <div className={props.classes.mainNav}>
        <nav>
          <GlobalTeamSelector />
          {routes.map(({ nav, ...route }, index) => {
            let to = nav.target || route.path;
            if (nav.name === 'Devices') {
              to = durationRouteHelper(to, props.devicesUiSettings);
            }
            if (nav.name === 'Threats') {
              to = durationRouteHelper(to, props.threatsUiSettings);
            }
            return (
              <ProtectedComponent key={`main-nav-${index}`} allow={route.allow}>
                <ListItem
                  button
                  selected={itemIsActive(to, location)}
                  component={NavLink}
                  to={to}
                >
                  <ListItemIcon
                    className={props.classes.itemIcon}
                    id={nav.name}
                  >
                    {nav.icon}
                  </ListItemIcon>
                  <ListItemText
                    className={props.classes.itemText}
                    disableTypography
                    secondary={t(nav.name)}
                  />
                </ListItem>
              </ProtectedComponent>
            );
          })}
        </nav>
      </div>
      <ProtectedComponent allow="any">
        <div className={props.classes.mainFooterNav}>
          {moduleContext === 'zdefend' && (
            <ListItem
              component="a"
              href={
                hasDocsLink
                  ? props.branding?.documentationLink
                  : `${window.location.origin}/ziap-docs/zdefend-docs`
              }
              target="_blank"
            >
              <ListItemText
                className={props.classes.itemText}
                disableTypography
                secondary={t('GLOBAL.DOCS')}
              />
            </ListItem>
          )}
          {moduleContext === 'zscan' && (
            <ListItem
              component="a"
              href={
                hasDocsLink
                  ? props.branding?.documentationLink
                  : `${window.location.origin}/ziap-docs/zscan-docs`
              }
              target="_blank"
            >
              <ListItemText
                className={props.classes.itemText}
                disableTypography
                secondary={t('GLOBAL.DOCS')}
              />
            </ListItem>
          )}
          {moduleContext === 'mtd' && (
            <ListItem
              component="a"
              href={
                hasDocsLink
                  ? props.branding?.documentationLink
                  : `${window.location.origin}/ziap-docs/zips-docs`
              }
              target="_blank"
            >
              <ListItemText
                className={props.classes.itemText}
                disableTypography
                secondary={t('GLOBAL.DOCS')}
              />
            </ListItem>
          )}
          {moduleContext === 'zshield' && (
            <ListItem
              component="a"
              href={
                hasDocsLink
                  ? props.branding?.documentationLink
                  : `${window.location.origin}/ziap-docs/zshield-docs`
              }
              target="_blank"
            >
              <ListItemText
                className={props.classes.itemText}
                disableTypography
                secondary={t('GLOBAL.DOCS')}
              />
            </ListItem>
          )}

          <ListItem
            component="a"
            href={
              hasPrivacyPolicyLink
                ? props.branding?.privacyPolicyLink
                : 'https://www.zimperium.com/zimperium-eula/'
            }
            target="_blank"
          >
            <ListItemText
              className={props.classes.itemText}
              disableTypography
              secondary={t('GLOBAL.POLICY')}
            />
          </ListItem>
          <ListItem
            component="a"
            href={
              hasTermsLink
                ? props.branding?.termsAndConditionsLink
                : 'https://www.zimperium.com/zimperium-eula/'
            }
            target="_blank"
          >
            <ListItemText
              className={props.classes.itemText}
              disableTypography
              secondary={t('GLOBAL.TERMS')}
            />
          </ListItem>
          <div
            ref={$userMenuRef}
            className={props.classes.userActionsBox}
            onClick={togglePopOver}
          >
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <UserIcon
                className={props.classes.iconStyle}
                style={{ marginRight: 5 }}
              />
              {firstName} {lastName[0]}.
              {popover.open ? (
                <KeyboardArrowRight className={props.classes.expandIcon} />
              ) : (
                <KeyboardArrowDown className={props.classes.expandIcon} />
              )}
            </span>
          </div>
        </div>
      </ProtectedComponent>

      <MuiPopover
        {...popover}
        position="right"
        onClose={togglePopOver}
        anchorOrigin={{
          vertical: 'left',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <ListItem button component={NavLink} to="/console/user-settings">
          <ListItemIcon style={{ marginRight: 0 }}>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            secondary={t('GLOBAL.USER_SETTINGS')}
          />
        </ListItem>
        <ListItem button onClick={props.toggleTheme}>
          <ListItemIcon style={{ marginRight: 0 }}>
            <InvertColors />
          </ListItemIcon>
          <ListItemText
            disableTypography
            secondary={t('GLOBAL.TOGGLE_THEME')}
          />
        </ListItem>
        <ListItem button component={NavLink} to="/logout">
          <ListItemIcon style={{ marginRight: 0 }}>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText disableTypography secondary={t('GLOBAL.LOGOUT')} />
        </ListItem>
      </MuiPopover>
    </div>
  );
};

function getContextualRoutes(moduleContext) {
  switch (moduleContext) {
    case 'zscan':
      return sortRoutes(CONSOLE_ZDEV_ROUTES);
    case 'zdefend':
      return sortRoutes(CONSOLE_ZIAP_ROUTES);
    case 'mtd':
      return sortRoutes(CONSOLE_MTD_ROUTES);
    case 'manage':
      return sortRoutes(CONSOLE_MANAGE_ROUTES);
    case 'zshield':
      return sortRoutes(CONSOLE_ZSHIELD_ROUTES);
    default:
      return [];
  }
}

function getModuleContext(pathname) {
  const parts = pathname.split('/');
  return parts[2];
}

function sortRoutes(routes) {
  return routes
    .filter((route) => route.nav)
    .sort((routeA, routeB) => routeA.nav.order - routeB.nav.order);
}

function itemIsActive(to, location) {
  // matching exact top-level routes
  if (location.pathname === _.first(to.split('?'))) {
    return true;
  }

  // matching subroutes
  const locParts = location.pathname.split('/').slice(0, 4).join('/');
  const toParts = to.split('/').slice(0, 4).join('/');

  if (locParts === _.first(toParts.split('?'))) {
    return true;
  }

  return false;
}

function durationRouteHelper(to, uiSettings) {
  if (uiSettings.duration) {
    return `${to}/?duration=${uiSettings.duration}`;
  }
  if (uiSettings.to) {
    return `${to}/?to=${uiSettings.to}&from=${uiSettings.from}`;
  }
  return `${to}/?duration=5`;
}

const styles = ({ config, palette, sizing }) => ({
  sidebarContainer: {
    width: sizing.sidebar.maxWidth,
    minHeight: '100%',
    height: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'fixed',
    background: palette.components.sidebar.background,
    paddingTop: 5,
    // allows popup to display over the the main content
    zIndex: 99,
  },

  itemIcon: {
    marginRight: 0,
    color: palette.text.primary,
  },

  itemText: {
    fontSize: config.textSizes.normal,
    fontWeight: 400,
  },

  mainFooterNav: {
    position: 'fixed',
    bottom: 0,
    width: sizing.sidebar.maxWidth,
    zIndex: '200',
    flex: 1,
  },

  userActionsBox: {
    padding: 16,
    position: 'relative',
    background: palette.background.default,
    fontSize: 14,

    '&:hover': {
      cursor: 'pointer',
    },

    '& button': {
      fontSize: 14,
    },
  },

  paddedContainer: {
    padding: 16,
    fontSize: 14,
  },

  buttonIcon: {
    padding: 10,
  },

  iconStyle: {
    fontSize: config.textSizes.paragon,
    color: palette.components.globalHeader.contrastText,
  },

  expandIcon: {
    position: 'absolute',
    right: 8,
  },
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ toggleTheme }, dispatch);

const mapStateToProps = (state) => {
  return {
    branding: state.auth.branding,
    threatsUiSettings: state.uiSettings.threats,
    devicesUiSettings: state.uiSettings.devices,
  };
};

ConsoleSidebar.defaultProps = {
  threatsUiSettings: { duration: 5 },
  devicesUiSettings: { duration: 5 },
};

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ConsoleSidebar);
/* new docs
 {moduleContext === 'zdefend' && (
            <ListItem
              component="a"
              href={`${window.location.origin}/ziap-docs/zdefend-docs`}
              target="_blank"
            >
              <ListItemText
                className={props.classes.itemText}
                disableTypography
                secondary="Docs"
              />
            </ListItem>
          )}
          {moduleContext === 'zscan' && (
            <ListItem
              component="a"
              href={`${window.location.origin}/ziap-docs/zscan-docs`}
              target="_blank"
            >
              <ListItemText
                className={props.classes.itemText}
                disableTypography
                secondary="Docs"
              />
            </ListItem>
          )}
*/
