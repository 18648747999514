import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from 'actions/UserActions';
import PublicComponentWrapper from 'components/wrappers/PublicComponentWrapper';

class Logout extends Component {
  componentDidMount() {
    this.props.logout();
  }

  render() {
    return (
      <PublicComponentWrapper>
        <h2>Goodbye!</h2>
        <div>
          <h3>
            Have an account? <Link to="/login">Sign in</Link>
          </h3>
        </div>
      </PublicComponentWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      dispatch(logout());
    },
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(Logout);
