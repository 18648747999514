import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { requestAllTeams } from 'api/apis';

// ui
import { Formik, Form, Field } from 'formik';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

// components
import ZButton from 'UI/Buttons/ZButton';
import {
  toggleModalDirect,
  toggleModalDiffered,
  openSnackBar,
} from 'utils/storeUtils';
import { FormikTextField } from '../inputs/FormikTextField';
import { FormikMultiSelect } from '../inputs/FormikMultiSelect';

// utils
import withRouter from '../hocs/withRouter';
import { unzipReference, zipReference } from '../../utils/componentUtils';
import {
  createConfiguration,
  updateConfiguration,
} from '../../dashboard/zDev/Integrations/TicketingConfigurations/configurations.service';
import {
  getIntegrationProjects,
  getIntegrations,
} from '../../dashboard/zDev/Integrations/TicketingIntegrations/integrations.service';
import { reduceForMultiSelect } from 'utils/componentUtils';
import { useTranslation } from 'react-i18next';
import { publishEvent } from 'utils/eventUtils';

const initialValues = {
  integrationId: [],
  project: [],
  issueType: [],
  team: [],
};

const ConfigurationCreateEdit = (props) => {
  const { t, ready } = useTranslation();
  // const integrationOptions = unzipReference(props.data.integrations);
  const {
    config,
    projectOptions,
    issueTypeOptions,
    setConfig,
    loading,
    teamOptions,
    integrations,
    setIssueTypes,
    projects,
  } = useGetDependencies();

  const editMode = !_.isEmpty(props.data);
  let editModeData = initialValues;

  if (editMode) {
    editModeData = {
      ..._.pick(props.data, ['name']),
      integrationId: [
        {
          label: props?.data?.integration?.name,
          value: props?.data?.integration?.id,
        },
      ],
      project: [
        {
          label: props?.data?.projectName,
          value: props?.data?.projectId,
        },
      ],
      issueType: [
        {
          label: props?.data?.issueType,
          value: props?.data?.issueTypeId,
        },
      ],
      team: [
        {
          label: props?.data?.teamName,
          value: props?.data?.teamId,
        },
      ],
    };
  }

  const onSubmit = (values, actions) => {
    const response = editMode
      ? updateConfiguration({ configurationId: props.data.id }, values)
      : createConfiguration({}, values);

    response
      .then(() => {
        toggleModalDirect('ConfigurationCreateEdit', false);
        openSnackBar(
          t('ZSCAN.CONFIGURATIONS.CREATE_EDIT_CONFIGURATION_SUCCESS', {
            configurationName: values.name,
            actionPerformed: t(
              (editMode && 'GLOBAL.UPDATED') || 'GLOBAL.CREATED'
            ),
          })
        );
        publishEvent('table:force-fetch-configurations');
      })
      .catch(({ response }) => {
        if (response && response.status === 409) {
          openSnackBar(
            t('ZSCAN.CONFIGURATIONS.CREATE_CONFIGURATION_FAIL_SAME_CONFIG', {
              configurationName: values.name,
            })
          );
        } else {
          openSnackBar(
            t('ZSCAN.CONFIGURATIONS.FAILED_TO_CREATE_INTEGRATION', {
              configurationName: values.name,
              errorBody: response?.data?.message ?? '',
            })
          );
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  if (!ready) {
    return null;
  }

  return (
    <DialogContent>
      <Formik
        initialValues={editMode ? editModeData : initialValues}
        onSubmit={onSubmit}
        validationSchema={ConfigurationSchema}
      >
        {({ isSubmitting, isValid, dirty }) => {
          return (
            <Form>
              <Field
                name="name"
                label={t('ZSCAN.CONFIGURATIONS.CONFIGURATION_NAME')}
                component={FormikTextField}
              />

              <Field
                name="integrationId"
                label={t('ZSCAN.CONFIGURATIONS.PARENT_INTEGRATION')}
                component={FormikMultiSelect}
                isMulti={false}
                loading={loading}
                onChange={(name, values) => {
                  setConfig((currentConfig) => ({
                    ...currentConfig,
                    [name]: [values],
                  }));
                }}
                options={integrations}
              />

              <Field
                name="project"
                label={t('ZSCAN.CONFIGURATIONS.PROJECT')}
                component={FormikMultiSelect}
                isMulti={false}
                loading={loading}
                disabled={!projectOptions.length}
                options={projectOptions}
                onChange={(name, values) => {
                  setConfig((currentConfig) => ({
                    ...currentConfig,
                    [name]: [values],
                  }));
                  setIssueTypes(
                    projects[values.value].issueTypes.map(({ id, name }) => ({
                      label: name,
                      value: id,
                    }))
                  );
                }}
              />

              <Field
                name="issueType"
                label={t('ZSCAN.CONFIGURATIONS.ISSUE_TYPE')}
                component={FormikMultiSelect}
                isMulti={false}
                loading={loading}
                disabled={!issueTypeOptions.length}
                options={issueTypeOptions}
                onChange={(name, values) => {
                  setConfig((currentConfig) => ({
                    ...currentConfig,
                    [name]: [values],
                  }));
                }}
              />

              <Field
                name="team"
                label={t('ZSCAN.CONFIGURATIONS.SELECT_TEAM')}
                component={FormikMultiSelect}
                isMulti={false}
                loading={loading}
                disabled={!teamOptions.length}
                options={teamOptions}
                onChange={(name, values) => {
                  setConfig((currentConfig) => ({
                    ...currentConfig,
                    [name]: [values],
                  }));
                }}
              />

              <DialogActions>
                <ZButton
                  action={toggleModalDiffered('ConfigurationCreate', false)}
                  buttonText={t('GLOBAL.CANCEL')}
                  color="secondary"
                  isDisabled={isSubmitting}
                  styleName="submit"
                />

                <ZButton
                  buttonType="submit"
                  buttonText={t('ZSCAN.CONFIGURATIONS.SAVE_CONFIGURATION')}
                  color="primary"
                  isDisabled={
                    !isValid ||
                    isSubmitting ||
                    !dirty ||
                    (!editMode &&
                      (!config.project.length ||
                        !config.issueType.length ||
                        !config.team.length))
                  }
                  proLabel
                  styleName="modalSave"
                />
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </DialogContent>
  );
};

ConfigurationCreateEdit.defaultProps = {
  integrations: {},
};

const SelectOptionSchema = Yup.object().shape({
  label: Yup.string().required(),
  value: Yup.string().required(),
});

const ConfigurationSchema = Yup.object().shape({
  name: Yup.string().min(1).max(120).required(),
  integrationId: Yup.array().of(SelectOptionSchema).min(1).required(),
  project: Yup.array().of(SelectOptionSchema).max(1).required(),
  issueType: Yup.array().of(SelectOptionSchema).max(1).required(),
  team: Yup.array().of(SelectOptionSchema).max(1).required(),
});

function useGetDependencies() {
  const [config, setConfig] = useState(initialValues);
  const [projects, setProjects] = useState([]);
  const [issueTypeOptions, setIssueTypes] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [integrations, setIntegrations] = useState([]);
  const [loading, setLoading] = useState(false);

  if (config.project.length && !issueTypeOptions.length) {
    setIssueTypes(
      projects[config.project[0].value].issueTypes.map(({ id, name }) => ({
        label: name,
        value: id,
      }))
    );
  }

  useEffect(() => {
    if (config.integrationId.length) {
      setLoading(true);

      getIntegrationProjects({ integrationId: config.integrationId[0].value })
        .then((resp) => {
          setLoading(false);
          setProjects(zipReference(resp.data, 'name', 'id', ['issueTypes']));
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }

    requestAllTeams()
      .then((resp) => {
        setTeamOptions(reduceForMultiSelect(resp.data));
      })
      .catch((error) => console.log(error));

    getIntegrations()
      .then((resp) => {
        setIntegrations(reduceForMultiSelect(resp.data));
      })
      .catch((error) => console.log(error));
  }, [config]);

  return {
    config,
    setConfig,
    projects,
    projectOptions: unzipReference(projects),
    issueTypeOptions,
    loading,
    teamOptions,
    integrations,
    setIssueTypes,
  };
}

export default withRouter(ConfigurationCreateEdit);
