import _ from 'lodash';
import React from 'react';

// ui
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ZButton from 'UI/Buttons/ZButton';

// services
import {
  toggleModalDirect,
  openSnackBar,
  toggleModalDiffered,
} from 'utils/storeUtils';
import { publishEvent } from 'utils/eventUtils';
import { disablePartnerAccess } from 'api/PartnerService';

export const PartnerUserDeleteConfirm = ({ data }) => {
  const userId = _.get(data, 'rowData.id');
  function handleDelete() {
    disablePartnerAccess({ userId })
      .then(() => {
        openSnackBar(`Successfully disabled ${_.get(data, 'rowData.email')}`);
        // because this is a shared modal with super ui and partner ui
        // - check which table to refresh
        const event = data.partnerUI
          ? 'table:force-fetch-partnerUsersForPartnerUI'
          : 'table:force-fetch-partnerUsers';
        publishEvent(event);
        toggleModalDirect('PartnerUserDeleteConfirm', false);
      })
      .catch(error => {
        openSnackBar(
          `Failed to delete ${_.get(data, 'rowData.email')}. ${_.get(
            error,
            'response.data',
            ''
          )}`
        );
      });
  }
  return (
    <DialogContent>
      <p>
        Are you sure you want to disable partner access to{' '}
        {_.get(data, 'rowData.email')}?
      </p>
      <DialogActions>
        <ZButton
          styleName="modalCancel"
          action={toggleModalDiffered('PartnerUserDeleteConfirm', false)}
          color="secondary"
          buttonText="Cancel"
        />
        <ZButton
          action={handleDelete}
          buttonType="submit"
          color="primary"
          styleName="modalSave"
          buttonText="Delete"
        />
      </DialogActions>
    </DialogContent>
  );
};

PartnerUserDeleteConfirm.defaultProps = {
  data: {},
};

export default PartnerUserDeleteConfirm;
