import React from 'react';
import { withStyles } from '@material-ui/core/styles';

// mui
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

// components
import TextField from './TextField';

class PasswordField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showText: false, value: '' };
    this.toggleShowPassword = this.toggleShowPassword.bind(this);
  }

  render() {
    const { props, state } = this;
    const { classes, ...rest } = props;

    return (
      <TextField
        {...rest}
        type={state.showText ? 'text' : 'password'}
        value={rest.value || state.value}
        endAdornment={
          <InputAdornment
            classes={{ root: classes.adornmentRoot }}
            position="end"
          >
            <IconButton onClick={this.toggleShowPassword} tabIndex="-1">
              {state.showText ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
    );
  }

  toggleShowPassword() {
    this.setState({ showText: !this.state.showText });
  }
}

const styles = () => {
  return {
    adornmentRoot: {
      height: 'inherit',
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translateY(-50%)',
    },
  };
};

export default withStyles(styles)(PasswordField);
