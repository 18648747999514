import { fetchRolesTablePage } from 'api/RolesService';
import { memoizeTableQuery } from 'components/RDGTables/utils';
import _ from 'lodash';

export default memoizeTableQuery(async query => {
  // Fetch Data
  const rawRolesTableData = await fetchRolesTablePage(query);

  return {
    data: _.get(rawRolesTableData, 'data.content', []).map(
      ({ scopeBounds, ...rest }) => ({
        bounds: scopeBounds === 'ACCOUNT_BOUNDED' ? 'Account' : 'Team',
        ...rest,
      })
    ),
    count: _.get(rawRolesTableData, 'data.totalElements', 0),
  };
});
