import { IOOCSelect } from 'components/main/oocSelect/models';
import {
  IAppPolicyRule,
  IAppPolicyRuleTableItem,
  TAppPolicyRuleAction,
  TAppPolicyRuleActionKey,
  TAppPolicyRuleCriteria,
  TAppPolicyRuleCriteriaKey,
} from './models';

export const keyToActionType = (
  key: TAppPolicyRuleActionKey
): TAppPolicyRuleAction => {
  const actionTypes: { [key: string]: TAppPolicyRuleAction } = {
    allow: 'Mark as Safe',
    deny: 'Mark as Suspicious',
    ooc: 'Out of Compliance (OOC)',
  };

  return actionTypes[key];
};

export const keyToCriteriaType = (
  key: TAppPolicyRuleCriteriaKey
): TAppPolicyRuleCriteria => {
  const criteriaTypes: { [key: string]: TAppPolicyRuleCriteria } = {
    developer_package_version: 'Developer Signature + Package ID + Version',
    developer_package: 'Developer Signature + Package ID',
    developer: 'Developer Signature',
    package_version: 'Package ID + Version',
    package: 'Package ID',
  };

  return criteriaTypes[key];
};

export const filterAndSortAppPolicyRules = (
  rules: IAppPolicyRule[],
  modifiedRules: { [key: string]: IOOCSelect[] },
  {
    showAndroid,
    showIOS,
    hideEmptyRules,
  }: { showAndroid: boolean; showIOS: boolean; hideEmptyRules: boolean } = {
    showAndroid: true,
    showIOS: true,
    hideEmptyRules: false,
  }
): IAppPolicyRuleTableItem[] =>
  rules
    ?.sort(({ order: a }, { order: b }) => (a > b ? 1 : -1))
    .map(({ name, criteria, ...rest }) => ({
      name,
      criteria: modifiedRules?.[name] ?? criteria,
      ...rest,
    }))
    .map(({ id, name, criteria }) => {
      const nameParts: string[] = name.split('_');

      return {
        id: id as unknown as string,
        name,
        platform: nameParts?.[0] ?? '',
        action: keyToActionType(
          (nameParts?.[1] ?? 'allow') as TAppPolicyRuleActionKey
        ),
        criteria: keyToCriteriaType(
          (nameParts?.slice(2)?.join('_') ??
            'package') as TAppPolicyRuleCriteriaKey
        ),
        numRows: (criteria ?? []).length,
      } as IAppPolicyRuleTableItem;
    })
    .filter(({ numRows }) => !hideEmptyRules || (hideEmptyRules && numRows > 0))
    .filter(
      ({ platform }) =>
        !['ios', 'android'].includes(platform) ||
        (platform === 'ios' && showIOS) ||
        (platform === 'android' && showAndroid)
    );
