import { DialogContent } from '@material-ui/core';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import Button from 'components/UI/Button';
import GenericErrorBox from 'components/UI/GenericErrorBox';
import TextField from 'components/UI/input/TextField';
import { Field as FormikField, Form as FormikForm, Formik } from 'formik';
import React from 'react';
import { toggleModalDiffered } from 'utils/storeUtils';
import { initialValues } from './initialValues';
import { IDescData, IFormSubmit } from './models';
import Schema from './schema';
import useStyles from './useStyles';

interface IFormProps {
  data: Partial<IDescData>;
  errorMessage: string;
  handleSubmit: (values: IFormSubmit) => void;
}

const Form: React.FC<IFormProps> = ({ data, errorMessage, handleSubmit }) => {
  const classes = useStyles();
  const resolvedInitialValues = initialValues(data);

  return (
    <Formik
      enableReinitialize
      initialValues={resolvedInitialValues}
      onSubmit={handleSubmit}
      validateOnBlur
      validationSchema={Schema()}
    >
      {() => {
        return (
          <FormikForm>
            <DialogContent>
              <FormikField
                autoComplete="off"
                component={TextField}
                label="Description"
                name="description"
              />
              {!!errorMessage && (
                <div className={classes.errorMessage}>
                  <GenericErrorBox errorMessage={errorMessage} />
                </div>
              )}
              <div className={classes.buttonPanel}>
                <Button
                  onClick={toggleModalDiffered('ZShieldEditDesc', false)}
                  color="secondary"
                  text="Cancel"
                />
                <ProtectedComponent allow={{ app_protections: 'manage' }}>
                  <Button type="submit" color="primary" text="Save" />
                </ProtectedComponent>
              </div>
            </DialogContent>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default Form;
