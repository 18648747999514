import { TFunction } from 'react-i18next';

import * as Yup from 'yup';

const Schema = (t: TFunction<'translation', undefined>) => {
  const fields = {
    project: Yup.string().required(
      t('ZSCAN.VALIDATIONS.PLEASE_SELECT_A_PROJECT')
    ),
    issueType: Yup.string().required(
      t('ZSCAN.VALIDATIONS.PLEASE_SELECT_AN_ISSUE_TYPE')
    ),
  };

  return Yup.object().shape({ ...fields });
};

export default Schema;
