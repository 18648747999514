import {
  Android as MUIAndroidIcon,
  LockOpenOutlined as MUILockIcon,
  Publish as MUIPublishIcon,
} from '@material-ui/icons';
import {
  fetchOSDevicesStatus,
  fetchOSRiskActiveExploits,
} from 'api/OSRiskService';
import AppleIcon from 'components/UI/icons/AppleIcon';
import { IDeviceOSStatus, IDeviceVulnerable, IStatusItem } from './models';
import { TFunction } from 'react-i18next';

export const ANDROID_STATUS_MAPPINGS: IStatusItem[] = [
  {
    id: 'totalAndroidDeviceCount', // TODO: Correct this, as needed
    label: 'Total Android Devices',
    linkUrl: '/console/mtd/devices/?os.name=Android&duration=9',
    icon: MUIAndroidIcon,
    borderColor: 'green',
  },
  {
    id: 'androidVulnerableDeviceCount',
    label: 'Devices w/ Vulnerable OS',
    linkUrl: '/console/mtd/devices/?os.name=Android&vulnerable=true&duration=9',
    icon: MUILockIcon,
    borderColor: 'red',
  },
  {
    id: 'androidUpgradeableCount',
    label: 'Upgradeable Devices',
    linkUrl:
      '/console/mtd/devices/?os.name=Android&upgradeable=true&duration=9',
    icon: MUIPublishIcon,
    borderColor: 'darkGreen',
  },
  {
    id: 'androidNotUpgradeableCount',
    label: 'Non-Upgradeable Devices',
    linkUrl:
      '/console/mtd/devices/?os.name=Android&upgradeable=false&duration=9',
    icon: MUIPublishIcon,
    borderColor: 'orange',
    mutedIconColor: true,
  },
];

export const IOS_STATUS_MAPPINGS: IStatusItem[] = [
  {
    id: 'totalIosDeviceCount', // TODO: Correct this, as needed
    label: 'Total iOS Devices',
    linkUrl: '/console/mtd/devices/?os.name=iOS&duration=9',
    icon: AppleIcon,
    borderColor: 'blue',
  },
  {
    id: 'iosVulnerableDeviceCount',
    label: 'Devices w/ Vulnerable OS',
    linkUrl: '/console/mtd/devices/?os.name=iOS&vulnerable=true&duration=9',
    icon: MUILockIcon,
    borderColor: 'red',
  },
  {
    id: 'iosUpgradeableCount',
    label: 'Upgradeable Devices',
    linkUrl: '/console/mtd/devices/?os.name=iOS&upgradeable=true&duration=9',
    icon: MUIPublishIcon,
    borderColor: 'darkGreen',
  },
  {
    id: 'iosNotUpgradeableCount',
    label: 'Non-Upgradeable Devices',
    linkUrl: '/console/mtd/devices/?os.name=iOS&upgradeable=false&duration=9',
    icon: MUIPublishIcon,
    borderColor: 'orange',
    mutedIconColor: true,
  },
];

export const DEVICES_VULNERABLE_EXPLOITS = (
  t: TFunction<'translation', undefined>
): IStatusItem[] => {
  return [
    {
      id: 'upgradeableAndroid',
      label: t('MTD.OS_RISK.UPGRADEABLE_ANDROID'),
      linkUrl:
        '/console/mtd/devices/?os.name=Android&duration=9&upgradeable=true&vulnerable=true',
      icon: MUIAndroidIcon,
      borderColor: 'green',
    },
    {
      id: 'upgradeableIos',
      label: t('MTD.OS_RISK.UPGRADEABLE_IOS'),
      linkUrl:
        '/console/mtd/devices/?os.name=iOS&vulnerable=true&duration=9&upgradeable=true',
      icon: AppleIcon,
      borderColor: 'blue',
    },
    {
      id: 'nonUpgradeableAndroid',
      label: t('MTD.OS_RISK.NON_UPGRADEABLE_ANDROID'),
      linkUrl:
        '/console/mtd/devices/?os.name=Android&upgradeable=false&vulnerable=true&duration=9',
      icon: MUIAndroidIcon,
      borderColor: 'red',
    },
    {
      id: 'nonUpgradeableIos',
      label: t('MTD.OS_RISK.NON_UPGRADEABLE_IOS'),
      linkUrl:
        '/console/mtd/devices/?os.name=iOS&upgradeable=false&duration=9&vulnerable=true',
      icon: AppleIcon,
      borderColor: 'red',
    },
  ];
};

export const fetchDeviceStats = async (accountId: string, teamId: string) => {
  let deviceStats: IDeviceOSStatus = {};
  try {
    deviceStats =
      (await fetchOSDevicesStatus({ teamId, accountId }))?.data ?? {};
  } catch (e) {
    console.error(e);
  }
  return deviceStats;
};

export const fetchActiveExploits = async (
  accountId: string,
  teamId: string
) => {
  let vulnerableActiveExploits: IDeviceVulnerable = {};
  try {
    vulnerableActiveExploits =
      (await fetchOSRiskActiveExploits({ teamId, accountId }))?.data ?? {};
  } catch (e) {
    console.error(e);
  }
  return vulnerableActiveExploits;
};
