import LinkTabs from 'components/UI/LinkTabs';
import AppInventoryDashboardTab from '../DashboardTab';
import AppsInventoryTab from 'components/main/appsInventory/AppsInventoryTab/AppsInventory';

import useStyles from './useStyles';
import { useTranslation } from 'react-i18next';

interface IAppInventoryProps {
  match: { params: { tabId: string } };
}

const AppInventory: React.FC<IAppInventoryProps> = ({ match }) => {
  const classes = useStyles();
  const { ready, t } = useTranslation();

  if (!ready) {
    return null;
  }

  return (
    <div className={classes.tabWrapper}>
      <LinkTabs
        allowedScope="app_inventory"
        tabs={[
          {
            allow: { app_inventory: 'view' },
            label: t('GLOBAL.DASHBOARD'),
            component: AppInventoryDashboardTab,
            to: '/console/mtd/apps-inventory/dashboard',
            value: 'dashboard',
          },
          {
            allow: { app_inventory: 'view' },
            label: t('MTD.APP_INVENTORY.APPS_INVENTORY'),
            component: AppsInventoryTab,
            to: '/console/mtd/apps-inventory/apps-inventory',
            value: 'apps-inventory',
          },
        ]}
        value={match.params.tabId}
      />
    </div>
  );
};

export default AppInventory;
