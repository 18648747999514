import { useTheme } from '@material-ui/core';
import cc from 'classcat';
import MultiBarChart from 'components/UI/Chart/MultiBar';
import GenericCard from 'components/UI/GenericCard';
import AndroidIcon from 'components/UI/icons/AndroidIcon';
import AppleIcon from 'components/UI/icons/AppleIcon';
import CircleIcon from 'components/UI/icons/CircleIcon';
import { useMemo } from 'react';
import useAsyncResult from 'utils/useAsyncResult';
import { IRiskiestOsVersions } from './models';
import useStyles from './useStyles';
import { fetchRiskiestOSVersions, transformRiskiestOsVersion } from './utils';
import { useTranslation } from 'react-i18next';

interface IRiskiestOsVersionsProps {
  accountId?: string | undefined;
  selectedTeamId?: string | null | undefined;
}

const RiskiestOsVersions: React.FC<IRiskiestOsVersionsProps> = ({
  accountId,
  selectedTeamId,
}) => {
  const classes = useStyles();
  const theme: {
    palette: {
      common: {
        avocado: string;
        paleTomato: string;
        schoolbus: string;
        strawberry: string;
      };
      charts: {
        purpleCritical: string;
      };
    };
  } = useTheme();

  const { i18n, t, ready } = useTranslation();

  const deviceStats = useAsyncResult<IRiskiestOsVersions>(
    fetchRiskiestOSVersions,
    accountId,
    selectedTeamId ?? ''
  );

  const iosVersions = useMemo(
    () => transformRiskiestOsVersion(deviceStats, 'ios'),
    [deviceStats]
  );

  const androidVersions = useMemo(
    () => transformRiskiestOsVersion(deviceStats, 'android'),
    [deviceStats]
  );

  const baseUrlforRedirect = '/console/mtd/os-risk/versions/?search=';

  const fill = useMemo(
    () => ({
      critical: theme.palette.charts.purpleCritical,
      high: theme.palette.common.strawberry,
      low: theme.palette.common.avocado,
      medium: theme.palette.common.schoolbus,
    }),
    [theme]
  );

  const hasData = useMemo(
    () => androidVersions.length || iosVersions.length,
    [androidVersions, iosVersions]
  );

  const hasIosData = useMemo(() => iosVersions.length > 0, [iosVersions]);
  const hasAndroidData = useMemo(
    () => androidVersions.length > 0,
    [androidVersions]
  );

  if (!ready) {
    return null;
  }

  return (
    <GenericCard
      className={classes.riskiestOsVersionsCard}
      classTitle={classes.title}
      title={t('MTD.OS_RISK.CVE_RISK_BY_OS_VERSION')}
      noPadding
    >
      {!hasData ? (
        <div className={classes.section}>
          <div
            className={cc([
              {
                [classes.pendingNonEnglish]:
                  i18n.language?.toLowerCase() !== 'en-us',
              },
              classes.pending,
            ])}
          >
            {t('GLOBAL.NOT_AVAILABLE')}
          </div>
        </div>
      ) : (
        <>
          <div className={classes.section}>
            <div className={classes.icon}>
              <AppleIcon classList={classes} />
            </div>
            {!hasIosData ? (
              <div
                className={cc([
                  {
                    [classes.pendingNonEnglish]:
                      i18n.language?.toLowerCase() !== 'en-us',
                  },
                  classes.pending,
                ])}
              >
                {t('GLOBAL.NOT_AVAILABLE')}
              </div>
            ) : (
              <MultiBarChart
                data={iosVersions}
                fill={fill}
                link={`${baseUrlforRedirect}os==2;`}
              />
            )}
          </div>
          <div className={classes.section}>
            <div className={classes.icon}>
              <AndroidIcon classList={classes} />
            </div>
            {!hasAndroidData ? (
              <div
                className={cc([
                  {
                    [classes.pendingNonEnglish]:
                      i18n.language?.toLowerCase() !== 'en-us',
                  },
                  classes.pending,
                ])}
              >
                {t('GLOBAL.NOT_AVAILABLE')}
              </div>
            ) : (
              <MultiBarChart
                data={androidVersions}
                fill={fill}
                link={`${baseUrlforRedirect}os==1;`}
              />
            )}
          </div>
          <div className={classes.legend}>
            <div>
              <CircleIcon
                className={classes.circle}
                size={12}
                color={theme.palette.charts.purpleCritical}
                error
                noExclamation
              />{' '}
              {t('GLOBAL.CRITICAL')}
            </div>
            <div>
              <CircleIcon
                className={classes.circle}
                size={12}
                error
                noExclamation
              />{' '}
              {t('GLOBAL.HIGH_RISK')}
            </div>
            <div>
              <CircleIcon className={classes.circle} size={12} warning />{' '}
              {t('GLOBAL.MEDIUM_RISK')}
            </div>
            <div>
              <CircleIcon className={classes.circle} size={12} success />{' '}
              {t('GLOBAL.LOW_RISK')}
            </div>
          </div>
        </>
      )}
    </GenericCard>
  );
};

export default RiskiestOsVersions;
