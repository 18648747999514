import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const styles = ({ palette, config }) => {
  return {
    wrapper: {
      background: palette.background.paper,
      outline: 'none',
      maxWidth: '60%',
    },
    tableCell: {
      borderBottomColor: 'transparent',
    },
    tableHeaderCell: {
      color: palette.text.secondary,
      fontWeight: 'bold',
      borderBottomColor: 'transparent',
    },
    tableRow: {
      height: config.textSizes.paragon,
    },
    emptyText: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '5vh',
      minWidth: '35vw',
    },
  };
};

class ARPTables extends Component {
  render() {
    const { classes, threat } = this.props;
    const noData = 'ARP Tables data not available';
    return (
      <div className={classes.wrapper}>
        {threat.arpTablesInfo && (
          <Table>
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.tableHeaderCell} />
                <TableCell className={classes.tableHeaderCell}>IP</TableCell>
                <TableCell className={classes.tableHeaderCell}>MAC</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {threat.arpTablesInfo.initial
                && threat.arpTablesInfo.initial.length > 0 && (
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableHeaderCell}>
                      {'ARP Table Initital'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {threat.arpTablesInfo.initial[0].ip}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {threat.arpTablesInfo.initial[0].mac}
                    </TableCell>
                  </TableRow>
              )}
              {threat.arpTablesInfo.initial
                && threat.arpTablesInfo.initial.length > 1
                && threat.arpTablesInfo.initial
                  .slice(1, threat.arpTablesInfo.initial.length)
                  .map((arpInitial, index) => {
                    return (
                      <TableRow key={index} className={classes.tableRow}>
                        <TableCell className={classes.tableCell} />
                        <TableCell className={classes.tableCell}>
                          {arpInitial.ip}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {arpInitial.mac}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              {threat.arpTablesInfo.before
                && threat.arpTablesInfo.before.length > 0 && (
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableHeaderCell}>
                      {'ARP Table Before'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {threat.arpTablesInfo.before[0].ip}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {threat.arpTablesInfo.before[0].mac}
                    </TableCell>
                  </TableRow>
              )}
              {threat.arpTablesInfo.before
                && threat.arpTablesInfo.before.length > 1
                && threat.arpTablesInfo.before
                  .slice(1, threat.arpTablesInfo.before.length)
                  .map((arpBefore, index) => {
                    return (
                      <TableRow key={index} className={classes.tableRow}>
                        <TableCell className={classes.tableCell} />
                        <TableCell className={classes.tableCell}>
                          {arpBefore.ip}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {arpBefore.mac}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              {threat.arpTablesInfo.after
                && threat.arpTablesInfo.after.length > 0 && (
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableHeaderCell}>
                      {'ARP Table After'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {threat.arpTablesInfo.after[0].ip}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {threat.arpTablesInfo.after[0].mac}
                    </TableCell>
                  </TableRow>
              )}
              {threat.arpTablesInfo.after
                && threat.arpTablesInfo.after.length > 1
                && threat.arpTablesInfo.after
                  .slice(1, threat.arpTablesInfo.after.length)
                  .map((arpAfter, index) => {
                    return (
                      <TableRow key={index} className={classes.tableRow}>
                        <TableCell className={classes.tableCell} />
                        <TableCell className={classes.tableCell}>
                          {arpAfter.ip}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {arpAfter.mac}
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        )}
        {!threat.arpTablesInfo && (
          <div className={classes.emptyText}>{noData}</div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ARPTables);
