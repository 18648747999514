import { checkSiteForPhishing, getAclById } from 'api/PhishingPolicyService';
import { getActiveModalAtom } from 'atoms/modals';
import EditableTable from 'components/UI/EditableTable';
import { ISelectOption } from 'components/UI/EditableTable/models';
import { GenericPromptModal_TITLE } from 'components/UI/Modals/GenericPrompt';
import { IGenericPromptModalData } from 'components/UI/Modals/GenericPrompt/models';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { openSnackBar } from 'utils/storeUtils';
import {
  aclDetailshasChanges as aclDetailsHasChangesAtom,
  phishingAclItemValue as aclListItemAtom,
  aclSelectedEntriesIds as aclSelectedEntriesIdsAtom,
  phishingAclControlListEntriesArr as phishingAclControlListEntriesArrAtom,
} from '../../atoms';
import {
  IACLListItem,
  IAclListTableDataItem,
  ICategories,
  ICategory,
  IControlListEntry,
  IPhishingSiteCheckResponse,
} from '../../models';
import { getSubCategoriesArray } from '../../utils';
import AclInUseModal from '../AclInUseModal';
import { headers, rowMappings } from './AclTableMappings';

interface IAclTableProps {
  categoriesFromApi: {
    categoryGroups: ICategories[];
  };
  policiesUsingAcl: { id: string; name: string }[];
  updateForAddAndDelete: (controlListEntries: IControlListEntry[]) => {};
}

const activeModalAtom = getActiveModalAtom<IGenericPromptModalData>();

const AclTable: React.FC<IAclTableProps> = ({
  categoriesFromApi,
  policiesUsingAcl,
  updateForAddAndDelete,
}) => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useRecoilState(
    aclSelectedEntriesIdsAtom
  );
  const persistSelectedAcl = useRecoilValue(aclListItemAtom);
  const [controlListEntriesArr, setControlListEntriesArr] = useRecoilState(
    phishingAclControlListEntriesArrAtom
  );
  const [testForChangesList, setTestForChangesList] = useState<
    IControlListEntry[]
  >([]);
  const [localChanges, setLocalChanges] = useState<boolean>(false);
  const setAclDetailsHasChanges = useSetRecoilState(aclDetailsHasChangesAtom);
  const setActiveModal = useSetRecoilState(activeModalAtom);
  const [aclChangesWarn, setAclChangesWarn] = useState<boolean>(false);
  const [tableDataArr, setTableDataArr] = useState<IAclListTableDataItem[]>([]);
  const selectedMappedCategoryForNewRow = useRef(1);

  const subCategoriesArray = useMemo(
    () => getSubCategoriesArray(categoriesFromApi?.categoryGroups),
    [categoriesFromApi]
  );

  const categForSelectOpt: ISelectOption[] = useMemo(
    () =>
      subCategoriesArray.map((el: ICategory) => ({
        label: el.name || '',
        value: el.name || '',
      })),
    [subCategoriesArray]
  );

  const getCategoryName = useCallback(
    (catNum: number) => {
      const catString = subCategoriesArray.find(
        (el) => el.categoryId === catNum
      );
      return catString?.name;
    },
    [subCategoriesArray]
  );

  const getCustomCategoryCode = useCallback(
    (catName: string): number => {
      const catCode = subCategoriesArray.find((el) => el.name === catName);
      return catCode?.categoryId || 1;
    },
    [subCategoriesArray]
  );

  const createTableData = useCallback(() => {
    const tableData: IAclListTableDataItem[] = [];

    controlListEntriesArr?.forEach((entry) =>
      tableData.push({
        select: false,
        id: entry.id ?? _.uniqueId(),
        url: entry.url,
        mappedCategory: getCategoryName(entry.mappedCategory) || '',
        originalCategories:
          getCategoryName(entry.originalCategories?.[0]) || '',
      } as IAclListTableDataItem)
    );
    setTableDataArr(tableData);
  }, [controlListEntriesArr, getCategoryName]);

  useEffect(() => {
    if (persistSelectedAcl) {
      getAclById({ id: persistSelectedAcl.id }).then(
        ({ data }: { data: IACLListItem }) => {
          if (data.controlListEntries) {
            const listWithId = data.controlListEntries.map((el) => ({
              ...el,
              id: _.uniqueId(),
            }));
            setControlListEntriesArr(listWithId);
            setTestForChangesList(listWithId);
            setAclChangesWarn(false);
          }
        }
      );
    } else {
      setTestForChangesList([]);
      setControlListEntriesArr([]);
      setTableDataArr([]);
    }
  }, [persistSelectedAcl, setControlListEntriesArr, setTableDataArr]);

  useEffect(() => {
    const changesBool =
      _.differenceWith(
        controlListEntriesArr,
        testForChangesList || [],
        _.isEqual
      ).length > 0 ||
      _.intersectionWith(
        controlListEntriesArr,
        testForChangesList || [],
        _.isEqual
      ).length !== testForChangesList?.length;
    setAclDetailsHasChanges(changesBool);
    setLocalChanges(changesBool);
  }, [controlListEntriesArr, testForChangesList, setAclDetailsHasChanges]);

  useEffect(() => {
    if (localChanges) {
      createTableData();
      setLocalChanges(false);
    }
  }, [createTableData, localChanges, setLocalChanges]);

  const toggleCheckTableRow = useCallback(
    (id: string) => {
      const itemToToggle = tableDataArr.find(
        (row: IAclListTableDataItem) => row.id === id
      );
      if (itemToToggle) {
        const itemIndex = tableDataArr.findIndex(
          (row: IAclListTableDataItem) => row.id === itemToToggle.id
        );
        const tempTable = tableDataArr.filter(
          (row: IAclListTableDataItem) => row.id !== itemToToggle.id
        );
        itemToToggle.select = !itemToToggle.select;
        tempTable.splice(itemIndex, 0, itemToToggle);
        setTableDataArr(tempTable);
      }
    },
    [tableDataArr]
  );

  const toggleAllRows = useCallback(
    (checked) => {
      setSelectedRows(
        checked ? tableDataArr.map((r: IAclListTableDataItem) => r.id) : []
      );
      const toggledTable = tableDataArr.map((row: IAclListTableDataItem) => {
        const newRow = { ...row, select: checked };
        return newRow;
      });
      setTableDataArr(toggledTable);
    },
    [tableDataArr, setSelectedRows]
  );

  const handleCreate = useCallback(
    async (rowData) => {
      const defaultCategory = [];
      try {
        const res = await checkSiteForPhishing({}, { url: rowData.url });
        const {
          data,
          status,
        }: { data: IPhishingSiteCheckResponse; status: number } = res;
        if (status === 200) {
          defaultCategory.push(data.zcat[0]);
        } else {
          defaultCategory.push(1);
          return 1;
        }
      } catch (e) {
        openSnackBar(t('MTD.POLICIES.PHISHING_POLICY.UNABLE_FIND_URL'));
        return;
      }

      const customCategory: number = selectedMappedCategoryForNewRow.current;
      const newItem: IControlListEntry = {
        id: _.uniqueId(),
        url: rowData.url,
        originalCategories: [...defaultCategory],
        mappedCategory: customCategory,
      };
      try {
        const res = await updateForAddAndDelete([
          ...controlListEntriesArr,
          newItem,
        ]);
        if (res) {
          setControlListEntriesArr((controlListEntriesArr) => [
            ...controlListEntriesArr,
            newItem,
          ]);
          selectedMappedCategoryForNewRow.current = 1;
        } else {
          openSnackBar(t('MTD.POLICIES.PHISHING_POLICY.URL_IN_LIST'));
        }
      } catch (error) {
        console.log(error);
        return;
      }
    },
    [setControlListEntriesArr, controlListEntriesArr, updateForAddAndDelete, t]
  );

  const handleDelete = useCallback(
    ({ id }: { id: string }) => {
      const filteredList = controlListEntriesArr.filter((el) => el.id !== id);
      setControlListEntriesArr(filteredList);
      updateForAddAndDelete(filteredList);
    },
    [controlListEntriesArr, setControlListEntriesArr, updateForAddAndDelete]
  );

  const warnChangeList = (
    handler: (rowdata: IAclListTableDataItem) => void,
    rowdata: IAclListTableDataItem
  ) => {
    setActiveModal(undefined);
    aclChangesWarn || policiesUsingAcl.length <= 1
      ? handler(rowdata)
      : setActiveModal({
          active: GenericPromptModal_TITLE,
          payload: {
            title: t('MTD.POLICIES.PHISHING_POLICY.LIST_IN_USE'),
            message: <AclInUseModal policiesUsingAcl={policiesUsingAcl} />,
            onConfirmCaption: t('GLOBAL.PROCEED'),
            onConfirm: () => {
              handler(rowdata);
              setAclChangesWarn(true);
              setActiveModal(undefined);
            },
            onCancel: () => {
              setAclChangesWarn(true);
              setActiveModal(undefined);
            },
          },
        });
  };
  const handleEditMappedCategory = useCallback(
    (id: string | undefined, value: string | number | null) => {
      let selectedMappedCategory = 1;
      if (id && value) {
        if (typeof value === 'string') {
          selectedMappedCategory = getCustomCategoryCode(value);
        } else if (typeof value === 'number') {
          selectedMappedCategory = getCustomCategoryCode(String(value));
        }
      }
      if (id === 'newRow') {
        selectedMappedCategoryForNewRow.current = selectedMappedCategory;
      }
      if (id !== 'newRow') {
        const newArray = controlListEntriesArr.map((item) => {
          if (item.id === id) {
            return { ...item, mappedCategory: selectedMappedCategory };
          } else {
            return item;
          }
        });

        try {
          updateForAddAndDelete(newArray);
          setControlListEntriesArr(newArray);
        } catch (error) {
          console.log('Cannot update custom category : ', error);
          return;
        }
      }
    },
    [
      controlListEntriesArr,
      getCustomCategoryCode,
      setControlListEntriesArr,
      updateForAddAndDelete,
    ]
  );

  const clearNewmappedCategory = () => {
    selectedMappedCategoryForNewRow.current = 1;
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <EditableTable
        columnHeaders={headers(t, toggleAllRows)}
        editVisible={false}
        onCreate={(rowData) =>
          warnChangeList(handleCreate, rowData as IAclListTableDataItem)
        }
        onDelete={(rowData) =>
          warnChangeList(handleDelete, rowData as IAclListTableDataItem)
        }
        onUpdate={(rowData) => rowData}
        rowMapping={rowMappings(
          categForSelectOpt,
          setSelectedRows,
          selectedRows,
          toggleCheckTableRow,
          handleEditMappedCategory
        )}
        showNoDataAddButton={true}
        tableData={tableDataArr}
        tableId="testTable"
        noDataAddButtonText="+"
        onCancelEdit={clearNewmappedCategory}
      />
    </div>
  );
};

export default AclTable;
