import cc from 'classcat';
import useStyles from './useStyles';

export interface ICircleIcon {
  className?: string;
  color?: string;
  error?: boolean;
  noExclamation?: boolean;
  size?: number;
  success?: boolean;
  unknown?: boolean;
  warning?: boolean;
}

const CircleIcon: React.FC<ICircleIcon> = ({
  className,
  color,
  error,
  noExclamation,
  size = 16,
  success,
  warning,
  unknown,
}) => {
  const classes = useStyles();

  return (
    <span
      className={cc([
        className,
        classes.icon,
        {
          [classes.error]: !!error,
          [classes.success]: !!success,
          [classes.warning]: !!warning,
          [classes.unknown]: !!unknown,
        },
      ])}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        ...(color ? { backgroundColor: color } : {}),
      }}
    >
      {!!error && !noExclamation && '!'}
    </span>
  );
};

export default CircleIcon;
