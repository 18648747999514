import { default as MUIFileMoreIcon } from '@material-ui/icons/MoreVert';
import { IFilterEditor, ITableRowMapping } from 'components/UI/Table/models';
import GenericTooltipList from 'components/UI/TooltipList';
import AndroidIcon from 'components/UI/icons/AndroidIcon';
import AppleIcon from 'components/UI/icons/AppleIcon';
import CircleIcon from 'components/UI/icons/CircleIcon';
import Moment from 'moment';
import { TFunction } from 'react-i18next';
import { trimAndAddAsterisk } from 'utility/QueryUtility';
import { PlatformMapping } from 'utils';
import ProtectedLink from '../../protected/ProtectedLink';
import { IAppVersionsRowMapping, IFilterValues } from './models';
import {
  CLASSIFICATION,
  HIGHEST_CVSS_TYPE,
  PLATFORMS,
  RISKS_TYPE,
} from './utils';
import { HighestCvssSeverity, RiskScoreCell } from './RiskScoreCell';

const downloadLinksData = (
  appVerId: string,
  t: TFunction<'translation', undefined>
) => {
  return [
    {
      to: `/api/devices/v1/appVersions/${appVerId}/pdf?isTechnical=false`,
      text: t('MTD.APP_INVENTORY.TABLE_VIEW.EXECUTIVE_PDF_REPORT'),
      fileName: 'Executive_Report.pdf',
    },
    {
      to: `/api/devices/v1/appVersions/${appVerId}/pdf?isTechnical=true`,
      text: t('MTD.APP_INVENTORY.TABLE_VIEW.TECHNICAL_PDF_REPORT'),
      fileName: 'Technical_Report.pdf',
    },
    {
      to: `/api/devices/v1/appVersions/${appVerId}/json`,
      text: t('GLOBAL.JSON_REPORT'),
      fileName: 'JSON_Report.json',
    },
  ];
};
export const severityByValues = (
  riskValues: string,
  t?: TFunction<'translation', undefined>,
  noText?: boolean
) => {
  if (
    typeof riskValues === 'undefined' ||
    riskValues === '' ||
    riskValues === 'unknown'
  ) {
    return <>{!noText && t?.('MTD.APP_INVENTORY.TABLE_VIEW.UNAVAILABLE')}</>;
  }
  return (
    <>
      {riskValues.toLocaleLowerCase() === 'critical' && <CircleIcon error />}
      {riskValues.toLocaleLowerCase() === 'high' && <CircleIcon error />}
      {riskValues.toLocaleLowerCase() === 'medium' && <CircleIcon warning />}
      {riskValues.toLocaleLowerCase() === 'low' && <CircleIcon success />}
      {!noText &&
        riskValues.toLocaleLowerCase() === 'critical' &&
        t?.('GLOBAL.CRITICAL')}
      {riskValues.toLocaleLowerCase() === 'critical' && <CircleIcon error />}
      {!noText &&
        riskValues.toLocaleLowerCase() === 'high' &&
        t?.('GLOBAL.HIGH')}
      {!noText &&
        riskValues.toLocaleLowerCase() === 'medium' &&
        t?.('GLOBAL.MEDIUM')}
      {!noText && riskValues.toLocaleLowerCase() === 'low' && t?.('GLOBAL.LOW')}
    </>
  );
};
export const riskAssessment = (
  risk: number | undefined,
  t?: TFunction<'translation', undefined>,
  noText?: boolean
) => {
  if (
    typeof risk === 'undefined' ||
    (typeof risk !== 'undefined' && (risk as number) < 0)
  ) {
    return (
      <>
        <CircleIcon unknown />
        {!noText && t?.('GLOBAL.UNKNOWN')}
      </>
    );
  }

  const riskNumber = risk as number;

  return (
    <>
      {riskNumber > 66 && <CircleIcon error />}
      {riskNumber > 33 && riskNumber <= 66 && <CircleIcon warning />}
      {riskNumber >= 0 && riskNumber <= 33 && <CircleIcon success />}
      {!noText && riskNumber > 66 && t?.('GLOBAL.HIGH')}
      {!noText && riskNumber > 33 && riskNumber <= 66 && t?.('GLOBAL.MEDIUM')}
      {!noText && riskNumber >= 0 && riskNumber <= 33 && t?.('GLOBAL.LOW')}
    </>
  );
};

export const defaultAppsInventoryTableHeaders = [
  {
    filterEditor: 'StringFilter',
    id: 'name',
    label: 'MTD.APP_INVENTORY.TABLE_VIEW.APPLICATION_NAME',
    show: true,
    path: 'name',
    sort: true,
    minWidth: 150,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    id: 'version',
    label: 'GLOBAL.VERSION',
    show: true,
    path: 'version',
    sort: true,
    minWidth: 75,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    filterEditor: 'SelectFilter',
    filterEditorProps: { multiple: true, wrapMultiple: false },
    id: 'platform',
    label: 'GLOBAL.PLATFORM',
    show: true,
    path: 'platform',
    sort: true,
    minWidth: 160,
    maxWidth: 180,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    id: 'bundleId',
    label: 'MTD.APP_INVENTORY.TABLE_VIEW.PACKAGE_BUNDLE',
    show: true,
    path: 'bundleId',
    sort: true,
    minWidth: 150,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    id: 'developerName',
    label: 'GLOBAL.DEVELOPER',
    show: true,
    path: 'developerName',
    sort: true,
    minWidth: 100,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    filterEditor: 'SelectFilter',
    filterEditorProps: { multiple: true, wrapMultiple: false },
    id: 'classification',
    label: 'GLOBAL.CLASSIFICATION',
    show: true,
    path: 'classification',
    sort: true,
    minWidth: 150,
    maxWidth: 150,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    filterEditor: 'SelectFilter',
    filterEditorProps: { multiple: true, wrapMultiple: false },
    id: 'securityRisk',
    label: 'MTD.APP_INVENTORY.DASHBOARD.SECURITY_RISK',
    show: true,
    path: 'securityRisk',
    sort: true,
    minWidth: 150,
    maxWidth: 150,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    id: 'privacyRisk',
    label: 'MTD.APP_INVENTORY.DASHBOARD.PRIVACY_RISK',
    filterEditor: 'SelectFilter',
    filterEditorProps: { multiple: true, wrapMultiple: false },
    show: true,
    path: 'privacyRisk',
    sort: true,
    minWidth: 150,
    maxWidth: 150,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    filterEditor: 'NumberFilter',
    filterEditorProps: { minValue: 0 },
    id: 'deviceCount',
    label: 'GLOBAL.DEVICES',
    show: true,
    path: 'deviceCount',
    sort: true,
    minWidth: 75,
    maxWidth: 100,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  // to do --- translate highest CVSS Severity
  {
    id: 'highestCvssSeverity',
    label: 'MTD.APP_INVENTORY.DASHBOARD.HIGHEST_CVSS_SEVERITY',
    filterEditor: 'SelectFilter',
    filterEditorProps: { multiple: true, wrapMultiple: false },
    show: true,
    path: 'highestCvssSeverity',
    sort: true,
    minWidth: 150,
    maxWidth: 260,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    id: 'created',
    label: 'GLOBAL.LAST_UPDATED',
    show: true,
    path: 'created',
    sort: true,
    minWidth: 150,
    maxWidth: 160,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
];

// This is currently here for reference -- will be re-enabled/modified
// when the backend is un-sandboxed for this view.
//
export const fetchAppsInventoryParamsMapping: ITableRowMapping[] = [
  {
    path: 'after',
    transform: ({ after, duration }) => {
      if (Number(duration) === 9) {
        return null;
      }

      return after ? after.toISOString() : null;
    },
  },
  {
    path: 'before',
    transform: ({ before, duration }) => {
      if (Number(duration) === 9) {
        return null;
      }

      return before ? before.toISOString() : new Date().toISOString();
    },
  },
  {
    path: 'from',
    paramKey: 'after',
    transform: ({ from, to }) => {
      if (!from) {
        return null;
      }

      return from === to
        ? Moment.unix(from).startOf('day').toISOString()
        : Moment.unix(from).toISOString();
    },
  },
  {
    path: 'to',
    paramKey: 'before',
    transform: ({ to, from }) => {
      if (!to) {
        return null;
      }

      return from === to
        ? Moment.unix(to).endOf('day').toISOString()
        : Moment.unix(to).toISOString();
    },
  },
  {
    path: 'page',
  },
  {
    path: 'size',
  },
  {
    path: 'search',
    transform: ({ search = '' }) => {
      if (!search) {
        return null;
      }

      return trimAndAddAsterisk(search);
    },
  },
  {
    path: 'sort',
    transform: ({ orderBy, order }) => {
      if (orderBy && order) {
        let sortSuffix = ![
          'created',
          'developerName',
          'deviceCount',
          'managed',
          'name',
          'privacyRisk',
          'securityRisk',
          'updated',
        ].includes(orderBy)
          ? '.keyword'
          : '';

        if (['developerName', 'name'].includes(orderBy)) {
          sortSuffix += '.sort';
        }
        if (['highestCvssSeverity'].includes(orderBy)) {
          return `highestCvssSeverityOrdinal,${order}`;
        }

        return `${orderBy}${sortSuffix},${order}`;
      }

      return null;
    },
  },
  {
    path: 'deviceCount',
  },
  {
    path: 'name',
  },
  {
    path: 'developerSignature.keyword',
  },
  {
    path: 'developerName.keyword',
  },
  {
    path: 'bundleId.keyword',
  },
  {
    path: 'version.keyword',
  },
  {
    path: 'highestCvssSeverity',
    paramKey: 'highestCvssSeverity.keyword',
  },
  {
    path: 'platformId',
  },
  {
    path: 'teamId',
    paramKey: 'teamId.keyword',
  },
];

export const appsInventoryColumnMapping = (
  t: TFunction<'translation', undefined>,
  inDrawer?: boolean
): IAppVersionsRowMapping[] => [
  { path: 'name' },
  { path: 'version' },
  {
    path: 'platform',
    columnContent: ({ classList, rowData }) => {
      const platform = rowData?.platform ?? '';

      return (
        <div className={classList.platform}>
          {/^android$/i.test(platform) && <AndroidIcon />}
          {/^ios$/i.test(platform) && <AppleIcon />}
          {t(`GLOBAL.${PlatformMapping[platform].toUpperCase()}`) ?? ''}
        </div>
      );
    },
  },
  { path: 'bundleId' },
  { path: 'developer' },
  {
    path: 'classification',
    columnContent: ({ classList, rowData }) => {
      const isLegit = /^legit/i.test(rowData?.classification ?? '');

      return (
        <div className={!isLegit && classList.malicious}>
          {isLegit ? t('GLOBAL.LEGITIMATE') : t('GLOBAL.MALICIOUS')}
        </div>
      );
    },
  },
  {
    path: 'securityRisk',
    columnContent: ({ rowData, classList }) => (
      <div className={classList.risk}>
        {RiskScoreCell(rowData, rowData?.securityRisk, t)}
      </div>
    ),
  },
  {
    path: 'privacyRisk',
    columnContent: ({ rowData, classList }) => (
      <div className={classList.risk}>
        {RiskScoreCell(rowData, rowData?.privacyRisk, t)}
      </div>
    ),
  },
  {
    path: 'deviceCount',
    columnContent: ({ classList, rowData }) => {
      const params = new URLSearchParams({
        duration: '9', // default duration for date selector
        appVersionId: rowData?.id ?? '',
        appName: rowData?.name ?? '',
        appVersion: rowData?.version?.toString() ?? '',
      });

      if (inDrawer) {
        return (
          <div className={classList.risk}>
            {(rowData?.deviceCount ?? 0).toLocaleString('en-US')}
          </div>
        );
      }
      return (
        <ProtectedLink
          allow={{ devices: 'view' }}
          to={`/console/mtd/devices/?${params.toString()}`}
        >
          {(rowData?.deviceCount ?? 0).toLocaleString('en-US')}
        </ProtectedLink>
      );
    },
  },
  {
    path: 'highestCvssSeverity',
    columnContent: ({ rowData, classList }) => (
      <div className={classList.risk}>{HighestCvssSeverity(rowData, t)}</div>
    ),
  },
  {
    path: 'created',
    columnContent: ({ classList, rowData }) => {
      const appVerId = rowData?.id ?? '';
      return (
        <div className={classList.created}>
          {Moment(rowData?.created).format('DD-MMM-YYYY')}
          <GenericTooltipList linkItems={downloadLinksData(appVerId, t)}>
            <MUIFileMoreIcon />
          </GenericTooltipList>
        </div>
      );
    },
  },
];

export const resolveFilters = (
  filterValues: IFilterValues,
  t: TFunction<'translation', undefined>
): IFilterEditor[] => {
  return [
    {
      name: 'id',
      operator: 'eq',
      type: 'string',
      value: filterValues?.id ?? '',
    },
    {
      name: 'name',
      operator: 'contains',
      type: 'string',
      value: filterValues?.name ?? '',
    },
    {
      name: 'version',
      operator: 'eq',
      type: 'string',
      value: filterValues?.version ?? '',
    },
    {
      name: 'platform',
      operator: 'eq',
      type: 'string',
      value: filterValues?.platform ?? '',
      customRSQLOperator: 'filterArray',
      filterEditorProps: {
        dataSource: PLATFORMS.map(({ label, value }) => ({
          id: value,
          label: t(`GLOBAL.${label}`),
        })),
      },
    },

    {
      name: 'bundleId',
      operator: 'eq',
      type: 'string',
      value: filterValues?.bundleId ?? '',
    },
    {
      name: 'developerName',
      operator: 'eq',
      type: 'string',
      value: filterValues?.developerName ?? '',
    },
    {
      name: 'classification',
      operator: 'eq',
      type: 'string',
      value: filterValues?.classification ?? '',
      customRSQLOperator: 'filterArray',
      filterEditorProps: {
        dataSource: CLASSIFICATION.map(({ label, value }) => ({
          id: value,
          label: t(`GLOBAL.${label}`),
        })),
      },
    },
    {
      name: 'securityRisk',
      operator: 'in',
      type: 'select',
      value: filterValues?.securityRisk ?? '',
      customRSQLOperator: 'in',
      filterEditorProps: {
        dataSource: RISKS_TYPE.map(({ label, value }) => ({
          id: value,
          label: t(`GLOBAL.${label}`),
        })),
      },
    },
    {
      name: 'highestCvssSeverity',
      operator: 'in',
      type: 'select',
      value: filterValues?.highestCvssSeverity ?? '',
      customRSQLOperator: 'in',
      filterEditorProps: {
        dataSource: HIGHEST_CVSS_TYPE.map(({ label, value }) => ({
          id: value,
          label: t(`GLOBAL.${label}`),
        })),
      },
    },
    {
      name: 'privacyRisk',
      operator: 'in',
      type: 'select',
      value: filterValues?.privacyRisk ?? '',
      customRSQLOperator: 'in',
      filterEditorProps: {
        dataSource: RISKS_TYPE.map(({ label, value }) => ({
          id: value,
          label: t(`GLOBAL.${label}`),
        })),
      },
    },
    {
      name: 'deviceCount',
      operator: 'gte',
      type: 'number',
      value: filterValues?.deviceCount ?? null,
    },
  ];
};

export const transformClassification = (classificationValue: string) => {
  if (classificationValue === 'MALICIOUS') {
    return 'GLOBAL.MALICIOUS';
  } else if (classificationValue === 'LEGIT') {
    return 'GLOBAL.LEGITIMATE';
  } else {
    return '';
  }
};
