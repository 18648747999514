import { IZScanAssessmentsRowMapping } from './models';
import Moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUpload from '@material-ui/icons/CloudUpload';
import InputIcon from '@material-ui/icons/Input';
import { Link } from 'react-router-dom';
import { toggleModalDiffered, toggleModalDirect } from 'utils/storeUtils';
import Tooltip from '@material-ui/core/Tooltip';
import { riskAssessment } from '../../appsInventory/AppsInventoryTab/appsInventory.mappings';
import { TFunction } from 'react-i18next';
import ProtectedLink from 'components/main/protected/ProtectedLink';

export const zScanAssessmentsTableHeaders = [
  {
    id: 'scanDate',
    flex: 1,
    label: 'Assessment Date',
    minWidth: 175,
    maxWidth: 175,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'zdevMetadata.analysis',
    flex: 1,
    label: 'Current Status',
    minWidth: 150,
    maxWidth: 175,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'zdevMetadata.appVersion',
    flex: 1,
    label: 'Version',
    minWidth: 125,
    maxWidth: 150,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'zdevMetadata.appBuildVersion',
    flex: 1,
    label: 'Build',
    minWidth: 125,
    maxWidth: 150,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'privacyScore',
    flex: 1,
    label: 'Privacy Risk',
    minWidth: 150,
    maxWidth: 175,
    resizable: true,
    show: false,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'securityScore',
    flex: 1,
    label: 'Security Risk',
    minWidth: 150,
    maxWidth: 175,
    resizable: true,
    show: false,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'buildSecurityFindingsCount',
    flex: 1,
    label: '# Security Findings',
    minWidth: 175,
    maxWidth: 175,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'buildPrivacyFindingsCount',
    flex: 1,
    label: '# Privacy Findings',
    minWidth: 175,
    maxWidth: 175,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'bestPracticesCount',
    flex: 1,
    label: '# Best Practices',
    minWidth: 175,
    maxWidth: 175,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'criticalFindingsCount',
    flex: 1,
    label: '# Critical Findings',
    minWidth: 175,
    maxWidth: 175,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'source',
    flex: 1,
    label: 'Source',
    minWidth: 125,
    maxWidth: 175,
    resizable: true,
    show: false,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'actions',
    label: 'Actions',
    show: true,
    path: 'actions',
    sort: false,
    minWidth: 150,
    maxWidth: 150,
    resizable: true,
    flex: 1,
  },
];

export const zScanAssessmentsColumnMapping = (
  t: TFunction<'translation', undefined>
): IZScanAssessmentsRowMapping[] => [
    {
      path: 'scanDate',
      columnContent: ({ rowData }) =>
        rowData?.scanDate ? Moment(rowData?.scanDate).format('DD-MMM-YYYY') : '',
    },
    {
      path: 'zdevMetadata.analysis',
      columnContent: ({ rowData }) => (
        <div className="table-cell__ellipsis">
          {rowData?.zdevMetadata?.analysis ?? 'Unknown'}
        </div>
      ),
    },
    {
      path: 'zdevMetadata.appVersion',
      columnContent: ({ rowData }) => (
        <div className="table-cell__ellipsis">
          {rowData?.zdevMetadata?.appVersion ?? 'Unknown'}
        </div>
      ),
    },
    {
      path: 'zdevMetadata.appBuildVersion',
      columnContent: ({ rowData }) => (
        <div className="table-cell__ellipsis">
          {rowData?.zdevMetadata?.appBuildVersion ?? 'Unknown'}
        </div>
      ),
    },
    {
      path: 'privacyScore',
      columnContent: ({ classList, rowData }) => (
        <div className={classList.risk}>
          {riskAssessment(rowData?.privacyScore, t)}
        </div>
      ),
    },
    {
      path: 'securityScore',
      columnContent: ({ classList, rowData }) => (
        <div className={classList.risk}>
          {riskAssessment(rowData?.securityScore, t)}
        </div>
      ),
    },
    {
      path: 'buildSecurityFindingsCount',
      columnContent: ({ rowData }) => (
        <ProtectedLink
          allow={{ zdev_apps: 'view' }}
          to={`/console/zscan/assessment/${rowData.id}/findings?security=true&orderBy=severityOrdinal&order=desc`}
        >
          <span> {rowData?.buildSecurityFindingsCount ?? 0}</span>
        </ProtectedLink>
      ),
    },
    {
      path: 'buildPrivacyFindingsCount',
      columnContent: ({ rowData }) => (
        <ProtectedLink
          allow={{ zdev_apps: 'view' }}
          to={`/console/zscan/assessment/${rowData.id}/findings?privacy=true&orderBy=severityOrdinal&order=desc`}
        >
          <span> {rowData?.buildPrivacyFindingsCount ?? 0}</span>
        </ProtectedLink>
      ),
    },
    {
      path: 'bestPracticesCount',
      columnContent: ({ rowData }) => (
        <ProtectedLink
          allow={{ zdev_apps: 'view' }}
          to={`/console/zscan/assessment/${rowData.id}/findings?severity=BEST_PRACTICES&order=desc`}
        >
          <span> {rowData?.bestPracticesCount ?? 0}</span>
        </ProtectedLink>
      ),
    },
    {
      path: 'criticalFindingsCount',
      columnContent: ({ rowData }) => (
        <ProtectedLink
          allow={{ zdev_apps: 'view' }}
          to={`/console/zscan/assessment/${rowData.id}/findings?severity=CRITICAL&order=desc`}
        >
          <span> {rowData?.criticalFindingsCount ?? 0}</span>
        </ProtectedLink>
      ),
    },
    {
      path: 'source',
      columnContent: ({ rowData }) => (
        <div className="table-cell__ellipsis">
          {rowData?.appSource ?? 'Unknown'}
        </div>
      ),
    },

    {
      path: 'actions',
      columnContent: ({ rowData }) => {
        // to do Delete should be delete build
        return (
          <>
            <Tooltip title="Delete Assessment">
              <DeleteIcon
                className="cursor--pointer"
                onClick={toggleModalDiffered(
                  'ZscanAssessmentDeleteConfirm',
                  {
                    rowData,
                  },
                  { title: 'Delete Assessment' }
                )}
              />
            </Tooltip>
            <Tooltip title="Findings">
              <Link
                to={`/console/zscan/assessment/${rowData.id}/findings?orderBy=severityOrdinal&order=desc`}
              >
                <InputIcon className="cursor--pointer" />
              </Link>
            </Tooltip>
            <Tooltip title="Upload SBOM">
              <span style={{ paddingLeft: '5px' }}>
                <CloudUpload
                  className="cursor--pointer"
                  onClick={() =>
                    toggleModalDirect('BomUpload', {
                      variant: 'BomUpload',
                      table: 'zScanAssessments',
                      assessmentId: rowData.id,
                    })
                  }
                />
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];
