import * as Yup from 'yup';

const timeRegEx = /^\d{2}:\d{2}$/;
const timeMessage = 'Must select a valid time';

const Schema = () => {
  return Yup.object().shape({
    dailyStartTime: Yup.string()
      .matches(timeRegEx, timeMessage)
      .required(timeMessage),
    days: Yup.array().required('Must select at least one day.').min(1),
  });
};
export default Schema;
