import AndroidIcon from '@material-ui/icons/Android';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Input from '@material-ui/icons/Input';
import AppleIcon from 'UI/Icons/AppleIcon';
import ComplianceTable from 'dashboard/zDev/Compliance/ComplianceTable';
import _ from 'lodash';
import { toggleModalDiffered } from 'utils/storeUtils';
import EnabledOption from './EnabledOption';
import SeveritySelector from './SeveritySelector';

export const zDevPoliciesTableHeaders = [
  {
    id: 'enabled',
    label: 'Enabled',
    show: true,
    sort: false,
  },
  {
    id: 'name',
    label: 'Name',
    show: true,
    sort: true,
  },
  {
    id: 'category.name',
    label: 'Category',
    show: true,
    sort: true,
  },
  {
    id: 'subcategory.name',
    label: 'Subcategory',
    show: true,
    sort: true,
  },
  {
    id: 'severity',
    label: 'Severity',
    show: true,
    sort: true,
  },
  {
    id: 'platform',
    label: 'Platform',
    show: true,
    sort: false,
  },
];

export const zDevPoliciesTableColumns = (policyId) => [
  {
    path: 'enabled',
    columnContent: (_, { rowData, handlers, editMode }) => (
      <EnabledOption
        key={rowData.id}
        finding={rowData}
        disabled={!editMode}
        onFindingUpdate={handlers.findingUpdates}
      />
    ),
  },
  { path: 'name' },
  { path: 'category.name' },
  { path: 'subcategory.name' },
  {
    path: 'severity',
    columnContent: (_, { rowData, handlers, editMode }) => (
      <SeveritySelector
        key={rowData.id}
        finding={rowData}
        disabled={!editMode}
        policyId={policyId}
        onFindingUpdate={handlers.findingUpdates}
      />
    ),
  },
  {
    path: 'platform',
    columnContent: (platforms) => {
      return platforms.map((os, index) => {
        if (!os) {
          return null;
        }
        return os === 'ios' ? (
          <span
            key={`os-${index}`}
            style={{ marginRight: '5px' }}
            className="table__os-icon"
          >
            <AppleIcon />
          </span>
        ) : (
          <AndroidIcon key={`os-${index}`} style={{ marginRight: '10px' }} />
        );
      });
    },
  },
  {
    path: 'id',
    columnContent: (id, { handlers, isShowingDetails, classList }) => {
      return (
        <ExpandMore
          className={isShowingDetails ? classList.toggle : classList.expandIcon}
          onClick={handlers.toggleDetails(id)}
        />
      );
    },
  },
];

export const zdevpoliciesConfig = {
  column1: [
    {
      key: 'name',
      label: 'Finding Name',
    },
    {
      key: 'platform',
      label: 'Platform',
      fetch: (platforms) => {
        return platforms.map((os, index) => {
          if (!os) {
            return null;
          }
          return os === 'ios' ? (
            <span style={{ marginRight: '5px' }} className="table__os-icon">
              <AppleIcon />
            </span>
          ) : (
            <AndroidIcon
              key={`${os}-${index}`}
              style={{ marginRight: '10px' }}
            />
          );
        });
      },
    },
    {
      key: 'severity',
      label: 'Severity',
      fetch: (text, data) => {
        return <SeveritySelector key={data.id} finding={data} disabled />;
      },
    },
    {
      key: 'findingDescription',
      label: 'Finding',
      fetch: (findingDescription) => {
        if (!findingDescription || findingDescription === '') {
          return 'omit';
        }
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: findingDescription,
            }}
          ></div>
        );
      },
    },
    {
      key: 'businessImpact',
      label: 'Business Impact',
      fetch: (businessImpact) => {
        if (!businessImpact || businessImpact === '') {
          return 'omit';
        }
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: businessImpact,
            }}
          ></div>
        );
      },
    },
    {
      key: 'recommendation',
      label: 'Recommendation',
      fetch: (recommendation) => {
        if (!recommendation || recommendation === '') {
          return 'omit';
        }
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: recommendation,
            }}
          ></div>
        );
      },
    },
  ],
  column2: [
    {
      key: 'category.name',
      label: 'Category',
      fetch: (name, { category }) => {
        return (
          <span>
            {name}
            <br />
            {_.get(category, 'description', '')}
          </span>
        );
      },
    },

    {
      key: 'subcategory.name',
      label: 'Subcategory',
      fetch: (name, props) => {
        return (
          <span>
            {name}
            <br />
            {_.get(props, 'subcategory.description', '')}
          </span>
        );
      },
    },
    {
      key: 'cvssData.cvss_2_0_score',
      label: 'CVSS 2.0',
      fetch: (value, data) => CvssCalculator('2.0', data),
    },
    {
      key: 'cvssData.cvss_3_1_score',
      label: 'CVSS 3.1',
      fetch: (value, data) => CvssCalculator('3.1', data),
    },
  ],
  column3: [
    {
      key: 'compliance',
      label: 'Compliance',
      fetch: (complianceArray) => {
        if (_.isEmpty(complianceArray)) {
          return 'No Compliance information available';
        }
        return <ComplianceTable complianceArray={complianceArray} />;
      },
    },
  ],
};

export const CvssCalculator = (version, data) => {
  const cvss_2_0_score = _.get(data, 'cvss_2_0_score');
  const cvss_2_0_vector = _.get(data, 'cvss_2_0_vector');
  const cvss_3_1_score = _.get(data, 'cvss_3_1_score');
  const cvss_3_1_vector = _.get(data, 'cvss_3_1_vector');

  return (
    <span
      style={{
        overflowWrap: 'anywhere',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      {version === '2.0' ? cvss_2_0_score : cvss_3_1_score}{' '}
      <Input
        onClick={toggleModalDiffered(
          'ZDevCVSSCalculator',
          {
            source: 'policies',
            ...data,
            version,
            cvss_2_0_score,
            cvss_2_0_vector,
            cvss_3_1_score,
            cvss_3_1_vector,
          },
          { fullWidth: true, maxWidth: 'l' }
        )}
        // classes={{ root: data.classList.enterAccount }}
      />{' '}
      {version === '2.0' ? cvss_2_0_vector : cvss_3_1_vector}
      {_.get(data, 'cvssData.modified') && ' (modified)'}
    </span>
  );
};

export function mergeTableDataWithCVSS(tableData, cvssData) {
  // console.log(`tableData`, tableData);
  /*
  cvss_2_0_score: "5"
cvss_2_0_vector: "AV:N/AC:L/Au:N/C:P/I:N/A:N"
cvss_3_1_score: "5.3"
cvss_3_1_vector
  */
  const hashedCvss = cvssData.reduce((accum, value) => {
    return {
      [value.findingId]: { ...value, modified: true },
      ...accum,
    };
  }, {});

  return tableData.map((finding) => {
    let existingCVSSData = {};
    if (finding.cvss_2_0_score) {
      existingCVSSData.cvss_2_0_score = finding.cvss_2_0_score;
    }
    if (finding.cvss_2_0_vector) {
      existingCVSSData.cvss_2_0_vector = finding.cvss_2_0_vector;
    }
    if (finding.cvss_3_1_score) {
      existingCVSSData.cvss_3_1_score = finding.cvss_3_1_score;
    }
    if (finding.cvss_3_1_vector) {
      existingCVSSData.cvss_3_1_vector = finding.cvss_3_1_vector;
    }
    if (_.isEmpty(existingCVSSData)) {
      existingCVSSData = undefined;
    }
    return {
      cvssData: hashedCvss[finding.id]
        ? { ...hashedCvss[finding.id] }
        : existingCVSSData,
      ...finding,
    };
  });
}
