import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

export default state => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      trm: {
        ...uiSettingsDefaults.trm,
        tableHeaders: uiSettingsDefaults.trm.tableHeaders,
      },
    },
  };
};
