import React, { useContext, useMemo } from 'react';
import MUIInputIcon from '@material-ui/icons/Input';
import useStyles from './useStyles';
import { IAuditLog } from '../models';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import { auditLogRequest } from 'api/ThreatsService';
import { DrawerContext } from 'components/drawer/DrawerProvider';
import ThreatDetailsDrawer from 'components/main/threats/ThreatDetailsDrawer';
import { IDrawerContext } from 'components/drawer/DrawerProvider/models';
import { IThreatDrawerData } from 'components/main/threats/ThreatDetailsDrawer/models';
import { fetchAllThreatTypes } from 'api/ThreatDefaultsService';
import { IRowData } from 'components/main/threats/Threats/models';

interface IActionTypeProps {
  rowData: IAuditLog;
}

const ActionType: React.FC<IActionTypeProps> = ({ rowData }) => {
  const classes = useStyles();
  const { openDrawer } =
    useContext<Partial<IDrawerContext<IThreatDrawerData>>>(DrawerContext);

  const timeStamp = rowData?.timestamp;

  const eventId = useMemo(() => {
    if (rowData?.actionType === 'THREAT') {
      const regex = /eventId:\s*([a-f0-9-]+)/i;
      // Extract the threatId
      const match = rowData?.action.match(regex);

      if (match) {
        console.log(`Extracted threatId: ${match[1]}`);
        return match[1];
      } else {
        console.error('Threat ID not extracted');
      }
    }
  }, [rowData]);

  const handleFetchTheatData = async () => {
    const timeStampDateObject = new Date(timeStamp);
    // we do not know the threat date.  the timestamp date is the timestamp of the mitigation
    timeStampDateObject.setFullYear(timeStampDateObject.getFullYear() - 6);
    const { data: threatTypesFromApi } = await fetchAllThreatTypes();
    const { data } = await auditLogRequest({
      eventId,
      after: timeStampDateObject.toISOString(),
    });

    const modifiedResult = {
      ...data,
      content: data.content.map((item: IRowData) => {
        let threatType;
        for (const key in threatTypesFromApi) {
          if (threatTypesFromApi[key].id === item.threatTypeId) {
            threatType = threatTypesFromApi[key];
            break;
          }
        }
        return {
          ...item,
          serverName: threatType?.serverName,
          description: threatType?.description,
        };
      }),
    };

    if (!openDrawer) {
      return;
    }
    openDrawer({
      drawerProps: {
        rowData: modifiedResult?.content[0],
        module: 'mtd',
        classList: classes.customDrawer,
      },
      drawer: ThreatDetailsDrawer,
    });
  };

  return (
    <div className={classes.tableCellWrapper}>
      <ProtectedComponent allow={{ threats: 'view' }}>
        <div onClick={handleFetchTheatData} className={classes.inputIcon}>
          <MUIInputIcon />
        </div>
      </ProtectedComponent>
      <span className={classes.actionText}>{rowData?.action}</span>
    </div>
  );
};

export default ActionType;
