import { default as MUIDialogContent } from '@material-ui/core/DialogContent';
import { zDevUploadBuildErrorHelper } from 'api/zDevService';
import Axios from 'axios';
// TODO: Replace later
import { default as OldFileInfoCard } from 'components/fileuploader/FileInfoCard';
import { default as OldUploadComplete } from 'components/fileuploader/UploadComplete';
import Uploader from 'components/UI/Uploader';
import { default as OldUploaderDropZone } from 'components/fileuploader/UploaderDropZone';
import { default as OldUploadError } from 'components/fileuploader/UploadError';
import { default as OldUploadProgress } from 'components/fileuploader/UploadProgress';
import InputError from 'components/UI/input/InputError';
import { Api } from 'config/axiosConfig';
import { Form as FormikForm, Formik } from 'formik';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { publishEvent } from 'utils/eventUtils';
import {
  openSnackBar,
  toggleModalDiffered,
  toggleModalDirect,
} from 'utils/storeUtils';
import { IBuildUploadData, IZScanPolicyData } from './models';
import useStyles from './useStyles';

// TODO: Replace later
const UploaderDropZone: any = OldUploaderDropZone;
const UploadError: any = OldUploadError;
const UploadComplete: any = OldUploadComplete;
const UploadProgress: any = OldUploadProgress;
const FileInfoCard: any = OldFileInfoCard;

interface IFormProps {
  data: Partial<IBuildUploadData>;
  handleSubmitForm: () => void;
  externalError: string | undefined;
}

interface IZScanPolicy {
  value: string;
  label: string;
  defaultPolicy: boolean;
}

const { CancelToken } = Axios;
let source = CancelToken.source();

const initialFormState = {
  name: '',
  selectedFile: null,
  appUrl: null,
};

const Form: React.FC<IFormProps> = ({
  data,
  externalError, // TODO remove
  handleSubmitForm,
}) => {
  const classes = useStyles();

  const [selectedPolicy, setSelectedPolicy] = useState<IZScanPolicy[]>([]);
  const [zScanPolicies, setZScanPolicies] = useState<IZScanPolicy[]>([]);
  const [form, setForm] = useState(initialFormState);
  const [fileLoaded, setFileLoaded] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploaded, setUploaded] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const tableName = data.variant || 'BuildUpload';

  useEffect(() => {
    const zScanPoliciesData: IZScanPolicy[] = [];
    if (!_.get(data, 'zScanPolicies')) {
      return;
    }
    data.zScanPolicies?.forEach((element: IZScanPolicyData) => {
      zScanPoliciesData.push({ value: element.id, label: element.name, defaultPolicy: element.defaultPolicy });
    });
    setZScanPolicies(zScanPoliciesData);
    const defaultPolicy = zScanPoliciesData.find(policy => policy.defaultPolicy);
    setSelectedPolicy(defaultPolicy ? [defaultPolicy] : []);
  }, [data]);

  const updateSelectedPolicy = (
    fieldName: string,
    selectedPolicy: IZScanPolicy
  ) => {
    setSelectedPolicy([selectedPolicy]);
  };

  const handleSubmitUrl = (
    url: string,
    notifyUploader: Blob | string,
    shouldAutoUpdateFromAppStore: Blob | string,
    zScanPolicyId: string
  ) => {
    const formData = new FormData();
    formData.append('url', url);
    formData.append('notifyUploader', notifyUploader);
    formData.append(
      'shouldAutoUpdateFromAppStore',
      shouldAutoUpdateFromAppStore
    );
    formData.append('zDevPolicyId', zScanPolicyId);
    Api.post('api/zdev-upload/v1/uploads/url', formData, {}).then(() =>
      handleCloseModal()
    );
  };

  const handleCloseModal = () => {
    toggleModalDirect(tableName, false);
    openSnackBar('Upload Successful');
    publishEvent(`table:force-fetch-${_.get(data, 'table', 'apps')}`);
  };

  const getFileData = (filedata: any) => {
    setForm({ ...form, selectedFile: filedata });
  };

  const handleRemoveFile = () => {
    setForm({ ...form, selectedFile: null });
  };

  const handleSubmitFile = (
    notifyUploader: Blob | string,
    zScanPolicyId: string
  ) => {
    const formData = new FormData();
    formData.append('buildFile', form.selectedFile || '');
    formData.append('notifyUploader', notifyUploader);
    formData.append('zDevPolicyId', zScanPolicyId); // backend uses zDev instead of zScan
    setLoading(true);

    Api.post('api/zdev-upload/v1/uploads/build', formData, {
      cancelToken: source.token,
      onUploadProgress: (ProgressEvent) => {
        setFileLoaded((ProgressEvent.loaded / ProgressEvent.total) * 100);
      },
    })
      .then(({ data }) => {
        const errorMessage = _.get(
          data,
          'zdevUploadResponse.errorMessage', // TODO
          'Session Timeout'
        );
        setUploaded(true);
        setErrorMessage(errorMessage);
        setLoading(false);
        setFileLoaded(100);
      })
      .catch((e) => {
        setUploaded(false);
        setErrorMessage(zDevUploadBuildErrorHelper(e));
        setLoading(false);
        setFileLoaded(100);
      });
  };

  const handleCancelUploadRequest = () => {
    source.cancel();
    toggleModalDirect(tableName, false);
    openSnackBar('Upload Cancelled');
    // this is needed to make requests again.  the cancel token needs to change.
    source = CancelToken.source();
  };

  const resetModal = () => {
    setForm(initialFormState);
    setFileLoaded(0);
    setLoading(false);
    setUploaded(false);
    setErrorMessage('');
    setSelectedPolicy([]);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={handleSubmitForm}
      validateOnBlur
    >
      {() => (
        <div className={classes.form}>
          <FormikForm>
            <MUIDialogContent>
              <UploaderDropZone
                handleCloseModal={toggleModalDiffered(tableName, false)}
                handlePolicyChange={updateSelectedPolicy}
                handleSubmitUrl={handleSubmitUrl}
                isShowing={_.isNull(form.selectedFile)}
                selectedPolicy={selectedPolicy}
                showUrlField={true}
                zScanPolicies={zScanPolicies}
                classList={{
                  customDropZoneGroup: classes.customDropZoneGroup,
                }}
              >
                <Uploader
                  accept=".apk,.ipa,.aab"
                  getFileData={getFileData}
                  isShowing={_.isNull(form.selectedFile)}
                  multiple
                />
              </UploaderDropZone>
              <FileInfoCard
                buttonText="Upload Build"
                fileData={_.get(form, 'selectedFile', null)}
                handleCloseModal={toggleModalDiffered(tableName, false)}
                handlePolicyChange={updateSelectedPolicy}
                handleSubmit={handleSubmitFile}
                isShowing={!_.isNull(form.selectedFile) && fileLoaded === 0}
                notify
                notifyLabel="Notify me when the build is analyzed"
                removeFile={handleRemoveFile}
                selectedPolicy={selectedPolicy}
                zScanPolicies={zScanPolicies}
              />
              <UploadError
                errorMessage={errorMessage}
                handleCloseModal={toggleModalDiffered(tableName, false)}
                isShowing={fileLoaded > 99 && errorMessage}
                retryUpload={resetModal}
              />
              <UploadComplete
                handleCloseModal={handleCloseModal}
                isShowing={uploaded && !errorMessage}
                message="Success! Your build is pending"
              />
              <UploadProgress
                fileLoaded={fileLoaded}
                handleCancelUploadRequest={handleCancelUploadRequest}
                isShowing={loading}
              />
              {!!externalError && <InputError override={externalError} />}
            </MUIDialogContent>
          </FormikForm>
        </div>
      )}
    </Formik>
  );
};

export default Form;
