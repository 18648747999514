import {
  AppBar as MUIAppBar,
  IconButton as MUIIconButton,
  ListItem as MUIListItem,
  Toolbar as MUIToolbar,
  Tooltip as MUITooltip,
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import AdminUserIcon from '@material-ui/icons/SupervisedUserCircle';
import Button from 'components/UI/Button';
import { IGenericPromptModalData } from 'components/UI/Modals/GenericPrompt/models';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, RouteComponentProps } from 'react-router';
import { Link, NavLink, Redirect } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { compose } from 'redux';
import { headerActions as headerActionsAtom } from '../../atoms/header';
import { getActiveModalAtom } from '../../atoms/modals';
import withRouter from '../../components/hocs/withRouter';
import { GenericPromptModal_TITLE } from '../../components/UI/Modals/GenericPrompt';
import useStyles from './useStyles';
import { useSafeBranding } from 'components/partner/branding/utils';
import { selectedTeam } from 'atoms/teams';

const activeModalAtom = getActiveModalAtom<IGenericPromptModalData>();

interface IGlobalHeaderProps extends RouteComponentProps {
  module: string;
  toggleTheme: () => void;
}

const GlobalHeader: React.FC<IGlobalHeaderProps> = ({
  location,
  module,
  toggleTheme,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [headerActions, setHeaderActions] = useRecoilState(headerActionsAtom);
  const setActiveModal = useSetRecoilState(activeModalAtom);
  const setSelectedTeamInRecoil = useSetRecoilState(selectedTeam);
  const branding = useSafeBranding();

  // Not going to bother typing any Redux related state structure as it's on the
  // chopping block entirely. - RL 11/29/22
  const { organization } = useSelector(({ user }: any) => user);
  const isSuperUser = useSelector(
    ({ auth }: any) => auth.superUser.isAuthenticated
  );
  const isPartnerUser = useSelector(
    ({ auth }: any) => auth.partnerUser.isAuthenticated
  );
  const { modules, scopes } = useSelector(({ auth }: any) => auth.user);
  const theme = useSelector(({ uiSettings }: any) => uiSettings.theme);
  const consoleVersion = useSelector(
    ({ uiSettings }: any) => uiSettings.version
  );

  const acctMgmtLink = useMemo(() => {
    let newAccountMgmtLink = '';

    if (scopes?.account?.includes('view')) {
      newAccountMgmtLink = 'manage';
    } else if (scopes?.users?.includes('view')) {
      newAccountMgmtLink = 'manage/users/users';
    } else if (scopes?.api_keys?.includes('view')) {
      newAccountMgmtLink = 'manage/authorizations';
    } else if (scopes?.data_exports?.includes('view')) {
      newAccountMgmtLink = 'manage/data-export';
    } else if (scopes?.audit_log?.includes('view')) {
      newAccountMgmtLink = 'manage/audit-log';
    }

    return newAccountMgmtLink;
  }, [
    scopes?.account,
    scopes?.api_keys,
    scopes?.audit_log,
    scopes?.data_exports,
    scopes?.users,
  ]);

  const docsReleaseNotesRoot = useMemo(
    () =>
      ['zdefend', 'zscan', 'mtd'].includes(module)
        ? `${module.replace('mtd', 'zips')}-docs`
        : 'zdefend-docs',
    [module]
  );

  const logoUrl = useMemo(
    () =>
      theme === 'dark'
        ? branding?.darkModeConsoleLogoUrl || 'logo.svg'
        : branding?.consoleLogoUrl || 'logo.svg',
    [branding, theme]
  );

  const isActiveProduct = useCallback(
    (product: string) => location?.pathname?.indexOf(product) !== -1,
    [location]
  );

  const [preventNavigationUrl, setPreventNavigationUrl] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (
      headerActions?.preventNavigation &&
      preventNavigationUrl === undefined
    ) {
      setPreventNavigationUrl(window.location?.href?.split('?')?.[0]);
    }
  }, [
    headerActions?.preventNavigation,
    preventNavigationUrl,
    setPreventNavigationUrl,
  ]);

  const shouldPreventNavigation = useMemo(
    () =>
      headerActions?.preventNavigation &&
      preventNavigationUrl !== undefined &&
      preventNavigationUrl === window.location.href?.split('?')?.[0],
    [headerActions?.preventNavigation, preventNavigationUrl]
  );

  const [shouldRedirect, setShouldRedirect] = useState<any | undefined>(
    undefined
  );

  useEffect(() => {
    const handleUnload = (e: any) => {
      e.preventDefault();

      // Fallback for legacy browsers. Most browsers
      // will not display this message.
      e.returnValue =
        'You have made changes on this page ' +
        'that have not been deployed yet. If ' +
        'you leave this page, these ' +
        'changes will be lost.';
    };

    if (!!shouldPreventNavigation) {
      window.addEventListener('beforeunload', handleUnload);
    } else {
      window.removeEventListener('beforeunload', handleUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [shouldPreventNavigation]);

  const triggerPreventNavigationModal = useCallback(
    (onContinueAction: () => void = () => {}) => {
      setActiveModal({
        active: GenericPromptModal_TITLE,
        payload: {
          title: headerActions?.navigationPrompt?.title ?? 'changes pending',
          message: headerActions?.navigationPrompt?.message ?? (
            <span>
              You have unsaved changes pending. yet. If you leave this page,
              these changes will be lost.
            </span>
          ),
          onCancelCaption:
            headerActions?.navigationPrompt?.onCancelCaption ?? 'LOSE CHANGES',
          onConfirmCaption:
            headerActions?.navigationPrompt?.onConfirmCaption ?? 'STAY HERE',
          onCancel: () => {
            headerActions?.clearNavigationPreventingChanges?.();
            setActiveModal({});
            setHeaderActions(undefined);
            onContinueAction();
            setPreventNavigationUrl(undefined);
          },
          onConfirm: () => {
            setActiveModal({});
          },
        },
      });
    },
    [headerActions, setActiveModal, setHeaderActions]
  );

  return (
    <>
      {!!shouldRedirect && <Redirect to={shouldRedirect} />}
      <Prompt
        when
        message={(nextLocation, action) => {
          let result = true;

          if (
            shouldPreventNavigation &&
            !preventNavigationUrl?.endsWith(nextLocation.pathname) &&
            action === 'PUSH'
          ) {
            triggerPreventNavigationModal(() =>
              setShouldRedirect(nextLocation)
            );

            result = false;
          }

          return result;
        }}
      />
      <MUIAppBar className={classes.headerContainer}>
        <MUIToolbar className={classes.toolbar}>
          <div className={classes.logoContainer}>
            <div className={classes.logoImageContainer}>
              <img
                alt="zConsole Logo"
                className={classes.logo}
                src={logoUrl as string}
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  width: 'auto',
                  height: 'auto',
                }}
              />
            </div>
            <span>
              Console <br />
              <a
                href={`${window.location.origin}/ziap-docs/${docsReleaseNotesRoot}/console/release_notes.html`}
                rel="noopener noreferrer"
                style={{ fontSize: 12 }}
                target="_blank"
              >
                <span className={classes.consoleVersion}>
                  v.{consoleVersion}
                </span>
              </a>
            </span>
          </div>
          <div className={classes.products}>
            {!!modules.ZIAP && (
              <MUIListItem
                classes={{
                  root: classes.productLinkRoot,
                  selected: classes.productLinkSelected,
                }}
                component={NavLink}
                selected={isActiveProduct('zdefend')}
                to="/console/zdefend"
              >
                {branding?.zdefendModuleText}
              </MUIListItem>
            )}

            {!!modules.ZDEV && (
              <MUIListItem
                classes={{
                  root: classes.productLinkRoot,
                  selected: classes.productLinkSelected,
                }}
                component={NavLink}
                selected={isActiveProduct('zscan')}
                to="/console/zscan/apps"
              >
                <span>{branding?.zscanModuleText}</span>
              </MUIListItem>
            )}
            {!!modules.ZIPS && (
              <MUIListItem
                classes={{
                  root: classes.productLinkRoot,
                  selected: classes.productLinkSelected,
                }}
                component={NavLink}
                selected={isActiveProduct('mtd')}
                to="/console/mtd"
              >
                {branding?.mtdModuleText}
              </MUIListItem>
            )}
            {!!modules.ZSHIELD && (
              <MUIListItem
                classes={{
                  root: classes.productLinkRoot,
                  selected: classes.productLinkSelected,
                }}
                component={NavLink}
                selected={isActiveProduct('zshield')}
                to="/console/zshield"
              >
                {branding?.zshieldModuleText}
              </MUIListItem>
            )}
          </div>
          <div className={classes.actions}>
            <div className={classes.customActions}>
              {headerActions?.buttons?.map((action, index) => (
                <Button key={index} {...action} />
              ))}
            </div>
            <div className={classes.organization}>{organization}</div>
            {!!modules.COMMON && (
              <MUITooltip enterDelay={100} title="Account Management">
                <Link to={`/console/${acctMgmtLink}`}>
                  <MUIIconButton
                    className={classes.buttonIcon}
                    onClick={toggleTheme}
                    style={{ marginLeft: 5 }}
                  >
                    <SettingsIcon />
                  </MUIIconButton>
                </Link>
              </MUITooltip>
            )}
            {isSuperUser || isPartnerUser ? (
              <MUIIconButton
                className={classes.buttonIcon}
                onClick={() => {
                  setSelectedTeamInRecoil('');
                  if (!shouldPreventNavigation) {
                    dispatch({ type: 'AUTH_CLEAR_USER' });
                  } else {
                    triggerPreventNavigationModal(() =>
                      dispatch({ type: 'AUTH_CLEAR_USER' })
                    );
                  }
                }}
              >
                <AdminUserIcon className={classes.iconStyle} fontSize="small" />
              </MUIIconButton>
            ) : null}
          </div>
        </MUIToolbar>
      </MUIAppBar>
    </>
  );
};

export default compose(withRouter)(GlobalHeader);
