import React from 'react';
import MUICheckIcon from '@material-ui/icons/Check';
import { useTheme } from '@material-ui/core';

const CheckIcon: React.FC = () => {
  const theme = useTheme();

  return <MUICheckIcon style={{ color: theme.palette.text.primary }} />;
};

export default CheckIcon;
