import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ config, palette }: any) =>
  createStyles({
    androidIcon: {
      fill: palette.common.white,
      minHeight: '25px',
      minWidth: '25px',
    },
    appleIcon: {
      fill: palette.common.white,
      minHeight: '25px',
      minWidth: '25px',
    },
    circle: {
      marginRight: 10,
    },
    icon: { alignSelf: 'flex-start', marginLeft: 26 },
    legend: {
      alignItems: 'flex-start',
      display: 'flex',
      flex: '0 0',
      flexBasis: 150,
      flexDirection: 'column',
      gap: 10,
      minWidth: 150,
      paddingTop: 60,
    },
    pending: {
      fontSize: 48,
      opacity: 0.125,
      padding: '48px 0 80px',
    },
    pendingSub: {
      padding: '80px 0 0px',
    },
    riskiestOsVersionsCard: {
      '& > div': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      },
    },
    section: {
      display: 'flex',
      flex: '1 1',
      flexBasis: 300,
      flexDirection: 'column',
      maxWidth: 350,
      paddingTop: 20,
      textAlign: 'center',
    },
    title: {
      backgroundColor: palette.background.default,
      border: `1px solid ${config.colors.darkGreySoft}`,
      color: palette.text.secondary,
    },
    pendingNonEnglish: {
      fontSize: 26,
    },
  })
);

export default useStyles;
