import useStyles from './useStyles';

const KeyIcon: React.FC = (props) => {
  const classes = useStyles();

  return (
    <svg className={classes.icon} viewBox="0 0 24 24" {...props}>
      <path d="M 6 3 C 3.8027056 3 2 4.8027056 2 7 C 2 8.8503017 3.2842135 10.405592 5 10.855469 L 5 21 L 10 21 L 10 19 L 7 19 L 7 17 L 9 17 L 9 15 L 7 15 L 7 10.855469 C 8.7157865 10.405592 10 8.8503017 10 7 C 10 4.8027056 8.1972944 3 6 3 z M 6 5 C 7.1164141 5 8 5.8835859 8 7 C 8 8.1164141 7.1164141 9 6 9 C 4.8835859 9 4 8.1164141 4 7 C 4 5.8835859 4.8835859 5 6 5 z M 12 6 L 12 11 L 22 11 L 22 6 L 12 6 z M 12 13 L 12 18 L 22 18 L 22 13 L 12 13 z" />
    </svg>
  );
};
export default KeyIcon;
