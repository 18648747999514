import { IFilterValues, IOSNames } from './models';
import { getApps } from 'api/zDevService';
import { IFilterEditor } from 'components/UI/Table/models';
import { IQueryParams } from './models';
import moment from 'moment';

export const SOURCE = [
  {
    label: 'Store Link',
    value: 'store',
  },
  {
    label: 'CI/CD',
    value: 'cd',
  },
  {
    label: 'Manual',
    value: 'manual',
  },
];
export const PLATFORMS = [
  {
    label: 'Android',
    value: 'android',
  },
  {
    label: 'iOS',
    value: 'ios',
  },
  // we currently have different ways the backend gives back chrome os.  leaving out for now
  // {
  //   label: 'Chrome OS',
  //   value: 'chrome_os'
  // }
];
export const OS_NAMES: IOSNames = {
  ios: 'iOS',
  android: 'Android',
  'chrome os': 'Chrome OS',
  chrome_os: 'Chrome OS', // having to defend against mislabeled backend OS names
};
export const INITIAL_FILTER_VALUES: IFilterValues = {
  name: '',
  bundleId: '',
  platform: '',
  appSource: '',
  policyName: '',
};

export const createRSQLParams = (filters: IFilterEditor[]) => {
  return (
    filters
      // Remove non-rsql filters
      .filter(({ useURLSearchParams }) => !useURLSearchParams)
      .map(({ name, operator: customRSQLOperator, type, value }) => {
        // This is used for string/number/SINGLE-select filters with custom RSQL operators
        if (customRSQLOperator && !!value && !value?.[0]) {
          return `${name}=${customRSQLOperator}=${value}`;
        }

        if (type === 'date' && !!value) {
          return `${name}==${moment(value).format('YYYY-MM-DD')}`;
        }

        if (type === 'select' && value?.[0]) {
          // This is used for MULTI-select filters
          const rsqlOperator = customRSQLOperator ?? '=';
          const rsqlInOperator = customRSQLOperator ?? 'in';

          return value.length === 1
            ? `${name}=${rsqlOperator}=${value[0]}`
            : `${name}=${rsqlInOperator}=(${value.join(',')})`;
        }

        return name === 'platform'
          ? `${name}.keyword==${
              (type === 'string' || type === 'select') &&
              typeof value === 'string'
                ? `*${value.replace(/ /g, '*')}*`
                : String(value ?? '')
            }`
          : `${name}==${
              type === 'string' && typeof value === 'string'
                ? `*${value.replace(/ /g, '*')}*`
                : String(value ?? '')
            }`;
      })
      .join(';')
  );
};
export const fetchTableData = async (
  filters: IFilterEditor[],
  query: IQueryParams
) => {
  let result; // IZScanAppsResponse | undefined = undefined;

  try {
    const nonNullFilters = filters.filter(
      ({ value }) =>
        typeof value === 'number' ||
        (typeof value === 'string' && value !== '') ||
        value?.[0]
    );

    query.query = createRSQLParams(nonNullFilters);
    result = await getApps({
      ...query,
    });
  } catch (e) {
    console.error(e);
  }
  return result;
};
export const ammendFiltersFromQuery = (
  filters: IFilterEditor[],
  query: IQueryParams
): {
  filters: IFilterEditor[];
  query: IQueryParams;
} => {
  const ammendedFilters: IFilterEditor[] = Object.keys(query)
    .filter((key) => typeof query[key] === 'object' && key !== 'teamfilter')
    .map((key) => ({
      name: key,
      operator: '',
      type: 'select',
      value: query[key],
    }));

  const filtersThatWereAdded: string[] = ammendedFilters.map(
    ({ name }) => name
  );

  return {
    filters: [...filters, ...ammendedFilters],
    query: Object.fromEntries(
      Object.entries(query).filter(
        ([key]) => !filtersThatWereAdded.includes(key)
      )
    ),
  };
};

export const riskScoreToValue: any = (riskScore: number) => {
  if (riskScore && riskScore >= 0) {
    if (riskScore > 66) {
      return 'HIGH';
    }
    if (riskScore > 33 && riskScore <= 66) {
      return 'MEDIUM';
    }
    if (riskScore >= 0 && riskScore <= 33) {
      return 'LOW';
    }
  } else {
    return 'UNKNOWN';
  }
};
