import React from 'react';
import _ from 'lodash';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CVSSRadioControl from './CVSSRadioControl';

import { spec, spec3 } from './cvssCalculationsSpecs.mappping';

const getValue = (version, options, value) => {
  if (!value) {
    if (version === '3.1') {
      return { label: 'Not Defined (X)', value: 'X' };
    }
    if (version === '2.0') {
      return { label: 'Not Defined (X)', value: 'ND' };
    }
  }

  const ret = _.filter(options, o => o.value === value);
  if (_.isEmpty(ret)) {
    if (version === '3.1') {
      return { label: 'Not Defined (X)', value: 'X' };
    }
    if (version === '2.0') {
      return { label: 'Not Defined (X)', value: 'ND' };
    }
  }
  return ret[0];
};

const cvss_2_0_options = {};
cvss_2_0_options.accessVectorOptions = [
  { label: 'Local (L)', value: 'L' },
  { label: 'Adjacent (A)', value: 'A' },
  { label: 'Network (N)', value: 'N' },
];

cvss_2_0_options.accessComplexityOptions = [
  { label: 'Low (L)', value: 'L' },
  { label: 'Medium (M)', value: 'M' },
  { label: 'High (H)', value: 'H' },
];

cvss_2_0_options.authenticationOptions = [
  { label: 'Multiple (M)', value: 'M' },
  { label: 'Single (S)', value: 'S' },
  { label: 'None (N)', value: 'N' },
];

cvss_2_0_options.confidentialityImpactOptions = [
  { label: 'None (N)', value: 'N' },
  { label: 'Partial (P)', value: 'P' },
  { label: 'Complete (C)', value: 'C' },
];

cvss_2_0_options.integrityImpactOptions = [
  { label: 'None (N)', value: 'N' },
  { label: 'Partial (P)', value: 'P' },
  { label: 'Complete (C)', value: 'C' },
];

cvss_2_0_options.availabilityImpactOptions = [
  { label: 'None (N)', value: 'N' },
  { label: 'Partial (P)', value: 'P' },
  { label: 'Complete (C)', value: 'C' },
];

cvss_2_0_options.exploitabilityOptions = [
  { label: 'Unproven (U)', value: 'U' },
  { label: 'Proof-of-Concept (POC)', value: 'POC' },
  { label: 'Functional (F)', value: 'F' },
  { label: 'High (H)', value: 'H' },
  { label: 'Not Defined (ND)', value: 'ND' },
];

cvss_2_0_options.remediationLevelOptions = [
  { label: 'Official Fix (OF)', value: 'OF' },
  { label: 'Temporary Fix (TF)', value: 'TF' },
  { label: 'Workaround (W)', value: 'W' },
  { label: 'Unavailable (U)', value: 'U' },
  { label: 'Not Defined (ND)', value: 'ND' },
];

cvss_2_0_options.reportConfidenceOptions = [
  { label: 'Unconfirmed (UC)', value: 'UC' },
  { label: 'Uncorroborated (UR)', value: 'UR' },
  { label: 'Confirmed (C)', value: 'C' },
  { label: 'Not Defined (ND)', value: 'ND' },
];

cvss_2_0_options.collateralDamagePotentialOptions = [
  { label: 'None (N)', value: 'N' },
  { label: 'Low (L)', value: 'L' },
  { label: 'Low-Medium (LM)', value: 'LM' },
  { label: 'Medium-High (MH)', value: 'MH' },
  { label: 'High (H)', value: 'H' },
  { label: 'Not Defined (ND)', value: 'ND' },
];

cvss_2_0_options.targetDistributionOptions = [
  { label: 'Not Defined (ND)', value: 'ND' },
  { label: 'High (H)', value: 'H' },
  { label: 'Medium (M)', value: 'M' },
  { label: 'Low (L)', value: 'L' },
  { label: 'None (N)', value: 'N' },
];

cvss_2_0_options.confidentialityRequirementOptions = [
  { label: 'Not Defined (ND)', value: 'ND' },
  { label: 'High (H)', value: 'H' },
  { label: 'Medium (M)', value: 'M' },
  { label: 'Low (L)', value: 'L' },
];

cvss_2_0_options.integrityRequirementOptions = [
  { label: 'Not Defined (ND)', value: 'ND' },
  { label: 'High (H)', value: 'H' },
  { label: 'Medium (M)', value: 'M' },
  { label: 'Low (L)', value: 'L' },
];

cvss_2_0_options.availabilityRequirementOptions = [
  { label: 'Not Defined (ND)', value: 'ND' },
  { label: 'High (H)', value: 'H' },
  { label: 'Medium (M)', value: 'M' },
  { label: 'Low (L)', value: 'L' },
];
export const cvss_2_0_metrics = {
  exploitability: [
    {
      key: 'AV',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V2-AV'}
            onChange={data.handleAccordionChange('V2-AV')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainer}>
              <span className={data.classList.accordionLabel}>
                Access Vector (AV)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V2-AV'}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="AV:2.0"
                      options={cvss_2_0_options.accessVectorOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '2.0',
                        cvss_2_0_options.accessVectorOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec.AV.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec.AV.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
    {
      key: 'AC',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V2-AC'}
            onChange={data.handleAccordionChange('V2-AC')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainer}>
              <span className={data.classList.accordionLabel}>
                Access Complexity (AC)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V2-AC'}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="AC:2.0"
                      options={cvss_2_0_options.accessComplexityOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '2.0',
                        cvss_2_0_options.accessComplexityOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec.AC.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec.AC.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
    // ],
    //             options={cvss_2_0_options.authenticationOptions}
    {
      key: 'Au',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V2-AU'}
            onChange={data.handleAccordionChange('V2-AU')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainer}>
              <span className={data.classList.accordionLabel}>
                Authentication Complexity (Au)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V2-AU'}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="Au:2.0"
                      options={cvss_2_0_options.authenticationOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '2.0',
                        cvss_2_0_options.authenticationOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec.Au.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec.Au.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
  ],
  impact: [
    {
      key: 'C',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V2-C'}
            onChange={data.handleAccordionChange('V2-C')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainer}>
              <span className={data.classList.accordionLabel}>
                Confidentiality Impact (C)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V2-C'}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="C:2.0"
                      options={cvss_2_0_options.confidentialityImpactOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '2.0',
                        cvss_2_0_options.confidentialityImpactOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec.C.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec.C.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
    // Integrity Impact (I))
    // cvss_2_0_options.integrityImpactOptions
    {
      key: 'I',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V2-I'}
            onChange={data.handleAccordionChange('V2-I')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainer}>
              <span className={data.classList.accordionLabel}>
                Integrity Impact (I)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V2-I'}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="I:2.0"
                      options={cvss_2_0_options.integrityImpactOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '2.0',
                        cvss_2_0_options.integrityImpactOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec.I.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec.I.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
    // availabilityImpactOptions
    // 'Availability Impact (A)',
    {
      key: 'A',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V2-A'}
            onChange={data.handleAccordionChange('V2-A')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainer}>
              <span className={data.classList.accordionLabel}>
                Availability Impact (A)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V2-A'}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="A:2.0"
                      options={cvss_2_0_options.availabilityImpactOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '2.0',
                        cvss_2_0_options.availabilityImpactOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec.A.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec.A.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
  ],
  temporal: [
    // exploitabilityOptions
    // Exploitability (E)
    {
      key: 'E',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V2-E'}
            onChange={data.handleAccordionChange('V2-E')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainerExtended}>
              <span className={data.classList.accordionLabel}>
                Exploitability (E)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V2-E'}
                aria-controls="panel1d-content"
                id="panel1d-header"
                classes={{ content: data.classList.accordionSummary }}
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="E:2.0"
                      options={cvss_2_0_options.exploitabilityOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '2.0',
                        cvss_2_0_options.exploitabilityOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec.E.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec.E.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
    // remediationLevelOptions
    // Remediation Level (RL)

    {
      key: 'RL',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V2-RL'}
            onChange={data.handleAccordionChange('V2-RL')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainerExtended}>
              <span className={data.classList.accordionLabel}>
                Remediation Level (RL)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V2-RL'}
                aria-controls="panel1d-content"
                id="panel1d-header"
                classes={{ root: data.classList.accordionSummary }}
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="RL:2.0"
                      options={cvss_2_0_options.remediationLevelOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '2.0',
                        cvss_2_0_options.remediationLevelOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec.RL.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec.RL.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
    // reportConfidenceOptions
    // Report Confidence (RC)

    //
    {
      key: 'RC',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V2-RC'}
            onChange={data.handleAccordionChange('V2-RC')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainerExtended}>
              <span className={data.classList.accordionLabel}>
                Report Confidence (RC)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V2-RC'}
                aria-controls="panel1d-content"
                id="panel1d-header"
                classes={{ root: data.classList.accordionSummary }}
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="RC:2.0"
                      options={cvss_2_0_options.reportConfidenceOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '2.0',
                        cvss_2_0_options.reportConfidenceOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec.RC.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec.RC.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
  ],
  environmental: [
    // collateralDamagePotentialOptions

    {
      key: 'CDP',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V2-CDP'}
            onChange={data.handleAccordionChange('V2-CDP')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainerExtended}>
              <span className={data.classList.accordionLabel}>
                Collateral Damage Potential (CDP)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V2-CDP'}
                aria-controls="panel1d-content"
                id="panel1d-header"
                classes={{ root: data.classList.accordionSummary }}
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="CDP:2.0"
                      options={
                        cvss_2_0_options.collateralDamagePotentialOptions
                      }
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '2.0',
                        cvss_2_0_options.collateralDamagePotentialOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec.CDP.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec.CDP.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
    // 'Target Distribution (TD)'
    // TD
    // targetDistributionOptions
    {
      key: 'TD',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V2-TD'}
            onChange={data.handleAccordionChange('V2-TD')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainerExtended}>
              <span className={data.classList.accordionLabel}>
                Target Distribution (TD)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V2-TD'}
                aria-controls="panel1d-content"
                id="panel1d-header"
                classes={{ root: data.classList.accordionSummary }}
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="TD:2.0"
                      options={cvss_2_0_options.targetDistributionOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '2.0',
                        cvss_2_0_options.targetDistributionOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec.TD.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec.TD.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
    // confidentialityRequirementOptions,
    //     label: 'Confidentiality Requirement (CR)',
    {
      key: 'CR',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V2-CR'}
            onChange={data.handleAccordionChange('V2-CR')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainerExtended}>
              <span className={data.classList.accordionLabel}>
                Confidentiality Requirement (CR)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V2-CR'}
                aria-controls="panel1d-content"
                id="panel1d-header"
                classes={{ root: data.classList.accordionSummary }}
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="CR:2.0"
                      options={
                        cvss_2_0_options.confidentialityRequirementOptions
                      }
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '2.0',
                        cvss_2_0_options.confidentialityRequirementOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec.CR.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec.CR.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
    // integrityRequirementOptions}
    // Integrity Requirement (IR)',
    {
      key: 'IR',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V2-IR'}
            onChange={data.handleAccordionChange('V2-IR')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainerExtended}>
              <span className={data.classList.accordionLabel}>
                Integrity Requirement (IR)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V2-IR'}
                aria-controls="panel1d-content"
                id="panel1d-header"
                classes={{ root: data.classList.accordionSummary }}
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="IR:2.0"
                      options={cvss_2_0_options.integrityRequirementOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '2.0',
                        cvss_2_0_options.integrityRequirementOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec.IR.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec.IR.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
    {
      key: 'AR',
      label: '',
      // availabilityRequirementOptions,
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V2-AR'}
            onChange={data.handleAccordionChange('V2-AR')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainerExtended}>
              <span className={data.classList.accordionLabel}>
                Availability Requirement (AR)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V2-AR'}
                aria-controls="panel1d-content"
                id="panel1d-header"
                classes={{ root: data.classList.accordionSummary }}
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="AR:2.0"
                      options={cvss_2_0_options.availabilityRequirementOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '2.0',
                        cvss_2_0_options.availabilityRequirementOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec.AR.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec.AR.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
  ],
};

const cvss_3_1_options = {};
cvss_3_1_options.attackVectorOptions = [
  { label: 'Network (N)', value: 'N' },
  { label: 'Adjacent (A)', value: 'A' },
  { label: 'Local (L)', value: 'L' },
  { label: 'Physical (P)', value: 'P' },
];

cvss_3_1_options.attackComplexityOptions = [
  { label: 'Low (L)', value: 'L' },
  { label: 'High (H)', value: 'H' },
];

cvss_3_1_options.privilegesRequiredOptions = [
  { label: 'None (N)', value: 'N' },
  { label: 'Low (L)', value: 'L' },
  { label: 'High (H)', value: 'H' },
];

cvss_3_1_options.userInteractionOptions = [
  { label: 'None (N)', value: 'N' },
  { label: 'Required (R)', value: 'R' },
];

cvss_3_1_options.scopeOptions = [
  { label: 'Unchanged (U)', value: 'U' },
  { label: 'Changed (C)', value: 'C' },
];

cvss_3_1_options.confidentialityOptions = [
  { label: 'None (N)', value: 'N' },
  { label: 'Low (L)', value: 'L' },
  { label: 'High (H)', value: 'H' },
];

cvss_3_1_options.integrityOptions = [
  { label: 'None (N)', value: 'N' },
  { label: 'Low (L)', value: 'L' },
  { label: 'High (H)', value: 'H' },
];

cvss_3_1_options.availabilityOptions = [
  { label: 'None (N)', value: 'N' },
  { label: 'Low (L)', value: 'L' },
  { label: 'High (H)', value: 'H' },
];

cvss_3_1_options.exploitCodeMaturityOptions = [
  { label: 'Not Defined (X)', value: 'X' },
  { label: 'High (H)', value: 'H' },
  { label: 'Functional (F)', value: 'F' },
  { label: 'Proof-of-Concept (P)', value: 'P' },
  { label: 'Unproven (U)', value: 'U' },
];

cvss_3_1_options.remediationLevelOptions = [
  { label: 'Not Defined (X)', value: 'X' },
  { label: 'Unavailable (U)', value: 'U' },
  { label: 'Workaround (W)', value: 'W' },
  { label: 'Temporary Fix (T)', value: 'T' },
  { label: 'Official Fix (O)', value: 'O' },
];

cvss_3_1_options.reportConfidenceOptions = [
  { label: 'Not Defined (X)', value: 'X' },
  { label: 'Confirmed (C)', value: 'C' },
  { label: 'Reasonable (R)', value: 'R' },
  { label: 'Unknown (U)', value: 'U' },
];

cvss_3_1_options.confidentialityRequirementOptions = [
  { label: 'Not Defined (X)', value: 'X' },
  { label: 'High (H)', value: 'H' },
  { label: 'Medium (M)', value: 'M' },
  { label: 'Low (L)', value: 'L' },
];

cvss_3_1_options.integrityRequirementOptions = [
  { label: 'Not Defined (X)', value: 'X' },
  { label: 'High (H)', value: 'H' },
  { label: 'Medium (M)', value: 'M' },
  { label: 'Low (L)', value: 'L' },
];

cvss_3_1_options.availabilityRequirementOptions = [
  { label: 'Not Defined (X)', value: 'X' },
  { label: 'High (H)', value: 'H' },
  { label: 'Medium (M)', value: 'M' },
  { label: 'Low (L)', value: 'L' },
];

cvss_3_1_options.modifiedAttackVectorOptions = [
  { label: 'Not Defined (X)', value: 'X' },
  { label: 'Network (N)', value: 'N' },
  { label: 'Adjacent (A)', value: 'A' },
  { label: 'Local (L)', value: 'L' },
  { label: 'Physical (P)', value: 'P' },
];

cvss_3_1_options.modifiedAttackComplexityOptions = [
  { label: 'Not Defined (X)', value: 'X' },
  { label: 'Low (L)', value: 'L' },
  { label: 'High (H)', value: 'H' },
];

cvss_3_1_options.modifiedPrivilegesRequiredOptions = [
  { label: 'Not Defined (X)', value: 'X' },
  { label: 'None (N)', value: 'N' },
  { label: 'Low (L)', value: 'L' },
  { label: 'High (H)', value: 'H' },
];

cvss_3_1_options.modifiedUserInteractionOptions = [
  { label: 'Not Defined (X)', value: 'X' },
  { label: 'None (N)', value: 'N' },
  { label: 'Required (R)', value: 'R' },
];

cvss_3_1_options.modifiedScopeOptions = [
  { label: 'Not Defined (X)', value: 'X' },
  { label: 'Unchanged (U)', value: 'U' },
  { label: 'Changed (C)', value: 'C' },
];

cvss_3_1_options.modifiedConfidentialityOptions = [
  { label: 'Not Defined (X)', value: 'X' },
  { label: 'None (N)', value: 'N' },
  { label: 'Low (L)', value: 'L' },
  { label: 'High (H)', value: 'H' },
];

cvss_3_1_options.modifiedIntegrityOptions = [
  { label: 'Not Defined (X)', value: 'X' },
  { label: 'None (N)', value: 'N' },
  { label: 'Low (L)', value: 'L' },
  { label: 'High (H)', value: 'H' },
];

cvss_3_1_options.modifiedAvailabilityOptions = [
  { label: 'Not Defined (X)', value: 'X' },
  { label: 'None (N)', value: 'N' },
  { label: 'Low (L)', value: 'L' },
  { label: 'High (H)', value: 'H' },
];
export const cvss_3_1_metrics = {
  exploitability: [
    // attackVectorOptions
    {
      key: 'AV',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V3-AV'}
            onChange={data.handleAccordionChange('V3-AV')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainer}>
              <span className={data.classList.accordionLabel}>
                Attack Vector (AV)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V3-AV'}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="AV:3.1"
                      options={cvss_3_1_options.attackVectorOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '3.1',
                        cvss_3_1_options.attackVectorOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec3.AV.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec3.AV.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
    {
      key: 'AC',
      label: '',
      // options={cvss_3_1_options.attackComplexityOptions}
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V3-AC'}
            onChange={data.handleAccordionChange('V3-AC')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainer}>
              <span className={data.classList.accordionLabel}>
                Attack Complexity (AC)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V3-AC'}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="AC:3.1"
                      options={cvss_3_1_options.attackComplexityOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '3.1',
                        cvss_3_1_options.attackComplexityOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec3.AC.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec3.AC.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
    {
      key: 'PR',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V3-PR'}
            onChange={data.handleAccordionChange('V3-PR')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainer}>
              <span className={data.classList.accordionLabel}>
                Privileges Required (PR)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V3-PR'}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="PR:3.1"
                      options={cvss_3_1_options.privilegesRequiredOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '3.1',
                        cvss_3_1_options.privilegesRequiredOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec3.PR.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec3.PR.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
    // userInteractionOptions}
    // User Interaction (UI)
    {
      key: 'UI',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V3-UI'}
            onChange={data.handleAccordionChange('V3-UI')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainer}>
              <span className={data.classList.accordionLabel}>
                User Interaction (UI)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V3-UI'}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="UI:3.1"
                      options={cvss_3_1_options.userInteractionOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '3.1',
                        cvss_3_1_options.userInteractionOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec3.UI.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec3.UI.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
  ],
  scope: [
    {
      key: 'S',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V3-S'}
            onChange={data.handleAccordionChange('V3-S')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainer}>
              <span className={data.classList.accordionLabel}>Scope (S)</span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V3-S'}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="S:3.1"
                      options={cvss_3_1_options.scopeOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '3.1',
                        cvss_3_1_options.scopeOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec3.S.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec3.S.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
  ],
  impact: [
    // confidentialityOptions
    // Confidentiality (C)
    {
      key: 'C',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V3-C'}
            onChange={data.handleAccordionChange('V3-C')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainer}>
              <span className={data.classList.accordionLabel}>
                Confidentiality (C)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V3-C'}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="C:3.1"
                      options={cvss_3_1_options.confidentialityOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '3.1',
                        cvss_3_1_options.confidentialityOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec3.C.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec3.C.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
    // options={cvss_3_1_options.integrityOptions}
    {
      key: 'I',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V3-I'}
            onChange={data.handleAccordionChange('V3-I')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainer}>
              <span className={data.classList.accordionLabel}>
                Integrity (I)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V3-I'}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="I:3.1"
                      options={cvss_3_1_options.integrityOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '3.1',
                        cvss_3_1_options.integrityOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec3.I.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec3.I.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
    {
      // options={cvss_3_1_options.availabilityOptions}
      key: 'A',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V3-A'}
            onChange={data.handleAccordionChange('V3-A')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainer}>
              <span className={data.classList.accordionLabel}>
                Availability (A)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V3-A'}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="A:3.1"
                      options={cvss_3_1_options.availabilityOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '3.1',
                        cvss_3_1_options.availabilityOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec3.A.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec3.A.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
  ],
  temporal: [
    {
      // exploitCodeMaturityOptions
      key: 'E',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V3-E'}
            onChange={data.handleAccordionChange('V3-E')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainerExtended}>
              <span className={data.classList.accordionLabel}>
                Exploit Code Maturity (E)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V3-E'}
                aria-controls="panel1d-content"
                id="panel1d-header"
                classes={{ root: data.classList.accordionSummary }}
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="E:3.1"
                      options={cvss_3_1_options.exploitCodeMaturityOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '3.1',
                        cvss_3_1_options.exploitCodeMaturityOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec3.E.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec3.E.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
    // remediationLevelOptions
    {
      key: 'RL',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V3-RL'}
            onChange={data.handleAccordionChange('V3-RL')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainerExtended}>
              <span className={data.classList.accordionLabel}>
                Remediation Level (RL)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V3-RL'}
                aria-controls="panel1d-content"
                id="panel1d-header"
                classes={{ root: data.classList.accordionSummary }}
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="RL:3.1"
                      options={cvss_3_1_options.remediationLevelOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '3.1',
                        cvss_3_1_options.remediationLevelOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec3.RL.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec3.RL.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
    // reportConfidenceOptions}
    {
      key: 'RC',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V3-RC'}
            onChange={data.handleAccordionChange('V3-RC')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainerExtended}>
              <span className={data.classList.accordionLabel}>
                Report Confidence (RC)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V3-RC'}
                aria-controls="panel1d-content"
                id="panel1d-header"
                classes={{ root: data.classList.accordionSummary }}
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="RC:3.1"
                      options={cvss_3_1_options.reportConfidenceOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '3.1',
                        cvss_3_1_options.reportConfidenceOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec3.RC.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec3.RC.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
  ],
  environmental: [
    {
      // confidentialityRequirementOptions}
      key: 'CR',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V3-CR'}
            onChange={data.handleAccordionChange('V3-CR')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainer}>
              <span className={data.classList.accordionLabel}>
                Confidentiality Requirement (CR)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V3-CR'}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="CR:3.1"
                      options={
                        cvss_3_1_options.confidentialityRequirementOptions
                      }
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '3.1',
                        cvss_3_1_options.confidentialityRequirementOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec3.CR.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec3.CR.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
    // integrityRequirementOptions}
    {
      key: 'IR',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V3-IR'}
            onChange={data.handleAccordionChange('V3-IR')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainer}>
              <span className={data.classList.accordionLabel}>
                Integrity Requirement (IR)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V3-IR'}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="IR:3.1"
                      options={cvss_3_1_options.integrityRequirementOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '3.1',
                        cvss_3_1_options.integrityRequirementOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec3.IR.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec3.IR.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
    {
      // availabilityRequirementOptions}
      key: 'AR',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V3-AR'}
            onChange={data.handleAccordionChange('V3-AR')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainer}>
              <span className={data.classList.accordionLabel}>
                Availability Requirement (AR)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V3-AR'}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="AR:3.1"
                      options={cvss_3_1_options.availabilityRequirementOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '3.1',
                        cvss_3_1_options.availabilityRequirementOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec3.AR.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec3.AR.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
  ],
  modified: [
    // modifiedAttackVectorOptions}
    {
      key: 'MAV',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V3-MAV'}
            onChange={data.handleAccordionChange('V3-MAV')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainer}>
              <span className={data.classList.accordionLabel}>
                Modified Attack Vector (MAV)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V3-MAV'}
                aria-controls="panel1d-content"
                id="panel1d-header"
                classes={{ root: data.classList.accordionSummary }}
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="MAV:3.1"
                      options={cvss_3_1_options.modifiedAttackVectorOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '3.1',
                        cvss_3_1_options.modifiedAttackVectorOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec3.MAV.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec3.MAV.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },

    {
      key: 'MAC',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V3-MAC'}
            onChange={data.handleAccordionChange('V3-MAC')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainer}>
              <span className={data.classList.accordionLabel}>
                Modified Attack Complexity (MAC)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V3-MAC'}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="MAC:3.1"
                      options={cvss_3_1_options.modifiedAttackComplexityOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '3.1',
                        cvss_3_1_options.modifiedAttackComplexityOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec3.MAC.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec3.MAC.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
    {
      // modifiedPrivilegesRequiredOptions}
      key: 'MPR',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V3-MPR'}
            onChange={data.handleAccordionChange('V3-MPR')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainer}>
              <span className={data.classList.accordionLabel}>
                Modified Privileges Required (MPR)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V3-MPR'}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="MPR:3.1"
                      options={
                        cvss_3_1_options.modifiedPrivilegesRequiredOptions
                      }
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '3.1',
                        cvss_3_1_options.modifiedPrivilegesRequiredOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec3.MPR.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec3.MPR.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
    {
      key: 'MUI',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V3-MUI'}
            onChange={data.handleAccordionChange('V3-MUI')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainer}>
              <span className={data.classList.accordionLabel}>
                Modified User Interaction (MUI)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V3-MUI'}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="MUI:3.1"
                      options={cvss_3_1_options.modifiedUserInteractionOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '3.1',
                        cvss_3_1_options.modifiedUserInteractionOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec3.MUI.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec3.MUI.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
    {
      // modifiedScopeOptions}
      key: 'MS',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V3-MS'}
            onChange={data.handleAccordionChange('V3-MS')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainer}>
              <span className={data.classList.accordionLabel}>
                Modified Scope (MS)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V3-MS'}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="MS:3.1"
                      options={cvss_3_1_options.modifiedScopeOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '3.1',
                        cvss_3_1_options.modifiedScopeOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec3.MS.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec3.MS.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
    {
      key: 'MC',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V3-MC'}
            onChange={data.handleAccordionChange('V3-MC')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainer}>
              <span className={data.classList.accordionLabel}>
                Modified Confidentiality (MC)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V3-MC'}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="MC:3.1"
                      options={cvss_3_1_options.modifiedConfidentialityOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '3.1',
                        cvss_3_1_options.modifiedConfidentialityOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec3.MC.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec3.MC.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },

    // modifiedIntegrityOptions
    {
      key: 'MI',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V3-MI'}
            onChange={data.handleAccordionChange('V3-MI')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainer}>
              <span className={data.classList.accordionLabel}>
                Modified Integrity (MI)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V3-MI'}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="MI:3.1"
                      options={cvss_3_1_options.modifiedIntegrityOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '3.1',
                        cvss_3_1_options.modifiedIntegrityOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec3.MI.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec3.MI.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
    // modifiedAvailabilityOptions}
    {
      key: 'MA',
      label: '',
      fetch: (value, data) => {
        return (
          <Accordion
            square
            expanded={data.expanded === 'V3-MA'}
            onChange={data.handleAccordionChange('V3-MA')}
            classes={{ root: data.classList.accordionRoot }}
          >
            <div className={data.classList.accordionContainer}>
              <span className={data.classList.accordionLabel}>
                Modified Availability (MA)
              </span>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                expanded={data.expanded === 'V3-MA'}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <CVSSRadioControl
                      name="MA:3.1"
                      options={cvss_3_1_options.modifiedAvailabilityOptions}
                      handleChange={data.handleScoreChange}
                      value={getValue(
                        '3.1',
                        cvss_3_1_options.modifiedAvailabilityOptions,
                        value
                      )}
                    />
                  }
                />
              </AccordionSummary>
            </div>
            <AccordionDetails
              classes={{ root: data.classList.accordionDetails }}
            >
              <div className={data.classList.dropdownText}>
                <span className={data.classList.optionDesc}>
                  {spec3.MA.description}
                </span>

                <span className={data.classList.optionDesc}>
                  {spec3.MA.options[value]}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      },
    },
  ],
};
