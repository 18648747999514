import { FormControlLabelProps as IMUIFormControlLabelProps } from '@material-ui/core';
import Popper, { PopperProps } from '@material-ui/core/Popper';
import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';
import { deployDateAtom } from 'atoms/policies';
import cc from 'classcat';
import InputError from 'components/UI/input/InputError';
import TextField from 'components/UI/input/TextField';
import useTextFieldStyles from 'components/UI/input/TextField/useStyles';
import LastDeployedBox from 'components/main/policies/common/LastDeployedBox';
import { ITeam } from 'components/modals/GroupsCreateEdit/models';
import { FieldProps as IFormikFieldProps } from 'formik';
import React from 'react';
import { useRecoilValue } from 'recoil';
import useStyles from './useStyles';
import { useTranslation } from 'react-i18next';

export interface ISelectItem<T = number | string | null> {
  accountBounded?: boolean;
  label: string;
  value: T;
  team?: ITeam | null | undefined;
  created?: string | Date;
  connection?: {
    id: string;
  };
}

export interface ISelectProps extends Partial<IFormikFieldProps> {
  clearOnBlur?: boolean;
  disabled?: boolean;
  defaultValue?: any;
  disableClearable?: boolean;
  disablePortal?: boolean;
  disableCloseOnSelect?: boolean;
  inputValue?: string;

  // Ensures setFieldValue is called when not being used
  // in a formik form
  interactable?: boolean;
  freeSolo?: boolean;
  multiple?: boolean;
  label?: IMUIFormControlLabelProps | string;
  name?: string;
  placeholder?: string;
  onInputChange?: (
    e: React.ChangeEvent<{}>,
    valuse: string,
    reason: string
  ) => void;
  options?: ISelectItem[];
  customOption?: React.FC<ISelectItem>;
  renderOption?: (
    option: ISelectItem,
    state: { selected: boolean }
  ) => JSX.Element;
  renderPopper?: (props: PopperProps) => JSX.Element;
  setFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  value?: ISelectItem;
  variant?: 'large';
  StartAdornment?: React.FC<ISelectItem | undefined>;
  EndAdornment?: React.FC<string | Date>;
  className?: string;
}

const Select: React.FC<ISelectProps> = ({
  disabled,
  defaultValue,
  disableClearable = false,
  disablePortal = true,
  interactable,
  field,
  form,
  freeSolo = false,
  multiple = false,
  label,
  name,
  options,
  placeholder = 'GLOBAL.SELECT',
  renderPopper: customRenderPopper,
  customOption: CustomOption,
  setFieldValue = () => {},
  value,
  variant,
  StartAdornment,
  EndAdornment,
  ...rest
}) => {
  const classes = useStyles();
  const textFieldClasses = useTextFieldStyles();
  const deployDate = useRecoilValue(deployDateAtom);
  const { t } = useTranslation();

  const popperModifiers = {
    flip: {
      enabled: false,
    },
    preventOverflow: {
      enabled: false,
    },
    hide: {
      enabled: false,
    },
  };

  const renderPopper = (props: PopperProps) => (
    <Popper
      disablePortal
      modifiers={popperModifiers}
      placement="bottom"
      {...props}
    />
  );

  const handleChange = (e: React.ChangeEvent<{}>, value: unknown) => {
    e.stopPropagation();
    e.preventDefault();
    (!!field?.name || interactable) && setFieldValue(field?.name ?? '', value);
  };

  const customRenderInput = (props: AutocompleteRenderInputParams) => {
    return (
      <TextField
        onClick={(e) => {
          e.stopPropagation();
        }}
        classes={{
          root: cc([
            textFieldClasses.root,
            classes.textFieldRoot,
            { [classes.large]: variant === 'large' },
          ]),
        }}
        label={label}
        name={'nnnn'}
        placeholder={t(placeholder)}
        variant="outlined"
        {...props}
        InputProps={{
          ...props.InputProps,
          startAdornment: StartAdornment ? (
            value ? (
              <StartAdornment {...{ ...value, showLabel: false }} />
            ) : (
              StartAdornment
            )
          ) : undefined,
          endAdornment: EndAdornment ? (
            <LastDeployedBox lastDeployedTime={deployDate} />
          ) : (
            props.InputProps.endAdornment
          ),
        }}
      />
    );
  };

  const renderInput = (props: AutocompleteRenderInputParams) => {
    return (
      <TextField
        classes={{
          root: cc([
            textFieldClasses.root,
            classes.textFieldRoot,
            { [classes.large]: variant === 'large' },
          ]),
        }}
        label={label}
        name={name}
        placeholder={placeholder}
        variant="outlined"
        {...props}
      />
    );
  };

  //only value that stay in the state
  return (
    <>
      <Autocomplete
        autoHighlight
        disabled={disabled}
        // Due to AutoComplete CSS API, when an alternative styling
        // is needed for Popper, we must completely remap
        // the popper class versus a variable class assignment
        // at the popper level (renderPopper) above [which
        // would be ignored by Autocomplete/Popper]
        classes={
          // i.e.:
          // variant === 'large'
          //  ? { ...classes, popper: classes.popperLarge }
          //  : classes
          classes
        }
        disablePortal={disablePortal}
        defaultValue={defaultValue}
        disableClearable={disableClearable}
        getOptionLabel={({ label }) => label}
        openOnFocus
        multiple={multiple}
        freeSolo={freeSolo}
        options={options as ISelectItem[]}
        PopperComponent={customRenderPopper ?? renderPopper}
        renderOption={
          CustomOption
            ? (option: ISelectItem) => <CustomOption {...option} />
            : undefined
        }
        renderInput={customRenderInput ?? renderInput}
        selectOnFocus
        value={value}
        {...field}
        {...rest}
        onChange={handleChange}
      />
      <InputError form={form} field={field} />
    </>
  );
};

export default Select;
