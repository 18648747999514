import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import FileDownloadIcon from '@material-ui/icons/SaveAlt';
import blueGrey from '@material-ui/core/colors/blueGrey';
import Typography from '@material-ui/core/Typography';

const styles = ({ palette }) => ({
  downloadCard: {
    background: palette.background.default,
    marginTop: '8px',
    display: 'flex',
    alignItems: 'space-around',
    justifyContent: 'space-between',
    minHeight: '75px',
    flexDirection: 'row',
    maxWidth: '40vw',
  },
  downloadContentWrapper: {
    display: 'flex',
    minWidth: '30vw',
    flexWrap: 'wrap',
    minHeight: '100%',
  },
  cardHeaderRoot: {
    order: 1,
    minWidth: '200px',
    flex: 1,
    paddingBottom: 0,
    paddingTop: 10,
  },
  action: {
    margin: 0,
  },
  downloadDescription: {
    order: 2,
    color: blueGrey[500],
    alignSelf: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    flexBasis: '35vw',
    justifyContent: 'flex-start',
    fontSize: '11px',
    fontWeight: '500',
    flex: 1,
    paddingLeft: 32,
    paddingRight: 10,
    paddingBottom: 5,
  },
  buttonText: {
    textTransform: 'none',
    order: 3,
    flex: 1,
    flexBasis: '15vw',
    minHeight: '100%',
    margin: 0,
  },
});

class DownloadCard extends Component {
  render() {
    const {
      classes, text, callFetchDownload, icon, description,
    } = this.props;

    return (
      <Card className={`${classes.downloadCard} ${'download-card-mobile'}`}>
        <div className={classes.downloadContentWrapper}>
          <CardHeader
            classes={{
              root: classes.cardHeaderRoot,
              action: classes.action,
            }}
            subheader={text}
            className="cardheader-root-mobile"
            avatar={icon}
          />
          <div
            className={`${
              classes.downloadDescription
            } ${'download-card-mobile-description'}`}
          >
            <Typography type="body1" className={classes.downloadDescription}>
              {description}
            </Typography>
          </div>
        </div>
        <div className="buttonWrapper">
          <Button
            variant="raised"
            size="medium"
            onClick={callFetchDownload}
            // classes={{ root: classes.buttonText }}
            className={`${classes.buttonText} ${'download-card-mobile-button'}`}
            color="primary"
          >
            <FileDownloadIcon /> Download
          </Button>
        </div>
      </Card>
    );
  }
}

export default withStyles(styles)(DownloadCard);
