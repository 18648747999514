import React from 'react';
import MUITableRowsIcon from '@material-ui/icons/ViewDay';
import MUICardViewIcon from '@material-ui/icons/Apps';
import useStyles from './useStyles';

interface ILayoutViewToggleProps {
  children: React.ReactNode;
  buttonComp: React.ReactNode;
  handleToggleView: (view: EVIEW_TYPES_ENUM) => void;
  view: EVIEW_TYPES_ENUM;
  title?: React.ReactNode;
}

export enum EVIEW_TYPES_ENUM {
  Card = 'Card',
  Table = 'Table'
}

const LayoutViewToggle: React.FC<ILayoutViewToggleProps> = ({ children, buttonComp, handleToggleView, view, title }) => {
  const classes = useStyles();
  const ActionButton = buttonComp;

  return (
    <>
      <div className={classes.viewHeader}>
        <h1>{title ?? ''}</h1>
        <div className={classes.containerActionsWrapper}>{!!ActionButton && ActionButton}
          <MUITableRowsIcon
            color={view === EVIEW_TYPES_ENUM.Table ? "primary" : "inherit"}
            classes={{ root: classes.icon }}
            onClick={() => handleToggleView(EVIEW_TYPES_ENUM.Table)}
          />
          <MUICardViewIcon
            classes={{ root: classes.icon }}
            color={view === EVIEW_TYPES_ENUM.Card ? "primary" : "inherit"}
            onClick={() => handleToggleView(EVIEW_TYPES_ENUM.Card)}
          />
        </div>
      </div>

      {children}
    </>
  );
};

export default LayoutViewToggle;