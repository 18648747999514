import _ from 'lodash';
import React, { Fragment } from 'react';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Security from '@material-ui/icons/Security';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from '@material-ui/core/Button';
import ContentCopyIcon from '@material-ui/icons/FilterNone';
import GlobalIndicator from 'components/main/common/GlobalIndicatorWrapper';

import { editModalConfig } from 'components/modals/AppsCreateEdit';
import { Checkbox } from 'components/inputs';
import momentHelper from 'utils/momentUtils';
import ProtectedComponent from '../components/main/protected/ProtectedComponent';

const handleChange = (rowData, handleChangeProp) => {
  if (!_.isFunction(handleChangeProp)) {
    return null;
  }
  return () => handleChangeProp(rowData);
};

export const inAppsMapping = [
  {
    path: 'name',
    columnContent: (appId, { rowData, rest }) => {
      return (
        <span onClick={handleChange(rowData, rest.handleSelectedAppVersion)}>
          {rowData.name}
        </span>
      );
    },
  },
  {
    path: 'bundleId',
  },
  {
    path: 'description',
  },
  {
    path: 'team.name', // team',
  },
  {
    path: 'groupName',
    columnContent: (appId, { rowData, rest }) => {
      const globalTeamFlag = _.get(
        rest.groupsHash,
        `${rowData.groupId}.team.name`,
        false
      );

      return (
        <GlobalIndicator isGlobal={!globalTeamFlag} text={rowData.groupName} />
      );
    },
  },
  {
    path: 'licenseJwt',
    columnContent: (licenseJwt, { classList, rest }) => {
      return (
        <Fragment>
          <CopyToClipboard text={licenseJwt}>
            <Button
              classes={{ root: classList.copyButton }}
              onClick={rest.handleCopyClipBoardSnackbar}
            >
              {licenseJwt || ''}
            </Button>
          </CopyToClipboard>
          <CopyToClipboard text={licenseJwt}>
            <Button
              classes={{ root: classList.copyIconButton }}
              onClick={rest.handleCopyClipBoardSnackbar}
            >
              <ContentCopyIcon className={classList.copyIcon} />
            </Button>
          </CopyToClipboard>
        </Fragment>
      );
    },
  },
  {
    path: 'created',
    columnContent: created => momentHelper(created),
  },
  {
    path: 'modified',
    columnContent: modified => momentHelper(modified),
  },
  {
    path: 'activationLimit',
  },
  {
    path: 'devModeEnabled',
    columnContent: value => {
      return <Checkbox style={{ padding: 0 }} disabled checked={value} />;
    },
  },
  {
    path: 'actions',
    columnContent: (appId, { rowData, classList, rest }) => {
      return (
        <ProtectedComponent allow={{ zapps: 'manage' }}>
          <div className="flex--spaced">
            <Edit
              className={classList.editIcon}
              onClick={rest.toggleModalDiffered(
                'AppsCreateEdit',
                rowData,
                editModalConfig
              )}
            />
            <Delete
              className={classList.editIcon}
              onClick={rest.toggleModalDiffered('AppsDeleteConfirm', rowData)}
            />
          </div>
        </ProtectedComponent>
      );
    },
  },
  // filler
  {
    path: '',
  },
];
