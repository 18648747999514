import withRouter from 'components/hocs/withRouter';
import Table from 'components/UI/Table';
import { IColumnHeader, ILocationQuery } from 'components/UI/Table/models';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  buildsTableColumnChange,
  updateUISettings,
} from 'reducers/UiSettingsReducers';
import {
  getUISettingsTableHeaders,
  getUISettingsWithout,
} from 'reducers/UiSettingsSelectors';
import { bindActionCreators, compose } from 'redux';
import useBackfill from 'utils/useBackfill';
import { withDirtyState } from 'utils/withDirtyState';
import withMemoizedProps from 'utils/withMemoizedProps';
import { zScanAssessmentsColumnMapping } from './zScanAssessment.mappings';
import { getActiveModalAtom } from 'atoms/modals';
import { useSetRecoilState } from 'recoil';
import { ILocation, IQueryParams, IZScanAssessment } from './models';
import tableQuery from './tableQuery';
import useStyles from './useStyles';
import ProtectedComponent from '../../protected/ProtectedComponent';
import Button from 'components/UI/Button';
import { openSnackBar, toggleModalDirect } from 'utils/storeUtils';

import { Link } from 'react-router-dom';
import useAppDetails from './useAppDetails';
import { useTranslation } from 'react-i18next';
import SearchBox from 'components/UI/input/SearchBox';
import MUICard from '@material-ui/core/Card';
import GenericCard from 'components/UI/GenericCard';
import TableActions from '../TableActions';
import { TableAction } from '../TableAction';
import UploadAppButton from '../UploadAppButton';
import ZScanUploadApp from 'components/modals/ZScanUploadApp';
import ZScanUploadComplete from 'components/modals/ZScanUploadComplete';
import ZScanCompareAssessments, {
  ZScanCompareAssessments_TITLE,
} from '../ZScanCompareAssessments';
import { SelectedRowProps } from '../ZScanCompareAssessments/models';
import PlatformIcon from '../PlatformIcon';
import { deleteSelectedZScanAssessment } from 'api/zDevService';
import { publishEvent } from 'utils/eventUtils';
import { appTeamId } from '../atoms/appTeamId';

interface IZScanAssessmentsProps {
  location: ILocation;
  buildsTableColumnChange: (...args: unknown[]) => void;
  buildsUiSettings: { [key: string]: unknown };
  currentTableHeaders: IColumnHeader[];
  definedUser?: string;
  jiggleDirtyState: (...args: any) => void;
  q: { [key: string]: unknown };
  rqps: string[];
  scopes: { [key: string]: string[] };
  updateUISettings: (...args: unknown[]) => void;
  updateUrl: (params: IQueryParams) => void;
  scopeBounds: string;
}
const activeModalAtom = getActiveModalAtom<IZScanAssessment>();

const ZScanAssessments: React.FC<IZScanAssessmentsProps> = ({
  buildsTableColumnChange,
  buildsUiSettings,
  currentTableHeaders,
  definedUser,
  jiggleDirtyState,
  q: query,
  location,
  rqps,
  updateUISettings,
  updateUrl,
  // scopeBounds,
}) => {
  useBackfill({
    query,
    requiredQueryParams: rqps,
    storedQueryParams: buildsUiSettings,
    updateUrl,
  });
  const [selectedRow, setSelectedRow] = useState<SelectedRowProps[]>([]);

  const [selectedTableAction, setSelectedTableAction] = useState<TableAction>();
  const { ready, t } = useTranslation();
  const setActiveModal = useSetRecoilState(activeModalAtom);
  const appId: string | unknown = query['appId.keyword'];
  const { appDetails } = useAppDetails(appId);
  const classes = useStyles();
  const handleColumnChange = (...args: unknown[]) => {
    buildsTableColumnChange(...args);
    jiggleDirtyState();
  };
  const onGoClick = useCallback((): void => {
    switch (selectedTableAction) {
      case TableAction.DeleteSelected: {
        const mapId = selectedRow.map((e) => e.id).toString();
        deleteSelectedZScanAssessment({
          assessmentId: mapId,
        })
          .then(() => {
            publishEvent('table:force-fetch-buildsApps');
            openSnackBar(`Your selected assessment was successfully deleted`);
          })
          .catch(() => {
            openSnackBar(`Deleted Failed`);
          });

        setSelectedTableAction(undefined);
        break;
      }
      default:
        break;
    }
  }, [selectedRow, selectedTableAction]);

  const handleSelectionChange = useCallback(
    (selectedRows: SelectedRowProps[]) => {
      setSelectedRow(Object.values(selectedRows));
      // if two builds are slected enable compare builds to be selected
    },
    []
  );
  const handleCompareBuilds = useCallback(() => {
    updateUrl({ orderBy: 'severityOrdinal', order: 'desc', cbo: 'combined' });
    if (selectedRow && selectedRow.length === 2) {
      setActiveModal({
        active: ZScanCompareAssessments_TITLE,
      });
    } else {
      toggleModalDirect('CompareBuildsSelectTwo');
    }
  }, [selectedRow, setActiveModal, updateUrl]);

  const onPaginationChange = useCallback(
    (update: IQueryParams) => {
      updateUISettings({
        domain: 'zScanAssessments',
        update,
      });

      updateUrl(update);
    },
    [updateUrl, updateUISettings]
  );

  const handleSearch = useCallback(
    (value: string) => {
      updateUrl({ search: value ?? '' });
    },
    [updateUrl]
  );

  const setSelectedTeamId = useSetRecoilState(appTeamId);

  useEffect(() => {
    setSelectedTeamId(appDetails?.teamId ?? '');
  }, [appDetails?.teamId, setSelectedTeamId]);

  if (!ready) {
    return null;
  }
  return (
    <>
      <div className="view__header">
        {' '}
        <div className={classes.titleHeader}>
          <PlatformIcon iconType={appDetails?.platform?.toLowerCase() ?? ''} />
          <div>
            <h1>{appDetails?.name} - Assessments</h1>
          </div>
        </div>
        <div style={{ display: 'flex', gap: '10px' }}>
          <ProtectedComponent allow={{ zdev_builds: 'upload' }}>
            <UploadAppButton buttonText="UPLOAD BUILD" />
          </ProtectedComponent>
          <Link to="/console/zscan/apps">
            <Button text="GO BACK TO MY APPS" color="secondary" />
          </Link>
          <Button
            text="COMPARE BUILDS"
            color="secondary"
            disabled={selectedRow?.length !== 2}
            onClick={handleCompareBuilds}
          />
        </div>
      </div>
      <GenericCard noPadding>
        <div className={classes.mainContainer}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span className={classes.packageIdStyle}>
              Package ID : {appDetails?.bundleId}
            </span>
            <TableActions
              setSelectedTableAction={setSelectedTableAction}
              selectedTableAction={selectedTableAction}
              onGoClick={onGoClick}
            />
          </div>

          <MUICard>
            <SearchBox onInputChange={handleSearch} />
          </MUICard>

          <Table
            serverSort={false}
            classList={classes}
            columnHeaders={currentTableHeaders}
            definedUser={definedUser}
            fetchTableData={() =>
              tableQuery()(location.query as unknown as ILocationQuery)
            }
            onColumnChange={handleColumnChange}
            onPaginationChange={onPaginationChange}
            query={query as unknown as ILocationQuery}
            rowMapping={zScanAssessmentsColumnMapping(t)}
            tableId="zScanAssessments"
            heightBuffer={290}
            checkboxColumn
            onSelectionChange={handleSelectionChange}
          />

          {!!selectedRow && selectedRow.length === 2 && (
            <ZScanCompareAssessments
              appDetails={appDetails}
              selectedRow={selectedRow}
            />
          )}

          <ZScanUploadApp />
          <ZScanUploadComplete />
        </div>
      </GenericCard>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  buildsUiSettings: getUISettingsWithout(state, 'zScanAssessments', [
    'tableHeaders',
  ]),
  currentTableHeaders: getUISettingsTableHeaders(state, 'zScanAssessments'),
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      buildsTableColumnChange,
      updateUISettings,
    },
    dispatch
  );

export default compose(
  withRouter,
  withDirtyState(),
  connect(mapStateToProps, mapDispatchToProps)
)(
  withMemoizedProps(ZScanAssessments, [
    'currentTableHeaders',
    'dirtyState',
    'q',
    'updateUISettings',
    'updateUrl',
  ])
);
