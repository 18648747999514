import { EPartnerBrandingDefaults, IPartnerBranding } from './models';
import { shallowEqual, useSelector } from 'react-redux';

const safeBrandingString = (
  item: string | null | undefined,
  fallback: string
): string => (item?.trim()?.length ? item : fallback);

export const useSafeBranding = (): IPartnerBranding => {
  const branding = useSelector(
    ({ auth }: { auth: { branding: IPartnerBranding; }; }) => auth.branding, shallowEqual
  );

  return {
    ...branding,
    consoleTitle: safeBrandingString(
      branding?.consoleTitle,
      EPartnerBrandingDefaults.consoleTitle
    ),
    mtdModuleText: safeBrandingString(
      branding?.mtdModuleText,
      EPartnerBrandingDefaults.mtdModuleText
    ),
    privacyPolicyLink: safeBrandingString(
      branding?.privacyPolicyLink,
      EPartnerBrandingDefaults.privacyPolicyLink
    ),
    termsAndConditionsLink: safeBrandingString(
      branding?.termsAndConditionsLink,
      EPartnerBrandingDefaults.termsAndConditionsLink
    ),
    zdefendModuleText: safeBrandingString(
      branding?.zdefendModuleText,
      EPartnerBrandingDefaults.zdefendModuleText
    ),
    zscanModuleText: safeBrandingString(
      branding?.zscanModuleText,
      EPartnerBrandingDefaults.zscanModuleText
    ),
    zshieldModuleText: safeBrandingString(
      branding?.zshieldModuleText,
      EPartnerBrandingDefaults.zshieldModuleText
    ),
  };
};
