import withRouter from 'components/hocs/withRouter';
import Select from 'components/UI/input/Select';
import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  getAvailableTeams,
  getAvailableTeamsAsSelectList,
} from 'reducers/TeamReducers';
import {
  clearSelectedApps,
  getSelectedTeam,
  updateSelectedTeam,
} from 'reducers/UiSettingsReducers';
import { bindActionCreators } from 'redux';
import {
  selectedTeam as selectedTeamAtom,
  availableTeams as availableTeamsAtom,
} from '../../../../atoms/teams';
import useStyles from './useStyles';

interface TeamSelection {
  value: string | null;
  label: string;
  name?: string;
}

interface IGlobalTeamSelectorProps {
  selectedTeam: string;
  location: {
    pathname: string;
    query: {
      teamId: string;
      zappId?: string;
    };
  };
  updateSelectedTeam: (update: any) => any;
  updateUrl: (update: any) => any;
  availableTeamsHash: { [key: string]: TeamSelection };
  availableTeams: TeamSelection[];
}

const GlobalTeamSelector: React.FC<IGlobalTeamSelectorProps> = ({
  location,
  selectedTeam,
  updateSelectedTeam,
  updateUrl,
  availableTeamsHash,
  availableTeams,
}) => {
  const selectedTeamFromRecoil = useRecoilValue(selectedTeamAtom);
  const setSelectedTeamInRecoil = useSetRecoilState(selectedTeamAtom);
  const setAvailableTeamsInRecoil = useSetRecoilState(availableTeamsAtom);
  const classes = useStyles();

  // Ensure this Atom persists (relying on Redux' Persist until we add recoil-persist)
  useEffect(() => {
    if (!selectedTeamFromRecoil && selectedTeamFromRecoil !== selectedTeam) {
      setSelectedTeamInRecoil(selectedTeam);
    }
  }, [selectedTeam, selectedTeamFromRecoil, setSelectedTeamInRecoil]);

  const handleSelect = useCallback(
    (name: string, value: TeamSelection) => {
      updateUrl({
        teamId: value.value,
        teamfilter: [value.value],
        zappId: 'All',
      });

      if (selectedTeam !== value.value) {
        updateSelectedTeam(value.value);

        // Parallel Recoil State
        setSelectedTeamInRecoil(value?.value);
      }
    },
    [selectedTeam, setSelectedTeamInRecoil, updateSelectedTeam, updateUrl]
  );

  useEffect(() => {
    // all is selected going into zDefend
    if (!availableTeams?.length) {
      return;
    }
    if (location?.pathname?.indexOf('/zdefend') > -1 && selectedTeam === null) {
      handleSelect('teams selector', availableTeams?.[0]);
    }
  }, [
    location?.pathname,
    location?.query?.teamId,
    selectedTeam,
    setSelectedTeamInRecoil,
    updateSelectedTeam,
    updateUrl,
    availableTeams,
    handleSelect,
  ]);

  useEffect(() => {
    // all is selected going into zDefend
    if (!availableTeams?.length) {
      return;
    }
    if (location?.pathname?.indexOf('/zshield') > -1 && selectedTeam === null) {
      handleSelect('teams selector', availableTeams?.[0]);
    }
  }, [
    location?.pathname,
    location?.query?.teamId,
    selectedTeam,
    setSelectedTeamInRecoil,
    updateSelectedTeam,
    updateUrl,
    availableTeams,
    handleSelect,
  ]);

  // Ensure this Atom persists (relying on Redux' Persist until we add recoil-persist)
  useEffect(() => {
    if (!selectedTeamFromRecoil && selectedTeamFromRecoil !== selectedTeam) {
      setSelectedTeamInRecoil(selectedTeam);
    }
  }, [selectedTeam, selectedTeamFromRecoil, setSelectedTeamInRecoil]);

  useEffect(() => {
    if (!location?.query?.teamId && selectedTeam) {
      updateUrl({
        teamId: selectedTeam,
        teamfilter: [selectedTeam],
        zappId: location?.query?.zappId ?? 'All',
      });

      if (!selectedTeam) {
        // Parallel Recoil State
        setSelectedTeamInRecoil(selectedTeam);

        updateSelectedTeam(selectedTeam);
      }
    }
  }, [
    // isGlobalTeamSelectorPresent,
    location?.query?.teamId,
    location?.query?.zappId,
    selectedTeam,
    setSelectedTeamInRecoil,
    updateSelectedTeam,
    updateUrl,
  ]);

  useEffect(() => {
    setAvailableTeamsInRecoil(availableTeams);
  }, [availableTeams, setAvailableTeamsInRecoil]);

  const teamObj = useMemo(
    () => availableTeamsHash[location?.query?.teamId || selectedTeam],
    [availableTeamsHash, location?.query?.teamId, selectedTeam]
  );

  const locallyAvailableTeams: TeamSelection[] = useMemo(() => {
    const allTeamsOption: TeamSelection = {
      value: null,
      label: 'All Teams',
    };
    return location?.pathname?.indexOf('/mtd') > -1 ||
      location?.pathname?.indexOf('/zscan') > -1
      ? [allTeamsOption, ...availableTeams]
      : availableTeams;
  }, [availableTeams, location?.pathname]);

  if (!locallyAvailableTeams.length) {
    return null;
  }

  let defaultValue = locallyAvailableTeams
    ? locallyAvailableTeams[0]
    : undefined;

  if (teamObj) {
    defaultValue = {
      value: location?.query?.teamId,
      label: teamObj?.name || '',
    };
  }

  return (
    <div className={classes.paddedContainer}>
      <Select
        disableClearable
        interactable
        placeholder="Select a Team"
        options={locallyAvailableTeams}
        setFieldValue={handleSelect}
        value={defaultValue}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    availableTeamsHash: getAvailableTeams(state),
    availableTeams: getAvailableTeamsAsSelectList(state),
    selectedTeam: getSelectedTeam(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      updateSelectedTeam,
      clearSelectedApps,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GlobalTeamSelector)
);
