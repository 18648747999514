import { ServiceHandlerFactory } from 'utils/serviceUtils';
import {
  groupsServiceMapping,
  groupsEmmConnectionServiceMapping,
} from '../components/main/policies/groups/groups.mappings';

export const fetchGroupsPage = ServiceHandlerFactory({
  axios: {
    url: 'api/groups/v1/groups/page?',
  },
  paramSchema: groupsServiceMapping,
});

export const fetchGroupsPageByEmmConnectionIds = ServiceHandlerFactory({
  axios: {
    url: 'api/groups/v1/groups/emm/page?',
  },
  paramSchema: groupsEmmConnectionServiceMapping,
});
// pass in module: 'MTD' to get all the groups in a mtd module
export const fetchGroupsByTeamId = ServiceHandlerFactory({
  axios: {
    url: 'api/groups/v1/groups',
  },
});

export const formGroupListForSelect = groupsRawData => {
  return groupsRawData.reduce((accum, value) => {
    return {
      ...accum,
      [value.id]: value,
    };
  }, {});
};

export const fetchAttachedZappsByGroupId = ServiceHandlerFactory({
  axios: {
    url: 'api/zapp/v1/zapps?groupId=:groupId',
  },
});

export const createGroup = ServiceHandlerFactory({
  axios: {
    url: 'api/groups/v1/groups',
    method: 'post',
  },
});

export const updateGroup = ServiceHandlerFactory({
  axios: {
    url: 'api/groups/v1/groups/:groupId',
    method: 'put',
  },
});

export const deleteSelectedGroup = ServiceHandlerFactory({
  axios: {
    url: 'api/groups/v1/groups/:groupId',
    method: 'delete',
  },
});
