import DialogContent from '@material-ui/core/DialogContent';
import { availableTeams as availableTeamsAtom } from 'atoms/teams';
import { useRecoilValue } from 'recoil';
import { ProtectionLabel, PROTECTION_COVERAGE_FIELDS, PROTECTION_COVERAGE_FIELDS_IOS } from 'components/main/zShieldApps/ProtectionCoverage/utils';
import Button from 'components/UI/Button';
import GenericCard from 'components/UI/GenericCard';
import Checkbox from 'components/UI/input/Checkbox';
import GenericGroup from 'components/UI/input/GenericGroup';
import InputError from 'components/UI/input/InputError';
import TextField from 'components/UI/input/TextField';
import { Field as FormikField, Form as FormikForm, Formik, FormikHelpers } from 'formik';
import React, { useCallback } from 'react';
import { toggleModalDirect } from 'utils/storeUtils';
import { initialValues } from './initialValues';
import { EZShieldDisclaimer, IFormSubmit, IProtectionCoverage } from './models';
import Schema from './schema';
import useStyles from './useStyles';
import Select, { ISelectItem } from 'components/UI/input/Select';
import AndroidIcon from '@material-ui/icons/Android';
import GenericError from 'components/UI/GenericErrorBox';
import AppleIcon from 'UI/Icons/AppleIcon';
import useGroupsByTeamId from '../useGroupsByTeamId';

// const fullProtectionCoverageFields = [...PROTECTION_COVERAGE_FIELDS_IOS, ...PROTECTION_COVERAGE_FIELDS];

interface IFormProps {
  data: Partial<IFormSubmit>;
  editMode: boolean;
  errorMessage: string;
  handleSubmit: (values: IFormSubmit, formikHelpers: FormikHelpers<IFormSubmit>) => void;
  resetModal: () => void;
}

const Form: React.FC<IFormProps> = ({
  data,
  // editMode,// this will eventually be an edit modal 
  errorMessage,
  handleSubmit,
}) => {
  const classes = useStyles();
  const availableTeams = useRecoilValue(availableTeamsAtom) || [];
  const [selectedTeam, setSelectedTeam] = React.useState<string | null>(availableTeams?.[0]?.value as string);
  const { availableGroups } = useGroupsByTeamId(selectedTeam ?? '');


  const isIOSPlatform = useCallback(() => data?.platform === 'IOS', [data?.platform]);

  const resolvedInitialValues = initialValues({ data, availableTeams, availableGroups });
  //sets the desired coverage filed based on os 
  const fullProtectionCoverageFields = isIOSPlatform() ? PROTECTION_COVERAGE_FIELDS_IOS : PROTECTION_COVERAGE_FIELDS;

  const handleCloseModal = () => {
    toggleModalDirect('ZShieldUploadApp', false);
  };

  const handleTeamChange = (field: string, value: ISelectItem, setFieldValue: (field: string, value?: string | null) => void) => {
    setSelectedTeam(value?.value as string);
    setFieldValue(field, value as unknown as string);
    // // when a new team is selected, wipe out the group(s)
    setFieldValue('group', null);
  };

  return (
    <>
      <div className={classes.header}>
        {isIOSPlatform() ? <div className={classes.titleIcon} > <AppleIcon color='white' /></div> :
          <AndroidIcon className={`${classes.titleIcon}`} />
        }
        <h1 className={classes.headerText}>{data?.name}</h1>
      </div>
      {isIOSPlatform() ? <div className={classes.backgroundCircleIOS} > <AppleIcon color='white' /></div> :
        <AndroidIcon className={`${classes.backgroundCircle}`} />
      }
      <div className={classes.osDisclaimer}>
        <GenericError
          errorMessage={isIOSPlatform() ? EZShieldDisclaimer.IOS : EZShieldDisclaimer.ANDROID}
          customClass={classes.errorInfoBox}
        />
      </div>
      <Formik
        enableReinitialize
        initialValues={resolvedInitialValues}
        onSubmit={handleSubmit}
        validateOnBlur
        validationSchema={Schema()}
      >
        {({
          errors,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          values,
        }) => {
          return (
            <>
              <FormikForm>
                <DialogContent style={{ paddingTop: 0 }}>
                  <>
                    <FormikField
                      color="primary"
                      component={TextField}
                      disabled
                      label="App Name"
                      name="name"
                    />
                    <div className={classes.bundleVersionWrapper}>
                      <div className={classes.appVersionField}>
                        <FormikField
                          color="primary"
                          component={TextField}
                          disabled
                          label="App Version"
                          name="version"
                        />
                      </div>
                      <div className={classes.bundleField}>
                        <FormikField
                          color="primary"
                          component={TextField}
                          disabled
                          label="Bundle ID"
                          name="bundleId"
                        />
                      </div>
                    </div>
                    <FormikField
                      color="primary"
                      component={TextField}
                      label="Description"
                      name="description"
                    />
                    {data?.teamId ?
                      <FormikField
                        color="primary"
                        component={TextField}
                        label="Team"
                        name="team"
                        disabled
                        value={values.team?.label}
                      /> :
                      <FormikField
                        component={Select}
                        disabled={data?.teamId}
                        label="Teams"
                        multiple={false}
                        name="team"
                        options={availableTeams}
                        setFieldValue={(field: string, value: ISelectItem) => handleTeamChange(field, value, setFieldValue)}
                        type="select"
                      />
                    }


                    {
                      data?.groupId ?

                        <FormikField
                          color="primary"
                          component={TextField}
                          label="Group"
                          name="group"
                          disabled
                          value={values.group?.label}
                        /> :
                        <FormikField
                          component={Select}
                          disabled={data?.groupId}
                          label="Group"
                          multiple={false}
                          name="group"
                          options={availableGroups}
                          setFieldValue={setFieldValue}
                          type="select"
                        />
                    }
                    <GenericCard title="protection coverage">
                      <GenericGroup>
                        <GenericError
                          errorMessage={!isIOSPlatform() ? EZShieldDisclaimer.STATIC : null}
                          customClass={classes.errorInfoBox}
                        />
                      </GenericGroup>
                      <div className={classes.twoColumnGroup}>
                        {fullProtectionCoverageFields.map(
                          ({
                            id,
                            fieldName,
                            displayName,
                            tooltip,
                          }: IProtectionCoverage) => {
                            return (
                              <div className={classes.protectionLabel}>
                                <FormikField
                                  color="primary"
                                  component={Checkbox}
                                  key={id}
                                  name={'protectionCoverages'}
                                  type="checkbox"
                                  value={fieldName}
                                />
                                {<ProtectionLabel displayName={displayName} tooltip={tooltip} />}
                              </div>
                            );
                          }
                        )}
                      </div>
                      {!!errors?.protectionCoverages && (
                        <InputError override="Must select at least one coverage option." />
                      )}
                    </GenericCard>
                  </>
                  <GenericError errorMessage={errorMessage} />
                  <div className={classes.buttonPanel}>
                    <Button
                      color="secondary"
                      disabled={isSubmitting}
                      onClick={handleCloseModal}
                      text="Cancel"
                    />
                    <Button
                      color="secondary"
                      type='submit'
                      onClick={() => handleSubmit}
                      text="Complete"
                    />
                  </div>

                </DialogContent>
              </FormikForm>
            </>
          );
        }}
      </Formik >
    </ >
  );
};

export default Form;
