import { withStyles } from '@material-ui/core/styles';
import withRouter from 'components/hocs/withRouter';
import _ from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import {
  getAvailableTeams, getAvailableTeamsAsSelectList
} from 'reducers/TeamReducers';
import { bindActionCreators, compose } from 'redux';
// ui
import AddIcon from '@material-ui/icons/Add';
import { fetchAllAppPolicies } from 'api/AppPolicyService';
import { fetchAllAppSettings } from 'api/AppSettingsService';
import { fetchAllPolicies as fetchAllBrandingPolicies } from 'api/BrandingPolicyService';
import { fetchAllPolicies as fetchAllOsRiskPolicies } from 'api/OSRiskService';
import {
  fetchGroupsPage,
  fetchGroupsPageByEmmConnectionIds
} from 'api/GroupService';
import { fetchConnections } from 'api/MDMService';
import { fetchAllPolicies as fetchAllNetworkPolicies } from 'api/NetworkPolicyService';
import { fetchAllPhishingPolicies } from 'api/PhishingPolicyService';
import { fetchAllPrivacyPoliciesList } from 'api/PrivacyPolicyService';
import { fetchAllTRMPoliciesList } from 'api/TRMPolicyService';
import GlobalIndicatorWrapper from 'components/main/common/GlobalIndicatorWrapper';
import { fetchPolicies as fetchAllDormancyPolicies } from 'components/main/policies/DormancyPolicy/utils';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import ProtectedMapping from 'components/main/protected/ProtectedMapping';
import { Table, TableColumnsContext } from 'components/Tables';
import { brandingColumn, groupsColumnMapping } from 'mappings/groupsMtdMapping';
import { selectIsBrandingSubscribed } from 'reducers/AuthReducers';
import {
  groupsMtdTableColumnChange, updateUISettings
} from 'reducers/UiSettingsReducers';
import {
  getChangedTableHeadersHash, getUISettingsTableHeaders, getUISettingsWithout
} from 'reducers/UiSettingsSelectors';
import ZButton from 'UI/Buttons/ZButton';
import {
  backfillUrl,
  reduceForMultiSelectWithGlobal
} from 'utils/componentUtils';
import { toggleModalDiffered } from 'utils/storeUtils';
import { withBackfill_DEPRECATED } from '../../../hocs/withBackfill';
import { GroupsFacetedSearch } from './GroupsFacetedSearch';
import { cleanGroupsTableData } from './utils';
import { withTranslation } from 'react-i18next';

class Groups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      trmPolicyList: [],
      privacyPolicyList: [],
      phishingPolicyList: [],
      appPolicyList: [],
      dormancyPolicyList: [],
      networkPolicyList: [],
      brandingPolicyList: [],
      osRiskPolicyList: [],
    };
    this.fetchTableData = this.fetchTableData.bind(this);
    this.onPaginationChange = this.onPaginationChange.bind(this);
  }

  componentDidMount() {
    backfillUrl(this.props, ['groupsUiSettings']);
    if (!_.isEmpty(this.props.location.query.size)) {
      this.setState({ loaded: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(this.props.location.query, prevProps.location.query) &&
      !_.isEmpty(this.props.location.query.size)
    ) {
      this.setState({ loaded: true });
    }
  }

  render() {
    const { props, state } = this;
    const { t } = this.props;

    if (!state.loaded) {
      return null;
    }

    // if (!props.location.query.teamId) {
    //   return null;
    // }
    // simple hack to remove filter based on modules
    const mtdModule = props.location.pathname.includes('mtd');

    const rowHandlers = {
      availableTeams: props.availableTeams,
      appSettingsList: state.appSettingsList,
      privacyPolicyList: state.privacyPolicyList,
      trmPolicyList: state.trmPolicyList,
      phishingPolicyList: state.phishingPolicyList,
      appPolicyList: state.appPolicyList,
      networkPolicyList: state.networkPolicyList,
      dormancyPolicyList: state.dormancyPolicyList,
      osRiskPolicyList: state.osRiskPolicyList,
      selectedTeamId: props.selectedTeam,
    };

    const formData = {
      appSettingsList: state.appSettingsList,
      privacyPolicyList: state.privacyPolicyList,
      trmPolicyList: state.trmPolicyList,
      phishingPolicyList: state.phishingPolicyList,
      appPolicyList: state.appPolicyList,
      networkPolicyList: state.networkPolicyList,
      dormancyPolicyList: state.dormancyPolicyList,
      osRiskPolicyList: state.osRiskPolicyList,
      availableTeams: props.availableTeams,
      mtdModule,
      selectedTeamId: props.selectedTeam,
      scopeBounds: props.scopeBounds,
    };

    let columnsHeaders = ProtectedMapping(props.currentTableHeaders);
    let rowMapping = ProtectedMapping(groupsColumnMapping);
    let columnHeadersHash = props.groupsColumnHeadersHash;

    if (props.isBrandingSubscribed) {
      rowHandlers['brandingPolicyList'] = state.brandingPolicyList;
      formData['brandingPolicyList'] = state.brandingPolicyList;

      if (!groupsColumnMapping.includes(brandingColumn)) {
        rowMapping.splice(10, 0, brandingColumn);
      }
    } else {
      columnsHeaders = columnsHeaders.filter(
        (h) => h.id !== 'brandingPolicyName'
      );
      const columnHeadersHashEntries = Object.entries(columnHeadersHash).filter(
        ([_, value]) => value.id !== 'brandingPolicyName'
      );
      columnHeadersHash = Object.fromEntries(columnHeadersHashEntries);
    }

    return (
      <div>
        <div className="view__header" style={{ marginBottom: 12 }}>
          <h1>{t('GLOBAL.GROUPS')}</h1>
          <ProtectedComponent allow={{ policies: 'manage' }}>
            <ZButton
              action={toggleModalDiffered('GroupsCreateEdit', formData, {
                title: t('MTD.POLICIES.GROUPS.CREATE_NEW_GROUP'),
                fullWidth: true,
              })}
              buttonText={t('MTD.POLICIES.GROUPS.ADD_NEW_GROUP')}
              color="secondary"
              styleName="titleBar"
              icon={{ elem: AddIcon }}
            />
          </ProtectedComponent>
        </div>

        <div style={{ marginBottom: 15 }}>
          <GlobalIndicatorWrapper inline isGlobal />
        </div>

        <div className={this.props.classes.emmConnectionsRoot}>
          {mtdModule && (
            <ProtectedComponent allow={{ emm: 'view' }}>
              <div className={this.props.classes.emmContent}>
                <GroupsFacetedSearch />
              </div>
            </ProtectedComponent>
          )}

          <div className={this.props.classes.tableRoot}>
            <TableColumnsContext.Provider
              value={{
                columnHeaders: columnsHeaders,
                columnHeadersHash: columnHeadersHash,
                rowMapping: rowMapping,
                fetchTableData: this.fetchTableData,
                onColumnChange: props.groupsMtdTableColumnChange,
                onChangePage: this.onPaginationChange,
                onChangeRows: this.onPaginationChange,
                onSort: this.onPaginationChange,
                rowHandlers: rowHandlers,
              }}
            >
              <Table tableId="Groups" fetchTableData={this.fetchTableData} />
            </TableColumnsContext.Provider>
          </div>
        </div>
      </div>
    );
  }

  async fetchTableData(query) {
    const { props } = this;
    const selectedTeamId = _.get(props, 'location.query.teamId', undefined);
    // trm / threat policies
    let trmPolicyList = [];
    try {
      const { data: trmPolicyRawList } = await fetchAllTRMPoliciesList(
        undefined,
        'ZIPS'
      );
      trmPolicyList = reduceForMultiSelectWithGlobal(trmPolicyRawList);
    } catch (e) {
      console.log('Error in Threat Policies: ', e);
    }
    // privacy policies
    let privacyPolicyList = [];
    try {
      const { data: privacyPolicyRawList } = await fetchAllPrivacyPoliciesList({
        module: 'ZIPS',
      });
      privacyPolicyList = reduceForMultiSelectWithGlobal(privacyPolicyRawList);
    } catch (e) {
      console.log('Error in Privacy Policy:', e);
    }
    // app settings
    let appSettingsList = [];
    try {
      const { data: appSettingsRawList } = await fetchAllAppSettings({
        module: 'ZIPS',
      });
      appSettingsList = reduceForMultiSelectWithGlobal(appSettingsRawList);
    } catch (e) {
      console.log('Error in App Policy:', e);
    }
    // phishing
    let phishingPolicyList = [];
    try {
      const { data: phishingPolicyRawList } = await fetchAllPhishingPolicies({
        module: 'ZIPS',
      });
      phishingPolicyList = reduceForMultiSelectWithGlobal(
        phishingPolicyRawList
      );
    } catch (e) {
      console.log('Error in Phishing Policy:', e);
    }
    // app policies
    let appPolicyList = [];
    try {
      const { data: appPolicyRawList } = await fetchAllAppPolicies({
        module: 'ZIPS',
      });
      appPolicyList = reduceForMultiSelectWithGlobal(appPolicyRawList);
    } catch (e) {
      console.log('Error in Policy Apps:', e);
    }
    // emm connection list
    let emmConnectionList = undefined;
    try {
      const { data: emmRawList } = await fetchConnections({
        accountId: props.accountId,
      });
      emmConnectionList = reduceForMultiSelectWithGlobal(emmRawList);
    } catch (e) {
      console.log('Error in Emm Connection:', e);
    }
    // network policies
    let networkPolicyList = [];
    try {
      const { data: networkPolicyRawList } = await fetchAllNetworkPolicies(
        selectedTeamId
      );
      networkPolicyList = reduceForMultiSelectWithGlobal(networkPolicyRawList);
    } catch (e) {
      console.log('Error in Policy Apps:', e);
    }
    // network policies
    let dormancyPolicyList = [];
    try {
      const dormancyPolicyRawList = await fetchAllDormancyPolicies(
        selectedTeamId
      );
      dormancyPolicyList = reduceForMultiSelectWithGlobal(
        dormancyPolicyRawList
      );
    } catch (e) {
      console.log('Error in Dormancy List:', e);
    }
    // branding policy
    let brandingPolicyList = [];
    if (props.isBrandingSubscribed) {
      try {
        const { data: brandingPolicyRawList } = await fetchAllBrandingPolicies(
          selectedTeamId
        );
        brandingPolicyList = reduceForMultiSelectWithGlobal(
          brandingPolicyRawList
        );
      } catch (e) {
        console.log('Error in Dormancy List:', e);
      }
    }
    // OS Risk
    let osRiskPolicyList = [];
    try {
      const { data: osRiskPolicyRawList } = await fetchAllOsRiskPolicies(
        selectedTeamId
      );
      osRiskPolicyList = reduceForMultiSelectWithGlobal(osRiskPolicyRawList);
    } catch (e) {
      console.log('Error in OS Risk Policy', e);
    }
    // check to see if emmconnectionId is in the query
    let groupsTableData = [];
    if (query.emmConnectionId) {
      try {
        groupsTableData = await fetchGroupsPageByEmmConnectionIds({ ...query });
      } catch (e) {
        console.log('Error in fetching gruops: ', e);
      }
    } else {
      try {
        groupsTableData = await fetchGroupsPage({ ...query });
      } catch (e) {
        console.log('Error in fetching gruops: ', e);
      }
    }

    const tableData = cleanGroupsTableData({
      groupsTableData: groupsTableData?.data?.content ?? [],
      trmPolicyList,
      privacyPolicyList,
      appSettingsList,
      phishingPolicyList,
      appPolicyList,
      dormancyPolicyList,
      emmConnectionList,
      networkPolicyList,
      brandingPolicyList,
      osRiskPolicyList,
    });
    // reduce for multi select
    this.setState({
      trmPolicyList,
      privacyPolicyList,
      appSettingsList,
      phishingPolicyList,
      appPolicyList,
      dormancyPolicyList,
      networkPolicyList,
      brandingPolicyList,
      osRiskPolicyList,
    });
    return {
      tableData: tableData.length ? tableData : [],
      totalResults: groupsTableData?.data?.totalElements,
      isLoading: false,
    };
  }

  onPaginationChange(update) {
    this.props.updateUISettings({
      domain: 'groups',
      update,
    });
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      groupsMtdTableColumnChange,
      updateUISettings,
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    groupsColumnHeadersHash: getChangedTableHeadersHash(state, 'mtdGroups'),
    currentTableHeaders: getUISettingsTableHeaders(state, 'mtdGroups'),
    groupsUiSettings: getUISettingsWithout(state, 'mtdGroups', [
      'tableHeaders',
    ]),
    accountId: _.get(state, 'user.account.id'),
    emmScope: _.get(state, 'auth.user.scopes.emm'),
    availableTeamsHash: getAvailableTeams(state),
    availableTeams: getAvailableTeamsAsSelectList(state),
    selectedTeam: _.get(state, 'teams.selected'),
    scopeBounds: state?.user?.scopeBounds ?? '',
    isBrandingSubscribed: selectIsBrandingSubscribed(state),
  };
};

const styles = () => ({
  tableRoot: {
    display: 'flex',
    marginTop: '105px',
    '& > div': {
      width: '100%',
    },
  },
  emmConnectionsRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  emmContent: {
    display: 'flex',
    zIndex: 1,
    position: 'absolute',
  },
});

export default compose(
  withRouter,
  withBackfill_DEPRECATED('uiSettings.groups'),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation()
)(Groups);
