import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    twoColumnGroup: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '18px',
      marginBottom: '18px',
    },
  })
);

export default useStyles;
