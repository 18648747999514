import useStyles from './useStyles';
import MUIFormControl from '@material-ui/core/FormControl';
import MUIFormControlLabel from '@material-ui/core/FormControlLabel';
import MUIRadio from '@material-ui/core/Radio';
import TextField from 'components/UI/input/TextField';

interface IEditableRadioProps {
  onFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  preText: string;
  afterText: string;
  radioValue: string | null;
  fieldValue: string | null;
  disabledField: boolean;
  fieldError: boolean;
}

const EditableRadio: React.FC<IEditableRadioProps> = (props) => {
  const {
    onFieldChange,
    preText,
    afterText,
    radioValue,
    fieldValue,
    disabledField,
    fieldError,
  } = props;

  const classes = useStyles();

  return (
    <MUIFormControl className={classes.wrapper}>
      <MUIFormControlLabel
        value={radioValue}
        control={<MUIRadio color='primary'/>}
        className={classes.smallLabel}
        label={
          <div className={classes.editableLabel}>
            <span>{preText}</span>
            <TextField
              disabled={disabledField}
              value={fieldValue}
              onChange={onFieldChange}
              style={{ width: '40px' }}
              error={fieldValue ? fieldError : undefined}
            />
            <span>{afterText}</span>
          </div>
        }
      />
    </MUIFormControl>
  );
};

export default EditableRadio;
