import cc from 'classcat';
import useStyles from './useStyles';

export interface IInformaticIcon {
  classList?: React.CSSProperties;
}

const InformaticIcon: React.FC<IInformaticIcon> = ({ classList }) => {
  const classes = useStyles();

  return (
    <svg className={cc([classes.icon, classList])} viewBox="0 0 25 25">
      <path
        d="M 5 2 C 3.347656 2 2 3.347656 2 5 L 2 19 C 2 20.652344 3.347656 22 5 22 L 19 22 C 20.652344 22 22 20.652344 22 19 L 22 5 C 22 3.347656 20.652344 2 19 2 Z M 4 7 L 20 7 L 20 19 C 20 19.550781 19.550781 20 19 20 L 5 20 C 4.449219 20 4 19.550781 4 19 Z M 6 9 L 6 10 L 7 10 L 7 13 L 8 13 L 8 9 Z M 12.5 9 C 11.671875 9 11 9.671875 11 10.5 L 11 11.5 C 11 12.328125 11.671875 13 12.5 13 C 13.328125 13 14 12.328125 14 11.5 L 14 10.5 C 14 9.671875 13.328125 9 12.5 9 Z M 16 9 L 16 10 L 17 10 L 17 13 L 18 13 L 18 9 Z M 12.5 10 C 12.777344 10 13 10.222656 13 10.5 L 13 11.5 C 13 11.777344 12.777344 12 12.5 12 C 12.222656 12 12 11.777344 12 11.5 L 12 10.5 C 12 10.222656 12.222656 10 12.5 10 Z M 7.5 14 C 6.671875 14 6 14.671875 6 15.5 L 6 16.5 C 6 17.328125 6.671875 18 7.5 18 C 8.328125 18 9 17.328125 9 16.5 L 9 15.5 C 9 14.671875 8.328125 14 7.5 14 Z M 11 14 L 11 15 L 12 15 L 12 18 L 13 18 L 13 14 Z M 16.5 14 C 15.671875 14 15 14.671875 15 15.5 L 15 16.5 C 15 17.328125 15.671875 18 16.5 18 C 17.328125 18 18 17.328125 18 16.5 L 18 15.5 C 18 14.671875 17.328125 14 16.5 14 Z M 7.5 15 C 7.777344 15 8 15.222656 8 15.5 L 8 16.5 C 8 16.777344 7.777344 17 7.5 17 C 7.222656 17 7 16.777344 7 16.5 L 7 15.5 C 7 15.222656 7.222656 15 7.5 15 Z M 16.5 15 C 16.777344 15 17 15.222656 17 15.5 L 17 16.5 C 17 16.777344 16.777344 17 16.5 17 C 16.222656 17 16 16.777344 16 16.5 L 16 15.5 C 16 15.222656 16.222656 15 16.5 15 Z M 16.5 15 "
        strokeWidth="1"
      />
    </svg>
  );
};

export default InformaticIcon;
