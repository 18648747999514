import tableServiceMapping from 'mappings/services/tableServiceMapping';
import { ServiceHandlerFactory } from '../utils/serviceUtils';

const partnerManageAccountsBase = '/api/auth/v2/managed_accounts';
const partnerUsersBase = 'api/auth/v1/partner_users';
const partnerAccountsBase = 'api/auth/v1/partner_accounts';
const partnerAPIKeyBase = `api/auth/v1/partner_api_keys`;

export const fetchPartnerUsersPage = ServiceHandlerFactory({
  axios: {
    url: `${partnerUsersBase}/all`,
  },
  paramSchema: tableServiceMapping,
});

// superui call createEditPartnerUser

export const fetchAllPartnerAccounts = ServiceHandlerFactory({
  axios: {
    url: `${partnerAccountsBase}/all`,
  },
});

export const fetchAllAccountsManagedByPartner = ServiceHandlerFactory({
  axios: {
    url: `${partnerAccountsBase}/:accountId/managed_accounts`,
  },
});

export const fetchAllCandidatesPartnerUsers = ServiceHandlerFactory({
  axios: {
    url: 'api/auth/v1/candidate_partner_users',
  },
});

export const fetchAllPartnerRoles = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/partner_roles',
  },
});

export const fetchAllAccountsNotManagedByPartner = ServiceHandlerFactory({
  axios: {
    url: `${partnerAccountsBase}/:accountId/other_managed_accounts`,
  },
});

export const enablePartnerUser = ServiceHandlerFactory({
  axios: {
    url: `${partnerUsersBase}/:userId`,
    method: 'put',
  },
});

export const fetchPartnerUserDetails = ServiceHandlerFactory({
  axios: {
    url: `${partnerUsersBase}/:userId`,
  },
});
// end of create edit partner users calls

export const disablePartnerAccess = ServiceHandlerFactory({
  axios: { url: `${partnerUsersBase}/:userId`, method: 'delete' },
});

export const enablePartnerAccess = ServiceHandlerFactory({
  axios: { url: `${partnerUsersBase}/:userId`, method: 'put' },
});
// enter partner account from super ui (accounts table)
export const enterPartnerAccount = ServiceHandlerFactory({
  axios: {
    url: `${partnerAccountsBase}/:accountId/enter`,
    method: 'post',
  },
});

// partner UI

export const fetchAllManagedAccountsByPartnerPage = ServiceHandlerFactory({
  axios: { url: `${partnerManageAccountsBase}` },
  paramSchema: tableServiceMapping,
});

export const fetchAllManagedAccountsByPartner = ServiceHandlerFactory({
  axios: { url: `${partnerAccountsBase}/:accountId/managed_accounts` },
});

export const fetchAllManagedAccountsByPartnerForPartnerUI =
  ServiceHandlerFactory({
    axios: { url: `${partnerManageAccountsBase}/all` },
  });

export const fetchAllPartnerAccountsForPartner = ServiceHandlerFactory({
  axios: {
    url: `${partnerAccountsBase}`,
    //
  },
});
export const fetchPartnerUsersPageForPartnerUI = ServiceHandlerFactory({
  axios: {
    url: partnerUsersBase,
  },
  paramSchema: tableServiceMapping,
});

// look up a managed acct by Id
export const fetchManagedAccountById = ServiceHandlerFactory({
  axios: {
    url: `${partnerManageAccountsBase}/:accountId`,
  },
});
// create a new managed account
export const addNewManagedAccount = ServiceHandlerFactory({
  axios: {
    url: partnerManageAccountsBase,
    method: 'post',
  },
});
// update a managed account
export const updateManagedAccount = ServiceHandlerFactory({
  axios: {
    url: `${partnerManageAccountsBase}/:accountId`,
    method: 'put',
  },
});

export const deleteManagedAccount = ServiceHandlerFactory({
  axios: {
    url: `${partnerManageAccountsBase}/:accountId`,
    method: 'delete',
  },
});

export const enablePartnerUserForPartnerUI = ServiceHandlerFactory({
  axios: {
    url: `${partnerUsersBase}/:userId`,
    method: 'put',
  },
});
// partner authorization

export const fetchPartnerAuthorizationsPage = ServiceHandlerFactory({
  axios: {
    url: partnerAPIKeyBase,
  },
  paramSchema: tableServiceMapping,
});

export const createPartnerAPIKey = ServiceHandlerFactory({
  axios: {
    url: partnerAPIKeyBase,
    method: 'post',
  },
});

export const updatePartnerAPIKey = ServiceHandlerFactory({
  axios: {
    url: `${partnerAPIKeyBase}/:partnerApiKeyId`,
    method: 'put',
  },
});

export const regeneratePartnerAPIKey = ServiceHandlerFactory({
  axios: {
    url: `${partnerAPIKeyBase}/:partnerApiKeyId/regenerate`,
    method: 'put',
  },
});

export const deletePartnerApiKey = ServiceHandlerFactory({
  axios: {
    url: `${partnerAPIKeyBase}/:partnerApiKeyId`,
    method: 'delete',
  },
});
