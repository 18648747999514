import { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from 'components/UI/input/Select';
import ZButton from 'UI/Buttons/ZButton';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    width: '350px',
    paddingTop: 10,
    paddingBottom: 10
  },
  select: {
    width: '100%',
    marginTop: -18,
    marginRight: 16,
  },
}));

const UIElementActions = ({ handleAction }) => {
  const classes = useStyles();
  const [selectionValue, setSelectionValue] = useState({});

  const setFieldValue = useCallback((field, value) => {
    setSelectionValue(value);
  }, []);

  return (
    <div className={classes.wrapper}>
      <Select
        label={<></>}
        setFieldValue={setFieldValue}
        name="UIElementAction"
        field={{ name: 'UIElementAction' }}
        options={[
          { label: 'Upload JSON table', value: 'upload-json' },
          { label: 'Download Sample JSON Data', value: 'download-sample-data' },
        ]}
        type="select"
        placeholder="Table Actions"
        className={classes.select}
      />
      <ZButton
        action={(e) => handleAction(e, selectionValue?.value)}
        color="secondary"
        buttonText="Go"
      />
    </div>
  );
};

export default UIElementActions;
