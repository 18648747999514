import _ from 'lodash';
import React from 'react';

// ui
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ZButton from 'UI/Buttons/ZButton';

// services
import {
  toggleModalDirect,
  openSnackBar,
  toggleModalDiffered,
} from 'utils/storeUtils';
import { publishEvent } from 'utils/eventUtils';
import { deleteProtectedApp } from 'api/zShieldService';

export const ZShieldAppDeleteConfirm = ({ data }) => {
  const appId = _.get(data, 'id');
  console.log(data);
  console.log(appId);
  function handleDelete() {
    deleteProtectedApp({ appId })
      .then(() => {
        openSnackBar(`Successfully deleted ${_.get(data, 'name')}`);
        publishEvent('table:force-fetch-zShieldApps');
        toggleModalDirect('ZShieldAppDeleteConfirm', false);
      })
      .catch(error => {
        openSnackBar(
          `Failed to delete ${_.get(data, 'name')}. ${_.get(
            error,
            'response.data',
            ''
          )}`
        );
      });
  }
  return (
    <DialogContent>
      <p>
        Are you sure you want to delete the app "{_.get(data, 'name')}"?
      </p>
      <DialogActions>
        <ZButton
          styleName="modalCancel"
          action={toggleModalDiffered('ZShieldAppDeleteConfirm', false)}
          color="secondary"
          buttonText="Cancel"
        />
        <ZButton
          action={handleDelete}
          buttonType="submit"
          color="primary"
          styleName="modalSave"
          buttonText="Delete"
        />
      </DialogActions>
    </DialogContent>
  );
};

ZShieldAppDeleteConfirm.defaultProps = {
  data: {},
};

export default ZShieldAppDeleteConfirm;
