import _ from 'lodash';
import { createTheme as createMuiTheme } from '@material-ui/core/styles';

import blueGrey from '@material-ui/core/colors/blueGrey';
import Color from 'color';

import _config from './_base/_config';
import _base from './_base/_base';
import _baseOverrides from './_base/_base.overrides';
import _baseGenerics from './_base/_base.generics';

const { colors } = _config;

const themebase = {
  config: _config,
  palette: {
    type: 'light',
    common: colors,
    primary: {
      light: colors.lightCyan,
      main: colors.cyan,
      dark: colors.aquaMarine,
      contrastText: colors.white,
      border: colors.lightBlueGrey,
    },

    secondary: {
      light: blueGrey[400],
      main: blueGrey[500],
      dark: blueGrey[600],
      contrastText: colors.white,
    },

    success: {
      main: colors.paleGreen,
      contrastText: colors.white,
    },

    error: {
      light: colors.paleTomato,
      main: colors.tomato,
      medium: colors.strawberry,
      dark: colors.ripeTomato,
      contrastText: colors.white,
    },

    warning: {
      light: colors.lightCanery,
      main: colors.canery,
      dark: colors.darkCanery,
      contrastText: colors.darkGreySoft,
      schoolbus: colors.schoolbus,
    },

    text: {
      primary: colors.darkGreySoft,
      secondary: colors.darkGreySoft,
      disabled: colors.mediumGrey,
      hint: '',
      icon: colors.darkGreySoft,
      link: colors.darkGreySoft,
      placeholder: colors.darkGreySoft,
      highlighter: colors.darkGrey,
    },

    background: {
      default: colors.white,
      paper: colors.babyBlueGrey,
      public: colors.white,
      tertiary: Color(colors.babyBlueGrey).lighten(0.5).string(),
      highlighter: Color(colors.cyan).lighten(0.75).string(),
      zShieldCardActive: '#2F6689',
      zShieldCard: '#C7C7C7',
      deviceAction: '#eeeff1',
      modalTitle: '#fff',
    },

    charts: {
      background: '#464646',
      brightBlue: '#2196F3',
      brightGreen: '#4CAE50',
      darkBlue: '#1561A0',
      darkGreen: '#225023',
      fuscia: '#F50057',
      icon: '#30414B',
      orange: '#FF9F1E',
      tomato: '#F44337',
      purpleCritical: '#B620E0',
    },

    /* components */
    tables: {
      selectedRow: colors.lightGrey,
      border: colors.darkerGrey,
      header: {
        background: colors.lightGrey,
      },
      shimmer: 'rgba(200, 200, 200, .15)',
      scrollbar: 'rgba(189, 189, 189, .9)',
    },

    graphs: {
      bar: {
        primary: '#d0d0d0',
      },
    },

    calendar: {
      headerText: colors.white,
      borderColor: colors.darkGreySoft,
      presetBackground: colors.babyBlueGrey,
      presetHoverBackground: colors.lightGrey,
      presetHoverText: colors.darkGreySoft,

      native: {
        selectionColor: colors.aquaMarine,
        weekdayColor: colors.lightAquaMarine,
        headerColor: colors.aquaMarine,
        todayColor: colors.lightAquaMarine,
        accentColor: colors.lightAquaMarine,
        floatingNav: {
          background: colors.lightGrey,
          color: colors.darkGreySoft,
          chevron: 'transparent',
        },
      },
    },

    components: {
      globalHeader: {
        background: '#586d79',
        contrastText: colors.white,
      },

      sidebar: {
        background: colors.white,
      },

      checkbox: {
        activeColor: colors.green,
        inactiveColor: colors.ripeTomato,
        pipColor: '#efefef',
        trackColor: '#ccc',
      },

      circleIcon: {
        error: colors.strawberry,
        warning: colors.schoolbus,
        success: colors.avocado,
        unknown: colors.mediumGrey,
      },
      
      severityIcon: {
        informational: colors.informational,
        bestPractices: colors.avocado,
        low: colors.lowSeverityBlue,
        high: colors.strawberry,
      },

      severityIcon: {
        informational: colors.informational,
        bestPractices: colors.avocado,
        low: colors.lowSeverityBlue,
        high: colors.strawberry,
      },

      statusDot: {
        critical: colors.strawberry,
        elevated: colors.schoolbus,
        low: colors.substantial,
        normal: colors.moderate,
      },

      checkboxInputGroup: {
        base: colors.white,
      },

      datePicker: {
        svgFilter:
          'invert(8%) sepia(0%) saturate(0%) hue-rotate(297deg) brightness(99%) contrast(94%)',
      },

      teamSelector: {
        default: colors.darkGreySoft,
      },
    },

    accounts: {
      inputs: {
        svgFilter:
          'invert(8%) sepia(0%) saturate(0%) hue-rotate(297deg) brightness(99%) contrast(94%)',
      },
      modals: {
        contrastText: colors.black,
      },
    },
  },
};

const themeoverrides = {
  MuiCard: {
    root: {
      background: themebase.palette.background.default,
    },
  },

  MuiFormControlLabel: {
    label: {
      color: `${themebase.palette.text.primary} !important`,
    },
  },

  MuiTabs: {
    indicator: {
      backgroundColor: themebase.palette.primary.main,
    },
  },

  MuiTab: {
    label: {
      color: themebase.palette.text.primary,
    },
  },

  MuiSelect: {
    '&$disabled': {
      color: Color(themebase.palette.text.primary).alpha(0.75).string(),
    },
  },

  MuiListItem: {
    root: {
      '&$selected': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        color: themebase.palette.primary.main,
        fontWeight: 'bold',

        '& svg': {
          color: themebase.palette.primary.main,
        },
      },
    },
  },

  MuiSwitch: {
    switchBase: {
      color: themebase.palette.components.checkbox.pipColor,
    },
    colorSecondary: {
      '&$checked': {
        color: themebase.palette.components.checkbox.pipColor,
      },
    },
    track: {
      opacity: 0.7,
      backgroundColor: themebase.palette.components.checkbox.inactiveColor,
      '$checked$checked + &': {
        opacity: 0.9,
        backgroundColor: themebase.palette.components.checkbox.activeColor,
      },
    },
  },
};

export default createMuiTheme({
  ..._.merge({}, _base, themebase),
  overrides: _.merge(
    {},
    _baseOverrides({
      primaryText: themebase.palette.text.primary,
      inputBaseStyles: {
        background: themebase.palette.background.default,
        borderColor: themebase.palette.common.darkGreySoft,
      },
    }),
    themeoverrides
  ),
  globals: _baseGenerics, // global styling for resuable components
});
