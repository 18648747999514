import DialogContent from '@material-ui/core/DialogContent';
import { availableTeams as availableTeamsAtom } from 'atoms/teams';
import { useRecoilState, useRecoilValue } from 'recoil';
import Button from 'components/UI/Button';
import TextField from 'components/UI/input/TextField';
import {
  Field as FormikField,
  Form as FormikForm,
  Formik,
  FormikHelpers,
} from 'formik';
import React from 'react';
import { initialValues } from '../Form/initialValues';
import { IFormSubmit, IResponseData } from '../Form/models';
import Schema from '../Form/schema';
import useStyles from '../Form/useStyles';
import Select, { ISelectItem } from 'components/UI/input/Select';
import GenericError from 'components/UI/GenericErrorBox';
import { getActiveModalAtom } from 'atoms/modals';
import { IZScanUrlSubmissionApp } from 'components/main/ZScanApps/models';

const activeModalAtom = getActiveModalAtom<IZScanUrlSubmissionApp>();

interface IFormProps {
  data: Partial<IResponseData>;
  editMode?: boolean;
  errorMessage: string;
  handleSubmit: (
    values: IFormSubmit,
    formikHelpers: FormikHelpers<IFormSubmit>
  ) => void;
  resetModal: () => void;
}

const SubmissionUrlForm: React.FC<IFormProps> = ({
  data,
  // editMode,// this will eventually be an edit modal
  errorMessage,
  handleSubmit,
}) => {
  const classes = useStyles();
  const availableTeams = useRecoilValue(availableTeamsAtom) || [];
  const [activeModal, setActiveModal] = useRecoilState(activeModalAtom);
  const resolvedInitialValues = initialValues({ data, availableTeams });
  const handleCloseModal = () => {
    if (activeModal) {
      setActiveModal(undefined);
    }
  };

  const handleTeamChange = (
    field: string,
    value: ISelectItem,
    setFieldValue: (field: string, value?: string | null) => void
  ) => {
    setFieldValue(field, value as unknown as string);
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={resolvedInitialValues}
        onSubmit={handleSubmit}
        validateOnBlur
        validationSchema={Schema()}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
          return (
            <>
              <FormikForm>
                <DialogContent style={{ paddingTop: 0 }}>
                  <>
                    <TextField
                      color="primary"
                      label="Playstore URL"
                      disabled
                      value={values?.name}
                    />
                    <div className={classes.bundleVersionWrapper}>
                      <div className={classes.bundleField}>
                        <FormikField
                          color="primary"
                          component={TextField}
                          disabled
                          label="Bundle ID"
                          name="bundleId"
                        />
                      </div>
                    </div>
                    {data?.teamId ? (
                      <TextField
                        color="primary"
                        label="Team"
                        name="team"
                        disabled
                        value={values?.team?.label}
                      />
                    ) : (
                      <FormikField
                        component={Select}
                        disabled={data?.teamId}
                        label="Teams"
                        multiple={false}
                        name="team"
                        options={availableTeams}
                        setFieldValue={(field: string, value: ISelectItem) =>
                          handleTeamChange(field, value, setFieldValue)
                        }
                        type="select"
                      />
                    )}
                  </>
                  <GenericError errorMessage={errorMessage} />
                  <div className={classes.buttonPanel}>
                    <Button
                      color="secondary"
                      disabled={isSubmitting}
                      onClick={handleCloseModal}
                      text="Cancel"
                    />
                    <Button
                      color="secondary"
                      type="submit"
                      onClick={() => handleSubmit}
                      text="Complete"
                    />
                  </div>
                </DialogContent>
              </FormikForm>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default SubmissionUrlForm;
