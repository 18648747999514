import { TypeDataSource } from '@inovua/reactdatagrid-enterprise/types';
import _ from 'lodash';
import { ITableQuery, ILocationQuery } from '../models';

export type IMemoizedTableQueryResponse = (
  query: ITableQuery | ILocationQuery,
  returnMemoizedResult?: boolean,
  overrideTTL?: number
) => TypeDataSource;

const memoizeTableQuery = (
  method: (a: any) => TypeDataSource,
  TTL = 100
): IMemoizedTableQueryResponse => {
  const memoized: {
    query: any;
    result: TypeDataSource;
    timestamp: number;
  } = {
    query: '',
    result: [],
    timestamp: new Date().getTime(),
  };

  return (query, returnMemoizedResult, overrideTTL) => {
    const finalTTL = 'number' === typeof overrideTTL ? overrideTTL : TTL;
    const currentTime = new Date().getTime();

    if (returnMemoizedResult) {
      return memoized.result;
    }

    if (
      finalTTL > 0 &&
      currentTime - memoized.timestamp <= finalTTL &&
      _.isEqual(memoized.query, query)
    ) {
      memoized.timestamp = currentTime;
      return memoized.result;
    }

    memoized.query = query;
    memoized.result = method(query);
    memoized.timestamp = currentTime;

    return memoized.result;
  };
};

export default memoizeTableQuery;
