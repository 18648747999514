import { IColumnHeader, IFilterEditor } from 'components/UI/Table/models';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import InputIcon from '@material-ui/icons/Input';
import AndroidIcon from 'components/UI/icons/AndroidIcon';
import AppleIcon from 'components/UI/icons/AppleIcon';
import {
  IZScanAppsRowMapping,
  IFilterValues,
  IResolveFiltersFn,
} from './models';
import { OS_NAMES, PLATFORMS, SOURCE, riskScoreToValue } from './utils';
import ChromeOSIcon from 'components/UI/icons/ChromeOSIcon';
import Tooltip from '@material-ui/core/Tooltip';
import { toggleModalDiffered } from '../../../utils/storeUtils';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ProtectedLink from 'components/main/protected/ProtectedLink';
import ZScanUploadEditButton from './ZScanEditButton';
import SeverityCircle from './SeverityCircle';

export const defaultZScanAppsTableHeaders: IColumnHeader[] = [
  {
    filterEditor: 'StringFilter',
    id: 'name',
    label: 'App Name',
    show: true,
    path: 'name',
    sort: true,
    minWidth: 220,
    maxWidth: 260,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    filterEditor: 'StringFilter',
    id: 'bundleId',
    label: 'Package/Bundle',
    show: true,
    path: 'bundleId',
    sort: true,
    minWidth: 220,
    maxWidth: 260,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    filterEditor: 'SelectFilter',
    filterEditorProps: { multiple: true, wrapMultiple: false },
    id: 'platform',
    label: 'Platform',
    show: true,
    path: 'platform',
    sort: true,
    minWidth: 175,
    maxWidth: 175,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },

  {
    filterEditor: 'SelectFilter',
    filterEditorProps: { multiple: true, wrapMultiple: false },
    id: 'appSource',
    label: 'Source',
    show: true,
    path: 'appSource',
    sort: true,
    minWidth: 175,
    maxWidth: 175,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },

  {
    id: 'hasEmulatorScript',
    label: 'Scan Arguments',
    show: false,
    path: 'hasEmulatorScript',
    sort: true,
    minWidth: 175,
    maxWidth: 175,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    id: 'privacyScore',
    label: 'Privacy Risk',
    show: true,
    path: 'privacyScore',
    sort: true,
    minWidth: 150,
    maxWidth: 150,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    id: 'securityScore',
    label: 'Security Risk',
    show: true,
    path: 'securityScore',
    sort: true,
    minWidth: 150,
    maxWidth: 150,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },

  {
    id: 'assessmentCount',
    label: '#Assessments',
    show: true,
    path: 'assessmentCount',
    sort: true,
    minWidth: 150,
    maxWidth: 150,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },

  {
    id: 'lastScanned',
    label: 'Last Assessment',
    show: false,
    path: 'lastScanned',
    sort: true,
    minWidth: 175,
    maxWidth: 175,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    id: 'criticalFindingCount',
    label: '#Critical Findings',
    show: true,
    path: 'criticalFindingCount',
    sort: true,
    minWidth: 175,
    maxWidth: 175,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    id: 'teamName',
    label: 'Team',
    show: true,
    path: 'teamName',
    sort: false,
    minWidth: 175,
    maxWidth: 175,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    id: 'created',
    label: 'Date Created',
    show: false,
    path: 'created',
    sort: true,
    minWidth: 175,
    maxWidth: 175,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    id: 'modified',
    label: 'Date Modified',
    show: false,
    path: 'modified',
    sort: true,
    minWidth: 175,
    maxWidth: 175,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
  {
    id: 'actions',
    label: 'Actions',
    show: true,
    path: 'actions',
    sort: false,
    minWidth: 150,
    maxWidth: 150,
    resizable: true,
    flex: 1,
    showInContextMenu: true,
  },
];

// This is currently here for reference -- will be re-enabled/modified
// when the backend is un-sandboxed for this view.
//

export const zScanAppsColumnMapping = (): // inDrawer?: boolean
IZScanAppsRowMapping[] => [
  {
    path: 'name',
    columnContent: ({ rowData }) => (
      <Tooltip title={rowData?.name ?? ''}>
        <div className="table-cell__ellipsis">{rowData?.name ?? ''}</div>
      </Tooltip>
    ),
  },
  {
    path: 'bundleId',
    columnContent: ({ rowData }) => (
      <div className="table-cell__ellipsis">{rowData?.bundleId ?? ''}</div>
    ),
  },
  {
    path: 'platform',
    columnContent: ({ classList, rowData }) => {
      if (!rowData?.platform) {
        return <span className={classList?.osCell ?? ''} />;
      }
      const osLowerCaseName = rowData?.platform?.toLowerCase() ?? '';
      const osName = OS_NAMES?.[osLowerCaseName] ?? '';

      const icon =
        osLowerCaseName === 'ios' ? (
          <AppleIcon />
        ) : ['chrome os', 'chrome_os'].includes(osLowerCaseName) ? (
          <div className="table__os-icon">
            <ChromeOSIcon />
          </div>
        ) : osLowerCaseName === 'chrome_os' ? ( // change this when BE renames all references of 'chrome_os' to 'chrome os' or viceversa
          <div className="table__os-icon">
            <ChromeOSIcon />
          </div>
        ) : (
          <AndroidIcon />
        );

      return (
        <div className="table__os-icon flex--spaced">
          {icon}
          <div className="table-cell__ellipsis" style={{ paddingLeft: '5px' }}>
            {osName}
          </div>
        </div>
      );
    },
  },
  {
    path: 'appSource',
    columnContent: ({ rowData }) => (
      <div className="table-cell__ellipsis">
        {rowData?.appSource ?? 'Unknown'}
      </div>
    ),
  },
  {
    path: 'hasEmulatorScript',
    columnContent: ({ rowData }) =>
      !!rowData?.hasEmulatorScript ? 'Enabled' : null,
  },
  {
    path: 'privacyScore',
    columnContent: ({ classList, rowData }) => (
      <div className={classList.risk}>
        {
          <SeverityCircle
            severity={riskScoreToValue(rowData?.privacyScore)}
            showLabel={true}
          />
        }
      </div>
    ),
  },
  {
    path: 'securityScore',
    columnContent: ({ classList, rowData }) => (
      <div className={classList.risk}>
        {
          <SeverityCircle
            severity={riskScoreToValue(rowData?.securityScore)}
            showLabel={true}
          />
        }
      </div>
    ),
  },

  {
    path: 'assessmentCount',
    columnContent: ({ rowData }) => (
      <ProtectedLink
        allow={{ zdev_apps: 'view' }}
        to={`/console/zscan/assessments?appId.keyword=${rowData?.id}`}
      >
        <span> {rowData?.assessmentCount ?? 0}</span>
      </ProtectedLink>
    ),
  },
  {
    path: 'lastScanned',
    columnContent: ({ rowData }) =>
      rowData?.lastScanned
        ? Moment(rowData?.lastScanned).format('DD-MMM-YYYY')
        : '',
  },
  {
    path: 'criticalFindingCount',
    columnContent: ({ rowData }) => (
      <ProtectedLink
        allow={{ zdev_apps: 'view' }}
        to={`/console/zscan/assessment/${rowData.lastAssessmentId}/findings?severity=CRITICAL`}
      >
        <span> {rowData?.criticalFindingCount ?? 0}</span>
      </ProtectedLink>
    ),
  },
  {
    path: 'teamName',
  },
  {
    path: 'created',
    columnContent: ({ rowData }) =>
      rowData?.created ? Moment(rowData?.created).format('DD-MMM-YYYY') : '',
    csvContent: ({ rowData }) =>
      rowData?.created ? Moment(rowData?.created).format('DD-MMM-YYYY') : '',
  },
  {
    path: 'modified',
    columnContent: ({ rowData }) =>
      rowData?.modified ? Moment(rowData?.modified).format('DD-MMM-YYYY') : '',
    csvContent: ({ rowData }) =>
      rowData?.modified ? Moment(rowData?.modified).format('DD-MMM-YYYY') : '',
  },
  {
    path: 'actions',
    columnContent: ({ rowData }) => {
      return (
        <>
          <Tooltip title="Edit App Details" className="hover--link">
            <ZScanUploadEditButton data={rowData} />
          </Tooltip>
          <Tooltip title="App Details" className="hover--link">
            <VisibilityIcon className="cursor--pointer" />
          </Tooltip>
          <Tooltip title="Delete App" className="hover--link">
            <DeleteIcon
              id="action-column"
              className="cursor--pointer"
              onClick={toggleModalDiffered(
                'ZscanAppsDeleteConfirm',
                {
                  rowData,
                },
                { title: 'Delete App' }
              )}
            />
          </Tooltip>
          <Tooltip title="View Assessments" className="hover--link">
            <Link
              className="cursor--pointer"
              to={`/console/zscan/assessments?appId.keyword=${rowData?.id}`}
            >
              <InputIcon id="action-column" />
            </Link>
          </Tooltip>
        </>
      );
    },
  },
];

export const resolveFilters: IResolveFiltersFn = (
  filterValues: IFilterValues
): IFilterEditor[] => {
  return [
    {
      name: 'name',
      operator: 'eq',
      type: 'string',
      value: filterValues?.name ?? '',
    },
    {
      name: 'bundleId',
      operator: 'eq',
      type: 'string',
      value: filterValues?.bundleId ?? '',
    },
    {
      name: 'platform',
      operator: 'in',
      type: 'select',
      value: filterValues?.platform ?? '',
      customRSQLOperator: 'in',
      filterEditorProps: {
        dataSource: PLATFORMS.map(({ label, value }) => ({
          id: value,
          label,
        })),
      },
    },

    {
      name: 'appSource',
      value: filterValues?.appSource ?? '',
      operator: 'in',
      type: 'select',
      customRSQLOperator: 'in',
      filterEditorProps: {
        dataSource: SOURCE.map(({ label, value }) => ({
          id: value,
          label,
        })),
      },
    },
  ];
};
