import { TabsProps as TMUITabsProps } from '@material-ui/core';
import { default as MUITab } from '@material-ui/core/Tab';
import { default as MUITabs } from '@material-ui/core/Tabs';
import React, { useState } from 'react';
import useStyles from './useStyles';
import { useTranslation } from 'react-i18next';

export interface ITabProps {
  content: React.ReactNode;
  disabled?: boolean;
  show?: boolean;
  title: string;
}

export interface ITabsProps {
  hideTabs?: boolean;
  tabs: ITabProps[];
}

export type TTabsProps = ITabsProps & TMUITabsProps;

const Tabs: React.FC<TTabsProps> = ({
  hideTabs,
  tabs,
  value: defaultValue,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue ?? 0);
  const { ready, t } = useTranslation();
  const handleChange = (e: React.ChangeEvent<{}>, newValue: number) =>
    setValue(newValue);
  if (!ready) {
    return null;
  }
  return (
    <>
      {!hideTabs && (
        <MUITabs value={value} onChange={handleChange}>
          {tabs.map(({ title, disabled }, idx) => (
            <MUITab
              classes={{ root: classes.tab }}
              disabled={!!disabled}
              key={`tab-${idx}`}
              label={t(title)}
            />
          ))}
        </MUITabs>
      )}
      {tabs.map(({ content }, idx) =>
        idx === value ? <div key={`tab-content-${idx}`}>{content}</div> : null
      )}
    </>
  );
};

export default Tabs;
