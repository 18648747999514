import { fetchOSRiskRiskiestOSVersions } from 'api/OSRiskService';
import { IRiskiestOsVersions } from './models';
import { TMultiBarChartData } from 'components/UI/Chart/MultiBar/models';

export const fetchRiskiestOSVersions = async (
  accountId?: string | undefined,
  teamId?: string | undefined
) => {
  const result: { data: IRiskiestOsVersions } =
    await fetchOSRiskRiskiestOSVersions({ accountId, teamId });

  return result?.data;
};

export const transformRiskiestOsVersion = (
  data: IRiskiestOsVersions,
  os: string
): TMultiBarChartData[] =>
  data?.riskiestOsVersion?.[os]?.map(
    ({
      lowCount,
      mediumCount,
      highCount,
      criticalCount,
      osVersionAndPatchDate,
    }) => ({
      low: lowCount,
      medium: mediumCount,
      high: highCount,
      critical: criticalCount,
      name: osVersionAndPatchDate,
    })
  ) ?? [];
