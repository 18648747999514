import GenericCard from 'components/UI/GenericCard';
import { passwordRulesDisplayMapping } from 'components/entry/password.mappings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './useStyles';

interface IPasswordRulesBoxProps {
  customPasswordRules: { [key: string]: boolean | null };
}

const PasswordRulesBox: React.FC<IPasswordRulesBoxProps> = ({
  customPasswordRules,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (!customPasswordRules?.enabled) {
    return null;
  }

  return (
    <GenericCard
      className={classes.passwordBoxCard}
      title={t('USER_SETTINGS.PASSWORD.PASSWORD_RULES')}
    >
      {passwordRulesDisplayMapping(customPasswordRules, t)}
    </GenericCard>
  );
};

export default PasswordRulesBox;
