import { Api } from 'config/axiosConfig';

const PolicyBase = 'api/mtd-policy/v1/branding/policies';

export const fetchAllPolicies = async(teamId) => {
  let url = PolicyBase;

  if (teamId?.trim().length) {
    url = `${url}?teamId=${teamId}`;
  }
  
  return Api.get(url);
};

export const fetchPolicyById = async (policyId) => {
  return Api.get(`${PolicyBase}/${policyId}`);
};

export const createPolicy = async (policy) => {
  return Api.post(`${PolicyBase}`, policy);
};

export const clonePolicy = async (policyId, policy) => {
  return Api.post(`${PolicyBase}/${policyId}/clone`, policy)
};

export const updatePolicy = async (policyId, policy) => {
  return Api.put(`${PolicyBase}/${policyId}`, policy)
};

export const deletePolicy = async (policyId) => {
  return Api.delete(`${PolicyBase}/${policyId}`);
};
