import MUIBuildIcon from '@material-ui/icons/Build';
import React, { useCallback, useState } from 'react';
import {
  IOSResponseTypes,
  ISelectTRMPolicyTableData,
  PolicyChangeOptions,
} from '../models';
import useStyles from '../useStyles';
import DeviceActionsModal from './DeviceActionsModal';

interface IDeviceActionsProps {
  rowData: ISelectTRMPolicyTableData;
  osResponseTypes?: IOSResponseTypes;
  handleTRMPolicyRuleChange: (
    optionName: PolicyChangeOptions,
    value: unknown,
    rule: ISelectTRMPolicyTableData
  ) => void;
}

const DeviceActions: React.FC<IDeviceActionsProps> = (props) => {
  const { rowData, osResponseTypes, handleTRMPolicyRuleChange } = props;

  const classes = useStyles();

  const [isDeviceActionsOpen, setIsDeviceActionsOpen] = useState(false);

  const onDeviceActionsClose = useCallback(() => {
    setIsDeviceActionsOpen(false);
  }, [setIsDeviceActionsOpen]);

  const onActionsClick = useCallback(() => {
    setIsDeviceActionsOpen(true);
  }, [setIsDeviceActionsOpen]);

  return (
    <>
      <MUIBuildIcon
        className={
          rowData.customResponses != null && rowData.customResponses.length > 0
            ? classes.toggledMailIcon
            : classes.mailIcon
        }
        onClick={onActionsClick}
      />

      <DeviceActionsModal
        isOpen={isDeviceActionsOpen}
        onClose={onDeviceActionsClose}
        rowData={rowData}
        osResponseTypes={osResponseTypes}
        handleTRMPolicyRuleChange={handleTRMPolicyRuleChange}
      />
    </>
  );
};

export default React.memo(DeviceActions);
