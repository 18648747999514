import { TClassList } from 'components/UI/Table/models';
import useStyles from './useStyles';

interface IKnoxIconProps {
  classList?: TClassList;
}

const KnoxIcon: React.FC<IKnoxIconProps> = ({ classList }) => {
  const KnoxIconClasses = useStyles();
  const classes = { ...KnoxIconClasses, ...classList };

  return (
    <svg
      className={classes.knoxIcon}
      viewBox="0 0 576 576"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M203.32,499.32c14.16-4.41,27.69-8.71,41.3-12.74c1.35-0.4,3.47,0.44,4.76,1.37c12.04,8.63,23.93,17.47,36.01,26.03
		c1.2,0.85,3.91,1.04,5.09,0.26c33.04-21.69,62.79-47.27,89.47-76.42c0.29-0.32,0.47-0.75,0.95-1.55
		c-8.92-2.77-17.68-5.49-26.45-8.2c-20.77-6.43-41.53-12.93-62.36-19.16c-2.53-0.76-5.68-0.7-8.22,0.07
		c-41.89,12.8-83.73,25.75-125.55,38.74c-2.86,0.89-4.67,0.83-6.86-1.81c-27.48-33.13-49.87-69.45-68.45-108.2
		c-0.32-0.67-0.56-1.37-1.06-2.57c9.9-3.07,19.63-6.09,29.37-9.1c57.57-17.81,115.13-35.64,172.74-53.31
		c2.52-0.77,5.7-0.72,8.23,0.05c52.56,16.11,105.07,32.38,157.6,48.61c2.13,0.66,4.28,1.21,7.26,2.05
		c6.16-16.34,12.2-32.39,18.45-48.97c-6.77-2.15-12.74-4.09-18.73-5.95C402.5,251.7,348.09,234.91,293.72,218
		c-3.77-1.17-7.1-1.27-10.93-0.08c-72.25,22.45-144.55,44.76-216.84,67.1c-0.56,0.17-1.13,0.33-2.31,0.67
		c-0.97-3.87-1.87-7.63-2.87-11.37c-10.46-39.24-15.42-79.15-15.03-119.77c0.19-19.82,0.04-39.65,0.03-59.47
		c0-2.13-0.21-3.96,2.69-4.85c36.23-11.16,72.42-22.47,108.62-33.73c0.41-0.13,0.87-0.08,1.83-0.16c0,17.72,0,35.33,0,53.6
		c9.13-2.81,17.62-5.4,26.09-8.04c9.56-2.97,9.55-2.99,9.55-12.86c0-13.47,0.09-26.94-0.07-40.41c-0.03-2.91,0.83-4.19,3.66-5.07
		c29.16-8.97,58.26-18.11,87.42-27.09c1.6-0.49,3.62-0.44,5.22,0.05c29.15,8.99,58.26,18.14,87.42,27.09
		c2.92,0.9,3.64,2.27,3.62,5.11c-0.12,15.44,0.02,30.87-0.13,46.31c-0.03,2.91,0.82,4.27,3.66,5.1c10.41,3.05,20.74,6.35,31.73,9.75
		c0-18.19,0-35.79,0-54.07c38.1,11.87,75.54,23.54,113.18,35.27c0,31.55,0,62.93,0,94.7c-12.3-3.81-24.42-7.47-36.44-11.44
		c-1.07-0.35-2.08-2.7-2.09-4.13c-0.15-15.43-0.2-30.87-0.01-46.31c0.04-3.33-1.04-4.66-4.17-5.57
		c-10.41-3.03-20.73-6.39-31.53-9.76c0,18.22,0,35.88,0,54.09c-11.53-3.59-22.61-7.04-33.69-10.5
		c-25.09-7.82-50.15-15.71-75.28-23.39c-3.17-0.97-4.15-2.37-4.11-5.64c0.18-15.13,0-30.27,0.14-45.4c0.03-3.19-0.79-4.75-4.08-5.74
		c-15.92-4.76-31.73-9.87-47.62-14.7c-1.89-0.57-4.25-0.67-6.12-0.1c-16.19,4.89-32.3,10.06-48.49,14.95
		c-2.86,0.86-3.7,2.22-3.68,5.11c0.13,15.44,0.03,30.87,0.09,46.31c0.01,2.45-0.23,4.08-3.14,4.98
		c-35.5,10.94-70.96,22.04-106.43,33.09c-0.84,0.26-1.73,0.37-3.16,0.66c0-17.92,0-35.51,0-53.81
		c-11.85,3.71-23.04,7.16-34.17,10.81c-0.83,0.27-1.67,1.99-1.68,3.04c-0.32,36.91-0.81,73.84,6.48,110.3
		c0.23,1.15,0.61,2.27,1.01,3.75c15.24-4.71,30.22-9.34,45.19-13.98c49.05-15.19,98.09-30.41,147.18-45.48
		c2.26-0.69,5.11-0.68,7.37,0.02c75.34,23.22,150.64,46.56,225.96,69.86c2.45,0.76,4.32,1.24,3.5,4.79
		c-9.46,41.01-23.92,80.13-43.85,117.21c-0.21,0.38-0.54,0.7-1.06,1.35c-12.3-3.79-24.68-7.59-37.05-11.42
		c-49.2-15.24-98.38-30.52-147.61-45.66c-2.13-0.66-4.81-0.7-6.93-0.04c-47.5,14.59-94.96,29.32-142.43,44.03
		c-1.41,0.44-2.79,0.93-5.33,1.79c10.13,14.84,19.84,29.21,29.82,43.4c0.66,0.93,3.52,1,5.07,0.53
		c29.18-8.9,58.32-17.94,87.46-26.95c8.52-2.63,17.01-5.35,25.58-7.8c1.9-0.54,4.23-0.64,6.1-0.06
		c51.4,15.78,102.76,31.68,154.12,47.57c0.86,0.27,1.67,0.69,3.02,1.25c-9.93,12.12-19.44,24.25-29.51,35.89
		c-33.55,38.81-71.7,72.35-115.36,99.46c-4.87,3.02-10.04,7.79-15.03,7.74c-5.19-0.05-10.48-4.74-15.48-7.8
		c-23.81-14.57-45.8-31.58-66.67-50.08C205.37,501.3,204.63,500.56,203.32,499.32z"
        />
      </g>
    </svg>
  );
};

export default KnoxIcon;
