import store from 'config/storeConfig';
import { createAction } from 'redux-actions';
import { openSnackbar as osb } from 'reducers/SnackbarReducers';

const toggleModal = createAction('UI_TOGGLE_MODAL', (key, content, config) => ({
  key,
  content,
  config,
}));

export const toggleModalDirect = (...args) => {
  store.dispatch(toggleModal(...args));
};

export const toggleModalDiffered = (...args) => {
  return () => {
    return store.dispatch(toggleModal(...args));
  };
};

export const openSnackBar = (...args) => {
  store.dispatch(osb(...args));
};

export const openSnackBarDiffered = (...args) => {
  return () => store.dispatch(osb(...args));
};
