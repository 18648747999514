import GenericError from 'components/UI/GenericErrorBox';
import { useTranslation } from 'react-i18next';
import useStyles from './useStyles';

interface IAclInUseModalProps {
  policiesUsingAcl: { id: string; name: string }[];
}

const AclInUseModal: React.FC<IAclInUseModalProps> = ({ policiesUsingAcl }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <GenericError
        customClass={classes.errorBox}
        errorMessage={t('MTD.POLICIES.PHISHING_POLICY.CHANGE_LIST_WARNING')}
        onClose={() => {}}
      />
      <div className={classes.usedPoliciesContainer}>
        <span>{t('MTD.POLICIES.PHISHING_POLICY.LIST_USED_POLICIES')}:</span>
        <ul className={classes.usedPoliciesList}>
          {policiesUsingAcl.map((p) => (
            <li className={classes.usedPoliciesListItem} key={p.id}>
              {p.name}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default AclInUseModal;
