import { IGenericPromptModalData } from 'components/UI/Modals/GenericPrompt/models';
import { atom } from 'recoil';
import { TButtonProps } from '../components/UI/Button';

interface IHeaderState {
  buttons: TButtonProps[];
  preventNavigation?: boolean;
  navigationPrompt?: Partial<
    Omit<IGenericPromptModalData, 'onCancel' | 'onConfirm'>
  >;
  clearNavigationPreventingChanges?: () => void;
}

export const headerActions = atom<IHeaderState | undefined>({
  key: 'headerActionButtons',
  default: undefined,
});
