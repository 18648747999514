import cc from 'classcat';
import useStyles from './useStyles';

export interface IVersionIcon {
  classList?: React.CSSProperties;
}

const VersionIcon: React.FC<IVersionIcon> = ({ classList }) => {
  const classes = useStyles();

  return (
    <svg className={cc([classes.icon, classList])} viewBox="0 0 25 25">
      <path
        d="M 11 3 C 9.897 3 9 3.897 9 5 L 9 19 C 9 20.103 9.897 21 11 21 L 19 21 C 20.103 21 21 20.103 21 19 L 21 5 C 21 3.897 20.103 3 19 3 L 11 3 z M 8 4 C 6.9069372 4 6 4.9069372 6 6 L 6 18 C 6 19.093063 6.9069372 20 8 20 L 8 18 L 8 6 L 8 4 z M 5 6 C 3.9069372 6 3 6.9069372 3 8 L 3 16 C 3 17.093063 3.9069372 18 5 18 L 5 16 L 5 8 L 5 6 z"
        strokeWidth="1"
      />
    </svg>
  );
};

export default VersionIcon;
