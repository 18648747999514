import CloudDownload from '@material-ui/icons/CloudDownload';
import { downloadAuditLogCsv } from 'api/AuditLogService';
import { AxiosResponse } from 'axios';
import withRouter from 'components/hocs/withRouter';
import {
  IPaginationChangeFn,
  IState,
} from 'components/main/os-risk/common/models';
import ExpansionRowDetails from 'components/Tables/ExpansionRowDetails';
import Table from 'components/UI/Table';
import {
  IColumnHeader,
  IFilterEditor,
  ILocationQuery,
} from 'components/UI/Table/models';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  auditLogTableColumnChange,
  updateUISettings,
} from 'reducers/UiSettingsReducers';
import {
  getChangedTableHeadersHash,
  getUISettingsTableHeaders,
  getUISettingsWithout,
} from 'reducers/UiSettingsSelectors';
import { bindActionCreators, compose } from 'redux';
import useBackfill from 'utils/useBackfill';
import { withDirtyState } from 'utils/withDirtyState';
import withMemoizedProps from 'utils/withMemoizedProps';
import {
  auditLogConfig,
  auditLogRowMapping,
  defaultAuditLogTableHeaders,
  resolveFilters,
} from './auditLog.mappings';
import { IAuditLog, IFilterValues } from './models';
import tableQuery from './tableQuery';
import useStyles from './useStyles';
import { INITIAL_FILTER_VALUES } from './utils';

interface IAuditLogProps {
  auditLogTableColumnChange: (...args: unknown[]) => void;
  auditLogUiSettings: { [key: string]: unknown };
  columnHeadersHash: {} | undefined;
  currentTableHeaders: IColumnHeader[];
  definedUser?: string;
  jiggleDirtyState: () => void;
  q: { [key: string]: unknown };
  rqps: string[];
  scopeBounds: string;
  scopes: { [key: string]: string[] };
  updateUISettings: ({
    domain,
    update,
  }: {
    domain: string;
    update: Partial<ILocationQuery>;
  }) => void;
  updateUrl: IPaginationChangeFn;
}

const RowDetails = ({ data }: { data: IAuditLog }) => {
  const classes = useStyles();
  return (
    <>
      <ExpansionRowDetails
        classList={classes}
        dataProperty="column1"
        config={auditLogConfig}
        data={data}
      />
      {data.details && (
        <ExpansionRowDetails
          classes={classes}
          dataProperty="column2"
          config={auditLogConfig}
          data={data}
        />
      )}
    </>
  );
};

const AuditLog: React.FC<IAuditLogProps> = ({
  auditLogTableColumnChange,
  auditLogUiSettings,
  currentTableHeaders,
  definedUser,
  jiggleDirtyState,
  q: query,
  rqps,
  updateUISettings,
  updateUrl,
}) => {
  useBackfill({
    query,
    requiredQueryParams: rqps,
    storedQueryParams: auditLogUiSettings,
    updateUrl,
  });
  const accountId = useSelector((state: IState) => state.user.account.id);

  const [filterValues, setFilterValues] = useState<IFilterValues>(
    INITIAL_FILTER_VALUES
  );
  const filters = resolveFilters(filterValues);

  function onPaginationChange(update: Partial<ILocationQuery>) {
    updateUISettings({
      domain: 'auditLog',
      update,
    });
    updateUrl(update);
  }

  const handleFilterChange = (filterEditors: IFilterEditor[]) => {
    const newFilterValues: IFilterValues = {};
    filterEditors.forEach(({ name, value }) => {
      newFilterValues[name] = value;
    });
    setFilterValues(newFilterValues);
  };

  const handleColumnChange = (...args: unknown[]) => {
    jiggleDirtyState();
    auditLogTableColumnChange(...args);
  };

  function handleDownload(
    filename: string,
    currentTableHeaders: IColumnHeader[],
    uiSettings: { [key: string]: unknown }
  ) {
    return () => {
      const tableHeaders = currentTableHeaders
        .filter((tableHeader) => {
          return tableHeader.show && tableHeader.showInContextMenu;
        })
        .map((tableHeader) => tableHeader.id);

      downloadAuditLogCsv(uiSettings, tableHeaders)
        .then((resp: AxiosResponse) => {
          const link = document.createElement('a');
          link.href = resp.data;
          link.setAttribute('download', `${filename}`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error: unknown) => console.error(error));
    };
  }

  return (
    <div>
      <div className="view__header">
        <h1>Audit Log</h1>
      </div>
      <Table
        columnHeaders={defaultAuditLogTableHeaders}
        definedUser={definedUser}
        fetchTableData={() => {
          return tableQuery(
            accountId,
            filters
          )(query as unknown as ILocationQuery);
        }}
        filters={filters}
        footerComponents={() => (
          <CloudDownload
            style={{ cursor: 'pointer', fontSize: 30 }}
            onClick={handleDownload(
              'audit-log.csv',
              currentTableHeaders,
              auditLogUiSettings
            )}
          />
        )}
        heightBuffer={290}
        rowExpandHeight={270}
        rowDetails={(details) => <RowDetails data={details} />}
        rowMapping={auditLogRowMapping}
        onColumnChange={handleColumnChange}
        onFilterValueChange={handleFilterChange as () => void}
        onPaginationChange={onPaginationChange}
        query={query as unknown as ILocationQuery}
        tableId="auditLog"
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  auditLogUiSettings: getUISettingsWithout(state, 'auditLog', ['tableHeaders']),
  columnHeadersHash: getChangedTableHeadersHash(state, 'auditLog'),
  currentTableHeaders: getUISettingsTableHeaders(state, 'auditLog'),
  scopes: state.auth.user.scopes,
  scopeBounds: state.user.scopeBounds,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      auditLogTableColumnChange,
      updateUISettings,
    },
    dispatch
  );

export default compose(
  withRouter,
  withDirtyState(),
  connect(mapStateToProps, mapDispatchToProps)
)(
  withMemoizedProps(AuditLog, [
    'currentTableHeaders',
    'dirtyState',
    'q',
    'updateUISettings',
    'updateUrl',
  ])
);
