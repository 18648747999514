import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

// ui
import DownloadCard from 'components/main/downloads/DownloadCard';

// services
import { getZDevDownloads, getZDevDownloadByFilename } from 'api/zDevService';

const initialState = {
  downloads: [],
};

class BuildsDownloads extends Component {
  constructor(props) {
    super(props);

    this.handleDownload = this.handleDownload.bind(this);

    this.state = initialState;
  }

  componentDidMount() {
    getZDevDownloads()
      .then(({ data }) => {
        this.setState({
          downloads: data,
        });
      })
      .catch(error => console.error(error));
  }

  render() {
    const { props, state } = this;

    return (
      <React.Fragment>
        <div className="view__header">
          <h1>Download Plugins for Continuous Integration</h1>
        </div>
        <div className={props.classes.downloadsContainer}>
          <div className={props.classes.osWrapper}>
            {state.downloads.map(download => {
              return (
                <DownloadCard
                  text={`${download.name}`}
                  description={`${download.description}`}
                  callFetchDownload={this.handleDownload(download.name)}
                />
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleDownload(filename) {
    return () => {
      getZDevDownloadByFilename({ filename })
        .then(resp => {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${filename}`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(error => console.error(error));
    };
  }
}

const styles = () => {
  return {
    downloadsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'spaceAround',
      flexWrap: 'wrap',
    },
    osWrapper: {
      marginRight: 8,
      flexGrow: 1,
      paddingBottom: '15px',
    },
  };
};

export default withStyles(styles, { withTheme: true })(BuildsDownloads);
