import { fetchThreatVectors } from 'api/apis';
import { IQueryParams } from '../common/models';
import { IVector } from './models';
import { isArray } from 'lodash';
import { IFilterEditor } from 'components/UI/Table/models';
import { ammendFiltersFromQuery, createRSQLParams } from '../Threats/utils';

export const VECTOR_MAPPING: IVector[] = [
  {
    name: 'Device',
    order: 2,
    vectorType: 2,
    count: 0,
    critical: 0,
    elevated: 0,
  },
  {
    name: 'Network',
    order: 3,
    vectorType: 1,
    count: 0,
    critical: 0,
    elevated: 0,
  },
  {
    name: 'Malware',
    order: 4,
    vectorType: 3,
    count: 0,
    critical: 0,
    elevated: 0,
  },
];

export const EMPTY_VECTORS: IVector[] = [
  {
    count: 0,
    critical: 0,
    elevated: 0,
    name: 'Total',
    order: 1,
    vectorType: 0,
  },
  ...VECTOR_MAPPING
];

export const checkForRevisedMappingName = (field: string | number | undefined) => {
  if (typeof field !== 'string') {
    return field;
  }
  switch (field) {
    case 'teamId':
    case 'threatTypeName':
    case 'deviceId':
    case 'device.zdeviceId':
    case 'device.mdmDeviceId':
    case 'device.mamDeviceId':
    case 'groupId':
    case 'zappInstance.externalTrackingId1':
    case 'appInstance.externalTrackingId2':
    case 'id':
    case 'appInstance.id':
    case 'zappInstance.bundleId':
    case 'zappInstance.groupId':
    case 'zappInstance.name':
    case 'zappInstance.version':
    case 'zappInstance.buildNumber':
    case 'zappInstance.zversion':
    case 'appInstance.zbuildNumber':
    case 'os':
    case 'generalInfo.ssid':
    case 'generalInfo.bssid':
    case 'generalInfo.gatewayMac':
    case 'sideLoadedAppInfo.developer':
    case 'malwareInfo.bundleId':
    case 'malwareInfo.processName':
    case 'malwareInfo.filename':
    case 'malwareInfo.fileHash':
    case 'zeventId':
    case 'activationName':
    case 'phishingUrl':
    case 'contentCategoryName':
      return `${field}.keyword`;
    case 'summary':
      return 'threatTypeId';
    case 'timestamp':
      return 'timestampInfo.timestamp';
    default:
      return field;
  }
};


const formatQueryToString = (queryObject: Partial<IQueryParams>) => {
  const queryString = Object.keys(queryObject).map((singleFilterValueName) => {
    const value = queryObject[singleFilterValueName];
    // check if value it needs keyword ---> backend query
    const revisedSingleFieldName = checkForRevisedMappingName(singleFilterValueName);
    if (isArray(value)) {
      return value.length === 1
        ? `${revisedSingleFieldName}=${value[0]}`
        : `${revisedSingleFieldName}=in=(${value.join(',')})`;
    }
    return `${revisedSingleFieldName}=in=(${value})`;
  });
  return queryString.join(';');
};
export const formatQueryFromQueryParams = ({ duration, page, size, order, orderBy, zappId, teamfilter, teamId, ...rest }: IQueryParams) => {
  const queryString = formatQueryToString({ ...rest });
  const revisedQuery = {
    query: queryString,
    duration: duration,
    page: page,
    size: size,
    order: order,
    orderBy: orderBy,
    teamId: teamId,
    zappId: zappId,
    teamFilter: teamfilter
  };
  return revisedQuery;
};


export const fetchVectors = async (module: string, rawQuery: IQueryParams, rawFilters: IFilterEditor[]) => {
  let result: IVector[] = [];
  const { filters, query } = ammendFiltersFromQuery(rawFilters, rawQuery);
  const nonNullFilters = filters.filter(
    ({ value }) =>
      typeof value === 'number' ||
      (typeof value === 'string' && value !== '') ||
      value?.[0]
  );
  if (query.orderBy === 'timestamp') {
    query.orderBy = 'timestampInfo.timestamp';
  }
  query.query = createRSQLParams(nonNullFilters);
  try {
    const buckets =
      (
        await fetchThreatVectors(
          module === 'mtd' ? { ...query, module: 'ZIPS' } : query
        )
      )?.data?.aggregations?.['filter#time range']?.['filters#vectors detected']
        ?.buckets ?? [];

    const vectors = VECTOR_MAPPING.map(({ name, order, vectorType }) => ({
      count: buckets?.[vectorType]?.doc_count ?? 0,
      critical:
        buckets?.[vectorType]?.['filters#severities']?.buckets?.['3']
          ?.doc_count ?? 0,
      elevated:
        buckets?.[vectorType]?.['filters#severities']?.buckets?.['2']
          ?.doc_count ?? 0,
      name: name,
      order: order,
      vectorType: vectorType,
    }));

    result = [
      vectors.reduce(
        (acc, curr) => ({
          ...acc,
          count: acc.count + curr.count,
          critical: acc.critical + curr.critical,
          elevated: acc.elevated + curr.elevated,
        }),
        {
          count: 0,
          critical: 0,
          elevated: 0,
          name: 'Total',
          order: 1,
          vectorType: 0,
        }
      ),
      ...vectors,
    ];
  } catch (e) {
    console.log(e);
  }
  return result;
};


