import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const styles = ({ palette, config }) => {
  return {
    wrapper: {
      background: palette.background.paper,
      outline: 'none',
      maxWidth: '50vw',
      maxHeight: 400,
      overflowY: 'scroll',
    },
    tableCell: {
      borderBottomColor: 'transparent',
    },
    tableHeaderCell: {
      color: palette.text.secondary,
      fontWeight: 'bold',
      borderBottomColor: 'transparent',
    },
    tableRow: {
      height: config.textSizes.paragon,
    },
    emptyText: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '5vh',
    },
  };
};

class RoutingTables extends Component {
  render() {
    const { classes, threat } = this.props;
    return (
      <div className={classes.wrapper}>
        <Table>
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableHeaderCell}>
                Destination
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>Flags</TableCell>
              <TableCell className={classes.tableHeaderCell}>Gateway</TableCell>
              <TableCell className={classes.tableHeaderCell}>Netif</TableCell>
              <TableCell className={classes.tableHeaderCell}>Refs</TableCell>
              <TableCell className={classes.tableHeaderCell}>Use</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {threat.routingTables.map((routingData, index) => {
              return (
                <TableRow key={index} className={classes.tableRow}>
                  <TableCell className={classes.tableCell}>
                    {routingData.destination}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {routingData.flags}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {routingData.gateway}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {routingData.netif}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {routingData.refs}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {routingData.use}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default withStyles(styles)(RoutingTables);
