export interface IZScanScript {
  controlId: string;
  controlType: string;
  controlValue?: string;
  ignore: boolean;
  order: number;
  uiScreenId: string;
}

export interface IRawParsedZScanScript {
  zScanScript: IZScanScript;
}
export interface ISubmitBuildFile {
  fullBuildFile: Blob;
  notifyUploader: string | boolean;
  setFileLoaded: (percent: number) => void;
}

export interface ISubmitUrl {
  notifyUploader: string | boolean;
  storeUrl: string;
  shouldAutoUpdateFromAppStore: string | boolean | undefined;
  value?: string;
}

export const JSON_SCHEMA_INVALID = `Error: The JSON file's schema is invalid. Please align with the sample provided and re-upload.`;

export const JSON_MULTIPLE_ELEMENTS = `Error: Multiple elements cannot have the same Order value. Please correct and re-upload.`;

export const CONTROL_VALUE_OPTIONS = [
  {
    label: 'Text View',
    value: 'TextView',
  },
  {
    label: 'Edit Text',
    value: 'EditText',
  },
  {
    label: 'Button',
    value: 'Button',
  },
  {
    label: 'Image Button',
    value: 'ImageButton',
  },
  {
    label: 'Toggle Button',
    value: 'ToggleButton',
  },
  {
    label: 'Checkbox',
    value: 'CheckBox',
  },
  {
    label: 'Radio Button',
    value: 'RadioButton',
  },
];

export interface IZScanPolicyForSelect {
  value: string;
  label: string;
}

export interface IMetadata {
  key: string;
  value: string;
  id: string;
}
export interface ITeam {
  value: string;
  label: string;
}

export interface IZScanUploadFormValues {
  rawJSONData?: any; //string | undefined;
  metadata?: IMetadata[];
  distributionList?: string[] | string;
  team?: any; //ISelectItem
  teamId: string;
}

export interface IRowData {
  rowData: IData;
}
export interface IData {
  emulatorScript: string;
  table: string;
  id: string;
  teamId: string;
  name: string;
  bundleId: string;
  distributionList?: string | string[];
  metadata: IMetadata[];
}

export interface IZScanPolicy {
  defaultPolicy: boolean;
  id: string;
  name: string;
}

export interface IMatch {
  path: string;
  url: string;
  params: any;
  isExact: boolean;
}

export interface IHistory {
  action: string;
  length: number;
  location: ILocation;
  push: (location: string) => void;
}

export interface ILocation {
  key: string;
  hash: string;
  pathname: string;
  query: IQuery;
  search: string;
}

export interface IQuery {
  page: string;
  order: string;
  orderBy: string;
  size: string;
}

export interface IFiledata {
  lastModified: string;
  lastModifiedDate: string;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
}

export interface IZScanAppById {
  id: string;
  accountId: string;
  teamId: string;
  name: string;
  bundleId: string;
  platform: string;
  appSource: string;
  assessmentCount: number;
  findingCount: number;
  criticalFindingCount: number;
  privacyScore: number;
  securityScore: number;
  created: number;
  modified: number;
  lastScanned: number;
  lastAssessmentId: string;
  metadata: any[];
  distributionList: string[];
  lastAssessmentStatistics: ILastAssessmentStatistics;
  emulatorScript: string;
}

export interface ILastAssessmentStatistics {
  version: string;
  build: string;
  scanDate: number;
  findingCount: number;
  criticalCount: number;
  securityCount: number;
  privacyCount: number;
  bestPracticesCount: number;
  privacyScore: number;
  securityScore: number;
  md5: string;
  sha1: string;
  sha256: string;
  complianceStatistics: IComplianceStatistics;
}

export interface IComplianceStatistics {
  complianceFindingCount: number;
  cwe: number;
  cve: number;
  owasp: number;
  masvs: number;
  gdpr: number;
  hipaa: number;
  pci: number;
  niap: number;
}
