/**
 * Warning! This file is not meant to be updated frequently!
 * Please make sure you actually need to modify THIS file before doing so!
 *
 * You must meet the following criteria before modifying this file...
 * 1. You are adding an official Mui supported property https://material-ui.com/customization/default-theme/
 * 2. You are changing something that will affect every theme
 * 3. You think that the amount of configuration it takes to replicate how css works is silly, and putting css in js is, in-fact, potentially... very silly
 */

import _config from './_config';

export default {
  shape: {
    borderRadius: 3,
  },
  typography: {
    fontSize: _config.textSizes.normal,
    fontFamily: _config.fonts.stackSans,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    useNextVariants: true,
  },

  // holds values for calculation later
  sizing: {
    sidebar: {
      maxWidth: 170,
    },
    mainContent: {
      padding: 20,
    },
  },
};
