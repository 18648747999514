export interface IThreatType {
  [key: number]: {
    id: number;
    color: string;
    name: string;
    description: string;
  };
}

const threatColors = {
  // Watery Blue-Greens
  blueBlack: '#021c1e',
  cadetBlue: '#004445',
  rain: '#2C7873',
  greenery: '#6FB98F',
  // Primary Colors Vibrant Twist
  sky: '#375E97',
  sunset: '#FB6542',
  sunflower: '#FFBB00',
  grass: '#3F681C',
  // 12 - Surft & Turf
  sandStone: '#F4CC70',
  burntOrange: '#DE7A22',
  sea: '#20948B',
  lagood: '#6AB187',
  // 16 - Day & Night
  darkNavy: '#011A27',
  blueberry: '#063852',
  tangerine: '#F0810F',
  daffodil: '#E6DF44',
  // 20 - Bright & Tropical
  hotPink: '#F52549',
  bubblegum: '#FA6775',
  pallen: '#FFD64D',
  chartreuse: '#9BC01C',
  // 22 - Bold Berries
  eggplant: '#50312F',
  strawberry: '#CB0000',
  lemonLime: '#E4EA8C',
  basilGreen: '#3F6C45',
  // 23 - Summer Sunflower
  blue: '#34888C',
  leafyGreen: '#7CAA2D',
  sunflower2: '#F5E356',
  seeds: '#CB6318',
  // 25 - Timeless Nautical
  navy: '#00293C',
  peacockBlue: '#1E656D',
  ivory: '#F1F3CE',
  candyApple: 'F62A00',
  // 26 - Neutral & Versatile
  slate: '#626D71',
  ceramic: '#CDCDC0',
  latte: 'DDBC95',
  coffee: '#B38867',
  // 28 - Garden Fresh
  carrot: '#EE693F',
  tangerine2: '#F69454',
  offWhite: '#FCFDFE',
  pearGreen: '#739F3D',
  // 29 - Summer Barbeque
  mint: '#B9D9C3',
  charred: '#752A07',
  toasted: '#FBCB7B',
  tomato2: '#EB5E30',
  // 30 - Berry Blues
  midnightBlue: '#1E1F26',
  indigoInk: '#283655',
  blueberry2: '#4D648D',
  periwinkle: '#D0E1F9',
  // 31 - Lemonade Stand
  cherryRed: '#F70025',
  ice: '#F7EFE2',
  marmalade: '#F25C00',
  orangeJuice: '#F9A603',
  // 32 - Serene & Spa-Like
  sage: '#A1BE95',
  honeydew: '#E2DFA2',
  bluebell: '#92AAC7',
  raspberry: '#ED5752',
  // 33 - Fun & Tropical
  electricBlue: '#4897D8',
  banana: '#FFDB5C',
  watermelon: '#FA6E59',
  canteloupe: '#F8A055',
  // 35 - Pastels
  babyBlue: '#C1E1DC',
  peach: '#FFCCAC',
  butter: '#FFEB94',
  butterscotch: '#FDD475',
  // 36 - Bold & Cultured
  fig: '#4C3F54',
  appleRed: '#D13525',
  swissCheese: '#F2C057',
  basil: '#486824',
  // 41 - Jewel Tones
  purpleOnion: '#7F152E',
  ripeTomato: '#D61800',
  lemon: '#EDAE01',
  spices: '#E94F08',
  // 42 - Polished & Inviting
  linen: '#EAE2D6',
  oyster: '#D5C3AA',
  pewter: '#867666',
  lemonTea: '#E1B80D',
  // 46 - Chocolaty Browns
  cocoa: '#301B28',
  chocolate: '#523634',
  toffee: '#B6452C',
  frosting: '#DDC5A2',
  // 49 - Violet Sunset
  stoneground: '#4B4345',
  lapis: '#556DAC',
  salmon: '#F79B77',
  peppercorn: '#755248',
  // 51 - Grecian Holiday
  grecianBlue: '#2988BC',
  sea2: '#2F496E',
  plaster: '#F4EADE',
  coral: '#ED8C72',
  // 55 - Misty Greens
  forest: '#04202C',
  evergreen: '#304040',
  pine: '#5B7065',
  fog: '#C9D1C8',
};

const ThreatTypes: IThreatType = {
  0: {
    id: 0,
    color: threatColors.blueBlack,
    name: 'TCP Scan',
    description:
      'A reconnaissance scan using the TCP protocol that is oftentimes an indicator of a malicious attacker searching for a device vulnerable for a network attack such as MITM.',
  },
  1: {
    id: 1,
    color: threatColors.cadetBlue,
    name: 'UDP Scan',
    description:
      'A reconnaissance scan using the UDP protocol that is oftentimes an indicator of a malicious attacker searching for a device vulnerable for a network attack such as MITM.',
  },
  2: {
    id: 2,
    color: threatColors.rain,
    name: 'IP Scan',
    description:
      'A reconnaissance scan using the IP protocol that is oftentimes an indicator of a malicious attacker searching for a device vulnerable for a network attack such as MITM.',
  },
  3: {
    id: 3,
    color: threatColors.greenery,
    name: 'ARP Scan',
    description:
      'A reconnaissance scan using the ARP protocol that is oftentimes an indicator of a malicious attacker searching for a device vulnerable for a network attack such as MITM.',
  },
  4: {
    id: 4,
    color: threatColors.sky,
    name: 'MITM - ARP',
    description:
      'Man-in-the-Middle attack using ARP table poisoning where a malicious attacker can hijack traffic and steal credentials or deliver malware to the device.',
  },
  5: {
    id: 5,
    color: threatColors.sunset,
    name: 'SYN Scan',
    description:
      'A reconnaissance scan using the SYN protocol (also known as Stealth Scan) that is oftentimes an indicator of a malicious attacker searching for a device vulnerable for a network attack such as MITM.',
  },
  6: {
    id: 6,
    color: threatColors.sunflower,
    name: 'Email Suspected',
    description: '',
  },
  7: {
    id: 7,
    color: threatColors.grass,
    name: 'File Suspected',
    description: '',
  },
  8: {
    id: 8,
    color: threatColors.sandStone,
    name: 'Unknown',
    description: '',
  },
  9: {
    id: 9,
    color: threatColors.burntOrange,
    name: 'Phishing Protection - Link Tapped',
    description: 'A potentially malicious URL was tapped on the device.',
  },
  10: {
    id: 10,
    color: threatColors.sea,
    name: 'Abnormal Process Activity',
    description:
      'Detected abnormal activity. Your device is being monitored for any attacks.',
  },
  11: {
    id: 11,
    color: threatColors.lagood,
    name: 'MITM - ICMP Redirect',
    description:
      'Man-in-the-Middle attack using ICMP protocol where a malicious attacker can hijack traffic and steal credentials or deliver malware to the device.',
  },
  12: {
    id: 12,
    color: threatColors.darkNavy,
    name: 'EOP',
    description:
      'A malicious process that results in the elevation of privileges on the mobile device, which allows the attacker to take full control of the device.',
  },
  13: {
    id: 13,
    color: threatColors.blueberry,
    name: 'Suspicious Android App',
    description:
      'A known malicious app that attempts to take control of the device in some manner (e.g. elevate privileges, spyware, etc).',
  },
  14: {
    id: 14,
    color: threatColors.tangerine,
    name: 'MITM - SSL Strip',
    description:
      'Man-in-the-Middle attack using SSL stripping that allows a malicious attacker to change HTTPS traffic to HTTP so they can hijack traffic and steal credentials or deliver malware to the device.',
  },
  15: {
    id: 15,
    color: threatColors.daffodil,
    name: 'Proxy Change',
    description:
      'Proxy configuration change on the mobile device that can be indicative of sending traffic to a non-intended destination.',
  },
  16: {
    id: 16,
    color: threatColors.hotPink,
    name: 'Gateway Change',
    description:
      'Gateway configuration change on the mobile device that can be indicative of sending traffic to a non-intended destination.',
  },
  17: {
    id: 17,
    color: threatColors.bubblegum,
    name: 'DNS Change',
    description:
      'DNS configuration change on the mobile device. If the DNS change happened in your own network to an unknown DNS server - it is likely to be a MITM attempt.',
  },
  18: {
    id: 18,
    color: threatColors.pallen,
    name: 'TCP Scan6',
    description:
      'A reconnaissance scan using the TCP6 protocol that is oftentimes an indicator of a malicious attacker searching for a device vulnerable for a network attack such as MITM.',
  },
  19: {
    id: 19,
    color: threatColors.chartreuse,
    name: 'UDP Scan6',
    description:
      'A reconnaissance scan using the UDP protocol that is oftentimes an indicator of a malicious attacker searching for a device vulnerable for a network attack such as MITM.',
  },
  20: {
    id: 20,
    color: threatColors.eggplant,
    name: 'IP Scan6',
    description:
      'A reconnaissance scan using IPv6. It is oftentimes an indicator of a malicious attacker searching for a device vulnerable for a network attack such as MITM.',
  },
  21: {
    id: 21,
    color: threatColors.strawberry,
    name: 'Access Point Change',
    description:
      'Legitimate access point change on a network with multiple routers.',
  },
  22: {
    id: 22,
    color: threatColors.lemonLime,
    name: 'Suspicious SMS',
    description: 'Attack via SMS by using a URL link to a malicious APK file.',
  },
  23: {
    id: 23,
    color: threatColors.basilGreen,
    name: 'File system changed',
    description: 'A normal file system change.',
  },
  24: {
    id: 24,
    color: threatColors.blue,
    name: 'Untrusted Profile',
    description:
      'An untrusted profile is a profile installed on one or more devices and is deemed unsafe to have installed on your devices. An untrusted profile installed on devices could be used to control devices remotely, monitor and manipulate user activities, and/or hijack a users traffic.',
  },
  25: {
    id: 25,
    color: threatColors.leafyGreen,
    name: 'Unknown sources download config changed',
    description:
      'App downloads from locations other than the Google Play store are enabled.',
  },
  26: {
    id: 26,
    color: threatColors.sunflower2,
    name: 'Rogue Cellular Tower MITM Attack',
    description:
      'Man-in-the-Middle attack using a rogue cellular tower (femtocell or basesation) where a malicious attacker can hijack traffic and steal credentials or deliver malware to the device.',
  },
  27: {
    id: 27,
    color: threatColors.seeds,
    name: 'MITM - Traffic Tampering',
    description:
      'Man-in-the-Middle attack that allows a malicious attacker to change the content of the network traffic and deliver malware to the device.',
  },
  28: {
    id: 28,
    color: threatColors.navy,
    name: 'A benevolent pentesting APP',
    description:
      'Mobile app designed to do penetration testing functions.  Can be benevolent if part of the normal security team procedures.',
  },
  29: {
    id: 29,
    color: threatColors.peacockBlue,
    name: 'SMS Config Changed',
    description:
      'Silent SMS push from a malicious attacker that allows them to change the configuration of the device, thereby controlling the device.',
  },
  30: {
    id: 30,
    color: threatColors.ivory,
    name: 'Rogue WiFi Tower MITM',
    description:
      'Man-in-the-Middle attack using a rogue WiFi access point where a malicious attacker can hijack traffic and steal credentials or deliver malware to the device.',
  },
  31: {
    id: 31,
    color: threatColors.candyApple,
    name: 'MTD Application closed by user',
    description: 'Occurs when the app is closed by the user.',
  },
  32: {
    id: 32,
    color: threatColors.slate,
    name: 'Stagefright Anomaly',
    description:
      'Detected an anomaly in media-server (Stagefright). Further investigation to this threat is required.',
  },
  33: {
    id: 33,
    color: threatColors.ceramic,
    name: 'Media Server Anomaly',
    description:
      'Uncommon modification to mediaserver by the device vendor. This event does not necessarily mean an attack. Continuing to monitor.',
  },
  34: {
    id: 34,
    color: threatColors.latte,
    name: 'Stagefright Exploit',
    description:
      'Detected a successful exploitation of mediaserver (Stagefright). This device should be treated as compromised.',
  },
  35: {
    id: 35,
    color: threatColors.coffee,
    name: 'MITM - Fake SSL certificate',
    description:
      'Man-in-the-Middle attack using fake certificate where a malicious attacker can hijack traffic and steal credentials or deliver malware to the device.',
  },
  36: {
    id: 36,
    color: threatColors.carrot,
    name: 'Network Handoff',
    description:
      'Network handoff allows a device to alter routing on a network, potentially allowing for a man-in-the-middle attack.',
  },
  37: {
    id: 37,
    color: threatColors.tangerine2,
    name: 'System Tampering',
    description:
      'System Tampering is a process of removing security limitations put in by the device manufacturer and indicates that the device is fully compromised and cannot longer be trusted.',
  },
  38: {
    id: 38,
    color: threatColors.offWhite,
    name: 'Rogue Access Point',
    description:
      'Rogue Access Points exploit a device vulnerability to connect to a previously known WiFi network by masking preferred/known networks.',
  },
  39: {
    id: 39,
    color: threatColors.pearGreen,
    name: 'Device jailbreaking / rooting',
    description:
      'Jailbreaking and rooting are the processes of gaining unauthorized access or elevated privileges on a system. \nJailbreaking and rooting can potentially open security holes that may have not been readily apparent, or undermine the device’s built-in security measures.',
  },
  40: {
    id: 40,
    color: threatColors.mint,
    name: 'Stagefright Vulnerability',
    description:
      'Stagefright vulnerability indicates the device is on an OS patch version susceptible to compromise.',
  },
  41: {
    id: 41,
    color: threatColors.charred,
    name: 'Vulnerability mitigated',
    description: 'The detected device vulnerability has been mitigated.',
  },
  42: {
    id: 42,
    color: threatColors.toasted,
    name: 'Suspicious iOS App',
    description:
      'A known malicious app that attempts to take control of the device in some manner (e.g. elevate privileges, spyware, etc).',
  },
  43: {
    id: 43,
    color: threatColors.tomato2,
    name: 'Daemon Anomaly',
    description:
      'Daemon Anomaly indicates abnormal system process activities which could indicate that the device has been exploited.',
  },
  44: {
    id: 44,
    color: threatColors.midnightBlue,
    name: 'USB Debugging Mode',
    description:
      'USB Debugging is an advanced configuration option intended for development purposes only. By enabling USB Debugging, your device can accept commands from a computer when plugged into a USB connection.',
  },
  45: {
    id: 45,
    color: threatColors.indigoInk,
    name: 'Suspicious Profile',
    description:
      'A suspicious profile is a new profile introduced to the environment and is not explicitly trusted or untrusted. It is recommended that the Administrator review the Profile and mark the profile as trusted or untrusted.',
  },
  46: {
    id: 46,
    color: threatColors.blueberry2,
    name: 'Unclassified Certificate',
    description: '',
  },
  47: {
    id: 47,
    color: threatColors.periwinkle,
    name: 'Developer Options',
    description:
      'Developer Options is an advanced configuration option intended for development purposes only. When enabled, the user has the option to change advanced settings, compromising the integrity of the device settings.',
  },
  48: {
    id: 48,
    color: threatColors.cherryRed,
    name: 'Internal Network Access',
    description:
      'Detected an app connecting to private, internal servers. It is uncommon for public applications to connect to internal servers. Public applications connecting to internal servers is considered suspicious behavior and should be investigated immediately for the possible threat of malware installed on the device and the risk of data leakage.',
  },
  49: {
    id: 49,
    color: threatColors.ice,
    name: 'Device Encryption',
    description:
      'Device Encryption notifies an administrator when a device is not setup to use encryption to protect device content.',
  },
  50: {
    id: 50,
    color: threatColors.marmalade,
    name: 'Device Pin',
    description:
      'Device Pin notifies the administrator when a device is not setup to use a PIN code or password to control access to the device.',
  },
  51: {
    id: 51,
    color: threatColors.orangeJuice,
    name: 'Vulnerable Android Version',
    description:
      'MTD has detected that the Android version installed on your device is not up-to-date. The outdated operating system exposes the device to known vulnerabilities and the threat of being exploited by malicious actors. It is advised to update your operating system immediately.',
  },
  52: {
    id: 52,
    color: threatColors.sage,
    name: 'Vulnerable iOS Version',
    description:
      'MTD has detected that the iOS version installed on your device is not up-to-date. The outdated operating system exposes the device to known vulnerabilities and the threat of being exploited by malicious actors. It is advised to update your operating system immediately.',
  },
  53: {
    id: 53,
    color: threatColors.honeydew,
    name: 'Windows Not Updated',
    description: '',
  },
  54: {
    id: 54,
    color: threatColors.bluebell,
    name: 'Kernel Anomaly',
    description: '',
  },
  55: {
    id: 55,
    color: threatColors.raspberry,
    name: 'Test Threat Rogue SSL',
    description: '',
  },
  56: {
    id: 56,
    color: threatColors.electricBlue,
    name: 'Test Threat Rogue Network',
    description: '',
  },
  57: {
    id: 57,
    color: threatColors.banana,
    name: 'Test Threat Device Compromised',
    description: '',
  },
  58: {
    id: 58,
    color: threatColors.watermelon,
    name: 'Test Threat Malicious App',
    description: '',
  },
  59: {
    id: 59,
    color: threatColors.canteloupe,
    name: 'Dynamic Library Injection',
    description: '',
  },
  60: {
    id: 60,
    color: threatColors.babyBlue,
    name: 'Cogito APK Detection',
    description: '',
  }, // Vulnerable iOS Version' },
  61: {
    id: 61,
    color: threatColors.peach,
    name: 'SELinux Disabled',
    description:
      'Security-enhanced Linux (SELinux) is a security feature in the operating system that helps maintain the integrity of operating system. If SELinux has been disabled, the integrity of the operating system may be compromised and should be investigated immediately.',
  },
  62: {
    id: 62,
    color: threatColors.butter,
    name: 'Suspicious Root Process',
    description:
      'Suspicious process or processes running as root on the device.',
  },
  63: {
    id: 63,
    color: threatColors.butterscotch,
    name: 'Suspicious Network Connection',
    description: 'Suspicious network connection established on the device.',
  },
  64: {
    id: 64,
    color: threatColors.fig,
    name: 'System Integrity Failure',
    description:
      'MTD has identified an integrity failure for one or more files on the system.',
  },
  65: {
    id: 65,
    color: threatColors.appleRed,
    name: 'Rogue Access Point : Nearby',
    description:
      'Rogue Access Points exploit a device vulnerability to connect to a previously known WiFi network by masking preferred/known networks.',
  },
  66: {
    id: 66,
    color: threatColors.swissCheese,
    name: 'Unsecured WiFi Network',
    description:
      'Unsecured Wifi Networks are not protected by encryption or authentication protocols and are open to attackers.',
  },
  67: {
    id: 67,
    color: threatColors.basil,
    name: 'Captive Portal',
    description:
      'Captive Portal networks route traffic through a single proxy (portal), potentially opening up the traffic to monitoring.',
  },
  68: {
    id: 68,
    color: threatColors.purpleOnion,
    name: 'MITM',
    description:
      'Man-in-the-Middle attack where a malicious attacker can hijack traffic and steal credentials or deliver malware to the device.',
  },
  69: {
    id: 69,
    color: threatColors.ripeTomato,
    name: 'BlueBorne Vulnerability',
    description:
      'MTD has detected this device is vulnerable to BlueBorne, an attack leveraging Bluetooth connections to penetrate and take control of targeted devices. To avoid any sort of risk from BlueBorne, it is highly recommended that the user turn off Bluetooth permanently until an update is available from your device manufacturer or wireless carrier. For those users that still require the use of Bluetooth, it is recommended that Bluetooth is turned off until it is needed and only in a trusted and secure area.',
  },
  70: {
    id: 70,
    color: threatColors.lemon,
    name: 'Android Device - Compatibility Not Tested By Google',
    description:
      "The profile of the Android device does not match the profile of any devices that have passed Google's Android compatibility testing.",
  },
  71: {
    id: 71,
    color: threatColors.spices,
    name: 'Android Device - Possible Tampering',
    description: 'The Android device may have been tampered with.',
  },
  72: {
    id: 72,
    color: threatColors.linen,
    name: 'Phishing Protection - Link Visited',
    description:
      'A potentially malicious URL was tapped on the device. The user was warned of the potential danger of visiting the linked site and chose to continue on to the site after the warning.',
  },
  73: {
    id: 73,
    color: threatColors.oyster,
    name: 'Accessed Blocked Domain',
    description: '',
  },
  74: {
    id: 74,
    color: threatColors.pewter,
    name: 'Device Service Compromised',
    description: '',
  },
  75: {
    id: 75,
    color: threatColors.lemonTea,
    name: 'App Tampering',
    description:
      'Existing MTD or zDefend app libraries may have been modified, or a foreign library may have been injected into zDefend or MTD.',
  },
  76: {
    id: 76,
    color: threatColors.cocoa,
    name: 'Sideloaded App(s)',
    description:
      'Sideloaded apps are installed independently of an official app store and can present a security risk.',
  },
  77: {
    id: 77,
    color: threatColors.chocolate,
    name: 'SSL / TLS Downgrade',
    description:
      'SSL/TLS downgrades force apps to use old encryption protocols. These protocols may be vulnerable to attacks that allow third parties to view encrypted information.',
  },
  78: {
    id: 78,
    color: threatColors.toffee,
    name: 'MTD is not activated on both work and personal profiles - Android for Work',
    description:
      'MTD is not activated on both the personal and work profiles on this device. Install and activate the application in both locations to ensure full device protection.',
  },
  79: {
    id: 79,
    color: threatColors.frosting,
    name: 'Danger Zone Connected',
    description:
      'The device has connected to a wifi network where malicious attacks have been observed.',
  },
  80: {
    id: 80,
    color: threatColors.stoneground,
    name: 'Danger Zone Nearby',
    description:
      'The device is near a wifi network where malicious attacks have been observed.',
  },
  81: {
    id: 81,
    color: threatColors.lapis,
    name: 'Dynamic Code Loading',
    description:
      'Detected dynamic code loading from a process. Your device is being monitored for any attacks.',
  },
  82: {
    id: 82,
    color: threatColors.salmon,
    name: 'Silent App Installation',
    description:
      'MTD detected that an application was silently installed on the device.',
  }, // SILENT_APP_INSTALLATION',
  83: {
    id: 83,
    color: threatColors.peppercorn,
    name: 'Suspicious File',
    description:
      'MTD has detected a suspicious file, which may contain malicious or vulnerable code and should not be opened or executed.',
  }, // SUSPICIOUS_FILE',
  84: {
    id: 84,
    color: threatColors.grecianBlue,
    name: 'Google Play Protect Disabled',
    description:
      'Google Play Protect has been disabled on this device. Google Play Protect helps protect the device from malicious apps and should be re-enabled.',
  }, // 'GOOGLE_PLAY_PROTECT_DISABLED',
  85: {
    id: 85,
    color: threatColors.sea2,
    name: 'Android Debug Bridge Apps Not Verified',
    description:
      'Apps installed via ADB are not required to be verified. This may allow malicious apps to be installed on the device.',
  }, // ANDROID_DEBUG_BRIDGE_APPS_NOT_VERIFIED',
  86: {
    id: 86,
    color: threatColors.plaster,
    name: 'Over The Air Updates Disabled',
    description:
      "Over-the-air (OTA) updates have been disabled on this device. OTA updates help keep a device's software up to date and more secure.",
  }, // OVER_THE_AIR_UPDATES_DISABLED',
  87: {
    id: 87,
    color: threatColors.coral,
    name: 'Always On VPN App Set',
    description:
      'An app has been configured as an always-on VPN on this device. The app may monitor all communications the device makes to the Internet.',
  }, // ALWAYS_ON_VPN_APP_SET',
  88: {
    id: 88,
    color: threatColors.forest,
    name: 'Vulnerable Non Upgradeable iOS Version',
    description:
      'MTD detected a device running a vulnerable iOS version. However, the device is not eligible to for an operating system upgrade at this time.',
  }, // VULNERABLE_NON_UPGRADEABLE_IOS_VERSION',
  89: {
    id: 89,
    color: threatColors.evergreen,
    name: 'Vulnerable Non Upgradeable Android Version',
    description:
      'MTD detected a device running a vulnerable Android version. However, the device is not eligible to for an operating system upgrade at this time.',
  }, // VULNERABLE_NON_UPGRADEABLE_ANDROID_VERSION',
  93: {
    id: 93,
    color: threatColors.evergreen,
    name: 'Out of Compliance App',
    description:
      'This threat is sent to a device when apps marked Out of Compliance are found on the device.',
  },
  94: {
    id: 94,
    color: threatColors.evergreen,
    name: 'iTunes Wi-Fi Sync Enabled',
    description: 'iTunes Wi-Fi Sync Enabled.',
  },
  100: {
    id: 100,
    color: threatColors.pine,
    name: 'Inactive Device',
    description:
      'Occurs whenever the device does not communicate with the server for a certain amount of time.',
  },
  103: {
    id: 103,
    color: threatColors.appleRed,
    name: 'App Debug Enabled',
    description:
      'An app with a debugger attached can pose a risk and allow an attacker to control and manipulate the underlying app functions.',
  },
  104: {
    id: 104,
    color: threatColors.appleRed,
    name: 'App Running on Emulator',
    description:
      'An app running on an emulator can pose a risk and allow an attacker to control and manipulate the underlying operating environment.',
  },
  121: {
    id: 121,
    color: threatColors.appleRed,
    name: 'Device Compromised via Network Based Attacks',
    description:
      'The device was compromised by a sophisticated kill chain attack that started at the network and ended leaving the device compromised.',
  },
  122: {
    id: 122,
    color: threatColors.appleRed,
    name: 'Device Compromised via Malicious App',
    description:
      'The device was compromised by a sophisticated kill chain attack that started with a malicious app and ended leaving the device compromised.',
  },
  123: {
    id: 123,
    color: threatColors.appleRed,
    name: 'Device Compromised via Phishing Attack',
    description:
      'The device was compromised by a sophisticated kill chain attack that started with a phishing threat and ended leaving the device compromised.',
  },
  124: {
    id: 124,
    color: threatColors.appleRed,
    name: 'Device Compromised via iOS malicious Profile',
    description:
      'The device was compromised by a sophisticated kill chain attack that started with a malicious iOS profile and ended leaving the device compromised.',
  },
  125: {
    id: 125,
    color: threatColors.appleRed,
    name: 'Compromised Network',
    description:
      'A pattern of threats occurred that indicates the device is connected to a compromised network. Sensitive data on the device may be intercepted and could be monitored and modified by an unauthorized party.',
  },
  130: {
    id: 130,
    color: threatColors.cherryRed,
    name: 'Pegasus Spyware',
    description: 'The Pegasus spyware has been detected on the device. Pegasus is a surveillance tool that is used to monitor and collect information from the device.'
  },
  132: {
    id: 132,
    color: threatColors.cherryRed,
    name: 'Site Warning - Link Tapped',
    description: 'Website content not approved by your organization was tapped on the device.'
  },
  133: {
    id: 133,
    color: threatColors.cherryRed,
    name: 'Site Warning - Link Visited',
    description: 'A user tapped on website content not approved by your organization. The user was warned the website content does not comply with your organization\'s policies and chose to continue to the website after the warning.'
  },
  134: {
    id: 134,
    color: threatColors.cherryRed,
    name: 'Site Blocked',
    description: 'A user tapped on website content not approved by your organization and the site was blocked.'
  },
  135: {
    id: 135,
    color: threatColors.cherryRed,
    name: 'Risky Site - Link Tapped',
    description: 'A potentially malicious website address (URL) link was tapped on the device.'
  },
  136: {
    id: 136,
    color: threatColors.cherryRed,
    name: 'Risky Site - Link Visited',
    description: 'A user tapped a potentially malicious link on the device. The user was warned of potential danger with the linked site, and chose to continue to the website after the warning.'
  },
  137: {
    id: 137,
    color: threatColors.cherryRed,
    name: 'Risky Site Blocked',
    description: 'A potentially malicious website address (URL) link was blocked on the device.'
  },
  138: {
    id: 138,
    color: threatColors.cherryRed,
    name: 'MITM - Fake SSL Certificate - Self Signed',
    description: 'A man-in-the-middle attack occurred using a fake self-signed certificate. This is when a malicious attacker can hijack traffic, steal credentials, and deliver malware to the device.'
  },
  141: {
    id: 141,
    color: threatColors.cherryRed,
    name: 'Tag Tracker Detected',
    description: 'A tag tracker is detected. This tag could be tracking the user\'s location. If this tag is not known to the user, it should be disabled.'
  },
  142: {
    id: 142,
    color: threatColors.appleRed,
    name: 'Storage Permission Required',
    description: 'The storage permission is required by the app to scan the device\'s local storage to identify risky or malicious apps that may steal personal or sensitive information.',
  },
  143: {
    id: 143,
    color: threatColors.cherryRed,
    name: 'Link Verification Disabled - On-device VPN',
    description: 'Link verification using the on-device VPN is disabled on the device.',
  },
  144: {
    id: 144,
    color: threatColors.cherryRed,
    name: 'Battery Permission Required',
    description: 'The app requires battery optimization permission to allow it to stay active when running in the background and ensure continuous protection on the device.',
  },
  145: {
    id: 145,
    color: threatColors.appleRed,
    name: 'Location Permission Required Android',
    description: 'Location permission is required by the app to protect devices from sophisticated network attacks.',
  },
  146: {
    id: 146,
    color: threatColors.appleRed,
    name: 'Location Permission Required iOS',
    description: 'Location permission is required by the app to include location information when reporting mobile threats. Location data provides real-time information on nearby Wi-Fi risks.',
  },
  147: {
    id: 147,
    color: threatColors.cherryRed,
    name: 'Device Admin Permission Required for Samsung Knox',
    description: 'Device admin permission is required by the app to enable Samsung Knox functionality to protect devices from mobile threats.',
  },
  148: {
    id: 148,
    color: threatColors.cherryRed,
    name: 'Bluetooth Permission Required',
    description: 'Bluetooth permission is required by the app to detect unknown tag trackers that could be tracking the device\'s location.',
  },
  149: {
    id: 149,
    color: threatColors.cherryRed,
    name: 'Local Network Access Permission Required',
    description: 'Local network access is required by the app to enable the protection of devices from sophisticated Wi-Fi-based network attacks.',
  },
  150: {
    id: 150,
    color: threatColors.cherryRed,
    name: 'Notification Permission Required',
    description: 'Notification permission is required by the app for users to receive on-device alerts about mobile security.',
  },
  151: {
    id: 151,
    color: threatColors.cherryRed,
    name: 'Link Verification Disabled - Safari Extension',
    description: 'Link verification using the Safari browser extension is disabled on the device.',
  },
  152: {
    id: 152,
    color: threatColors.cherryRed,
    name: 'Lockdown Mode Not Enabled',
    description: 'Lockdown mode is an iOS feature aimed at increasing the device\'s security. It is recommended that it be enabled.',
  },
  153: {
    id: 153,
    color: threatColors.cherryRed,
    name: 'VPN Permission Required - Secure Web',
    description: 'VPN permission is required by the app to keep devices safe from risky websites.',
  },
  154: {
    id: 154,
    color: threatColors.cherryRed,
    name: 'VPN Permission Required - Secure Wi-Fi',
    description: 'VPN permission is required by the app to protect network data in the event of a malicious network attack.',
  },
  155: {
    id: 155,
    color: threatColors.cherryRed,
    name: 'Deny-Listed Certificate',
    description: 'A side-loaded app signed with a deny-listed certificate was detected. These certificates can be used to sign malicious apps on third-party app stores.',
  },
  156: {
    id: 156,
    color: threatColors.cherryRed,
    name: 'Android Debug Bridge (ADB) Wi-Fi Enabled',
    description: 'Wireless Developer Options is an advanced configuration option intended for development purposes only. When enabled, the user has the option to change advanced settings remotely without a physical connection to the device, compromising the integrity of the device settings.',
  },
  157: {
    id: 157,
    color: threatColors.cherryRed,
    name: 'Forensic - Filesystem Mount Points Changed',
    description: 'Filesystem mounts are often changed as a part of regular device behavior but this can also occur as a part of a device attack. This is viewed as normal/low risk on its own but impacted devices should continue to be monitored for threats.',
  },
  200: {
    id: 200,
    color: threatColors.fog,
    name: 'App Pending Activation',
    description: 'Device activation for MTD is not complete.',
  },
  1004: {
    id: 1004,
    color: threatColors.banana,
    name: 'High Risk Browser Extension',
    description: 'Test.',
  },
  1002: {
    id: 1002,
    color: threatColors.tangerine,
    name: 'Suspicious Browser Extension',
    description: 'Test.',
  },
  1003: {
    id: 1003,
    color: threatColors.babyBlue,
    name: 'Out of Compliance Browser Extension',
    description: 'Test.',
  },
  1005: {
    id: 1005,
    color: threatColors.purpleOnion,
    name: 'Sideloaded Browser Extension',
    description: 'Test.',
  },
};

export default ThreatTypes;
