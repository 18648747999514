import { syncModeSelections } from '../../../emmTable/MDM/utils';
import { ISelectItem } from 'components/UI/input/Select';
import { IMDMRowData } from '../../../emmTable/MDM/models';
import { TModalMode } from '../../../models';
import { IAllPolicyOptions, IFormData } from './models';
import { TFunction } from 'react-i18next';

const initialValues = (
  t: TFunction<'translation', undefined>,
  mode: TModalMode = 'ADD',
  policies?: IAllPolicyOptions,
  deviceSpaces?: ISelectItem[],
  rowData?: IMDMRowData,
  previousFieldValues?: Partial<IFormData>,
  isIntune?: boolean,
  isSOTIConnector?: boolean,
  isGoogleConnector?: boolean
): IFormData => {
  return {
    appSettings: policies?.appSettingOptions?.[0],
    deviceSpace:
      mode !== 'ADD' && deviceSpaces?.[0]
        ? previousFieldValues?.deviceSpace ??
          deviceSpaces.find(
            ({ value }) => rowData?.auth?.device_space_id === value
          )
        : previousFieldValues?.deviceSpace,
    dormancyPolicy: policies?.dormancyPolicies?.[0],
    groups: undefined,
    includeNestedGroups:
      isIntune || isSOTIConnector || isGoogleConnector
        ? previousFieldValues?.includeNestedGroups ??
          rowData?.includeNestedGroups
        : undefined,
    allowDevicesWithNoUser: isIntune
      ? previousFieldValues?.allowDevicesWithNoUser ??
        rowData?.allowDevicesWithNoUser
      : undefined,
    maxDeviceIdleDays: isIntune
      ? previousFieldValues?.maxDeviceIdleDays ?? rowData?.maxDeviceIdleDays
      : undefined,
    phishingPolicy: policies?.phishingPolicies?.[0],
    privacyPolicy: policies?.privacyPolicies?.[0],
    syncMode: isIntune
      ? syncModeSelections(t).find(
          ({ value }) =>
            value === (previousFieldValues?.syncMode?.value ?? rowData?.mode)
        )
      : undefined,
    trmPolicy: mode !== 'ADD' ? policies?.trmPolicies?.[0] : undefined,
  };
};

export default initialValues;
