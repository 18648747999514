import React, { useMemo } from 'react';
import useStyles from './useStyles';
import GenericCard from 'components/UI/GenericCard';
import Checkbox from 'components/UI/input/Checkbox';
import AndroidIcon from 'components/UI/icons/AndroidIcon';
import AppleIcon from 'components/UI/icons/AppleIcon';
import { ITrigger } from '../models';

interface ITriggerCardProps {
  triggers: ITrigger[];
  handleChange: (trigger: ITrigger) => void;
  isViewOnly: boolean;
}

export const TriggerCard: React.FC<ITriggerCardProps> = ({
  triggers,
  handleChange,
  isViewOnly,
}) => {
  const classes = useStyles();

  const memoizedTriggers = useMemo(() => triggers, [triggers]);

  return (
    <GenericCard noPadding>
      <div style={{ padding: '2px 10px 2px 0px' }}>
        <div className={classes.subRightBoxSize}>
          {memoizedTriggers?.map((trigger) => (
            <div key={trigger?.name} className={classes.subCheckBoxStyling}>
              <Checkbox
                color="primary"
                label={trigger?.description}
                name={trigger?.name}
                id={trigger?.name}
                disabled={isViewOnly}
                onChange={() => handleChange(trigger)}
              />
              <div>
                {trigger?.platform?.toLowerCase() === 'ios' ? (
                  <span className={classes?.appleIcon}>
                    <AppleIcon />
                  </span>
                ) : (
                  <span className={classes?.androidIcon}>
                    <AndroidIcon />
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </GenericCard>
  );
};
