import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

export default state => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      trmPolicyMtd: {
        ...uiSettingsDefaults.trmPolicyMtd,
        tableHeaders: uiSettingsDefaults.trmPolicyMtd.tableHeaders,
      },
    },
  };
};
