import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      textAlign: 'center',
      paddingBottom: 0,
    },
    passwordBoxCard: {
      minHeight: 200,
      width: 400,
      zIndex: 1000,
      overflow: 'auto',
      whiteSpace: 'break-spaces',
    },
  })
);

export default useStyles;
