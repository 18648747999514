import { IGraphOptions } from 'components/UI/graphs/common/models';
import { IThreatByAppDataset } from './models';

export const DEVICE_TYPES = [
  { key: 'android', label: 'Android Devices' },
  { key: 'ios', label: 'iOS Devices' },
  { key: 'chromeos', label: 'Chrome OS Devices' },
];

export const GRAPH_OPTIONS: IGraphOptions = {
  legend: {
    display: false,
  },
  tooltips: {
    mode: 'point',
    position: 'nearest',
    intersect: false,
    callbacks: {
      title: function formatTitle(tooltipItem, data) {
        if (!tooltipItem.length) {
          return '';
        }
        const { datasetIndex, xLabel } = tooltipItem[0];
        const dataset = data.datasets[datasetIndex] as IThreatByAppDataset;
        return `${xLabel}: ${dataset.zappName}`;
      },
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
      gridLines: {
        display: false,
      },
    },
    y: {
      stacked: true,
      ticks: {
        min: 0,
        beginAtZero: true,
        userCallback(label) {
          if (Math.floor(label as number) === label) {
            return label;
          }
        },
      },
    },
  },
  onHover: (event, chartElement) => {
    event.target.style.cursor = chartElement?.[0] ? 'pointer' : 'default';
  },
};

export const calcDaysToAdd = (duration: number) => {
  if (duration <= 4 || duration >= 9) {
    return 0;
  }

  return 2 * duration - 9; // y = mx + b, rough estimate of days to add, possibly change
};
