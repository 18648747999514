import { useEffect, useState } from 'react';
import createActivityDetector from 'activity-detector';
import { getActiveModalAtom } from 'atoms/modals';
import { useSetRecoilState } from 'recoil';

const activeModalAtom = getActiveModalAtom();

export default function useIdle(options) {
  const setActiveModal = useSetRecoilState(activeModalAtom);
  const [isIdle, setIsIdle] = useState(false);
  useEffect(() => {
    const activityDetector = createActivityDetector({
      timeToIdle: 900000,
      inactivityEvents: [],
    });
    activityDetector.on('idle', () => {
      setIsIdle(true);
      setActiveModal(undefined);
    });

    activityDetector.on('active', () => setIsIdle(false));
    return () => activityDetector.stop();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
  return isIdle;
}
