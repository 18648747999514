import { Typography } from '@material-ui/core';
import GenericError from 'components/UI/GenericErrorBox';
import { TClassList } from 'components/UI/Table/models';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './useStyles';
import { convertFileToBase64Async } from 'utils/fileUtils';

interface IUploaderProps {
  accept?: string;
  classList?: TClassList;
  disabled?: boolean;
  getFileData?: (data: File) => void;
  isShowing?: boolean;
  multiple?: boolean;
  name?: string;
  required?: boolean;
  setFieldValue?: (field: string, value: string | ArrayBuffer | null) => void;
  text?: React.ReactNode;
}

const Uploader: React.FC<IUploaderProps> = (props: IUploaderProps) => {
  const nativeClasses = useStyles();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const isAcceptedExtension = useCallback(
    (extension: string) => {
      if (props.accept) {
        return props.accept.includes(`${extension}`);
      }
      return true;
    },
    [props.accept]
  );

  const validateExtension = useCallback(
    (extension: string) => {
      if (!extension) {
        setErrorMessage(
          t('GLOBAL.UNABLE_DETERMINE_FILE_TYPE_MISSING_EXTENSION')
        );
        return;
      }

      if (!isAcceptedExtension(extension)) {
        setErrorMessage(`${t('GLOBAL.UNSUPPORTED_FILE_TYPE')} ${props.accept}`);
        return;
      }

      return true;
    },
    [props.accept, t, isAcceptedExtension]
  );

  const onChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event?.target?.files?.[0];
      const extension = getFileExtension(file) ?? '';
      const isValidExtension = validateExtension(extension);
      if (!isValidExtension) {
        return;
      }
      if (file) {
        props.setFieldValue?.(
          event?.target?.name,
          await convertFileToBase64Async(file)
        );
      }
      props?.getFileData?.(file as File);
    },
    [props, validateExtension]
  );

  const onDrop = useCallback(
    (event: React.DragEvent<HTMLInputElement>) => {
      event.preventDefault();
      event.stopPropagation();

      const droppedFiledata = event?.dataTransfer?.files?.[0];
      const extension = getFileExtension(droppedFiledata);
      const isValidExtension = validateExtension(extension);
      if (!isValidExtension) {
        return;
      }
      props?.getFileData?.(droppedFiledata as File);
    },
    [props, validateExtension]
  );

  const getFileExtension = (file: File | undefined) => {
    return file?.name?.match(/\.[A-Za-z0-9]+$/im)?.[0] ?? '';
  };

  const classes = {
    ...nativeClasses,
    ...props.classList,
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.fileButton}>
        {t('GLOBAL.CHOOSE_FILE')}
      </Typography>
      <Typography color="primary">
        {props.text ?? t('GLOBAL.DRAG_DROP_FILE_OR_CLICK_BUTTON')}
      </Typography>
      {!errorMessage && (
        <input
          {...props}
          type="file"
          className={classes.hideFile}
          onChange={(e) => onChange(e)}
          onDrop={onDrop}
        />
      )}
      {!!errorMessage && (
        <div className={classes.errorBox}>
          <GenericError
            errorMessage={errorMessage}
            showCloseIcon
            onClose={() => setErrorMessage(undefined)}
          />
        </div>
      )}
    </div>
  );
};

export default Uploader;
