import defaultMappings from 'components/UI/Table/utils/default.mappings';
import { ServiceHandlerFactory } from 'utils/serviceUtils';

const zDefendInsightsMappings = [
  { path: 'zappId' },
  { path: 'teamId', paramKey: 'teamId.keyword' },
];

const ZDEFEND_INSIGHTS_BASE = 'v1/zdefend-insights';

export const fetchApps = ServiceHandlerFactory({
  axios: {
    url: '/api/zapp/v1/zapps/page',
  },
  paramSchema: [{ path: 'teamId' }, { path: 'size' } ],
});

export const fetchDevicesImpactedAndroidIosThreats = ServiceHandlerFactory({
  axios: {
    url: `/api/threats/${ZDEFEND_INSIGHTS_BASE}/device-threat-stats`,
  },
  paramSchema: zDefendInsightsMappings,
});

export const fetchPotentialFraudIndicators = ServiceHandlerFactory({
  axios: {
    url: `/api/threats/${ZDEFEND_INSIGHTS_BASE}/potential-fraud-indicators`,
  },
  paramSchema: zDefendInsightsMappings,
});

export const fetchRiskiestDeviceModels = ServiceHandlerFactory({
  axios: {
    url: `/api/threats/${ZDEFEND_INSIGHTS_BASE}/riskiest-device-models`,
  },
  paramSchema: zDefendInsightsMappings,
});

export const fetchTopThreats = ServiceHandlerFactory({
  axios: {
    url: `/api/threats/${ZDEFEND_INSIGHTS_BASE}/top-threats`,
  },
  paramSchema: zDefendInsightsMappings,
});

export const fetchOperationalStatus = ServiceHandlerFactory({
  axios: {
    url: `/api/devices/${ZDEFEND_INSIGHTS_BASE}/operational-status`,
  },
  paramSchema: zDefendInsightsMappings,
});
