import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import { editModalConfig } from 'components/modals/AuthorizationsCreateEdit';
import _ from 'lodash';
import React, { Fragment } from 'react';
import momentHelper from 'utils/momentUtils';
import { toggleModalDiffered } from 'utils/storeUtils';

export const defaultAuthorizationTableHeaders = [
  {
    flex: 1,
    id: 'id',
    label: 'Client Id',
    maxWidth: 500,
    minWidth: 250,
    numeric: false,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    flex: 1,
    id: 'description',
    label: 'Description',
    maxWidth: 500,
    minWidth: 200,
    numeric: false,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    flex: 1,
    id: 'teams',
    label: 'Teams',
    maxWidth: 500,
    minWidth: 200,
    numeric: false,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: false,
  },
  {
    flex: 1,
    id: 'createdBy',
    label: 'Created By',
    maxWidth: 500,
    minWidth: 200,
    resizable: true,
    numeric: false,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    flex: 1,
    id: 'modifiedBy',
    label: 'Modified By',
    maxWidth: 500,
    minWidth: 200,
    numeric: false,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    flex: 1,
    id: 'modified',
    label: 'Modified',
    maxWidth: 150,
    minWidth: 150,
    numeric: false,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    flex: 1,
    id: 'created',
    label: 'Created',
    maxWidth: 150,
    minWidth: 150,
    numeric: false,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    flex: 0,
    id: 'actions',
    isProtected: true,
    label: 'Actions',
    maxWidth: 125,
    minWidth: 125,
    numeric: false,
    scope: 'authorizations',
    show: true,
    showInContextMenu: true,
    sort: false,
  },
];

export const authorizationColumnMapping = [
  {
    path: 'id',
  },
  {
    path: 'description',
  },
  {
    path: 'teams',
    csvContent: teams => teams.map(({ name = '' }) => name).join('; '),
    columnContent: ({ rowData }) => {
      return rowData.teams.map((team, index) => {
        return <Fragment key={`${team.name} ${index}`}>{team.name}</Fragment>;
      });
    },
  },
  {
    path: 'createdBy',
  },
  {
    path: 'modifiedBy',
  },
  {
    path: 'modified',
    columnContent: ({ rowData }) => {
      const modified = _.get(rowData, 'modified');
      return modified ? momentHelper(modified) : '';
    },
  },
  {
    path: 'created',
    columnContent: ({ rowData }) => {
      const created = _.get(rowData, 'created');
      return created ? momentHelper(created) : '';
    },
  },
  {
    path: 'actions',
    columnContent: ({ rowData }) => (
      <div className="flex--spaced">
        <ProtectedComponent allow={{ api_keys: 'manage' }}>
          <Tooltip title="Edit Authorization">
            <EditIcon
              className="hover--link"
              onClick={toggleModalDiffered(
                'AuthorizationsCreateEdit',
                {
                  ...rowData,
                },
                editModalConfig
              )}
            />
          </Tooltip>
        </ProtectedComponent>
        <ProtectedComponent allow={{ api_keys: 'manage' }}>
          <Tooltip title="Delete Authorization">
            <DeleteIcon
              className="hover--link"
              onClick={toggleModalDiffered(
                'AuthorizationsDeleteConfirm',
                {
                  ...rowData,
                },
                {
                  title: 'Delete Api Access Key',
                }
              )}
            />
          </Tooltip>
        </ProtectedComponent>
      </div>
    ),
  },
];
