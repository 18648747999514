import { createStyles, makeStyles } from '@material-ui/styles';
/* custom-dialog.css */
const useStyles = makeStyles(() =>
  createStyles({
    customDialog: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    customDialogContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    },
  })
);

export default useStyles;
