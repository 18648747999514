export interface IThreatCategoryMapping {
  [key: number | string]: {
    id: number;
    name: string;
    threatTypes: number[];
  };
}

const ThreatCategoryMapping: IThreatCategoryMapping = {
  1: {
    id: 1,
    name: 'Reconnaissance Scan',
    threatTypes: [0, 1, 2, 3, 5, 18, 19, 20],
  },
  2: {
    id: 2,
    name: 'MITM',
    threatTypes: [4, 11, 14, 26, 27, 30, 35, 38, 65, 66, 68],
  },
  3: { id: 3, name: 'Host Suspected', threatTypes: [6, 7] },
  4: { id: 4, name: 'Abnormal Process', threatTypes: [10, 28] },
  5: { id: 5, name: 'Malicious Process', threatTypes: [12] },
  6: { id: 6, name: 'Malicious App', threatTypes: [13, 25, 42] },
  7: { id: 7, name: 'Network Configuration', threatTypes: [15, 16, 17, 36] },
  8: { id: 8, name: 'SMS', threatTypes: [22, 29] },
  9: { id: 9, name: 'Functionality', threatTypes: [31, 47] },
  10: { id: 10, name: 'ARP Cache Poison', threatTypes: [] },
  11: { id: 11, name: 'Persistent Filesystem Modification', threatTypes: [23] },
  12: { id: 12, name: 'Stagefright Anomaly', threatTypes: [34, 40, 41, 43] },
  13: { id: 13, name: 'Media Server Anomaly', threatTypes: [33] },
  14: { id: 14, name: 'Stagefright Exploit', threatTypes: [] },
  15: { id: 15, name: 'System Config', threatTypes: [37, 39] },
  16: { id: 16, name: 'Risk Assessment', threatTypes: [] },
  17: { id: 17, name: 'Profiles', threatTypes: [24, 45] },
  26: { id: 26, name: 'USB Debugging', threatTypes: [44] },
  32: { id: 32, name: 'Device Configuration', threatTypes: [49, 50] },
  33: { id: 33, name: 'Malicious Website', threatTypes: [9, 72] },
  64: { id: 64, name: 'Vulnerable OS', threatTypes: [51, 52, 69, 88, 89] },
  65: { id: 65, name: 'SE Linux Disabled', threatTypes: [61] },
  66: { id: 66, name: 'Suspicious Root Process', threatTypes: [62] },
  67: { id: 67, name: 'Suspicious Network Connection', threatTypes: [63] },
  68: { id: 68, name: 'System Integrity Failure', threatTypes: [64] },
  70: { id: 70, name: 'SafetyNet Attestation', threatTypes: [70, 71] },
  75: { id: 75, name: 'App Tampering', threatTypes: [75] },
  76: { id: 76, name: 'Sideloaded App', threatTypes: [76] },
  77: { id: 77, name: 'SSL / TLS Downgrade', threatTypes: [77] },
  78: { id: 78, name: 'MTD Not Running', threatTypes: [78, 100, 200] },
  79: { id: 79, name: 'Danger Zone', threatTypes: [79, 80] },
  81: { id: 81, name: 'Dynamic Code Loading', threatTypes: [81] },
  82: { id: 82, name: 'Silent App Installation', threatTypes: [82] },
  83: { id: 83, name: 'Suspicious File Detected', threatTypes: [83] },
  84: { id: 84, name: 'Google Play Protect Disabled', threatTypes: [84] },
  85: {
    id: 85,
    name: 'Android Debug Bridge Apps Not Verified',
    threatTypes: [85],
  },
  86: { id: 86, name: 'OTA Updates Disabled', threatTypes: [86] },
  87: { id: 87, name: 'Always On VPN App Set', threatTypes: [87] },
  93: { id: 93, name: 'Out of Compliance App', threatTypes: [93] },
  94: { id: 94, name: 'iTunes Wi-Fi Sync Enabled', threatTypes: [94] },
  128: { id: 128, name: 'Internal Network Access', threatTypes: [48] },
  129: { id: 129, name: 'Captive Portal', threatTypes: [67] },
  130: {
    id: 130,
    name: 'Composite Threat',
    threatTypes: [121, 122, 123, 124, 125],
  },
};

export const getCategoryIdByThreatId = (threatId: number) => {
  let categoryId;

  Object.keys(ThreatCategoryMapping).forEach(key => {
    if (ThreatCategoryMapping?.[key]?.threatTypes?.includes(threatId)) {
      categoryId = key;
    }
  });
  return categoryId;
};

export default ThreatCategoryMapping;
