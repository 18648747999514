import { Api } from 'config/axiosConfig';
import { IConnector } from '../models';

export const fetchMDMProviders = async () => {
  let data: IConnector[] = [];
  const apiUrl = 'api/emm/v1/provision/connector-types';
  try {
    const result = await Api.get(apiUrl);
    if (!!result?.data) {
      data = result.data;
    }
  } catch (e) {
    console.log(e);
  }
  return data;
};
