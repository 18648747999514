import { ServiceHandlerFactory } from 'utils/serviceUtils';
import {
  buildConfigurationsParams,
  createConfigurationPayload,
  updateConfigurationPayload,
} from './configurations.mapping';

const integrationServiceBaseURL = '/api/zdev-app/v1';

export const getConfigurations = ServiceHandlerFactory({
  axios: {
    url: `${integrationServiceBaseURL}/integration-configs`,
  },
  isCancelable: true,
  paramSchema: buildConfigurationsParams,
});

// export const getConfigurationDetails = ServiceHandlerFactory({
//   axios: {
//     url: `${integrationServiceBaseURL}/integration-configs/:configurationId`,
//   },
//   isCancelable: true,
// });

export const createConfiguration = ServiceHandlerFactory({
  axios: {
    url: `${integrationServiceBaseURL}/integration-configs`,
    method: 'post',
  },
  payloadSchema: createConfigurationPayload,
});

export const updateConfiguration = ServiceHandlerFactory({
  axios: {
    url: `${integrationServiceBaseURL}/integration-configs/:configurationId`,
    method: 'put',
  },
  payloadSchema: updateConfigurationPayload,
});

export const deleteConfiguration = ServiceHandlerFactory({
  axios: {
    url: `${integrationServiceBaseURL}/integration-configs/:configurationId`,
    method: 'delete',
  },
});
