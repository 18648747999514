import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

// ui
import ZButton from 'UI/Buttons/ZButton';
import Typography from '@material-ui/core/Typography';

// Actions
import {
  checkUserInvitedValidationToken,
  postValidationTokenPassword,
} from 'api/UserService';

import { fetchPasswordPolicyForUserInvited } from 'api/PasswordPolicyService';

// components
import PublicComponentWrapper from 'components/wrappers/PublicComponentWrapper';
import { FormikPasswordField } from 'components/inputs/FormikPasswordField';
import PasswordRulesBox from 'components/main/common/PasswordRulesBox';
import { passwordErrorHelper } from './password.mappings';
import safePropsToken from 'utils/safePropsToken';

const UserInvitationConfirmation = (props) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [tokenError, setTokenError] = useState('');
  const [customPasswordRules, setCustomPasswordRules] = useState({});

  const { t } = useTranslation();

  const urlToken = safePropsToken(props);

  const fetchTokenData = () => {
    checkUserInvitedValidationToken(urlToken)
      .then((resp) => {
        setFirstName(_.get(resp, 'data.firstName'));
        setLastName(_.get(resp, 'data.lastName'));
        // set password rules here for Yup
      })
      .catch((error) => {
        console.log('Error in invite token: ', error);
        switch (error.response.status) {
          case 404:
            return setTokenError('The validation token was not found!');
          case 400:
            return setTokenError('The validation token has expired.');
          default:
            return setTokenError('Unexpected Error');
        }
      });
  };

  useEffect(() => {
    fetchTokenData();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    fetchPasswordPolicyForUserInvited(urlToken)
      .then(({ data }) => {
        setCustomPasswordRules(data);
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  function handleSubmit(values, { setErrors }) {
    postValidationTokenPassword(urlToken, values)
      .then(() => {
        props.history.push('/login');
      })
      .catch((error) => {
        console.log('Error in Token Password submit: ', error);
        const errors = _.get(error, 'response.data');
        let displayedErrors =
          'Please contact your Zimperium Customer Success team.';
        if (error.response.status === 400) {
          displayedErrors = passwordErrorHelper(errors, t);
        }
        return setErrors({ password: displayedErrors });
      });
  }
  if (!_.isEmpty(tokenError)) {
    return (
      <PublicComponentWrapper>
        <Typography variant="body2" color="error" align="center">
          {tokenError}
        </Typography>
      </PublicComponentWrapper>
    );
  }

  // const cleanedRules = cleanupCustomRules(customPasswordRules);
  return (
    <PublicComponentWrapper>
      <Formik
        validationSchema={UserInvitationConfirmationSchema}
        validateOnBlur
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={{ password: '', confirmedPassword: '' }}
      >
        {({ dirty, isSubmitting, values }) => {
          return (
            <div>
              <Typography variant="h6" className="entry-label" align="center">
                Hello {firstName} {lastName}! Please set your password.
              </Typography>

              <Form>
                <Field
                  name="password"
                  label="Password"
                  component={FormikPasswordField}
                  classlist={{
                    label: 'entry-label',
                  }}
                />

                <Field
                  name="confirmedPassword"
                  label="Confirm Password"
                  component={FormikPasswordField}
                  classlist={{ label: 'entry-label' }}
                />

                <ZButton
                  fullWidth
                  buttonType="submit"
                  styleName="login"
                  color="primary"
                  buttonText="Set Password"
                />
              </Form>
            </div>
          );
        }}
      </Formik>
      <PasswordRulesBox customPasswordRules={customPasswordRules} />
    </PublicComponentWrapper>
  );
};

const UserInvitationConfirmationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters.'),
  confirmedPassword: Yup.string()
    .oneOf(
      [Yup.ref('password', 'Passwords must match'), ''],
      'Passwords must be the same'
    )
    .required('Password confirm is required'),
});

export default UserInvitationConfirmation;