import _ from 'lodash';
import React from 'react';
import withRouter from 'components/hocs/withRouter';
import { withTranslation } from 'react-i18next';

// ui
import MaterialTableCell from '@material-ui/core/TableCell';
import MaterialTableRow from '@material-ui/core/TableRow';
import MaterialTableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';

// utils
import ColumnSelector from 'utility/ColumnSelector';

// components
import TableColumnsContext from './TableColumnsContext';

class TableHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      columnFilter: false,
    };

    this.createSortHandler = this.createSortHandler.bind(this);
    this.handleClickColumn = this.handleClickColumn.bind(this);
    this.handleCloseColumnFilter = this.handleCloseColumnFilter.bind(this);
  }

  render() {
    const { props, state } = this;
    const { t } = this.props;
    const { order, orderBy } = props.location.query;

    return (
      <TableColumnsContext.Consumer>
        {({ columnHeaders, onColumnChange, onSort, omitTableColumns = [] }) => {
          const tableHeaders = _.differenceBy(
            columnHeaders,
            omitTableColumns,
            'id'
          );
          return (
            <MaterialTableHead>
              <MaterialTableRow>
                {tableHeaders.map((c, index) => {
                  if (!c.show) {
                    return null;
                  }

                  const sortKeyword = (Boolean(c.sort) && `${c.id}`) || c.id;

                  return (
                    <MaterialTableCell
                      key={`${c.id}-${index}`}
                      align={c.numeric ? 'right' : 'left'}
                      classes={{
                        root: props.classList.tableHeaderCell,
                      }}
                    >
                      {!c.sort ? (
                        t(c.label)
                      ) : (
                        <Tooltip
                          title={t('GLOBAL.SORT')}
                          enterDelay={300}
                          placement={c.numeric ? 'bottom-end' : 'bottom-start'}
                        >
                          <TableSortLabel
                            active={orderBy === c.id || orderBy === sortKeyword}
                            direction={order}
                            onClick={this.createSortHandler(
                              sortKeyword,
                              onSort
                            )}
                          >
                            {t(c.label)}
                          </TableSortLabel>
                        </Tooltip>
                      )}
                    </MaterialTableCell>
                  );
                })}

                <MaterialTableCell
                  align="left"
                  padding="none"
                  classes={{ root: props.classList.tableHeaderCell }}
                  style={{ paddingLeft: 24, paddingRight: 0 }}
                >
                  <ColumnSelector
                    defaultOptions={tableHeaders}
                    open={state.columnFilter}
                    anchorEl={state.anchorEl}
                    columnChange={onColumnChange}
                    toOpen={this.handleClickColumn}
                    onClose={this.handleCloseColumnFilter}
                  />
                </MaterialTableCell>
              </MaterialTableRow>
            </MaterialTableHead>
          );
        }}
      </TableColumnsContext.Consumer>
    );
  }

  handleCloseColumnFilter() {
    this.setState({
      anchorEl: null,
      columnFilter: false,
    });
  }

  handleClickColumn(event) {
    this.setState({
      anchorEl: event.currentTarget,
      columnFilter: !this.state.columnFilter,
    });
  }

  createSortHandler(property, onSort) {
    return () => {
      const { props } = this;
      let newOrder = 'asc';

      if (
        _.eq(props.location.query.orderBy, property) &&
        _.eq(props.location.query.order, 'asc')
      ) {
        newOrder = 'desc';
      }

      props.updateUrl({
        page: 0, // need to send them back to first page
        order: newOrder,
        orderBy: property,
      });

      if (_.isFunction(onSort)) {
        onSort({ order: newOrder, orderBy: property });
      }
    };
  }
}

export default withRouter(withTranslation()(TableHeader));
