import Button from 'components/UI/Button';
import { Field as FormikField, Form as FormikForm, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import Schema from './schema';
import useStyles from './useStyles';
import Select, { ISelectItem } from 'components/UI/input/Select';
import { IFormProps } from '../models';
import { useRef } from 'react';

const Form: React.FC<IFormProps> = ({
  handleClose,
  handleCreateSubmit,
  projectOptions = [],
  issueTypeOptions = [],
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const projectId = useRef('');

  return (
    <Formik
      enableReinitialize
      initialValues={{ issueType: '', project: '' }}
      onSubmit={(formValues) => handleCreateSubmit(formValues)}
      validationSchema={Schema(t)}
    >
      {({ isSubmitting, setFieldValue, values, resetForm }) => {
        return (
          <FormikForm>
            <FormikField
              component={Select}
              label="Project"
              multiple={false}
              name="project"
              value={values.project}
              options={projectOptions}
              setFieldValue={(field: string, value: ISelectItem) => {
                setFieldValue(field, value);
                setFieldValue('issueType', '');
                projectId.current = String(value?.value);
              }}
              type="select"
            />{' '}
            <FormikField
              component={Select}
              label="Issue Type"
              multiple={false}
              name="issueType"
              value={values.issueType}
              disabled={!values.project}
              options={issueTypeOptions.filter(
                (i) => projectId.current === i.projectId
              )}
              setFieldValue={(field: string, value: ISelectItem) => {
                setFieldValue(field, value);
              }}
              type="select"
            />
            <div className={classes.twoColumnGroup}>
              <Button
                color="secondary"
                disabled={isSubmitting}
                text={t('GLOBAL.CANCEL')}
                onClick={handleClose}
              />
              <Button
                color="primary"
                text={t('ZSCAN.CREATE_TICKET')}
                disabled={isSubmitting || !values.issueType}
                isLoading={isSubmitting}
                type="submit"
                onClick={() => handleCreateSubmit(values, resetForm)}
              />
            </div>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default Form;
