import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    accordion: {
      boxShadow: 'none',
      margin: '10px -10px 0 -10px !important',
      color: palette.text.secondary,
    },
    drawerMainContent: {
      display: 'flex',
      gap: '10px',
      position: 'relative',
      padding: '15px',
    },
    drawerLogo: {
      alignSelf: 'center',
      padding: '6px',
    },
    drawerEditIcon: {
      marginLeft: 'auto',
    },

    drawerViewButton: {
      left: '0',
      right: '0',
      position: 'absolute',
      transform: 'translate(324px, 90px)',
    },
    accordionHeader: {
      backgroundColor: palette.tables.header.background,
      border: `1px solid ${palette.tables.border}`,
      color: palette.text.secondary,
      minHeight: 'none',
      '& .MuiAccordionSummary-expandIcon': {
        '&.Mui-expanded': {
          transform: 'rotate(90deg)',
        },
      },
      '& svg': {
        width: 14,
      },
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
    },
    circleStyle: {
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
      flexWrap: 'wrap',
      padding: '1rem',

      textAlign: 'center',
      '&>div>div': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '13ch',
        color: palette.text.secondary,
        fontSize: '12px',
        fontWeight: 500,
        gap: '10px',
      },
      '&>div>div>span': {
        color: palette.text.secondary,
        fontSize: '12px',
        fontWeight: 500,
      },
      '&>div': {
        display: 'flex',
        justifyContent: 'space-evenly',
      },
    },
    details: {
      display: 'flex',
      fontSize: 12,
      padding: 15,
    },
    detailsTable: {
      fontSize: 13,
    },
    detailsTableWithPadding: {
      '& tr': {
        '& td:first-child': {
          paddingRight: 15,
          color: palette.text.secondary,
        },
      },
    },
    permissions: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: 12,
      padding: 15,
      '& div': {
        marginBottom: 5,
      },
    },
    heading: {
      paddingRight: 15,
    },
    risk: {
      '& span': {
        marginRight: 10,
      },
      alignItems: 'center',
      display: 'flex',
      textTransform: 'capitalize',
    },
    rows: {
      '& div': {
        marginBottom: 5,
      },
      '& div:last-child': {
        marginBottom: 0,
      },
    },
    summary: {
      alignItems: 'flex-end',
      display: 'flex',
      flexWrap: 'nowrap',
      fontSize: 14,
      justifyContent: 'space-between',
      marginTop: 10,
      '& span': {
        display: 'inline-flex',
        fontWeight: 'bold',
      },
    },
    summaryAssessment: {
      display: 'flex',
      textTransform: 'capitalize',
      '& span': {
        marginRight: 10,
      },
    },
    '& tr': {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '20px',
    },
    detailsTableFile: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      fontSize: 13,
      padding: 15,
      '& tr': {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px',
        alignItems: 'center',
      },
    },

    backgroundCircle: {
      display: 'flex',
      width: 65,
      height: 65,
      marginRight: 5,
      justifyContent: 'center',
      borderRadius: '50%',
      background: palette.background.paper,
    },
    backgroundCircleAndroid: {
      border: `2px solid ${palette.common.android}`,
      '& > svg': { height: '80%', width: '80%', marginTop: '6px' },
    },

    backgroundCircleIos: {
      border: `2px solid ${palette.common.ios}`,
      '& > svg': { minHeight: '80%', maxWidth: '80%', minWidth: '80%' },
    },
  })
);

export default useStyles;
