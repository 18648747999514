import { Api } from 'config/axiosConfig';
import { IFilterValues } from './models';
import { openSnackBar } from 'utils/storeUtils';
import { TFunction } from 'react-i18next';

export const CLASSIFICATION = [
  {
    label: 'LEGITIMATE',
    value: 'legit',
  },
  {
    label: 'MALICIOUS',
    value: 'malicious',
  },
];
// value: { start: 50, end: 1000 }

export const RISKS_TYPE = [
  {
    label: 'HIGH',
    value: 'High',
  },
  {
    label: 'MEDIUM',
    value: 'Medium',
  },
  {
    label: 'LOW',
    value: 'Low',
  },
];
export const HIGHEST_CVSS_TYPE = [
  {
    label: 'CRITICAL',
    value: 'CRITICAL',
  },
  {
    label: 'HIGH',
    value: 'HIGH',
  },
  {
    label: 'MEDIUM',
    value: 'MEDIUM',
  },
  {
    label: 'LOW',
    value: 'LOW',
  },
];
export const PLATFORMS = [
  {
    label: 'ANDROID',
    value: 'android',
  },
  {
    label: 'IOS',
    value: 'ios',
  },
];

export const INITIAL_FILTER_VALUES: IFilterValues = {
  name: '',
  version: '',
  platform: '',
  bundleId: '',
  developerName: '',
  classification: '',
  deviceCount: null,
  highestCvssSeverity: '',
};

export const getDownloadReports = async (
  apiUrl: string,
  fileName: string,
  t: TFunction<'translation', undefined>
) => {
  try {
    Api.get(apiUrl, { responseType: 'blob' })
      .then((res) => {
        const href = URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${fileName}`);
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          openSnackBar(t('GLOBAL.THERE_IS_NO_REQUESTED_RESULT'));
        } else {
          openSnackBar(t('GLOBAL.UNKNOWN_ERROR_TRY_AGAIN'));
        }
      });
  } catch (e) {
    console.log(e);
  }
};

export const extractVersionNumber = (metric: string) => {
  const match = metric.match(/\d+(\.\d+)?/);
  return match ? parseFloat(match[0]) / 10 : metric;
};
