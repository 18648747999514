import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    card: {
      '& > div': {
        paddingTop: 5,
        color: palette.common.white,
        backgroundColor: palette.common.marineBlue,
        border: `1px solid ${palette.primary.border}`,
      },
      '& h2': {
        color: palette.common.white,
      },
      '& label': {
        '& span': {
          color: `${palette.common.white} !important`,
          fontSize: 14,
        },
        flex: '1 0',
      },
      '& > p': {
        color: palette.common.almostWhite,
      },
    },
    horizontal: {
      display: 'flex',
      flexDirection: 'row',
      gap: 20,
      justifyContent: 'space-between',
    },
  })
);

export default useStyles;
