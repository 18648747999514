import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    appleIcon: {
      fill: 'currentColor',
      minHeight: '25px',
      minWidth: '25px',
      maxWidth:'25px',
      stroke: 'currentColor',
    },
  })
);

export default useStyles;
