import axios from 'axios';
import { Api } from 'config/axiosConfig';
import moment from 'moment';
import { openSnackBar, toggleModalDirect } from 'utils/storeUtils';
import { IDayMapping, IScheduleBody, ISyncSchedule } from './models';

const timeRegEx = /^\d{2}:\d{2}$/;

// Time string should be in 24-hr format ("23:59")
const parseTimeString = (localTimeString: string) => {
  if (!timeRegEx.test(localTimeString)) {
    throw new Error('Time string invalid');
  }
  const [hour, minute] = localTimeString.split(':');
  return [Number(hour), Number(minute)];
};

const createLocalTimeString = (hour: number, minute: number): string => {
  return moment().set({ hour, minute }).format('HH:mm');
};

const DAY_MAPPING: IDayMapping = {
  SUN: '1',
  MON: '2',
  TUE: '3',
  WED: '4',
  THU: '5',
  FRI: '6',
  SAT: '7',
};

const parseDayString = (dayString: string) => {
  const dayStrings = dayString.split(',');
  if (isNaN(Number(dayStrings[0]))) {
    return dayStrings.map((day) => DAY_MAPPING[day]);
  }

  return dayStrings;
};

export const createCronString = ({
  dailyStartTime,
  days,
  hourlyStartTime,
  hours,
}: ISyncSchedule) => {
  if (days?.[0] && dailyStartTime) {
    const [hour, minute] = parseTimeString(dailyStartTime);
    return `0 ${minute} ${hour} ? * ${days.join(',')}`;
  }

  if (typeof hours === 'number' && hourlyStartTime) {
    const [hour, minute] = parseTimeString(hourlyStartTime);
    return hours === 24
      ? `0 ${minute} ${hour} ? * *`
      : `0 ${minute} ${hour}/${hours} ? * *`;
  }

  return '';
};

export const parseCronString = (cronString: string): Partial<ISyncSchedule> => {
  const cronMatrix = cronString.split(' ').map((field) => field.split('/'));

  const hour = Number(cronMatrix[2][0]);
  const minute = Number(cronMatrix[1][0]);
  const startTime = createLocalTimeString(hour, minute);

  // If daily
  if (cronMatrix[5][0] !== '*') {
    return {
      dailyStartTime: startTime,
      days: parseDayString(cronMatrix[5][0]),
    };
  }

  // Else hourly
  return {
    hours: Number(cronMatrix[2][1] ?? 24),
    hourlyStartTime: startTime,
  };
};

export const formatErrorMessage = (
  statusText: string,
  dataString: string,
  status?: number
) => {
  if (status === 404) {
    return 'Not Found - Error reaching server.';
  }
  if (status === 401) {
    return 'Unauthorized - Session timeout';
  }
  if (status === 503) {
    return 'A bad gateway (503) error was generated during update';
  }
  if (status === 504) {
    return 'A request timeout was generated during update.';
  }

  const errorStatus = statusText ?? 'Error setting schedule';
  const responseData = dataString ?? 'Please try again';

  return `${errorStatus} - ${responseData}`;
};

export const submitSchedule = async (
  apiUrl: string,
  setErrorMessage: (message: string) => void,
  body: IScheduleBody
) => {
  try {
    await Api.patch(apiUrl, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    toggleModalDirect('EMMSyncSchedule', false);
    openSnackBar('Schedule updated successfully');
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const status = e?.response?.status ?? -1;
      const statusText = e?.response?.statusText ?? 'Error setting schedule';
      let dataString = 'Please Try Again';
      if (typeof e?.response?.data === 'string') {
        dataString = e.response.data;
      }
      if (typeof e?.response?.data?.messages?.[0]?.summary === 'string') {
        const summary = e.response.data.messages[0].summary;
        dataString = summary.startsWith('syncCronSchedule:')
          ? summary.split(':').slice(1).join(':')
          : summary;
      }
      setErrorMessage(formatErrorMessage(statusText, dataString, status));
    } else {
      console.error(e);
      setErrorMessage(
        formatErrorMessage('Error setting schedule', 'Please Try Again')
      );
    }
  }
};
