import React from 'react';
import { withStyles } from '@material-ui/core/styles';

// ui
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import QRCode from 'qrcode.react';
import ZButton from 'UI/Buttons/ZButton';

// components
import { toggleModalDiffered, openSnackBarDiffered } from 'utils/storeUtils';
import { CopyField } from 'components/inputs/index';
import { useTranslation } from 'react-i18next';

const ActivationsLink = (props) => {
  // set data into form array
  const token = props.data.rowData.shortToken;
  let { host, protocol } = window.location;
  const { t, ready } = useTranslation();

  if (protocol === 'http:' && !host.includes('localhost')) {
    protocol = 'https:';
  }

  const url = `${protocol}//${host}/api/acceptor/v1/user-activation/activation?stoken=${token}`;

  if (!ready) {
    return null;
  }

  return (
    <DialogContent>
      <div style={{ width: 275, paddingBottom: 20 }}>
        {t('GLOBAL.SCAN_AND_SHARE_QR_CODE_DESCRIPTION')}
      </div>
      <div className="flex--column--spaced">
        <QRCode value={url} includeMargin />
      </div>
      <CopyField
        label={t('MTD.ACTIVATIONS.ACTIVATION_LINK')}
        value={url}
        onCopy={openSnackBarDiffered('Activation link copied to clipboard')}
        // helperText={
        //   <Grid item md={8}>
        //     This link expires on 04/26/2020.
        //   </Grid>
        // }
      />
      Or click <a href={url}>here</a> to activate.
      <DialogActions>
        {/* <ZButton
          styleName="modalSave"
          buttonText="Regenerate Link"
          color="primary"
          // action={handleConfigureTFA}
        /> */}
        <ZButton
          styleName="modalSave"
          buttonText={t('GLOBAL.CLOSE')}
          color="primary"
          action={toggleModalDiffered('ActivationsLink', false)}
        />
      </DialogActions>
    </DialogContent>
  );
};

const styles = ({ palette }) => {
  return {
    errorText: {
      display: 'flex',
      alignItems: 'center',
      color: palette.text.secondary,
    },

    errorOutline: {
      marginRight: 5,
      verticalAlign: 'bottom',
      color: palette.error.main,
    },

    fileButton: {
      padding: '6px 16px',
      fontSize: '0.875rem',
      minWidth: 64,
      boxSizing: 'border-box',
      lineHeight: 1.75,
      fontWeight: 400,
      borderRadius: 3,
      color: palette.text.secondary,
      backgroundColor: palette.primary.main,
      cursor: 'pointer',
      marginBottom: 10,
    },

    hideFile: {
      position: 'absolute',
      zIndex: '1000',
      opacity: 0,
      cursor: 'pointer',
      right: 0,
      top: 0,
      height: '80%',
      fontSize: 24,
      width: '100%',
    },
  };
};
export default withStyles(styles)(ActivationsLink);
