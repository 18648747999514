import { useCallback, useEffect, useState } from 'react';
import { fetchAdminAccountById } from 'api/AdminAccountService';
import { fetchManagedAccountById } from 'api/PartnerService';

export default (id, managed) => {
  if (!id) return {};
  const [accountInfo, setAccountInfo] = useState([]);

  const fetchAdminAccount = useCallback(async () => {
    const { data } = !!managed
      ? await fetchManagedAccountById({ accountId: id })
      : await fetchAdminAccountById(id);

    setAccountInfo({ ...data, domains: data?.domains?.join('\r\n') });
  }, [id, managed]);

  useEffect(() => {
    fetchAdminAccount(id);
  }, [fetchAdminAccount, id]);

  return { accountInfo };
};
