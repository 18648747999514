import Moment from 'moment';
import { IRowData } from '../models';
import { IFormData, IPolicyGroupOption } from './models';

export const initialValues = (
  rowData?: IRowData,
  selectedTeamId?: string,
  allPolicyGroups?: IPolicyGroupOption[]
): IFormData => {
  return {
    activationLimit: rowData?.activationLimit || '',
    description: rowData?.description || '',
    editMode: !!rowData ?? false,
    group: allPolicyGroups?.[0]
      ? allPolicyGroups.find(({ value }) => value === rowData?.groupId) ?? null
      : null,
    id: rowData?.id || '',
    linkExpiry: rowData?.linkExpiry
      ? Moment(rowData?.linkExpiry).format('YYYY-MM-DD').toString()
      : '',
    name: rowData?.name || '',
    selectedTeamId: selectedTeamId || '',
    team:
      rowData?.team?.name && rowData?.team?.id
        ? {
            label: rowData.team.name,
            value: rowData.team.id,
          }
        : null,
  };
};
