import { IFilterEditor } from 'components/UI/Table/models';
import memoizeTableQuery from 'components/UI/Table/utils/memoizeTableQuery';
import { IZScanAppsResponse, IQueryParams } from './models';
import { ammendFiltersFromQuery, fetchTableData } from './utils';
import { ISelectItem } from 'components/UI/input/Select';

const memoizeQuery = (
  rawFilters: IFilterEditor[],
  rawQuery: IQueryParams,
  availableTeams: ISelectItem[],
  setShowEmptyPage: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return memoizeTableQuery(async () => {
    const { filters, query } = ammendFiltersFromQuery(rawFilters, rawQuery);

    const data: IZScanAppsResponse | undefined = await fetchTableData(
      filters,
      query
    );

    const tableData = data?.data.content.map((appData) => {
      const teamId = appData?.teamId ?? null;

      const teamName =
        availableTeams.find(({ value }) => value === teamId)?.label ?? '';
      return { ...appData, teamName };
    });

    if (
      data?.data?.empty &&
      (query?.search === '' || query?.search === undefined) &&
      query?.query === ''
    ) {
      setShowEmptyPage(true);
    } else {
      setShowEmptyPage(false);
    }
    return {
      data: tableData ?? [],
      count: data?.data?.totalElements ?? 0,
    };
  });
};
export default memoizeQuery;
