import { Tooltip } from '@material-ui/core';
import GenericError from 'components/UI/GenericErrorBox';
import { Delete, InfoOutlined } from '@material-ui/icons';
import { ProtectionStatus } from '../ProtectionStatus';
import React, { useCallback } from 'react';
import axios from 'axios';
import useStyles from './useStyles';
import { openSnackBar, toggleModalDiffered, toggleModalDirect } from 'utils/storeUtils';
import { deleteProtectedApp } from 'api/zShieldService';
import Button from 'components/UI/Button';
import { IZShieldApp } from 'components/main/zShieldApps/ZShieldApps/models';
// import { PageContext } from './PageProvider';
import { EPROTECTION_STATUSES_ENUM } from '../models';
import { publishEvent } from 'utils/eventUtils';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';

const placeholderImageFile = () => {
  // images do not come in yet from backend.  this is our placeholder for now
  return 'zdev-logo.svg';
};

interface IProtectionStatusText {
  app: IZShieldApp;
}

const ProtectionStatusText: React.FC<IProtectionStatusText> = ({ app }) => {
  const classes = useStyles();
  const status = app?.status;
  const errorMessage = app?.errorMessage ?? EPROTECTION_STATUSES_ENUM.ERRORED.tooltip;

  return (
    <>
      {status as string === 'ERRORED' ?
        <div className={classes.protectionStatusWrapper}>
          <div><ProtectionStatus status={status} hideTooltip /></div>
          <Tooltip
            arrow
            enterDelay={300}
            placement="top"
            title={<GenericError errorMessage={errorMessage} />}
          >
            <InfoOutlined color="primary" className={classes.protectionStatusInfoIcon} />
          </Tooltip>
        </div >
        :


        <div className={classes.protectionStatusWrapper}>
          <ProtectionStatus status={status} hideTooltip />
        </div>}
    </>
  );
};


interface IAppCardContentProps {
  app: IZShieldApp;
}

const AppCardContent: React.FC<IAppCardContentProps> = ({ app }) => {
  const classes = useStyles();
  // const { pageNumber, setPageNumber, totalPages, setHasMore } = React.useContext<any>(PageContext);
  // const observer = useRef<IntersectionObserver | null>(null);
  const isStatusOutdated = app.status === EPROTECTION_STATUSES_ENUM.OUTDATED_ZSHIELD_VERSION.value;


  /*
  
  Infinite scroll to be implemented in next release
  
  */


  // const lastAppRef = useCallback(  // (*)
  //   (node) => {
  //     if (observer.current) {
  //       observer.current.disconnect();
  //     }

  //     observer.current = new IntersectionObserver((entries) => {
  //       if (entries[0].isIntersecting) {
  //         // if (pageNumber === (totalPages - 1)) {
  //         //   return setHasMore(false);
  //         // }
  //         // return setPageNumber(pageNumber + 1);
  //       }
  //     });
  //     if (node) {
  //       observer?.current?.observe(node);
  //     }
  //   },
  //   [pageNumber, totalPages, setPageNumber, setHasMore]
  // );
  const deleteApp = useCallback(async () => {
    if (!app?.id) {
      return;
    }
    try {
      await deleteProtectedApp({ appId: app?.id });
      openSnackBar(`You successfully deleted  "${app?.name}" , please check the deletion of your app by refreshing the page after some time.`);
      publishEvent('zshield:reload-tables');
      toggleModalDirect('GenericConfirm', false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        publishEvent('zshield:reload-tables');
        toggleModalDirect('GenericConfirm', false);
        openSnackBar(`Failed to delete ${app?.name}.${JSON.stringify(error?.response?.data) ?? ''}`);
      }
    }
  }, [app?.name, app?.id]);

  const handleDeleteConfirm = useCallback(() => {
    toggleModalDirect(
      'GenericConfirm',
      {
        confirmButtonText: 'Delete',
        confirmMessageText: `Are you sure you want to delete "${app?.name ?? ''}"?`,
        onConfirm: deleteApp,
        onCancel: () => toggleModalDirect('GenericConfirm', false),
      },
      { title: 'Delete App Confirmation' }
    );
  }, [app?.name, deleteApp]);

  return (
    <>
      <div className={classes.appContentContainer}>
        <div className={classes.appLogo}><img src={placeholderImageFile()} alt="App" /></div>
        <div className={classes.nameContainer}>
          <span className={classes.nameWrapper}>
            {app?.name}
          </span>
          <span className={classes.versionText}>App Version {app?.version}</span>
        </div>
        <ProtectedComponent allow={{ app_protections: 'manage' }}>
          <div className="delete--confirm" onClick={handleDeleteConfirm}>
            <Delete />
          </div>
        </ProtectedComponent>
      </div>
      <div className={classes.statusWrapper}>
        <div className={classes.protectionStatus}>
          <ProtectionStatusText app={app} />
        </div>
        <div className="button-wrapper">
          <ProtectedComponent allow={{ app_protections: 'manage' }}>
            <Button
              text='Update Now'
              className='delete--confirm'
              disabled={!isStatusOutdated}
              classes={{ root: classes.updateButton }}
              onClick={toggleModalDiffered('ZShieldUploadApp')}
            />
          </ProtectedComponent>
        </div>
      </div>
    </>
  );
};

export default AppCardContent;