import React from 'react';
// ui
import { Tabs, TabContent } from 'components/tabs';
import MDMGroupsInfo from './MDMGroupsInfo';
import { useTranslation } from 'react-i18next';

export default props => {
  const { t } = useTranslation();
  return (
    <Tabs initialTab="groups-list" syncUrl={false}>
      <TabContent label={t('GLOBAL.GROUPS_LIST')} id="groups-list">
        <MDMGroupsInfo groupsInfo={props} />
      </TabContent>
    </Tabs>
  );
};
