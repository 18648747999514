import React from 'react';
import withRouter from 'components/hocs/withRouter';

// components
import { TableFooter, TableRow, TableCell, Button } from '@material-ui/core';
import { TablePagination, TableColumnsContext } from 'components/Tables';

import CloudDownload from '@material-ui/icons/CloudDownload';

const InAppsTableFooter = props => {
  return (
    <TableColumnsContext.Consumer>
      {({ onChangePage, onChangeRows, handleDownload, rest }) => {
        return (
          <TableFooter>
            <TableRow>
              <TableCell className={props.classList.footerButtonGroup}>
                <CloudDownload
                  style={{ cursor: 'pointer', fontSize: 30 }}
                  onClick={handleDownload('inapps-table.csv')}
                />
                {rest.showDeselectButton && (
                  <div className={props.classList.clearSelectedAppWrapper}>
                    <Button
                      size="small"
                      className={props.classList.clearSelectedApp}
                      onClick={rest.handleClearSelectedApps}
                    >
                      Deselect All
                    </Button>
                  </div>
                )}
              </TableCell>
              <TablePagination
                totalResults={props.totalResults}
                onChangePage={onChangePage}
                onChangeRows={onChangeRows}
                rowsPerPageOptions={[5, 10, 25]}
                syncUrl
              />
            </TableRow>
          </TableFooter>
        );
      }}
    </TableColumnsContext.Consumer>
  );
};

export default withRouter(InAppsTableFooter);
