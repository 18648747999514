import Button from 'components/UI/Button';
import MUICheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import useStyles from './useStyles';

interface IAuthButtonProps {
  authenticated?: boolean;
  buttonText?: string;
  disabled?: boolean;
  errorText?: string;
  onClick?: () => void;
  successText?: string;
}

const AuthButton: React.FC<IAuthButtonProps> = ({
  authenticated = false,
  buttonText,
  disabled,
  onClick,
  successText,
}) => {
  const classes = useStyles();
  return (
    <>
      {!authenticated && (
        <Button
          className={classes.button}
          disabled={disabled}
          onClick={onClick}
          text={buttonText}
          type="button"
        />
      )}
      {authenticated && (
        <div className={classes.successTextWrapper}>
          <MUICheckCircleOutlineIcon
            color="primary"
            classes={{ root: classes.checkIconStyle }}
          />
          <span className={classes.successText}>
            {successText ?? 'Authenticated'}
          </span>
        </div>
      )}
    </>
  );
};

export default AuthButton;
