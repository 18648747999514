import { useCallback, useEffect, useState } from 'react';
import { fetchProtectedAppsCard } from 'api/zShieldService';
import { EPROTECTION_STATUSES_ENUM, IZShieldApp, IZShieldAppSorted } from './models';
// import { PersonalVideo } from '@material-ui/icons';

const PROTECTED = [EPROTECTION_STATUSES_ENUM.READY.value];
const UNPROTECTED = [EPROTECTION_STATUSES_ENUM.ERRORED.value];
const PROTECTION_IN_PROGRESS = [
  EPROTECTION_STATUSES_ENUM.IN_PROGRESS.value,
  EPROTECTION_STATUSES_ENUM.QUEUED.value,
  EPROTECTION_STATUSES_ENUM.SUCCESS.value,
  EPROTECTION_STATUSES_ENUM.UPLOADED.value,
  EPROTECTION_STATUSES_ENUM.READY_TO_PROCESS.value
];
const UPDATED_AVAILABLE = [EPROTECTION_STATUSES_ENUM.OUTDATED_ZSHIELD_VERSION.value];

const cardViewCategoryHelper = (app: IZShieldApp) => {
  if (PROTECTION_IN_PROGRESS.includes(app.status)) {
    return 'Protection in Progress';
  }
  if (UPDATED_AVAILABLE.includes(app.status)) {
    return 'Update Available';
  }
  if (UNPROTECTED.includes(app.status)) {
    return 'Unprotected';
  }
  if (PROTECTED.includes(app.status)) {
    return 'Protected';
  }
  return 'N/A';
};

// helper function to sort apps by the status categories

// const initialSortedApps = { 'Protection in Progress': [], 'Unprotected': [], 'Update Available': [], 'Protected': [], 'N/A': [] };


const sortZShieldAppsByCategory = (apps: IZShieldApp[]) => {
  const initialSortedApps = { 'Protection in Progress': [], 'Unprotected': [], 'Update Available': [], 'Protected': [], 'N/A': [] };
  return apps.reduce((accum, value) => {
    const cardViewCategory = cardViewCategoryHelper(value);
    return {
      [cardViewCategory]: (accum?.[cardViewCategory] as IZShieldApp[]).push({ ...value}),
      ...accum
    };
  }, initialSortedApps);
};

const useFetch = (selectedTeamId: string) => {
  const [zShieldAppsCount, setZShieldAppsCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [sortedApps, setSortedApps] = useState<IZShieldAppSorted | null>(null);
  const [persistData,setPersistData]=useState<IZShieldApp[]>([])
  const [stopScrolling,setStopScrolling]=useState<number>()
 
  const fetchApps = useCallback( async () => {
    setIsLoading(true);
    const { data } = await fetchProtectedAppsCard({ teamId: selectedTeamId,page:page }) 
    setStopScrolling(data?.content.length)
    setZShieldAppsCount(data?.totalElements);
    setPersistData((prev)=>[...prev,...data?.content])
  },[page, selectedTeamId])
   

  //here persistData consist of prev state(before scroll data) and current state(after scroll data)  and passing to sortZshiedlAppsByCategaory function
   useEffect(() => {
    if(persistData){
      const newSortedApps =  sortZShieldAppsByCategory(persistData);
      setSortedApps(newSortedApps)
      setIsLoading(false);
    }
    //clear out the app on unmount
    return () => {
      setSortedApps(null);
    };

   }, [persistData])
   
    // Implementing InfiniteScroll
    const handelInfiniteScroll = useCallback(async () => {
      try {
        if(stopScrolling){
        if (
          window.innerHeight + document.documentElement.scrollTop + 1 >=
          document.documentElement.scrollHeight
        ) {
         
         
            setIsLoading(true);
            setPage((prev) => prev + 1);
          }
         
        }
      } catch (error) {
        console.log(error);
      }
    },[stopScrolling] ) 
    
    useEffect(() => {
      window.addEventListener("scroll", handelInfiniteScroll);
      return () => window.removeEventListener("scroll", handelInfiniteScroll);
    }, [handelInfiniteScroll]);

  useEffect(() => {
    fetchApps();
  
  }, [fetchApps]);

  return { zShieldAppsCount, sortedApps, isLoading };
};
export default useFetch;