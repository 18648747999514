import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    selectLanguage: {
      width: 148,
    },
  })
);

export default useStyles;
