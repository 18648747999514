import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { IQueryParams } from 'components/main/devices/common/models';
import RangeCalendar from 'components/UI/RangeCalendar';
import Moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import {
  calculateDuration,
  RangeTypes as DEFAULT_RANGE_TYPES,
} from 'utils/RangeUtils';
import {
  IMomentDuration,
  IMomentRange,
  IMomentToFrom,
  IRangeType,
} from './models';
import useStyles from './useStyles';
import { defaultDates } from './utils';
import { useTranslation } from 'react-i18next';

interface IRangeSelectorProps {
  additionalRangeTypes?: IRangeType[];
  defaultDuration?: number | string;
  query: IQueryParams;
  storeKey: string;
  updateUISettings: (...args: unknown[]) => void;
  updateUrl: (params: IQueryParams) => void;
}

const RangeSelector: React.FC<IRangeSelectorProps> = ({
  additionalRangeTypes = [],
  defaultDuration = 5,
  query,
  storeKey,
  updateUISettings,
  updateUrl,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [minDate, setMinDate] = useState<Date>(defaultDates.minDate);
  const [maxDate, setMaxDate] = useState<Date>(defaultDates.maxDate);

  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const momentDuration: IMomentDuration | undefined = useMemo(
    () => calculateDuration(query?.duration),
    [query?.duration]
  );

  const momentToFrom: IMomentToFrom | undefined = useMemo(
    () =>
      !query?.duration &&
      !!query?.from &&
      !!query?.to &&
      !isNaN(Number(query?.from)) &&
      !isNaN(Number(query?.to))
        ? {
            from: Moment.unix(Number(query.from)),
            to: Moment.unix(Number(query.to)),
          }
        : undefined,
    [query]
  );

  const range: IMomentRange = useMemo(() => {
    if (!query?.duration && !!momentToFrom?.from && !!momentToFrom?.to) {
      return {
        start: momentToFrom.from,
        end: momentToFrom.to,
      };
    }

    return {
      start: momentDuration.after,
      end: momentDuration.before,
    };
  }, [query?.duration, momentDuration, momentToFrom]);

  const rangeTypes = useMemo(
    () => [...DEFAULT_RANGE_TYPES, ...additionalRangeTypes],
    [additionalRangeTypes]
  );

  const currentLanguage = i18n?.language || 'en-US';
  const formattedDateRange = `${range.start
    .locale(currentLanguage)
    .format('MMM Do')} - ${range.end.locale(currentLanguage).format('MMM Do')}`;

  const buttonText = useMemo(
    () =>
      rangeTypes.find(
        ({ ordinal }) =>
          !!query?.duration && ordinal === Number(query?.duration)
      )?.name ?? formattedDateRange,
    [query?.duration, rangeTypes, formattedDateRange]
  );

  const selectedPreset = useMemo(
    () =>
      !!query?.duration && !isNaN(Number(query?.duration))
        ? Number(query?.duration)
        : Number(defaultDuration),
    [query?.duration, defaultDuration]
  );

  const handleClick = useCallback(
    () => setIsOpen((value) => !value),
    [setIsOpen]
  );

  const handleClickAway = useCallback(() => setIsOpen(false), [setIsOpen]);

  const onRangeSelect = useCallback(
    (event: { from: Date | null; to: Date | null }) => {
      const update = {
        duration: '', // we need to set this to a string so the uisettings will honor a backfill for duration
        from: String(Moment(event.from).unix()),
        page: '0',
        to: String(Moment(event.to).unix()),
      };

      updateUrl(update);
      updateUISettings({ domain: storeKey, update });
      setIsOpen(false);
      setMinDate(defaultDates.minDate);
      setMaxDate(defaultDates.maxDate);
    },
    [updateUrl, storeKey, updateUISettings]
  );

  const onRangeInitialSelect = useCallback(
    (event: { from: Date | null; to: Date | null }) => {
      setMinDate(
        Moment(event.from).subtract(90, 'days').startOf('day').toDate()
      );
      setMaxDate(Moment(event.from).add(90, 'days').endOf('day').toDate());
    },
    [setMaxDate, setMinDate]
  );

  const onPresetSelect = useCallback(
    (duration: number) => {
      const update = {
        duration: String(duration),
        from: undefined,
        to: undefined,
        page: '0',
      };

      updateUrl(update);
      updateUISettings({
        domain: storeKey,
        update,
      });

      setIsOpen(false);
      setMinDate(defaultDates.minDate);
      setMaxDate(defaultDates.maxDate);
    },
    [updateUrl, storeKey, updateUISettings]
  );

  return (
    <div className={classes.dropdownWrapper}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <div className="select--button" onClick={handleClick}>
            {t(buttonText)}
            <ArrowDropDown className={classes.icon} />
          </div>
          {isOpen && (
            <RangeCalendar
              className={classes.calendar}
              rootCalendarProps={{
                locale: {
                  headerFormat: 'MMM Do',
                },
                height: 275,
                minDate: minDate,
                maxDate: maxDate,
              }}
              rangePresets={rangeTypes}
              onRangeSelect={onRangeSelect}
              onRangeInitialSelect={onRangeInitialSelect}
              onPresetSelect={onPresetSelect}
              selectedPreset={selectedPreset}
              start={range.start.toDate()}
              end={range.end.toDate()}
            />
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default RangeSelector;
