import React from 'react';
import Moment from 'moment';
import useStyles from './useStyles';

interface IFooterProps {
  privacyPolicyLink: string;
  termsLink: string;
}

const Footer: React.FC<IFooterProps> = ({ privacyPolicyLink, termsLink }) => {
  const classes = useStyles();

  return (
    <footer className={classes.publicFooter}>
      <a href={termsLink} target="_blank" rel="noopener noreferrer">
        <div className="entry-content-footer">Terms and Conditions</div>
      </a>
      |
      <a href={privacyPolicyLink} target="_blank" rel="noopener noreferrer">
        <div className="entry-content-footer"> Privacy Policy</div>
      </a>
      | <div>{`Copyright © 2018-${Moment().year()} All Rights Reserved`}</div>
    </footer>
  );
};

export default Footer;
