import { Api } from 'config/axiosConfig';
import { IFormSubmit } from './Form/models';
import { FormikHelpers } from 'formik';

export const updateZShieldApp = async (
  formData: IFormSubmit,
  formikHelpers: FormikHelpers<IFormSubmit>,
) => {
  const protectionPayload = formData?.protectionCoverages.reduce((accum, protectionCoverage) => {
    return {
      ...accum,
      [`${protectionCoverage}`]: true
    };
  }, {});
  const payload = {
    description: formData?.description,
    groupId: formData?.group?.value,
    teamId: formData?.team?.value,
    ...protectionPayload
  };
  const apiUrl = `api/zapp/v1/builds/${formData?.buildId}/protect`;
  try {
    await Api.put(apiUrl, payload);
  } catch (e) {
    console.error('Error in updating build:', e);
    formikHelpers.setSubmitting(false);
  }
};