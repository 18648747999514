import { AppBar } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import withRouter from 'components/hocs/withRouter';
import React from 'react';
import useStyles from './useStyles';

interface IHeaderProps {}

const Header: React.FC<IHeaderProps> = () => {
  const classes = useStyles();

  return (
    <AppBar className={classes.headerContainer}>
      <Grid
        container
        justify="center"
        spacing={6}
        direction="row"
        wrap="nowrap"
      >
        <Grid item>
          <div className={classes.logoContainer}>
            <img
              className={classes.logo}
              src="subscription/Zimperium_greenZ_WSM.svg"
              alt="MTD Logo"
            />
          </div>
        </Grid>
        <Grid item>
          <div className={classes.logoContainer}>
            <img
              className={classes.logo}
              src="subscription/stc_logo.png"
              alt="STC Logo"
            />
          </div>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default withRouter(Header);
