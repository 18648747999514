import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

export default state => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      mtdDevices: {
        ...state.uiSettings.devices,
        tableHeaders: uiSettingsDefaults.mtdDevices.tableHeaders,
      },
    },
  };
};
