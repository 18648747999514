/* eslint-disable max-lines-per-function */
import { Grid } from '@material-ui/core';
import MUIInputAdornment from '@material-ui/core/InputAdornment';
import {
  deleteTRMPolicy,
  fetchAllResponseTypes,
  subscribeToThreatNotifications,
  updateTRMPolicy,
} from 'api/TRMPolicyService';
import SearchIcon from 'components/UI/icons/SearchIcon';
import { ISelectItem } from 'components/UI/input/Select';
import TextField from 'components/UI/input/TextField';
import withRouter from 'components/hocs/withRouter';
import { IThreatType } from 'components/main/MTDInsights/models';
import { mdmActionPayloadHelper } from 'components/main/policies/threats/helpers';
import _ from 'lodash';
import { LanguagePayloadMapping } from 'models/language';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  getAvailableTeamsAsSelectList,
  getSelectedTeamName,
} from 'reducers/TeamReducers';
import { getSelectedTeam } from 'reducers/UiSettingsReducers';
import { cleanResponseTypes } from 'utility/ResponseTypesUtilities';
import { openSnackBar, toggleModalDirect } from 'utils/storeUtils';
import { searchTable } from 'utils/tableUtils';
import useAsyncResult from 'utils/useAsyncResult';
import EmptyPolicies from '../common/EmptyPolicies';
import PolicySelector from '../common/PolicySelector';
import TRMPolicyTable from './TRMPolicyTable';
import TableAction from './TableAction';
import MtdTRMPolicyTable from './MtdTRMPolicyTable';
import {
  MDMSelectionHelper,
  mergeRulesWithThreats,
  sortRules,
} from './helpers';
import {
  IActions,
  IMDMActionsList,
  IOSResponseTypes,
  ISelectTRMPolicyTableData,
  ITRMGroups,
  ITRMPolicy,
  IUserThreatNotification,
  PolicyChangeOptions,
  TRMTableActions,
} from './models';
import {
  fetchAllPoliciesList,
  fetchTRMData,
  fetchThreatTypesDataForTRMPolicyService,
  fetchUserThreatNotificationsData,
} from './tableQuery';
import useStyles from './useStyles';
import ResponseTypesModal from './TableComponents/ResponseTypesModal';

const defaultPolicy: ITRMPolicy = {
  id: '',
  accountId: '',
  groups: [],
  name: '',
  rules: [],
  androidJsonHash: '',
  androidProtoHash: '',
  iosJsonHash: '',
  iosProtoHash: '',
  isDeployed: false,
  emm: false,
  assigned: false,
  global: false,
  connection: {
    actions: [],
  },
};

interface ITRMPolicyFProps {
  mtdModule: boolean;
  module: string;
  q: {
    teamId: string;
    teamfilter: string[];
    zappId: string;
  };
  ziapDistribution: number;
  location: {
    query: {
      trm: string;
    };
  };
  match: {
    module: string;
  };
}

const filterableFields: (keyof ISelectTRMPolicyTableData)[] = [
  'name',
  'vector',
  'severityString',
];

const TRMPolicy = (props: ITRMPolicyFProps) => {
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState<string>();
  const [selectedPolicy, setSelectedPolicy] = useState<ISelectItem>();
  const [currentPolicy, setCurrentPolicy] = useState(defaultPolicy);
  const [policyDetails, setPolicyDetails] = useState(defaultPolicy);
  const [mdmActionsList, setMdmActionsList] = useState<IMDMActionsList>();
  const [mdmThreatActions, setMDMThreatActions] = useState<IActions[]>();
  const [mdmMitigationActions, setMDMMitigationActions] =
    useState<IActions[]>();
  const [trmGroups, setTRMGroups] = useState<ITRMGroups[]>();
  const [selectTRMPolicyTableData, setSelectTRMPolicyTableData] = useState<
    ISelectTRMPolicyTableData[]
  >([]);
  const [osResponseTypes, setOSResponseTypes] = useState<IOSResponseTypes>();
  const [threatNotifications, setThreatNotifications] =
    useState<IUserThreatNotification>();
  const { t, i18n } = useTranslation();

  const distribution = useMemo(
    () => (props?.module === 'zdefend' ? '0' : props?.ziapDistribution),
    [props?.module, props?.ziapDistribution]
  );

  const threatTypes = useAsyncResult<IThreatType>(
    fetchThreatTypesDataForTRMPolicyService,
    distribution,
    !!i18n.language
      ? LanguagePayloadMapping[
          i18n.language.toLowerCase() as keyof typeof LanguagePayloadMapping
        ]
      : 'en'
  );

  const fetchResponseTypes = async () => {
    const result = await fetchAllResponseTypes();
    const response = cleanResponseTypes(result.data) as IOSResponseTypes;
    setOSResponseTypes(response);
  };

  const fetchThreatNotifications = useCallback(async () => {
    if (selectedPolicy?.value) {
      const result = await fetchUserThreatNotificationsData(
        selectedPolicy.value as string
      );
      setThreatNotifications(result);
    }
  }, [selectedPolicy]);

  const filteredData = useMemo(
    () => searchTable(searchTerm, selectTRMPolicyTableData, filterableFields),
    [searchTerm, selectTRMPolicyTableData]
  );

  const filteredDataIds = useMemo(
    () => filteredData.map((d) => d.id),
    [filteredData]
  );

  useEffect(() => {
    if (currentPolicy?.connection?.actions?.length > 0) {
      setMdmActionsList(
        MDMSelectionHelper(
          currentPolicy.connection.actions,
          t
        ) as unknown as IMDMActionsList
      );
    }
  }, [currentPolicy?.connection?.actions, t]);

  useEffect(() => {
    if (currentPolicy && threatTypes) {
      const { groups, rules } = currentPolicy;

      if (threatNotifications !== undefined) {
        const { table } = mergeRulesWithThreats(
          rules,
          threatTypes,
          mdmActionsList,
          threatNotifications
        );

        setMDMThreatActions(mdmActionsList?.threatActions);
        setMDMMitigationActions(mdmActionsList?.mitigationActions);
        setTRMGroups(groups as unknown as ITRMGroups[]);
        setSelectTRMPolicyTableData(sortRules(table, 'name', 'asc'));
      }
    }
  }, [currentPolicy, threatTypes, mdmActionsList, threatNotifications]);

  useEffect(() => {
    fetchResponseTypes();
    fetchThreatNotifications();
  }, [fetchThreatNotifications]);

  const onSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setSearchTerm(value);
    },
    [setSearchTerm]
  );

  const subscribeToNotifications = useCallback(
    async (rules: ISelectTRMPolicyTableData[], toSubscribe: boolean) => {
      const promises = rules.map((r) => {
        const payload = {
          sendEmail:
            r.threatNotification?.sendEmail === undefined
              ? toSubscribe
              : !r.threatNotification?.sendEmail,
          threatTypeIds: [r.threatTypeId],
        };

        return subscribeToThreatNotifications(
          { trmId: selectedPolicy?.value },
          payload
        );
      });

      await Promise.all(promises);

      openSnackBar(
        t(`GLOBAL.SUCCESSFULLY_${toSubscribe ? '' : 'UN'}SUBSCRIBED`)
      );
    },
    [selectedPolicy?.value, t]
  );

  const tableHeaderCheckboxHelper = useCallback(
    async (optionName: PolicyChangeOptions, checkboxValue: boolean) => {
      const optionFieldName: string = optionName;

      const newPolicy = _.cloneDeep(currentPolicy);

      switch (optionName) {
        case PolicyChangeOptions.EnableCheckbox:
          newPolicy.rules = newPolicy.rules.map((item) => {
            if (filteredDataIds.includes(item.id)) {
              return {
                ...item,
                selectRow: checkboxValue,
              };
            }

            return item;
          });
          break;
        case PolicyChangeOptions.EnableNotifications:
        case PolicyChangeOptions.DisableNotifications: {
          const filtered = newPolicy.rules.filter((r) => r.selectRow);
          const toSubscribe =
            optionName === PolicyChangeOptions.EnableNotifications;

          await subscribeToNotifications(filtered, toSubscribe);
          await fetchThreatNotifications();

          newPolicy.rules = newPolicy.rules.map((item) => {
            if (item.selectRow) {
              return {
                ...item,
                threatNotification: {
                  sendEmail: toSubscribe,
                  sendSms: false,
                  threatTypeId: item.threatTypeId,
                  trmId: '',
                  userId: '',
                },
              };
            }

            return item;
          });
          break;
        }
        default:
          newPolicy.rules = newPolicy.rules.map((item) => {
            if (item.selectRow) {
              return {
                ...item,
                [optionFieldName]: checkboxValue,
              };
            }

            return item;
          });
          break;
      }

      setCurrentPolicy(newPolicy);
    },
    [
      currentPolicy,
      filteredDataIds,
      subscribeToNotifications,
      fetchThreatNotifications,
    ]
  );

  const hasSelectedRows = () => {
    const result = currentPolicy.rules.some((item) => item.selectRow);
    if (result !== undefined) {
      return result;
    }
    return false;
  };

  const handleTableAction = (type: TRMTableActions | undefined) => {
    switch (type) {
      case TRMTableActions.DeviceAlert:
        toggleModalDirect(
          'DeviceAlert',
          {
            variant: 'DeviceAlert',
            policyData: selectTRMPolicyTableData,
            trmPolicyId: selectedPolicy?.value,
          },
          { largeModal: true }
        );
        break;
      case TRMTableActions.EnableDetection:
        tableHeaderCheckboxHelper(PolicyChangeOptions.ShouldCollect, true);
        break;
      case TRMTableActions.DisableDetection:
        tableHeaderCheckboxHelper(PolicyChangeOptions.ShouldCollect, false);
        break;
      case TRMTableActions.EnableAlert:
        tableHeaderCheckboxHelper(PolicyChangeOptions.AlertUser, true);
        break;
      case TRMTableActions.SubscribeNotifications:
        tableHeaderCheckboxHelper(
          PolicyChangeOptions.EnableNotifications,
          true
        );
        break;
      case TRMTableActions.UnsubscribeNotifications:
        tableHeaderCheckboxHelper(
          PolicyChangeOptions.DisableNotifications,
          true
        );
        break;
      default:
        break;
    }
  };

  const handleTRMPolicyRuleChange = useCallback(
    (
      optionName: PolicyChangeOptions,
      value: unknown,
      rule: string | ISelectTRMPolicyTableData
    ) => {
      if (rule === 'all') {
        return tableHeaderCheckboxHelper(optionName, value as boolean);
      }

      const newRule = rule as ISelectTRMPolicyTableData;
      const index = currentPolicy.rules.findIndex((r) => r.id === newRule.id);
      const newPolicy = _.cloneDeep(currentPolicy);

      switch (optionName) {
        case PolicyChangeOptions.EditAlertUser:
          toggleModalDirect(
            'DeviceAlert',
            {
              variant: 'DeviceAlert',
              editThreatTypeId: newRule.threatTypeId,
              policyData: selectTRMPolicyTableData,
              trmPolicyId: selectedPolicy?.value,
            },
            { largeModal: true }
          );
          return;
        case PolicyChangeOptions.MdmThreatTarget:
        case PolicyChangeOptions.MdmMitigationTarget: {
          const actions = value as IActions | null;

          const mdmProperty =
            optionName === PolicyChangeOptions.MdmThreatTarget
              ? 'mdmThreatAction'
              : 'mdmMitigationAction';

          newPolicy.rules[index] = {
            ...newPolicy.rules[index],
            [optionName]: actions?.value,
            [mdmProperty]: actions?.mdmId,
          };
          break;
        }
        default:
          newPolicy.rules[index] = {
            ...newPolicy.rules[index],
            [optionName]: value,
          };
          break;
      }

      setCurrentPolicy(newPolicy);
    },
    [
      currentPolicy,
      selectedPolicy,
      selectTRMPolicyTableData,
      tableHeaderCheckboxHelper,
    ]
  );

  const onNotificationChange = useCallback(
    async (rule: ISelectTRMPolicyTableData) => {
      const newPolicy = _.cloneDeep(currentPolicy);
      const index = newPolicy.rules.findIndex((r) => r.id === rule.id);

      newPolicy.rules[index].threatNotification = rule.threatNotification;

      await fetchThreatNotifications();
      setCurrentPolicy(newPolicy);
    },
    [currentPolicy, fetchThreatNotifications]
  );

  const getRequestObject = useCallback(
    (policy?: ITRMPolicy) => {
      if (!policy) {
        return undefined;
      }

      const rules = policy.rules.map((rule) => {
        let mdmActionObj = {};

        if (
          !_.isEmpty(rule.mdmThreatAction) ||
          !_.isEmpty(rule.mdmMitigationAction)
        ) {
          mdmActionObj = mdmActionPayloadHelper(rule);
        }

        return {
          ...rule,
          ...mdmActionObj,
        };
      });

      return {
        id: policy.id,
        accountId: policy.accountId,
        trmGroups: _.sortBy(trmGroups, (g) => g.id),
        name: policy.name,
        rules: _.sortBy(
          // eslint-disable-next-line
          rules.map(({ threatNotification, selectRow, ...r }) => r),
          (rule) => rule.id
        ),
        global: policy.global,
        team: selectedPolicy?.team,
        connection: selectedPolicy?.connection,
      };
    },
    [selectedPolicy, trmGroups]
  );

  const fetchTRMDataCall = useCallback(
    (id?: string | number | null) => {
      return fetchTRMData(t, id);
    },
    [t]
  );

  return (
    <>
      <div>
        <PolicySelector
          query={props.location.query.trm}
          policyType="Threat"
          canDeploy={() => true}
          getRequestObject={getRequestObject}
          fetchPolicies={fetchAllPoliciesList}
          fetchPolicyDetails={fetchTRMDataCall}
          updatePolicy={updateTRMPolicy}
          deletePolicy={deleteTRMPolicy}
          selectedPolicy={selectedPolicy}
          setSelectedPolicy={setSelectedPolicy}
          currentPolicy={currentPolicy}
          setCurrentPolicy={setCurrentPolicy}
          policyDetails={policyDetails}
          setPolicyDetails={setPolicyDetails}
          mtdModule={props.mtdModule}
        />

        {!currentPolicy && <EmptyPolicies />}

        {currentPolicy ? (
          <div>
            <div className={classes.actionsBar}>
              <div className={classes.searchBox}>
                <TextField
                  name="search"
                  placeholder={t('GLOBAL.SEARCH')}
                  onChange={onSearchChange}
                  InputProps={{
                    endAdornment: (
                      <MUIInputAdornment
                        position="end"
                        classes={{ root: classes.searchIcon }}
                      >
                        <SearchIcon />
                      </MUIInputAdornment>
                    ),
                  }}
                />
              </div>

              <TableAction
                editMode={hasSelectedRows()}
                mtdModule={props.mtdModule}
                handleTableAction={handleTableAction}
              />
            </div>
            <Grid container style={{ marginTop: 10, overflow: 'auto' }}>
              <Grid item md={12}>
                {props.mtdModule ? (
                  <MtdTRMPolicyTable
                    key={selectedPolicy?.value}
                    tableData={filteredData}
                    emmConnections={currentPolicy?.connection !== undefined}
                    handleTRMPolicyRuleChange={handleTRMPolicyRuleChange}
                    selectedTRMPolicy={selectedPolicy}
                    mdmThreatActions={mdmThreatActions}
                    mdmMitigationActions={mdmMitigationActions}
                    osResponseTypes={osResponseTypes}
                    onNotificationChange={onNotificationChange}
                  />
                ) : (
                  <TRMPolicyTable
                    key={selectedPolicy?.value}
                    tableData={filteredData}
                    handleTRMPolicyRuleChange={handleTRMPolicyRuleChange}
                    osResponseTypes={osResponseTypes}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        ) : null}
      </div>
      <ResponseTypesModal />
    </>
  );
};

const mapStateToProps = (state: any) => {
  const selectedTeam = getSelectedTeam(state);
  return {
    scopeBounds: state?.user?.scopeBounds ?? '',
    selectedTeam,
    selectedTeamName: getSelectedTeamName(state, selectedTeam),
    ziapDistribution: _.get(state, 'user.account.ziapDistribution'),
    availableTeams: getAvailableTeamsAsSelectList(state),
  };
};

export default withRouter(connect(mapStateToProps)(TRMPolicy));
