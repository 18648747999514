import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, config }: any) =>
  createStyles({
    iconStyle: {
      color: palette.components.globalHeader.contrastText,
      fontSize: config.textSizes.paragon,
    },
    enterAccount: {
      fontSize: config.textSizes.primer,
      marginLeft: 5,

      '&:hover': {
        cursor: 'pointer',
      },
    },
    favorite: {
      cursor: 'pointer',
      color: palette.common.canery,
    },
  })
);

export default useStyles;
