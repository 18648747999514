import React, { Fragment, useState } from 'react';
import _ from 'lodash';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ZButton from 'UI/Buttons/ZButton';
// form
import { toggleModalDirect, openSnackBar } from 'utils/storeUtils';
import { publishEvent } from 'utils/eventUtils';
import GenericErrorBox from 'components/inputs/GenericErrorBox';
import { deleteDevice } from 'api/DevicesService';
import CheckBox from 'components/inputs/Checkbox';
import { useTranslation } from 'react-i18next';

export default function DeviceDelete(props) {
  const [error, setError] = useState(null);
  const [logoutDevice, setLogoutDevice] = useState(false);
  const { t, ready } = useTranslation();
  const handleSubmit = () => {
    const deviceId = _.get(props, 'data.id');
    deleteDevice({ deviceId }, { logout: logoutDevice })
      .then(() => {
        toggleModalDirect('DeviceDelete', false);
        publishEvent('table:force-fetch-devices'); // check this?
        openSnackBar(t(`MTD.DEVICES.DELETED_SUCCESSFULLY`));
      })
      .catch((err) => {
        setError(_.get(err, 'response.data.message', 'Unknown Error'));
      });
  };

  function handleClose() {
    toggleModalDirect('DeviceDelete', false);
  }

  if (!ready) {
    return null;
  }

  return (
    <Fragment>
      <DialogContent>
        <p>{t('MTD.DEVICES.DELETE_DEVICE_CONFIRMATION')}</p>
        <p>{t('MTD.DEVICES.DELETE_DEVICE_DISCLAMER')}</p>
        <CheckBox
          checked={logoutDevice}
          onChange={() => setLogoutDevice(!logoutDevice)}
          label={<p>{t('MTD.DEVICES.DEVICE_LOGOUT_ON_DELETE')}</p>}
        />

        <DialogActions>
          <ZButton
            styleName="modalCancel"
            action={() => handleClose()}
            color="secondary"
            buttonText={t('GLOBAL.CANCEL')}
          />
          <ZButton
            id="fix"
            onClick={handleSubmit}
            color="primary"
            styleName="modalSave"
            buttonText={t('GLOBAL.OK')}
          />
        </DialogActions>
        <GenericErrorBox errorMessage={error} />
      </DialogContent>
    </Fragment>
  );
}
