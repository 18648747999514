import { Card } from '@material-ui/core';
import Button from 'components/UI/Button';
import useStyles from './useStyles';
import { TFunction } from 'react-i18next';

interface CustomDialogProps {
  isOpen: boolean;
  onClose: () => void;
  t: TFunction<'translation', undefined>;
}

const CustomDialog: React.FC<CustomDialogProps> = ({ isOpen, onClose, t }) => {
  if (!isOpen) {
    return null;
  }

  const classes = useStyles();
  return (
    <div className={classes.customDialog}>
      <Card className={classes.customDialogContent}>
        <p>This Group Name is being used in policies can not be deleted </p>
        <Button text={t('GLOBAL.OK')} color="secondary" onClick={onClose} />
      </Card>
    </div>
  );
};

export default CustomDialog;
