import axios from 'axios';
import withRouter from 'components/hocs/withRouter';
import { Api } from 'config/axiosConfig';
import React, { useState, useCallback } from 'react';
import { toggleModalDirect } from 'utils/storeUtils';
import Form from './Form';
import { formatErrorMessage } from './utils';
import { IUploadError } from './Form/models';

export const createModalConfig = {
  fullWidth: true,
};

const ZShieldUploadApp: React.FC = () => {
  const [fileData, setFileData] = useState<File | null>(null);
  const [uploadedPercent, setUploadedPercent] = useState(0);
  const [uploaded, setUploaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const resetModal = () => {
    setErrorMessage('');
    setUploaded(false);
    setUploadedPercent(0);
  };

  const uploadApp = (url: string, formData: FormData) => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    return Api.post(url, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
      cancelToken: source.token,
      onUploadProgress: (progressEvent: ProgressEvent) => {
        setUploadedPercent((progressEvent.loaded / progressEvent.total) * 100);
      },
    });
  };

  const handleSubmit = useCallback(async () => {
    if (!fileData) {
      setErrorMessage('Upload Error - Please Try Again');
      return;
    }

    // Create form data
    const formData = new FormData();
    formData.append('file', fileData);

    const url = 'api/zapp/v1/apps/upload';

    try {
      const { data } = await uploadApp(url, formData);
      setUploadedPercent(100);
      setUploaded(true);
      toggleModalDirect('ZShieldRegisterApp', data, createModalConfig);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const status = e?.response?.status ?? -1;
        const statusText = e?.response?.statusText ?? 'Upload Error';
        const errorData: IUploadError = e?.response?.data;
        const dataString =
          typeof errorData?.message === 'string'
            ? errorData.message
            : 'Please Try Again';
        console.log(e?.response);
        setErrorMessage(formatErrorMessage(statusText, dataString, status));
      } else {
        console.log(e);
        setErrorMessage(formatErrorMessage('Upload Error', 'Please Try Again'));
      }
    }
  }, [fileData]);

  return (
    <Form
      errorMessage={errorMessage}
      fileData={fileData}
      handleSubmit={handleSubmit}
      resetModal={resetModal}
      setFileData={setFileData}
      uploaded={uploaded}
      uploadedPercent={uploadedPercent}
    />
  );
};

export default withRouter(ZShieldUploadApp);
