import { withStyles } from '@material-ui/core/styles';
import { updateAdminAccountById } from 'api/AdminAccountService';
import { addNewManagedAccount, updateManagedAccount } from 'api/PartnerService';
import { addNewAccount } from 'api/apis';
import withRouter from 'components/hocs/withRouter';
import Moment from 'moment';
import React, { useState } from 'react';
import { publishEvent } from 'utils/eventUtils';
import { openSnackBar, toggleModalDirect } from 'utils/storeUtils';
import Form from './Form';
import { IFeatureListForFormik, IFormSubmit } from './Form/models';
import styles from './styles';
import {
  generateFeatureListValues,
  prepareFeaturesPayload,
} from './Form/utils';
import { fetchFeaturesByPlanIds } from 'api/PlansService';
import axios from 'axios';
import { IAccountData } from './Form/models';

interface IAccountCreateEditProps {
  data: IAccountData;
}

const AccountCreateEdit: React.FC<IAccountCreateEditProps> = ({ data }) => {
  const [externalError, setExternalError] = useState<string | undefined>(
    undefined
  );
  const [initialFeatureList, setInitialFeatureList] =
    useState<IFeatureListForFormik | null>(null);
  const accountId = data?.id;
  React.useEffect(() => {
    const planIdFromExistingAccount = data?.subscription?.plans.map(
      ({ id }) => id
    );
    const fetchFeaturesForInitialFormData = async () => {
      const { data } = await fetchFeaturesByPlanIds(
        { planIds: planIdFromExistingAccount },
        accountId
      );
      const initialFeaturesCheckboxFormed = generateFeatureListValues(data);
      setInitialFeatureList(initialFeaturesCheckboxFormed);
    };
    fetchFeaturesForInitialFormData();
  }, [data?.subscription?.plans, accountId]);

  const handleSubmit = async ({
    accountName,
    accountType,
    auditLogDays,
    description,
    ela,
    featureList,
    hostname,
    languagePreference,
    managingAccount,
    participateInZlabsResearch,
    plans,
    salesforceId,
    subscriptionEnd,
    tasEnabled,
    tfaRequired,
    threatsDays,
    geography,
    marketVertical,
    threatDefaultsSource,
  }: IFormSubmit) => {
    const updatedAccount = {
      businessPartner: accountType === 'partner',
      dataRetentionSettings: {
        threatsDays: threatsDays?.value ?? null,
        auditLogDays: auditLogDays?.value ?? null,
      },
      description,
      ela: !!ela,
      hostname: !!hostname ? hostname : null,
      languagePreference: languagePreference?.value ?? null,
      managingAccountId:
        accountType === 'managed' ? managingAccount?.value ?? null : null,
      name: accountName,
      participateInZlabsResearch: participateInZlabsResearch?.value,
      planIds: plans,
      features: prepareFeaturesPayload(featureList ?? []),
      salesforceId,
      subscriptionEndDate: Moment(subscriptionEnd, 'YYYY-MM-DD'),
      tasEnabled: tasEnabled?.value,
      tfaRequired: !!tfaRequired,
      geography: geography?.value,
      marketVertical: marketVertical?.value,
      threatDefaultsSource,
    };

    const updateMethod = () =>
      !!data?.selectedAccountId
        ? !!data?.partnerModal
          ? updateManagedAccount(
              { accountId: data.selectedAccountId },
              updatedAccount
            )
          : updateAdminAccountById(data.selectedAccountId, updatedAccount)
        : !!data?.partnerModal
        ? addNewManagedAccount({}, updatedAccount)
        : addNewAccount(updatedAccount);

    const forceFetchTimeout = !!data?.selectedAccountId ? 50 : 1500;
    const snackBarMessage = !!data?.selectedAccountId
      ? 'Account Successfully Updated'
      : 'Account Successfully Created';

    try {
      await updateMethod();
      toggleModalDirect('AccountsCreateEdit', false);
      openSnackBar(snackBarMessage);
      setTimeout(
        () =>
          publishEvent(
            `table:force-fetch-${!!data?.partnerModal ? 'managed' : ''}Accounts`
          ),
        forceFetchTimeout
      );
      // Use of any here is discouraged -- but due to us having multiple request frameworks
      // [vanilla axios and servicehandlerfactory or circulardependency land for short]
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        (error?.response?.status === 409 ||
          error?.response?.status === 400 ||
          error?.response?.status === 403)
      ) {
        if (error?.response?.data) {
          return setExternalError(error?.response?.data);
        }
        setExternalError('An unknown error has occurred. Please try again.');
      }
    }
  };
  return (
    <Form
      data={{ ...data, featureList: initialFeatureList }}
      externalError={externalError}
      handleSubmit={handleSubmit}
    />
  );
};

export default withStyles(styles)(withRouter(AccountCreateEdit));
