import Chip from '@material-ui/core/Chip';
import Button from 'components/UI/Button';
import InfiniteSelect from 'components/UI/input/InfiniteSelect';
import {
  IFetchOptionsParams,
  IFetchOptionsResult,
} from 'components/UI/input/InfiniteSelect/models';
import Select, { ISelectItem } from 'components/UI/input/Select';
import CustomPolicyOption from 'components/main/policies/common/CustomPolicyOption';
import { Formik, Field as FormikField, Form as FormikForm } from 'formik';
import { useCallback, useMemo, useState, useEffect } from 'react';
import useAsyncResult from 'utils/useAsyncResult';
import { IStandardAuth } from '../../../AddEditCloneEMMAuth/StandardForm/models';
import { IVMWareAuth } from '../../../AddEditCloneEMMAuth/VMWareForm/models';
import { IFullData } from '../../../emmTable/MDM/models';
import {
  IAuthForGroupsCreate,
  ICloneInfoFromAuth,
  IConnector,
  TModalMode,
} from '../../../models';
import { IIntuneFields } from '../models';
import SelectGroupsSkeleton from './GroupsSkeleton/SelectGroupSkeleton';

import initialValues from './initialValues';
import {
  IAllPolicyOptions,
  IFormData,
  IResponseFormData,
  IGroupsWithPages,
} from './models';
import Schema from './schema';
import useStyles from './useStyles';
import { fetchAllPolicyOptions, fetchDeviceSpaces, fetchGroups } from './utils';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import Divider from '@material-ui/core/Divider';
import { TFunction } from 'react-i18next';
export interface IFormProps {
  t: TFunction<'translation', undefined>;
  authForGroupsCreate?: IAuthForGroupsCreate<IStandardAuth | IVMWareAuth>;
  clearGroupTable: () => void;
  cloneInfoFromAuth?: ICloneInfoFromAuth;
  connector?: IConnector;
  data?: IFullData | undefined;
  groupValuesToExclude: string[];
  handleAddTableRowPolicyGroup: (values: IFormData) => void;
  isSubmitting: boolean;
  isIntune: boolean;
  mode?: TModalMode;
  previousFieldValues: Partial<IFormData> | undefined;
  setIntuneFields: React.Dispatch<React.SetStateAction<IIntuneFields>>;
  setPreviousFieldValues: React.Dispatch<
    React.SetStateAction<Partial<IFormData> | undefined>
  >;
  setThirdPartyMDMSelected: React.Dispatch<React.SetStateAction<boolean>>;
  thirdPartyMDMSelected: boolean;
  setIsOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
  showPolicy?: boolean;
  handleAddTableRowResponseActionGroup?: (values: IResponseFormData) => void;
  setReloadedGroupData?: React.Dispatch<
    React.SetStateAction<IGroupsWithPages | undefined>
  >;
  reloadedGroupData?: IGroupsWithPages | undefined;
  reloadingGroups?: boolean;
  setReloadingGroups?: React.Dispatch<React.SetStateAction<boolean>>;
  groupType?: string;
  deviceSpace?: string | number;
}
// eslint-disable-next-line max-lines-per-function
const Form: React.FC<IFormProps> = ({
  t,
  authForGroupsCreate,
  cloneInfoFromAuth,
  connector,
  data,
  groupValuesToExclude,
  handleAddTableRowPolicyGroup,
  isSubmitting = false,
  isIntune,
  mode = 'ADD',
  previousFieldValues,
  setPreviousFieldValues,
  thirdPartyMDMSelected,
  setIsOpenModal,
  showPolicy,
  handleAddTableRowResponseActionGroup,
  reloadedGroupData,
  setReloadingGroups,
  reloadingGroups,
  setReloadedGroupData,
  groupType,
  deviceSpace,
}) => {
  const classes: any = useStyles();
  const [selectedGroups, setSelectedGroups] = useState<ISelectItem[]>([]);
  const isSOTIConnector = connector?.id === 'MobiControlConnector';

  const hasSpacesCapability = useMemo(
    () =>
      !!(
        connector?.capabilities?.[0] &&
        connector.capabilities.includes('DEVICE_SPACES')
      ),
    [connector?.capabilities]
  );

  const deviceSpaces: ISelectItem[] | undefined = useAsyncResult(
    fetchDeviceSpaces,
    hasSpacesCapability,
    mode === 'EDIT'
      ? data?.rowData?.id
      : authForGroupsCreate?.id ?? cloneInfoFromAuth?.connectionIdToClone
  );

  useEffect(() => {
    const fetchData = async () => {
      if (hasSpacesCapability && mode !== 'EDIT') {
        setReloadingGroups?.(true);
        setReloadedGroupData?.(
          await fetchGroups(
            hasSpacesCapability,
            authForGroupsCreate?.id ??
              cloneInfoFromAuth?.connectionIdToClone ??
              '',
            previousFieldValues?.deviceSpace?.value,
            groupType
          )
        );
        setReloadingGroups?.(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupType]);
  // If in edit mode, this pulls the first page of groups, and nothing in create mode
  const initialGroupData: IGroupsWithPages | undefined = useAsyncResult(
    fetchGroups,
    hasSpacesCapability,
    data?.rowData?.id ??
      authForGroupsCreate?.id ??
      cloneInfoFromAuth?.connectionIdToClone,
    deviceSpace,
    groupType
  );

  const policies: IAllPolicyOptions | undefined = useAsyncResult(
    fetchAllPolicyOptions,
    data?.rowData?.id,
    mode,
    data?.rowData?.teamId ??
      authForGroupsCreate?.teamId ??
      cloneInfoFromAuth?.teamId
  );
  const initialGroupOptionsLoaded = useMemo(
    () =>
      (hasSpacesCapability && mode !== 'EDIT') ||
      !!initialGroupData?.groupOptions,
    [hasSpacesCapability, initialGroupData?.groupOptions, mode]
  );

  const fetchPagedGroups = useCallback(
    async (
      params: IFetchOptionsParams,
      deviceSpaceId: string
    ): Promise<IFetchOptionsResult> => {
      const groupData = await fetchGroups(
        hasSpacesCapability,
        data?.rowData?.id ??
          authForGroupsCreate?.id ??
          cloneInfoFromAuth?.connectionIdToClone ??
          '',
        deviceSpaceId,
        groupType,
        params?.page,
        params?.query
      );

      return {
        options: groupData.groupOptions,
        page: groupData.page,
        lastPage: groupData.lastPage,
      };
    },

    [
      authForGroupsCreate?.id,
      cloneInfoFromAuth?.connectionIdToClone,
      data?.rowData?.id,
      groupType,
      hasSpacesCapability,
    ]
  );

  const handleSubmit = useCallback(
    (values: IFormData) => {
      handleAddTableRowPolicyGroup(values);
      setPreviousFieldValues(values);
      setIsOpenModal?.(false);
    },
    [handleAddTableRowPolicyGroup, setPreviousFieldValues, setIsOpenModal]
  );
  const handleResponseSubmit = useCallback(
    (values: IResponseFormData) => {
      handleAddTableRowResponseActionGroup?.(values);
      setPreviousFieldValues(values);
      setIsOpenModal?.(false);
    },
    [
      handleAddTableRowResponseActionGroup,
      setIsOpenModal,
      setPreviousFieldValues,
    ]
  );
  // Initial values are resolved last so group options are initialized correctly and not as undefined
  const resolvedInitialValues = useMemo(
    () =>
      initialValues(
        t,
        mode,
        policies,
        deviceSpaces,
        data?.rowData,
        previousFieldValues,
        isIntune,
        isSOTIConnector
      ),
    [
      t,
      mode,
      policies,
      deviceSpaces,
      data?.rowData,
      previousFieldValues,
      isIntune,
      isSOTIConnector,
    ]
  );
  const onFieldChange = (value: ISelectItem[]) => {
    setSelectedGroups(value);
  };

  // Skeleton shown here so initialValues resolved correctly and dropdowns are populated on load.
  if (!policies) {
    return (
      <SelectGroupsSkeleton
        t={t}
        showPolicy={showPolicy}
        isIntune={isIntune}
        thirdPartyMDMSelected={thirdPartyMDMSelected}
      />
    );
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={resolvedInitialValues}
        onSubmit={!showPolicy ? handleResponseSubmit : handleSubmit}
        validateOnBlur
        validationSchema={Schema(mode)}
      >
        {({ setFieldValue, values }) => {
          return (
            <FormikForm>
              {(!isIntune || !thirdPartyMDMSelected) && (
                <>
                  <div className={classes.mainContainerSelectGroup}>
                    {(!initialGroupOptionsLoaded ||
                      reloadingGroups ||
                      isSubmitting) && (
                      <SelectGroupsSkeleton
                        t={t}
                        connector={connector}
                        showPolicy={showPolicy}
                        isIntune={isIntune}
                        thirdPartyMDMSelected={thirdPartyMDMSelected}
                      />
                    )}
                    {initialGroupOptionsLoaded &&
                      !reloadingGroups &&
                      !isSubmitting && (
                        <div className={classes.subMainSelectGroup}>
                          <div className={classes.titleStyles}>
                            {connector?.id === 'GoogleAdminConnector'
                              ? t(
                                  'MTD.INTEGRATIONS.SELECT_ORGANIZATIONAL_UNITS'
                                )
                              : t('MTD.INTEGRATIONS.SELECT_GROUPS')}
                          </div>
                          <div>
                            <FormikField
                              component={InfiniteSelect}
                              StartAdornment={
                                <div>
                                  {selectedGroups.map((group) => (
                                    <Chip
                                      className={classes.chipMargin}
                                      label={group.label}
                                    />
                                  ))}
                                </div>
                              }
                              disabled={isSubmitting}
                              fetchOptions={(params: IFetchOptionsParams) => {
                                return fetchPagedGroups(
                                  params,
                                  String(values?.deviceSpace?.value ?? '')
                                );
                              }}
                              initialOptions={
                                reloadedGroupData?.groupOptions?.length
                                  ? reloadedGroupData?.groupOptions
                                  : initialGroupData?.groupOptions ?? []
                              }
                              initialPage={initialGroupData?.page ?? 0}
                              label={t('GLOBAL.SEARCH')}
                              lastPage={
                                reloadedGroupData?.lastPage ??
                                initialGroupData?.lastPage ??
                                0
                              }
                              multiple={true}
                              name="groups"
                              optionValuesToExclude={groupValuesToExclude}
                              placeholder={
                                !values?.groups?.[0] ? t('GLOBAL.SELECT') : ''
                              }
                              setFieldValue={(
                                field: string,
                                value: any,
                                shouldValidate?: boolean | undefined
                              ) => {
                                onFieldChange(value);
                                setFieldValue(field, value, shouldValidate);
                              }}
                              type="select"
                            />
                          </div>
                          {!showPolicy && (
                            <div className={classes.buttons}>
                              <Button
                                className={classes.buttonBackgroundColor}
                                text={t('GLOBAL.BACK')}
                                onClick={() => setIsOpenModal?.(false)}
                                type="submit"
                              />
                              <Button
                                color="secondary"
                                disabled={
                                  !initialGroupOptionsLoaded ||
                                  !values?.groups?.[0] ||
                                  isSubmitting
                                }
                                text={t('GLOBAL.NEXT')}
                                type="submit"
                              />
                            </div>
                          )}
                        </div>
                      )}

                    {showPolicy && (
                      <Divider
                        className={classes.dividerStyle}
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                    )}
                    {showPolicy && (
                      <div className={classes.subMainThreatContainer}>
                        <div className={classes.titleStyles}>
                          {t('MTD.INTEGRATIONS.SELECT_POLICIES_APPLIED')}
                        </div>
                        {/* Below fields are wrapped in divs so error components don't throw off grid. */}
                        <div>
                          <FormikField
                            component={Select}
                            customOption={CustomPolicyOption}
                            disableClearable={true}
                            disabled={isSubmitting || mode !== 'EDIT'}
                            label={t('GLOBAL.THREAT_POLICY')}
                            multiple={false}
                            name="trmPolicy"
                            options={
                              policies?.trmPolicies?.[0]
                                ? policies.trmPolicies
                                : []
                            }
                            placeholder={
                              mode !== 'EDIT' ? t('GLOBAL.DEFAULT_POLICY') : ''
                            }
                            setFieldValue={setFieldValue}
                            type="select"
                          />
                        </div>
                        <div>
                          <FormikField
                            component={Select}
                            customOption={CustomPolicyOption}
                            disableClearable={true}
                            disabled={isSubmitting}
                            label={t('GLOBAL.PRIVACY_POLICY')}
                            multiple={false}
                            name="privacyPolicy"
                            options={
                              policies?.privacyPolicies?.[0]
                                ? policies.privacyPolicies
                                : []
                            }
                            setFieldValue={setFieldValue}
                            type="select"
                          />
                        </div>
                        <div>
                          <ProtectedComponent allow={{ phishing: 'view' }}>
                            <FormikField
                              component={Select}
                              customOption={CustomPolicyOption}
                              disableClearable={true}
                              disabled={isSubmitting}
                              label={t('GLOBAL.PHISHING_POLICY')}
                              multiple={false}
                              name="phishingPolicy"
                              options={
                                policies?.phishingPolicies?.[0]
                                  ? policies.phishingPolicies
                                  : []
                              }
                              setFieldValue={setFieldValue}
                              type="select"
                            />
                          </ProtectedComponent>
                        </div>
                        <div>
                          <ProtectedComponent allow={{ app_settings: 'view' }}>
                            <FormikField
                              component={Select}
                              customOption={CustomPolicyOption}
                              disableClearable={true}
                              disabled={isSubmitting}
                              label={t('GLOBAL.APP_SETTINGS')}
                              multiple={false}
                              name="appSettings"
                              options={policies?.appSettingOptions ?? []}
                              setFieldValue={setFieldValue}
                              type="select"
                            />
                          </ProtectedComponent>
                        </div>
                        <div>
                          <FormikField
                            component={Select}
                            customOption={CustomPolicyOption}
                            disableClearable={true}
                            disabled={isSubmitting}
                            label={t('GLOBAL.DEVICE_INACTIVITY')}
                            multiple={false}
                            name="dormancyPolicy"
                            options={policies?.dormancyPolicies ?? []}
                            setFieldValue={setFieldValue}
                            type="select"
                          />
                        </div>

                        <div className={classes.buttons}>
                          <Button
                            className={classes.buttonBackgroundColor}
                            text={t('GLOBAL.BACK')}
                            onClick={() => setIsOpenModal?.(false)}
                            type="submit"
                          />
                          <Button
                            color="secondary"
                            disabled={
                              !initialGroupOptionsLoaded ||
                              !values?.groups?.[0] ||
                              isSubmitting
                            }
                            text={t('GLOBAL.NEXT')}
                            type="submit"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </FormikForm>
          );
        }}
      </Formik>
    </>
  );
};

export default Form;
