import React, { Fragment } from 'react';
import _ from 'lodash';
import withRouter from 'components/hocs/withRouter';

import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import ZButton from 'UI/Buttons/ZButton';

import { updatePhishingPolicy } from 'api/PhishingPolicyService';

import { openSnackBar, toggleModalDirect } from 'utils/storeUtils';

const PhishingPolicySaveConfirmation = props => {
  const appsAttached = _.get(props, 'data.apps', []);
  return (
    <Fragment>
      <DialogTitle id="simple-dialog-title">
        {props.data.headerText}
      </DialogTitle>
      <DialogContent>
        <p>
          Are you sure you want to make changes to the{' '}
          {_.get(props, 'data.name', ' ')} policy?
        </p>
        {appsAttached.length > 0 ? (
          <p>
            Your changes will be published to the following apps:
            <ul>
              {props.data.apps.map(app => {
                return <li key={app.id}>{app.name}</li>;
              })}
            </ul>
          </p>
        ) : null}
        <DialogActions>
          <ZButton
            styleName="modalCancel"
            action={() => handleClose()}
            color="secondary"
            buttonText="Cancel"
          />
          <ZButton
            action={() => handleSubmit()}
            buttonType="submit"
            color="primary"
            styleName="modalSave"
            buttonText="Save"
          />
        </DialogActions>
      </DialogContent>
    </Fragment>
  );

  function handleClose() {
    toggleModalDirect('PhishingPolicyCreateClone', false);
  }

  function handleSubmit() {
    const phishingDetectionAction = _.get(
      props,
      'data.payload.phishingDetectionAction',
      false
    )
      ? 'BLOCK'
      : 'WARN';

    const finalPayload = {
      ...props.data.payload,
      ...props.data.name,
      phishingDetectionAction,
    };

    const id = _.get(props, 'data.policySelected.value', null);

    updatePhishingPolicy({ id }, finalPayload)
      .then(() => {
        toggleModalDirect('PhishingPolicySaveConfirmation', false);
        props.data.setEditMode(false);
        props.updateUrl({ phishing: id });
        openSnackBar('Phishing Policy Successfully Saved');
        props.setPhishingPolicyName(finalPayload.name);
      })
      .catch(error => console.error(error));
  }
};

export default withRouter(PhishingPolicySaveConfirmation);
