import { isEmpty, pickBy } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

// components
import Modals, { Modal } from 'components/modals';
import { toggleModalDiffered } from '../../utils/storeUtils';

// selectors
import { getModals } from 'reducers/UiReducers';

const ModalContainer = props => {
  const _modal = pickBy(props.modals, val => val === true);
  const _modalKey = Object.keys(_modal)[0];

  return (
    <Modal
      open={!isEmpty(_modal)}
      onClose={toggleModalDiffered(_modalKey, false)}
      {...props.modals.modalConfig}
    >
      {!_modalKey
        ? null
        : React.createElement(Modals[_modalKey], {
          data: props.modals.modalContent,
        })}
    </Modal>
  );
};

const mapStateToProps = state => ({
  modals: getModals(state),
});

export default connect(mapStateToProps)(ModalContainer);
