import MaterialTableBody from '@material-ui/core/TableBody';
import React, { Fragment } from 'react';
import TableColumnsContext from './TableColumnsContext';
import { withLoader } from './tableHelpers';
import TableRow from './TableRow';
import { withTranslation } from 'react-i18next';

class TableBody extends React.Component {
  shouldComponentUpdate() {
    return true;
  }

  render() {
    const { props } = this;
    const { t } = this.props;

    return (
      <Fragment>
        <TableColumnsContext.Consumer>
          {({ rowMapping, rowHandlers, rowProps, omitTableColumns = [] }) => {
            return (
              <MaterialTableBody>
                {withLoader(
                  props,
                  this.renderTableContent(
                    props,
                    rowMapping,
                    rowHandlers,
                    omitTableColumns,
                    rowProps
                  ),
                  t
                )}
              </MaterialTableBody>
            );
          }}
        </TableColumnsContext.Consumer>
      </Fragment>
    );
  }

  renderTableContent(
    props,
    rowMapping = {},
    rowHandlers = {},
    omitTableColumns = [],
    rowProps = {}
  ) {
    return () => {
      return props.tableData.map((data, index) => {
        return (
          <Fragment key={`row-frag-${data.id || index}`}>
            <TableRow
              rowData={data}
              omitRowPath={omitTableColumns}
              rowMapping={rowMapping}
              handlers={rowHandlers}
              classList={props.classList}
              fetchTableData={props.fetchTableData}
              isLastRow={index === props.tableData.length - 1}
              {...rowProps}
            />
          </Fragment>
        );
      });
    };
  }
}

export default withTranslation()(TableBody);
