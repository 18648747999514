import { IGenericRowActions } from './models';
import useStyles from './useStyles';
import { getAction } from './utils';

interface IGenericTableActionsProps {
  actions: IGenericRowActions[];
  isLastRow?: boolean;
  styleCellContent?: string;
}

const GenericTableActions: React.FC<IGenericTableActionsProps> = ({
  actions,
  isLastRow,
  styleCellContent,
}) => {
  const classes = useStyles();

  return (
    <div className={(classes.actionsContainer, styleCellContent)}>
      {actions.map(
        ({
          actionFn,
          disabled,
          hidden,
          icon: actionIcon,
          onlyLastRow,
          type,
          onDragOver,
          onDrop,
          visible = true,
        }, index) => {
          if (!visible) {
            return null;
          }

          const actionMapping = type ? getAction(type, classes) : null;

          if (hidden || (!actionIcon && !actionMapping)) {
            return null;
          }

          const icon = actionIcon ?? actionMapping?.icon;
          const iconColor = actionMapping?.color ?? classes.default;

          if (!icon) {
            return null;
          }

          if (!isLastRow && onlyLastRow) {
            return null;
          }

          return (
            <button
              key={index}
              onDragOver={onDragOver}
              onDrop={onDrop}
              className={`${classes.iconButton} ${disabled ? classes.disabled : ''
                } ${iconColor}`}
              disabled={disabled}
              onClick={actionFn}
              style={{
                cursor: disabled ? 'auto' : 'pointer',
              }}
            >
              {icon}
            </button>
          );
        }
      )}
    </div>
  );
};

export default GenericTableActions;
