import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// mui
import MuiInput from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ContentCopyIcon from '@material-ui/icons/FilterNone';
import slugify from 'slugify';

const CopyField = props => {
  const id = props.id || `text-field-${slugify(props.label || props.name)}`;
  return (
    <div className={props.classes.container}>
      {props.label && (
        <label className={props.classes.label} htmlFor={id}>
          {props.label}
        </label>
      )}
      <MuiInput
        {...props}
        disabled
        classList={props.classes}
        inputProps={{
          classes: {
            input: props.classes.input,
          },
        }}
        endAdornment={
          <InputAdornment
            classes={{ root: props.classes.adornmentRoot }}
            position="end"
          >
            <CopyToClipboard text={props.value} onCopy={props.onCopy}>
              <IconButton>
                <ContentCopyIcon />
              </IconButton>
            </CopyToClipboard>
          </InputAdornment>
        }
      />
    </div>
  );
};

CopyField.defaultProps = {
  onCopy: () => {},
};

const styles = ({ config }) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    input: {
      // textOverflow: 'ellipsis',
      paddingRight: 40,
    },
    label: {
      paddingBottom: 5,
      marginTop: 15,
      fontSize: config.textSizes.petite,
    },
    adornmentRoot: {
      height: 'inherit',
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translateY(-50%)',
    },
  };
};

export default withStyles(styles)(CopyField);
