export interface IPartnerBranding {
  appStoreAndroidLink: string;
  appStoreIosLink: string;
  consoleBackgroundImage: string | File | null;
  consoleLogo: string | File | null;
  consoleTitle: string | null;
  darkModeConsoleBackgroundImage: string | File | null;
  darkModeConsoleLogo: string | File | null;
  darkModeEmailLogo: string | File | null;
  darkModeLoginBackgroundImage: string | File | null;
  darkModeLoginLogo: string | File | null;
  darkModePartnerLogo: string | File | null;
  disableTemplateContactBlock?: boolean;
  documentationLink: string;
  emailLogo: string | File | null;
  favicon: string | File | null;
  fromEmailAddress: string;
  loginBackgroundImage: string | File | null;
  loginLogo: string | File | null;
  // mobileAppLink: string;
  mobileAppName: string;
  partnerLogo: string | File | null;
  privacyPolicyLink: string;
  removeConsoleBackgroundImage?: boolean;
  removeConsoleLogo?: boolean;
  removeDarkModeConsoleBackgroundImage?: boolean;
  removeDarkModeConsoleLogo?: boolean;
  removeDarkModeEmailLogo?: boolean;
  removeDarkModeFavicon?: boolean;
  removeDarkModeLoginBackgroundImage?: boolean;
  removeDarkModeLoginLogo?: boolean;
  removeDarkModePartnerLogo?: boolean;
  removeEmailLogo?: boolean;
  removeFavicon?: boolean;
  removeLoginBackgroundImage?: boolean;
  removeLoginLogo?: boolean;
  removePartnerLogo?: boolean;
  replyToEmailAddress: string;
  supportEmailAddress: string;
  termsAndConditionsLink: string;
  themePreference: string;
  zdefendModuleText: string;
  mtdModuleText: string;
  zscanModuleText: string;
  zshieldModuleText: string;
  [key: string]: string | File | boolean | null | undefined;
}

export enum EPartnerBrandingDefaults {
  consoleTitle = 'zConsole',
  mtdModuleText = 'MTD',
  privacyPolicyLink = 'https://www.zimperium.com/zimperium-eula/',
  termsAndConditionsLink = 'https://www.zimperium.com/zimperium-eula/',
  zdefendModuleText = 'zDefend',
  zscanModuleText = 'zScan',
  zshieldModuleText = 'zShield',
}

export interface IPartnerBrandingState {
  partnerUser: { account: { [key: string]: unknown }; [key: string]: unknown };
  uiSettings: { [key: string]: unknown };
}

export enum EThemePreference {
  'light' = 'Light',
  'dark' = 'Dark',
}

export interface IThemePreference {
  disabled?: boolean;
  label: string;
  value: 'dark' | 'light';
}

export enum ESupportEmailAddressType {
  NONE = '0',
  DEFAULT = '1',
  CUSTOM = '2',
}
