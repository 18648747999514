import { ServiceHandlerFactory } from 'utils/serviceUtils';

export const fetchAllPhishingPolicies = ServiceHandlerFactory({
  axios: {
    url: 'api/phishing/v1/policies/all',
  },
});

export const fetchPhishingPolicyById = ServiceHandlerFactory({
  axios: {
    url: 'api/mtd-policy/v1/phishing/policies/:id',
  },
});

export const createPhishingPolicy = ServiceHandlerFactory({
  axios: {
    url: 'api/phishing/v1/policies/',
    method: 'post',
  },
});

export const updatePhishingPolicy = ServiceHandlerFactory({
  axios: {
    url: 'api/mtd-policy/v1/phishing/policies/:id',
    method: 'put',
  },
});

export const deletePhishingPolicy = ServiceHandlerFactory({
  axios: {
    url: 'api/phishing/v1/policies/:id',
    method: 'delete',
  },
});

export const cloneSelectedPhishingPolicy = ServiceHandlerFactory({
  axios: {
    url: 'api/phishing/v1/policies/:id/clone',
    method: 'post',
  },
});

export const fetchSiteCategoryCodes = ServiceHandlerFactory({
  axios: {
    url: 'api/mtd-policy/v1/wcf/catalog',
  },
});

export const fetchAccessControlLists = ServiceHandlerFactory({
  axios: {
    url: 'api/mtd-policy/v1/acl',
  },
});

export const fetchPoliciesUsingAcl = ServiceHandlerFactory({
  axios: {
    url: 'api/mtd-policy/v1/acl/:id/policies',
  },
});

export const updateAcl = ServiceHandlerFactory({
  axios: {
    url: 'api/mtd-policy/v1/acl/:id',
    method: 'put',
  },
});

export const createAcl = ServiceHandlerFactory({
  axios: {
    url: 'api/mtd-policy/v1/acl',
    method: 'post',
  },
});

export const deleteAcl = ServiceHandlerFactory({
  axios: {
    url: 'api/mtd-policy/v1/acl/:id',
    method: 'delete',
  },
});

export const getAclById = ServiceHandlerFactory({
  axios: {
    url: '/api/mtd-policy/v1/acl/:id?includeDefaults=true',
  },
});

export const checkSiteForPhishing = ServiceHandlerFactory({
  axios: {
    url: 'api/mtd-policy/v1/wcf/categorize',
    method: 'post',
  },
});
