import { IQueryParams } from 'components/main/devices/common/models';
import Numeral from 'config/numeral/Numeral';
// @ts-ignore
import qs from 'qs'; // TODO: Replace this or add types for qs library
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { IDataCardData } from './models';
import useStyles from './useStyles';
import { OS_CONTENT_MAPPING } from './utils';

interface IDataCardProps {
  data: IDataCardData;
  hide?: boolean;
  query: IQueryParams;
  matchUrl: string;
}

const DataCard: React.FC<IDataCardProps> = ({
  data,
  hide = false,
  matchUrl,
  query,
}) => {
  const classes = useStyles();

  const osNames = Object.keys(OS_CONTENT_MAPPING);

  const osMap = useMemo(
    () =>
      data?.os && osNames.includes(data.os)
        ? OS_CONTENT_MAPPING[data.os]
        : undefined,
    [data, osNames]
  );

  const linkTo = useMemo(() => {
    const newQuery = qs.stringify({
      ...query,
      'os.name': osMap?.name || undefined,
      'os.version': undefined,
    });

    return `${matchUrl}?${newQuery}`;
  }, [query, matchUrl, osMap]);

  if (hide) {
    return null;
  }

  return (
    <div className={classes.statisticCard}>
      {!!osMap && (
        <>
          <div className={osMap.styleName(classes)}>{osMap.icon(classes)}</div>
          <div className={classes.text}>
            <div className={classes.count}>
              <Link
                className={classes.count}
                title={String(osMap.tooltip(data.count))}
                to={linkTo}
              >
                {Numeral(data.count).format('a')}
              </Link>
            </div>
            <div className={classes.label}>{data?.label || osMap.label}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default DataCard;
