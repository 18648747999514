import GenericCard from 'components/UI/GenericCard';
import Checkbox from 'components/UI/input/Checkbox';
import Select, { ISelectItem } from 'components/UI/input/Select';
import TextField from 'components/UI/input/TextField';
import Tooltip from 'components/UI/Tooltip';
import { Field as FormikField, FormikState } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import {
  dormancyChanges as dormancyChangesAtom,
  dormancyFormValid as dormancyFormValidAtom,
} from '../../atoms';
import { IDormancyPolicyForm } from '../models';
import { INTERVAL_OPTIONS } from '../utils';
import useStyles from './useStyles';

interface IThreatWarningCardProps {
  dirty?: boolean;
  isSubmitting: boolean;
  isValid?: boolean;
  reloadingPolicy?: boolean;
  resetForm?: (
    nextState?: Partial<FormikState<IDormancyPolicyForm>> | undefined
  ) => void;
  setFieldValue: (
    field: string,
    value: string | number | boolean | ISelectItem | undefined,
    shouldValidate?: boolean | undefined
  ) => void;
  setReloadingPolicy?: React.Dispatch<React.SetStateAction<boolean>>;
  suffix: string;
  showNotificationCheckbox?: boolean;
  title: string;
  values: IDormancyPolicyForm;
}

const ThreatWarningCard: React.FC<IThreatWarningCardProps> = ({
  dirty,
  isSubmitting,
  isValid,
  reloadingPolicy,
  resetForm,
  setFieldValue,
  setReloadingPolicy,
  showNotificationCheckbox = false,
  suffix,
  title,
  values,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const warningCountKey = `maxWarningsCount${suffix}`;
  const enabledKey = `enabled${suffix}`;
  const notifyIosKey = `notifyDevicesIos${suffix}`;
  const notifyAndroidKey = `notifyDevicesAndroid${suffix}`;
  const initialWarningCountValue = values?.[warningCountKey] ?? 0;
  const [warningTypesDisabled, setWarningTypesDisabled] = useState(
    initialWarningCountValue === 0
  );
  const [disableWarningSettings, setDisableWarningSettings] = useState<boolean>(
    (!values?.[enabledKey] as boolean) ?? true
  );
  const setDormancyChanges = useSetRecoilState(dormancyChangesAtom);
  const setDormancyFormValid = useSetRecoilState(dormancyFormValidAtom);

  const disableForm = isSubmitting;
  const disableWarningTypes = disableForm || warningTypesDisabled;

  // Show deploy button if form dirty. This is done in ThreatWarningCard to have access to Formik values and dirty variable.
  useEffect(() => {
    // Only set in Pending Activation section to prevent double state change.
    // Note that this works because both ThreatWarningCard components are re-rendered by Formik for every form change.
    if (suffix === 'PA') {
      setDormancyChanges({
        formData: values,
        hasChanges: !!dirty,
      });
    }
  }, [suffix, dirty, values, setDormancyChanges]);

  // Update isValid to disable deploy button if there are errors on page.
  // This is in a separate useEffect because it must be independant of whether the form is dirty
  // Otherwise the disabling of the button would be delayed and incorrect.
  useEffect(() => {
    if (suffix === 'PA') {
      setDormancyFormValid(isValid);
    }
  }, [isValid, setDormancyFormValid, suffix]);

  useEffect(() => {
    if (
      suffix === 'PA' &&
      reloadingPolicy &&
      !!resetForm &&
      !!setReloadingPolicy
    ) {
      resetForm();
      setReloadingPolicy(false);
    }
  }, [reloadingPolicy, setReloadingPolicy, suffix, resetForm]);

  const handleEnabledClick = (e: React.MouseEvent<HTMLInputElement>) => {
    const checked = e.currentTarget.checked;
    setFieldValue(enabledKey, checked);
    setDisableWarningSettings(!checked);
  };

  const handleWarningCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.currentTarget.value) ?? null;
    setFieldValue(warningCountKey, value, true);

    if (!value) {
      setWarningTypesDisabled(true);
      return;
    }

    setWarningTypesDisabled(false);
  };

  return (
    <div className={classes.warningCard}>
      <GenericCard title={title}>
        <div
          className={`${classes.warningCardContent} ${
            !disableForm && !disableWarningSettings ? classes.enableColor : ''
          }`}
        >
          <div className={classes.enableSection}>
            <div
              className={`${!disableForm ? classes.enableColor : ''} ${
                classes.enableText
              }`}
            >
              {t('GLOBAL.ENABLE')}
            </div>
            <div className={classes.enableToggle}>
              <FormikField
                disabled={disableForm}
                component={Checkbox}
                name={`enabled${suffix}`}
                onClick={handleEnabledClick}
                type="checkbox"
                asToggle={true}
              />
            </div>
            <div className={classes.enableTooltip}>
              <Tooltip
                disabled={disableForm}
                tooltipText={`${
                  suffix === 'PA'
                    ? t(
                        'MTD.POLICIES.DEVICE_INACTIVITY.TOGGLE_THIS_PENDING_ACTIVATION_THREAT_WARNINGS'
                      )
                    : t(
                        'MTD.POLICIES.DEVICE_INACTIVITY.TOGGLE_THIS_INACTIVE_APP_THREAT_WARNINGS'
                      )
                }`}
              />
            </div>
          </div>
          <div className={classes.sectionGrid}>
            <div className={classes.warningSubSection}>
              <div className={classes.heading}>
                {t('MTD.POLICIES.DEVICE_INACTIVITY.ALLOWED_INACTIVITY_TIME')}
              </div>
              <div className={classes.description}>
                {t('MTD.POLICIES.DEVICE_INACTIVITY.CONTROLS_DESCRIPTION')}
              </div>
              <div className={classes.inputGrid}>
                <FormikField
                  component={TextField}
                  disabled={disableForm || disableWarningSettings}
                  className={classes.inputField}
                  name={`timeBeforeWarning${suffix}`}
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                />
                <div className={classes.inputField}>
                  <FormikField
                    component={Select}
                    className={classes.dropdown}
                    disableClearable={true}
                    disabled={disableForm || disableWarningSettings}
                    multiple={false}
                    name={`timeBeforeWarningDisplayUnits${suffix}`}
                    options={INTERVAL_OPTIONS(t)}
                    setFieldValue={setFieldValue}
                    type="select"
                  />
                </div>
              </div>
              <div className={classes.note}>
                {t('MTD.POLICIES.DEVICE_INACTIVITY.MINIMUM_VALUE')}
              </div>
            </div>

            <div className={classes.warningSubSection}>
              <div className={classes.heading}>
                {t('MTD.POLICIES.DEVICE_INACTIVITY.MAMIMUM_WARNING_COUNT')}
              </div>
              <div className={classes.description}>
                {t('MTD.POLICIES.DEVICE_INACTIVITY.LIMIT_TOTAL_WARNINGS')}
              </div>
              <div className={classes.inputGrid}>
                <FormikField
                  className={classes.inputField}
                  component={TextField}
                  disabled={disableForm || disableWarningSettings}
                  name={`maxWarningsCount${suffix}`}
                  onChange={handleWarningCountChange}
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                />
              </div>
              <div className={classes.note}>
                {t('MTD.POLICIES.DEVICE_INACTIVITY.SET_ZERO')}
              </div>
            </div>

            <div
              className={`${classes.warningSubSection} ${
                warningTypesDisabled ? classes.warningTypesDisabled : ''
              }`}
            >
              <div className={classes.heading}>
                {t('MTD.POLICIES.DEVICE_INACTIVITY.WARNING_TYPES')}
              </div>
              <div className={classes.description}>
                {t('MTD.POLICIES.DEVICE_INACTIVITY.WHICH_TYPE_WARNINGS_SEND')}
              </div>
              <div className={classes.warningTypeCheckboxes}>
                {showNotificationCheckbox && (
                  <>
                    <FormikField
                      color="primary"
                      component={Checkbox}
                      disabled={disableWarningTypes || disableWarningSettings}
                      label={
                        <div className={classes.checkboxLabel}>
                          <div>
                            {t(
                              'MTD.POLICIES.DEVICE_INACTIVITY.SEND_IOS_NOTIFICATION'
                            )}
                          </div>
                          <Tooltip
                            disabled={
                              disableWarningTypes || disableWarningSettings
                            }
                            tooltipText={`${t(
                              'MTD.POLICIES.DEVICE_INACTIVITY.CHECK_TO_SEND_PUSH_NOTIFICATION',
                              { platform: t('GLOBAL.IOS') }
                            )} ${t(
                              'MTD.POLICIES.DEVICE_INACTIVITY.SET_VALUE_GREATER_ZERO'
                            )}`}
                          />
                        </div>
                      }
                      name={`notifyDevicesIos${suffix}`}
                      type="checkbox"
                    />
                    {!!values?.[notifyIosKey] && (
                      <div
                        className={`${
                          disableWarningTypes || disableWarningSettings
                            ? classes.notificationNoticeDisabled
                            : classes.notificationNotice
                        }`}
                      >
                        {t(
                          'MTD.POLICIES.DEVICE_INACTIVITY.ENABLE_SET_USER_ALERT_CHECKBOX',
                          { platform: t('GLOBAL.IOS') }
                        )}
                      </div>
                    )}
                    <FormikField
                      color="primary"
                      component={Checkbox}
                      disabled={disableWarningTypes || disableWarningSettings}
                      label={
                        <div className={classes.checkboxLabel}>
                          <div>
                            {t(
                              'MTD.POLICIES.DEVICE_INACTIVITY.SEND_ANDROID_NOTIFICATION'
                            )}
                          </div>
                          <Tooltip
                            disabled={
                              disableWarningTypes || disableWarningSettings
                            }
                            tooltipText={`${t(
                              'MTD.POLICIES.DEVICE_INACTIVITY.CHECK_TO_SEND_PUSH_NOTIFICATION',
                              { platform: t('GLOBAL.ANDROID') }
                            )} ${t(
                              'MTD.POLICIES.DEVICE_INACTIVITY.SET_VALUE_GREATER_ZERO'
                            )}`}
                          />
                        </div>
                      }
                      name={`notifyDevicesAndroid${suffix}`}
                      type="checkbox"
                    />
                    {!!values?.[notifyAndroidKey] && (
                      <div
                        className={`${
                          disableWarningTypes || disableWarningSettings
                            ? classes.notificationNoticeDisabled
                            : classes.notificationNotice
                        }`}
                      >
                        {t(
                          'MTD.POLICIES.DEVICE_INACTIVITY.ENABLE_SET_USER_ALERT_CHECKBOX',
                          { platform: t('GLOBAL.ANDROID') }
                        )}
                      </div>
                    )}
                  </>
                )}
                <FormikField
                  color="primary"
                  component={Checkbox}
                  disabled={disableWarningTypes || disableWarningSettings}
                  label={
                    <div className={classes.checkboxLabel}>
                      <div>
                        {t(
                          'MTD.POLICIES.DEVICE_INACTIVITY.SEND_USER_EMAIL_IOS'
                        )}
                      </div>
                      <Tooltip
                        disabled={disableWarningTypes || disableWarningSettings}
                        tooltipText={`${
                          suffix === 'PA'
                            ? t(
                                'MTD.POLICIES.DEVICE_INACTIVITY.CHECK_TO_SEND_EMAIL_NOT_ACTIVATED',
                                { platform: t('GLOBAL.IOS') }
                              )
                            : t(
                                'MTD.POLICIES.DEVICE_INACTIVITY.CHECK_TO_SEND_EMAIL_INACTIVE',
                                { platform: t('GLOBAL.IOS') }
                              )
                        } ${t(
                          'MTD.POLICIES.DEVICE_INACTIVITY.SET_VALUE_GREATER_ZERO'
                        )}`}
                      />
                    </div>
                  }
                  name={`sendEmailIos${suffix}`}
                  type="checkbox"
                />
                <FormikField
                  color="primary"
                  component={Checkbox}
                  disabled={disableWarningTypes || disableWarningSettings}
                  label={
                    <div className={classes.checkboxLabel}>
                      <div>
                        {t(
                          'MTD.POLICIES.DEVICE_INACTIVITY.SEND_USER_EMAIL_ANDROID'
                        )}
                      </div>
                      <Tooltip
                        disabled={disableWarningTypes || disableWarningSettings}
                        tooltipText={`${
                          suffix === 'PA'
                            ? t(
                                'MTD.POLICIES.DEVICE_INACTIVITY.CHECK_TO_SEND_EMAIL_NOT_ACTIVATED',
                                { platform: t('GLOBAL.ANDROID') }
                              )
                            : t(
                                'MTD.POLICIES.DEVICE_INACTIVITY.CHECK_TO_SEND_EMAIL_INACTIVE',
                                { platform: t('GLOBAL.ANDROID') }
                              )
                        } ${t(
                          'MTD.POLICIES.DEVICE_INACTIVITY.SET_VALUE_GREATER_ZERO'
                        )}`}
                      />
                    </div>
                  }
                  name={`sendEmailAndroid${suffix}`}
                  type="checkbox"
                />
              </div>
            </div>

            <div className={classes.warningSubSection}>
              <div className={classes.heading}>
                {t('MTD.POLICIES.DEVICE_INACTIVITY.WARNING_INTERVAL')}
              </div>
              <div className={classes.description}>
                {t(
                  'MTD.POLICIES.DEVICE_INACTIVITY.INTERVAL_BETWEEN_CONSECUTIVE_WARNINGS'
                )}
              </div>
              <div className={classes.inputGrid}>
                <FormikField
                  className={classes.inputField}
                  component={TextField}
                  disabled={disableForm || disableWarningSettings}
                  name={`timeBetweenWarnings${suffix}`}
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                />
                <div className={classes.inputField}>
                  <FormikField
                    className={classes.dropdown}
                    component={Select}
                    disableClearable={true}
                    disabled={disableForm || disableWarningSettings}
                    multiple={false}
                    name={`timeBetweenWarningsDisplayUnits${suffix}`}
                    options={INTERVAL_OPTIONS(t)}
                    setFieldValue={setFieldValue}
                    type="select"
                  />
                </div>
              </div>
              <div className={classes.note}>
                {t('MTD.POLICIES.DEVICE_INACTIVITY.MINIMUM_VALUE')}
              </div>
            </div>
          </div>
        </div>
      </GenericCard>
    </div>
  );
};

export default ThreatWarningCard;
