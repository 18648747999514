import { fetchAllPhishingPolicies } from 'api/PhishingPolicyService';
import { ISelectItem } from 'components/UI/input/Select';
import _ from 'lodash';
import { TFunction } from 'react-i18next';
import {
  ICategories,
  ICategory,
  ICategoryIdElement,
  IFormCategory,
  IPhishingPolicyElement,
} from './models';

export const CATEGORY_OPTIONS = (t: TFunction<'translation', undefined>): ISelectItem[] => [
  { value: 'ALLOW', label: t('GLOBAL.ALLOW') },
  { value: 'ALERT', label: t('GLOBAL.ALERT') },
  { value: 'ALERT_AND_CREATE_THREAT', label: t('MTD.POLICIES.PHISHING_POLICY.ALERT_CREATE_THREAT') },
  { value: 'BLOCK', label: t('GLOBAL.BLOCK') },
  { value: 'BLOCK_AND_CREATE_THREAT', label: t('MTD.POLICIES.PHISHING_POLICY.BLOCK_CREATE_THREAT') },
  { value: 'BLOCK_WITH_NO_ALERT', label: t('MTD.POLICIES.PHISHING_POLICY.BLOCK_WITH_NO_ALERT') },
  { value: 'MULTIPLE', label: t('MTD.POLICIES.PHISHING_POLICY.MULTIPLE') },
];

export const SUB_CATEG_OPTIONS = (t: TFunction<'translation', undefined>): ISelectItem[] => [
  { value: 'ALLOW', label: t('GLOBAL.ALLOW') },
  { value: 'ALERT', label: t('GLOBAL.ALERT') },
  { value: 'ALERT_AND_CREATE_THREAT', label: t('MTD.POLICIES.PHISHING_POLICY.ALERT_CREATE_THREAT') },
  { value: 'BLOCK', label: t('GLOBAL.BLOCK') },
  { value: 'BLOCK_AND_CREATE_THREAT', label: t('MTD.POLICIES.PHISHING_POLICY.BLOCK_CREATE_THREAT') },
  { value: 'BLOCK_WITH_NO_ALERT', label: t('MTD.POLICIES.PHISHING_POLICY.BLOCK_WITH_NO_ALERT') },
];

export const initialValues = (t: TFunction<'translation', undefined>, data: ICategories[]): IFormCategory => {
  const catArray: ICategory[][] = [];
  data?.forEach((el: ICategories) => {
    catArray.push(el['categories']);
  });
  const flatCategArray = catArray.reduce((acc, curr) => acc.concat(curr), []);

  const result = apiCategories(t, data, flatCategArray);
  return result;
};

export const apiCategories = (
  t: TFunction<'translation', undefined>,
  data: ICategories[],
  catArray?: ICategory[]
): IFormCategory => {
  const obj: IFormCategory = {};

  catArray &&
    catArray.forEach((el) => {
      const name = `cat-${el.categoryId}`;
      const dropdownValue = SUB_CATEG_OPTIONS(t).find(
        (op) => op.value === el.action
      );
      obj[name] = dropdownValue!;
    });

  const referenceArr: ICategoryIdElement[] = [];
  data?.map((el: ICategories) => {
    const catIdArr: number[] = [];
    el.categories.map((cat: ICategory) => catIdArr.push(cat.categoryId));
    referenceArr.push({ [el.name]: catIdArr });
    return catIdArr;
  });

  const objCat: IFormCategory = {};
  referenceArr.forEach((c) => {
    Object.keys(c).forEach((key: string) => {
      const testEqArr: string[] = [];
      catArray?.forEach((ac) => {
        if (c[key].includes(ac.categoryId)) {
          testEqArr.push(ac.action);
        }
        return testEqArr;
      });
      const categValue = Array.from(new Set<string>(testEqArr));
      const value = CATEGORY_OPTIONS(t).find((c) => c.value === categValue[0])!;
      if (categValue.length === 1) {
        objCat[key] = value;
      } else {
        objCat[key] = CATEGORY_OPTIONS(t)[6];
      }
    });
    return objCat;
  });

  return {
    ...objCat,
    ...obj,
  };
};

export const referenceValues = (data: ICategories[]) => {
  const refValArr: Array<{ name: string; categories: string[] }> = [];

  data?.forEach((el: ICategories) => {
    const categoriesArr: string[] = [];
    el.categories.forEach((subEl: ICategory) => {
      categoriesArr.push(`cat-${subEl.categoryId}`);
    });

    const element = {
      name: el.name,
      categories: categoriesArr,
    };
    refValArr.push(element);
  });

  return refValArr;
};

export const buildCategoryGroups = (data: IFormCategory) => {
  const categoryKeys = Object.keys(data);
  const contentCategoryActionList: ICategory[] = [];

  categoryKeys.forEach((key) => {
    const categoryId = Number(key.slice(-(key.length - 1 - key.indexOf('-'))));
    const categoryItem: ICategory = {
      categoryId: categoryId,
      action: String(data?.[key]?.value ?? ''),
    };
    if (categoryItem.categoryId > 0) {
      contentCategoryActionList.push(categoryItem);
    }
  });

  return contentCategoryActionList;
};

export const checkSubCatFieldsEqual = (
  field: string,
  val: ISelectItem,
  values: {},
  setField: (field: string, val: ISelectItem) => void,
  categoryGroups: ICategories[],
  t: TFunction<'translation', undefined>,
) => {
  setField(field, val);

  const categoryId = Number(
    field.slice(-(field.length - 1 - field.indexOf('-')))
  );

  let catName = '';
  const catToCheck: string[] = [];

  categoryGroups.forEach((el) => {
    if (el.categories.some((el) => el.categoryId === categoryId)) {
      el.categories.forEach((itm) => {
        const name = `cat-${itm.categoryId}`;
        catToCheck.push(name);
      });
      catName = el.name;
    }
  });

  const toCompare = _.pick(values, catToCheck);
  const objectToCompare = _.mapValues(toCompare, 'value');
  objectToCompare[field] = val.value;
  const arrayToCompare = Object.values(objectToCompare);
  Array.from(new Set<string[]>(arrayToCompare)).length === 1
    ? setField(catName, val)
    : setField(catName, CATEGORY_OPTIONS(t)[6]);
};

export const getSubCategoriesArray = (categCodes: ICategories[]) => {
  const catArray: ICategory[][] = [];
  categCodes?.forEach((el: ICategories) => {
    catArray.push(el['categories']);
  });
  const flatCategArray = catArray.reduce((acc, curr) => acc.concat(curr), []);
  return flatCategArray;
};

export const fetchPolicies = async (
  teamId?: string | undefined
): Promise<Array<ISelectItem & { created: string }>> => {
  const { data: policies }: { data: IPhishingPolicyElement[] } =
    await fetchAllPhishingPolicies({
      teamId,
    });

  return policies.map(({ name, id, team }: IPhishingPolicyElement) => {
    return {
      label: name,
      value: id,
      accountBounded: !team?.id,
      team,
    } as ISelectItem & { created: string };
  });
};
