import { appsInventoryColumnMapping } from 'components/main/appsInventory/AppsInventoryTab/appsInventory.mappings';
import { connect } from 'react-redux';
import { DrawerContext } from 'components/drawer/DrawerProvider';
import { getUISettingsTableHeaders } from 'reducers/UiSettingsSelectors';
import { Grid, Typography } from '@material-ui/core';
import { IColumnHeader, ILocationQuery } from 'components/UI/Table/models';
import { IDrawerContext } from 'components/drawer/DrawerProvider/models';
import { IRowProps } from '../models';
import { tableColumnData } from '../oocSelect.mappings';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/UI/Button';
import RuleCard from '../RuleCard';
import Table from 'components/UI/Table';
import tableQuery from './tableQuery';
import useStyles from './useStyles';

interface IDrawerProps {
  currentTableHeaders: IColumnHeader[];
}

const Drawer: React.FC<IDrawerProps> = ({ currentTableHeaders }) => {
  const classes = useStyles();
  const { drawerData, closeDrawer } =
    useContext<Partial<IDrawerContext<IRowProps>>>(DrawerContext);
  const { t } = useTranslation();

  const { ruleType } = drawerData ?? {};
  const { package: bundleId, version } = drawerData?.data ?? {};
  const query = { page: '0' };

  const getIOSDeveloperName = useMemo(() => {
    let developerName = undefined;
    const items = ruleType?.split('_') ?? [];
    if (items[0] === 'ios') {
      developerName = drawerData?.data?.developer;
    }
    return developerName;
  }, [ruleType, drawerData]);

  const getAndroidDeveloperSignature = useMemo(() => {
    let developerSignature = undefined;
    const items = ruleType?.split('_') ?? [];
    if (items[0] === 'android') {
      developerSignature = drawerData?.data?.developer;
    }
    return developerSignature;
  }, [ruleType, drawerData]);

  const getPlatformId = useMemo(() => {
    const items = ruleType?.split('_') ?? [];
    if (items[0] === 'ios') {
      return 2;
    } else if (items[0] === 'android') {
      return 1;
    }
  }, [ruleType]);

  const renderRuleData = useCallback(() => {
    const items = ruleType?.split('_') ?? [];
    return (
      <>
        {items.map((item: any, index: number) => (
          <RuleCard os={item} key={index} />
        ))}
      </>
    );
  }, [ruleType]);

  const renderContentData = () => {
    const dataArr = [];
    if (getIOSDeveloperName) {
      dataArr.push({
        label: tableColumnData(t).developerName.label,
        content: getIOSDeveloperName,
      });
    }

    if (getAndroidDeveloperSignature) {
      dataArr.push({
        label: tableColumnData(t).developer.label,
        content: getAndroidDeveloperSignature,
      });
    }

    if (bundleId) {
      dataArr.push({
        label: tableColumnData(t).package.label,
        content: bundleId,
      });
    }

    if (version) {
      dataArr.push({
        label: tableColumnData(t).version.label,
        content: version,
      });
    }

    return dataArr.map(({ label, content }, index) => (
      <Grid key={index} container>
        <Typography className={classes.contentLabel}>{`${label}:`}</Typography>
        &nbsp;
        <Typography className={classes.contentLabel}>{content}</Typography>
      </Grid>
    ));
  };

  return (
    <div className={classes.container}>
      <Typography variant="h5" className={classes.titleLabel}>
        {t('MTD.POLICIES.APP_POLICY.SHOW_AFFECTED_APPS')}
      </Typography>
      <div className={classes.infoContainer}>
        <div className={classes.iconContainer}>{renderRuleData()}</div>
        <div>{renderContentData()}</div>
      </div>
      <Table
        classList={classes}
        columnHeaders={currentTableHeaders}
        // definedUser={definedUser}
        fetchTableData={() =>
          tableQuery({
            developerName: getIOSDeveloperName,
            developerSignature: getAndroidDeveloperSignature,
            bundleId,
            version,
            platformId: getPlatformId,
          })(query as unknown as ILocationQuery)
        }
        heightBuffer={400}
        pagination={false}
        query={query as unknown as ILocationQuery}
        rowMapping={appsInventoryColumnMapping(t, true)}
        showLastColumnMenuTool={false}
        tableId="OOCSelectModal_Content"
      />
      <div className={classes.buttonContainer}>
        <Button
          onClick={closeDrawer}
          color="secondary"
          text={t('MTD.POLICIES.APP_POLICY.GO_BACK_TO_PREVIOUS_VIEW')}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentTableHeaders: getUISettingsTableHeaders(state, 'appsInventory'),
});

export default connect(mapStateToProps, null)(Drawer);
