import _ from 'lodash';
import { createTheme as createMuiTheme } from '@material-ui/core/styles';
import Color from 'color';

import blueGrey from '@material-ui/core/colors/blueGrey';

import _config from './_base/_config';
import _base from './_base/_base';
import _baseOverrides from './_base/_base.overrides';
import _baseGenerics from './_base/_base.generics';

const { colors } = _config;
const primaryColor = '#2dbdbd';

const themebase = {
  config: _config,

  palette: {
    type: 'dark',
    common: colors,

    primary: {
      light: colors.lightCyan,
      main: primaryColor,
      dark: colors.aquaMarine,
      contrastText: colors.white,
      border: colors.lightBlueGrey,
    },

    secondary: {
      light: blueGrey[400],
      main: blueGrey[500],
      dark: blueGrey[600],
      contrastText: colors.white,
    },

    success: {
      main: colors.paleGreen,
      contrastText: colors.white,
    },

    error: {
      light: colors.paleTomato,
      main: colors.tomato,
      medium: colors.strawberry,
      dark: colors.ripeTomato,
      contrastText: colors.white,
    },

    warning: {
      light: colors.lightCanery,
      main: colors.canery,
      dark: colors.darkCanery,
      contrastText: colors.darkGrey,
      schoolbus: colors.schoolbus,
    },

    text: {
      primary: colors.almostWhite,
      secondary: colors.white,
      disabled: 'rgba(255, 255, 255, 0.5)',
      hint: '',
      icon: colors.almostWhite,
      link: colors.white,
      placeholder: colors.almostWhite,
      highlighter: colors.darkGrey,
    },

    background: {
      default: colors.darkGrey,
      paper: colors.blueGrey,
      public: colors.almostBlack,
      tertiary: '#39474e',
      highlighter: Color(primaryColor).lighten(0.75).string(),
      zShieldCardActive: colors.darkGreySoft,
      zShieldCard: colors.darkGrey,
      deviceAction: '#30414B',
      modalTitle: '#454545',
    },

    charts: {
      background: '#464646',
      brightBlue: '#2196F3',
      brightGreen: '#4CAE50',
      darkBlue: '#1561A0',
      darkGreen: '#225023',
      fuscia: '#F50057',
      icon: '#30414B',
      orange: '#FF9F1E',
      tomato: '#F44337',
      purpleCritical: '#B620E0',
    },

    tables: {
      selectedRow: blueGrey[800],
      border: colors.darkerGrey,
      header: {
        background: colors.darkGreySoft,
      },
      shimmer: 'rgba(255, 255, 255, .15)',
      scrollbar: 'rgba(189, 189, 189, .5)',
    },

    graphs: {
      bar: {
        primary: blueGrey[800],
      },
    },

    calendar: {
      headerText: colors.white,
      borderColor: 'rgba(255, 255, 255, 0.7)',
      presetBackground: colors.darkGrey,
      presetHoverBackground: primaryColor,
      presetHoverText: colors.white,

      native: {
        selectionColor: primaryColor,
        weekdayColor: colors.lightAquaMarine,
        headerColor: primaryColor,
        todayColor: colors.lightAquaMarine,
        accentColor: colors.lightAquaMarine,
        floatingNav: {
          background: colors.lightGrey,
          color: colors.darkGreySoft,
          chevron: 'transparent',
        },
      },
    },

    components: {
      globalHeader: {
        background: colors.darkGrey,
        contrastText: colors.white,
      },

      sidebar: {
        background: 'hsla(200, 19%, 22%, 1)',
      },

      checkbox: {
        activeColor: colors.green,
        inactiveColor: colors.ripeTomato,
        pipColor: colors.white,
        trackColor: '#ccc',
      },

      circleIcon: {
        error: colors.strawberry,
        warning: colors.schoolbus,
        success: colors.avocado,
        unknown: colors.mediumGrey,
        low: colors.avocado,
      },

      severityIcon: {
        informational: colors.informational,
        bestPractices: colors.avocado,
        low: colors.lowSeverityBlue,
        high: colors.strawberry,
      },

      statusDot: {
        critical: colors.strawberry,
        elevated: colors.schoolbus,
        low: colors.substantial,
        normal: colors.moderate,
      },

      checkboxInputGroup: {
        base: colors.marineBlue,
      },

      datePicker: {
        svgFilter:
          'invert(87%) sepia(10%) saturate(8%) hue-rotate(344deg) brightness(85%) contrast(93%)',
      },

      teamSelector: {
        default: colors.white,
      },
    },

    accounts: {
      inputs: {
        svgFilter:
          'invert(87%) sepia(10%) saturate(8%) hue-rotate(344deg) brightness(85%) contrast(93%)',
      },
      modals: {
        contrastText: colors.white,
      },
    },
  },
};

const themeoverrides = {
  MuiCard: {
    root: {
      background: themebase.palette.background.default,
    },
  },

  MuiFormControlLabel: {
    label: {
      color: `${themebase.palette.text.link}`,
    },
  },

  MuiTabs: {
    indicator: {
      backgroundColor: themebase.palette.primary.main,
    },
  },

  MuiTab: {
    label: {
      color: themebase.palette.primary.contrastText,
    },
  },

  MuiListItem: {
    root: {
      '&$selected': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        color: themebase.palette.primary.main,
        fontWeight: 'bold',

        '& svg': {
          color: themebase.palette.primary.main,
        },
      },
    },
  },

  MuiSwitch: {
    switchBase: {
      color: themebase.palette.components.checkbox.pipColor,
    },
    colorSecondary: {
      '&$checked': {
        color: themebase.palette.components.checkbox.pipColor,
      },
    },
    track: {
      opacity: 0.7,
      backgroundColor: themebase.palette.components.checkbox.inactiveColor,
      '$checked$checked + &': {
        opacity: 0.9,
        backgroundColor: themebase.palette.components.checkbox.activeColor,
      },
    },
  },
};

export default createMuiTheme({
  ..._.merge({}, _base, themebase),
  overrides: _.merge(
    {},
    _baseOverrides({
      primaryText: themebase.palette.text.primary,
    }),
    themeoverrides
  ),
  globals: _baseGenerics, // global styling for resuable components
});
