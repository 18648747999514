import React from 'react';
import MuiInput from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import slugify from 'slugify';

const TextField = ({
  classes,
  label,
  error,
  helperText,
  inputProps,
  containerClass,
  isShowing,
  ...props
}) => {
  if (!isShowing) {
    return null;
  }

  const overRidingClasses = {
    ...classes,
    ...props.classlist,
  };
  // creating this quiets material ui bad classnames
  const validMaterialUIclasses = {
    root: overRidingClasses.root,
    formControl: overRidingClasses.formControl,
    focused: overRidingClasses.focused,
    disabled: overRidingClasses.disabled,
    adornedStart: overRidingClasses.adornedStart,
    adornedEnd: overRidingClasses.adornedEnd,
    error: overRidingClasses.error,
    marginDense: overRidingClasses.marginDense,
    multiline: overRidingClasses.multiline,
    colorSecondary: overRidingClasses.colorSecondary,
    fullWidth: overRidingClasses.fullWidth,
    input: overRidingClasses.input,
    inputMarginDense: overRidingClasses.inputMarginDense,
    inputMultiline: overRidingClasses.inputMultiline,
    inputTypeSearch: overRidingClasses.inputTypeSearch,
    inputAdornedStart: overRidingClasses.inputAdornedStart,
    inputAdornedEnd: overRidingClasses.inputAdornedEnd,
    inputHiddenLabe: overRidingClasses.inputHiddenLabel,
  };

  const id = props.id || `text-field-${slugify(label || props.name)}`;
  return (
    <div className={classnames(classes.container, containerClass)}>
      {label && (
        <label
          className={overRidingClasses.label}
          htmlFor={id}
          style={props.labelStyle}
        >
          {label}
        </label>
      )}
      <div style={{ position: 'relative' }}>
        <MuiInput
          id={id}
          {...props}
          classes={{ ...validMaterialUIclasses }}
          inputProps={inputProps}
          error={Boolean(error)}
          style={props.inputStyle}
        />
        {error && <span className={classes.errorText}>{error}</span>}
      </div>
      {helperText && <div className={classes.helperText}>{helperText}</div>}
    </div>
  );
};

TextField.defaultProps = {
  isShowing: true,
};

const styles = ({ palette, config, ...theme }) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },

    label: {
      paddingBottom: 5,
      marginTop: 15,
      fontSize: config.textSizes.petite,
    },

    helperText: {
      marginTop: 5,
      fontSize: 12,
    },

    errorText: {
      display: 'block',
      minWidth: 200,
      padding: 10,
      position: 'absolute',
      top: '50%',
      left: 'calc(100% + 5px)',
      zIndex: 200,
      background: palette.error.main,
      color: palette.error.contrastText,
      borderRadius: theme.shape.borderRadius,
      transform: 'translateY(-50%)',

      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '50%',
        right: 'calc(100% - 1px)',
        borderRight: `10px solid ${palette.error.main}`,
        borderTop: '10px solid transparent',
        borderBottom: '10px solid transparent',
        transform: 'translateY(-50%)',
      },
    },
  };
};

export default withStyles(styles)(TextField);
