import Select, { ISelectItem } from 'components/UI/input/Select';

import { Formik, Field as FormikField, Form as FormikForm } from 'formik';
import { useCallback, useMemo } from 'react';
import useAsyncResult from 'utils/useAsyncResult';
import { IStandardAuth } from '../../../AddEditCloneEMMAuth/StandardForm/models';
import { IVMWareAuth } from '../../../AddEditCloneEMMAuth/VMWareForm/models';
import { IFullData } from '../../../emmTable/MDM/models';
import {
  IAuthForGroupsCreate,
  ICloneInfoFromAuth,
  IConnector,
  TModalMode,
} from '../../../models';
import { IIntuneFields } from '../models';
import GroupsSkeleton from './GroupsSkeleton';
import IntuneFields from './IntuneFields';
import initialValues from './initialValues';
import { IAllPolicyOptions, IFormData, IGroupsWithPages } from './models';
import Schema from './schema';
import useStyles from './useStyles';
import { fetchAllPolicyOptions, fetchDeviceSpaces, fetchGroups } from './utils';
import { TFunction } from 'react-i18next';

export interface IFormProps {
  t: TFunction<'translation', undefined>;
  authForGroupsCreate?: IAuthForGroupsCreate<IStandardAuth | IVMWareAuth>;
  clearGroupTable: () => void;
  cloneInfoFromAuth?: ICloneInfoFromAuth;
  connector?: IConnector;
  data?: IFullData | undefined;
  isSubmitting: boolean;
  isIntune: boolean;
  mode?: TModalMode;
  previousFieldValues: Partial<IFormData> | undefined;
  setIntuneFields: React.Dispatch<React.SetStateAction<IIntuneFields>>;
  setPreviousFieldValues: React.Dispatch<
    React.SetStateAction<Partial<IFormData> | undefined>
  >;
  setThirdPartyMDMSelected: React.Dispatch<React.SetStateAction<boolean>>;
  thirdPartyMDMSelected: boolean;
  setReloadedGroupData: React.Dispatch<
    React.SetStateAction<IGroupsWithPages | undefined>
  >;
  reloadedGroupData: IGroupsWithPages | undefined;
  reloadingGroups: boolean;
  setReloadingGroups: React.Dispatch<React.SetStateAction<boolean>>;
  groupType?: string;
  setDeviceSpace: any;
}

const OptionForm: React.FC<IFormProps> = ({
  t,
  authForGroupsCreate,
  clearGroupTable,
  cloneInfoFromAuth,
  connector,
  data,
  isSubmitting = false,
  isIntune,
  mode = 'ADD',
  previousFieldValues,
  setIntuneFields,
  setPreviousFieldValues,
  setThirdPartyMDMSelected,
  thirdPartyMDMSelected,
  setDeviceSpace,
}) => {
  const classes = useStyles();

  const isSOTIConnector = connector?.id === 'MobiControlConnector';
  const isGoogleConnector = connector?.id === 'GoogleAdminConnector';

  const hasSpacesCapability = useMemo(
    () =>
      !!(
        connector?.capabilities?.[0] &&
        connector.capabilities.includes('DEVICE_SPACES')
      ),
    [connector?.capabilities]
  );

  const deviceSpaces: ISelectItem[] | undefined = useAsyncResult(
    fetchDeviceSpaces,
    hasSpacesCapability,
    mode === 'EDIT'
      ? data?.rowData?.id
      : authForGroupsCreate?.id ?? cloneInfoFromAuth?.connectionIdToClone
  );
  // If in edit mode, this pulls the first page of groups, and nothing in create mode
  const initialGroupData: IGroupsWithPages | undefined = useAsyncResult(
    fetchGroups,
    hasSpacesCapability,
    data?.rowData?.id ??
      authForGroupsCreate?.id ??
      cloneInfoFromAuth?.connectionIdToClone,
    data?.rowData?.auth?.device_space_id
  );

  // The below reloadedGroupData is loaded after selecting a device space

  const policies: IAllPolicyOptions | undefined = useAsyncResult(
    fetchAllPolicyOptions,
    data?.rowData?.id,
    mode,
    data?.rowData?.teamId ??
      authForGroupsCreate?.teamId ??
      cloneInfoFromAuth?.teamId
  );

  const initialGroupOptionsLoaded = useMemo(
    () =>
      (hasSpacesCapability && mode !== 'EDIT') ||
      !!initialGroupData?.groupOptions?.[0],
    [mode, initialGroupData?.groupOptions, hasSpacesCapability]
  );

  const handleDeviceSpaceChange = useCallback(
    async (
      field: string,
      value: ISelectItem,
      setFieldValue: (field: string, value: ISelectItem | ISelectItem[]) => void
    ) => {
      setFieldValue(field, value);
      setFieldValue('groups', []);
      clearGroupTable();
      setDeviceSpace(value?.value ?? '');
    },

    [clearGroupTable, setDeviceSpace]
  );

  const onFieldChange = (value: ISelectItem[]) => {
    setPreviousFieldValues((prevState: any) => ({
      ...prevState,
      deviceSpace: value,
    }));
  };
  // Initial values are resolved last so group options are initialized correctly and not as undefined
  const resolvedInitialValues = useMemo(
    () =>
      initialValues(
        t,
        mode,
        policies,
        deviceSpaces,
        data?.rowData,
        previousFieldValues,
        isIntune,
        isSOTIConnector,
        isGoogleConnector
      ),
    [
      t,
      mode,
      policies,
      deviceSpaces,
      data?.rowData,
      previousFieldValues,
      isIntune,
      isSOTIConnector,
      isGoogleConnector,
    ]
  );

  // Skeleton shown here so initialValues resolved correctly and dropdowns are populated on load.
  if (!policies || (hasSpacesCapability && !deviceSpaces?.[0])) {
    return (
      <GroupsSkeleton
        t={t}
        isSOTIConnector={isSOTIConnector}
        isGoogleConnector={isGoogleConnector}
        hasSpacesCapability={hasSpacesCapability}
        isIntune={isIntune}
        thirdPartyMDMSelected={thirdPartyMDMSelected}
      />
    );
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={resolvedInitialValues}
        onSubmit={() => {}}
        validateOnBlur
        validationSchema={Schema(mode)}
      >
        {({ setFieldValue, values }) => {
          return (
            <FormikForm>
              {(isIntune || isSOTIConnector || isGoogleConnector) && (
                <IntuneFields
                  t={t}
                  clearGroupTable={clearGroupTable}
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                  setIntuneFields={setIntuneFields}
                  setThirdPartyMDMSelected={setThirdPartyMDMSelected}
                  thirdPartyMDMSelected={thirdPartyMDMSelected}
                  values={values}
                  isSOTIConnector={isSOTIConnector}
                  isGoogleConnector={isGoogleConnector}
                />
              )}

              {(!isIntune || !thirdPartyMDMSelected) && (
                <>
                  {hasSpacesCapability && (
                    <>
                      <FormikField
                        component={Select}
                        disableClearable={true}
                        disabled={!initialGroupOptionsLoaded || isSubmitting}
                        label={t('GLOBAL.DEVICE_SPACES')}
                        multiple={false}
                        name="deviceSpace"
                        options={deviceSpaces ?? []}
                        setFieldValue={(field: string, value: any) => {
                          onFieldChange(value);
                          handleDeviceSpaceChange(field, value, setFieldValue);
                        }}
                        type="select"
                      />
                      <div className={classes.resetNotice}>
                        {t('MTD.INTEGRATIONS.DEVICE_SPACES_DESCRIPTION')}
                      </div>
                    </>
                  )}
                </>
              )}
            </FormikForm>
          );
        }}
      </Formik>
    </>
  );
};

export default OptionForm;
