import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    threatsSummaryContainer: {
      display: 'flex',
      minHeight: 110,
      marginBottom: 0,

      '& > div': {
        display: 'flex',
        justifyContent: 'space-between',
        flexGrow: 1,
      },
    },
  })
);

export default useStyles;
