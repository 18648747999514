import blueGrey from '@material-ui/core/colors/blueGrey';
import { createColorStep } from 'utils/colorUtils';
import Color from 'color';

export default {
  textSizes: {
    inch: 48,
    canon: 30,
    paragon: 21,
    primer: 18,
    tertia: 15,
    normal: 14,
    petite: 12,
    minion: 10,
  },

  fonts: {
    stackSerif: '"Times New Roman", Times, Georgia, serif',
    stackSans:
      '"Roboto", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif',
    stackMono: '"Roboto Mono", Courier, monospace',
    secondaryStackSans: '"Oswald", Roboto, Helvetica, Arial, "Arial Unicode"',
  },
  weights: {
    light: 200,
    normal: 400,
    bold: 600,
  },
  colors: {
    // greys
    white: '#fff',
    black: '#000',
    lightGrey: '#f8f9f9',
    mediumGrey: '#a6a6a6',
    darkGrey: blueGrey[900],
    darkerGrey: '#646464',
    darkGreySoft: '#494d52',
    lightBlueGrey: blueGrey[500],
    blueGrey: blueGrey[700],
    almostWhite: '#bdbdbd',
    almostBlack: '#1a1a1a',

    // blues
    cyan: '#23bebd',
    lightCyan: '#3fdad9',
    aquaMarine: '#1F8E89',
    lightAquaMarine: '#25a7a1',
    babyBlueGrey: '#eceff1',
    marineBlue: '#25435D',
    lowSeverityBlue: '#079898',

    // greens
    green: '#40a33f',
    paleGreen: '#b8e986',
    avocado: '#4cae50',

    // reds
    tomato: '#eb5e30',
    paleTomato: '#ff6736',
    ripeTomato: '#f53d00',
    strawberry: '#f34336',

    // yellows
    canery: '#d9db3d',
    lightCanery: '#eaeb93',
    darkCanery: '#8e9009',
    schoolbus: '#fe9700',

    // custom
    critical: '#ca2b2b',
    elevated: '#ca5d2b',
    substantial: '#d9db3d',
    moderate: '#2b90ca',
    low: '#2bbdca',
    bestPractices: '#4caf50',
    informational: '#9f8fef',
    ios: '#4a90e2',
    android: '#7ed321',
    uniqueDevices: '#1f6dc7',
    chromeos: '#FCC011',

    gradient: {
      android: createColorStep(Color('#7ed321').darken(0.25).hex()),
      ios: createColorStep('#4a90e2'),
      chromeos: createColorStep('#FCC011'),
    },
  },
};
