import { default as MUIDialog } from '@material-ui/core/Dialog';
import { default as MUIDialogTitle } from '@material-ui/core/DialogTitle';
import { getActiveModalAtom } from 'atoms/modals';
import cc from 'classcat';
import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import useStyles, { useOtherStyles } from './useStyles';
import CloseIcon from '@material-ui/icons/Close';

interface IModalProps {
  isOpenModal?: boolean;
  caption?: string;
  large?: boolean;
  onBackdropClick?: (...props: any) => void;
  onClose?: (...props: any) => void;
  noPadding?: boolean;
  rightToLeft?: boolean;
  scrollable?: boolean;
  title?: string;
  isCloseIcon?: boolean;
  platformIconType?: any;
  [key: string | number | symbol]: unknown;
}

const activeModalAtom = getActiveModalAtom();

const Modal: React.FC<IModalProps> = ({
  caption,
  children,
  large,
  onBackdropClick,
  onClose,
  noPadding,
  rightToLeft,
  scrollable,
  title,
  isOpenModal,
  isCloseIcon,
  platformIconType,
  ...rest
}) => {
  const classes = useStyles(scrollable, large)();
  const otherClasses = useOtherStyles();
  const activeModal = useRecoilValue(activeModalAtom);

  const handleClickAway = useCallback(
    (e: unknown, reason?: string) => {
      if (onBackdropClick && reason === 'backdropClick') {
        onBackdropClick();
      }

      if (typeof onClose === 'function') {
        onClose(e, reason);
      }
    },
    [onBackdropClick, onClose]
  );

  /**
   * Modal's will return NULL if they are not currently
   * active. This will prevent them from being mounted
   * globally until they are needed.
   */
  return activeModal?.active === title || !!isOpenModal ? (
    <MUIDialog
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      classes={classes}
      onClose={handleClickAway}
      open={activeModal?.active === title || isOpenModal === true}
      style={{ zIndex: 1000000000 }} // Facepalm.
      {...rest}
    >
      <div dir={rightToLeft ? 'rtl' : 'ltr'}>
        {!!caption && (
          <MUIDialogTitle className={otherClasses.title}>
            <div className={isCloseIcon ? otherClasses.zScanModalStyle : ''}>
              <div
                className={isCloseIcon ? otherClasses.zScanModalCloseStyle : ''}
              >
                {isCloseIcon && platformIconType}
                {caption}
              </div>
              {isCloseIcon && (
                <CloseIcon
                  fontSize="large"
                  className="cursor--pointer"
                  onClick={handleClickAway}
                />
              )}
            </div>
          </MUIDialogTitle>
        )}
        <div className={cc([{ [otherClasses.content]: !noPadding }])}>
          {children}
        </div>
      </div>
    </MUIDialog>
  ) : null;
};

export default Modal;
