import { fetchAllPolicies, fetchPolicyById } from 'api/BrandingPolicyService';
import { ISelectItem } from 'components/UI/input/Select';
import {
  IBrandingPolicy,
  IBrandingPolicyDetails,
  IBrandingPolicyDetailsResponse,
} from './models';

export const fetchPolicies = async (
  teamId?: string | null
): Promise<Array<ISelectItem & { created: string }>> => {
  const { data: policies }: { data: Partial<IBrandingPolicy>[] } =
    await fetchAllPolicies(teamId);

  return policies.map(
    ({ name, id, created, ...rest }: Partial<IBrandingPolicy>) =>
      ({ label: name, value: id, created, ...rest } as ISelectItem & {
        created: string;
      })
  );
};

const getBlob = (base64String: string | undefined): Blob | undefined => {
  if (!base64String) {
    return undefined;
  }

  const value = Buffer.from(base64String, 'base64');

  return new Blob([value], {
    type: 'image/svg+xml',
  });
};

const getColor = (value: string | undefined): string => {
  if (value) {
    return value.startsWith('#') ? value.split('#')[1] : value;
  }

  return '';
};

export const fetchPolicyDetails = async (
  id?: string | number | null
): Promise<IBrandingPolicyDetails> => {
  const response: { data: IBrandingPolicyDetailsResponse } =
    await fetchPolicyById(id);
  const { data } = response;

  return {
    ...data,
    buttonColorLight: getColor(data.buttonColorLight),
    buttonTextColorLight: getColor(data.buttonTextColorLight),
    buttonColorDark: getColor(data.buttonColorDark),
    buttonTextColorDark: getColor(data.buttonTextColorDark),
    images: {
      logoHorizontalDark: getBlob(data.images.logoHorizontalDark),
      logoVerticalDark: getBlob(data.images.logoVerticalDark),
      logoHorizontalLight: getBlob(data.images.logoHorizontalLight),
      logoVerticalLight: getBlob(data.images.logoVerticalLight),
    },
  };
};
