import LinkTabs from 'components/UI/LinkTabs';
import OSRiskDashboardTab from '../DashboardTab';
import OSRelatedCVEsTab from '../OSRelatedCVEsTab';
import OSVersionsTab from '../OSVersionsTab';
import useStyles from './useStyles';
import { useTranslation } from 'react-i18next';

interface IOSRiskProps {
  match: { params: { tabId: string } };
}

const OSRisk: React.FC<IOSRiskProps> = ({ match }) => {
  const classes = useStyles();
  const { t, ready } = useTranslation();

  if (!ready) {
    return null;
  }

  return (
    <div className={classes.tabWrapper}>
      <LinkTabs
        allowedScope="os_risk"
        tabs={[
          {
            allow: { os_risk: 'view' },
            label: t('GLOBAL.DASHBOARD'),
            component: OSRiskDashboardTab,
            to: '/console/mtd/os-risk/dashboard',
            value: 'dashboard',
          },
          {
            allow: { os_risk: 'view' },
            label: t('GLOBAL.OS_VERSIONS'),
            component: OSVersionsTab,
            to: '/console/mtd/os-risk/versions',
            value: 'versions',
          },
          {
            allow: { os_risk: 'view' },
            label: t('MTD.OS_RISK.OS_RELATED_CV_ES'),
            component: OSRelatedCVEsTab,
            to: '/console/mtd/os-risk/cves',
            value: 'cves',
          },
        ]}
        value={match.params.tabId}
      />
    </div>
  );
};

export default OSRisk;
