import { checkedRules as checkedRulesAtom } from 'atoms/policies';
import { IOOCSelect, ISelectRuleContent } from '../models';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Checkbox from 'components/UI/input/Checkbox';
import React, { useCallback, useMemo } from 'react';

interface RowCheckboxProps {
  ruleType: string;
  rowData: IOOCSelect;
  path: string;
  disabled: boolean;
}

const RowCheckbox: React.FC<RowCheckboxProps> = ({
  ruleType,
  rowData,
  path,
  disabled,
}) => {
  const checkedRulesInRecoil = useRecoilValue(checkedRulesAtom);
  const setCheckedRulesInRecoil = useSetRecoilState(checkedRulesAtom);

  const checked = useMemo(
    () =>
      checkedRulesInRecoil[ruleType]
        ?.filter((item) => {
          if (item.id) {
            return item.id === rowData.id;
          } else if (item.developer && item.package) {
            return (
              item.developer === rowData.developer &&
              item.package === rowData.package
            );
          } else if (item.package) {
            return item.package === rowData.package;
          } else if (item.developer) {
            return item.developer === rowData.developer;
          }
          return false;
        })?.[0]
        ?.targets?.includes(path),
    [checkedRulesInRecoil, path, rowData, ruleType]
  );

  const onChange = useCallback(
    () => {
      setCheckedRulesInRecoil((old: ISelectRuleContent) => {
        const selectedRows = old[ruleType];
        const targets = [path];
        let newRows: IOOCSelect[] = [];

        if (selectedRows && selectedRows.length > 0) {
          const currentRowData = selectedRows.filter(
            (item) => item.id === rowData.id
          );
          if (currentRowData.length > 0) {
            newRows = selectedRows.map((item) => {
              if (item.id === rowData.id) {
                const copyTarget = [...(item.targets ?? [])];
                const pathIndex = copyTarget.indexOf(path);
                if (pathIndex > -1) {
                  // checked
                  copyTarget.splice(pathIndex, 1);
                } else {
                  // unchecked
                  copyTarget.push(path);
                }
                return {
                  ...item,
                  targets: copyTarget,
                };
              }
              return item;
            });
          } else {
            newRows = selectedRows.concat({ ...rowData, targets });
          }
        } else {
          newRows.push({ ...rowData, targets });
        }

        return {
          ...old,
          [ruleType]: newRows, // save checkbox value
        };
      });
    },
    [path, rowData, ruleType, setCheckedRulesInRecoil]
  );

  return (
    <Checkbox
      color="primary"
      disabled={disabled}
      onChange={onChange}
      checked={!!checked}
    />
  );
};

export default RowCheckbox;
