import cc from 'classcat';
import useStyles from './useStyles';
import AndroidIcon from 'components/UI/icons/AndroidIcon';
import AppleIcon from 'components/UI/icons/AppleIcon';

interface IPlatformProps {
  iconType: string;
}

const PlatformIcon: React.FC<IPlatformProps> = ({ iconType }) => {
  const classes = useStyles();
  return (
    <div
      style={{ marginRight: '10px' }}
      className={cc([classes.osRiskIcon, classes.osRiskIconAndroid])}
    >
      {iconType === 'ios' ? <AppleIcon /> : <AndroidIcon />}
    </div>
  );
};

export default PlatformIcon;
