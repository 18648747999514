import { fetchDevicesImpactedAndroidIosThreats } from 'api/ZDefendInsights';
import { IDevicesThreats } from './models';
import { IHorizontalLegendItem } from 'components/UI/Chart/HorizontalLegend/models';

export const fetchDevicesThreats = async (
  teamId?: string | undefined,
  zappId?: string | undefined
): Promise<IDevicesThreats> => {
  const result: { data: IDevicesThreats } =
    await fetchDevicesImpactedAndroidIosThreats({
      teamId,
      zappId,
    });

  return result?.data;
};

export const getThreatsImpactedLegendItems = ({
  critical,
  elevated,
  low,
  os,
  zappId,
}: {
  critical: number;
  elevated: number;
  low: number;
  os: string;
  zappId: string;
}): IHorizontalLegendItem[] => [
  {
    color: '#f50057',
    label: 'Critical',
    link: `/console/zdefend/threats/?duration=8&severity=3&&zappId=${
      zappId ?? 'All'
    }&os=${os}`,
    order: 0,
    value: critical,
  },
  {
    color: '#ff9f1e',
    label: 'Elevated',
    link: `/console/zdefend/threats/?duration=8&severity=2&&zappId=${
      zappId ?? 'All'
    }&os=${os}`,
    order: 1,
    value: elevated,
  },
  {
    color: '#4cae50',
    label: 'Low',
    link: `/console/zdefend/threats/?duration=8&severity=1&&zappId=${
      zappId ?? 'All'
    }&os=${os}`,
    order: 2,
    value: low,
  },
];
