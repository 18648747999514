import { trimAndAddAsterisk } from 'utility/QueryUtility';

export const fetchSupportUsersParamsMapping = [
  {
    path: 'page',
  },
  {
    path: 'size',
  },
  {
    path: 'sort',
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      if (orderBy === 'name') {
        return `sr.name,${order}`;
      }
      if (orderBy && order) {
        return `${orderBy},${order}`;
      }

      return null;
    },
  },
  {
    path: 'search',
    transform: ({ search = '' }) => {
      if (!search) {
        return null;
      }

      return trimAndAddAsterisk(search);
    },
  },
];
