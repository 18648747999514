import { TFunction } from 'react-i18next';

const geti18nValues = (
  i18n: { [key: string]: string },
  t: TFunction<'translation', undefined>
): { [key: string]: string } => ({
  ...i18n,
  'calendar.cancelButtonText': t('REACTDATAGRID.CALENDAR.CANCEL_BUTTON_TEXT'),
  'calendar.clearButtonText': t('REACTDATAGRID.CALENDAR.CLEAR_BUTTON_TEXT'),
  'calendar.okButtonText': t('REACTDATAGRID.CALENDAR.OK_BUTTON_TEXT'),
  'calendar.todayButtonText': t('REACTDATAGRID.CALENDAR.TODAY_BUTTON_TEXT'),
  clear: t('GLOBAL.CLEAR'),
  clearAll: t('REACTDATAGRID.CLEAR_ALL'),
  columns: t('GLOBAL.COLUMNS'),
  contains: t('GLOBAL.CONTAINS'),
  disable: t('GLOBAL.DISABLE'),
  dragHeaderToGroup: t('REACTDATAGRID.DRAG_HEADER_TO_GROUP'),
  empty: t('GLOBAL.EMPTY'),
  enable: t('GLOBAL.ENABLE'),
  endsWith: t('REACTDATAGRID.ENDS_WITH'),
  eq: t('GLOBAL.EQUALS'),
  group: t('GLOBAL.GROUP'),
  gt: t('GLOBAL.GREATER_THAN'),
  gte: t('GLOBAL.GREATER_THAN_OR_EQUAL'),
  hideFilteringRow: t('REACTDATAGRID.HIDE_FILTERING_ROW'),
  lockEnd: t('REACTDATAGRID.LOCK_END'),
  lockStart: t('REACTDATAGRID.LOCK_START'),
  lt: t('GLOBAL.LESS_THAN'),
  lte: t('GLBOAL.LESS_THAN_OR_EQUAL'),
  neq: t('GLOBAL.DOES_NOT_EQUAL'),
  notContains: t('GLOBAL.DOES_NOT_CONTAIN'),
  notEmpty: t('GLOBAL.NOT_EMPTY'),
  ofText: t('REACTDATAGRID.OF_TEXT'),
  pageText: t('REACTDATAGRID.PAGE_TEXT'),
  perPageText: t('REACTDATAGRID.PER_PAGE_TEXT'),
  showFilteringRow: t('REACTDATAGRID.SHOW_FILTERING_ROW'),
  showingText: t('REACTDATAGRID.SHOWING_TEXT'),
  sortAsc: t('REACTDATAGRID.SORT_ASCENDING'),
  sortDesc: t('REACTDATAGRID.SORT_DESCENDING'),
  startsWith: t('REACTDATAGRID.'),
  ungroup: t('GLOBAL.UNGROUP'),
  unlock: t('GLOBAL.UNLOCK'),
  unsort: t('GLOBAL.UNSORT'),
});

export default geti18nValues;
