import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import {
  fetchPasswordPolicy,
  updatePasswordPolicy,
} from 'api/PasswordPolicyService';

import { openSnackBar } from 'utils/storeUtils';

import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

// ui
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { FormikCheckbox } from 'components/inputs/FormikCheckbox';
import { FormikTextField } from 'components/inputs/FormikTextField';
import Grid from '@material-ui/core/Grid';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import ZButton from 'UI/Buttons/ZButton';

const initialState = {
  enabled: false,
  minimumLengthEnabled: false,
  minimumLength: 0,
  uppercaseRequired: false,
  lowercaseRequired: false,
  numberRequired: false,
  symbolRequired: false,
  nonLetterRequired: false,
  maxRepeatingCharactersEnabled: false,
  maxRepeatingCharacters: 0,
  lastKnownPasswordsEnabled: false,
  lastKnownPasswords: 0,
  passwordChangeMonthPeriodEnabled: false,
  passwordChangeMonthPeriod: 0,
};

const PasswordPolicy = () => {
  const [passwordPolicy, setPasswordPolicy] = useState({});

  useEffect(() => {
    fetchPasswordPolicy()
      .then(resp =>
        setPasswordPolicy(
          _.pick(resp.data, [
            'enabled',
            'minimumLengthEnabled',
            'minimumLength',
            'uppercaseRequired',
            'lowercaseRequired',
            'numberRequired',
            'symbolRequired',
            'nonLetterRequired',
            'maxRepeatingCharactersEnabled',
            'maxRepeatingCharacters',
            'lastKnownPasswordsEnabled',
            'lastKnownPasswords',
            'passwordChangeMonthPeriodEnabled',
            'passwordChangeMonthPeriod',
          ])
        )
      )
      .catch(() =>
        openSnackBar(
          'Unable to fetch password policy for this account, please try again.'
        )
      );
  }, []);

  function onSubmit(values, actions) {
    updatePasswordPolicy({ ...values })
      .then(() =>
        openSnackBar('Successfully saved Password Policy for this account.')
      )
      .catch(() =>
        openSnackBar(
          'Unable to save Password Policy for this account, please re-check the form.'
        )
      );

    actions.setSubmitting(false);
  }

  return (
    <div>
      <div className="view__header">
        <h1>Password Policy</h1>
      </div>
      <Card>
        <CardContent>
          <Formik
            initialValues={{
              ...initialState,
              ...passwordPolicy,
            }}
            onSubmit={onSubmit}
            validationSchema={PasswordPolicySchema}
            enableReinitialize
          >
            {({ values, isValid }) => {
              return (
                <Form>
                  <Grid container justify="space-between" spacing="1">
                    <Grid item md={6}>
                      <Field
                        name="enabled"
                        label="Enable password policy"
                        component={FormikCheckbox}
                        type="checkbox"
                        checked={values.enabled}
                      />
                      <div className="flex--spaced">
                        <Field
                          disabled={!values.enabled}
                          name="minimumLengthEnabled"
                          label="Minimum password length"
                          component={FormikCheckbox}
                          type="checkbox"
                          checked={values.minimumLengthEnabled}
                        />
                        <Field
                          disabled={!values.enabled}
                          name="minimumLength"
                          component={FormikTextField}
                          checked={values.minimumLength}
                        />
                      </div>
                      Required password elements:
                      <div style={{ marginLeft: 24 }}>
                        <Field
                          disabled={!values.enabled}
                          name="uppercaseRequired"
                          label="Uppercase (A - Z)"
                          component={FormikCheckbox}
                          type="checkbox"
                          checked={values.uppercaseRequired}
                        />
                        <Field
                          disabled={!values.enabled}
                          name="lowercaseRequired"
                          label="Lowercase (a - z)"
                          component={FormikCheckbox}
                          type="checkbox"
                          checked={values.lowercaseRequired}
                        />
                        <Field
                          disabled={!values.enabled}
                          name="numberRequired"
                          label="Numbers (0 - 9)"
                          component={FormikCheckbox}
                          type="checkbox"
                          checked={values.numberRequired}
                        />
                        <Field
                          disabled={!values.enabled}
                          name="symbolRequired"
                          label="Symbols (Punctuation, Underscore)"
                          component={FormikCheckbox}
                          type="checkbox"
                          checked={values.symbolRequired}
                        />
                        <Field
                          disabled={!values.enabled}
                          name="nonLetterRequired"
                          label="Non-letter (Number or Symbol)"
                          component={FormikCheckbox}
                          type="checkbox"
                          checked={values.nonLetterRequired}
                        />
                      </div>
                      <div className="flex--spaced">
                        <Field
                          disabled={!values.enabled}
                          name="maxRepeatingCharactersEnabled"
                          label="Maximum number of repeating characters"
                          component={FormikCheckbox}
                          type="checkbox"
                          checked={values.maxRepeatingCharactersEnabled}
                        />
                        <Field
                          disabled={!values.enabled}
                          name="maxRepeatingCharacters"
                          component={FormikTextField}
                          checked={values.maxRepeatingCharacters}
                        />
                      </div>
                      <div className="flex--spaced">
                        <Field
                          disabled={!values.enabled}
                          name="lastKnownPasswordsEnabled"
                          label="Password used must be different from the last"
                          component={FormikCheckbox}
                          type="checkbox"
                          checked={values.lastKnownPasswordsEnabled}
                        />
                        <Field
                          disabled={!values.enabled}
                          name="lastKnownPasswords"
                          component={FormikTextField}
                          checked={values.lastKnownPasswords}
                        />
                      </div>
                      <div className="flex--spaced">
                        <Field
                          disabled={!values.enabled}
                          name="passwordChangeMonthPeriodEnabled"
                          label="Password Change Period (in Months)"
                          component={FormikCheckbox}
                          type="checkbox"
                          checked={values.passwordChangeMonthPeriodEnabled}
                        />
                        <Field
                          disabled={!values.enabled}
                          name="passwordChangeMonthPeriod"
                          component={FormikTextField}
                          checked={values.passwordChangeMonthPeriod}
                        />
                      </div>
                      <div
                        style={{
                          marginTop: 15,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <ProtectedComponent allow={{ account: 'manage' }}>
                          <ZButton
                            isDisabled={!isValid}
                            buttonType="submit"
                            buttonText="Save Password Policy"
                            color="primary"
                            styleName="modalSave"
                          />
                        </ProtectedComponent>
                      </div>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </CardContent>
      </Card>
    </div>
  );
};

const PasswordPolicySchema = Yup.object().shape({
  enabled: Yup.boolean(),
  minimumLengthEnabled: Yup.boolean(),
  minimumLength: Yup.number()
    .typeError('Must be a number')
    .min(8, 'Must be greater than or equal to 8'),
  uppercaseRequired: Yup.boolean(),
  lowercaseRequired: Yup.boolean(),
  numberRequired: Yup.boolean(),
  symbolRequired: Yup.boolean(),
  nonLetterRequired: Yup.boolean(),
  maxRepeatingCharactersEnabled: Yup.boolean(),
  maxRepeatingCharacters: Yup.number()
    .typeError('Must be a number')
    .min(0, 'Must be greater than or equal to zero'),
  lastKnownPasswordsEnabled: Yup.boolean(),
  lastKnownPasswords: Yup.number()
    .typeError('Must be a number')
    .min(0, 'Must be greater than or equal to zero'),
  passwordChangeMonthPeriodEnabled: Yup.boolean(),
  passwordChangeMonthPeriod: Yup.number()
    .typeError('Must be a number')
    .min(0, 'Must be greater than or equal to zero'),
});

export default PasswordPolicy;
