import { getAssessmentDetails } from 'api/zDevService';
import { useCallback, useEffect, useState } from 'react';
import { IZScanAssessment } from '../ZScanAssessments/models';

export default (id: string) => {
  const [assessmentDetails, setAssessmentDetails] = useState<IZScanAssessment>();

  const fetchAssessmentDetails = useCallback(async () => {
    const { data } = await getAssessmentDetails({ id });

    setAssessmentDetails(data);
  }, [id]);

  useEffect(() => {
    fetchAssessmentDetails();
  }, [fetchAssessmentDetails]);

  return { assessmentDetails };
};
