import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

export default state => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      appsInventory: {
        ...uiSettingsDefaults.appsInventory,
        tableHeaders: uiSettingsDefaults.appsInventory.tableHeaders,
      },
      adminAccounts: {
        ...uiSettingsDefaults.appsInventory,
        tableHeaders: uiSettingsDefaults.adminAccounts.tableHeaders,
      },
      superUsers: {
        ...uiSettingsDefaults.superUsers,
        tableHeaders: uiSettingsDefaults.superUsers.tableHeaders,
      },
    },
  };
};
