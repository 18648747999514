import { DialogActions, DialogContent } from '@material-ui/core';
import { default as MUICard } from '@material-ui/core/Card';
import {
  ErrorOutline as ErrorOutlineIcon,
  Sync as SyncIcon,
} from '@material-ui/icons';
import Button from 'components/UI/Button';
import RadioGroup, { Radio } from 'components/UI/input/RadioGroup';
import Tabs from 'components/UI/Tabs';
import { Api } from 'config/axiosConfig';
import { useEffect, useState } from 'react';
import { toggleModalDiffered } from 'utils/storeUtils';
import DailyForm from './DailyForm';
import { IDailySyncSchedule } from './DailyForm/models';
import HourlyForm from './HourlyForm';
import { IHourlySyncSchedule } from './HourlyForm/models';
import { IRowData, IScheduleBody, ISyncSchedule } from './models';
import useStyles from './useStyles';
import { createCronString, parseCronString, submitSchedule } from './utils';
import { useTranslation } from 'react-i18next';

interface IEMMSyncScheduleProps {
  data: IRowData;
}

const EMMSyncSchedule: React.FC<IEMMSyncScheduleProps> = ({
  data: { id: connectionId },
}) => {
  const apiUrl = `api/emm/v1/provision/connection/${connectionId}/schedule`;
  const [syncData, setSyncData] = useState<Partial<ISyncSchedule>>({});
  const { hours, hourlyStartTime, dailyStartTime, days } = syncData;
  const hourlyData: Partial<IHourlySyncSchedule> = { hours, hourlyStartTime };
  const dailyData: Partial<IDailySyncSchedule> = { dailyStartTime, days };
  const [showSchedule, setShowSchedule] = useState(true);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const classes = useStyles();

  const handleToggleSyncType = () => {
    setShowSchedule(!showSchedule);
  };

  const handleManualSubmit = () => {
    submitSchedule(apiUrl, setErrorMessage, {
      syncEnabled: showSchedule,
    });
  };

  const handleSubmit = (schedule: IHourlySyncSchedule | IDailySyncSchedule) => {
    const syncCronSchedule = createCronString(schedule as ISyncSchedule);
    submitSchedule(apiUrl, setErrorMessage, {
      syncEnabled: showSchedule,
      syncCronSchedule,
    });
  };

  useEffect(() => {
    const fetchSyncData = async () => {
      try {
        const data: IScheduleBody = (await Api.get(apiUrl))?.data ?? null;
        if (data?.syncCronSchedule) {
          const scheduleData = parseCronString(data.syncCronSchedule);
          setSyncData(scheduleData);
          setShowSchedule(data?.syncEnabled ?? true);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    fetchSyncData();
  }, [apiUrl]);

  const { t, ready } = useTranslation();

  if (!ready) {
    return null;
  }

  return (
    <DialogContent>
      <div className={classes.container}>
        <div className={classes.header}>
          <h1 className={classes.headerText}>
            {t('MTD.INTEGRATIONS.EMM_SYNC_SCHEDULING')}
          </h1>
        </div>
        <div className={classes.content}>
          {errorMessage ? (
            <>
              {errorMessage && (
                <>
                  <div className={classes.errorMessage}>
                    <ErrorOutlineIcon className={classes.errorIcon} />
                    <span className={classes.errorText}>{errorMessage}</span>
                  </div>
                  <DialogActions>
                    <Button
                      color="secondary"
                      onClick={toggleModalDiffered(false, 'EMMSyncSchedule')}
                      text={t('GLOBAL.CANCEL')}
                      type="button"
                    />
                    <Button
                      color="primary"
                      onClick={() => setErrorMessage('')}
                      text={t('GLOBAL.RETRY')}
                      type="button"
                    ></Button>
                  </DialogActions>
                </>
              )}
            </>
          ) : (
            <>
              {loading ? (
                <MUICard
                  classes={{ root: classes.emptyCard }}
                  className="is-loading shimmer"
                />
              ) : (
                <>
                  <div className={classes.syncSelection}>
                    <RadioGroup
                      label={t('MTD.INTEGRATIONS.HOW_TO_HANDLE_CONSOLE_SYNC')}
                      onChange={handleToggleSyncType}
                    >
                      <Radio
                        checked={showSchedule}
                        color="primary"
                        label={t('GLOBAL.SCHEDULED')}
                        name="syncType"
                        size="small"
                        value="scheduled"
                      />
                      <Radio
                        checked={!showSchedule}
                        color="primary"
                        label={t('MTD.INTEGRATIONS.MANUAL_SYNC_ONLY')}
                        name="syncType"
                        size="small"
                        value="manual"
                      />
                    </RadioGroup>
                  </div>
                  {showSchedule ? (
                    <Tabs
                      tabs={[
                        {
                          title: 'GLOBAL.HOURLY',
                          content: (
                            <HourlyForm
                              data={hourlyData}
                              handleSubmit={handleSubmit}
                            />
                          ),
                        },
                        {
                          title: 'GLOBAL.DAILY',
                          content: (
                            <DailyForm
                              data={dailyData}
                              handleSubmit={handleSubmit}
                            />
                          ),
                        },
                      ]}
                      value={dailyData?.days?.[0] ? 1 : 0}
                    />
                  ) : (
                    <>
                      <div className={classes.manualNotice}>
                        <span>{t('MTD.INTEGRATIONS.THE_EMM_SYNC_ONLY')}</span>
                        <SyncIcon className={classes.icon} />
                        <span>{t('MTD.INTEGRATIONS.ICON_IN_THE_TABLE')}</span>
                      </div>
                      <DialogActions>
                        <Button
                          color="secondary"
                          onClick={toggleModalDiffered(
                            'EMMSyncSchedule',
                            false
                          )}
                          text={t('GLOBAL.CANCEL')}
                          type="button"
                        />
                        <Button
                          color="primary"
                          onClick={handleManualSubmit}
                          text={t('GLOBAL.SAVE')}
                          type="submit"
                        ></Button>
                      </DialogActions>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </DialogContent>
  );
};

export default EMMSyncSchedule;
