import cc from 'classcat';
import DoughnutChart from 'components/UI/Chart/Doughnut';
import { IHorizontalLegendItem } from 'components/UI/Chart/HorizontalLegend/models';
import GenericCard from 'components/UI/GenericCard';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stats } from '../models';
import useStyles from '../useStyles';

interface IRiskCount {
  riskCategory: string;
  riskStats: IHorizontalLegendItem[];
  stats: Stats | undefined;
  summarySuffix: string;
}

const RiskCount: React.FC<IRiskCount> = ({
  riskCategory,
  riskStats,
  stats,
  summarySuffix,
}) => {
  const classes = useStyles();
  const { i18n, t, ready } = useTranslation();

  const statsSegments: IHorizontalLegendItem[] | undefined = useMemo(
    () => (stats ? riskStats : undefined),
    [riskStats, stats]
  );

  if (!ready) {
    return null;
  }

  return (
    <GenericCard className={classes.card}>
      <h2>{riskCategory}</h2>
      {statsSegments ? (
        <DoughnutChart
          forceProportionate
          forceSegmentsOrder
          segments={statsSegments}
          summarySuffix={summarySuffix}
          withLegend
        />
      ) : (
        <div
          className={cc([
            {
              [classes.pendingNonEnglish]:
                i18n.language?.toLowerCase() !== 'en-us',
            },
            classes.pending,
          ])}
        >
          {t('GLOBAL.NOT_AVAILABLE')}
        </div>
      )}
    </GenericCard>
  );
};

export default RiskCount;
