import { useRef, useState, useCallback } from 'react';
import { default as MuiAccordion } from '@material-ui/core/Accordion';
import { default as MuiAccordionDetails } from '@material-ui/core/AccordionDetails';
import { default as MuiAccordionSummary } from '@material-ui/core/AccordionSummary';
import { default as MUIArrowForwardIosIcon } from '@material-ui/icons/ArrowForwardIos';
import { generateJsonFile } from 'utils/componentUtils';
import UIElementActions from './UIElementActions';
import useStyles from './useStyles';
import zScanTranscript_sample from './zScanTranscriptTable/zscantranscript_sample.json';
import GenericError from 'components/UI/GenericErrorBox';
import ZScanTranscriptTable from './zScanTranscriptTable/ZScanTranscriptTable';
import { getParsedJSON } from './utils';
import { JSON_SCHEMA_INVALID } from '../../models';

interface IProps {
  expanded: boolean;
  handleAccordion: (expanded: boolean) => void;
  handleFormData: (file: string) => void;
  rawJSONZScanTranscript?: string;
}

export enum IElementActionType {
  DOWNLOAD_SAMPLE = 'download-sample-data',
  UPLOAD_JSON = 'upload-json',
}

const UIDataElementExpansion: React.FC<IProps> = ({
  expanded,
  handleAccordion,
  handleFormData,
  rawJSONZScanTranscript,
}: IProps) => {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileUploadError, setFileUploadError] = useState<string | undefined>(
    undefined
  );

  const handleFileChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setFileUploadError(undefined);
      const file = e?.currentTarget?.files?.[0];
      let parsedJSONObject;
      if (file) {
        parsedJSONObject = await getParsedJSON(file);
      }
      if (!parsedJSONObject?.zScanScript) {
        setFileUploadError(JSON_SCHEMA_INVALID);
      }
      handleFormData(JSON.stringify(parsedJSONObject));
    },
    [handleFormData]
  );

  const handleAction = useCallback(
    async (
      e: React.ChangeEvent<HTMLInputElement>,
      actionType: IElementActionType
    ) => {
      switch (actionType) {
        case IElementActionType.DOWNLOAD_SAMPLE:
          generateJsonFile(
            'zScanTranscript_sample.json',
            zScanTranscript_sample
          );
          break;
        case IElementActionType.UPLOAD_JSON:
          if (inputRef) {
            inputRef?.current?.click();
          }
          break;
        default:
          break;
      }
    },
    []
  );

  /* for now, i am keepping the accordion 
     open if a zscanTranscript exists.  here it is a string (before getting parsed in the table)
  */
  return (
    <MuiAccordion className={classes.accordion} expanded={expanded}>
      <MuiAccordionSummary
        onClick={() => handleAccordion(!expanded)}
        className={classes.accordianHeader}
        expandIcon={<MUIArrowForwardIosIcon />}
      >
        Enter Data for UI Elements (optional)
      </MuiAccordionSummary>
      <MuiAccordionDetails>
        <div className={classes.accordionDetails}>
          <span>
            Use this table to add UI Elements that need to be populated or
            touched in order to properly test the application.
          </span>
          <div className={classes.elementDropdownWrapper}>
            <UIElementActions handleAction={handleAction} />
          </div>
          <div>
            <ZScanTranscriptTable
              rawJSONZScanTranscript={rawJSONZScanTranscript || '[]'}
              handleFormData={handleFormData}
            />
            <GenericError errorMessage={fileUploadError} />
          </div>
        </div>
      </MuiAccordionDetails>
      <input
        style={{ display: 'none' }}
        ref={inputRef}
        type="file"
        onChange={handleFileChange}
        accept={'.json'}
      />
    </MuiAccordion>
  );
};

export default UIDataElementExpansion;
