import AccountsCreateEdit from './AccountsCreateEdit';
import AccountsDeleteConfirm from './AccountsDeleteConfirm';
import ActivationsBulk from './ActivationsBulk';
import ActivationGroupCreateEdit from './ActivationGroupCreateEdit';
import ActivationsLink from './ActivationsLink';
import AppsCreateEdit from './AppsCreateEdit';
import AppsDeleteConfirm from './AppsDeleteConfirm';
import AppsChangeEntitlements from './AppsChangeEntitlements';
import AppSettingsCreateClone from './AppSettingsCreateClone';
import AppSettingsSaveConfirmation from './AppSettingsSaveConfirmation';
import AuthorizationsCreateEdit from './AuthorizationsCreateEdit';
import AuthorizationsDeleteConfirm from './AuthorizationsDeleteConfirm';
import AuthorizationsRegenerateConfirm from './AuthorizationsRegenerateConfirm';
import AuthorizationsSecretDisplay from './AuthorizationsSecretDisplay';
import BomUpload from './BomUpload';
import BuildUpload from './BuildUpload';
import CompareBuildsSelectTwo from './CompareBuildsSelectTwo';
import ConfirmDialog from './ConfirmDialog';
import CsvExport from './CsvExport';
import DataExportCreateEdit from './DataExportCreateEdit';
import DataExportDeleteConfirm from './DataExportDeleteConfirm';
import DeviceAlert from 'components/main/policies/threats/DeviceAlert';
import DeviceDelete from './DeviceDeleteConfirm';
//Remove dormancy import??
import DormancyPolicyCreateClone from './DormancyPolicyCreateClone';
import ActivationsConfirmAction from './ActivationsConfirmAction';
import GenericConfirm from './GenericConfirm';
import GenericReminder from './GenericReminder';
import GroupActivationsCreateEdit from './GroupActivationsCreateEdit';
import GroupActivationsDeleteConfirm from './GroupActivationsDeleteConfirm';
import GroupActivationsLink from './GroupActivationsLink';
import GroupsCreateEdit from './GroupsCreateEdit';
import GroupsDeleteConfirm from './GroupsDeleteConfirm';
import InstancesStatusChangeConfirm from './InstancesStatusChangeConfirm';
import PartnerAccountsDeleteConfirm from './PartnerAccountsDeleteConfirm';
import { MDMCronJob } from './MDMCronJob';
import MDMDeleteConfirm from './MDMDeleteConfirm';
import { MDMSyncConfirm } from './MDMSyncConfirm';
import EMMSyncSchedule from './EMMSyncSchedule';
import NearlyThere from './NearlyThere';
import OOCSelectModal from 'components/main/oocSelect/OOCSelectModal';
import PartnerAuthorizationsCreateEdit from './PartnerAuthorizationsCreateEdit';
import PartnerUsersCreateEditForPartners from './PartnerUsersCreateEditForPartners';
import PartnerUsersCreateEdit from './PartnerUsersCreateEdit';
import PartnerUserDeleteConfirm from './PartnerUserDeleteConfirm';
import PartnerUserEdit from './PartnerUserEdit';
import PhishingPolicyCreateClone from './PhishingPolicyCreateClone';
import PhishingPolicySaveConfirmation from './PhishingPolicySaveConfirmation';
import PlansClone from './PlansClone';
import PlansDeleteConfirm from './PlansDeleteConfirm';
import PolicyDeleteConfirm from './PolicyDeleteConfirm';
import RolesCreateEdit from './RolesCreateEdit';
import RolesDeleteConfirm from './RolesDeleteConfirm';
import SubscriptionMFA from './subscriptions/SubscriptionMFA';
import SubscriptionText from './subscriptions/SubscriptionText';
import SuperUsersCreateEdit from './SuperUsersCreateEdit';
import SuperUsersDeleteConfirm from './SuperUsersDeleteConfirm';
import ThreatsMitigation from './ThreatsMitigation';
import TeamsCreateEdit from './TeamsCreateEdit';
import TeamsDeleteConfirm from './TeamsDeleteConfirm';
import UploadApp from './UploadApp';
import UserActivationsCreateEdit from './UserActivationsCreateEdit';
import UserActivationsDeleteConfirm from './UserActivationsDeleteConfirm';
import UserCreateEdit from './UserCreateEdit';
import UserDeleteConfirm from './UserDeleteConfirm';
import ZDevAppsUpdate from './ZDevAppsUpdate';
import ZScanUpload from './ZScanAppEdit';
import ZDevCVSSCalculator from './ZDevCVSSCalculator';
import ZDevPolicyCreateClone from './ZDevPolicyCreateClone';
import ZDevPolicyDeleteConfirm from './ZDevPolicyDeleteConfirm';
import ZscanAppsDeleteConfirm from './ZscanAppsDeleteConfirm';
import ZscanAssessmentDeleteConfirm from './ZScanAssessmentDelete';
import ZShieldUploadApp from './ZShieldUploadApp';
import ZShieldRegisterApp from './ZShieldRegisterApp';
import ZShieldEditDesc from './ZShieldEditDesc';
import ZShieldAppDeleteConfirm from './ZShieldAppDeleteConfirm';
import ZScanSnippets from './ZScanSnippets';
import { IntegrationCreateEdit } from './IntegrationCreateEdit';
import { IntegrationDeleteConfirm } from './IntegrationDeleteConfirm';
import { ConfigurationDeleteConfirm } from './ConfigurationDeleteConfirm';
import ConfigurationCreateEdit from './ConfigurationCreateEdit';
import ZScanUploadApp from './ZScanUploadApp';

const Modals = {
  AccountsCreateEdit,
  AccountsDeleteConfirm,
  ActivationsBulk,
  ActivationGroupCreateEdit,
  ActivationsLink,
  AppsCreateEdit,
  AppsDeleteConfirm,
  AppsChangeEntitlements,
  AppSettingsCreateClone,
  AppSettingsSaveConfirmation,
  AuthorizationsCreateEdit,
  AuthorizationsDeleteConfirm,
  AuthorizationsRegenerateConfirm,
  AuthorizationsSecretDisplay,
  BomUpload,
  BuildUpload,
  CompareBuildsSelectTwo,
  ConfigurationCreateEdit,
  ConfigurationDeleteConfirm,
  ConfirmDialog,
  CsvExport,
  DataExportCreateEdit,
  DataExportDeleteConfirm,
  DeviceAlert,
  DeviceDelete,
  DormancyPolicyCreateClone,
  EMMSyncSchedule,
  ActivationsConfirmAction,
  GenericConfirm,
  GenericReminder,
  GroupActivationsCreateEdit,
  GroupActivationsDeleteConfirm,
  GroupActivationsLink,
  GroupsCreateEdit,
  GroupsDeleteConfirm,
  InstancesStatusChangeConfirm,
  IntegrationCreateEdit,
  IntegrationDeleteConfirm,
  MDMCronJob,
  MDMDeleteConfirm,
  MDMSyncConfirm,
  NearlyThere,
  OOCSelectModal,
  PartnerAccountsDeleteConfirm,
  PartnerAuthorizationsCreateEdit,
  PartnerUsersCreateEditForPartners,
  PartnerUserEdit,
  PartnerUsersCreateEdit,
  PartnerUserDeleteConfirm,
  PhishingPolicyCreateClone,
  PhishingPolicySaveConfirmation,
  PlansClone,
  PlansDeleteConfirm,
  PolicyDeleteConfirm,
  RolesCreateEdit,
  RolesDeleteConfirm,
  SubscriptionMFA,
  SubscriptionText,
  SuperUsersCreateEdit,
  SuperUsersDeleteConfirm,
  TeamsCreateEdit,
  TeamsDeleteConfirm,
  ThreatsMitigation,
  UploadApp,
  UserActivationsCreateEdit,
  UserActivationsDeleteConfirm,
  UserCreateEdit,
  UserDeleteConfirm,
  ZDevAppsUpdate,
  ZScanUpload,
  ZDevCVSSCalculator,
  ZDevPolicyCreateClone,
  ZDevPolicyDeleteConfirm,
  ZscanAppsDeleteConfirm,
  ZscanAssessmentDeleteConfirm,
  ZShieldAppDeleteConfirm,
  ZShieldUploadApp,
  ZShieldRegisterApp,
  ZShieldEditDesc,
  ZScanSnippets,
  ZScanUploadApp,
};

export const mappedModals = Object.keys(Modals).reduce(
  (acc, key) => ({
    ...acc,
    [key]: false,
  }),
  {}
);

export { default as Modal } from './GenericModal';
export default Modals;
