import cc from 'classcat';
import GenericCard from 'components/UI/GenericCard';
import React, { useCallback, useMemo } from 'react';
import useAsyncResult from 'utils/useAsyncResult';
import { default as useSharedStyles } from '../../useStyles';
import { ITopFiveThreats } from './models';
import useStyles from './useStyles';
import { fetchThreatsTypes, fetchTopFiveThreats } from './utils';
import { IThreatType } from 'components/main/MTDInsights/models';

interface ITopFiveThreatsProps {
  teamId: string | null | undefined;
  zappId: string | null | number | undefined;
}

const TopFiveThreats: React.FC<ITopFiveThreatsProps> = ({ teamId, zappId }) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  const topFiveThreats = useAsyncResult<ITopFiveThreats>(
    fetchTopFiveThreats,
    teamId ?? '',
    zappId ?? ''
  );

  const threatTypes = useAsyncResult<IThreatType[]>(fetchThreatsTypes);

  const getThreatsPageLink = (
    threatTypeIds: number[],
    zappId: null | number | undefined | string
  ): string =>
    `/console/zdefend/threats/?duration=8&zappId=${zappId ?? 'All'}${threatTypeIds?.length
      ? `&threatTypeId=${threatTypeIds.join('&threatTypeId=')}`
      : ''
    }`;

  const getThreatCategoryName = useCallback(
    (categoryId: string) => {
      return threatTypes?.find((el) => el.id === Number(categoryId))?.shortName;
    },
    [threatTypes]
  );

  const byVolumeElements = useMemo(
    () =>
      (topFiveThreats?.byVolume ?? []).map(
        ({ category, count, threatTypeIds }, index) => {
          const link = getThreatsPageLink(threatTypeIds, zappId);
          const catName = getThreatCategoryName(category);

          return (
            <div className="top-five-threats-item" key={`${category}-${index}`}>
              <div>
                <a href={link}>{catName}</a>
              </div>
              <div className={classes.title}>
                <a href={link}>{count}</a>
              </div>
            </div>
          );
        }
      ),
    [classes.title, getThreatCategoryName, topFiveThreats, zappId]
  );

  const byUniqueDeviceElements = useMemo(
    () =>
      (topFiveThreats?.byUniqueDevice ?? []).map(
        ({ category, count, threatTypeIds }, index) => {
          const link = getThreatsPageLink(threatTypeIds, zappId);
          const catName = getThreatCategoryName(category);

          return (
            <div className="top-five-threats-item" key={`${category}-${index}`}>
              <div>
                <a href={link}>{catName}</a>
              </div>
              <div className={classes.title}>
                <a href={link}>{count}</a>
              </div>
            </div>
          );
        }
      ),
    [classes.title, getThreatCategoryName, topFiveThreats, zappId]
  );

  const hasData = useMemo(
    () =>
      topFiveThreats?.byUniqueDevice?.length ||
      topFiveThreats?.byVolume?.length,
    [topFiveThreats]
  );

  return (
    <GenericCard
      className={cc([sharedClasses.card, classes.topFiveThreatsCard])}
    >
      <div className={sharedClasses.column}>
        {hasData ? (
          <>
            <h2>Top 5 Threats by Volume</h2>
            <div className={sharedClasses.column}>
              <div className={classes.topFiveThreats}>{byVolumeElements}</div>
              <h2>Top 5 Threats by Unique Device</h2>
              <div className={classes.topFiveThreats}>
                {byUniqueDeviceElements}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={classes.emptyContainer}>
              <h2 className={classes.emptyTitle}>Top 5 Threats by Volume</h2>
              <div className={sharedClasses.column}>
                <div className={classes.pending}>N/A</div>
              </div>
            </div>
            <div className={classes.emptyContainer}>
              <h2 className={classes.emptyTitle}>Top 5 Threats by Unique Device</h2>
              <div className={sharedClasses.column}>
                <div className={classes.pending}>N/A</div>
              </div>
            </div>
          </>
        )}
      </div>
    </GenericCard>
  );
};

export default TopFiveThreats;
