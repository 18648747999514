import { Api } from 'config/axiosConfig';

const ThreatDefaultsBase = 'api/mtd-policy/v1/threat-defaults';

export const fetchThreatDefaultsSource = async () => {
  return Api.get(`${ThreatDefaultsBase}/sources`);
};

export const fetchAllThreatDefaults = async (trmId: string) => {
  return Api.get(`${ThreatDefaultsBase}/${trmId}`);
};

export const updateThreatDefaults = async (trmId: string, payload: any) => {
  return Api.put(`${ThreatDefaultsBase}/${trmId}`, payload);
};

export const fetchAllThreatTypes = async () => {
  return Api.get(`api/trm/v1/threat-types/all`);
};
