import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }: any) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'baseline',
      position: 'relative',
      paddingLeft: 5,

      '&:before': {
        content: '""',
        width: 12,
        height: 12,
        marginRight: 5,
        marginBottom: 3,
        borderRadius: '50%',
        backgroundColor: palette.success.main,
      },
    },
    absolute: {
      '&:before': {
        position: 'absolute',
        right: '100%',
        transform: 'translate(-1px, 1px)',
      },
    },
    noMarker: {
      '&:before': {
        display: 'none',
      },
    },
    connectionIcon: {
      marginLeft: 0,
      paddingLeft: 0,
      display: 'flex',
      paddingBottom: 2,
      alignItems: 'center',
    },
  };
});

export default useStyles;
