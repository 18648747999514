import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, config }: any) =>
  createStyles({
    enterAccount: {
      fontSize: config.textSizes.primer,
      marginRight: 5,
  
      '&:hover': {
        cursor: 'pointer',
      },
    },
    favorite: {
      cursor: 'pointer',
      color: palette.common.canery,
    },
    nameWrapper: {
      display: 'flex'
    }
  })
);

export default useStyles;
