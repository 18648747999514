import _ from 'lodash';
import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';

import withRouter from 'components/hocs/withRouter';
import { TextField, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { withTranslation } from 'react-i18next';

class SearchBox extends PureComponent {
  state = {};

  constructor(props) {
    super(props);
    this.debounceSearch = _.debounce(this.onSearch.bind(this), 400);
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <TextField
        onChange={(e) => this.debounceSearch(e.target.value)}
        className={classes.searchBoxWrapper}
        defaultValue={this.props.location.query.search}
        InputProps={{
          placeholder: t(`GLOBAL.${this.props.placeholder.toUpperCase()}`),
          classes: {
            root: classes.searchBoxNaked,
          },
          endAdornment: (
            <InputAdornment
              classes={{
                root: classes.searchIcon,
              }}
              position="end"
            >
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    );
  }

  onSearch(value) {
    const { props } = this;
    props.updateUrl({ search: value });
    props.updateSearchTerm && props.updateSearchTerm(value);
  }
}

SearchBox.defaultProps = {
  id: 'search-box',
  placeholder: 'Search',
  variant: 'filled',
};

const styles = ({ palette }) => {
  return {
    searchBoxNaked: {
      position: 'relative',
      borderBottom: 'none',

      '&:before': {
        display: 'none',
        borderBottom: 'none !important',
        position: 'initial',
      },
      '&:after': {
        display: 'none',
        borderBottom: 'none',
        position: 'initial',
      },
      '&:hover': {
        borderBottom: 'none',
      },

      '& > input': {
        padding: 15,
      },
    },

    searchBoxWrapper: {
      width: '100%',
    },

    searchIcon: {
      height: '100%',
      maxHeight: 'initial',
      width: 55,
      padding: '0 5px',
      marginLeft: 0,
      justifyContent: 'center',
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translateY(-50%)',
      background: palette.background.paper,
      border: `1px solid ${palette.primary.border}`,

      '& svg': {
        fontSize: 32,
      },
    },
  };
};

export default withStyles(styles)(withRouter(withTranslation()(SearchBox)));
