import _ from 'lodash';
import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
// services
import {
  createSuperUser,
  fetchSuperRoles,
  fetchAllAccounts,
  requestSuperUser,
  updateSuperUser,
} from 'api/apis';

// actions
import { publishEvent } from 'utils/eventUtils';

// components
import { Grid } from '@material-ui/core';
import { MultiSelect } from 'components/Selects';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import ZButton from 'UI/Buttons/ZButton';
import { FormikTextField } from 'components/inputs/FormikTextField';
import {
  csvInjectionRegex,
  csvInjectionErrorMessage,
  looksGenerallyLikeAnEmailRegex,
  looksGenerallyLikeAnEmailRegexErrorMessage,
} from 'utils/componentUtils';
import {
  openSnackBar,
  toggleModalDirect,
  toggleModalDiffered,
} from '../../utils/storeUtils';

const defaultFormState = {
  role: [],
  accounts: [],
  error: '',
};

class SuperUsersCreateEdit extends PureComponent {
  constructor(props) {
    super(props);

    this.fetchSuperUser = this.fetchSuperUser.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.editMode = !_.isEmpty(props.data);

    this.state = this.editMode
      ? {
          ...defaultFormState,
          ..._.pick(props.data, ['firstName', 'lastName', 'email']),
        }
      : {
          ...defaultFormState,
          availableRoles: [],
          availableAccounts: [],
        };
  }

  componentDidMount() {
    const promises = [fetchSuperRoles(), fetchAllAccounts()];

    if (this.editMode) {
      promises.push(requestSuperUser(this.props.data.id));
    }

    Promise.all(promises).then((values) => {
      const availableRoles = reduceForMultiSelect(_.get(values, '[0].data'));
      const availableAccounts = reduceForMultiSelect(_.get(values, '[1].data'));

      const newState = {
        availableRoles,
        availableAccounts,
      };

      if (this.editMode) {
        newState.role = reduceForMultiSelect([
          _.get(values, '[2].data.superRole'),
        ]);

        newState.accounts = reduceForMultiSelect(
          mapAccountPersonas(_.get(values, '[2].data.accountPersonas'))
        );
      }

      this.setState(newState);
    });
  }

  componentDidUpdate(prevProps) {
    const { props } = this;

    if (_.isEmpty(prevProps.data.id) && !_.isEmpty(props.data.id)) {
      this.fetchSuperUser(props.data.id);
    }
  }

  render() {
    const { props } = this;
    return (
      <DialogContent>
        <Formik
          initialValues={{
            firstName: _.get(props, 'data.firstName', ''),
            lastName: _.get(props, 'data.lastName', ''),
            email: _.get(props, 'data.email', ''),
            // role: state.role,
            // teams: state.teams,
          }}
          validationSchema={SuperUserSchema}
          validateOnBlur
          enableReinitialize
          onSubmit={this.handleSubmit}
        >
          {({ dirty, isSubmitting, values }) => {
            return (
              <Form>
                <Grid container spacing={8}>
                  <Grid item xs>
                    <Field
                      name="firstName"
                      label="First Name"
                      component={FormikTextField}
                      autoComplete="off"
                      classList={{ errorClass: props.classes.errorClass }}
                    />
                  </Grid>
                  <Grid item xs>
                    <Field
                      name="lastName"
                      label="Last Name"
                      component={FormikTextField}
                      autoComplete="off"
                      classList={{ errorClass: props.classes.errorClass }}
                    />
                  </Grid>
                </Grid>
                <Field
                  name="email"
                  label="Email"
                  component={FormikTextField}
                  autoComplete="off"
                  classList={{ errorClass: props.classes.errorClass }}
                />
                <MultiSelect
                  name="role"
                  label="Role"
                  isMulti={false}
                  buttonPlaceholder="Select a Role"
                  options={this.state.availableRoles}
                  onChange={this.handleSelect}
                  values={this.state.role}
                />
                <MultiSelect
                  name="accounts"
                  label="Accounts"
                  buttonPlaceholder="Assign Accounts"
                  options={this.state.availableAccounts}
                  onChange={this.handleSelect}
                  values={this.state.accounts}
                />
                <Typography type="body1" align="left">
                  {this.state.error}
                </Typography>
                <DialogActions>
                  <ZButton
                    styleName="modalCancel"
                    action={toggleModalDiffered('SuperUsersCreateEdit', false)}
                    color="secondary"
                    buttonText="Cancel"
                  />
                  <ZButton
                    buttonType="submit"
                    color="primary"
                    styleName="modalSave"
                    buttonText="Save Super User"
                  />
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    );
  }

  handleSelect(name, selectedOption) {
    // this is needed for Role editing
    const option = _.isArray(selectedOption)
      ? selectedOption
      : [selectedOption];
    this.setState({ [name]: option });
  }

  handleChange(field) {
    return (event) => {
      this.setState({
        [field]: event.target.value,
      });
    };
  }

  handleSubmit(values) {
    const { props, state } = this;
    const payload = {
      ..._.pick(values, ['firstName', 'lastName', 'email']),
      roleId: _.get(_.head(state.role), 'value'), // role is an array of one element
      accounts: state.accounts.map((account) => account.value),
    };
    const saveRequest = this.editMode
      ? updateSuperUser(props.data.id, payload)
      : createSuperUser(payload);

    saveRequest
      .then(() => {
        const message = this.editMode
          ? `Super User ${values.firstName} ${values.lastName} was updated`
          : `Super User ${values.firstName} ${values.lastName} was created`;
        openSnackBar(message);
        toggleModalDirect('SuperUsersCreateEdit', false);
        publishEvent('table:force-fetch-superUsers');
      })
      .catch((error) => {
        const message = `An error occurred: ${error.response.data}`;
        this.setState({ error: message });
      });
  }

  fetchSuperUser(userId) {
    requestSuperUser(userId)
      .then((resp) => {
        const role = reduceForMultiSelect([_.get(resp.data, 'superRole')]);
        const accounts = reduceForMultiSelect(
          mapAccountPersonas(_.get(resp.data, 'accountPersonas'))
        );

        this.setState({ role, accounts });
      })
      .catch((error) => console.log(error));
  }
}

function mapAccountPersonas(array) {
  return array.map((account) => {
    return {
      id: account.accountId,
      name: account.accountName,
    };
  });
}

function reduceForMultiSelect(array) {
  return array.reduce((accum, value) => {
    const { id, name } = value;

    accum.push({
      label: name,
      value: id,
    });

    return accum;
  }, []);
}

const SuperUserSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(1)
    .matches(csvInjectionRegex, csvInjectionErrorMessage)
    .required('Required'),

  lastName: Yup.string()
    .min(1)
    .matches(csvInjectionRegex, csvInjectionErrorMessage)
    .required('Required'),

  email: Yup.string()
    .matches(
      looksGenerallyLikeAnEmailRegex,
      looksGenerallyLikeAnEmailRegexErrorMessage
    )
    .matches(csvInjectionRegex, csvInjectionErrorMessage)
    .required('Required'),
});

const styles = ({ palette, shape }) => ({
  errorClass: {
    minWidth: 150,
    padding: '7px 10px',
    marginTop: 19,
    marginLeft: 10,
    position: 'absolute',
    zIndex: 1,
    transform: 'translateY(-50%)',
    background: palette.error.light,
    color: palette.error.contrastText,
    borderRadius: shape.borderRadius,
  },
});

SuperUsersCreateEdit.defaultProps = {
  data: {},
};

export default withStyles(styles)(SuperUsersCreateEdit);
