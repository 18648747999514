import Tabs from 'components/UI/Tabs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import EditAccountProfile from '../manage/EditAccountProfile';
import ChangePassword from './ChangePassword';
import EditUserAccountProfile from './EditUserAccountProfile';

interface IProfileProps {
  disableLocalLogins?: boolean;
}

const Profile: React.FC<IProfileProps> = ({ disableLocalLogins }) => {
  const { t, ready } = useTranslation();

  if (!ready) {
    return null;
  }

  return (
    <>
      {!disableLocalLogins ? (
        <div>
          <h1>{t('USER_SETTINGS.PROFILE.USER_PROFILE')}</h1>
          <Tabs
            tabs={[
              {
                title: t('USER_SETTINGS.PROFILE.PERSONAL_INFO'),
                content: <EditUserAccountProfile />,
              },
              {
                title: t('GLOBAL.PASSWORD'),
                content: <ChangePassword />,
              },
            ]}
          />
        </div>
      ) : (
        <EditAccountProfile />
      )}
    </>
  );
};

const mapStateToProps = (state: {
  user: { account: { disableLocalLogins: boolean } };
}) => ({
  disableLocalLogins: state?.user?.account?.disableLocalLogins ?? false,
});

export default connect(mapStateToProps)(Profile);
