import cc from 'classcat';
import LiteralProportionChart from 'components/UI/Chart/LiteralProportion';
import GenericCard from 'components/UI/GenericCard';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { App } from '../models';
import useStyles from '../useStyles';

interface ITopApps {
  category: string;
  topAppsByCategory: App[];
}

const TopApps: React.FC<ITopApps> = ({ category, topAppsByCategory }) => {
  const classes = useStyles();
  const { i18n, t, ready } = useTranslation();

  const topApps = useMemo(
    () =>
      (topAppsByCategory ?? []).map(({ appName, deviceCount }) => ({
        count: deviceCount,
        label: appName,
        suffix: deviceCount > 1 ? t('GLOBAL.DEVICES') : t('GLOBAL.DEVICE'),
      })),
    [t, topAppsByCategory]
  );

  if (!ready) {
    return null;
  }

  return (
    <GenericCard className={classes.card}>
      <h2>{category}</h2>
      <div className={classes.column}>
        {topApps?.length > 0 ? (
          <LiteralProportionChart
            columns={topApps}
            className={classes.literalProportionChart}
            showSingleMetric
          />
        ) : (
          <div
            className={cc([
              {
                [classes.pendingNonEnglish]:
                  i18n.language?.toLowerCase() !== 'en-us',
              },
              classes.pending,
            ])}
          >
            {t('GLOBAL.NOT_AVAILABLE')}
          </div>
        )}
      </div>
    </GenericCard>
  );
};

export default TopApps;
