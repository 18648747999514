import React from 'react';
import { withStyles } from '@material-ui/core/styles';

// ui
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import QRCode from 'qrcode.react';
import ZButton from 'UI/Buttons/ZButton';
import { useTranslation } from 'react-i18next';

// components
import {
  toggleModalDiffered,
  openSnackBarDiffered,
  toggleModalDirect,
  openSnackBar,
} from 'utils/storeUtils';
import { regenerateLinkForGroupActivation } from 'api/GroupActivationsService';
import { publishEvent } from 'utils/eventUtils';
import { CopyField } from 'components/inputs/index';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';

function handleRegenerateLink(rowData) {
  return () => {
    regenerateLinkForGroupActivation(
      {
        id: rowData.id,
      },
      {}
    )
      .then(() => {
        toggleModalDirect('GroupActivationsCreateEdit', false);
        openSnackBar(`Regenerated activation link for ${rowData.name}`);
        publishEvent('table:force-fetch-groupActivations');
      })
      .catch(() =>
        openSnackBar(
          'Unable to regenerate link for group activation, please try again.'
        )
      );
  };
}

const GroupActivationsLink = (props) => {
  const { t, ready } = useTranslation();

  let url = props.data.rowData.activationLink;
  if (url.includes('http:') && !url.includes('localhost')) {
    url = url.replace('http:', 'https:');
  }

  if (!ready) {
    return null;
  }

  return (
    <DialogContent>
      <div style={{ width: 275, paddingBottom: 20 }}>
        {t('GLOBAL.SCAN_AND_SHARE_QR_CODE_DESCRIPTION')}
      </div>
      <div className="flex--column--spaced">
        <QRCode value={url} includeMargin />
      </div>
      <CopyField
        label={t('MTD.ACTIVATIONS.ACTIVATION_LINK')}
        value={url}
        onCopy={openSnackBarDiffered('Activation link copied to clipboard')}
      />
      Or click <a href={url}>here</a> to activate.
      <DialogActions>
        <ProtectedComponent allow={{ zapps: 'manage' }}>
          <ZButton
            styleName="modalSave"
            buttonText={t('GLOBAL.REGENERATE_LINK')}
            color="primary"
            action={handleRegenerateLink(props.data.rowData)}
          />
        </ProtectedComponent>
        <ZButton
          styleName="modalSave"
          buttonText={t('GLOBAL.CLOSE')}
          color="primary"
          action={toggleModalDiffered('GroupActivationsLink', false)}
        />
      </DialogActions>
    </DialogContent>
  );
};

const styles = ({ palette }) => {
  return {
    errorText: {
      display: 'flex',
      alignItems: 'center',
      color: palette.text.secondary,
    },

    errorOutline: {
      marginRight: 5,
      verticalAlign: 'bottom',
      color: palette.error.main,
    },

    fileButton: {
      padding: '6px 16px',
      fontSize: '0.875rem',
      minWidth: 64,
      boxSizing: 'border-box',
      lineHeight: 1.75,
      fontWeight: 400,
      borderRadius: 3,
      color: palette.text.secondary,
      backgroundColor: palette.primary.main,
      cursor: 'pointer',
      marginBottom: 10,
    },

    hideFile: {
      position: 'absolute',
      zIndex: '1000',
      opacity: 0,
      cursor: 'pointer',
      right: 0,
      top: 0,
      height: '80%',
      fontSize: 24,
      width: '100%',
    },
  };
};
export default withStyles(styles)(GroupActivationsLink);
