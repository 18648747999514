import React, { useMemo } from 'react';
import HorizontalLegend from '../HorizontalLegend';
import { IHorizontalLegendItem } from '../HorizontalLegend/models';
import Segment from './Segment';
import useStyles from './useStyles';

export interface INestedDoughnutChartProps {
  forceProportionate?: boolean;
  segments: IHorizontalLegendItem[];
  withLegend?: boolean;
}

const NestedDoughnutChart: React.FC<INestedDoughnutChartProps> = ({
  forceProportionate,
  segments,
  withLegend,
}) => {
  const rPad = 0.2;

  const classes = useStyles();

  const scalar = useMemo(() => {
    const total = segments.reduce((a, { value }) => a + value, 0);
    return total > 100 || forceProportionate ? total : 100;
  }, [forceProportionate, segments]);

  const circleSegments = useMemo(
    () =>
      (scalar > 100 || forceProportionate
        ? segments.sort(({ value: a }, { value: b }) => (a > b ? 1 : -1))
        : segments
      ).map(({ color, value, ...rest }, index) => (
        <Segment
          color={color}
          index={index}
          key={index}
          rPad={rPad}
          scalar={scalar}
          value={value}
          {...rest}
        />
      )),
    [forceProportionate, scalar, segments]
  );

  return (
    <div className={classes.doughnut}>
      <div className={classes.section}>
        <svg
          version="1.1"
          viewBox="0 0 42 42"
          width="100%"
          xmlns="http://www.w3.org/2000/svg"
        >
          {circleSegments}
        </svg>
      </div>
      <div className={classes.section}>
        {!!withLegend && (
          <HorizontalLegend className={classes.legend} items={segments} />
        )}
      </div>
    </div>
  );
};

export default NestedDoughnutChart;
