import withRouter from 'components/hocs/withRouter';
import { Table, TableColumnsContext } from 'components/Tables';
import { useContext } from 'react';
import { connect } from 'react-redux';
import {
  configurationsTableColumnChange,
  updateUISettings,
} from 'reducers/UiSettingsReducers';
import { getAvailableTeamsAsList } from 'reducers/TeamReducers';
import {
  getChangedTableHeadersHash,
  getUISettingsTableHeaders,
  getUISettingsWithout,
} from 'reducers/UiSettingsSelectors';
import { bindActionCreators, compose } from 'redux';
import useBackfill from 'utils/useBackfill';
import { IntegrationsContext } from '../store-integration-context';
import { configurationsTableColumns } from './configurations.mapping';
import { getConfigurations } from './configurations.service';
import { Toolbar } from '@material-ui/core';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import ZButton from 'UI/Buttons/ZButton';
import { toggleModalDiffered } from 'utils/storeUtils';
import AddIcon from 'components/UI/icons/AddIcon';

const ConfigurationsListing = (props) => {
  useBackfill({
    query: props.q,
    requiredQueryParams: props.rqps,
    storedQueryParams: props.uiSettings,
    updateUrl: props.updateUrl,
  });

  const { integrations } = useContext(IntegrationsContext);

  function onPaginate(update) {
    props.updateUISettings({
      domain: 'configurations',
      update,
    });
  }

  function fetchTableData(query) {
    return getConfigurations(query)
      .then(({ data }) => {
        const availableTeams = props.availableTeams;
        data?.map((configurationDetails) => {
          configurationDetails.teamName = availableTeams.filter(
            (team) => team.id === configurationDetails.teamId
          )?.[0]?.name;
        });
        return {
          tableData: data,
          totalResults: data?.length,
        };
      })
      .catch(console.error);
  }

  return (
    <>
      <Toolbar style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ProtectedComponent allow={{ zdev_apps: 'manage' }}>
          <ZButton
            action={toggleModalDiffered(
              'ConfigurationCreateEdit',
              {},
              {
                title: 'ZSCAN.CONFIGURATIONS.CREATE_NEW_CONFIGURATION',
                disableBackdropClick: true,
              }
            )}
            buttonText="New Configuration"
            color="secondary"
            icon={{ elem: AddIcon }}
          />
        </ProtectedComponent>
      </Toolbar>
      <TableColumnsContext.Provider
        value={{
          columnHeaders: props.currentTableHeaders,
          columnHeadersHash: props.columnHeadersHash,
          onColumnChange: props.configurationsTableColumnChange,
          onChangePage: onPaginate,
          onChangeRows: onPaginate,
          rowMapping: configurationsTableColumns,
        }}
      >
        <Table tableId="configurations" fetchTableData={fetchTableData} />
      </TableColumnsContext.Provider>
    </>
  );
};

const mapStateToProps = (state) => ({
  columnHeadersHash: getChangedTableHeadersHash(state, 'configurations'),
  currentTableHeaders: getUISettingsTableHeaders(state, 'configurations'),
  uiSettings: getUISettingsWithout(state, 'configurations', ['tableHeaders']),
  availableTeams: getAvailableTeamsAsList(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      configurationsTableColumnChange,
      updateUISettings,
    },
    dispatch
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ConfigurationsListing);
