import { ServiceHandlerFactory } from 'utils/serviceUtils';
import defaultMappings from 'components/RDGTables/default.mappings';

const zShieldBaseUrl = `/api/zapp`;
const zShieldParmSchema = [
  ...defaultMappings,
  {
    path: 'teamId',
  },
];

export const fetchProtectedAppsPage = ServiceHandlerFactory({
  axios: {
    url: `${zShieldBaseUrl}/v1/apps?teamId=:teamId`,
  },
  isCancelable: true,
  paramSchema: zShieldParmSchema,
});

export const fetchProtectedAppsCard = ServiceHandlerFactory({
  axios: {
    url: `${zShieldBaseUrl}/v1/apps?page=:page&size=100&statusSortOrder=1,2,3,4&teamId=:teamId`,
  },
  isCancelable: true,
  paramSchema: zShieldParmSchema,
});
export const fetchProtectedBuildsPage = ServiceHandlerFactory({
  axios: {
    url: `${zShieldBaseUrl}/v1/apps/:appId/builds?teamId=:teamId&sort=created,desc`,
  },
  isCancelable: true,
  paramSchema: zShieldParmSchema,
});

export const updateProtectedApp = ServiceHandlerFactory({
  axios: {
    url: `${zShieldBaseUrl}/v1/apps/:appId`,
    method: 'put',
  },
});

export const deleteProtectedApp = ServiceHandlerFactory({
  axios: {
    url: `${zShieldBaseUrl}/v1/apps/:appId`,
    method: 'delete',
  },
});

export const deleteProtectedBuild = ServiceHandlerFactory({
  axios: {
    url: `${zShieldBaseUrl}/v1/builds/:buildId`,
    method: 'delete',
  },
});
export const downloadProtectedFile = ServiceHandlerFactory({
  axios: {
    url: `${zShieldBaseUrl}/v1/builds/:id/protected`,
  },
});
