import { IZShieldApp } from '../models';
import useZShieldApps from '../useZShieldApps';
import CategorySection from './CategorySection';
import useStyles from './useStyles';
import { useState, useCallback } from 'react';
// import Spinner from "components/main/common/Spinner";
import { useRecoilValue } from 'recoil';
import { selectedTeam as selectedTeamAtom } from 'atoms/teams';

const ZShieldCardView: React.FC = () => {
  const selectedTeamId = useRecoilValue(selectedTeamAtom) || undefined;
  const { zShieldAppsCount, sortedApps /*, isLoading*/ } = useZShieldApps(
    selectedTeamId ?? ''
  );
  const [activeCard, setActiveCard] = useState<string>('');
  const classes = useStyles();

  const handleSelectActiveCard = useCallback((app: IZShieldApp) => {
    setActiveCard(app?.id);
  }, []);

  // if (isLoading) {
  //   return <div className={classes.spinnerStatus}>
  //     <Spinner />
  //   </div>;
  // }
  if (!sortedApps) {
    return null;
  }

  return (
    <div className={classes.cardContainer}>
      {zShieldAppsCount > 0 ? (
        <>
          <CategorySection
            category={'Protection in Progress'}
            categoryApps={sortedApps['Protection in Progress']}
            activeCard={activeCard}
            setActiveCard={handleSelectActiveCard}
          />
          <CategorySection
            category={'Unprotected'}
            categoryApps={sortedApps['Unprotected']}
            activeCard={activeCard}
            setActiveCard={handleSelectActiveCard}
          />
          <CategorySection
            category={'Update Available'}
            categoryApps={sortedApps['Update Available']}
            activeCard={activeCard}
            setActiveCard={handleSelectActiveCard}
          />
          <CategorySection
            category={'Protected'}
            categoryApps={sortedApps['Protected']}
            activeCard={activeCard}
            setActiveCard={handleSelectActiveCard}
          />
        </>
      ) : (
        <div className={classes.empty}>
          Click on the "Add New App" button to get started.
        </div>
      )}
    </div>
  );
};

export default ZShieldCardView;
