import _ from 'lodash';
import Moment from 'moment';

export function cleanAcceptedFindingsPayload(checkedFindings) {
  const checkedFindingsArray = Object.keys(checkedFindings).map(key => {
    const finding = checkedFindings[key];
    return {
      appFindingId: finding.id,
      appId: finding.appId,
    };
  });
  return {
    appFindings: [...checkedFindingsArray], // appFindings: [...checkFindingsArray]
  };
}

export function cleanStatusFindingsPayload(checkedFindings, status) {
  const checkedFindingsArray = Object.keys(checkedFindings).map(key => {
    const finding = checkedFindings[key];
    return {
      appFindingId: finding.id,
      appId: finding.appId,
    };
  });
  return {
    newIssueStatus: status,
    appFindings: [...checkedFindingsArray],
  };
}

export function cleanAcceptedInstancesPayload(checkedInstances, appId) {
  const checkedInstancesArray = Object.keys(checkedInstances).map(key => {
    const instance = checkedInstances[key];

    return {
      appFindingLocationId: instance.id,
      appFindingId: instance.appFindingId,
    };
  });
  return {
    appId,
    appFindingLocations: [...checkedInstancesArray],
  };
}

export function cleanTicketedFindingsPayload(checkedFindings) {
  const checkedFindingsArray = Object.keys(checkedFindings).map(key => {
    const finding = checkedFindings[key];
    return {
      appFindingId: finding.id,
      appId: finding.appId,
    };
  });
  return {
    appFindings: [...checkedFindingsArray],
  };
}

export function mapFindingsStatsCount(data) {
  return {
    accepted: _.get(
      data.aggregations,
      [
        'filter#finding stats',
        'filters#findings',
        'buckets',
        'accepted findings',
        'doc_count',
      ],
      0
    ),
    closed: _.get(
      data.aggregations,
      [
        'filter#finding stats',
        'filters#findings',
        'buckets',
        'closed findings',
        'doc_count',
      ],
      0
    ),
    total: _.get(data.aggregations, ['filter#finding stats', 'doc_count'], 0),
  };
}

export function mapFindingsGraphStats(data) {
  const reduced = data.reduce(
    ({ labels, values }, val, index) => {
      if (index === 0) {
        // need to account for the first day/bucket not having a label.
        return {
          labels: [
            ...labels,
            Moment(data[1].from)
              .subtract(1, 'days')
              .format('MMM Do'),
          ],
          values: [...values, _.get(val, 'value', 0)],
        };
      }
      return {
        labels: [...labels, Moment(val.from).format('MMM Do')],
        values: [...values, _.get(val, 'value', 0)],
      };
    },
    { labels: [], values: [] }
  );

  return {
    labels: reduced.labels,
    datasets: [
      {
        key: 'critical',
        label: 'Findings',
        data: reduced.values,
      },
    ],
  };
}

// clean the description text

export function descriptionTextHelper(description) {
  return description.replace(/(\\")|(\n)/gim, (g1, g2) => {
    if (g2) {
      return '"';
    }
    if (g1) {
      return '<br />';
    }

    return null;
  });
}

// add finding data to instance data to show parent(finding) info in the instance table expansion row

export function mergeFindingToInstanceTable(
  instanceData,
  { categoryName, subcategoryName, severity }
) {
  return {
    ...instanceData,
    categoryName,
    subcategoryName,
    severity,
  };
}
