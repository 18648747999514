import { default as MUICard } from '@material-ui/core/Card';
import { IzShieldOSType } from 'components/main/zShieldApps/ZShieldApps/models';
import DrawerHeader from 'components/UI/DrawerHeader';
import React from 'react';
import useStyles from './useStyles';

interface IDrawerSkeletonProps {
  onClose: () => void;
  os: IzShieldOSType;
  title: string;
}

const DrawerSkeleton: React.FC<IDrawerSkeletonProps> = ({
  onClose,
  os,
  title,
}) => {
  const classes = useStyles();
  const showHeader = !!os && !!title;

  return (
    <>
      {showHeader && <DrawerHeader os={os} title={title} onClose={onClose} />}
      <MUICard
        classes={{ root: classes.emptyCard }}
        className="is-loading shimmer"
      />
    </>
  );
};

export default DrawerSkeleton;
