import { ISelectRuleContent } from 'components/main/oocSelect/models';
import { atom } from 'recoil';

export const selectedRules = atom<ISelectRuleContent>({
  key: 'appPolicySelectedRules',
  default: {},
});

export const originSelectedRules = atom<ISelectRuleContent>({
  key: 'appPolicyOriginSelectedRules',
  default: {},
});

export const checkedRules = atom<ISelectRuleContent>({
  key: 'appPolicyCheckedRules',
  default: {},
});

export const hasChanges = atom<boolean>({
  key: 'appPolicyHasChanges',
  default: false,
});

export const deployDateAtom = atom<string | Date | number>({
  key: 'policyDeployDate',
  default: '',
});
