import { makeStyles } from '@material-ui/core/styles';
import Button from 'components/UI/Button';
import Select from 'components/UI/input/Select';
import React, { useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { TRMTableActions } from './models';

interface SelectionValue {
  label: string;
  value: TRMTableActions;
}

interface TableActionProps {
  editMode: boolean;
  mtdModule: boolean;
  handleTableAction: (value: TRMTableActions | undefined) => void;
}

const defaultOptions = (t: TFunction<'translation', undefined>) => [
  {
    label: t('MTD.POLICIES.THREAT.SET_CUSTOM_DEVICE_ALERT_MESSAGE'),
    value: TRMTableActions.DeviceAlert,
  },
  {
    label: t('MTD.POLICIES.THREAT.ENABLE_DETECTION_ALL_SELECTED'),
    value: TRMTableActions.EnableDetection,
  },
  {
    label: t('MTD.POLICIES.THREAT.DISABLE_DETECTION_ALL_SELECTED'),
    value: TRMTableActions.DisableDetection,
  },
];

const mtdOptions = (t: TFunction<'translation', undefined>) => [
  {
    label: t('MTD.POLICIES.THREAT.ENABLE_USER_DEVICE_ALERTS'),
    value: TRMTableActions.EnableAlert,
  },
  {
    label: t('MTD.POLICIES.THREAT.SUBSCRIBE_NOTIFICATIONS_ALL_SELECTED'),
    value: TRMTableActions.SubscribeNotifications,
  },
  {
    label: t('MTD.POLICIES.THREAT.UNSUBSCRIBE_NOTIFICATIONS_ALL_SELECTED'),
    value: TRMTableActions.UnsubscribeNotifications,
  },
];

const TableAction = ({
  editMode,
  mtdModule,
  handleTableAction,
}: TableActionProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [options, setOptions] = useState(defaultOptions(t));
  const [selectionValue, setSelectionValue] = useState<SelectionValue>();

  const setFieldValue = (field: string, value: SelectionValue) => {
    setSelectionValue(value);
  };

  if (mtdModule && options.length <= defaultOptions(t).length) {
    setOptions([...defaultOptions(t), ...mtdOptions(t)]);
  }

  return (
    <div className={classes.wrapper}>
      <Select
        multiple={false}
        disabled={!editMode}
        field={{ name: 'tableAction' }}
        disableClearable={false}
        options={options}
        setFieldValue={setFieldValue}
        placeholder={t('GLOBAL.TABLE_ACTIONS')}
        className={classes.select}
      />
      <Button
        onClick={() => handleTableAction(selectionValue?.value)}
        disabled={!selectionValue?.value || !editMode}
        color="secondary"
        text={t('GLOBAL.GO')}
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    width: '30%',
  },
  select: {
    width: '100%',
    marginRight: 16,
  },
}));

export default TableAction;
