import Moment from 'moment';
import { createAction, handleActions } from 'redux-actions';
import { zipReference } from 'utils/componentUtils';

// action types
export const SET_ZDEV_POLICIES = 'SET_ZDEV_POLICIES';
export const SET_ZDEV_APPS = 'SET_ZDEV_APPS';

// selectors
export const getZDevPolicies = state => state.zDevPolicies;
export const getZDevApps = state => state.zDevPoliciesApps;

// actions
export const setZDevPolicies = createAction(SET_ZDEV_POLICIES);
export const setZDevApps = createAction(SET_ZDEV_APPS);

// reducers
const zDevPoliciesInitialState = {};
export const zDevPoliciesReducer = handleActions(
  {
    SET_ZDEV_POLICIES: (state, action = {}) => ({
      ...zipReference(action.payload),
    }),
  },
  zDevPoliciesInitialState
);

const zDevAppInitialState = {
  cached: 0,
};
export const zDevAppsReducer = handleActions(
  {
    SET_ZDEV_APPS: (state, action = {}) => ({
      cached: Moment()
        .add(5, 'm')
        .unix(),
      ...zipReference(action.payload),
    }),
  },
  zDevAppInitialState
);
