import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ config, palette }: any) =>
  createStyles({
    inputError: {
      backgroundColor: palette.background.default,
      border: `1px solid ${config.colors.schoolbus}`,
      color: palette.text.primary,
      display: 'inline-block',
      marginTop: 10,
      padding: '7px 10px',
      whiteSpace: 'pre-wrap',
      width: 'auto',
    },
  })
);

export default useStyles;
