import { ISelectItem } from 'components/UI/input/Select';
import { IConnector } from 'components/main/integrations/emm/models';
import { IMDMRowData } from '../../emmTable/MDM/models';
import { TModalMode } from '../../models';
import { IVMWareFormData } from './models';

export const initialValues = (
  modalMode: TModalMode = 'ADD',
  rowData?: IMDMRowData,
  connector?: IConnector,
  availableTeams?: ISelectItem[],
  selectedTeamId?: string,
  oauthProviders?: ISelectItem[]
): IVMWareFormData => {
  return {
    api_key: '',
    baseUrl: rowData?.baseUrl ?? '',
    connectionId: rowData?.id ?? '',
    connectorTypeId: connector?.id ?? '',
    certificate: rowData?.certificate ?? '',
    custom_token_type:
      !rowData?.auth?.provider_id && rowData?.auth?.oauth_url
        ? 'custom'
        : 'default',
    modalMode,
    mode: connector?.supportedModes?.[0] ?? '',
    auth_method_name: rowData?.auth?.auth_method_name ?? 'basic',
    client_id: rowData?.auth?.client_id ?? '',
    client_secret: '',
    name:
      modalMode === 'CLONE' ? `${rowData?.name} (Cloned)` : rowData?.name ?? '',
    oauth_url: rowData?.auth?.oauth_url ?? '',
    password: '',
    provider: oauthProviders?.filter(
      (provider) => provider.value === rowData?.auth?.provider_id
    )?.[0],
    selectedTeamId: selectedTeamId ?? '',
    team: !!availableTeams?.[0]
      ? availableTeams.find(
          ({ value }) => value === (rowData?.teamId ?? selectedTeamId)
        )
      : undefined,
    user_id: rowData?.auth?.user_id ?? '',
  };
};
