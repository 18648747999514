import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    topFiveThreats: {
      paddingTop: 20,
      paddingLeft: 20,
      justifyContent: 'center',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',

      '& > div.top-five-threats-item': {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '15px',
        textAlign: 'left',
        width: '100%',
      },
    },
    topFiveThreatsCard: {
      maxWidth: 500,
      paddingBottom: 30,
    },
    icon: {
      marginRight: '10px',
    },
    title: {
      fontWeight: 'bold',
      width: '50%',
    },
    pending: {
      fontSize: 48,
      left: '50%',
      opacity: 0.125,
      position: 'absolute',
      transform: 'translateX(-50%) translateY(-50%)',
    },
    emptyTitle: {
      marginBottom: '40px',
    },
    emptyContainer: {
      marginBottom: '50px',
    }

  })
);

export default useStyles;
