import Color from 'color';
import { IGraphData, IGraphOptions } from '../common/models';
import { IColors, IColorStops } from './models';

export const defaultOptions: IGraphOptions = {
  legend: {
    display: false,
  },
  responsive: true,
  scales: {
    x: {
      gridLines: {
        display: false,
      },
    },
    y: {
      ticks: {
        min: 0,
        userCallback(value) {
          if (Math.floor(value as number) === value) {
            return value;
          }
        },
      },
    },
  },
  tooltips: {
    mode: 'index',
    position: 'nearest',
    intersect: false,
  },
  onHover: (event, chartElement) => {
    if (event?.target?.style?.cursor) {
      event.target.style.cursor = chartElement?.[0] ? 'pointer' : 'default';
    }
  },
};

export const addOpacity = (color: string, opacity: number) => {
  return Color(color).alpha(opacity);
};

export const generateColorStops = (colors: IColors) => {
  const colorStops: IColorStops = {};
  Object.keys(colors).forEach((color) => {
    colorStops[color] = {
      dot6: String(addOpacity(colors[color], 0.6)),
      dot4: String(addOpacity(colors[color], 0.4)),
      dot1: String(addOpacity(colors[color], 0.1)),
    };
  });
  return colorStops;
};

export const getGenerateGraphDataFn = (
  colors: IColors,
  colorStops: IColorStops,
  graphData: IGraphData
) => {
  const generateGraphData = (canvas: HTMLCanvasElement): IGraphData => {
    const ctx = canvas.getContext('2d');

    const data = (graphData?.datasets ?? []).map((dataset) => {
      const key = Object(dataset)?.key;
      const gradient = ctx?.createLinearGradient(0, 0, 0, 250);
      if (
        colorStops?.[key]?.dot6 &&
        colorStops?.[key]?.dot4 &&
        colorStops?.[key]?.dot1
      ) {
        gradient?.addColorStop(0, colorStops[key].dot6);
        gradient?.addColorStop(0.5, colorStops[key].dot4);
        gradient?.addColorStop(1, colorStops[key].dot1);
      }

      return {
        ...Object(dataset),
        backgroundColor: !!key ? gradient : undefined,
        borderWidth: 1.5,
        borderColor: colors?.[key],
        pointColor: colors?.[key],
        fill: true,
      };
    });
    return {
      labels: graphData.labels ?? [],
      datasets: data,
    };
  };

  return generateGraphData;
};
