import { TFunction } from 'react-i18next';
import {
  csvInjectionRegex,
  looksGenerallyLikeAnEmailRegex,
} from 'utils/componentUtils';
import * as Yup from 'yup';

const Schema = (t: TFunction<'translation', undefined>) => {
  const csvInjectionErrorMessage = t('GLOBAL.CSV_INJECTION_ERROR');

  return Yup.object().shape({
    firstName: Yup.string()
      .min(1)
      .matches(csvInjectionRegex, csvInjectionErrorMessage)
      .required(t('GLOBAL.REQUIRED')),

    middleName: Yup.string().matches(
      csvInjectionRegex,
      csvInjectionErrorMessage
    ),
    languagePreference: Yup.object()
      .shape({
        value: Yup.string().required(
          t('USER_SETTINGS.ERRORS.LANGUAGE_PREFERENCE_REQUIRED')
        ),
      })
      .typeError(t('USER_SETTINGS.ERRORS.LANGUAGE_PREFERENCE_REQUIRED')),
    lastName: Yup.string()
      .min(1)
      .matches(csvInjectionRegex, csvInjectionErrorMessage)
      .required(t('GLOBAL.REQUIRED')),
    phone: Yup.number().typeError(t('USER_SETTINGS.ERRORS.PHONE_ONLY_NUMBERS')),
    email: Yup.string()
      .matches(
        looksGenerallyLikeAnEmailRegex,
        t('USER_SETTINGS.ERRORS.INVALID_EMAIL')
      )
      .matches(csvInjectionRegex, csvInjectionErrorMessage)
      .required(t('GLOBAL.REQUIRED')),
    notificationEmail: Yup.string()
      .matches(
        looksGenerallyLikeAnEmailRegex,
        t('USER_SETTINGS.ERRORS.INVALID_EMAIL')
      )
      .matches(csvInjectionRegex, csvInjectionErrorMessage)
      .required(t('GLOBAL.REQUIRED')),
  });
};

export default Schema;
