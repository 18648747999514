import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { defaultImages } from '../../initialValues';
import { IPartnerBranding, IPartnerBrandingState } from '../../models';
import ImageCard from './ImageCard';
import useStyles from './useStyles';
import Tabs from 'components/UI/Tabs';

export const getRemoveFieldName = (fieldName: string) =>
  'remove' + (fieldName.charAt(0).toUpperCase() + fieldName.substring(1));

export const getImageBlobMaybe = (
  field: string | File | null | undefined,
  placeholder: string
) =>
  field instanceof File
    ? URL.createObjectURL(field)
    : !!field
    ? (field as string)
    : placeholder;

const imageCards = [
  {
    alt: 'favicon',
    btnRemoveText: 'Click To Remove Favicon',
    height: '60',
    key: 'favicon',
    title: 'Favicon',
    width: '50',
  },
  {
    alt: 'email header image',
    height: '31',
    key: 'emailLogo',
    title: 'Email Header Image',
    width: '40',
  },
];

const imageCardsWithDarkMode = (dark: boolean) => [
  ...(!dark ? imageCards : []),
  {
    alt: 'login logo',
    dark,
    height: '100',
    key: 'loginLogo',
    title: 'Login Page Logo',
    width: '400',
  },
  {
    alt: 'login background',
    dark,
    height: '95',
    key: 'loginBackgroundImage',
    title: 'Login Background Image',
    width: '144',
  },
  {
    alt: 'console logo',
    dark,
    height: '31',
    key: 'consoleLogo',
    title: 'Console Logo',
    width: '40',
  },
];

const ImageForm: React.FC = () => {
  const classes = useStyles();

  const { values, initialValues, isSubmitting, setFieldValue } =
    useFormikContext<IPartnerBranding>();

  const [hostnameAccount, setHostnameAccount] = useState<boolean>(false);

  const partnerUser = useSelector(
    (state: IPartnerBrandingState) => state.partnerUser
  );

  useEffect(
    () => setHostnameAccount(!!partnerUser?.account?.hostname),
    [partnerUser]
  );

  const handleUploadImage = useCallback(
    (fieldName: string, file: File) => {
      setFieldValue(fieldName, file);
    },
    [setFieldValue]
  );

  const handleRemoveImage = useCallback(
    (fieldName: string) => {
      setFieldValue(
        fieldName,
        /\//.test(initialValues[fieldName] as string)
          ? undefined
          : initialValues[fieldName]
      );
    },
    [initialValues, setFieldValue]
  );

  const renderImageCard = useCallback(
    ({
      alt,
      width,
      height,
      key,
      btnRemoveText,
      dark,
      title,
    }: {
      alt: string;
      dark?: boolean | undefined;
      height: string;
      key: string;
      btnRemoveText?: string;
      title: string;
      width: string;
    }) => {
      const safeKey = dark
        ? 'darkMode' + key.charAt(0).toUpperCase() + key.substring(1)
        : key;

      return (
        <ImageCard
          btnRemoveText={btnRemoveText}
          currentImage={
            <img
              className={classes.img}
              src={getImageBlobMaybe(
                values[safeKey] as string,
                defaultImages[safeKey]
              )}
              alt={alt}
              width={width}
              height={height}
            />
          }
          disabled={
            (!hostnameAccount &&
              (key === 'loginLogo' || key === 'loginBackgroundImage')) ||
            isSubmitting
          }
          key={safeKey}
          onImageUpload={(file) => handleUploadImage(safeKey, file)}
          onRemoveImage={() => handleRemoveImage(safeKey)}
          removeDisabled={values[safeKey] === defaultImages[safeKey]}
          title={title}
        />
      );
    },
    [
      classes.img,
      handleRemoveImage,
      handleUploadImage,
      hostnameAccount,
      isSubmitting,
      values,
    ]
  );

  return (
    <>
      <Tabs
        tabs={[
          {
            title: 'Light',
            content: imageCardsWithDarkMode(false).map((imageCard) =>
              renderImageCard(imageCard)
            ),
          },
          {
            title: 'Dark',
            content: imageCardsWithDarkMode(true).map((imageCard) =>
              renderImageCard(imageCard)
            ),
          },
        ]}
      />
    </>
  );
};

export default ImageForm;
