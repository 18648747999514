import MUIDeleteIcon from '@material-ui/icons/Delete';
import cc from 'classcat';
import AndroidIcon from 'components/UI/icons/AndroidIcon';
import AppleIcon from 'components/UI/icons/AppleIcon';
import ChromeOSIcon from 'components/UI/icons/ChromeOSIcon';
import { IColumnHeader } from 'components/UI/Table/models';
import moment from 'moment';
import momentHelper from 'utils/momentUtils';
import { toggleModalDiffered } from 'utils/storeUtils';
import ProtectedComponent from '../protected/ProtectedComponent';
import {
  IActivationStatuses,
  IFullData,
  IOSNames,
  IRiskPostures,
} from './Devices/models';
import { TFunction } from 'react-i18next';

export const defaultDevicesTableHeaderData: IColumnHeader[] = [
  {
    id: 'riskPosture',
    flex: 1,
    sort: true,
    label: 'MTD.DEVICES.RISK_POSTURE',
    idCSV: 'riskPosture',
    resizable: true,
    showInContextMenu: true,
    show: true,
  },
  {
    id: 'zdeviceId',
    flex: 1,
    sort: true,
    label: 'GLOBAL.DEVICE_ID',
    idCSV: 'zdeviceId',
    resizable: true,
    showInContextMenu: true,
    show: true,
  },
  {
    id: 'mdmDeviceId',
    flex: 1,
    sort: true,
    label: 'MTD.DEVICES.EMM_DEVICE_ID',
    idCSV: 'mdmDeviceId',
    resizable: true,
    showInContextMenu: true,
    show: false,
  },
  {
    id: 'mamDeviceId',
    flex: 1,
    sort: true,
    label: 'MTD.DEVICES.MAM_ID',
    idCSV: 'mamDeviceId',
    resizable: true,
    showInContextMenu: true,
    show: false,
  },
  {
    id: 'model',
    flex: 1,
    sort: true,
    label: 'GLOBAL.MODEL',
    idCSV: 'model',
    resizable: true,
    showInContextMenu: true,
    show: true,
  },
  {
    id: 'os.name',
    flex: 1,
    idCSV: 'osName',
    sort: true,
    label: 'GLOBAL.OS',
    resizable: true,
    showInContextMenu: true,
    show: true,
  },
  {
    id: 'os.version',
    flex: 1,
    idCSV: 'osVersion',
    sort: true,
    label: 'GLOBAL.OS_VERSION',
    resizable: true,
    showInContextMenu: true,
    show: false,
  },
  {
    id: 'os.patchDate',
    flex: 1,
    idCSV: 'os.patchDate',
    label: 'GLOBAL.PATCH_DATE',
    resizable: true,
    showInContextMenu: true,
    sort: true,
    show: false,
  },
  {
    id: 'zappInstance.externalTrackingId1',
    flex: 1,
    sort: true,
    idCSV: 'zappInstance.externalTrackingId1',
    label: 'GLOBAL.TRACKING_ID_1',
    resizable: true,
    showInContextMenu: true,
    show: false,
  },
  {
    id: 'zappInstance.externalTrackingId2',
    flex: 1,
    sort: true,
    idCSV: 'zappInstance.externalTrackingId2',
    label: 'GLOBAL.TRACKING_ID_2',
    resizable: true,
    showInContextMenu: true,
    show: false,
  },
  {
    id: 'zappInstance.name',
    flex: 1,
    idCSV: 'zappInstanceName',
    sort: true,
    label: 'MTD.POLICIES.APP_POLICY.APP_NAME',
    resizable: true,
    showInContextMenu: true,
    show: true,
  },
  {
    id: 'zappInstance.version',
    flex: 1,
    idCSV: 'zappInstanceVersion',
    sort: true,
    label: 'GLOBAL.VERSION',
    resizable: true,
    showInContextMenu: true,
    show: true,
  },
  {
    id: 'zappInstance.buildNumber',
    flex: 1,
    idCSV: 'zappInstanceBuildNumber',
    sort: true,
    label: 'GLOBAL.BUILD',
    resizable: true,
    showInContextMenu: true,
    show: false,
  },
  {
    id: 'zappInstance.zversion',
    flex: 1,
    idCSV: 'zappInstanceZVersion',
    sort: true,
    label: 'GLOBAL.Z_DEFEND_VERSION',
    resizable: true,
    showInContextMenu: true,
    show: false,
  },
  {
    id: 'zappInstance.zbuildNumber',
    flex: 1,
    idCSV: 'zappInstanceZBuildNumber',
    sort: true,
    label: 'GLOBAL.Z_DEFEND_BUILD',
    resizable: true,
    showInContextMenu: true,
    show: false,
  },
  {
    id: 'zappInstance.bundleId',
    flex: 1,
    idCSV: 'zappInstanceBundleId',
    sort: true,
    label: 'MTD.POLICIES.APP_POLICY.BUNDLE_ID',
    resizable: true,
    showInContextMenu: true,
    show: true,
  },
  {
    id: 'teamName',
    flex: 1,
    sort: false,
    label: 'GLOBAL.TEAM_NAME',
    idCSV: 'teamName',
    resizable: true,
    showInContextMenu: true,
    show: false,
  },
  {
    id: 'groupName',
    flex: 1,
    sort: false,
    label: 'GLOBAL.GROUP_NAME',
    idCSV: 'groupName',
    resizable: true,
    showInContextMenu: true,
    show: true,
  },
  {
    id: 'activationName',
    flex: 1,
    idCSV: 'activationName',
    label: 'MTD.DEVICES.ACTIVATION_EMM_NAME',
    resizable: true,
    showInContextMenu: true,
    sort: true,
    show: false,
  },
  {
    id: 'deviceOwner',
    flex: 1,
    idCSV: 'deviceOwner',
    label: 'GLOBAL.DEVICE_OWNER',
    resizable: true,
    showInContextMenu: true,
    sort: true,
    show: false,
  },
  {
    id: 'appStatus',
    flex: 1,
    idCSV: 'appStatus',
    label: 'GLOBAL.APP_STATUS',
    resizable: true,
    showInContextMenu: true,
    sort: true,
    show: false,
  },
  {
    id: 'created',
    flex: 1,
    sort: true,
    label: 'GLOBAL.CREATED',
    idCSV: 'created',
    resizable: true,
    showInContextMenu: true,
    show: false,
  },
  {
    id: 'lastSeen',
    flex: 1,
    sort: true,
    label: 'GLOBAL.LAST_SEEN',
    idCSV: 'lastSeen',
    resizable: true,
    showInContextMenu: true,
    show: true,
    minWidth: 200,
  },
  {
    id: 'actions',
    flex: 1,
    sort: false,
    label: 'GLOBAL.ACTIONS',
    resizable: true,
    show: true,
  },
];

export const RISK_POSTURES: IRiskPostures = {
  0: { name: 'GLOBAL.NORMAL', className: 'normal' },
  1: { name: 'GLOBAL.LOW', className: 'low' },
  2: { name: 'GLOBAL.ELEVATED', className: 'elevated' },
  3: { name: 'GLOBAL.CRITICAL', className: 'critical' },
};

export const ACTIVATION_STATUSES: IActivationStatuses = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  PENDING_ACTIVATION: 'Pending Activation',
};

export const OS_NAMES: IOSNames = {
  ios: 'iOS',
  android: 'Android',
  'chrome os': 'Chrome OS',
  chrome_os: 'Chrome OS', // having to defend against mislabeled backend OS names
};

export const defaultDevicesTableHeaders = (module: string) => {
  if (module === 'MTD') {
    return defaultDevicesTableHeaderData.map((item: IColumnHeader) => {
      if (item.id === 'mdmDeviceId') {
        return { ...item, show: true };
      }
      return item;
    });
  }

  return defaultDevicesTableHeaderData
    .filter((item) => item.id !== 'deviceOwner')
    .map((item) => {
      if (item.id === 'activationName') {
        return {
          ...item,
          label: 'Activation Name',
        };
      }
      return item;
    });
};

export const deviceColumnMapping = (t: TFunction<'translation', undefined>) => [
  {
    path: 'riskPosture',
    columnContent: (fullData: IFullData) => {
      const riskPosture = fullData?.rowData?.riskPosture;
      const colorClassName =
        typeof riskPosture === 'number'
          ? RISK_POSTURES?.[riskPosture]?.className ?? ''
          : '';

      const colorClass = fullData?.classList?.[colorClassName] ?? '';

      return (
        <div className="table__os-icon flex--spaced">
          <div
            className={cc([fullData?.classList?.statusDot ?? '', colorClass])}
          />
          <div className="table-cell__ellipsis">
            {typeof riskPosture === 'number'
              ? t(RISK_POSTURES?.[riskPosture]?.name) ?? ''
              : ''}
          </div>
        </div>
      );
    },
  },
  {
    path: 'zdeviceId',
    columnContent: (fullData: IFullData) => {
      return (
        <span className="table-cell__ellipsis">
          {fullData?.rowData?.zdeviceId ?? ''}
        </span>
      );
    },
  },
  {
    path: 'mdmDeviceId',
    columnContent: (fullData: IFullData) => {
      return (
        <span className="table-cell__ellipsis">
          {fullData?.rowData?.mdmDeviceId ?? ''}
        </span>
      );
    },
  },
  {
    path: 'mamDeviceId',
    columnContent: (fullData: IFullData) => {
      return (
        <span className="table-cell__ellipsis">
          {fullData?.rowData?.mamDeviceId ?? ''}
        </span>
      );
    },
  },
  {
    path: 'model',
    columnContent: ({ rowData }: IFullData) => {
      return rowData?.model &&
        rowData?.iPhoneModelMap &&
        Object.keys(rowData.iPhoneModelMap).includes(rowData?.model)
        ? rowData.iPhoneModelMap[rowData.model]?.commercialName ??
            rowData?.model
        : rowData?.model;
    },
  },
  {
    path: 'os.name',
    columnContent: (fullData: IFullData) => {
      if (!fullData?.rowData?.os) {
        return <span className={fullData?.classList?.osCell ?? ''} />;
      }
      const osLowerCaseName = fullData?.rowData?.os?.name?.toLowerCase() ?? '';
      const osName = OS_NAMES?.[osLowerCaseName] ?? '';
      const icon =
        osLowerCaseName === 'ios' ? (
          <AppleIcon />
        ) : ['chrome os', 'chrome_os'].includes(osLowerCaseName) ? (
          <div className="table__os-icon">
            {' '}
            <ChromeOSIcon />
          </div>
        ) : (
          <AndroidIcon />
        );

      return (
        <div className="table__os-icon flex--spaced">
          {icon}
          <div className="table-cell__ellipsis" style={{ paddingLeft: '5px' }}>
            {osName}
          </div>
        </div>
      );
    },
  },
  {
    path: 'os.version',
    columnContent: (fullData: IFullData) => (
      <span className="table-cell__ellipsis">
        {fullData?.rowData?.os?.version ?? ''}
      </span>
    ),
  },
  {
    path: 'os.patchDate',
    columnContent: (fullData: IFullData) =>
      fullData?.rowData?.os?.patchDate
        ? moment(fullData.rowData.os.patchDate).format('l')
        : undefined,
    csvContent: (fullData: IFullData) =>
      fullData?.rowData?.os?.patchDate
        ? moment(fullData.rowData.os.patchDate).format('l')
        : undefined,
  },
  {
    path: 'zappInstance.externalTrackingId1',
    columnContent: (fullData: IFullData) => (
      <span className="table-cell__ellipsis">
        {fullData?.rowData?.zappInstance?.[0]
          ? fullData.rowData.zappInstance
              .map(({ externalTrackingId1 }) => externalTrackingId1)
              .join(', ')
          : ''}
      </span>
    ),
  },
  {
    path: 'zappInstance.externalTrackingId2',
    columnContent: (fullData: IFullData) => (
      <span className="table-cell__ellipsis">
        {fullData?.rowData?.zappInstance?.[0]
          ? fullData.rowData.zappInstance
              .map(({ externalTrackingId2 }) => externalTrackingId2)
              .join(', ')
          : ''}
      </span>
    ),
  },
  {
    path: 'zappInstance.name',
    columnContent: (fullData: IFullData) => (
      <span className="table-cell__ellipsis">
        {fullData?.rowData?.zappInstance?.[0]
          ? fullData.rowData.zappInstance.map(({ name }) => name).join(', ')
          : ''}
      </span>
    ),
  },
  {
    path: 'zappInstance.version',
    columnContent: (fullData: IFullData) => (
      <span className="table-cell__ellipsis">
        {fullData?.rowData?.zappInstance?.[0]
          ? fullData.rowData.zappInstance
              .map(({ version }) => version)
              .join(', ')
          : ''}
      </span>
    ),
  },
  {
    path: 'zappInstance.buildNumber',
    columnContent: (fullData: IFullData) => (
      <span className="table-cell__ellipsis">
        {fullData?.rowData?.zappInstance?.[0]
          ? fullData.rowData.zappInstance
              .map(({ buildNumber }) => buildNumber)
              .join(', ')
          : ''}
      </span>
    ),
  },
  {
    path: 'zappInstance.zversion',
    columnContent: (fullData: IFullData) => (
      <span className="table-cell__ellipsis">
        {fullData?.rowData?.zappInstance?.[0]
          ? fullData.rowData.zappInstance
              .map(({ zversion }) => zversion)
              .join(', ')
          : ''}
      </span>
    ),
  },
  {
    path: 'zappInstance.zbuildNumber',
    columnContent: (fullData: IFullData) => (
      <span className="table-cell__ellipsis">
        {fullData?.rowData?.zappInstance?.[0]
          ? fullData.rowData.zappInstance
              .map(({ zbuildNumber }) => zbuildNumber)
              .join(', ')
          : ''}
      </span>
    ),
  },
  {
    path: 'zappInstance.bundleId',
    columnContent: (fullData: IFullData) => (
      <span className="table-cell__ellipsis">
        {fullData?.rowData?.zappInstance?.[0]
          ? fullData.rowData.zappInstance
              .map(({ bundleId }) => bundleId)
              .join(', ')
          : ''}
      </span>
    ),
  },
  {
    path: 'teamName',
  },
  {
    path: 'groupName',
  },
  {
    path: 'activationName',
    columnContent: (fullData: IFullData) => (
      <span className="table-cell__ellipsis">
        {fullData?.rowData?.activationName ?? ''}
      </span>
    ),
  },
  {
    path: 'deviceOwner',
    columnContent: (fullData: IFullData) => (
      <span className="table-cell__ellipsis">
        {fullData?.rowData?.deviceOwner?.email ?? 'N/A'}
      </span>
    ),
  },
  {
    path: 'appStatus',
    columnContent: (fullData: IFullData) => (
      <span className="table-cell__ellipsis">
        {Object.keys(ACTIVATION_STATUSES).includes(
          fullData?.rowData?.appStatus ?? ''
        )
          ? ACTIVATION_STATUSES[fullData.rowData.appStatus]
          : fullData?.rowData?.appStatus ?? ''}
      </span>
    ),
  },
  {
    path: 'detectionStatus',
    columnContent: (fullData: IFullData) => (
      <span className="table-cell__ellipsis">
        {Object.keys(ACTIVATION_STATUSES).includes(
          fullData?.rowData?.detectionStatus
        )
          ? ACTIVATION_STATUSES[fullData.rowData.detectionStatus]
          : fullData?.rowData?.detectionStatus ?? ''}
      </span>
    ),
  },
  {
    path: 'created',
    columnContent: (fullData: IFullData) =>
      fullData?.rowData?.created
        ? momentHelper(fullData.rowData.created)
        : null,
    csvContent: (fullData: IFullData) =>
      fullData?.rowData?.created
        ? momentHelper(fullData.rowData.created)
        : null,
  },
  {
    path: 'lastSeen',
    columnContent: (fullData: IFullData) =>
      fullData?.rowData?.lastSeen
        ? momentHelper(fullData.rowData.lastSeen)
        : null,
    csvContent: (fullData: IFullData) =>
      momentHelper(fullData.rowData.lastSeen),
  },
  {
    path: 'actions',
    columnContent: (fullData: IFullData) => {
      return (
        <>
          <ProtectedComponent allow={{ devices: 'manage' }}>
            <div id="action-column">
              <MUIDeleteIcon
                id="delete-device-action"
                className="cursor--pointer"
                onClick={toggleModalDiffered(
                  'DeviceDelete',
                  fullData?.rowData,
                  {
                    title: 'MTD.DEVICES.REMOVE_DEVICE',
                    fullWidth: true,
                  }
                )}
              />
            </div>
          </ProtectedComponent>
        </>
      );
    },
  },
  {
    path: 'dd',
  },
];
