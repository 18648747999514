import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: '25px',
      paddingBottom: '25px',
    },
    modal: {
      '& .MuiDialog-container > div': {
        maxWidth: '1500px',
        maxHeight: '900px',
        width: '1500px',
        height: '900px',
      },
    },
    globalIndWrapper: {
      paddingBottom: 10,
    },
    note: {
      marginTop: '25px',
      fontSize: '15px',
    },
    table: {
      '& td:nth-child(1)': {
        width: '190px',
        minWidth: '190px',
        maxWidth: '190px',
      },
      '& td:nth-child(3)': {
        width: '120px',
        minWidth: '120px',
        maxWidth: '120px',
      },
    },
    selectStyle: {
      '& ::placeholder': {
        color: palette.text.secondary,
        opacity: 1,
      },
    },
    appNameHeader: {
      justifyContent: 'center',
    },
    sidebarHeader: {
      backgroundColor: palette.tables.header.background,
      color: palette.text.secondary,
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0px 10px 0px 10px',
    },
    header: {
      backgroundColor: palette.tables.header.background,
      color: palette.text.secondary,
      display: 'flex',
      justifyContent: 'center',
      padding: '0px 10px 0px 10px',
    },
    accordion: {
      boxShadow: 'none',
      color: palette.text.secondary,
    },
    accordionHeader: {
      backgroundColor: palette.tables.header.background,
      border: `1px solid ${palette.tables.border}`,
      color: palette.text.secondary,
      minHeight: 'none',
      '& .MuiAccordionSummary-expandIcon': {
        '&.Mui-expanded': {
          transform: 'rotate(90deg)',
        },
      },
      '& svg': {
        width: 14,
      },
    },
    cardContent: {
      overflow: 'scroll',
      width: '440px',
      height: '219px',
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
    circleStyle: {
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
      flexWrap: 'wrap',
      padding: '1rem',

      textAlign: 'center',
      '&>div>div': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: palette.text.secondary,
        fontSize: '12px',
        fontWeight: 500,
        gap: '10px',
      },
      '&>div>div>span': {
        color: palette.text.secondary,
        fontSize: '12px',
        fontWeight: 500,
      },
      '&>div': {
        display: 'flex',
        justifyContent: 'space-evenly',
      },
    },
    showFindingsStyle: {
      color: palette.text.secondary,
    },
    findingDetailsContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      padding: '16px',
    },
    detailsSubContianer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    detailsDescriptions: {
      display: 'flex',
      fontSize: 14,
      flexDirection: 'column',
      gap: '20px',
      '& div span': { color: palette.text.secondary },
      '& div p': { fontSize: 13, lineHeight: 1.5 },
    },
    formatDetails: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      fontWeight: 'bold',
    },
    formatDetailValues: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
    details: {
      display: 'flex',
      fontSize: 12,
      padding: 15,
    },
    detailsTable: {
      fontSize: 14,
      alignSelf: 'baseline',
      marginBottom: '10px',
    },
    detailsTableWithPadding: {
      '& tr': {
        '& td:first-child': {
          paddingRight: 15,
          color: palette.text.secondary,
        },
      },
    },
    activeRowIndicator: {
      border: '2px solid #8e8e8e',
      backgroundColor: '#747c80',
      opacity: 0.1,
    },
    radioGroup: {
      height: '175px',
      flexWrap: 'wrap',
      margin: '10px',
      display: 'flex',
      maxWidth: 'fit-content',
      flexDirection: 'column',
      '& label': {
        maxWidth: 'fit-content',
        paddingRight: 0,
      },
    },
  })
);

export default useStyles;
