import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import DateFilter from '@inovua/reactdatagrid-enterprise/DateFilter';
import '@inovua/reactdatagrid-enterprise/index.css';
import NumberFilter from '@inovua/reactdatagrid-enterprise/NumberFilter';
import SelectFilter from '@inovua/reactdatagrid-enterprise/SelectFilter';
import {
  TypeColumn,
  TypeDataSource,
} from '@inovua/reactdatagrid-enterprise/types';
import { default as MUICard } from '@material-ui/core/Card';
import { default as MUICardContent } from '@material-ui/core/CardContent';
import Checkbox from 'components/UI/input/Checkbox';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  IColumnHeader,
  IFilterEditor,
  ILocationQuery,
  ITableRowMapping,
  TClassList,
  TTypeColumnWithMenu,
} from './models';
import useStyles from './useStyles';
import getLastVisibleColumnIndex from './utils/getLastVisibleColumnIndex';
import getNumVisibleColumns from './utils/getNumVisibleColumns';
import tableQueryToLocationQuery from './utils/tableQueryToLocationQuery';
import { useTranslation } from 'react-i18next';
import geti18nValues from './utils/geti18nValues';

// Default Row Height -- Parameterizing this for later thought.
// Providing a row height greatly speeds up the virtualized row rendering
// with Inovua React-Data-Grid
const ROW_HEIGHT = 35;

// Future filter editor implementations will be referenced here.
const FILTER_EDITORS: { [key: string]: any } = {
  SelectFilter,
  NumberFilter,
  DateFilter,
};

export interface ITableProps {
  classList?: TClassList;
  checkboxColumn?: boolean;
  columnHeaders: IColumnHeader[];
  defaultActiveIndex?: number;
  activeIndex?: number;
  onActiveIndexChange?: any;
  definedUser?: string;
  fetchTableData: () => TypeDataSource | TypeDataSource;
  filters?: IFilterEditor[];
  footerComponents?: () => JSX.Element;
  height?: number | string;
  heightBuffer?: number;
  isSuperUser?: boolean;
  maxHeight?: number | string;
  miscHandler?: (args: any) => void;
  minHeight?: number | string;
  multiRowExpand?: boolean;
  omitTableColumns?: string[];
  onColumnChange?: (args: any) => void;
  onFilterValueChange?: () => void;
  onPaginationChange?: (...args: any) => void;
  onRowClick?: (rowProps: any, event: any) => void;
  onSelectionChange?: (args: any) => void;
  onSortInfoChange?: (args: any) => void;
  pagination?: boolean;
  query: ILocationQuery;
  rowDetails?: (n: Object) => JSX.Element;
  rowExpandHeight?: number;
  rowMapping: ITableRowMapping[];
  showLastColumnMenuTool?: boolean;
  selected?: any;
  serverSort?: boolean;
  superUser?: string[];
  tableId: string;
  activeRowStyle?: any;
  rowHeight?: number;
}

const Table: React.FC<ITableProps> = ({
  classList,
  checkboxColumn,
  columnHeaders,
  defaultActiveIndex,
  activeIndex,
  onActiveIndexChange,
  definedUser,
  fetchTableData,
  filters = [],
  footerComponents = () => <></>,
  height = '100vh',
  heightBuffer = 220,
  isSuperUser,
  maxHeight,
  minHeight = 250,
  omitTableColumns = [],
  onColumnChange,
  onFilterValueChange = () => {},
  onPaginationChange,
  onRowClick,
  onSelectionChange,
  onSortInfoChange,
  pagination = true,
  query,
  query: { page: ready },
  rowDetails,
  rowExpandHeight = 130,
  rowMapping,
  showLastColumnMenuTool = true,
  selected,
  serverSort,
  superUser,
  tableId,
  activeRowStyle,
  rowHeight,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const i18n = useMemo(
    () => geti18nValues(ReactDataGrid.defaultProps.i18n, t),
    [t]
  );

  // Used to calculate table min height.
  const [reservedViewportWidth, setReservedViewportWidth] = useState<
    number | null
  >(null);

  // Stores Row Details Expanded State.
  const [expandedRows, setExpandedRows] = useState<{
    [key: string | number]: boolean;
  }>({
    1: true,
    2: true,
  });

  const [collapsedRows, setCollapsedRows] = useState<
    { [key: string]: boolean } | undefined
  >(undefined);

  const onExpandedRowsChange = useCallback(
    ({ expandedRows, collapsedRows }) => {
      setExpandedRows(expandedRows);
      setCollapsedRows(collapsedRows);
    },
    []
  );

  const handleReservedViewportWidthChange = (newWidth: number) =>
    setReservedViewportWidth(newWidth < 0 ? newWidth : null);

  // FUNC as ANY with spread used below to prevent typescript
  // from complaining about the variable parameter size. This is
  // required since we do not know the definition of the method
  // that is passed in with any given use-case.
  const handleColumnChange = (show: unknown, ...args: any) => {
    if (show || (!show && getNumVisibleColumns(columnDefs) > 1)) {
      onColumnChange && (onColumnChange as any)(show, ...args);
    }
  };

  // FUNC as ANY with spread used below to prevent typescript
  // from complaining about the variable parameter size. This is
  // required since we do not know the definition of the method
  // that is passed in with any given use-case.
  const handlePaginationChange = (...args: any) =>
    onPaginationChange &&
    (onPaginationChange as any)(
      (tableQueryToLocationQuery as any)(...args, query.size)
    );

  const handleSelectionChange = useCallback(
    ({ data, selected }) => {
      if (!!onSelectionChange) {
        if (selected === true && Array.isArray(data)) {
          // all selected
          const selectedObj = data.reduce(
            (item: any, current: any) => ({
              ...item,
              [current.id]: current,
            }),
            {}
          );
          onSelectionChange(selectedObj);
        } else {
          onSelectionChange(selected);
        }
      }
    },
    [onSelectionChange]
  );

  const toggleDetails = (id: string | number) => {
    const newExpandedRows: any = {};

    if (!expandedRows[id]) {
      newExpandedRows[id] = true;
    }

    setExpandedRows(newExpandedRows);
  };

  // FUNC as ANY with spread used below to prevent typescript
  // from complaining about the variable parameter size. This is
  // required since we do not know the definition of the method
  // that is passed in with any given use-case.
  const cellRenderers: { [key: string]: any } = rowMapping.reduce(
    (a, c) => ({
      ...a,
      [c.path]: ({ data: rowData }: { data: any }) => (
        <div className={classes.tableCell}>
          {typeof c.columnContent === 'function'
            ? (c.columnContent as any)({
                classes,
                classList,
                definedUser,
                omitTableColumns,
                rowData,
                superUser,
                tableId,
                isShowingDetails: expandedRows[rowData.id],
                toggleDetails,
                ...rest,
              })
            : rowData[c.path]}
        </div>
      ),
    }),
    {}
  );

  useEffect(() => {
    window.addEventListener(
      `table:force-fetch-${tableId}`,
      fetchTableData as unknown as EventListenerOrEventListenerObject
    );

    return () => {
      window.removeEventListener(
        `table:force-fetch-${tableId}`,
        fetchTableData as unknown as EventListenerOrEventListenerObject
      );
    };
  }, [fetchTableData, tableId]);

  const handleRowClick = useCallback(
    (rowProps, event) => {
      if (event?.target?.closest) {
        if (event.target.closest('#action-column')) {
          return;
        }
        if (event.target.closest('a')) {
          return;
        } // if there is a link, disable row clicking
      }
      if (!onRowClick) {
        return;
      }
      onRowClick(rowProps, event);
    },
    [onRowClick]
  );

  const columnDefs: TypeColumn[] = _.differenceBy(
    columnHeaders,
    omitTableColumns,
    'id'
  )
    .filter(({ id }) => !(id === 'favorite' && isSuperUser))
    .map(
      ({
        flex,
        id,
        label,
        maxWidth,
        draggable = false,
        filterEditor,
        filterEditorProps = {},
        hideable = true,
        minWidth = 0,
        resizable = false,
        show,
        showColumnMenuTool = false,
        sort,
        defaultLocked = false,
      }) => ({
        visible: show,
        draggable,
        defaultFlex: flex,
        filterEditor: filterEditor && FILTER_EDITORS[filterEditor],
        filterEditorProps: {
          ...filterEditorProps,
          ..._.get(
            filters.filter(({ name }) => name === id),
            ['0', 'filterEditorProps'],
            {}
          ),
        },
        header: t(label),
        hideable,
        maxWidth,
        minWidth,
        name: id,
        render: id && cellRenderers[id],
        resizable,
        showColumnMenuTool,
        sortable: !!sort,
        sort: serverSort ? (a: any) => a : undefined,
        defaultLocked,
      })
    );

  // Prevent row details/expand icon column from being hidden.
  const lastVisibleColumnIndex = getLastVisibleColumnIndex(columnDefs);

  columnDefs[lastVisibleColumnIndex] = {
    ...columnDefs[lastVisibleColumnIndex],
    hideable: false,
    showColumnMenuTool: showLastColumnMenuTool,
    showColumnMenuToolOnHover: false,
    showColumnMenuLockOptions: false,
    showColumnMenuSortOptions: false,
    showColumnMenuGroupOptions: false,
    showColumnMenuFilterOptions: false,
  } as TTypeColumnWithMenu;

  // Passing props that are not a part of the enterprise definitions but
  // exist on community (which enterprise extends; however, the typing is broken)
  // using rest spread to get past the typescript errors.
  const restProps = {
    reservedViewportWidth: reservedViewportWidth,
    onReservedViewportWidthChange: handleReservedViewportWidthChange,
  };

  return (
    <MUICard className={classes.card}>
      <MUICardContent
        className={classes.cardContent}
        style={{ minHeight: 'auto' }}
      >
        {!!ready && (
          <ReactDataGrid
            allowUnsort={false}
            checkboxColumn={!!checkboxColumn}
            checkboxOnlyRowSelect
            collapsedRows={collapsedRows}
            columns={columnDefs}
            className={classes.table}
            dataSource={fetchTableData as TypeDataSource}
            defaultLimit={parseInt(query.size as string) || 25}
            defaultSkip={
              parseInt(query.page as string) * parseInt(query.size as string)
            }
            defaultSortInfo={{
              name: query.orderBy,
              dir: query.order === 'desc' ? -1 : 1,
            }}
            expandedRows={expandedRows}
            emptyText={t('GLOBAL.NO_RECORDS_AVAILABLE')}
            defaultFilterValue={filters}
            i18n={i18n}
            defaultActiveIndex={defaultActiveIndex}
            activeIndex={activeIndex}
            onActiveIndexChange={onActiveIndexChange}
            licenseKey={process.env.REACT_APP_RDG_KEY}
            loadingText={t('GLOBAL.LOADING')}
            multiRowExpand={false}
            onExpandedRowsChange={onExpandedRowsChange as () => boolean}
            onFilterValueChange={onFilterValueChange}
            onLimitChange={(update) => handlePaginationChange('limit', update)}
            onSelectionChange={handleSelectionChange}
            onSkipChange={(update) => handlePaginationChange('skip', update)}
            onSortInfoChange={(update) =>
              onSortInfoChange
                ? (onSortInfoChange as any)(update)
                : handlePaginationChange('sort', update)
            }
            pageSizes={[25, 50, 100]}
            pagination={pagination}
            renderColumnContextMenu={(menuProps) => {
              menuProps.items = columnHeaders
                .map(({ label, show, showInContextMenu }, index) => ({
                  label: (
                    <div className={classes.contextMenuItem}>
                      {' '}
                      <Checkbox
                        checked={show}
                        color="primary"
                        label={t(label)}
                      />
                    </div>
                  ),
                  onClick: () =>
                    handleColumnChange(!show, index, columnHeaders),
                  showInContextMenu,
                }))
                .filter(({ showInContextMenu }) => showInContextMenu);

              return undefined;
            }}
            renderRowDetails={
              rowDetails ? ({ data }) => rowDetails(data) : undefined
            }
            rowExpandColumn={false}
            rowExpandHeight={rowExpandHeight}
            rowHeight={rowHeight ?? ROW_HEIGHT}
            onRowClick={handleRowClick}
            selected={selected}
            scrollProps={{
              autoHide: false,
            }}
            activeRowIndicatorClassName={activeRowStyle}
            shareSpaceOnResize
            showCellBorders="horizontal"
            showHoverRows={false}
            skip={
              parseInt(query.page as string) * parseInt(query.size as string)
            }
            sortable
            style={{
              maxHeight: maxHeight as string,
              minHeight,
              height:
                typeof height === 'string'
                  ? `calc(${height} - ${heightBuffer}px)`
                  : height,
              verticalAlign: 'middle',
              margin: '16px 16px 40px 16px',
              width: '100%',
            }}
            {...restProps}
          />
        )}
        <div className={classes.footer}>{footerComponents()}</div>
      </MUICardContent>
    </MUICard>
  );
};

export default Table;
