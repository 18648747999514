import { TFunction } from 'react-i18next';
import {
  csvInjectionErrorMessageLocalized,
  csvInjectionRegex,
} from 'utils/componentUtils';
import * as Yup from 'yup';
import { IActiveModalTrigger } from '../models';
import useAppCharacteristics from '../../../AppCharacteristicProvider';

const Schema = (
  t: TFunction<'translation', undefined>,
  ruleData?: IActiveModalTrigger
) => {
  const { fullTriggerRules } = useAppCharacteristics();
  if (!fullTriggerRules) {
    return;
  }
  const ruleNames =
    ruleData?.action !== 'edit'
      ? fullTriggerRules.map((e) => e.name.toLowerCase().trim())
      : fullTriggerRules
          .map((e) => e.name.toLowerCase().trim())
          .filter((e) => e !== ruleData?.name?.toLowerCase().trim());

  const fields = {
    name: Yup.string()
      .min(1)
      .matches(csvInjectionRegex, t(csvInjectionErrorMessageLocalized))
      .notOneOf(ruleNames, t('MTD.VALIDATIONS.NAME_ALREADY_EXISTS'))
      .required(t('MTD.VALIDATIONS.NAME_IS_REQUIRED'))
      .transform((value) => value.toLowerCase()),
    description: Yup.string(),
  };

  return Yup.object().shape({ ...fields });
};

export default Schema;
