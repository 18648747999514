export const defaultPlansTableHeaders = [
  {
    id: 'name',
    numeric: false,
    label: 'Name',
    show: true,
    path: 'name',
    sort: true,
  },
  {
    id: 'subscriptionCount',
    numeric: false,
    label: 'Accounts Linked',
    show: true,
    path: 'subscriptionCount',
    sort: true,
  },
  {
    id: 'description',
    numeric: false,
    label: 'Description',
    show: true,
    path: 'description',
    sort: true,
  },
  {
    id: 'modified',
    numeric: false,
    label: 'Modified',
    show: true,
    path: 'modified',
    sort: true,
  },
  {
    id: 'created',
    numeric: false,
    label: 'Created',
    path: 'created',
    show: true,
    sort: true,
  },
  {
    id: 'modifiedBy',
    numeric: false,
    label: 'Modified By',
    path: 'modifiedBy',
    show: true,
    sort: true,
  },
  {
    id: 'createdBy',
    numeric: false,
    label: 'Created By',
    path: 'createdBy',
    show: true,
    sort: true,
  },
  {
    id: 'actions',
    numeric: false,
    label: 'Actions',
    sortable: false,
    show: true,
    sort: false,
  },
];
