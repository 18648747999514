import cc from 'classcat';
import useStyles from './useStyles';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core';

interface ICircleOutlineIcon {
  noBackground?: boolean;
  mutedIconColor?: boolean;
  borderColor?: 'blue' | 'darkGreen' | 'green' | 'orange' | 'red';
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> | React.FC;
}

const CircleOutlineIcon: React.FC<ICircleOutlineIcon> = ({
  noBackground,
  borderColor,
  Icon,
  mutedIconColor = false,
}) => {
  const classes = useStyles();

  return (
    <div
      className={cc([
        classes.root,
        {
          [classes.noBackground]: noBackground,
          [classes.whiteIcon]: !mutedIconColor,
          [classes.grayIcon]: mutedIconColor,
          [classes.blueBorder]: borderColor === 'blue',
          [classes.darkGreenBorder]: borderColor === 'darkGreen',
          [classes.greenBorder]: borderColor === 'green',
          [classes.orangeBorder]: borderColor === 'orange',
          [classes.redBorder]: borderColor === 'red',
        },
      ])}
    >
      <Icon className={classes.icon} />
    </div>
  );
};

export default CircleOutlineIcon;
