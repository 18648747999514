import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

export default state => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      managedAccounts: {
        ...uiSettingsDefaults.managedAccounts,
        tableHeaders: uiSettingsDefaults.managedAccounts.tableHeaders,
      },
      partnerUsers: {
        ...uiSettingsDefaults.partnerUsers,
        tableHeaders: uiSettingsDefaults.partnerUsers.tableHeaders,
      },
      partnerUsersForPartnerUI: {
        ...uiSettingsDefaults.partnerUsers,
        tableHeaders: uiSettingsDefaults.partnerUsersForPartnerUI.tableHeaders,
      },
      teams: {
        ...uiSettingsDefaults.teams,
        tableHeaders: uiSettingsDefaults.teams.tableHeaders,
      },
      zdevPolicies: {
        ...uiSettingsDefaults.zdevPolicies,
        tableHeaders: uiSettingsDefaults.zdevPolicies.tableHeaders,
      },
      trmPolicy: {
        ...uiSettingsDefaults.trmPolicy,
        tableHeaders: uiSettingsDefaults.trmPolicy.tableHeaders,
      },
      trmPolicyMtd: {
        ...uiSettingsDefaults.trmPolicyMtd,
        tableHeaders: uiSettingsDefaults.trmPolicyMtd.tableHeaders,
      },
    },
  };
};
