import { default as MUITooltip } from '@material-ui/core/Tooltip';
import { getDownloadReports } from 'components/main/appsInventory/AppsInventoryTab/utils';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import React, { useMemo } from 'react';
import useStyles from './useStyles';
import { useTranslation } from 'react-i18next';

export interface IGenericTooltipProps {
  children: React.ReactNode;
  linkItems: {
    to: string;
    text: string;
    fileName: string;
  }[];
}

const GenericTooltipList: React.FC<IGenericTooltipProps> = ({
  children,
  linkItems,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const titleContent = useMemo(
    () =>
      linkItems.map((lnk) => {
        return (
          <li className={classes.li}>
            <ProtectedComponent
              allow={{ devices: 'view' }}
              componentIfNotAllowed={children}
            >
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  getDownloadReports(lnk.to, lnk.fileName, t);
                }}
              >
                {lnk.text}
              </a>
            </ProtectedComponent>
          </li>
        );
      }),
    [children, classes?.li, linkItems, t]
  );

  return (
    <MUITooltip
      className={classes.genericTooltip}
      title={<ul className={classes.list}>{titleContent}</ul>}
      PopperProps={{
        style: { marginTop: -60, marginRight: 70 },
      }}
      interactive
    >
      <div style={{ display: 'inline-block' }}>{children}</div>
    </MUITooltip>
  );
};

export default GenericTooltipList;