import { getActiveModalAtom } from 'atoms/modals';
import Modal from 'components/UI/Modal';
import Button from 'components/UI/Button';
import { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import useAsyncResult from 'utils/useAsyncResult';
import { AddEditCloneEMMAuth_TITLE } from '../AddEditCloneEMMAuth';
import { IAddEditCloneEMMAuth } from '../AddEditCloneEMMAuth/models';
import { IConnector } from '../models';
import { ISelectProvider } from './models';
import useStyles from './useStyles';
import { fetchMDMProviders } from './utils';
import HorizontalLinearStepper from './stepper';
import { useTranslation } from 'react-i18next';

export const SelectProvider_TITLE = 'SelectProvider';

const activeModalAtom = getActiveModalAtom<
  ISelectProvider | IAddEditCloneEMMAuth
>();

const handleOnLoad = () => {
  const lazyloadImages = document.querySelectorAll('.is-loading');
  lazyloadImages.forEach((img) => {
    img.classList.remove('is-loading', 'shimmer');
  });
};

const SelectProvider: React.FC = () => {
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const [selectConnector, setSelectConnector] = useState<IConnector>();
  const setActiveModal = useSetRecoilState(activeModalAtom);
  const providers: IConnector[] = useAsyncResult(fetchMDMProviders);

  const handleSelect = useCallback(
    (connector?: IConnector) => {
      setActiveModal({
        active: AddEditCloneEMMAuth_TITLE,
        payload: { connector, mode: 'ADD' },
      });
    },
    [setActiveModal]
  );
  const handleCancelClick = useCallback(() => {
    setActiveModal({
      active: undefined,
    });
  }, [setActiveModal]);

  const handleClose = useCallback(() => {
    setActiveModal(undefined);
  }, [setActiveModal]);

  if (!ready) {
    return null;
  }

  return (
    <Modal
      caption={t('MTD.INTEGRATIONS.CREATE_NEW_CONNECTION')}
      title={SelectProvider_TITLE}
      onClose={handleClose}
      scrollable={true}
      className={classes.modal}
    >
      <div className={classes.content}>
        <div className={classes.horizontalStepper}>
          <HorizontalLinearStepper status={0} />
        </div>
        <div className={classes.providerContainer}>
          {!!providers &&
            providers?.map((connector) => (
              <button
                className={`image-wrapper ${classes.imageButton} ${
                  selectConnector?.logo === connector.logo && classes.selected
                }`}
                key={connector.description}
                onClick={() => {
                  setSelectConnector(connector);
                }}
              >
                <div className={classes.imgBox}>
                  <img
                    alt={connector.description}
                    className={`${classes.image}`}
                    src={`api/emm/${connector.logo}`}
                    onLoad={handleOnLoad}
                  />
                </div>
                <p className={classes.label}>{connector.description}</p>
              </button>
            ))}
        </div>
        <div className={classes.buttonStyle}>
          <Button
            className={classes.buttonBackgroundColor}
            onClick={handleCancelClick}
            text={t('GLOBAL.CANCEL')}
            type="button"
          />
          <Button
            color="secondary"
            onClick={() => handleSelect(selectConnector)}
            text={t('GLOBAL.NEXT')}
            type="button"
          />
        </div>
      </div>
    </Modal>
  );
};

export default SelectProvider;
