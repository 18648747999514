import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    buttonPanel: {
      display: 'flex',
      margin: '20px 0',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
    },
    scopeSelector: {
      paddingTop: '20px',
      color: palette.text.secondary,
    },
  })
);

export default useStyles;
