import React from 'react';
import useStyles from './useStyles';

const EmptyPolicies: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.empty}>
      Select a policy or click + to add a new policy
    </div>
  );
};

export default EmptyPolicies;
