import { ServiceHandlerFactory } from '../utils/serviceUtils';

const brandingBase = '/api/auth/v1/branding';

export const fetchBrandingByDomain = ServiceHandlerFactory({
  axios: {
    url: `${brandingBase}/resource`,
  },
});

export const fetchBrandingByAccountId = ServiceHandlerFactory({
  axios: {
    url: brandingBase,
  },
});

export const updatePartnerBrandingByAccountId = ServiceHandlerFactory({
  axios: {
    url: brandingBase,
    method: 'PUT',
  },
});
