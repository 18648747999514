import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    inputGroupBackground: {
      color: palette.text.contrast,
      backgroundColor: palette.components.checkboxInputGroup.base,
    },
  })
);

export default useStyles;
