import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Field, Form } from 'formik';
// import * as Yup from 'yup';
// services

// actions
import { publishEvent } from 'utils/eventUtils';

// components
import { Grid } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ZButton from 'UI/Buttons/ZButton';
import { reduceForMultiSelect } from 'utils/componentUtils';
import { FormikMultiSelect } from 'components/inputs/FormikMultiSelect';
import {
  enablePartnerUserForPartnerUI,
  fetchAllCandidatesPartnerUsers,
  fetchAllManagedAccountsByPartnerForPartnerUI,
  fetchAllPartnerRoles,
} from 'api/PartnerService';
import {
  openSnackBar,
  toggleModalDirect,
  toggleModalDiffered,
} from '../../utils/storeUtils';

function multiSelectHelper(candidateArray) {
  return candidateArray.map(user => {
    return {
      label: user.email,
      value: user.id,
    };
  });
}
function PartnerUsersCreateEditForPartners(props) {
  const [partnerUsers, setPartnerUsers] = useState([]);
  const [partnerRoles, setPartnerRoles] = useState([]);
  const [managedAccounts, setManagedAccounts] = useState([]);
  const [showManagedAccounts, setShowMangedAccounts] = useState(false);
  const accountId = _.get(props, 'data.accountId');

  function populatePartnerRoles() {
    fetchAllPartnerRoles()
      .then(response => {
        const rawRoles = _.get(response, 'data', []);
        const options = reduceForMultiSelect(rawRoles);
        setPartnerRoles(options);
      })
      .catch(error => {
        console.log('error in Fetching Partner Accounts', error);
      });
  }

  function populateCandidates(account) {
    fetchAllCandidatesPartnerUsers({ accountId: account })
      .then(response => {
        const rawCandidates = _.get(response, 'data', []);
        const options = multiSelectHelper(rawCandidates);
        setPartnerUsers(options);
      })
      .catch(error => {
        console.log('error in Fetching Possible Candidates', error);
      });
  }

  function populateManagedAccounts() {
    setShowMangedAccounts(true);
    fetchAllManagedAccountsByPartnerForPartnerUI({ accountId })
      .then(response => {
        const rawAccounts = _.get(response, 'data', []);
        const options = reduceForMultiSelect(rawAccounts);
        setManagedAccounts(options);
      })
      .catch(error => {
        console.log('error in Fetching Possible Candidates', error);
      });
  }

  function handlePartnerRoleSelect({ values, optionSelected, setFieldValue }) {
    setFieldValue('partnerRole', optionSelected);
    if (!optionSelected.partnerScopes?.includes('partner:unbounded')) {
      return populateManagedAccounts();
    }
    return setShowMangedAccounts(false);
  }

  useEffect(() => {
    // fetch Partner Options
    populateCandidates(accountId);
    populatePartnerRoles();
  }, [accountId]);

  function handleSubmit({ partnerUser, partnerRole, accounts }, actions) {
    let accountArray = [];
    if (accounts) {
      accountArray = accounts.map(singleAccount => singleAccount.value);
    }
    const userId = _.get(partnerUser, 'value');
    const payload = {
      userId,
      partnerRoleId: _.get(partnerRole, 'value'),
      accounts: accountArray,
    };
    // create
    enablePartnerUserForPartnerUI({ userId }, payload)
      .then(data => {
        toggleModalDirect('PartnerUserCreateEditForPartners', false);
        openSnackBar('Partner User Enabled');
        publishEvent('table:force-fetch-partnerUsersForPartnerUI');
      })
      .catch(error => {
        console.log('Error in enabling partner user ', error);
      });
  }
  return (
    <DialogContent>
      <Formik
        initialValues={{}}
        // validationSchema={PartnerUserSchema}
        validateOnBlur
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting, values, setFieldValue }) => {
          return (
            <Form>
              <Grid container spacing={12}>
                <Grid item xs={12}>
                  <Field
                    label="Partner User"
                    name="partnerUser"
                    isMulti={false}
                    buttonPlaceholder="Select User"
                    options={partnerUsers}
                    component={FormikMultiSelect}
                    onChange={(name, optionSelected) => {
                      setFieldValue('partnerUser', optionSelected);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label="Partner Role"
                    name="partnerRole"
                    isMulti={false}
                    buttonPlaceholder="Select Role"
                    options={partnerRoles}
                    component={FormikMultiSelect}
                    onChange={(name, optionSelected) => {
                      handlePartnerRoleSelect({
                        setFieldValue,
                        values,
                        optionSelected,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label="Accounts"
                    name="accounts"
                    buttonPlaceholder="Select Accounts"
                    options={managedAccounts}
                    component={FormikMultiSelect}
                    isShowing={showManagedAccounts}
                    onChange={(name, optionSelected) => {
                      setFieldValue('accounts', optionSelected);
                    }}
                  />
                </Grid>
              </Grid>

              <DialogActions>
                <ZButton
                  styleName="modalCancel"
                  action={toggleModalDiffered('SuperUsersCreateEdit', false)}
                  color="secondary"
                  buttonText="Cancel"
                />
                <ZButton
                  buttonType="Submit"
                  color="primary"
                  styleName="modalSave"
                  buttonText="Save Partner User"
                />
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </DialogContent>
  );
}

const styles = ({ palette, shape }) => ({
  errorClass: {
    minWidth: 150,
    padding: '7px 10px',
    marginTop: 19,
    marginLeft: 10,
    position: 'absolute',
    zIndex: 1,
    transform: 'translateY(-50%)',
    background: palette.error.light,
    color: palette.error.contrastText,
    borderRadius: shape.borderRadius,
  },
});

export default withStyles(styles)(PartnerUsersCreateEditForPartners);
