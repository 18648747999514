import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import useAsyncResult from 'utils/useAsyncResult';
import { selectedTeam as selectedTeamAtom } from '../../../../atoms/teams';
import OSDevicesVulnerable from '../OSDevicesVulnerable';
import OSStatusCard from '../OSStatusCard';
import OSTopTenImpactful from '../OSTopTenImpactful';
import RiskiestOsVersions from '../RiskiestOsVersions';
import { IState } from '../common/models';
import { IDeviceOSStatus, IDeviceVulnerable } from './models';
import useStyles from './useStyles';
import {
  DEVICES_VULNERABLE_EXPLOITS,
  fetchActiveExploits,
  fetchDeviceStats,
} from './utils';
import { useTranslation } from 'react-i18next';

const OSRiskDashboardTab: React.FC = () => {
  const classes = useStyles();
  const accountId = useSelector((state: IState) => state.user.account.id);
  const selectedTeamId = useRecoilValue(selectedTeamAtom);
  const { t, ready } = useTranslation();

  const deviceStats: IDeviceOSStatus | undefined = useAsyncResult(
    fetchDeviceStats,
    accountId,
    selectedTeamId || ''
  );
  const vulnerableActiveExploits: IDeviceVulnerable | undefined =
    useAsyncResult(fetchActiveExploits, accountId, selectedTeamId || '');

  const devicesVulnerable = useMemo(
    () =>
      DEVICES_VULNERABLE_EXPLOITS(t).map((item) => {
        return {
          ...item,
          value: vulnerableActiveExploits?.[item.id] ?? 0,
        };
      }),
    [vulnerableActiveExploits, t]
  );

  if (!ready) {
    return null;
  }

  return (
    <div className={classes.row}>
      <div>
        <OSStatusCard
          title={t('MTD.OS_RISK.ANDROID_DEVICE_OS_STATUS')}
          data={deviceStats?.platformData?.android}
        />
        <OSStatusCard
          title={t('MTD.OS_RISK.IOS_DEVICE_OS_STATUS')}
          data={deviceStats?.platformData?.ios}
        />
        <OSTopTenImpactful
          title={t('MTD.OS_RISK.TOP_TEN_IMPACTFUL_CV_ES_BY_DEVICE_COUNT')}
        />
      </div>
      <div>
        <OSDevicesVulnerable
          title={t('MTD.OS_RISK.DEVICES_VULNERABLE_TO_ACTIVE_EXPLOITS')}
          items={devicesVulnerable}
        />
        <RiskiestOsVersions
          accountId={accountId}
          selectedTeamId={selectedTeamId}
        />
      </div>
    </div>
  );
};

export default OSRiskDashboardTab;
