import { useCallback, useEffect, useState } from 'react';
import { fetchAllPartnerAccounts } from 'api/PartnerService';
import { reduceForMultiSelect } from 'utils/componentUtils';

export default (selectAccountId, skipFetch) => {
  const [managedAccounts, setMangedAccounts] = useState([]);

  const fetchPartners = useCallback(async () => {
    const { data } = await fetchAllPartnerAccounts();

    let managingAccountOptions = reduceForMultiSelect(data);
    // filter out the partner from dropdown if it currently is a partner
    // meaning a partner cannot have a partner of itself.  ABC cannot belong to ABC
    if (selectAccountId) {
      // isPartnerAccount) {
      managingAccountOptions = managingAccountOptions.filter(
        (account) => account.value !== selectAccountId
      );
    }
    setMangedAccounts(managingAccountOptions);
  }, [selectAccountId]);

  useEffect(() => {
    if (!skipFetch) {
      fetchPartners(selectAccountId);
    }
  }, [fetchPartners, selectAccountId, skipFetch]);
  return { managedAccounts };
};
