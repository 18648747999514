import cc from 'classcat';
import DoughnutChart from 'components/UI/Chart/Doughnut';
import { IHorizontalLegendItem } from 'components/UI/Chart/HorizontalLegend/models';
import GenericCard from 'components/UI/GenericCard';
import { useMemo } from 'react';
import useAsyncResult from 'utils/useAsyncResult';
import { IInsightsChart } from '../models';
import { default as useSharedStyles } from '../useStyles';
import { IDevicePool } from './models';
import useStyles from './useStyles';
import { fetchDevicePoolDevices } from './utils';
import { useTranslation } from 'react-i18next';

const DevicePool: React.FC<IInsightsChart> = ({ selectedTeamId }) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const { t, i18n, ready } = useTranslation();

  const devicePoolDevices = useAsyncResult<IDevicePool>(
    fetchDevicePoolDevices,
    selectedTeamId ?? ''
  );

  const devicePool: IHorizontalLegendItem[] = useMemo(
    () => [
      {
        color: '#4cae50',
        label: t('GLOBAL.ACTIVE'),
        link: '/console/mtd/devices/?duration=9&appStatus=ACTIVE',
        value: devicePoolDevices?.ACTIVE ?? 0,
      },
      {
        color: '#ff9f1e',
        label: t('GLOBAL.PENDING'),
        link: '/console/mtd/devices/?duration=9&appStatus=PENDING_ACTIVATION',
        value: devicePoolDevices?.PENDING_ACTIVATION ?? 0,
      },
      {
        color: '#f50057',
        label: t('GLOBAL.INACTIVE'),
        link: '/console/mtd/devices/?duration=9&appStatus=INACTIVE',
        value: devicePoolDevices?.INACTIVE ?? 0,
      },
    ],
    [devicePoolDevices, t]
  );

  const hasData = useMemo(
    () =>
      devicePoolDevices?.ACTIVE ||
      devicePoolDevices?.PENDING_ACTIVATION ||
      devicePoolDevices?.INACTIVE,
    [devicePoolDevices]
  );

  if (!ready) {
    return null;
  }

  return (
    <GenericCard className={cc([sharedClasses.card, classes.devicePoolCard])}>
      <h2>{t('MTD.INSIGHTS.DEVICE_POOL')}</h2>
      <div className={sharedClasses.column}>
        {hasData ? (
          <DoughnutChart
            forceProportionate
            segments={devicePool}
            summarySuffix="GLOBAL.DEVICES"
            withLegend
          />
        ) : (
          <div
            className={cc([
              {
                [classes.pendingNonEnglish]:
                  i18n.language?.toLowerCase() !== 'en-us',
              },
              classes.pending,
            ])}
          >
            {t('GLOBAL.NOT_AVAILABLE')}
          </div>
        )}
      </div>
    </GenericCard>
  );
};

export default DevicePool;
