import { Api } from 'config/axiosConfig';
import {
  createEditDataExportPayloadMapping,
  fetchDataExportParamsMapping,
} from 'mappings/services/dataExportServiceMapping';
import severitiesMapping from 'utility/Severities';
import { ServiceHandlerFactory } from 'utils/serviceUtils';

const ZIAPBase = '/api/ziapdataexport/v1/export-configuration';

export const fetchDataExportActiveExist = ServiceHandlerFactory({
  axios: {
    url: `${ZIAPBase}/active-exist`,
    method: 'get',
  },
});

export const fetchDataExportTablePage = ServiceHandlerFactory({
  axios: {
    url: ZIAPBase,
    method: 'get',
  },
  payloadSchema: fetchDataExportParamsMapping,
});

// create data export configuration
export const createDataExportConfig = ServiceHandlerFactory({
  axios: {
    url: ZIAPBase,
    method: 'post',
  },
  payloadSchema: createEditDataExportPayloadMapping,
});

// with certificate
export const createDataExportConfigCert = ServiceHandlerFactory({
  axios: {
    url: `${ZIAPBase}/certificate`,
    method: 'post',
  },
  payloadSchema: createEditDataExportPayloadMapping,
});
// edit data export configuration
export const updateDataExportConfig = ServiceHandlerFactory({
  axios: {
    url: `${ZIAPBase}/:dataExportConfigId?deleteTrustedCA=:deletedTrustedCA`,
    method: 'put',
  },
  payloadSchema: createEditDataExportPayloadMapping,
});
// with certificate
export const updateDataExportConfigCert = ServiceHandlerFactory({
  axios: {
    url: `${ZIAPBase}/certificate/:dataExportConfigId`,
    method: 'put',
  },
  payloadSchema: createEditDataExportPayloadMapping,
});
// delete data export configuration
export function deleteSelectedDataExportConfig(dataExportConfigId) {
  return Api.delete(`${ZIAPBase}/${dataExportConfigId}`);
}

// fetch options for multiselect dropdowns for DataExport
export function fetchDataTypes() {
  return new Promise((resolve) => {
    return resolve([
      {
        value: 'THREATS',
        label: 'Threats',
      },
      {
        value: 'AUDIT',
        label: 'Console User Activity',
      },
    ]);
  });
}

export function fetchDestinationTypes() {
  return new Promise((resolve) => {
    return resolve([
      {
        value: 'MICROSOFT_DEFENDER_ATP',
        label: 'Microsoft Defender ATP',
        configKey: 'microsoftDefenderConfiguration',
      },
      {
        value: 'SIEM',
        label: 'General SIEM',
        configKey: 'siemConfiguration',
      },
      {
        value: 'KINESIS',
        label: 'Amazon Kinesis',
        configKey: 'awsConfiguration',
      },
      {
        value: 'MICROSOFT_SENTINEL',
        label: 'Microsoft Azure Sentinel',
        configKey: 'sentinelConfiguration',
      },
      /* NOT NEEDED FOR NOW, since the v5 env is not yet whitelisted on ATT side
      {
        value: 'ATT_ALIEN_VAULT',
        label: 'Alienvault USM Anywhere',
        configKey: 'siemConfiguration', // this is same as SIEM on the micro-service side for alienvault
      }, */
      {
        value: 'WORKSPACE_ONE',
        label: 'VMware Workspace ONE Intelligence',
        configKey: 'siemConfiguration', // this is same as SIEM on the micro-service side for alienvault
      },
      {
        value: 'REST_ENDPOINT',
        label: 'REST endpoint',
        configKey: 'restEndpointConfiguration',
      },
      {
        value: 'OKTA',
        label: 'Okta',
        configKey: 'oktaConfiguration',
      },
    ]);
  });
}

export function fetchActiveDestinationTypes() {
  return Api.get(
    '/api/ziapdataexport/v1/destination-types/destination-type',
    {}
  );
}

export function getCertificateDetails(endpoint, port) {
  return Api.get('/api/ziapdataexport/v1/siem/server/certificates', {
    params: { endpoint, port },
  });
}

export function fetchSeverityCriteria() {
  return new Promise((resolve) => {
    return resolve(severitiesMapping);
  });
}
