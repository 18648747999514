import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    errors: {
      alignItems: 'center',
      display: 'flex',
      marginTop: 24,

      '& span': {
        color: palette.text.secondary,
        flex: 1,
        fontSize: 14,
        marginRight: 24,
      },

      '& svg': {
        color: palette.error.main,
        flex: 0,
        height: 56,
        minHeight: 56,
        minWidth: 56,
        width: 56,
      },
    },
    modal: {
      '& .MuiDialog-container > div': {
        overflowY: 'scroll',
        overflowX: 'hidden',
        height: '815px',
        width: '1100px',
        maxWidth: '1185px',
        minWidth: '600px',
      },
    },
  })
);

export default useStyles;
