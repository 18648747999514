import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

export default state => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      trmPolicy: {
        tableHeaders: uiSettingsDefaults.trmPolicy.tableHeaders,
      },
      trmPolicyMtd: {
        tableHeaders: uiSettingsDefaults.trmPolicyMtd.tableHeaders,
      },
    },
  };
};
