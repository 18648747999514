import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    rowElement: {
      marginTop: 15,
    },
  })
);

export default useStyles;
