import Card from '@material-ui/core/Card';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { postTFAConfigured, verifyTFACode } from 'api/SignupService';
import { FormikTextField } from 'components/inputs/FormikTextField';
import PublicComponentWrapper from 'components/wrappers/PublicComponentWrapper';
import { Field, Form, Formik } from 'formik';
import QRCode from 'qrcode.react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import ZButton from 'UI/Buttons/ZButton';

const TwoFactorLanding = ({ history }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => {
    return state?.auth?.user ?? false;
  });

  const handleConfigureTFA = () => {
    dispatch({ type: 'CLEAR_TFA_SETUP' });

    postTFAConfigured()
      .then(() => {
        console.log('TFA Configured: ');
      })
      .catch((error) => {
        console.log('Error in TFA Configure; ', error);
      });
  };

  const handleSubmit = (values, { setErrors }) =>
    verifyTFACode(values?.tfaCode)
      .then(() => {
        console.log('TFA Code verified');
        history?.push('/');
      })
      .catch((error) => {
        console.log('Error in TFA Code Submit; ', error);
        setErrors({
          tfaCode:
            'The onetime code you have entered is not valid.  Please try again.',
        });
      });

  if (!user?.isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return user?.otpAuthUrl ? (
    <PublicComponentWrapper>
      <div className="flex--spaced" style={{ width: 750 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Card
            style={{
              height: 400,
              width: 400,
              background: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <QRCode value={user.otpAuthUrl} includeMargin />
          </Card>
          <DialogActions>
            <ZButton
              styleName="modalSave"
              buttonText="Scanned"
              color="primary"
              action={handleConfigureTFA}
            />
          </DialogActions>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              paddingTop: 20,
            }}
          >
            <Link to="/logout">Logout</Link>
          </div>
        </div>
        <div style={{ paddingLeft: 20, height: 350 }}>
          <Typography variant="h6" className="entry-label" align="left">
            Your account has two factor authentication. Please open your 2FA
            device or desktop app to continue. Scan the QR Code provided and
            click Scanned. You will then be presented with a 6-digit one-time
            code to login.
          </Typography>
        </div>
      </div>
    </PublicComponentWrapper>
  ) : (
    <PublicComponentWrapper>
      <Formik
        // validationSchema = {}
        initialValues={{ tfaCode: '' }}
        validateOnBlur
        onSubmit={handleSubmit}
      >
        {({ values }) => {
          return (
            <Form>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'center',
                }}
              >
                <Field
                  name="tfaCode"
                  label="TFA Code"
                  inputProps={{ 'data-testid': 'tfa-input' }}
                  classlist={{ label: 'entry-label' }}
                  component={FormikTextField}
                />

                <ZButton
                  fullWidth
                  styleName="login"
                  buttonText="Submit 6 Digit Code"
                  color="primary"
                  buttonType="Submit"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
      <Link to="/logout"> Logout</Link>
    </PublicComponentWrapper>
  );
};

export default withRouter(TwoFactorLanding);
