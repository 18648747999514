import { fetchTopThreats } from "api/ZDefendInsights";
import { ITopFiveThreats } from "./models";
import { fetchThreatTypes } from "api/TRMPolicyService";
import { IThreatType } from "components/main/MTDInsights/models";

export const fetchTopFiveThreats = async (
  teamId?: string | undefined,
  zappId?: string | undefined
): Promise<ITopFiveThreats> => {
  const result: { data: ITopFiveThreats } = await fetchTopThreats({
    teamId,
    zappId
  });

  return result?.data;
};

export const fetchThreatsTypes = async (
): Promise<IThreatType[]> => {
  const result: { data: IThreatType[] } = await fetchThreatTypes();

  return result?.data;
};
