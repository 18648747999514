import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/UI/Button';
import React from 'react';
import { IConfirmData } from './models';

interface IGenericConfirmProps {
  data: IConfirmData;
}

const GenericConfirm: React.FC<IGenericConfirmProps> = ({ data }) => {
  const {
    cancelButtonText,
    confirmButtonText,
    confirmMessageText,
    content,
    onCancel,
    onConfirm,
  } = data;

  return (
    <DialogContent>
      {!content && <p>{confirmMessageText ?? 'Are you sure?'}</p>}
      {!!content && !confirmMessageText && content}
      <DialogActions>
        <Button
          color="secondary"
          onClick={onCancel}
          text={cancelButtonText ?? 'Cancel'}
        />
        <Button
          color="primary"
          onClick={onConfirm}
          text={confirmButtonText ?? 'Confirm'}
        ></Button>
      </DialogActions>
    </DialogContent>
  );
};

export default GenericConfirm;
