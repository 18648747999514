import { publishEvent } from 'utils/eventUtils';
import axios from 'axios';
import { IBCDMFormData, IStandardAuth } from './models';
import { openSnackBar } from 'utils/storeUtils';
import { Api } from 'config/axiosConfig';
import { IAuthPayload, IAuthForGroupsCreate } from '../../models';
import { FormikHelpers } from 'formik';
import { TFunction } from 'react-i18next';

const createAuthPayload = (
  formData: IBCDMFormData
): IAuthPayload<IStandardAuth> => ({
  authData: {
    auth: {
      dm_code: formData?.dm_code,
      secret_key: formData?.secret_key,
      access_key: formData?.access_key,
    },
    baseUrl: formData?.baseUrl,
    connectorTypeId: formData?.connectorTypeId,
  },
  name: formData?.name,
});

export const createConnection = async (
  formData: IBCDMFormData,
  formikHelpers: FormikHelpers<IBCDMFormData>,
  setAuthError: React.Dispatch<React.SetStateAction<string>>,
  goToNextPage: (
    authForGroupsCreate: IAuthForGroupsCreate<IStandardAuth>
  ) => void,
  t: TFunction<'translation', undefined>
) => {
  const authPayload = createAuthPayload(formData);
  const apiUrl = 'api/emm/v1/provision/connection/authenticate';
  try {
    const result = await Api.post(apiUrl, authPayload);
    goToNextPage({
      ...authPayload,
      id: result?.data?.connectionId ?? '',
      teamId:
        typeof formData?.team?.value === 'string' ? formData?.team?.value : '',
    });
  } catch (e) {
    if (axios.isAxiosError(e) && e?.response?.status === 409) {
      setAuthError(t('MTD.INTEGRATIONS.ERROR_ACCOUNT_ALREADY_EXIST'));
    } else {
      setAuthError(t('MTD.INTEGRATIONS.ERROR_AUTHENTICATION'));
    }
    formikHelpers.setSubmitting(false);
  }
};

export const updateConnection = async (
  formData: IBCDMFormData,
  formikHelpers: FormikHelpers<IBCDMFormData>,
  setAuthError: React.Dispatch<React.SetStateAction<string>>,
  closeModal: () => void,
  t: TFunction<'translation', undefined>
) => {
  if (!formData?.connectionId) {
    return;
  }
  const authPayload = createAuthPayload(formData);
  const apiUrl = `api/emm/v1/provision/connection/${formData.connectionId}/auth-data`;
  try {
    await Api.patch(apiUrl, authPayload);
    closeModal();
    openSnackBar(t('MTD.INTEGRATIONS.SUCCESSFULLY_UPDATED_MDM_CONNECTION.'));
    publishEvent('emm:reload-table');
  } catch (e) {
    if (axios.isAxiosError(e) && e?.response?.status === 409) {
      setAuthError(t('MTD.INTEGRATIONS.ERROR_ACCOUNT_ALREADY_EXIST'));
    } else {
      setAuthError(t('MTD.INTEGRATIONS.ERROR_AUTHENTICATION'));
    }
    formikHelpers.setSubmitting(false);
  }
};
