import { ServiceHandlerFactory } from 'utils/serviceUtils';
import genericTableServiceMapping from '../mappings/services/tableServiceMapping';

export const requestAllTeams = ServiceHandlerFactory({
  axios: {
    url: 'api/auth/v1/teams/all',
  },
});

export const requestTeamsListing = ServiceHandlerFactory({
  axios: {
    url: '/api/teams/v1/teams',
  },
  paramSchema: genericTableServiceMapping,
});

export const fetchSelectedTeamById = ServiceHandlerFactory({
  axios: {
    url: 'api/teams/v1/teams/:teamId',
  },
});

export const createNewTeam = ServiceHandlerFactory({
  axios: {
    url: 'api/teams/v1/teams',
    method: 'post',
  },
});

export const editSelectedTeam = ServiceHandlerFactory({
  axios: {
    url: 'api/teams/v1/teams/:teamId',
    method: 'put',
  },
});

export const deleteSelectedTeam = ServiceHandlerFactory({
  axios: {
    url: 'api/teams/v1/teams/:teamId',
    method: 'delete',
  },
});
