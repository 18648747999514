import React from 'react';
import _ from 'lodash';
// import withRouter from 'components/hocs/withRouter';
// import PropTypes from 'prop-types';

import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

// components
import ZButton from 'UI/Buttons/ZButton';
import { FormikTextField } from 'components/inputs/FormikTextField';

// ui
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

// utils
import {
  // openSnackBar,
  toggleModalDiffered,
  // toggleModalDirect,
} from 'utils/storeUtils';
import {
  csvInjectionErrorMessage,
  csvInjectionRegex,
} from 'utils/componentUtils';
// import { FormikMultiSelect } from 'components/inputs/FormikMultiSelect';
// import { FormikCheckbox } from 'components/inputs/FormikCheckbox';

function ActivationGroupCreateEdit(props) {
  function handleSubmit(values) {
    console.log('values', values);
  }
  return (
    <DialogContent>
      <Formik
        initialValues={{
          name: _.get(props, 'data.team.name', ''),
          description: _.get(props, 'data.team.description', ''),
        }}
        validationSchema={ActivationGroupSchema}
        validateOnBlur
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {// eslint-disable-next-line
        ({ dirty, isSubmitting, values }) => {
          return (
            <Form>
              <Field
                name="activationName"
                label="Activation Name"
                component={FormikTextField}
                autoComplete="offl"
              />
              <Field
                name="description"
                label="Description"
                component={FormikTextField}
                autoComplete="offl"
              />
              <Field
                name="localGroup"
                label="Local Group"
                component={FormikTextField}
                autoComplete="offl"
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Field
                  name="activationLimit"
                  label="Activation Limit"
                  component={FormikTextField}
                  type="number"
                  inputProps={{
                    min: 0,
                  }}
                  style={{ width: 100 }}
                />
                <Field
                  name="inviteExpire"
                  label="Invites expire after"
                  component={FormikTextField}
                  type="number"
                  inputProps={{
                    min: 0,
                  }}
                  style={{ width: 100 }}
                />
              </div>
              <DialogActions>
                <ZButton
                  styleName="submit"
                  action={toggleModalDiffered(
                    'ActivationGroupCreateEdit',
                    false
                  )}
                  color="secondary"
                  buttonText="Cancel"
                />

                <ZButton
                  buttonType="submit"
                  color="primary"
                  styleName="modalSave"
                  buttonText="Create"
                  proLabel
                />
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </DialogContent>
  );
}

const ActivationGroupSchema = Yup.object().shape({
  name: Yup.string()
    .min(1)
    .matches(csvInjectionRegex, csvInjectionErrorMessage)
    .required('Required'),

  description: Yup.string()
    // .min(1)
    .matches(csvInjectionRegex, csvInjectionErrorMessage),
});

export default ActivationGroupCreateEdit;
