import Tooltip from '@material-ui/core/Tooltip';
import CloudDownload from '@material-ui/icons/CloudDownload';
import { createStyles, makeStyles } from '@material-ui/styles';
import { IColumnHeader, ITableRowMapping } from 'components/UI/Table/models';
import _ from 'lodash';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';

const mapTableData = (
  tableData: unknown[],
  headerBuilder: { [key: string | number]: unknown }
): unknown[] => {
  return tableData.map((obj) =>
    Object.keys(headerBuilder)
      .map((key) => {
        const value = _.get(obj, key);
        return value ? value.toString().replace(/,/g, '') : '';
      })
      .join(',')
  );
};

const mapTableDataWithDataMap = ({
  resolvedTable,
  dataMap,
  ...rest
}: {
  resolvedTable: unknown[];
  dataMap: ITableRowMapping[];
}) => {
  return resolvedTable.map((tableData) =>
    dataMap
      .map((map) => {
        const columnData = _.get(tableData, map.path);
        let csvContent = columnData;

        if (typeof map.csvContent === 'function') {
          csvContent = map.csvContent(columnData, {
            table: resolvedTable,
            dataMap,
            ...rest,
          });
        }

        if (!csvContent) {
          return '';
        }

        return csvContent
          .toString()
          .replace(/^(=|\+|-|@|")/im, "'$1")
          .replace(/,/g, '');
      })
      .join(',')
  );
};

const ZTableToCSV = ({
  fileName = `export-${Moment().unix()}`,
  header = [],
  table = [],
  dataMap,
  handleCSVDownload,
  ...rest
}: {
  fileName: string;
  header: IColumnHeader[];
  table: unknown[] | (() => unknown);
  dataMap: ITableRowMapping[];
  handleCSVDownload?: () => void;
  [key: string | number | symbol]: unknown;
}) => {
  const classes: any = useStyles();
  const { t, ready } = useTranslation();
  const handleDownloadClick = async () => {
    if (handleCSVDownload) {
      handleCSVDownload();
      return;
    }
    const tableData =
      'function' === typeof table ? _.get(await table(), 'data', []) : table;
    const headerDataString = header.map((obj) => obj.label).join(',');
    const headerBuilder = header.reduce((accum, value) => {
      const path = value.idCSV ? value.idCSV : value.id;

      return {
        ...accum,
        [path]: path,
      };
    }, {});

    const tableDataString = (
      _.isEmpty(dataMap)
        ? mapTableData(tableData, headerBuilder)
        : mapTableDataWithDataMap({
            resolvedTable: tableData,
            dataMap,
            ...rest,
          })
    ).join('\n');

    const csvFile = new Blob([headerDataString, '\n', tableDataString], {
      type: 'text/csv',
    });

    // Download link
    const downloadLink = document.createElement('a');
    // Make sure that the link is not displayed
    downloadLink.style.display = 'none';
    // File name
    downloadLink.download = fileName;
    // We have to create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile);
    // Add the link to your DOM
    document.body.appendChild(downloadLink);

    // Lanzamos
    downloadLink.click();
  };
  if (!ready) {
    return null;
  }

  return (
    <Tooltip
      title={t('GLOBAL.DOWNLOAD_TO_CSV_FILE') || ''}
      enterDelay={300}
      placement="bottom-start"
    >
      <CloudDownload
        className={classes.cloudDownloadIcon}
        onClick={handleDownloadClick}
      />
    </Tooltip>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    cloudDownloadIcon: {
      display: 'flex',
      marginRight: 'auto',
      cursor: 'pointer',
      fontSize: 30,
      marginBottom: 30,
    },
  })
);

export default ZTableToCSV;
