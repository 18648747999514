import { getActiveModalAtom } from 'atoms/modals';
import Button from 'components/UI/Button';
import InputError from 'components/UI/input/InputError';
import PasswordField from 'components/UI/input/PasswordField';
import { Radio } from 'components/UI/input/RadioGroup';
import Select from 'components/UI/input/Select';
import TextField from 'components/UI/input/TextField';
import {
  Field as FormikField,
  Form as FormikForm,
  Formik,
  FormikHelpers,
} from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { AddEditCloneEMMGroups_TITLE } from '../../AddEditCloneEMMGroups';
import { IAddEditCloneEMMGroups } from '../../AddEditCloneEMMGroups/models';
import { IAuthForGroupsCreate } from '../../models';
import { IAuthFormProps } from '../models';
import { initialValues } from './initialValues';
import { IVMWareAuth, IVMWareFormData, FileData } from './models';
import Schema from './schema';
import useStyles from './useStyles';
import { createConnection, updateConnection } from './utils';
import cc from 'classcat';
import Uploader from 'components/UI/Uploader';
import { default as MUIDeleteIcon } from '@material-ui/icons/Delete';
import { truncateString } from '../utils';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/UI/Tooltip';

const activeModalAtom =
  getActiveModalAtom<IAddEditCloneEMMGroups<IVMWareAuth>>();

const VMWareForm: React.FC<IAuthFormProps> = ({
  availableTeams,
  connector,
  data,
  handleBackClick,
  handleCancelClick,
  mode = 'ADD',
  selectedTeamId,
}) => {
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const [authError, setAuthError] = useState<string>('');
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const setActiveModal = useSetRecoilState(activeModalAtom);
  const [selectedFile, setSelectedFile] = useState<FileData>({ name: '' });

  const oAuthProviders = useMemo(
    () =>
      connector?.oauthProviders?.map((provider) => ({
        label: provider.providerLocation,
        value: provider.id,
      })),
    [connector]
  );

  const resolvedInitialValues = initialValues(
    mode,
    data?.rowData,
    connector,
    availableTeams,
    selectedTeamId,
    oAuthProviders
  );

  const handleSubmit = useCallback(
    (
      formData: IVMWareFormData,
      formikHelpers: FormikHelpers<IVMWareFormData>
    ) => {
      switch (mode) {
        case 'ADD':
          createConnection(
            formData,
            formikHelpers,
            setAuthError,
            (authForGroupsCreate: IAuthForGroupsCreate<IVMWareAuth>) =>
              setActiveModal({
                active: AddEditCloneEMMGroups_TITLE,
                payload: { authForGroupsCreate, connector, mode },
              }),
            t
          );
          break;
        case 'EDIT':
          updateConnection(
            formData,
            formikHelpers,
            setAuthError,
            () =>
              setActiveModal(() => ({
                active: AddEditCloneEMMGroups_TITLE,
                payload: {
                  cloneInfoFromAuth: {
                    connectionIdToClone: data?.rowData?.id ?? '',
                    name: formData?.name ?? '',
                    teamId: (formData?.team?.value as string) ?? '',
                  },
                  connector,
                  data,
                  mode,
                },
              })),
            t
          );
          break;
        case 'CLONE':
          setActiveModal(undefined); // First clear out data so it doesn't show up in groups table.
          setActiveModal(() => ({
            active: AddEditCloneEMMGroups_TITLE,
            payload: {
              cloneInfoFromAuth: {
                connectionIdToClone: data?.rowData?.id ?? '',
                name: formData?.name ?? '',
                teamId: (formData?.team?.value as string) ?? '',
              },
              connector,
              data: undefined,
              mode,
            },
          }));
          break;
      }
    },
    [mode, setActiveModal, connector, data, setAuthError, t]
  );

  const getFileData = useCallback((filedata: File) => {
    setSelectedFile({ name: filedata?.name || 'file', file: filedata });
  }, []);

  const handleRemoveFile = useCallback(() => {
    setSelectedFile({ name: '' });
  }, []);

  if (!ready) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={resolvedInitialValues}
      onSubmit={handleSubmit}
      validateOnBlur
      validationSchema={Schema(t)}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <FormikForm>
          <FormikField
            component={TextField}
            disabled={isSubmitting}
            label={t('GLOBAL.NAME')}
            name="name"
          />
          <FormikField
            component={Select}
            disableClearable={true}
            disabled={mode === 'EDIT'}
            label={t('GLOBAL.TEAM')}
            multiple={false}
            name="team"
            options={availableTeams ?? []}
            setFieldValue={setFieldValue}
            type="select"
          />
          <FormikField
            component={TextField}
            disabled={isSubmitting}
            label={t('GLOBAL.URL')}
            name="baseUrl"
          />
          <FormikField
            component={TextField}
            disabled={isSubmitting || mode === 'CLONE'}
            label={t('GLOBAL.API_KEY')}
            name="api_key"
          />
          <div className={classes.UploadZone}>
            <div className={classes.authMethod}>
              <div>{t('MTD.INTEGRATIONS.AUTHENTICATION_METHOD')}</div>
              <div>
                <FormikField
                  component={Radio}
                  disabled={isSubmitting || mode === 'CLONE'}
                  label={t('GLOBAL.CERTIFICATE')}
                  name="auth_method_name"
                  type="radio"
                  value="certificate"
                />
                <FormikField
                  component={Radio}
                  disabled={isSubmitting || mode === 'CLONE'}
                  label={t('GLOBAL.USERNAME')}
                  name="auth_method_name"
                  type="radio"
                  value="basic"
                />
                <FormikField
                  component={Radio}
                  disabled={isSubmitting || mode === 'CLONE'}
                  label={t('GLOBAL.OAUTH')}
                  name="auth_method_name"
                  type="radio"
                  value="oauth"
                />
              </div>
              {!!selectedFile.name &&
                values.auth_method_name === 'certificate' && (
                  <div className={cc([classes.fileName, 'overflow__ellipsis'])}>
                    <span className={classes.fileNameTypography}>
                      <span
                        className={classes.tooltip}
                        onMouseEnter={() => setShowTooltip?.(true)}
                        onMouseLeave={() => setShowTooltip?.(false)}
                      >
                        {t('MTD.INTEGRATIONS.CURRENT_CERTIFICATE_FILE')}{' '}
                        {truncateString(selectedFile.name)}
                        {showTooltip && (
                          <span className={classes.tooltipText}>
                            {selectedFile.name}
                          </span>
                        )}
                      </span>
                    </span>
                    <button
                      className={cc([
                        classes.fileInfoDeleteButton,
                        {
                          [classes.disabled]: false,
                        },
                      ])}
                      disabled={isSubmitting}
                      onClick={handleRemoveFile}
                      type="button"
                    >
                      <MUIDeleteIcon />
                    </button>
                  </div>
                )}
            </div>
            {values.auth_method_name === 'certificate' && (
              <div className={classes.uploadContent}>
                <Uploader
                  accept=".p12"
                  disabled={isSubmitting || mode === 'CLONE'}
                  getFileData={getFileData}
                  isShowing={!selectedFile.name}
                  name="certificate"
                  setFieldValue={setFieldValue}
                  required
                />
              </div>
            )}
          </div>

          {values.auth_method_name === 'basic' && (
            <FormikField
              component={TextField}
              disabled={isSubmitting || mode === 'CLONE'}
              label={t('MTD.INTEGRATIONS.MDM_USERNAME')}
              name="user_id"
            />
          )}
          {values.auth_method_name !== 'oauth' && (
            <FormikField
              component={PasswordField}
              disabled={isSubmitting || mode === 'CLONE'}
              label={t(
                values.auth_method_name === 'basic'
                  ? 'MTD.INTEGRATIONS.MDM_PASSWORD'
                  : 'MTD.INTEGRATIONS.PASSPHRASE'
              )}
              name="password"
            />
          )}
          {values.auth_method_name === 'oauth' && (
            <>
              <div style={{ marginBottom: '5px', marginTop: '10px' }}>
                <div className={classes.oAuthLabel}>
                  <span>{t('MTD.INTEGRATIONS.CLIENT_ID')}</span>
                  <Tooltip
                    tooltipText={t('MTD.INTEGRATIONS.CLIENT_ID_TOOLTIP_TEXT')}
                  />
                </div>
                <FormikField
                  component={TextField}
                  disabled={isSubmitting || mode === 'CLONE'}
                  name="client_id"
                />
              </div>

              <div style={{ marginBottom: '20px' }}>
                <div className={classes.oAuthLabel}>
                  <span>{t('MTD.INTEGRATIONS.CLIENT_SECRET')}</span>
                  <Tooltip
                    tooltipText={t(
                      'MTD.INTEGRATIONS.CLIENT_SECRET_TOOLTIP_TEXT'
                    )}
                  />
                </div>
                <FormikField
                  component={PasswordField}
                  disabled={isSubmitting || mode === 'CLONE'}
                  name="client_secret"
                />
              </div>

              <div className={classes.oAuthLabel}>
                {t('MTD.INTEGRATIONS.REGION_SPECIFIC_TOKEN_URL')}
                <Tooltip
                  tooltipText={t(
                    'MTD.INTEGRATIONS.REGION_SPECIFIC_TOOLTIP_TEXT'
                  )}
                />
              </div>

              <FormikField
                component={Radio}
                disabled={isSubmitting || mode === 'CLONE'}
                label={t('MTD.INTEGRATIONS.CHOOSE_FROM_DEFAULTS')}
                name="custom_token_type"
                type="radio"
                value="default"
              />
              <FormikField
                component={Radio}
                disabled={isSubmitting || mode === 'CLONE'}
                label={t('MTD.INTEGRATIONS.CUSTOM_TOKEN_URL')}
                name="custom_token_type"
                type="radio"
                value="custom"
              />

              {values.custom_token_type === 'default' && (
                <FormikField
                  component={Select}
                  disableClearable={true}
                  disabled={isSubmitting || mode === 'CLONE'}
                  label={t('MTD.INTEGRATIONS.SELECT_REGION')}
                  multiple={false}
                  name="provider"
                  options={oAuthProviders ?? []}
                  setFieldValue={setFieldValue}
                  onInputChange={() => {
                    setFieldValue('oauth_url', '');
                  }}
                  type="select"
                />
              )}
              {values.custom_token_type === 'custom' && (
                <FormikField
                  component={TextField}
                  disabled={isSubmitting || mode === 'CLONE'}
                  label={t('MTD.INTEGRATIONS.ENTER_CUSTOM_URL')}
                  name="oauth_url"
                />
              )}
            </>
          )}
          {!!authError && <InputError override={authError} />}
          <div className={classes.buttons}>
            {mode === 'ADD' && (
              <Button
                className={classes.buttonBackgroundColor}
                disabled={isSubmitting}
                onClick={handleBackClick}
                text={t('GLOBAL.BACK')}
                type="button"
              />
            )}
            <Button
              className={classes.buttonBackgroundColor}
              disabled={isSubmitting}
              onClick={handleCancelClick}
              text={t('GLOBAL.CANCEL')}
              type="button"
            />
            <Button
              color="secondary"
              disabled={isSubmitting}
              text={t('GLOBAL.NEXT')}
              type="submit"
            />
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default VMWareForm;
