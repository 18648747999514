import { IMDMRowData } from '../../emmTable/MDM/models';
import { IGroupPolicyMapping, IResponseGroupMapping } from './models';

export const initialTableValues = (
  rowData?: IMDMRowData
): IGroupPolicyMapping[] => {
  return (
    rowData?.connectedGroups.map(({ singleGroup }, index) => ({
      id: String(index),
      appSettings: singleGroup?.appSettings,
      group: singleGroup?.groupIdsToSync,
      dormancyPolicy: singleGroup?.dormancyPolicy,
      phishingPolicy: singleGroup?.phishingPolicy,
      privacyPolicy: singleGroup?.privacyPolicy,
      trmPolicy: singleGroup?.trmPolicy,
      networkPolicy: singleGroup?.networkPolicy,
      osRiskPolicy: singleGroup?.osRiskPolicy
    })) ?? []
  );
};

export const initialResponseTableValues = (
  rowData?: IMDMRowData
): IResponseGroupMapping[] => {
  return (
    rowData?.connectedActionGroupsToSync.map(({ singleGroup }, index) => ({
      id: String(index),
      group: singleGroup?.groupIdsToSync,
    })) ?? []
  );
};
