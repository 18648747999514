import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      fontSize: '12px',
      fontFamily:
        'Roboto, Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    loader: {
      height: '36px',
      marginTop: '5px',
    },
    wrapper: {
      marginTop: '15px',
    },
  })
);

export default useStyles;
