import React, { PureComponent } from 'react';
import _ from 'lodash';
import withRouter from 'components/hocs/withRouter';
import PropTypes from 'prop-types';

import { Formik, Field, Form } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';

// components
import ZButton from 'UI/Buttons/ZButton';
import { FormikTextField } from 'components/inputs/FormikTextField';

// ui
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

// utils
import {
  openSnackBar,
  toggleModalDiffered,
  toggleModalDirect,
} from 'utils/storeUtils';

// services
import { updateAvailableTeams } from 'reducers/TeamReducers';
import {
  csvInjectionErrorMessage,
  csvInjectionRegex,
} from 'utils/componentUtils';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import {
  createNewTeam,
  editSelectedTeam,
  requestAllTeams,
} from '../../api/apis';
import { publishEvent } from '../../utils/eventUtils';

class TeamsCreateEdit extends PureComponent {
  constructor(props) {
    super(props);

    this.editMode = _.get(props, 'data.rowData');

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const { props } = this;
    return (
      <DialogContent>
        <Formik
          initialValues={{
            name: _.get(props, 'data.rowData.name', ''),
            description: _.get(props, 'data.rowData.description', ''),
          }}
          validationSchema={TeamSchema}
          validateOnBlur
          enableReinitialize
          onSubmit={this.handleSubmit}
        >
          {({ dirty, isSubmitting, values }) => {
            return (
              <Form>
                <Field
                  name="name"
                  label="Team Name"
                  component={FormikTextField}
                  autoComplete="off"
                />
                <Field
                  name="description"
                  label="Description"
                  component={FormikTextField}
                  autoComplete="off"
                />
                <DialogActions>
                  <ZButton
                    styleName="submit"
                    action={toggleModalDiffered('TeamsCreateEdit', false)}
                    color="secondary"
                    buttonText="Cancel"
                  />
                  <ProtectedComponent allow={{ teams: 'manage' }}>
                    <ZButton
                      buttonType="submit"
                      color="primary"
                      styleName="modalSave"
                      buttonText="Save"
                      proLabel
                    />
                  </ProtectedComponent>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    );
  }

  handleSubmit(values, actions) {
    // event.preventDefault();
    const { props } = this;
    if (this.editMode) {
      editSelectedTeam(
        {
          teamId: _.get(props, 'data.rowData.id'),
        },
        values
      )
        .then(() => {
          publishEvent('table:force-fetch-teamsTable');
          toggleModalDirect('TeamsCreateEdit', false);
          // fetch all teams and update the global team selector in side bar
          requestAllTeams()
            .then(resp => {
              if (resp.data.length) {
                props.updateAvailableTeams(resp.data);
              }
            })
            .catch(error => {
              console.error('Error fetching teams', error);
            });
          openSnackBar(`${values.name} Successfully Updated`);
        })
        .catch(error => {
          if (error.response.status === 409) {
            actions.setFieldError(
              'name',
              'A team already exists with this name'
            );
          } else {
            console.error('Error creating team', error);
            const message = `An error occurred: ${_.get(
              error,
              'response.data',
              'Network Error'
            )}`;
            actions.setFieldError('name', message);
          }
        });
    } else {
      createNewTeam({}, values)
        .then(() => {
          publishEvent('table:force-fetch-teamsTable');
          toggleModalDirect('TeamsCreateEdit', false);
          openSnackBar(`${values.name} Successfully Create`);
          requestAllTeams()
            .then(resp => {
              if (resp.data.length) {
                props.updateAvailableTeams(resp.data);
              }
            })
            .catch(error => {
              console.log('error', error);
              console.error('Error fetching teams', error);
            });
        })
        .catch(error => {
          if (error.response.status === 409) {
            actions.setFieldError(
              'name',
              'A team already exists with this name'
            );
          } else {
            console.error('Error creating team', error);
            const message = `An error occurred: ${_.get(
              error,
              'response.data',
              'Network Error'
            )}`;
            actions.setFieldError('name', message);
          }
        });
    }
  }
}

TeamsCreateEdit.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string,
    name: PropTypes.string,
  }),
};

const TeamSchema = Yup.object().shape({
  name: Yup.string()
    .min(1)
    .matches(csvInjectionRegex, csvInjectionErrorMessage)
    .required('Required'),

  description: Yup.string()
    // .min(1)
    .matches(csvInjectionRegex, csvInjectionErrorMessage),
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateAvailableTeams,
    },
    dispatch
  );
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(TeamsCreateEdit)
);
