import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    backgroundCircle: {
      display: 'flex',
      borderRadius: '50%',
      background: palette.background.paper,
      justifyContent: 'center',
      padding: 3,
      width: 65,
      height: 65,
      marginRight: 5,
    },
    vectorCard: {
      color: palette.text.link,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'Oswald',
      fontSize: '48px',
    },
    vectorIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        height: 40,
        width: 40,
      },
    },
    vectorText: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    vectorDescription: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      height: 5,
      fontSize: 12,
      textDecoration: 'none',
      alignSelf: 'center',
    },
    severityCounts: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingLeft: 10,
      fontFamily: 'Roboto',
      fontWeight: 400,
      height: 32,
      fontSize: 12,
      textDecoration: 'none',
      alignSelf: 'center',
    },
    vectorCount: {
      color: palette.text.link,
      fontFamily: 'Oswald',
      fontSize: '48px',
      textDecoration: 'none',
    },
    elevated: {
      color: palette.common.elevated,
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 12,
      textDecoration: 'none',
    },
    critical: {
      color: palette.common.critical,
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 12,
      textDecoration: 'none',
    },
  })
);

export default useStyles;
