import React from 'react';
import momentHelper from 'utils/momentUtils';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import { toggleModalDiffered } from 'utils/storeUtils';

// partner api keys table
export const partnerAuthorizationsTableHeaders = [
  {
    id: 'description',
    label: 'Description',
    show: true,
    sort: true,
  },
  {
    id: 'createdBy',
    label: 'Created By',
    show: true,
    sort: true,
  },

  {
    id: 'modifiedBy',
    label: 'Modified By',
    show: true,
    sort: true,
  },
  {
    id: 'modified',
    label: 'Modified',
    show: true,
    sort: true,
  },
  {
    id: 'created',
    label: 'Created',
    show: true,
    sort: true,
  },
  {
    id: 'actions',
    numeric: false,
    label: 'Actions',
    sort: false,
    show: true,
    // isProtected: true,
    // scope: 'teams',
  },
];

export const partnerAuthorizationsColumnMapping = [
  { path: 'description' },
  { path: 'createdBy' },
  { path: 'modifiedBy' },
  { path: 'created', columnContent: timestamp => momentHelper(timestamp) },
  { path: 'modified', columnContent: timestamp => momentHelper(timestamp) },

  {
    path: 'actions',
    csvContent: null,
    columnContent: (id, { rowData, handlers }) => {
      return (
        <div className="flex--spaced">
          <Tooltip title="Edit Description or Regenerate Secret Key">
            <EditIcon
              className="hover--link"
              onClick={toggleModalDiffered(
                'PartnerAuthorizationsCreateEdit',
                {
                  id: rowData.id,
                  description: rowData.description,
                },
                {
                  title: 'Edit Partner API Key',
                  fullWidth: true,
                }
              )}
            />
          </Tooltip>
          <Tooltip title="Disable user from being a partner">
            <DeleteIcon
              className="hover--link"
              onClick={toggleModalDiffered(
                'AuthorizationsDeleteConfirm',
                {
                  id: rowData.id,
                  description: rowData.description,
                  partnerUi: true,
                },
                {
                  title: 'Remove Partner User',
                }
              )}
            />
          </Tooltip>
        </div>
      );
    },
  },
  { path: '' }, // hack to get the table row 100%
];
