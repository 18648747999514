import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    androidIcon: {
      fill: 'currentColor',
      minHeight: '38px',
      minWidth: '38px',
    },
    appleIcon: {
      fill: 'currentColor',
      minHeight: '32px',
      minWidth: '32px',
      stroke: 'currentColor',
    },
    chromeIcon: {
      fill: 'currentColor',
      minHeight: '38px',
      minWidth: '38px',
    },
    column: {
      display: 'flex',
      flexDirection: 'row',
      gap: 30,
      height: '100%',
      justifyContent: 'space-between',
      padding: '20px 10px 0 10px',
      width: '100%',

      '&:first-child': { marginBottom: 50 },

      '& ul': {
        listStyleType: 'none',
        margin: '10px 0 0',
        padding: 0,

        '& li > label > span': {
          color: palette.text.primary,
          fontSize: 14,
        },
      },
    },
    buttonBackgroundColor: {
      backgroundColor: palette.charts.icon,
      color: palette.common.white,
      '&:hover': {
        backgroundColor: palette.charts.icon,
      },
    },
    content: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      minWidth: 550,
      marginLeft: '30px',
    },
    modal: {
      '& .MuiDialog-container > div': {
        backgroundColor: palette.background.deviceAction,
      },
      '& .MuiDialogTitle-root': {
        backgroundColor: palette.background.modalTitle,
      },
    },

    footer: {
      alignSelf: 'flex-end',
      display: 'flex',
      flexdirection: 'row',
      justifyContent: 'flex-end',
      margin: '20px 0',
      marginRight: '30px',
    },
    knoxIcon: {
      fill: 'currentColor',
      maxHeight: 38,
      maxWidth: 38,
      paddingTop: 3,
    },
    os: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: '100%',
    },
    title: {
      alignItems: 'center',
      display: 'flex',

      '& h3': {
        color: palette.text.secondary,
        flex: 0,
        fontSize: 20,
        fontWeight: 'normal',
        margin: '0 0 0 20px',
        padding: 0,
        textAlign: 'center',
      },
      '& > div': {
        color: palette.text.secondary,
      },
    },
  })
);

export default useStyles;
