import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    icon: {
      borderRadius: 1000,
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 800,
    },
    error: {
      backgroundColor: palette.components.circleIcon.error,
    },
    success: {
      backgroundColor: palette.components.circleIcon.success,
    },
    warning: {
      backgroundColor: palette.components.circleIcon.warning,
    },
    unknown: {
      backgroundColor: 'transparent',
      borderStyle: 'dashed',
      borderColor: palette.components.circleIcon.unknown,
      borderWidth: 1,
    },
  })
);

export default useStyles;
