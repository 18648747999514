import { IConnector } from 'components/main/integrations/emm/models';
import { ISelectItem } from 'components/UI/input/Select';
import { IMDMRowData } from '../../emmTable/MDM/models';
import { TModalMode } from '../../models';
import { IBCDMFormData } from './models';

export const initialValues = (
  modalMode: TModalMode,
  rowData?: IMDMRowData,
  connector?: IConnector,
  availableTeams?: ISelectItem[],
  selectedTeamId?: string
): IBCDMFormData => {
  return {
    baseUrl: rowData?.baseUrl ?? '',
    connectionId: rowData?.id ?? '',
    connectorTypeId: connector?.id ?? '',
    modalMode,
    mode: connector?.supportedModes?.[0] ?? '',
    name:
      modalMode === 'CLONE' ? `${rowData?.name} (Cloned)` : rowData?.name ?? '',
    dm_code: '',

    team: !!availableTeams?.[0]
      ? availableTeams.find(
        ({ value }) => value === (rowData?.teamId ?? selectedTeamId)
      )
      : undefined,
    user_id: rowData?.auth?.user_id ?? '',
    access_key: rowData?.access_key ?? '',
    secret_key: rowData?.access_key ?? ''
  };
};
