import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, config }: any) =>
  createStyles({
    background: {
      stroke: palette.text.primary,
      strokeWidth: 5,
    },
    doughnut: {
      flex: 0,
    },
    legend: {
      flex: 1,
    },
    section: {
      display: 'flex',
      position: 'relative',
      paddingTop: 20,
      '&:first-child': {
        flex: '1',
      },
    },
    summary: {
      left: '50%',
      position: 'absolute',
      top: '50%',
      transform: 'translateX(-50%) translateY(-50%)',

      '& > h1, & > h2': {
        color: palette.text.secondary,
        fontFamily: config.fonts.stackSans,
        fontWeight: config.weights.normal,
        fontSize: '2em',
        margin: 0,
        textAlign: 'center',
      },

      '& > h2': {
        color: palette.text.primary,
        fontSize: 18,
        fontWeight: 'normal',
      },
    },
  })
);

export default useStyles;
