import AndroidIcon from '@material-ui/icons/Android';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import PersonIcon from '@material-ui/icons/Person';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import cc from 'classcat';
import InformaticIcon from 'components/UI/icons/Informatic';
import VersionIcon from 'components/UI/icons/Version';
import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import useStyles from './useStyles';

interface RuleCardProps {
  os: string;
}

interface ContentDetail {
  id: string;
  label: string;
  icon: any;
  borderColor: string;
}

// interface OSContent {
//   [key: string]: ContentDetail;
// }

const ContentMapping = (
  t: TFunction<'translation', undefined>
): Record<string, ContentDetail> => ({
  allow: {
    id: 'allow',
    label: t('MTD.POLICIES.NETWORK_POLICY.SAFE'),
    icon: (classes: any) => <CheckIcon className={classes.icon} />,
    borderColor: 'green',
  },
  deny: {
    id: 'deny',
    label: t('MTD.POLICIES.APP_POLICY.SUSPICIOUS'),
    icon: (classes: any) => <CancelIcon className={classes.icon} />,
    borderColor: 'red',
  },
  ooc: {
    id: 'ooc',
    label: 'OOC',
    icon: (classes: any) => <ReportProblemIcon className={classes.icon} />,
    borderColor: 'orange',
  },
  android: {
    id: 'android',
    label: t('GLOBAL.ANDROID'),
    icon: (classes: any) => <AndroidIcon className={classes.icon} />,
    borderColor: 'green',
  },
  ios: {
    id: 'ios',
    label: t('GLOBAL.IOS'),
    icon: (classes: any) => <i className={`fab fa-apple  ${classes.icon} `} />,
    borderColor: 'blue',
  },
  developer: {
    id: 'developer',
    label: t('GLOBAL.DEVELOPER'),
    icon: (classes: any) => <PersonIcon className={classes.icon} />,
    borderColor: 'darkGreen',
  },
  package: {
    id: 'package',
    label: t('MTD.POLICIES.APP_POLICY.PACKAGE_ID'),
    icon: (classes: any) => <InformaticIcon classList={classes.customIcon} />,
    borderColor: 'darkGreen',
  },
  bundle: {
    id: 'bundle',
    label: t('MTD.POLICIES.APP_POLICY.BUNDLE_ID'),
    icon: (classes: any) => <InformaticIcon classList={classes.customIcon} />,
    borderColor: 'darkGreen',
  },
  version: {
    id: 'version',
    label: t('MTD.POLICIES.APP_POLICY.VERSION'),
    icon: (classes: any) => <VersionIcon classList={classes.customIcon} />,
    borderColor: 'darkGreen',
  },
});

const RuleCard: React.FC<RuleCardProps> = ({ os }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const osMap = ContentMapping(t)[os];
  if (!osMap) {
    return null;
  }
  return (
    <div className={classes.statisticCard}>
      <div
        className={cc([
          classes.backgroundCircle,
          {
            [classes.blueBorder]: osMap.borderColor === 'blue',
            [classes.darkGreenBorder]: osMap.borderColor === 'darkGreen',
            [classes.greenBorder]: osMap.borderColor === 'green',
            [classes.orangeBorder]: osMap.borderColor === 'orange',
            [classes.redBorder]: osMap.borderColor === 'red',
          },
        ])}
      >
        {osMap.icon(classes)}
      </div>
      <div className={classes.text}>
        <div className={classes.label}>{osMap.label}</div>
      </div>
    </div>
  );
};

export default RuleCard;
