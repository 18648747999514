import cc from 'classcat';
import React, { useEffect, useMemo, useState } from 'react';
import useStyles from './useStyles';
import { useTranslation } from 'react-i18next';

export interface IRpmChartProps {
  asScore?: boolean;
  percentage?: number;
  stretch?: boolean;
  width?: string;
}

const RPM_MIN = 282.7431;
const RPM_MAX = 0;

const RpmChart: React.FC<IRpmChartProps> = ({
  asScore,
  percentage,
  stretch,
  width,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [strokeDashoffset, setStrokeDashoffset] = useState<number>(RPM_MIN);
  const [animated, setAnimated] = useState<boolean>(false);

  const safePercentage = useMemo(
    () => (typeof percentage === 'number' ? percentage : -1),
    [percentage]
  );

  const score = useMemo(
    () =>
      asScore
        ? `${(safePercentage * 10).toFixed(1)}`
        : `${Math.floor(safePercentage * 100)}%`,
    [asScore, safePercentage]
  );

  useEffect(() => {
    if (!animated && safePercentage !== -1) {
      setStrokeDashoffset(RPM_MIN - RPM_MIN * safePercentage);
      setAnimated(true);
    }
  }, [animated, safePercentage]);

  return (
    <div className={classes.rpm}>
      <svg
        version="1.1"
        viewBox="0 0 220 110"
        width={stretch ? '100%' : width}
        xmlns="http://www.w3.org/2000/svg"
      >
        <filter id="shadow">
          <feDropShadow dx="-1" dy="-1" stdDeviation="3" floodOpacity="0.5" />
        </filter>
        <circle
          cx="109"
          cy="0"
          fill="transparent"
          r="90"
          strokeDasharray={RPM_MIN}
          strokeDashoffset={RPM_MAX}
        />
        {safePercentage >= 0 ? (
          <>
            <circle
              cx="109"
              cy="0"
              fill="transparent"
              filter="url(#shadow)"
              className={cc([
                {
                  [classes.high]: safePercentage >= 0.7,
                  [classes.low]: safePercentage < 0.4,
                  [classes.medium]:
                    safePercentage >= 0.4 && safePercentage < 0.7,
                },
              ])}
              r="90"
              strokeDasharray={RPM_MIN}
              strokeDashoffset={RPM_MAX}
              style={{ strokeDashoffset }}
            />
            <circle
              cx="109"
              cy="0"
              fill="transparent"
              className={classes.stripe}
              r="90"
              strokeDasharray="6"
              strokeDashoffset={RPM_MAX}
              style={{ strokeDashoffset: '90px' }}
            />
            <text
              className={classes.caption}
              textAnchor="middle"
              x="50%"
              y="109px"
            >
              {score}
            </text>
          </>
        ) : (
          <text
            className={cc([classes.caption, classes.notApplicable])}
            textAnchor="middle"
            x="50%"
            y="109px"
          >
            {t('GLOBAL.NOT_AVAILABLE')}
          </text>
        )}
      </svg>
    </div>
  );
};

export default RpmChart;
