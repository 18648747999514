import { ServiceHandlerFactory } from 'utils/serviceUtils';
import { fetchUserActivationsParamsMapping } from 'components/main/activations/DeviceActivations.mapping';

const userActivationsBase = 'api/zapp/v1/user-activations';

export const fetchAllUserActivations = ServiceHandlerFactory({
  axios: {
    url: userActivationsBase,
  },
  paramSchema: fetchUserActivationsParamsMapping,
});

export const fetchUserActivationById = ServiceHandlerFactory({
  axios: {
    url: `${userActivationsBase}/:id`,
  },
});

export const createUserActivation = ServiceHandlerFactory({
  axios: {
    url: `${userActivationsBase}`,
    method: 'post',
  },
});

export const updateUserActivation = ServiceHandlerFactory({
  axios: {
    url: `${userActivationsBase}/:id`,
    method: 'put',
  },
});

export const deleteUserActivation = ServiceHandlerFactory({
  axios: {
    url: `${userActivationsBase}/:id`,
    method: 'delete',
  },
});

export const createBulkUserActivation = ServiceHandlerFactory({
  axios: {
    url: `${userActivationsBase}/bulk`,
    method: 'post',
  },
});

// bulk resend invites - takes a body --- array of ids

export const bulkResendInvites = ServiceHandlerFactory({
  axios: {
    url: `${userActivationsBase}/resend-invitation`,
    method: 'post',
  },
});

// takes a body --- array of ids
export const bulkDeleteActivations = ServiceHandlerFactory({
  axios: {
    url: `${userActivationsBase}`,
    method: 'delete',
  },
});
