import _ from 'lodash';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
// action types
import AT from 'actions/ActionTypes';
import { AUTH_CLEAR_USER } from 'reducers/AuthReducers';

export const TEAMS_UPDATE_AVAILABLE = 'TEAMS_UPDATE_AVAILABLE';
export const CLEAR_SELECTED_TEAMS_USERS_TABLE =
  'CLEAR_SELECTED_TEAMS_USERS_TABLE';
export const UPDATE_SELECTED_TEAMS_USERS_TABLE =
  'UPDATE_SELECTED_TEAMS_USERS_TABLE';
export const PERSIST_SELECTED_TEAMS_FILTER = 'PERSIST_SELECTED_TEAMS_FILTER';
export const CLEAR_PERSIST_SELECTED_TEAMS_FILTER =
  'CLEAR_PERSIST_SELECTED_TEAMS_FILTER';
// selectors
export const getAvailableTeams = state => state.teams.available;
export const getAvailableTeamsAsList = createSelector(
  getAvailableTeams,
  teamsHash => {
    return Object.keys(teamsHash).map(teamId => {
      return {
        id: teamId,
        ...teamsHash[teamId],
      };
    });
  }
);

export const getAvailableTeamsAsMultiSelectList = createSelector(
  getAvailableTeams,
  teamsHash => {
    return Object.keys(teamsHash).map(teamId => ({
      label: teamsHash[teamId].name,
      value: teamId,
    }));
  }
);

export const getSelectedTeamName = (state, teamId) => {
  const { teams } = state;

  if (teams.available[teamId]) {
    return teams.available[teamId].name;
  }

  return undefined;
};

export const getSelectedTeamMultiSelect = (state, teamId) => {
  const { teams, uiSettings } = state;
  const selectedTeam = {};

  if (teams.available[teamId]) {
    selectedTeam.label = teams.available[teamId].name;
    selectedTeam.value = uiSettings.teams.selected;
    return selectedTeam;
  }

  return undefined;
};

export const getInitialSelectedTeamMultiSelect = (selected, available) => {
  const selectedTeam = {};

  if (available[selected]) {
    selectedTeam.label = available[selected].name;
    selectedTeam.value = selected;
    return [selectedTeam];
  }

  return undefined;
};

/**
 * transforms hash into a list consumable by a Select component's
 * "generateSelectOptions" function
 */
export const getAvailableTeamsAsSelectList = createSelector(
  getAvailableTeams,
  teamsHash => {
    return Object.keys(teamsHash).map(teamId => {
      return {
        value: teamId,
        label: teamsHash[teamId].name,
      };
    });
  }
);

// actions
export const updateAvailableTeams = createAction(TEAMS_UPDATE_AVAILABLE);

export const updateTeamsFilter = createAction(
  UPDATE_SELECTED_TEAMS_USERS_TABLE
);

// reducer
const initialState = {
  selected: null,
  selectedTeamsUsersTable: [],
  selectedTeamsAppsTable: [],
  available: {},
};

const teamsArrayToHash = (state, action) => {
  const hashedTeams = action.payload.reduce((acc, { id, ...teamRest }) => {
    acc[id] = teamRest;
    return acc;
  }, {});

  return {
    ...state,
    available: hashedTeams,
  };
};

const updateUsersTableTeamsFilter = (state, action) => {
  return {
    ...state,
    [`selectedTeams${action.payload.table}Table`]: [...action.payload.values],
  };
};

const TeamReducer = handleActions(
  {
    TEAMS_UPDATE_AVAILABLE: teamsArrayToHash,
    UPDATE_SELECTED_TEAMS_USERS_TABLE: updateUsersTableTeamsFilter,
    [AT.TEAM_SELECT]: (state, action) => {
      const selectedTeam = action.payload.selected;
      if (
        state.selected !== action.payload.selected &&
        !_.isEmpty(state.available)
      ) {
        const initialTeamInFilter = getInitialSelectedTeamMultiSelect(
          selectedTeam,
          state.available
        );
        return {
          ...state,
          selected: action.payload.selected,
          selectedTeamsUsersTable: initialTeamInFilter,
          selectedTeamsAppsTable: initialTeamInFilter,
        };
      }

      return { ...state };
    },
    [AUTH_CLEAR_USER]: () => {
      return initialState;
    },
    [AT.USER_LOGOUT]: () => {
      return initialState;
    },
  },
  initialState
);

export default TeamReducer;
