import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ config, palette }: any) =>
  createStyles({
    checkboxLabel: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      '& > div': {
        paddingRight: '5px',
      },
    },
    description: {
      fontSize: '15px',
      paddingTop: '17px',
    },
    dropdown: {
      '& input': { marginTop: '2px', marginBottom: '1px' },
    },
    enableColor: {
      color: palette.text.secondary,
    },
    enableSection: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: '24px',
    },
    enableText: {
      fontSize: '19px',
    },
    enableToggle: {
      paddingLeft: '33px',
    },
    enableTooltip: {
      marginTop: '3px',
      marginLeft: '-25px',
    },
    heading: {
      fontSize: '17px',
      fontWeight: 'bold',
      marginTop: '15px',
    },
    inputField: {
      paddingRight: '12px',
    },
    inputGrid: {
      marginTop: '18px',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr ',
      position: 'relative',
      '& > span': {
        backgroundColor: palette.background.default,
        border: `1px solid ${config.colors.schoolbus}`,
        borderRadius: '0px',
        color: palette.text.primary,
        position: 'absolute',
        maxWidth: '100%',
        top: '40px',
        whiteSpace: 'normal',
      },
      width: '370px',
    },
    note: {
      color: palette.text.primary,
      fontSize: '13px',
      paddingTop: '13px',
    },
    notificationNotice: {
      backgroundColor: palette.background.default,
      border: `1px solid ${config.colors.schoolbus}`,
      borderRadius: '0px',
      color: palette.text.primary,
      marginTop: '8px',
      marginBottom: '8px',
      padding: '8px',
    },
    notificationNoticeDisabled: {
      backgroundColor: palette.background.default,
      border: `1px solid ${palette.text.primary}`,
      borderRadius: '0px',
      color: palette.text.primary,
      marginTop: '8px',
      marginBottom: '8px',
      padding: '8px',
    },
    sectionGrid: {
      alignItems: 'start',
      display: 'grid',
      gridColumnGap: '48px',
      gridRowGap: '32px',
      gridTemplateColumns: '1fr 1fr',
      justifyItems: 'start',
      paddingLeft: '54px',
      paddingTop: '10px',
    },
    warningCard: {
      '& > div > div:nth-child(1)': {
        fontSize: '26px',
        paddingBottom: '25px',
        paddingTop: '25px',
      },
      '& > div > div:nth-child(2)': {
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          height: '8px',
          opacity: '30%',
          width: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          background: palette.tables.scrollbar,
          borderRadius: '10px',
        },
      },
    },
    warningCardContent: {
      marginTop: '-10px',
    },
    warningSubSection: {
      width: '490px',
    },
    warningTypesDisabled: {
      color: palette.text.primary,
    },
    warningTypeCheckboxes: { paddingTop: '5px', paddingLeft: '15px' },
  })
);

export default useStyles;
