import { fetchTableData } from './utils';
import { ISidebarProp, SelectedRowProps } from './models';

const memoizeQuery = (
  activeIndex: number,
  preSelectFirstRowData: boolean,
  setPreSelectFirstRowData: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectFinding: React.Dispatch<
    React.SetStateAction<ISidebarProp | undefined>
  >,
  setTotalDataCount: React.Dispatch<React.SetStateAction<number>>,
  searchQuery: string,
  policyId: string,
  selectedRow: SelectedRowProps[],
  fullTableData: any,
  setFullTableData: React.Dispatch<React.SetStateAction<{}>>,
  setCompareBuildOptions: (build1Data: any, build2Data: any) => void,
  query: { [key: string]: unknown }
) => {
  return async () => {
    const data: any = await fetchTableData(
      setTotalDataCount,
      searchQuery,
      policyId,
      query,
      selectedRow,
      fullTableData,
      setFullTableData,
      setCompareBuildOptions
    );

    if (activeIndex === 0 && preSelectFirstRowData && data?.tableData?.length) {
      setSelectFinding(data?.tableData[0]);
      setPreSelectFirstRowData(false);
    }
    return {
      data: data?.tableData ?? [],
      count: data?.totalResults ?? 0,
    };
  };
};

export default memoizeQuery;
