import MUITooltip from '@material-ui/core/Tooltip';
import Input from '@material-ui/icons/Input';
import { fetchGroupPolicyMetadata } from 'api/MTDPolicy';
import cc from 'classcat';
import { TClassList } from 'components/UI/Table/models';
import { ITabProps } from 'components/UI/Tabs';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DetailTable from '../../common/DetailTable';
import { IPolicyMetaData } from '../../common/DetailTable/models';
import { IRowData } from '../Devices/models';
import { IAppDetails, IDetail, ILabelMapping, IResolvedData } from './models';
import OSLabelIcon from 'components/UI/icons/OSLabelIcon';
import { TFunction, useTranslation } from 'react-i18next';

const checkValue = (tableValue: boolean | undefined, asEnabled?: boolean) =>
  new Map<undefined | boolean, string | undefined>([
    [undefined, undefined],
    [true, asEnabled ? 'Enabled' : 'Yes'],
    [false, asEnabled ? 'Disabled' : 'No'],
  ]).get(tableValue);

export const fetchPolicyMetadata = async (groupId: string) => {
  let result: IPolicyMetaData | undefined;
  try {
    const response = await fetchGroupPolicyMetadata({ groupId: groupId });
    result = response?.data ?? [];
  } catch (e) {
    console.log(e);
  }
  return result;
};

const getDeviceOwnerContent = (
  email?: string,
  firstName?: string,
  lastName?: string
) => {
  if (!email && !firstName && !lastName) {
    return;
  }

  if (!email && (firstName || lastName)) {
    return [firstName, lastName].join(' ');
  }

  if (email && (firstName || lastName)) {
    return [firstName, lastName, `(${email})`].join(' ');
  }

  return email;
};

// Note that if the content field evaluates to undefined, the item will be removed from the table
export const getAllDeviceDetails = (
  classes: TClassList,
  rowData: IRowData,
  module: string,
  closeDrawer?: () => void
): IDetail[] => {
  const { t } = useTranslation();

  return [
    {
      id: 'model',
      label: 'GLOBAL.MODEL',
      content:
        rowData?.model &&
        rowData?.iPhoneModelMap &&
        Object.keys(rowData.iPhoneModelMap).includes(rowData?.model)
          ? rowData.iPhoneModelMap[rowData.model]?.commercialName ??
            rowData?.model
          : rowData?.model,
    },
    {
      id: 'deviceOwner',
      label: 'GLOBAL.DEVICE_OWNER',
      content: getDeviceOwnerContent(
        rowData?.deviceOwner?.email,
        rowData?.deviceOwner?.firstName,
        rowData?.deviceOwner?.lastName
      ),
      filterable: !!rowData?.deviceOwner?.email,
      path: 'deviceOwner.email',
    },
    {
      id: 'zdeviceId',
      label: 'GLOBAL.DEVICE_ID',
      content: rowData?.zdeviceId,
      filterable: true,
    },
    {
      id: 'os.name',
      label: 'GLOBAL.OS',
      content: <OSLabelIcon os={rowData?.os?.type} classList={classes} />,
      filterValue: rowData?.os?.type,
      filterable: true,
    },
    {
      id: 'os.version',
      label: 'GLOBAL.OS_VERSION',
      content: rowData?.os?.version,
      filterable: true,
    },
    {
      hideFromTopSection: !rowData?.os?.patchDate,
      id: 'os.patchDate',
      label: 'GLOBAL.PATCH_DATE',
      content: moment(rowData?.os?.patchDate).format('l'),
    },
    {
      id: 'lastSeen',
      label: 'GLOBAL.LAST_SEEN',
      content: rowData?.lastSeen
        ? moment(rowData.lastSeen).format('LLL')
        : undefined,
    },
    {
      id: 'operator',
      label: 'GLOBAL.OPERATOR',
      content:
        !!rowData?.operator && rowData.operator !== 'Unknown'
          ? rowData.operator
          : undefined,
    },
    {
      id: 'mdmDeviceId',
      label: 'MTD.DEVICES.MDM_ID',
      content: rowData?.mdmDeviceId,
      filterable: true,
    },
    {
      id: 'mamDeviceId',
      label: 'MTD.DEVICES.MAM_ID',
      content: rowData?.mamDeviceId,
      filterable: true,
    },
    {
      id: 'teamName',
      label: 'GLOBAL.TEAM_NAME',
      content: rowData?.teamName,
    },
    {
      id: 'groupName',
      label: 'GLOBAL.GROUP_NAME',
      content: rowData?.groupName,
    },
    {
      id: 'groupId',
      label: 'GLOBAL.GROUP_ID',
      content: rowData?.groupId,
      filterable: true,
    },
    {
      id: 'created',
      label: 'GLOBAL.CREATED',
      content: rowData?.created
        ? moment(rowData?.created).format('LLL')
        : undefined,
    },
    {
      id: 'relatedThreats',
      label: 'MTD.DEVICES.RELATED_THREATS',
      content:
        !!rowData?.zdeviceId && !!module ? (
          <>
            <MUITooltip
              title={t('MTD.DEVICES.FIND_THREATS_FOR_THIS_DEVICE') || ''}
              className={classes.threatsTooltip}
            >
              <Link
                onClick={() =>
                  typeof closeDrawer === 'function' && closeDrawer()
                }
                to={`/console/${module}/threats/?device.zdeviceId=${rowData?.zdeviceId}&duration=8`}
                className={classes.threatsLink}
              >
                <Input className={classes.inputIcon} />
              </Link>
            </MUITooltip>
          </>
        ) : (
          <>
            <MUITooltip
              title={t('MTD.DEVICES.FIND_THREATS_FOR_THIS_DEVICE') || ''}
              className={classes.threatsTooltip}
            >
              <Link
                onClick={() =>
                  typeof closeDrawer === 'function' && closeDrawer()
                }
                // should pass device id
                to={`/console/${module}/threats/?deviceId=${rowData?.id}&duration=8`}
                className={classes.threatsLink}
              >
                <Input className={classes.inputIcon} />
              </Link>
            </MUITooltip>
          </>
        ),
    },
    {
      id: 'isJailbroken',
      label: 'GLOBAL.ROOTED',
      content: checkValue(rowData?.isJailbroken),
      oses: ['android', 'ios'],
      isDeviceStatus: true,
      filterable: true,
    },
    {
      id: 'isUsbDebuggingEnabled',
      label: 'MTD.DEVICES.USB_DEBUG_MODE',
      content: checkValue(rowData?.isUsbDebuggingEnabled, true),
      oses: ['android', 'ios'],
      isDeviceStatus: true,
      filterable: true,
    },
    {
      id: 'developerOptionsOn',
      label: 'GLOBAL.DEVELOPER_MODE',
      content: checkValue(rowData?.developerOptionsOn, true),
      oses: ['android', 'ios'],
      isDeviceStatus: true,
      filterable: true,
    },
    {
      id: 'isUnknownSourceEnabled',
      label: 'GLOBAL.3RD_PARTY_APP_STORE',
      content: checkValue(rowData?.isUnknownSourceEnabled, true),
      oses: ['android', 'ios'],
      isDeviceStatus: true,
      filterable: true,
      path: 'zappInstance.isUnknownSourceEnabled',
    },
    {
      id: 'stagefrightVulnerable',
      label: 'GLOBAL.STAGEFRIGHT_VULNERABLE',
      content: checkValue(rowData?.stagefrightVulnerable),
      oses: ['android', 'ios'],
      isVulnerability: true,
    },
    {
      id: 'diskNotEncrypted',
      label: 'GLOBAL.DEVICE_ENCRYPTION',
      content: checkValue(rowData?.diskNotEncrypted),
      oses: ['android', 'ios'],
      isVulnerability: true,
    },
    {
      id: 'lockScreenUnprotected',
      label: 'GLOBAL.SCREEN_LOCK',
      content: checkValue(rowData?.lockScreenUnprotected),
      oses: ['android', 'ios'],
      isDeviceStatus: true,
      filterable: true,
    },
  ];
};

export const PERMISSION_LABELS: ILabelMapping = {
  CAMERA: 'Allow Camera Access',
  DEVICE_LOCATION_STATE: 'Device Location',
  LOCATION: 'Allow Location Access',
  NOTIFICATION: 'Enable Notifications',
  PRECICE_LOCATION: 'Precise Location',
  VPN: 'Allow VPN Configuration',
  VPN_STATE: 'VPN State',
};

export const STATUS_LABELS: ILabelMapping = {
  ACTIVE: 'Active',
  ALLOW: 'Allow',
  ALLOW_WHILE_USING_APP: 'Allow',
  DISABLED: 'Disabled',
  ENABLED: 'Enabled',
  INACTIVE: 'Inactive',
  UNKNOWN: 'Unknown',
};

export const POLICY_LABELS: ILabelMapping = {
  'Threat Android': 'Threat Policy',
  'Threat iOS': 'Threat Policy',
  Privacy: 'Privacy Policy',
  'App Settings': 'App Settings',
  Phishing: 'Phishing Policy',
  'App Policy Android v2': 'App Policy',
  'App Policy iOS v2': 'App Policy',
};

export const getAllAppDetails = (
  rowData: IRowData,
  policyMetadata: IPolicyMetaData[],
  classes: TClassList
): IAppDetails[] =>
  rowData?.zappInstance?.[0]
    ? rowData.zappInstance.map((zappInstance) => ({
        id: zappInstance.id,
        details: [
          {
            id: 'zappInstance.zappName',
            label: 'MTD.DEVICES.MTD_APP_NAME',
            content: zappInstance?.name,
            showInAppFocusSection: true,
            filterable: true,
          },
          {
            id: 'zappInstance.version',
            label: 'GLOBAL.APP_VERSION',
            content: zappInstance?.version,
            showInAppFocusSection: true,
            filterable: true,
          },
          {
            id: 'zappInstance.buildNumber',
            label: 'GLOBAL.APP_BUILD',
            content: zappInstance?.buildNumber,
            showInAppFocusSection: true,
            filterable: true,
          },
          {
            id: 'zappInstance.bundleId',
            label: 'MTD.POLICIES.APP_POLICY.BUNDLE_ID',
            content: zappInstance?.bundleId,
            showInAppFocusSection: true,
            filterable: true,
          },
          {
            id: 'zappInstance.lastSeen',
            label: 'GLOBAL.APP_LAST_SEEN',
            content: zappInstance?.lastSeen
              ? moment(zappInstance.lastSeen).format('LLL')
              : undefined,
            showInAppFocusSection: true,
          },
          {
            id: 'zappInstance.groupId',
            label: 'GLOBAL.GROUP_ID',
            content: zappInstance?.groupId,
            filterable: true,
          },
          {
            id: 'zappInstance.externalTrackingId1',
            label: 'GLOBAL.TRACKING_ID_1',
            content: zappInstance?.externalTrackingId1,
            filterable: true,
          },
          {
            id: 'zappInstance.externalTrackingId2',
            label: 'GLOBAL.TRACKING_ID_2',
            content: zappInstance?.externalTrackingId2,
            filterable: true,
          },
          {
            id: 'zappInstance.zversion',
            label: 'GLOBAL.Z_DEFEND_VERSION',
            content: zappInstance?.zversion,
            filterable: true,
          },
          {
            id: 'zappInstance.zbuildNumber',
            label: 'GLOBAL.Z_DEFEND_BUILD',
            content: zappInstance?.zbuildNumber,
            filterable: true,
          },
          ...((zappInstance?.policiesInfo
            ?.map((policyInfo, index) => {
              const metaData = policyMetadata.find(
                ({ jsonHash, type }) =>
                  jsonHash === policyInfo.hash && type === policyInfo.type
              );

              return {
                id: POLICY_LABELS?.[policyInfo.type] ?? 'policy-' + index,
                label: POLICY_LABELS?.[policyInfo.type] ?? policyInfo.type,
                content:
                  policyInfo?.hash === metaData?.jsonHash
                    ? `Updated ${
                        policyInfo?.deployedAt
                          ? moment(policyInfo.deployedAt).format('LLL')
                          : ''
                      }`
                    : `Deployed ${
                        policyInfo?.downloadedAt
                          ? moment(policyInfo.downloadedAt).format('LLL')
                          : ''
                      }`,
                prefixContent: (
                  <div
                    className={cc([
                      classes.statusDot,
                      {
                        [classes.green]:
                          policyInfo?.hash === metaData?.jsonHash,
                        [classes.red]: policyInfo?.hash !== metaData?.jsonHash,
                      },
                    ])}
                  />
                ),
              };
            })
            ?.filter((item) => !!item) as IDetail[]) ?? []),
        ],
        permissions: zappInstance?.permissionsState?.[0]
          ? zappInstance.permissionsState.map(
              ({ permissionName, permissionState }) => ({
                id: permissionName,
                label: PERMISSION_LABELS?.[permissionName] ?? permissionName,
                // TODO: Uncomment/rework this when we find out all possible permissions and colors.
                // We can't guess what color these dots should be without any context.
                /* prefixContent: (
          <div
            className={cc([
              classes.statusDot,
              {
                [classes.green]:
                  STATUS_LABELS?.[permissionState]?.color === 'green',
              },
            ])}
          />
        ), */
                content: STATUS_LABELS?.[permissionState] ?? permissionState,
              })
            )
          : [],
      }))
    : [];

export const getAllDetails = (
  classes: TClassList,
  closeDrawer?: () => void,
  rowData?: IRowData,
  policyMetadata?: IPolicyMetaData[],
  module?: string
): IResolvedData => {
  if (!rowData) {
    return {
      allAppDetails: [],
      allDeviceDetails: [],
      topSectionDeviceDetails: [],
      topSectionFocusAppDetails: [],
      topSectionCombinedDetails: [],
      statusSectionDetails: [],
      vulnerabilitySectionDetails: [],
    };
  }

  const allAppDetails = getAllAppDetails(
    rowData,
    policyMetadata ?? [],
    classes
  );
  const allDeviceDetails = getAllDeviceDetails(
    classes,
    rowData,
    module ?? '',
    closeDrawer
  );

  const appDetailsForCombined =
    allAppDetails?.length === 1 && allAppDetails?.[0]?.details?.[0]
      ? allAppDetails[0].details.filter(
          ({ showInAppFocusSection }) => !showInAppFocusSection
        )
      : [];

  const deviceDetailsForCombined = allDeviceDetails?.[0]
    ? allDeviceDetails.filter(({ isPolicy }) => isPolicy)
    : [];

  return {
    allAppDetails,
    allDeviceDetails,
    topSectionDeviceDetails: allDeviceDetails.filter(
      ({ hideFromTopSection, isDeviceStatus, isVulnerability, isPolicy }) =>
        !hideFromTopSection && !isDeviceStatus && !isVulnerability && !isPolicy
    ),
    topSectionFocusAppDetails:
      allAppDetails?.length === 1 && allAppDetails?.[0]?.details?.[0]
        ? allAppDetails[0].details.filter(
            ({ showInAppFocusSection }) => showInAppFocusSection
          )
        : [],
    topSectionCombinedDetails: [
      ...appDetailsForCombined,
      ...deviceDetailsForCombined,
    ],
    statusSectionDetails: allDeviceDetails?.[0]
      ? allDeviceDetails.filter(({ isDeviceStatus }) => !!isDeviceStatus)
      : [],
    vulnerabilitySectionDetails: allDeviceDetails?.[0]
      ? allDeviceDetails.filter(({ isVulnerability }) => !!isVulnerability)
      : [],
  };
};

export const getTabs = (
  classes: TClassList,
  details: IResolvedData,
  t: TFunction<'translation', undefined>
): ITabProps[] =>
  [
    {
      title: t('GLOBAL.PERMISSIONS'),
      show:
        details?.allAppDetails?.length === 1 &&
        !!details?.allAppDetails?.[0]?.permissions?.[0],
      content: (
        <div className={cc([classes.drawerCard, classes.tabMargin])}>
          <div className={classes.permissionsTab}>
            <DetailTable
              details={details?.allAppDetails?.[0]?.permissions ?? []}
            />
          </div>
        </div>
      ),
    },
  ].filter(({ show }) => show) ?? [];
