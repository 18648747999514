import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
// Utils
import { renderForensics } from 'utils/componentUtils';
import MalwareFamily from 'mappings/MalwareFamilyMapping';
/* Component */
import GenericDataFilter from 'components/shared/GenericDataFilter';

const styles = ({ palette }) => {
  return {
    wrapper: {
      display: 'flex',
      alignSelf: 'flex-start',
      flexDirection: 'column',
      width: '50%',
    },
    dataWrapper: {
      display: 'flex',
      flexDirection: 'row',
      padding: 5,
      justifyContent: 'space-between',
    },
    label: {
      color: palette.text.secondary,
      fontWeight: 'bold',
      width: '25%',
    },
    labelValue: {
      width: '75%',
      display: 'flex',
    },
    malwareContainer: {
      width: '75%',
      display: 'flex',
    },
  };
};

class ThreatsMalware extends Component {
  render() {
    const { props } = this;
    return (
      <div className={props.classes.wrapper}>
        {renderForensics('malwareInfo', props)}
        {props.threat.malwareInfo.matches && (
          <div className={props.classes.dataWrapper}>
            <div className={props.classes.label}>{'Malware List'}</div>
            <div className={props.classes.malwareContainer}>
              {props.threat.malwareInfo.matches.map((malware, index) => {
                return (
                  <div key={index}>
                    <GenericDataFilter
                      tableValue={malware.name}
                      path={'malwareInfo.matches.name'}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {props.threat.malwareFamilyInfo && (
          <div className={props.classes.dataWrapper}>
            <div className={props.classes.label}>{'Malware Family'} </div>
            <div className={props.classes.malwareContainer}>
              {props.threat.malwareFamilyInfo.map(item => {
                return (
                  <div key={item.family}>
                    <GenericDataFilter
                      tableValue={MalwareFamily[item.family].name}
                      path={'malwareFamilyInfo.familyName'}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ThreatsMalware);
