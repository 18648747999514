import { createStyles, makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    circleIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '55px',
      height: '55px',
      borderRadius: '50%',
      borderColor: palette.primary.light,

      border: '2px solid', // Add the border styling
    },
    avtarStyle: {
      width: '50px',
      height: '50px',
      color: palette.common.white,
      fontSize: '16px',
      fontWeight: 'bold',
      backgroundColor: '#263238',
    },
  })
);
