import { ISelectItem } from 'components/UI/input/Select';
import { IMDMRowData } from '../../emmTable/MDM/models';
import { IConnector, TModalMode } from '../../models';
import { IIntuneFormData, IMSAuthResponse } from './models';

export const initialValues = (
  modalMode: TModalMode = 'ADD',
  rowData?: IMDMRowData,
  savedIntuneFormData?: IIntuneFormData,
  msAuthResponse?: IMSAuthResponse,
  connector?: IConnector,
  availableTeams?: ISelectItem[],
  selectedTeamId?: string
): IIntuneFormData => ({
  // Refill aad_apps_consent with saved data if authenticating with Microsoft
  aad_apps_consent: savedIntuneFormData?.aad_apps_consent?.[0]
    ? savedIntuneFormData.aad_apps_consent.map(item => {
        // Don't inject msAuthResponse unless it exists and the item is authenticating
        if (
          !item?.authenticating ||
          !msAuthResponse?.admin_consent ||
          !msAuthResponse?.tenant
        ) {
          return item;
        }

        // Inject msAuthResponse into the item that is authenticating, and disable authenticating toggle
        return {
          ...item,
          admin_consent: msAuthResponse.admin_consent,
          authenticated: true,
          authenticating: false,
          tenant: msAuthResponse.tenant,
        };
      })
    : // Fill with aad_apps data and initialize to "unauthenticated" if creating/editing connection
      connector?.externalConsentParameters?.aad_apps.map(aadApp => ({
        ...aadApp,
        authenticated: modalMode === 'CLONE',
        authenticating: false,
      })) ?? [],
  baseUrl:
    savedIntuneFormData?.baseUrl ??
    rowData?.baseUrl ??
    connector?.externalConsentParameters?.base_url ??
    '',
  cloud:
    savedIntuneFormData?.cloud ??
    rowData?.auth?.cloud ??
    connector?.externalConsentParameters?.cloud ??
    '',
  connectionId: savedIntuneFormData?.connectionId ?? rowData?.id ?? '',
  connector,
  connectorTypeId: connector?.id ?? '',
  modalMode,
  mode: connector?.supportedModes?.[0] ?? '',
  name:
    modalMode === 'CLONE'
      ? `${rowData?.name} (Cloned)`
      : savedIntuneFormData?.name ?? rowData?.name ?? '',
  selectedTeamId: savedIntuneFormData?.selectedTeamId ?? selectedTeamId ?? '',
  team: !!availableTeams?.[0]
    ? availableTeams.find(
        ({ value }) =>
          value ===
          (savedIntuneFormData?.team?.value ??
            rowData?.teamId ??
            selectedTeamId)
      )
    : undefined,
});
