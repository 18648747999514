import {
  EPartnerBrandingDefaults,
  ESupportEmailAddressType,
  EThemePreference,
  IPartnerBranding,
} from './models';

export const defaultImages: { [key: string]: string } = {
  consoleLogo: 'logo.svg',
  emailLogo: 'logo.svg',
  favicon: 'favicon.ico',
  loginBackgroundImage: `public-light.jpg`,
  loginLogo: `ZconsoleLogoWhite.svg`,
  darkModeConsoleLogo: 'logo.svg',
  darkModeLoginBackgroundImage: `public-dark.jpg`,
  darkModeLoginLogo: `ZconsoleLogoBlack.svg`,
};

const initialValues = (data: Partial<IPartnerBranding>) => ({
  appStoreAndroidLink: data?.appStoreAndroidLink ?? '',
  appStoreIosLink: data?.appStoreIosLink ?? '',
  consoleLogo: data.consoleLogo ?? defaultImages.consoleLogo,
  consoleTitle: data?.consoleTitle || EPartnerBrandingDefaults.consoleTitle,
  darkModeConsoleLogo:
    data.darkModeConsoleLogo ?? defaultImages.darkModeConsoleLogo,
  darkModeLoginBackgroundImage:
    data?.darkModeLoginBackgroundImage ??
    defaultImages.darkModeLoginBackgroundImage,
  darkModeLoginLogo: data?.darkModeLoginLogo ?? defaultImages.darkModeLoginLogo,
  documentationLink: data?.documentationLink ?? '',
  disableTemplateContactBlock: (data?.supportEmailAddress ?? '')?.length > 0,
  emailLogo: data.emailLogo ?? defaultImages.emailLogo,
  favicon: data.favicon ?? defaultImages.favicon,
  fromEmailAddress: data?.fromEmailAddress ?? '',
  loginBackgroundImage:
    data?.loginBackgroundImage ?? defaultImages.loginBackgroundImage,
  loginLogo: data?.loginLogo ?? defaultImages.loginLogo,
  // mobileAppLink: data?.mobileAppLink ?? '',
  mobileAppName: data?.mobileAppName ?? '',
  privacyPolicyLink: data?.privacyPolicyLink ?? '',
  replyToEmailAddress: data?.replyToEmailAddress ?? '',
  supportEmailAddress: data?.supportEmailAddress ?? '',
  supportEmailAddressType:
    data?.disableTemplateContactBlock
      ? ESupportEmailAddressType.NONE
      : (data?.supportEmailAddress === 'support@zimperium.com' || !data?.supportEmailAddress)
      ? ESupportEmailAddressType.DEFAULT
      : ESupportEmailAddressType.CUSTOM,
  termsAndConditionsLink: data?.termsAndConditionsLink ?? '',
  themePreference: {
    label: !!data?.themePreference
      ? EThemePreference[data.themePreference as keyof typeof EThemePreference]
      : 'Dark',
    value: !!data?.themePreference ? data.themePreference : 'dark',
  },
  zdefendModuleText:
    data?.zdefendModuleText || EPartnerBrandingDefaults.zdefendModuleText,
  mtdModuleText: data?.mtdModuleText || EPartnerBrandingDefaults.mtdModuleText,
  zscanModuleText:
    data?.zscanModuleText || EPartnerBrandingDefaults.zscanModuleText,
  zshieldModuleText:
    data?.zshieldModuleText || EPartnerBrandingDefaults.zshieldModuleText,
});

export default initialValues;
