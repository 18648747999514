import { createAction, handleActions } from 'redux-actions';

const MDM_FORM_UPDATE = 'MDM_FORM_UPDATE';
const MDM_CLEAR_FORM = 'MDM_CLEAR_FORM';

export const updateMDMForm = createAction(MDM_FORM_UPDATE);
export const clearMDMForm = createAction(MDM_CLEAR_FORM);

const initialState = { groupsIdsToSync: [] };
export const MDMFormReducer = handleActions(
  {
    MDM_FORM_UPDATE: (state, { payload }) => {
      return { ...state, ...payload };
    },
    MDM_CLEAR_FORM: () => {
      return initialState;
    },
  },

  {},
  {}
);
