import { Card } from '@material-ui/core';
import React, { useContext, useCallback } from 'react';
import { DrawerContext } from 'components/drawer/DrawerProvider';
import { IDrawerContext } from 'components/drawer/DrawerProvider/models';
import useStyles from './useStyles';
import ZShieldAppDrawer from '../../ZShieldAppDrawer';
import AppCardContent from './AppCardContent';
import { IZShieldApp } from '../models';

type IAppCardProps = {
  app: IZShieldApp;
  activeCard: string;
  setActiveCard: (appId: IZShieldApp) => void;
};

const AppCard: React.FC<IAppCardProps> = ({ app, activeCard, setActiveCard }) => {
  const { openDrawer, isOpen: isDrawerOpen } =
    useContext<Partial<IDrawerContext<IZShieldApp>>>(DrawerContext);
  const classes = useStyles();

  const handleOpenDrawer = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setActiveCard(app);
    // user clicks on trashcan should not open drawer
    if ((event.target as HTMLInputElement).closest('.delete--confirm')) {
      return;
    }
    if (!openDrawer) {
      return;
    }
    openDrawer({ drawerProps: app, drawer: ZShieldAppDrawer });
  }, [app, openDrawer, setActiveCard]);

  const isAppActiveInDrawer = useCallback(() => app?.id === activeCard && isDrawerOpen, [activeCard, app?.id, isDrawerOpen]);

  return (
    <Card
      className={`${classes.cardRoot} ${isAppActiveInDrawer() && 'activeCard'}`}
      onClick={handleOpenDrawer as unknown as React.MouseEventHandler<HTMLDivElement>}
    >
      <AppCardContent app={{ ...app }} />
    </Card >
  );
};

export default AppCard;