import _ from 'lodash';
import React from 'react';

// ui
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ZButton from 'UI/Buttons/ZButton';
import Typography from '@material-ui/core/Typography';

// services
import {
  toggleModalDirect,
  openSnackBar,
  toggleModalDiffered,
} from 'utils/storeUtils';
import { publishEvent } from 'utils/eventUtils';
import { syncMDMConnection } from '../../api/MDMService';
import Spinner from '../main/common/Spinner';
import { useTranslation } from 'react-i18next';

export const MDMSyncConfirm = (props) => {
  const { data } = props;
  const { t, ready } = useTranslation();
  const connectionId = _.get(data, 'rowData.id');
  function handleSync() {
    syncMDMConnection({ connectionId })
      .then(() => {
        openSnackBar(`Successfully sync ${data.rowData.name}`);
        publishEvent('table:force-fetch-MDM');
        toggleModalDirect('MDMSyncConfirm', false);
      })
      .catch((error) => {
        openSnackBar(
          `Failed to delete ${data.rowData.name}. ${_.get(
            error,
            'response.data',
            ''
          )}`
        );
      });
  }
  const deviceGroups = _.get(data, 'rowData.connectedGroups');

  if (!ready) {
    return null;
  }

  return (
    <DialogContent>
      <p>{t('MTD.INTEGRATIONS.SYNC_EMM_CONNECTION_DESCRIPTION')}</p>
      <ul className={props.data.classList.groupList}>
        {deviceGroups ? (
          deviceGroups.map((group) => {
            return (
              <li key={_.get(group, 'singleGroup.groupIdsToSync.value')}>
                {' '}
                <Typography variant="body1">
                  {_.get(group, 'singleGroup.groupIdsToSync.label')}
                </Typography>
              </li>
            );
          })
        ) : (
          <div className={props.data.classList.emptyText}>
            <Spinner />
          </div>
        )}
      </ul>
      <DialogActions>
        <ZButton
          styleName="modalCancel"
          action={toggleModalDiffered('MDMSyncConfirm', false)}
          color="secondary"
          buttonText={t('GLOBAL.CANCEL')}
        />
        <ZButton
          action={handleSync}
          buttonType="submit"
          color="primary"
          styleName="modalSave"
          buttonText={t('MTD.INTEGRATIONS.SYNC')}
        />
      </DialogActions>
    </DialogContent>
  );
};

MDMSyncConfirm.defaultProps = {
  data: {
    classList: {},
  },
};

export default MDMSyncConfirm;
