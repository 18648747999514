import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    card: {
      alignItems: 'center',
      backgroundColor: palette.background.default,
      display: 'flex',
    },
    modal: {
      '& .MuiDialog-container > div': {
        position: 'relative',
        overflowY: 'scroll',
        overflowX: 'hidden',
        width: '1100px',
        maxWidth: '1185px',
        minWidth: '600px',
      },
    },
    risk: {
      '& span': {
        marginRight: 10,
      },
      alignItems: 'center',
      display: 'flex',
      textTransform: 'capitalize',
    },
    critical: {
      background: palette.components.statusDot.critical,
    },
    elevated: {
      background: palette.components.statusDot.elevated,
    },
    low: {
      background: palette.components.statusDot.low,
    },
    normal: {
      background: palette.components.statusDot.normal,
    },
    statusDot: {
      borderRadius: '50%',
      height: 12,
      marginRight: 6,
      width: 12,
    },
  })
);

export default useStyles;
