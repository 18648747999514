import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      width: '60%',
      gap: '20px',
    },
    inputCard: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      padding: '30px',
      border: '1px solid #1A445F',
      width: '100%',
      backgroundColor:
        palette.type === 'dark' ? '#18425A' : palette.background.default,
    },
    lightInputCard: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      padding: '30px',
      border: '1px solid #1A445F',
      width: '100%',
      backgroundColor: '#FFFFFF',
    },
    darkInputCard: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      padding: '30px',
      border: '1px solid #1A445F',
      width: '100%',
      backgroundColor: '#263238',
    },
    darkLogoCard: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      padding: '30px',
      gap: '20px',
      backgroundColor: '#263238',
    },
    lightLogoCard: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      padding: '30px',
      gap: '20px',
      backgroundColor: '#FFFFFF',
    },
    noMargin: {
      margin: 0,
    },
    darkTitle: {
      margin: 0,
      color: 'white',
    },
    lightTitle: {
      margin: 0,
      color: 'black',
    },
    logoUpload: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      gap: '20px',
    },
    logoContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '60%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    logoImage: {
      height: '85%',
      display: 'flex',
      alignItems: 'center',
    },
    removeLogoContainer: {
      height: '15%',
      width: '100%',
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'end',
      gap: '10px',
    },
    removeLogoLabel: {
      cursor: 'pointer',
      color: '#2dbdbd',
      fontSize: 'small',
    },
    requiredLogosError: {
      border: '2px solid #be7911',
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: palette.background.default,

      '& span': {
        fontWeight: 'bold',
        textAlign: 'center',
      },
    },
    darkDropZone: {
      padding: '25px',
      outline: '2px dashed #2dbdbd',
    },
    lightDropZone: {
      padding: '25px',
      outline: '2px dashed black',
    },
    fileButton: {
      color: 'black !important',
      backgroundColor: '#e6e6e6 !important',
    },
    uploadPrompt: {
      color: 'black',
    },
    colorButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      border: '1px solid #1A445F',
      gap: '15px',
      backgroundColor: '#FFFFFF',
    },
    colorButtonContainerDark: {
      display: 'flex',
      flexDirection: 'row',
      border: '1px solid #1A445F',
      gap: '15px',
      backgroundColor: '#263238',
    },
    colorInputs: {
      display: 'flex',
      flexDirection: 'row',
      gap: '80px',
      '& > label': {
        '& > span': {
          color: 'black !important',
        },
        '& > div': {
          '& > div': {
            '& > input': {
              color: 'black !important',
              backgroundColor: '#FFFFFF',
            },
            '& > div': {
              '& > span': {
                color: 'black !important',
              },
            },
          },
        },
      },
    },
    colorInputsDark: {
      display: 'flex',
      flexDirection: 'row',
      gap: '80px',
      '& > label': {
        '& > span': {
          color: '#FFFFFF !important',
        },
        '& > div': {
          '& > div': {
            '& > input': {
              color: '#FFFFFF !important',
              backgroundColor: '#263238 ',
            },
            '& > div': {
              '& > span': {
                color: '#FFFFFF !important',
              },
            },
          },
        },
      },
    },
    exampleButtonContainer: {
      display: 'flex',
      width: '60%',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'inherit',
    },
    exampleButton: {
      display: 'flex',
      width: '100%',
      minHeight: '50px',
      paddingRight: '35px',
    },
  })
);

export default useStyles;
