import ZShieldApps from 'components/main/zShieldApps/ZShieldApps';
import AppsIcon from '@material-ui/icons/Apps';

export const CONSOLE_ZSHIELD_ROUTES = [
  {
    path: '/console/zshield',
    component: ZShieldApps,
    exact: true,
    rqps: ['page', 'size', 'order', 'orderBy', 'teamId'],
    allow: { app_protections: 'view' },
    nav: {
      name: 'Apps',
      order: 2,
      icon: <AppsIcon />,
    },
  },
];
