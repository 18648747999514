import {
  availableTeams as availableTeamsAtom,
  selectedTeam as selectedTeamAtom,
} from 'atoms/teams';
import TeamSelectorRadioForm from 'components/main/policies/common/TeamSelectorRadioForm';
import Button from 'components/UI/Button';
import InputError from 'components/UI/input/InputError';
import RadioGroup, { Radio } from 'components/UI/input/RadioGroup';
import TextField from 'components/UI/input/TextField';
import {
  Field as FormikField,
  Form as FormikForm,
  Formik,
  FormikHelpers,
} from 'formik';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import { IAppPolicyCreateForm } from '../models';
import Schema from './schema';
import useStyles from './useStyles';

export interface IFormProps {
  cloningPolicy?: string;
  defaultName?: string;
  handleClose: () => void;
  handleSubmit: (
    form: IAppPolicyCreateForm,
    actions: FormikHelpers<IAppPolicyCreateForm>
  ) => void;
}

const Form: React.FC<IFormProps> = ({
  cloningPolicy,
  defaultName,
  handleClose,
  handleSubmit,
}) => {
  const scopeBounds = useSelector(
    (state: { user: { scopeBounds: string } }) => {
      const { scopeBounds } = state?.user ?? '';
      return scopeBounds;
    }
  );

  const { t } = useTranslation();

  const [showTeamSelector, setShowTeamSelector] = useState<boolean>(true);

  const classes = useStyles();

  const availableTeams = useRecoilValue(availableTeamsAtom) || [];
  const selectedTeamId = useRecoilValue(selectedTeamAtom);
  const selectedTeam = availableTeams.find(
    ({ value }) => value === selectedTeamId
  );

  const handleScopeChange = useCallback(
    (value: string) => {
      if (!selectedTeamId && value !== 'global') {
        setShowTeamSelector(true);
        return;
      }

      setShowTeamSelector(false);
    },
    [setShowTeamSelector, selectedTeamId]
  );

  const handleTeamChange = useCallback(
    (teamId: string, setFieldValue: (field: string, value: string) => void) => {
      setFieldValue('teamId', teamId);
    },
    []
  );

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: defaultName ?? '',
        bounds: 'team',
        teamId: selectedTeamId ?? '',
        selectedTeamId: selectedTeamId ?? '',
      }}
      onSubmit={handleSubmit}
      validationSchema={Schema(t)}
    >
      {({
        errors,
        handleSubmit: handleFormikSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => {
        return (
          <FormikForm>
            <FormikField type="hidden" name="selectedTeamId" />
            <FormikField
              name="name"
              label={t('MTD.POLICIES.APP_POLICY.POLICY_NAME')}
              component={TextField}
            />
            <RadioGroup label={t('MTD.POLICIES.POLICY_TYPE')}>
              <FormikField
                component={Radio}
                disabled={isSubmitting || scopeBounds === 'TEAM_BOUNDED'}
                label={t('GLOBAL.GLOBAL')}
                name="bounds"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('bounds', e.currentTarget.value);
                  handleScopeChange(e.currentTarget.value);
                }}
                size="small"
                type="radio"
                value="global"
              />
              <FormikField
                component={Radio}
                disabled={isSubmitting || scopeBounds === 'TEAM_BOUNDED'}
                label={`${
                  selectedTeam?.label
                    ? t('GLOBAL.TEAM_WITH_NAME', {
                        teamName: selectedTeam?.label,
                      })
                    : t('GLOBAL.TEAM')
                }`}
                name="bounds"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('bounds', e.currentTarget.value);
                  handleScopeChange(e.currentTarget.value);
                }}
                size="small"
                type="radio"
                value="team"
              />
            </RadioGroup>
            {showTeamSelector && (
              <TeamSelectorRadioForm
                handleTeamChange={handleTeamChange}
                setFieldValue={setFieldValue}
                availableTeams={availableTeams}
                isRowDataPresent={false}
                values={values}
                selectedTeamId={selectedTeamId}
                scopeBounds={scopeBounds}
              />
            )}
            {!!touched?.teamId && !!errors?.teamId && (
              <InputError override="Team required" />
            )}

            <div className={classes.buttonPanel}>
              <Button
                color="secondary"
                disabled={isSubmitting}
                text={t('GLOBAL.CANCEL')}
                onClick={handleClose}
              />
              <Button
                color="primary"
                text={!!cloningPolicy ? t('GLOBAL.CLONE') : t('GLOBAL.CREATE')}
                disabled={isSubmitting || !values?.name?.length}
                isLoading={isSubmitting}
                onClick={() => handleFormikSubmit()}
              />
            </div>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default Form;
