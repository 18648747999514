import JsUploader from 'components/fileuploader/Uploader';
import React, { useCallback, useEffect, useState } from 'react';
import { openSnackBar } from 'utils/storeUtils';
import { BrandingLogo } from './models';
import useStyles from './useStyles';

const Uploader: any = JsUploader;

const uploadPrompt =
  'Drag and drop the file here or click button. File must be in SVG format, maximum 500k size';

interface UploadLogoProps {
  isDark?: true;
  id: BrandingLogo;
  logo?: Blob;
  title: string;
  disabled: boolean;
  onUpload: (fileData: File, logo: BrandingLogo) => void;
  onRemoveClick: (logo: BrandingLogo) => void;
}

const UploadLogo: React.FC<UploadLogoProps> = (props) => {
  const { id, isDark, logo, title, disabled, onUpload, onRemoveClick } = props;

  const classes = useStyles();

  const [file, setFile] = useState<File | Blob>();

  useEffect(() => {
    setFile(logo);
  }, [logo]);

  const getFileData = useCallback(
    (fileData: File) => {
      if (fileData.size / 1000 > 500) {
        openSnackBar('File size must be under 500kb');
      } else {
        setFile(fileData);
        onUpload(fileData, id);
      }
    },
    [id, setFile, onUpload]
  );

  const onRemoveLogoclick = useCallback(() => {
    onRemoveClick(id);
  }, [id, onRemoveClick]);

  return (
    <div className={isDark ? classes.darkLogoCard : classes.lightLogoCard}>
      <div className={classes.logoUpload}>
        <h2 className={isDark ? classes.darkTitle : classes.lightTitle}>
          {title}
        </h2>
        <div className={isDark ? classes.darkDropZone : classes.lightDropZone}>
          <Uploader
            disabled={disabled}
            accept=".svg"
            uploadPrompt={uploadPrompt}
            classes={isDark ? undefined : classes}
            getFileData={getFileData}
          />
        </div>
      </div>

      <div className={classes.logoContainer}>
        <div className={classes.logoImage}>
          {file ? (
            <img width="150px" alt={id} src={URL.createObjectURL(file)} />
          ) : (
            <span>No Logo Selected</span>
          )}
        </div>

        <div className={classes.removeLogoContainer}>
          {file !== undefined && (
            <span
              className={classes.removeLogoLabel}
              onClick={disabled ? undefined : onRemoveLogoclick}
            >
              Click To Remove Logo
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadLogo;
