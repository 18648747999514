import React from 'react';

// services
import { RequestDeleteUser } from 'api/apis';

// actions
import { publishEvent } from 'utils/eventUtils';

// components
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ZButton from 'UI/Buttons/ZButton';
import {
  toggleModalDiffered,
  openSnackBar,
  toggleModalDirect,
} from 'utils/storeUtils';

const SuperUsersDeleteConfirm = props => (
  <DialogContent>
    <p>
      This super user ({props.data.email}) has access to{' '}
      {props.data.accountsCount} account(s), are you sure you want to delete
      them?
    </p>
    <DialogActions>
      <ZButton
        styleName="modalCancel"
        action={toggleModalDiffered('SuperUsersDeleteConfirm', false)}
        color="secondary"
        buttonText="Cancel"
      />
      <ZButton
        action={handleSubmit(props)}
        buttonType="submit"
        color="primary"
        styleName="modalSave"
        buttonText="Delete user"
      />
    </DialogActions>
  </DialogContent>
);

function handleSubmit(props) {
  return () => {
    RequestDeleteUser(props.data.id)
      .then(() => {
        toggleModalDirect('SuperUsersDeleteConfirm', false);
        openSnackBar('User successfully deleted');
        publishEvent('table:force-fetch-superUsers');
      })
      .catch(error => {
        toggleModalDirect('SuperUsersDeleteConfirm', false);
        openSnackBar(`Failed to delete user: ${error.response.data}`);
        console.log(error);
      });
  };
}

SuperUsersDeleteConfirm.defaultProps = {
  data: {},
};

export default SuperUsersDeleteConfirm;
