import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    iconContainer: {
      display: 'flex',
      marginBottom: 16,
    },
    searchBar: {
      marginBottom: 16,
    },
    title: {
      color: palette.text.secondary,
    },
    checkBoxColumn: {
      marginLeft: 40,
    }
  })
);

export default useStyles;
