export const checkboxData = [
  {
    group: 'Type',
    options: [
      {
        label: 'Security',
        name: 'type',
        value: 'security',
      },
      {
        label: 'Privacy',
        name: 'type',
        value: 'privacy',
      },
    ],
  },
  {
    group: 'Compliance',
    options: [
      {
        label: 'CVE',
        name: 'compliance',
        value: 'cve',
      },
      {
        label: 'CVSS',
        name: 'compliance',
        value: 'cvss',
      },
      {
        label: 'CWE',
        name: 'compliance',
        value: 'cwe',
      },
      {
        label: 'GDPR',
        name: 'compliance',
        value: 'gdpr',
      },
      {
        label: 'HIPAA',
        name: 'compliance',
        value: 'hipaa',
      },
      {
        label: 'MASVS',
        name: 'compliance',
        value: 'masvs',
      },
      {
        label: 'NIAP',
        name: 'compliance',
        value: 'niap',
      },
      {
        label: 'OWASP',
        name: 'compliance',
        value: 'owasp',
      },
      {
        label: 'PCI',
        name: 'compliance',
        value: 'pci',
      },
    ],
  },
  {
    group: 'Platform',
    options: [
      {
        label: 'iOS',
        name: 'platform',
        value: 'ios',
      },
      {
        label: 'Android',
        name: 'platform',
        value: 'android',
      },
    ],
  },
  {
    group: 'Severity',
    options: [
      {
        label: 'Critical',
        name: 'severity',
        value: 'critical',
      },
      {
        label: 'High',
        name: 'severity',
        value: 'high',
      },
      {
        label: 'Medium',
        name: 'severity',
        value: 'medium',
      },
      {
        label: 'Low',
        name: 'severity',
        value: 'low',
      },
      {
        label: 'Informational',
        name: 'severity',
        value: 'informational',
      },
      {
        label: 'Best Practices',
        name: 'severity',
        value: 'best_practices',
      },
    ],
  },
];
