import { ISelectItem } from 'components/UI/input/Select';
import Select from 'components/UI/input/Select';
import { IGenericFullData } from 'components/UI/GenericTable/models';
import Checkbox from 'components/UI/input/Checkbox';
import TextField from 'components/UI/input/TextField';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { publishEvent } from 'utils/eventUtils';
import {
  ICheckboxOnChangeFn,
  IEditableRowMapping,
  IInputOnChangeFn,
  INewSelectOnChangeFn,
  ISelectOnChangeFn,
} from '../models';

interface IEditableCellContentProps {
  headerId: string;
  fullData: IGenericFullData;
  rowMapping?: IEditableRowMapping;
  tableId?: string;
}

const EditableCellContent: React.FC<IEditableCellContentProps> = ({
  fullData,
  headerId,
  rowMapping,
  tableId,
}) => {
  const { rowData } = fullData;
  const { rowEditable = false } = rowData;
  const value = rowData?.[headerId] ?? '';
  const dependsOn = rowMapping?.dependsOn;
  const onChange = rowMapping?.onChange;
  const alwaysEnabled = !!rowMapping?.alwaysEnabled;

  const columnDependencyMet = useMemo(() => {
    return (
      dependsOn?.id &&
      dependsOn?.value &&
      rowData?.[dependsOn.id] === dependsOn.value
    );
  }, [dependsOn, rowData]);

  const inputType = useMemo(() => {
    return dependsOn && !columnDependencyMet
      ? 'text'
      : rowMapping?.inputType ?? 'text';
  }, [dependsOn, columnDependencyMet, rowMapping]);

  const selectedOption = useMemo(() => {
    return inputType === 'select'
      ? rowMapping?.options?.find((option) => value === option.value)
      : undefined;
  }, [rowMapping, inputType, value]);

  // selectValue is used with our custom select field. This is necessary because it doesn't maintain its
  // own state the way all the other inputs do.
  const [selectValue, setSelectValue] = useState<ISelectItem | undefined>(
    selectedOption
  );
  const tempOptions = rowMapping?.options;
  useEffect(() => {
    tempOptions &&
      setSelectValue(
        tempOptions.find(
          (option) => option.value === value || option.value === value?.value
        )
      );
  }, [tempOptions, value]);

  const showInputWhenNotEditing = useMemo(() => {
    return (
      (inputType === 'checkbox' && (rowMapping?.alwaysDisplayInput ?? true)) || // Show checkbox unless explicitly alwaysDisplayInput === false
      ((rowMapping?.alwaysDisplayInput ?? false) &&
        (!dependsOn || columnDependencyMet))
    ); // Show input if column has no dependent column, or dependency not met
  }, [inputType, rowMapping, dependsOn, columnDependencyMet]);

  const updateAndNotifyOnChange = useCallback(
    (newValue: string | number | boolean | ISelectItem) => {
      publishEvent(`table:item-changed-${tableId}`, {
        value: newValue,
        rowMappingId: rowMapping?.path,
        rowDataId: rowData?.id,
      });
    },
    [rowMapping, rowData, tableId]
  );

  const handleCheckboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateAndNotifyOnChange(e?.currentTarget?.checked ?? false);

      if (onChange) {
        (onChange as ICheckboxOnChangeFn)(e, e.currentTarget.checked, fullData);
      }
    },
    [updateAndNotifyOnChange, onChange, fullData]
  );

  const handleSelectChange = useCallback(
    (field: string, value: ISelectItem) => {
      if (onChange) {
        (onChange as INewSelectOnChangeFn)(
          value.value,
          fullData?.rowData?.id,
          fullData?.rowData?.url
        );
      } else {
        updateAndNotifyOnChange(value);

        if (onChange) {
          (onChange as ISelectOnChangeFn)(field, value, fullData);
        }
      }
      setSelectValue(value);
    },
    [updateAndNotifyOnChange, onChange, fullData, setSelectValue]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      updateAndNotifyOnChange(e?.currentTarget?.value ?? '');

      if (onChange) {
        (onChange as IInputOnChangeFn)(e, fullData);
      }
    },
    [updateAndNotifyOnChange, onChange, fullData]
  );

  const enableInput = useMemo(() => {
    return rowEditable || alwaysEnabled;
  }, [rowEditable, alwaysEnabled]);

  if (!rowMapping || (!rowEditable && !showInputWhenNotEditing)) {
    return <>{String(selectedOption?.label ?? value)}</>;
  }

  const { max, min, options = [], searchable } = rowMapping;

  switch (inputType) {
    case 'checkbox':
      return (
        <Checkbox
          color="primary"
          checked={typeof value === 'boolean' && value}
          disabled={!enableInput}
          onChange={handleCheckboxChange}
        />
      );
    case 'select':
      return (
        <Select
          disableClearable={!!searchable}
          disabled={!enableInput}
          interactable={true}
          options={options}
          setFieldValue={handleSelectChange}
          value={selectValue}
        />
      );
    default:
      return (
        <TextField
          disabled={!enableInput}
          id={rowData?.id}
          inputProps={{
            max,
            min,
          }}
          onChange={handleInputChange}
          type={inputType}
          value={value}
        />
      );
  }
};

export default EditableCellContent;
