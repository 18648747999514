import Switch from '@material-ui/core/Switch';
import React from 'react';
import { ISelectTRMPolicyTableData, PolicyChangeOptions } from '../models';
import useStyles from '../useStyles';

interface IEnabledProps {
  rowData: ISelectTRMPolicyTableData;
  handleTRMPolicyRuleChange: (
    optionName: PolicyChangeOptions,
    value: boolean,
    rule: ISelectTRMPolicyTableData
  ) => void;
}

const Enabled: React.FC<IEnabledProps> = (props) => {
  const { rowData, handleTRMPolicyRuleChange } = props;

  const classes = useStyles();

  return (
    <div className={classes.tableColumn}>
      <Switch
        checked={rowData.shouldCollect}
        onChange={() => {
          handleTRMPolicyRuleChange(
            PolicyChangeOptions.ShouldCollect,
            !rowData.shouldCollect,
            rowData
          );
        }}
      />
    </div>
  );
};

export default React.memo(Enabled);
