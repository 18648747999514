import { TFunction } from 'react-i18next';

const passwordRulesText = (
  t: TFunction<'translation', undefined>
): { [key: string]: string } => ({
  minimumLength: t('USER_SETTINGS.PASSWORD_RULES.MINIMUM_LENGTH'),
  uppercaseRequired: t('USER_SETTINGS.PASSWORD_RULES.UPPERCASE_REQUIRED'),
  lowercaseRequired: t('USER_SETTINGS.PASSWORD_RULES.LOWERCASE_REQUIRED'),
  numberRequired: t('USER_SETTINGS.PASSWORD_RULES.NUMBER_REQUIRED'),
  symbolRequired: t('USER_SETTINGS.PASSWORD_RULES.SYMBOL_REQUIRED'),
  nonLetterRequired: t('USER_SETTINGS.PASSWORD_RULES.NON_LETTER_REQUIRED'),
  maxRepeatingCharacters: t(
    'USER_SETTINGS.PASSWORD_RULES.MAX_REPEATING_CHARACTERS'
  ),
  lastKnownPasswords: t('USER_SETTINGS.PASSWORD_RULES.LAST_KNOWN_PASSWORDS'),
});

export const passwordRulesDisplayMapping = (
  rules: { [key: string]: boolean | null },
  t: TFunction<'translation', undefined>
): string | null => {
  const rulesText = passwordRulesText(t);

  const rulesDisplay = Object.keys(rules)
    .map((key) => {
      const ruleValue = rules[key];
      if (!rulesText[key]) {
        return null;
      }

      if (key === 'minimumLength') {
        if (!rules.minimumLengthEnabled) {
          return null;
        }
      }

      if (key === 'maxRepeatingCharacters') {
        if (!rules.maxRepeatingCharactersEnabled) {
          return null;
        } else {
          return rulesText.maxRepeatingCharacters;
        }
      }

      if (key === 'lastKnownPasswords') {
        if (!rules.lastKnownPasswordsEnabled) {
          return null;
        } else {
          return t('USER_SETTINGS.PASSWORD_RULES.PREVIOUS_PASSWORD', {
            ruleValue,
          });
        }
      }

      if (typeof ruleValue === 'number' && ruleValue > 0) {
        return `${rulesText[key]}: ${ruleValue}`;
      }
      return ruleValue ? rulesText[key] : undefined;
    })
    .filter((rule) => rule != null)
    .join('\n');
  return rulesDisplay;
};

export const passwordErrorHelper = (
  rules: string[],
  t: TFunction<'translation', undefined>
) => {
  const rulesText = passwordRulesText(t);

  if (!Array.isArray(rules)) {
    return rules;
  }

  return rules
    .map((key) => {
      return rulesText[key];
    })
    .join('\n');
};
