import {
  fetchAllThreatDefaults,
  fetchAllThreatTypes,
  fetchThreatDefaultsSource,
  updateThreatDefaults,
} from 'api/ThreatDefaultsService';
import { MultiSelect as OldMultiSelect } from 'components/Selects';
import Button from 'components/UI/Button';
import {
  ISelectTRMPolicyTableData,
  PolicyChangeOptions,
} from 'components/main/policies/threats/models';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { openSnackBar } from 'utils/storeUtils';
import useAsyncResult from 'utils/useAsyncResult';
import TRMPolicyTable from './TRMPolicyTable';
import { IThreatDefaultsSource } from './models';
import useStyles from './useStyles';
import { mergeRulesWithThreats } from './utils';

const MultiSelect: any = OldMultiSelect;

const TRMPolicy = () => {
  const classes = useStyles();
  const [threatDefaultsSource, setThreatDefaultsSource] =
    useState<IThreatDefaultsSource[]>();
  const [selectedThreatDefaultsSource, setSelectedThreatDefaultsSource] =
    useState<IThreatDefaultsSource>();
  const [threatDefaultsData, setThreatDefaultsData] =
    useState<ISelectTRMPolicyTableData[]>();
  const [originThreatDefaultsData, setOriginThreatDefaultsData] =
    useState<ISelectTRMPolicyTableData[]>();
  const [isLoading, setLoading] = useState(false);

  const threatTypes = useAsyncResult(fetchAllThreatTypes);

  const getThreatDefaultsSource = async () => {
    const result = await fetchThreatDefaultsSource();
    if (!!result.data?.length) {
      const source = result.data.map((item: IThreatDefaultsSource) => ({
        ...item,
        label: item.name,
        value: item.id,
      }));
      setThreatDefaultsSource(source);
      setSelectedThreatDefaultsSource(source[0]);
    }
  };

  const getAllThreatDefaults = useCallback(
    async (trmId: string) => {
      setLoading(true);
      const result = await fetchAllThreatDefaults(trmId);
      if (result.data?.threatTypes) {
        const { table } = mergeRulesWithThreats(
          result.data.threatTypes,
          threatTypes?.data ?? []
        );
        setThreatDefaultsData(table);
        setOriginThreatDefaultsData(table);
        setLoading(false);
      }
    },
    [threatTypes]
  );

  useEffect(() => {
    getThreatDefaultsSource();
  }, []);

  useEffect(() => {
    if (selectedThreatDefaultsSource?.id) {
      getAllThreatDefaults(selectedThreatDefaultsSource.id);
    }
  }, [getAllThreatDefaults, selectedThreatDefaultsSource?.id]);

  const handleThreatDefaultsSourceChange = useCallback(
    (_: unknown, selectedOption: IThreatDefaultsSource) => {
      setSelectedThreatDefaultsSource(selectedOption);
    },
    []
  );

  const handleTRMPolicyRuleChange = useCallback(
    (
      optionName: PolicyChangeOptions,
      value: unknown,
      rule: string | ISelectTRMPolicyTableData
    ) => {
      const newRule = rule as ISelectTRMPolicyTableData;
      const findIndex =
        threatDefaultsData?.findIndex(
          (r) => r.threatTypeId === newRule.threatTypeId
        ) ?? 0;
      const newData = _.cloneDeep(threatDefaultsData) ?? [];

      newData[findIndex] = {
        ...newData[findIndex],
        [optionName]: value,
      };

      setThreatDefaultsData(newData);
    },
    [threatDefaultsData]
  );

  const onSaveChanges = useCallback(async () => {
    await updateThreatDefaults(
      selectedThreatDefaultsSource?.id ?? '',
      threatDefaultsData
    );
    openSnackBar('Saved changes successfully!');
  }, [selectedThreatDefaultsSource?.id, threatDefaultsData]);

  const onCancelChanges = useCallback(async () => {
    setThreatDefaultsData(originThreatDefaultsData);
  }, [originThreatDefaultsData]);

  return (
    <div>
      <div className={classes.topArea}>
        <div className={classes.selectorWrapper}>
          <MultiSelect
            buttonPlaceholder="Threat Defaults Source"
            isMulti={false}
            options={threatDefaultsSource ?? []}
            values={selectedThreatDefaultsSource ?? {}}
            onChange={handleThreatDefaultsSourceChange}
            fullWidth
          />
        </div>
        <div>
          <Button color="secondary" text="Cancel" onClick={onCancelChanges} />
          <Button color="primary" text="Save" onClick={onSaveChanges} />
        </div>
      </div>
      <div className={classes.tableContainer}>
        <TRMPolicyTable
          tableData={threatDefaultsData ?? []}
          handleTRMPolicyRuleChange={handleTRMPolicyRuleChange}
        />
      </div>
      {isLoading && <></>}
    </div>
  );
};

export default TRMPolicy;
