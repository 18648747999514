import Tooltip from '@material-ui/core/Tooltip';
import { Schedule as ScheduleIcon } from '@material-ui/icons';
import MUIDeleteIcon from '@material-ui/icons/Delete';
import MUIEditIcon from '@material-ui/icons/Edit';
import MUIExpandLessIcon from '@material-ui/icons/ExpandLess';
import MUIExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MUIContentCopyIcon from '@material-ui/icons/FilterNone';
import MUIGroupIcon from '@material-ui/icons/Group';
import MUISyncIcon from '@material-ui/icons/Sync';
import { fetchAllAppSettings } from 'api/AppSettingsService';
import { fetchConnections } from 'api/MDMService';
import { fetchAllPhishingPolicies } from 'api/PhishingPolicyService';
import { fetchAllPrivacyPoliciesList } from 'api/PrivacyPolicyService';
import { fetchAllTRMPoliciesList } from 'api/TRMPolicyService';
import { getActiveModalAtom } from 'atoms/modals';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import CopyIcon from 'components/UI/icons/CopyIcon';
import KeyIcon from 'components/UI/icons/KeyIcon';
import Moment from 'moment';
import React from 'react';
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard'; // TODO: Fix this import. I'm not sure if we want to add these types yet
import { useSetRecoilState } from 'recoil';
import { reduceForMultiSelectWithGlobal } from 'utils/componentUtils';
import { openSnackBarDiffered, toggleModalDiffered } from 'utils/storeUtils';
import { AddEditCloneEMMAuth_TITLE } from '../../AddEditCloneEMMAuth';
import { savedIntuneFormData as savedIntuneFormDataAtom } from '../../AddEditCloneEMMAuth/IntuneForm/atoms';
import { IAddEditCloneEMMAuth } from '../../AddEditCloneEMMAuth/models';
import { IStandardAuth } from '../../AddEditCloneEMMAuth/StandardForm/models';
import { IVMWareAuth } from '../../AddEditCloneEMMAuth/VMWareForm/models';
import { AddEditCloneEMMGroups_TITLE } from '../../AddEditCloneEMMGroups';
import { fetchDormancyPolicies } from '../../AddEditCloneEMMGroups/ModalContent/Form/utils';
import { IAddEditCloneEMMGroups } from '../../AddEditCloneEMMGroups/models';
import { cleanMDMTableData } from '../../MDM.mappings';
import { fetchMDMProviders } from '../../SelectProvider/utils';
import { ViewAppConfig_TITLE } from '../../ViewAppConfig';
import { IViewAppConfig } from '../../ViewAppConfig/models';
import { fetchAppConfig } from '../../ViewAppConfig/utils';
import { IDetailTableConfig } from '../MDMTableRowDetails/models';
import { IEMMConnectionMode, IFullData, ITeam } from './models';
import { TFunction } from 'react-i18next';

export async function fetchTableData(
  accountId: string,
  teamId?: string | null | undefined
) {
  // Fetch Data
  // trm / threat policies

  let trmPolicyList = [];
  try {
    const { data: trmPolicyRawList } = await fetchAllTRMPoliciesList(
      undefined,
      'ZIPS'
    );
    trmPolicyList = reduceForMultiSelectWithGlobal(trmPolicyRawList);
  } catch (e) {
    console.log('Error in Threat Policies: ', e);
  }

  // privacy policies
  let privacyPolicyList = [];
  try {
    const { data: privacyPolicyRawList } = await fetchAllPrivacyPoliciesList();
    privacyPolicyList = reduceForMultiSelectWithGlobal(privacyPolicyRawList);
  } catch (e) {
    console.log('Error in Privacy Policy:', e);
  }

  // app settings
  let appSettingsPolicyList = [];
  try {
    const { data: appSettingsRawList } = await fetchAllAppSettings({
      module: 'ZIPS',
    });
    appSettingsPolicyList = reduceForMultiSelectWithGlobal(appSettingsRawList);
  } catch (e) {
    console.log('Error in App Policy:', e);
  }

  // phishing
  let phishingPolicyList = [];
  try {
    const { data: phishingPolicyRawList } = await fetchAllPhishingPolicies();
    phishingPolicyList = reduceForMultiSelectWithGlobal(phishingPolicyRawList);
  } catch (e) {
    console.log('Error in Phishing Policy:', e);
  }
  let dormancyList = [];
  try {
    const dormancyPolicyRawList = await fetchDormancyPolicies();
    dormancyList = reduceForMultiSelectWithGlobal(dormancyPolicyRawList);
  } catch (e) {
    console.log('Error in Dormancy Policy: ', e);
  }

  const mdmList = await fetchConnections({
    accountId,
    teamId: teamId ?? '',
  });

  const mdmConnectionList = mdmList.data;
  const tableData = cleanMDMTableData({
    trmPolicyList,
    privacyPolicyList,
    phishingPolicyList,
    appSettingsPolicyList,
    dormancyList,
    tableData: mdmConnectionList,
  });

  return {
    tableData,
    totalResults: tableData.length,
  };
}

export const syncModeSelections = (t: TFunction<'translation', undefined>) => [
  { value: 'MDM_MODE', label: t('MTD.INTEGRATIONS.MDM_MANAGED_DEVICES') },
  { value: 'MAM_MODE', label: t('MTD.INTEGRATIONS.MDM_UNMANAGED_DEVICES') },
  {
    value: 'MDM_MAM_MODE',
    label: t('MTD.INTEGRATIONS.MDM_MANAGED_UNMANAGED_DEVICES'),
  },
  {
    value: 'MAM_WITH_OTHER_MDM_MODE',
    label: t('MTD.INTEGRATIONS.MAM_WITH_OTHER_MDM_MODE'),
  },
];

export const MDMTableHeaders = (t: TFunction<'translation', undefined>) => {
  return [
    {
      id: 'name',
      numeric: false,
      label: t('GLOBAL.NAME'),
      show: true,
      path: 'name',
      sort: 1,
      minWidth: 265,
      maxWidth: 265,
      resizable: true,
      flex: 1,
      showInContextMenu: true,
    },
    {
      id: 'connectorType.displayName',
      numeric: false,
      label: t('MTD.INTEGRATIONS.CONNECTOR_TYPE'),
      show: true,
      path: 'connectorType.displayName',
      sort: 1,
      minWidth: 150,
      maxWidth: 265,
      resizable: true,
      flex: 1,
      showInContextMenu: true,
    },
    {
      id: 'serverVersion',
      numeric: false,
      label: t('MTD.INTEGRATIONS.SERVER_VERSION'),
      show: true,
      path: 'serverVersion',
      sort: 1,
      minWidth: 160,
      maxWidth: 265,
      resizable: true,
      flex: 1,
      showInContextMenu: true,
    },
    {
      id: 'team',
      numeric: false,
      label: t('GLOBAL.TEAM'),
      show: true,
      path: 'team',
      sort: 1,
      minWidth: 160,
      maxWidth: 265,
      resizable: true,
      flex: 1,
      showInContextMenu: true,
    },
    {
      id: 'syncInfo.lastCompletedAt',
      numeric: false,
      label: t('MTD.INTEGRATIONS.LAST_SYNC'),
      show: true,
      path: 'syncInfo.lastCompletedAt',
      sort: 1,
      minWidth: 160,
      maxWidth: 265,
      resizable: true,
      flex: 1,
      showInContextMenu: true,
    },

    {
      id: 'modified',
      numeric: false,
      label: t('GLOBAL.MODIFIED'),
      show: true,
      path: 'modified',
      sort: 1,
      minWidth: 160,
      maxWidth: 265,
      resizable: true,
      flex: 1,
      showInContextMenu: true,
    },
    {
      id: 'id',
      numeric: false,
      label: t('GLOBAL.ACTIONS'),
      show: true,
      path: 'id',
      sort: 0,
      minWidth: 230,
      maxWidth: 265,
      resizable: true,
      flex: 1,
      showInContextMenu: true,
    },
    {
      id: 'dd',
      numeric: false,
      label: '',
      show: true,
      sort: 0,
      minWidth: 35,
      maxWidth: 35,
      flex: 0,
      showInContextMenu: false,
    },
  ];
};

const activeModalAtom = getActiveModalAtom<
  | IViewAppConfig
  | IAddEditCloneEMMAuth
  | IAddEditCloneEMMGroups<IStandardAuth | IVMWareAuth>
>();

const ConfigButton: React.FC<{ data: IFullData; }> = ({ data }) => {
  const setActiveModal = useSetRecoilState(activeModalAtom);
  return (
    <button
      onClick={async () => {
        const connectionId = data?.rowData?.id;
        const appConfiguration = connectionId
          ? await fetchAppConfig(connectionId)
          : undefined;

        setActiveModal({
          active: ViewAppConfig_TITLE,
          payload: { appConfiguration },
        });
      }}
      className={data?.classList?.iconButton ?? ''}
    >
      <KeyIcon />
    </button>
  );
};

const GroupsButton: React.FC<{ data: IFullData; }> = ({ data }) => {
  const setActiveModal = useSetRecoilState(activeModalAtom);
  return (
    <MUIGroupIcon
      className="hover--link"
      onClick={async () => {
        setActiveModal({
          active: AddEditCloneEMMGroups_TITLE,
          payload: {
            connector: data?.rowData?.connectorType,
            data,
            mode: 'EDIT',
          },
        });
      }}
    />
  );
};

const EditButton: React.FC<{ data: IFullData; }> = ({ data }) => {
  const setActiveModal = useSetRecoilState(activeModalAtom);
  const setSavedIntuneFormData = useSetRecoilState(savedIntuneFormDataAtom);
  return (
    <MUIEditIcon
      className="hover--link"
      onClick={async () => {
        const connectors = await fetchMDMProviders();
        // Clear out intune data and redirectUrl in case auth was interrupted
        setSavedIntuneFormData(undefined);
        localStorage.removeItem('redirectUrl');
        setActiveModal({
          active: AddEditCloneEMMAuth_TITLE,
          payload: {
            connector: connectors?.find(
              ({ id }) => id === data?.rowData?.connectorType?.id
            ),
            data,
            mode: 'EDIT',
          },
        });
      }}
    />
  );
};

const CloneButton: React.FC<{ data: IFullData; }> = ({ data }) => {
  const setActiveModal = useSetRecoilState(activeModalAtom);
  const setSavedIntuneFormData = useSetRecoilState(savedIntuneFormDataAtom);
  return (
    <button
      onClick={async () => {
        const connectors = await fetchMDMProviders();
        // Clear out intune data and redirectUrl in case auth was interrupted
        setSavedIntuneFormData(undefined);
        localStorage.removeItem('redirectUrl');
        setActiveModal({
          active: AddEditCloneEMMAuth_TITLE,
          payload: {
            connector: connectors?.find(
              ({ id }) => id === data?.rowData?.connectorType?.id
            ),
            data,
            mode: 'CLONE',
          },
        });
      }}
      className={data?.classList?.iconButton ?? ''}
    >
      <div className={data?.classList?.copyIcon ?? ''}>
        <CopyIcon />
      </div>
    </button>
  );
};

export const getMDMColumnMapping = (
  availableTeams: ITeam[],
  t: TFunction<'translation', undefined>
) => {
  return [
    { path: 'name' },
    {
      path: 'connectorType.displayName',
      columnContent: (fullData: IFullData) => {
        const { rowData, classList } = fullData;
        const displayName = rowData?.connectorType?.displayName ?? '';
        const logoUrl = rowData?.connectorType?.logo ?? '';
        return (
          <Tooltip title={displayName}>
            <div className={classList.connectorTypeTableCell}>
              <div className={classList.connectorTypeImageWrapper}>
                <img
                  src={`api/emm/${logoUrl}`}
                  alt={displayName}
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
              </div>
              <span className={classList.connectorTypeLabelWrapper}>
                {displayName}
              </span>
            </div>
          </Tooltip>
        );
      },
    },
    {
      path: 'serverVersion',
      columnContent: (fullData: IFullData) => {
        const serverVersion = fullData?.rowData?.serverVersion ?? '';
        return <div className="table-cell__ellipsis">{serverVersion}</div>;
      },
    },
    {
      path: 'team',
      columnContent: (fullData: IFullData) => {
        const teamId = fullData?.rowData?.teamId ?? '';
        const team = availableTeams.find(
          ({ value }) => !!teamId && value === teamId
        );
        return (
          <div className="table-cell__ellipsis">
            {team?.label
              ? team.label !== 'Default'
                ? team.label
                : t('GLOBAL.DEFAULT')
              : ''}
          </div>
        );
      },
    },
    {
      path: 'syncInfo.lastCompletedAt',
      columnContent: (fullData: IFullData) => {
        const {
          classList,
          rowData: { syncInfo },
        } = fullData;

        if (!syncInfo?.lastCompletedStatus) {
          return <></>;
        }

        const lastCompletedStatus = syncInfo?.lastCompletedStatus;
        const lastCompletedAt = syncInfo?.lastCompletedAt;
        const tooltipLanguage = `${lastCompletedStatus || ''
          } ${lastCompletedAt}`;

        let statusClasses = classList.syncStatusCircle + ' ';
        statusClasses +=
          lastCompletedStatus === 'COMPLETED'
            ? fullData.classList.paleGreen
            : fullData.classList.tomato;

        return (
          <Tooltip title={tooltipLanguage} placement="right">
            <div className="flex--spaced">
              <div className={statusClasses} />
              {Moment(lastCompletedAt).fromNow()}
            </div>
          </Tooltip>
        );
      },
    },

    {
      path: 'modified',
      columnContent: (fullData: IFullData) => {
        const {
          rowData: { modified },
        } = fullData;
        return Moment(modified).fromNow();
      },
      csvContent: (timestamp: string) => Moment(timestamp).toISOString(),
    },

    {
      path: 'id',
      columnContent: (fullData: IFullData) => {
        const data = fullData;
        const mode = data?.rowData?.mode;
        const hideSyncOptions = mode === IEMMConnectionMode.MAM_WITH_OTHER_MDM_MODE;
        return (
          <div id="mdm-actions">
            <ProtectedComponent
              allow={{ emm: 'manage' }}
              hide={hideSyncOptions}
            >
              <Tooltip title={t('MTD.INTEGRATIONS.SYNC_CONNECTION') || ''}>
                <MUISyncIcon
                  className="hover--link"
                  onClick={toggleModalDiffered('MDMSyncConfirm', data, {
                    title: t('MTD.INTEGRATIONS.SYNC_EMM_CONNECTION'),
                    fullWidth: true,
                  })}
                />
              </Tooltip>
            </ProtectedComponent>
            <ProtectedComponent allow={{ emm: 'manage' }}>
              <Tooltip title={t('MTD.INTEGRATIONS.EDIT_CONNECTION') || ''}>
                <EditButton data={data} />
              </Tooltip>
            </ProtectedComponent>
            <ProtectedComponent allow={{ emm: 'manage' }}>
              <Tooltip
                title={t('MTD.INTEGRATIONS.EDIT_CONNECTION_GROUPS') || ''}
              >
                <GroupsButton data={data} />
              </Tooltip>
            </ProtectedComponent>
            <ProtectedComponent
              allow={{ emm: 'manage' }}
              hide={hideSyncOptions}
            >
              <Tooltip
                title={t('MTD.INTEGRATIONS.COPY_CONFIGURATION_VALUES') || ''}
              >
                <ConfigButton data={fullData} />
              </Tooltip>
            </ProtectedComponent>
            <ProtectedComponent
              allow={{ emm: 'manage' }}
              hide={hideSyncOptions}
            >
              <Tooltip title={t('MTD.INTEGRATIONS.SCHEDULE_SYNCS') || ''}>
                <ScheduleIcon
                  className="hover--link"
                  onClick={toggleModalDiffered(
                    'EMMSyncSchedule',
                    { id: data.rowData.id },
                    {}
                  )}
                />
              </Tooltip>
            </ProtectedComponent>
            <ProtectedComponent allow={{ emm: 'manage' }}>
              <Tooltip title={t('MTD.INTEGRATIONS.EDIT_CONNECTION') || ''}>
                <CloneButton data={data} />
              </Tooltip>
            </ProtectedComponent>
            <ProtectedComponent allow={{ emm: 'manage' }}>
              <Tooltip title={t('MTD.INTEGRATIONS.DELETE_CONNECTION') || ''}>
                <MUIDeleteIcon
                  className="hover--link"
                  onClick={toggleModalDiffered('MDMDeleteConfirm', data, {
                    title: t('MTD.INTEGRATIONS.DELETE_EMM'),
                    fullWidth: true,
                  })}
                />
              </Tooltip>
            </ProtectedComponent>
          </div>
        );
      },
    },
    {
      path: 'dd',
      columnContent: (fullData: IFullData) => {
        const { toggleDetails, isShowingDetails, rowData } = fullData;
        return isShowingDetails ? (
          <MUIExpandLessIcon onClick={() => toggleDetails(rowData.id)} />
        ) : (
          <MUIExpandMoreIcon onClick={() => toggleDetails(rowData.id)} />
        );
      },
    },
  ];
};

export const BASIC_TABLE_CONFIG = (
  t: TFunction<'translation', undefined>
): IDetailTableConfig[] => {
  return [
    {
      id: 1,
      key: 'connectionName',
      label: t('MTD.INTEGRATIONS.CONNECTION_NAME'),
    },
    {
      id: 2,
      key: 'baseUrl',
      label: t('GLOBAL.URL'),
      formatFn: (url: string | number) => (
        <a
          href={typeof url === 'string' ? url : ''}
          target="_blank"
          rel="noopener noreferrer"
        >
          {url}
        </a>
      ),
    },
    {
      id: 3,
      key: 'apiKey',
      label: t('GLOBAL.API_KEY'),
      formatFn: (apiKey: string | number) => {
        return (
          <CopyToClipboard
            text={apiKey}
            onCopy={openSnackBarDiffered('API Key copied to clipboard')}
          >
            <span
              className="flex--spaced cursor--pointer"
              style={{ maxWidth: 200 }}
            >
              {' '}
              <span className="text__ellipsis">{apiKey}</span>
              <MUIContentCopyIcon />
            </span>
          </CopyToClipboard>
        );
      },
    },
  ];
};

export const DETAILS_TABLE_CONFIG = (
  t: TFunction<'translation', undefined>
): IDetailTableConfig[] => {
  return [
    {
      id: 1,
      key: 'modified',
      label: t('GLOBAL.MODIFIED'),
      formatFn: (timestamp: string | number) =>
        typeof timestamp === 'string' ? Moment(timestamp).fromNow() : '',
    },
    {
      id: 2,
      key: 'provisioned',
      label: t('MTD.INTEGRATIONS.PROVISIONED'),
      formatFn: (timestamp: string | number) =>
        typeof timestamp === 'string' ? Moment(timestamp).fromNow() : '',
    },
    {
      id: 3,
      key: 'provisionId',
      label: t('MTD.INTEGRATIONS.PROVISION_ID'),
    },
    {
      id: 4,
      key: 'connectorApiVersion',
      label: t('MTD.INTEGRATIONS.API_VERSION'),
    },
    {
      id: 5,
      key: 'proxy_config_id',
      label: t('MTD.INTEGRATIONS.PROXY_COUNTRY'),
    },
    {
      id: 6,
      key: 'mode',
      label: t('GLOBAL.MODE'),
    },
    {
      id: 7,
      key: 'maxDeviceIdleDays',
      label: t('MTD.INTEGRATIONS.MAX_DEVICE_IDLE_DAYS'),
    },
    {
      id: 8,
      key: 'status',
      label: t('GLOBAL.STATUS'),
    },
  ];
};
