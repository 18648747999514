import Tooltip from '@material-ui/core/Tooltip';
import Edit from '@material-ui/icons/Edit';
import cc from 'classcat';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import AndroidIcon from 'components/UI/icons/AndroidIcon';
import AppleIcon from 'components/UI/icons/AppleIcon';
import ChromeOSIcon from 'components/UI/icons/ChromeOSIcon';
import { IColumnHeader, IFilterEditor } from 'components/UI/Table/models';
import Moment from 'moment';
import momentHelper from 'utils/momentUtils';
import { toggleModalDiffered } from 'utils/storeUtils';
import { OS_NAMES } from '../devices/deviceMappings';
import { IFilterValues, IFullData, PLATFORMS } from './Threats/models';
import { TFunction } from 'react-i18next';

export const threatLevelMapping = ['normal', 'low', 'elevated', 'critical'];

export const THREAT_STATUSES_DEPRECATED = [
  { id: 1, name: 'Pending', value: 'Pending' },
  { id: 2, name: 'Fixed', value: 'Fixed' },
  { id: 3, name: 'Approved', value: 'Approved' },
];

export const THREAT_STATUSES = [
  { id: 1, name: 'GLOBAL.PENDING', value: 'Pending' },
  { id: 2, name: 'GLOBAL.FIXED', value: 'Fixed' },
  { id: 3, name: 'GLOBAL.APPROVED', value: 'Approved' },
];

export const SEVERITIES = [
  { id: 0, name: 'GLOBAL.NORMAL', className: 'normal', value: 'normal' },
  { id: 1, name: 'GLOBAL.LOW', className: 'low', value: 'low' },
  { id: 2, name: 'GLOBAL.ELEVATED', className: 'elevated', value: 'elevated' },
  { id: 3, name: 'GLOBAL.CRITICAL', className: 'critical', value: 'critical' },
];

export const VECTORS = [
  { id: 1, name: 'GLOBAL.NETWORK', value: 'Network' },
  { id: 2, name: 'GLOBAL.DEVICE', value: 'Device' },
  { id: 3, name: 'GLOBAL.MALWARE', value: 'Malware' },
];
export const defaultMtdThreatsTableHeaders: IColumnHeader[] = [
  {
    filterEditor: 'SelectFilter',
    filterEditorProps: { multiple: true, wrapMultiple: false },
    id: 'severity',
    flex: 1,
    label: 'GLOBAL.SEVERITY',
    path: 'severity',
    show: true,
    sort: true,
    minWidth: 125,
    maxWidth: 125,
    resizable: true,
    showInContextMenu: true,
  },
  {
    filterEditor: 'SelectFilter',
    filterEditorProps: { multiple: true, wrapMultiple: false },
    id: 'state',
    flex: 1,
    label: 'GLOBAL.STATUS',
    show: true,
    sort: true,
    minWidth: 125,
    maxWidth: 125,
    resizable: true,
    showInContextMenu: true,
  },
  {
    filterEditor: 'SelectFilter',
    filterEditorProps: { multiple: true, wrapMultiple: false },
    id: 'vector',
    flex: 1,
    idCSV: 'vectorName',
    label: 'GLOBAL.VECTOR',
    show: true,
    sort: true,
    minWidth: 125,
    maxWidth: 125,
    resizable: true,
    showInContextMenu: true,
  },
  {
    // filterEditor: 'StringFilter',
    id: 'threatTypeId',
    flex: 1,
    label: 'GLOBAL.THREAT_NAME',
    show: true,
    sort: false,
    minWidth: 150,
    maxWidth: 150,
    resizable: true,
    showInContextMenu: true,
  },
  {
    id: 'zappName',
    filterEditor: 'StringFilter',
    flex: 1,
    label: 'GLOBAL.APP_NAME',
    sort: true,
    show: true,
    minWidth: 125,
    maxWidth: 125,
    resizable: true,
    showInContextMenu: true,
  },
  {
    id: 'zappInstance.version',
    filterEditor: 'NumberFilter',
    flex: 1,
    label: 'GLOBAL.VERSION',
    sort: true,
    show: true,
    minWidth: 125,
    maxWidth: 125,
    resizable: true,
    showInContextMenu: true,
  },
  {
    id: 'zappInstance.zversion',
    flex: 1,
    sort: true,
    label: 'GLOBAL.Z_DEFEND_VERSION',
    show: false,
    minWidth: 125,
    maxWidth: 125,
    resizable: true,
    showInContextMenu: true,
  },
  {
    id: 'zappInstance.zbuildNumber',
    flex: 1,
    sort: true,
    label: 'GLOBAL.Z_DEFEND_BUILD',
    show: false,
    minWidth: 125,
    maxWidth: 125,
    resizable: true,
    showInContextMenu: true,
  },
  {
    filterEditor: 'SelectFilter',
    filterEditorProps: { multiple: true, wrapMultiple: false },
    id: 'os',
    flex: 1,
    label: 'GLOBAL.OS',
    sort: true,
    show: true,
    minWidth: 125,
    maxWidth: 125,
    resizable: true,
    showInContextMenu: true,
  },
  {
    id: 'device.os.version',
    flex: 1,
    label: 'GLOBAL.OS_VERSION',
    sort: true,
    show: false,
    minWidth: 125,
    maxWidth: 125,
    resizable: true,
    showInContextMenu: true,
  },
  {
    id: 'device.model',
    flex: 1,
    label: 'GLOBAL.DEVICE_MODEL',
    sort: true,
    show: false,
    minWidth: 125,
    maxWidth: 125,
    resizable: true,
    showInContextMenu: true,
  },
  {
    id: 'device.zdeviceId',
    flex: 1,
    label: 'GLOBAL.DEVICE_ID',
    sort: true,
    show: true,
    minWidth: 125,
    maxWidth: 125,
    resizable: true,
    showInContextMenu: true,
  },
  {
    id: 'zappInstance.externalTrackingId1',
    flex: 1,
    label: 'GLOBAL.TRACKING_ID_1',
    sort: true,
    show: false,
    minWidth: 125,
    maxWidth: 125,
    resizable: true,
    showInContextMenu: true,
  },
  {
    id: 'zappInstance.externalTrackingId2',
    flex: 1,
    label: 'GLOBAL.TRACKING_ID_2',
    sort: true,
    show: false,
    minWidth: 125,
    maxWidth: 125,
    resizable: true,
    showInContextMenu: true,
  },
  {
    filterEditor: 'StringFilter',
    id: 'activationName',
    flex: 1,
    label: 'MTD.THREATS.ACTIVATION_EMM_NAME',
    sort: true,
    show: false,
    minWidth: 180,
    maxWidth: 180,
    resizable: true,
    showInContextMenu: true,
  },
  {
    id: 'deviceOwner',
    flex: 1,
    label: 'GLOBAL.DEVICE_OWNER',
    sort: true,
    show: true,
    minWidth: 180,
    maxWidth: 180,
    resizable: true,
    showInContextMenu: true,
  },
  {
    id: 'device.mdmDeviceId',
    flex: 1,
    label: 'MTD.THREATS.MDM_ID',
    sort: true,
    show: true,
    minWidth: 125,
    maxWidth: 125,
    resizable: true,
    showInContextMenu: true,
  },
  {
    id: 'device.mamDeviceId',
    flex: 1,
    label: 'MTD.THREATS.MAM_ID',
    sort: true,
    show: false,
    minWidth: 125,
    maxWidth: 125,
    resizable: true,
    showInContextMenu: true,
  },
  {
    id: 'teamName',
    // filterEditor: 'StringFilter',
    flex: 1,
    label: 'GLOBAL.TEAM_NAME',
    sort: true,
    show: true,
    minWidth: 125,
    maxWidth: 125,
    resizable: true,
    showInContextMenu: true,
  },
  {
    id: 'timestamp',
    flex: 1,
    label: 'GLOBAL.TIMESTAMP',
    sort: true,
    show: true,
    minWidth: 200,
    maxWidth: 225,
    resizable: true,
    showInContextMenu: true,
  },
  {
    id: 'actions',
    flex: 1,
    label: 'GLOBAL.ACTIONS',
    show: true,
    sort: false,
    minWidth: 125,
    resizable: true,
    showInContextMenu: false,
  },
];

export const defaultThreatsTableHeaders = defaultMtdThreatsTableHeaders
  .filter((item) => item.id !== 'deviceOwner')
  .map((item) => {
    if (item.id === 'activationName') {
      return {
        ...item,
        label: 'MTD.THREATS.ACTIVATION_NAME',
      };
    }
    return item;
  });

export const threatsColumnMapping = (
  t: TFunction<'translation', undefined>
) => [
    {
      id: 'severity',
      path: 'severity',
      columnContent: ({ rowData, classList }: IFullData) => {
        const severity = SEVERITIES.find(({ id }) => id === rowData?.severity);
        const colorClassName = severity?.className ?? '';
        const colorClass = classList?.[colorClassName] ?? '';

        return (
          <div className="table__os-icon flex--spaced">
            <Tooltip
              title={
                rowData.simulated
                  ? (t('MTD.THREATS.SIMULATED_THREAT') as string)
                  : (t('MTD.THREATS.REAL_THREAT') as string)
              }
            >
              <>
                <div className={cc([classList?.statusDot ?? '', colorClass])} />
                <div className="table-cell__ellipsis">
                  {t(severity?.name ?? '')}
                </div>
              </>
            </Tooltip>
          </div>
        );
      },
      csvContent: ({ rowData }: IFullData) =>
        t(SEVERITIES.find(({ id }) => id === rowData?.severity)?.name ?? ''),
    },
    {
      path: 'state',
      columnContent: ({ rowData }: IFullData) => (
        <span className="table-cell__ellipsis">
          {t(THREAT_STATUSES.find(({ id }) => id === rowData?.state)?.name ?? '')}
        </span>
      ),
      csvContent: ({ rowData }: IFullData) => (
        <span className="table-cell__ellipsis">
          {t(THREAT_STATUSES.find(({ id }) => id === rowData?.state)?.name ?? '')}
        </span>
      ),
    },
    {
      path: 'vector',
      columnContent: ({ rowData }: IFullData) => (
        <span className="table-cell__ellipsis">
          {t(VECTORS?.find(({ id }) => id === rowData?.vector)?.name ?? '')}
        </span>
      ),
      csvContent: ({ rowData }: IFullData) =>
        t(VECTORS?.find(({ id }) => id === rowData?.vector)?.name ?? ''),
    },
    {
      path: 'threatTypeId',
      columnContent: ({ rowData }: IFullData) => (
        <span className="table-cell__ellipsis">{rowData?.serverName ?? ''}</span>
      ),
    },
    {
      path: 'zappName',
      columnContent: ({ rowData }: IFullData) => (
        <span className="table-cell__ellipsis">
          {rowData?.zappInstance?.name ?? ''}
        </span>
      ),
    },
    {
      path: 'zappInstance.version',
      columnContent: ({ rowData }: IFullData) => (
        <span className="table-cell__ellipsis">
          {rowData?.zappInstance?.version ?? ''}
        </span>
      ),
    },
    {
      path: 'zappInstance.zversion',
      columnContent: ({ rowData }: IFullData) => (
        <span className="table-cell__ellipsis">
          {rowData?.zappInstance?.zversion ?? ''}
        </span>
      ),
    },
    {
      path: 'zappInstance.zbuildNumber',
      columnContent: ({ rowData }: IFullData) => (
        <span className="table-cell__ellipsis">
          {rowData?.zappInstance?.zbuildNumber ?? ''}
        </span>
      ),
    },
    {
      path: 'os',
      columnContent: (fullData: IFullData) => {
        if (!fullData?.rowData?.os) {
          return <span className={fullData?.classList?.osCell ?? ''} />;
        }
        const osLowerCaseName = fullData?.rowData?.os?.toLowerCase() ?? '';
        const osName = OS_NAMES?.[osLowerCaseName] ?? '';

        const icon =
          osLowerCaseName === 'ios' ? (
            <AppleIcon />
          ) : ['chrome os', 'chrome_os'].includes(osLowerCaseName) ? (
            <div className="table__os-icon">
              {' '}
              <ChromeOSIcon />
            </div>
          ) : osLowerCaseName === 'chrome_os' ? ( // change this when BE renames all references of 'chrome_os' to 'chrome os' or viceversa
            <div className="table__os-icon">
              <ChromeOSIcon />
            </div>
          ) : (
            <AndroidIcon />
          );

        return (
          <div className="table__os-icon flex--spaced">
            {icon}
            <div className="table-cell__ellipsis" style={{ paddingLeft: '5px' }}>
              {osName}
            </div>
          </div>
        );
      },
      csvContent: (fullData: IFullData) => {
        const osLowerCaseName = fullData?.rowData?.os?.toLowerCase() ?? '';
        return OS_NAMES?.[osLowerCaseName] ?? '';
      },
    },
    {
      path: 'device.os.version',
      columnContent: ({ rowData }: IFullData) => (
        <span className="table-cell__ellipsis">
          {rowData?.device?.os?.version ?? ''}
        </span>
      ),
    },
    {
      path: 'device.model',
      columnContent: ({ rowData }: IFullData) => (
        <span className="table-cell__ellipsis">
          {rowData?.device?.model ?? ''}
        </span>
      ),
    },
    {
      path: 'device.zdeviceId',
      columnContent: ({ rowData }: IFullData) => (
        <span className="table-cell__ellipsis">
          {rowData?.device.zdeviceId ?? ''}
        </span>
      ),
    },
    {
      path: 'teamName',
      columnContent: ({ rowData }: IFullData) => (
        <span className="table-cell__ellipsis">{rowData?.teamName ?? ''}</span>
      ),
    },
    {
      path: 'zappInstance.externalTrackingId1',
      columnContent: ({ rowData }: IFullData) => (
        <span className="table-cell__ellipsis">
          {rowData?.zappInstance?.externalTrackingId1 ?? ''}
        </span>
      ),
    },
    {
      path: 'zappInstance.externalTrackingId2',
      columnContent: ({ rowData }: IFullData) => (
        <span className="table-cell__ellipsis">
          {rowData?.zappInstance?.externalTrackingId2 ?? ''}
        </span>
      ),
    },
    {
      path: 'activationName',
      columnContent: ({ rowData }: IFullData) => (
        <span className="table-cell__ellipsis">
          {rowData?.activationName ?? ''}
        </span>
      ),
    },
    {
      path: 'deviceOwner',
      columnContent: ({ rowData }: IFullData) => (
        <span className="table-cell__ellipsis">{rowData?.deviceOwner ?? ''}</span>
      ),
    },
    {
      path: 'device.mdmDeviceId',
      columnContent: ({ rowData }: IFullData) => (
        <span className="table-cell__ellipsis">
          {rowData?.device?.mdmDeviceId ?? ''}
        </span>
      ),
    },
    {
      path: 'device.mamDeviceId',
      columnContent: ({ rowData }: IFullData) => (
        <span className="table-cell__ellipsis">
          {rowData?.device?.mamDeviceId ?? ''}
        </span>
      ),
    },
    {
      path: 'timestamp',
      columnContent: ({ rowData }: IFullData) =>
        rowData?.timestamp ? momentHelper(rowData.timestamp) : '',
      csvContent: ({ rowData }: IFullData) =>
        rowData?.timestamp ? Moment(rowData.timestamp).toISOString() : '',
    },
    {
      path: 'actions',
      columnContent: ({ rowData }: IFullData) => {
        const status = rowData?.state;
        if (status === 1) {
          return (
            <>
              <ProtectedComponent allow={{ threats: 'manage' }}>
                <div id="action-column">
                  <Tooltip title={t('MTD.THREATS.MITIGATE_THREAT') as string}>
                    <Edit
                      id="threat-mitigation-action"
                      className="cursor--pointer"
                      onClick={toggleModalDiffered('ThreatsMitigation', rowData, {
                        title: t('MTD.THREATS.THREAT_MITIGATION'),
                        fullWidth: true,
                      })}
                    />
                  </Tooltip>
                </div>
              </ProtectedComponent>
            </>
          );
        }
      },
    },
  ];

export const resolveFilters = (
  filterValues: IFilterValues,
  t: TFunction<'translation', undefined>
): IFilterEditor[] => {
  return [
    {
      name: 'severity',
      operator: 'in',
      type: 'select',
      value: filterValues?.severity ?? '',
      customRSQLOperator: 'in',
      filterEditorProps: {
        dataSource: SEVERITIES.map(({ name, id }) => ({
          id: id,
          label: t(name ?? ''),
        })),
      },
    },
    {
      name: 'state',
      operator: 'in',
      type: 'select',
      value: filterValues?.state ?? '',
      customRSQLOperator: 'in',
      filterEditorProps: {
        dataSource: THREAT_STATUSES.map(({ name, id }) => ({
          id,
          label: t(name ?? ''),
        })),
      },
    },
    {
      name: 'vector',
      operator: 'in',
      type: 'select',
      value: filterValues?.vector ?? '',
      customRSQLOperator: 'in',
      filterEditorProps: {
        dataSource: VECTORS.map(({ name, value }) => ({
          id: value,
          label: t(name ?? ''),
        })),
      },
    },
    {
      name: 'os',
      operator: 'in',
      type: 'select',
      value: filterValues?.os ?? '',
      customRSQLOperator: 'in',
      filterEditorProps: {
        dataSource: PLATFORMS.map(({ label, value }) => ({
          id: value,
          label,
        })),
      },
    },
    // {
    //   name: 'threatTypeId',
    //   operator: 'eq',
    //   type: 'string',
    //   value: filterValues?.threatTypeId ?? '',
    // },
    {
      name: 'zappName',
      operator: 'eq',
      type: 'string',
      value: filterValues?.zappName ?? '',
    },
    {
      name: 'activationName',
      operator: 'eq',
      type: 'string',
      value: filterValues?.activationName ?? '',
    },
    {
      name: 'teamName',
      operator: 'eq',
      type: 'string',
      value: filterValues?.teamName ?? '',
    },
  ];
};
