import { fetchAppsInventory } from 'api/AppsInventoryService';
import { IAppVersionsResponse } from 'components/main/appsInventory/AppsInventoryTab/models';
import { ILocationQuery } from 'components/UI/Table/models';
import { ITableQuery } from '../models';
import memoizeTableQuery from 'components/UI/Table/utils/memoizeTableQuery';

const memoizeQuery = (tableQeury: ITableQuery) => {
  return memoizeTableQuery(async (query: ILocationQuery) => {
    const result =
      (
        (await fetchAppsInventory({
          ...query,
          'developerName.keyword': tableQeury.developerName,
          'developerSignature.keyword': tableQeury.developerSignature,
          'bundleId.keyword': tableQeury.bundleId,
          'version.keyword': tableQeury.version,
          'platformId': tableQeury.platformId,
        })) as IAppVersionsResponse
      )?.data ?? { content: [], totalElements: 0 };

    return {
      data: result?.content ?? [],
      count: result?.content?.length ?? 0,
    };
  });
};

export default memoizeQuery;
