import Tooltip from '@material-ui/core/Tooltip';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import withRouter from 'components/hocs/withRouter';
import { useCallback } from 'react';
import useStyles from './useStyles';

interface IGenericDataFilterProps {
  children: JSX.Element;
  filterValue?: string | number | boolean
  onClick?: () => void;
  path: string;
  showTableValue: boolean;
  tableValue: JSX.Element | string;
  updateUrl: (...args: unknown[]) => void;
}

const GenericDataFilter: React.FC<IGenericDataFilterProps> = ({
  children,
  filterValue,
  onClick,
  path,
  showTableValue = true,
  tableValue,
  updateUrl,
}) => {
  const classes = useStyles();

  const handleSearch = useCallback(() => {
    updateUrl({ [path]: filterValue ?? tableValue });
    if (typeof onClick === 'function') {
      onClick();
    }
  }, [updateUrl, path, tableValue, onClick, filterValue]);

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      placement="right"
      title={<AddCircleOutline className={classes.circleIcon} />}
    >
      <div className="hover--link" onClick={handleSearch}>
        {showTableValue && tableValue}
        {children}
      </div>
    </Tooltip>
  );
};

export default withRouter(GenericDataFilter);
