import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Redirect, Route, withRouter } from 'react-router-dom';

import { clearAuthUser } from 'reducers/AuthReducers';
import { logout } from 'actions/UserActions';
import { debug } from 'utils/debugUtils';
import { publishEvent } from 'utils/eventUtils';
import useIdle from 'workers/useIdle';

const DashboardRoute = ({
  component: Component,
  isAuthenticated,
  hasUserAccessToken,
  hasSuperAccessToken,
  hasPartnerAccessToken,
  ...rest
}) => {
  const isIdle = useIdle({
    timeToIdle: rest.customIdleTime,
    inactivityEvents: [],
  });
  // a bug occured where user isn't auth but has an access token
  if (!isAuthenticated && hasUserAccessToken) {
    publishEvent('auth:remove-token');
    rest.clearAuthUser();
    return <Redirect to={{ pathname: '/admin' }} />;
  }

  if (hasSuperAccessToken && !hasUserAccessToken) {
    debug('Redirect to Admin - Dashboard Router');
    return <Redirect to={{ pathname: '/admin' }} />;
  }
  if (hasPartnerAccessToken && !hasUserAccessToken) {
    debug('Redirect to Partner - Dashboard Router');
    return <Redirect to={{ pathname: '/partner' }} />;
  }
  if (isIdle) {
    rest.logout();
  }
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthenticated) {
          return <Component {...props} />;
        }

        debug('Redirect Dashboard Router: Unauthenticated');
        return (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

const mapStateToProps = ({ auth, uiSettings }) => {
  return {
    isAuthenticated: auth.user.isAuthenticated,
    hasSuperAccessToken: Boolean(auth.superUser.accessToken),
    hasPartnerAccessToken: Boolean(auth.partnerUser.accessToken),
    hasUserAccessToken: Boolean(auth.user.accessToken),
    customIdleTime: _.get(uiSettings, 'customIdleTime', 900000),
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      clearAuthUser,
      logout,
    },
    dispatch
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DashboardRoute);
