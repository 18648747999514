import { handleActions } from 'redux-actions';

export const dataExportReducer = handleActions(
  {
    MICROSOFT_HANDLER: (state, payload) => {
      return { ...state, ...payload.object };
    },
  },
  {},
  {}
);
