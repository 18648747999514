import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ config, palette }: any) =>
  createStyles({
    contentContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '0px 50px 20px 0px',
      border: `1px solid ${config.colors.darkGreySoft}`,
    },
    itemContainer: {
      padding: '5px 30px 0px 30px',
      width: '35%',
      '& > first-child': {
        width: '20%',
      }
    },
    itemContents: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    chartTitle: {
      fontSize: '14px',
      fontWeight: 500,
      width: '105%',
      textAlign: 'center',
      margin: '5px 0 0 0'
    },
    label: {
      color: palette.text.secondary,
      fontSize: '12px',
      whiteSpace: 'nowrap',
    },
    value: {
      color: palette.text.secondary,
      fontSize: '38px',
      padding: '6px 0px 4px 0px',
    },
    title: {
      color: palette.text.secondary,
      backgroundColor: palette.background.default,
      border: `1px solid ${config.colors.darkGreySoft}`,
      textTransform: 'none',
    },
    chartSize: {
      display: 'flex',
      position: 'relative',
      paddingTop: 0,
      justifyContent: 'center',
      '& > div': {
        top: '55%',
        '& > h1, & > h2': {
          fontSize: '1.6em',
        }
      }
    },
    iosIcon: {
      '& > svg': { height: '100%', width: '100%' },
      '& > div': { height: '40px', width: '40px' },
    },
    appleIcon: {
      fill: 'currentColor',
      minHeight: '25px',
      minWidth: '25px',
      maxWidth: '60px',
      stroke: 'currentColor',
    },
    totalContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '20px',
      '& > div': { height: '70px', width: '70px' },
    }
  })
);

export default useStyles;
