import { IGraphOptions } from 'components/UI/graphs/common/models';
import { IDataset } from './models';
import { TFunction } from 'react-i18next';

export const DEVICE_TYPES = [
  { key: 'android', label: 'Android Devices' },
  { key: 'ios', label: 'iOS Devices' },
  { key: 'chromeos', label: 'Chrome OS Devices' },
];

export const GRAPH_OPTIONS = (
  t: TFunction<'translation', undefined>
): IGraphOptions => ({
  tooltips: {
    callbacks: {
      title: function formatTitle(tooltipItems, { datasets }) {
        if (!tooltipItems.length) {
          return '';
        }
        const { datasetIndex, index, xLabel } = tooltipItems[0];
        const dataset = (datasets as IDataset[])?.[datasetIndex];

        return `${xLabel}: ${dataset?.threatCounts?.[index] ?? ''} ${t(
          'GLOBAL.THREATS'
        )}`;
      },
    },
  },
});

export const calcDaysToAdd = (duration: number) => {
  if (duration <= 4 || duration >= 9) {
    return 0;
  }

  return 2 * duration - 9; // y = mx + b, rough estimate of days to add, possibly change
};
