import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    multiLinePlot: {
      alignItems: 'center',
      display: 'flex',
      flex: 0,
      flexDirection: 'column',
      justifyContent: 'center',
    },
    section: {
      display: 'flex',
      position: 'relative',
      '&:first-child': {
        flex: '1',
        marginBottom: 20,
        width: '100%',
      },
    },
  })
);

export default useStyles;
