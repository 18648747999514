import { InputProps as TMUIInputProps } from '@material-ui/core';
import MUIIconButton from '@material-ui/core/IconButton';
import MUITextField from '@material-ui/core/Input';
import MUIInputAdornment from '@material-ui/core/InputAdornment';
import MUIContentCopyIcon from '@material-ui/icons/FilterNone';
import { FieldProps as IFormikFieldProps } from 'formik';
import React from 'react';
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard'; // TODO: Fix this import. I'm not sure if we want to add these types yet
import slugify from 'slugify';
import useStyles from './useStyles';

interface ICopyFieldProps extends Partial<IFormikFieldProps> {
  id?: string;
  label?: string;
  name: string;
  onCopy?: () => void;
  value?: string;
}
export type TCopyFieldProps = ICopyFieldProps & TMUIInputProps;

const CopyField: React.FC<TCopyFieldProps> = ({
  id: propsId,
  label,
  name,
  onCopy = () => {},
  value,
  ...rest
}) => {
  const classes = useStyles();
  const id = propsId || `text-field-${slugify(label || name)}`;
  return (
    <div className={classes.container}>
      {label && (
        <label className={classes.label} htmlFor={id}>
          {label}
        </label>
      )}
      <MUITextField
        {...rest}
        classes={classes}
        endAdornment={
          <MUIInputAdornment
            classes={{ root: classes.adornmentRoot }}
            position="end"
          >
            <CopyToClipboard text={value} onCopy={onCopy}>
              <MUIIconButton>
                <MUIContentCopyIcon />
              </MUIIconButton>
            </CopyToClipboard>
          </MUIInputAdornment>
        }
        inputProps={{
          classes: {
            input: classes.input,
          },
        }}
        value={value}
      />
    </div>
  );
};

export default CopyField;
