import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    customDrawer: {
      width: '92% !important',
      height: '100% !important',
      marginTop: '0px !important',
    },
    container: {
      padding: '64px 8px 8px 8px',
    },
    infoContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 24,
      marginBottom: 16,
    },
    iconContainer: {
      display: 'flex',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 16,
    },
    titleLabel: {
      color: palette.text.secondary,
      textAlign: 'center',
    },
    contentLabel: {
      color: palette.text.secondary,
    },
    malicious: {
      alignItems: 'center',
      backgroundColor: palette.error.medium,
      border: `1px solid ${palette.tables.border}`,
      borderRadius: 100,
      color: 'white',
      display: 'flex',
      fontSize: 11,
      padding: '2px 8px',
      textTransform: 'uppercase',
    },
    platform: {
      '& svg': {
        fontSize: 20,
        marginRight: 10,
        minHeight: 20,
        minWidth: 20,
      },
      alignItems: 'center',
      display: 'flex',
    },
    risk: {
      '& span': {
        marginRight: 10,
      },
      alignItems: 'center',
      display: 'flex',
      textTransform: 'capitalize',
    },
    created: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }
  })
);

export default useStyles;
