import {
  MenuItem as MUIMenuItem,
  Select as MUISelect,
} from '@material-ui/core';
import MUIDialog from '@material-ui/core/Dialog';
import MUIDialogActions from '@material-ui/core/DialogActions';
import MUIDialogContent from '@material-ui/core/DialogContent';
import MUIDialogTitle from '@material-ui/core/DialogTitle';
import Button from 'components/UI/Button';
import JsFileInfoCard from 'components/fileuploader/FileInfoCard';
import JsUploader from 'components/fileuploader/Uploader';
import { arrayToCSV } from 'components/main/policies/network/arrayToCSV';
import { csvToArray } from 'components/main/policies/network/csvToArray';
import { downloadFile } from 'components/main/policies/network/downloadFile';
import _ from 'lodash';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { openSnackBar } from 'utils/storeUtils';
import {
  aclSelectedEntriesIds as aclSelectedEntriesIdsAtom,
  phishingAclControlListEntriesArr as phishingAclControlListEntriesArrAtom,
  phishingAclItemValue,
} from '../../atoms';
import { IACLListItem, ICategories, IControlListEntry } from '../../models';
import { getSubCategoriesArray } from '../../utils';
import useStyles from '../useStyles';
import {
  TableOptions,
  exampleData,
  fileHeaders,
  options,
  sampleFileHeaders,
} from './utils';
import { getAclById } from 'api/PhishingPolicyService';

const Uploader: any = JsUploader;
const FileInfoCard: any = JsFileInfoCard;

interface ITableActionProps {
  editMode: boolean;
  updateAcl: (contolListArr: IControlListEntry[]) => {};
  categoriesFromApi: {
    categoryGroups: ICategories[];
  };
}

const TableActions = ({
  editMode,
  updateAcl,
  categoriesFromApi,
}: ITableActionProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectionValue, setSelectionValue] = useState<TableOptions>();
  const [selectedRows, setSelectedRows] = useRecoilState(
    aclSelectedEntriesIdsAtom
  );
  const [controlListEntriesArr, setControlListEntriesArr] = useRecoilState(
    phishingAclControlListEntriesArrAtom
  );
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);
  const setFieldValue = (e: ChangeEvent<{ value: unknown }>) => {
    setSelectionValue(e.target.value as TableOptions);
  };
  const subCategoriesArray = useMemo(
    () => getSubCategoriesArray(categoriesFromApi?.categoryGroups),
    [categoriesFromApi]
  );

  const getCategoryCode = useCallback(
    (catName: string): number => {
      const catCode = subCategoriesArray.find((el) => el.name === catName);
      return catCode?.categoryId || 1;
    },
    [subCategoriesArray]
  );

  const handleDeleteSelected = useCallback(() => {
    if (selectedRows.length > 0) {
      const filteredList = controlListEntriesArr.filter(
        (el) => !selectedRows.includes(el.id!)
      );
      setControlListEntriesArr(filteredList);
      updateAcl(filteredList);
    }
  }, [
    controlListEntriesArr,
    selectedRows,
    setControlListEntriesArr,
    updateAcl,
  ]);

  const findCategoryName = useCallback(
    (category: number) => {
      let categName;
      const flatCategArray = getSubCategoriesArray(
        categoriesFromApi.categoryGroups
      );
      const found = flatCategArray.find((cat) => cat.categoryId === category);
      if (found) {
        categName = found.name;
      }
      return categName || '';
    },
    [categoriesFromApi]
  );

  const exportToCSV = useCallback((): void => {
    const example = selectionValue === TableOptions.DownloadExample;

    let values = [];
    let fileName = '';

    if (example) {
      values = exampleData;
      fileName = 'Example Access Control List';
      const csvData = arrayToCSV(sampleFileHeaders, values);
      const blob = new Blob([csvData], { type: 'text/csv' });
      downloadFile(blob, fileName);
    } else {
      fileName = `ACL-${new Date().toLocaleDateString()}.csv`;

      const newData =
        selectionValue === TableOptions.ExportSelected
          ? [
              ...controlListEntriesArr.filter((el) =>
                selectedRows.includes(el.id!)
              ),
            ]
          : [...controlListEntriesArr];

      values = newData.map((entry) => {
        return {
          site: entry.url,
          DefaultCategory: findCategoryName(entry.originalCategories[0]),
          CustomCategory: findCategoryName(entry.mappedCategory),
        };
      });
      const csvData = arrayToCSV(fileHeaders, values);
      const blob = new Blob([csvData], { type: 'text/csv' });

      downloadFile(blob, fileName);
    }

    setSelectionValue(undefined);
    setSelectedRows([]);
  }, [
    controlListEntriesArr,
    selectionValue,
    setSelectionValue,
    selectedRows,
    findCategoryName,
    setSelectedRows,
  ]);
  const persistSelectedAcl = useRecoilValue(phishingAclItemValue);

  const removeSpecialCharacters = (str = ''): string => {
    return str.replace(/(\r\n|\n|\r)/gm, '');
  };

  const handleSubmitForm = useCallback((): void => {
    if (selectedFile) {
      try {
        setIsLoading(true);

        const fileReader = new FileReader();

        fileReader.onload = async function (event) {
          if (event.target) {
            const text = event.target.result;

            if (text) {
              const headers: (keyof IControlListEntry)[] = [
                'url',
                'mappedCategory',
                'originalCategories',
              ];
              const entries = csvToArray(text as string, headers, {
                select: false,
              });

              if (entries.length > 0) {
                let newData = entries.map((entry) => {
                  return {
                    id: entry.id ?? _.uniqueId(),
                    url: entry.url,
                    mappedCategory: getCategoryCode(
                      removeSpecialCharacters(entry.mappedCategory)
                    ),
                    originalCategories: [
                      getCategoryCode(
                        removeSpecialCharacters(entry.originalCategories)
                      ),
                    ],
                  };
                });

                if (
                  controlListEntriesArr.length > 0 &&
                  selectionValue === TableOptions.ImportTableAppend
                ) {
                  newData.push(..._.cloneDeep(controlListEntriesArr));
                  newData = newData.filter(
                    (v, i, a) => a.findIndex((v2) => v2.url === v.url) === i
                  );
                }
                await updateAcl(newData);
                await getAclById({ id: persistSelectedAcl?.id }).then(
                  ({ data }: { data: IACLListItem }) => {
                    if (data.controlListEntries) {
                      const listWithId = data.controlListEntries.map((el) => ({
                        ...el,
                        id: _.uniqueId(),
                      }));
                      setControlListEntriesArr(listWithId);
                    }
                  }
                );
              }
            }
          }
        };

        fileReader.readAsText(selectedFile);

        setSelectedFile(undefined);
        setSelectionValue(undefined);
        openSnackBar(t('GLOBAL.SUCCESS_UPLOAD'));
        setIsUploadOpen(false);
      } catch (error: any) {
        openSnackBar(error?.response?.data?.message || 'Something went wrong');
      } finally {
        setIsLoading(false);
      }
    }
  }, [
    selectedFile,
    t,
    controlListEntriesArr,
    selectionValue,
    updateAcl,
    persistSelectedAcl?.id,
    getCategoryCode,
    setControlListEntriesArr,
  ]);

  const handleTableAction = useCallback(() => {
    switch (selectionValue) {
      case TableOptions.DeleteSelected:
        handleDeleteSelected();
        break;
      case TableOptions.ExportSelected:
      case TableOptions.ExportTable:
      case TableOptions.DownloadExample:
        exportToCSV();
        break;
      case TableOptions.ImportTable:
      case TableOptions.ImportTableAppend:
        setIsUploadOpen(true);
        break;
      default:
        break;
    }
  }, [handleDeleteSelected, selectionValue, exportToCSV]);

  return (
    <>
      <div className={classes.wrapper}>
        <MUISelect
          MenuProps={{
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            classes: { paper: classes.menu },
          }}
          disabled={!editMode}
          id={'id'}
          onChange={(e) => setFieldValue(e)}
          value={selectionValue}
          placeholder={t('GLOBAL.TABLE_ACTIONS')}
          className={classes.select}
        >
          {options(t).map(({ label, value }) => {
            return value === 'none' ? (
              <MUIMenuItem value={value} disabled className={classes.listGroup}>
                <span>{label}</span>
              </MUIMenuItem>
            ) : (
              <MUIMenuItem className={classes.listItem} value={value}>
                {label}
              </MUIMenuItem>
            );
          })}
        </MUISelect>
        <Button
          onClick={() => handleTableAction()}
          disabled={selectionValue === undefined || !editMode}
          color="primary"
          text={t('GLOBAL.GO')}
        />
      </div>
      <MUIDialog open={isUploadOpen} onClose={() => setIsUploadOpen(false)}>
        <MUIDialogTitle className={classes.dialogTitle}>
          {t('GLOBAL.UPLOAD_CSV')}
        </MUIDialogTitle>
        <MUIDialogContent>
          {!selectedFile && (
            <div className={classes.formGroup}>
              <Uploader
                accept=".csv"
                isShowing={true}
                getFileData={setSelectedFile}
              />
            </div>
          )}

          {selectedFile && (
            <FileInfoCard
              fileData={selectedFile}
              isShowing={!!selectedFile}
              removeFile={() => setSelectedFile(undefined)}
              noActionButtons
            />
          )}
        </MUIDialogContent>

        <MUIDialogActions>
          <Button
            text={t('GLOBAL.UPLOAD')}
            disabled={!selectedFile || isLoading}
            onClick={handleSubmitForm}
          />
        </MUIDialogActions>
      </MUIDialog>
    </>
  );
};

export default TableActions;
