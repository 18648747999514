import _ from 'lodash';
// ui
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ZButton from 'UI/Buttons/ZButton';
import { useRecoilState } from 'recoil';

// services
import {
  toggleModalDirect,
  openSnackBar,
  toggleModalDiffered,
} from 'utils/storeUtils';
import { deletePolicy } from '../../api/zDevService';
import { zScanPolicyListAtom } from 'components/main/ZScanPolicies/atoms';
import withRouter from 'components/hocs/withRouter';

const ZDevPolicyDeleteConfirm = ({ data, updateUrl }) => {
  const [zScanPolicylist, setzScanPolicyList] =
    useRecoilState(zScanPolicyListAtom);
  const selectedPolicyLens = zScanPolicylist.filter(
    (e) => e.value !== data.value
  );

  const handleDelete = () => {
    deletePolicy({
      policyId: data.value,
    })
      .then(() => {
        //delete the policy
        setzScanPolicyList(selectedPolicyLens);

        toggleModalDirect('ZDevPolicyDeleteConfirm', false);
        openSnackBar(`Successfully Deleted ${data.label}`);
        updateUrl({ policyId: zScanPolicylist?.[0]?.value });
      })
      .catch((error) => {
        toggleModalDirect('ZDevPolicyDeleteConfirm', false);
        openSnackBar(
          `Failed to delete policy: ${_.get(
            error,
            'response.data.message',
            'Unknown Error'
          )}`
        );
        console.error(error);
      });
  };
  return (
    <DialogContent>
      <p>Are you sure you want to delete {data.label}?</p>
      <DialogActions>
        <ZButton
          styleName="modalCancel"
          action={toggleModalDiffered('ZDevPolicyDeleteConfirm', false)}
          color="secondary"
          buttonText="Cancel"
        />
        <ZButton
          action={handleDelete}
          buttonType="submit"
          color="primary"
          styleName="modalSave"
          buttonText="Delete"
        />
      </DialogActions>
    </DialogContent>
  );
};

ZDevPolicyDeleteConfirm.defaultProps = {
  data: {},
};

export default withRouter(ZDevPolicyDeleteConfirm);
