import moment from 'moment';
import { PROTECTION_COVERAGE_FIELDS, PROTECTION_COVERAGE_FIELDS_IOS } from '../ProtectionCoverage/utils';
import { EPROTECTION_STATUSES_ENUM } from '../ZShieldApps/models';
import { ProtectionStatus } from '../ZShieldApps/ProtectionStatus';
import { IAppDetailMapping, IBuildData, IProtectionStatuses } from './models';

export const PROTECTION_STATUSES: IProtectionStatuses = {
  ERRORED: 'Protection error',
  IN_PROGRESS: 'In Progress',
  OUTDATED_ZSHIELD_VERSION: 'Protection update available',
  QUEUED: 'In Progress',
  READY: 'Protected',
  SUCCESS: 'In Progress',
  UPLOADED: 'In Progress',
};

export const APP_DETAIL_MAPPINGS: IAppDetailMapping[] = [
  {
    id: 1,
    key: 'bundleId',
    label: 'Package / Bundle ID',
  },
  {
    id: 2,
    key: 'version',
    label: 'Version',
  },
  {
    id: 3,
    key: 'description',
    label: 'Description',
  },
  {
    id: 4,
    key: 'teamName',
    label: 'Team'
  },
  {
    id: 5,
    key: 'groupName',
    label: 'Group'
  },
  {
    id: 6,
    key: 'protectedOn',
    label: 'Protection Date',
    formatFn: (date: string | number | boolean | undefined | null) => {
      if (typeof date === 'string' && date !== 'N/A') {
        return moment(new Date(date)).format('DD-MMM-YYYY');
      }
      return date;
    },
  },
  {
    id: 7,
    key: 'status',
    label: 'Status',
    formatFn: (status: string | number | boolean | undefined | null) => {
      if (
        typeof status === 'string' &&
        Object.keys(PROTECTION_STATUSES).includes(status)
      ) {
        return <ProtectionStatus status={status} hideTooltip={status === EPROTECTION_STATUSES_ENUM.ERRORED.value} />;
      }
      return status;
    },
  },
  {
    id: 8,
    key: 'zshieldVersion',
    label: 'zShield Version',
  },
];
export function protectionCoverageSelectionHelper(singleBuild: IBuildData | null) {
  if (!singleBuild) {
    return {};
  }

  // we need to add both coverages together because the single build does NOT tell us what os it is
  // i.e. Android or iOS

  const combinationIOSandAndroidCoverages = [...PROTECTION_COVERAGE_FIELDS, ...PROTECTION_COVERAGE_FIELDS_IOS];
  return combinationIOSandAndroidCoverages.reduce((accum, value) => {
    const fieldName = value?.fieldName;
    return {
      ...accum,
      [fieldName]: singleBuild[fieldName]
    };
  }, {});


}

