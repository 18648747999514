import { TFunction } from 'react-i18next';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export enum TableAction {
  EnableAllSelected = 'enableAllSelected',
  DisableAllSelected = 'disableAllSelected',
  Informational = 'informational',
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  Critical = 'critical',
}

export interface ITableActionGroup {
  group: string;
  actions: {
    value: string;
    label: string;
  }[];
}

export const tableActions = (
  t: TFunction<'translation', undefined>
): ITableActionGroup[] => [
  {
    group: t('GLOBAL.SELECTED_ROWS'),
    actions: [
      {
        value: TableAction.EnableAllSelected,
        label: 'Enable All Selected',
      },
      {
        value: TableAction.DisableAllSelected,
        label: 'Disable All Selected',
      },
      {
        value: TableAction.Informational,
        label: 'INFORMATIONAL',
      },
      {
        value: TableAction.Low,
        label: 'LOW',
      },
      {
        value: TableAction.Medium,
        label: 'MEDIUM',
      },
      {
        value: TableAction.High,
        label: 'HIGH',
      },
      {
        value: TableAction.Critical,
        label: 'CRITICAL',
      },
    ],
  },
];
