import cc from 'classcat';
import MuiDrawer from '@material-ui/core/Drawer';
import React, { useContext } from 'react';
import { DrawerContext } from '../DrawerProvider';
import { IDrawerContext } from '../DrawerProvider/models';
import useStyles from './useStyles';

export function DrawerContainer() {
  const classes = useStyles();
  const { isOpen, closeDrawer, drawer, drawerData } =
    useContext<Partial<IDrawerContext<any>>>(DrawerContext);
  if (!isOpen || !drawer) {
    return null;
  }
  return (
    <MuiDrawer
      BackdropProps={{ invisible: true }}
      anchor="right"
      className={cc([classes.drawer, drawerData.classList])}
      classes={{ paper: cc([classes.drawer, drawerData.classList]) }}
      style={{ zIndex: 1300 }}
      onClose={closeDrawer}
      open={isOpen}
      variant="temporary"
    >
      {React.createElement(drawer)}
    </MuiDrawer>
  );
}
