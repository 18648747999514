import { IFilterEditor, ILocationQuery } from 'components/UI/Table/models';
import memoizeTableQuery from 'components/UI/Table/utils/memoizeTableQuery';
import moment from 'moment';
import { IParamObj } from '../common/models';
import { Api } from 'config/axiosConfig';

const url = 'api/devices/v1/os-versions/stats?';

export const createRSQLParams = (filters: IFilterEditor[]) => {
  return (
    filters
      // Remove non-rsql filters
      .filter(({ useURLSearchParams }) => !useURLSearchParams)
      .map(({ name, customRSQLOperator, type, value }) => {
        // This is used for string/number/SINGLE-select filters with custom RSQL operators
        if (customRSQLOperator && !!value && !value?.[0]) {
          return `${name}=${customRSQLOperator}=${value}`;
        }

        if (type === 'date' && !!value) {
          return `${name}==${moment(value).format('YYYY-MM-DD')}`;
        }

        if (type === 'select' && value?.[0]) {
          // This is used for MULTI-select filters
          const rsqlOperator = customRSQLOperator ?? '';
          const rsqlInOperator = customRSQLOperator ?? 'in';
          return value.length === 1
            ? `${name}=${rsqlOperator}=${value[0]}`
            : `${name}=${rsqlInOperator}=(${value.join(',')})`;
        }

        return `${name}==${
          type === 'string' && typeof value === 'string'
            ? `*${value.replace(/ /g, '*')}*`
            : String(value ?? '')
        }`;
      })
      .join(';')
  );
};

export const createParams = (
  queries: ILocationQuery,
  filters: IFilterEditor[]
) => {
  // Remove nulls and empty string/arrays
  const nonNullFilters = filters.filter(
    ({ value }) =>
      typeof value === 'number' ||
      (typeof value === 'string' && value !== '') ||
      value?.[0]
  );

  // Create params for RSQL
  const search = createRSQLParams(nonNullFilters);

  // Create params excluded from RSQL
  const nonRSQLParams: IParamObj = {};
  nonNullFilters
    .filter(({ useURLSearchParams }) => useURLSearchParams)
    .forEach(({ name: key, value }) => {
      nonRSQLParams[key] = String(value);
    });

  const { page, size, order, orderBy, teamId } = queries;

  return new URLSearchParams({
    page: page,
    size: size,
    teamId: teamId ?? '',
    sort: `${orderBy},${order}`,
    ...nonRSQLParams,
    search,
  } as Record<string, string>);
};

export const fetchTableData = async (
  query: ILocationQuery,
  filters: IFilterEditor[],
  url: string
) => {
  let data = {
    content: [],
    totalElements: 0,
    numberOfElements: 0,
  };
  const params = createParams(query, filters);

  const apiUrl = `${url}${params.toString()}`;

  try {
    const result = await Api.get(apiUrl);
    if (!!result?.data) {
      data = result.data;
    }
  } catch (e) {
    console.error(e);
  }
  return {
    data: data?.content ?? [],
    count: data?.totalElements ?? 0,
  };
};

const memoizeQuery = (filters: IFilterEditor[]) => {
  return memoizeTableQuery(async (query: ILocationQuery) => {
    return await fetchTableData(query, filters, url);
  });
};
export default memoizeQuery;
