import Button from 'components/UI/Button';
import Select, { ISelectItem } from 'components/UI/input/Select';
import TextField from 'components/UI/input/TextField';
import { Formik, Field as FormikField, Form as FormikForm } from 'formik';
import { TLanguageOption } from 'models/language';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IUserAccountProfile, TUserAccountProfileResponse } from '../models';
import initialValues from './initialValues';
import Schema from './schema';
import useStyles from './useStyles';

interface IFormProps {
  account: IUserAccountProfile;
  onSubmit: (values: IUserAccountProfile) => void;
}

const languagePreferences: ISelectItem<TLanguageOption>[] = [
  {
    value: 'en-us',
    label: 'English',
  },
  {
    value: 'ja-jp',
    label: 'Japanese',
  },
];

const Form: React.FC<IFormProps> = ({ account, onSubmit }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const initialFormValues = useMemo(
    () => initialValues(account as unknown as TUserAccountProfileResponse),
    [account]
  );

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={Schema(t)}
      validateOnBlur
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({
        dirty,
        handleSubmit: handleFormikSubmit,
        isSubmitting,
        isValid,
        setFieldValue,
      }) => (
        <FormikForm>
          <FormikField
            name="firstName"
            label={t('USER_SETTINGS.PERSONAL_INFO.FIRST_NAME')}
            component={TextField}
            autoComplete="off"
          />
          <FormikField
            name="middleName"
            label={t('USER_SETTINGS.PERSONAL_INFO.MIDDLE_NAME')}
            component={TextField}
            autoComplete="off"
          />
          <FormikField
            name="lastName"
            label={t('USER_SETTINGS.PERSONAL_INFO.LAST_NAME')}
            component={TextField}
            autoComplete="off"
          />
          <FormikField
            name="email"
            label={t('GLOBAL.EMAIL')}
            component={TextField}
            autoComplete="off"
          />
          <FormikField
            name="notificationEmail"
            label={t('USER_SETTINGS.PERSONAL_INFO.NOTIFICATION_EMAIL')}
            component={TextField}
            autoComplete="off"
          />
          <FormikField
            name="phone"
            label={t('USER_SETTINGS.PERSONAL_INFO.CONTACT_PHONE')}
            component={TextField}
            autoComplete="off"
          />
          <FormikField
            autoComplete="off"
            className={classes.selectLanguage}
            component={Select}
            disabled={isSubmitting}
            label={t('USER_SETTINGS.PERSONAL_INFO.SELECT_LANGUAGE')}
            multiple={false}
            name="languagePreference"
            options={languagePreferences}
            setFieldValue={setFieldValue}
            type="select"
            disableClearable="true"
          />
          <Button
            color="secondary"
            text={t('USER_SETTINGS.PERSONAL_INFO.UPDATE_PROFILE')}
            disabled={!dirty || isSubmitting || !isValid}
            onClick={() => handleFormikSubmit()}
          />
        </FormikForm>
      )}
    </Formik>
  );
};

export default Form;
