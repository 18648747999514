import * as React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { getActiveModalAtom } from 'atoms/modals';
import { useSetRecoilState } from 'recoil';
import { ZScanAppEdit_TITLE } from 'components/modals/ZScanAppEdit';
import { IZScanApp } from './models';

interface IZScanEditButtonProps {
  data: Partial<IZScanApp>;
}

const activeModalAtom = getActiveModalAtom<Partial<IZScanApp>>();
const ZScanEditButton: React.FunctionComponent<IZScanEditButtonProps> = ({ data }) => {
  const setActiveModal = useSetRecoilState(activeModalAtom);
  return (
    <EditIcon
      id="action-column"

      className="cursor--pointer"
      onClick={() => {
        setActiveModal({
          active: ZScanAppEdit_TITLE,
          payload: data
        });
      }}
    />
  );
};

export default ZScanEditButton;
