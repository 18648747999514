import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import GlobalIndicator from 'components/main/common/GlobalIndicatorWrapper';

export function GlobalMenuOption(props) {
  return (
    <MenuItem
      className={props.selectProps.classes.menuItem}
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.isMulti ? (
        <React.Fragment>
          <Checkbox checked={props.isSelected} /> {props.children}
        </React.Fragment>
      ) : (
        <div
          style={{
            paddingLeft: '10px',
            paddingRight: '10px',
          }}
        >
          <GlobalIndicator
            inline
            isGlobal={props.data.accountBounded}
            text={props.children}
          />
        </div>
      )}
    </MenuItem>
  );
}
