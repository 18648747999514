import {
  createContext,
  ReactNode,
  useState,
  useContext,
  useEffect,
} from 'react';
import { IAppPolicyTriggerRule, ISelectItem } from './models';
import {
  IAPCFormData,
  IAffectedAppsPostPayload,
  ICategory,
} from './AppCharacteristics/APCCreatePolicyModal/models';
import { getAppCharacteristics } from 'api/AppPolicyService';

export type AppCharacteristicsContextType = {
  fullTriggerRules: IAppPolicyTriggerRule[] | undefined;
  setFullTriggerRules: React.Dispatch<
    React.SetStateAction<IAppPolicyTriggerRule[]>
  >;
  setAppPolicyTabSetting: React.Dispatch<React.SetStateAction<number>>;
  appPolicyTabSetting: number;
  appCharacteristicsList: ICategory[] | undefined;
  setAppCharacteristicsList: React.Dispatch<
    React.SetStateAction<ICategory[] | undefined>
  >;
  setDeviceApiCall: React.Dispatch<React.SetStateAction<boolean>>;
  deviceApiCall: boolean;
  affectedAppsPostDataPayload: IAffectedAppsPostPayload;
  setAffectedAppsPostDataPayload: React.Dispatch<
    React.SetStateAction<IAffectedAppsPostPayload>
  >;
  appPolicyDetails: ISelectItem | null;
  setAppPolicyDetails: React.Dispatch<React.SetStateAction<ISelectItem | null>>;
  appCharFormData: IAPCFormData | undefined;
  setAppCharFormData: React.Dispatch<
    React.SetStateAction<IAPCFormData | undefined>
  >;
};

export const AppCharacteristicsContext =
  createContext<AppCharacteristicsContextType>({
    fullTriggerRules: [],
    setFullTriggerRules: () => [],
    appPolicyTabSetting: 0,
    setAppPolicyTabSetting: () => 0,
    appCharacteristicsList: undefined,
    setAppCharacteristicsList: () => undefined,
    setDeviceApiCall: () => false,
    deviceApiCall: false,
    affectedAppsPostDataPayload: { all: [], any: [] },
    setAffectedAppsPostDataPayload: () => undefined,
    appPolicyDetails: null,
    setAppPolicyDetails: () => null,
    appCharFormData: undefined,
    setAppCharFormData: () => undefined,
  });

export const AppCharacteristicsProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const [fullTriggerRules, setFullTriggerRules] = useState<
    IAppPolicyTriggerRule[]
  >([]);
  const [appPolicyTabSetting, setAppPolicyTabSetting] = useState<number>(0);
  const [appCharacteristicsList, setAppCharacteristicsList] =
    useState<ICategory[]>();
  const [deviceApiCall, setDeviceApiCall] = useState<boolean>(false);
  const [affectedAppsPostDataPayload, setAffectedAppsPostDataPayload] =
    useState<IAffectedAppsPostPayload>({ all: [], any: [] });
  const [appPolicyDetails, setAppPolicyDetails] = useState<ISelectItem | null>(
    null
  );
  const [appCharFormData, setAppCharFormData] = useState<
    IAPCFormData | undefined
  >(undefined);

  useEffect(() => {
    const fetchAppList = async () => {
      const { data } = await getAppCharacteristics();
      return setAppCharacteristicsList(data?.categories);
    };
    fetchAppList();
  }, []);

  return (
    <AppCharacteristicsContext.Provider
      value={{
        fullTriggerRules,
        setFullTriggerRules,
        appPolicyTabSetting,
        setAppPolicyTabSetting,
        appCharacteristicsList,
        setAppCharacteristicsList,
        setDeviceApiCall,
        deviceApiCall,
        affectedAppsPostDataPayload,
        setAffectedAppsPostDataPayload,
        appPolicyDetails,
        setAppPolicyDetails,
        appCharFormData,
        setAppCharFormData,
      }}
    >
      {children}
    </AppCharacteristicsContext.Provider>
  );
};

export default function useAppCharacteristics() {
  return useContext(AppCharacteristicsContext);
}
