import { ISelectItem } from 'components/UI/input/Select';
import { IAccountData, IFeatureListForFormik, IFeatureListResponseData } from './models';

export const EXCLUDE_PLANS = [
  'Legacy Consumer zIPS',
  'Legacy Consumer zIPS + zScan',
];

export const DATA_RETENTION_VALUES = [
  {
    label: 'Indefinite',
    value: 2147483647,
  },
  { label: '7 days', value: 7 },
  { label: '30 days', value: 30 },
  { label: '60 days', value: 60 },
  { label: '90 days', value: 90 },
  { label: '180 days', value: 180 },
  { label: '365 days', value: 365 },
];

export const resolveAccountType = (data: Partial<IAccountData>): string => {
  let result = 'managed';

  const businessPartner = data?.businessPartner ?? false;
  const managingAccount = data?.managingAccount ?? false;

  if (!businessPartner && !managingAccount) {
    result = 'default';
  }
  if (!!businessPartner && !managingAccount) {
    result = 'partner';
  }

  return result;
};

export const isLegacyAccount = (data: Partial<IAccountData>): boolean => {
  let result = false;

  (data?.subscription?.plans ?? [])
    .map(({ name }) => name)
    .every((name) => {
      let everyResult = true;

      if (EXCLUDE_PLANS.includes(name)) {
        result = true;
        everyResult = false;
      }

      return everyResult;
    });

  return result;
};

export const generateRetentionValue = (days: number): ISelectItem =>
  days <= 365
    ? { label: `${days} days`, value: days }
    : { label: 'Indefinite', value: null };

export const MARKET_VERTICAL_OPTIONS = [
  { label: 'Financial Services', value: 'FINANCIAL_SERVICES' },
  {
    label: 'Healthcare & Life Sciences',
    value: 'HEALTHCARE_AND_LIFE_SCIENCES',
  },
  { label: 'Communications', value: 'COMMUNICATIONS' },
  { label: 'Consumer Goods', value: 'CONSUMER_GOODS' },
  { label: 'Public Sector', value: 'PUBLIC_SECTOR' },
  { label: 'Retail', value: 'RETAIL' },
  { label: 'Manufacturing', value: 'MANUFACTURING' },
  { label: 'Media', value: 'MEDIA' },
  {
    label: 'Transportation & Hospitality',
    value: 'TRANSPORTATION_AND_HOSPITALITY',
  },
  { label: 'Automotive', value: 'AUTOMOTIVE' },
  { label: 'Energy & Utilities', value: 'ENERGY_AND_UTILITIES' },
  { label: 'Higher Education', value: 'HIGHER_EDUCATION' },
  {
    label: 'Primary & Secondary Education',
    value: 'PRIMARY_AND_SECONDARY_EDUCATION',
  },
  { label: 'Philanthropy', value: 'PHILANTHROPY' },
  { label: 'Nonprofit', value: 'NONPROFIT' },
  { label: 'Information Technology', value: 'INFORMATION_TECHNOLOGY' },
  { label: 'Software', value: 'SOFTWARE' },
  { label: 'Consulting', value: 'CONSULTING' },
  { label: 'Telcom', value: 'TELCOM' },
  { label: 'Demo', value: 'DEMO' },
];

export const dropDownOptionCache: { [key: string]: ISelectItem | undefined; } = {};

export const getMarketVerticalOption = (
  marketVertical: string
): ISelectItem => {
  if (!dropDownOptionCache.hasOwnProperty(marketVertical)) {
    dropDownOptionCache[marketVertical] = MARKET_VERTICAL_OPTIONS.find(
      (option) => {
        return option.value === marketVertical;
      }
    );
  }
  return dropDownOptionCache[marketVertical] as ISelectItem;
};

export const GEOGRAPHY_OPTIONS = [
  { label: 'North America', value: 'NORTH_AMERICA' },
  { label: 'South America', value: 'SOUTH_AMERICA' },
  { label: 'Europe', value: 'EUROPE' },
  { label: 'Asia Pacific', value: 'ASIA_PACIFIC' },
  { label: 'Middle East', value: 'MIDDLE_EAST' },
  { label: 'Africa', value: 'AFRICA' },
];

export const getGeography = (geography: string): ISelectItem => {
  if (!dropDownOptionCache.hasOwnProperty(geography as string)) {
    dropDownOptionCache[geography] = GEOGRAPHY_OPTIONS.find((option) => {
      return option.value === geography;
    });
  }
  return dropDownOptionCache[geography] as ISelectItem;
};

export const generateFeatureListValues = (featureList: IFeatureListResponseData[]) => {
  if (!featureList || !featureList?.length) {
    return {};
  }
  return featureList.reduce((accum, feature) => {
    return {
      ...accum,
      [feature.id]: !!feature?.status
    };
  }, {});
};

export const prepareFeaturesPayload = (featureFormValues: IFeatureListForFormik) => {
  return Object.keys(featureFormValues).map(featureKey => {
    const fullFeatureValue = featureFormValues[featureKey as unknown as keyof IFeatureListForFormik];
    return {
      id: Number(featureKey),
      status: fullFeatureValue ? 1 : 0
    };
  });
};