import cc from 'classcat';
import React, { useMemo } from 'react';
import HorizontalLegend from '../HorizontalLegend';
import {
  IHorizontalLegendItem,
  THorizontalLegendSortByFunc,
} from '../HorizontalLegend/models';
import Segment from './Segment';
import Summary from './Summary';
import useStyles from './useStyles';
import { useTranslation } from 'react-i18next';

export interface IDoughnutChartProps {
  customSizeClass?: string;
  forceProportionate?: boolean;
  forceSegmentsOrder?: boolean;
  icon?: React.FC | JSX.Element;
  order?: THorizontalLegendSortByFunc | boolean;
  segments: IHorizontalLegendItem[];
  showChart?: boolean;
  summarySuffix?: string | boolean;
  svgWidthPercentage?: number;
  withBackground?: boolean;
  withLegend?: boolean;
}

const DoughnutChart: React.FC<IDoughnutChartProps> = ({
  customSizeClass = '',
  forceProportionate,
  forceSegmentsOrder = false,
  icon,
  order,
  segments,
  showChart = true,
  summarySuffix,
  svgWidthPercentage = 100,
  withBackground,
  withLegend,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const scalar = useMemo(() => {
    const total = segments.reduce((a, { value }) => a + value, 0);
    return total > 100 || forceProportionate ? total : 100;
  }, [forceProportionate, segments]);

  const circleSegments = useMemo(
    () =>
      ((scalar > 100 || forceProportionate) && !forceSegmentsOrder
        ? segments.sort(({ value: a }, { value: b }) => (a > b ? 1 : -1))
        : segments
      ).map((segment, index) => (
        <Segment
          key={index}
          index={index}
          segments={segments}
          scalar={scalar}
          {...segment}
        />
      )),
    [forceProportionate, forceSegmentsOrder, scalar, segments]
  );

  return (
    <div className={classes.doughnut}>
      <div
        className={cc({
          [customSizeClass]: !!customSizeClass,
          [classes.section]: !customSizeClass,
        })}
      >
        <svg
          version="1.1"
          viewBox="0 0 42 42"
          width={`${svgWidthPercentage}%`}
          xmlns="http://www.w3.org/2000/svg"
        >
          {!!withBackground && (
            <circle
              className={classes.background}
              cx="21"
              cy="21"
              fill="none"
              r="15.91549430918954"
            />
          )}
          {showChart && circleSegments}
        </svg>
        {!!summarySuffix && (
          <div className={classes.summary}>
            {icon}
            <Summary
              segments={segments}
              summarySuffix={
                typeof summarySuffix === 'string' ? t(summarySuffix) : ''
              }
            />
          </div>
        )}
      </div>
      <div
        className={cc({
          [customSizeClass]: !!customSizeClass,
          [classes.section]: !customSizeClass,
        })}
      >
        {!!withLegend && (
          <HorizontalLegend
            className={classes.legend}
            items={segments}
            order={!!order ? order : false}
          />
        )}
      </div>
    </div>
  );
};

export default DoughnutChart;
