import {
  compareFindingAndLocations,
  prepareCompareBuilds,
} from './CompareBuildUtils';
import { generateFindingsByAssessmentId } from 'api/zDevService';
import _ from 'lodash';
import { Query } from './models';
import { SelectedRowProps } from './models';

export const fetchTableData: any = async (
  setTotalDataCount: React.Dispatch<React.SetStateAction<number>>,
  searchQuery: string,
  policyId: string,
  query: Query,
  selectedRow: SelectedRowProps[],
  fullTableData: any,
  setFullTableData: React.Dispatch<React.SetStateAction<{}>>,
  setCompareBuildOptions: (build1Data: any, build2Data: any) => void
) => {
  if (_.isEmpty(fullTableData)) {
    const fetchBuild1 = await generateFindingsByAssessmentId({
      id: selectedRow[0]?.id,
      policyId: policyId,
      search: searchQuery,
    });
    const fetchBuild2 = await generateFindingsByAssessmentId({
      id: selectedRow[1]?.id,
      policyId: policyId,
      search: searchQuery,
    });
    try {
      return Promise.all([fetchBuild1, fetchBuild2]).then(
        ([build1, build2]) => {
          setCompareBuildOptions(selectedRow[0], selectedRow[1]);

          const assessment1 = _.get(build1, 'data');
          const assessment2 = _.get(build2, 'data');

          const updatedFullTableData: any = compareFindingAndLocations(
            assessment2,
            assessment1
          );

          setFullTableData(updatedFullTableData);

          return prepareCompareBuilds({
            setTotalDataCount,
            query,
            rawTableData: fullTableData,
          });
        }
      );
    } catch (error: any) {
      console.error(error);
    }
  }

  return new Promise((resolve) => {
    return resolve(
      prepareCompareBuilds({
        setTotalDataCount,
        query,
        rawTableData: fullTableData,
      })
    );
  });
};
