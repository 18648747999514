import _ from 'lodash';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Input from '@material-ui/icons/Input';

const ExpansionRowDetails = (props) => {
  const { data, dataProperty, config, omitRowPath, handlers } = props;
  let rowMapping = config[dataProperty];
  if (omitRowPath) {
    rowMapping = _.differenceBy(rowMapping, omitRowPath, 'path');
  }
  const classes = {
    ...props.classes,
    ...props.classList,
  };

  return (
    <div className={classes.wrapper}>
      {props.title && <h3 style={{ paddingLeft: 10 }}>{props.title}</h3>}
      {config[dataProperty] &&
        rowMapping.map((configObj, index) => {
          const path = configObj.path ? configObj.path : configObj.key;
          let value = configObj.defaultValue;
          if (_.has(data, path)) {
            value = _.get(data, path);
          } else {
            return null;
          }

          if (_.startsWith(path, 'cvss') && _.isEmpty(value)) {
            return null;
          }

          if (typeof configObj.fetch === 'function') {
            value = configObj.fetch(value, data, handlers);
          }

          if (_.get(configObj, 'type', '') === 'link') {
            value = _.map(value.split('\n'), (val) => (
              <Link target="_blank" to={{ pathname: val }}>
                {val} <Input className={classes.inputIcon} />
              </Link>
            ));
          }
          if (value === 'omit') return null;
          return (
            <div key={`${value}-${index}`} className={classes.dataWrapper}>
              <div className={classes.label}>{configObj.label}</div>
              <div className={classes.labelValue}>{value}</div>
            </div>
          );
        })}
    </div>
  );
};

const styles = ({ palette }) => ({
  wrapper: {
    display: 'flex',
    width: '70%',
    alignSelf: 'flex-start',
    flexDirection: 'column',
  },

  dataWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    justifyContent: 'space-between',
  },

  label: {
    color: palette.text.secondary,
    fontWeight: 'bold',
    width: '25%',
  },

  labelValue: {
    color: palette.tables.cell,
    paddingLeft: 8,
    fontSize: 13,
    width: '75%',
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'center',
    // whiteSpace: 'break-spaces',
  },
  inputIcon: {
    fontSize: 17,
  },
});

export default withStyles(styles)(ExpansionRowDetails);
