import _ from 'lodash';
import TextField from 'components/UI/input/TextField';
import { ChangeEvent } from 'react';
import { IOktaConfig } from './models';

const key = 'OKTA';

interface IOktaProps {
  onChange: (key: string, update: () => void) => void;
  config: IOktaConfig;
}

export const Okta = ({ onChange, config }: IOktaProps) => {
  const handleChange =
    (field: string) => (event: ChangeEvent<HTMLInputElement>) =>
      onChange(
        key,
        _.update(_.cloneDeep(config), field, () => event.target.value)
      );

  return (
    <TextField
      required
      label="Audience"
      value={config.audience}
      onChange={handleChange('audience')}
    />
  );
};
