import { ISelectItem } from 'components/UI/input/Select';
import { TLanguageOption } from 'models/language';

export interface IAccountPlan {
  id: string;
  name: string;
  disabled?: boolean;
}

export interface IAccountSubscription {
  active: boolean;
  ela: boolean;
  id: string;
  plans: IAccountPlan[];
  salesforceId: string;
  subscriptionEnd: Date | string;
}

export interface IAccountDataRetentionSetting {
  auditLogDays: number;
  created: Date | string;
  id: string;
  modified: Date | string;
  threatsDays: number;
}

export interface IFeatureListResponseData {
  id: number;
  status: number;
}

export interface IFeatureListForFormik {
  [id: number]: {
    id: number;
    status: boolean;
  };
}

export interface IAccountData {
  accountPersona: string | unknown | null;
  businessPartner: boolean;
  created: Date | string;
  dataRetentionSettings: IAccountDataRetentionSetting;
  description: string;
  domains: string;
  featureList: IFeatureListForFormik | null;
  hostname: string;
  id: string;
  languagePreference: string;
  managingAccount: IAccountPlan | null;
  name: string;
  participateInZlabsResearch: boolean;
  planName: string;
  selectedAccountId: string;
  subscription: IAccountSubscription;
  tasEnabled: boolean;
  tfaRequired: boolean;
  partnerModal?: boolean;
  geography?: string;
  marketVertical?: string;
  threatDefaultsSource?: string;
}

export interface IFormSubmit {
  accountName: string;
  accountType: string;
  auditLogDays: ISelectItem | undefined;
  description: string;
  domains: string;
  ela: boolean | undefined;
  featureList: IFeatureListForFormik;
  hostname: string;
  languagePreference: ISelectItem<TLanguageOption> | undefined;
  managingAccount: ISelectItem | undefined;
  participateInZlabsResearch: ISelectItem | undefined;
  plans: string[];
  salesforceId: string;
  subscriptionEnd: string;
  tasEnabled: ISelectItem | undefined;
  tfaRequired: boolean | undefined;
  threatsDays: ISelectItem | undefined;
  geography?: ISelectItem | undefined;
  marketVertical?: ISelectItem | undefined;
  threatDefaultsSource?: string;
}

export enum ThreatDefaultsSource {
  GTS = 'GTS',
  SYSTEM_DEFAULT = 'SYSTEM_DEFAULT',
  ACCOUNT_DEFAULT = 'ACCOUNT_DEFAULT',
  INHERIT_FROM_PARTNER = 'INHERIT_FROM_PARTNER',
}
