import cc from 'classcat';
import RpmChart from 'components/UI/Chart/Rpm';
import GenericCard from 'components/UI/GenericCard';
import { ISecurityScore } from '../models';
import { default as useSharedStyles } from '../useStyles';
import useStyles from './useStyles';
import { useTranslation } from 'react-i18next';

interface ICurrentSecurityScore {
  securityScoreData: ISecurityScore;
}

const CurrentSecurityScore: React.FC<ICurrentSecurityScore> = ({
  securityScoreData,
}) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const { t, ready } = useTranslation();

  if (!ready) {
    return null;
  }

  return (
    <GenericCard className={cc([sharedClasses.card, classes.securityRiskCard])}>
      <h2>{t('MTD.INSIGHTS.CURRENT_SECURITY_SCORE')}</h2>
      <div className={sharedClasses.column}>
        <RpmChart
          percentage={
            securityScoreData?.currentScore
              ? securityScoreData?.currentScore * 0.1
              : undefined
          }
          stretch
          asScore
        />
      </div>
    </GenericCard>
  );
};

export default CurrentSecurityScore;
