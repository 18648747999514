import React, { useState } from 'react';
import { Card } from '@material-ui/core';
import { default as MUICard } from '@material-ui/core/Card';
import useStyles from './useStyles';
import { default as MuiAccordion } from '@material-ui/core/Accordion';
import { default as MuiAccordionDetails } from '@material-ui/core/AccordionDetails';
import { default as MuiAccordionSummary } from '@material-ui/core/AccordionSummary';
import { default as MUIArrowForwardIosIcon } from '@material-ui/icons/ArrowForwardIos';
import GenericCard from 'components/UI/GenericCard';
import Button from 'components/UI/Button';
import cc from 'classcat';
import CircleIcon from '../../ZScanApps/CircleComponent/index';
import { ISidebarProp } from './models';
import CompareInstancesByFindingIdTable from './CompareInstancesTable';
import PlatformIcon from '../PlatformIcon';
import { countFrequency } from '../ZScanFindings/utils';
import SeverityCircle from '../SeverityCircle';
import { ESeverity } from '../SeverityCircle/models';

interface ISidebarProps {
  selectedRowData: ISidebarProp | undefined;
}
const CompareBuildSidebar: React.FC<ISidebarProps> = ({ selectedRowData }) => {
  const classes = useStyles();
  const [commentTagHistory, setCommentTagHistory] = useState<boolean>(true);
  const [complianceExpanded, setComplianceExpanded] = useState<boolean>(true);
  const [appsExpanded, setAppsExpanded] = useState<boolean>(true);
  const [instancesExpanded, setInstancesExpanded] = useState<boolean>(true);
  const complianceCount = countFrequency(selectedRowData?.compliance ?? []);

  return (
    <div style={{ overflow: 'hidden' }}>
      <Card>
        {' '}
        <MUICard className={classes.sidebarHeader}>
          <div style={{ display: 'flex' }}>
            <div style={{ marginTop: '5px' }}>
              <PlatformIcon
                iconType={selectedRowData?.platform[0]?.toLowerCase() ?? ''}
              />
            </div>
            <h3 className={classes.appNameHeader}>{selectedRowData?.name}</h3>
          </div>
        </MUICard>
        <MuiAccordion className={classes.accordion} expanded={appsExpanded}>
          <MuiAccordionSummary
            onClick={() => setAppsExpanded(!appsExpanded)}
            className={classes.accordionHeader}
            expandIcon={<MUIArrowForwardIosIcon />}
          >
            Finding Details
          </MuiAccordionSummary>

          <MuiAccordionDetails
            classes={{
              root: classes.accordionDetails,
            }}
          >
            <GenericCard noPadding>
              <div className={classes.findingDetailsContainer}>
                <div className={classes.detailsSubContianer}>
                  <table
                    className={cc([
                      classes.detailsTable,
                      classes.detailsTableWithPadding,
                    ])}
                  >
                    <tr>
                      <td>Severity:</td>
                      <td>
                        <SeverityCircle
                          severity={selectedRowData?.severity as ESeverity}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Category:</td>
                      <td>{selectedRowData?.categoryName ?? ''}</td>
                    </tr>
                    <tr>
                      <td>Subcategory:</td>
                      <td>{selectedRowData?.subcategoryName ?? ''}</td>
                    </tr>
                    <tr>
                      <td>CVSS 3.1:</td>
                      <td>{selectedRowData?.cvss31Score ?? ''}</td>
                    </tr>
                  </table>
                </div>
                <div className={classes.detailsDescriptions}>
                  <div>
                    <span>Description</span>
                    <p>{selectedRowData?.findingDescription ?? ''}</p>
                  </div>
                  <div>
                    <span>Business Impact</span>
                    <p>{selectedRowData?.businessImpact ?? ''}</p>
                  </div>
                  <div>
                    <span>Recommendation</span>
                    <p>{selectedRowData?.recommendation ?? ''}</p>
                  </div>
                </div>
              </div>
            </GenericCard>
          </MuiAccordionDetails>
        </MuiAccordion>
        <MuiAccordion
          className={classes.accordion}
          expanded={instancesExpanded}
        >
          <MuiAccordionSummary
            onClick={() => setInstancesExpanded(!instancesExpanded)}
            className={classes.accordionHeader}
            expandIcon={<MUIArrowForwardIosIcon />}
          >
            Instances
          </MuiAccordionSummary>

          <MuiAccordionDetails
            classes={{
              root: classes.circleStyle,
            }}
          >
            <div style={{ overflow: 'hidden' }}>
              <CompareInstancesByFindingIdTable
                locations={selectedRowData?.locations}
              />
            </div>
          </MuiAccordionDetails>
        </MuiAccordion>
        <MuiAccordion
          className={classes.accordion}
          expanded={complianceExpanded}
        >
          <MuiAccordionSummary
            onClick={() => setComplianceExpanded(!complianceExpanded)}
            className={classes.accordionHeader}
            expandIcon={<MUIArrowForwardIosIcon />}
          >
            Compliance
          </MuiAccordionSummary>

          <MuiAccordionDetails
            classes={{
              root: classes.circleStyle,
            }}
          >
            <div>
              <div>
                <CircleIcon data={complianceCount.CWE} />
                <span>CWE</span>
              </div>
              <div>
                <CircleIcon data={complianceCount.CVE} />
                <span>CVE</span>
              </div>
              <div>
                <CircleIcon data={complianceCount.OWASP} />
                <span>OWASP</span>
              </div>
              <div>
                <CircleIcon data={complianceCount.MASVS} />
                <span>MASVS</span>
              </div>
            </div>
            <div>
              <div>
                <CircleIcon data={complianceCount?.GDPR} />
                <span>GDPR</span>
              </div>
              <div>
                <CircleIcon data={complianceCount?.HIPAA} />
                <span>HIPAA</span>
              </div>
              <div>
                <CircleIcon data={complianceCount?.PCI} />
                <span>PCI</span>
              </div>
              <div>
                <CircleIcon data={complianceCount?.NIAP} />
                <span>NIAP</span>
              </div>
            </div>
          </MuiAccordionDetails>
        </MuiAccordion>
        <div style={{ display: 'none' }}>
          <MuiAccordion
            className={classes.accordion}
            expanded={commentTagHistory}
          >
            <MuiAccordionSummary
              onClick={() => setCommentTagHistory(!commentTagHistory)}
              className={classes.accordionHeader}
              expandIcon={<MUIArrowForwardIosIcon />}
            >
              Comment and Tag History
            </MuiAccordionSummary>
            <MuiAccordionDetails
              classes={{
                root: classes.accordionDetails,
              }}
            >
              <GenericCard noPadding>
                <table
                  className={cc([
                    classes.detailsTable,
                    classes.detailsTableWithPadding,
                  ])}
                >
                  <h4>Comment History</h4>
                  <tr>
                    <td>User & Date:</td>
                    <td>Data</td>
                  </tr>
                  <tr>
                    <td>Comment:</td>
                    <td>Comment</td>
                  </tr>
                </table>
              </GenericCard>
              <GenericCard noPadding>
                <table
                  className={cc([
                    classes.detailsTable,
                    classes.detailsTableWithPadding,
                  ])}
                >
                  {' '}
                  <h4>Tag History</h4>
                  <tr>
                    <td>User & Date:</td>
                    <td>Data</td>
                  </tr>
                  <tr>
                    <td>Tag:</td>
                    <td>Tag</td>
                  </tr>
                </table>
              </GenericCard>
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  justifyContent: 'flex-end',
                }}
              >
                <Button text="ADD NEW COMMENT" color="secondary" />
                <Button text="ADD NEW TAG" color="secondary" />
              </div>
            </MuiAccordionDetails>
          </MuiAccordion>
        </div>
      </Card>
    </div>
  );
};
export default CompareBuildSidebar;
