import { Tooltip } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { IColumnHeader, IFilterEditor } from 'components/UI/Table/models';
import React from 'react';
import { IAuditLogRowMapping, IFilterValues } from './models';
import useStyles from './useStyles';
import ActionType from './ActionType';

export const defaultAuditLogTableHeaders: IColumnHeader[] = [
  {
    filterEditor: 'StringFilter',
    flex: 1,
    id: 'username',
    label: 'User',
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    filterEditor: 'StringFilter',
    flex: 1,
    id: 'action',
    label: 'Action',
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    flex: 1,
    id: 'timestamp',
    label: 'Date',
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    flex: 0,
    id: 'id',
    label: '',
    show: true,
    showInContextMenu: false,
    sort: false,
    minWidth: 70,
    maxWidth: 70,
  },
];

export const auditLogRowMapping: IAuditLogRowMapping[] = [
  { path: 'username' },
  {
    path: 'action',
    columnContent: (args) => {
      const actionData = args?.rowData?.action;
      if (args?.rowData?.actionType === 'THREAT') {
        return <ActionType rowData={args?.rowData} />;
      }
      if (!actionData) {
        return null;
      }
      if (actionData?.length > 50) {
        return (
          <Tooltip title={actionData}>
            <span>{actionData}</span>
          </Tooltip>
        );
      }
      return actionData;
    },
  },
  {
    path: 'timestamp',
  },
  {
    path: 'id',
    columnContent: (args) => {
      return (
        <div>
          {args.rowData?.details ||
          args.rowData?.action.toLowerCase().includes('send risk posture') ? (
            <span
              onClick={() => {
                args.toggleDetails(args.rowData?.id);
              }}
            >
              {args.isShowingDetails ? (
                <ExpandLessIcon style={{ cursor: 'pointer' }} />
              ) : (
                <ExpandMoreIcon style={{ cursor: 'pointer' }} />
              )}
            </span>
          ) : null}
        </div>
      );
    },
  },
];

export const auditLogConfig = {
  column1: [
    {
      key: 'action',
      label: 'Action',
    },
  ],
  column2: [
    {
      key: 'details',
      label: 'Details',
      fetch: (details: { [key: string]: {} }) => {
        if (details) {
          return (
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpanded={['root']}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              {RenderTree(details)}
            </TreeView>
          );
        } else {
          return null;
        }
      },
    },
  ],
};

const RenderTree = (nodes: { [key: string]: {} }) => {
  const classes = useStyles();
  return Object.keys(nodes).map((singleKey, index) => {
    const labelKey = normalizeKeys(singleKey);
    const value = nodes[singleKey];
    if (value) {
      if (typeof value === 'object' && value !== null) {
        return (
          <TreeItem
            key={singleKey + index + Math.floor(Math.random() * 6) + 1}
            nodeId={singleKey + index + Math.floor(Math.random() * 6) + 1}
            label={<span style={{ fontSize: 14 }}>{`${labelKey}`}</span>}
            className={classes.treeItem}
          >
            {typeof nodes[singleKey] === 'object'
              ? RenderTree(nodes[singleKey])
              : null}
          </TreeItem>
        );
      }
    }
    return (
      <TreeItem
        key={singleKey + index}
        nodeId={singleKey + index}
        label={
          <span
            className={classes.treeItem}
          >{`${labelKey}: ${nodes[singleKey]}`}</span>
        }
      />
    );
  });
};

const normalizeKeys = (str: string) => {
  if (/\d/.test(str)) {
    return str;
  }
  if (str === str.toUpperCase() && str !== str.toLowerCase()) {
    return str.toLowerCase();
  }

  return str
    .split('')
    .map((letter, idx) => {
      return letter.toUpperCase() === letter
        ? `${idx !== 0 ? ' ' : ''}${letter.toLowerCase()}`
        : letter;
    })
    .join('');
};

export const resolveFilters = (
  filterValues: IFilterValues
): IFilterEditor[] => {
  return [
    {
      name: 'username',
      operator: 'eq',
      type: 'string',
      value: filterValues?.username ?? '',
      useURLSearchParams: true,
    },
    {
      name: 'action',
      operator: 'eq',
      type: 'string',
      value: filterValues?.action ?? '',
      useURLSearchParams: true,
    },
  ];
};
