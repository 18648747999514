import React, { useMemo } from 'react';
import { IHorizontalLegendItem } from '../../HorizontalLegend/models';

export interface ISummaryProps {
  segments: IHorizontalLegendItem[];
  summarySuffix?: string;
}

const Summary: React.FC<ISummaryProps> = ({ segments, summarySuffix }) => {
  const summarySegments = useMemo(
    () => segments.reduce((a, { value }) => a + value, 0).toLocaleString(),
    [segments]
  );

  return (
    <>
      <h1>{summarySegments}</h1>
      {summarySuffix && <h2>{summarySuffix}</h2>}
    </>
  );
};

export default Summary;
