import { IFilterValues } from './models';

export const AVAILABLE_RISKS = [
  {
    label: 'High',
    value: 'Critical',
  },
  {
    label: 'Medium',
    value: 'Medium',
  },
  {
    label: 'Low',
    value: 'Low',
  },
];

export const AVAILABLE_OSES = [
  {
    label: 'Android',
    value: 1,
  },
  {
    label: 'iOS',
    value: 2,
  },
];

export const INITIAL_FILTER_VALUES: IFilterValues = {
  cveCount: null,
  deviceCount: null,
  nonUpgradeableCount: null,
  os: null,
  osPatchDate: '',
  osVersion: '',
  risk: '',
  upgradeableCount: null,
};
