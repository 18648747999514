import * as Yup from 'yup';
import {
  csvInjectionErrorMessage,
  csvInjectionRegex,
} from 'utils/componentUtils';

const domainRegExp =
  /^(?![^-\n]*(?:-(?!\.)[^-\n]*)*-\.)(?:(?!-)[A-Za-z0-9-]{1,63}\.)+[A-Za-z]{2,63}$/;

const domainError = ({ value, path }: { value: string; path: string }) => (
  <div key={path}>{`'${value}' not valid domain(s)`}</div>
);

const Schema = (managed?: boolean) => {
  const basicFields = {
    accountName: Yup.string()
      .min(1)
      .matches(csvInjectionRegex, csvInjectionErrorMessage)
      .required('Account name is required.'),
    description: Yup.string().matches(
      csvInjectionRegex,
      csvInjectionErrorMessage
    ),
    languagePreference: Yup.object()
      .shape({
        value: Yup.string().required('Language preference is required.'),
      })
      .typeError('Language preference is required.'),
    plans: Yup.array().required('Must select at least one plan.').min(1),
  };

  const defaultRequiredMsg = 'This data collection field is required.';

  const nonPartnerFields = {
    hostname: Yup.string().min(1).matches(domainRegExp, domainError),
    participateInZlabsResearch: Yup.mixed().required(defaultRequiredMsg),
    tasEnabled: Yup.mixed().required(defaultRequiredMsg),
    geography: Yup.mixed().when(['tasEnabled', 'participateInZlabsResearch'], {
      is: (tasEnabled: any, participateInZlabsResearch: any) =>
        tasEnabled?.value || participateInZlabsResearch?.value,
      then: Yup.mixed().required(defaultRequiredMsg),
    }),
    marketVertical: Yup.mixed().when(
      ['tasEnabled', 'participateInZlabsResearch'],
      {
        is: (tasEnabled: any, participateInZlabsResearch: any) =>
          tasEnabled?.value || participateInZlabsResearch?.value,
        then: Yup.mixed().required(defaultRequiredMsg),
      }
    ),
  };

  return Yup.object().shape({
    ...basicFields,
    ...(!!managed ? {} : nonPartnerFields),
  });
};
export default Schema;
