import GenericTable from 'components/UI/GenericTable';
import OperatingSystems from 'components/main/policies/threats/TableComponents/OperatingSystems';
import SeveritySelect from 'components/main/policies/threats/TableComponents/SeveritySelect';
import TypeColumn from 'components/main/policies/threats/TableComponents/TypeColumn';
import {
  ISelectTRMPolicyTableData,
  PolicyChangeOptions,
} from 'components/main/policies/threats/models';
import { trmPolicyTableColumnHeaders } from './TRMPolicy.mappings';
import Distribution from './TableComponents/Distribution';
import Enabled from './TableComponents/Enabled';

interface ITRMPolicyTableProps {
  tableData: ISelectTRMPolicyTableData[];
  handleTRMPolicyRuleChange: (
    optionName: PolicyChangeOptions,
    value: unknown,
    rule: ISelectTRMPolicyTableData | string
  ) => void;
}

const TRMPolicyTable = ({
  tableData,
  handleTRMPolicyRuleChange,
}: ITRMPolicyTableProps) => {
  return (
    <GenericTable
      columnHeaders={trmPolicyTableColumnHeaders}
      rowMapping={[
        {
          path: 'id',
          columnContent: ({ rowData }) => <span>{rowData.threatTypeId}</span>,
        },
        {
          path: 'enabled',
          columnContent: ({ rowData }) => (
            <Enabled
              data={!!rowData['enabled']}
              onChange={() =>
                handleTRMPolicyRuleChange(
                  PolicyChangeOptions.Enabled,
                  !rowData['enabled'],
                  rowData as unknown as ISelectTRMPolicyTableData
                )
              }
            />
          ),
        },
        {
          path: 'visible',
          columnContent: ({ rowData }) => (
            <Enabled
              data={!!rowData['visible']}
              onChange={() =>
                handleTRMPolicyRuleChange(
                  PolicyChangeOptions.Visible,
                  !rowData['visible'],
                  rowData as unknown as ISelectTRMPolicyTableData
                )
              }
            />
          ),
        },
        {
          path: 'type',
          columnContent: ({ rowData }) => (
            <TypeColumn
              rowData={rowData as unknown as ISelectTRMPolicyTableData}
            />
          ),
        },
        {
          path: 'platform',
          columnContent: ({ rowData }) => (
            <OperatingSystems
              rowData={rowData as unknown as ISelectTRMPolicyTableData}
            />
          ),
        },
        {
          path: 'severity',
          columnContent: ({ rowData }) => (
            <SeveritySelect
              rowData={rowData as unknown as ISelectTRMPolicyTableData}
              handleTRMPolicyRuleChange={handleTRMPolicyRuleChange}
            />
          ),
        },
        {
          path: 'sdkThreats',
          columnContent: ({ rowData }) => (
            <Distribution
              path="sdkThreats"
              data={rowData['distributions'] as number[]}
            />
          ),
        },
        {
          path: 'enterpriseThreats',
          columnContent: ({ rowData }) => (
            <Distribution
              path="enterpriseThreats"
              data={rowData['distributions'] as number[]}
            />
          ),
        },
        {
          path: 'consumerThreats',
          columnContent: ({ rowData }) => (
            <Distribution
              path="consumerThreats"
              data={rowData['distributions'] as number[]}
            />
          ),
        },
      ]}
      tableData={tableData ? (tableData as any) : []}
      tableId="TRM Policy Table"
    />
  );
};

export default TRMPolicyTable;
