import MUIPhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import MUIAndroidIcon from '@material-ui/icons/Android';
import { IOSContentMapSet, TClasses } from './models';

export const OS_CONTENT_MAPPING: IOSContentMapSet = {
  total: {
    id: 'total',
    tooltip: (count: number) => count,
    label: 'Total',
    icon: (classes: TClasses) => (
      <MUIPhoneAndroidIcon className={classes.icon} />
    ),
    styleName: (classes: TClasses) =>
      `${classes.backgroundCircle} ${classes.backgroundCircle}`,
  },
  android: {
    id: 'android',
    tooltip: (count: number) => count,
    name: 'Android',
    label: 'Android',
    icon: (classes: TClasses) => <MUIAndroidIcon className={classes.icon} />,
    styleName: (classes: TClasses) =>
      `${classes.backgroundCircle} ${classes.backgroundCircleAndroid}`,
  },
  ios: {
    id: 'apple',
    tooltip: (count: number) => count,
    name: 'iOS',
    label: 'iOS',
    icon: (classes: TClasses) => (
      <i className={`fab fa-apple ${classes.appleIcon} ${classes.icon}`} />
    ),
    styleName: (classes: TClasses) =>
      `${classes.backgroundCircle} ${classes.backgroundCircleIos}`,
  },
  'chrome os': {
    id: 'chrome os',
    tooltip: (count: number) => count,
    name: 'Chrome OS',
    label: 'Chrome OS',
    icon: (classes: TClasses) => (
      <i className={`fab fa-chrome ${classes.appleIcon} ${classes.icon}`} />
    ),
    styleName: (classes: TClasses) =>
      `${classes.backgroundCircle} ${classes.backgroundCircleChromeOS}`,
  },
};
