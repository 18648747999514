import React from 'react';
import Moment from 'moment';
import _ from 'lodash';
import momentHelper from 'utils/momentUtils';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import Input from '@material-ui/icons/Input';
import Tooltip from '@material-ui/core/Tooltip';
import { toggleModalDiffered } from 'utils/storeUtils';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import { enterAccount } from 'api/apis';

// super ui partner users table
export const managedAccountsTableHeaders = [
  {
    id: 'name',
    label: 'Name',
    showInContextMenu: true,
    show: true,
    sort: true,
    flex: 1,
  },
  {
    id: 'description',
    numeric: false,
    label: 'Description',
    showInContextMenu: true,
    show: true,
    sort: true,
    flex: 1,
  },
  {
    id: 'planName',
    numeric: false,
    label: 'Plan',
    sort: false,
    showInContextMenu: true,
    show: true,
    flex: 1,
  },
  {
    id: 'subscription.subscriptionEnd',
    numeric: false,
    label: 'Ends',
    showInContextMenu: true,
    show: true,
    sort: true,
    flex: 1,
  },
  {
    id: 'created',
    label: 'Created',
    showInContextMenu: true,
    show: true,
    sort: true,
    flex: 1,
  },
  {
    id: 'modified',
    label: 'Modified',
    showInContextMenu: true,
    show: true,
    sort: true,
    flex: 1,
  },

  {
    id: 'actions',
    numeric: false,
    label: 'Actions',
    sort: false,
    show: true,
    flex: 1,
  },
];

export const managedAccountsColumnMapping = [
  {
    path: 'name',
    columnContent: ({ classList, rowData }) => {
      return (
        <div className={classList.nameWrapper}>
          <Input
            onClick={() => enterAccount({ accountId: rowData.id })}
            classes={{ root: classList.enterAccount }}
          />
          {rowData.name}
        </div>
      );
    },
  },
  { path: 'description' },
  { path: 'planName' },
  {
    path: 'subscription.subscriptionEnd',
    columnContent: ({ rowData }) => {
      const endDate = rowData.subscription.subscriptionEnd;
      if (Moment(endDate).isBefore()) {
        return `Expired - ${Moment(endDate).fromNow()}`;
      }
      return momentHelper(endDate);
    },
  },
  { path: 'created', columnContent: ({ rowData }) => momentHelper(rowData.created) },
  { path: 'modified', columnContent: ({ rowData }) => momentHelper(rowData.modified) },

  {
    path: 'actions',
    csvContent: null,
    columnContent: ({ rowData }) => {
      return (
        <div className="flex--spaced">
          <ProtectedComponent allow={{ managed_accounts: 'create' }} isPartner>
            <Tooltip title="Edit Account">
              <EditIcon
                className="hover--link"
                onClick={toggleModalDiffered(
                  'AccountsCreateEdit',
                  {
                    selectedAccountId: _.get(rowData, 'id'),
                    partnerModal: true,
                    ...rowData,
                  },
                  {
                    title: 'Edit Managed Account',
                    fullWidth: true,
                  }
                )}
              />
            </Tooltip>
          </ProtectedComponent>
          {!rowData.businessPartner && (
            <ProtectedComponent allow={{ managed_accounts: 'create' }} isPartner>
              <Tooltip title="Remove Account from this managed list">
                <DeleteIcon
                  className="hover--link"
                  onClick={toggleModalDiffered(
                    'PartnerAccountsDeleteConfirm',
                    {
                      rowData,
                    },
                    {
                      title: 'Delete Account',
                    }
                  )}
                />
              </Tooltip>
            </ProtectedComponent>
          )}
        </div>
      );
    },
  },
];

export const resolveFilters = (
  filterValues
) => {
  return [
    {
      name: 'name',
      operator: 'contains',
      type: 'string',
      value: filterValues?.name ?? '',
    },
    {
      name: 'description',
      operator: 'contains',
      type: 'string',
      value: filterValues?.description ?? '',
    }
  ];
};
