/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { default as MUIAddIcon } from '@material-ui/icons/Add';
import { default as MUIDeleteIcon } from '@material-ui/icons/Delete';
import { default as MUIEditIcon } from '@material-ui/icons/Edit';
import { getAllAppsByGroupIds } from 'api/InAppsService';
import {
  cleanPrivacyCollectibles,
  cleanPrivacyPolicyForTable,
  deletePrivacyPolicy,
  fetchAllCollectibles,
  fetchAllPrivacyPoliciesList,
  fetchPrivacyPolicyById,
} from 'api/PrivacyPolicyService';
import { headerActions as headerActionsAtom } from 'atoms/header';
import { getActiveModalAtom } from 'atoms/modals';
import { deployDateAtom } from 'atoms/policies';
import { GenericPromptModal_TITLE } from 'components/UI/Modals/GenericPrompt';
import { IGenericPromptModalData } from 'components/UI/Modals/GenericPrompt/models';
import ViewSelector from 'components/UI/ViewSelector';
import AlertIcon from 'components/UI/icons/AlertIcon';
import CopyIcon from 'components/UI/icons/CopyIcon';
import withRouter from 'components/hocs/withRouter';
import GlobalIndicatorWrapper from 'components/main/common/GlobalIndicatorWrapper';
import { IQueryParams } from 'components/main/devices/common/models';
import _ from 'lodash';
import { EDistribution } from 'models/distribution';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useRecoilState, useSetRecoilState } from 'recoil';
import asyncSleep from 'utils/asyncSleep';
import { reduceForMultiSelectWithGlobal } from 'utils/componentUtils';
import { openSnackBar } from 'utils/storeUtils';
import AddClonePolicy, {
  AddClonePolicy_TITLE,
} from '../appPolicy/AddClonePolicy';
import { IAppPolicyCreateModalData } from '../appPolicy/AddClonePolicy/models';
import { TpolicyMap } from '../appPolicy/models';
import ConfirmDeleteMessage from '../common/ConfirmDeleteMessage';
import CustomPolicyOption from '../common/CustomPolicyOption';
import LastDeployedBox from '../common/LastDeployedBox';
import { default as OldPrivacyPolicySkeleton } from './PrivacyPolicySkeleton';
import { default as OldPrivacyPolicyTable } from './PrivacyPolicyTable';
import {
  hasChanges as hasChangesAtom,
  preselectedPolicy as preselectedPolicyAtom,
} from './atoms';
import {
  IPrivacyPoliciesResponse,
  IPrivacyPolicyCategoryRule,
  IPrivacyPolicyData,
  IPrivacyPolicyRuleData,
} from './model';
import useStyles from './useStyles';
import { updatePrivacyPolicyData } from './utils';

const PrivacyPolicyTable: any = OldPrivacyPolicyTable;
const PrivacyPolicySkeleton: any = OldPrivacyPolicySkeleton;

interface IPrivacyPolicyProps {
  location: {
    query: {
      teamId: string;
      privacy: string;
    };
  };
  mtdModule: boolean;
  emmScoped: string[];
  scopeBounds: string;
  module: string;
  updateUrl: (params: IQueryParams) => void;
}

enum PrivacyPolicyAction {
  ADDCLONE = 'ADDCLONE',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
}

const activeModalAtom = getActiveModalAtom<
  IAppPolicyCreateModalData | IGenericPromptModalData
>();

const PrivacyPolicy: React.FC<IPrivacyPolicyProps> = (props) => {
  const { emmScoped, mtdModule, location, module, updateUrl } = props;

  const classes = useStyles();

  const { t, ready } = useTranslation();

  const [lastAction, setLastAction] = useState<string | undefined>();

  const [, setApps] = useState([]);
  const [privacyList, setPrivacyList] = useState<IPrivacyPolicyData[]>([]);
  const [privacySelected, setPrivacySelected] = useState<IPrivacyPolicyData>();
  const [selectedPrivacyRules, setSelectedPrivacyRules] =
    useState<IPrivacyPolicyCategoryRule>();
  const [originPrivacyRules, setOriginPrivacyRules] =
    useState<IPrivacyPolicyCategoryRule>();
  const [privacyPolicyFull, setPrivacyPolicyFull] =
    useState<IPrivacyPolicyData>();
  const [privacyPolicyCollectibles, setPrivacyPolicyCollectibles] = useState();
  const [locationAccuracy, setLocationAccuracy] = useState<number | string>(0);
  const [originLocationAccuracy, setOriginLocationAccuracy] =
    useState<string>();
  const showEmmFields = emmScoped && mtdModule;

  const [hasChanges, setHasChanges] = useRecoilState(hasChangesAtom);
  const setActiveModal = useSetRecoilState(activeModalAtom);
  const setPreselectedPolicy = useSetRecoilState(preselectedPolicyAtom);
  const setHeaderActions = useSetRecoilState(headerActionsAtom);
  const setDeployDate = useSetRecoilState(deployDateAtom);

  const navigationPrompt = useMemo(
    () =>
      ({
        message = (
          <div className={classes.navigationWarning}>
            {t('MTD.POLICIES.APP_POLICY.POLICY_LEAVE_PAGE_CONFIRMATION', {
              policyName: TpolicyMap['Privacy'],
            })}
            <AlertIcon />
          </div>
        ),
        onCancelCaption = t('MTD.POLICIES.APP_POLICY.LOSE_CHANGES'),
        onConfirmCaption = t('MTD.POLICIES.APP_POLICY.STAY_HERE'),
      }: Partial<IGenericPromptModalData> = {}): Partial<
        Omit<IGenericPromptModalData, 'onCancel' | 'onConfirm'>
      > => ({
        title: t('MTD.POLICIES.APP_POLICY.PENDING_POLICY_UPDATE'),
        message: <span>{message}</span>,
        onCancelCaption,
        onConfirmCaption,
      }),
    [classes]
  );

  const onDeploySuccess = useCallback(() => {
    setHasChanges(false);
    setPreselectedPolicy(privacySelected);
    setOriginPrivacyRules(selectedPrivacyRules);
    setOriginLocationAccuracy(`${locationAccuracy}`);
  }, [
    locationAccuracy,
    privacySelected,
    selectedPrivacyRules,
    setHasChanges,
    setPreselectedPolicy,
  ]);

  const onDeployFailure = useCallback(() => {
    setActiveModal(undefined);
  }, [setActiveModal]);

  const deploySavedChanges = useCallback(() => {
    const data = {
      selectedPrivacyRules,
      name: privacySelected?.label ?? '',
      locationAccuracy,
    };
    updatePrivacyPolicyData(
      t,
      privacySelected as IPrivacyPolicyData,
      data,
      onDeploySuccess,
      onDeployFailure
    );
  }, [
    locationAccuracy,
    onDeploySuccess,
    onDeployFailure,
    privacySelected,
    selectedPrivacyRules,
  ]);

  const resetForms = useCallback(() => {
    setActiveModal(undefined);
    setHasChanges(false);
  }, [setActiveModal, setHasChanges]);

  useEffect(() => {
    if (hasChanges) {
      setHeaderActions({
        clearNavigationPreventingChanges: () => {
          setHasChanges(false);
        },
        preventNavigation: true,
        navigationPrompt: navigationPrompt({
          title: t('MTD.POLICIES.APP_POLICY.PENDING_POLICY_UPDATE'),
        }),
        buttons: [
          {
            className: classes.green,
            color: 'inherit',
            disabled: false,
            onClick: () => deploySavedChanges(),
            text: t('MTD.POLICIES.APP_POLICY.DEPLOY_POLICY_CHANGES'),
          },
        ],
      });
    }

    return () => setHeaderActions(undefined);
  }, [
    classes.green,
    deploySavedChanges,
    hasChanges,
    navigationPrompt,
    setHasChanges,
    setHeaderActions,
  ]);

  useEffect(() => {
    fetchSetPrivacyandList();
  }, [lastAction, location.query.privacy, location.query.teamId]);

  useEffect(() => {
    if (location.query.privacy) {
      fetchPrivacyTable();
    }
  }, [location.query.privacy]);

  useEffect(() => {
    if (!!privacyPolicyFull?.groups?.length) {
      fetchGroupsAndSetApps();
    }
  }, [privacyPolicyFull]);

  const fetchGroupsAndSetApps = () => {
    const privacyGroups = privacyPolicyFull?.groups;

    const groupIdList = privacyGroups?.map((group) => {
      return group.id;
    });
    if (!_.isEmpty(groupIdList)) {
      getAllAppsByGroupIds(groupIdList)
        .then((resp) => {
          setApps(resp.data);
        })
        .catch((error) => {
          console.log('error fetching app by groups', error);
        });
    } else {
      setApps([]);
    }
  };

  const fetchPrivacyTable = () => {
    if (location.query.privacy) {
      let distribution = EDistribution.CONSUMER;

      if (module === 'mtd') {
        distribution = EDistribution.ENTERPRISE;
      } else if (module === 'zdefend') {
        distribution = EDistribution.SDK_ONLY;
      }

      const fetchCollectibles = fetchAllCollectibles(distribution);
      const fetchPrivacyPolicy = fetchPrivacyPolicyById({
        id: location.query.privacy,
      });

      return Promise.all([fetchCollectibles, fetchPrivacyPolicy]).then(
        ([collectibles, rawPrivacyPolicy]) => {
          const privacyPolicyCollectibles = cleanPrivacyCollectibles(
            collectibles.data,
            showEmmFields,
            mtdModule
          );
          const selectedPrivacyRules = cleanPrivacyPolicyForTable(
            rawPrivacyPolicy.data,
            privacyPolicyCollectibles
          );
          setDeployDate(rawPrivacyPolicy.data.modified);
          setPrivacyPolicyFull(rawPrivacyPolicy.data);
          setLocationAccuracy(rawPrivacyPolicy.data.locationAccuracy);
          setOriginLocationAccuracy(
            `${rawPrivacyPolicy.data.locationAccuracy}`
          );
          setSelectedPrivacyRules(selectedPrivacyRules);
          setOriginPrivacyRules(selectedPrivacyRules);
          setPrivacyPolicyCollectibles(privacyPolicyCollectibles);
        }
      );
    }
  };

  const fetchSetPrivacyandList = () => {
    fetchAllPrivacyPoliciesList({ teamId: location.query.teamId }).then(
      ({ data }: IPrivacyPoliciesResponse) => {
        const privacyList = reduceForMultiSelectWithGlobal(data);
        const privacyUrlValue = location.query?.privacy;
        const privacy = _.find(privacyList, { value: privacyUrlValue });
        // if there is no value or a bad value in the trm url go to first in the list
        if (!privacyUrlValue || !privacy) {
          updateUrl({
            privacy: privacyList[0].value,
          });
        }
        setPrivacyList(privacyList);
        setPrivacySelected(privacy);
      }
    );
  };

  const handleTableChange = (
    ruleToAdd: string,
    value: boolean,
    option: IPrivacyPolicyRuleData,
    category: number
  ) => {
    const trailMapped = selectedPrivacyRules?.[category].map(
      (rule: IPrivacyPolicyRuleData) => {
        if (rule.id === option.id) {
          return {
            ...rule,
            shouldCollect: value,
          };
        }
        return rule;
      }
    );

    const newSelectedPrivacyRules = {
      ...selectedPrivacyRules,
      [category]: trailMapped,
    };
    setSelectedPrivacyRules(newSelectedPrivacyRules);
    setHasChanges(!_.isEqual(originPrivacyRules, newSelectedPrivacyRules));
  };

  const handleLocationChange = (locationAccuracy: string) => {
    const newPrivacyPolicyFull = {
      ...privacyPolicyFull,
      locationAccuracy,
    } as IPrivacyPolicyData;
    setPrivacyPolicyFull(newPrivacyPolicyFull);
    setLocationAccuracy(locationAccuracy);
    setHasChanges(originLocationAccuracy != locationAccuracy);
  };

  const handleViewSelectorChange = useCallback(
    (name: string, value: IPrivacyPolicyData | undefined) => {
      const changeSelectedPolicy = () => {
        setPrivacySelected(value);
        updateUrl({ privacy: value?.id });
        resetForms();
      };

      if (hasChanges) {
        setActiveModal({
          active: GenericPromptModal_TITLE,
          payload: {
            ...navigationPrompt(),
            onCancel: () => {
              changeSelectedPolicy();
            },
            onConfirm: () => {
              setActiveModal(undefined);
            },
          },
        });
      } else {
        changeSelectedPolicy();
      }
    },
    [hasChanges, navigationPrompt, resetForms, setActiveModal, updateUrl]
  );

  const onEditNameSuccess = useCallback(
    (policy: IPrivacyPolicyData) => {
      setPreselectedPolicy({
        value: policy.id,
        label: policy.name,
      });

      setTimeout(() => window.location?.reload(), 500);
    },
    [setPreselectedPolicy]
  );

  const onEditNameFailure = useCallback(() => {
    setTimeout(() => window.location?.reload(), 500);
  }, []);

  const handleEditNameClick = useCallback(() => {
    const editPolicyName = () => {
      resetForms();

      setActiveModal({
        active: GenericPromptModal_TITLE,
        payload: {
          disableOnSubmit: true,
          title: t('MTD.POLICIES.APP_POLICY.EDIT_POLICY_NAME'),
          question: {
            label: t('MTD.POLICIES.APP_POLICY.POLICY_NAME'),
            defaultAnswer: privacySelected?.label,
          },
          onCancel: () => {
            setActiveModal(undefined);
          },
          onConfirm: async (answer?: string) => {
            const data = {
              selectedPrivacyRules,
              name: answer,
              locationAccuracy,
            };
            updatePrivacyPolicyData(
              t,
              privacySelected as IPrivacyPolicyData,
              data,
              onEditNameSuccess,
              onEditNameFailure
            );
          },
        },
      });
    };

    if (hasChanges) {
      setActiveModal({
        active: GenericPromptModal_TITLE,
        payload: {
          ...navigationPrompt({
            onCancelCaption: t('GLOBAL.CONTINUE'),
            onConfirmCaption: t('GLOBAL.CANCEL'),
          }),
          onCancel: () => {
            editPolicyName();
          },
          onConfirm: () => {
            setActiveModal(undefined);
          },
        },
      });
    } else {
      editPolicyName();
    }
  }, [
    hasChanges,
    locationAccuracy,
    navigationPrompt,
    onEditNameSuccess,
    onEditNameFailure,
    privacySelected,
    resetForms,
    selectedPrivacyRules,
    setActiveModal,
  ]);

  const handleAddCloneClick = useCallback(
    (cloningPolicy?: string) => {
      const addClonePolicy = () => {
        resetForms();
        setActiveModal({
          active: AddClonePolicy_TITLE,
          payload: {
            policyType: 'Privacy',
            cloningPolicy,
            defaultName: cloningPolicy
              ? privacySelected?.label + ' (cloned)'
              : '',
            onCreateSuccess: () => {
              setLastAction(PrivacyPolicyAction.ADDCLONE);
              setPrivacySelected(undefined);
            },
          },
        });
      };

      if (hasChanges) {
        setActiveModal({
          active: GenericPromptModal_TITLE,
          payload: {
            ...navigationPrompt(),
            onCancel: () => {
              addClonePolicy();
            },
            onConfirm: () => {
              setActiveModal(undefined);
            },
          },
        });
      } else {
        addClonePolicy();
      }
    },
    [hasChanges, privacySelected, navigationPrompt, resetForms, setActiveModal]
  );

  const handleDeleteClick = useCallback(
    (policyId: string) => {
      const deletePolicy = () => {
        resetForms();

        setActiveModal({
          active: GenericPromptModal_TITLE,
          payload: {
            title: t('MTD.POLICIES.APP_POLICY.DELETE_POLICY'),
            message: (
              <ConfirmDeleteMessage
                groups={privacyPolicyFull?.groups}
                policyType="Privacy"
              />
            ),
            onConfirmDisabled:
              privacyPolicyFull?.groups && privacyPolicyFull?.groups.length > 0,
            onCancel: () => {
              setActiveModal(undefined);
            },
            onConfirm: async () => {
              await deletePrivacyPolicy({ id: policyId });
              await asyncSleep(800);
              openSnackBar(t('MTD.POLICIES.APP_POLICY.DELETED_POLICY'));
              setLastAction(PrivacyPolicyAction.DELETE);
              setPrivacySelected(undefined);
              setActiveModal(undefined);
            },
          },
        });
      };

      if (hasChanges) {
        setActiveModal({
          active: GenericPromptModal_TITLE,
          payload: {
            ...navigationPrompt(),
            onCancel: () => {
              deletePolicy();
            },
            onConfirm: () => {
              setActiveModal(undefined);
            },
          },
        });
      } else {
        deletePolicy();
      }
    },
    [
      hasChanges,
      privacyPolicyFull,
      navigationPrompt,
      resetForms,
      setActiveModal,
    ]
  );

  if (!ready) {
    return null;
  }

  if (!privacyList?.length || (privacyList.length > 0 && !privacySelected)) {
    return <PrivacyPolicySkeleton />;
  }

  return (
    <div>
      <GlobalIndicatorWrapper isGlobal inline />

      <ViewSelector
        interactable
        value={privacySelected}
        label={t('MTD.POLICIES.APP_POLICY.SELECT_POLICY')}
        options={privacyList}
        placeholder={t('MTD.POLICIES.APP_POLICY.NO_POLICY_SELECTED')}
        setFieldValue={handleViewSelectorChange}
        customOption={CustomPolicyOption as React.FC}
        deployDateComp={LastDeployedBox as React.FC}
        actions={[
          {
            disabled: !privacySelected?.value,
            icon: MUIEditIcon,
            onClick: () => handleEditNameClick(),
          },
          {
            disabled: !privacySelected?.value,
            icon: CopyIcon,
            onClick: () => handleAddCloneClick(privacySelected?.value),
          },
          {
            disabled: !privacySelected?.value,
            icon: MUIDeleteIcon,
            onClick: () => handleDeleteClick(privacySelected?.value as string),
          },
          {
            icon: MUIAddIcon,
            onClick: () => handleAddCloneClick(),
          },
        ]}
      />
      <PrivacyPolicyTable
        selectedPrivacyPolicyRulesByCategory={selectedPrivacyRules}
        privacyPolicyCollectibles={privacyPolicyCollectibles}
        selectedPrivacyPolicy={privacyPolicyFull}
        editMode={true}
        handleTableChange={handleTableChange}
        handleLocationChange={handleLocationChange}
        module={module}
        showEmmFields={showEmmFields}
      />
      <AddClonePolicy />
    </div>
  );
};

const mapStateToProps = (state: unknown) => {
  return {
    emmScoped: _.get(state, 'auth.user.scopes.emm'),
  };
};

export default withRouter(connect(mapStateToProps)(PrivacyPolicy));
