// TODO use this component in policy/threat page
import Switch from '@material-ui/core/Switch';
import React from 'react';
import useStyles from './useStyles';

interface IEnabledProps {
  data: boolean;
  onChange: () => void;
}

const Enabled: React.FC<IEnabledProps> = (props) => {
  const { data, onChange } = props;

  const classes = useStyles();

  return (
    <div className={classes.tableColumn}>
      <Switch checked={data} onChange={onChange} />
    </div>
  );
};

export default React.memo(Enabled);
