import MUIDialog from '@material-ui/core/Dialog';
import MUIDialogActions from '@material-ui/core/DialogActions';
import MUIDialogContent from '@material-ui/core/DialogContent';
import MUIDialogTitle from '@material-ui/core/DialogTitle';
import MUIFormControl from '@material-ui/core/FormControl';
import MUIInputAdornment from '@material-ui/core/InputAdornment';
import MUIMenuItem from '@material-ui/core/MenuItem';
import MUISelect from '@material-ui/core/Select';
import { getActiveModalAtom } from 'atoms/modals';
import Button from 'components/UI/Button';
import GenericTable from 'components/UI/GenericTable';
import { GenericPromptModal_TITLE } from 'components/UI/Modals/GenericPrompt';
import { IGenericPromptModalData } from 'components/UI/Modals/GenericPrompt/models';
import DeleteIcon from 'components/UI/icons/DeleteIcon';
import SearchIcon from 'components/UI/icons/SearchIcon';
import Checkbox from 'components/UI/input/Checkbox';
import TextField from 'components/UI/input/TextField';
import JsFileInfoCard from 'components/fileuploader/FileInfoCard';
import JsUploader from 'components/fileuploader/Uploader';
import _ from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { openSnackBar } from 'utils/storeUtils';
import { searchTable } from 'utils/tableUtils';
import { checkIfDomainIsValid } from 'utils/urlUtils';
import { renderSelectGroup } from './SelectGroup';
import { ITableActionGroup, TableActions, tableActions } from './TableActions';
import { arrayToCSV } from './arrayToCSV';
import { csvToArray } from './csvToArray';
import { downloadFile } from './downloadFile';
import { IDomain } from './models';
import useStyles from './useStyles';

const Uploader: any = JsUploader;
const FileInfoCard: any = JsFileInfoCard;

const defaultDomain: IDomain = {
  id: '',
  domain: '',
  selected: false,
};

const fileHeaders = ['domain'];

const exampleData = [
  {
    domain: 'www.domain1.com',
  },
  {
    domain: 'www.domain2.com',
  },
  {
    domain: 'www.domain3.com',
  },
  {
    domain: 'www.domain4.com',
  },
  {
    domain: 'www.domain5.com',
  },
];

const activeModalAtom = getActiveModalAtom<IGenericPromptModalData>();

const searchFields: (keyof IDomain)[] = ['domain'];

interface ISinkholeDomainsPops {
  onDomainsChanged: (key: 'domains', value: IDomain[]) => void;
  domains: IDomain[];
}

const SinkholeDomains: React.FC<ISinkholeDomainsPops> = (props) => {
  const { onDomainsChanged, domains } = props;

  const classes = useStyles();

  const { t, ready } = useTranslation();

  const setActiveModal = useSetRecoilState(activeModalAtom);

  const [searchTerm, setSearchTerm] = useState<string>();
  const [data, setData] = useState(domains);
  const [newDomainAddress, setNewDomainAddress] = useState(defaultDomain);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTableAction, setSelectedTableAction] =
    useState<TableActions>();
  const [isValidDomain, setIsValidDomain] = useState(true);

  useEffect(() => {
    if (!_.isEqual(data, domains)) {
      onDomainsChanged('domains', data);
    }
  }, [data, domains, onDomainsChanged]);

  const exportToCSV = useCallback((): void => {
    const isExample = selectedTableAction === TableActions.DownloadExample;

    let values = [];
    let fileName = '';

    if (isExample) {
      values = exampleData;
      fileName = 'Example Domains';
    } else {
      fileName = `Domains-${new Date().toLocaleDateString()}.csv`;

      const newData =
        selectedTableAction === TableActions.ExportSelected
          ? [...data.filter((ssid) => ssid.selected)]
          : [...data];

      values = newData.map(({ domain }) => ({ domain }));
    }

    const csvData = arrayToCSV(fileHeaders, values);
    const blob = new Blob([csvData], { type: 'text/csv' });

    downloadFile(blob, fileName);

    setSelectedTableAction(undefined);
  }, [data, selectedTableAction, setSelectedTableAction]);

  const newDomainExist = useCallback(() => {
    const results = searchTable(newDomainAddress.domain, data, searchFields);
    return results.length > 0;
  }, [data, newDomainAddress]);

  const onDeleteClick = useCallback(
    (item: any): void => {
      setActiveModal({
        active: GenericPromptModal_TITLE,
        payload: {
          title: t('MTD.POLICIES.NETWORK_POLICY.DELETE_DOMAIN_ADDRESS'),
          message: t('MTD.POLICIES.NETWORK_POLICY.CONFIRM_DELETE'),
          onCancel: () => setActiveModal(undefined),
          onConfirm: () => {
            const newData = [...data].filter((el) => el.id !== item.id);
            setData(newData);
            setActiveModal(undefined);
          },
        },
      });
    },
    [data, setActiveModal, t]
  );

  const onSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setSearchTerm(value);
    },
    [setSearchTerm]
  );

  const onDomainAddressChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setNewDomainAddress({ ...newDomainAddress, domain: value });

      if (value) {
        setIsValidDomain(checkIfDomainIsValid(value));
      } else {
        setIsValidDomain(true);
      }
    },
    [newDomainAddress, setNewDomainAddress]
  );

  const onAddClick = useCallback((): void => {
    if (newDomainAddress && newDomainAddress.domain) {
      const newData = [
        ...data,
        {
          ...newDomainAddress,
          id: new Date().valueOf().toString(),
        },
      ];

      setData(newData);
      setNewDomainAddress(defaultDomain);
    }
  }, [data, newDomainAddress]);

  const onSelectCheckboxChange = useCallback(
    (domain: IDomain): void => {
      const selectedIndex = data.findIndex((el) => el.id === domain.id);

      const newData = [...data];
      newData[selectedIndex] = { ...domain, selected: !domain.selected };

      setData(newData);
    },
    [data]
  );

  const onTableActionChange = useCallback(
    (
      event: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ): void => {
      const { value } = event.target;
      setSelectedTableAction(value as TableActions);
    },
    [setSelectedTableAction]
  );

  const onGoClick = useCallback((): void => {
    switch (selectedTableAction) {
      case TableActions.DeleteSelected: {
        const newData = data.filter((el) => !el.selected);
        setData(newData);
        setSelectedTableAction(undefined);
        break;
      }
      case TableActions.ExportSelected:
      case TableActions.ExportTable:
      case TableActions.DownloadExample:
        exportToCSV();
        break;
      case TableActions.ImportTable:
      case TableActions.ImportTableAppend:
        setIsUploadOpen(true);
        break;
      default:
        break;
    }
  }, [data, selectedTableAction, exportToCSV]);

  const onUploadClose = useCallback((): void => {
    setIsUploadOpen(false);
  }, [setIsUploadOpen]);

  const setFileData = useCallback(
    (fileData: File): void => {
      setSelectedFile(fileData);
    },
    [setSelectedFile]
  );

  const removeFile = useCallback((): void => {
    setSelectedFile(undefined);
  }, [setSelectedFile]);

  const handleSubmitForm = useCallback((): void => {
    if (selectedFile) {
      try {
        setIsLoading(true);

        const fileReader = new FileReader();

        fileReader.onload = function (event) {
          if (event.target) {
            const text = event.target.result;

            if (text) {
              const headers: (keyof IDomain)[] = ['domain'];
              const importedDomains = csvToArray(text as string, headers, {
                enabled: true,
                selected: true,
              });

              if (importedDomains.length > 0) {
                const newData = importedDomains;

                if (
                  data.length > 0 &&
                  selectedTableAction === TableActions.ImportTableAppend
                ) {
                  newData.push(..._.cloneDeep(data));
                }

                setData(newData);
              }
            }
          }
        };

        fileReader.readAsText(selectedFile);

        setSelectedFile(undefined);
        setSelectedTableAction(undefined);
        openSnackBar(t('GLOBAL.SUCCESS_UPLOAD'));
        onUploadClose();
      } catch (error: any) {
        openSnackBar(
          error?.response?.data?.message || t('GLOBAL.SOMETHING_WRONG')
        );
      } finally {
        setIsLoading(false);
      }
    }
  }, [data, selectedTableAction, selectedFile, onUploadClose, t]);

  const selectGroup = useCallback(
    (group: ITableActionGroup) => renderSelectGroup(group),
    []
  );

  if (!ready) {
    return null;
  }

  return (
    <div className={classes.root} style={{ gap: '15px', width: '50%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          alignItems: 'end',
        }}
      >
        <TextField
          name="domain"
          label={t('MTD.POLICIES.NETWORK_POLICY.DOMAIN_ADDRESS')}
          placeholder="www.domain.com"
          width="100%"
          onChange={onDomainAddressChange}
          value={newDomainAddress.domain}
          helperText={
            !isValidDomain
              ? t('MTD.POLICIES.NETWORK_POLICY.INVALID_DOMAIN')
              : undefined
          }
          error={!isValidDomain}
        />
        <Button
          text={t('GLOBAL.ADD')}
          color="secondary"
          style={{ height: 'min-content' }}
          onClick={onAddClick}
          disabled={
            !isValidDomain || !newDomainAddress.domain || newDomainExist()
          }
        />

        <MUIFormControl className={classes.selectFormControl}>
          <MUISelect
            displayEmpty
            onChange={onTableActionChange}
            value={selectedTableAction || ''}
          >
            <MUIMenuItem value="" disabled>
              {t('GLOBAL.TABLE_ACTIONS')}
            </MUIMenuItem>
            {tableActions(t).map((group) => selectGroup(group))}
          </MUISelect>
        </MUIFormControl>

        <Button
          text={t('GLOBAL.GO')}
          color="secondary"
          style={{ height: 'min-content' }}
          onClick={onGoClick}
        />
      </div>

      <TextField
        name="search"
        placeholder={t('GLOBAL.SEARCH')}
        onChange={onSearchChange}
        InputProps={{
          endAdornment: (
            <MUIInputAdornment
              position="end"
              classes={{ root: classes.searchIcon }}
            >
              <SearchIcon />
            </MUIInputAdornment>
          ),
        }}
      />

      <GenericTable
        columnHeaders={[
          { id: 'select', label: '' },
          {
            id: 'domain',
            label: t('MTD.POLICIES.NETWORK_POLICY.DOMAIN_ADDRESS'),
          },
          { id: 'actions', label: t('GLOBAL.ACTIONS') },
        ]}
        rowMapping={[
          {
            path: 'select',
            columnContent: ({ rowData }) => (
              <Checkbox
                key={rowData.id}
                checked={rowData.selected as boolean}
                onChange={() =>
                  onSelectCheckboxChange(rowData as unknown as IDomain)
                }
                color="primary"
                className="table__checkbox"
              />
            ),
          },
          {
            path: 'actions',
            columnContent: ({ rowData }) => (
              <Button
                style={{ padding: 0, minWidth: 'auto' }}
                variant="text"
                icon={DeleteIcon}
                onClick={() => onDeleteClick(rowData)}
              />
            ),
          },
        ]}
        tableData={searchTable(searchTerm, data, searchFields) as any}
        tableId="Domains Rules"
      />

      <MUIDialog open={isUploadOpen} onClose={onUploadClose}>
        <MUIDialogTitle style={{ display: 'flex', justifyContent: 'center' }}>
          {t('GLOBAL.UPLOAD_CSV')}
        </MUIDialogTitle>
        <MUIDialogContent>
          {!selectedFile && (
            <div className={classes.formGroup}>
              <Uploader
                accept=".csv"
                isShowing={true}
                getFileData={setFileData}
              />
            </div>
          )}

          {selectedFile && (
            <FileInfoCard
              fileData={selectedFile}
              isShowing={!!selectedFile}
              removeFile={removeFile}
              noActionButtons
            />
          )}
        </MUIDialogContent>

        <MUIDialogActions>
          <Button
            text={t('GLOBAL.UPLOAD')}
            disabled={!selectedFile || isLoading}
            onClick={handleSubmitForm}
          />
        </MUIDialogActions>
      </MUIDialog>
    </div>
  );
};

export default SinkholeDomains;
