import * as api from 'api/apis';
import AT from './ActionTypes';

export const setSelectedApp = selectedApp => {
  return {
    type: AT.SET_SELECTED_APP,
    payload: {
      selectedApp,
    },
  };
};

export const clearSelectedApp = () => {
  return {
    type: AT.CLEAR_SELECTED_APP,
  };
};

export const closeAppSnackbar = () => {
  return {
    type: AT.CLOSE_APP_SNACKBAR,
  };
};

/*
 * Fetching all zapps.
 */
const fetchAllAppsSucceeded = resp => {
  const payload = { apps: [], appsMetadata: { count: 0 } };
  payload.apps = resp.data;
  const all = { name: 'All', id: 'All' };
  payload.selectedApp = all;

  payload.appsMetadata.count = payload.apps.length;
  return {
    type: AT.FETCH_ALL_APPS_SUCCEEDED,
    payload,
  };
};

export const fetchAllAppsByTeamId = teamId => {
  return dispatch => {
    return api.fetchAllAppsByTeamId(teamId).then(resp => {
      dispatch(fetchAllAppsSucceeded(resp));
    });
  };
};

/*
 * Login / Signup related action creators
 */

/* Fetching a page of zapps.
 */

export const setUiDashboardRange = newRange => {
  return {
    type: AT.SET_UI_DASHBOARD_RANGE,
    payload: {
      range: newRange,
    },
  };
};

export const setUiDashboardSelectedApp = zapp => {
  return {
    type: AT.SET_UI_DASHBOARD_SELECTED_APP,
    payload: {
      zappId: zapp.id,
    },
  };
};

const saveZiapConsoleVersion = body => {
  return {
    type: AT.FETCH_ZIAP_CONSOLE_VERSION_SUCCEEDED,
    payload: body,
  };
};

export const fetchZiapConsoleVersion = () => {
  return dispatch => {
    return api.fetchZiapConsoleVersion().then(resp => {
      dispatch(saveZiapConsoleVersion(resp.data));
    });
  };
};
