import Button from 'components/UI/Button';
import InputSkeleton from 'components/UI/InputSkeleton';
import useStyles from './useStyles';
import Divider from '@material-ui/core/Divider';
import { IConnector } from 'components/main/integrations/emm/models';
import { TFunction } from 'react-i18next';

interface IGroupsSkeletonProps {
  t: TFunction<'translation', undefined>;
  connector?: IConnector;
  showPolicy?: boolean;
  isIntune: boolean;
  thirdPartyMDMSelected: boolean;
}

const SelectGroupsSkeleton: React.FC<IGroupsSkeletonProps> = ({
  t,
  connector,
  showPolicy,
  isIntune,
  thirdPartyMDMSelected,
}) => {
  const classes: any = useStyles();

  return (
    <>
      {(!isIntune || !thirdPartyMDMSelected) && (
        <div className={classes.selectGroupContainer}>
          <div className={classes.mainSkeletonGroups}>
            <div className={classes.skeletonGroups}>
              {connector && (
                <div className={classes.titleStyles}>
                  {connector?.id === 'GoogleAdminConnector'
                    ? t('MTD.INTEGRATIONS.SELECT_ORGANIZATIONAL_UNITS')
                    : t('MTD.INTEGRATIONS.SELECT_GROUPS')}
                </div>
              )}
              <InputSkeleton label={t('GLOBAL.SEARCH')} />
              {!showPolicy && (
                <div className={classes.buttons}>
                  <Button
                    className={classes.buttonBackgroundColor}
                    text={t('GLOBAL.BACK')}
                    type="submit"
                  />
                  <Button
                    color="secondary"
                    disabled={true}
                    text={t('GLOBAL.NEXT')}
                  />
                </div>
              )}
            </div>
            <div>
              {showPolicy && (
                <Divider
                  className={classes.dividerStyle}
                  orientation="vertical"
                  variant="middle"
                  flexItem
                />
              )}
            </div>
            <div>
              {showPolicy && (
                <div>
                  <div className={classes.titleStyles}>
                    {t('MTD.INTEGRATIONS.SELECT_POLICIES_APPLIED')}
                  </div>
                  <div className={classes.policySkeleton}>
                    {' '}
                    <InputSkeleton label={t('GLOBAL.THREAT_POLICY')} />
                    <InputSkeleton label={t('GLOBAL.PRIVACY_POLICY')} />
                    <InputSkeleton label={t('GLOBAL.PHISHING_POLICY')} />
                    <InputSkeleton label={t('GLOBAL.APP_SETTINGS')} />
                    <InputSkeleton label={t('GLOBAL.DEVICE_INACTIVITY')} />
                  </div>
                  <div className={classes.buttons}>
                    <Button
                      className={classes.buttonBackgroundColor}
                      text={t('GLOBAL.BACK')}
                      type="submit"
                    />
                    <Button
                      color="secondary"
                      disabled={true}
                      text={t('GLOBAL.NEXT')}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div></div>
        </div>
      )}
    </>
  );
};

export default SelectGroupsSkeleton;
