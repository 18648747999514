import { useCallback, useEffect, useState } from 'react';
import { getAppById } from 'api/zDevService';
import { IZScanApp } from '../models';

export default (appId: string | unknown) => {
  const [appDetails, setAppDetails] = useState<IZScanApp>();

  const fetchAppDetails = useCallback(async () => {
    const { data } = await getAppById({ appId });

    setAppDetails(data);
  }, [appId]);

  useEffect(() => {
    fetchAppDetails();
  }, [fetchAppDetails]);

  return { appDetails };
};
