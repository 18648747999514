import MUIListSubheader from '@material-ui/core/ListSubheader';
import MUIMenuItem from '@material-ui/core/MenuItem';
import { ITableActionGroup } from './MenuItemMapping';
import SeverityCircle from 'components/main/ZScanApps/SeverityCircle';
import { ESeverity } from 'components/main/ZScanApps/SeverityCircle/models';

export const renderSelectGroup = (group: ITableActionGroup) => {
  const items = group.actions.map((action) => (
    <MUIMenuItem
      value={action.value}
      style={{ marginLeft: '15px' }}
      key={action.value}
    >
      {action.label === 'INFORMATIONAL' ||
      action.label === 'LOW' ||
      action.label === 'HIGH' ||
      action.label === 'MEDIUM' ||
      action.label === 'CRITICAL' ? (
        <span style={{ display: 'flex', gap: '6px' }}>
          Change all to{' '}
          <SeverityCircle
            severity={action.label as ESeverity}
            showLabel={true}
          />
        </span>
      ) : (
        action?.label
      )}
    </MUIMenuItem>
  ));

  return [<MUIListSubheader>{group.group}</MUIListSubheader>, items];
};
