import { ServiceHandlerFactory } from 'utils/serviceUtils';
import { Api } from 'config/axiosConfig';

const OSRiskBase = 'api/devices/v1';
const OsRiskPolicyBase = 'api/mtd-policy/v1/os-risk/policies';

export const fetchOSRiskDeviceStats = ServiceHandlerFactory({
  axios: {
    url: `${OSRiskBase}/devices/stats`,
    method: 'get',
  },
  paramSchema: [
    { path: 'teamId', paramKey: 'teamId.keyword' },
    { path: 'accountId', paramKey: 'accountId' },
  ],
});

export const fetchOSDevicesStatus = ServiceHandlerFactory({
  axios: {
    url: `${OSRiskBase}/osrisk-insights/compliant`,
    method: 'get',
  },
  paramSchema: [
    { path: 'teamId', paramKey: 'teamId.keyword' },
    { path: 'accountId', paramKey: 'accountId' },
  ],
});

export const fetchOSRiskActiveExploits = ServiceHandlerFactory({
  axios: {
    url: `${OSRiskBase}/osrisk-insights/active-exploits`,
    method: 'get',
  },
  paramSchema: [
    { path: 'teamId', paramKey: 'teamId.keyword' },
    { path: 'accountId', paramKey: 'accountId' },
  ],
});

export const fetchOSRiskRiskiestOSVersions = ServiceHandlerFactory({
  axios: {
    url: `${OSRiskBase}/osrisk-insights/riskiest-os-versions`,
    method: 'get',
  },
  paramSchema: [
    { path: 'teamId', paramKey: 'teamId.keyword' },
    { path: 'accountId', paramKey: 'accountId' },
  ],
});

export const fetchOSRiskTopTenImpactful = ServiceHandlerFactory({
  axios: {
    url: `${OSRiskBase}/osrisk-insights/top-impactful-cves`,
    method: 'get',
  },
  paramSchema: [
    { path: 'teamId', paramKey: 'teamId.keyword' },
    { path: 'accountId', paramKey: 'accountId' },
  ],
});

export const fetchAllPolicies = async (teamId) => {
  let url = OsRiskPolicyBase;

  if (teamId?.trim().length) {
    url = `${OsRiskPolicyBase}?teamId=${teamId}`;
  }

  return Api.get(url);
};

export const fetchPolicyById = async (policyId) => {
  return Api.get(`${OsRiskPolicyBase}/${policyId}`);
};

export const createPolicy = async (policy) => {
  return Api.post(`${OsRiskPolicyBase}`, policy);
};

export const clonePolicy = async (policy) => {
  return Api.post(`${OsRiskPolicyBase}/clone`, policy);
};

export const updatePolicy = async (policyId, policy) => {
  return Api.put(`${OsRiskPolicyBase}/${policyId}`, policy);
};

export const deletePolicy = async (policyId) => {
  return Api.delete(`${OsRiskPolicyBase}/${policyId}`);
};
