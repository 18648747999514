import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    literalProportion: {
      color: palette.text.primary,
      flex: 0,
      flexDirection: 'row',
    },
    row: {
      display: 'flex',
      fontSize: 14,
      marginBottom: 15,

      '&:last-child': {
        marginBottom: 0,
      },

      '& > div': {
        alignSelf: 'center',
        display: 'flex',
        position: 'relative',

        '&:nth-child(even)': {
          width: '55%',
        },
        '&:nth-child(odd)': {
          width: '45%',
        },

        '& > span': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          userSelect: 'none',
          transition: 'padding 100ms ease, transform 100ms ease',

          '&:hover': {
            backgroundColor: palette.common.black,
            color: palette.common.white,
            borderRadius: '6px',
            overflow: 'visible',
            padding: '5px',
            transform: 'scale(1.125)',
            zIndex: 1,
          },

          '& > a:link, & > a:active, > a:visited': {
            color: 'inherit',
          },

          '& > a:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
  })
);

export default useStyles;
