import { IQueryParams } from 'components/main/devices/common/models';
import Select, { ISelectItem } from 'components/UI/input/Select';
import { defaultApp } from 'mappings/appMappings';
import { useCallback, useMemo } from 'react';
import useAsyncResult from 'utils/useAsyncResult';
import useStyles from './useStyles';
import { DEFAULT_APP_OPTION, fetchAppsByTeamId } from './utils';

interface IAppDropdownProps {
  query: IQueryParams;
  storeKey: string;
  updateUISettings: (...args: unknown[]) => void;
  updateUrl: (params: IQueryParams) => void;
}

const AppDropdown: React.FC<IAppDropdownProps> = ({
  query,
  storeKey,
  updateUISettings,
  updateUrl,
}) => {
  const apps: ISelectItem[] | undefined = useAsyncResult(
    fetchAppsByTeamId,
    query?.teamId ?? ''
  );

  const classes = useStyles();

  const zappId = useMemo(() => query?.zappId || defaultApp.id, [query]);

  const value = useMemo(
    () =>
      apps?.[0]
        ? apps.find(({ value }) => value === zappId)
        : DEFAULT_APP_OPTION,
    [apps, zappId]
  );

  const handleChange = useCallback(
    (field: string, value: ISelectItem) => {
      updateUrl({ zappId: String(value.value) });
      updateUISettings({
        domain: storeKey,
        update: { zappId },
      });
    },
    [updateUrl, updateUISettings, storeKey, zappId]
  );

  if (!apps?.[0]) {
    return null;
  }

  return (
    <div className={classes.dropDownWrapper}>
      <Select
        disableClearable
        interactable
        setFieldValue={handleChange}
        options={apps}
        value={value}
      />
    </div>
  );
};

export default AppDropdown;
