import { IConnector } from 'components/main/integrations/emm/models';
import { ISelectItem } from 'components/UI/input/Select';
import { IMDMRowData } from '../../emmTable/MDM/models';
import { IMaasFormData } from './models';
import { TModalMode } from '../../models';

export const initialValues = (
  modalMode: TModalMode,
  rowData?: IMDMRowData,
  connector?: IConnector,
  availableTeams?: ISelectItem[],
  selectedTeamId?: string
): IMaasFormData => {
  console.log('rowData', rowData);
  return {
    baseUrl: rowData?.baseUrl ?? '',
    connectionId: rowData?.id ?? '',
    connectorTypeId: connector?.id ?? '',
    modalMode,
    mode: connector?.supportedModes?.[0] ?? '',
    name:
      modalMode === 'CLONE' ? `${rowData?.name} (Cloned)` : rowData?.name ?? '',
    password: '',
    team: !!availableTeams?.[0]
      ? availableTeams.find(
        ({ value }) => value === (rowData?.teamId ?? selectedTeamId)
      )
      : undefined,
    user_id: rowData?.auth?.user_id ?? '',
    access_key: rowData?.auth?.access_key ?? '',
    app_id: rowData?.auth?.app_id ?? '',
    app_version: rowData?.auth?.app_version ?? '',
    billing_id: rowData?.auth?.billing_id ?? '',
    platform_id: rowData?.auth?.platform_id ?? ''
  };
};
