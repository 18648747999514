import { default as MUIFormControlLabel } from '@material-ui/core/FormControlLabel';
import {
  default as MUIRadioGroup,
  RadioGroupProps as IMUIRadioGroupProps,
} from '@material-ui/core/RadioGroup';
import InputError from 'components/UI/input/InputError';
import { FieldProps as IFormikFieldProps } from 'formik';
import React from 'react';
import { IRadioProps } from './Radio';
import useStyles from './useStyles';

interface IRadioGroup extends IMUIRadioGroupProps, Partial<IFormikFieldProps> {
  children: React.ReactElement<IRadioProps> | React.ReactElement<IRadioProps>[];
  label?: string;
  vertical?: boolean;
}

const RadioGroup: React.FC<IRadioGroup> = ({
  children,
  label,
  field,
  form,
  vertical,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <>
      <MUIFormControlLabel
        label={label}
        classes={{
          root: `${classes.root} ${vertical ? classes.rootVertical : ''}`,
          label: classes.label,
        }}
        control={
          <MUIRadioGroup
            classes={{
              root: vertical ? classes.verticalRadios : classes.radios,
            }}
            {...field}
            {...rest}
          >
            {children}
          </MUIRadioGroup>
        }
      />
      <InputError form={form} field={field} />
    </>
  );
};

export { default as Radio } from './Radio';
export default RadioGroup;
