import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    card: {
      '& > div': {
        paddingTop: 5,
        color: palette.common.white,
        backgroundColor: palette.common.marineBlue,
        border: `1px solid ${palette.primary.border}`,
      },
      '& h2': {
        color: palette.common.white,
      },
      '& label': {
        '& span': {
          color: `${palette.common.white} !important`,
          fontSize: 14,
        },
        flex: '1 0',
      },
      '& > p': {
        color: palette.common.almostWhite,
      },
    },
    horizontal: {
      display: 'flex',
      flexDirection: 'row',
      gap: 20,
      justifyContent: 'space-between',

      '& > div': {
        flex: 1,
      },
    },
    radios: {
      '& > label > div': {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 0 10px',
      },
    },
    appLinkIcon: {
      backgroundColor: palette.charts.icon,
      color: palette.common.white,
      borderRadius: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 48,
      padding: 8,
      width: 48,
      '& > svg': { height: '100%', width: '100%' },
    },
    appLinkIconAndroid: { border: `1px solid ${palette.charts.brightGreen}` },
    appLinkIconIos: { border: `1px solid ${palette.charts.brightBlue}` },
  })
);

export default useStyles;
