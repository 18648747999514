import * as Yup from 'yup';
import { TModalMode } from '../../../models';

const Schema = (mode: TModalMode) => {
  return Yup.object().shape({
    // appSettings: Yup.mixed().required('App settings required.'),
    groups: Yup.mixed().required('Groups required.'),
    // phishingPolicy: Yup.mixed().required('Phishing policy required.'),
    // privacyPolicy: Yup.mixed().required('Privacy policy required.'),
    trmPolicy:
      mode !== 'EDIT'
        ? Yup.mixed()
        : Yup.mixed().required('Threat policy required.'),
  });
};

export default Schema;
