import { TFunction } from 'react-i18next';
// import { csvInjectionRegex, looksGenerallyLikeAnEmailRegex } from 'utils/componentUtils';
import * as Yup from 'yup';

const Schema = (t: TFunction<'translation', undefined>) => {
  return Yup.object().shape({
    // notificationEmails: Yup.object().shape({
    //   email: Yup.string()
    //      .matches(
    //        looksGenerallyLikeAnEmailRegex,
    //        t('MTD.VALIDATIONS.INVALID_EMAIL')
    //      )
    //      .matches(csvInjectionRegex, t(csvInjectionErrorMessageLocalized))
    //      // .required(t('MTD.VALIDATIONS.EMAIL_IS_REQUIRED'))
    // }),

    team: Yup.mixed().test({
      name: 'team-required-if-all-teams',
      // function keyword used below because we need access to "this"
      test: function (value, ctx) {
        if (!!ctx?.parent?.selectedTeamId) {
          return true;
        }

        return !value
          ? this?.createError({
              message: t('GLOBAL.REQUIRED_TEAM'),
              path: 'team',
            })
          : true;
      },
    }),
  });
};
export default Schema;
