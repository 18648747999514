import { ICompliance, IOSNames, IZScanFindings } from './models';
import { getZScanFindings } from 'api/zDevService';
import { IQueryParams } from './models';
import { trimAndAddAsterisk } from 'utility/QueryUtility';

export const OS_NAMES: IOSNames = {
  ios: 'iOS',
  android: 'Android',
  'chrome os': 'Chrome OS',
  chrome_os: 'Chrome OS', // having to defend against mislabeled backend OS names
};

export const fetchTableData = async (
  activeIndex: number,
  preSelectFirstRowData: boolean,
  setPreSelectFirstRowData: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectFinding: React.Dispatch<
    React.SetStateAction<IZScanFindings | undefined>
  >,
  setTotalDataCount: React.Dispatch<React.SetStateAction<number>>,
  query: IQueryParams,
  assessmentId: string
) => {
  let result: any; // IZScanAppsResponse | undefined = undefined;

  try {
    result = await getZScanFindings({
      assessmentId,
      ...query,
    });
    setTotalDataCount(result.data.length);
    if (activeIndex === 0 && preSelectFirstRowData && result?.data?.length) {
      setSelectFinding(result?.data[0]);
      setPreSelectFirstRowData(false);
    }
  } catch (e) {
    console.error(e);
  }
  return result;
};

export const zScanFindingsDataFilterMappings = [
  {
    path: 'name',
    transform: ({ name }: any) => ({ label: 'Finding Name', value: name }),
  },
  {
    path: 'severity',
    transform: ({ severity }: any) => ({ label: 'Severity', value: severity }),
  },
  {
    path: 'appName',
    transform: ({ appName }: any) => ({ label: 'App Name', value: appName }),
  },
  {
    path: 'platform',
    transform: ({ platform }: any) => ({ label: 'OS System', value: platform }),
  },
  {
    path: 'security',
    transform: ({ security }: any) => ({
      label: 'Security',
      value: security.toUpperCase(),
    }),
  },
  {
    path: 'privacy',
    transform: ({ privacy }: any) => ({
      label: 'Privacy',
      value: privacy.toUpperCase(),
    }),
  },

  {
    path: 'categoryName',
    transform: ({ categoryName }: any) => ({
      label: 'Category Name',
      value: categoryName,
    }),
  },
  {
    path: 'accepted',
    transform: ({ accepted }: any) => {
      return {
        label: 'Show Accepted',
        value: accepted,
      };
    },
  },
  {
    path: 'subcategoryName',
    transform: ({ subcategoryName }: any) => ({
      label: 'Subcategory Name',
      value: subcategoryName,
    }),
  },
  {
    path: 'compliance.id.keyword',
    transform: (locationProps: any) => ({
      label: 'Compliance',
      value: locationProps['compliance.id.keyword'],
    }),
  },
  {
    path: 'issue.issueStatus',
    transform: (locationProps: any) => ({
      label: 'Issue Status',
      value: locationProps['issue.issueStatus'],
    }),
  },
  {
    path: 'issue.externalIssueId',
    transform: (locationProps: any) => ({
      label: 'Ticket Id',
      value: locationProps['issue.externalIssueId'],
    }),
  },
];

export const zScanFindingsParamsMapping = [
  {
    path: 'page',
  },
  {
    path: 'teamId',
  },
  {
    path: 'size',
  },
  {
    path: 'policyId',
  },
  { path: 'compliance.id.keyword' },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }: { order: string; orderBy: string }) => {
      switch (orderBy) {
        case 'appName':
        case 'name':
          return `${orderBy}.sort,${order}`;
        case 'ticketed':
        case 'lastSightedBuild':
        case 'lastSightedVersion':
        case 'accepted':
        case 'description':
        case 'issue.issueStatus':
        case 'severity':
        case 'created':
        case 'issue.issueId':
          return `created,${order}`;
        case 'modified':
          return `${orderBy},${order}`;
        default:
          if (orderBy && order) {
            return `${orderBy},${order}`;
          }
          return null;
      }
    },
  },
  {
    path: 'search',
    transform: ({ search = '' }) => {
      if (!search) {
        return null;
      }

      return trimAndAddAsterisk(search);
    },
  },
  {
    path: 'id',
  },
  {
    path: 'accountId',
  },
  {
    path: 'appId',
    paramKey: 'appId.keyword',
    transform: ({ appId }: any) => {
      if (appId === 'All') {
        return null;
      }
      return appId;
    },
  },
  {
    path: 'appName',
  },
  {
    path: 'findingId',
  },
  {
    path: 'subcategoryId',
  },
  {
    path: 'categoryId',
  },
  {
    path: 'name',
  },
  {
    path: 'subcategoryName',
  },
  {
    path: 'categoryName',
  },
  {
    path: 'lastSightedVersion',
  },
  {
    path: 'lastSightedBuild',
  },
  {
    path: 'description',
  },
  {
    path: 'label',
  },
  {
    path: 'security',
  },
  {
    path: 'privacy',
  },
  {
    path: 'platform',
  },
  {
    path: 'locationCount',
  },
  {
    path: 'accepted',
  },
  {
    path: 'severity',
  },
  {
    path: 'created',
  },
  {
    path: 'modified',
  },
  {
    path: 'issue.issueStatus',
    paramKey: 'issue.issueStatus.keyword',
  },
  {
    path: 'issue.externalIssueId',
    paramKey: 'issue.externalIssueId.keyword',
  },
  {
    path: 'accepted',
  },
];

export const countFrequency = (arr: ICompliance[]) => {
  const frequencyMap: any = {
    CWE: 0,
    CVE: 0,
    OWASP: 0,
    MASVS: 0,
    GDPR: 0,
    HIPAA: 0,
    PCI: 0,
    NIAP: 0,
  };

  arr?.forEach((item: any) => {
    const id = item.id;
    frequencyMap[id] = (frequencyMap[id] || 0) + 1;
  });

  return frequencyMap;
};
