import useStyles from './useStyles';
import MUIPhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';

export interface IPhoneAndroidIcon {}

const PhoneAndroidIcon: React.FC<IPhoneAndroidIcon> = () => {
  const classes = useStyles();

  return <MUIPhoneAndroidIcon className={classes.icon} />;
};

export default PhoneAndroidIcon;
