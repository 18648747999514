import React, { useState } from 'react';
import { hoistStatics } from 'recompose';

/**
 * This is a TEMPORARY fix for pass-by-reference issues with Redux.
 * Instead of addressing the giant redux reducers, we will be
 * replacing them entirely. In the short term we will use this provider
 * to force component updates on components that are memoized and have
 * issues with pass by reference state like the table column headers.
 * - Randy
 */

export function withDirtyState() {
  return WrappedComponent => {
    const C = props => {
      const [dirtyState, setDirtyState] = useState();
      const jiggleDirtyState = () => setDirtyState(!dirtyState);

      return (
        <WrappedComponent
          {...props}
          dirtyState={dirtyState}
          jiggleDirtyState={jiggleDirtyState}
        />
      );
    };

    C.dislayName = `withDirtyState(${WrappedComponent.displayName ||
      WrappedComponent.name})`;
    C.wrappedComponent = WrappedComponent;

    return hoistStatics(C, WrappedComponent);
  };
}
