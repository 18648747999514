import { Api } from 'config/axiosConfig';
import { ServiceHandlerFactory } from 'utils/serviceUtils';

export const fetchAllPrivacyPolicies = teamId => {
  return Api.get('api/privacy/v1/policies', {
    params: {
      teamId,
    },
  });
};

export const fetchAllCollectibles = distribution => {
  return Api.get('api/mtd-policy/v2/privacy/collectibles', {
    params: {
      distribution,
    },
  });
};

export const updatePrivacyPolicy = ServiceHandlerFactory({
  axios: {
    url: 'api/privacy/v1/policies/:policyId',
    method: 'put',
  },
});

export const deletePrivacyPolicy = ServiceHandlerFactory({
  axios: {
    url: 'api/privacy/v1/policies/:id',
    method: 'delete',
  },
});

export const createPrivacyPolicy = ServiceHandlerFactory({
  axios: {
    url: 'api/privacy/v1/policies/',
    method: 'post',
  },
});

export const cloneSelectedPrivacyPolicy = ServiceHandlerFactory({
  axios: {
    url: 'api/privacy/v1/policies/:id/clone',
    method: 'post',
  },
});

export const fetchAllPrivacyPoliciesList = ServiceHandlerFactory({
  axios: {
    url: '/api/privacy/v1/policies',
  },
});

export const fetchPrivacyPolicyById = ServiceHandlerFactory({
  axios: {
    url: 'api/privacy/v1/policies/:id',
  },
});

// helpers
export const cleanPrivacyPolicyForTable = (
  selectedPrivacyPolicy,
  privacyPolicyCollectibles
) => {
  const { rules } = selectedPrivacyPolicy;
  const { byCollectibleId } = privacyPolicyCollectibles;
  const categorizedRules = rules.reduce((accum, value) => {
    const collectible = byCollectibleId[value.collectibleId];

    if (collectible) {
      const { category } = collectible;
      const newValue = {
        ...value,
      };
      if (accum[category]) {
        // if the category exists ?  push   else {  accum [category] = [];   .push(newvalue)}
        accum[category].push(newValue);
      } else {
        /* eslint-disable no-param-reassign */
        accum[category] = [];
        accum[category].push(newValue);
        /* eslint-enable no-param-reassign */
      }
    }
    return accum;
  }, {});
  return categorizedRules;
};
// only show emm fields on a privacy policy on emm scoped AND mtd module
// not zdefend and not legacy or consumer mtd... on mtd plan
// logic is set on privacyPrivacyPolicy.js when fetching the privacy policy (table fetch)
export const cleanPrivacyCollectibles = (collectibles, emmFlag, mtdModule) => {
  return collectibles.reduce(
    (accum, value) => {
      const { id, category } = value;
      if (!emmFlag && value.target === 'MDM') return accum;

      if (category === 0) {
        accum.device.push(value);
      }
      if (category === 1) {
        accum.network.push(value);
      }
      if (category === 2) {
        accum.applications.push(value);
      }
      if (category === 3) {
        accum.deviceOwner.push(value);
      }
      if (category === 4) {
        accum.attackerNetwork.push(value);
      }
      if (category === 5) {
        accum.browserHistory.push(value);
      }
      if (category === 6) {
        accum.attackingFile.push(value);
      }
      if (category === 7) {
        accum.attackingSMS.push(value);
      }
      if (category === 8) {
        accum.browserExtensions.push(value);
      }

      if (id === 3 && !mtdModule) {
        // remove iOS icon for zDefend Application Threat Forensics 
        value = {
          ...value,
          operatingSystems: [1],
        }
      }

      if (id === 5) {
        // Knox icon for Running Processes
        value = {
          ...value,
          operatingSystems: [4],
        }
      }

      if (id === 13 || id === 26) {
        // add Chrome icon for Model
        // add Chrome icon for Risky or Unapproved sites
        value = {
          ...value,
          operatingSystems: [...value.operatingSystems, 3],
        }
      }

      if ((id === 22 || id === 23) && mtdModule) {
        // Knox icon for ARP Table and Routing Table
        value = {
          ...value,
          operatingSystems: [4],
        }
      }

      if ((id === 34 || id === 35) && mtdModule) {
        // Chrome icon for Browser Extensions
        value = {
          ...value,
          operatingSystems: [3],
        }
      }
      /* eslint-disable no-param-reassign */
      accum.byCollectibleId[value.id] = value;
      /* eslint-enable no-param-reassign */

      return accum;
    },
    {
      applications: [],
      network: [],
      device: [],
      deviceOwner: [],
      attackerNetwork: [],
      browserHistory: [],
      attackingFile: [],
      attackingSMS: [],
      browserExtensions: [],

      byCollectibleId: {},
    }
  );
};
