import Checkbox from 'components/UI/input/Checkbox';
import React from 'react';
import { ICheckBoxValues, IProtectionCoverage } from './models';
import useStyles from './useStyles';
import { ProtectionLabel, PROTECTION_COVERAGE_FIELDS, PROTECTION_COVERAGE_FIELDS_IOS } from './utils';

interface IProtectionCoverageProps {
  checkBoxValues: Partial<ICheckBoxValues>;
  buildOs: string;
}

const ProtectionCoverage: React.FC<IProtectionCoverageProps> = ({
  buildOs, checkBoxValues,
}) => {
  const classes = useStyles();
  const protectionFields = buildOs === 'ANDROID' ? PROTECTION_COVERAGE_FIELDS : PROTECTION_COVERAGE_FIELDS_IOS;
  return (
    <div className={classes.twoColumnGroup}>
      {protectionFields.map(
        ({ id, fieldName, displayName, tooltip }: IProtectionCoverage) => {
          const checked = !!checkBoxValues?.[fieldName];
          return (
            <Checkbox
              checked={checked}
              color="primary"
              disabled
              key={id}
              name="protectionCoverages"
              size="small"
              value={fieldName}
              label={<ProtectionLabel displayName={displayName} tooltip={tooltip} />}
            />
          );
        }
      )}
    </div >
  );
};

export default ProtectionCoverage;
