import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    searchBar: {
      marginBottom: 16,
    },
    mainContainer: {
      padding: '15px',
    },
  })
);

export default useStyles;
