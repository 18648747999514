import { IGroupPolicyMapping } from './models';
import { IConnector } from '../../models';
import { useMemo } from 'react';
import GenericTable from 'components/UI/GenericTable';
import { headers, getRowMapping } from './PolicyGroupTable.mappings';
import NoDataTableContent from './NoDataTableContent';
import { TFunction } from 'react-i18next';
interface IPolicyGroupTableProps {
  t: TFunction<'translation', undefined>;
  connector?: IConnector;
  handleDeletePolicyGroup: (id: string) => void;
  groupPolicyMappings: IGroupPolicyMapping[];
  handleAddPolicyGroupModal: () => void;
  handleDragStart: (rowIndex?: number | null | undefined) => void;
  handleDragOver: (
    e: React.DragEvent<HTMLButtonElement>,
    rowIndex?: number
  ) => void;
  handleDrop: (
    e: React.DragEvent<HTMLButtonElement>,
    rowIndex?: number | null | undefined
  ) => void;
  handleCellMouseEnter: (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    content: IGroupPolicyMapping
  ) => void;
  handleCellMouseLeave: () => void;
  showTooltip: boolean;
  tooltipContent?: IGroupPolicyMapping | undefined;
}

const PolicyGroupTable: React.FC<IPolicyGroupTableProps> = ({
  t,
  connector,
  handleDeletePolicyGroup,
  groupPolicyMappings,
  handleAddPolicyGroupModal,
  handleDragOver,
  handleDragStart,
  handleDrop,
  showTooltip,
  tooltipContent,
  handleCellMouseEnter,
  handleCellMouseLeave,
}) => {
  const rowMapping = useMemo(
    () =>
      getRowMapping(
        showTooltip,
        tooltipContent,
        handleAddPolicyGroupModal,
        handleDeletePolicyGroup,
        handleDragOver,
        handleDrop,
        handleCellMouseEnter,
        handleCellMouseLeave
      ),
    [
      handleAddPolicyGroupModal,
      handleDeletePolicyGroup,
      handleDragOver,
      handleDrop,
      showTooltip,
      tooltipContent,
      handleCellMouseEnter,
      handleCellMouseLeave,
    ]
  );
  return (
    <GenericTable
      draggable
      handleDragStart={handleDragStart}
      noDataTableContent={
        <NoDataTableContent onHandleClick={handleAddPolicyGroupModal} />
      }
      columnHeaders={headers(connector?.id, t)}
      rowMapping={rowMapping}
      tableData={groupPolicyMappings}
      tableId="EMMPolicyGroupTableId"
    />
  );
};

export default PolicyGroupTable;
