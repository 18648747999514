import { default as MuiAccordion } from '@material-ui/core/Accordion';
import { default as MuiAccordionDetails } from '@material-ui/core/AccordionDetails';
import { default as MuiAccordionSummary } from '@material-ui/core/AccordionSummary';
import { default as MUIArrowForwardIosIcon } from '@material-ui/icons/ArrowForwardIos';
import { fetchAppInventory } from 'api/AppsInventoryService';
import { selectedTeam as selectedTeamAtom } from 'atoms/teams';
import { AxiosResponse } from 'axios';
import cc from 'classcat';
import { DrawerContext } from 'components/drawer/DrawerProvider';
import { IDrawerContext } from 'components/drawer/DrawerProvider/models';
import ProtectedLink from 'components/main/protected/ProtectedLink';
import { IzShieldOSType } from 'components/main/zShieldApps/ZShieldApps/models';
import DrawerHeader from 'components/UI/DrawerHeader';
import DrawerSkeleton from 'components/UI/DrawerSkeleton';
import GenericCard from 'components/UI/GenericCard';
import Moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  severityByValues,
  transformClassification,
} from '../appsInventory.mappings';
import { IAppDetailsResponse, IAppVersion, ICvesData } from '../models';
import useStyles from './useStyles';
import { extractVersionNumber } from '../utils';
import { HighestCvssSeverity, RiskScoreCell } from '../RiskScoreCell';
import Tooltip from '@material-ui/core/Tooltip';

interface IDrawerProps {}

const Drawer: React.FC<IDrawerProps> = () => {
  const [appDetails, setAppDetails] = useState<Partial<IAppVersion>>({});
  const [riskExpanded, setRiskExpanded] = useState<boolean>(true);
  const [permissionsExpanded, setPermissionsExpanded] =
    useState<boolean>(false);
  const classes = useStyles();
  const { drawerData, closeDrawer } =
    useContext<Partial<IDrawerContext<{ data: Partial<IAppVersion> }>>>(
      DrawerContext
    );
  const selectedTeamId = useRecoilValue(selectedTeamAtom);
  const { t } = useTranslation();
  const protectedAppRowData = drawerData?.data ?? null;

  const params = useMemo(
    () =>
      new URLSearchParams({
        duration: '9',
        appVersionId: protectedAppRowData?.id ?? '',
        appName: protectedAppRowData?.name ?? '',
        appVersion: protectedAppRowData?.version?.toString() ?? '',
      }),
    [
      protectedAppRowData?.id,
      protectedAppRowData?.name,
      protectedAppRowData?.version,
    ]
  );

  const closeDrawerOnLogout = useCallback(() => {
    if (!closeDrawer) {
      return;
    }
    closeDrawer();
  }, [closeDrawer]);

  useEffect(() => {
    if (!protectedAppRowData?.id) {
      return;
    }

    fetchAppInventory({
      appId: protectedAppRowData.id,
      // For TEAM_BOUNDED user, the UI should be passing the selected teamId as a query parameter
      teamId: selectedTeamId,
    }).then((response: AxiosResponse<IAppDetailsResponse>) => {
      setAppDetails((response?.data ?? {}) as Partial<IAppVersion>);
    });
  }, [protectedAppRowData, selectedTeamId]);

  useEffect(() => {
    window.addEventListener(`auth:logout`, closeDrawerOnLogout);

    return () => {
      window.removeEventListener(`auth:logout`, closeDrawerOnLogout);
    };
  }, [closeDrawerOnLogout]);

  const handleClose = () => {
    if (!closeDrawer) {
      return;
    }
    closeDrawer();
  };

  if (!appDetails?.id) {
    return (
      <DrawerSkeleton
        onClose={handleClose}
        os={IzShieldOSType.ANDROID}
        title={protectedAppRowData?.name ?? ''}
      />
    );
  }

  return (
    <>
      <DrawerHeader
        onClose={handleClose}
        os={
          /^ios$/i.test(protectedAppRowData?.platform ?? '')
            ? IzShieldOSType.IOS
            : IzShieldOSType.ANDROID
        }
        title={protectedAppRowData?.name ?? ''}
      />
      <div className={classes.summary}>
        <div className={classes.classificationHeader}>
          <span>{t('GLOBAL.CLASSIFICATION')}</span>{' '}
          {protectedAppRowData?.classification ? (
            protectedAppRowData?.classification === 'MALICIOUS' ? (
              <span className={classes.malicious}>
                {t(
                  transformClassification(protectedAppRowData?.classification)
                )}
              </span>
            ) : (
              t(transformClassification(protectedAppRowData?.classification))
            )
          ) : (
            ''
          )}
        </div>
        <div>
          <div className={classes.summaryAssessment}>
            <span>{t('MTD.APP_INVENTORY.DASHBOARD.PRIVACY_RISK')}</span>{' '}
            <div className={classes.headerRisk}>
              {RiskScoreCell(
                protectedAppRowData,
                protectedAppRowData?.privacyRisk,
                t
              )}
            </div>
          </div>
        </div>
        <div>
          <div className={classes.summaryAssessment}>
            <span>{t('MTD.APP_INVENTORY.DASHBOARD.SECURITY_RISK')}</span>{' '}
            <div className={classes.headerRisk}>
              {RiskScoreCell(
                protectedAppRowData,
                protectedAppRowData?.securityRisk,
                t
              )}
            </div>
          </div>
        </div>
      </div>
      <GenericCard noPadding>
        <table
          className={cc([
            classes.detailsTable,
            classes.detailsTableWithPadding,
          ])}
        >
          <tr>
            <td>{t('MTD.POLICIES.APP_POLICY.DEVELOPER_NAME')}:</td>
            <td>{protectedAppRowData?.developerName ?? ''}</td>
          </tr>
          <tr>
            <td>{t('MTD.POLICIES.APP_POLICY.DEVELOPER_SIGNATURE')}:</td>
            <td>{protectedAppRowData?.developerSignature ?? ''}</td>
          </tr>
          <tr>
            <td>{t('MTD.APP_INVENTORY.TABLE_VIEW.PACKAGE_BUNDLE')}:</td>
            <td>{protectedAppRowData?.bundleId ?? ''}</td>
          </tr>
          <tr>
            <td>{t('GLOBAL.VERSION')}:</td>
            <td>{protectedAppRowData?.version ?? ''}</td>
          </tr>
          <tr>
            <td>{t('MTD.APP_INVENTORY.DASHBOARD.ORIGINAL_FILE_NAME')}:</td>
            <td>
              {(protectedAppRowData?.filename ?? '').split('/').pop() ?? ''}
            </td>
          </tr>
          <tr>
            <td>{t('MTD.APP_INVENTORY.DASHBOARD.APP_HASH')}:</td>

            <td>
              <Tooltip title={protectedAppRowData?.hash ?? ''}>
                <div>
                  {(protectedAppRowData?.hash ?? '')?.length <= 45
                    ? protectedAppRowData?.hash ?? ''
                    : (protectedAppRowData?.hash ?? '')?.slice(0, 45) + '...'}
                </div>
              </Tooltip>
            </td>
          </tr>
          <tr>
            <td>{t('MTD.APP_INVENTORY.DASHBOARD.CREATION_DATE')}:</td>
            <td>
              {Moment(protectedAppRowData?.created ?? '').format('DD-MMM-YYYY')}
            </td>
          </tr>
          <tr>
            <td>{t('GLOBAL.DEVICE_COUNT')}:</td>
            <td>
              <ProtectedLink
                allow={{ devices: 'view' }}
                to={`/console/mtd/devices/?${params.toString()}`}
              >
                <span
                  onClick={() => {
                    handleClose();
                  }}
                >
                  {' '}
                  {(appDetails?.deviceCount ?? 0).toLocaleString('en-US')}
                </span>
              </ProtectedLink>
            </td>
          </tr>
        </table>
      </GenericCard>
      <MuiAccordion className={classes.accordion} expanded={riskExpanded}>
        <MuiAccordionSummary
          onClick={() => setRiskExpanded(!riskExpanded)}
          className={classes.accordionHeader}
          expandIcon={<MUIArrowForwardIosIcon />}
        >
          <div className={classes.riskDetailsAccordion}>
            <div className={classes.riskTitle}>
              {t('MTD.APP_INVENTORY.DASHBOARD.CVE_RISK_DETAILS')}
            </div>
            <div className={classes.severityStyle}>
              <span className={classes.heading}>
                {t('MTD.APP_INVENTORY.DASHBOARD.HIGHEST_CVSS_SEVERITY')}:
              </span>
              <span className={classes.risk}>
                {HighestCvssSeverity(protectedAppRowData, t)}
              </span>
            </div>
          </div>
        </MuiAccordionSummary>
        <MuiAccordionDetails
          classes={{
            root: classes.accordionDetails,
          }}
        >
          {protectedAppRowData?.cves?.map(
            ({ baseScore, baseSeverity, cveId, scoreType }: ICvesData) => (
              <GenericCard noPadding>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className={classes.cvesDetail}>{cveId}</div>
                  <div className={classes.details}>
                    <div className={classes.severityStyle}>
                      <span className={cc([classes.heading, classes.rows])}>
                        {t('GLOBAL.SEVERITY')}:
                      </span>
                      <span className={classes.risk}>
                        {severityByValues(baseSeverity, t)}
                      </span>
                    </div>
                    <div>
                      <span className={cc([classes.heading, classes.rows])}>
                        {t('MTD.APP_INVENTORY.DASHBOARD.CVSS_SCORE')}:
                      </span>
                      <span>
                        {baseScore ??
                          t?.('MTD.APP_INVENTORY.TABLE_VIEW.UNAVAILABLE')}
                      </span>
                    </div>
                    <div>
                      <span className={cc([classes.heading, classes.rows])}>
                        {t('MTD.APP_INVENTORY.DASHBOARD.CVSS_VERSION')}:
                      </span>
                      <span>
                        {extractVersionNumber(scoreType) ??
                          t?.('MTD.APP_INVENTORY.TABLE_VIEW.UNAVAILABLE')}
                      </span>
                    </div>
                  </div>
                </div>
              </GenericCard>
            )
          )}
        </MuiAccordionDetails>
      </MuiAccordion>
      <MuiAccordion
        className={classes.accordion}
        expanded={permissionsExpanded}
      >
        <MuiAccordionSummary
          onClick={() => setPermissionsExpanded(!permissionsExpanded)}
          className={classes.accordionHeader}
          expandIcon={<MUIArrowForwardIosIcon />}
        >
          <span className={classes.riskTitle}>
            {t('MTD.APP_INVENTORY.DASHBOARD.PERMISSIONS')}
          </span>
        </MuiAccordionSummary>
        <MuiAccordionDetails
          classes={{
            root: classes.accordionDetails,
          }}
        >
          <div className={classes.permissions}>
            {!appDetails?.permissions?.length && <div>{t('GLOBAL.NONE')}</div>}
            {appDetails?.permissions?.map((permission) => (
              <div>{permission}</div>
            ))}
          </div>
        </MuiAccordionDetails>
      </MuiAccordion>
    </>
  );
};
export default Drawer;
