import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import { Link } from 'react-router-dom';
import { IAllow } from './models';
import useStyles from './useStyles';

interface IProtectedLinkProps {
  allow: IAllow;
  children: JSX.Element | string | number;
  to: string;
}

const ProtectedLink: React.FC<IProtectedLinkProps> = ({
  allow,
  children,
  to,
}) => {
  const classes = useStyles();
  return (
    <ProtectedComponent allow={allow} componentIfNotAllowed={children}>
      <Link
        className={
          typeof children === 'string' || typeof children === 'number' || typeof children === 'object'
            ? classes.link
            : ''
        }
        to={to}
      >
        {children}
      </Link>
    </ProtectedComponent>
  );
};

export default ProtectedLink;
