import {
  Checkbox as MUICheckbox,
  Input as MUIInput,
  Select as MUISelect,
} from '@material-ui/core';
import GenericRowActions from 'components/UI/GenericTable/GenericRowActions';
import { TFunction } from 'react-i18next';
import { IGenericRowActions } from '../GenericTable/GenericRowActions/models';
import {
  IGenericColumnHeader,
  IGenericFullData,
  TRowData,
} from '../GenericTable/models';
import EditableCellContent from './EditableCellContent';
import { IEditableRowMapping, TEditableRowData } from './models';

export const ACTIONS_HEADER = (
  t: TFunction<'translation', undefined>
): IGenericColumnHeader => ({
  id: 'actions',
  label: t('GLOBAL.ACTIONS'),
});

export const getActionsMapping = ({
  editVisible = true,
  onAdd,
  onCancel,
  onDelete,
  onEdit,
  onSave,
}: {
  editVisible?: boolean;
  onAdd?: (
    e: React.ChangeEvent<HTMLInputElement | unknown>,
    rowData: TEditableRowData
  ) => void;
  onCancel?: (
    e: React.ChangeEvent<HTMLInputElement | unknown>,
    rowData: TEditableRowData
  ) => void;
  onDelete?: (
    e: React.ChangeEvent<HTMLInputElement | unknown>,
    rowData: TEditableRowData
  ) => void;
  onEdit?: (
    e: React.ChangeEvent<HTMLInputElement | unknown>,
    rowData: TEditableRowData
  ) => void;
  onSave?: (
    e: React.ChangeEvent<HTMLInputElement | unknown>,
    rowData: TEditableRowData
  ) => void;
}) => {
  const actionsMapping: IEditableRowMapping = {
    path: 'actions',
    columnContent: (fullData, rowIndex, tableData) => {
      const { rowData } = fullData;
      const isLastRow = !!tableData?.[0] && rowIndex === tableData.length - 1;
      const rowDisabled = rowData.rowDisabled;
      const rowEditable = rowData.rowEditable;

      const defaultActions: IGenericRowActions[] = [
        {
          actionFn: (e) => onEdit && onEdit(e, rowData),
          disabled: rowDisabled,
          type: 'edit',
          visible: editVisible,
        },
        {
          actionFn: (e) => onDelete && onDelete(e, rowData),
          disabled: rowDisabled,
          hidden: !onDelete,
          type: 'delete',
        },
        {
          actionFn: (e) => onAdd && onAdd(e, rowData),
          onlyLastRow: true,
          type: 'add',
        },
      ];

      const editingActions: IGenericRowActions[] = [
        {
          actionFn: (e) => onCancel && onCancel(e, rowData),
          disabled: rowDisabled && !rowEditable,
          type: 'cancel',
        },
        {
          actionFn: (e) => onSave && onSave(e, rowData),
          disabled: rowDisabled && !rowEditable,
          hidden: !onSave,
          type: 'save',
        },
      ];

      return (
        <GenericRowActions
          actions={rowEditable ? editingActions : defaultActions}
          isLastRow={isLastRow}
        />
      );
    },
  };

  return actionsMapping;
};

export const INPUT_COMPONENT_MAPPINGS = {
  checkbox: MUICheckbox,
  default: MUIInput,
  select: MUISelect,
};

export const generateColumnContentFn = ({
  headerId,
  rowMapping,
  tableId,
}: {
  headerId: string;
  rowMapping?: IEditableRowMapping;
  tableId?: string;
}) => {
  const columnContent = (
    fullData: IGenericFullData,
    rowIndex?: number,
    tableData?: TRowData[]
  ) => {
    const {
      classes,
      rowData,
      rowData: { rowDisabled },
    } = fullData;
    const cellDisabled =
      rowDisabled &&
      !(rowMapping?.alwaysEnabled ?? false) &&
      !rowData?.rowEditable;
    const cellContent = !!rowMapping?.columnContent ? (
      rowMapping.columnContent(fullData, rowIndex, tableData)
    ) : (
      <EditableCellContent
        fullData={fullData}
        headerId={headerId}
        rowMapping={rowMapping}
        tableId={tableId}
        key={rowIndex}
      />
    );

    return (
      <div className={cellDisabled ? classes?.disabled ?? '' : ''}>
        {cellContent}
      </div>
    );
  };

  return columnContent;
};

export const generateEmptyRow = (headers: IGenericColumnHeader[]) => {
  const headerIds = headers.map(({ id }) => id);

  const emptyEditableRow: TEditableRowData = {
    id: 'newRow',
    rowEditable: true,
  };
  headerIds.forEach((headerId) => {
    emptyEditableRow[headerId] = '';
  });

  return emptyEditableRow;
};
