import CopyField from 'components/UI/input/CopyField';
import React from 'react';
import { openSnackBarDiffered } from 'utils/storeUtils';
import useStyles from './useStyles';
import useActivationCode from './useActivationCode';
import { useTranslation } from 'react-i18next';

interface IActivationCodeProps {
  emmConnectionId: string;
  connectorTypeId: string;
}

const ActivationCode: React.FC<IActivationCodeProps> = ({
  emmConnectionId,
  connectorTypeId,
}) => {
  const { message, activationCode } = useActivationCode({
    emmConnectionId,
    connectorTypeId,
  });
  const classes = useStyles();

  const { t } = useTranslation();

  if (!activationCode || !message) {
    return null;
  }
  return (
    <div>
      <div className={classes.title}>{t(message.title)}</div>

      <div className={classes.message}>
        {t(message.main)}
        <CopyField
          disabled
          name=""
          onCopy={openSnackBarDiffered(
            t('MTD.INTEGRATIONS.MESSAGE_ACTIVATION_CODE_COPIED')
          )}
          value={activationCode}
        />
      </div>
    </div>
  );
};

export default ActivationCode;
