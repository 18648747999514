import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

// ui
import Typography from '@material-ui/core/Typography';

// actions and services
import { requestPasswordReset } from 'api/SignupService';

import PublicComponentWrapper from 'components/wrappers/PublicComponentWrapper';
import ZButton from 'UI/Buttons/ZButton';
import { FormikTextField } from 'components/inputs/FormikTextField';
import {
  csvInjectionRegex,
  csvInjectionErrorMessage,
  looksGenerallyLikeAnEmailRegex,
  looksGenerallyLikeAnEmailRegexErrorMessage,
} from 'utils/componentUtils';

const ForgotPassword = (props) => {
  const [emailSent, setEmailSent] = useState(false);
  const { sso } = useSelector((state) => state.auth);

  function handleSubmit(values, { setErrors }) {
    requestPasswordReset(values.email)
      .then((data) => {
        setEmailSent(true);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }

  if (sso.enabled === true && sso.disableLocalLogins === true) {
    props.history.replace('/login');
  }

  return (
    <PublicComponentWrapper>
      <div>
        {!emailSent ? (
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={ResetPasswordSchema}
            validateOnBlur
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ dirty, isSubmitting, values }) => {
              return (
                <div>
                  <Form>
                    <Field
                      name="email"
                      label="Email"
                      component={FormikTextField}
                      classlist={{ label: 'entry-label' }}
                    />
                    <ZButton
                      buttonType="submit"
                      styleName="login"
                      color="primary"
                      buttonText="Reset Password"
                    />
                  </Form>
                </div>
              );
            }}
          </Formik>
        ) : (
          <div>
            <Typography variant="body1" align="center" className="entry-label">
              An email has been sent to you. <br /> Please follow the
              instructions to reset your password.
            </Typography>
          </div>
        )}
      </div>
    </PublicComponentWrapper>
  );
};

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .matches(
      looksGenerallyLikeAnEmailRegex,
      looksGenerallyLikeAnEmailRegexErrorMessage
    )
    .matches(csvInjectionRegex, csvInjectionErrorMessage)
    .required('Required'),
});

export default withRouter(ForgotPassword);
