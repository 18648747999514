import React from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';

const MaterialUiRadioGroup = ({ handleChange, options, classes, ...props }) => (
  <RadioGroup
    className={classes.radioContainer}
    onChange={(e) => {
      handleChange(e, _.find(options, ['value', e.target.value]));
    }}
    {...props}
  >
    {options.map((option) => {
      return (
        <div>
          <FormControlLabel
            key={option.label}
            color="primary"
            control={<Radio color="primary" size="small" />}
            disabled
            {...option}
            classes={{ label: classes.labelRoot }}
            value={option.value}
          />
        </div>
      );
    })}
  </RadioGroup>
);

export const RadioControl = (props) => {
  return (
    <>
      <MaterialUiRadioGroup
        classes={props.classes}
        handleChange={(e) => {
          props.handleChange(e, _.find(props.options, ['value', props.name]));
        }}
        options={props.options}
        {...props}
        value={props.value.value}
      />
    </>
  );
};

const styles = ({ palette }) => ({
  radioContainer: {
    flexDirection: 'row',
  },
  labelRoot: {
    fontSize: 14,
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  labelText: {
    fontSize: 15,
    color: palette.text.secondary,
  },
  labelWrapper: {
    // width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 5,
  },
});

RadioControl.prototypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};
RadioControl.defaultProps = {
  disabled: false,
};
export default withStyles(styles)(RadioControl);
