import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    tabWrapper: {
      paddingTop: '15px',
    },
  })
);

export default useStyles;
