import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      '& .MuiDialog-container > div': {
        maxWidth: '1186px',
        maxHeight: '756px',
        width: '1186px',
        height: '756px',
      },
    },
  })
);

export default useStyles;
