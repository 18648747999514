import _ from 'lodash';
import React, { useState, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import withRouter from 'components/hocs/withRouter';

// components
import ProtectedComponent from 'components/main/protected/ProtectedComponent';

// material ui
import DashboardIcon from '@material-ui/icons/Dashboard';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import PaletteIcon from '@material-ui/icons/Palette';
import MuiPopover from '@material-ui/core/Popover';

// icons
import InvertColors from '@material-ui/icons/InvertColors';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import AuthIcon from '@material-ui/icons/Fingerprint';
import UserIcon from '@material-ui/icons/AccountCircle';
import LogoutIcon from '@material-ui/icons/FirstPage';

import { toggleTheme } from 'reducers/UiSettingsReducers';

const ConsoleSidebar = ({ match, classes, ...props }) => {
  const $userMenuRef = useRef(null);

  const { firstName, lastName } = useSelector((state) => state.partnerUser);
  const { scopes } = useSelector(({ auth }) => auth.partnerUser);
  const [popover, setPopover] = useState({ open: false });

  function togglePopOver() {
    setPopover({
      open: !popover.open,
      anchorEl: $userMenuRef.current,
    });
  }
  if (_.isEmpty(scopes)) {
    return (
      <div className={props.classes.sidebarContainer}>
        <div className={props.classes.mainNav}>
          <nav>
            <ListItem button component={NavLink} to="/logout">
              <ListItemIcon style={{ marginRight: 0 }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText disableTypography secondary="Logout" />
            </ListItem>
          </nav>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.sidebarContainer}>
      <div className={classes.mainNav}>
        <nav>
          <ProtectedComponent allow={{ managed_accounts: 'enter' }} isPartner>
            <ListItem
              button
              to={`${match.url}/accounts`}
              component={NavLink}
              className={classes.linkWrapper}
              activeClassName={classes.active}
            >
              <ListItem button>
                <ListItemIcon>
                  <DashboardIcon className={classes.nonActiveIcon} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  secondary="Accounts"
                  className={classes.listItemText}
                />
              </ListItem>
            </ListItem>
          </ProtectedComponent>

          <ProtectedComponent allow={{ partner_users: 'manage' }} isPartner>
            <ListItem
              button
              component={NavLink}
              to={`${match.url}/users`}
              className={classes.linkWrapper}
              activeClassName={classes.active}
            >
              <ListItem button>
                <ListItemIcon>
                  <PeopleIcon className={classes.nonActiveIcon} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  secondary="Partner Users"
                  className={classes.listItemText}
                />
              </ListItem>
            </ListItem>
          </ProtectedComponent>
          <ProtectedComponent allow={{ partner_api_keys: 'manage' }} isPartner>
            <ListItem
              button
              component={NavLink}
              to={`${match.url}/authorizations`}
              className={classes.linkWrapper}
              activeClassName={classes.active}
            >
              <ListItem button>
                <ListItemIcon>
                  <AuthIcon className={classes.nonActiveIcon} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  secondary="API Keys"
                  className={classes.listItemText}
                />
              </ListItem>
            </ListItem>
          </ProtectedComponent>
          <ProtectedComponent allow={{ branding: 'manage' }} isPartner>
            <ListItem
              button
              component={NavLink}
              to={`${match.url}/branding`}
              className={classes.linkWrapper}
              activeClassName={classes.active}
            >
              <ListItem button>
                <ListItemIcon>
                  <PaletteIcon className={classes.nonActiveIcon} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  secondary="Branding"
                  className={classes.listItemText}
                />
              </ListItem>
            </ListItem>
          </ProtectedComponent>
        </nav>
        <div className={classes.mainFooterNav}>
          <div
            ref={$userMenuRef}
            className={classes.userActionsBox}
            onClick={togglePopOver}
          >
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <UserIcon
                className={classes.iconStyle}
                style={{ marginRight: 5 }}
              />
              {firstName} {lastName}
              {popover.open ? (
                <KeyboardArrowRight className={classes.expandIcon} />
              ) : (
                <KeyboardArrowDown className={classes.expandIcon} />
              )}
            </span>
          </div>
        </div>
      </div>

      <MuiPopover
        {...popover}
        position="right"
        onClose={togglePopOver}
        anchorOrigin={{
          vertical: 'left',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <ListItem button onClick={props.toggleTheme}>
          <ListItemIcon style={{ marginRight: 0 }}>
            <InvertColors />
          </ListItemIcon>
          <ListItemText disableTypography secondary="Toggle Theme" />
        </ListItem>
        <ListItem button component={NavLink} to="/logout">
          <ListItemIcon style={{ marginRight: 0 }}>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText disableTypography secondary="Logout" />
        </ListItem>
      </MuiPopover>
    </div>
  );
};

const styles = ({ config, palette, sizing }) => ({
  sidebarContainer: {
    width: sizing.sidebar.maxWidth,
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100%',
    flexDirection: 'column',
    background: palette.background.default,
    position: 'fixed',
    height: '100vw',
    // allows popup to display over the the main content
    zIndex: 99,
  },

  itemIcon: {
    marginRight: 0,
    color: palette.text.primary,
  },

  itemText: {
    fontSize: config.textSizes.normal,
    fontWeight: 400,
  },
  linkWrapper: {
    textDecoration: 'none',
    padding: 0,
    transition: 'none',
  },

  mainFooterNav: {
    position: 'fixed',
    bottom: 0,
    width: sizing.sidebar.maxWidth,
    zIndex: '200',
    flex: 1,
  },

  userActionsBox: {
    padding: 16,
    position: 'relative',
    background: palette.background.default,
    fontSize: 14,

    '&:hover': {
      cursor: 'pointer',
    },

    '& button': {
      fontSize: 14,
    },
  },

  paddedContainer: {
    padding: 16,
    fontSize: 14,
  },

  buttonIcon: {
    padding: 10,
  },

  iconStyle: {
    fontSize: config.textSizes.paragon,
    color: palette.components.globalHeader.contrastText,
  },
  listItemText: {
    padding: 0,
    fontSize: '0.875rem',
    fontWeight: 400,
    fontFamily: config.fonts.stackSans,
    lineHeight: '1.46429em',
  },

  expandIcon: {
    position: 'absolute',
    right: 8,
  },
  active: {
    background: palette.background.paper,
    color: palette.primary.main,
    fontWeight: 'bold',

    '& svg': {
      color: palette.primary.main,
    },
  },

  activeIcon: {
    color: palette.primary.main,
  },
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ toggleTheme }, dispatch);

export default compose(
  withStyles(styles),
  withRouter,
  connect(null, mapDispatchToProps)
)(ConsoleSidebar);
