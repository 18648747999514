import _ from 'lodash';
import { Link } from 'react-router-dom';
import Input from '@material-ui/icons/Input';
import useStyles from './useStyles';
import Moment from 'moment';

const DetailsData = (props: any) => {
  const classes = useStyles();
  const { data, dataProperty, config, omitRowPath, handlers } = props;
  let rowMapping = config[dataProperty];
  if (omitRowPath) {
    rowMapping = _.differenceBy(rowMapping, omitRowPath, 'path');
  }

  return (
    <div className={classes.detailsDataWrapper}>
      {props.title && <h3 style={{ paddingLeft: 10 }}>{props.title}</h3>}
      {config[dataProperty] &&
        rowMapping.map((configObj: any, index: any) => {
          const path = configObj.path ? configObj.path : configObj.key;
          let value = configObj.defaultValue;
          if (_.has(data, path)) {
            value = _.get(data, path);
          } else {
            return null;
          }

          if (path == 'createdAt') {
            value = Moment(_.get(data, path)).format('DD-MMM-YYYY');
          }

          if (_.startsWith(path, 'cvss') && _.isEmpty(value)) {
            return null;
          }

          if (typeof configObj.fetch === 'function') {
            value = configObj.fetch(value, data, handlers);
          }

          if (_.get(configObj, 'type', '') === 'link') {
            value = _.map(value.split('\n'), (val) => (
              <Link target="_blank" to={{ pathname: val }}>
                {val} <Input className={'lasses.inputIcon'} />
              </Link>
            ));
          }
          if (value === 'omit') {
            return null;
          }
          return (
            <div key={`${value}-${index}`} className={classes.detailsData}>
              <div className={classes.detailsLabel}>{configObj.label}</div>
              <div className={classes.detailsLabelValue}>{value}</div>
            </div>
          );
        })}
    </div>
  );
};

export default DetailsData;
