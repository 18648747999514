import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import withRouter from 'components/hocs/withRouter';

// material ui
import AppBar from '@material-ui/core/AppBar';
import MaterialTab from '@material-ui/core/Tab';
import MaterialTabs from '@material-ui/core/Tabs';

const LinkTab = props => {
  return <MaterialTab className="tabs-label" component={Link} {...props} />;
};

function RouterTabsNav(props) {
  return (
    <AppBar position="static" className={props.classes.tabsAppBar}>
      <MaterialTabs value={props.match.params.tabId}>
        {props.routesMap.map((route, i) => {
          if (typeof route.renderInMenu === 'boolean' && !route.renderInMenu) {
            return null;
          }

          return (
            <LinkTab
              disableRipple
              key={`${props.subroute}-link-${i}`}
              label={route.linkLabel}
              to={{ pathname: `/console/${props.subroute}${route.path}` }}
              value={route.path.substring(1)}
            />
          );
        })}
      </MaterialTabs>
    </AppBar>
  );
}

const styles = () => {
  return {
    tabsAppBar: {
      marginTop: 15,
      marginBottom: 15,
      background: 'none',
      boxShadow: 'none',
    },
  };
};

export default withStyles(styles)(withRouter(RouterTabsNav));
