import { ISelectItem } from 'components/UI/input/Select';
import { ELanguageLabel, TLanguageOption } from 'models/language';
import {
  TUserAccountProfileResponse,
} from '../models';

const initialValues = (
  data: TUserAccountProfileResponse
) => {
  const accountFallbackLanguageValue = data?.account?.languagePreference;
  const accountFallbackLanguageLabel = ELanguageLabel[accountFallbackLanguageValue?.toLowerCase() as keyof typeof ELanguageLabel];
  return {

    firstName: data?.firstName || '',
    middleName: data?.middleName || '',
    languagePreference: {
      label: !!data?.languagePreference
        ? ELanguageLabel[data.languagePreference.toLowerCase() as keyof typeof ELanguageLabel]
        : accountFallbackLanguageLabel,
      value: !!data?.languagePreference ? data.languagePreference : accountFallbackLanguageValue,
    } as ISelectItem<TLanguageOption>,
    lastName: data?.lastName || '',
    email: data?.email || '',
    notificationEmail: data?.notificationEmail || '',
    phone: data?.phone || '',
  };
};

export default initialValues;
