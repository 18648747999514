import { createStyles, makeStyles } from '@material-ui/styles';

// Usage of 'any' inside this style decorator is discouraged; however, our theme
// currently does not conform to MUI's theme structure standards. So this will
// be a followup fix for later.
const useStyles = makeStyles(({ config }: any) =>
  createStyles({
    root: {
      textTransform: 'none',
    },
    icon: {
      marginRight: 5,
      fontSize: `${config.textSizes.primer}px !important`,
    },
  })
);

export default useStyles;
