import React, { useCallback, ChangeEvent, useMemo } from 'react';

import MUISelect from '@material-ui/core/Select';
import MUIMenuItem from '@material-ui/core/MenuItem';
import MUIFormControl from '@material-ui/core/FormControl';
import Button from '../../../../UI/Button';
import useStyles from './useStyles';
// import { ITableActionGroup, TableAction, tableActions } from './TableAction';
import { useTranslation } from 'react-i18next';
import { RenderSelectGroup } from './RenderSelectGroup';
import { TableAction } from './utils';
import { ITableActionGroup } from './models';

interface IFindingsTableAction {
  setSelectedTableAction: React.Dispatch<
    React.SetStateAction<TableAction | undefined>
  >;
  selectedTableAction: TableAction | undefined;
  onGoClick: () => void;
  tableActionsFromParent: ITableActionGroup[];
  isDisabled: boolean;
}

const FindingsTableAction: React.FC<IFindingsTableAction> = ({
  setSelectedTableAction,
  selectedTableAction,
  onGoClick,
  tableActionsFromParent,
  isDisabled = false,
}) => {
  const classes = useStyles();
  const { ready, t } = useTranslation();
  const onTableActionChange = useCallback(
    (
      event: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ): void => {
      const { value } = event.target;
      setSelectedTableAction(value as TableAction);
    },
    [setSelectedTableAction]
  );
  const generateDropDownList = useCallback(
    (group: ITableActionGroup) => RenderSelectGroup(group),
    []
  );

  const memoizedDropDownList = useMemo(
    () => tableActionsFromParent.map((group) => generateDropDownList(group)),
    [tableActionsFromParent, generateDropDownList]
  );

  if (!ready) {
    return null;
  }
  return (
    <div className={classes.tableActionsStyle}>
      <MUIFormControl className={classes.selectFormControl}>
        <MUISelect
          displayEmpty
          onChange={onTableActionChange}
          value={selectedTableAction || ''}
        >
          <MUIMenuItem value="" disabled>
            {t('GLOBAL.TABLE_ACTIONS')}
          </MUIMenuItem>
          {memoizedDropDownList}
        </MUISelect>
      </MUIFormControl>

      <Button
        disabled={isDisabled}
        text={t('GLOBAL.GO')}
        color="secondary"
        style={{ height: 'min-content' }}
        onClick={onGoClick}
      />
    </div>
  );
};
export default FindingsTableAction;
