import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import {
  buildsAppsTableColumnChange as instancesTableColumnChange,
  updateUISettings,
} from 'reducers/UiSettingsReducers';
import {
  getUISettingsTableHeaders,
  getUISettingsWithout,
} from 'reducers/UiSettingsSelectors';
import { ZScanInstancesColumnMapping } from './zScanInstances.mappings';
// import { getInstances } from 'api/zDevService';
import { IColumnHeader, ILocationQuery } from 'components/UI/Table/models';
import { ILocation, IQueryParams } from './models';
import { withDirtyState } from 'utils/withDirtyState';
//import withMemoizedProps from 'utils/withMemoizedProps';
import { bindActionCreators, compose } from 'redux';
import SearchBox from 'components/UI/input/SearchBox';
import MUICard from '@material-ui/core/Card';
import Table from 'components/UI/Table';
import TableActions from '../../TableActions';
import useBackfill from 'utils/useBackfill';
import { TableAction } from '../../TableAction';
import tableQuery from './tableQuery';
import useStyles from './useStyles';
import withMemoizedProps from 'utils/withMemoizedProps';

interface IZScanInstances {
  location: ILocation;
  instancesTableColumnChange: (...args: unknown[]) => void;
  instancesUiSettings: { [key: string]: unknown };
  currentTableHeaders: IColumnHeader[];
  definedUser?: string;
  jiggleDirtyState: (...args: any) => void;
  q: { [key: string]: unknown };
  rqps: string[];
  scopes: { [key: string]: string[] };
  updateUISettings: (...args: unknown[]) => void;
  updateUrl: (params: IQueryParams) => void;
  scopeBounds: string;
  findingId: string;
  assessmentId: string;
}

const ZScanInstances: React.FC<IZScanInstances> = ({
  instancesTableColumnChange,
  instancesUiSettings,
  currentTableHeaders,
  definedUser,
  jiggleDirtyState,
  q: query,
  // location,
  rqps,
  updateUISettings,
  updateUrl,
  findingId,
  assessmentId,
}) => {
  const classes: any = useStyles();

  const [selectedTableAction, setSelectedTableAction] = useState<TableAction>();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<string | number | undefined>(0);
  useBackfill({
    query,
    requiredQueryParams: rqps,
    storedQueryParams: instancesUiSettings,
    updateUrl,
  });
  const onGoClick = useCallback((): void => {
    switch (selectedTableAction) {
      case TableAction.DeleteSelected: {
        // const newData = data.filter((el) => !el.selected);
        // setData(newData);
        setSelectedTableAction(undefined);
        break;
      }
      default:
        break;
    }
  }, [selectedTableAction]);

  const handleColumnChange = (...args: unknown[]) => {
    instancesTableColumnChange(...args);
    jiggleDirtyState();
  };
  const onPaginationChange = useCallback(
    (update: IQueryParams) => {
      setPageNumber(Number(update?.page));
      updateUISettings({
        domain: 'zScanInstances',
        update,
      });
    },
    [updateUISettings]
  );

  const handleSearch = useCallback(
    (value: string) => {
      setSearchQuery(value);
    },
    [setSearchQuery]
  );
  const handleSelectionChange = (selectedRows: any) => {
    console.log('selectedRows --->', selectedRows);
  };

  return (
    <>
      {findingId && (
        <div>
          <div style={{ display: 'none' }}>
            <TableActions
              setSelectedTableAction={setSelectedTableAction}
              selectedTableAction={selectedTableAction}
              onGoClick={onGoClick}
            />
          </div>
          <MUICard>
            <SearchBox onInputChange={handleSearch} />
          </MUICard>
          <div style={{ width: '500px', height: '350px', overflow: 'scroll' }}>
            <Table
              minHeight={300}
              height={200}
              serverSort={false}
              classList={classes}
              columnHeaders={currentTableHeaders}
              definedUser={definedUser}
              fetchTableData={() =>
                tableQuery(findingId, assessmentId, {
                  page: Number(pageNumber),
                  size: 25,
                  order: 'desc',
                  search: searchQuery,
                  // accepted: 'false',
                })({
                  page: Number(pageNumber),
                  size: 25,
                  order: 'desc',
                  search: searchQuery,
                  // accepted: false,
                } as unknown as ILocationQuery)
              }
              onColumnChange={handleColumnChange}
              onPaginationChange={onPaginationChange}
              query={
                {
                  page: Number(pageNumber) || '0',
                  size: 25,
                  order: 'desc',
                } as unknown as ILocationQuery
              }
              rowMapping={ZScanInstancesColumnMapping}
              tableId="zScanInstances"
              heightBuffer={290}
              // checkboxColumn
              onSelectionChange={handleSelectionChange}
            />
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    currentTableHeaders: getUISettingsTableHeaders(state, 'zScanInstances'),
    instancesUiSettings: getUISettingsWithout(state, 'zScanInstances', [
      'tableHeaders',
    ]),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      instancesTableColumnChange,
      updateUISettings,
    },
    dispatch
  );
};

export default compose(
  withDirtyState(),
  connect(mapStateToProps, mapDispatchToProps)
)(
  withMemoizedProps(ZScanInstances, [
    'currentTableHeaders',
    'findingId',
    'assessmentId',
    'dirtyState',
    'q',
    'updateUISettings',
    'updateUrl',
  ])
);
