import AT from './ActionTypes';

/*
 * Fetching a page of versions for a given zapp.
 */

export const inappsVersionsTableColumnChange = (value, index, columnData) => {
  return {
    type: AT.INAPPS_VERSIONS_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};
