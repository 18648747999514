import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    buttonPanel: {
      alignItems: 'flex-start',
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '20px 0',
    },
    container: {
      borderTopLeftRadius: '3px',
      borderTopRightRadius: '3px',
      margin: '0px -24px 0px -24px',
      padding: '0px',
      position: 'relative',
      top: '-20px',
      width: '500px',
    },
    content: {
      margin: '50px 30px 0px 30px',
      '& p': {
        marginBlockStart: '0px',
      },
    },
    header: {
      backgroundColor: palette.common.darkGreySoft,
      borderRadius: '3px',
      padding: '18px 0px 16px 0px',
      textAlign: 'center',
      width: '100%',
      paddingLeft: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'

    },
    headerText: {
      color: palette.primary.contrastText,
      fontWeight: 400,
      margin: '0px',
      padding: '0px',
      paddingLeft: '22px',
      textTransform: 'capitalize',
      width: '100%',
      textAlign: 'left'
    },
    titleIcon: {
      color: palette.common.ios,
    },
    osDisclaimer: {
      paddingLeft: 25,
      paddingRight: 25,
      paddingTop: 20
    },
    messageText: {
      color: palette.text.secondary,
    },
    bundleVersionWrapper: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    appVersionField: {
      width: '20%'
    },
    bundleField: {
      width: '60%'
    },
    twoColumnGroup: {
      alignItems: 'flex-end',
      display: 'grid',
      gridAutoFlow: 'row',
      gridGap: '5px',
      gridTemplateColumns: '50% 50%',
      gridTemplateRows: 'auto auto',
      padding: '0 10px',
    },
    // android icon
    backgroundCircle: {
      display: 'flex',
      width: 55,
      height: 55,
      marginTop: 10,
      padding: 5,
      justifyContent: 'center',
      position: 'relative',
      borderRadius: '50%',
      background: palette.background.default,
      marginRight: 'auto',
      marginLeft: 'auto',
      border: `2px solid ${palette.common.android}`,
    },
    // apple icon
    backgroundCircleIOS: {
      display: 'flex',
      alignItems: 'center',
      width: 55,
      height: 55,
      marginTop: 10,
      padding: 5,
      justifyContent: 'center',
      position: 'relative',
      borderRadius: '50%',
      background: palette.primary.border,
      marginRight: 'auto',
      marginLeft: 'auto',
      border: `2px solid ${palette.common.ios}`,
    },
    // info box
    errorInfoBox: {
      alignItems: 'center',
      background: palette.background.default,
      borderColor: palette.charts.orange,
      borderStyle: 'double',
      borderWidth: 1.5,
      display: 'flex',
      justifyContent: 'center',
      padding: '2px 10px',
      cursor: 'default',
    },
    protectionLabel: {
      display: 'flex',
    }
  })
);
export default useStyles;
