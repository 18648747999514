import _ from 'lodash';
import { GlobalMenuOption } from 'components/Selects/MultiSelect/GlobalMenuOption';
import { MultiSelect } from 'components/Selects/index';
import { TextField } from 'components/inputs';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from 'components/inputs/Checkbox';
import clsx from 'clsx';
import DialogActions from '@material-ui/core/DialogActions';
import GlobalMultiSelectButton from 'components/Selects/MultiSelect/GlobalMultiSelectButton';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import ZButton from 'UI/Buttons/ZButton';

const UploaderDropZone = ({
  isShowing,
  handleCloseModal,
  children,
  classes,
  classList,
  showUrlField = null,
  zScanPolicies = [],
  handleSubmitUrl = (
    url,
    notifyUploader,
    shouldAutoUpdateFromAppStore,
    zScanPolicyId
  ) => { },
  handlePolicyChange,
  selectedPolicy,
}) => {
  const { contactEmail } = useSelector((state) => state.account);
  const { email } = useSelector((state) => state.superUser);
  const [storeUrl, setStoreUrl] = useState();
  const [notificationEmail, setNotificationEmail] = useState(
    contactEmail || email
  );
  const [notify, setNotify] = useState(false);
  const [autoUpdateFromAppStore, setAutoUpdateFromAppStore] = useState(false);

  const handleUrlChange = (event) => setStoreUrl(event.target.value);

  const handleNotificationEmailChange = (event) =>
    setNotificationEmail(event.target.value);

  const toggleNotify = () => setNotify(!notify);
  const toggleAutoUpdateFromAppStore = () =>
    setAutoUpdateFromAppStore(!autoUpdateFromAppStore);

  if (!isShowing) {
    return null;
  }

  return (
    <Fragment>
      <div className={clsx(classes.formGroup, classList?.customDropZoneGroup)}>
        {children}
      </div>
      {showUrlField && (
        <div>
          <TextField
            name="storeUrl"
            value={storeUrl}
            label="App Store/Google Play Store URL"
            onChange={handleUrlChange}
          />
          <MultiSelect
            label="Select zScan policy"
            name="zScanPolicy"
            isMulti={false}
            buttonPlaceholder="No policy selected"
            components={{
              Option: GlobalMenuOption,
              DropDownButton: GlobalMultiSelectButton,
            }}
            options={zScanPolicies}
            onChange={handlePolicyChange}
            values={selectedPolicy}
          />
          <div>
            <Checkbox
              checked={autoUpdateFromAppStore}
              onChange={toggleAutoUpdateFromAppStore}
              label="Auto check app store for updates"
            />
          </div>
          <div>
            <Checkbox
              checked={notify}
              onChange={toggleNotify}
              label="Notify when complete"
            />
            <div className={classes.notificationEmailContainer}>
              <TextField
                disabled={!notify}
                label="Send notifications to the following email address"
                labelStyle={notify ? {} : { color: '#6D7B81' }}
                maxRows={3}
                multiline
                name="notificationEmail"
                onChange={handleNotificationEmailChange}
                placeholder="useremail@company.com"
                rows={3}
                value={notificationEmail}
              />
            </div>
          </div>
        </div>
      )}

      <DialogActions>
        <ZButton
          isShowing={!_.isNull(showUrlField)}
          styleName="modalCancel"
          action={handleCloseModal}
          color="secondary"
          buttonText="Cancel"
        />
        <ZButton
          styleName="modalSubmit"
          isShowing={!_.isNull(showUrlField)}
          action={() =>
            handleSubmitUrl(
              storeUrl,
              notify,
              autoUpdateFromAppStore,
              _.get(_.head(selectedPolicy), 'value', '')
            )
          }
          color="primary"
          buttonText="Ok"
        />
      </DialogActions>
    </Fragment>
  );
};

const styles = ({ palette }) => {
  return {
    formGroup: {
      outline: `2px dashed ${palette.primary.main}`,
      outlineOffset: '-10px',
      WebkitTransition:
        'outline-offset .15s ease-in-out, background-color .15s linear',
      transition:
        'outline-offset .15s ease-in-out, background-color .15s linear',
      height: 250,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    dropLabel: {
      marginTop: -100,
    },
    notificationEmailContainer: {
      marginLeft: '30px',
    },
  };
};

UploaderDropZone.propTypes = {
  isShowing: PropTypes.bool,
  handleCloseModal: PropTypes.func.isRequired,
};

UploaderDropZone.defaultProps = {
  isShowing: false,
};

export default withStyles(styles)(UploaderDropZone);