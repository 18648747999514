import { ServiceHandlerFactory } from 'utils/serviceUtils';

export const getSSOConfiguration = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/accounts/sso-settings',
  },
});

export const postSSOConfiguration = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/accounts/sso-settings',
    method: 'put',
  },
});

export const getSSOHostname = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/accounts/sso-settings/account',
  },
});
