import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
/* Helpers */
import { renderForensics } from 'utils/componentUtils';

const styles = ({ palette }) => {
  return {
    wrapper: {
      display: 'flex',
      alignSelf: 'flex-start',
      flexDirection: 'column',
      width: '50%',
    },
    dataWrapper: {
      display: 'flex',
      flexDirection: 'row',
      padding: 5,
      justifyContent: 'space-between',
    },
    label: {
      color: palette.text.secondary,
      fontWeight: 'bold',
      width: '25%',
    },
    labelValue: {
      width: '75%',
      display: 'flex',
    },
  };
};

class ForensicsInfo extends Component {
  render() {
    const { props } = this;
    return (
      <div className={props.classes.wrapper}>
        {renderForensics('forensicsInfo', props)}
      </div>
    );
  }
}

export default withStyles(styles)(ForensicsInfo);
