import { MultiSelect } from 'components/Selects/index';
import React from 'react';
import _ from 'lodash';
import { Checkbox, PasswordField, TextField } from 'components/inputs/index';
import {
  availablePayloadTypes,
  availableProtocolTypes,
  availableAuthorizationTypes,
  availableApiKeyFormat,
} from '../enums/DataExportEnums';

export const RESTEndpoint = (props) => {
  const key = 'REST_ENDPOINT';

  const { config } = props;

  const filteredAailablePayloadTypes = _.filter(
    availablePayloadTypes,
    (payloadType) => payloadType.value === 'JSON'
  );

  const validate = (field, value) => {
    switch (field) {
      case 'authorizationType':
      case 'payloadType':
      case 'protocol':
        return !_.isEmpty(value);
      case 'allowSelfSignedSsl':
      case 'endPoint':
      case 'username':
      case 'password':
      case 'apiKeyFormat':
      case 'apiKeyFormatValue':
        return true;
      default:
        return;
    }
  };

  const handleSelect = (field, selectedOption) => {
    const value = selectedOption;
    if (validate(field, value))
      props.onChange(
        key,
        _.update(_.cloneDeep(props.config), field, () => value)
      );
  };

  const handleChange = (event) => {
    const field = event.target.name;
    const { value } = event.target;
    if (validate(field, value))
      props.onChange(
        key,
        _.update(_.cloneDeep(props.config), field, () => value)
      );
  };

  const handleCheckChange = (event) => {
    const field = event.target.name;
    const newValue = !props.config[field];
    props.onChange(
      key,
      _.update(_.cloneDeep(props.config), field, () => newValue)
    );
  };

  const fillValuesForEditMode = () => {
    if (!_.isObject(config.payloadType)) {
      config.payloadType =
        availablePayloadTypes[
          _.findIndex(
            availablePayloadTypes,
            (_) => _.value === config.payloadType
          )
        ];
    }

    if (!_.isObject(config.protocol)) {
      config.protocol =
        availableProtocolTypes[
          _.findIndex(
            availableProtocolTypes,
            (_) => _.value === config.protocol
          )
        ];
    }

    if (!_.isObject(config.authorizationType)) {
      config.authorizationType =
        availableAuthorizationTypes[
          _.findIndex(
            availableAuthorizationTypes,
            (_) => _.value === config.authorizationType
          )
        ];
    }

    if (config?.authorizationType?.value === "API_KEY" && !_.isObject(config.apiKeyFormat)) {
      config.apiKeyFormat =
        availableApiKeyFormat[
          _.findIndex(
            availableApiKeyFormat,
            (_) => _.value === config.apiKeyFormat
          )
        ];
    }
  };

  if (props.editMode) {
    fillValuesForEditMode();
  }

  return (
    <div>
      <MultiSelect
        required
        name="payloadType"
        label="Payload Type"
        isMulti={false}
        buttonPlaceholder="Select a Payload type"
        options={filteredAailablePayloadTypes}
        onChange={handleSelect}
        values={config.payloadType}
      />
      <MultiSelect
        required
        name="protocol"
        label="Protocol"
        isMulti={false}
        buttonPlaceholder="Select a Protocol type"
        options={availableProtocolTypes}
        onChange={handleSelect}
        values={config.protocol}
      />
      <TextField
        required
        name="endPoint"
        label="Endpoint"
        value={config.endPoint}
        onChange={handleChange}
      />
      <Checkbox
        name="allowSelfSignedSsl"
        label={
          <span style={{ fontSize: '12px' }}>
            Allow Self-Sign SSL Certificate for the endpoint
          </span>
        }
        checked={config.allowSelfSignedSsl}
        onChange={handleCheckChange}
      />
      <MultiSelect
        required
        name="authorizationType"
        label="Authorization"
        isMulti={false}
        buttonPlaceholder="Select an Authorization"
        options={availableAuthorizationTypes}
        onChange={handleSelect}
        values={config.authorizationType}
      />
        { props?.config?.authorizationType?.value === "BASIC" &&
        <div>
            <TextField
                required
                name="username"
                label="Username"
                value={config.username}
                onChange={handleChange}
            />
            <PasswordField
                required
                name="password"
                label="Password"
                value={config.password}
                onChange={handleChange}
            />
        </div>
        }
        { props?.config?.authorizationType?.value === "API_KEY" &&
        <div>
            <MultiSelect
                required
                name="apiKeyFormat"
                label="Format"
                isMulti={false}
                buttonPlaceholder="Select an API format"
                options={availableApiKeyFormat}
                onChange={handleSelect}
                values={config.apiKeyFormat}
            />
            <TextField
                required
                name="apiKeyFormatValue"
                label="Format Value"
                value={config.apiKeyFormatValue}
                onChange={handleChange}
            />
        </div>
        }
    </div>
  );
};
