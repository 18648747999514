import React, { useCallback } from 'react';
import Form from './Form';
import { getActiveModalAtom } from 'atoms/modals';
import { useRecoilState } from 'recoil';
import Modal from 'components/UI/Modal';
import { IZScanApp } from 'components/main/ZScanApps/models';
import { IintegrationConfiguration } from './models';

export const CreateTicket_TITLE = 'CreateTicket';
const activeModalAtom = getActiveModalAtom<IZScanApp>();

interface ICreateTicketProps {
  configurations: IintegrationConfiguration[];
  requestToCreateTicket: (integrationConfigId: string) => Promise<void>;
  clearSelectedRows: any;
}

const CreateTicket: React.FC<ICreateTicketProps> = ({
  configurations = [],
  requestToCreateTicket,
  clearSelectedRows,
}) => {
  const [activeModal, setActiveModal] = useRecoilState(activeModalAtom);
  const handleClose = useCallback(() => {
    if (activeModal) {
      setActiveModal(undefined);
    }
  }, [setActiveModal, activeModal]);

  const handleSubmit = useCallback(
    async (values) => {
      const cinfigrationID: string = values.issueType.configId;
      requestToCreateTicket(cinfigrationID);
      clearSelectedRows();
      setActiveModal(undefined);
    },
    [clearSelectedRows, requestToCreateTicket, setActiveModal]
  );

  const projectOptionsMap: {
    [key: string]: { configId: string; label: string; value: string };
  } = {};
  const allIssueOptions: {
    configId: string;
    label: string;
    value: string;
    projectId: string;
  }[] = [];

  configurations.forEach((config) => {
    // Generate unique key for project
    const projectKey = `${config.projectId}-${config.projectName}`;

    // Add project to projectOptionsMap if not already present
    if (!projectOptionsMap[projectKey]) {
      projectOptionsMap[projectKey] = {
        configId: config.id,
        label: config.projectName,
        value: config.projectId,
      };
    }

    // Add issue type to allIssueOptions
    allIssueOptions.push({
      configId: config.id,
      label: config.issueType,
      value: config.issueTypeId,
      projectId: config.projectId,
    });
  });

  // Convert projectOptionsMap to array
  const projectOptions = Object.values(projectOptionsMap);

  return (
    <Modal
      caption={'Create Ticket'}
      onClose={handleClose}
      scrollable={true}
      title={CreateTicket_TITLE}
    >
      <Form
        handleClose={handleClose}
        handleCreateSubmit={handleSubmit}
        projectOptions={projectOptions}
        issueTypeOptions={allIssueOptions}
      />
    </Modal>
  );
};

export default CreateTicket;
