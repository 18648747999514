import React, { Component, Fragment } from 'react';

// components
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Grid } from '@material-ui/core';
import { CopyField } from '../inputs/index';
import ZButton from 'UI/Buttons/ZButton';

// ui
import {
  openSnackBarDiffered,
  toggleModalDiffered,
} from '../../utils/storeUtils';

class ApiSecretDisplayModal extends Component {
  render() {
    const { props } = this;

    return (
      <Fragment>
        <DialogContent>
          <Grid item xs>
            <CopyField
              label="Client ID"
              value={props.data.id}
              onCopy={openSnackBarDiffered('Client ID copied to clipboard')}
            />
          </Grid>
          <Grid item xs>
            <CopyField
              label="Client Secret"
              value={props.data.secret}
              onCopy={openSnackBarDiffered('Client Secret copied to clipboard')}
              helperText={
                <Grid item md={8}>
                  Make sure you copy your new api access key. You will not be
                  able to see it again.
                </Grid>
              }
            />
          </Grid>
          <DialogActions>
            <ZButton
              styleName="modalCancel"
              action={toggleModalDiffered('AuthorizationsSecretDisplay', false)}
              color="secondary"
              buttonText="Close"
            />
          </DialogActions>
        </DialogContent>
      </Fragment>
    );
  }
}

ApiSecretDisplayModal.defaultProps = {
  data: {},
};

export default ApiSecretDisplayModal;
