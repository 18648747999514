import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { APP_ENV } from 'config/appConfig';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import pkg from '../package.json';

if (APP_ENV !== 'local') {
  Sentry.init({
    dsn: 'https://c005bfa80b9f459885fab1c4da208427@sentry.io/24417',
    release: pkg.version,
    environment: APP_ENV,
    attachStacktrace: true,
  });
}

/* ts-lint-disable import/first */
import App from './App';
import 'typeface-roboto';
import 'typeface-roboto-mono';
import 'typeface-oswald';
import './index.css';
// import i18n (needs to be bundled ;))
import './i18n';

ReactDOM.render(<App />, document.getElementById('root'));
unregisterServiceWorker();
