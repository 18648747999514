import GenericConfirm from 'components/modals/GenericConfirm';
import Checkbox from 'components/UI/input/Checkbox';
import React, { useState } from 'react';
import { IGenericReminderData } from './models';
import useStyles from './useStyles';

interface IGenericReminderContentProps {
  doNotRemindText: string;
  headerText: string;
  messageText: string;
  reminderToBeHidden: boolean;
  onToggleReminderToBeHidden: () => void;
}

interface IGenericReminderProps {
  data: IGenericReminderData;
}

const GenericReminderContent: React.FC<IGenericReminderContentProps> = ({
  doNotRemindText,
  headerText,
  messageText,
  reminderToBeHidden,
  onToggleReminderToBeHidden,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h1 className={classes.headerText}>{headerText}</h1>
      </div>
      <div className={classes.content}>
        <p className={classes.messageText}>{messageText}</p>
        <Checkbox
          color="primary"
          label={doNotRemindText}
          value={reminderToBeHidden}
          onClick={onToggleReminderToBeHidden}
        />
      </div>
    </div>
  );
};

const GenericReminder: React.FC<IGenericReminderProps> = ({
  data: reminderData,
}) => {
  const {
    doNotRemindText,
    headerText,
    messageText,
    onConfirm,
    onDisableReminder,
  } = reminderData;

  const [reminderToBeHidden, setReminderToBeHidden] = useState(false);
  const toggleReminderToBeHidden = () =>
    setReminderToBeHidden(!reminderToBeHidden);

  const handleConfirm = () => {
    // If reminder seleted to be hidden, disable it when confirmed
    if (reminderToBeHidden) {
      onDisableReminder();
    }
    onConfirm();
  };

  const data = {
    ...reminderData,
    onConfirm: handleConfirm,
    content: (
      <GenericReminderContent
        doNotRemindText={doNotRemindText}
        headerText={headerText}
        messageText={messageText}
        reminderToBeHidden
        onToggleReminderToBeHidden={toggleReminderToBeHidden}
      />
    ),
  };

  return <GenericConfirm data={data} />;
};

export default GenericReminder;
