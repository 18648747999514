const Languages = [
  {
    label: 'Arabic',
    value: 'ara',
  },
  {
    label: 'Bengali',
    value: 'ben',
  },
  {
    label: 'German',
    value: 'deu',
  },
  {
    label: 'English',
    value: 'eng',
  },
  {
    label: 'Spanish',
    value: 'spa',
  },
  {
    label: 'French',
    value: 'fra',
  },
  {
    label: 'Hebrew',
    value: 'heb',
  },
  {
    label: 'Haitian Creole',
    value: 'hat',
  },
  {
    label: 'Japanese',
    value: 'jpn',
  },
  {
    label: 'Korean',
    value: 'kor',
  },
  {
    label: 'Dutch',
    value: 'dum',
  },
  {
    label: 'Polish',
    value: 'pol',
  },
  {
    label: 'Russian',
    value: 'rus',
  },
  {
    label: 'Urdu',
    value: 'urd',
  },
  {
    label: 'Vietnamese',
    value: 'vie',
  },
  {
    label: 'Chinese Simplified',
    value: 'zho',
  },
  {
    label: 'Chinese Traditional',
    value: 'znd',
  },
];

export default Languages;
