import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    popper: {
      '& .MuiPaper-root': {
        border: '0px',
        marginBottom: '-5px',
        marginTop: '-5px',
        overflow: 'visible',
      },
      '& .MuiPaper-elevation1': {
        boxShadow: '0 0',
      },
      '& .MuiAutocomplete-listbox': {
        border: '0x',
        height: '230px',
        marginBottom: '5px',
        marginTop: '5px',
        maxHeight: '230px',
        minHeight: '230px',
        overflow: 'visible',
      },
      '& .MuiAutocomplete-popper': {
        marginTop: '4px',
        width: '852px',
      },
    },
  })
);

export default useStyles;
