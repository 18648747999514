import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    accordion: {
      boxShadow: 'none',
      margin: '10px -10px 0 -10px !important',
    },
    accordionHeader: {
      backgroundColor: palette.tables.header.background,
      border: `1px solid ${palette.tables.border}`,
      color: palette.white,
      minHeight: 'none',
      '& .MuiAccordionSummary-expandIcon': {
        '&.Mui-expanded': {
          transform: 'rotate(90deg)',
        },
      },
      '& svg': {
        width: 14,
      },
    },
    permissionsAccordionHeader: {
      backgroundColor: palette.tables.header.background,
      border: `1px solid ${palette.tables.border}`,
      color: palette.white,
      fontWeight: 'bold',
      minHeight: 'none',
      '& .MuiAccordionSummary-expandIcon': {
        '&.Mui-expanded': {
          transform: 'rotate(90deg)',
        },
      },
      '& svg': {
        width: 14,
      },
    },

    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
    },
    details: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: 12,
      padding: 15,
    },
    detailsTable: {
      fontSize: 13,
      padding: 15,
    },
    detailsTableWithPadding: {
      '& tr': {
        '& td:first-child': {
          paddingRight: 15,
          color: palette.text.secondary,
        },
      },
    },
    cvesDetail: {
      paddingTop: '10px',
      fontSize: '16px',
      fontWeight: 500,
      alignSelf: 'center',
      color: palette.text.secondary,
    },
    riskTitle: {
      color: palette.text.secondary,
      marginRight: '40px',
    },
    permissions: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: 12,
      padding: 15,
      '& div': {
        marginBottom: 5,
      },
    },
    riskDetailsAccordion: {
      display: 'flex',
      alignItems: 'center',
    },
    heading: {
      paddingRight: 5,
      color: palette.text.secondary,
    },
    risk: {
      '& span': {
        margin: '0 10px',
      },
      alignItems: 'center',
      alignSelf: 'baseline',
      display: 'flex',
      textTransform: 'capitalize',
    },
    headerRisk: {
      '& span': {
        margin: '0 0px',
      },
      alignItems: 'center',
      alignSelf: 'baseline',
      display: 'flex',
      textTransform: 'capitalize',
    },
    severityStyle: {
      display: 'flex',
    },
    rows: {
      '& div': {
        marginBottom: 5,
      },
      '& div:last-child': {
        marginBottom: 0,
      },
    },
    summary: {
      alignItems: 'flex-end',
      display: 'flex',
      flexWrap: 'nowrap',
      fontSize: 14,
      justifyContent: 'space-between',
      marginTop: 10,
      '& span': {
        display: 'inline-flex',
        fontWeight: 'bold',
      },
    },
    summaryAssessment: {
      display: 'flex',
      gap: '5px',
      flexDirection: 'column',
      textTransform: 'capitalize',
      '& span': {
        marginRight: 10,
        color: palette.text.secondary,
      },
      '& span:last-child': {
        color: palette.text.primary,
        fontWeight: 'normal',
      },
    },
    classificationHeader: {
      display: 'flex',
      gap: '5px',
      flexDirection: 'column',
      textTransform: 'capitalize',
      '& span': {
        marginRight: 10,
        color: palette.text.secondary,
      },
    },

    malicious: {
      alignItems: 'center',
      backgroundColor: palette.error.medium,
      border: `1px solid ${palette.tables.border}`,
      borderRadius: 100,
      color: 'white',
      display: 'flex',
      fontSize: 11,
      padding: '2px 8px',
      textTransform: 'uppercase',
    },
  })
);

export default useStyles;
