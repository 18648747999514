import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ config, palette }: any) =>
  createStyles({
    mainTitle: {
      color: palette.text.secondary,
      backgroundColor: palette.background.default,
      border: `1px solid ${config.colors.darkGreySoft}`,
      textTransform: 'none',
    },
    tableContainer: {
      backgroundColor: palette.background.default,
      '& th': {
        fontWeight: 800,
        color: palette.text.secondary,
        borderBottom: 'none',
        paddingTop: '15px',
        paddingLeft: '20px',
        fontSize: '12px',
      },
      '& td': {
        borderBottom: 'none',
        padding: '0 0 5px 20px',
        fontSize: '12px',
        '& p': {
          margin: 0,
        },
        '& span': {
          margin: 0,
          paddingTop: '4px',
          paddingLeft: '5px',
          display: 'flex',
          alignItems: 'center',
          padding: 0,
          '& p': {
            margin: 0,
            paddingTop: '0',
            paddingLeft: '5px',
          }
        },
        '& a': {
          color: '#2EBDBD',
        },
      },
      '& .MuiTableBody-root > tr:last-child td': {
        paddingBottom: '20px',
      },
    },
    osIcon: {
      height: '23px',
      width: '20px',
    },
    withIcon: {
      display: 'flex',
      alignItems: 'center',
    },
    severityCell: {
      '& span': {
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        '& p': {
          margin: 0,
          paddingTop: '0',
          paddingLeft: '5px',
        },
      },
      '& svg': {
        color: '#F44336',
        margin: '0 5px 0 0',
        minHeight: 18,
        minWidth: 18,
        height: 18,
        width: 18,
      },
    },
    footer: {
      display: 'none',
    }
  })
);

export default useStyles;
