module.exports = {
  0: 'GLOBAL.ALERT_USER',
  1: 'GLOBAL.DISCONNECT_WIFI',
  2: 'GLOBAL.FILTER_SOURCE',
  3: 'GLOBAL.SILENT_ALERT',
  4: 'GLOBAL.ENABLE_ENTERPRISE_VPN',
  5: 'GLOBAL.ENFORCE_VVLAN',
  6: 'GLOBAL.WIPE_DEVICE',
  7: 'GLOBAL.RESTORE_FROM_BACKUP',
  8: 'GLOBAL.UNINSTALL_APPLICATION',
  9: 'GLOBAL.ISOLATE_DEVICE',
  10: 'GLOBAL.QUARANTINE_FILE',
  11: 'GLOBAL.NOP',
  12: 'GLOBAL.AW_COMPROMISED',
  13: 'GLOBAL.KILL_PROCESS',
  14: 'GLOBAL.ISOLATE_DEVICE_FROM_THE_NETWORK',
  15: 'GLOBAL.NETWORK_SINKHOLE',
  16: 'GLOBAL.DISABLE_BLUETOOTH',
  17: 'GLOBAL.KNOX_BLOCK_WIFI_SSID',
  18: 'GLOBAL.KNOX_BLOCK_BLUETOOTH',
  19: 'GLOBAL.KNOX_BLOCK_CELLULAR_DATA',
  20: 'GLOBAL.KNOX_BLOCK_ALL_NETWORK',
  21: 'GLOBAL.KNOX_BLOCK_PHONECALLS',
  22: 'GLOBAL.KNOX_BLOCK_SMS',
  23: 'GLOBAL.DISABLE_APP',
  24: 'GLOBAL.UNINSTALL_APP',
  25: 'GLOBAL.BLOCK_APP',
  26: 'GLOBAL.ALSO_USE_ANDROID_ACTIONS',
  27: 'GLOBAL.TUNNEL_UNSECURED_TRAFFIC',
  28: 'GLOBAL.KNOX_DATA_LOSS_PREVENTION',
  29: 'GLOBAL.REMOVE_FILE',
  30: 'GLOBAL.TERMINATE_PROCESS',
  31: 'GLOBAL.BLOCK_ANDROID_ACTIONS',
  32: 'GLOBAL.DISABLED_BY_TIME_PRESET',
  33: 'GLOBAL.UNINSTALL_EXTENSION',
};
