import Checkbox from 'components/UI/input/Checkbox';
import React, { useCallback } from 'react';
import { TClassList } from '../Table/models';
import { IGenericColumnHeader } from './models';
import useStyles from './useStyles';
import { useTranslation } from 'react-i18next';

interface IColumnCellProps {
  headerRow: IGenericColumnHeader;
  parentClasses?: TClassList;
}

const ColumnHeaderCell: React.FC<IColumnCellProps> = (props) => {
  const { headerRow, parentClasses } = props;
  const { ready, t } = useTranslation();
  const tableClasses = useStyles();
  const classes = { ...tableClasses, ...parentClasses };

  const handleSelectAllChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      headerRow: IGenericColumnHeader
    ) => {
      if (headerRow?.onCheckboxChange) {
        headerRow.onCheckboxChange(e, e.currentTarget.checked);
      }
    },
    []
  );
  if (!ready) {
    return null;
  }

  return (
    <>
      {!headerRow?.isSelectAllCheckbox && t(headerRow?.label ?? '')}
      {headerRow?.isSelectAllCheckbox && (
        <Checkbox
          color="primary"
          className={classes.headerCheckBox}
          onChange={(e) => handleSelectAllChange(e, headerRow)}
        />
      )}
    </>
  );
};

export default ColumnHeaderCell;
