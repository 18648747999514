import _ from 'lodash';

/**
 * Apply Mapping
 * @description Creates a dictionary based on a schema array
 * @param {(string|object)[]} Schema
 * @param {object} data
 * @param {boolean} allowUnknowns
 * @param {string[]} ignored
 * @param {function} callback - overwrite filtering of values out of final object
 */
export function applyMapping(
  Schema = [],
  data = {},
  allowUnknowns = false,
  ignored = [],
  callback
) {
  let schema = Schema;

  if (allowUnknowns) {
    const ignoredMapkeys = Schema.reduce((acc, val) => {
      return _.isObject(val) ? [...acc, val.path] : acc;
    }, ignored);

    schema = [...Schema, ..._.difference(Object.keys(data), ignoredMapkeys)];
  }

  return schema.reduce((final, _config) => {
    let config = _config;
    // normalize "path" param
    if (_.isString(config)) {
      config = { path: config };
    } else if (_.isUndefined(config.path)) {
      throw new Error(
        'You must provide a "path" key when using an object as the schema configuration'
      );
    }

    // key the value will be known as in output object
    const key = _.isFunction(config.key)
      ? config.key(data)
      : config.key || config.path;

    // final value
    const value = _.isFunction(config.transform)
      ? config.transform(data)
      : _.get(data, config.path);

    if (_.isFunction(callback)) {
      return callback(key, value, final, config);
    }

    if (config.preserveEmptyValue || !_.isUndefined(value)) {
      return { ...final, [key]: value };
    }

    return final;
  }, {});
}
