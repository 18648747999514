import { riskAssessment } from 'components/main/appsInventory/AppsInventoryTab/RiskScoreCell';
import { TFunction } from 'react-i18next';

export const showAffectedAppsHeader = [
  {
    id: 'name.sort',
    flex: 1,
    label: 'App Name',
    minWidth: 175,
    maxWidth: 500,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'version.keyword',
    label: 'Version',
    flex: 1,
    minWidth: 175,
    maxWidth: 500,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'privacyRisk',
    flex: 1,
    sort: false,
    label: 'Privacy Risk',
    minWidth: 125,
    maxWidth: 500,
    resizable: true,
    show: true,
    showInContextMenu: true,
  },
  {
    id: 'securityRisk',
    flex: 1,
    label: 'Security Risk',
    minWidth: 125,
    maxWidth: 500,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: false,
  },
];

export const showAppsAffectedRowMapping = (
  t: TFunction<'translation', undefined>
) => [
  {
    path: 'name.sort',
    columnContent: ({ rowData }: any) => <div>{rowData?.name}</div>,
  },
  {
    path: 'version.keyword',
    columnContent: ({ rowData }: any) => <div>{rowData?.version}</div>,
  },
  {
    path: 'privacyRisk',
    columnContent: ({ classList, rowData }: any) => (
      <div className={classList.risk}>
        {riskAssessment(rowData?.privacyRisk, t)}
      </div>
    ),
  },
  {
    path: 'securityRisk',
    columnContent: ({ classList, rowData }: any) => {
      return (
        <div className={classList.risk}>
          {riskAssessment(rowData?.securityRisk, t)}
        </div>
      );
    },
  },
];
