import { DialogActions } from '@material-ui/core';
import Button from 'components/UI/Button';
import Checkbox from 'components/UI/input/Checkbox';
import GenericGroup from 'components/UI/input/GenericGroup';
import InputError from 'components/UI/input/InputError';
import TextField from 'components/UI/input/TextField';
import { Field as FormikField, Form as FormikForm, Formik } from 'formik';
import { toggleModalDiffered } from 'utils/storeUtils';
import { initialValues } from './initialValues';
import { IDailySyncSchedule } from './models';
import Schema from './schema';
import useStyles from './useStyles';
import { useTranslation } from 'react-i18next';

interface IHourlyFormProps {
  data: Partial<IDailySyncSchedule>;
  handleSubmit: (schedule: IDailySyncSchedule) => void;
}
const weekdays = [
  { label: 'Monday', value: '2' },
  { label: 'Tuesday', value: '3' },
  { label: 'Wednesday', value: '4' },
  { label: 'Thursday', value: '5' },
  { label: 'Friday', value: '6' },
  { label: 'Saturday', value: '7' },
  { label: 'Sunday', value: '1' },
];

const DailyForm: React.FC<IHourlyFormProps> = ({ data, handleSubmit }) => {
  const resolvedInitialValues = initialValues(data);
  const { ready, t } = useTranslation();
  const classes = useStyles();

  if (!ready) {
    return null;
  }
  return (
    <Formik
      enableReinitialize
      initialValues={resolvedInitialValues}
      onSubmit={handleSubmit}
      validateOnBlur
      validationSchema={Schema()}
    >
      {({ isSubmitting, errors }) => (
        <FormikForm>
          <GenericGroup label={t('MTD.INTEGRATIONS.DAYS_OF_THE_WEEK')}>
            <div className={classes.dayGroup}>
              {weekdays.map(({ label, value }) => (
                <FormikField
                  color="primary"
                  component={Checkbox}
                  disabled={isSubmitting}
                  key={label}
                  label={t(`GLOBAL.WEEK.${label.toUpperCase()}`)}
                  name="days"
                  size="small"
                  type="checkbox"
                  value={value}
                />
              ))}
            </div>
          </GenericGroup>
          {!!errors?.days && (
            <InputError
              override={t('MTD.INTEGRATIONS.MUST_SELECT_AT_LEAST_ONE_DAY')}
            />
          )}
          <div className={classes.timeWrapper}>
            <span className={classes.timelabel}>
              {t('MTD.INTEGRATIONS.STARTING_AT')}
            </span>
            <div>
              <FormikField
                className={classes.timeInput}
                color="primary"
                component={TextField}
                disabled={isSubmitting}
                name="dailyStartTime"
                size="small"
                type="time"
              />
            </div>
          </div>
          <DialogActions>
            <Button
              color="secondary"
              onClick={toggleModalDiffered('EMMSyncSchedule', false)}
              text={t('GLOBAL.CANCEL')}
              type="button"
            />
            <Button
              color="primary"
              text={t('GLOBAL.SAVE')}
              type="submit"
            ></Button>
          </DialogActions>
        </FormikForm>
      )}
    </Formik>
  );
};
export default DailyForm;
