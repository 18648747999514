import React, { Fragment } from 'react';

// components
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import ZButton from 'UI/Buttons/ZButton';
import { toggleModalDirect } from '../../utils/storeUtils';
import { editModalConfig } from './AppsCreateEdit';

const AppsChangeEntitlements = ({ data }) => {
  return (
    <Fragment>
      <DialogTitle>Change Entitlements</DialogTitle>
      <DialogContent>
        <p>
          Changing entitlements would result in generating a new license key. Do
          you wish to continue ?
        </p>
        <DialogActions>
          <ZButton
            styleName="modalCancel"
            buttonText="No"
            color="secondary"
            action={() => onFailure(data)}
          />
          <ZButton
            buttonType="submit"
            styleName="modalDelete"
            buttonText="Yes"
            color="primary"
            action={() => onSuccess(data)}
          />
        </DialogActions>
      </DialogContent>
    </Fragment>
  );
};

const onFailure = props => {
  toggleModalDirect('AppsChangeEntitlements', false);
  toggleModalDirect(
    'AppsCreateEdit',
    {
      ...props.originalData,
      name: props.formValues.name,
      description: props.formValues.description,
      bundleId: props.formValues.bundleId,
      groupId: props.formValues.groupId.value,
      groupName: props.formValues.groupId.label,
      team: {
        id: props.formValues.teamId.value,
        name: props.formValues.teamId.label,
      },
      activationLimit: props.formValues.activationLimit,
      devModeEnabled: props.formValues.devModeEnabled,
      // TODO: FUTURE: might need to add callbackUrl & customHostname here (???)
    },
    editModalConfig
  );
};

const onSuccess = props => {
  toggleModalDirect('AppsChangeEntitlements', false);
  props.updateFnc();
};

AppsChangeEntitlements.defaultProps = {
  data: {},
};

export default AppsChangeEntitlements;
