import { createStyles, makeStyles } from '@material-ui/styles';

// Usage of 'any' inside this style decorator is discouraged; however, our theme
// currently does not conform to MUI's theme structure standards. So this will
// be a followup fix for later.
const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    container: {
      borderTopLeftRadius: '3px',
      borderTopRightRadius: '3px',
      margin: '0px -24px 0px -24px',
      padding: '0px',
      position: 'relative',
      top: '-20px',
      width: '500px',
    },
    content: {
      margin: '50px 30px 0px 30px',
      '& p': {
        marginBlockStart: '0px',
      },
    },
    header: {
      backgroundColor: palette.common.darkGreySoft,
      borderRadius: '3px',
      padding: '18px 0px 16px 0px',
      textAlign: 'center',
      width: '100%',
    },
    headerText: {
      color: palette.primary.contrastText,
      fontWeight: 400,
      margin: '0px',
      padding: '0px',
      paddingLeft: '22px',
      textTransform: 'uppercase',
      width: '100%',
    },
    messageText: {
      color: palette.text.secondary,
    },
  })
);

export default useStyles;
