import { IApp } from './models';
import { ISelectItem } from 'components/UI/input/Select';
import { fetchAllAppsByTeamId } from 'api/apis';

export const DEFAULT_APP_OPTION = { label: 'All', value: 'All' };

export const fetchAppsByTeamId = async (
  teamId: string
): Promise<ISelectItem[]> => {
  let apps: ISelectItem[] = [DEFAULT_APP_OPTION];
  try {
    const response = await fetchAllAppsByTeamId(teamId);
    if (response?.data?.[0]) {
      apps = [
        DEFAULT_APP_OPTION,
        ...response.data.map(({ id, name }: IApp) => ({
          label: name,
          value: id,
        })),
      ];
    }
  } catch (e) {
    console.log(e);
  }
  return apps;
};
