import { fetchAppPolicyParamsMapping } from 'components/main/policies/appPolicy/AppPolicy.mapping';
import { ServiceHandlerFactory } from 'utils/serviceUtils';

const PolicyBase = 'api/devices/v1';
const appCharacteristicsBase = '/api/devices/v1/app-triggers';
const affectedApps = 'api/devices/v1/appVersions/apps-by-trigger';
const affectedDevices = 'api/devices/v1/devices';

export const fetchAllAppPolicies = ServiceHandlerFactory({
  axios: {
    url: `${PolicyBase}/apps/policies/?teamId=:teamId`,
  },
});

export const fetchAppPolicyById = ServiceHandlerFactory({
  axios: {
    url: `${PolicyBase}/apps/policies/:id`,
  },
});

export const createAppPolicy = ServiceHandlerFactory({
  axios: {
    url: `${PolicyBase}/apps/policies/`,
    method: 'post',
  },
});

export const cloneAppPolicy = ServiceHandlerFactory({
  axios: {
    url: `${PolicyBase}/apps/policies/:policyId/clone`,
    method: 'post',
  },
});

export const updateAppPolicy = ServiceHandlerFactory({
  axios: {
    url: `${PolicyBase}/apps/policies/:id`,
    method: 'put',
  },
});

export const deleteAppPolicy = ServiceHandlerFactory({
  axios: {
    url: `${PolicyBase}/apps/policies/:id`,
    method: 'delete',
  },
});

export const fetchAppPolicyCriteria = ServiceHandlerFactory({
  axios: {
    url: `${PolicyBase}/apps/policies/:id/aggregate`,
    method: 'get',
  },
  paramSchema: fetchAppPolicyParamsMapping,
});

export function fetchTypes() {
  return new Promise((resolve) => {
    return resolve([
      {
        value: 'app',
        label: 'App',
      },
    ]);
  });
}

export function fetchCriteria() {
  return new Promise((resolve) => {
    return resolve([
      {
        value: 'package',
        label: 'Package',
      },
    ]);
  });
}

export function fetchRules() {
  return new Promise((resolve) => {
    return resolve([
      // TODO - NOT YET SUPPORTED
      // {
      //   value: 1,
      //   label: 'Allow',
      // },
      {
        value: 2,
        label: 'Deny',
      },
      // TODO - NOT YET SUPPORTED
      // {
      //   value: 3,
      //   label: 'OOC',
      // },
    ]);
  });
}

export const getAppCharacteristics = ServiceHandlerFactory({
  axios: {
    url: `${appCharacteristicsBase}`,
  },
  isCancelable: true,
});

export const getRuleEffectCount = ServiceHandlerFactory({
  axios: {
    url: `${appCharacteristicsBase}/rule-effect-count`,
    method: 'post',
  },
  isCancelable: true,
  paramSchema: [
    {
      path: 'teamId',
    },
  ],
});
export const getAffectedApps = ServiceHandlerFactory({
  axios: {
    url: affectedApps,
    method: 'post',
  },
  isCancelable: true,
  paramSchema: [
    {
      path: 'page',
    },
    {
      path: 'size',
    },
    {
      path: 'sort',
    },
    {
      path: 'search',
      transform: ({ search }) => (search ? `${search}*` : undefined),
    },
  ],
});
export const getAffectedDevicesByApps = ServiceHandlerFactory({
  axios: {
    url: `${affectedDevices}/devices-by-app?module=ZIPS`,
    method: 'post',
  },
  isCancelable: true,
  paramSchema: [
    {
      path: 'page',
    },
    {
      path: 'size',
    },
    {
      path: 'sort',
    },
    {
      path: 'search',
      transform: ({ search }) => (search ? `${search}*` : undefined),
    },
    {
      path: 'teamId',
    },
  ],
});
export const getAffectedDevicesByTriggers = ServiceHandlerFactory({
  axios: {
    url: `${affectedDevices}/devices-by-trigger?module=ZIPS`,
    method: 'post',
  },
  isCancelable: true,
  paramSchema: [
    {
      path: 'page',
    },
    {
      path: 'size',
    },
    {
      path: 'sort',
    },
    {
      path: 'search',
      transform: ({ search }) => (search ? `${search}*` : undefined),
    },
    {
      path: 'teamId',
    },
  ],
});
