import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    icon: {
      backgroundColor: palette.common.white,
      borderRadius: 1000,
      fill: 'currentColor',
      margin: 0,
      minHeight: '16px',
      minWidth: '16px',
      padding: 0,
    },
  })
);

export default useStyles;
