import { ISelectRuleContent } from "./models";

const removeEntryFromObj = (originObj: ISelectRuleContent) => {
  return Object.keys(originObj)
    .filter((key) => !!originObj[key]?.length)
    .reduce((obj: ISelectRuleContent, key: string) => {
      obj[key] = originObj[key];
      return obj;
    }, {});
};

export default removeEntryFromObj;
