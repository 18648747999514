import { default as MUITypography } from '@material-ui/core/Typography';
import Button from 'components/UI/Button';
import TextField from 'components/UI/input/TextField';
import { Field as FormikField, Form as FormikForm, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import initialValues from './initialValues';
import Schema from './schema';
import useStyles from './useStyles';

interface IFormProps {
  handleSubmit: any; //() => void;
}

const Form: React.FC<IFormProps> = ({ handleSubmit }) => {
  const { t, ready } = useTranslation();
  const classes = useStyles();
  const resolvedInitialValues = initialValues();

  return !ready ? null : (
    <Formik
      enableReinitialize
      initialValues={resolvedInitialValues}
      onSubmit={handleSubmit}
      validateOnBlur
      validationSchema={Schema(t)}
    >
      {({ errors, values, handleSubmit: handleFormikSubmit, isSubmitting }) => (
        <div>
          <MUITypography
            // align="left"
            className={classes.textBody}
            variant="body2"
          >
            {t('STC.ENTERDETAILS')}
          </MUITypography>
          <FormikForm className={classes.form}>
            <FormikField
              component={TextField}
              disabled={isSubmitting}
              label={t('STC.EMAIL')}
              name="email"
              errorClass={!!errors?.email && classes.errorOutline}
            />
            <FormikField
              component={TextField}
              label={t('STC.PHONENUMBER')}
              name="phoneNumber"
              disabled={isSubmitting}
              errorClass={!!errors?.phoneNumber && classes.errorOutline}
            />
            <div className={classes.spacer} />
            <Button
              color="secondary"
              text={t('STC.BUTTONTEXTNEXT')}
              disabled={
                isSubmitting ||
                !!errors?.email ||
                !!errors?.phoneNumber ||
                !values.email ||
                !values.phoneNumber
              }
              onClick={() => handleFormikSubmit()}
            />
          </FormikForm>
        </div>
      )}
    </Formik>
  );
};

export default Form;
