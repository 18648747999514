import React from 'react';
import _ from 'lodash';
import { PasswordField } from 'components/inputs';
import { FormError } from './FormError';

export const FormikPasswordField = ({ field, form, ...props }) => {
  return (
    <div style={{ position: 'relative' }}>
      <PasswordField {...field} {...props} />
      {form.touched[field.name] && form.errors[field.name] && (
        <FormError
          field={field}
          form={form}
          classlist={{ errors: _.get(props, 'classlist.errorClass') }}
        />
      )}
    </div>
  );
};
