import MUIAddIcon from '@material-ui/icons/Add';
import MUIDeleteIcon from '@material-ui/icons/Delete';
import { default as MUIDoneIcon } from '@material-ui/icons/Done';
import MUIEditIcon from '@material-ui/icons/Edit';
import { headerActions as headerActionsAtom } from 'atoms/header';
import { getActiveModalAtom } from 'atoms/modals';
import { deployDateAtom } from 'atoms/policies';
import { GenericPromptModal_TITLE } from 'components/UI/Modals/GenericPrompt';
import { IGenericPromptModalData } from 'components/UI/Modals/GenericPrompt/models';
import ViewSelector from 'components/UI/ViewSelector';
import AlertIcon from 'components/UI/icons/AlertIcon';
import CopyIcon from 'components/UI/icons/CopyIcon';
import { ISelectItem } from 'components/UI/input/Select';
import GlobalIndicatorWrapper from 'components/main/common/GlobalIndicatorWrapper';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import useAsyncResult from 'utils/useAsyncResult';
import { selectedTeam as selectedTeamAtom } from '../../../../atoms/teams';
import AddClonePolicy, {
  AddClonePolicy_TITLE,
} from '../appPolicy/AddClonePolicy';
import { IAppPolicyCreateModalData } from '../appPolicy/AddClonePolicy/models';
import ConfirmDeleteMessage from '../common/ConfirmDeleteMessage';
import CustomPolicyOption from '../common/CustomPolicyOption';
import LastDeployedBox from '../common/LastDeployedBox';
import Form from './Form';
import initialValues from './Form/initialValues';
import { IDormancyPolicyForm } from './Form/models';
import {
  dormancyChanges as dormancyChangesAtom,
  dormancyFormValid as dormancyFormValidAtom,
  preselectedPolicy as preselectedPolicyAtom,
} from './atoms';
import useStyles from './useStyles';
import {
  deletePolicy,
  fetchPolicies,
  fetchPolicyById,
  updatePolicy,
} from './utils';

interface IDormancyPolicyProps {
  location: {
    query: {
      dormancy: string;
    };
  };
}

const activeModalAtom = getActiveModalAtom<
  IGenericPromptModalData | IAppPolicyCreateModalData
>();

const DormancyPolicy: React.FC<IDormancyPolicyProps> = (props) => {
  const classes = useStyles();
  const { t, ready } = useTranslation();

  const selectedTeamId = useRecoilValue(selectedTeamAtom);
  const [reloadingPolicy, setReloadingPolicy] = useState<boolean>(false);
  const [dormancyChanges, setDormancyChanges] =
    useRecoilState(dormancyChangesAtom);
  const [dormancyFormValid, setDormancyFormValid] = useRecoilState(
    dormancyFormValidAtom
  );
  const setActiveModal = useSetRecoilState(activeModalAtom);
  const [clearedPreselectedPolicy, setClearedPreselectedPolicy] =
    useState<boolean>(false);
  const [preselectedPolicy, setPreselectedPolicy] = useRecoilState(
    preselectedPolicyAtom
  );

  const policies: ISelectItem[] | undefined = useAsyncResult(
    fetchPolicies,
    selectedTeamId || ''
  );

  const policyIds = useMemo(
    () => (policies?.[0] ? policies.map(({ value }) => value) : []),
    [policies]
  );

  const [selectedPolicy, setSelectedPolicy] = useState<ISelectItem | undefined>(
    !!preselectedPolicy ? preselectedPolicy : policies?.[0]
  );
  const setDeployDate = useSetRecoilState(deployDateAtom);
  const setHeaderActions = useSetRecoilState(headerActionsAtom);

  useEffect(() => {
    if (!clearedPreselectedPolicy && !!preselectedPolicy) {
      setPreselectedPolicy(undefined);
      setClearedPreselectedPolicy(true);
    }
  }, [
    clearedPreselectedPolicy,
    preselectedPolicy,
    setClearedPreselectedPolicy,
    setPreselectedPolicy,
  ]);

  useEffect(() => {
    if (
      policies?.[0] &&
      (!selectedPolicy?.value || !policyIds.includes(selectedPolicy.value))
    ) {
      const id = props.location.query.dormancy;
      const find = policies?.find((p) => p.value === id);

      if (find) {
        setSelectedPolicy(find);
      } else {
        setSelectedPolicy(policies?.[0]);
      }
    }
  }, [
    selectedPolicy,
    setSelectedPolicy,
    policies,
    policyIds,
    props.location.query.dormancy,
  ]);

  const policyDetails = useAsyncResult(
    fetchPolicyById,
    selectedPolicy?.value || null
  );
  setDeployDate(policyDetails?.modified);

  const resolvedInitialValues = initialValues(t, policyDetails); // This is outside Form to facilitate policy name editing

  const navigationPrompt = useMemo(
    () =>
      ({
        message = (
          <div className={classes.navigationWarning}>
            {t('MTD.POLICIES.APP_POLICY.POLICY_LEAVE_PAGE_CONFIRMATION', {
              policyName: 'device inactivity',
            })}
            <AlertIcon />
          </div>
        ),
        onCancelCaption = t('MTD.POLICIES.APP_POLICY.LOSE_CHANGES'),
        onConfirmCaption = t('MTD.POLICIES.APP_POLICY.STAY_HERE'),
      }: Partial<IGenericPromptModalData> = {}): Partial<
        Omit<IGenericPromptModalData, 'onCancel' | 'onConfirm'>
      > => ({
        title: t('MTD.POLICIES.APP_POLICY.PENDING_POLICY_UPDATE'),
        message: <span>{message}</span>,
        onCancelCaption,
        onConfirmCaption,
      }),
    [classes, t]
  );

  const handleViewSelectorChange = useCallback(
    async (name: string, value: ISelectItem | undefined) => {
      const changeSelectedPolicy = () => {
        setSelectedPolicy(value);
        setActiveModal(undefined);
      };

      if (dormancyChanges?.hasChanges) {
        setActiveModal({
          active: GenericPromptModal_TITLE,
          payload: {
            ...navigationPrompt(),
            onCancel: () => {
              changeSelectedPolicy();
            },
            onConfirm: () => {
              setActiveModal(undefined);
            },
          },
        });
      } else {
        setSelectedPolicy(value);
      }
    },
    [
      dormancyChanges?.hasChanges,
      navigationPrompt,
      setActiveModal,
      setSelectedPolicy,
    ]
  );

  const onDeleteSuccess = useCallback(() => {
    setSelectedPolicy(undefined);
    setDormancyChanges({ hasChanges: false });
    setDormancyFormValid(true);

    // We should revisit this with react-router-browser's history
    // object ASAP, as it might facilitate this action w/o the need
    // for this hackyness. - RL
    setTimeout(() => window.location?.reload(), 500);
  }, [setSelectedPolicy, setDormancyFormValid, setDormancyChanges]);

  const onCreateCloneSuccess = useCallback(
    (policy: ISelectItem) => {
      setClearedPreselectedPolicy(true);
      setPreselectedPolicy(policy);

      // Make sure the persistant atom is set before killing the process
      // We should revisit this with react-router-browser's history
      // object ASAP, as it might facilitate this action w/o the need
      // for this hackyness. - RL
      setTimeout(() => window.location?.reload(), 500);
    },
    [setClearedPreselectedPolicy, setPreselectedPolicy]
  );

  const onUpdateSuccess = useCallback(
    (policy: ISelectItem) => {
      setActiveModal({
        active: GenericPromptModal_TITLE,
        payload: {
          disableOnSubmit: true,
          title: t('MTD.POLICIES.APP_POLICY.DEPLOYED_POLICY'),
          message: (
            <div className={classes.policyDeployed}>
              <div>
                <MUIDoneIcon />
              </div>
              {t('MTD.POLICIES.APP_POLICY.YOUR_CHANGES_SAVED_THIS_POLICY')}
            </div>
          ),
          onCancelHidden: true,
          onCancel: () => {},
          onConfirm: () => {
            setDormancyChanges({ hasChanges: false });
            setClearedPreselectedPolicy(true);
            setPreselectedPolicy(policy);

            // Make sure the persistant atom is set before killing the process
            // We should revisit this with react-router-browser's history
            // object ASAP, as it might facilitate this action w/o the need
            // for this hackyness. - RL
            setTimeout(() => window.location?.reload(), 500);
          },
          onConfirmCaption: 'OK',
        },
      });
    },
    [
      classes?.policyDeployed,
      setActiveModal,
      setDormancyChanges,
      setPreselectedPolicy,
      t,
    ]
  );

  const handleAddClone = useCallback(
    (cloningPolicy?: string) => {
      setActiveModal({
        active: AddClonePolicy_TITLE,
        payload: {
          policyType: 'Dormancy',
          cloningPolicy,
          defaultName: cloningPolicy ? selectedPolicy?.label + ' (cloned)' : '',
          onCreateSuccess: () => {
            // We should revisit this with react-router-browser's history
            // object ASAP, as it might facilitate this action w/o the need
            // for this hackyness. - JT
            setTimeout(() => window.location?.reload(), 500);
          },
        },
      });
    },
    [setActiveModal, selectedPolicy]
  );

  const handleUpdateName = useCallback(
    async (formData: IDormancyPolicyForm, editedPolicyName?: string) => {
      updatePolicy(
        policyDetails,
        onCreateCloneSuccess,
        formData,
        editedPolicyName
      );
    },
    [policyDetails, onCreateCloneSuccess]
  );

  const handleUpdatePolicy = useCallback(
    async (formData: IDormancyPolicyForm, editedPolicyName?: string) => {
      updatePolicy(policyDetails, onUpdateSuccess, formData, editedPolicyName);
    },
    [policyDetails, onUpdateSuccess]
  );

  const handleEditNameClick = useCallback(() => {
    const editPolicyName = () => {
      setDormancyChanges({ hasChanges: false });
      setDormancyFormValid(true);
      setReloadingPolicy(true);

      setActiveModal({
        active: GenericPromptModal_TITLE,
        payload: {
          disableOnSubmit: true,
          title: t('MTD.POLICIES.APP_POLICY.EDIT_POLICY_NAME'),
          question: {
            label: t('MTD.POLICIES.APP_POLICY.POLICY_NAME'),
            defaultAnswer: selectedPolicy?.label,
          },
          onCancel: () => setActiveModal(undefined),
          onConfirm: async (answer) => {
            !!resolvedInitialValues &&
              (await handleUpdateName(resolvedInitialValues, answer));
            setClearedPreselectedPolicy(true);
            setPreselectedPolicy({
              label: answer,
              value: selectedPolicy?.value,
            } as ISelectItem);

            // Make sure the persistant atom is set before killing the process
            // We should revisit this with react-router-browser's history
            // object ASAP, as it might facilitate this action w/o the need
            // for this hackyness. - RL
            setTimeout(() => window.location?.reload(), 500);
          },
        },
      });
    };

    if (dormancyChanges?.hasChanges) {
      setActiveModal({
        active: GenericPromptModal_TITLE,
        payload: {
          ...navigationPrompt({
            onCancelCaption: t('GLOBAL.CONTINUE'),
            onConfirmCaption: t('GLOBAL.CANCEL'),
          }),
          onCancel: () => {
            editPolicyName();
          },
          onConfirm: () => {
            setActiveModal(undefined);
          },
        },
      });
    } else {
      editPolicyName();
    }
  }, [
    dormancyChanges?.hasChanges,
    handleUpdateName,
    navigationPrompt,
    resolvedInitialValues,
    setActiveModal,
    setDormancyChanges,
    setDormancyFormValid,
    setPreselectedPolicy,
    selectedPolicy?.label,
    selectedPolicy?.value,
    t,
  ]);

  const handleDeleteClick = useCallback(
    (policyId: string) => {
      setActiveModal({
        active: GenericPromptModal_TITLE,
        payload: {
          title: t('MTD.POLICIES.APP_POLICY.DELETE_POLICY'),
          message: (
            <ConfirmDeleteMessage
              groups={policyDetails?.groups}
              policyType="Dormancy"
            />
          ),
          onConfirmDisabled:
            policyDetails?.groups && policyDetails?.groups.length > 0,
          onCancel: () => {
            setActiveModal(undefined);
          },
          onConfirm: async () => {
            await deletePolicy(policyId, onDeleteSuccess);
          },
        },
      });
    },
    [policyDetails, setActiveModal, onDeleteSuccess, t]
  );

  useEffect(() => {
    if (dormancyChanges?.hasChanges) {
      setHeaderActions({
        clearNavigationPreventingChanges: () => {
          setDormancyChanges({ hasChanges: false });
          setDormancyFormValid(true);
        },
        preventNavigation: true,
        navigationPrompt: navigationPrompt({
          title: t('MTD.POLICIES.APP_POLICY.PENDING_POLICY_UPDATE'),
        }),
        buttons: [
          {
            className: classes.green,
            color: 'inherit',
            disabled: !dormancyFormValid,
            onClick: () =>
              !!dormancyChanges?.formData &&
              handleUpdatePolicy(dormancyChanges?.formData),
            text: t('MTD.POLICIES.APP_POLICY.DEPLOY_POLICY_CHANGES'),
          },
        ],
      });
    }

    return () => setHeaderActions(undefined);
  }, [
    classes.green,
    dormancyChanges?.formData,
    dormancyChanges?.hasChanges,
    dormancyFormValid,
    handleUpdatePolicy,
    navigationPrompt,
    setDormancyChanges,
    setDormancyFormValid,
    setHeaderActions,
    t,
  ]);

  if (!ready) {
    return null;
  }

  if (!policies || (policies.length > 0 && !selectedPolicy)) {
    return null;
  }

  return (
    <>
      <div style={{ height: '85px' }}>
        <GlobalIndicatorWrapper isGlobal inline />
        <ViewSelector
          interactable
          label={t('MTD.POLICIES.APP_POLICY.SELECT_POLICY')}
          placeholder={t('MTD.POLICIES.APP_POLICY.NO_POLICY_SELECTED')}
          setFieldValue={handleViewSelectorChange}
          customOption={CustomPolicyOption}
          deployDateComp={LastDeployedBox as React.FC}
          actions={[
            {
              onClick: handleEditNameClick,
              icon: MUIEditIcon,
              disabled: !policyDetails,
            },
            {
              onClick: () => handleAddClone(selectedPolicy?.value as string),
              icon: CopyIcon,
              disabled: !policyDetails,
            },
            {
              onClick: () => handleDeleteClick(policyDetails?.id),
              icon: MUIDeleteIcon,
              disabled: !policyDetails,
            },
            {
              onClick: () => handleAddClone(),
              icon: MUIAddIcon,
            },
          ]}
          options={policies}
          value={selectedPolicy}
        />
      </div>

      {!policyDetails && (
        <div className={classes.empty}>
          {t('MTD.POLICIES.APP_POLICY.SELECT_OR_ADD_POLICY')}
        </div>
      )}

      {!!policyDetails && (
        <div className={classes.formWrapper}>
          <Form
            reloadingPolicy={reloadingPolicy}
            resolvedInitialValues={resolvedInitialValues}
            setReloadingPolicy={setReloadingPolicy}
          />
        </div>
      )}
      <AddClonePolicy />
      {/* <DormancyCreateClone /> */}
    </>
  );
};

export default DormancyPolicy;
