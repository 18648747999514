import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    horizontalLegend: {
      flex: 0,
    },
    item: {
      alignItems: 'center',
      borderRight: `1px solid ${palette.text.primary}`,
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
      justifyContent: 'flex-start',
      padding: '0 25px',
      textAlign: 'center',
      whiteSpace: 'nowrap',

      '&:first-child': {
        paddingLeft: 0,
      },

      '&:last-child': {
        borderRight: 0,
        paddingRight: 0,
      },

      '& > a:link, & > a:active, > a:visited': {
        color: 'inherit',
      },

      '& > a:hover': {
        textDecoration: 'underline',
      },
    },
    legend: {
      alignItems: 'center',
      color: palette.text.primary,
      display: 'flex',
      fontSize: 14,
      justifyContent: 'center',
      width: '100%',
    },
    pip: {
      borderRadius: 12,
      display: 'inline-block',
      height: 12,
      marginRight: 10,
      width: 12,
    },
    value: {
      fontWeight: 'bold',
    },
  })
);

export default useStyles;
