import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import withRouter from 'components/hocs/withRouter';

// material ui
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Tooltip from '@material-ui/core/Tooltip';
import AndroidIcon from '@material-ui/icons/Android';
import Warning from '@material-ui/icons/Warning';

// components
import Spinner from 'components/main/common/Spinner';

// actions
import { inappsVersionsTableColumnChange } from 'actions/AppVersionsActions';
import { updateUISettings } from 'reducers/UiSettingsReducers';
import { downloadZappVersionsCsv } from 'api/InAppsService';

// selectors
import {
  getUISettingsTableHeaders,
  getChangedTableHeadersHash,
  getUISettingsWithout,
} from 'reducers/UiSettingsSelectors';
import ColumnSelector from 'utility/ColumnSelector';
import { fetchAppVersionsPage } from '../../../api/InAppsService';
import AppleIcon from '../../../UI/Icons/AppleIcon';

class VersionTableHead extends React.Component {
  constructor() {
    super();
    this.state = { anchorEl: null, columnFilter: false };
    this.handleClickColumn = this.handleClickColumn.bind(this);
    this.handleCloseColumnFilter = this.handleCloseColumnFilter.bind(this);
  }

  handleClickColumn(e) {
    this.setState({
      anchorEl: e.currentTarget,
      columnFilter: !this.state.columnFilter,
    });
  }

  handleCloseColumnFilter() {
    this.setState({
      anchorEl: null,
      columnFilter: false,
    });
  }

  static propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  createSortHandler = property => event => {
    if (property === 'appStatusIcon') {
      this.props.onRequestSort(event, 'zVersion');
    } else {
      this.props.onRequestSort(event, property);
    }
  };

  render() {
    const {
      order,
      orderBy,
      classes,
      currentTableHeaders,
      inappsVersionsTableColumnChange,
    } = this.props;

    return (
      <TableHead className={classes.tableHeader}>
        <TableRow>
          {currentTableHeaders.map(column => {
            if (column.show) {
              return (
                <TableCell
                  key={column.id}
                  padding="dense"
                  className={classes.tableHeader}
                >
                  <Tooltip title="Sort" enterDelay={300}>
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={order}
                      onClick={this.createSortHandler(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              );
            }
            return null;
          }, this)}
          <TableCell
            align="right"
            padding="none"
            classes={{ root: classes.tableHeaderCell }}
          >
            <ColumnSelector
              defaultOptions={currentTableHeaders}
              open={this.state.columnFilter}
              anchorEl={this.state.anchorEl}
              toOpen={e => this.handleClickColumn(e)}
              columnChange={inappsVersionsTableColumnChange}
              onClose={() => this.handleCloseColumnFilter()}
            />
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

let appleIconColor;
const styles = ({ palette, ...theme }) => {
  appleIconColor = palette.text.primary;

  return {
    osCell: {
      display: 'flex',
      alignItems: 'center',
    },

    tableWrapper: {
      overflowX: 'auto',
    },

    tableRow: {
      height: 40,
    },

    csvDownloadButton: {
      padding: 0,
    },

    osIcon: {
      marginRight: theme.spacing.unit,
      color: palette.text.primary,
      fontSize: '20px',
    },

    tableHeader: {
      fontSize: theme.config.textSizes.normal,
    },

    warningIcon: {
      color: palette.warning.main,
      paddingLeft: 5,
      paddingRight: 5,
    },

    zversionWrapper: {
      display: 'flex',
      alignItems: 'center',
    },

    appVersionIconLatest: {
      marginLeft: 5,
    },

    emptyText: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '5vh',
    },
  };
};

class VersionTable extends React.Component {
  constructor(props) {
    super(props);
    this.doFetch = this.doFetch.bind(this);
    this.handleRequestSort = this.handleRequestSort.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.state = {
      tableData: [],
      loader: false,
      count: 0,
    };
  }

  componentDidMount() {
    const { props } = this;
    if (_.isEmpty(props.appVersions)) {
      this.props.updateUISettings({
        domain: 'inappsVersions',
        update: {
          count: 0,
        },
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(prevProps.selectedApps, this.props.selectedApps) &&
      !_.isEmpty(this.props.selectedApps)
    ) {
      const { order, orderBy, size } = this.props.inappsVersionsUiSettings;
      this.doFetch(0, size, order, orderBy);
    }
    // checks for an empty selection(object)
    if (
      !_.isEqual(prevProps.selectedApps, this.props.selectedApps) &&
      _.isEmpty(this.props.selectedApps)
    ) {
      this.setState({ tableData: [] });
    }
  }

  doFetch(page, size, order, orderBy) {
    const appArray = Object.keys(this.props.selectedApps).map(appId => appId);

    let id = -1;
    if (!!appArray) {
      id = appArray;
    }

    let newOrderBy = orderBy;
    if (orderBy === 'name') newOrderBy = 'z.name';
    if (orderBy === 'bundle_id') newOrderBy = 'z.bundle_id';

    if (id !== -1) {
      this.setState({ loader: true });
      const zappId = id.join(',');
      fetchAppVersionsPage({
        zappId,
        page,
        size,
        sort: `${newOrderBy},${order}`,
      }).then(resp => {
        this.props.updateUISettings({
          domain: 'inappsVersions',
          update: {
            count: 0,
            order,
            orderBy,
            page,
            size,
          },
        });
        this.setState({
          tableData: resp.data.content,
          loader: false,
          count: resp.data.totalElements,
        });
      });
    }
  }

  handleRequestSort(event, property) {
    const { order, orderBy, size } = this.props.inappsVersionsUiSettings;
    let newOrder = 'asc';
    if (orderBy === property && order === 'asc') {
      newOrder = 'desc';
    }
    this.doFetch(0, size, newOrder, property);
  }

  handleChangePage(event, page) {
    const { order, orderBy, size } = this.props.inappsVersionsUiSettings;
    this.doFetch(page, size, order, orderBy);
  }

  handleChangeRowsPerPage(event) {
    const { order, orderBy } = this.props.inappsVersionsUiSettings;
    const update = {};
    update.size = event.target.value;
    this.props.updateUISettings({ domain: 'inappsVersions', update });
    this.doFetch(0, event.target.value, order, orderBy);
  }

  /*

* Styling for zVersion
*
* @const renderZVersion   this helper
*renders an lastest icon if latest build is true
*if the installation is  not the latest build AND the it is in use
* we serve a warning
*/
  renderZVersion = app => {
    const { classes } = this.props;
    if (app.latest_sdk) {
      return (
        <img
          className={classes.appVersionIconLatest}
          src="latest-label.svg"
          alt="latest"
        />
      );
    }
    if (!app.latest_sdk && app.mad > 0 && app.dad > 0) {
      return <Warning className={classes.warningIcon} />;
    }
    return <div />;
  };

  handleDownload(filename) {
    return () => {
      const { props } = this;

      const appArray = Object.keys(props.selectedApps).map(appId => appId);

      let id = -1;
      if (!!appArray && !_.isEmpty(appArray)) {
        id = appArray;
      }

      if (id !== -1) {
        const tableHeaders = props.currentTableHeaders
          .filter(tableHeader => {
            return tableHeader.show;
          })
          .map(tableHeader => tableHeader.id);

        const zappId = id.join(',');
        downloadZappVersionsCsv(
          {
            ...props.location.query,
            zappId,
            orderBy: props.inappsVersionsUiSettings.orderBy,
            order: props.inappsVersionsUiSettings.order,
          },
          tableHeaders
        )
          .then(resp => {
            const link = document.createElement('a');
            link.href = resp.data;
            link.setAttribute('download', `${filename}`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch(error => console.error(error));
      }
    };
  }

  render() {
    const {
      classes,
      inappsVersionsTableColumnChange,
      currentTableHeaders,
      currentTableHeadersHash,
    } = this.props;
    const { page, size, order, orderBy } = this.props.inappsVersionsUiSettings;

    let noData;
    if (
      !_.isEmpty(this.props.selectedApps) &&
      this.state.tableData.length === 0
    ) {
      noData = 'There is no data for selected app.';
    } else if (_.isEmpty(this.props.selectedApps)) {
      noData = 'There is no data, please select an app.';
    }
    return (
      <Card style={{ overflowX: 'auto' }}>
        <CardContent className={classes.cardContent}>
          <Table>
            <VersionTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              currentTableHeaders={currentTableHeaders}
              inappsVersionsTableColumnChange={inappsVersionsTableColumnChange}
            />
            <TableBody>
              {this.state.tableData.map((n, index) => {
                const os = n.os.toLowerCase() === 'ios' ? 'iOS' : 'Android';
                const icon =
                  n.os.toLowerCase() === 'ios' ? (
                    <div className={classes.osIcon}>
                      <AppleIcon color={appleIconColor} />
                    </div>
                  ) : (
                    <AndroidIcon className={classes.osIcon} />
                  );
                return (
                  <TableRow
                    key={`${n.hash} ${index}`}
                    className={classes.tableRow}
                  >
                    {currentTableHeadersHash.version.show && (
                      <TableCell padding="dense">{n.version}</TableCell>
                    )}
                    {currentTableHeadersHash.build.show && (
                      <TableCell padding="dense">{n.build}</TableCell>
                    )}
                    {currentTableHeadersHash.name.show && (
                      <TableCell padding="dense">{n.name}</TableCell>
                    )}
                    {currentTableHeadersHash.bundle_id.show && (
                      <TableCell padding="dense">{n.bundle_id}</TableCell>
                    )}
                    {currentTableHeadersHash.os.show && (
                      <TableCell>
                        <span className={classes.osCell}>
                          {icon} {os}
                        </span>
                      </TableCell>
                    )}
                    {currentTableHeadersHash.mad.show && (
                      <TableCell padding="dense">{n.mad}</TableCell>
                    )}
                    {currentTableHeadersHash.dad.show && (
                      <TableCell padding="dense">{n.dad}</TableCell>
                    )}
                    {currentTableHeadersHash.installation_units.show && (
                      <TableCell padding="dense">
                        {n.installation_units}
                      </TableCell>
                    )}
                    {currentTableHeadersHash.z_version.show && (
                      <TableCell padding="dense" style={{ paddingRight: 5 }}>
                        <div className={classes.zversionWrapper}>
                          {n.z_version} {this.renderZVersion(n)}
                        </div>
                      </TableCell>
                    )}
                    {currentTableHeadersHash.z_build.show && (
                      <TableCell
                        padding="dense"
                        style={{ marginRight: 'auto' }}
                      >
                        {n.z_build}
                      </TableCell>
                    )}
                    <TableCell padding="dense" />
                  </TableRow>
                );
              })}
              {this.state.tableData.length === 0 && (
                <TableRow className={classes.tableRow}>
                  <TableCell
                    padding="dense"
                    colSpan={currentTableHeaders.length + 1}
                  >
                    {this.state.loader ? (
                      <span className={classes.emptyText}>
                        <Spinner />
                      </span>
                    ) : (
                      <span className={classes.emptyText}>{noData}</span>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter className={classes.tableHeader}>
              <TableRow>
                <TableCell className={classes.csvDownloadButton}>
                  <CloudDownload
                    style={{ cursor: 'pointer', fontSize: 30 }}
                    onClick={this.handleDownload('inapps-versions-table.csv')}
                  />
                </TableCell>
                <TablePagination
                  count={this.state.count}
                  rowsPerPage={size}
                  rowsPerPageOptions={[50, 100, 500]}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </CardContent>
      </Card>
    );
  }
}

VersionTable.propTypes = {
  classes: PropTypes.object.isRequired,
  inappsVersionsUiSettings: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    currentTableHeaders: getUISettingsTableHeaders(state, 'inappsVersions'),
    currentTableHeadersHash: getChangedTableHeadersHash(
      state,
      'inappsVersions'
    ),
    inappsVersionsUiSettings: getUISettingsWithout(state, 'inappsVersions', [
      'tableHeaders',
    ]),
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      inappsVersionsTableColumnChange,
      updateUISettings,
    },
    dispatch
  );
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(VersionTable)
  )
);
