import AT from 'actions/ActionTypes';

const accountDefault = {
  name: '',
  firstName: '',
  lastName: '',
  contactEmail: '',
  contactPhone: '',
  address: '',
  zipcode: '',
  country: '',
  plans: [],
};

export const account = (state = accountDefault, action) => {
  switch (action.type) {
    case AT.FETCH_ACCOUNT_SUCCEEDED:
      return action.payload.account;
    
    case AT.SET_ACCOUNT:
      return {
        ...state,
        plans: action.payload.subscription.plans
      };

    case AT.UPDATE_ACCOUNT_SUCCEEDED:
      return {
        ...state,
        name: action.payload.name,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        address: action.payload.address,
        city: action.payload.city,
        zipCode: action.payload.zipCode,
        country: action.payload.country,
        contactEmail: action.payload.contactEmail,
        contactPhone: action.payload.contactPhone,
      };

    default:
      return state;
  }
};
