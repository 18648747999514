import MUIListSubheader from '@material-ui/core/ListSubheader';
import MUIMenuItem from '@material-ui/core/MenuItem';
import { ITableActionGroup } from './TableAction';
import { useTranslation } from 'react-i18next';

export const RenderSelectGroup = (group: ITableActionGroup) => {
  const { t } = useTranslation();

  const items = group.actions.map((action) => (
    <MUIMenuItem
      value={action.value}
      style={{ marginLeft: '15px' }}
      key={action.value}
      disabled={action.isDisabled}
    >
      {t(action.label)}
    </MUIMenuItem>
  ));

  return [<MUIListSubheader>{t(group.group)}</MUIListSubheader>, items];
};
