import { DrawerContext } from 'components/drawer/DrawerProvider';
import { IDrawerContext } from 'components/drawer/DrawerProvider/models';
import withRouter from 'components/hocs/withRouter';
import Table from 'components/UI/Table';
import {
  IColumnHeader,
  IFilterEditor,
  ILocationQuery,
} from 'components/UI/Table/models';

import { availableTeams as availableTeamsAtom } from 'atoms/teams';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  buildsAppsTableColumnChange as zScanTableColumnChange,
  updateUISettings,
} from 'reducers/UiSettingsReducers';
import {
  getUISettingsTableHeaders,
  getUISettingsWithout,
} from 'reducers/UiSettingsSelectors';
import { bindActionCreators, compose } from 'redux';
import useBackfill from 'utils/useBackfill';
import { withDirtyState } from 'utils/withDirtyState';
import withMemoizedProps from 'utils/withMemoizedProps';
import { zScanAppsColumnMapping, resolveFilters } from './zScanApps.mappings';
import Drawer from './drawer';
import { IFilterValues, ILocation, IQueryParams, IZScanApp } from './models';
import tableQuery from './customTableQuery';
import useStyles from './useStyles';
import { INITIAL_FILTER_VALUES } from './utils';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import ReactToCSV from 'utility/ReactCSVDownloader';
import MUICard from '@material-ui/core/Card';
import { getAppsById } from 'api/zDevService';
import GenericCard from 'components/UI/GenericCard';
import TableActions from './TableActions';
import { TableAction } from './TableAction';
import UploadAppButton from './UploadAppButton';
import ZScanUploadApp from 'components/modals/ZScanUploadApp';
import ZScanUploadComplete from 'components/modals/ZScanUploadComplete';
import { IZScanAppsDrawerData } from './drawer/models';
import ZScanUploadEdit from 'components/modals/ZScanAppEdit';
import { default as OldSearchBox } from '../common/SearchBox';
import { useRecoilValue } from 'recoil';

const SearchBox: any = OldSearchBox;
interface IAppsProps {
  location: ILocation;
  zScanTableColumnChange: (...args: unknown[]) => void;
  zScanAppsUiSettings: { [key: string]: unknown };
  currentTableHeaders: IColumnHeader[];
  definedUser?: string;
  jiggleDirtyState: (...args: any) => void;
  q: { [key: string]: unknown };
  rqps: string[];
  scopes: { [key: string]: string[] };
  updateUISettings: (...args: unknown[]) => void;
  updateUrl: (params: IQueryParams) => void;
  scopeBounds: string;
}

const Apps: React.FC<IAppsProps> = ({
  zScanTableColumnChange,
  zScanAppsUiSettings,
  currentTableHeaders,
  definedUser,
  jiggleDirtyState,
  q: query,
  location,
  rqps,
  updateUISettings,
  updateUrl,
  // scopeBounds,
}) => {
  useBackfill({
    query,
    requiredQueryParams: rqps,
    storedQueryParams: zScanAppsUiSettings,
    updateUrl,
  });

  const availableTeams = useRecoilValue(availableTeamsAtom) || [];

  const [selectedTableAction, setSelectedTableAction] = useState<TableAction>();
  const [showEmptyPage, setShowEmptyPage] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState<IFilterValues>(
    INITIAL_FILTER_VALUES
  );
  const filters = resolveFilters(filterValues);
  const { closeDrawer = null, openDrawer } =
    useContext<Partial<IDrawerContext<IZScanAppsDrawerData>>>(DrawerContext);

  const classes = useStyles();

  const handleColumnChange = (...args: unknown[]) => {
    zScanTableColumnChange(...args);
    jiggleDirtyState();
  };

  const onGoClick = useCallback((): void => {
    switch (selectedTableAction) {
      case TableAction.DeleteSelected: {
        // const newData = data.filter((el) => !el.selected);
        // setData(newData);
        setSelectedTableAction(undefined);
        break;
      }
      default:
        break;
    }
  }, [selectedTableAction]);

  const handleOpenDrawer = async (rowProps: { data: IZScanApp }) => {
    const appDataById = await getAppsById({ appId: rowProps.data.id });
    if (!openDrawer) {
      return;
    }
    openDrawer({
      drawerProps: {
        rowData: appDataById?.data,
        module: 'ZDEV',
        classList: '',
      },
      drawer: Drawer,
    });
  };

  const reloadTable = useCallback(() => {
    jiggleDirtyState(); // This will force a reload without relying on the Table component.
    if (typeof closeDrawer === 'function') {
      closeDrawer();
    }
  }, [jiggleDirtyState, closeDrawer]);

  useEffect(() => {
    window.addEventListener(`zScanApps:reload-tables`, reloadTable);
    return () => {
      window.removeEventListener(`zScanApps:reload-tables`, reloadTable);
    };
  }, [reloadTable]);

  const onPaginationChange = useCallback(
    (update: IQueryParams) => {
      updateUISettings({
        domain: 'zScanApps',
        update,
      });

      updateUrl(update);
    },
    [updateUrl, updateUISettings]
  );

  const handleFilterChange = useCallback((filterEditors: IFilterEditor[]) => {
    const newFilterValues: IFilterValues = {};
    filterEditors.forEach(({ name, value }) => {
      newFilterValues[name] = value;
    });
    setFilterValues(newFilterValues);
  }, []);

  return (
    <>
      <div className="view__header">
        <h1>Apps</h1>
        <ProtectedComponent allow={{ zdev_builds: 'upload' }}>
          <UploadAppButton />
        </ProtectedComponent>
      </div>
      <GenericCard noPadding>
        <div className={classes.mainContainer}>
          <div style={{ display: 'none' }}>
            <TableActions
              setSelectedTableAction={setSelectedTableAction}
              selectedTableAction={selectedTableAction}
              onGoClick={onGoClick}
            />
          </div>
          <MUICard>
            <SearchBox />
          </MUICard>
          <div style={{ display: showEmptyPage ? 'block' : 'none' }}>
            <div className={classes.empty}>
              Use the top right button to add your first app
            </div>
          </div>
          <div style={{ display: showEmptyPage ? 'none' : 'block' }}>
            <Table
              serverSort={false}
              classList={classes}
              columnHeaders={currentTableHeaders}
              footerComponents={() => (
                <ReactToCSV
                  buttonText="exportCSV"
                  dataMap={zScanAppsColumnMapping()}
                  fileName="zscan-apps"
                  header={currentTableHeaders.filter(
                    (column) => column.id !== 'actions'
                  )}
                  table={() =>
                    tableQuery(
                      filters,
                      location.query,
                      availableTeams,
                      setShowEmptyPage
                    )(location.query as unknown as ILocationQuery)
                  }
                />
              )}
              filters={filters}
              onFilterValueChange={handleFilterChange as () => void}
              definedUser={definedUser}
              fetchTableData={() =>
                tableQuery(
                  filters,
                  location.query,
                  availableTeams,
                  setShowEmptyPage
                )(location.query as unknown as ILocationQuery)
              }
              onColumnChange={handleColumnChange}
              onPaginationChange={onPaginationChange}
              onRowClick={handleOpenDrawer}
              query={query as unknown as ILocationQuery}
              rowMapping={zScanAppsColumnMapping()}
              tableId="zScanApps"
              heightBuffer={290}
            />
          </div>
        </div>

        <ZScanUploadApp />
        <ZScanUploadComplete />
        <ZScanUploadEdit />
      </GenericCard>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  zScanAppsUiSettings: getUISettingsWithout(state, 'zScanApps', [
    'tableHeaders',
  ]),
  currentTableHeaders: getUISettingsTableHeaders(state, 'zScanApps'),
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      zScanTableColumnChange,
      updateUISettings,
    },
    dispatch
  );

export default compose(
  withRouter,
  withDirtyState(),
  connect(mapStateToProps, mapDispatchToProps)
)(
  withMemoizedProps(Apps, [
    'currentTableHeaders',
    'dirtyState',
    'q',
    'updateUISettings',
    'updateUrl',
  ])
);
