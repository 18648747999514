import {
  FormControlLabelProps as IMUIFormControlLabelProps,
  RadioProps as IMUIRadioProps,
} from '@material-ui/core';
import { default as MUIFormControlLabel } from '@material-ui/core/FormControlLabel';
import { default as MUIRadio } from '@material-ui/core/Radio';
import { TClassList } from 'components/UI/Table/models';
import { FieldProps as IFormikFieldProps } from 'formik';
import React from 'react';
import useStyles from './useStyles';

export interface IRadioProps extends Partial<IFormikFieldProps> {
  label: IMUIFormControlLabelProps | string | React.ReactNode;
  classList?: TClassList;
}

const Radio: React.FC<IRadioProps & IMUIRadioProps> = ({
  label,
  field,
  color = 'primary',
  classList,
  ...rest
}) => {
  const radioButtonClasses = useStyles();

  const classes = { ...radioButtonClasses, ...classList };

  return (
    <MUIFormControlLabel
      label={label}
      classes={{ label: classes?.label }}
      control={
        <MUIRadio
          color={color}
          classes={{ root: classes?.radio }}
          {...field}
          {...rest}
        />
      }
    />
  );
};

export default Radio;
