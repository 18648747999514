import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    tableColumn: {
      display: 'flex',
      alignItems: 'center',
    },
    distributionColumn: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 65,
    },
  })
);

export default useStyles;
