import { ServiceHandlerFactory } from 'utils/serviceUtils';

const MTDBase = 'api/mtd-policy/v1';

export const fetchMTDStatus = ServiceHandlerFactory({
  axios: {
    url: `${MTDBase}/status`,
    method: 'get',
  },
  paramSchema: [{ path: 'teamId', paramKey: 'teamId' }],
});

export const fetchGroupPolicyMetadata = ServiceHandlerFactory({
  axios: {
    url: `${MTDBase}/groups/:groupId/policyMetadata`,
    method: 'get',
  },
});
