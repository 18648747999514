import { IDeviceCountsByVersion, IDeviceCountsData } from '../common/models';
import { IOSVersionCountData } from './models';
import PhoneAndroidIcon from 'components/UI/icons/PhoneAndoidIcon';
import ChromeOSIcon from 'components/UI/icons/ChromeOSIcon';
import AppleIcon from 'components/UI/icons/AppleIcon';
import AndroidIcon from 'components/UI/icons/AndroidIcon';

const emptyCountData = { counts: [], labels: [] };

export const prepareDeviceCountsForGraph = (
  deviceCounts: IDeviceCountsData,
  osVersionCounts: IDeviceCountsByVersion,
  module: string,
  theme: any // This is any because we are not yet able to conform to MUI's theme standards
): IOSVersionCountData[] => {
  return [
    {
      counts: [
        deviceCounts?.android ?? 0,
        deviceCounts?.ios ?? 0,
        deviceCounts?.['chrome os'] ?? 0,
      ],
      icon: <PhoneAndroidIcon />,
      labels: ['Android', 'iOS', 'Chrome OS'],
      os: 'allOSs',
      osLabel: 'Total',
      backgroundColor: [
        theme.palette.common.android,
        theme.palette.common.ios,
        theme.palette.common.chromeos,
      ],
    },
    {
      os: 'ios',
      osLabel: 'iOS',
      backgroundColor: theme.palette.common.gradient.ios,
      icon: <AppleIcon />,
      ...(osVersionCounts?.ios?.[0]
        ? {
            counts: osVersionCounts.ios.map(({ count }) => count),
            labels: osVersionCounts.ios.map(({ version }) => version),
          }
        : emptyCountData),
    },
    {
      os: 'android',
      osLabel: 'Android',
      backgroundColor: theme.palette.common.gradient.android,
      icon: <AndroidIcon />,
      ...(osVersionCounts?.android?.[0]
        ? {
            counts: osVersionCounts.android.map(({ count }) => count),
            labels: osVersionCounts.android.map(({ version }) => version),
          }
        : emptyCountData),
    },
    {
      os: 'chromeOS',
      osLabel: 'Chrome OS',
      backgroundColor: theme.palette.common.gradient.chromeos,
      hide: module !== 'mtd' || deviceCounts?.['chrome os'] === 0,
      icon: <ChromeOSIcon />,
      ...(osVersionCounts?.['chrome os']?.[0]
        ? {
            counts: osVersionCounts['chrome os'].map(({ count }) => count),
            labels: osVersionCounts['chrome os'].map(({ version }) => version),
          }
        : emptyCountData),
    },
  ];
};
