import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      fill: 'currentColor',
      minHeight: '25px',
      minWidth: '25px',
    },
  })
);

export default useStyles;
