import React, { PureComponent } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';

import { PARTNER_ROUTE_MAPPING } from 'mappings/routes/routeMappings';

// material ui
import Snackbar from '@material-ui/core/Snackbar';

// components
import PartnerProtectedRoute from 'routes/PartnerProtectedRoute';
import GlobalHeaderPartner from 'components/admin/common/GlobalHeader-Partner';
// selectors
import { getUserValid } from 'reducers/UserDetailsReducer';
import { getSnackbar, closeSnackbar } from 'reducers/SnackbarReducers';
import {
  getAvailableTeamsAsList,
  updateAvailableTeams,
} from 'reducers/TeamReducers';
import { getSelectedTeam } from 'reducers/UiSettingsReducers';

// actions
import { onPartnerExitTenant } from 'reducers/AuthReducers';

import ConsoleSidebarPartner from 'components/admin/common/ConsoleSidebarPartner';
import withRouter from '../hocs/withRouter';

class PartnerDashboardRoutesWrapper extends PureComponent {
  componentDidUpdate(prevProps) {
    // const { props } = this;
    // if (!!prevProps.authUser.accessToken && !!props.authUser.accessToken) {
    //   props.onPartnerExitTenant();
    // }
    // if (!prevProps.authUser.accessToken && !!props.authUser.accessToken) {
    //   props.history.push('/partner');
    // }
  }

  render() {
    const { props } = this;
    const mainRootClass = classNames('main-root-wrapper', {
      takewarning: !props.userValid,
    });

    return (
      <div className={mainRootClass}>
        <GlobalHeaderPartner className="main-header-wrapper" />
        <div className="main-root-container">
          <ConsoleSidebarPartner classNameclassName="main-sidebar-wrapper" />
          <div className="main-content">
            <div className="main-content__container">
              <Switch>
                {PARTNER_ROUTE_MAPPING.map((route, i) => {
                  return (
                    <PartnerProtectedRoute
                      key={`public-route-${i}`}
                      {...route}
                    />
                  );
                })}
                <Redirect to="/partner/accounts" />
              </Switch>
            </div>
            <Snackbar {...props.snackbar} onClose={props.closeSnackbar} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authUser: state.auth.partnerUser,
    userValid: getUserValid(state),
    snackbar: getSnackbar(state),
    availableTeams: getAvailableTeamsAsList(state),
    selectedTeam: getSelectedTeam(state),
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      closeSnackbar,
      updateAvailableTeams,
      onPartnerExitTenant,
    },
    dispatch
  );
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PartnerDashboardRoutesWrapper)
);
