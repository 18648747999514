import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    genericTooltip: {
      margin: '0 0 0 30px',
    },
    list: {
      listStyleType: 'none',
      justifyContent: 'start',
      margin: '0 -8px 0 -47px'
    },
    li: {
      padding: '3px 15px',
      '& > a': {
        color: palette.primary.contrastText
      }
    }
  })
);

export default useStyles;