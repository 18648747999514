import { isEmpty, omit } from 'lodash';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

// components
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';

const GenericModal = ({
  children,
  title,
  scrollable,
  onBackdropClick,
  onClose,
  dir,
  largeModal,
  ...props
}) => {
  const classes = scrollable
    ? {
        root: props.classes.scrollableRoot,
        container: props.classes.scrollableContainer,
        scrollPaper: props.classes.scrollableScrollPaper,
        paperScrollPaper: props.classes.scrollablePaperScrollPaper,
      }
    : omit(props.classes, [
        'scrollableRoot',
        'scrollableContainer',
        'scrollableScrollPaper',
        'scrollablePaperScrollPaper',
      ]);

  const handleClickAway = (e, reason) => {
    if (onBackdropClick && reason === 'backdropClick') {
      onBackdropClick();
    }

    if (typeof onClose === 'function') {
      onClose(e, reason);
    }
  };

  const { t, ready } = useTranslation();

  if (!ready) {
    return null;
  }

  return (
    <Dialog
      // keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      {...props}
      classes={classes}
      className={largeModal ? props.classes.largeModal : {}}
      onClose={handleClickAway}
        // style={{ zIndex: 1000000000 }}
    >
      <div dir={dir || 'ltr'}>
        {!isEmpty(title) && <DialogTitle>{t(title)}</DialogTitle>}
        {children}
      </div>
    </Dialog>
  );
};

GenericModal.defaultProps = {
  data: {},
  scrollable: false,
  scroll: 'body',
};

const styles = () => ({
  scrollableRoot: {
    overflowY: 'auto',
  },

  scrollableContainer: {
    // height: 'unset',
    // overflowY: 'scroll',
  },

  scrollableScrollPaper: {
    alignItems: 'unset',
  },

  scrollablePaperScrollPaper: {
    // maxHeight: 'unset',
    // maxWidth: 600,
    // maxHeight: '70vh',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  largeModal: {
    '& .MuiDialog-paper': {
      width: '80%',
      maxWidth: 'none',
    },
  },
});

export default withStyles(styles)(GenericModal);
