import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const LogoHeader = props => {
  return (
    <div className={props.classes.mainContentLogo}>
      <Link to="/admin">
        <img
          className="main-content-logo-image"
          src={props.partnerConsoleLogo ? props.partnerConsoleLogo : 'logo.svg'}
          alt="zAPP Logo"
          style={{ width: 'auto', maxHeight: '36px', maxWidth: '153px' }}
        />
      </Link>
    </div>
  );
};

const styles = () => ({
  mainContentLogo: {
    width: 170,
    marginRight: 25,
    borderRight: '1px solid #647c8a',
    textAlign: 'center',
  },
});

export default withStyles(styles)(LogoHeader);
