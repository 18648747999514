import { ServiceHandlerFactory } from 'utils/serviceUtils';

export const fetchAllAppSettings = ServiceHandlerFactory({
  axios: {
    url: 'api/app_settings/v1/appsettings/',
  },
});

export const fetchAppSettingsById = ServiceHandlerFactory({
  axios: {
    url: 'api/app_settings/v1/appsettings/:id',
  },
});

export const createAppSettings = ServiceHandlerFactory({
  axios: {
    url: 'api/app_settings/v1/appsettings/',
    method: 'post',
  },
});

export const updateAppSettings = ServiceHandlerFactory({
  axios: {
    url: 'api/app_settings/v1/appsettings/:id',
    method: 'put',
  },
});

export const deleteAppSettings = ServiceHandlerFactory({
  axios: {
    url: 'api/app_settings/v1/appsettings/:id',
    method: 'delete',
  },
});

export const cloneSelectedAppSettings = ServiceHandlerFactory({
  axios: {
    url: 'api/app_settings/v1/appsettings/:id/clone',
    method: 'post',
  },
});
