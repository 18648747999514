import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';

interface IHeadlessInfiniteScrollProps {
  children: React.ReactNode;
  hasMore: boolean;
  onScrollEnd: () => Promise<void>;
}

const HeadlessInfiniteScroll: React.FC<IHeadlessInfiniteScrollProps> = ({
  children,
  hasMore,
  onScrollEnd: handleScrollEnd,
}) => {
  const [loading, setLoading] = useState(false);
  const [scrollTargetY, setScrollTargetY] = useState<number | undefined>(
    undefined
  );
  const wrapperEl = useRef<HTMLDivElement>(null);

  const loadMore = useCallback(async () => {
    setLoading(true);
    if (wrapperEl?.current) {
      setScrollTargetY(
        wrapperEl.current.scrollTop + wrapperEl.current.clientHeight
      );
    }
    await handleScrollEnd();
    setLoading(false);
  }, [handleScrollEnd]);

  const checkIfAtBottom = useCallback(
    async (e: React.UIEvent<HTMLDivElement>) => {
      if (!loading) {
        const scrollProgressPercent =
          ((e.currentTarget.scrollTop + e.currentTarget.clientHeight) /
            e.currentTarget.scrollHeight) *
          100;

        if (scrollProgressPercent === 100 && hasMore) {
          await loadMore();
        }
      }
    },
    [hasMore, loadMore, loading]
  );

  useLayoutEffect(() => {
    if (!!scrollTargetY && !!wrapperEl?.current) {
      wrapperEl.current.scrollTo(0, scrollTargetY);
    }
    setScrollTargetY(undefined);
  }, [scrollTargetY, loading]);

  return (
    <div ref={wrapperEl} onScroll={checkIfAtBottom}>
      {children}
    </div>
  );
};

export default HeadlessInfiniteScroll;
