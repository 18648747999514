import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '81px',
      '& > button': { boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.38)' },
    },
    buttonBackgroundColor: {
      backgroundColor: palette.charts.icon,
      color: palette.common.white,
      '&:hover': {
        backgroundColor: palette.charts.icon,
      },
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gridColumnGap: '20px',
    },
    maxIdle: {
      '& .MuiInputBase-root': {
        width: '120px',
      },
    },
    nestedGroups: {
      display: 'flex',
      justifyItems: 'flex-start',
      alignItems: 'center',
      marginTop: '12px',
      fontSize: '12px',
      '& > label > span:nth-child(2)': {
        color: palette.text.primary,
        whiteSpace: 'nowrap',
      },
    },
    resetNotice: {
      fontSize: '12px',
      marginTop: '5px',
    },
    tooltip: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-start',
      position: 'relative',
    },
    tooltipText: {
      color: palette.text.secondary,
      width: '293px',
      left: '40px',
      borderRadius: '3px',
      overflow: 'hidden',
      backgroundColor: palette.common.darkGreySoft,
      padding: '6px 8px 6px 8px',
      position: 'absolute',
    },
    chipMargin: {
      margin: '5px',
    },
    mainContainerSelectGroup: {
      marginTop: '19px',
      marginBottom: '36px',
      display: 'flex',
      gap: '30px',
    },
    subMainThreatContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    subMainSelectGroup: {
      display: 'flex',
      flexDirection: 'column',
      width: '864px',
    },
    titleStyles: {
      color: palette.text.primary,
      fontFamily: 'Roboto',
      fontSize: ' 20px',
      fontStyle: 'normal',
      fontweight: '700',
      lineHeight: ' 38px',
      letterSpacing: '0.028px',
    },
    dividerStyle: {
      background: palette.background.public,
    },
  })
);

export default useStyles;
