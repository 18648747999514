import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import withRouter from 'components/hocs/withRouter';

import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

const styles = ({ palette }) => {
  return {
    headerMenu: {
      paddingLeft: 15,
      marginLeft: 25,
      borderLeft: `1px solid ${palette.components.globalHeader.contrastText}`,
      borderRradius: 'none',

      '& button': {
        padding: 0,
        color: palette.components.globalHeader.contrastText,
        textTransform: 'capitalize',
      },
    },

    iconStyle: {
      paddingLeft: 5,
      paddingRight: 5,
      fontSize: 36,
      color: palette.components.globalHeader.contrastText,
    },

    linkWrapper: {
      textDecoration: 'none',
      outline: 0,
    },

    menuItem: {
      paddingLeft: 8,
      paddingRight: 8,
    },

    menuItemText: {
      color: palette.secondary.text,
    },
  };
};

class HeaderMenu extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const name =
      this.props.firstName && this.props.lastName
        ? this.props.firstName.concat(' ', this.props.lastName)
        : '';

    return (
      <div className={classes.headerMenu}>
        <Button
          aria-owns={anchorEl ? 'header-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          {name}
          <KeyboardArrowDownIcon className={classes.iconStyle} />
        </Button>
        <Menu
          id="header-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem
            dense
            onClick={this.handleClose}
            className={classes.menuItem}
          >
            <NavLink to="/admin/accounts" className={classes.linkWrapper}>
              <Typography variant="body1" className={classes.menuItemText}>
                Accounts
              </Typography>
            </NavLink>
          </MenuItem>
          <MenuItem
            dense
            onClick={this.handleClose}
            className={classes.menuItem}
          >
            <NavLink to="/admin/superusers" className={classes.linkWrapper}>
              <Typography variant="body1" className={classes.menuItemText}>
                Super Users
              </Typography>
            </NavLink>
          </MenuItem>
          <MenuItem
            dense
            onClick={this.handleClose}
            className={classes.menuItem}
          >
            <NavLink to="/admin/partnerusers" className={classes.linkWrapper}>
              <Typography variant="body1" className={classes.menuItemText}>
                Partner Users
              </Typography>
            </NavLink>
          </MenuItem>
          <MenuItem
            dense
            onClick={this.handleClose}
            className={classes.menuItem}
          >
            <NavLink to="/admin/threatdefaults" className={classes.linkWrapper}>
              <Typography variant="body1" className={classes.menuItemText}>
                Threat Defaults
              </Typography>
            </NavLink>
          </MenuItem>
          <NavLink to="/logout" className={classes.linkWrapper}>
            <MenuItem
              dense
              onClick={this.handleClose}
              className={classes.menuItem}
            >
              <Typography variant="body1" className={classes.menuItemText}>
                Log out
              </Typography>
            </MenuItem>
          </NavLink>
        </Menu>
      </div>
    );
  }
}

HeaderMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    firstName: state.superUser.firstName,
    lastName: state.superUser.lastName,
  };
};

export default withRouter(
  withStyles(styles)(connect(mapStateToProps)(HeaderMenu))
);
