import React, { PureComponent, Fragment } from 'react';

// ui
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ZButton from 'UI/Buttons/ZButton';

// import { publishEvent } from 'utils/eventUtils';
import { regenerateSecret } from 'api/AuthorizationService';
import { regeneratePartnerAPIKey } from 'api/PartnerService';
import { publishEvent } from 'utils/eventUtils';
import { toggleModalDirect, toggleModalDiffered } from '../../utils/storeUtils';

class AuthorizationsRegenerateConfirm extends PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <Fragment>
        <DialogContent>
          <p>
            Are you sure you want to regenerate API Access Key?
            <br />
            If you regenerate, the previous access key will become invalid.
          </p>
          <DialogActions>
            <ZButton
              styleName="modalCancel"
              action={toggleModalDiffered(
                'AuthorizationsRegenerateConfirm',
                false
              )}
              color="secondary"
              buttonText="Cancel"
            />
            <ZButton
              action={this.handleSubmit}
              buttonType="submit"
              color="primary"
              styleName="modalSave"
              buttonText="Regenerate Key"
            />
          </DialogActions>
        </DialogContent>
      </Fragment>
    );
  }

  handleSubmit() {
    const { props } = this;
    if (props.data.partnerUi) {
      return regeneratePartnerAPIKey({ partnerApiKeyId: props.data.apiKeyId })
        .then(resp => {
          toggleModalDirect('AuthorizationsSecretDisplay', resp.data, {
            title: 'API Access Key Generated',
          });
          publishEvent('table:force-fetch-partnerAuthorizations');
        })
        .catch(error => {
          console.log('Error in regenerating secret', error);
        });
    }

    regenerateSecret({ apiKey: this.props.data.apiKeyId })
      .then(resp => {
        toggleModalDirect('AuthorizationsSecretDisplay', resp.data, {
          title: 'API Access Key Generated',
        });
      })
      .catch(error => {
        console.log('Error in regenerating secret', error);
      });
  }
}

AuthorizationsRegenerateConfirm.defaultProps = {
  data: {},
};

export default AuthorizationsRegenerateConfirm;
