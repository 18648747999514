import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    selectFormControl: {
      minWidth: 220,
    },
    tableActionsStyle: {
      display: 'flex',
      gap: '5px',
      justifyContent: 'flex-end',
      marginBottom: '5px',
    },
  })
);

export default useStyles;
