import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    keyFeatures: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',

      '& > div.key-feature-item': {
        marginBottom: '15px',
      },
    },
    keyFeaturesCard: { maxWidth: 420 },
    icon: {
      marginRight: '10px',
    },
    status: {
      width: '50%',
    },
    title: {
      fontWeight: 'bold',
      width: '50%',

      '& > a:link, & > a:active, > a:visited': {
        color: 'inherit',
      },

      '& > a:hover': {
        textDecoration: 'underline',
      },
    },
  })
);

export default useStyles;
