import { DrawerContext } from 'components/drawer/DrawerProvider';
import { IDrawerContext } from 'components/drawer/DrawerProvider/models';
import { IOOCSelect, IRowProps } from '../models';
import { useContext } from 'react';
import Drawer from '.';
import VisibilityIcon from '@material-ui/icons/Visibility';
import useStyles from './useStyles';

interface IContentSelectorProps {
  rowData: IOOCSelect;
  ruleType: string;
}

const ContentSelector: React.FC<IContentSelectorProps> = ({
  rowData,
  ruleType,
}) => {
  const classes = useStyles();
  const { openDrawer } =
    useContext<Partial<IDrawerContext<IRowProps>>>(DrawerContext);

  const handleOpenDrawer = (rowProps: IRowProps) => {
    if (!openDrawer) {
      return;
    }
    openDrawer({ drawerProps: rowProps, drawer: Drawer });
  };

  return (
    <div id="action-column">
      <VisibilityIcon
        className="cursor--pointer"
        onClick={() => {
          const rowProps: IRowProps = {
            data: rowData,
            ruleType,
            classList: classes.customDrawer,
          };
          handleOpenDrawer(rowProps);
        }}
      />
    </div>
  );
};

export default ContentSelector;
