export const formatErrorMessage = (
  statusText: string,
  dataString: string,
  status?: number
) => {
  if (status === 404 || status === 400) {
    return 'Not Found - Error reaching server.';
  }
  if (status === 401) {
    return 'Unauthorized - Session Timeout.';
  }
  if (status === 503) {
    return 'A Bad Gateway (503) error was generated during request.';
  }
  if (status === 504) {
    return 'A request timeout was generated during request.';
  }

  const errorStatus = statusText ?? 'Error';
  const responseData = dataString ?? 'Please Try Again';

  return `${errorStatus} - ${responseData}`;
};
