import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    devicesThreatsCard: {
      flex: 1,
      '& > div': {
        display: 'flex',
        flexDirection: 'row',
      }
    },
    section: {
      textAlign: 'center',
      flex: 1,
      width: "33.333%",
    },
    naCard: {
      alignItems: "center",
      display: "flex",
      fontSize: 48,
      height: "calc(100% - 120px)",
      justifyContent: "center",
      opacity: 0.125,
    }
  })
);

export default useStyles;
