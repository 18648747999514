import {
  FormControlLabelProps as IMUIFormControlLabelProps,
  InputAdornment as MUIInputAdornment,
  TextFieldProps as TMUITextFieldProps,
} from '@material-ui/core';
import MUIIconButton from '@material-ui/core/IconButton';
import MUIVisibility from '@material-ui/icons/Visibility';
import MUIVisibilityOff from '@material-ui/icons/VisibilityOff';
import { FieldProps as IFormikFieldProps } from 'formik';
import { useState } from 'react';
import TextField from '../TextField';
import useStyles from './useStyles';

export interface IPasswordFieldProps extends Partial<IFormikFieldProps> {
  disabled?: boolean;
  errorClass?: string;
  label?: IMUIFormControlLabelProps | string;
}

export type TPasswordFieldProps = IPasswordFieldProps & TMUITextFieldProps;

const PasswordField: React.FC<IPasswordFieldProps> = (props) => {
  const classes = useStyles();
  const [showText, setShowText] = useState(false);

  return (
    <TextField
      {...props}
      type={showText ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <MUIInputAdornment
            classes={{ root: classes.adornmentRoot }}
            position="end"
          >
            <MUIIconButton
              disabled={props?.disabled}
              onClick={() => setShowText(!showText)}
            >
              {showText ? <MUIVisibility /> : <MUIVisibilityOff />}
            </MUIIconButton>
          </MUIInputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
