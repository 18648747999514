import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import withRouter from 'components/hocs/withRouter';
import { createModalConfig } from 'components/modals/AuthorizationsCreateEdit';
import Table from 'components/RDGTables';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  authorizationTableColumnChange,
  updateUISettings,
} from 'reducers/UiSettingsReducers';
import {
  getUISettingsTableHeaders,
  getUISettingsWithout,
} from 'reducers/UiSettingsSelectors';
import { bindActionCreators, compose } from 'redux';
import ZButton from 'UI/Buttons/ZButton';
import ReactToCSV from 'utility/ReactCSVDownloader';
import { toggleModalDiffered } from 'utils/storeUtils';
import useBackfill from 'utils/useBackfill';
import { withDirtyState } from 'utils/withDirtyState';
import withMemoizedProps from 'utils/withMemoizedProps';
import ProtectedComponent from '../../protected/ProtectedComponent';
import { authorizationColumnMapping } from './authorization.mapping.js';
import tableQuery from './tableQuery';

const Authorization = ({
  authorizationUiSettings,
  authorizationTableColumnChange,
  classes,
  currentTableHeaders,
  definedUser,
  jiggleDirtyState,
  q: query,
  rqps,
  updateUISettings,
  updateUrl,
}) => {
  useBackfill({
    query,
    requiredQueryParams: rqps,
    storedQueryParams: authorizationUiSettings,
    updateUrl: updateUrl,
  });

  const handleColumnChange = (...args) => {
    jiggleDirtyState();
    authorizationTableColumnChange(...args);
  };
  const onPaginationChange = (update) => {
    updateUISettings({
      domain: 'authorization',
      update,
    });

    updateUrl(update);
  };

  return (
    <div>
      <div className="view__header">
        <h1>Authorizations</h1>
        <ProtectedComponent allow={{ api_keys: 'manage' }}>
          <ZButton
            styleName="titleBar"
            action={toggleModalDiffered(
              'AuthorizationsCreateEdit',
              {},
              createModalConfig
            )}
            buttonText="Generate API Key"
            color="secondary"
            icon={{ elem: AddIcon }}
          />
        </ProtectedComponent>
      </div>
      <Table
        classes={classes}
        columnHeaders={currentTableHeaders}
        defaultSortInfo={{}}
        footerComponents={() => (
          <ReactToCSV
            buttonText="exportCSV"
            dataMap={authorizationColumnMapping}
            fileName="authorization-table"
            header={currentTableHeaders.filter(
              (column) => column.id !== 'actions'
            )}
            table={() => tableQuery(query, true)}
          />
        )}
        definedUser={definedUser}
        fetchTableData={() => tableQuery(query)}
        isSuperUser={definedUser === 'superAdminUser'}
        onColumnChange={handleColumnChange}
        onPaginationChange={onPaginationChange}
        query={query}
        rowMapping={authorizationColumnMapping}
        tableId="Authorization"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  authorizationUiSettings: getUISettingsWithout(state, 'authorization', [
    'tableHeaders',
  ]),
  currentTableHeaders: getUISettingsTableHeaders(state, 'authorization'),
  createEditModal: _.get(state, 'ui.modals.AuthorizationsCreateEdit'),
  secretModal: _.get(state, 'ui.modals.AuthorizationsSecretDisplay'),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUISettings,
      authorizationTableColumnChange,
    },
    dispatch
  );

const styles = () => ({
  actionIcon: {
    cursor: 'pointer',
  },

  editLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
});

export default compose(
  withStyles(styles),
  withRouter,
  withDirtyState(),
  connect(mapStateToProps, mapDispatchToProps)
)(
  withMemoizedProps(Authorization, [
    'authorizationUiSettings',
    'createEditModal',
    'currentTableHeaders',
    'dirtyState',
    'q',
    'secretModal',
    'updateUISettings',
  ])
);
