import {
  ButtonProps as TMUIButtonProps,
  SvgIconTypeMap,
} from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import React from 'react';
import useStyles from './useStyles';
import { default as MUIButton } from '@material-ui/core/Button';
import cc from 'classcat';

export interface IButtonProps {
  component?: string;
  for?: string;
  text?: string;
  icon?: unknown;
  isLoading?: boolean;
  form?: string;
}

export type TButtonProps = IButtonProps & TMUIButtonProps;

const Button: React.FC<TButtonProps> = (props) => {
  const { icon, form, ...rest } = props;
  const classes = useStyles();

  const Icon =
    !!icon && !React.isValidElement(icon as {} | null | undefined)
      ? React.createElement(
          icon as OverridableComponent<SvgIconTypeMap<{}, 'svg'>>,
          {
            classes: {
              root: classes.icon,
            },
          }
        )
      : icon;

  return (
    <MUIButton
      className={cc([
        { 'is-loading': !!props?.isLoading },
        { 'shimmer': !!props?.isLoading },
      ])}
      classes={{ root: classes.root }}
      variant="contained"
      form={form}
      {...rest}
    >
      {Icon}
      {props?.text ?? ''}
    </MUIButton>
  );
};

export default Button;
