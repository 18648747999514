import {
  csvInjectionErrorMessage,
  csvInjectionRegex,
} from 'utils/componentUtils';
import * as Yup from 'yup';
import { IGenericPromptModalQuestion } from '../models';

const Schema = ({
  requiredCaption,
  schema,
  t,
}: Omit<IGenericPromptModalQuestion, 'defaultAnswer' | 'label'>) =>
  Yup.object().shape({
    answer:
      schema ??
      Yup.string()
        .min(1)
        .matches(csvInjectionRegex, t ? t('GLOBAL.CSV_INJECTION_ERROR') : csvInjectionErrorMessage)
        .required(requiredCaption ?? (t ? t('GLOBAL.REQUIRED_THIS_FIELD') : 'This field is required.')),
  });

export default Schema;
