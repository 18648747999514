export const defaultMtdGroupsTableHeaders = [
  {
    id: 'name',
    numeric: false,
    label: 'GLOBAL.GROUP_NAME',
    sort: true,
    show: true,
  },
  {
    id: 'description',
    numeric: false,
    label: 'GLOBAL.DESCRIPTION',
    sort: true,
    show: false,
  },
  {
    id: 'emmConnectionId',
    numeric: false,
    label: 'MTD.POLICIES.GROUPS.EMM_CONNECTION',
    sort: false,
    show: true,
    scope: 'emm',
  },
  {
    id: 'privacyName',
    numeric: false,
    label: 'GLOBAL.PRIVACY_POLICY',
    sort: false,
    show: true,
  },
  {
    id: 'trmName',
    numeric: false,
    label: 'GLOBAL.THREAT_POLICY',
    sort: false,
    show: true,
  },
  {
    id: 'phishingName',
    numeric: false,
    label: 'GLOBAL.PHISHING_POLICY',
    sort: false,
    show: true,
    isProtected: true,
    scope: 'phishing',
  },
  {
    id: 'appSettingsName',
    numeric: false,
    label: 'GLOBAL.APP_SETTINGS',
    sort: false,
    show: true,
    isProtected: true,
    scope: 'app_settings',
  },
  {
    id: 'appPolicyName',
    numeric: false,
    label: 'MTD.INSIGHTS.APP_POLICY',
    sort: false,
    show: true,
  },
  {
    id: 'networkPolicyName',
    numeric: false,
    label: 'GLOBAL.NETWORK_POLICY',
    sort: false,
    show: true,
  },
  {
    id: 'dormancyPolicyName',
    numeric: false,
    label: 'GLOBAL.DEVICE_INACTIVITY',
    sort: false,
    show: true,
  },
  {
    id: 'brandingPolicyName',
    numeric: false,
    label: 'GLOBAL.BRANDING_POLICY',
    sort: false,
    show: true,
  },
  {
    id: 'osRiskPolicyName',
    numeric: false,
    label: 'GLOBAL.OS_RISK_POLICY',
    sort: false,
    show: true,
  },
  {
    id: 'created',
    numeric: false,
    label: 'GLOBAL.CREATED',
    sort: true,
    show: false,
  },
  {
    id: 'modified',
    numeric: false,
    label: 'GLOBAL.MODIFIED',
    sort: true,
    show: false,
  },

  {
    id: 'actions',
    numeric: false,
    label: 'GLOBAL.ACTIONS',
    sort: false,
    show: true,
    isProtected: true,
    scope: 'policies',
  },
];

export const groupsServiceMapping = [
  {
    path: 'page',
  },
  {
    path: 'size',
  },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      if (orderBy && order) {
        return `${orderBy},${order}`;
      }

      return null;
    },
  },
  {
    path: 'teamId',
  },
];

export const groupsEmmConnectionServiceMapping = [
  {
    path: 'page',
  },
  {
    path: 'size',
  },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      if (orderBy && order) {
        return `${orderBy},${order}`;
      }

      return null;
    },
  },
  {
    path: 'teamId',
  },
  {
    path: 'emmConnectionId',
    transform: ({ emmConnectionId }) => {
      if (!emmConnectionId) return null;
      return emmConnectionId.toString();
    },
  },
];
