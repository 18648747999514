import axios from 'axios';
import { Api } from 'config/axiosConfig';
import { FormikHelpers } from 'formik';
import { publishEvent } from 'utils/eventUtils';
import { openSnackBar } from 'utils/storeUtils';
import { IAuthForGroupsCreate, IAuthPayload } from '../../models';
import { IIntuneAuth, IIntuneFormData } from './models';
import { TFunction } from 'react-i18next';

const createAuthPayload = (
  formData: IIntuneFormData
): IAuthPayload<IIntuneAuth> => {
  return {
    authData: {
      auth: {
        cloud: formData?.cloud,
        aad_apps_consent: formData?.aad_apps_consent,
      },
      baseUrl: formData?.baseUrl,
      connectorTypeId: formData?.connectorTypeId,
    },
    mode: formData?.mode,
    name: formData?.name,
  };
};

export const createConnection = async (
  formData: IIntuneFormData,
  formikHelpers: FormikHelpers<IIntuneFormData>,
  setAuthError: React.Dispatch<React.SetStateAction<string>>,
  goToNextPage: (
    authForGroupsCreate: IAuthForGroupsCreate<IIntuneAuth>
  ) => void,
  t: TFunction<'translation', undefined>
) => {
  const authPayload = createAuthPayload(formData);
  const apiUrl = 'api/emm/v1/provision/connection/authenticate';
  try {
    const result = await Api.post(apiUrl, authPayload);
    goToNextPage({
      ...authPayload,
      id: result?.data?.connectionId ?? '',
      teamId:
        typeof formData?.team?.value === 'string' ? formData?.team?.value : '',
    });
  } catch (e) {
    if (axios.isAxiosError(e) && e?.response?.status === 409) {
      setAuthError(t('MTD.INTEGRATIONS.ERROR_ACCOUNT_ALREADY_EXIST'));
    } else {
      setAuthError(t('MTD.INTEGRATIONS.ERROR_AUTHENTICATION'));
    }
    console.error(e);
    formikHelpers.setSubmitting(false);
  }
};

export const updateConnection = async (
  formData: IIntuneFormData,
  formikHelpers: FormikHelpers<IIntuneFormData>,
  setAuthError: React.Dispatch<React.SetStateAction<string>>,
  closeModal: () => void,
  t: TFunction<'translation', undefined>
) => {
  if (!formData?.connectionId) {
    return;
  }
  const authPayload = createAuthPayload(formData);
  const apiUrl = `api/emm/v1/provision/connection/${formData.connectionId}/auth-data`;
  try {
    await Api.patch(apiUrl, authPayload);
    closeModal();
    openSnackBar(t('MTD.INTEGRATIONS.SUCCESSFULLY_UPDATED_MDM_CONNECTION.'));
    publishEvent('emm:reload-table');
  } catch (e) {
    if (axios.isAxiosError(e) && e?.response?.status === 409) {
      setAuthError(t('MTD.INTEGRATIONS.ERROR_ACCOUNT_ALREADY_EXIST'));
    } else {
      setAuthError(t('MTD.INTEGRATIONS.ERROR_AUTHENTICATION'));
    }
    formikHelpers.setSubmitting(false);
    console.error(e);
  }
};
