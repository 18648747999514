import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Route, withRouter } from 'react-router-dom';

const SubscriptionPublicRoute = ({
     component: Component,
     ...rest
 }) => {
    return (
        <Route
            {...rest}
            render={props => <Component {...props} /> }
        />
    );
};

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {},
        dispatch
    );
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(SubscriptionPublicRoute);
