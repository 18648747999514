import { IBuildData } from '../models';
import { APP_DETAIL_MAPPINGS } from '../utils';
import useStyles from './useStyles';

interface IDetailTableProps {
  data: IBuildData | null | undefined;
}

const DetailTable: React.FC<IDetailTableProps> = ({ data }) => {
  const classes = useStyles();

  return (
    <table>
      <tbody>
        {APP_DETAIL_MAPPINGS.filter(({ key }) =>
          Object.keys(data ?? {}).includes(key)
        ).map(({ id, key, label, formatFn }) => {
          const detailValue = data?.[key] ?? 'N/A';
          const formatedValue =
            !!formatFn &&
              (typeof detailValue === 'string' || typeof detailValue === 'number')
              ? formatFn(detailValue)
              : detailValue;
          return (
            <tr key={id}>
              <td className={classes.label}>{label}:</td>
              <td className={classes.detail}>{formatedValue}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default DetailTable;
