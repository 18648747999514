import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

// ui

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

class PrivacyPolicyTable extends Component {
  render() {
    const { props } = this;
    const categoryOrder = [
      { id: 1, height: 797 },
      { id: 3, height: 291 },
      { id: 2, height: 266 },
      { id: 0, height: 384 },
    ];
    return (
      <div className={props.classes.container}>
        <div className={props.classes.item}>
          <Card
            key="location"
            className="is-loading shimmer"
            style={{ height: 291 }}
          >
            <CardContent></CardContent>
          </Card>
        </div>

        {categoryOrder.map(category => {
          // device owner (category 3) could be empty
          return (
            <div key={category.id} className={props.classes.item}>
              <Card
                key={category.id}
                className="is-loading shimmer"
                style={{ height: category.height }}
              >
                <CardContent></CardContent>
              </Card>
            </div>
          );
        })}
      </div>
    );
  }
}

PrivacyPolicyTable.defaultProps = {
  selectedPrivacyPolicyRulesByCategory: [],
};

const styles = () => ({
  container: {
    display: 'flex',
    flexFlow: 'column wrap',
    alignContent: 'space-between',
    height: '1000px', // needs a fixed height
    overflowY: 'hidden',
    // '&:before,
    '&:after': {
      content: '',
      flexBasis: '100%',
      width: 0,
      order: 2,
    },
    '&:before': {
      content: '',
      flexBasis: '100%',
      width: 0,
      order: 2,
    },
  },

  item: {
    width: '32%',
    '&:before': {
      counterIncrement: 'items',
    },

    /* 3 rows */
    '&:nth-child(3n+1)': { order: 1 },
    '&:nth-child(3n+2)': { order: 2 },
    '&:nth-child(3n)': { order: 3 },
  },
});

export default withStyles(styles)(PrivacyPolicyTable);
