import { useTheme } from '@material-ui/core';
import LineGraph from 'components/UI/graphs/LineGraph';
import { IGraphData } from 'components/UI/graphs/common/models';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IQueryParams } from '../common/models';
import useStyles from './useStyles';
import { GRAPH_OPTIONS, calcDaysToAdd } from './utils';

interface IUniqueDevicesGraphProps {
  graphData?: IGraphData;
  query: IQueryParams;
  updateUrl: (params: IQueryParams) => void;
}

const UniqueDevicesGraph: React.FC<IUniqueDevicesGraphProps> = ({
  graphData: uniqueDevicesGraphData,
  query,
  updateUrl,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theme: any = useTheme(); // This is any because we are not yet able to conform to MUI's them standards

  const [initialized, setInitialized] = useState<boolean>(false);

  const [graphData, setGraphData] = useState<IGraphData | undefined>();

  const handleClickDataPoint = useCallback(
    (elems) => {
      if (!elems?.[0]) {
        return;
      }

      const el = elems[0];
      const timestamp =
        el?._chart?.data?.datasets?.[el._datasetIndex]?.parameters?.[
          el._index
        ] ?? '';

      updateUrl({
        from: String(moment(timestamp).unix()),
        to: String(
          moment(timestamp)
            .add(calcDaysToAdd(Number(query?.duration ?? 0)), 'd')
            .unix()
        ),
        duration: undefined,
      });
    },
    [query?.duration, updateUrl]
  );

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }
    if (!!uniqueDevicesGraphData && !!query) {
      setGraphData(uniqueDevicesGraphData);
    }
  }, [uniqueDevicesGraphData, query, initialized]);

  return (
    <div className={classes.graph}>
      <LineGraph
        clickHandler={handleClickDataPoint}
        colors={{
          uniqueDevices: theme.palette.common.uniqueDevices,
        }}
        graphData={graphData}
        height={51}
        options={GRAPH_OPTIONS(t)}
      />
    </div>
  );
};

export default UniqueDevicesGraph;
