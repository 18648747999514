//Review file: if it is no longer in use delete
import axios from 'axios';
import { ISelectItem } from 'components/UI/input/Select';
import { Api } from 'config/axiosConfig';
import { FormikHelpers } from 'formik';
import { openSnackbar } from 'reducers/SnackbarReducers';
import { toggleModalDirect } from 'utils/storeUtils';
import { IDormancyCreateForm } from './Form/models';

export const getHandleCloneFn = (
  onSuccess: (policy: ISelectItem) => void,
  policyId: string
) => {
  return async (
    form: IDormancyCreateForm,
    actions: FormikHelpers<IDormancyCreateForm>
  ) => {
    const nonGlobalTeamId = form?.selectedTeamId || form?.teamId;
    const teamId = form?.bounds === 'global' ? null : nonGlobalTeamId;
    const name = form?.name;
    const baseUrl = 'api/devices/v1/dormancy/policies';
    const apiUrl = `${baseUrl}/${policyId}/clone`;
    try {
      const response = await Api.post(apiUrl, { name, teamId });
      toggleModalDirect('DormancyPolicyCreateClone', false);
      openSnackbar(`${form.name} dormancy policy successfully cloned.`);
      onSuccess({ label: name, value: response?.data?.id ?? '' });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e?.response?.status === 409) {
          actions.setErrors({
            name: 'A dormancy policy with this name already exists.',
          });
        }
      }
      console.error(e);
    }
  };
};

export const getHandleCreateFn = (onSuccess: (policy: ISelectItem) => void) => {
  return async (
    form: IDormancyCreateForm,
    actions: FormikHelpers<IDormancyCreateForm>
  ) => {
    const apiUrl = 'api/devices/v1/dormancy/policies';
    const nonGlobalTeamId = form?.selectedTeamId || form?.teamId;
    const teamId = form?.bounds === 'global' ? null : nonGlobalTeamId;
    const name = form?.name;
    try {
      const response = await Api.post(apiUrl, { name, teamId });
      toggleModalDirect('DormancyPolicyCreateClone', false);
      openSnackbar(`${form.name} dormancy policy successfully created.`);
      onSuccess({ label: name, value: response?.data?.id ?? '' });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e?.response?.status === 409) {
          actions.setErrors({
            name: 'A dormancy policy with this name already exists.',
          });
        }
      }
      console.error(e);
    }
  };
};
