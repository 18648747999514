import AT from 'actions/ActionTypes';
import * as api from 'api/apis';
import { openSnackBar } from 'utils/storeUtils';

export const setAccount = account => {
  return {
    type: AT.SET_ACCOUNT,
    payload: account
  };
};

const fetchAccountSucceeded = resp => {
  return {
    type: AT.FETCH_ACCOUNT_SUCCEEDED,
    payload: {
      account: resp.data,
    },
  };
};

export const fetchAccount = () => {
  return dispatch => {
    return api.fetchAccount().then(resp => {
      dispatch(fetchAccountSucceeded(resp));
    });
  };
};

const updateAccountSucceeded = account => {
  return {
    type: AT.UPDATE_ACCOUNT_SUCCEEDED,
    payload: account,
  };
};

export const updateAccount = (account, successCallback, errorCallback) => {
  return dispatch => {
    api
      .postAccount(account)
      .then(resp => {
        const responseData = JSON.parse(resp.config.data);
        if (resp.status === 200) {
          openSnackBar(`${'Updated'} Account "${responseData.name}"`);
          dispatch(updateAccountSucceeded(account));
        }
      })
      .catch(error => {
        console.log('Caught error: ', error.response);
        errorCallback(error.response);
      });
  };
};
