import { Grid, Typography } from '@material-ui/core';
import { default as MUIDialogActions } from '@material-ui/core/DialogActions';
import { default as MUIDialogContent } from '@material-ui/core/DialogContent';
import {
  checkedRules as checkedRulesAtom,
  hasChanges as hasChangesAtom,
  selectedRules as selectedRulesAtom,
  originSelectedRules as originSelectedRulesAtom,
} from 'atoms/policies';
import { IColumnHeader, ILocationQuery } from 'components/UI/Table/models';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  oocSelectTableColumnChange,
  updateUISettings,
} from 'reducers/UiSettingsReducers';
import {
  getUISettingsTableHeaders,
  getUISettingsWithout,
} from 'reducers/UiSettingsSelectors';
import { bindActionCreators, compose } from 'redux';
import { isEmptyObj } from 'utils';
import { toggleModalDirect } from 'utils/storeUtils';
import { withDirtyState } from 'utils/withDirtyState';
import { default as OldSearchBox } from '../common/SearchBox';
import { IOOCSelect, OOCModalData } from './models';
import {
  oocSelectColumnMapping,
  oocSelectTableHeaders,
  oocSelectTableTitle,
} from './oocSelect.mappings';

import withRouter from 'components/hocs/withRouter';
import Button from 'components/UI/Button';
import EditableTable from 'components/UI/EditableTable';
import GenericError from 'components/UI/GenericErrorBox';
import Table from 'components/UI/Table';
import memoizeTableQuery from 'components/UI/Table/utils/memoizeTableQuery';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useBackfill from 'utils/useBackfill';
import withMemoizedProps from 'utils/withMemoizedProps';
import { IAppPolicyTableRow } from '../policies/appPolicy/PolicyTable/models';
import {
  appPolicyColumnHeaders,
  appPolicyRowMapping,
} from '../policies/appPolicy/PolicyTable/PolicyTable.mappings';
import RuleCard from './RuleCard';
import tableQuery from './tableQuery';
import useStyles from './useStyles';
import removeEntryFromObj from './utils';
import useAppCharacteristics from '../policies/appPolicy/AppCharacteristicProvider';

const SearchBox: any = OldSearchBox;

interface OOCSelectModalProps {
  oocSelectTableColumnChange: (...args: any) => void;
  oocSelectUiSettings: { [key: string]: unknown };
  currentTableHeaders: IColumnHeader[];
  definedUser?: string;
  q: { [key: string]: unknown };
  rqps: string[];
  updateUISettings: (...args: any) => void;
  updateUrl: (...args: any) => void;
  data: OOCModalData;
  scopes: { [key: string]: string[] };
}

const OOCSelectModal: React.FC<OOCSelectModalProps> = ({
  oocSelectTableColumnChange,
  oocSelectUiSettings,
  definedUser,
  q: query,
  rqps,
  updateUrl,
  data,
  scopes,
}) => {
  useBackfill({
    query,
    requiredQueryParams: rqps,
    storedQueryParams: oocSelectUiSettings,
    updateUrl,
  });
  const [modalQuery, setModalQuery] = useState({
    order: 'asc',
    page: '0',
    size: '25',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [topSelected, setTopSelected] = useState<{
    [key: string | number]: IOOCSelect;
  }>({});
  const [bottomSelected, setBottomSelected] = useState<
    | {
        [key: string | number]: IOOCSelect;
      }
    | boolean
  >({});
  const [ruleContent, setRuleContent] = useState<IOOCSelect[]>([]); // for bottom table
  const [, setEditableRuleContent] = useState<IAppPolicyTableRow[]>([]); // for bottom table
  const checkedRules = useRecoilValue(checkedRulesAtom);
  const selectedRules = useRecoilValue(selectedRulesAtom);
  const originSelectedRules = useRecoilValue(originSelectedRulesAtom);
  const setSelectedRulesInRecoil = useSetRecoilState(selectedRulesAtom);
  const { setAppPolicyTabSetting } = useAppCharacteristics();
  const setHasChanges = useSetRecoilState(hasChangesAtom);

  const { t } = useTranslation();
  const classes = useStyles();
  const tableName = data.variant || 'OOCSelectModal';
  const ruleType = data.ruleType; // ex. ios_allow_package_version

  // IAppPolicyTableRow
  const createEditableRows = useCallback(
    (rules: IOOCSelect[]) => {
      const tableData: IAppPolicyTableRow[] = [];
      rules.forEach((rule) => {
        tableData.push({
          id: _.uniqueId(),
          name: rule.name,
          package: rule.package,
          version: rule.version?.toString(),
        } as IAppPolicyTableRow);
      });
      setEditableRuleContent(tableData);
    },
    [setEditableRuleContent]
  );

  useEffect(() => {
    if (ruleType && selectedRules && selectedRules[ruleType]) {
      createEditableRows(selectedRules[ruleType]);
      setRuleContent(selectedRules[ruleType]);
      // set the saved tab to 0 (not app char)
      setAppPolicyTabSetting(0);
    }
  }, [ruleType, selectedRules, createEditableRows, setAppPolicyTabSetting]);

  const onColumnChange = (...args: any) => {
    oocSelectTableColumnChange(...args);
  };

  const updateSearchTerm = useCallback((val: string) => {
    setModalQuery((old) => ({ ...old, search: val }));
  }, []);

  const updateRuleContent = useCallback(() => {
    const contentData: IOOCSelect[] = Object.values(topSelected);
    const parts = ruleType?.split('_') ?? [];
    if (parts[1] === 'allow' && ruleType !== 'ios_allow_developer') {
      const even = contentData.some((item: IOOCSelect) => {
        const matched = checkedRules[ruleType]?.filter(
          (cr) => cr.id === item.id
        );

        if (!!matched?.length) {
          return !matched[0].targets?.length;
        } else {
          return true;
        }
      });

      if (even) {
        setErrorMessage(
          'You must choose at least one option for Apply to Suspicious, Sideloaded or OOC for each selected app.'
        );
        return;
      }
      setErrorMessage('');
    }

    setRuleContent((old: IOOCSelect[]) => {
      const copyOld = [...old];
      // to avoid adding duplidated items
      contentData.forEach((contentItem: IOOCSelect) => {
        if (!copyOld.some((item: IOOCSelect) => item.id === contentItem.id)) {
          copyOld.push(contentItem);
        }
      });
      return copyOld;
    });
    setTopSelected({}); // clear top selection
  }, [checkedRules, ruleType, topSelected]);

  const removeRuleContent = useCallback(() => {
    const contentDataKeys: string[] = Object.keys(bottomSelected);
    const newContentData =
      bottomSelected === true
        ? []
        : ruleContent.filter(
            (content) => !contentDataKeys.includes(content.id)
          );
    setRuleContent(newContentData);
    setBottomSelected({}); // clear bottom selection
  }, [bottomSelected, ruleContent]);

  const getRuleContent = memoizeTableQuery(async () => {
    return {
      data: ruleContent,
      count: ruleContent?.length,
    };
  });

  const saveRuleContent = useCallback(() => {
    const updatedContent = ruleContent.map((item) => {
      const matched = checkedRules[ruleType]?.filter((cr) => cr.id === item.id);
      if (matched?.length > 0) {
        return matched[0];
      }
      return item;
    });
    let newSelectedRules = {
      ...selectedRules,
      [ruleType]: updatedContent, // save bottom table data
    };
    setSelectedRulesInRecoil(newSelectedRules);
    newSelectedRules = removeEntryFromObj(newSelectedRules);
    const isEqualSelectedRules = _.isEqual(
      originSelectedRules,
      newSelectedRules
    );
    setHasChanges(!isEqualSelectedRules);
    toggleModalDirect(tableName, false);
  }, [
    checkedRules,
    originSelectedRules,
    ruleContent,
    ruleType,
    selectedRules,
    setHasChanges,
    setSelectedRulesInRecoil,
    tableName,
  ]);

  const hasPermission = useMemo(
    () => !!scopes?.policies?.includes('manage'),
    [scopes]
  );

  const renderRuleData = useCallback(() => {
    const items = ruleType?.split('_') ?? [];
    return (
      <>
        {items.map((item, index) => (
          <RuleCard os={item} key={index} />
        ))}
      </>
    );
  }, [ruleType]);

  const warnChangeList = useCallback(
    (handler: (rowData: any) => void, rowData: IAppPolicyTableRow) => {
      handler(rowData);
    },
    []
  );
  const handleDelete = useCallback(
    (rowData) => {
      const newData: IOOCSelect = {
        id: rowData.id,
        name: rowData.name,
        package: rowData.package,
        developer: '',
        version: '',
      };
      const newContentData = ruleContent.filter(
        (content) => content.id !== newData.id
      );
      setRuleContent(newContentData);
    },
    [ruleContent]
  );
  const handleCreate = useCallback(
    async (rowData) => {
      const newData: IOOCSelect = {
        id: _.uniqueId(),
        name: rowData.name,
        package: rowData.package,
        developer: '',
        version: '',
      };

      const contentData: IOOCSelect[] = Object.values([newData]);
      const parts = ruleType?.split('_') ?? [];
      if (parts[1] === 'allow' && ruleType !== 'ios_allow_developer') {
        const even = contentData.some((item: IOOCSelect) => {
          const matched = checkedRules[ruleType]?.filter(
            (cr) => cr.id === item.id
          );

          if (!!matched?.length) {
            return !matched[0].targets?.length;
          } else {
            return true;
          }
        });

        if (even) {
          setErrorMessage(
            'You must choose at least one option for Apply to Suspicious, Sideloaded or OOC for each selected app.'
          );
          return;
        }
        setErrorMessage('');
      }

      setRuleContent((old: IOOCSelect[]) => {
        const copyOld = [...old];
        // to avoid adding duplidated items
        contentData.forEach((contentItem: IOOCSelect) => {
          // copyOld.push(contentItem);

          if (!copyOld.some((item: IOOCSelect) => item.id === contentItem.id)) {
            copyOld.push(contentItem);
          }
        });
        return copyOld;
      });
      setTopSelected({}); //
    },
    [checkedRules, ruleType]
  );

  return (
    <MUIDialogContent>
      <Grid container justifyContent="center">
        <Typography variant="h4" className={classes.title}>
          {oocSelectTableTitle(ruleType, t)}
        </Typography>
      </Grid>
      <Grid className={classes.iconContainer}>{renderRuleData()}</Grid>
      <Grid className={classes.searchBar}>
        <SearchBox fullWidth updateSearchTerm={updateSearchTerm} />
      </Grid>
      <Table
        classList={classes}
        checkboxColumn={true}
        columnHeaders={oocSelectTableHeaders(ruleType, t)}
        definedUser={definedUser}
        fetchTableData={() =>
          tableQuery({
            ruleType: data.ruleType,
            policyId: data.policyId,
            selectItems: ruleContent,
          })(modalQuery as unknown as ILocationQuery)
        }
        heightBuffer={650}
        onColumnChange={onColumnChange}
        onSelectionChange={setTopSelected}
        pagination={false}
        query={modalQuery as unknown as ILocationQuery}
        rowMapping={oocSelectColumnMapping(ruleType)}
        showLastColumnMenuTool={false}
        selected={topSelected}
        tableId="OOCSelectModal"
      />
      {!!errorMessage && (
        <GenericError
          errorMessage={t(
            'MTD.POLICIES.APP_POLICY.MUST_CHOOSE_ONE_FOR_SELECTED_APP'
          )}
        />
      )}
      <MUIDialogActions>
        <Button
          onClick={() => {
            toggleModalDirect('UploadApp', {
              variant: 'UploadApp',
            });
          }}
          color="primary"
          text={t('MTD.APP_INVENTORY.TABLE_VIEW.UPLOAD_APPS')}
        />
        <Button
          onClick={updateRuleContent}
          disabled={isEmptyObj(topSelected) || !hasPermission}
          color="primary"
          text={t('MTD.POLICIES.APP_POLICY.ADD_SELECTED_TO_RULE')}
        />
      </MUIDialogActions>
      {data.module == 'zdefend' &&
      (ruleType == 'android_ooc_package' ||
        ruleType == 'android_deny_package') ? (
        <div>
          <EditableTable
            columnHeaders={appPolicyColumnHeaders(ruleType)}
            editVisible={false}
            onCreate={(rowData) => warnChangeList(handleCreate, rowData as any)}
            onDelete={(rowData) => warnChangeList(handleDelete, rowData as any)}
            onUpdate={(rowData) => rowData}
            rowMapping={appPolicyRowMapping(ruleType)}
            showNoDataAddButton={true}
            tableData={ruleContent as any}
            tableId="AppPolicyTable"
            noDataAddButtonText="+"
          />
        </div>
      ) : (
        <div>
          {ruleContent?.length > 0 && (
            <div>
              <Table
                classList={classes}
                checkboxColumn={true}
                columnHeaders={oocSelectTableHeaders(ruleType, t)}
                definedUser={definedUser}
                fetchTableData={() =>
                  getRuleContent({
                    ...(modalQuery as unknown as ILocationQuery),
                  })
                }
                heightBuffer={650}
                onSelectionChange={setBottomSelected}
                pagination={false}
                query={modalQuery as unknown as ILocationQuery}
                rowMapping={oocSelectColumnMapping(ruleType, true)}
                showLastColumnMenuTool={false}
                selected={bottomSelected}
                tableId="OOCSelectModal_Rule_Content"
              />
              <MUIDialogActions>
                <Button
                  onClick={removeRuleContent}
                  disabled={isEmptyObj(bottomSelected)}
                  color="primary"
                  text={t('MTD.POLICIES.APP_POLICY.REMOVE_SELECTED_FROM_RULE')}
                />
              </MUIDialogActions>
            </div>
          )}
        </div>
      )}

      <MUIDialogActions>
        <Button
          onClick={() => {
            toggleModalDirect(tableName, false);
          }}
          color="secondary"
          text={t('GLOBAL.CANCEL')}
        />
        <Button
          onClick={saveRuleContent}
          color="primary"
          text={t('GLOBAL.SAVE_CHANGES')}
        />
      </MUIDialogActions>
    </MUIDialogContent>
  );
};

const mapStateToProps = (state: any) => {
  return {
    oocSelectUiSettings: getUISettingsWithout(state, 'oocSelect', [
      'tableHeaders',
    ]),
    currentTableHeaders: getUISettingsTableHeaders(state, 'oocSelect'),
    scopes: state.auth.user.scopes,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      oocSelectTableColumnChange,
      updateUISettings,
    },
    dispatch
  );
};

export default compose(
  withRouter,
  withDirtyState(),
  connect(mapStateToProps, mapDispatchToProps)
)(
  withMemoizedProps(OOCSelectModal, [
    'currentTableHeaders',
    'dirtyState',
    'q',
    'updateUISettings',
  ])
);
