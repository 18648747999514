import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import '@inovua/reactdatagrid-enterprise/index.css';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox } from 'components/inputs/index';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import SelectFilter from '@inovua/reactdatagrid-enterprise/SelectFilter';
import {
  getLastVisibleColumnIndex,
  getNumVisibleColumns,
  tableQueryToLocationQuery,
} from './utils';

// const FOOTER_HEIGHT = 36;
// const HEADER_HEIGHT = 38;
// const PADDING_HEIGHT = 6;
const ROW_HEIGHT = 35;

const FILTER_EDITORS = {
  SelectFilter,
};

const Table = ({
  classes,
  classList,
  columnHeaders,
  definedUser,
  fetchTableData,
  filters = [],
  footerComponents = () => {},
  height = '100vh',
  heightBuffer = 220,
  isSuperUser,
  maxHeight,
  minHeight = 250,
  multiRowExpand = false,
  omitTableColumns = [],
  onColumnChange,
  onFilterValueChange = () => {},
  onPaginationChange,
  onRowClick,
  onSortInfoChange,
  query,
  query: { page: ready },
  rowDetails,
  rowMapping,
  serverSort,
  superUser,
  tableId,
  ...rest
}) => {
  // Used to calculate table min height.
  // const [numRows, setNumRows] = useState(0);
  const [reservedViewportWidth, setReservedViewportWidth] = useState(null);

  // Stores Row Details Expanded State.
  const [expandedRows, setExpandedRows] = useState({ 1: true, 2: true });
  const [collapsedRows, setCollapsedRows] = useState(null);

  // Prevents vertical table scrolling.
  // const minTableHeight =
  //   ROW_HEIGHT * numRows +
  //   HEADER_HEIGHT +
  //   FOOTER_HEIGHT +
  //   PADDING_HEIGHT +
  //   (filters.length ? ROW_HEIGHT + PADDING_HEIGHT : 0);

  // Called by RDG when Row Details is expanded.

  const onExpandedRowsChange = useCallback(
    ({ expandedRows, collapsedRows }) => {
      setExpandedRows(expandedRows);
      setCollapsedRows(collapsedRows);
    },
    []
  );

  const handleReservedViewportWidthChange = (newWidth) =>
    setReservedViewportWidth(newWidth < 0 ? newWidth : null);

  const handleColumnChange = (show, ...args) => {
    if (show || (!show && getNumVisibleColumns(columnDefs) > 1))
      onColumnChange(show, ...args);
  };

  const handlePaginationChange = (...args) =>
    onPaginationChange(tableQueryToLocationQuery(...args, query.size));

  const toggleDetails = (id) => {
    const newExpandedRows = {};

    if (!expandedRows[id]) {
      newExpandedRows[id] = true;
    }

    setExpandedRows(newExpandedRows);
  };

  // Translate row mappings into cell render callbacks.
  const cellRenderers = rowMapping.reduce(
    (a, c) => ({
      ...a,
      [c.path]: ({ data: rowData }) => (
        <div className={classes.tableCell}>
          {typeof c.columnContent === 'function'
            ? c.columnContent({
                classes,
                classList,
                definedUser,
                omitTableColumns,
                rowData,
                superUser,
                tableId,
                isShowingDetails: expandedRows[rowData.id],
                toggleDetails,
                ...rest,
              })
            : rowData[c.path]}
        </div>
      ),
    }),
    {}
  );

  // Intercept data fetches to calculate table min height.
  // const remoteData = async q => {
  // let result = await fetchTableData(q);
  // setNumRows(result.data.length);
  // return result;
  // };

  useEffect(() => {
    window.addEventListener(`table:force-fetch-${tableId}`, fetchTableData);

    return () => {
      window.removeEventListener(
        `table:force-fetch-${tableId}`,
        fetchTableData
      );
    };
  }, [fetchTableData, tableId]);

  // Filter and translate column definitions to RDG friendly terms.

  const handleRowClick = useCallback(
    (rowProps, event) => {
      if (event.target.closest('#action-column')) return;
      if (!onRowClick) return;
      onRowClick(rowProps, event);
    },
    [onRowClick]
  );

  const columnDefs = _.differenceBy(columnHeaders, omitTableColumns, 'id')
    .filter(({ id }) => !(id === 'favorite' && isSuperUser))
    .map(
      ({
        flex,
        id,
        label,
        maxWidth,
        draggable = false,
        filterEditor,
        filterEditorProps = {},
        hideable = true,
        minWidth = 0,
        resizable = false,
        show,
        showColumnMenuTool = false,
        sort,
        defaultLocked = false,
      }) => ({
        visible: show,
        draggable,
        defaultFlex: flex,
        filterEditor: FILTER_EDITORS[filterEditor],
        filterEditorProps: {
          ...filterEditorProps,
          ..._.get(
            filters.filter(({ name }) => name === id),
            ['0', 'filterEditorProps'],
            {}
          ),
        },
        header: label,
        hideable,
        maxWidth,
        minWidth,
        name: id,
        render: cellRenderers[id],
        resizable,
        showColumnMenuTool,
        sortable: sort,
        sort: serverSort ? (a) => a : undefined,
        defaultLocked,
      })
    );

  // Prevent row details/expand icon column from being hidden.
  const lastVisibleColumnIndex = getLastVisibleColumnIndex(columnDefs);

  columnDefs[lastVisibleColumnIndex] = {
    ...columnDefs[lastVisibleColumnIndex],
    hideable: false,
    showColumnMenuTool: true,
    showColumnMenuToolOnHover: false,
    showColumnMenuLockOptions: false,
    showColumnMenuSortOptions: false,
    showColumnMenuGroupOptions: false,
    showColumnMenuFilterOptions: false,
  };
  return (
    <Card className={classes.card}>
      <CardContent
        className={classes.cardContent}
        style={{ minHeight: 'auto' }}
      >
        {!!ready && (
          <ReactDataGrid
            allowUnsort={false}
            checkboxOnlyRowSelect
            collapsedRows={collapsedRows}
            columns={columnDefs}
            dataSource={fetchTableData}
            defaultLimit={parseInt(query.size) || 25}
            defaultSkip={parseInt(query.page) * parseInt(query.size)}
            defaultSortInfo={{
              name: query.orderBy,
              dir: query.order === 'desc' ? -1 : 1,
            }}
            expandedRows={expandedRows}
            defaultFilterValue={filters}
            licenseKey={process.env.REACT_APP_RDG_KEY}
            multiRowExpand={false}
            onExpandedRowsChange={onExpandedRowsChange}
            onFilterValueChange={onFilterValueChange}
            onLimitChange={(update) => handlePaginationChange('limit', update)}
            onSkipChange={(update) => handlePaginationChange('skip', update)}
            onSortInfoChange={(update) =>
              onSortInfoChange
                ? onSortInfoChange(update)
                : handlePaginationChange('sort', update)
            }
            pageSizes={[25, 50, 100]}
            pagination
            renderColumnContextMenu={(menuProps) => {
              menuProps.items = columnHeaders
                .map(({ label, show, showInContextMenu }, index) => ({
                  label: (
                    <div className={classes.contextMenuItem}>
                      {' '}
                      <Checkbox checked={show} /> {label}{' '}
                    </div>
                  ),
                  onClick: () =>
                    handleColumnChange(!show, index, columnHeaders),
                  showInContextMenu,
                }))
                .filter(({ showInContextMenu }) => showInContextMenu);
            }}
            renderRowDetails={
              rowDetails ? ({ data }) => rowDetails(data) : undefined
            }
            reservedViewportWidth={reservedViewportWidth}
            onReservedViewportWidthChange={handleReservedViewportWidthChange}
            rowExpandColumn={false}
            rowExpandHeight={130}
            rowHeight={ROW_HEIGHT}
            onRowClick={handleRowClick}
            scrollProps={{
              autoHide: false,
            }}
            shareSpaceOnResize
            showCellBorders="horizontal"
            showHoverRows={false}
            skip={parseInt(query.page) * parseInt(query.size)}
            sortable
            style={{
              maxHeight,
              minHeight,
              height:
                typeof height === 'string'
                  ? `calc(${height} - ${heightBuffer}px)`
                  : height,
              verticalAlign: 'middle',
              width: '100%',
            }}
          />
        )}
        <div className={classes.footer}>{footerComponents()}</div>
      </CardContent>
    </Card>
  );
};

const styles = () => {
  return {
    card: {
      padding: 1,
    },

    cardContent: {
      position: 'relative',
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
    },

    contextMenuItem: {
      paddingRight: 9,
    },

    footer: {
      position: 'absolute',
      bottom: 0,
      left: 16,
    },
    tableCell: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  };
};

export default withStyles(styles)(Table);
