import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    btnRemove: {
      bottom: -15,
      color: palette.primary.main,
      position: 'absolute',
      right: -10,
      whiteSpace: 'nowrap',
    },
    card: { paddingTop: 1 },
    cardContent: {
      alignItems: 'center',
      display: 'flex',
      position: 'relative',
    },
    container: {
      alignItems: 'center',
      border: `2px dashed ${palette.primary.main}`,
      display: 'flex',
      flexDirection: 'column-reverse',
      gap: '21px',
      height: '100%',
      justifyContent: 'center',
      minHeight: 200,
      padding: '55px 80px 30px',
      position: 'relative',
      textAlign: 'center',
      '& p': { fontSize: 14, whiteSpace: 'nowrap' },
    },
    fileButton: {
      backgroundColor: palette.primary.border,
      borderRadius: 3,
      color: palette.common.white,
      cursor: 'pointer',
      display: 'flex',
      fontWeight: 400,
      textTransform: 'uppercase',
      justifyContent: 'center',
      lineHeight: 1.75,
      marginBottom: 20,
      minWidth: 64,
      padding: '6px 16px',
    },
    imgWrapper: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
    }
  })
);

export default useStyles;
