import { combineReducers } from 'redux';
import {
  UserReducer as user,
  SuperUserReducer as superUser,
  PartnerUserReducer as partnerUser,
} from './UserReducers';
import auth from './AuthReducers';
import details from './UserDetailsReducer';
import downloads from './DownloadsReducers';
import ui from './UiReducers';
import uiSettings from './UiSettingsReducers';
import { PrivacyPolicy } from './PrivacyPolicyReducers';
import { account } from './AccountReducers';
import { snackbar } from './SnackbarReducers';
// import { teamsTable,  } from './TeamsTableReducers';
import teams from './TeamReducers';
import {
  zDevPoliciesReducer as zDevPolicies,
  zDevAppsReducer as zDevApps,
} from './zDevReducers';
import { dataExportReducer as dataExportStore } from './DataExportReducer';
import { MDMFormReducer } from './MDMReducer';

const reducers = combineReducers({
  account,
  auth,
  details,
  dataExportStore,
  downloads,
  MDMFormReducer,
  partnerUser,
  PrivacyPolicy,
  snackbar,
  superUser,
  teams,
  // teamsTable,
  // teamsTableMetadata,
  ui,
  uiSettings,
  user,
  zDevPolicies,
  zDevApps,
});

export default reducers;
