import AT from 'actions/ActionTypes';

export const getSdkAND = state => state.downloads.ANDROID_GA;
export const getSdkIOS = state => state.downloads.IOS_GA;
export const getSdkANDRC = state => state.downloads.ANDROID_RC;
export const getSdkIOSRC = state => state.downloads.IOS_RC;

const downloadsDefault = {
  IOS_GA: '',
  IOS_RC: '',
  ANDROID_GA: '',
  ANDROID_RC: '',
  loading: false,
  error: null,
};

const downloads = (state = downloadsDefault, action) => {
  switch (action.type) {
    case AT.FETCH_DOWNLOADS_META_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AT.FETCH_DOWNLOADS_META_SUCCEEDED:
      return action.payload.reduce(
        (sdk, value) => {
          return {
            ...sdk,
            [value.sdkType]: value,
          };
        },
        { ...state, loading: false }
      );
    case AT.FETCH_DOWNLOADS_META_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default downloads;
