import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

export default (state) => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      zScanFindings: {
        ...uiSettingsDefaults.zScanFindings,
        tableHeaders: uiSettingsDefaults.zScanFindings.tableHeaders,
      },
    },
  };
};
