import React from 'react';
import { Link } from 'react-router-dom';

export const complianceTabData = {
  column1: [
    {
      key: 'name',
      label: 'Compliance Name',
      fetch: name => {
        return name?.[0];
      },
    },
    {
      key: 'category',
      label: 'Category',
      fetch: category => {
        return (<div style={{ display: 'flex' }}>
          {category?.[0]}
        </div>);
      },
    },
    {
      key: 'subcategory',
      label: 'Subcategory',
      fetch: subcategory => {
        return subcategory?.[0];
      },
    },
    {
      key: 'description',
      label: 'Description',
      fetch: description => {
        return (<div style={{ display: 'flex', textAlign: 'left' }}>

          {description?.[0]}
        </div>
        );
      },
    },
    {
      key: 'complianceLink',
      label: 'Compliance Link',
      fetch: (complianceLink, { regnum }) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {complianceLink.map((singleLink, index) => {
              return (
                <Link
                  target="_blank"
                  to={{ pathname: singleLink }}
                  className="cursor--pointer"
                >
                  {regnum && regnum[index] ? regnum[index].trim() : singleLink}
                </Link>
              );
            })}
          </div>
        );
      },
    },
  ],
};
