import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    empty: {
      border: `1px solid ${palette.text.primary}`,
      height: 'calc(100vh - 290px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 36,
      marginTop: '20px',
    },
    formWrapper: {
      paddingTop: 15
    },
    green: {
      backgroundColor: palette.common.green,
      fontSize: 13,
      padding: '8px 15px',
      textTransform: 'uppercase',

      '&:hover': {
        backgroundColor: palette.common.avocado,
      },
    },
    navigationWarning: {
      alignItems: 'center',
      display: 'flex',

      '& svg': {
        color: palette.common.tomato,
        height: 64,
        margin: '0 24px',
        minHeight: 64,
        minWidth: 64,
        width: 64,
      },
    },
    policyDeployed: {
      alignItems: 'center',
      display: 'flex',

      '& div:first-child': {
        alignItems: 'center',
        border: `2px solid ${palette.common.avocado}`,
        borderRadius: 64,
        display: 'flex',
        fontSize: 36,
        height: 64,
        justifyContent: 'center',
        margin: '0 24px 0 0',
        width: 64,
        backgroundColor: palette.common.darkGrey,

        '& svg': {
          color: palette.common.white,
          height: 42,
          minHeight: 42,
          minWidth: 42,
          width: 42,
        },
      },
    },
  })
);

export default useStyles;
