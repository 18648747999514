import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      width: '75%',
      gap: '20px',
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${palette.background.default}`,
    },
    cardTitle: {
      alignItems: 'center',
      backgroundColor: palette.background.default,
      display: 'flex',
      flexDirection: 'column',
      fontWeight: 'bold',
      justifyContent: 'center',
      padding: '15px 0',
    },
    cardBold: {
      fontSize: 22,
      fontWeight: 'bold',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      padding: '15px 10px',
      gap: '20px',
    },
    rulesContent: {
      display: 'flex',
      flexDirection: 'row',
      gap: '20px',
    },
    androidRules: {
      display: 'flex',
      flexDirection: 'row',
      gap: '15px',
      width: '50%',
    },
    iosRules: {
      display: 'flex',
      flexDirection: 'row',
      gap: '15px',
      width: '50%',
    },
    rulesForm: {
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'center',
      width: '100%',
      gap: '5px',
      '& > label': {
        '& > span': {
          marginTop: '0px !important',
        },
      },
    },
    editableLabel: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      '& span:first-child': {
        whiteSpace: 'nowrap',
      },
      '& span:last-child': {
        wordWrap: 'break-word',
      },
    },
    osRiskIcon: {
      backgroundColor: palette.type === 'dark' ? '#30414B' : undefined,
      borderRadius: '50%',
      height: 60,
      padding: 8,
      width: 70,
      '& > svg': {
        height: '100%',
        width: '100%',
      },
    },
    osRiskIconAndroid: {
      border: `1px solid ${palette.charts.brightGreen}`,
    },
    osRiskIconIos: {
      border: `1px solid ${palette.charts.brightBlue}`,
    },
    smallLabel: {
      '& span.MuiFormControlLabel-label': {
        fontSize: 'small',
      },
    },
    label: {
      fontSize: 'small',
    },
    wrapper: {
      width: 'fit-content',
    },
    appleIcon: {
      fill: 'currentColor',
      minHeight: '25px',
      minWidth: '25px',
      stroke: 'currentColor',
    },
  })
);

export default useStyles;
