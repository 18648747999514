import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import GlobalIndicator from 'components/main/common/GlobalIndicatorWrapper';
import { editModalConfig } from 'components/modals/UserCreateEdit';
import _ from 'lodash';
import React, { Fragment } from 'react';
import momentHelper from 'utils/momentUtils';
import { toggleModalDiffered, toggleModalDirect } from 'utils/storeUtils';

export const usersColumnMapping = [
  {
    path: 'email',
  },
  {
    path: 'notificationEmail',
  },
  {
    path: 'firstName',
  },
  {
    path: 'lastName',
  },
  {
    path: 'teamName',
    csvContent: (teamNames) => teamNames,
    columnContent: ({ rowData }) => _.get(rowData, 'teamName', 'No Team'),
  },
  {
    path: 'role',
    csvContent: ({ name = '', accountBounded = false }) =>
      `${name}${accountBounded ? ' global' : ''}`,
    columnContent: ({ rowData }) => (
      <GlobalIndicator
        isGlobal={_.get(rowData, 'role.accountBounded', false)}
        text={_.get(rowData, 'role.name', '')}
      />
    ),
  },
  {
    path: 'lastLogin',
    columnContent: ({ rowData }) => {
      const lastLogin = _.get(rowData, 'lastLogin');
      return lastLogin ? momentHelper(lastLogin) : '';
    },
  },
  {
    path: 'created',
    columnContent: ({ rowData }) => {
      const created = _.get(rowData, 'created');
      return created ? momentHelper(created) : '';
    },
  },
  {
    path: 'actions',
    columnContent: ({ rowData }) => {
      return (
        <Fragment>
          <Edit
            className="cursor--pointer"
            onClick={() =>
              toggleModalDirect(
                'UserCreateEdit',
                {
                  rowData,
                  fetchUsersTablePage: rowData.fetchUsersTablePage,
                },
                editModalConfig
              )
            }
          />

          <Delete
            className="cursor--pointer"
            onClick={toggleModalDiffered('UserDeleteConfirm', {
              rowData,
              fetchUsersTablePage: rowData.fetchUsersTablePage,
            })}
          />
        </Fragment>
      );
    },
  },
];

export const usersTableHeaders = [
  {
    id: 'email',
    flex: 1,
    label: 'Email',
    minWidth: 100,
    maxWidth: 500,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'notificationEmail',
    flex: 1,
    label: 'Notification Email',
    minWidth: 100,
    maxWidth: 500,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'firstName',
    flex: 1,
    label: 'First Name',
    minWidth: 125,
    maxWidth: 500,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'lastName',
    flex: 1,
    label: 'Last Name',
    minWidth: 125,
    maxWidth: 500,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'teamName',
    filterEditor: 'SelectFilter',
    filterEditorProps: { multiple: true, wrapMultiple: false },
    flex: 1,
    label: 'Team',
    maxWidth: 500,
    minWidth: 250,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: false,
  },
  {
    id: 'role',
    flex: 1,
    label: 'Role',
    minWidth: 100,
    maxWidth: 500,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'lastLogin',
    flex: 1,
    label: 'Last Login',
    minWidth: 150,
    maxWidth: 150,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'created',
    flex: 1,
    label: 'Date Created',
    minWidth: 150,
    maxWidth: 150,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'actions',
    numeric: false,
    label: 'Actions',
    show: true,
    sort: false,
    minWidth: 125,
    maxWidth: 125,
    flex: 0,
    showInContextMenu: false,
  },
];
