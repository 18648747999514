import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import withRouter from 'components/hocs/withRouter';
import _ from 'lodash';

import { toggleModalDiffered, toggleModalDirect } from 'utils/storeUtils';
import { fetchAllAppSettings } from 'api/AppSettingsService';

// ui
import AddIcon from '@material-ui/icons/Add';
import GlobalIndicatorWrapper from 'components/main/common/GlobalIndicatorWrapper';
import ZButton from 'UI/Buttons/ZButton';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';

import AppSettingsForm from './AppSettingsForm';
import AppSettingsSelector from './AppSettingsSelector';
import { getAvailableTeamsAsSelectList } from 'reducers/TeamReducers';

const AppSettings = (props) => {
  const [appSettingsData, setAppSettingsData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [appSettingsList, setAppSettingsList] = useState([]);
  const [appSettingsName, setAppSettingsName] = useState('');
  const [selectedAppSettings, setSelectedAppSettings] = useState({});
  const [attachedApps, setAttachedApps] = useState([]);

  const { t, ready } = useTranslation();

  const formRef = useRef({});

  const selectedTeam = useSelector((state) => {
    const { available } = state.teams;

    return {
      selectedTeam: state.teams.selected,
      selectedTeamName: _.get(available[state.teams.selected], 'name'),
      availableTeams: getAvailableTeamsAsSelectList(state),
    };
  });

  const scopeBounds = useSelector((state) => {
    const { scopeBounds } = state?.user ?? '';
    return scopeBounds;
  });

  const newProps = {
    ...props,
    attachedApps,
    editMode,
    appSettingsData,
    appSettingsName,
    formRef,
    save,
    scopeBounds,
    setAppSettingsData,
    setAttachedApps,
    selectedAppSettings,
    selectedTeam,
    setEditMode,
    setAppSettingsName,
    setSelectedAppSettings,
    mtdModule: props.mtdModule,
  };

  const fetchPolicies = useCallback(
    async (teamId) => {
      try {
        const policies = await fetchAllAppSettings();
        setAppSettingsList(
          policies?.data?.[0]
            ? policies.data
                .filter(
                  ({ team }) =>
                    !team ||
                    team?.id === teamId ||
                    teamId === undefined ||
                    teamId === ''
                )
                .map(({ name, id, team }) => ({
                  accountBounded: !team?.id,
                  label: name,
                  value: id,
                }))
            : []
        );
      } catch (e) {
        console.log(e);
      }
    },
    [setAppSettingsList]
  );

  useEffect(() => {
    fetchPolicies(props?.location?.query?.teamId);
  }, [props?.location?.query?.teamId, fetchPolicies]);

  // TODO: The below useEffect should be replaced with initial/default form values when porting to TypeScript.
  // DO NOT PUT updateUrl IN THIS useEffect AS WAS DONE PREVIOUSLY IN PhishingPolicySelector.
  // DOING SO WILL CREATE AN INFINITE LOOP AND CRASH THE APPLICATION.
  useEffect(() => {
    if (props.location?.query?.appsettings && appSettingsList.length > 0) {
      const preSelectedPolicy = appSettingsList.find(p => p.value === props.location.query.appsettings);
      setSelectedAppSettings(preSelectedPolicy);
      setAppSettingsName(preSelectedPolicy?.label);
    } else {
      setSelectedAppSettings(appSettingsList?.[0] ?? undefined);
      setAppSettingsName(appSettingsList?.[0]?.label ?? undefined);
    }
  }, [props.location.query.appsettings, appSettingsList, setAppSettingsName, setSelectedAppSettings]);

  function save(values) {
    toggleModalDirect('AppSettingsSaveConfirmation', {
      headerText: t('MTD.POLICIES.APP_SETTINGS.SAVE_APP_SETTINGS'),
      policySelected: selectedAppSettings,
      payload: {
        ...values,
        name: appSettingsName,
      },
      apps: attachedApps,
      setEditMode,
      setAppSettingsName,
    });
  }

  if (!ready) {
    return null;
  }

  return (
    <div>
      <div className="view__header" style={{ marginBottom: 12 }}>
        <h1>{t('GLOBAL.APP_SETTINGS')}</h1>
        <ProtectedComponent allow={{ policies: 'manage' }}>
          <ZButton
            styleName="titleBar"
            action={toggleModalDiffered('AppSettingsCreateClone', {
              headerText: t('MTD.POLICIES.APP_SETTINGS.CREATE_NEW_APP_SETTINGS'),
              buttonText: t('GLOBAL.CREATE'),
              scopeBounds,
              selectedTeam,
              mtdModule: props.mtdModule,
              setAppSettingsName,
            })}
            buttonText={t('MTD.POLICIES.APP_SETTINGS.ADD_NEW_APP_SETTINGS')}
            color="secondary"
            icon={{ elem: AddIcon }}
          />
        </ProtectedComponent>
      </div>
      <GlobalIndicatorWrapper isGlobal inline />

      <AppSettingsSelector
        {...newProps}
        selectedAppSettings={selectedAppSettings}
        setSelectedAppSettings={setSelectedAppSettings}
        appSettingsList={appSettingsList}
      />

      <AppSettingsForm
        {...newProps}
        selectedAppSettings={selectedAppSettings}
      />
    </div>
  );
};

export default withRouter(AppSettings);
