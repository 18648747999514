import CheckIcon from '@material-ui/icons/Check';
import { EDistribution } from 'models/distribution';
import React from 'react';
import useStyles from './useStyles';

interface IDistributionProps {
  path: string;
  data: number[];
}

const Distribution: React.FC<IDistributionProps> = (props) => {
  const { path, data } = props;

  const classes = useStyles();

  let result = false;
  if (path === 'enterpriseThreats') {
    result = data.includes(EDistribution.ENTERPRISE);
  } else if (path === 'consumerThreats') {
    result = data.includes(EDistribution.CONSUMER);
  } else if (path === 'sdkThreats') {
    result = data.includes(EDistribution.SDK_ONLY);
  }

  return (
    <div className={classes.distributionColumn}>{result && <CheckIcon />}</div>
  );
};

export default React.memo(Distribution);
