import { getActiveModalAtom } from 'atoms/modals';
import Button from 'components/UI/Button';
import InputError from 'components/UI/input/InputError';
import PasswordField from 'components/UI/input/PasswordField';
import Select from 'components/UI/input/Select';
import TextField from 'components/UI/input/TextField';
import {
  Field as FormikField,
  Form as FormikForm,
  Formik,
  FormikHelpers,
} from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { AddEditCloneEMMGroups_TITLE } from '../../AddEditCloneEMMGroups';
import { IAddEditCloneEMMGroups } from '../../AddEditCloneEMMGroups/models';
import { IAuthForGroupsCreate } from '../../models';
import { IAuthFormProps } from '../models';
import { initialValues } from './initialValues';
import { IStandardAuth, IStandardFormData } from './models';
import Schema from './schema';
import useStyles from './useStyles';
import { createConnection, updateConnection } from './utils';
import { useTranslation } from 'react-i18next';

const activeModalAtom =
  getActiveModalAtom<IAddEditCloneEMMGroups<IStandardAuth>>();

const StandardForm: React.FC<IAuthFormProps> = ({
  availableTeams,
  connector,
  data,
  handleBackClick,
  handleCancelClick,
  mode = 'ADD',
  selectedTeamId,
}) => {
  const { t, ready } = useTranslation();
  const classes = useStyles();
  const [authError, setAuthError] = useState<string>('');
  const setActiveModal = useSetRecoilState(activeModalAtom);
  const proxyOptions = useMemo(
    () =>
      connector?.proxyOptions?.[0]
        ? [
            { label: 'None', value: null },
            ...connector.proxyOptions.map(({ id, name }) => ({
              label: name,
              value: id,
            })),
          ]
        : [{ label: 'None', value: null }],
    [connector?.proxyOptions]
  );

  const resolvedInitialValues = initialValues(
    mode,
    data?.rowData,
    connector,
    proxyOptions,
    availableTeams,
    selectedTeamId
  );

  const handleSubmit = useCallback(
    (
      formData: IStandardFormData,
      formikHelpers: FormikHelpers<IStandardFormData>
    ) => {
      switch (mode) {
        case 'ADD':
          createConnection(
            formData,
            formikHelpers,
            setAuthError,
            (authForGroupsCreate: IAuthForGroupsCreate<IStandardAuth>) =>
              setActiveModal({
                active: AddEditCloneEMMGroups_TITLE,
                payload: { authForGroupsCreate, connector, mode },
              }),
            t
          );
          break;
        case 'EDIT':
          updateConnection(
            formData,
            formikHelpers,
            setAuthError,
            () =>
              setActiveModal(() => ({
                active: AddEditCloneEMMGroups_TITLE,
                payload: {
                  cloneInfoFromAuth: {
                    connectionIdToClone: data?.rowData?.id ?? '',
                    name: formData?.name ?? '',
                    teamId: (formData?.team?.value as string) ?? '',
                  },
                  connector,
                  data,
                  mode,
                },
              })),
            t
          );
          break;
        case 'CLONE':
          setActiveModal(undefined); // First clear out data so it doesn't show up in groups table.
          setActiveModal(() => ({
            active: AddEditCloneEMMGroups_TITLE,
            payload: {
              cloneInfoFromAuth: {
                connectionIdToClone: data?.rowData?.id ?? '',
                name: formData?.name ?? '',
                teamId: (formData?.team?.value as string) ?? '',
              },
              connector,
              data: undefined,
              mode,
            },
          }));
          break;
      }
    },
    [mode, setActiveModal, connector, data, setAuthError, t]
  );

  if (!ready) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={resolvedInitialValues}
      onSubmit={handleSubmit}
      validateOnBlur
      validationSchema={Schema(t)}
    >
      {({ isSubmitting, setFieldValue }) => (
        <FormikForm>
          <input name="connectorId" type="hidden" />
          <FormikField
            component={TextField}
            disabled={isSubmitting}
            label={t('MTD.INTEGRATIONS.CONNECTION_NAME')}
            name="name"
          />
          <FormikField
            component={Select}
            disableClearable={true}
            disabled={mode === 'EDIT'}
            label={t('GLOBAL.TEAM')}
            multiple={false}
            name="team"
            options={availableTeams ?? []}
            setFieldValue={setFieldValue}
            type="select"
          />
          <FormikField
            component={TextField}
            disabled={isSubmitting || mode === 'CLONE'}
            label={t('GLOBAL.URL')}
            name="baseUrl"
          />
          {connector?.id === 'MobiControlConnector' && (
            <FormikField
              component={TextField}
              disabled={isSubmitting || mode === 'CLONE'}
              label={t('GLOBAL.API_KEY')}
              name="api_key"
            />
          )}
          <FormikField
            component={TextField}
            disabled={isSubmitting || mode === 'CLONE'}
            label={t('MTD.INTEGRATIONS.MDM_USERNAME')}
            name="user_id"
          />
          <FormikField
            component={PasswordField}
            disabled={isSubmitting || mode === 'CLONE'}
            label={t('MTD.INTEGRATIONS.MDM_PASSWORD')}
            name="password"
          />
          {connector?.id === 'UemConnector' && (
            <FormikField
              component={Select}
              disableClearable={true}
              disabled={isSubmitting || mode === 'CLONE'}
              label={t('MTD.INTEGRATIONS.BLACK_BERRY_PROXY')}
              multiple={false}
              name="proxyConfig"
              options={proxyOptions}
              setFieldValue={setFieldValue}
              type="select"
            />
          )}
          {!!authError && <InputError override={authError} />}
          <div className={classes.buttons}>
            {mode === 'ADD' && (
              <Button
                className={classes.buttonBackgroundColor}
                disabled={isSubmitting}
                onClick={handleBackClick}
                text={t('GLOBAL.BACK')}
                type="button"
              />
            )}
            <Button
              className={classes.buttonBackgroundColor}
              disabled={isSubmitting}
              onClick={handleCancelClick}
              text={t('GLOBAL.CANCEL')}
              type="button"
            />
            <Button
              color="secondary"
              disabled={isSubmitting}
              text={t('GLOBAL.NEXT')}
              type="submit"
            />
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default StandardForm;
