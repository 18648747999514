import { useCallback, useEffect, useState } from 'react';
import { IZScanPolicyData, IZScanPolicyEntriesHash } from './models';
import { getPolicy } from 'api/zDevService';
import { useRecoilState } from 'recoil';
import { zScanPolicyEntriesHash, zScanActivePolicyId } from './atoms';

export default (policyId: string | unknown) => {
  const [policyData, setPolicyData] = useState<IZScanPolicyData>();
  const [zScanPolicyEntry, setzScanPolicyEntry] = useRecoilState(
    zScanPolicyEntriesHash
  );
  const [activePolicyId, setActivePolicyId] =
    useRecoilState(zScanActivePolicyId);

  const fetchPolicy = useCallback(async () => {
    const { data } = await getPolicy({ policyId });
    const policyEntriesFromApi = data?.policyEntries.reduce(
      (accum: any, value: any) => {
        return {
          ...accum,
          [value?.findingId]: { ...value },
        };
      },
      {} as IZScanPolicyEntriesHash
    );
    // when the policy id changes, overwrite the zscanPolicyEntry recoil atom
    if (policyId !== activePolicyId) {
      setActivePolicyId(policyId as string);
      setzScanPolicyEntry({ ...policyEntriesFromApi });
      return setPolicyData(data);
    }
    setzScanPolicyEntry({ ...policyEntriesFromApi, ...zScanPolicyEntry });
    return setPolicyData(data);
  }, [
    activePolicyId,
    policyId,
    setzScanPolicyEntry,
    setActivePolicyId,
    zScanPolicyEntry,
  ]);

  useEffect(() => {
    if (!policyId) {
      return;
    }

    fetchPolicy();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyId]);

  return { policyData, fetchPolicy };
};
