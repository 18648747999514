import React from 'react';
import _ from 'lodash';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
// components
import GlobalIndicator from 'components/main/common/GlobalIndicatorWrapper';
import MultiSelectButton from './MultiSelectButton';

const generateSelectedLabel = (values, buttonPlaceholder) => {
  if (Array.isArray(values)) {
    return values.length
      ? values.map((singleValue, index) => {
        return (
            <GlobalIndicator
              key={`${singleValue.value} ${index}`}
              inline
              isGlobal={_.get(singleValue, 'accountBounded', false)}
              showText={true}
              text={singleValue.label}
            />
        );
      })
      : buttonPlaceholder;
  }

  if (typeof values === 'object' && !_.isEmpty(values)) {
    return (
      <GlobalIndicator
        inline
        isGlobal={_.get(values, 'accountBounded', false)}
        showText={true}
        text={values.label}
      />
    );
  }
  return buttonPlaceholder;
};

export function GlobalMultiSelectButton(props) {
  return (
    <MultiSelectButton toggleOpen={props.toggleOpen}>
      {generateSelectedLabel(props.data.values, props.buttonPlaceholder)}
      <ArrowDropDown className={props.classes.buttonDown} />
    </MultiSelectButton>
  );
}
export default GlobalMultiSelectButton;
