import { IConnector } from '../../models';
import MUIInfoIcon from '@material-ui/icons/InfoOutlined';
import useStyles from './useStyles';
import cc from 'classcat';
import { TFunction } from 'react-i18next';
export interface IResponseActionGroupTooltipProps {
  connector?: IConnector;
  setResponseActionTooltip: React.Dispatch<React.SetStateAction<boolean>>;
  showResponseActionTooltip: boolean;
  t: TFunction<'translation', undefined>;
}
export const ResponseActionGroupTooltip: React.FC<
  IResponseActionGroupTooltipProps
> = ({ connector, setResponseActionTooltip, showResponseActionTooltip, t }) => {
  const classes = useStyles();
  return (
    <div className={cc([classes.infoTooltip, classes.actionTooltip])}>
      <h2>{t('MTD.INTEGRATIONS.RESPONSE_GROUP_TITLE')}</h2>
      <div
        className={classes.tooltip}
        onMouseEnter={() => setResponseActionTooltip?.(true)}
        onMouseLeave={() => setResponseActionTooltip?.(false)}
      >
        <MUIInfoIcon color="primary" />
        {showResponseActionTooltip && (
          <div className={classes.tooltipText}>
            {connector?.id === 'GoogleAdminConnector'
              ? t('MTD.INTEGRATIONS.RESPONSE_ORGANIZATIONAL_UNITS_DESCRIPTION')
              : t('MTD.INTEGRATIONS.RESPONSE_GROUPS_DESCRIPTION')}
          </div>
        )}
      </div>
    </div>
  );
};
export default ResponseActionGroupTooltip;
