import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { fetchAllScopes } from 'api/RolesService';
import withRouter from 'components/hocs/withRouter';
import GlobalIndicator from 'components/main/common/GlobalIndicatorWrapper';
import { createModalConfig } from 'components/modals/RolesCreateEdit';
import Table from 'components/RDGTables';
import { memoizeTableQuery } from 'components/RDGTables/utils';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  rolesTableColumnChange,
  updateUISettings,
} from 'reducers/UiSettingsReducers';
import {
  getUISettingsTableHeaders,
  getUISettingsWithout,
} from 'reducers/UiSettingsSelectors';
import { bindActionCreators, compose } from 'redux';
import ZButton from 'UI/Buttons/ZButton';
import ReactToCSV from 'utility/ReactCSVDownloader';
import { toggleModalDiffered } from 'utils/storeUtils';
import useBackfill from 'utils/useBackfill';
import { withDirtyState } from 'utils/withDirtyState';
import withMemoizedProps from 'utils/withMemoizedProps';
import ProtectedComponent from '../../protected/ProtectedComponent';
import { rolesColumnMapping } from './roles.mapping';
import tableQuery from './tableQuery';

const Roles = ({
  classes,
  currentTableHeaders,
  definedUser,
  jiggleDirtyState,
  q: query,
  rolesTableColumnChange,
  rolesUiSettings,
  rqps,
  updateUISettings,
  updateUrl,
}) => {
  useBackfill({
    query,
    requiredQueryParams: rqps,
    storedQueryParams: rolesUiSettings,
    updateUrl,
  });

  const availableScopes = memoizeTableQuery(fetchAllScopes);

  const handleColumnChange = (...args) => {
    jiggleDirtyState();
    rolesTableColumnChange(...args);
  };

  const onPaginationChange = (update) => {
    updateUISettings({
      domain: 'roles',
      update,
    });

    updateUrl(update);
  };

  return (
    <div>
      <div className="view__header">
        <h1>Role Management</h1>
        <ProtectedComponent allow={{ users: 'manage' }}>
          <ZButton
            styleName="titleBar"
            action={toggleModalDiffered(
              'RolesCreateEdit',
              {
                defaultScopes: availableScopes,
              },
              createModalConfig
            )}
            buttonText="Add New Role"
            color="secondary"
            icon={{ elem: AddIcon }}
            proLabel
          />
        </ProtectedComponent>
      </div>
      <div style={{ marginBottom: 15 }}>
        <GlobalIndicator isGlobal inline />
      </div>
      <Table
        classes={classes}
        columnHeaders={currentTableHeaders}
        defaultSortInfo={{}}
        footerComponents={() => (
          <ReactToCSV
            buttonText="exportCSV"
            dataMap={rolesColumnMapping}
            fileName="roles-table"
            header={currentTableHeaders.filter(
              (column) => column.id !== 'actions'
            )}
            table={() => tableQuery(query, true)}
          />
        )}
        heightBuffer={330}
        definedUser={definedUser}
        fetchTableData={() => tableQuery(query)}
        isSuperUser={definedUser === 'superAdminUser'}
        onColumnChange={handleColumnChange}
        onPaginationChange={onPaginationChange}
        query={query}
        rowMapping={rolesColumnMapping}
        tableId="Roles"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  createEditModal: _.get(state, 'ui.modals.RolesCreateEdit'),
  currentTableHeaders: getUISettingsTableHeaders(state, 'roles'),
  rolesUiSettings: getUISettingsWithout(state, 'roles', ['tableHeaders']),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUISettings,
      rolesTableColumnChange,
    },
    dispatch
  );

const styles = () => ({
  actionIcon: {
    cursor: 'pointer',
  },

  editLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
});

export default compose(
  withStyles(styles),
  withRouter,
  withDirtyState(),
  connect(mapStateToProps, mapDispatchToProps)
)(
  withMemoizedProps(Roles, [
    'createEditModal',
    'currentTableHeaders',
    'dirtyState',
    'q',
    'rolesUiSettings',
    'updateUISettings',
  ])
);
