import React from 'react';
import withRouter from 'components/hocs/withRouter';
import _ from 'lodash';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

// ui
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ZButton from 'UI/Buttons/ZButton';

// components

// services and actions
import { cloneSelectedPlan } from 'api/PlansService';
import { FormikTextField } from 'components/inputs/FormikTextField';
import {
  csvInjectionRegex,
  csvInjectionErrorMessage,
} from 'utils/componentUtils';
import { toggleModalDiffered, toggleModalDirect } from '../../utils/storeUtils';

const PlansCloneModal = props => {
  const handleSubmit = values => {
    // clone
    cloneSelectedPlan(
      { planId: props.data.id },
      {
        name: values.name,
        description: values.description,
        username: props.username,
      }
    )
      .then(({ data }) => {
        toggleModalDirect('PlansClone', false);
        props.history.replace(`/admin/plans/edit/${data.id}`);
      })
      .catch(error => {
        console.log('Error in plan clone: ', error);
      });
  };

  return (
    <DialogContent>
      <Formik
        initialValues={{
          name: _.get(props, 'data.name', ''),
        }}
        validationSchema={PlanSchema}
        validateOnBlur
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting, values }) => {
          return (
            <Form>
              <Field name="name" label="Name" component={FormikTextField} />
              <Field
                name="description"
                label="Description"
                component={FormikTextField}
                // multiline
              />

              <DialogActions>
                <ZButton
                  styleName="modalCancel"
                  action={toggleModalDiffered('PlansClone', false)}
                  color="secondary"
                  buttonText="Cancel"
                />
                <ZButton
                  buttonType="submit"
                  color="primary"
                  styleName="modalSave"
                  buttonText="Clone Plan"
                />
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </DialogContent>
  );
};

const PlanSchema = Yup.object().shape({
  name: Yup.string()
    .min(1)
    .matches(csvInjectionRegex, csvInjectionErrorMessage)
    .required('Required'),

  description: Yup.string().matches(
    csvInjectionRegex,
    csvInjectionErrorMessage
  ),
});

export default withRouter(PlansCloneModal);
