import { makeStyles, createStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import Tabs from 'components/UI/Tabs';
import ExpansionRowDetails from 'components/Tables/ExpansionRowDetails';
import { complianceTabData } from './complianceTable.mappings';
import GenericCard from 'components/UI/GenericCard';
import { useMemo } from 'react';

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    childWrapper: {
      height: 'none',
    },
    card: { paddingLeft: 20, height: 400, overflow: 'auto' },
    labelValue: {
      // color: palette.tables.cell,
      marginLeft: 12,
      paddingRight: 8,
      fontSize: 13,
      width: '80%',
      display: 'flex',
      textAlign: 'left',
      flexWrap: 'wrap',
      // whiteSpace: 'break-spaces',
      // alignSelf: 'flex-end',
    },
    dataWrapper: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      marginBottom: 20,
      justifyContent: 'space-between',
      textWrap: 'balance',
    },
    wrapper: {
      display: 'flex',
      marginLeft: 75,
      paddingTop: '20px',
      alignSelf: 'flex-start',
      flexDirection: 'column',
    },
    label: {
      color: palette.text.secondary,
      fontWeight: 'bold',
      width: '25%',
      display: 'flex',
      justifyContent: 'space-between',
      textWrap: 'nowrap',
    },
  })
);

export default function ComplianceTable(props) {
  const classes = useStyles();

  /*
  IdealStructure = {
    CWE: {
      id: ['cwe', 'cwe'],
      desc: ['long one here', 'another one here'],
      link: ['http://comp.con', 'http://anotherlink'],
    },
    GDPR: {
      id: ['cwe', 'cwe'],
      desc: ['long one here', 'another one here'],
      link: ['http://comp.con', 'http://anotherlink'],
    },
  };
*/
  function addFieldValues(value, fullSet) {
    const dataObj = {};
    if (!fullSet) {
      Object.keys(value).forEach((key) => {
        dataObj[key] = [value[key]];
      });
      return dataObj;
    }
    // take care of duplicate id's.  i.e. GDPR
    Object.keys(fullSet).forEach((key) => {
      if (value[key]) {
        dataObj[key] = [...fullSet[key], value[key]];
      }
    });
    // cover any missing from the previous one
    Object.keys(value).forEach((key) => {
      if (!dataObj[key]) {
        dataObj[key] = value[key];
      }
    });
    return dataObj;
  }
  const newDataStructure = props.complianceArray.reduce((accum, value) => {
    return {
      ...accum,
      [value.id]: addFieldValues(value, accum[value.id]),
    };
  }, {});

  const tabContent = useMemo(
    () =>
      Object.keys(newDataStructure).map((singleComp) => {
        const data = newDataStructure[singleComp];
        return {
          title: singleComp,
          content: (
            <ExpansionRowDetails
              dataProperty="column1"
              config={complianceTabData}
              classList={classes}
              data={newDataStructure[singleComp]}
            />
          ),
        };
      }),
    [props?.complianceArray]
  );

  if (props?.complianceArray?.length === 0) {
    return null;
  }

  return (
    <GenericCard noPadding>
      <Tabs tabs={tabContent} />
    </GenericCard>
  );
}
