import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import ZButton from 'UI/Buttons/ZButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Spinner from '../main/common/Spinner';

const UploadProgress = ({
  handleCancelUploadRequest,
  fileLoaded,
  isShowing,
  showSpinner,
}) => {
  // need a spinner as well.  at times the progresss hits 100 but hangs on response from service
  if (!isShowing) {
    return null;
  }
  // need a spinner as well.  at times the progresss hits 100 but hangs on response from service
  return (
    <Fragment>
      <LinearProgress
        color="secondary"
        variant="determinate"
        value={fileLoaded}
      />
      {showSpinner && (
        <div
          style={{ display: 'flex', justifyContent: 'center', paddingTop: 10 }}
        >
          <Spinner />
        </div>
      )}
      <DialogActions>
        <ZButton
          action={handleCancelUploadRequest}
          color="primary"
          styleName="modalSave"
          buttonText="Cancel Upload"
        />
      </DialogActions>
    </Fragment>
  );
};

UploadProgress.propTypes = {
  isShowing: PropTypes.bool,
  handleCancelUploadRequest: PropTypes.func,
  showSpinner: PropTypes.bool,
  fileLoaded: PropTypes.number.isRequired,
};

UploadProgress.defaultProps = {
  isShowing: false,
  showSpinner: true,
};

export default UploadProgress;
