import jws from 'jws';

// Returns boolean.  Doesn't perform signature validation.
function isValid(payload) {
  const current = Math.floor(Date.now() / 1000);
  return !(current >= payload.exp);
}

function getPayload(token) {
  return decodeAndPullPayload(token);
}

function decodeAndPullPayload(token) {
  return new Promise((resolve, reject) => {
    const decoded = jws.decode(token);

    if (decoded) {
      try {
        const payload = JSON.parse(decoded.payload);
        return resolve(payload);
      } catch (error) {
        return reject(new Error(error));
      }
    }

    return reject(new Error('Decoded Token is Null'));
  });
}

export default {
  isValid,
  getPayload,
  decodeAndPullPayload,
};
