import { getActiveModalAtom } from 'atoms/modals';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import Modal from 'components/UI/Modal';
import { hasChanges as hasChangesAtom } from 'atoms/policies';
import { useSetRecoilState } from 'recoil';
import useStyles from './useStyles';
import Button from 'components/UI/Button';
import { APCCreateNewPolicy_TITLE } from '../APCCreatePolicyModal';
import React, { useCallback, useEffect, useState } from 'react';
import {
  showAffectedAppsHeader,
  showAppsAffectedRowMapping,
} from './affectedApps.mapping';
import SearchBox from 'components/UI/input/SearchBox';
import Table from 'components/UI/Table';
import { ILocationQuery } from 'components/UI/Table/models';
import tableQuery from './tableQuery';
import AffectedDevicesTable from './AffectedDevicesTable';
import {
  IAffectedApps,
  IAffectedAppsRowSelected,
  IQueryParams,
} from './models';
import { updateUISettings } from 'reducers/UiSettingsReducers';
import {
  ITableHeaderSort,
  TTableHeaderHelperArgList,
} from './AffectedDevicesTable/models';
import { tableHeaderHelper } from './AffectedDevicesTable/utils';
import useAppCharacteristics from '../../AppCharacteristicProvider';
import {
  IActiveModalAffectedAppsDevices,
  ITrigger,
} from '../APCCreatePolicyModal/models';

export const AffectedAppsAndDevices_TITLE = 'AffectedAppsAndDevices';
const activeModalAtom = getActiveModalAtom<IActiveModalAffectedAppsDevices>();
const appStoreKey = 'appPolicyAffectedDevices';
const appStoreKeyWithCheckboxes = 'appPolicyAffectedAppsWithSelection';

const AffectedAppsAndDevices: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [totalDataCount, setTotalDataCount] = useState<number>(0);
  const [toggleAffectedDevices, setToggleAffectedDevices] =
    useState<boolean>(true);
  const [selectedRowPayload, setSelectedRowPayload] = useState<IAffectedApps[]>(
    []
  );
  const setHasChanges = useSetRecoilState(hasChangesAtom);
  const [data, setActiveModal] = useRecoilState(activeModalAtom);

  const {
    fullTriggerRules: existingJsonTriggerRules,
    setFullTriggerRules: setJsonTriggerRules,
    setDeviceApiCall,
    affectedAppsPostDataPayload,
    appCharFormData,
  } = useAppCharacteristics();
  const [tableheaders, setTableheaders] = useState(showAffectedAppsHeader);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  const [tableSelectedRows, setTableSelectedRows] = useState<
    IAffectedAppsRowSelected | Record<string, never>
  >({});
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [sortByQuery, setSortByQuery] = useState<string>('name.keyword,asc');
  const [order, setOrder] = useState<string>('asc');
  const [pageSize, setPageSize] = useState<number>(25);

  const [pageNumber, setPageNumber] = useState<string | number | undefined>(0);
  // when resetting apps w/ the button, this clears out the selection
  useEffect(() => {
    if (toggleAffectedDevices) {
      setSelectedRowPayload([]);
      setIsAllSelected(false);
    }
  }, [toggleAffectedDevices]);

  const handleClose = useCallback(() => {
    setToggleAffectedDevices(true);
    setSelectedRowPayload([]);
    setActiveModal(undefined);
    setSearchQuery(undefined);
    setIsAllSelected(false);
    setTableSelectedRows({});
  }, [setActiveModal]);

  const handlePrevious = useCallback(() => {
    if (!data?.payload) {
      return;
    }
    setActiveModal({
      active: APCCreateNewPolicy_TITLE,
      payload: {
        ...data?.payload,
        all: data?.payload?.allTriggers?.map((e: ITrigger) => e?.name) ?? [],
        any: data?.payload?.anyTriggers?.map((e: ITrigger) => e?.name) ?? [],
      },
    });
    setSearchQuery(undefined);
    setSelectedRowPayload([]);
    setToggleAffectedDevices(true);
  }, [setActiveModal, data]);

  const handleFinish = useCallback(() => {
    if (!data?.payload) {
      return;
    }
    setSearchQuery(undefined);
    const timeStamp = new Date();
    const timeStampNumeric = timeStamp.valueOf();
    const action = data?.payload?.action;
    let hasRuleChanges = false;
    let isAnyTriggersTheSame = true;
    let isAllTriggersTheSame = true;
    let hasFormChanges = false;
    if (!existingJsonTriggerRules) {
      return;
    }
    let existingTriggerRulesFromAtom = existingJsonTriggerRules;
    if (data?.payload?.id) {
      existingTriggerRulesFromAtom = existingJsonTriggerRules.filter(
        (rule) => rule.id !== data?.payload?.id
      );
    }
    let oldRuleTriggers = undefined;
    if (action === 'edit') {
      oldRuleTriggers = existingJsonTriggerRules.find(
        (rule) => rule.id === data?.payload?.id
      );
      const oldAnyTriggers = oldRuleTriggers?.any.map(
        (triggerString) => triggerString ?? ''
      ) ?? [''];
      const oldAllTriggers = oldRuleTriggers?.all.map(
        (triggerString) => triggerString ?? ''
      ) ?? [''];
      let newAnyTriggers = [] as string[];
      if (data?.payload?.anyTriggers?.length) {
        newAnyTriggers = data?.payload?.anyTriggers.map(
          (trigger: ITrigger) => trigger.name ?? ''
        );
      }
      let newAllTriggers = [] as string[];
      if (data?.payload?.allTriggers?.length) {
        newAllTriggers = data?.payload.allTriggers.map(
          (trigger: ITrigger) => trigger.name ?? ''
        );
      }

      isAnyTriggersTheSame = _.isEqual(newAnyTriggers, oldAnyTriggers);
      isAllTriggersTheSame = _.isEqual(oldAllTriggers, newAllTriggers);
      hasFormChanges =
        appCharFormData?.name !== data?.payload.name ||
        appCharFormData?.description !== data?.payload.description;

      hasRuleChanges =
        !isAnyTriggersTheSame || !isAllTriggersTheSame || hasFormChanges;
    }
    if (action !== 'viewOnly') {
      setJsonTriggerRules([
        ...existingTriggerRulesFromAtom,
        {
          id: data?.payload?.id ?? timeStampNumeric.toString(), // check if id exists
          name: data?.payload?.formData.name,
          description: data?.payload?.formData.description,
          all: data?.payload?.allTriggers?.map((e: ITrigger) => e?.name) || [],
          any: data?.payload?.anyTriggers?.map((e: ITrigger) => e?.name) || [],
          createdBy: data?.payload?.email,
          enabled:
            data?.payload?.enabled && data?.payload.action === 'edit'
              ? data?.payload?.enabled
              : false,
          modified: !hasRuleChanges ? data?.payload.modified : timeStampNumeric,
          created: timeStampNumeric,
          deviceCount: data?.payload.devicesCount,
          appCount: data?.payload?.appsCount,
          modifiedBy: !hasRuleChanges
            ? data?.payload?.modifiedBy
            : data?.payload?.email,
        },
      ]);
    }
    setActiveModal(undefined);

    if ((action === 'edit' && !hasRuleChanges) || action === 'viewOnly') {
      // check if rule changed
      setHasChanges(false);
    } else {
      setHasChanges(true);
    }
    setSearchQuery(undefined);
    setSelectedRowPayload([]);
    setToggleAffectedDevices(true);
  }, [
    data?.payload,
    existingJsonTriggerRules,
    setJsonTriggerRules,
    setHasChanges,
    setActiveModal,
    appCharFormData,
  ]);
  const handleSearch = useCallback(
    (value: string) => {
      setIsAllSelected(false);
      setSearchQuery(value);
    },
    [setSearchQuery]
  );
  const handleSelectionChange = (selectedRows: IAffectedAppsRowSelected) => {
    const selectedRowCount = Object.keys(selectedRows).length;
    if (selectedRowCount === 0 || isAllSelected) {
      setSelectedRowPayload([]);
      setTableSelectedRows({});
      return setIsAllSelected(false);
    }

    if (selectedRowCount - selectedRowPayload.length > 1 && !searchQuery) {
      setTableSelectedRows(selectedRows);
      setSearchQuery(undefined);
      return setIsAllSelected(true);
    }
    setTableSelectedRows(selectedRows);
    return setSelectedRowPayload(Object.values(selectedRows));
  };

  const handleToggleAffectedDeviceApps = useCallback(() => {
    if (isAllSelected) {
      setDeviceApiCall(true);
    } else {
      setDeviceApiCall(false);
    }
    if (!!tableSelectedRows) {
      setTableSelectedRows({});
    }
    setToggleAffectedDevices(!toggleAffectedDevices);
    setSearchQuery(undefined);
  }, [
    isAllSelected,
    tableSelectedRows,
    setToggleAffectedDevices,
    setDeviceApiCall,
    toggleAffectedDevices,
    // searchQuery,
  ]);
  const handleColumnChange = (...args: TTableHeaderHelperArgList) => {
    const newTableheaders = tableHeaderHelper(args);
    setTableheaders(newTableheaders);
  };

  const handleSortQuery = (columnHeaderInfo: ITableHeaderSort) => {
    if (isAllSelected && toggleAffectedDevices) {
      return;
    }
    setOrder(columnHeaderInfo.dir === 1 ? 'asc' : 'desc');
    setSortByQuery(
      `${columnHeaderInfo.id},${columnHeaderInfo?.dir === 1 ? 'asc' : 'desc'}`
    );
  };
  const onPaginationChange = useCallback((update: IQueryParams) => {
    if ('page' in update) {
      setPageNumber(Number(update?.page ?? 0));
    }
    if ('size' in update) {
      setPageSize(Number(update?.size));
    }
    updateUISettings({
      domain: appStoreKey,
      update,
    });
  }, []);

  return (
    <Modal
      caption={t('MTD.POLICIES.APP_POLICY.APP_CHAR.AFFECTED_APPS_AND_DEVICES')}
      title={AffectedAppsAndDevices_TITLE}
      className={classes.modal}
      onClose={handleClose}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '30px',
          marginTop: '20px',
          marginBottom: 20,
        }}
      >
        <h2>{t('MTD.POLICIES.APP_POLICY.APP_CHAR.AFFECTED_APPS')}</h2>
        {toggleAffectedDevices && (
          <SearchBox onInputChange={handleSearch} disabled={isAllSelected} />
        )}
        {toggleAffectedDevices && (
          <Table
            height={300}
            tableId={appStoreKeyWithCheckboxes}
            serverSort={true}
            classList={classes}
            columnHeaders={tableheaders}
            footerComponents={() => (
              <div style={{ paddingBottom: '40px' }}>
                {isAllSelected
                  ? 'all ' + totalDataCount + ' apps selected'
                  : selectedRowPayload.length + ' apps selected'}
              </div>
            )}
            fetchTableData={() =>
              tableQuery(affectedAppsPostDataPayload, setTotalDataCount, {
                page: Number(pageNumber),
                size: pageSize,
                sort: sortByQuery,
                search: searchQuery,
              })({
                page: Number(pageNumber),
                size: pageSize,
                sort: sortByQuery,
                search: searchQuery,
              } as unknown as ILocationQuery)
            }
            onPaginationChange={onPaginationChange}
            onColumnChange={handleColumnChange}
            selected={tableSelectedRows}
            onSortInfoChange={handleSortQuery}
            query={
              {
                page: Number(pageNumber) || '0',
                size: pageSize,
                sort: sortByQuery,
                order,
                search: searchQuery,
              } as unknown as ILocationQuery
            }
            rowMapping={showAppsAffectedRowMapping(t)}
            heightBuffer={290}
            checkboxColumn
            pagination={!isAllSelected}
            onSelectionChange={
              !!toggleAffectedDevices ? handleSelectionChange : undefined
            }
          />
        )}
      </div>
      {!toggleAffectedDevices &&
        (selectedRowPayload?.length > 0 || isAllSelected) && (
          <Table
            height={300}
            serverSort={isAllSelected}
            heightBuffer={290}
            columnHeaders={tableheaders}
            classList={classes}
            footerComponents={() => (
              <div>
                {isAllSelected
                  ? 'all ' + totalDataCount + ' apps selected'
                  : selectedRowPayload.length + ' apps selected'}
              </div>
            )}
            query={
              {
                page: Number(pageNumber) || '0',
                size: pageSize,
                sort: sortByQuery,
              } as unknown as ILocationQuery
            }
            onColumnChange={handleColumnChange}
            fetchTableData={
              isAllSelected
                ? () =>
                    tableQuery(affectedAppsPostDataPayload, setTotalDataCount, {
                      page: Number(pageNumber) || 0,
                      size: pageSize,
                      sort: sortByQuery,
                      search: searchQuery,
                    })({
                      page: Number(pageNumber) || 0,
                      size: pageSize,
                      sort: sortByQuery,
                      search: searchQuery,
                    } as unknown as ILocationQuery)
                : () => selectedRowPayload
            }
            pagination={isAllSelected}
            onPaginationChange={onPaginationChange}
            onSortInfoChange={handleSortQuery}
            rowMapping={showAppsAffectedRowMapping(t)}
            tableId={appStoreKey}
          />
        )}
      <div style={{ marginTop: '30px' }}>
        <Button
          color="secondary"
          text={
            toggleAffectedDevices
              ? t('MTD.POLICIES.APP_POLICY.APP_CHAR.SHOW_AFFECTED_DEVICES')
              : t('MTD.POLICIES.APP_POLICY.APP_CHAR.RESET_APP_SEARCH_FILTER')
          }
          disabled={selectedRowPayload?.length === 0 && !isAllSelected}
          onClick={handleToggleAffectedDeviceApps}
        />
      </div>
      <div style={{ marginBottom: '30px' }}>
        <AffectedDevicesTable
          selectedApps={selectedRowPayload}
          hide={toggleAffectedDevices}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingBottom: 20,
        }}
      >
        <Button
          color="secondary"
          text={t('GLOBAL.PREVIOUS')}
          onClick={handlePrevious}
        />
        <Button
          color="secondary"
          text={t('GLOBAL.FINISH')}
          onClick={handleFinish}
        />
      </div>
    </Modal>
  );
};

export default AffectedAppsAndDevices;
