import * as Yup from 'yup';
import {
  csvInjectionRegex,
  csvInjectionErrorMessage,
} from 'utils/componentUtils';
import { TFunction } from 'react-i18next';

const Schema = (t: TFunction<'translation', undefined>) => {
  return Yup.object().shape({
    name: Yup.string()
      .matches(csvInjectionRegex, csvInjectionErrorMessage)
      .required(t('GLOBAL.REQUIRED_NAME')),
    user_id: Yup.string()
      .matches(csvInjectionRegex, csvInjectionErrorMessage)
      .required(t('MTD.VALIDATIONS.MDM_USER_EMAIL_IS_REQUIRED')),
    certificate: Yup.string()
      .matches(csvInjectionRegex, csvInjectionErrorMessage)
      .when('modalMode', {
        is: 'ADD',
        then: Yup.string().required(
          t('MTD.VALIDATIONS.CERTIFICATE_UPLOAD_REQUIRED')
        ),
      }),
    team: Yup.mixed().test({
      name: 'team-required-if-all-teams',
      // function keyword used below because we need access to "this"
      test: function (value, ctx) {
        if (!!ctx?.parent?.selectedTeamId) {
          return true;
        }

        return !value
          ? this?.createError({
              message: t('GLOBAL.REQUIRED_TEAM'),
              path: 'team',
            })
          : true;
      },
    }),
  });
};

export default Schema;
