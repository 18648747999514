import MUIAppsIcon from '@material-ui/icons/Apps';
import MUIWifiIcon from '@material-ui/icons/Wifi';
import PhoneAndroidIcon from 'components/UI/icons/PhoneAndoidIcon';
// @ts-ignore
import qs from 'qs';
import { IQueryParams } from '../common/models';
import { IIconMap } from './models';

// The below maps vector types to icons
export const ICON_MAP: IIconMap = {
  1: <MUIWifiIcon />, // Network
  2: <PhoneAndroidIcon />, // Device
  3: <MUIAppsIcon />, // Malware
};

export const generateQuery = (
  query: IQueryParams,
  matchUrl: string,
  vectorType?: number,
  severity?: number
) => {
  const { ...queryParams } = query;
  let newQuery;
  if (Number(vectorType) > 0) {

    newQuery = qs.stringify(
      {
        ...queryParams,
        severity,
        vector: vectorType
      },
      { addQueryPrefix: true }
    );
  } else if (Number(vectorType) === 0) {
    newQuery = qs.stringify(
      {
        ...queryParams,
        severity,
      },
      { addQueryPrefix: true }
    );
  }

  return `${matchUrl}${newQuery}`;
};
