import { ServiceHandlerFactory } from 'utils/serviceUtils';
import { fetchGroupActivationsParamsMapping } from 'components/main/activations/GroupActivations.mapping';

const groupActivationsBase = 'api/zapp/v1/group-activations';

export const fetchAllGroupActivations = ServiceHandlerFactory({
  axios: {
    url: groupActivationsBase,
  },
  paramSchema: fetchGroupActivationsParamsMapping,
});

export const fetchGroupActivationById = ServiceHandlerFactory({
  axios: {
    url: `${groupActivationsBase}/:id`,
  },
});

export const createGroupActivation = ServiceHandlerFactory({
  axios: {
    url: `${groupActivationsBase}`,
    method: 'post',
  },
});

export const updateGroupActivation = ServiceHandlerFactory({
  axios: {
    url: `${groupActivationsBase}/:id`,
    method: 'put',
  },
});

export const deleteGroupActivation = ServiceHandlerFactory({
  axios: {
    url: `${groupActivationsBase}/:id`,
    method: 'delete',
  },
});

export const regenerateLinkForGroupActivation = ServiceHandlerFactory({
  axios: {
    url: `${groupActivationsBase}/:id/regenerate`,
    method: 'put',
  },
});
