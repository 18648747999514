import Checkbox from '@material-ui/core/Checkbox';
import React from 'react';
import { ISelectTRMPolicyTableData, PolicyChangeOptions } from '../models';
import useStyles from '../useStyles';

interface ISelectColumnProps {
  rowData: ISelectTRMPolicyTableData;
  handleTRMPolicyRuleChange: (
    optionName: PolicyChangeOptions,
    value: boolean | undefined,
    rule: ISelectTRMPolicyTableData
  ) => void;
}

export const SelectColumn: React.FC<ISelectColumnProps> = (props) => {
  const { rowData, handleTRMPolicyRuleChange } = props;

  const classes = useStyles();

  return (
    <div className={classes.checkboxColumn}>
      <Checkbox
        id={`${rowData.id}--checkbox`}
        color="primary"
        checked={rowData.selectRow}
        onChange={() => {
          handleTRMPolicyRuleChange(
            PolicyChangeOptions.SelectRow,
            !rowData.selectRow,
            rowData
          );
        }}
        value={String(rowData.selectRow)}
      />
    </div>
  );
};

export default SelectColumn;
