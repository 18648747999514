import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

export default state => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      compareBuilds: {
        page: 0,
        size: 25,
        order: 'desc',
        orderBy: 'category_name',
        cbo: 'combined',
        tableHeaders: uiSettingsDefaults.compareBuilds.tableHeaders,
      },
      zdevPolicies: {
        ...uiSettingsDefaults.zdevPolicies,
        tableHeaders: uiSettingsDefaults.zdevPolicies.tableHeaders,
      },
      deviceActivations: {
        ...uiSettingsDefaults.deviceActivations,
        tableHeaders: uiSettingsDefaults.deviceActivations.tableHeaders,
      },
      findings: {
        ...uiSettingsDefaults.findings,
        tableHeaders: uiSettingsDefaults.findings.tableHeaders,
      },
    },
  };
};
