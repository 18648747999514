import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ config, palette }: any) =>
  createStyles({
    actions: {
      alignItems: 'center',
      display: 'flex',
      flex: '0 0',
      whiteSpace: 'nowrap',
    },
    buttonIcon: {
      color: palette.components.globalHeader.contrastText,
      padding: 10,
    },
    consoleVersion: {
      color: palette.components.globalHeader.contrastText,
      whiteSpace: 'nowrap',
    },
    customActions: { '& > button:last-child': { marginRight: 20 } },
    favorite: {
      cursor: 'pointer',
      color: palette.common.canery,
    },
    headerContainer: {
      backgroundColor: palette.components.globalHeader.background,
      boxShadow: 'none',
      display: 'flex',
      minWidth: 360,
      paddingBottom: 5,
      position: 'fixed',
      right: 0,
      top: 0,
      width: '100%',
      zIndex: 999,
    },
    iconStyle: {
      color: palette.components.globalHeader.contrastText,
      fontSize: config.textSizes.paragon,
    },
    logo: { width: 40 },
    logoContainer: {
      alignItems: 'center',
      display: 'flex',
      flex: 0,
      '@media screen and (min-width: 500px)': { paddingRight: 53 },
      '& img': { display: 'inline-block', marginRight: 10 },
      '& span': {
        display: 'none',
        '@media screen and (min-width: 600px)': { display: 'block' },
      },
    },
    logoImageContainer: {
      width: '40px',
      height: '40px',
      display: 'flex',
      justifyContent: 'center',
      overflow: 'hidden',
    },
    organization: {
      fontSize: 11,
      whiteSpace: 'normal',
      '@media screen and (min-width: 500px)': { fontSize: 12 },
      '@media screen and (min-width: 800px)': {
        fontSize: 'initial',
        whiteSpace: 'nowrap',
      },
    },
    productLinkRoot: {
      color: palette.components.globalHeader.contrastText,
      display: 'inline-flex',
      lineHeight: 1,
      padding: 0,
    },
    productLinkSelected: { backgroundColor: 'transparent !important' },
    products: {
      display: 'flex',
      flex: 1,
      fontSize: 12,
      marginLeft: 20,
      '@media screen and (min-width: 500px)': {
        fontSize: 'initial',
        marginLeft: 0,
      },
      '& a': {
        flex: 0,
        marginRight: 10,
        '@media screen and (min-width: 500px)': { marginRight: 20 },
        '@media screen and (min-width: 800px)': { marginRight: 40 },
        '@media screen and (min-width: 1024px)': { marginRight: 100 },
      },
    },
    toolbar: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      minHeight: 'unset',
      padding: 10,
    },
  })
);

export default useStyles;
