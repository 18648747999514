import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import classnames from 'classnames';
import InfiniteCalendar, { Calendar, withRange } from 'react-infinite-calendar';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import 'react-infinite-calendar/styles.css';

const CalendarWithRange = withRange(Calendar);



class RangeCalendar extends Component {
  static defaultProps = {
    rangePresets: [],
    end: new Date(),
    start: Moment().subtract(7, 'days').toDate(),
    rootCalendarProps: {},
  };

  static propTypes = {
    rootCalendarProps: PropTypes.object,
    syncUrl: PropTypes.bool,
  };

  presets = [];

  constructor(props) {
    super(props);

    // method binding
    this.presets = [...props.rangePresets];
    this.onRangeSelect = this.onRangeSelect.bind(this);
    this.onPresetSelect = this.onPresetSelect.bind(this);
  }

  dateFn = () => {
    return (i18n?.language?.substring(0, 2) ?? 'en') === 'ja' ? dateFnsJa : undefined;
  };

  render() {
    const { props } = this;
    const { t, i18n } = this.props;
    // eslint-disable-next-line eqeqeq
    const isAllTime = props.selectedPreset == 9;
    const classes = classnames(props.className, props.classes.root, {
      [props.classes.isAllTime]: isAllTime,
    });

    return (
      <div className={classes}>
        <div className="calendar-container">
          <InfiniteCalendar
            Component={CalendarWithRange}
            selected={
              isAllTime
                ? null
                : {
                  start: props.start,
                  end: props.end,
                }
            }
            onSelect={this.onRangeSelect}
            theme={props.theme.palette.calendar.native}
            {...props.rootCalendarProps}
            locale={{
              blank: t('RANGE_PICKER.SELECT_A_DATE'),
              weekdays: [
                t('RANGE_PICKER.DAYS_MIN.SUN'),
                t('RANGE_PICKER.DAYS_MIN.MON'),
                t('RANGE_PICKER.DAYS_MIN.TUE'),
                t('RANGE_PICKER.DAYS_MIN.WED'),
                t('RANGE_PICKER.DAYS_MIN.THU'),
                t('RANGE_PICKER.DAYS_MIN.FRI'),
                t('RANGE_PICKER.DAYS_MIN.SAT'),
              ],
              todayLabel: {
                long: t('GLOBAL.TODAY'),
                short: t('GLOBAL.TODAY'),
              },
              locale: this.dateFn,
            }}
          />
        </div>

        {!!this.presets.length && (
          <div className={props.classes.presets}>
            <ul>
              {this.presets.map((preset) => {
                return (
                  <li
                    key={preset.ordinal}
                    value={preset.ordinal}
                    onClick={this.onPresetSelect}
                    className={
                      // eslint-disable-next-line eqeqeq
                      preset.ordinal == props.selectedPreset
                        ? props.classes.selectedPreset
                        : null
                    }
                  >
                    {t(preset.name)}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    );
  }

  onRangeSelect(event) {
    if (
      event.eventType === 1 &&
      typeof this.props.onRangeInitialSelect === 'function'
    ) {
      this.props.onRangeInitialSelect({
        from: event.start,
        to: null,
      });
    }

    if (event.eventType !== 3) {
      return;
    }

    if (typeof this.props.onRangeSelect === 'function') {
      this.props.onRangeSelect({
        from: event.start,
        to: event.end,
      });
    }
  }

  onPresetSelect({ target }) {
    if (typeof this.props.onPresetSelect === 'function') {
      this.props.onPresetSelect(target.value);
    }
  }
}

const styles = (theme) => {
  const { palette } = theme;

  return {
    root: {
      background: palette.calendar.presetBackground,
      border: `1px solid ${palette.calendar.borderColor}`,
      borderRadius: 3,
      display: 'inline-flex',
      flexDirection: 'row-reverse',

      '& .Cal__Header__root': {
        padding: 10,
        minHeight: 'auto',
      },

      '& .Cal__Header__day': {
        fontSize: 24,
      },

      '& .Cal__Header__year': {
        fontSize: 16,
      },

      '& .Cal__Header__dateWrapper': {
        color: palette.calendar.headerText,
      },
    },

    presets: {
      padding: '10px 0',
      minWidth: 175,
      maxHeight: 402,
      overflow: 'auto',
      color: palette.text.primary,
      borderRight: `1px solid ${palette.calendar.borderColor}`,

      '& ul': {
        maxHeight: 'inhert',
        padding: 0,
        margin: 0,
        overflow: 'scroll',
        listStyle: 'none',
      },

      '& ul li': {
        padding: '10px 20px',
      },

      '& ul li:hover': {
        cursor: 'pointer',
        background: palette.calendar.presetHoverBackground,
        color: palette.calendar.presetHoverText,
      },
    },

    selectedPreset: {
      background: palette.calendar.presetHoverBackground,
      color: palette.calendar.presetHoverText,
    },

    isAllTime: {
      '& .calendar-container': {
        position: 'relative',
      },

      '& .calendar-container:before': {
        // content: '"Disabled by Time Preset"',    // TODO uncomment the code when have an idea for it's translation
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 20,
        color: '#263238',
      },

      '& .calendar-container:after': {
        content: '""',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        background: palette.calendar.presetBackground,
        opacity: 0.75,
        zIndex: 10,
      },
    },
  };
};

export default withStyles(styles, { withTheme: true })(
  withTranslation()(RangeCalendar)
);
