import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ config, palette }: any) =>
  createStyles({
    noPieData: {
      textAlign: 'center',
      width: '300px',
    },
    pieChart: {
      position: 'relative',
      width: '275px',
      '@media (min-width: 1240px)': { width: '325px' },
      '@media (min-width: 1400px)': { width: '375px' },
      '& canvas': { position: 'relative' },
    },
    pieIcon: {
      color: palette.secondary.text,
      fontSize: config.textSizes.canon,
      padding: '5px',
    },
    pieLabel: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '50%',
      justifyContent: 'center',
      left: '50%',
      margin: 0,
      position: 'absolute',
      textAlign: 'center',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '49%',
    },
    pieText: {
      fontSize: config.textSizes.normal,
    },
  })
);

export default useStyles;
