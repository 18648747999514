import { ServiceHandlerFactory } from '../utils/serviceUtils';

const acceptorBase = 'api/acceptor/v1';

export const fetchActivationLink = ServiceHandlerFactory({
  axios: {
    url: `${acceptorBase}/activationLinks/:emmConnectionId`,
  },
});

export const regenerateActivationLink = ServiceHandlerFactory({
  axios: {
    url: `${acceptorBase}/activationLinks/:emmConnectionId`,
    method: 'PUT'
  }
})

export const fetchActivationCode = ServiceHandlerFactory({
  axios: {
    url: `${acceptorBase}/activationCodes/:emmConnectionId`,
    method: 'GET'
  }
})
