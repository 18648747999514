import { IUploadAppData } from './Form/models';
import { withStyles } from '@material-ui/core/styles';
import Form from './Form';
import React from 'react';
import styles from './styles';
import withRouter from 'components/hocs/withRouter';

interface IUploadAppProps {
  data: Partial<IUploadAppData>;
}

const UploadApp: React.FC<IUploadAppProps> = ({ data }) => {
  return <Form data={data} />;
};

export default withStyles(styles)(withRouter(UploadApp));
