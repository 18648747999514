import AT from 'actions/ActionTypes';

const detailsDefaults = {
  firstName: '',
  lastName: '',
  email: '',
  organization: '',
};

export const getUserValid = state => state.user.validated;

const details = (state = detailsDefaults, action) => {
  switch (action.type) {
    case AT.UPDATE_ACCOUNT_SUCCEEDED:
      return {
        ...state,
        organization: action.payload.name,
      };
    case AT.UPDATE_CURRENT_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AT.UPDATE_CURRENT_USER_SUCCEEDED:
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        middleName: action.payload.middleName,
        email: action.payload.email,
        phone: action.payload.phone,
        loading: false,
      };
    case AT.UPDATE_CURRENT_USER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case AT.USER_LOGOUT:
      return detailsDefaults;

    default:
      if (state) {
        return state;
      }
      return detailsDefaults;
  }
};

export default details;
