import { useCallback, useEffect, useState } from 'react';
import { fetchActivationCode } from 'api/AcceptorService';
import { AxiosError, AxiosResponse } from 'axios';
import { IMessage } from './models';


interface IFetchActivationCodeProps {
  emmConnectionId: string;
  connectorTypeId: string;
}

export default ({ emmConnectionId, connectorTypeId }: IFetchActivationCodeProps) => {
  const [message, setMessage] = useState<IMessage | null>(null);
  const [activationCode, setActivationCode] = useState<string | null>(null);
  const getActivationCode = useCallback(async () => {
    fetchActivationCode({ emmConnectionId })
      .then((response: AxiosResponse) => {
        setActivationCode(response.data.mtdActivationCode);
      })
      .catch((e: AxiosError) => {
        console.error('Error in fetching activation code: ', e);
      });
  }, [emmConnectionId]);

  useEffect(() => {
    if (
      ['MobileIronCoreConnector', 'MobileIronCloudConnector'].includes(
        connectorTypeId ?? ''
      )
    ) {
      setMessage({
        title: 'MTD.INTEGRATIONS.MOBILE_IRON_MTD_ACTIVATION_CODE',
        main: 'MTD.INTEGRATIONS.ACTIVATION_CODE_IN_THE_MOBILE_IRON',
      });
      getActivationCode();
    }
    if (['Maas360Connector'].includes(connectorTypeId ?? '')) {
      setMessage({
        title: 'MTD.INTEGRATIONS.MAA_S360_MTD_ACTIVATION_CODE',
        main: 'MTD.INTEGRATIONS.ACTIVATION_CODE_IN_THE_MAA_S360',
      });
      getActivationCode();
    }
    return;
  }, [getActivationCode, connectorTypeId]);

  return { message, activationCode };
};
