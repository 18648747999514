import { IRowData } from '../models';
import { IFormData } from './models';

export const initialValues = (
  rowData?: IRowData,
  selectedTeamId?: string,
  scopeBounds?: string
): IFormData => {
  return {
    bounds:
      !!rowData?.team || scopeBounds === 'TEAM_BOUNDED' ? 'team' : 'global',
    description: rowData?.description ?? '',
    editMode: !!rowData ?? false,
    id: rowData?.id ?? '',
    selectedTeamId: selectedTeamId ?? '',
    name: rowData?.name ?? '',
    selectedAppPolicy:
      rowData?.appPolicyId && rowData?.appPolicyName
        ? {
            value: rowData?.appPolicyId,
            label: rowData?.appPolicyName,
            accountBounded: rowData?.appPolicyGlobal ?? false,
          }
        : undefined,
    selectedAppSettings:
      rowData?.appSettingsId && rowData?.appSettingsName
        ? {
            value: rowData?.appSettingsId,
            label: rowData?.appSettingsName,
            accountBounded: rowData?.appSettingsGlobal ?? false,
          }
        : undefined,
    selectedDormancyPolicy:
      rowData?.dormancyPolicyId && rowData?.dormancyPolicyName
        ? {
            value: rowData?.dormancyPolicyId,
            label: rowData?.dormancyPolicyName,
            accountBounded: rowData?.dormancyPolicyGlobal ?? false,
          }
        : undefined,
    selectedPhishingPolicy:
      rowData?.phishingPolicyId && rowData?.phishingName
        ? {
            value: rowData?.phishingPolicyId,
            label: rowData?.phishingName,
            accountBounded: rowData?.phishingGlobal ?? false,
          }
        : undefined,
    selectedNetworkPolicy:
      rowData?.networkPolicyId && rowData?.networkPolicyName
        ? {
            value: rowData?.networkPolicyId,
            label: rowData?.networkPolicyName,
            accountBounded: rowData?.networkPolicyGlobal ?? false,
          }
        : undefined,
    selectedBrandingPolicy:
      rowData?.brandingPolicyId && rowData?.brandingPolicyName
        ? {
            value: rowData?.brandingPolicyId,
            label: rowData?.brandingPolicyName,
            accountBounded: rowData?.brandingPolicyGlobal ?? false,
          }
        : undefined,
    selectedPrivacy:
      rowData?.privacyId && rowData?.privacyName
        ? {
            value: rowData?.privacyId,
            label: rowData?.privacyName,
            accountBounded: rowData?.privacyGlobal ?? false,
          }
        : undefined,
    selectedOsRiskPolicy:
      rowData?.osRiskPolicyId && rowData?.osRiskPolicyName
        ? {
            value: rowData?.osRiskPolicyId,
            label: rowData?.osRiskPolicyName,
            accountBounded: rowData?.osRiskPolicyGlobal ?? false,
          }
        : undefined,
    selectedTRM:
      rowData?.trmId && rowData?.trmName
        ? {
            value: rowData?.trmId,
            label: rowData?.trmName,
            accountBounded: rowData?.trmGlobal ?? false,
          }
        : undefined,
    teamId: rowData?.team?.id ?? '',
  };
};
