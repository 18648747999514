import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    malicious: {
      alignItems: 'center',
      backgroundColor: palette.error.medium,
      border: `1px solid ${palette.tables.border}`,
      borderRadius: 100,
      color: 'white',
      display: 'flex',
      fontSize: 11,
      padding: '2px 8px',
      textTransform: 'uppercase',
    },
    platform: {
      '& svg': {
        fontSize: 20,
        marginRight: 10,
        minHeight: 20,
        minWidth: 20,
      },
      alignItems: 'center',
      display: 'flex',
    },
    risk: {
      '& span': {
        margin: '0px 10px',
      },
      alignItems: 'center',
      display: 'flex',
      textTransform: 'capitalize',
    },
    searchBar: {
      marginBottom: 16,
    },
    created: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  })
);

export default useStyles;
