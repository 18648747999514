import {
  CheckboxProps as IMUICheckboxProps,
  FormControlLabelProps as IMUIFormControlLabelProps,
} from '@material-ui/core';
import { default as MUICheckbox } from '@material-ui/core/Checkbox';
import { default as MUIFormControlLabel } from '@material-ui/core/FormControlLabel';
import cc from 'classcat';
import { FieldProps as IFormikFieldProps } from 'formik';
import React from 'react';
import useStyles from './useStyles';

export interface ICheckboxProps extends IMUICheckboxProps {
  asToggle?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isHidden?: boolean;
  label?: IMUIFormControlLabelProps | string | JSX.Element;
}

const Checkbox: React.FC<ICheckboxProps & Partial<IFormikFieldProps>> = ({
  asToggle,
  field,
  isHidden,
  label,
  onChange: handleChange,
  ...rest
}) => {
  const classes = useStyles();
  if (isHidden) {
    return null;
  }
  return (
    <MUIFormControlLabel
      label={label}
      classes={{ label: classes.label }}
      control={
        asToggle ? (
          <div className={classes.switch}>
            <input
              disabled={rest?.disabled ?? false}
              onClick={e => {
                if (!rest?.disabled && !!rest?.onClick) {
                  rest.onClick(
                    e as React.MouseEvent<HTMLButtonElement, MouseEvent>
                  );
                }
              }}
              type="checkbox"
              {...field}
            />
            <span
              className={cc([
                classes.slider,
                {
                  [classes.sliderChecked]: field?.checked,
                  [classes.sliderDisabled]: rest?.disabled,
                },
              ])}
            ></span>
          </div>
        ) : (
          <MUICheckbox
            classes={{ root: classes.checkbox }}
            onChange={handleChange}
            {...field}
            {...rest}
          />
        )
      }
    />
  );
};

// Consuming the Checkbox in a FormikField as field={CheckboxAsToggle}
// versus field={p => <Checkbox {...p} asToggle/>} prevents unneccessary
// re-renders that break CSS animations...
export const CheckboxAsToggle = (
  props: ICheckboxProps & Partial<IFormikFieldProps>
) => <Checkbox {...props} asToggle />;

export default Checkbox;
