import { createGroup, updateGroup } from 'api/GroupService';
import axios from 'axios';
import { FormikHelpers } from 'formik';
import { publishEvent } from 'utils/eventUtils';
import { openSnackBar, toggleModalDirect } from 'utils/storeUtils';
import { IFormData } from './Form/models';
import { IAllPolicies, IData, IPayload } from './models';
import { TFunction } from 'react-i18next';

export const handleSubmit = async (
  formData: IFormData,
  formikHelpers: FormikHelpers<IFormData>,
  t: TFunction<'translation', undefined>
) => {
  const payload: IPayload = {
    bounds: formData?.bounds ?? null,
    description: formData?.description ?? null,
    name: formData?.name ?? null,
    appPolicyId: (formData?.selectedAppPolicy?.value as string) ?? null,
    appSettingsId: (formData?.selectedAppSettings?.value as string) ?? null,
    dormancyPolicyId:
      (formData?.selectedDormancyPolicy?.value as string) ?? null,
    phishingPolicyId:
      (formData?.selectedPhishingPolicy?.value as string) ?? null,
    privacyId: (formData?.selectedPrivacy?.value as string) ?? null,
    networkPolicyId: (formData?.selectedNetworkPolicy?.value as string) ?? null,
    brandingPolicyId:
      (formData?.selectedBrandingPolicy?.value as string) ?? null,
    osRiskPolicyId: (formData?.selectedOsRiskPolicy?.value as string) ?? null,
    trmId: (formData?.selectedTRM?.value as string) ?? null,
  };

  if (!!formData?.editMode) {
    try {
      const teamId = !!formData?.teamId ? formData.teamId : null;
      await updateGroup({ groupId: formData?.id }, { ...payload, teamId });
      openSnackBar(t('MTD.ACTIVATIONS.GROUP_WAS_CREATED'));
      publishEvent('table:force-fetch-Groups');
      toggleModalDirect('GroupsCreateEdit', false);
    } catch (e: unknown) {
      if (axios.isAxiosError(e) && e?.response?.status === 409) {
        formikHelpers.setErrors({
          name: t('MTD.ACTIVATIONS.POLICY_GROUP_WITH_THIS_NAME_ALREADY_EXIST'),
        });
      } else {
        formikHelpers.setErrors({
          name: t('MTD.ACTIVATIONS.FAILED_TO_EDIT_GROUP'),
        });
      }
      formikHelpers.setSubmitting(false);
      console.error(e);
    }
  } else {
    const nonGlobalTeamId = formData?.selectedTeamId || formData?.teamId;
    const teamId = formData?.bounds === 'global' ? null : nonGlobalTeamId;

    try {
      await createGroup({}, { ...payload, teamId });
      openSnackBar(t('MTD.ACTIVATIONS.GROUP_WAS_CREATED'));
      publishEvent('table:force-fetch-Groups');
      toggleModalDirect('GroupsCreateEdit', false);
    } catch (e: unknown) {
      if (axios.isAxiosError(e) && e?.response?.status === 409) {
        formikHelpers.setErrors({
          name: t('MTD.ACTIVATIONS.POLICY_GROUP_WITH_THIS_NAME_ALREADY_EXIST'),
        });
      } else {
        formikHelpers.setErrors({
          name: t('MTD.ACTIVATIONS.FAILED_TO_CREATE_GROUP'),
        });
      }
      formikHelpers.setSubmitting(false);
      console.error(e);
    }
  }
};

export const initialPolicies = (data: IData): IAllPolicies => ({
  appPolicyList: data?.appPolicyList,
  appSettingsList: data?.appSettingsList,
  dormancyPolicyList: data?.dormancyPolicyList,
  phishingPolicyList: data?.phishingPolicyList,
  privacyPolicyList: data?.privacyPolicyList,
  trmPolicyList: data?.trmPolicyList,
  networkPolicyList: data?.networkPolicyList,
  brandingPolicyList: data?.brandingPolicyList,
  osRiskPolicyList: data?.osRiskPolicyList,
});

export const filterPolicies = (
  policyLists: IAllPolicies,
  bounds: string,
  teamId?: string,
  emmConnectionId?: string
) => {
  const newPolicyLists: IAllPolicies = {};
  const listNames = Object.keys(policyLists);
  listNames.forEach((name) => {
    const policyList = policyLists[name];
    newPolicyLists[name] = !!policyList?.[0]
      ? policyList.filter((item) =>
          bounds === 'global'
            ? !item?.team
            : (!item?.team && !item?.teamId) ||
              item?.team?.id === teamId ||
              item?.teamId === teamId
        )
      : [];
  });

  if (emmConnectionId) {
    newPolicyLists.trmPolicyList = newPolicyLists.trmPolicyList?.filter(
      (trm) => trm.connection?.id === emmConnectionId
    );
  }

  return newPolicyLists;
};
