import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// ui
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

// components
import ProtectedComponent from '../protected/ProtectedComponent';
import { TextField } from 'components/inputs';
import ZButton from 'UI/Buttons/ZButton';

/* Actions / Services */
import { fetchAccount, updateAccount } from 'actions/AccountActions';

/* Selectors */
import { getUserScopesRoles } from 'reducers/UserReducers';

class EditAccountProfile extends Component {
  constructor(props) {
    super(props);

    this.viewOnlyScope = _.includes(props.scopes.account, 'view')
      && !_.includes(props.scopes.account, 'manage');

    this.state = _getDirivedStateFromProps(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.error = this.error.bind(this);
  }

  componentDidMount() {
    this.props.fetchAccount();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.account !== this.props.account) {
      this.setState(_getDirivedStateFromProps(this.props));
    }
  }

  error(resp) {
    if (resp.status === 409) {
      this.setState({
        errors: {
          ...this.state.errors,
          email: resp.data,
        },
      });
    } else if (resp.status === 400) {
      this.setState({ roleError: true });
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value, buttonDisabled: false });
  };

  handleSubmit(event) {
    event.preventDefault();
    const nameParts = this.state.contactName.split(' ');

    this.props.updateAccount(
      {
        ..._.pick(this.state, [
          'name',
          'address',
          'city',
          'zipCode',
          'country',
          'contactEmail',
          'contactPhone',
        ]),
        firstName: nameParts[0],
        lastName: nameParts[1],
      },
      () => null,
      this.error,
    );
  }

  render() {
    const { state } = this;

    return (
      <div>
        <div className="view__header">
          <h1>Account Profile</h1>
        </div>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item md={6}>
                <form onSubmit={this.handleSubmit}>
                  <TextField
                    disabled={this.viewOnlyScope}
                    id="name"
                    label="Company Name"
                    margin="none"
                    required
                    value={state.name}
                    onChange={this.handleChange('name')}
                  />
                  <TextField
                    disabled={this.viewOnlyScope}
                    id="address"
                    label="Address"
                    margin="none"
                    value={state.address}
                    onChange={this.handleChange('address')}
                  />
                  <TextField
                    disabled={this.viewOnlyScope}
                    id="zipCode"
                    label="Zip Code"
                    margin="none"
                    value={state.zipCode}
                    onChange={this.handleChange('zipCode')}
                  />
                  <TextField
                    disabled={this.viewOnlyScope}
                    id="country"
                    label="Country"
                    margin="none"
                    value={state.country}
                    onChange={this.handleChange('country')}
                  />

                  <TextField
                    disabled={this.viewOnlyScope}
                    id="contactName"
                    label="Contact Name"
                    margin="none"
                    required
                    value={state.contactName}
                    onChange={this.handleChange('contactName')}
                  />
                  <TextField
                    disabled={this.viewOnlyScope}
                    id="contactEmail"
                    label="Contact Email"
                    margin="none"
                    required
                    value={state.contactEmail}
                    error={state.errors.email}
                    helperText={state.emailHelperText}
                    onChange={this.handleChange('contactEmail')}
                  />
                  <TextField
                    disabled={this.viewOnlyScope}
                    id="contactPhone"
                    label="Contact Phone"
                    margin="none"
                    value={state.contactPhone}
                    onChange={this.handleChange('contactPhone')}
                  />

                  <div style={{ marginTop: 15 }}>
                    <ProtectedComponent allow={{ account: 'manage' }}>
                      <ZButton
                        buttonType="submit"
                        styleName="titleBar"
                        isDisabled={state.buttonDisabled}
                        buttonText="Update Account"
                        color="primary"
                      />
                    </ProtectedComponent>
                  </div>
                </form>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}

function _getDirivedStateFromProps(props) {
  return {
    ..._.pick(props.account, [
      'name',
      'address',
      'city',
      'zipCode',
      'country',
      'contactEmail',
      'contactPhone',
    ]),
    contactName: `${props.account.firstName || ''} ${props.account.lastName
      || ''}`,
    buttonDisabled: true,
    errors: {
      email: '',
    },
  };
}

const mapStateToProps = state => {
  return {
    account: state.account,
    scopes: getUserScopesRoles(state),
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchAccount,
      updateAccount,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditAccountProfile);
