import { ISelectItem } from 'components/UI/input/Select';
import RadioGroup, { Radio } from 'components/UI/input/RadioGroup';
import { Field as FormikField } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './useStyles';

interface IValues {
  [key: string]: unknown;
  bounds: string;
}

export interface IHandleTeamChangeFn {
  (
    teamId: string,
    setFieldValue: (field: string, value?: string) => void,
    values: unknown
  ): void;
}

export interface ITeamSelectorRadioFormProps {
  handleTeamChange: IHandleTeamChangeFn;
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean | undefined
  ) => void;
  availableTeams: ISelectItem[];
  isSubmitting?: boolean;
  isRowDataPresent: boolean;
  values: unknown;
  selectedTeamId: string | null | undefined | number;
  scopeBounds?: string;
}

const TeamSelectorRadioForm: React.FC<ITeamSelectorRadioFormProps> = ({
  handleTeamChange,
  selectedTeamId,
  setFieldValue,
  availableTeams = [],
  values,
  isSubmitting,
  isRowDataPresent,
}) => {
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const showTeamSelector = useMemo(() => {
    return (
      !selectedTeamId &&
      !isRowDataPresent &&
      (values as IValues)?.bounds === 'team'
    );
  }, [isRowDataPresent, selectedTeamId, values]);

  if (!showTeamSelector) {
    return null;
  }
  const availableTeamsTransformed = availableTeams.map((team) => {
    if (team.label == 'Default') {
      return { ...team, label: t('GLOBAL.DEFAULT') };
    } else {
      return team;
    }
  });
  if (!ready) {
    return null;
  }

  return (
    <div className={classes.teamSelector}>
      <div>{t('MTD.POLICIES.SELECT_TEAM_FOR_THIS_POLICY')}</div>
      <RadioGroup
        label={t(
          `MTD.POLICIES.APP_POLICY.YOU_ARE_A_MEMBER_OF_TEAM${
            availableTeams?.length !== 1 ? 'S' : ''
          }`,
          { data: availableTeams?.length }
        )}
        onChange={(e: React.ChangeEvent<HTMLInputElement>, value: string) => {
          handleTeamChange(value, setFieldValue, values);
        }}
        vertical
      >
        {availableTeamsTransformed.map(({ label, value }) => (
          <FormikField
            component={Radio}
            disabled={isSubmitting || isRowDataPresent}
            value={value}
            name="teamId"
            label={label}
            type="radio"
          />
        ))}
      </RadioGroup>
    </div>
  );
};

export default TeamSelectorRadioForm;
