import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { destinationTypes } from 'components/dataexport/enums/DataExportEnums';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import _ from 'lodash';
import React from 'react';
import _config from 'themes/_base/_config';
import severitiesMapping from 'utility/Severities';
import momentHelper from 'utils/momentUtils';
import { toggleModalDiffered } from 'utils/storeUtils';

const getRestEndpointToolTipText = (destinationType, isRunning, errorText) => {
  if (!errorText) return null;

  if (destinationType === 'SIEM') {
    return null;
  }

  if (errorText.split('\t').length !== 2) {
    return null;
  }
  const errorReason = errorText.split('\t')[0];
  const errorTime = errorText.split('\t')[1];
  return (
    <div>
      <p>{isRunning ? `LAST ${errorReason}` : errorReason}</p>
      <p>at {new Date(errorTime).toString()}</p>
    </div>
  );
};

const formatConditions = (conditions) => {
  if (!conditions?.length) {
    return null;
  }

  for (const { field, value } of conditions) {
    if (field === 'severity') {
      const threatSeverities = value.map(
        (threat) => severitiesMapping[threat].name
      );

      if (threatSeverities.length) {
        return `Severity: ${threatSeverities.join(', ')}`;
      }
    }
  }
};

export const defaultDataExportTableHeaders = [
  {
    flex: 1,
    id: 'name',
    label: 'Name',
    maxWidth: 500,
    minWidth: 125,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    flex: 1,
    id: 'description',
    label: 'Description',
    maxWidth: 500,
    minWidth: 250,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    flex: 1,
    id: 'dataType',
    label: 'Data Type',
    maxWidth: 125,
    minWidth: 250,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    flex: 1,
    id: 'destinationType',
    label: 'Destination Type',
    maxWidth: 500,
    minWidth: 250,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    flex: 1,
    id: 'lastModifiedDate',
    label: 'Last Modified Date',
    maxWidth: 150,
    minWidth: 150,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    flex: 1,
    id: 'conditions',
    label: 'Conditions',
    maxWidth: 500,
    minWidth: 250,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: false,
  },
  {
    flex: 1,
    id: 'numExported',
    label: 'Events Exported',
    maxWidth: 250,
    minWidth: 125,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    flex: 0,
    id: 'actions',
    isProtected: true,
    label: 'Actions',
    maxWidth: 125,
    minWidth: 125,
    numeric: false,
    scope: 'roles',
    show: true,
    showInContextMenu: true,
    sort: false,
  },
];

export const dataExportColumnMapping = [
  {
    path: 'name',
  },
  {
    path: 'description',
  },
  {
    path: 'dataType',
    csvContent: (dataType) => _.startCase(_.toLower(dataType)),
    columnContent: ({ rowData }) => {
      const dataType = _.get(rowData, 'dataType');

      return _.startCase(_.toLower(dataType));
    },
  },
  {
    path: 'destinationType',
    csvContent: (destinationType) => {
      return _.get(
        _.head(
          _.filter(
            destinationTypes,
            (iDestinationType) => iDestinationType.value === destinationType
          )
        ),
        'label'
      );
    },
    columnContent: ({ rowData, classes }) => {
      const destinationType = _.get(rowData, 'destinationType');
      const isRunning = _.get(rowData, 'isRunning');
      const lastError = _.get(rowData, 'lastError');

      return (
        <div className="flex--spaced">
          <span>
            {_.get(
              _.head(
                _.filter(
                  destinationTypes,
                  (iDestinationType) =>
                    iDestinationType.value === destinationType
                )
              ),
              'label'
            )}
          </span>

          <span>
            {['REST_ENDPOINT', 'SIEM'].includes(destinationType) && (
              <Tooltip
                title={
                  getRestEndpointToolTipText(
                    destinationType,
                    isRunning,
                    lastError
                  ) || ''
                }
                disableHoverListener={destinationType === 'SIEM' || !lastError}
              >
                <div
                  className={classes.statusCircle}
                  style={{
                    background: isRunning
                      ? _config.colors.paleGreen
                      : _config.colors.tomato,
                  }}
                />
              </Tooltip>
            )}
          </span>
        </div>
      );
    },
  },
  {
    path: 'lastModifiedDate',
    csvContent: (lastModifiedDate) => momentHelper(lastModifiedDate),
    columnContent: ({ rowData }) => {
      const lastModifiedDate = _.get(rowData, 'lastModifiedDate');

      return momentHelper(lastModifiedDate);
    },
  },
  {
    path: 'conditions',
    csvContent: (conditions) => formatConditions(conditions),
    columnContent: ({ rowData }) => {
      const conditions = _.get(rowData, 'conditions');
      return formatConditions(conditions);
    },
  },
  {
    path: 'numExported',
  },
  {
    path: 'actions',
    columnContent: ({ rowData }) => {
      const id = _.get(rowData, 'id');

      return (
        <>
          <ProtectedComponent allow={{ data_exports: 'manage' }}>
            <EditIcon
              className="cursor--pointer"
              onClick={toggleModalDiffered(
                'DataExportCreateEdit',
                {
                  rowData,
                },
                {
                  title: 'Edit Data Export Configuration',
                  fullWidth: true,
                }
              )}
            />
            <DeleteIcon
              className="cursor--pointer"
              onClick={toggleModalDiffered(
                'DataExportDeleteConfirm',
                {
                  id,
                },
                { title: 'Delete Data Export Configuration' }
              )}
            />
          </ProtectedComponent>
        </>
      );
    },
  },
];
