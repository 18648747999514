import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// material-ui
import { Checkbox } from 'components/inputs';
import Card from '@material-ui/core/Card';
// helpers
// import ScopeMapping from 'mappings/scopeMapping';
import { swapScopeAndLabel } from 'utils/scopeUtils';

const ScopeOptions = props => {
  const [actualPermissionKeyHash, setActualPermissionKeyHash] = useState({});
  useEffect(() => {
    const keyHash = swapScopeAndLabel(props.scopeMapping);
    setActualPermissionKeyHash(keyHash);
  }, [props, props.scopeMapping]);
  if (!props.show) {
    return null;
  }

  return (
    <div className={props.classes.scopeCard}>
      <h2>{props.title}</h2>
      {props.sortedDefaultScopes.map((permission, index) => {
        const actualPermissionKey = actualPermissionKeyHash[permission];
        const permissionsForScope = props.availableScopes[actualPermissionKey];
        if (!permissionsForScope) {
          return null;
        }
        return (
          <Card key={`scope-${index}`} className={props.classes.root}>
            <div
              className={props.classes.scopeContainer}
              key={`scope-${index}`}
            >
              <div className={props.classes.rowContainer}>
                <div className={props.classes.permissionLabel}>
                  {permission}
                </div>
                <div className={props.classes.checkBoxContainer}>
                  {Object.keys(permissionsForScope)
                    .sort()
                    .map(permissionKey => {
                      return (
                        <div
                          className={props.classes.box}
                          key={`box-${permission}${permissionKey}`}
                        >
                          <Checkbox
                            disableRipple
                            onChange={props.handleToggleSwitch(
                              actualPermissionKey,
                              permissionKey
                            )}
                            checked={
                              props.availableScopes[actualPermissionKey][
                                permissionKey
                              ]
                            }
                            value={
                              props.availableScopes[actualPermissionKey][
                                permissionKey
                              ]
                            }
                            disabled={props.disable}
                            label={permissionKey}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </Card>
        );
      })}
    </div>
  );
};

const styles = ({ config }) => {
  return {
    root: {
      // width: '25%',
      marginBottom: 0,
    },
    scopeCard: {
      // display: 'flex',
      // flexDirection: 'column',
      // flexWrap: 'wrap',
      padding: 20,
      width: 490,
    },
    scopeContainer: {
      display: 'flex',
      // flexDirection: 'column',
      // width: 750,
      flexWrap: 'wrap',
    },
    scopeLabel: {
      fontSize: config.textSizes.petite,
      marginTop: 15,
    },
    scopeOption: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    permissionLabel: {
      paddingTop: 15,
      paddingLeft: 15,
    },
    rowContainer: {
      width: '100%',
    },
    checkBoxContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    box: {
      marginLeft: '15px',
    },
  };
};

ScopeOptions.propTypes = {
  disable: PropTypes.bool,
  selectedOptions: PropTypes.array.isRequired,
  availableScopes: PropTypes.object.isRequired,
};

ScopeOptions.defaultProps = {
  disable: false,
  selectedOptions: [],
  availableScopes: {},
};

export default withStyles(styles, { withTheme: true })(ScopeOptions);
