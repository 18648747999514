import { Card } from '@material-ui/core';
import MUIArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import momentHelper from 'utils/momentUtils';
import useStyles from './useStyles';

interface ILastDeployedBoxProps {
  lastDeployedTime: Date | string | number | null | undefined;
  isBox?: boolean;
}

const LastDeployedBox: React.FC<ILastDeployedBoxProps> = ({
  lastDeployedTime,
  isBox = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const humanReadable = useCallback(() => {
    if (lastDeployedTime) {
      return momentHelper(lastDeployedTime);
    }
    return 'N/A';
  }, [lastDeployedTime]);

  return isBox ? (
    <Card className={classes.cardRoot}>
      {t('MTD.POLICIES.LAST_DEPLOYED')} {humanReadable()}{' '}
    </Card>
  ) : (
    <div className={classes.inDropdown}>
      {t('MTD.POLICIES.LAST_DEPLOYED')} {humanReadable()}{' '}
      <MUIArrowDropDown className={classes.ddIcon} />{' '}
    </div>
  );
};

export default LastDeployedBox;
