import { IIntuneFormData } from './models';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const savedIntuneFormData = atom<IIntuneFormData | undefined>({
  key: 'emm-savedIntuneFormData',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
