import AT from 'actions/ActionTypes';
import * as api from 'api/apis';
import { postUserInvitedValidationToken } from 'api/SignupService';
import { setCurrentUser } from 'actions/LocalUserActions';

export {
  logout,
  setCurrentUser,
  updatePartnerUser,
  updateSuperUser,
} from 'actions/LocalUserActions';

export const fetchAndSetCurrentUser = () => (dispatch) => {
  return new Promise((resolve) => {
    return api.fetchCurrentUser().then(({ data }) => {
      dispatch(setCurrentUser(data));
      return resolve(data);
    });
  });
};

export const rejectToken = (response) => (dispatch) => {
  dispatch({
    type: AT.SET_TOKEN_REJECTED,
    payload: response,
  });
};
