import AT from 'actions/ActionTypes';

export const defaultInappsVersionsTableHeaders = [
  { id: 'version', label: 'Version', show: true },
  { id: 'build', label: 'Build', show: true },
  { id: 'name', label: 'App Name', show: false },
  { id: 'bundle_id', label: 'Bundle Id', show: false },
  { id: 'os', label: 'OS', show: true },
  { id: 'mad', label: 'Monthly Active Devices', show: true },
  { id: 'dad', label: 'Daily Active Devices', show: true },
  { id: 'installation_units', label: 'Unique Installs', show: true },
  // { id: 'privacyRisk',  label: 'zDev Privacy Risk', show: true },
  // { id: 'securityRisk',  label: 'zDev Security Risk', show: true },
  { id: 'z_version', label: 'zDefend Version', show: true },
  { id: 'z_build', label: 'zDefend Build', show: true },
];

const appVersionsMetadataDefault = {
  count: 0,
  currentVersion: '',
  loader: false,
};

export const appVersionsMetadata = (
  state = appVersionsMetadataDefault,
  action
) => {
  switch (action.type) {
    case AT.FETCH_APP_VERSIONS_PAGE_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case AT.FETCH_APP_VERSIONS_PAGE_SUCCEEDED:
      return {
        ...state,
        count: action.payload.appVersionsMetadata.count,
        loader: false,
      };

    case AT.CLEAR_SELECTED_APP:
    case AT.DELETE_SELECTED_APP_SUCCEEDED:
      return appVersionsMetadataDefault;

    case AT.USER_LOGOUT:
      return appVersionsMetadataDefault;

    case AT.FETCH_APP_VERSIONS_COUNT_SUCCEEDED:
      return {
        ...state,
        count: action.payload.count, // this is still used by dashboard app selector
        currentVersion: action.payload.currentVersion,
      };

    default:
      return state;
  }
};
