import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    textField: {
      marginTop: '17px',
      marginLeft: '-2px',
      paddingTop: '3px',
      '& label': {
        color: palette.text.primary,
        paddingLeft: '2px',
      },
      '& input': {
        padding: '16px',
      },
    },
    noMargin: {
      '& > *:first-child': {
        marginBotom: '0 !important',
        marginRight: '0 !important',
      },
    },
    wrapper: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      gap: 4,
      justifyContent: 'space-between',
      width: '100%',

      '& > *:first-child': {
        flex: 1,
        marginBottom: 10,
        marginRight: 12,
      },
      '& > button': {
        alignSelf: 'flex-end',
        display: 'inline-flex',
        flex: 0,
        margin: 0,
        marginBottom: 18,
        minWidth: 32,
        padding: 0,

        '& svg': {
          height: 28,
          width: 28,
        },
      },
    },
  })
);

export default useStyles;
