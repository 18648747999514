import AT from 'actions/ActionTypes';
import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { AUTH_CLEAR_USER } from 'reducers/AuthReducers';

export const getUserScopes = state => state.auth.user.scopes;
export const getTokenError = state => state.user.error;
export const getUser = state => state.user;

const createUserRolesObject = scopes => {
  const scopeObject = {};
  Object.keys(scopes).forEach(roleName => {
    const scopeArray = scopes[roleName];
    // in case a role has a manage, enter, etc scope but not 'view'
    if (
      !_.isEmpty(scopeArray) &&
      !scopeArray.includes('view') &&
      roleName !== 'zdev_builds'
    ) {
      scopeArray.push('view');
    }
    scopeObject[roleName] = scopeArray;
  });
  return scopeObject;
};

export const getUserScopesRoles = createSelector(
  getUserScopes,
  createUserRolesObject
);

const defaultState = {
  firstName: '',
  lastName: '',
  email: '',
  organization: '',
  scopes: {},
  error: null,
};

export const UserReducer = (state = defaultState, action) => {
  switch (action.type) {
    case AT.AUTHENTICATED_USER:
      return {
        ...state,
        authenticated: true,
      };

    case AT.SET_CURRENT_USER:
      return {
        ...state,
        ...action.payload,
      };

    case AT.SET_TOKEN_REQUEST:
      return {
        ...state,
      };
    case AT.RECEIVED_JWT:
    case AT.SET_TOKEN_SUCCEEDED:
      return {
        ...state,
        jwt: action.payload.jwt,
        name: action.payload.name,
        email: action.payload.email,
        scopes: action.payload.scopes,
        scopeBound: action.payload.scopeBounds,
        authenticated: true,
      };
    case AT.SET_TOKEN_REJECTED:
      return {
        ...defaultState,
        error: action.payload,
      };

    case AUTH_CLEAR_USER:
      return defaultState;

    case AT.USER_LOGOUT:
      sessionStorage.removeItem('jwt');
      return defaultState;

    default:
      return state;
  }
};

const superUserInitialState = {};
const partnerUserInitialState = {};

// super user action types
export const UPDATE_SUPER_USER = 'UPDATE_SUPER_USER';
export const UPDATE_PARTNER_USER = 'UPDATE_PARTNER_USER';

// admin actions
export const getSuperUserScopes = state => state.auth.superUser.scopes;

// admin selectors
export const getSuperUserScopesRoles = createSelector(
  getSuperUserScopes,
  createUserRolesObject
);

export const getPartnerUserScopes = state => state.auth.partnerUser.scopes;

export const getPartnerUserScopesRoles = createSelector(
  getPartnerUserScopes,
  createUserRolesObject
);

export const SuperUserReducer = handleActions(
  {
    UPDATE_SUPER_USER: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    [AT.USER_LOGOUT]: () => {
      return superUserInitialState;
    },
  },
  superUserInitialState
);
export const PartnerUserReducer = handleActions(
  {
    UPDATE_PARTNER_USER: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    [AT.USER_LOGOUT]: () => {
      return partnerUserInitialState;
    },
  },
  partnerUserInitialState
);
