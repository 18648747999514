import { Card, CardContent } from '@material-ui/core';
import React from 'react';
import useStyles from './useStyles';
import { Radio } from 'components/UI/input/RadioGroup';
import Tooltip from 'components/UI/Tooltip';
import { Info } from '@material-ui/icons';
import { ECACMODE } from 'components/entry/CACLogin/models';
import { useSelector } from 'react-redux';

const CACLoginLabel = () => {
  return (
    <div style={{ display: 'flex' }}>
      <span style={{ paddingRight: 5 }}>CAC Only</span>
      <Tooltip
        placement="right"
        tooltipText="CAC (Common Access Cards) are a physical authentication method used by Federal Employees. The certificate associated with these is used to log in the Console."
      >
        <Info />
      </Tooltip>
    </div>
  );
};

const GlobalCACSettings: React.FC = () => {
  const classes = useStyles();
  const state: any = useSelector((state) => state);
  const cacMode: ECACMODE = state?.uiSettings.systemProperties.cacMode;

  return (
    <div>
      <Card>
        <CardContent className={classes.inputGroupBackground}>
          <h2>Global Login Settings</h2>
          <p>
            To change the global login method, contact your Zimperium SRE team
          </p>
          <Radio
            checked={cacMode === ECACMODE.OFF}
            color="primary"
            label={'Username/Password'}
            name="loginType"
            size="small"
            value="username"
            disabled
          />
          <Radio
            checked={cacMode === ECACMODE.CAC_ONLY}
            color="primary"
            label={<CACLoginLabel />}
            name="loginType"
            size="small"
            value="CAC"
            disabled
          />
          <Radio
            checked={cacMode === ECACMODE.CAC_PLUS_USERNAME_PASSWORD}
            color="primary"
            label={'Both CAC and Username/Password'}
            name="loginType"
            size="small"
            value="CAC-and-username"
            disabled
          />
        </CardContent>
      </Card>
    </div>
  );
};

export default GlobalCACSettings;
