import { IGenericColumnHeader } from 'components/UI/GenericTable/models';

export const trmPolicyTableColumnHeaders: IGenericColumnHeader[] = [
  {
    id: 'id',
    label: 'ID',
  },
  {
    id: 'enabled',
    label: 'Enabled',
  },
  {
    id: 'visible',
    label: 'Visible',
  },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'severity',
    label: 'Severity',
  },
  {
    id: 'name',
    label: 'Threat Name',
    sort: true,
  },
  {
    id: 'platform',
    label: 'Platform',
    sort: true,
  },
  {
    id: 'sdkThreats',
    label: 'SDK',
  },
  {
    id: 'enterpriseThreats',
    label: 'Enterprise',
  },
  {
    id: 'consumerThreats',
    label: 'Consumer',
  },
];
