export const onlyNumbersRegex = new RegExp(/^\d+$/);

export const HexColorRegex = new RegExp(/^([A-Fa-f0-9]{6})$/);

export const LinkRegex = new RegExp(
  /(?:https?)?(?::\/\/)?[\w.-]+\.(?:[a-z]+)(?:\/[\S]*)*/
);

export const IpRegex = new RegExp(
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/g
);

export const subnetIpRegex = new RegExp(
  /\b(?:(?:2(?:[0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9])\.){3}(?:(?:2([0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9]))\b/);
