import { getActiveModalAtom } from 'atoms/modals';
import Modal from 'components/UI/Modal';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { IStandardAuth } from '../AddEditCloneEMMAuth/StandardForm/models';
import { IVMWareAuth } from '../AddEditCloneEMMAuth/VMWareForm/models';
import ModalContent from './ModalContent';
import { IAddEditCloneEMMGroups } from './models';
import useStyles from './useStyles';

export const AddEditCloneEMMGroups_TITLE = 'AddEditCloneEMMGroups';
const activeModalAtom =
  getActiveModalAtom<IAddEditCloneEMMGroups<IStandardAuth | IVMWareAuth>>();
const AddEditCloneEMMGroups: React.FC = () => {
  const [activeModal, setActiveModal] = useRecoilState(activeModalAtom);
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const handleClose = useCallback(() => {
    setActiveModal(undefined);
  }, [setActiveModal]);

  const switchMode = (mode: string | undefined) => {
    switch (mode) {
      case 'EDIT':
        return t('MTD.INTEGRATIONS.EDIT_CONNECTION');
        break;
      case 'ADD':
        return t('MTD.INTEGRATIONS.CREATE_NEW_CONNECTION');
        break;
      case 'CLONE':
        return t('MTD.INTEGRATIONS.CLONE_CONNECTION');
      default:
        return '';
    }
  };

  if (!ready) {
    return null;
  }

  return (
    <Modal
      caption={switchMode(activeModal?.payload?.mode)}
      className={classes.modal}
      onClose={handleClose}
      scrollable={true}
      title={AddEditCloneEMMGroups_TITLE}
    >
      <ModalContent
        authForGroupsCreate={activeModal?.payload?.authForGroupsCreate}
        cloneInfoFromAuth={activeModal?.payload?.cloneInfoFromAuth}
        connector={activeModal?.payload?.connector}
        data={activeModal?.payload?.data}
        handleClose={handleClose}
        mode={activeModal?.payload?.mode}
      />
    </Modal>
  );
};

export default AddEditCloneEMMGroups;
