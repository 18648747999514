import axios from 'axios';
import React, { useState, useCallback } from 'react';
import { openSnackBar } from 'utils/storeUtils';
import Form from './Form';
import { IFormSubmit, IWithRouterProps } from './Form/models';
import { formatErrorMessage } from './Form/utils';
import { getActiveModalAtom } from 'atoms/modals';
import { useRecoilState } from 'recoil';
import Modal from 'components/UI/Modal';
import { completeZScanUpload } from 'api/zDevService';
import SubmissionUrlForm from './SubmissionUrlForm';
import withRouter from 'components/hocs/withRouter';
import { publishEvent } from 'utils/eventUtils';

export const ZScanUploadComplete_TITLE = 'ZScanUploadComplete';
const activeModalAtom =
  getActiveModalAtom<any>();

const ZScanUploadAppForm: React.FC<IWithRouterProps> = (props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [activeModal, setActiveModal] = useRecoilState(activeModalAtom);
  const isURLSubmission = activeModal?.payload?.urlSubmissionMetadata;

  const resetModal = () => {
    setErrorMessage('');
  };

  const handleSubmit = async (values: IFormSubmit) => {
    try {
      await completeZScanUpload({ appId: values.appId }, { teamId: values?.team?.value });
      props.updateUrl({ orderBy: 'created', order: 'desc', timestamp: Math.random() });
      publishEvent('table:force-fetch-zScanApps');
      handleClose();
      openSnackBar('Your application is currently being processed, please check the status of your app by refreshing the page after some time. You will also receive email(s) regarding updates');

    } catch (e) {
      if (axios.isAxiosError(e)) {
        const status = e?.response?.status ?? -1;
        let statusText = e?.response?.data?.message ?? 'Unknown Error';
        if (status === 404) {
          statusText = 'Build is not found';
        }
        const dataString =
          typeof e?.response?.data === 'string'
            ? e.response.data
            : 'Please Try Again';
        console.log(e?.response);
        setErrorMessage(formatErrorMessage(statusText, dataString, status));
      } else {
        console.log(e);
        setErrorMessage(formatErrorMessage('Upload Error', 'Please Try Again'));
      }
    }
  };

  const handleClose = useCallback(() => {
    setActiveModal(undefined);
    resetModal();
  }, [setActiveModal]);

  return (
    <Modal
      caption={`Complete Upload`}
      // className={'classes.modal'}
      onClose={handleClose}
      scrollable={true}
      title={ZScanUploadComplete_TITLE}
    >
      {isURLSubmission ?
        <SubmissionUrlForm
          data={activeModal?.payload}
          errorMessage={errorMessage}
          handleSubmit={handleSubmit}
          resetModal={resetModal}
        />
        :
        <Form
          data={activeModal?.payload}
          errorMessage={errorMessage}
          handleSubmit={handleSubmit}
          resetModal={resetModal}
        />
      }
    </Modal>
  );
};

export default withRouter(ZScanUploadAppForm);
