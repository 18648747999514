/**
 * Global Modal Imports Here
 *
 * i.e.
 * import GlobalModalA from './GlobalModalA';
 * import GlobalModalB from './GlobalModalB';
 * import GlobalModalC from './GlobalModalC';
 */
import GenericPromptModal from './GenericPrompt';

const Modals: React.FC = () => (
  <>
    {/* <GlobalModalA/> */}
    {/* <GlobalModalB/> */}
    {/* <GlobalModalC/> */}
    <GenericPromptModal />
  </>
);

export default Modals;
