import BarGraph from 'components/UI/graphs/BarGraph';
import { IGraphData } from 'components/UI/graphs/common/models';
import { useEffect, useState } from 'react';
import { IQueryParams } from '../common/models';
import useStyles from './useStyles';
import { GRAPH_OPTIONS } from './utils';

interface IThreatsByAppGraphProps {
  graphData?: IGraphData;
  query: IQueryParams;
  updateUrl: (params: IQueryParams) => void;
}

const ThreatsByAppGraph: React.FC<IThreatsByAppGraphProps> = ({
  graphData: threatGraphData,
  query,
}) => {
  const classes = useStyles();

  const [initialized, setInitialized] = useState<boolean>(false);

  const [graphData, setGraphData] = useState<IGraphData | undefined>();

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }
    if (!!threatGraphData && !!query) {
      setGraphData(threatGraphData);
    }
  }, [threatGraphData, query, initialized]);

  return (
    <div className={classes.graph}>
      {graphData && (
        <BarGraph data={graphData} height={51} options={GRAPH_OPTIONS} />
      )}
    </div>
  );
};

export default ThreatsByAppGraph;
