import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { fetchConnections } from 'api/MDMService';
import withRouter from 'components/hocs/withRouter';
import CheckBox from 'components/inputs/Checkbox';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useStyles from './useStyles';
import { useTranslation } from 'react-i18next';

export const GroupsFacetedSearch = withRouter((props) => {
  const classes = useStyles();
  const { emmConnectionId } = props.q;
  const { t, ready } = useTranslation();

  const accountId = useSelector((state) => _.get(state, 'user.account.id'));

  const [emmConnections, setEmmConnections] = useState([]);

  useEffect(() => {
    fetchConnections({ accountId }).then(({ data }) => {
      setEmmConnections(data);
    });
  }, [accountId]);

  if (!ready) {
    return null;
  }

  return (
    <Accordion className={classes.collapseRoot}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <h4>{t('MTD.POLICIES.GROUPS.EMM_CONNECTION')}</h4>
      </AccordionSummary>
      <AccordionDetails>
        <ul className={classes.listRoot}>
          {emmConnections.length > 0 ? (
            emmConnections.map((connection) => (
              <li key={connection.id} className={classes.elementRoot}>
                <CheckBox
                  label={
                    <span className={classes.elementLabel}>
                      {connection.name}
                    </span>
                  }
                  onChange={onFacetChange(
                    'emmConnectionId',
                    connection.id,
                    props
                  )}
                  defaultChecked={checkDefault(emmConnectionId, connection.id)}
                />
              </li>
            ))
          ) : (
            <p style={{ whiteSpace: 'pre-wrap' }}>
              {t('MTD.POLICIES.GROUPS.NO_EMM_CONNECTIONS_AVAILABLE')}
            </p>
          )}
        </ul>
      </AccordionDetails>
    </Accordion>
  );
});

function checkDefault(param, value) {
  if (_.isArray(param)) {
    return param.includes(value);
  }

  return param === value;
}

// eslint-disable-next-line
function onSelectFacetChange({ updateUrl }) {
  return (name, value) => {
    updateUrl({ [name]: value.map(v => v.value) });
  };
}

function onFacetChange(name, value, props) {
  return () => changeFacet(name, value, props);
}

function changeFacet(name, value, { updateUrl, q }) {
  if (!_.has(q, name)) {
    updateUrl({ [name]: value });
    return;
  }

  // deal with multiple values
  if (_.isArray(q[name])) {
    const newValues = [...q[name]];

    // remove the value
    if (q[name].includes(value)) {
      _.pull(newValues, value);
    } else {
      newValues.push(value);
    }

    updateUrl({ [name]: newValues });
  }

  if (_.isString(q[name])) {
    // remove value
    if (q[name] === value) {
      updateUrl({ [name]: undefined });
      return;
    }

    updateUrl({ [name]: [q[name], value] });
  }
}

// eslint-disable-next-line
function deriveValuesFromHash(hash, values) {
  if (!values) {
    return [];
  }

  const map = _.isArray(values) ? values : [values];
  return map.map(value => ({
    label: _.get(hash, `${value}.label`, 'N/A'),
    value,
  }));
}
