import MUIAppBar from '@material-ui/core/AppBar';
import useStyles from './useStyles';

interface IStickyAppBarProps {
  children: React.ReactNode;
}

const StickyAppBar: React.FC<IStickyAppBarProps> = ({ children }) => {
  const classes = useStyles();
  return (
    <MUIAppBar
      className={`${classes.titleBar} zappbar-sticky`}
      position="fixed"
    >
      {children}
    </MUIAppBar>
  );
};

export default StickyAppBar;
