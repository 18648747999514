import _ from 'lodash';
import { createAction, handleActions } from 'redux-actions';
import { mappedModals } from 'components/modals';
import AT from 'actions/ActionTypes';

export const UI_TOGGLE_MODAL = 'UI_TOGGLE_MODAL';

// selectors
export const getModals = state => state.ui.modals;

// actions
export const toggleModal = createAction(
  UI_TOGGLE_MODAL,
  (key, content, config) => ({
    key,
    content,
    config,
  })
);
// reducer
const initialState = {
  modals: {
    modalConfig: {},
    modalContent: {},
    ...mappedModals,
  },
};

const UiReducer = handleActions(
  {
    [UI_TOGGLE_MODAL]: (state, action) => {
      const activeModalKey = Object.keys(state.modals).find(
        key => _.isBoolean(state.modals[key]) && !!state.modals[key]
      );
      const updates = {};

      if (activeModalKey) {
        updates[activeModalKey] = false;
      }

      if (action.payload.key) {
        updates[action.payload.key] = true;
      }

      // user wants to close the current modal
      if (action.payload.key && action.payload.content === false) {
        updates[action.payload.key] = false;
      }

      return {
        ...state,
        modals: {
          ...state.modals,
          ...updates,
          modalContent:
            action.payload.content || initialState.modals.modalContent,
          modalConfig: action.payload.config || initialState.modals.modalConfig,
        },
      };
    },

    [AT.USER_LOGOUT]: () => {
      return initialState;
    },
  },
  initialState
);

export default UiReducer;
