import { fetchDataExportActiveExist } from 'api/DataExportService';
import { fetchMDMConnectionEnabled } from 'api/MDMService';
import { fetchMTDStatus } from 'api/MTDPolicy';
import { IMDMIntegration, ISIEMIntegration } from './models';

export const fetchMDMIntegrationConfigured = async (
  teamId?: string | undefined
) => {
  const result: { data: IMDMIntegration } = await fetchMDMConnectionEnabled({
    teamId,
  });
  
  return result?.data;
};

export const fetchMTDPolicyStatus = async (teamId?: string | undefined) => {
  const result: { data: IMDMIntegration } = await fetchMTDStatus({ teamId });

  return result?.data;
};

export const fetchSIEMIntegrationConfigured = async () => {
  const result: { data: ISIEMIntegration } = await fetchDataExportActiveExist();

  return result?.data?.activeConfigurationsExist;
};
