import { useMemo } from 'react';
import { Line as CJSLine } from 'react-chartjs-2';
import { ICJSGraph, IGraphData, IGraphOptions } from '../common/models';
import { IColors } from './models';
import {
  defaultOptions,
  generateColorStops,
  getGenerateGraphDataFn,
} from './utils';

const CJSLineGraph = CJSLine as unknown as React.FC<ICJSGraph>;

interface ILineGraph {
  clickHandler?: (elems: Element[]) => void;
  colors: IColors;
  graphData?: IGraphData;
  height?: number;
  onGraphClick?: (...args: unknown[]) => void;
  options?: IGraphOptions;
  ref?: string;
  width?: number;
}

const LineGraph: React.FC<ILineGraph> = ({
  clickHandler,
  colors,
  graphData,
  height,
  onGraphClick,
  options: initialOptions,
  ref,
  width,
}) => {
  const options = {
    ...defaultOptions,
    ...initialOptions,
  };

  const colorStops = useMemo(() => generateColorStops(colors), [colors]);

  if (!graphData) {
    return null;
  }

  return (
    <CJSLineGraph
      data={getGenerateGraphDataFn(colors, colorStops, graphData)}
      getElementAtEvent={clickHandler}
      height={height}
      options={options}
      onGraphClick={onGraphClick}
      ref={ref}
      width={width}
    />
  );
};

export default LineGraph;
