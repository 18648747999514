import cc from 'classcat';
import withRouter from 'components/hocs/withRouter';
import ProtectedComponent from 'components/main/protected/ProtectedComponent';
import Button from 'components/UI/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { resetCatHandling as resetCategoriesAtom } from '../atoms';
import { ICategory } from '../models';
import useStyles from '../useStyles';
import CategoryTree from './CategoryTree';
interface ICategoryHandlingListProps {
  show: boolean;
  categories: {
    categoryGroups: [];
  };
  categoriesFromApi?: ICategory[];
}

const CategoryHandlingList: React.FC<ICategoryHandlingListProps> = (props) => {
  const classes = useStyles();
  const setResetCategories = useSetRecoilState(resetCategoriesAtom);
  const { t } = useTranslation();

  return (
    <div
      className={cc([
        classes.categoryList,
        {
          [classes.categoryListShow]: props.show,
        },
      ])}
    >
      <div className={cc([classes.card, classes.categoryGroupTitle])}>
        {t('MTD.POLICIES.PHISHING_POLICY.CATEGORY_HANDLING')}
      </div>
      <div className={classes.categoryListPadding}>
        <div>
          <ProtectedComponent allow={{ policies: 'manage' }}>
            <Button
              text={t('MTD.POLICIES.PHISHING_POLICY.USE_RECOMMENDED_SETTINGS')}
              color="primary"
              form="phishingPolicyForm"
              onClick={() => {
                setResetCategories(true);
              }}
            />
          </ProtectedComponent>
        </div>
        <div className={cc([classes.card, classes.categoryListColumnTitle])}>
          <div>{t('GLOBAL.CATEGORY')}</div>
          <div>{t('GLOBAL.ACTION')}</div>
        </div>
        <div>
          <CategoryTree
            categoryList={props.categories}
            categFromApi={props.categoriesFromApi}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(CategoryHandlingList);
