import AppsIcon from '@material-ui/icons/Apps';
import IntegrationsIcon from '@material-ui/icons/SettingsInputComposite';
import PolicyIcon from '@material-ui/icons/VerifiedUser';
import DownloadsIcon from '@material-ui/icons/CloudDownload';
// import BuildPolicies from '../../dashboard/zDev/buildsPolicies/BuildPolicies';
import BuildsDownloads from '../../dashboard/zDev/buildsDownloads/BuildsDownloads';
import Integrations from '../../dashboard/zDev/Integrations/index';
import ZScanApps from 'components/main/ZScanApps';
import ZScanAssessments from 'components/main/ZScanApps/ZScanAssessments';
import ZScanCompareAssessments from 'components/main/ZScanApps/ZScanCompareAssessments';
import ZScanFindings from 'components/main/ZScanApps/ZScanFindings';
import ZScanPolicies from 'components/main/ZScanPolicies';

export const CONSOLE_ZDEV_ROUTES = [
  {
    path: '/console/zscan/apps',
    component: ZScanApps,
    exact: true,
    allow: { zdev_apps: 'view' },
    rqps: ['page', 'size', 'orderBy', 'order'],
    nav: {
      name: 'Apps',
      order: 3,
      icon: <AppsIcon />,
    },
  },
  {
    path: '/console/zscan/assessments',
    component: ZScanAssessments,
    allow: { zdev_apps: 'view' },
    rqps: ['page', 'size', 'orderBy', 'order'],
  },
  {
    path: '/console/zscan/compare-assessments/:id1/:id2',
    component: ZScanCompareAssessments,
    allow: { zdev_assessments: 'view' },
    //rqps: ['page', 'size', 'orderBy', 'order', 'cbo'],
  },
  {
    path: '/console/zscan/assessment/:assessmentId/findings',
    component: ZScanFindings,
    allow: { zdev_apps: 'view' },
    rqps: ['page', 'size', 'orderBy', 'order'],
  },
  // {
  //   path: '/console/zscan/integrations/:integrationId/configurations/:configurationId',
  //   component: Integrations,
  //   allow: { zdev_apps: 'view' },
  // },
  {
    path: '/console/zscan/integrations/:tabId',
    component: Integrations,
    allow: { zdev_apps: 'view' },
  },
  {
    path: '/console/zscan/integrations/ticketing-integrations',
    component: Integrations,
    exact: true,
    allow: { zdev_apps: 'view' },
    rqps: ['page', 'size', 'orderBy', 'order', 'duration'],
    nav: {
      name: 'Integrations',
      order: 5,
      icon: <IntegrationsIcon />,
    },
  },
  {
    path: '/console/zscan/policies?policyId:=policyId',
    component: ZScanPolicies,
    allow: { zdev_policies: 'view' },
  },
  {
    path: '/console/zscan/policies',
    component: ZScanPolicies,
    allow: { zdev_policies: 'view' },
    exact: true,
    rqps: ['orderBy', 'order'],
    nav: {
      name: 'Policies',
      order: 6,
      icon: <PolicyIcon />,
    },
  },
  // {
  //   path: '/console/zscan/policies/:policyId',
  //   component: BuildPolicies,
  //   allow: { zdev_policies: 'view' },
  // },

  // {
  //   path: '/console/zscan/policies',
  //   component: BuildPolicies,
  //   exact: true,
  //   allow: { zdev_policies: 'view' },
  //   rqps: ['orderBy', 'order'],
  //   nav: {
  //     name: 'Policies',
  //     order: 6,
  //     icon: <PolicyIcon />,
  //   },
  // },
  {
    path: '/console/zscan/downloads',
    component: BuildsDownloads,
    exact: true,
    allow: { zdev_assessments: 'view' },
    nav: {
      name: 'Downloads',
      order: 7,
      icon: <DownloadsIcon />,
    },
  },
];
