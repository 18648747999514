import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    topArea: {
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'space-between',
    },
    selectorWrapper: {
      width: 500,
      marginBottom: 16,
    },
    tableContainer: {
      maxHeight: 'calc(100vh - 170px)',
      overflowY: 'auto',
    }
  })
);

export default useStyles;
