import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import withRouter from 'components/hocs/withRouter';

const ProtectedRoute = ({
  allow,
  component: Component,
  rqps,
  requiredQueryParams,
  scopes,
  mtdModule,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      const userHasScope = !_.isEmpty(scopes);
      if (scopes.tfaRequired) {
        return <Redirect to="/tfa" />;
      }
      // allowed scopes
      let hasRequiredScope;
      if (allow) {
        hasRequiredScope =
          (allow === 'any' && userHasScope) ||
          _.find(Object.keys(allow), scope => _.has(scopes, scope));
      }

      if (
        // route requires a certain scope
        hasRequiredScope ||
        // allows scoped users to access non-scopebound routes
        (_.isEmpty(allow) && userHasScope) ||
        // catches the redirect to render user profile
        props.location.pathname === '/console/user-settings'
      ) {
        return (
          <Component
            {...props}
            rqps={rqps || requiredQueryParams}
            mtdModule={mtdModule}
          />
        );
      }

      if (!userHasScope) {
        return <Redirect to="/console/user-settings" />;
      }

      return <Redirect to="/console/notfound" />;
    }}
  />
);

const mapStateToProps = ({ auth }) => {
  return {
    scopes: auth.user.scopes,
  };
};

export default withRouter(connect(mapStateToProps)(ProtectedRoute));
