import _ from 'lodash';

export const fetchDataExportParamsMapping = [
  {
    path: 'page',
  },
  {
    path: 'size',
  },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      if (orderBy && order) {
        return `${orderBy},${order}`;
      }

      return null;
    },
  },
];

export const createEditDataExportPayloadMapping = [
  'name',
  'description',
  'active',
  'concise',
  'isRunning',
  'trustedCAFileName',
  {
    path: 'config',
    key: state => _.get(_.head(state.destinationType), 'configKey', null),
    transform: state => {
      if(state?.config?.authorizationType?.value === "API_KEY"){
        delete state.config.password;
        delete state.config.username;
      }
      else if(state?.config?.authorizationType?.value === "BASIC"){
        delete state.config.apiKeyFormatValue;
        delete state.config.apiKeyFormat;
      }
      return _.mapValues(state.config, value =>
          _.isObjectLike(value) ? _.get(value, 'value', undefined) : value
      );
    }
  },
  {
    path: 'dataType',
    transform: state => _.get(_.head(state.dataType), 'value'),
  },
  {
    path: 'destinationType',
    transform: state => _.get(_.head(state.destinationType), 'value'),
  },
  {
    path: 'conditions',
    transform: state => {
      const severityConditions = state.severityCriteria.reduce(
        (acc, severity) => {
          acc.value.push(severity.value);
          return acc;
        },
        {
          field: 'severity',
          operator: 'CONTAINS',
          value: [],
        }
      );

      const teamConditions = state.teamCriteria.reduce(
        (acc, teamId) => {
          acc.value.push(teamId.value);
          return acc;
        },
        {
          field: 'teamId',
          operator: 'CONTAINS',
          value: [],
        }
      );

      let final = [];

      if (severityConditions.value.length) {
        final = [severityConditions];
      }

      if (teamConditions.value.length) {
        final.push(teamConditions);
      }

      if (state.externalTrackingId1) {
        final.push({
          field: 'ZEvent.env.versionDetails.externalTrackingId1',
          operator: 'EQUALS',
          value: state.externalTrackingId1,
        });
      }

      if (state.externalTrackingId2) {
        final.push({
          field: 'ZEvent.env.versionDetails.externalTrackingId2',
          operator: 'EQUALS',
          value: state.externalTrackingId2,
        });
      }

      return final.length ? final : [];
    },
  },
];
