import { ISelectItem } from 'components/UI/input/Select';
import { IResponseData } from './models';

export const initialValues = (
  { data, availableTeams }:
    {
      data: Partial<IResponseData>,
      availableTeams: ISelectItem[];
    }
) => {
  const preSelectedTeam = availableTeams[0] ? availableTeams.find(
    ({ value }) => value === data?.teamId) : undefined;

  return {
    team: preSelectedTeam,
    name: data?.zdevUploadResponse?.appName,
    appId: data?.zdevAppId,
    buildId: data?.buildId,
    appVersion: data?.zdevUploadResponse?.appVersion,
    buildVersion: data?.zdevUploadResponse?.appBuildVersion,
    bundleId: data?.zdevUploadResponse?.bundleIdentifier
  };
};
