import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

export default state => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      devices: {
        ...uiSettingsDefaults.devices,
        tableHeaders: uiSettingsDefaults.devices.tableHeaders,
      },
      threats: {
        ...uiSettingsDefaults.threats,
        tableHeaders: uiSettingsDefaults.threats.tableHeaders,
      },
      inappsVersions: {
        ...uiSettingsDefaults.inappsVersions,
        tableHeaders: uiSettingsDefaults.inappsVersions.tableHeaders,
      },
    },
  };
};
