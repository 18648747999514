import React from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import { Checkbox } from 'components/inputs';
import CircularProgress from '@material-ui/core/CircularProgress';
import SelectClasses from './MultiSelectStyles';

const Blanket = props => <Paper {...props} />;

export const Menu = withStyles(SelectClasses)(props => {
  return (
    <Paper square className={props.classes.paper}>
      {props.children}
    </Paper>
  );
});

export function Dropdown({ children, isOpen, target, isLoading, fullWidth }) {
  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <CircularProgress
          size={25}
          style={{
            position: 'absolute',
            right: 10,
            zIndex: 101,
          }}
        />
        {target}
      </div>
    );
  }

  return (
    <div style={{ position: 'relative', width: fullWidth ? '100%' : 'auto' }}>
      {target}
      {isOpen ? <Menu>{children}</Menu> : null}
      {isOpen ? <Blanket /> : null}
    </div>
  );
}

export function DropdownIndicator() {
  return <SearchIcon />;
}

export function Option(props) {
  return (
    <MenuItem
      className={props.selectProps.classes.menuItem}
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      disabled={_.get(props, 'data.disabled')}
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.isMulti ? (
        <React.Fragment>
          <Checkbox checked={props.isSelected} /> {props.children}
        </React.Fragment>
      ) : (
        <div
          style={{
            paddingLeft: '10px',
            paddingRight: '10px',
          }}
        >
          {props.children}
        </div>
      )}
    </MenuItem>
  );
}

export function MenuList(props) {
  return (
    <div className={props.selectProps.classes.menuList} id="menu-list">
      {props.children}
    </div>
  );
}
