import { withStyles } from '@material-ui/core';
import Table from 'components/UI/Table';
import {
  IColumnHeader,
  IFilterEditor,
  ILocationQuery,
  TClassList,
} from 'components/UI/Table/models';
import { withBackfill_DEPRECATED } from 'components/hocs/withBackfill';
import withRouter from 'components/hocs/withRouter';
import { useCallback, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import {
  updateUISettings,
  mtdOSRelatedCVEsTableColumnChange,
} from 'reducers/UiSettingsReducers';
import {
  getChangedTableHeadersHash,
  getUISettingsTableHeaders,
} from 'reducers/UiSettingsSelectors';
import { bindActionCreators, compose } from 'redux';
import { withDirtyState } from 'utils/withDirtyState';
import withMemoizedProps from 'utils/withMemoizedProps';
import { selectedTeam as selectedTeamAtom } from '../../../../atoms/teams';
import { IColumnChangeFn, IPaginationChangeFn, IState } from '../common/models';
import { COLUMN_MAPPINGS, resolveFilters } from './OSRelatedCVEs.mappings';
import { IFilterValues } from './models';
import styles from './styles';
import tableQuery from './tableQuery';
import { INITIAL_FILTER_VALUES } from './utils';
import { useTranslation } from 'react-i18next';

interface IOSRelatedCVEsTab {
  classes: TClassList;
  currentTableHeaders: IColumnHeader[];
  jiggleDirtyState: () => void;
  q: ILocationQuery;
  mtdOSRelatedCVEsTableColumnChange: IColumnChangeFn;
  updateUrl: IPaginationChangeFn;
}

const OSRelatedCVEsTab: React.FC<IOSRelatedCVEsTab> = ({
  classes,
  currentTableHeaders,
  jiggleDirtyState,
  mtdOSRelatedCVEsTableColumnChange,
  q: query,
  updateUrl,
}) => {
  const { ready, t } = useTranslation();
  const accountId = useSelector((state: IState) => state.user.account.id);
  const [filterValues, setFilterValues] = useState<IFilterValues>(
    INITIAL_FILTER_VALUES
  );
  const filters = useMemo(
    () => resolveFilters(filterValues, t),
    [filterValues, t]
  );
  const selectedTeamId = useRecoilValue(selectedTeamAtom);

  const handleColumnChange: IColumnChangeFn = useCallback(
    (value, index, columnData) => {
      jiggleDirtyState();
      mtdOSRelatedCVEsTableColumnChange(value, index, columnData);
    },
    [jiggleDirtyState, mtdOSRelatedCVEsTableColumnChange]
  );

  const onPaginationChange: IPaginationChangeFn = useCallback(
    (update) => {
      updateUISettings({
        domain: 'mtdOSRelatedCVEs',
        update,
      });

      updateUrl(update);
    },
    [updateUrl]
  );

  const handleFilterChange = useCallback((filterEditors: IFilterEditor[]) => {
    const newFilterValues: IFilterValues = {};
    filterEditors.forEach(({ name, value }) => {
      newFilterValues[name] = value;
    });
    setFilterValues(newFilterValues);
  }, []);

  if (!ready) {
    return null;
  }

  return (
    <div className={classes.tableWrapper}>
      <Table
        classList={classes}
        columnHeaders={currentTableHeaders}
        fetchTableData={() =>
          tableQuery(accountId, filters, selectedTeamId)(query)
        }
        filters={filters}
        onColumnChange={handleColumnChange as () => void}
        onFilterValueChange={handleFilterChange as () => void}
        onPaginationChange={onPaginationChange}
        query={query}
        rowMapping={COLUMN_MAPPINGS(t)}
        tableId={'mtdOSRelatedCVEs'}
      />
    </div>
  );
};

const mapStateToProps = (state: IState) => {
  return {
    zShieldAppsColumnHeadersHash: getChangedTableHeadersHash(
      state,
      'mtdOSRelatedCVEs'
    ),
    currentTableHeaders: getUISettingsTableHeaders(state, 'mtdOSRelatedCVEs'),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      mtdOSRelatedCVEsTableColumnChange,
      updateUISettings,
    },
    dispatch
  );
};

export default compose(
  withStyles(styles),
  withRouter,
  withBackfill_DEPRECATED('uiSettings.mtdOSRelatedCVEs'),
  withDirtyState(),
  connect(mapStateToProps, mapDispatchToProps)
)(
  withMemoizedProps(OSRelatedCVEsTab, [
    'currentTableHeaders',
    'dirtyState',
    'q',
    'updateUISettings',
  ])
) as React.FC<Partial<IOSRelatedCVEsTab>>;
