import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import withRouter from 'components/hocs/withRouter';
import { withTranslation } from 'react-i18next';

import { TablePagination as MaterialTablePagination } from '@material-ui/core';

class TablePagination extends React.Component {
  constructor(props) {
    super(props);

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  shouldComponentUpdate(prevProps) {
    const queryKeys = ['size', 'page'];
    const _prevProps = _.pick(prevProps.location.query, queryKeys);
    const _props = _.pick(this.props.location.query, queryKeys);

    if (!_.isEqual(_prevProps, _props)) {
      return true;
    }

    if (!_.eq(prevProps.totalResults, this.props.totalResults)) {
      return true;
    }

    return false;
  }

  render() {
    const { props } = this;
    const { t } = this.props;
    const pageSize =
      Number(_.get(props.location.query, 'pageSize')) ||
      Number(_.get(props.location.query, 'size')) ||
      props.rowsPerPageOptions[0];
    const pageIndex =
      Number(_.get(props.location.query, 'pageIndex')) ||
      Number(_.get(props.location.query, 'page')) ||
      0;
    const totalResults = Number(props.totalResults);

    return (
      <MaterialTablePagination
        count={totalResults}
        rowsPerPage={pageSize}
        page={pageIndex}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeRowsPerPage}
        rowsPerPageOptions={props.rowsPerPageOptions}
        labelRowsPerPage={t('GLOBAL.ROWS_PER_PAGE')}
      />
    );
  }

  handleChangePage(event, page) {
    const { props } = this;
    props.updateUrl({ page });

    if (_.isFunction(props.onChangePage)) {
      props.onChangePage({
        page,
      });
    }
  }

  handleChangeRowsPerPage(event) {
    const { props } = this;
    props.updateUrl({ page: 0, size: event.target.value });

    if (_.isFunction(props.onChangeRows)) {
      props.onChangeRows({
        page: 0,
        size: event.target.value,
      });
    }
  }
}

TablePagination.defaultProps = {
  rowsPerPageOptions: [25, 50, 100],
  totalResults: 0,
};

TablePagination.propTypes = {
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  totalResults: PropTypes.number,
};

export default withRouter(withTranslation()(TablePagination));
