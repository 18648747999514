import { useMemo } from 'react';
import { IConnector } from '../../models';
import GenericTable from 'components/UI/GenericTable';
import { headers, getRowMapping } from './ResponseActionGroupTable.mappings';
import { IResponseGroupMapping } from './models';
import useStyles from './useStyles';
import NoDataTableContent from './NoDataTableContent';
import { TFunction } from 'react-i18next';
interface IResponseActionTableProps {
  t: TFunction<'translation', undefined>;
  connector?: IConnector;
  responseGroupMappings: IResponseGroupMapping[];
  handleAddResponseActionGroupModal: () => void;
  handleDeleteResponseActionGroup: (id: string) => void;
}
const ResponseActionGroupTable: React.FC<IResponseActionTableProps> = ({
  t,
  connector,
  responseGroupMappings,
  handleAddResponseActionGroupModal,
  handleDeleteResponseActionGroup,
}) => {
  const classes = useStyles();

  const rowMapping = useMemo(
    () =>
      getRowMapping(
        classes,

        handleAddResponseActionGroupModal,
        handleDeleteResponseActionGroup
      ),
    [
      classes,
      handleAddResponseActionGroupModal,
      handleDeleteResponseActionGroup,
    ]
  );
  return (
    <GenericTable
      columnHeaders={headers(connector?.id, classes, t)}
      classList={classes}
      rowMapping={rowMapping}
      noDataTableContent={
        <NoDataTableContent onHandleClick={handleAddResponseActionGroupModal} />
      }
      tableData={responseGroupMappings}
      tableId="EMMResponseGroupTableId"
    />
  );
};
export default ResponseActionGroupTable;
