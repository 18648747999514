import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

export default state => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      buildsApps: {
        ...uiSettingsDefaults.buildsApps,
        tableHeaders: uiSettingsDefaults.buildsApps.tableHeaders,
      },
    },
  };
};
