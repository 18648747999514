import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    paddedContainer: {
      padding: 10,
      fontSize: 14,
      textAlign: 'center',
    },
  })
);

export default useStyles;
