import { fetchDevicesStatsByAccount } from 'api/DevicesService';
import { IAbstractProportionChartColumn } from 'components/UI/Chart/AbstractProportion/models';
import { EInsightsDashboardOSType, IDeviceStats } from './models';
import { TFunction } from 'react-i18next';

export const fetchOSRiskData = async (
  accountId?: string | undefined,
  selectedTeamId?: string | undefined
) => {
  const result: { data: IDeviceStats } = await fetchDevicesStatsByAccount({
    accountId,
    teamId: selectedTeamId,
  });

  return result?.data;
};

export const generateAbstractProportionChart = (
  t: TFunction<'translation', undefined>,
  notUpgradeableCount: number,
  OS: EInsightsDashboardOSType,
  upgradeableCount: number
): IAbstractProportionChartColumn[] => [
  {
    count: upgradeableCount,
    label: t('MTD.INSIGHTS.VULNERABLE_UPGRADEABLE'),
    link: `/console/mtd/devices/?os.name=${OS}&duration=9&vulnerable=true&upgradeable=true`,
    suffix: t('GLOBAL.DEVICES'),
  },
  {
    count: notUpgradeableCount,
    label: t('MTD.INSIGHTS.VULNERABLE_NON_UPGRADEABLE'),
    link: `/console/mtd/devices/?os.name=${OS}&duration=9&vulnerable=true&upgradeable=false`,
    suffix: t('GLOBAL.DEVICES'),
  },
];
