import React from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { logout } from 'actions/UserActions';
import useIdle from 'workers/useIdle';
import { clearAuthUser } from 'reducers/AuthReducers';

const PartnerDashboardRoute = ({
  component: Component,
  isAuthenticated,
  isUserAuthenticated,
  ...rest
}) => {
  const isIdle = useIdle({
    timeToIdle: rest.customIdleTime,
    inactivityEvents: [],
  });
  if (isIdle) {
    rest.clearAuthUser();
    rest.logout();
    return <Redirect to={{ pathname: '/login' }} />;
  }
  return (
    <Route
      {...rest}
      render={props =>
        !isUserAuthenticated && isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/console',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = ({ auth, uiSettings }) => {
  return {
    isAuthenticated: auth.partnerUser.isAuthenticated,
    isUserAuthenticated: auth.user.isAuthenticated,
    customIdleTime: _.get(uiSettings, 'customIdleTime', 900000),
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      logout,
      clearAuthUser,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PartnerDashboardRoute)
);
