import { trimAndAddAsterisk } from 'utility/QueryUtility';
import Moment from 'moment';

export const fetchDeviceParamsMapping = [
  {
    path: 'after',
    transform: ({ after, duration }) => {
      if (Number(duration) === 9) {
        return null;
      }

      return after ? after.toISOString() : null;
    },
  },
  {
    path: 'before',
    transform: ({ before, duration }) => {
      if (Number(duration) === 9) {
        return null;
      }

      return before ? before.toISOString() : new Date().toISOString();
    },
  },
  {
    path: 'from',
    paramKey: 'after',
    transform: ({ from, to }) => {
      if (!from) {
        return null;
      }

      return from === to
        ? Moment.unix(from).startOf('day').toISOString()
        : Moment.unix(from).toISOString();
    },
  },
  {
    path: 'to',
    paramKey: 'before',
    transform: ({ to, from }) => {
      if (!to) {
        return null;
      }

      return from === to
        ? Moment.unix(to).endOf('day').toISOString()
        : Moment.unix(to).toISOString();
    },
  },
  {
    path: 'os',
    paramKey: 'os.name',
  },
  {
    path: 'page',
  },
  {
    path: 'size',
  },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      switch (orderBy) {
        case 'appStatus':
        case 'id':
        case 'model':
        case 'os.name':
        case 'os.version':
        case 'os.patchDate':
        case 'zappInstance.externalTrackingId1':
        case 'zappInstance.externalTrackingId2':
        case 'zappInstance.zappName':
        case 'zappInstance.name':
        case 'zappInstance.version':
        case 'zappInstance.zversion':
        case 'zappInstance.buildNumber':
        case 'zappInstance.zbuildNumber':
        case 'zdeviceId':
        case 'mdmDeviceId':
        case 'mamDeviceId':
        case 'zappInstance.bundleId':
          return `${orderBy}.keyword,${order}`;
        case 'zappInstance.groupName':
          return `zappInstance.groupId.keyword,${order}`;
        case 'activationName':
          return `activationName.keyword,${order}`;
        case 'deviceOwner':
          return `deviceOwner.email.keyword,${order}`;
        default:
          if (orderBy && order) {
            return `${orderBy},${order}`;
          }
          return null;
      }
    },
  },
  {
    path: 'search',
    transform: ({ search = '' }) => {
      if (!search) {
        return null;
      }

      return trimAndAddAsterisk(search);
    },
  },
  {
    path: 'teamId',
    paramKey: 'teamId.keyword',
  },
  {
    path: 'zappId',
    paramKey: ({ zappId }) => {
      return zappId !== 'All' ? 'zappInstance.zappId.keyword' : 'zappId';
    },
    transform: ({ zappId }) => {
      if (zappId !== 'All') {
        return zappId;
      }

      return undefined;
    },
  },

  // Mappings for search fields
  {
    path: 'severity',
  },
  {
    path: 'vector',
  },
  {
    path: 'lastSeen',
  },
  {
    path: 'created',
  },
  {
    path: 'zappInstance.externalTrackingId1',
    paramKey: 'zappInstance.externalTrackingId1.keyword',
  },
  {
    path: 'zappInstance.externalTrackingId2',
    paramKey: 'zappInstance.externalTrackingId2.keyword',
  },
  {
    path: 'zappInstance.zappName',
    paramKey: 'zappInstance.name',
  },
  {
    path: 'zappInstance.version',
    paramKey: 'zappInstance.version.keyword',
  },
  {
    path: 'zappInstance.buildNumber',
  },
  {
    path: 'zappInstance.bundleId',
    paramKey: 'zappInstance.bundleId.keyword',
  },
  {
    path: 'zappInstance.groupId',
  },
  {
    path: 'zappInstance.zversion',
    paramKey: 'zappInstance.zversion.keyword',
  },
  {
    path: 'zappInstance.zbuildNumber',
    paramKey: 'zappInstance.zbuildNumber.keyword',
  },
  {
    path: 'model',
    paramKey: 'model.keyword',
  },
  {
    path: 'os.name',
  },
  {
    path: 'os.patchDate.keyword',
    paramKey: 'os.patchDate.keyword',
  },
  {
    path: 'os.version',
    paramKey: 'os.version',
  },
  {
    path: 'appVersionId',
    paramKey: 'appVersions.appVersionId.keyword',
  },
  {
    path: 'isJailbroken',
    paramKey: 'isJailbroken',
    transform: ({ isJailbroken }) => {
      if (isJailbroken === 'Yes') return 'true';
      if (isJailbroken === 'No') return 'false';
      return false;
    },
  },
  {
    path: 'isUsbDebuggingEnabled',
    paramKey: 'isUsbDebuggingEnabled',
    transform: ({ isUsbDebuggingEnabled }) => {
      if (isUsbDebuggingEnabled === 'Enabled') return 'true';
      if (isUsbDebuggingEnabled === 'Disabled') return 'false';
      return false;
    },
  },
  {
    path: 'developerOptionsOn',
    paramKey: 'developerOptionsOn',
    transform: ({ developerOptionsOn }) => {
      if (developerOptionsOn === 'Enabled') return 'true';
      if (developerOptionsOn === 'Disabled') return 'false';
      return false;
    },
  },
  {
    path: 'zappInstance.isUnknownSourceEnabled',
    paramKey: 'zappInstance.isUnknownSourceEnabled',
    transform: locationProps => {
      if (locationProps['zappInstance.isUnknownSourceEnabled'] === 'Yes')
        return 'true';
      if (locationProps['zappInstance.isUnknownSourceEnabled'] === 'No')
        return 'false';
      return false;
    },
  },
  {
    path: 'stagefrightVulnerable',
    paramKey: 'stagefrightVulnerable',
    transform: ({ stagefrightVulnerable }) => {
      if (stagefrightVulnerable === 'Yes') return 'true';
      if (stagefrightVulnerable === 'No') return 'false';
      return false;
    },
  },
  {
    path: 'diskNotEncrypted',
    paramKey: 'diskNotEncrypted',
    transform: ({ diskNotEncrypted }) => {
      if (diskNotEncrypted === 'Yes') return 'false';
      if (diskNotEncrypted === 'No') return 'true';
      return false;
    },
  },
  {
    path: 'lockScreenUnprotected',
    paramKey: 'lockScreenUnprotected',
    transform: ({ lockScreenUnprotected }) => {
      if (lockScreenUnprotected === 'Disabled') return 'true';
      if (lockScreenUnprotected === 'Enabled') return 'false';
      return false;
    },
  },
  {
    path: 'zdeviceId',
  },
  {
    path: 'mdmDeviceId',
  },
  {
    path: 'mamDeviceId',
    paramKey: 'mamDeviceId.keyword',
  },
  {
    path: 'groupId',
  },
  {
    path: 'activationName',
    paramKey: 'activationName.keyword',
  },
  {
    path: 'deviceOwner.email',
    paramKey: 'deviceOwner.email.keyword',
    transform: (locationProps) => {
      const fullString = locationProps?.['deviceOwner.email'];
      if (!fullString) {
        return null;
      }
      const regexForParenthesis = /\(([^)]+)\)/;
      const emailMatch = fullString.match(regexForParenthesis);
      const email = emailMatch?.[1] || fullString;
      return email;
    }
  },
  {
    path: 'module',
  },
  {
    path: 'upgradeable',
    paramKey: 'os.versionUpgradeable',
    transform: ({ upgradeable }) => {
      if (upgradeable === 'true' || upgradeable === 'false') {
        return upgradeable;
      }
      return null;
    },
  },
  // The below path with paramKey='query' is not yet composable.
  // The device mappings need to be extended to allow composibility on the query paramKey
  {
    path: 'vulnerable',
    paramKey: 'query',
    transform: ({ vulnerable }) => {
      if (vulnerable === 'true') return `os.cveCount>0`;
      return null;
    },
  },
  {
    path: 'cve',
    paramKey: 'cveId',
  },
  {
    path: 'appStatus',
    paramKey: 'appStatus.keyword',
  },
  {
    path: 'riskyThreats',
    paramKey: 'query',
    transform: ({ riskyThreats }) => {
      if (riskyThreats === 'true') return `threatState.numberOfRiskyThreats>0`;
      return null;
    },
  },
  {
    path: 'criticalThreats',
    paramKey: 'query',
    transform: ({ criticalThreats }) => {
      if (criticalThreats === 'true')
        return `threatState.numberOfCriticalThreats>0`;
      return null;
    },
  },
];
