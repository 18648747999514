import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const styles = ({ config }) => ({
  titleBar: {
    textTransform: 'none',
    margin: 0,
  },
  responseTypeCancel: {
    display: 'flex',
  },
  menu: {
    marginLeft: 8,
    textTransform: 'none',
  },
  rightAlign: {
    float: 'right',
    textTransform: 'none',
    marginTop: '1vh',
  },
  appPolicyMenu: {
    marginLeft: 8,
    textTransform: 'none',
    whiteSpace: 'nowrap',
  },
  profile: {
    marginTop: 10,
    textTransform: 'none',
    width: '100%',
  },
  login: {
    height: 40,
    padding: 8,
    marginTop: 20,
    width: '30vw',
  },
  root: {
    textTransform: 'none',
  },
  icon: {
    marginRight: 5,
    fontSize: `${config.textSizes.primer}px !important`,
  },
});

const ZButton = ({ isShowing, classlist = {}, ...props }) => {
  if (!isShowing) {
    return null;
  }

  const _styles = Object.assign(
    {},
    props.fullWidth && {
      marginLeft: 0,
      textTransform: 'none',
      alignItems: 'baseline',
    },
    props.styles
  );

  const Icon = props.icon.elem
    ? React.createElement(props.icon.elem, {
        classes: {
          root: props.classes.icon,
        },
      })
    : null;

  return (
    <Button
      variant="contained"
      color={props.color}
      classes={{ root: props.classes.root, ...classlist }}
      className={props.classes[props.styleName]}
      onClick={props.action || props.onClick}
      type={props.buttonType}
      disabled={props.isDisabled}
      fullWidth={props.fullWidth}
      style={_styles}
      form={props.form}
    >
      {Icon}
      {props.buttonText}
    </Button>
  );
};

ZButton.defaultProps = {
  styles: {},
  isShowing: true,
  icon: {
    elem: null,
  },
};

ZButton.propTypes = {
  action: PropTypes.func,
  addIcon: PropTypes.bool,
  buttonType: PropTypes.string,
  classlist: PropTypes.object,
  color: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  proLabel: PropTypes.bool,
  styleName: PropTypes.string,
};

export default withStyles(styles)(ZButton);
