export const convertFileToBase64Async = (
  file: File
): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader?.result === 'string') {
        resolve(reader.result.split(',')[1]);
      } else {
        reject();
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};
