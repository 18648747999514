import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    viewHeader: {
      display: 'flex',
      padding: 0,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'baseline',

      '& > div': {
        display: 'flex',
        alignItems: 'center',
      },
    },

    containerActionsWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    icon: {
      fontSize: 36,
      marginRight: 5,
      marginLeft: 5,
      cursor: 'pointer'
    },
  })
);

export default useStyles;