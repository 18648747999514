import { default as MUIDialogActions } from '@material-ui/core/DialogActions';
import { default as MUIDialogContent } from '@material-ui/core/DialogContent';
import { default as MUIDialogTitle } from '@material-ui/core/DialogTitle';
// TODO: Replace later
import { default as OldFileInfoCard } from 'components/fileuploader/FileInfoCard';
import Uploader from 'components/UI/Uploader';

import { FileData, IFormDescData, IUploadAppData } from './models';
import { Field as FormikField, Form as FormikForm, Formik } from 'formik';
import { Grid } from '@material-ui/core';
import {
  openSnackBar,
  toggleModalDiffered,
  toggleModalDirect,
} from 'utils/storeUtils';
import { uploadAppBinary, uploadAppUrl } from 'api/DevicesService';
import { validateAndSplitHref } from 'utils/urlUtils';
import Button from 'components/UI/Button';
import initialValues from './initialValues';
import RadioGroup, { Radio } from 'components/UI/input/RadioGroup';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './useStyles';
import TextField from 'components/UI/input/TextField';

// TODO: Replace later
const FileInfoCard: any = OldFileInfoCard;

interface IFormProps {
  data: Partial<IUploadAppData>;
}

const Form: React.FC<IFormProps> = ({ data }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedFile, setSelectedFile] = useState<FileData>({ name: '' });
  const [loading, setLoading] = useState<boolean>(false);

  const tableName = data.variant || 'UploadApp';

  useEffect(() => { }, [data]);

  const resolvedInitialValues = initialValues();

  const getFileData = (filedata: File) => {
    setSelectedFile({ name: filedata?.name || 'file', file: filedata });
  };

  const handleRemoveFile = () => {
    setSelectedFile({ name: '' });
  };

  const handleSubmitForm = async (values: IFormDescData) => {
    const { selectType, appStoreUrl } = values;
    setLoading(true);
    try {
      if (selectType === 'url') {
        await uploadAppUrl({}, { url: appStoreUrl });
      } else if (selectType === 'file') {
        const formData = new FormData();
        formData.append('file', selectedFile.file || '');
        await uploadAppBinary({}, formData);
      }
      openSnackBar(t('GLOBAL.SUCCESS_UPLOAD'));
    } catch (e: any) {
      openSnackBar(e?.response?.data?.message || t('GLOBAL.SOMETHING_WRONG'));
    }
    setLoading(false);
    toggleModalDirect(tableName, false);
  };

  const canSubmit = (values: IFormDescData) => {
    if (values.selectType === 'file') {
      return !!selectedFile.name;
    }

    if (values.selectType === 'url') {
      return !!values.appStoreUrl;
    }
  };

  const validateUrl = (value: string) => {
    let error;
    if (!value) {
      error = t('STC.REQUIRED');
    } else if (
      /^https:\/\/apps\.apple\.com\//i.test(value) ||
      /^https:\/\/play\.google\.com\//i.test(value)
    ) {
      error = '';
    } else {
      error = t('GLOBAL.INVALID_URL');
    }
    return error;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={resolvedInitialValues}
      onSubmit={handleSubmitForm}
      validateOnBlur
    >
      {({
        isSubmitting,
        values,
        setFieldValue,
        errors,
        handleSubmit: handleFormikSubmit,
      }) => (
        <div className={classes.form}>
          <FormikForm>
            <MUIDialogContent>
              <Grid container justifyContent="center">
                <MUIDialogTitle>
                  {t('MTD.POLICIES.APP_POLICY.UPLOAD_APP_FOR_ANALYSIS')}
                </MUIDialogTitle>
              </Grid>
              <RadioGroup
                label={t(
                  'MTD.POLICIES.APP_POLICY.SELECT_FILE_PROVIDE_APP_STORE_URL'
                )}
                className={classes.radioGroup}
              >
                <FormikField
                  color="primary"
                  component={Radio}
                  label={t('GLOBAL.CHOOSE_FILE')}
                  name="selectType"
                  size="small"
                  type="radio"
                  value="file"
                />
                <FormikField
                  color="primary"
                  component={Radio}
                  label={t(
                    'MTD.POLICIES.APP_POLICY.RETRIEVE_APP_FROM_APP_STORE_URL'
                  )}
                  name="selectType"
                  size="small"
                  type="radio"
                  value="url"
                />
              </RadioGroup>
              {values.selectType === 'file' && (
                <>
                  {!selectedFile.name && (
                    <div className={classes.formGroup}>
                      <Uploader
                        accept=".apk,.ipa"
                        getFileData={getFileData}
                        isShowing={!selectedFile.name}
                      />
                    </div>
                  )}
                  <FileInfoCard
                    fileData={selectedFile}
                    isShowing={!!selectedFile.name}
                    noActionButtons
                    removeFile={handleRemoveFile}
                  />
                </>
              )}
              {values.selectType === 'url' && (
                <FormikField
                  component={TextField}
                  disabled={isSubmitting}
                  label={t('MTD.POLICIES.APP_POLICY.APP_STORE_URL')}
                  name="appStoreUrl"
                  validate={validateUrl}
                  placeholder="https://apps.apple.com/us/app/fishdom/id664575829"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const parsedUrl = validateAndSplitHref(
                      e?.currentTarget?.value
                    );

                    if (!parsedUrl) {
                      setFieldValue('appStoreUrl', e?.target?.value);
                      return;
                    }

                    setFieldValue(
                      'appStoreUrl',
                      (parsedUrl?.[0] ?? '').toLowerCase() + parsedUrl?.[1] ??
                      ''
                    );
                  }}
                />
              )}
              <MUIDialogActions>
                <Button
                  onClick={toggleModalDiffered(tableName, false)}
                  color="secondary"
                  text={t('GLOBAL.CANCEL')}
                />
                <Button
                  onClick={() => handleFormikSubmit()}
                  disabled={
                    isSubmitting || !canSubmit(values) || !!errors.appStoreUrl
                  }
                  color="primary"
                  text={t('GLOBAL.UPLOAD')}
                  isLoading={loading}
                />
              </MUIDialogActions>
            </MUIDialogContent>
          </FormikForm>
        </div>
      )}
    </Formik>
  );
};

export default Form;
