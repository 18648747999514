import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    card: {
      alignItems: 'center',
      backgroundColor: palette.background.default,
      border: `1px solid ${palette.primary.border}`,
      display: 'flex',
      fontWeight: 'bold',
      justifyContent: 'center',
      marginTop: 20,
      marginBottom: 20,
      padding: '4px 0',
      position: 'relative',

      '& div': {
        marginLeft: 30,
        marginRight: 30,
      },
    },
    cardBold: {
      fontSize: 22,
      fontWeight: 'bold',
    },
    cardOutlined: {
      border: `1px solid ${palette.warning.schoolbus}`,

      '& > button': {
        color: palette.warning.schoolbus,
        cursor: 'pointer',
        minWidth: 0,
        position: 'absolute',
        right: 0,
        userSelect: 'none',

        '& svg': {
          margin: 0,
        },
      },
    },
    filterLabel: {
      fontSize: 15,
      color: palette.text.secondary,
      marginTop: 15,
      marginBottom: 5,
    },
    empty: {
      alignItems: 'center',
      border: `1px solid ${palette.text.primary}`,
      display: 'flex',
      fontSize: 36,
      height: 'calc(100vh - 290px)',
      justifyContent: 'center',
    },
    green: {
      backgroundColor: palette.common.green,
      fontSize: 13,
      padding: '8px 15px',
      textTransform: 'uppercase',

      '&:hover': {
        backgroundColor: palette.common.avocado,
      },
    },
    header: {
      color: palette.text.contrast,
    },
    policyDeployed: {
      alignItems: 'center',
      display: 'flex',

      '& div:first-child': {
        alignItems: 'center',
        border: `2px solid ${palette.common.avocado}`,
        borderRadius: 64,
        display: 'flex',
        fontSize: 36,
        height: 64,
        justifyContent: 'center',
        margin: '0 24px 0 0',
        width: 64,
        backgroundColor: palette.common.darkGrey,

        '& svg': {
          color: palette.common.white,
          height: 42,
          minHeight: 42,
          minWidth: 42,
          width: 42,
        },
      },
    },
    navigationWarning: {
      alignItems: 'center',
      display: 'flex',

      '& svg': {
        color: palette.common.tomato,
        height: 64,
        margin: '0 24px',
        minHeight: 64,
        minWidth: 64,
        width: 64,
      },
    },
    platform: {
      alignItems: 'center',
      display: 'flex',

      '& svg': {
        fontSize: 20,
        marginRight: 10,
        maxHeight: 20,
        maxWidth: 20,
        minHeight: 20,
        minWidth: 20,
      },
    },
  })
);

export default useStyles;
