import { atom } from 'recoil';
import {
  IACLListItem,
  IControlListEntry,
  IFormCategory,
  IPhishingCheckboxFormData,
} from './models';

export const hasChangesChkboxes = atom<boolean>({
  key: 'phishingPolicyChkboxes-changes',
  default: false,
});

export const hasChangesCategories = atom<boolean>({
  key: 'phishingPolicyCategories-changes',
  default: false,
});

export const resetCatHandling = atom<boolean>({
  key: 'phishingPolicyCategories-reset',
  default: false,
});

export const phishingFormValues = atom<IPhishingCheckboxFormData | undefined>({
  key: 'phishingPolicy-formValues',
  default: undefined,
});

export const phishingCategoryValues = atom<IFormCategory>({
  key: 'phishingPolicy-categoryValues',
  default: {},
});

export const phishingAclItemValue = atom<IACLListItem | undefined | null>({
  key: 'phishingPolicy-AclListItem',
  default: undefined,
});

export const phishingAclList = atom<IACLListItem[] | undefined>({
  key: 'phishingPolicy-AclList',
  default: [],
});

export const phishingAclHasChanges = atom<boolean>({
  key: 'phishingPolicy-AclListItemChanges',
  default: false,
});

export const phishingAclControlListEntriesArr = atom<IControlListEntry[]>({
  key: 'phishingPolicy-AclControlListEntriesArr',
  default: [],
});

export const aclDetailshasChanges = atom<boolean>({
  key: 'phishingPolicy-aclDetailsList',
  default: false,
});

export const aclSelectedEntriesIds = atom<string[]>({
  key: 'phishingPolicy-aclSelectedIds',
  default: [],
});
