import TextField from 'components/UI/input/TextField';
import {
  Field as FormikField,
  Form as FormikForm,
  Formik,
  FormikProps,
} from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IGenericPromptModalQuestion } from '../models';
import Schema from './schema';

export interface IFormProps {
  onSubmit: (answer: string) => void;
  renderButtonPanel: (
    formikProps: FormikProps<{ answer: string }>
  ) => JSX.Element;
}

const Form: React.FC<IGenericPromptModalQuestion & IFormProps> = ({
  defaultAnswer = '',
  label,
  onSubmit,
  placeholder = '',
  schema,
  renderButtonPanel,
  requiredCaption,
}) => {
  const { t } = useTranslation();
  const validationSchema = useMemo(
    () => Schema({ schema, requiredCaption, t }),
    [schema, requiredCaption, t]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={{
        answer: defaultAnswer,
      }}
      onSubmit={({ answer }) => onSubmit(answer)}
      validationSchema={validationSchema}
    >
      {(formikProps) => (
        <FormikForm>
          <FormikField
            component={TextField}
            label={label}
            name="answer"
            placeholder={placeholder}
          />
          {renderButtonPanel(formikProps)}
        </FormikForm>
      )}
    </Formik>
  );
};

export default Form;
