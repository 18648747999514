import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(
  ({
    palette: {
      components: { datePicker },
    },
  }: any) =>
    createStyles({
      buttonPanel: {
        alignItems: 'flex-start',
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '20px 0',
      },
      checkbox: {
        paddingTop: '8px',
      },
      dateField: {
        '& ::-webkit-calendar-picker-indicator': {
          filter: datePicker.svgFilter,
        },
      },
      grid: {
        columnGap: '30px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        width: '100%',
      },
    })
);

export default useStyles;
