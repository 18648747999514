import { ISelectItem } from 'components/UI/input/Select';
import { TFunction } from 'react-i18next';

export const INTERVAL_OPTIONS = (t: TFunction<'translation', undefined>): ISelectItem[] => [
  { label: t('GLOBAL.DAYS'), value: 'DAYS' },
  { label: t('GLOBAL.HOURS'), value: 'HOURS' },
  { label: t('GLOBAL.MINUTES'), value: 'MINUTES' },
];

export const convertSecondsToUnit = (
  seconds: number | null,
  unit: string | null
) => {
  if (seconds === null || unit === null) {
    return null;
  }

  switch (unit) {
    case 'MINUTES':
      return seconds / 60;
    case 'HOURS':
      return seconds / 60 / 60;
    case 'DAYS':
      return seconds / 60 / 60 / 24;
    default:
      return null;
  }
};

export const convertUnitToSeconds = (
  time: number | null,
  unit: string | null
) => {
  if (time === null || unit === null) {
    return;
  }

  switch (unit) {
    case 'MINUTES':
      return time * 60;
    case 'HOURS':
      return time * 60 * 60;
    case 'DAYS':
      return time * 60 * 60 * 24;
    default:
      return null;
  }
};
