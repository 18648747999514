export function arrayToCSV<T extends Object>(
  headers: string[],
  values: T[]
): string {
  const rows: string[] = [];

  rows.push(headers.join(','));

  values.forEach((row) => {
    const data = Object.values(row).join(',');
    rows.push(data);
  });

  return rows.join('\n');
}
