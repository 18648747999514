import _ from 'lodash';
import GenericDataFilter from 'components/shared/GenericDataFilter';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Input from '@material-ui/icons/Input';

class BuildsAnalysisDetails extends Component {
  render() {
    const { buildsFindingsData, dataProperty, config, omitRowPath, handlers } =
      this.props;
    let rowMapping = config[dataProperty];
    if (omitRowPath) {
      rowMapping = _.differenceBy(rowMapping, omitRowPath, 'path');
    }
    const classes = {
      ...this.props.classes,
      ...this.props.classList,
    };

    return (
      <div className={classes.wrapper}>
        {config[dataProperty] &&
          rowMapping.map((configObj, index) => {
            const path = configObj.path ? configObj.path : configObj.key;
            let value = configObj.defaultValue;
            let filterValue;

            if (_.has(buildsFindingsData, path)) {
              value = _.get(buildsFindingsData, path);
            } else {
              return null;
            }

            if (typeof configObj.fetch === 'function') {
              value = configObj.fetch(value, buildsFindingsData, handlers);
            }
            filterValue = value === null ? undefined : value;

            if (_.get(configObj, 'type', '') === 'link') {
              value = _.map(value.split('\n'), (val) => (
                <div>
                  <Link target="_blank" to={{ pathname: val }}>
                    {val} <Input className={classes.inputIcon} />
                    {/* {val} <Input /> */}
                  </Link>
                </div>
              ));
            }

            return (
              <div key={`${value}-${index}`} className={classes.dataWrapper}>
                {!!configObj?.label && (
                  <div className={classes.label}>{configObj.label}</div>
                )}
                <div
                  className={
                    !!configObj?.label
                      ? classes.labelValue
                      : classes.noLabelValue
                  }
                >
                  {configObj.filterable ? (
                    <GenericDataFilter tableValue={filterValue} path={path} />
                  ) : (
                    value
                  )}
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}

const styles = ({ palette, config }) => ({
  wrapper: {
    display: 'flex',
    width: '70%',
    alignSelf: 'flex-start',
    flexDirection: 'column',
  },

  dataWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    justifyContent: 'space-between',
  },

  label: {
    color: palette.text.secondary,
    fontWeight: 'bold',
    width: '25%',
  },

  labelValue: {
    color: palette.tables.cell,
    paddingLeft: 8,
    fontSize: 13,
    width: '75%',
    display: 'flex',
    flexWrap: 'wrap',
    whiteSpace: 'break-spaces',
  },
  noLabelValue: {
    display: 'flex',
    width: '100%',
    overflow: 'hidden',
    fontSize: config.textSizes.normal,
  },
  inputIcon: {
    fontSize: 17,
  },
});

export default withStyles(styles)(BuildsAnalysisDetails);
