import { default as MUICard } from '@material-ui/core/Card';
import React from 'react';
import useStyles from './useStyles';

interface IDrawerCardProps {
  insideAccordian?: boolean;
  title: string;
}

const DrawerCard: React.FC<IDrawerCardProps> = ({
  children,
  insideAccordian,
  title,
}) => {
  const classes = useStyles();

  const cardStyles = insideAccordian
    ? classes.accordionDrawerCard
    : classes.noAccordianDrawerCard;

  return (
    <MUICard classes={{ root: cardStyles }}>
      <div className={classes.drawerCardHeader}>{title}</div>
      <div className={classes.drawerCardContent}>{children}</div>
    </MUICard>
  );
};

export default DrawerCard;
