import Switch from '@material-ui/core/Switch';
import MUISmartphoneIcon from '@material-ui/icons/Smartphone';
import React from 'react';
import { ISelectTRMPolicyTableData, PolicyChangeOptions } from '../models';
import useStyles from '../useStyles';

interface IAlertUserProps {
  rowData: ISelectTRMPolicyTableData;
  showToggle: boolean;
  handleTRMPolicyRuleChange: (
    optionName: PolicyChangeOptions,
    value: boolean | undefined,
    rule: ISelectTRMPolicyTableData
  ) => void;
}

const AlertUser: React.FC<IAlertUserProps> = (props) => {
  const { rowData, showToggle, handleTRMPolicyRuleChange } = props;

  const classes = useStyles();

  return (
    <div className={classes.tableColumn}>
      {showToggle && (
        <Switch
          checked={rowData.alertUser}
          onChange={() => {
            handleTRMPolicyRuleChange(
              PolicyChangeOptions.AlertUser,
              !rowData.alertUser,
              rowData
            );
          }}
        />
      )}

      <MUISmartphoneIcon
        className={'table__os-icon hover--link'}
        onClick={() =>
          handleTRMPolicyRuleChange(
            PolicyChangeOptions.EditAlertUser,
            undefined,
            rowData
          )
        }
      />
    </div>
  );
};

export default React.memo(AlertUser);
