import React, { PureComponent } from 'react';

// actions
import { publishEvent } from 'utils/eventUtils';

// components
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ZButton from 'UI/Buttons/ZButton';

// services
import { deleteSelectedDataExportConfig } from 'api/DataExportService';
import {
  toggleModalDiffered,
  openSnackBar,
  toggleModalDirect,
} from '../../utils/storeUtils';

class DataExportDeleteModal extends PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <DialogContent>
        <p>Are you sure you want to delete this data export configuration?</p>
        <DialogActions>
          <ZButton
            styleName="modalCancel"
            action={toggleModalDiffered('DataExportDeleteConfirm', false)}
            color="secondary"
            buttonText="Cancel"
          />
          <ZButton
            action={this.handleSubmit}
            buttonType="submit"
            color="primary"
            styleName="modalSave"
            buttonText="Delete Configuration"
          />
        </DialogActions>
      </DialogContent>
    );
  }

  handleSubmit() {
    const { props } = this;

    deleteSelectedDataExportConfig(props.data.id)
      .then(() => {
        toggleModalDirect('DataExportDeleteConfirm', false);
        openSnackBar('Data export configuration successfully deleted');
        publishEvent('table:force-fetch-dataExport');
      })
      .catch(error => {
        toggleModalDirect('DataExportDeleteConfirm', false);
        openSnackBar('Failed to delete data export configuration');
        console.log(error);
      });
  }
}

DataExportDeleteModal.defaultProps = {
  data: {},
};

export default DataExportDeleteModal;
