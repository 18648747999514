const ActionTypes = {
  // Token
  SET_TOKEN_REQUEST: 'SET_TOKEN_REQUEST',
  SET_TOKEN_SUCCEEDED: 'SET_TOKEN_SUCCEEDED',
  SET_TOKEN_REJECTED: 'SET_TOKEN_REJECTED',

  // App
  ADD_NEW_APP_REQEUST: 'add_new_app_REQUEST',
  ADD_NEW_APP_SUCCEEDED: 'ADD_NEW_APP_SUCCEEDED',
  ADD_NEW_APP_FAILURE: 'add_new_app_FAILURE',
  SET_SELECTED_APP: 'SET_SELECTED_APP',
  CLEAR_SELECTED_APP: 'CLEAR_SELECTED_APP',
  FETCH_ALL_APPS_SUCCEEDED: 'FETCH_ALL_APPS_SUCCEEDED',
  FETCH_APPS_PAGE_SUCCEEDED: 'FETCH_APPS_PAGE_SUCCEEDED',
  FETCH_APP_VERSIONS_PAGE_REQUEST: 'fetch_app_version_page_request',
  FETCH_APP_VERSIONS_PAGE_SUCCEEDED: 'FETCH_APP_VERSIONS_PAGE_SUCCEEDED',
  FETCH_APP_VERSIONS_COUNT_SUCCEEDED: 'FETCH_APP_VERSIONS_COUNT_SUCCEEDED',
  UPDATE_SELECTED_APP_REQUEST: 'update_selected_app_REQUEST',
  UPDATE_SELECTED_APP_SUCCEEDED: 'update_selected_app_SUCCEEDED',
  UPDATE_SELECTED_APP_FAILURE: 'update_selected_app_FAILURE ',
  DELETE_SELECTED_APP_REQUEST: 'delete_selected_app_REQUEST',
  DELETE_SELECTED_APP_SUCCEEDED: 'delete_selected_app_SUCCEEDED',
  DELETE_SELECTED_APP_FAILURE: 'delete_selected_app_REJECTED',
  INAPPS_TABLE_COLUMN_CHANGE: 'inapps_table_column_CHANGE',
  INAPPS_VERSIONS_TABLE_COLUMN_CHANGE: 'inapps_versions_table_column_CHANGE',

  // SideBar
  FETCH_ZIAP_CONSOLE_VERSION_SUCCEEDED: 'FETCH_ZIAP_CONSOLE_VERSION_SUCCEEDED',

  // Downloads
  FETCH_DOWNLOADS_META_REQUEST: 'FETCH_DOWNLOADS_META_REQUEST',
  FETCH_DOWNLOADS_META_SUCCEEDED: 'FETCH_DOWNLOADS_META_SUCCEEDED',
  FETCH_DOWNLOADS_META_FAILURE: 'FETCH_DOWNLOADS_META_FAILURE',

  // Threats page
  AUTHENTICATED_USER: 'AUTHENTICATED_USER',
  RECEIVED_JWT: 'RECEIVED_JWT',
  USER_LOGOUT: 'USER_LOGOUT',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  FETCH_ALL_ROLES_SUCCEEDED: 'FETCH_ALL_ROLES_SUCCEEDED',
  FETCH_USER_ACTIVITY_PAGE_SUCCEEDED: 'FETCH_USER_ACTIVITY_PAGE_SUCCEEDED',

  SIGNUP: 'SIGNUP',

  SET_UI_THREATS_QUERY: 'SET_UI_THREATS_QUERY',
  SET_UI_DASHBOARD_RANGE: 'SET_UI_DASHBOARD_RANGE',
  SET_UI_DASHBOARD_SELECTED_APP: 'SET_UI_DASHBOARD_SELECTED_APP',
  SET_UI_THREATS_RANGE: 'SET_UI_THREATS_RANGE',
  SET_UI_THREATS_SELECTED_APP: 'SET_UI_THREATS_SELECTED_APP',
  SET_UI_DEVICES_RANGE: 'SET_UI_DEVICES_RANGE',
  TEAM_SELECT: 'TEAM_SELECT',

  // Users Table
  FETCH_USERS_TABLE_PAGE_SUCCEEDED: 'fetch_users_table_succeeded',
  FETCH_USERS_TABLE_PAGE_FAILURE: 'fetch_users_table_failure',
  FETCH_USERS_TABLE_PAGE_REQUEST: 'fetch_users_table_request',
  USERS_TABLE_COLUMN_CHANGE: 'users_table_column_CHANGE',

  // Roles Table
  FETCH_ROLES_TABLE_PAGE_REQUEST: 'fetch_roles_table_request',
  FETCH_ROLES_TABLE_PAGE_SUCCEEDED: 'fetch_roles_table_succeeded',
  FETCH_ROLES_TABLE_PAGE_FAILURE: 'fetch_roles_table_failure',

  FETCH_ALL_SCOPES_REQUEST: 'fetch_SCOPESALL__request',
  FETCH_ALL_SCOPES_SUCCEEDED: 'fetch_SCOPESALL__succeeded',
  FETCH_ALL_SCOPES_FAILURE: 'fetch_SCOPESALL__failure',

  SET_SELECTED_ROLE: 'set_selected_role',
  CLEAR_SELECTED_ROLE: 'clear_selected_role',

  ROLES_TABLE_COLUMN_CHANGE: 'roles_table_column_CHANGE',

  SET_SELECTED_USER: 'set_selected_user',
  CLEAR_SELECTED_USER: 'clear_selected_user',

  // Manage Account Profile
  FETCH_ACCOUNT_REQUEST: 'FETCH_ACCOUNT_REQUEST',
  FETCH_ACCOUNT_SUCCEEDED: 'FETCH_ACCOUNT_SUCCEEDED',
  UPDATE_ACCOUNT_SUCCEEDED: 'UPDATE_ACCOUNT_SUCCEEDED',
  SET_ACCOUNT: 'SET_ACCOUNT',

  // Manage User Account Profile
  UPDATE_CURRENT_USER_REQUEST: 'update_current_user_request',
  UPDATE_CURRENT_USER_SUCCEEDED: 'update_current_user_SUCCEEDED',
  UPDATE_CURRENT_USER_FAILURE: 'update_current_user_FAILURE',

  // Groups Table
  FETCH_GROUP_LIST_REQUEST: 'fetch_group_list_REQUEST',
  FETCH_GROUP_LIST_SUCCEEDED: 'fetch_group_list_SUCCEEDED',
  FETCH_GROUP_LIST_FAILURE: 'fetch_group_list_FAILURE',
  FETCH_GROUPS_TABLE_PAGE_SUCCEEDED: 'fetch_groups_table_succeeded',
  FETCH_GROUPS_TABLE_PAGE_FAILURE: 'fetch_groups_table_failure',
  FETCH_GROUPS_TABLE_PAGE_REQUEST: 'fetch_groups_table_request',
  // Snackbar

  // Groups
  GROUPS_TABLE_COLUMN_CHANGE: 'groups_table_column_CHANGE',

  // Privacy Polices
  FETCH_PRIVACY_COLLECTIBLES_REQUEST: 'fetch_privacy_collectibles_REQUEST',
  FETCH_PRIVACY_COLLECTIBLES_SUCCEEDED: 'fetch_privacy_collectibles_SUCCEEDED',
  FETCH_PRIVACY_COLLECTIBLES_FAILURE: 'fetch_privacy_collectibles_FAILURE',
  FETCH_PRIVACY_POLICIES_SUCCEEDED: 'fetch_all_privacy_policies_SUCCEEDED',
  FETCH_PRIVACY_POLICIES_REQUEST: 'fetch_all_privacy_policies_REQUEST',
  FETCH_PRIVACY_POLICIES_FAILURE: 'fetch_all_privacy_policies_FAILURE',
  SET_SELECTED_PRIVACY_POLICY_REQUEST: 'set_selected_privacy_policy_REQUEST',
  SET_SELECTED_PRIVACY_POLICY_SUCCEEDED:
    'set_selected_privacy_policy_SUCCEEDED',
  SET_SELECTED_PRIVACY_POLICY_FAILURE: 'set_selected_privacy_policy_FAILURE',

  CLEAR_SELECTED_PRIVACY_POLICY: 'clear_selected_privacy_policy',
  HANDLE_CHANGE_SELECTED_PRIVACY_POLICY:
    'handle_change_selected_privacy_policy',
  HANDLE_CHANGE_LOCATION_SELECTED_PRIVACY_POLICY:
    'handle_change_location_selected_privacy_policy',
  HANDLE_CHANGE_NAME_SELECTED_PRIVACY_POLICY:
    'handle_change_name_selected_privacy_policy',
  UPDATE_SELECTED_PRIVACY_POLICY_REQUEST:
    'update_selected_private_policy_REQUEST',
  UPDATE_SELECTED_PRIVACY_POLICY_SUCCEEDED:
    'update_selected_private_policy_SUCEEDED',
  UPDATE_SELECTED_PRIVACY_POLICY_FAILURE:
    'update_selected_private_policy_FAILURE',
  CREATE_NEW_PRIVACY_POLICY_REQUEST: 'create_new_privacy_policy_REQUEST',
  CREATE_NEW_PRIVACY_POLICY_SUCCEEDED: 'create_new_privacy_policy_SUCEEDED',
  CREATE_NEW_PRIVACY_POLICY_FAILURE: 'create_new_privacy_policy_FAILURE',
  DELETE_PRIVACY_POLICY_REQUEST: 'delete_privacy_policy_REQUEST',
  DELETE_PRIVACY_POLICY_SUCCEEDED: 'delete_privacy_policy_SUCEEDED',
  DELETE_PRIVACY_POLICY_FAILURE: 'delete_privacy_policy_FAILURE',
  PRIVACY_POLICY_EDIT_MODE: 'privacy_policy_edit_mode',

  FETCH_THREATS_BY_DEVICE_ID_AND_ZAPP_ID_REQUEST:
    'FETCH_THREATS_BY_DEVICE_ID_AND_ZAPP_ID_REQUEST',
  // FETCH_THREATS_BY_DEVICE_ID_AND_ZAPP_ID_SUCCEEDED: 'FETCH_THREATS_BY_DEVICE_ID_AND_ZAPP_ID_SUCCEEDED',
  FETCH_THREATS_BY_DEVICE_ID_AND_ZAPP_ID_FAILURE:
    'FETCH_THREATS_BY_DEVICE_ID_AND_ZAPP_ID_FAILURE',
  SET_SELECTED_DEVICE: 'SET_SELECTED_DEVICE',
  // DEVICES_TABLE_COLUMN_CHANGE: 'DEVICES_TABLE_COLUMN_CHANGE',
  FETCH_DEVICES_COUNT_REQUEST: 'FETCH_DEVICES_COUNT_REQUEST',
  FETCH_DEVICES_COUNT_SUCCEEDED: 'FETCH_DEVICES_COUNT_SUCCEEDED',
  CHANGE_DEVICE_SHOW_DETAILS: 'CHANGE_DEVICE_SHOW_DETAILS',
  SET_UI_DEVICES_SELECTED_APP: 'Set_Ui_Devices_Selected_app',
  CLEAR_DEVICES: 'clear_devices',

  // Teams Table
  SET_SELECTED_TEAM: 'SET_SELECTED_TEAM',
  CLEAR_TEAM_SELECTION: 'CLEAR_TEAM_SELECTION',

  // Admin Account
  ADMIN_ACCOUNTS_TABLE_COLUMN_CHANGE: 'ADMIN_ACCOUNTS_TABLE_COLUMN_CHANGE',

  SUPERUSERS_TABLE_COLUMN_CHANGE: 'SUPERUSERS_TABLE_COLUMN_CHANGE',
};

export default ActionTypes;
