import React, { useMemo } from 'react';
import { default as MuiAccordion } from '@material-ui/core/Accordion';
import useStyles from './useStyles';
import { default as MuiAccordionDetails } from '@material-ui/core/AccordionDetails';
import { default as MuiAccordionSummary } from '@material-ui/core/AccordionSummary';
import { default as MUIArrowForwardIosIcon } from '@material-ui/icons/ArrowForwardIos';
import AndroidIcon from 'components/UI/icons/AndroidIcon';
import AppleIcon from 'components/UI/icons/AppleIcon';
import Checkbox from 'components/UI/input/Checkbox';
import { ICategory, ITrigger } from '../models';

interface IFilteredAccordianProps {
  filteredAPCList: ICategory[];
  categoriesCheckboxes: ITrigger[];
  handleChangeCategoryCheckbox: (trigger: ITrigger) => void;
  disabledCheckboxes: string[];
  isViewOnly: boolean;
}
const FilteredAccordion: React.FC<IFilteredAccordianProps> = ({
  filteredAPCList,
  categoriesCheckboxes,
  handleChangeCategoryCheckbox,
  disabledCheckboxes,
  isViewOnly,
}) => {
  const classes = useStyles();

  const memoizedFilteredAPCList = useMemo(
    () => filteredAPCList,
    [filteredAPCList]
  );
  return (
    <>
      {memoizedFilteredAPCList?.map((e) => (
        <MuiAccordion key={e.name} className={classes.accordion}>
          <MuiAccordionSummary
            style={{ height: '30px' }}
            className={classes.accordionHeader}
            expandIcon={<MUIArrowForwardIosIcon />}
          >
            {e.name}
          </MuiAccordionSummary>
          {e.triggers.map((trigger) => (
            <MuiAccordionDetails
              key={trigger.name}
              classes={{
                root: classes.accordionDetails,
              }}
            >
              <div className={classes.subCheckBoxStyling}>
                {!trigger.deleted && (
                  <>
                    <Checkbox
                      color="primary"
                      label={trigger.description}
                      name={trigger.name}
                      id={trigger.name}
                      checked={categoriesCheckboxes?.some(
                        (checkbox: ITrigger) => checkbox?.name === trigger?.name
                      )}
                      disabled={
                        disabledCheckboxes?.includes(trigger.name) || isViewOnly
                      }
                      onChange={() =>
                        handleChangeCategoryCheckbox(
                          trigger as unknown as ITrigger
                        )
                      }
                    />
                    <div>
                      {trigger?.platform?.toLowerCase() === 'ios' ? (
                        <span
                          className={
                            disabledCheckboxes?.includes(trigger?.name)
                              ? classes.disableIconStyle
                              : classes.appleIcon
                          }
                        >
                          <AppleIcon />
                        </span>
                      ) : (
                        <span
                          className={
                            disabledCheckboxes?.includes(trigger?.name)
                              ? classes.disableIconStyle
                              : classes.androidIcon
                          }
                        >
                          <AndroidIcon />
                        </span>
                      )}
                    </div>
                  </>
                )}
              </div>
            </MuiAccordionDetails>
          ))}
        </MuiAccordion>
      ))}
    </>
  );
};

export default FilteredAccordion;
