import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './useStyles';
import AlertIcon from 'components/UI/icons/AlertIcon';
import { TPolicy, TpolicyMap } from '../appPolicy/models';

export interface IGroupBase {
  id: string;
  name: string;
}

interface IConfirmDeleteMessage {
  groups: IGroupBase[] | null | undefined;
  policyType: TPolicy;
}

const ConfirmDeleteMessage: React.FC<IConfirmDeleteMessage> = (props) => {
  const { groups, policyType } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const transformGroupName = (name: string) => {
    if (name == 'Default Group') {
      return t('GLOBAL.DEFAULT_GROUP');
    } else {
      return name;
    }
  };

  return groups && groups.length > 0 ? (
    <div className={classes.navigationWarning}>
      <div>
        <p>
          {t('MTD.POLICIES.CAN_NOT_DELETE_POLICY_FOLLOWING_GROUPS', {
            policyName: TpolicyMap[policyType],
          })}
        </p>
        {groups.map((group) => (
          <li key={group.id}>{transformGroupName(group.name)}</li>
        ))}
      </div>
      <AlertIcon />
    </div>
  ) : (
    <>{t('MTD.POLICIES.POLICY_CONFIRM_DELETE')}</>
  );
};

export default ConfirmDeleteMessage;
