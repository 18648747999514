import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    platform: {
      '& svg': {
        fontSize: 20,
        marginRight: 10,
        minHeight: 20,
        minWidth: 20,
      },
      alignItems: 'center',
      display: 'flex',
    },
    packageIdStyle: { fontSize: 16, color: palette.text.secondary },
    searchBar: {
      marginBottom: 16,
    },
    mainContainer: {
      padding: '15px',
    },
    created: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    risk: {
      '& span': {
        marginRight: 10,
      },
      alignItems: 'center',
      display: 'flex',
      textTransform: 'capitalize',
    },

    appNameHeader: {
      paddingLeft: '6px',
    },
    titleHeader: {
      display: 'flex',
      gap: '10px',
      maxWidth: '40%',
    },
    facetedSearchHeader: {
      color: palette.text.secondary,
    },
    enabledSwitch: {
      dispay: 'flex',
      alignItems: 'center',
    },
    pickerContainer: {
      display: 'flex',
      flex: '1 0 auto',
      gap: '10px',
      '& > div:first-of-type': {
        flexGrow: 1,
      },

      '& > *': {
        marginRight: 'auto',
        marginTop: 'auto',
        marginBottom: 'auto',
      },
    },
    globalIndWrapper: {
      paddingBottom: 10,
    },
    selectStyle: {
      '& ::placeholder': {
        color: palette.text.secondary,
        opacity: 1,
      },
    },
  })
);

export default useStyles;
