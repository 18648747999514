import { uiSettingsDefaults } from '../reducers/UiSettingsReducers';

export default state => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      mtdGroups: {
        ...uiSettingsDefaults.mtdGroups,
        tableHeaders: uiSettingsDefaults.mtdGroups.tableHeaders,
      },
      groups: {
        ...uiSettingsDefaults.groups,
        tableHeaders: uiSettingsDefaults.groups.tableHeaders,
      },
      trmPolicy: {
        ...uiSettingsDefaults.trmPolicy,
        tableHeaders: uiSettingsDefaults.trmPolicy.tableHeaders,
      },
      trmPolicyMtd: {
        ...uiSettingsDefaults.trmPolicyMtd,
        tableHeaders: uiSettingsDefaults.trmPolicyMtd.tableHeaders,
      },
    },
  };
};
