import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from 'components/UI/input/Select';
import ZButton from 'UI/Buttons/ZButton';

const useStyles = makeStyles(({ palette }) => ({
  wrapper: { display: 'flex', gap: 10 },
  select: {
    '& ::placeholder': {
      color: palette.text.secondary,
      opacity: 1,
    },
  },
}));

const ActionReport = ({ handleActionReport, reportPending }) => {
  const classes = useStyles();
  const [selectionValue, setSelectionValue] = useState({});

  const setFieldValue = (field, value) => {
    setSelectionValue(value);
  };

  return (
    <div className={classes.wrapper}>
      <div style={{ width: 245 }}>
        <Select
          interactable
          multiple={false}
          field={{ name: 'sbomAction' }}
          options={[
            { label: 'Download PDF Report', value: 'download-pdf' },
            { label: 'Download JSON Report', value: 'download-json' },
            { label: 'Download SBOM', value: 'download-sbom' },
            { label: 'Download SBOM PDF', value: 'download-sbom-pdf' },
            { label: 'Download Enriched SBOM', value: 'download-e-sbom' },
            {
              label: 'Download Enriched SBOM PDF',
              value: 'download-e-sbom-pdf',
            },
          ]}
          setFieldValue={setFieldValue}
          type="select"
          placeholder="Select assessment report"
          className={classes.select}
        />
      </div>
      <ZButton
        action={() => handleActionReport(selectionValue?.value)}
        isDisabled={!selectionValue?.value || reportPending}
        color="secondary"
        icon={{
          elem: () => {
            if (reportPending) {
              return (
                <CircularProgress size={15} styles={{ paddingRight: 15 }} />
              );
            }
            return ' DOWNLOAD ';
          },
        }}
      />
    </div>
  );
};

export default ActionReport;
