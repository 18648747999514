import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { logout } from 'actions/UserActions';
import useIdle from 'workers/useIdle';

const AdminDashboardRoute = ({
  component: Component,
  isAuthenticated,
  isPartner,
  ...rest
}) => {
  const isIdle = useIdle({
    timeToIdle: rest.customIdleTime,
    inactivityEvents: [],
  });
  if (isPartner) {
    return <Redirect to={{ pathname: '/partner/accounts' }} />;
  }
  if (isIdle) {
    rest.logout();
  }
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/console',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = ({ auth, uiSettings }) => {
  return {
    isAuthenticated: auth.superUser.isAuthenticated,
    isPartner: auth.partnerUser.isAuthenticated,
    customIdleTime: _.get(uiSettings, 'customIdleTime', 900000),
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AdminDashboardRoute);
