import useStyles from './useStyles';
import { IBasicData, IDetailData, IDetailTableConfig } from '../models';

interface IDetailTableProps {
  data: IDetailData | IBasicData;
  tableConfig: IDetailTableConfig[];
  hiddenFields?: string[];
}

const DetailTable: React.FC<IDetailTableProps> = ({
  data,
  tableConfig,
  hiddenFields = [],
}) => {
  const classes = useStyles();

  return (
    <table>
      <tbody>
        {tableConfig
          .filter(({ key }) => !hiddenFields.includes(key))
          .map(({ key, label, formatFn }) => {
            if (!data[key]) {
              return <></>;
            }

            const detailValue = data[key];
            const formatedValue =
              !!formatFn &&
              (typeof detailValue === 'string' ||
                typeof detailValue === 'number')
                ? formatFn(detailValue)
                : detailValue;
            return (
              <tr key={key}>
                <td className={classes.label}>{label}:</td>
                <td className={classes.detail}>{formatedValue}</td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default DetailTable;
