import { IconButton, Tooltip } from '@material-ui/core';
import HelpCenter from '@material-ui/icons/Help';

const EmailSupportButton = () => {
  return (
    <Tooltip title="Email support for help with your protection">
      <a href="mailto:devsupport@whitecryption.com">
        <IconButton
          color="primary"
        >
          <HelpCenter />
        </IconButton>
      </a>
    </Tooltip>
  );
};

export default EmailSupportButton;