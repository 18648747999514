import React from 'react';
import _ from 'lodash';
import { MultiSelect } from 'components/Selects/index';
import { TextField } from '../../inputs';
import {
  azureCloudType,
  configureAzureCloudValue,
} from '../enums/DataExportEnums';

const key = 'MICROSOFT_SENTINEL';

export const MicrosoftSentinel = props => {
  const [selectedAzureCloudType, setAzureCloudType] = React.useState({});
  React.useEffect(() => {
    let acValue = _.get(props, 'config.azureCloudType');
    if (typeof acValue === 'string') {
      acValue = configureAzureCloudValue(acValue);
    }
    setAzureCloudType(acValue);
  }, [props]);
  const handleChange = field => event =>
    props.onChange(
      key,
      _.update(_.cloneDeep(props.config), field, () => event.target.value)
    );

  const handleSelect = (name, selectedOption) => {
    // this is needed for populating multiSelect

    props.onChange(key, { ...config, azureCloudType: selectedOption });
  };

  const { config } = props;
  return (
    <div>
      <TextField
        required
        label="Workspace Id"
        value={config.workspaceId}
        onChange={handleChange('workspaceId')}
      />
      <TextField
        required
        label="Workspace Primary Key"
        value={config.primaryKey}
        onChange={handleChange('primaryKey')}
      />
      <MultiSelect
        name="azureCloudType"
        label="Cloud Type"
        isMulti={false}
        buttonPlaceholder="Select Azure Cloud Type"
        options={azureCloudType}
        onChange={handleSelect}
        values={selectedAzureCloudType}
      />
    </div>
  );
};
