import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    children: {
      order: 1,
      width: '100%',
    },
    label: {
      color: palette.text.secondary,
      fontSize: 15,
      marginTop: 15,
      order: 0,
      paddingBottom: 5,
    },
    root: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
    },
  })
);

export default useStyles;
