/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { compareBuildsColumnMapping } from './ZScanCompareAssessments.mappings';
import { withBackfill_DEPRECATED } from '../../../../components/hocs/withBackfill';
import {
  getUISettingsTableHeaders,
  getChangedTableHeadersHash,
  getUISettingsWithout,
} from 'reducers/UiSettingsSelectors';
import { compareBuildsTableColumnChange } from 'reducers/UiSettingsReducers';
import _ from 'lodash';
import useBackfill from 'utils/useBackfill';
import { withDirtyState } from 'utils/withDirtyState';
import withRouter from 'components/hocs/withRouter';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { IColumnHeader, ILocationQuery } from 'components/UI/Table/models';
import { ILocation, IQueryParams, ISelectPolicy } from './models';
import Table from 'components/UI/Table';
import { Card } from '@material-ui/core';
import { default as MUICard } from '@material-ui/core/Card';
import { generateAssessmentPDFReport } from 'api/zDevService';
import Button from 'components/UI/Button';
import Modal from 'components/UI/Modal';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { getActiveModalAtom } from 'atoms/modals';
import useStyles from './useStyles';
import CompareBuildSidebar from './CompareBuildSidebar';
import { Radio } from 'components/UI/input/RadioGroup';
import Select, { ISelectItem } from 'components/UI/input/Select';
//import Checkbox from 'components/UI/input/Checkbox';
import cc from 'classcat';
import Moment from 'moment';
import tableQuery from './tableQuery';
import SearchBox from 'components/UI/input/SearchBox';
import GlobalIndicatorWrapper from 'components/main/common/GlobalIndicatorWrapper';
import { SelectedRowProps, ISidebarProp } from './models';
import { getPolicies } from 'api/zDevService';
import useAsyncResult from 'utils/useAsyncResult';
import { IZScanApp } from '../models';
import PlatformIcon from '../PlatformIcon';
import CustomPolicyOption from 'components/main/policies/common/CustomPolicyOption';

interface IzScanCompareProps {
  selectedRow: SelectedRowProps[];
  updateUrl: (params: IQueryParams) => void;
  currentTableHeaders: IColumnHeader[];
  compareBuildsTableColumnChange: (...args: unknown[]) => void;
  appsUiSettings: { [key: string]: unknown };
  jiggleDirtyState: (...args: any) => void;
  q: { [key: string]: unknown };
  location: ILocation;
  rqps: string[];
  appDetails: IZScanApp;
}

export const ZScanCompareAssessments_TITLE = 'ZScanCompareAssessments';
const activeModalAtom = getActiveModalAtom<any>();

const generateLabelName = (buildData: any) => {
  const appVersion = _.get(buildData, 'zdevMetadata.appVersion', 'N/A');
  const versionBuild = _.get(
    buildData,
    'zdevMetadata.appBuildVersion',
    'unknown build'
  );
  return `Risks Unique to Version ${appVersion} (${versionBuild})`;
};

const initialOptions = [
  { label: 'Shared Risks', value: 'shared' },
  { label: 'Combined Risks', value: 'combined' },
  // {
  //   label: 'Resolved Findings from earlier assessments',
  //   value: 'resolveFindings',
  // },
  // { label: 'New Findings in more recent assessments ', value: 'newFindingss' },
];

const ZScanCompareAssessments: React.FC<IzScanCompareProps> = ({
  selectedRow,
  updateUrl,
  currentTableHeaders,
  compareBuildsTableColumnChange,
  appsUiSettings,
  jiggleDirtyState,
  q: query,
  // location,
  rqps,

  appDetails,
}) => {
  const setActiveModal = useSetRecoilState(activeModalAtom);
  const classes = useStyles();

  useBackfill({
    query,
    requiredQueryParams: rqps,
    storedQueryParams: appsUiSettings,
    updateUrl,
  });
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const [preSelectFirstRowData, setPreSelectFirstRowData] =
    useState<boolean>(true);
  const [totalDataCount, setTotalDataCount] = useState<number>(0);
  const [compareOptions, setCompareOptions] = useState(initialOptions);
  const [selectFinding, setSelectFinding] = useState<
    ISidebarProp | undefined
  >();
  const [fullTableData, setFullTableData] = useState<any>();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [reportPending, setReportPending] = useState({
    reportPending1: false,
    reportPending2: false,
  });
  const [policyId, setPolicyId] = useState<any>(null);
  const getPolicyLens: any = useAsyncResult(getPolicies);
  const selectPolicyLens: ISelectItem[] | undefined = getPolicyLens?.data?.map(
    ({ id, name, teamId }: ISelectPolicy) => {
      return { label: name, value: id, accountBounded: !teamId };
    }
  );

  const setCompareBuildOptions = (build1Data: any, build2Data: any) => {
    const build1DataName = generateLabelName(build1Data);
    const build2DataName = generateLabelName(build2Data);

    const buildDataOptions = [
      { label: build1DataName, value: 'unq1' },
      { label: build2DataName, value: 'unq2' },
    ];

    const updatedCompareOptions: any = [...initialOptions, ...buildDataOptions];

    setCompareOptions(updatedCompareOptions);
  };

  const handleClose = useCallback(() => {
    updateUrl({ orderBy: 'scanDate', order: 'desc', cbo: '' });
    setActiveIndex(0);
    setSearchQuery('');
    setPolicyId('');
    setFullTableData([]);
    setPreSelectFirstRowData(true);
    setActiveModal(undefined);
  }, [setActiveModal, updateUrl]);
  const handleDownloadReport = (id: string, reportPendings: any) => {
    return () => {
      setReportPending((prev) => ({
        ...prev,
        [reportPendings]: true,
      }));
      generateAssessmentPDFReport({ id })
        .then(({ data }: any) => {
          setReportPending((prev) => ({
            ...prev,
            [reportPendings]: false,
          }));
          window.open(data?.cdn_link, '_blank');
        })
        .catch((err: any) => console.log('err', err));
    };
  };

  const handleColumnChange = (...args: unknown[]) => {
    compareBuildsTableColumnChange(...args);
    jiggleDirtyState();
  };
  const handleSearch = useCallback(
    (value: string) => {
      setSearchQuery(value);
      setActiveIndex(0);
      setFullTableData([]);
      setPreSelectFirstRowData(true);
    },

    [setSearchQuery]
  );
  const handleSelectionChange = useCallback((selectedRows: any) => {
    console.log(Object.values(selectedRows), 'selectOptions');
  }, []);

  const handleSelect = useCallback(
    (selected: any) => {
      updateUrl({ cbo: selected.target.value });
      setActiveIndex(0);
      setPreSelectFirstRowData(true);
    },
    [updateUrl]
  );
  const handleSelectRow = useCallback((rowData: any) => {
    setSelectFinding(rowData?.data);
  }, []);

  const handleChange = useCallback((_, value: ISelectItem) => {
    if (value?.value) {
      setPolicyId(String(value?.value) ?? '');
      setActiveIndex(0);
      setPreSelectFirstRowData(true);
    } else {
      setPolicyId('');
      setActiveIndex(0);
      setPreSelectFirstRowData(true);
    }
    setFullTableData([]);
  }, []);
  return (
    <Modal
      caption={`${appDetails?.name} - ${appDetails?.bundleId} - BUILD COMPARE`}
      className={classes.modal}
      onClose={handleClose}
      title={ZScanCompareAssessments_TITLE}
      scrollable={true}
      isCloseIcon={true}
      platformIconType={
        <PlatformIcon iconType={appDetails?.platform?.toLowerCase()} />
      }
    >
      <>
        <div style={{ display: 'flex', gap: '26px', marginTop: '30px' }}>
          <div style={{ width: '65%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '20px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: '10px',
                }}
              >
                <span className={classes.showFindingsStyle}>Show Findings</span>

                <div className={classes.radioGroup}>
                  {compareOptions.map((option) => (
                    <Radio
                      key={option.value}
                      checked={query?.cbo === option.value}
                      color="primary"
                      label={option.label}
                      name="showFindings"
                      size="small"
                      value={option.value}
                      onChange={handleSelect}
                    />
                  ))}
                </div>
                <div style={{ width: '400px' }}>
                  <div className={classes.globalIndWrapper}>
                    <GlobalIndicatorWrapper isGlobal inline />
                  </div>
                  <Select
                    interactable
                    placeholder="Select Policy Lens"
                    options={selectPolicyLens}
                    setFieldValue={handleChange}
                    className={classes.selectStyle}
                    customOption={CustomPolicyOption as React.FC}
                  />
                </div>
                {/* <Checkbox
                  label="Also show  accepted findings"
                  color="primary"
                /> */}
              </div>
              <Card
                style={{
                  width: '55%',
                }}
              >
                <MUICard className={classes.header}>
                  <h3 className={classes.appNameHeader}>
                    VERSION {selectedRow[0]?.zdevMetadata?.appVersion ?? ''} -
                    BUILD {selectedRow[0]?.zdevMetadata?.appBuildVersion ?? ''}
                  </h3>
                </MUICard>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    overflow: 'hidden',
                  }}
                >
                  <table
                    className={cc([
                      classes.detailsTable,
                      classes.detailsTableWithPadding,
                    ])}
                  >
                    <tr>
                      <td>Assessment Date:</td>
                      <td>
                        {Moment(selectedRow[0]?.scanDate)
                          .format(' MMM DD, YYYY h:mm A')
                          .toString() ?? ''}
                      </td>
                    </tr>
                    <tr>
                      <td>Version:</td>
                      <td>{selectedRow[0]?.zdevMetadata?.appVersion ?? ''}</td>
                    </tr>
                    <tr>
                      <td>Build:</td>
                      <td>
                        {selectedRow[0]?.zdevMetadata?.appBuildVersion ?? ''}
                      </td>
                    </tr>
                    <tr>
                      <td>File hash:</td>
                      <td>{selectedRow[0]?.zdevMetadata?.md5 ?? ''}</td>
                    </tr>
                  </table>
                  <div style={{ padding: '10px' }}>
                    <PlatformIcon
                      iconType={selectedRow[0]?.zdevMetadata?.osName?.toLowerCase()}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '20px',
                  }}
                >
                  <Button
                    onClick={handleDownloadReport(
                      selectedRow[0]?.id,
                      'reportPending1'
                    )}
                    //isLoading={reportPending.reportPending1}
                    disabled={reportPending.reportPending1}
                    text={
                      !reportPending.reportPending1
                        ? 'DOWNLOAD REPORT'
                        : 'GENERATING REPORT'
                    }
                    color="secondary"
                  />
                </div>
              </Card>
            </div>
          </div>

          <Card style={{ width: '33%' }}>
            {' '}
            <MUICard className={classes.header}>
              <h3 className={classes.appNameHeader}>
                VERSION {selectedRow[1]?.zdevMetadata?.appVersion ?? ''} - BUILD{' '}
                {selectedRow[1]?.zdevMetadata?.appBuildVersion ?? ''}
              </h3>
            </MUICard>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                overflow: 'hidden',
                marginLeft: '10px',
              }}
            >
              <table
                className={cc([
                  classes.detailsTable,
                  classes.detailsTableWithPadding,
                ])}
              >
                <tr>
                  <td>Assessment Date:</td>
                  <td>
                    {Moment(selectedRow[1]?.scanDate ?? '')
                      .format(' MMM DD, YYYY h:mm A')
                      .toString() ?? ''}
                  </td>
                </tr>
                <tr>
                  <td>Version:</td>
                  <td>{selectedRow[1]?.zdevMetadata?.appVersion ?? ''}</td>
                </tr>
                <tr>
                  <td>Build:</td>
                  <td>{selectedRow[1]?.zdevMetadata?.appBuildVersion ?? ''}</td>
                </tr>
                <tr>
                  <td>File hash:</td>
                  <td>{selectedRow[1]?.zdevMetadata?.md5 ?? ''}</td>
                </tr>
              </table>
              <div style={{ padding: '10px' }}>
                <PlatformIcon
                  iconType={selectedRow[1]?.zdevMetadata?.osName?.toLowerCase()}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '20px',
              }}
            >
              <Button
                onClick={handleDownloadReport(
                  selectedRow[1]?.id,
                  'reportPending2'
                )}
                disabled={reportPending.reportPending2}
                //isLoading={reportPending.reportPending2}
                text={
                  !reportPending.reportPending2
                    ? 'DOWNLOAD REPORT'
                    : 'GENERATING REPORT'
                }
                color="secondary"
              />
            </div>
          </Card>
        </div>
        <div
          className="table and panel wrapper"
          style={{ display: 'flex', gap: '26px' }}
        >
          <div style={{ width: '100%' }}>
            <MUICard>
              <SearchBox onInputChange={handleSearch} />
            </MUICard>

            <Table
              serverSort={false}
              classList={classes}
              columnHeaders={currentTableHeaders}
              footerComponents={() => (
                <div>{totalDataCount + ' rows total'}</div>
              )}
              fetchTableData={() =>
                tableQuery(
                  activeIndex,
                  preSelectFirstRowData,
                  setPreSelectFirstRowData,
                  setSelectFinding,
                  setTotalDataCount,
                  searchQuery,
                  policyId,
                  selectedRow,
                  fullTableData,
                  setFullTableData,
                  setCompareBuildOptions,
                  query
                )()
              }
              pagination={false}
              onColumnChange={handleColumnChange}
              onRowClick={handleSelectRow}
              query={query as unknown as ILocationQuery}
              rowMapping={compareBuildsColumnMapping}
              tableId="zScanCompareBuilds"
              heightBuffer={290}
              onSelectionChange={handleSelectionChange}
              activeIndex={activeIndex}
              onActiveIndexChange={setActiveIndex}
              activeRowStyle={classes.activeRowIndicator}
            />
          </div>
          {!_.isEmpty(selectFinding) && Number(totalDataCount) ? (
            <div
              className="card wrapper"
              style={{
                width: '50%',
                boxShadow: '10px 10px 10px 10px rgb(0,0,0,0.258)',
                border: ' 1px solid black',
                borderRadius: '4px',
              }}
            >
              <CompareBuildSidebar selectedRowData={selectFinding} />
            </div>
          ) : null}
        </div>
      </>
    </Modal>
  );
};
const mapStateToProps = (state: any) => {
  return {
    compareBuildsColumnHeadersHash: getChangedTableHeadersHash(
      state,
      'zScanCompareBuilds'
    ),
    currentTableHeaders: getUISettingsTableHeaders(state, 'zScanCompareBuilds'),
    compareBuildsUiSettings: getUISettingsWithout(state, 'zScanCompareBuilds', [
      'tableHeaders',
    ]),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      compareBuildsTableColumnChange,
    },
    dispatch
  );
};

export default compose(
  withRouter,
  withDirtyState(),
  withBackfill_DEPRECATED('uiSettings.zScanCompareBuilds'),
  connect(mapStateToProps, mapDispatchToProps)
)(ZScanCompareAssessments);
