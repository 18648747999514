import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    cell: {
      borderBottom: `1px solid ${palette.common.almostWhite}`,
      paddingBottom: '3px',
      paddingTop: '3px',
      whiteSpace: 'nowrap',
      maxWidth: 'none',
    },
    checkboxCell: {
      maxWidth: 38,
      minWidth: 38,
      paddingRight: 0,
      width: 38,
    },
    footer: {
      backgroundColor: palette.background.default,
      padding: '12px 16px 12px 16px',
    },
    headerCell: {
      color: palette.text.primary,
      maxWidth: 'none',
      whiteSpace: 'nowrap',
      '&:last-child': {
        width: 0,
        paddingRight: 36,
      },
    },
    headerCheckBox: {
      '& span': {
        marginBottom: '-10px',
        marginTop: '-10px',
      },
    },
    headerRow: {
      backgroundColor: palette.background.default,
    },
    tableContainer: {
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        height: '8px',
        opacity: '30%',
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        background: palette.tables.scrollbar,
        borderRadius: '10px',
      },
    },
  })
);

export default useStyles;
