import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import withRouter from 'components/hocs/withRouter';

const ProtectedRoute = ({
  component: Component,
  scopes,
  requiredScope,
  requiredQueryParams,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      let hasRequiredScope;
      const userHasScope = !_.isEmpty(scopes);

      if (requiredScope) {
        hasRequiredScope = _.find(requiredScope.split(','), scope =>
          _.has(scopes, scope)
        );
      }

      if (
        // route requires a certain scope
        hasRequiredScope ||
        // allows scoped users to access non-scopebound routes
        (!requiredScope && userHasScope)
      ) {
        return (
          <Component
            {...props}
            requiredQueryParams={requiredQueryParams}
            rqps={requiredQueryParams}
          />
        );
      }

      return <Redirect to="/admin" />;
    }}
  />
);

const mapStateToProps = ({ auth }) => {
  return {
    scopes: auth.superUser.scopes,
  };
};

export default withRouter(connect(mapStateToProps)(ProtectedRoute));
