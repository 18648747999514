import cc from 'classcat';
import { useStyles } from './useStyles';
import { ESeverity } from './models';
import { useTranslation } from 'react-i18next';

export interface ISeverityCircle {
  severity: ESeverity | string;
  showLabel?: boolean;
}

const SeverityCircle: React.FC<ISeverityCircle> = ({
  severity,
  showLabel = true,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.severityCircleWrapper}>
      <span
        className={cc([
          classes.icon,
          {
            [classes.unknown]: severity === ESeverity.UNKNOWN,
            [classes.bestPractices]: severity === ESeverity.BEST_PRACTICES,
            [classes.informational]: severity === ESeverity.INFORMATIONAL,
            [classes.low]: severity === ESeverity.LOW,
            [classes.medium]: severity === ESeverity.MEDIUM,
            [classes.high]:
              severity === ESeverity.HIGH || severity === ESeverity.CRITICAL,
          },
        ])}
        style={{
          width: 16,
          height: 16,
          marginRight: 4,
        }}
      >
        {severity === ESeverity.CRITICAL && '!'}
      </span>
      <span>{showLabel && t(`ZSCAN.SEVERITY.${severity}`)}</span>
    </div>
  );
};

export default SeverityCircle;
