import React from 'react';
import { ISelectItem } from '../../../../components/UI/input/Select';
import SeverityCircle from 'components/main/ZScanApps/SeverityCircle';
import { ESeverity } from 'components/main/ZScanApps/SeverityCircle/models';

export const SeverityOptions: Record<string, string> = {
  Critical: 'CRITICAL',
  High: 'HIGH',
  Medium: 'MEDIUM',
  Low: 'LOW',
  Informational: 'INFORMATIONAL',
  BestPractices: 'BEST_PRACTICES',
};

const CustomOption: React.FC<ISelectItem & { showLabel?: boolean }> = (
  option
) => {
  return (
    <SeverityCircle
      severity={option.value as ESeverity}
      showLabel={option.showLabel}
    />
  );
};

export default CustomOption;
