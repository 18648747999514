import { IPolicyOption } from "components/modals/GroupsCreateEdit/models";
import _ from "lodash";
import { IGroupsTableData } from "./models";


interface ICleanGroupsTableDataArguments {
  groupsTableData: IGroupsTableData[],
  trmPolicyList: IPolicyOption[],
  privacyPolicyList: IPolicyOption[],
  appSettingsList: IPolicyOption[],
  phishingPolicyList: IPolicyOption[],
  appPolicyList: IPolicyOption[],
  osRiskPolicyList: IPolicyOption[],
  emmConnectionList?: IPolicyOption[],
  brandingPolicyList?: IPolicyOption[],
  networkPolicyList?: IPolicyOption[],
  dormancyPolicyList?: IPolicyOption[];
}


export const cleanGroupsTableData = (
  { groupsTableData,
    trmPolicyList,
    privacyPolicyList,
    appSettingsList,
    phishingPolicyList,
    appPolicyList,
    emmConnectionList,
    dormancyPolicyList,
    networkPolicyList,
    brandingPolicyList,
    osRiskPolicyList }: ICleanGroupsTableDataArguments
) => {

  return groupsTableData.map((group) => {
    const _group = group;
    const emmConnectionName = _.find(emmConnectionList, {
      value: group.emmConnectionId,
    });

    _group.emmConnectionName = _.get(emmConnectionName, 'label');

    const trmName = _.find(trmPolicyList, { value: group.trmId });
    if (trmName) {
      _group.trmName = trmName.label;
      _group.trmGlobal = _.get(trmName, 'accountBounded', false);
    } else {
      _group.trmName = 'N/A';
    }

    const privacyName = _.find(privacyPolicyList, { value: group.privacyId });
    if (privacyName) {
      _group.privacyName = privacyName.label;
      _group.privacyGlobal = _.get(privacyName, 'accountBounded', false);
    } else {
      _group.privacyName = 'N/A';
    }

    const phishingName = _.find(phishingPolicyList, {
      value: group.phishingPolicyId,
    });

    if (phishingName) {
      _group.phishingName = phishingName.label;
      _group.phishingGlobal = _.get(phishingName, 'accountBounded', false);
    } else {
      _group.phishingName = 'N/A';
    }

    const appSettingsName = _.find(appSettingsList, {
      value: group.appSettingsId,
    });
    if (appSettingsName) {
      _group.appSettingsName = appSettingsName.label;
      _group.appSettingsGlobal = _.get(
        appSettingsName,
        'accountBounded',
        false
      );
    } else {
      _group.appSettingsName = 'N/A';
    }

    const appPolicyName = _.find(appPolicyList, {
      value: group.appPolicyId,
    });
    if (appPolicyName) {
      _group.appPolicyName = appPolicyName.label;
      _group.appPolicyGlobal = _.get(appPolicyName, 'accountBounded', false);
    } else {
      _group.appPolicyName = 'N/A';
    }

    const dormancyPolicyName = _.find(dormancyPolicyList, {
      value: group.dormancyPolicyId,
    });

    if (dormancyPolicyName) {
      _group.dormancyPolicyName = dormancyPolicyName.label;
      _group.dormancyPolicyGlobal =
        dormancyPolicyName?.accountBounded ?? false;
    } else {
      _group.dormancyPolicyName = 'N/A';
    }

    const networkPolicyName = _.find(networkPolicyList, {
      value: group.networkPolicyId,
    });
    if (networkPolicyName) {
      _group.networkPolicyName = networkPolicyName.label;
      _group.networkPolicyGlobal = _.get(networkPolicyName, 'accountBounded', false);
    } else {
      _group.networkPolicyName = 'N/A';
    }

    if (brandingPolicyList !== undefined) {
      const brandingPolicyName = _.find(brandingPolicyList, {
        value: group.brandingPolicyId,
      });
      if (brandingPolicyName) {
        _group.brandingPolicyName = brandingPolicyName.label;
        _group.brandingPolicyGlobal = _.get(brandingPolicyName, 'accountBounded', false);
      } else {
        _group.brandingPolicyName = 'N/A';
      }
    }

    const osRiskPolicyName = osRiskPolicyList.find(item => item.value === group.osRiskPolicyId);
    if (osRiskPolicyName) {
      _group.osRiskPolicyName = osRiskPolicyName.label;
      _group.osRiskPolicyGlobal = osRiskPolicyName?.accountBounded ?? false;
    } else {
      _group.osRiskPolicyName = 'N/A';
    }

    return _group;
  });
};
