import AddIcon from '@material-ui/icons/Add';
import withRouter from 'components/hocs/withRouter';
import Table from 'components/UI/Table';
import { SCOPE_BOUNDS } from 'mappings/scopeMapping';
import { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
  adminAccountsTableColumnChange,
  updateUISettings,
} from 'reducers/UiSettingsReducers';
import {
  getUISettingsTableHeaders,
  getUISettingsWithout,
} from 'reducers/UiSettingsSelectors';
import { bindActionCreators, compose } from 'redux';
import ReactToCSV from 'utility/ReactCSVDownloader';
import { toggleModalDirect } from 'utils/storeUtils';
import useBackfill from 'utils/useBackfill';
import { withDirtyState } from 'utils/withDirtyState';
import withMemoizedProps from 'utils/withMemoizedProps';
import AccountDetails from './AccountDetails';
import { accountsRowMapping } from './accounts.mappings';

import Button from 'components/UI/Button';
import {
  IColumnHeader,
  IFilterEditor,
  ILocationQuery,
} from 'components/UI/Table/models';
import useStyles from './useStyles';
import { resolveFilters } from './accounts.mappings';
import { IAccountPersona, IFilterValues } from './models';
import tableQuery from './tableQuery';
import { updateFavoriteStatus } from 'api/AdminAccountService';

const INITIAL_FILTER_VALUES: IFilterValues = {
  name: '',
  id: '',
  description: '',
};
interface IAdminAccountsProps {
  adminAccountsTableColumnChange: (...args: unknown[]) => void;
  adminAccountsUiSettings: { [key: string]: unknown; };
  currentTableHeaders: IColumnHeader[];
  classes: any;
  definedUser?: string;
  jiggleDirtyState: (...args: any) => void;
  q: { [key: string]: unknown; };
  rqps: string[];
  scopes: { [key: string]: string[]; };
  updateUISettings: (...args: any) => void;
  updateUrl: (...args: any) => void;
  scopeBounds: string;
}
const Accounts: React.FC<IAdminAccountsProps> = ({
  adminAccountsTableColumnChange,
  adminAccountsUiSettings,
  currentTableHeaders,
  definedUser,
  jiggleDirtyState,
  q: query,
  rqps,
  updateUISettings,
  updateUrl,
  scopeBounds,
}) => {
  useBackfill({
    query,
    requiredQueryParams: rqps,
    storedQueryParams: adminAccountsUiSettings,
    updateUrl,
  });
  const classes = useStyles();

  const handleChangeFavorite = useCallback(async ({
    id,
    accountPersona,
  }: { id: string, accountPersona: IAccountPersona; }) => {
    const { favorite } = accountPersona;
    if (!id) {
      return;
    }
    await updateFavoriteStatus(id, !favorite);
    jiggleDirtyState();
  }, [jiggleDirtyState]);

  const handleColumnChange = useCallback(
    (...args: any) => {
      jiggleDirtyState();
      adminAccountsTableColumnChange(...args);
    },
    [jiggleDirtyState, adminAccountsTableColumnChange]
  );
  const [filterValues, setFilterValues] = useState<IFilterValues>(
    INITIAL_FILTER_VALUES
  );
  const filters = useMemo(() => resolveFilters(filterValues), [filterValues]);

  const onPaginationChange = useCallback(
    (update: Partial<ILocationQuery>) => {
      updateUISettings({
        domain: 'adminAccounts',
        update,
      });

      updateUrl(update);
    },
    [updateUISettings, updateUrl]
  );
  const handleFilterChange = useCallback((filterEditors: IFilterEditor[]) => {
    const newFilterValues: IFilterValues = {};
    filterEditors.forEach(({ name, value }) => {
      newFilterValues[name] = value;
    });
    setFilterValues(newFilterValues);
  }, []);

  return (
    <div>
      <div className="view__header">
        <h1>My Accounts</h1>
        <Button
          onClick={() =>
            toggleModalDirect(
              'AccountsCreateEdit',
              {},
              {
                title: 'Add New Account',
                fullWidth: true,
              }
            )
          }
          text="Add Account"
          color="secondary"
          icon={AddIcon}
        />
      </div>
      <Table
        classList={classes}
        columnHeaders={
          scopeBounds === SCOPE_BOUNDS.team
            ? currentTableHeaders.filter((column) => column.id !== 'plan')
            : currentTableHeaders
        }
        filters={filters}
        serverSort
        onFilterValueChange={handleFilterChange as () => void}
        onSelectionChange={handleChangeFavorite}
        definedUser={definedUser}
        footerComponents={() => (
          <ReactToCSV
            buttonText="exportCSV"
            dataMap={accountsRowMapping}
            fileName="admin-accounts-table"
            header={currentTableHeaders.filter(
              (column) => column.id !== 'actions'
            )}
            table={() =>
              tableQuery(filters)(query as unknown as ILocationQuery)
            }
          />
        )}
        fetchTableData={() =>
          tableQuery(filters)(query as unknown as ILocationQuery)
        }
        onColumnChange={handleColumnChange}
        onPaginationChange={onPaginationChange}
        query={query as unknown as ILocationQuery}
        // @ts-ignore
        rowDetails={(rowData) => <AccountDetails account={rowData} />}
        rowMapping={accountsRowMapping}
        miscHandler={handleChangeFavorite} // passing into accounts.mapping for the STAR
        tableId="Accounts"
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  accountScopes: state.auth.superUser.scopes.accounts,
  adminAccountsUiSettings: getUISettingsWithout(state, 'adminAccounts', [
    'tableHeaders',
  ]),
  createEditModal: state.ui.modals.AccountsCreateEdit,
  currentTableHeaders: getUISettingsTableHeaders(state, 'adminAccounts'),
  definedUser: state.auth.superUser.scopes.root
    ? 'superAdminUser'
    : 'superUser',
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      adminAccountsTableColumnChange,
      updateUISettings,
    },
    dispatch
  );

export default compose(
  withRouter,
  withDirtyState(),
  connect(mapStateToProps, mapDispatchToProps)
)(
  withMemoizedProps(Accounts, [
    'accountScopes',
    'adminAccountsUiSettings',
    'createEditModal',
    'currentTableHeaders',
    'dirtyState',
    'q',
    'updateUISettings',
  ])
);
