import React from 'react';
import _ from 'lodash';
import { MicrosoftDefenderATP } from './Connectors/MicrosoftDefenderATP';
import { SIEM } from './Connectors/SIEM';
import { MicrosoftSentinel } from './Connectors/MicrosoftSentinel';
import { AmazonKinesis } from './Connectors/AmazonKinesis';
import { RESTEndpoint } from './Connectors/RESTEndpoint';
import { Okta } from './Connectors/Okta';

export class Configuration extends React.Component {
  constructor(props) {
    super(props);
    this.initConfigs();

    this.state = {
      microsoftDefenderConfiguration: {
        ...this.microsoftDefenderConfiguration,
      },
      siemConfiguration: { ...this.siemConfiguration },
      kinesisConfig: { ...this.kinesisConfig },
      microsoftSentinelConfig: { ...this.microsoftSentinelConfig },
      restEndpointConfiguration: { ...this.restEndpointConfiguration },
      oktaConfiguration: { ...this.oktaConfiguration },
    };

    if (!_.isNil(_.get(this.props, 'editModeData'))) {
      this.state[
        this.getKey(_.head(this.props.formState.destinationType).value)
      ] = _.get(this.props, 'editModeData');
      if (_.head(this.props.formState.destinationType).value === 'SIEM') {
        this.state.siemConfiguration.trustedCAFileName = _.get(
          props,
          'formState.trustedCAFileName'
        );
      }
    }
  }

  getKey = (key) => {
    switch (key) {
      case 'MICROSOFT_DEFENDER_ATP':
        return 'microsoftDefenderConfiguration';

      case 'SIEM':
      case 'ATT_ALIEN_VAULT':
      case 'WORKSPACE_ONE':
        return 'siemConfiguration';

      case 'MICROSOFT_SENTINEL':
        return 'microsoftSentinelConfig';

      case 'KINESIS':
        return 'kinesisConfig';

      case 'REST_ENDPOINT':
        return 'restEndpointConfiguration';

      case 'OKTA':
        return 'oktaConfiguration';

      default:
        return null;
    }
  };

  initConfigs = () => {
    this.kinesisConfig = {
      regionName: '',
      assumedRoleArn: null,
      accessKeyId: null,
      secretAccessKey: null,
      streamName: '',
      externalId: this.props.accountId,
    };

    this.microsoftSentinelConfig = {
      primaryKey: '',
      workspaceId: '',
      azureCloudType: 'COMMERCIAL',
    };

    this.microsoftDefenderConfiguration = {
      directoryId: _.isString(_.get(this.props, 'queryParams.tenantID', ''))
        ? _.get(this.props, 'queryParams.tenantID')
        : _.head(_.get(this.props, 'queryParams.tenantID', '')),
      isLoggedIn: {
        _value:
          _.hasIn(this.props, 'queryParams.tenantID') &&
          _.get(this.props, 'queryParams.tenantID') !== '',
      }, // '_value' since mapper includes objects with 'value'
    };

    this.siemConfiguration = {
      endpoint: '',
      port: '',
      messageType: '',
      payloadType: '',
      transportType: '',
      severity: '',
      facility: '',
      certificate: '',
      transportTypeTrustedCAFile: {},
    };

    this.restEndpointConfiguration = {
      payloadType: '',
      protocol: '',
      endPoint: '',
      allowSelfSignedSsl: false,
      authorizationType: '',
      username: null,
      password: null,
      apiKeyFormat: null,
      apiKeyFormatValue: null,
    };

    this.oktaConfiguration = {
      endpoint: '',
      port: '',
      messageType: '',
      payloadType: '',
      transportType: '',
      severity: '',
      facility: '',
      certificate: '',
      transportTypeTrustedCAFile: {},
    };

    if (_.hasIn(this.props, 'queryParams.tenantID')) {
      this.updateConfig(
        'MICROSOFT_DEFENDER_ATP',
        this.microsoftDefenderConfiguration
      );
    }
  };

  updateConfig = (key, configObject) => {
    this.setState({ [this.getKey(key)]: { ...configObject } });
    _.invoke(this.props, 'onConfigChange', configObject);
  };

  destinationSection = (state, props) => {
    switch (_.head(props.formState.destinationType).value) {
      case 'MICROSOFT_DEFENDER_ATP':
        return (
          <MicrosoftDefenderATP
            config={state.microsoftDefenderConfiguration}
            formState={_.get(props, 'formState')}
            reduxWrite={_.get(props, 'reduxWrite')}
            onChange={this.updateConfig}
          />
        );

      case 'SIEM':
        return (
          <SIEM
            config={state.siemConfiguration}
            onChange={this.updateConfig}
            editMode={!_.isNil(_.get(props, 'editModeData'))}
          />
        ); // editMode prop only because of drop downs in the view

      case 'MICROSOFT_SENTINEL':
        return (
          <MicrosoftSentinel
            config={state.microsoftSentinelConfig}
            onChange={this.updateConfig}
          />
        );

      case 'ATT_ALIEN_VAULT':
        return (
          <SIEM
            config={state.siemConfiguration}
            onChange={this.updateConfig}
            editMode={!_.isNil(_.get(props, 'editModeData'))}
            isAlienVault
          />
        ); // editMode prop only because of drop downs in the view

      case 'KINESIS':
        return (
          <AmazonKinesis
            config={state.kinesisConfig}
            onChange={this.updateConfig}
          />
        );

      case 'WORKSPACE_ONE':
        return (
          <SIEM
            config={state.siemConfiguration}
            onChange={this.updateConfig}
            editMode={!_.isNil(_.get(props, 'editModeData'))}
            isWorkspaceOne
          />
        ); // editMode prop only because of drop downs in the view
      case 'REST_ENDPOINT':
        return (
          <RESTEndpoint
            config={state.restEndpointConfiguration}
            onChange={this.updateConfig}
            editMode={props.editModeData}
          />
        ); // editMode prop only because of drop downs in the view

      case 'OKTA':
        return (
          <Okta
            config={state.oktaConfiguration}
            onChange={this.updateConfig}
            editMode={props.editModeData}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { state, props } = this;
    if (!_.head(props.formState.destinationType)) return null;

    if (props.onDestinationChangedObject.changed) {
      props.onDestinationChangedObject.changed = false;
      this.updateConfig(
        _.head(props.formState.destinationType).value,
        this.state[this.getKey(_.head(props.formState.destinationType).value)]
      );
    }

    return this.destinationSection(state, props);
  }
}

Configuration.defaultProps = {
  data: {},
};
