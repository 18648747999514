import cc from 'classcat';
import useAsyncResult from 'utils/useAsyncResult';
import { default as useSharedStyles } from '../../useStyles';
import useStyles from './useStyles';
import { fetchRiskiestDeviceModels } from './utils';
import { IRiskiestDeviceModels } from './models';
import GenericCard from 'components/UI/GenericCard';
import React, { useMemo } from 'react';
import CircleIcon from 'components/UI/icons/CircleIcon';
import HorizontalLegend from 'components/UI/Chart/HorizontalLegend';

interface IRiskiestDeviceModelsProps {
  teamId: string | null | undefined;
  zappId: string | null | number | undefined;
}

enum EDeviceColors {
  iOS = '#2a95cc',
  Android = '#4cae50',
}

const RiskiestDeviceModels: React.FC<IRiskiestDeviceModelsProps> = ({
  teamId,
  zappId,
}) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  const riskiestDeviceModels = useAsyncResult<IRiskiestDeviceModels>(
    fetchRiskiestDeviceModels,
    teamId ?? '',
    zappId ?? ''
  );

  const elements = useMemo(
    () =>
      (riskiestDeviceModels?.topCriticalDevices ?? []).map(
        ({ deviceModel, count, platform }, index) => (
          <div
            className="riskiest-device-model-item"
            key={`${deviceModel}-${index}`}
          >
            <div>
              <CircleIcon
                className={classes.icon}
                color={
                  platform === "2"
                    ? EDeviceColors.iOS
                    : EDeviceColors.Android
                }
                noExclamation
              />
              {deviceModel}
            </div>
            <div className={classes.title}>{count} critical threats</div>
          </div>
        )
      ),
    [classes.icon, classes.title, riskiestDeviceModels]
  );

  const segments = useMemo(
    () => [
      {
        color: EDeviceColors.iOS,
        label: 'iOS',
        value: riskiestDeviceModels?.iosThreatsCount ?? 0,
      },
      {
        color: EDeviceColors.Android,
        label: 'Android',
        value: riskiestDeviceModels?.androidThreatsCount ?? 0,
      },
    ],
    [riskiestDeviceModels]
  );

  const hasData = useMemo(
    () => riskiestDeviceModels?.topCriticalDevices?.length,
    [riskiestDeviceModels]
  );

  return (
    <GenericCard
      className={cc([sharedClasses.card, classes.riskiestDeviceModelsCard])}
    >
      <div className={sharedClasses.column}>
        <h2>Riskiest Device Models</h2>
        <div className={sharedClasses.column}>
          {hasData ? (
            <>
              <div className={classes.riskiestDeviceModels}>{elements}</div>
              <HorizontalLegend items={segments} />
            </>
          ) : (
            <div className={sharedClasses.pending}>N/A</div>
          )}
        </div>
      </div>
    </GenericCard>
  );
};

export default RiskiestDeviceModels;
