// TODO use this component in policy/threat page
import Switch from '@material-ui/core/Switch';
import React from 'react';
import useStyles from './useStyles';
import { useRecoilState } from 'recoil';
import { zScanPolicyEntriesHash } from './atoms';
import { IZscanIndicator } from './models';

interface IEnabledProps {
  data: IZscanIndicator;
  disabled: boolean;
}

const Enabled: React.FC<IEnabledProps> = (props) => {
  const { data, disabled } = props;
  const [zScanPolicyEntry, setzScanPolicyEntry] = useRecoilState(
    zScanPolicyEntriesHash
  );

  const classes = useStyles();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setzScanPolicyEntry({
      ...zScanPolicyEntry,
      [data?.id]: {
        severity: zScanPolicyEntry[data?.id]?.severity ?? data?.severity,
        enabled: e.target.checked,
        findingId: data?.id,
      },
    });
  };
  return (
    <div className={classes.enabledSwitch}>
      <Switch disabled={disabled} checked={data?.enabled} onChange={onChange} />
    </div>
  );
};

export default React.memo(Enabled);
