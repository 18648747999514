import { trimAndAddAsterisk } from 'utility/QueryUtility';

export const fetchInstancesParamsMapping = [
  {
    path: 'page',
  },
  {
    path: 'findingId.keyword',
  },
  { path: 'assessmentId.keyword' },
  { path: 'appId.keyword' },
  {
    path: 'size',
  },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }) => {
      switch (orderBy) {
        case 'appFindingName':
        case 'lastSightedVersion':
        case 'lastSightedBuild':
        case 'value':
          return `${orderBy}.keyword,${order}`;
        case 'created':
        case 'modified':
          return `${orderBy},${order}`;
        default:
          if (orderBy && order) {
            return `${orderBy},${order}`;
          }
          return null;
      }
    },
  },
  {
    path: 'search',
    transform: ({ search = '' }) => {
      console.log('search', search);
      if (!search) {
        return null;
      }

      return trimAndAddAsterisk(search);
    },
  },
  {
    path: 'id',
  },
  {
    path: 'accountId',
  },
  {
    path: 'appId',
    paramKey: 'appId.keyword',
    transform: ({ appId }) => appId,
  },
  {
    path: 'appFindingName',
    paramKey: 'appFindingName.keyword',
    transform: ({ appFindingName }) => appFindingName,
  },
  {
    path: 'locationId',
  },
  {
    path: 'findingId',
  },
  {
    path: 'value',
    paramKey: 'value.keyword',
    transform: ({ value }) => value,
  },
  {
    path: 'security',
  },
  {
    path: 'privacy',
  },
  {
    path: 'lastSightedVersion',
    paramKey: 'lastSightedVersion.keyword',
    transform: ({ lastSightedVersion }) => lastSightedVersion,
  },
  {
    path: 'lastSightedBuild',
    paramKey: 'lastSightedBuild.keyword',
    transform: ({ lastSightedBuild }) => lastSightedBuild,
  },
  {
    path: 'lastSighted',
  },
  {
    path: 'accepted',
  },
  {
    path: 'created',
    paramKey: 'created',
  },
  {
    path: 'modified',
  },
];
