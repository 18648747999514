import {
  Bar as CJSBar,
  HorizontalBar as CJSHorizontalBar,
} from 'react-chartjs-2';
import { ICJSGraph, IGraphData, IGraphOptions } from '../common/models';

// These types are asserted to help create layer until we upgrade to Chart.js 3.x
const CJSVerticalBarGraph = CJSBar as unknown as React.FC<ICJSGraph>;
const CJSHorizontalBarGraph =
  CJSHorizontalBar as unknown as React.FC<ICJSGraph>;

interface IBarGraphProps {
  data: IGraphData;
  getElementAtEvent?: (elems: Element[]) => void;
  height?: number;
  isHorizontal?: boolean;
  options: IGraphOptions;
  ref?: string;
  width?: number;
}

const BarGraph: React.FC<IBarGraphProps> = ({
  data,
  getElementAtEvent,
  height,
  isHorizontal = false,
  options,
  ref,
  width,
}) => {
  const CJSBarGraph = isHorizontal
    ? CJSHorizontalBarGraph
    : CJSVerticalBarGraph;

  return (
    <CJSBarGraph
      data={data}
      getElementAtEvent={getElementAtEvent}
      height={height}
      options={options}
      ref={ref}
      width={width}
    />
  );
};

export default BarGraph;
