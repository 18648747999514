import { OPEN_SNACKBAR } from 'reducers/SnackbarReducers';
import * as api from 'api/apis';
import AT from './ActionTypes';
/*
 * Fetching a page of zapps.
 */
const fetchAppsPageSucceeded = (page, size, order, orderBy, resp) => {
  return {
    type: AT.FETCH_APPS_PAGE_SUCCEEDED,
    payload: {
      apps: resp.data.content,
      appsMetadata: {
        count: resp.data.totalElements,
        page,
        size,
        order,
        orderBy,
      },
    },
  };
};

export const fetchAppsPage = (selectedTeams, page, size, order, orderBy) => {
  const selectedTeamIds = selectedTeams.map(team => team.value);
  return dispatch => {
    return api
      .fetchAppsPage(selectedTeamIds, page, size, order, orderBy)
      .then(resp => {
        dispatch(fetchAppsPageSucceeded(page, size, order, orderBy, resp));
      });
  };
};

export const deleteSelectedApp = (
  appId,
  app,
  successCallback,
  errorCallback
) => dispatch => {
  dispatch({
    type: AT.DELETE_SELECTED_APP_REQUEST,
  });
  return api
    .deleteSelectedApp(appId)
    .then(resp => {
      dispatch({
        type: AT.DELETE_SELECTED_APP_SUCCEEDED,
        payload: resp.data,
      });
      dispatch({
        type: OPEN_SNACKBAR,
        payload: `Deleted App "${app.name}"`,
      });
      successCallback(resp);
    })
    .catch(error => {
      dispatch({
        type: AT.DELETE_SELECTED_APP_FAILURE,
        error,
      });
      errorCallback(error);
      return Promise.reject(error);
    });
};

export const inappsTableColumnChange = (value, index, columnData) => {
  return {
    type: AT.INAPPS_TABLE_COLUMN_CHANGE,
    payload: { value, index, columnData },
  };
};
