import { createStyles, makeStyles } from '@material-ui/styles';

// Usage of 'any' inside this style decorator is discouraged; however, our theme
// currently does not conform to MUI's theme structure standards. So this will
// be a followup fix for later.
const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    accordionDrawerCard: {
      margin: '20px -6px 15px -6px',
      minHeight: 137,
    },
    drawerCardContent: {
      padding: '15px 15px 10px 15px',
    },
    drawerCardHeader: {
      alignItems: 'center',
      backgroundColor: palette.secondary.main,
      color: palette.primary.contrastText,
      display: 'flex',
      fontSize: 20,
      height: 30,
      justifyContent: 'center',
      textTransform: 'uppercase',
    },
    noAccordianDrawerCard: {
      marginTop: '15px',
      minHeight: 137,
    },
  })
);

export default useStyles;
