import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import DialogActions from '@material-ui/core/DialogActions';
import ZButton from 'UI/Buttons/ZButton';

const UploadError = ({
  errorMessage,
  handleCloseModal,
  retryUpload,
  isShowing = false,
  classes,
}) => {
  if (!isShowing) {
    return null;
  }

  return (
    <Fragment>
      <p>
        {' '}
        <ErrorOutline className={classes.errorOutline} />{' '}
        <span className={classes.errorText}>{errorMessage}</span>
      </p>
      <DialogActions>
        <ZButton
          styleName="modalCancel"
          action={handleCloseModal}
          color="secondary"
          buttonText="Cancel"
        />

        <ZButton
          action={retryUpload}
          buttonType="submit"
          color="primary"
          styleName="modalSave"
          buttonText="Retry"
        />
      </DialogActions>
    </Fragment>
  );
};

const styles = ({ palette }) => {
  return {
    errorOutline: {
      color: palette.error.main,
      verticalAlign: 'bottom',
    },
    errorText: {
      color: palette.text.secondary,
    },
  };
};

UploadError.propTypes = {
  isShowing: PropTypes.bool,
  errorMessage: PropTypes.string,
  handleCloseModal: PropTypes.func,
  retryUpload: PropTypes.func,
};

UploadError.defaultProps = {
  isShowing: false,
  errorMessage: 'Something went wrong!',
};

export default withStyles(styles)(UploadError);
