import Moment from 'moment';
import { trimAndAddAsterisk } from 'utility/QueryUtility';

export const fetchAppStatsParamMapping = [
  {
    path: 'after',
    transform: ({ after }) => (after ? after.toISOString() : null),
  },
  {
    path: 'before',
    transform: ({ before }) => (before ? before.toISOString() : new Date().toISOString()),
  },
  {
    path: 'from',
    paramKey: 'after',
    transform: ({ from, to }) => {
      if (!from) {
        return null;
      }

      return from === to
        ? Moment.unix(from)
          .startOf('day')
          .toISOString()
        : Moment.unix(from).toISOString();
    },
  },
  {
    path: 'to',
    paramKey: 'before',
    transform: ({ to, from }) => {
      if (!to) {
        return null;
      }

      return from === to
        ? Moment.unix(to)
          .endOf('day')
          .toISOString()
        : Moment.unix(to).toISOString();
    },
  },
  {
    path: 'search',
    transform: ({ search = '' }) => {
      if (!search) {
        return null;
      }

      return trimAndAddAsterisk(search);
    },
  },
  {
    path: 'zdevMetadata.appName',
    paramKey: 'zdevMetadata.appName.keyword',
  },
  {
    path: 'zdevMetadata.bundleIdentifier',
    paramKey: 'zdevMetadata.bundleIdentifier.keyword',
  },
  {
    path: 'zdevMetadata.appVersion',
    paramKey: 'zdevMetadata.appVersion.keyword',
  },
  {
    path: 'zdevMetadata.appBuildVersion',
    paramKey: 'zdevMetadata.appBuildVersion.keyword',
  },
  {
    path: 'zdevMetadata.osName',
    paramKey: 'zdevMetadata.osName.keyword',
  },
  {
    path: 'unresolvedPrivacyFindingsCount',
  },
  {
    path: 'unresolvedSecurityFindingsCount',
  },
  {
    path: 'zdevMetadata.analysis',
    paramKey: 'zdevMetadata.analysis.keyword',
  },
  {
    path: 'buildFindingsCount',
  },
];

export const fetchDashboardAggParamMapping = [
  // {
  //   path: 'after',
  //   transform: ({ after }) => (after ? after.toISOString() : null),
  // },
  // {
  //   path: 'before',
  //   transform: ({ before }) => (before ? before.toISOString() : new Date().toISOString()),
  // },
  // {
  //   path: 'from',
  //   paramKey: 'after',
  //   transform: ({ from, to }) => {
  //     if (!from) {
  //       return null;
  //     }

  //     return from === to
  //       ? Moment.unix(from)
  //         .startOf('day')
  //         .toISOString()
  //       : Moment.unix(from).toISOString();
  //   },
  // },
  // {
  //   path: 'to',
  //   paramKey: 'before',
  //   transform: ({ to, from }) => {
  //     if (!to) {
  //       return null;
  //     }

  //     return from === to
  //       ? Moment.unix(to)
  //         .endOf('day')
  //         .toISOString()
  //       : Moment.unix(to).toISOString();
  //   },
  // },
  {
    path: 'search',
    transform: ({ search = '' }) => {
      if (!search) {
        return null;
      }

      return trimAndAddAsterisk(search);
    },
  },
  {
    path: 'zdevMetadata.appName',
    paramKey: 'zdevMetadata.appName.keyword',
  },
  {
    path: 'zdevMetadata.bundleIdentifier',
    paramKey: 'zdevMetadata.bundleIdentifier.keyword',
  },
  {
    path: 'zdevMetadata.appVersion',
    paramKey: 'zdevMetadata.appVersion.keyword',
  },
  {
    path: 'zdevMetadata.appBuildVersion',
    paramKey: 'zdevMetadata.appBuildVersion.keyword',
  },
  {
    path: 'zdevMetadata.osName',
    paramKey: 'zdevMetadata.osName.keyword',
  },
  {
    path: 'unresolvedPrivacyFindingsCount',
  },
  {
    path: 'unresolvedSecurityFindingsCount',
  },
  {
    path: 'zdevMetadata.analysis',
    paramKey: 'zdevMetadata.analysis.keyword',
  },
  {
    path: 'buildFindingsCount',
  },
];
