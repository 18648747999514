import { DialogActions } from '@material-ui/core';
import Button from 'components/UI/Button';
import TextField from 'components/UI/input/TextField';
import { Field as FormikField, Form as FormikForm, Formik } from 'formik';
import { toggleModalDiffered } from 'utils/storeUtils';
import { initialValues } from './initialValues';
import { IHourlySyncSchedule } from './models';
import Schema from './schema';
import useStyles from './useStyles';
import { useTranslation } from 'react-i18next';

interface IHourlyFormProps {
  data: Partial<IHourlySyncSchedule>;
  handleSubmit: (schedule: IHourlySyncSchedule) => void;
}

const HourlyForm: React.FC<IHourlyFormProps> = ({ data, handleSubmit }) => {
  const resolvedInitialValues = initialValues(data);
  const { ready, t } = useTranslation();
  const classes = useStyles();
  if (!ready) {
    return null;
  }
  return (
    <Formik
      enableReinitialize
      initialValues={resolvedInitialValues}
      onSubmit={handleSubmit}
      validateOnBlur
      validationSchema={Schema()}
    >
      {({ isSubmitting }) => (
        <FormikForm>
          <div className={classes.timeWrapper}>
            <span className={classes.timelabel}>
              {t('MTD.INTEGRATIONS.SYNC_EVERY')}
            </span>
            <div className={classes.fieldsWrapper}>
              <FormikField
                className={classes.timeInput}
                color="primary"
                component={TextField}
                disabled={isSubmitting}
                InputProps={{
                  inputProps: {
                    min: '1',
                    max: '24',
                  },
                }}
                name="hours"
                type="number"
              />
            </div>
            <span className={classes.timelabel}>
              {t('MTD.INTEGRATIONS.HOURS_STARTING_AT')}
            </span>
            <div style={{ maxWidth: '122px' }}>
              <FormikField
                className={classes.timeInput}
                color="primary"
                component={TextField}
                disabled={isSubmitting}
                name="hourlyStartTime"
                type="time"
              />
            </div>
          </div>
          <DialogActions>
            <Button
              color="secondary"
              onClick={toggleModalDiffered('EMMSyncSchedule', false)}
              text={t('GLOBAL.CANCEL')}
              type="button"
            />
            <Button
              color="primary"
              text={t('GLOBAL.SAVE')}
              type="submit"
            ></Button>
          </DialogActions>
        </FormikForm>
      )}
    </Formik>
  );
};
export default HourlyForm;
