import { createStyles, makeStyles } from '@material-ui/styles';

// Usage of 'any' inside this style decorator is discouraged; however, our theme
// currently does not conform to MUI's theme structure standards. So this will
// be a followup fix for later.
const useStyles = makeStyles(({ palette }: any) =>
  createStyles({
    dayGroup: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gap: '0px 5px',
    },
    timeInput: {
      padding: '0px 5px 15px 5px',
      '& span': {
        position: 'fixed',
        top: '0px',
        left: '0px',
      },
      '& input': {
        padding: '4px 8px 4px 8px',
      },
      '& input::-webkit-calendar-picker-indicator': {
        filter: palette.accounts.inputs.svgFilter,
      },
    },
    timelabel: {
      paddingTop: '4px',
      whiteSpace: 'nowrap',
    },
    timeWrapper: {
      alignItems: 'flex-start',
      display: 'flex',
      marginTop: '8px',
    },
  })
);

export default useStyles;
