import CloseIcon from '@material-ui/icons/Close';
import cc from 'classcat';
import React from 'react';
import useStyles from './useStyles';

export interface IGenericErrorProps {
  customClass?: string;
  errorMessage: string | null | undefined;
  onClose?: () => void;
  showCloseIcon?: boolean;
}

const GenericError: React.FC<IGenericErrorProps> = ({
  customClass,
  errorMessage,
  onClose,
  showCloseIcon,
}) => {
  const classes = useStyles();
  if (!errorMessage) {
    return null;
  }
  return (
    <div
      className={cc([classes.errorBox, { [customClass ?? '']: !!customClass }])}
    >
      <span className={classes.intuneError}>{errorMessage}</span>
      {showCloseIcon && <CloseIcon color="error" onClick={onClose} />}
    </div>
  );
};

export default GenericError;