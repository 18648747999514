import React from 'react';

import ZButton from 'UI/Buttons/ZButton';

class SSOLoginButton extends React.Component {
  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <a href="api/auth/saml/login" style={{ textDecoration: 'none' }}>
          <ZButton
            fullWidth
            styleName="login"
            color="primary"
            buttonText="Sign In with SSO"
          />
        </a>
      </div>
    );
  }
}

export default SSOLoginButton;
