import { OS_NAMES } from './utils';
import AndroidIcon from 'components/UI/icons/AndroidIcon';
import AppleIcon from 'components/UI/icons/AppleIcon';
import ChromeOSIcon from 'components/UI/icons/ChromeOSIcon';
import Moment from 'moment';
import SeverityCircle from '../SeverityCircle';

export const findingsTableHeaders = [
  {
    id: 'severity',
    flex: 1,
    label: 'Severity',
    minWidth: 175,
    maxWidth: 500,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'name',
    label: 'Finding Name',
    flex: 1,
    minWidth: 175,
    maxWidth: 500,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'ticketed',
    label: 'Ticketed',
    flex: 1,
    minWidth: 100,
    maxWidth: 500,
    resizable: false,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'platform',
    flex: 1,
    label: 'Platform',
    minWidth: 125,
    maxWidth: 500,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
  {
    id: 'locationCount',
    flex: 1,
    label: 'Instances',
    minWidth: 125,
    maxWidth: 500,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },

  {
    id: 'modified',
    flex: 1,
    label: 'Modified',
    minWidth: 125,
    maxWidth: 500,
    resizable: true,
    show: true,
    showInContextMenu: true,
    sort: true,
  },
];

export const zScanFindingsColumnMapping = () => [
  {
    path: 'severity',
    columnContent: ({ rowData }: any) => {
      return <SeverityCircle severity={rowData?.severity} />;
    },
  },
  {
    path: 'name',
  },
  {
    path: 'ticketed',
    columnContent: ({ rowData }: any) => {
      return (
        <div>
          {rowData?.isTicketed ? (
            <span className="link--primary">Yes</span>
          ) : (
            'No'
          )}
        </div>
      );
    },
  },
  {
    path: 'platform',
    columnContent: ({ classList, rowData }: any) => {
      if (!rowData?.platform[0]) {
        return <span className={classList?.osCell ?? ''} />;
      }
      const osLowerCaseName = rowData?.platform[0]?.toLowerCase() ?? '';
      const osName = OS_NAMES?.[osLowerCaseName] ?? '';

      const icon =
        osLowerCaseName === 'ios' ? (
          <AppleIcon />
        ) : ['chrome os', 'chrome_os'].includes(osLowerCaseName) ? (
          <div className="table__os-icon">
            <ChromeOSIcon />
          </div>
        ) : osLowerCaseName === 'chrome_os' ? ( // change this when BE renames all references of 'chrome_os' to 'chrome os' or viceversa
          <div className="table__os-icon">
            <ChromeOSIcon />
          </div>
        ) : (
          <AndroidIcon />
        );

      return (
        <div className="table__os-icon flex--spaced">
          {icon}
          <div className="table-cell__ellipsis" style={{ paddingLeft: '5px' }}>
            {osName}
          </div>
        </div>
      );
    },
  },
  {
    path: 'locationCount',
    columnContent: ({ rowData }: any) => {
      return <div>{rowData?.locations.length}</div>;
    },
  },
  {
    path: 'modified',
    columnContent: ({ rowData }: any) =>
      rowData?.modified ? Moment(rowData?.modified).format('DD-MMM-YYYY') : '',
  },
];
